import { useEffect } from 'react';

import { InfoModalContent, Modal, useDialogModal } from '@features/modal';
import { PublishingActionsCreator } from '@actionCreators/PublishingActionCreator';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { selectPublishingStatus } from '@selectors/CoursesSelectors';
import { OtherLocations } from '@components/OtherLocations';
import { ListOfLinks } from '@components/ListOfLinks';

export const PublishingErrorsModal = () => {
  const dispatch = useAppDispatch();
  const publishing = useAppSelector(selectPublishingStatus);

  const { modalOpened: isPublishingErrorsModalOpened, invalidChildren, errorInExercises } = publishing;

  const handleClose = () => {
    dispatch(PublishingActionsCreator.closeChildrenErrorsModal());
  };

  const { open: openOtherPlacesModal, modal: otherPlacesModal } = useDialogModal((modalControls) => (
    <Modal {...modalControls}>
      <InfoModalContent
        testIdPrefix="publish-error"
        title="Warning!"
        description="Publish/Update following components, some errors were found in their published versions"
        close={() => {
          handleClose();
          modalControls.close();
        }}
      >
        {errorInExercises ? (
          <ListOfLinks ids={invalidChildren} />
        ) : (
          <OtherLocations mappingsDetails={invalidChildren} />
        )}
      </InfoModalContent>
    </Modal>
  ));

  useEffect(() => {
    if (isPublishingErrorsModalOpened) {
      openOtherPlacesModal();
    }
  }, [isPublishingErrorsModalOpened, openOtherPlacesModal]);

  return otherPlacesModal;
};
