import { SectionActions } from '@actions/SectionActions';
import { AvailableLocalizations } from '@common/enums/AvailableLocalizations';
import { ExerciseTypes } from '@common/enums/ExerciseTypes';
import { LoadingStage } from '@common/enums/LoadingStage';
import { CourseSliceInterface } from '@common/interfaces/slices/CourseSliceInterface';
import ExerciseDataModelAssembler from '@components/Exercises/ExerciseDataModelAssembler';
import { updateTextField } from '@helpers/updateTextField';
import { UploadMediaResponse } from '@services/HelpersService';
import { FlashcardExerciseActions } from '@actions/FlashcardExerciseActions';
import FlashcardExerciseInterface from '@components/Exercises/Flashcard/interfaces/FlashCardExerciseInterface';
import { selectedGroupsOfParentInitialData } from '@redux/initialStates/courseInitialState';
import { PayloadAction } from '@reduxjs/toolkit';
import { clone } from '@helpers/clone';
import { removeAudioDataFromBundle } from './common/helpers';

const FlashcardExerciseCourseReducers = {
  [SectionActions.COURSE_EXERCISE_FLASHCARD_REQUEST_SUCCEEDED]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    let clonedCourseSlice: CourseSliceInterface = clone(state);
    let { content }: FlashcardExerciseInterface = payload;

    let exercise: FlashcardExerciseInterface = {
      ...clonedCourseSlice.loadedExercise.exercise,
      content,
      type: ExerciseTypes.flashcard,
    };

    return {
      ...clonedCourseSlice,
      selectedGroupsOfParent: selectedGroupsOfParentInitialData,
      loadedExercise: {
        loaded: LoadingStage.loaded,
        exercise,
      },
    };
  },
  [FlashcardExerciseActions.SET_LEARNING_WORD_TEXT_ALT]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    return ExerciseDataModelAssembler.updateAltTextField(
      'phrase',
      state,
      payload,
      AvailableLocalizations.text,
      'learningWordBundle',
    );
  },
  [FlashcardExerciseActions.SET_LEARNING_EXAMPLE_TEXT]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    return ExerciseDataModelAssembler.updateTextFieldInBundle(
      'example',
      'learningWordBundle',
      state,
      payload,
      AvailableLocalizations.text,
      'Flashcard entity example.',
    );
  },
  [FlashcardExerciseActions.NULLIFY_EXAMPLE]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    return ExerciseDataModelAssembler.nullifyFieldInBundle({
      field: 'example',
      bundleName: 'learningWordBundle',
      state,
    });
  },
  [FlashcardExerciseActions.SET_INSTRUCTIONS_LANGUAGE]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    return ExerciseDataModelAssembler.updateCheckbox('instructionsLanguage', state, payload);
  },
  [FlashcardExerciseActions.SET_FLASHCARD_ALL_FIELDS]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    let clonedCourseSlice: CourseSliceInterface = clone(state);

    const { values } = payload;

    clonedCourseSlice = updateTextField(clonedCourseSlice, values, 'instructions');
    clonedCourseSlice = updateTextField(clonedCourseSlice, values, 'phrase', 'learningWordBundle');
    clonedCourseSlice = updateTextField(clonedCourseSlice, values, 'example', 'learningWordBundle');

    return clonedCourseSlice;
  },
  [FlashcardExerciseActions.REMOVE_IMAGE]: (state: CourseSliceInterface): CourseSliceInterface => {
    return ExerciseDataModelAssembler.nullifyFieldInBundle({
      field: 'image',
      bundleName: 'learningWordBundle',
      state,
    });
  },
  [FlashcardExerciseActions.REMOVE_AUDIO]: (state: CourseSliceInterface): CourseSliceInterface => {
    let clonedCourseSlice: CourseSliceInterface = clone(state);

    return removeAudioDataFromBundle(clonedCourseSlice, 'phrase', 'learningWordBundle', true);
  },
  [FlashcardExerciseActions.REMOVE_EXAMPLE_AUDIO]: (state: CourseSliceInterface): CourseSliceInterface => {
    let clonedCourseSlice: CourseSliceInterface = clone(state);

    return removeAudioDataFromBundle(clonedCourseSlice, 'example', 'learningWordBundle', true);
  },
  [FlashcardExerciseActions.REMOVE_VIDEO]: (state: CourseSliceInterface): CourseSliceInterface => {
    return ExerciseDataModelAssembler.nullifyFieldInBundle({
      field: 'video',
      bundleName: 'learningWordBundle',
      state,
    });
  },
  [FlashcardExerciseActions.UPLOAD_VIDEO_SUCCESS]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<UploadMediaResponse>,
  ): CourseSliceInterface => {
    let { content, mediaId } = payload;

    state.loadedExercise.exercise.content.learningWordBundle = {
      ...state.loadedExercise.exercise.content.learningWordBundle,
      video: {
        ...content,
        _id: mediaId,
        changed: true,
      },
    };
    state.mediaUploading.imageOrVideoUploadingInProcess = state.mediaUploading.imageOrVideoUploadingInProcess.filter(
      (item) => item !== 'video',
    );

    return state;
  },
};

export default FlashcardExerciseCourseReducers;
