import styled, { css } from 'styled-components/macro';

import { Flag } from '@components/Flag';
import CopyToClipboard from 'react-copy-to-clipboard';

export const VocabularyBundlesWrapper = styled.div`
  border-color: ${({ theme }) => theme.colorV2.uiDivider};
  border-style: solid;
  border-width: 0.1rem 0;
  margin: 3.6rem 0 0 1.6rem;
`;

export const VocabularyBundlesToggle = styled.div<{ isOpen: boolean }>`
  ${({ isOpen, theme }) => css`
    align-items: center;
    display: flex;
    font-size: 1.6rem;
    font-weight: 700;
    justify-content: space-between;
    line-height: 2.4rem;
    padding: 2.4rem 1.6rem;

    &:hover {
      cursor: pointer;
    }

    > svg {
      height: 2.4rem;
      transform: rotate(${isOpen ? '180deg' : '0'});
      transition: transform 0.25s ease-in;
      width: 2.4rem;

      path {
        fill: ${theme.colorV2.textTertiary};
      }
    }
  `}
`;

export const VocabularyBundlesTitle = styled.div`
  display: flex;
`;

export const VocabularyBundlesContainer = styled.div<{ noContent: boolean }>`
  align-items: ${({ noContent }) => (noContent ? 'center' : 'initial')};
  display: flex;
  flex-direction: column-reverse;
  font-size: 1.4rem;
  justify-content: ${({ noContent }) => (noContent ? 'center' : 'initial')};
  min-height: 9rem;
`;

export const VocabularyBundlesList = styled.ul`
  list-style-type: none;
  margin: 1.6rem 0 0 0;
  padding: 0;
  width: 100%;
`;

export const VocabularyBundlesListItem = styled.li`
  align-items: center;
  display: flex;
  margin: 0 0 2.4rem 0;
  padding: 0 1.6rem;
`;

export const VocabularyBundleImage = styled.img`
  aspect-ratio: 1.375;
  border-radius: 1rem;
  margin-right: 1.6rem;
  width: 5.5rem;
`;

export const VocabularyBundleData = styled.div`
  flex: 1;
`;

export const VocabularyBundlePhrase = styled.span`
  font-size: 1.6rem;
  font-weight: 700;
`;

export const VocabularyBundleLocations = styled.div`
  color: ${({ theme }) => theme.colorV2.accentPrimary};
  font-size: 1.6rem;
  line-height: 2.4rem;
`;

export const VocabularyBundleLocationsWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 0.4rem;
`;

export const StyledFlag = styled(Flag)`
  img {
    height: 2rem;
    width: 2rem;
  }
`;

export const CopyToClipboardButton = styled(CopyToClipboard)`
  font-size: 1.4rem;
  padding: 1.2rem 0 1.2rem 1.2rem;
  width: fit-content;

  svg {
    margin-right: 0.2rem;
  }
`;
