// Roleplay actions

const GET_ROLEPLAY = 'GET_ROLEPLAY';
const ROLEPLAY_LOADING = 'ROLEPLAY_LOADING';
const ROLEPLAY_LOADED = 'ROLEPLAY_LOADED';
const GET_ROLEPLAY_CATEGORIES = 'GET_ROLEPLAY_CATEGORIES';
const ROLEPLAY_CATEGORIES_LOADING = 'ROLEPLAY_CATEGORIES_LOADING';
const ROLEPLAY_CATEGORIES_LOADED = 'ROLEPLAY_CATEGORIES_LOADED';
const SET_INITIAL_ROLEPLAY = 'SET_INITIAL_ROLEPLAY';
const CREATE_ROLEPLAY_CATEGORY = 'CREATE_ROLEPLAY_CATEGORY';
const REMOVE_ROLEPLAY_CATEGORY = 'REMOVE_ROLEPLAY_CATEGORY';
const UPDATE_ROLEPLAY_CATEGORIES_ORDER = 'UPDATE_ROLEPLAY_CATEGORIES_ORDER';

// Roleplay Category actions
const GET_ROLEPLAY_CATEGORY = 'GET_ROLEPLAY_CATEGORY';
const ROLEPLAY_CATEGORY_LOADING = 'ROLEPLAY_CATEGORY_LOADING';
const ROLEPLAY_CATEGORY_LOADED = 'ROLEPLAY_CATEGORY_LOADED';
const GET_ROLEPLAY_SCENARIOS = 'GET_ROLEPLAY_SCENARIOS';
const ROLEPLAY_SCENARIOS_LOADING = 'ROLEPLAY_SCENARIOS_LOADING';
const ROLEPLAY_SCENARIOS_LOADED = 'ROLEPLAY_SCENARIOS_LOADED';
const SET_INITIAL_ROLEPLAY_CATEGORY = 'SET_INITIAL_ROLEPLAY_CATEGORY';
const SET_ROLEPLAY_CATEGORY_TITLE = 'SET_ROLEPLAY_CATEGORY_TITLE';
const SET_ROLEPLAY_CATEGORY_ICON = 'SET_ROLEPLAY_CATEGORY_ICON';
const CREATE_ROLEPLAY_SCENARIO = 'CREATE_ROLEPLAY_SCENARIO';
const REMOVE_ROLEPLAY_SCENARIO = 'REMOVE_ROLEPLAY_SCENARIO';
const UPDATE_ROLEPLAY_SCENARIOS_ORDER = 'UPDATE_ROLEPLAY_SCENARIOS_ORDER';
const SAVE_CATEGORY = 'SAVE_ROLEPLAY_CATEGORY';
const SET_CATEGORY_ALL_VALUES = 'SET_ROLEPLAY_CATEGORY_ALL_VALUES';
const SET_CATEGORY_TO_NOT_CHANGED = 'SET_ROLEPLAY_CATEGORY_TO_NOT_CHANGED';

// Roleplay Scenario actions
const SCENARIO_LOADING = 'SCENARIO_LOADING';
const SCENARIO_LOADED = 'SCENARIO_LOADED';
const GET_SCENARIO = 'GET_SCENARIO';
const SET_INITIAL_SCENARIO = 'SET_INITIAL_SCENARIO';
const UPLOAD_IMAGE_TO_SCENARIO = 'UPLOAD_IMAGE_TO_SCENARIO';
const REMOVE_IMAGE_FROM_SCENARIO = 'REMOVE_IMAGE_FROM_SCENARIO';
const SET_SCENARIO_IMAGE = 'SET_SCENARIO_IMAGE';
const UPDATE_SCENARIO_FIELD = 'UPDATE_SCENARIO_FIELD';
const SAVE_SCENARIO = 'SAVE_SCENARIO';
const SET_SCENARIO_ALL_VALUES = 'SET_SCENARIO_ALL_VALUES';

// Roleplay Navigation
const SET_LOADING_PARENT_ID = 'SET_ROLEPLAY_LOADING_PARENT_ID';
const GET_ALL_NAVIGATION = 'GET_ROLEPLAY_ALL_NAVIGATION';
const UPDATE_NAVIGATION = 'UPDATE_ROLEPLAY_NAVIGATION';

// Translation Panel
const CLOSE_TRANSLATIONS_PANEL = 'ROLEPLAY_CLOSE_TRANSLATIONS_PANEL';
const OPEN_TRANSLATIONS_PANEL = 'ROLEPLAY_OPEN_TRANSLATIONS_PANEL';
const REMOVE_AUDIO = 'ROLEPLAY_REMOVE_AUDIO';
const SET_AUDIO = 'ROLEPLAY_SET_AUDIO';
const UPLOAD_AUDIO = 'ROLEPLAY_UPLOAD_AUDIO';

// Common
const REMOVE_STRING = 'ROLEPLAY_REMOVE_STRING';
const SET_STRING = 'ROLEPLAY_SET_STRING';
const SET_CONTENT_ID = 'ROLEPLAY_SET_CONTENT_ID';
const SET_MEDIA_VALUE_AFTER_PROCESSING = 'ROLEPLAY_SET_MEDIA_VALUE_AFTER_PROCESSING';
const SET_VALIDATION_RESULT = 'ROLEPLAY_SET_VALIDATION_RESULT';

export const RoleplayActions = {
  GET_ROLEPLAY,
  ROLEPLAY_LOADING,
  ROLEPLAY_LOADED,
  GET_ROLEPLAY_CATEGORIES,
  ROLEPLAY_CATEGORIES_LOADING,
  ROLEPLAY_CATEGORIES_LOADED,
  SET_INITIAL_ROLEPLAY,
  CREATE_ROLEPLAY_CATEGORY,
  REMOVE_ROLEPLAY_CATEGORY,
  UPDATE_ROLEPLAY_CATEGORIES_ORDER,
  SET_LOADING_PARENT_ID,
  GET_ALL_NAVIGATION,
  UPDATE_NAVIGATION,
  GET_ROLEPLAY_CATEGORY,
  ROLEPLAY_CATEGORY_LOADING,
  ROLEPLAY_CATEGORY_LOADED,
  GET_ROLEPLAY_SCENARIOS,
  ROLEPLAY_SCENARIOS_LOADING,
  ROLEPLAY_SCENARIOS_LOADED,
  SET_INITIAL_ROLEPLAY_CATEGORY,
  SET_ROLEPLAY_CATEGORY_TITLE,
  SET_ROLEPLAY_CATEGORY_ICON,
  CREATE_ROLEPLAY_SCENARIO,
  REMOVE_ROLEPLAY_SCENARIO,
  UPDATE_ROLEPLAY_SCENARIOS_ORDER,
  SAVE_CATEGORY,
  SET_CATEGORY_ALL_VALUES,
  SET_CATEGORY_TO_NOT_CHANGED,
  SCENARIO_LOADING,
  SCENARIO_LOADED,
  GET_SCENARIO,
  SET_INITIAL_SCENARIO,
  UPLOAD_IMAGE_TO_SCENARIO,
  REMOVE_IMAGE_FROM_SCENARIO,
  SET_SCENARIO_IMAGE,
  UPDATE_SCENARIO_FIELD,
  SAVE_SCENARIO,
  SET_SCENARIO_ALL_VALUES,
  CLOSE_TRANSLATIONS_PANEL,
  OPEN_TRANSLATIONS_PANEL,
  REMOVE_AUDIO,
  SET_AUDIO,
  UPLOAD_AUDIO,
  REMOVE_STRING,
  SET_STRING,
  SET_CONTENT_ID,
  SET_MEDIA_VALUE_AFTER_PROCESSING,
  SET_VALIDATION_RESULT,
} as const;
