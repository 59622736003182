import { UploadTranslationActions } from '@actions/UploadTranslationActions';

export const UploadTranslationActionsCreator = {
  upload: ({ translationsFile }: { translationsFile: File }) => ({
    type: UploadTranslationActions.UPLOAD,
    payload: {
      translationsFile,
    },
  }),
  close: () => ({
    type: UploadTranslationActions.CLOSE,
  }),
};
