import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import { Loader } from '@features/theme';

const StyledPublishButton = styled(Button)`
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  border-radius: 3.2rem;
  padding: 1.2rem 2.4rem;
  height: 4.8rem;
`;

type CommonPublishButtonProps = {
  contentType: string;
  isButtonDisabled: boolean;
  isPublishAvailable: boolean;
  isPublishedContent: boolean;
  isPublishingInProgress: boolean;
  isUpdateButtonDisabled: boolean;
  isUpdateVisible: boolean;
  isUnpublishVisible: boolean;
  tooltipText: string;
  onPublishClick: (forUpdate: boolean) => void;
};

export const CommonPublishButton = ({
  contentType,
  isButtonDisabled,
  isPublishAvailable,
  isPublishedContent,
  isPublishingInProgress,
  isUpdateButtonDisabled,
  isUpdateVisible,
  isUnpublishVisible,
  tooltipText,
  onPublishClick,
}: CommonPublishButtonProps) => {
  const buttonText = `${isPublishedContent ? 'Unpublish' : 'Publish'} ${contentType}`;

  return (
    <>
      {isPublishAvailable ? (
        <>
          {isUpdateVisible ? (
            <StyledPublishButton
              id="header-update-button"
              onClick={() => onPublishClick(true)}
              disabled={isUpdateButtonDisabled}
            >
              {isPublishingInProgress ? <Loader /> : 'Update'}
            </StyledPublishButton>
          ) : null}
          <div data-tip={tooltipText} data-for="publish-tooltip">
            {tooltipText && <ReactTooltip className="tooltip" id="publish-tooltip" effect="solid" place="bottom" />}
            <StyledPublishButton
              onClick={() => onPublishClick(false)}
              disabled={isButtonDisabled}
              id="header-publish-button"
            >
              {isPublishingInProgress ? <Loader /> : buttonText}
            </StyledPublishButton>
          </div>
        </>
      ) : (
        <>
          {isUnpublishVisible ? (
            <div data-tip={tooltipText} data-for="unpublish-tooltip">
              {tooltipText && <ReactTooltip className="tooltip" id="unpublish-tooltip" effect="solid" place="bottom" />}
              <StyledPublishButton
                onClick={() => onPublishClick(false)}
                disabled={isButtonDisabled}
                id="header-unpublish-button"
              >
                {isPublishingInProgress ? <Loader /> : `Unpublish ${contentType}`}
              </StyledPublishButton>
            </div>
          ) : null}
        </>
      )}
    </>
  );
};
