import { takeLatest } from 'redux-saga/effects';

import { GrammarReviewSagas } from '@sagas/grammarReview/definitions/GrammarReviewSagasDefinition';
import { GrammarReviewActions } from '@actions/GrammarReviewActions';
import type { AppDispatch } from '@redux/store';

export function* GramarReviewRelatedSagas(dispatch: AppDispatch) {
  // Review sagas
  yield takeLatest(GrammarReviewActions.GET_GRAMMAR_REVIEW, GrammarReviewSagas.getGrammarReview);
  yield takeLatest(GrammarReviewActions.GET_GRAMMAR_CATEGORIES, GrammarReviewSagas.getGrammarCategories);
  yield takeLatest(GrammarReviewActions.CREATE_GRAMMAR_CATEGORY, GrammarReviewSagas.createGrammarCategory);
  yield takeLatest(GrammarReviewActions.UPDATE_CATEGORIES_ORDER, GrammarReviewSagas.reorderCategories);
  yield takeLatest(GrammarReviewActions.REMOVE_CATEGORY, GrammarReviewSagas.removeCategory);

  // Category sagas
  yield takeLatest(GrammarReviewActions.GET_GRAMMAR_CATEGORY, GrammarReviewSagas.getGrammarCategory);
  yield takeLatest(GrammarReviewActions.GET_GRAMMAR_TOPICS, GrammarReviewSagas.getGrammarTopics);
  yield takeLatest(GrammarReviewActions.CREATE_GRAMMAR_TOPIC, GrammarReviewSagas.createGrammarTopic);
  yield takeLatest(GrammarReviewActions.UPDATE_TOPICS_ORDER, GrammarReviewSagas.reorderTopics);
  yield takeLatest(GrammarReviewActions.REMOVE_TOPIC, GrammarReviewSagas.removeTopic);
  yield takeLatest(GrammarReviewActions.SAVE_CATEGORY, GrammarReviewSagas.saveCategory, dispatch);

  // Topic sagas
  yield takeLatest(GrammarReviewActions.GET_GRAMMAR_TOPIC, GrammarReviewSagas.getGrammarTopic);
  yield takeLatest(GrammarReviewActions.GET_GRAMMAR_EXERCISES, GrammarReviewSagas.getGrammarExercises);
  yield takeLatest(GrammarReviewActions.CREATE_GRAMMAR_EXERCISE, GrammarReviewSagas.createGrammarExercise);
  yield takeLatest(GrammarReviewActions.CREATE_REFERENCE_EXERCISE, GrammarReviewSagas.createReferenceExercise);
  yield takeLatest(GrammarReviewActions.UPDATE_REFERENCE_EXERCISES_ORDER, GrammarReviewSagas.reorderReferenceExercises);
  yield takeLatest(GrammarReviewActions.UPDATE_GRAMMAR_EXERCISES_ORDER, GrammarReviewSagas.reorderGrammarExercises);
  yield takeLatest(GrammarReviewActions.REMOVE_EXERCISE, GrammarReviewSagas.removeExercise);
  yield takeLatest(GrammarReviewActions.ATTACH_LESSONS_TO_TOPIC, GrammarReviewSagas.attachLessonsToTopic);
  yield takeLatest(GrammarReviewActions.UPDATE_CEFR, GrammarReviewSagas.updateCefr);
  yield takeLatest(GrammarReviewActions.REUSE_EXERCISE, GrammarReviewSagas.reuseExercise);
  yield takeLatest(GrammarReviewActions.COPY_EXERCISE, GrammarReviewSagas.copyExercise);
  yield takeLatest(GrammarReviewActions.SAVE_TOPIC, GrammarReviewSagas.saveTopic, dispatch);

  // Common sagas
  yield takeLatest(GrammarReviewActions.REMOVE_STRING, GrammarReviewSagas.removeString);
  yield takeLatest(GrammarReviewActions.UPLOAD_AUDIO, GrammarReviewSagas.uploadAudio);
  yield takeLatest(GrammarReviewActions.MAKE_COPY, GrammarReviewSagas.makeCopy);
  yield takeLatest(GrammarReviewActions.GET_ALL_NAVIGATION, GrammarReviewSagas.getAllNavigation);
}
