const SAVE = 'TYPING_RECOGNITION_EXERCISE_SAVE';
const SET_PHRASE_TEXT_ALT = 'TYPING_SET_PHRASE_TEXT_ALT';
const SET_INSTRUCTIONS_LANGUAGE = 'TYPING_SET_INSTRUCTIONS_LANGUAGE';
const UPLOAD_IMAGE = 'TYPING_UPLOAD_IMAGE';
const REMOVE_IMAGE = 'TYPING_REMOVE_IMAGE';
const REMOVE_AUDIO = 'TYPING_REMOVE_AUDIO';
const SET_TYPING_ALL_FIELDS = 'SET_TYPING_ALL_FIELDS';

export const TypingExerciseActions = {
  SAVE,
  SET_PHRASE_TEXT_ALT,
  SET_INSTRUCTIONS_LANGUAGE,
  UPLOAD_IMAGE,
  REMOVE_IMAGE,
  REMOVE_AUDIO,
  SET_TYPING_ALL_FIELDS,
} as const;
