import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { ContentTypesActionsCreator } from '@actionCreators/ContentTypesActionsCreator';
import { getValueByLanguage } from '@features/content';
import { getUrlToNavigate } from '@helpers/contentCardHelper';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { PublishingCardButton } from '@components/ContentTypes/BaseContent/PublishingCardButton';
import { CardPendingStatus } from '@components/ContentTypes/BaseContent/CardPendingStatus';
import { RemoveCardButton } from '@components/ContentTypes/BaseContent/RemoveCardButton';
import { CardPublishingStatus } from '@components/ContentTypes/BaseContent/CardPublishingStatus';
import { CardValidationMark } from '@components/ContentTypes/BaseContent/CardValidationMark';
import { CardDragHandler } from '@components/ContentTypes/BaseContent/CardDragHandler';
import { RemoveModal } from '@components/ContentTypes/BaseContent/RemoveModal';
import { ChapterCardProps } from './ChapterCardProps';

import { CardWrapper, CardDataWrapper, CardControllers, DataWrapper, Data, Title } from '../card.styles';
import { selectNewContentElementId, selectSelectedGroupsOfParentParentContents } from '@selectors/CoursesSelectors';

export const ChapterCard = ({ content, idx, dndProps }: ChapterCardProps) => {
  const params: { courseId?: string; levelId?: string; lessonId?: string; activityId?: string } = useParams();

  const { id, ready, type, titleWithLocalizations, changeStatus, validation } = content;

  const loadedContent = useAppSelector(selectSelectedGroupsOfParentParentContents);
  let contentToValidate = loadedContent;
  const isChildInvalid = Object.values(contentToValidate?.validation?.invalidChildEntities || {})?.includes(id);

  const dispatch = useAppDispatch();
  const [hovered, setHovered] = useState(false);
  const [isRemoveModalOpened, setIsRemoveModalOpened] = useState(false);

  const newElementId = useAppSelector(selectNewContentElementId);

  const handleRemove = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsRemoveModalOpened(true);
  };

  const chapterTitle = getValueByLanguage(titleWithLocalizations?.textLocalizations) || 'Untitled chapter';

  return (
    <CardWrapper id={`chapter-${id}`} onMouseOver={() => setHovered(true)} onMouseOut={() => setHovered(false)}>
      <RemoveModal
        opened={isRemoveModalOpened}
        onHide={() => setIsRemoveModalOpened(false)}
        onDelete={() => {
          dispatch(ContentTypesActionsCreator.removeContent(content));
        }}
      />
      <CardDragHandler dndProps={dndProps} visible={hovered} />
      <CardDataWrapper
        to={getUrlToNavigate(params, id, type)}
        $active={dndProps?.snapshot?.isDragging || id === newElementId}
        $hovered={hovered}
      >
        <CardPublishingStatus hasPendingChanges={!!changeStatus?.hasPendingChanges} ready={!!ready} />
        <DataWrapper>
          <Data>
            <CardPendingStatus content={content} />
            <Title title={chapterTitle}>
              {idx !== null ? `${idx + 1} - ` : ''}
              {chapterTitle}
              <CardValidationMark isInvalid={isChildInvalid || !validation?.valid} />
            </Title>
          </Data>
          <CardControllers>
            <PublishingCardButton hovered={hovered} content={content} />
            <RemoveCardButton hovered={hovered} content={content} onRemove={handleRemove} />
          </CardControllers>
        </DataWrapper>
      </CardDataWrapper>
    </CardWrapper>
  );
};
