const SAVE = 'SPEECH_RECOGNITION_EXERCISE_SAVE';
const UPLOAD_IMAGE = 'SPEECH_RECOGNITION_EXERCISE_UPLOAD_IMAGE';
const SET_INSTRUCTIONS_LANGUAGE = 'SPEECH_RECOGNITION_SET_INSTRUCTIONS_LANGUAGE';
const UPDATE_PHONEMES = 'SPEECH_RECOGNITION_UPDATE_PHONEMES';
const REMOVE_MULTI_OPTION_CHOOSER_OPTION = 'SPEECH_RECOGNITION_REMOVE_MULTI_OPTION_CHOOSER_OPTION';
const SET_MULTI_OPTION_CHOOSER_OPTION_AS = 'SPEECH_RECOGNITION_SET_MULTI_OPTION_CHOOSER_OPTION_AS';
const REMOVE_IMAGE = 'SPEECH_RECOGNITION_REMOVE_IMAGE';
const REMOVE_AUDIO = 'SPEECH_RECOGNITION_REMOVE_AUDIO';
const SET_FEEDBACK_TEXT_OPTION = 'SET_FEEDBACK_TEXT_OPTION';
const SET_SPEECH_RECOGNITION_ALL_FIELDS = 'SET_SPEECH_RECOGNITION_ALL_FIELDS';
const REMOVE_VIDEO = 'SPEECH_RECOGNITION_REMOVE_VIDEO';
const UPLOAD_VIDEO_SUCCESS = 'SPEECH_RECOGNITION_UPLOAD_VIDEO_SUCCESS';

export const SpeechRecognitionExerciseActions = {
  SAVE,
  UPLOAD_IMAGE,
  SET_INSTRUCTIONS_LANGUAGE,
  UPDATE_PHONEMES,
  REMOVE_MULTI_OPTION_CHOOSER_OPTION,
  SET_MULTI_OPTION_CHOOSER_OPTION_AS,
  REMOVE_IMAGE,
  REMOVE_AUDIO,
  SET_FEEDBACK_TEXT_OPTION,
  SET_SPEECH_RECOGNITION_ALL_FIELDS,
  REMOVE_VIDEO,
  UPLOAD_VIDEO_SUCCESS,
} as const;
