import { PayloadAction } from '@reduxjs/toolkit';

import { SectionActions } from '@actions/SectionActions';
import { ExerciseTypes } from '@common/enums/ExerciseTypes';
import { LoadingStage } from '@common/enums/LoadingStage';
import { CourseSliceInterface } from '@common/interfaces/slices/CourseSliceInterface';
import SlidePptxExerciseContent from '@components/Exercises/SlidePptx/interfaces/SlidePptxExerciseContent';
import SlidePptxExerciseInterface from '@components/Exercises/SlidePptx/interfaces/SlidePptxExerciseInterface';
import { SlidePptxExerciseActions } from '@actions/SlidePptxExerciseActions';
import { selectedGroupsOfParentInitialData } from '@redux/initialStates/courseInitialState';

const SlidePptxExerciseCourseReducers = {
  [SectionActions.COURSE_EXERCISE_SLIDE_PPTX_REQUEST_SUCCEEDED]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    let { content }: SlidePptxExerciseInterface = payload;

    let exercise: SlidePptxExerciseInterface = {
      ...state.loadedExercise.exercise,
      content,
      type: ExerciseTypes.slidePptx,
    };

    return {
      ...state,
      selectedGroupsOfParent: selectedGroupsOfParentInitialData,
      loadedExercise: {
        loaded: LoadingStage.loaded,
        exercise,
      },
    };
  },
  [SlidePptxExerciseActions.SET_PPTX]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<SlidePptxExerciseContent>,
  ): CourseSliceInterface => {
    const { pptxFile, pptxFileId } = payload;

    state.loadedExercise.exercise.content.pptxFileId = pptxFileId;
    state.loadedExercise.exercise.content.pptxUrl = pptxFile;
    state.loadedExercise.exercise.content.pptxUrlChanged = true;

    return state;
  },
};

export default SlidePptxExerciseCourseReducers;
