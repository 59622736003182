import { DBId } from '@common/types/DBId';
import { useIsEditAvailable } from '@features/content/courses';

import { ActivityDetailsTab, ActivitySearchResultDetailsType } from '../../types';
import { DetailsTabs, DetailsWrapper, LocationsContent, ReuseContentButton } from '../Common';
import { useAppSelector } from '@redux/store';
import { selectSearchPanel } from '@selectors/UiSelectors';

type ActivitySearchResultDetailsProps = {
  details: ActivitySearchResultDetailsType | null;
  defaultTab: ActivityDetailsTab['key'] | null;
  errorMessage: string;
  isExpanded: boolean;
  isLoaded: boolean;
  resultId: DBId;
  onCopyContent?: () => void;
  onUseContent?: () => void;
};

export const ActivityResultDetails = ({
  details,
  defaultTab,
  errorMessage,
  isExpanded,
  isLoaded,
  resultId,
  onCopyContent,
  onUseContent,
}: ActivitySearchResultDetailsProps) => {
  const isLoaderDisplayed = !isLoaded && !details;
  const { isEditAvailable } = useIsEditAvailable();
  const searchParams = useAppSelector(selectSearchPanel);

  const { forReusing, reuseInProgress } = searchParams;
  const showReuseContentButton = isEditAvailable && forReusing && onCopyContent && onUseContent;

  const tabs: ActivityDetailsTab[] = [
    {
      key: 'locations',
      title: 'Location(s)',
      Component: () => <LocationsContent locations={details?.mappings || []} />,
    },
  ];

  return (
    <DetailsWrapper isExpanded={isExpanded} errorMessage={errorMessage} isLoaderDisplayed={isLoaderDisplayed}>
      {showReuseContentButton && (
        <ReuseContentButton
          contentLabel="Activity"
          inProgress={reuseInProgress === resultId}
          onCopyContent={onCopyContent}
          onUseContent={onUseContent}
        />
      )}
      <DetailsTabs tabs={tabs} defaultTab={defaultTab} />
    </DetailsWrapper>
  );
};
