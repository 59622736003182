import { Modal } from 'react-bootstrap';
import successImage from '@components/ContentTypes/BaseContent/img/success.svg';
import failImage from '@components/ContentTypes/BaseContent/img/fail.svg';
import xIconImage from '@static/svg/x-icon.svg';
import { Badge, Box, Typography } from './components';
import './ResourceBundleModal.scss';
import { ResourceData, Resources } from './Resources';
import { useAppSelector } from '@redux/store';
import { selectResourceBundleData } from '@selectors/CoursesSelectors';

type ResourceBundleModalProps = {
  show: boolean;
  onHide: () => void;
  resources: ResourceData[];
  bundleName: string;
};
export const ResourceBundleModal = ({ show, onHide, resources, bundleName }: ResourceBundleModalProps) => {
  const currentResourceBundle = useAppSelector(selectResourceBundleData);

  return (
    <Modal show={show} onHide={onHide} centered dialogClassName="resource-bundle-modal">
      <Modal.Header>
        <Modal.Title>
          <Box alignItems="center">
            <Typography fontSize="20px" fontWeight="800">
              Entire Bundle
            </Typography>
            <Badge variant="primary">
              <Typography fontSize="10px">VIEW ONLY</Typography>
            </Badge>
          </Box>
          <Box alignItems="center">
            <img src={currentResourceBundle?.isVocabulary ? successImage : failImage} alt="Vocabulary Status" />
            <Typography fontSize="14px">
              {currentResourceBundle?.isVocabulary ? 'Added to Vocabulary Review' : 'Not added to Vocabulary Review'}
            </Typography>
          </Box>
        </Modal.Title>
        <img
          id="close-entire-bundle-modal-icon"
          className="resource-bundle-modal__close"
          src={xIconImage}
          alt="Close"
          onClick={onHide}
        />
      </Modal.Header>
      <Modal.Body>
        <Resources resources={resources} bundleName={bundleName} />
      </Modal.Body>
    </Modal>
  );
};
