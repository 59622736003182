import { ChapterSearchResultDetailsType, ChapterDetailsTab } from '../../types';
import { DetailsWrapper, DetailsTabs, LocationsContent } from '../Common';

type DetailsProps = {
  isLoaded: boolean;
  isExpanded: boolean;
  errorMessage: string;
  details: ChapterSearchResultDetailsType | null;
  defaultTab: ChapterDetailsTab['key'] | null;
};

export const ChapterResultDetails = ({ isLoaded, isExpanded, errorMessage, details, defaultTab }: DetailsProps) => {
  const isLoaderDisplayed = !isLoaded && !details;

  const tabs: ChapterDetailsTab[] = [
    {
      key: 'locations',
      title: 'Location(s)',
      Component: () => <LocationsContent locations={details?.mappings || []} />,
    },
  ];

  return (
    <DetailsWrapper isExpanded={isExpanded} errorMessage={errorMessage} isLoaderDisplayed={isLoaderDisplayed}>
      <DetailsTabs tabs={tabs} defaultTab={defaultTab} />
    </DetailsWrapper>
  );
};
