import styled, { css } from 'styled-components/macro';

import { SidePanel } from '@features/theme';

export const StyledSidePanel = styled(SidePanel)`
  min-width: 59rem;

  .modal-content {
    padding: 0 !important;
  }

  .modal-header {
    padding: 2.4rem 4rem;
    margin-bottom: 3.2rem;
  }
`;

export const PanelFooter = styled.div`
  ${({ theme }) => css`
    align-items: center;
    background: ${theme.colorV2.uiBackgroundPrimary};
    bottom: 0;
    box-shadow: 0 -0.1rem 0 ${theme.colorV2.uiBackgroundTertiary};
    display: flex;
    gap: 0.8rem;
    height: 8rem;
    justify-content: end;
    min-width: 59rem;
    padding-right: 4rem;
    position: fixed;
    width: 33vw;
  `}
`;

export const IconsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 0 8rem 5.6rem;
`;

export const IconContainer = styled.div<{ selected: boolean }>`
  ${({ selected, theme }) => css`
    align-items: center;
    border: ${selected ? `solid 0.4rem ${theme.colorV2.accentPrimary}` : 'solid 0.1rem rgba(0, 0, 0, 0.1)'};
    border-radius: 13rem;
    display: flex;
    font-size: 4rem;
    height: 8.8rem;
    justify-content: center;
    margin: 0 4.2rem 4.2rem 0;
    width: 8.8rem;

    :hover {
      background: ${theme.colorV2.uiBackgroundSecondary};
      cursor: pointer;
    }
  `}
`;

export const SelectedIconAndValidationErrorContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
