import styled from 'styled-components/macro';
import Tooltip from 'react-tooltip';

import { Button } from '@features/theme';
import { useIsPublishAvailable } from '@features/auth/roles';
import { HelpDisplayer } from '@features/help';
import { ExerciseInterface } from '@common/interfaces/contentTypes/ExerciseInterface';
import { ExerciseListItemType } from '@components/ContentTypes/ExerciseCard/types';
import { Checkbox } from '@features/theme';
import { BulkPublishingActionsCreator } from '@actionCreators/BulkPublishingActionsCreator';
import { useBulkPublishing } from '../_hooks';
import { useAppDispatch } from '@redux/store';

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;
  margin-top: 2.4rem;
  gap: 0.8rem;
  font-size: 1.6rem;
  height: 3.2rem;
`;

export const BulkPublishingButtons = ({
  itemsReadyForPublish,
  itemsReadyForUnpublish,
}: {
  itemsReadyForPublish: (ExerciseListItemType | ExerciseInterface)[];
  itemsReadyForUnpublish: (ExerciseListItemType | ExerciseInterface)[];
}) => {
  const { publish: isPublishAvailable, unpublish: isUnpublishAvailable } = useIsPublishAvailable();

  const { selectedForBulkPublishing, currentBulkPublishingState } = useBulkPublishing();

  const dispatch = useAppDispatch();

  const isSelectAllChecked =
    currentBulkPublishingState === 'publish'
      ? selectedForBulkPublishing.length === itemsReadyForPublish.length
      : selectedForBulkPublishing.length === itemsReadyForUnpublish.length;

  const handleSelectAll = () => {
    if (currentBulkPublishingState === 'publish') {
      dispatch(
        BulkPublishingActionsCreator.setSelectedChildren(
          selectedForBulkPublishing.length === itemsReadyForPublish.length
            ? []
            : itemsReadyForPublish.map((children) => children.id),
        ),
      );
    } else {
      dispatch(
        BulkPublishingActionsCreator.setSelectedChildren(
          selectedForBulkPublishing.length === itemsReadyForUnpublish.length
            ? []
            : itemsReadyForUnpublish.map((children) => children.id),
        ),
      );
    }
  };

  const unpublishTooltipText = !itemsReadyForUnpublish.length ? 'No published exercises to unpublish!' : '';
  const publishTooltipText = !itemsReadyForPublish.length ? 'No valid exercises to publish!' : '';

  if (!isPublishAvailable) return null;

  return (
    <ButtonsContainer>
      {currentBulkPublishingState ? (
        <Checkbox label="Select All" checked={isSelectAllChecked} onToggle={handleSelectAll} />
      ) : (
        <>
          {isPublishAvailable && (
            <div data-tip={publishTooltipText} data-for="multi-publish-tooltip">
              <Button
                disabled={!itemsReadyForPublish.length}
                variant="primary"
                size="S"
                onClick={() => dispatch(BulkPublishingActionsCreator.enableBulkPublishingState('publish'))}
              >
                Publish multiple
              </Button>

              <Tooltip id="multi-publish-tooltip" effect="solid" />
            </div>
          )}
          {isUnpublishAvailable && (
            <div data-tip={unpublishTooltipText} data-for="multi-unpublish-tooltip">
              <Button
                disabled={!itemsReadyForUnpublish.length}
                variant="secondary"
                size="S"
                onClick={() => dispatch(BulkPublishingActionsCreator.enableBulkPublishingState('unpublish'))}
              >
                Unpublish multiple
                <Tooltip id="multi-unpublish-tooltip" effect="solid" />
              </Button>
            </div>
          )}
          <HelpDisplayer id="how-to-bulk-publishing" type="how-to" />
        </>
      )}
    </ButtonsContainer>
  );
};
