import { Fragment } from 'react';
import { useParams } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components/macro';

import { ContentTypes, ContentTypesForCreateContentType, type ContentTypesType } from '@common/enums/ContentTypes';
import type { ExerciseType } from '@common/enums/ExerciseTypes';
import { LoadingStage } from '@common/enums/LoadingStage';
import { DBId } from '@common/types/DBId';
import { ContentCreator } from '@features/content/contentCreator';
import { Loader } from '@features/theme';
import { PublishingStatus } from '@components/Publishing/PublishingStatus';
import { ValidationErrorDisplayer } from '@components/ValidationErrorDisplayer';
import helpersService from '@services/HelpersService';

import { ExerciseCard } from '@components/ContentTypes/ExerciseCard/ExerciseCard';
import { PlacementTestActionCreators } from '@actionCreators/PlacementTestActionCreators';
import { ReactComponent as InfoIcon } from './info.svg';
import { LinkedChapterButton } from './LinkedChapter';
import { BulkPublishingWrapper } from '@features/content/exercises';
import { AppDispatch, useAppDispatch, useAppSelector } from '@redux/store';
import { selectPlacementTestEntrypoint } from '@selectors/PlacementTestSelectors';
import { ActivityType } from '@features/content/activities';

const ChildrenListsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ChildrenListTitleWrapper = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
`;

const ChildrenListTitle = styled.span`
  color: ${({ theme }) => theme.colorV2.placementTestEntryPointChildrenListTitleText};
  font-size: 1.4rem;
  font-weight: 700;
  margin-right: 1rem;
`;

const EntrypointTitle = styled.div`
  font-size: 3rem;
  font-weight: 700;
  padding: 0.8rem 0.2rem 5rem;
`;

const ExercisesInfoTooltip = styled(ReactTooltip)`
  font-size: 1.6rem !important;
  line-height: 1.5;
  max-width: fit-content;
  padding: 0.4rem 1.2rem !important;
  width: 38.6rem;
`;

const PublishingStatusWrapper = styled.div`
  display: flex;
`;

export const EntrypointData = () => {
  const dispatch = useAppDispatch();
  const { courseId, entrypointId } = useParams<{ courseId: DBId; entrypointId: DBId }>();

  const entrypoint = useAppSelector(selectPlacementTestEntrypoint);
  const { content, exercisesData } = entrypoint;

  const chaptersValidationErrors =
    content?.validationStatus?.errors.filter((error) => error.field === 'chapterId') || [];
  const exercisesValidationErrors =
    content?.validationStatus?.errors.filter((error) => error.field === 'exercises') || [];

  const exercises = exercisesData?.exercises || [];

  const renderContentCreator = (dispatch: AppDispatch, position: number, isPreenabled: boolean) => (
    <ContentCreator
      position={position}
      onCreateContent={(
        contentTypeToCreate: ContentTypesType,
        contentCategory: ActivityType | ExerciseType | ContentTypesForCreateContentType | null,
      ) => {
        dispatch(
          PlacementTestActionCreators.createExercise(courseId, entrypointId, contentCategory as ExerciseType, position),
        );
      }}
      acceptableButtons={{
        onlyGradable: true,
      }}
      isPreEnabled={isPreenabled}
    />
  );

  return (
    <>
      <PublishingStatusWrapper>
        <PublishingStatus
          ready={content ? content.ready : false}
          changeStatus={
            content
              ? content.changeStatus
              : {
                  hasNewChanges: false,
                  hasPendingChanges: false,
                }
          }
        />
        {content.midpoint && <LinkedChapterButton />}
      </PublishingStatusWrapper>
      <ValidationErrorDisplayer text={helpersService.getValidationErrorMessageText(chaptersValidationErrors)} />
      <EntrypointTitle>{`${content.title} - Entrypoint`}</EntrypointTitle>

      {exercisesData.loaded !== LoadingStage.loaded ? (
        <Loader size="L" />
      ) : (
        <ChildrenListsContainer>
          <ChildrenListTitleWrapper>
            <ChildrenListTitle>EXERCISES</ChildrenListTitle>
            <ExercisesInfoTooltip id="entrypoint-exercises-tooltip" effect="solid" place="right" />
            <InfoIcon
              data-for="entrypoint-exercises-tooltip"
              data-tip={'Only gradable exercises can be added to the test. Requires at least 3.'}
            />
          </ChildrenListTitleWrapper>
          {!exercises.length ? (
            renderContentCreator(dispatch, 0, true)
          ) : (
            <BulkPublishingWrapper items={exercises} parentId={entrypointId} parentType={ContentTypes.entrypoint}>
              <>
                {renderContentCreator(dispatch, 0, false)}
                {exercises.map((exercise, idx) => (
                  <Fragment key={exercise.id}>
                    <ExerciseCard content={exercise} idx={null} />
                    {renderContentCreator(dispatch, idx + 1, false)}
                  </Fragment>
                ))}
              </>
            </BulkPublishingWrapper>
          )}
          <ValidationErrorDisplayer text={helpersService.getValidationErrorMessageText(exercisesValidationErrors)} />
        </ChildrenListsContainer>
      )}
    </>
  );
};
