import { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useAppSelector } from '@redux/store';
import { selectGrammarReviewId } from '@selectors/GrammarSelectors';
import { selectPlacementTestId } from '@selectors/PlacementTestSelectors';

export const useComponentId = () => {
  const { path, params } = useRouteMatch();

  const [componentId, setComponentId] = useState<string | null>(null);

  const grammarReviewId = useAppSelector(selectGrammarReviewId);
  const placementTestId = useAppSelector(selectPlacementTestId);

  const getComponentId = (params: Record<string, string>) => {
    if (params.exerciseId) return params.exerciseId;

    if (path.includes('grammar-review')) {
      if (params.topicId) return params.topicId;
      if (params.categoryId) return params.categoryId;
      if (grammarReviewId) return grammarReviewId;
    } else if (path.includes('placement-test')) {
      if (params.entrypointId) return params.entrypointId;
      if (placementTestId) return placementTestId;
    } else {
      if (params.activityId) return params.activityId;
      if (params.lessonId) return params.lessonId;
      if (params.chapterId) return params.chapterId;
      if (params.levelId) return params.levelId;
      if (params.courseId) return params.courseId;
    }

    return null;
  };

  useEffect(() => {
    setComponentId(getComponentId(params));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, placementTestId, grammarReviewId]);

  return componentId;
};
