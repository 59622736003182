import { useAppDispatch } from '@redux/store';
import styled from 'styled-components';

import { Dropdown, DropdownInterface } from '@components/Dropdown';
import { useIsEditAvailable } from '@features/content/courses';
import { camelCaseSeparateWords } from '@helpers/camel-case-separator';
import { DBId } from '@common/types/DBId';
import type { ActivityType } from '@features/content/activities';
import { ContentTypesActionsCreator } from '@actionCreators/ContentTypesActionsCreator';

const ActivityDropdown = styled(Dropdown)`
  min-height: unset;
  margin-block: 0.8rem;

  .dropdown-toggle {
    font-weight: 700;
    font-size: 1.6rem;
    color: ${({ theme }) => theme.color.brandBlack} !important;
    padding: 0 2.6rem 0 0.4rem !important;
    background: ${({ theme }) => theme.color.brandGreyscale500} !important;
    border: none !important;
    border-radius: 0.4rem !important;
    margin: 0;
    min-height: 3.2rem !important;
  }
`;

const ACTIVITY_TYPES: DropdownInterface[] = [
  { name: 'Vocabulary', code: 'vocabulary' },
  { name: 'Memorise', code: 'memorise' },
  { name: 'Quiz', code: 'quiz' },
  { name: 'Discover', code: 'discover' },
  { name: 'Develop', code: 'develop' },
  { name: 'Practice', code: 'practice' },
  { name: 'Conversation', code: 'conversation' },
  { name: 'Pronunciation', code: 'pronunciation' },
  { name: 'Video', code: 'video' },
  { name: 'Reading', code: 'reading' },
  { name: 'Comprehension Skills', code: 'comprehensionSkills' },
  { name: 'Productive Skills', code: 'productiveSkills' },
];

export const ActivityTypeSelector = ({ activityType, id }: { activityType: string; id: DBId }) => {
  const dispatch = useAppDispatch();
  const { isEditAvailable } = useIsEditAvailable();

  return (
    <ActivityDropdown
      values={ACTIVITY_TYPES}
      value={activityType}
      title={camelCaseSeparateWords(activityType)}
      disabled={!isEditAvailable}
      onChange={({ code }) => {
        dispatch(ContentTypesActionsCreator.changeActivityType(id, code as ActivityType));
      }}
    />
  );
};
