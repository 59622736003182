import { useMemo } from 'react';

import { MultiValue, MultiValueGenericProps, OptionProps } from 'react-select';

import { CommonFilterOptionType, CommonFilterMultiSelector } from '@components/SelectorCommonComponents';
import { constants as contentConstants } from '@features/content';

import { handleOnChange, mapStateValueToFilterOptionValue } from './util';
import { FiltersState } from './types';
import { SelectorCustomOption } from './SelectorCustomOption';
import { SelectorCustomValueContainer } from './SelectorCustomValueContainer';

const { CEFR } = contentConstants;

export const CefrSelector = ({
  value,
  onFilter,
}: {
  value: FiltersState['cefr'];
  onFilter: (value?: FiltersState['cefr']) => void;
}) => {
  const currentValue = mapStateValueToFilterOptionValue(value) || null;
  const options = mapStateValueToFilterOptionValue(
    Object.keys(CEFR) as FiltersState['cefr'],
  ) as CommonFilterOptionType[];

  const title = useMemo(() => currentValue?.map(({ label }) => label).join('\n') ?? '', [currentValue]);

  return (
    <CommonFilterMultiSelector
      closeMenuOnSelect={false}
      optionContainer={(props: OptionProps<CommonFilterOptionType>) => <SelectorCustomOption {...props} />}
      options={options}
      placeholder="CEFR Level"
      rule="CEFR"
      value={currentValue}
      valueContainer={(props: MultiValueGenericProps<MultiValue<CommonFilterOptionType>>) => (
        <SelectorCustomValueContainer title={title} value={currentValue} {...props} />
      )}
      onChange={(selectedOption) => handleOnChange<'cefr'>(selectedOption, onFilter)}
    />
  );
};
