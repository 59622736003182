import { ExerciseService } from '@common/types/ExerciseService';
import ExerciseDataModelAssembler from '@components/Exercises/ExerciseDataModelAssembler';
import { ExerciseCommonActionCreators as ExerciseCommonActions } from '@actionCreators/ExerciseCommonActionCreator';
import { FormikValuesInterface } from '@helpers/formikInitialValuesHelper';
import FillGapExerciseInterface from '@components/Exercises/FillGap/interfaces/FillGapExerciseInterface';
import ExercisesService from '@services/ExercisesService';
import { AppDispatch } from '@redux/store';
import { clone } from '@helpers/clone';
import { editFillgapExercise } from '@services/exercises/editExerciseService';
import { EditFillgapExerciseRequest, DisplayedLanguageType } from '@services/exercises/editExerciseTypes';
import { addToast } from '@features/app/toast';

type FillGapExerciseServiceType = ExerciseService<FillGapExerciseInterface>;

const FillGapExerciseService: FillGapExerciseServiceType = {
  ensureExerciseFieldsAreReadyForUse(
    exerciseAndEmptyLocalizationBranchesPayload: any,
    exercise: FillGapExerciseInterface,
  ) {
    let output = clone(exerciseAndEmptyLocalizationBranchesPayload);

    return ExerciseDataModelAssembler.ensureFieldIsReadyForUse(
      exercise,
      output,
      ['instructions', 'mainBundle', 'feedback', 'distractor1', 'distractor2'],
      exerciseAndEmptyLocalizationBranchesPayload,
    );
  },
  async save(dispatch: AppDispatch, exercise: FillGapExerciseInterface, values: FormikValuesInterface) {
    try {
      let payload: EditFillgapExerciseRequest = {
        instructionsLanguage: exercise.content.instructionsLanguage as DisplayedLanguageType,
        mainImageEnabled: exercise.content.mainImageEnabled,
        mainVideoEnabled: exercise.content.mainVideoEnabled,
        mainAudioEnabled: exercise.content.mainAudioEnabled,
        recapExerciseId: exercise.content.recapExerciseId,
        experiment: exercise.content.experiment,
      };

      const updatePayload = await Promise.all([
        (values.instructionsChanged || exercise.content.instructions.changed) && !exercise.content.instructions.isReused
          ? ExercisesService.misc.saveField(dispatch, 'instructions', exercise, payload)
          : { instructions: exercise.content.instructions._id },
        (values.feedbackChanged || exercise.content.feedback?.changed || exercise.content.feedbackChanged) &&
        !exercise.content.feedback?.isReused
          ? ExercisesService.misc.saveField(dispatch, 'feedback', exercise, payload)
          : { feedback: exercise.content.feedback?._id || null },
        values.mainBundle_phraseChanged ||
        exercise.content?.mainBundle?.isVocabularyChanged ||
        exercise.content?.mainBundle?.image?.changed ||
        exercise.content?.mainBundle?.imageChanged ||
        exercise.content?.mainBundle?.phrase?.changed ||
        exercise.content?.mainBundle?.video?.changed ||
        exercise.content?.mainBundle?.videoChanged ||
        exercise.content?.mainBundle?.phrase?.isReused
          ? ExercisesService.misc.saveBundle(dispatch, 'mainBundle', exercise, payload, values)
          : null,
      ]);

      const distractorsPayload = await Promise.all([
        values.distractor1_phraseChanged ||
        exercise.content?.distractor1?.phrase?.changed ||
        exercise.content?.distractor1Changed ||
        exercise.content?.distractor1?.phrase?.isReused
          ? ExercisesService.misc.saveBundle(dispatch, 'distractor1', exercise, {}, values)
          : { distractor1: exercise.content?.distractor1?._id || null },
        values.distractor2_phraseChanged ||
        exercise.content?.distractor2?.phrase?.changed ||
        exercise.content?.distractor2Changed ||
        exercise.content?.distractor2?.phrase?.isReused
          ? ExercisesService.misc.saveBundle(dispatch, 'distractor2', exercise, {}, values)
          : { distractor2: exercise.content?.distractor2?._id || null },
      ]);

      payload = {
        ...payload,
        ...updatePayload.reduce((sum, item) => ({ ...sum, ...item }), {}),
        distractors: [distractorsPayload[0].distractor1, distractorsPayload[1].distractor2],
      };

      if (exercise.content.id) {
        await editFillgapExercise(exercise.content.id, payload);

        dispatch(ExerciseCommonActions.setSaveProgress({ value: false, updateData: true }));

        addToast({
          type: 'success',
          title: 'This exercise has been saved',
        });
      }
    } catch (e: any) {
      if (e.response?.status === 400) {
        addToast({
          type: 'error',
          title: `${e.response.data?.detail}`,
        });
      }

      throw new Error(e as string);
    }
  },
};

export default FillGapExerciseService;
