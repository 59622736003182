import { VocabularyReviewViewParams } from '../types';
import { FiltersState } from './VocabularyReviewFilters';

/**
 * Generates a unique key based on Vocabulary Review language, filters and pagination params to be used in a cache object
 * Ex. "language=en&offset=10&page=2&speechPart=adjective,noun&hasMedia=true&cefr=a1,a2"
 * @returns string
 */
export const generateCacheKey = (params: Record<keyof VocabularyReviewViewParams, any>) => {
  let _params = { ...params };

  // prune every filter with a falsy value
  Object.keys(_params.filters).forEach(
    (key) => !_params.filters[key as keyof FiltersState] && delete _params.filters[key as keyof FiltersState],
  );

  if (Object.keys(_params.filters).length) {
    // flatten filter values
    _params = {
      ..._params,
      ..._params.filters,
    };
  }

  delete _params.filters;

  return decodeURIComponent(new URLSearchParams(_params).toString());
};
