import { type DisplayContentToUserModeType } from '@common/enums/DisplayContentToUserMode';
import { ExerciseTypes } from '@common/enums/ExerciseTypes';
import { DBId } from '@common/types/DBId';
import { AnyExerciseInterface } from '@common/types/exercises/AnyExerciseInterface';
import { Language } from '@features/content/languages';
import { FormikValuesInterface } from '@helpers/formikInitialValuesHelper';
import { TrueFalseExerciseActions } from '@actions/TrueFalseExerciseActions';
import TrueFalseExerciseInterface from '@components/Exercises/TrueFalse/interfaces/TrueFalseExerciseInterface';

export const TrueFalseExerciseActionsCreator = {
  save: (exercise: TrueFalseExerciseInterface, learningLanguage: Language, values: FormikValuesInterface) => ({
    type: TrueFalseExerciseActions.SAVE,
    payload: {
      exercise,
      learningLanguage,
      values,
    },
  }),
  setImage: (exercise: AnyExerciseInterface, image: File, progressHandler: Function) => ({
    type: TrueFalseExerciseActions.UPLOAD_IMAGE,
    payload: {
      exercise,
      image,
      exerciseType: ExerciseTypes.trueFalse,
      progressHandler,
    },
  }),
  setStatementAs: (isTrue: boolean) => ({
    type: TrueFalseExerciseActions.SET_STATEMENT_AS,
    payload: {
      isTrue,
    },
  }),
  setInstructionsLanguage: (instructionsLanguage: DisplayContentToUserModeType) => ({
    type: TrueFalseExerciseActions.SET_INSTRUCTIONS_LANGUAGE,
    payload: {
      instructionsLanguage,
    },
  }),
  setStatementLanguage: (statementLanguage: DisplayContentToUserModeType) => ({
    type: TrueFalseExerciseActions.SET_STATEMENT_LANGUAGE,
    payload: {
      statementLanguage,
    },
  }),
  removeImage: () => ({
    type: TrueFalseExerciseActions.REMOVE_IMAGE,
  }),
  removeAudio: ({ contentId, language }: { contentId: DBId | undefined; language: string }) => ({
    type: TrueFalseExerciseActions.REMOVE_AUDIO,
    payload: { contentId, language },
  }),
  removeMainContent: () => ({
    type: TrueFalseExerciseActions.REMOVE_MAIN_CONTENT,
  }),
};
