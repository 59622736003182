const REMOVE_IMAGE = 'LISTEN_REPEAT_REMOVE_IMAGE';
const REMOVE_VIDEO = 'LISTEN_REPEAT_REMOVE_VIDEO';
const SAVE = 'LISTEN_REPEAT_EXERCISE_SAVE';
const SET_LISTEN_REPEAT_ALL_FIELDS = 'SET_LISTEN_REPEAT_ALL_FIELDS';
const SET_INSTRUCTIONS_LANGUAGE = 'LISTEN_REPEAT_SET_INSTRUCTIONS_LANGUAGE';
const UPLOAD_IMAGE = 'LISTEN_REPEAT_UPLOAD_IMAGE';
const UPLOAD_VIDEO_SUCCESS = 'LISTEN_REPEAT_UPLOAD_VIDEO_SUCCESS';

export const ListenRepeatExerciseActions = {
  REMOVE_IMAGE,
  REMOVE_VIDEO,
  SAVE,
  SET_INSTRUCTIONS_LANGUAGE,
  SET_LISTEN_REPEAT_ALL_FIELDS,
  UPLOAD_IMAGE,
  UPLOAD_VIDEO_SUCCESS,
} as const;
