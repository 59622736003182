const SAVE = 'PHRASEBUILDER_SAVE';
const SET_INSTRUCTIONS_LANGUAGE = 'PHRASEBUILDER_SET_INSTRUCTIONS_LANGUAGE';
const SET_PHRASE_BUILDER_ALL_FIELDS = 'SET_PHRASE_BUILDER_ALL_FIELDS';
const REMOVE_AUDIO = 'REMOVE_AUDIO';

export const PhraseBuilderExerciseActions = {
  SAVE,
  SET_INSTRUCTIONS_LANGUAGE,
  SET_PHRASE_BUILDER_ALL_FIELDS,
  REMOVE_AUDIO,
} as const;
