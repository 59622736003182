import { PayloadAction } from '@reduxjs/toolkit';
import produce from 'immer';

import { PlacementTestSliceType } from '@common/interfaces/slices/PlacementTestSliceType';
import {
  PlacementTestContentType,
  EntrypointContentType,
  EntrypointListItemType,
} from '@features/content/placementTest';
import { ExerciseListItemType } from '@components/ContentTypes/ExerciseCard/types';
import { ValidationInterface } from '@common/interfaces/validation/ValidationInterface';
import { ChangeStatusInterface } from '@common/interfaces/contentTypes/ChangeStatusInterface';
import { LoadingStage } from '@common/enums/LoadingStage';
import { DBId } from '@common/types/DBId';
import { PlacementTestActions } from '@actions/PlacementTestActions';
import { ContentTypes, type ContentTypesType } from '@common/enums/ContentTypes';
import {
  placementTestInitialContent,
  entrypointInitialContent,
  placementExerciseInitialContent,
} from '@redux/initialStates/placementTestInitialState';
import { PublishingActions } from '@actions/PublishingActions';
import { AnyExerciseContentInterface } from '@common/types/exercises/AnyExerciseContentInterface';
import { ContentOwnershipActions } from '@actions/ContentOwnershipActions';
import { SetContentOwnershipPayload } from '@features/content/contentOwnership';

export const PlacementTestReducers = {
  // PlacementTest
  [PlacementTestActions.PLACEMENT_TEST_LOADING]: (state: PlacementTestSliceType) => {
    return produce(state, (draft) => {
      draft.placementTest.loaded = LoadingStage.loading;
    });
  },
  [PlacementTestActions.PLACEMENT_TEST_LOADED]: (
    state: PlacementTestSliceType,
    { payload }: PayloadAction<PlacementTestContentType>,
  ) => {
    return produce(state, (draft) => {
      draft.placementTest.loaded = LoadingStage.loaded;
      draft.placementTest.content = { ...payload, type: ContentTypes.placementTest };
    });
  },
  [PlacementTestActions.ENTRYPOINTS_LOADING]: (state: PlacementTestSliceType) => {
    return produce(state, (draft) => {
      draft.placementTest.entrypointsData.loaded = LoadingStage.loading;
    });
  },
  [PlacementTestActions.SET_INITIAL_PLACEMENT_TEST]: (state: PlacementTestSliceType) => {
    return produce(state, (draft) => {
      draft.placementTest.loaded = LoadingStage.notLoaded;
      draft.placementTest.content = placementTestInitialContent;
      draft.placementTest.entrypointsData.loaded = LoadingStage.notLoaded;
      draft.placementTest.entrypointsData.entrypoints = [];
    });
  },
  [PlacementTestActions.ENTRYPOINTS_LOADED]: (
    state: PlacementTestSliceType,
    { payload }: PayloadAction<EntrypointListItemType[]>,
  ) => {
    return produce(state, (draft) => {
      draft.placementTest.entrypointsData.loaded = LoadingStage.loaded;
      draft.placementTest.entrypointsData.entrypoints = payload;
      draft.placementTest.entrypointsData.entrypoints = payload.map((entrypoint) => ({
        ...entrypoint,
        type: ContentTypes.entrypoint,
      }));
    });
  },
  [PlacementTestActions.SET_INITIAL_ENTRYPOINT]: (state: PlacementTestSliceType) => {
    return produce(state, (draft) => {
      draft.entrypoint.loaded = LoadingStage.notLoaded;
      draft.entrypoint.content = entrypointInitialContent;
      draft.entrypoint.exercisesData.loaded = LoadingStage.notLoaded;
      draft.entrypoint.exercisesData.exercises = [];
    });
  },
  // Navigation reducers
  [PlacementTestActions.UPDATE_NAVIGATION]: (state: PlacementTestSliceType, { payload }: PayloadAction<any>) => {
    return produce(state, (draft) => {
      draft.navigation.structure = payload;
    });
  },
  [PlacementTestActions.SET_LOADING_PARENT_ID]: (state: PlacementTestSliceType, { payload }: PayloadAction<DBId>) => {
    return produce(state, (draft) => {
      draft.navigation.loadingParentId = payload;
    });
  },

  // Content Ownership reducers
  [ContentOwnershipActions.SET_PLACEMENT_TEST_OWNER]: (
    state: PlacementTestSliceType,
    { payload: { owner } }: PayloadAction<SetContentOwnershipPayload>,
  ) => {
    return produce(state, (draft) => {
      draft.placementTest.content.owner = owner;
    });
  },
  [ContentOwnershipActions.SET_ENTRYPOINT_OWNER]: (
    state: PlacementTestSliceType,
    { payload: { owner } }: PayloadAction<SetContentOwnershipPayload>,
  ) => {
    return produce(state, (draft) => {
      draft.entrypoint.content.owner = owner;
    });
  },

  // Entrypoint
  [PlacementTestActions.ENTRYPOINT_LOADING]: (state: PlacementTestSliceType) => {
    return produce(state, (draft) => {
      draft.entrypoint.loaded = LoadingStage.loading;
    });
  },
  [PlacementTestActions.ENTRYPOINT_LOADED]: (
    state: PlacementTestSliceType,
    { payload }: PayloadAction<EntrypointContentType>,
  ) => {
    return produce(state, (draft) => {
      draft.entrypoint.loaded = LoadingStage.loaded;
      draft.entrypoint.content = { ...payload, type: ContentTypes.entrypoint };
    });
  },
  [PlacementTestActions.ENTRYPOINT_EXERCISES_LOADING]: (state: PlacementTestSliceType) => {
    return produce(state, (draft) => {
      draft.entrypoint.exercisesData.loaded = LoadingStage.loading;
    });
  },
  [PlacementTestActions.ENTRYPOINT_EXERCISES_LOADED]: (
    state: PlacementTestSliceType,
    { payload }: PayloadAction<ExerciseListItemType[]>,
  ) => {
    return produce(state, (draft) => {
      draft.entrypoint.exercisesData.loaded = LoadingStage.loaded;
      draft.entrypoint.exercisesData.exercises = payload ? payload : draft.entrypoint.exercisesData.exercises;
    });
  },
  [PlacementTestActions.SET_LINKED_CHAPTER]: (
    state: PlacementTestSliceType,
    { payload }: PayloadAction<{ id: DBId; title: string }>,
  ) =>
    produce(state, (draft) => {
      draft.entrypoint.content.chapter = payload;
    }),
  [PublishingActions.ON_MULTI_PUBLISH_SUCCESS]: (
    state: PlacementTestSliceType,
    { payload: { exerciseIds } }: PayloadAction<{ exerciseIds: string[] }>,
  ) =>
    produce(state, (draftState) => {
      draftState.entrypoint.exercisesData.exercises = draftState.entrypoint.exercisesData.exercises.map((exercise) =>
        exerciseIds.includes(exercise.id) ? { ...exercise, ready: true } : exercise,
      );
      draftState.navigation.structure = draftState.navigation.structure.map((exercise) =>
        exerciseIds.includes(exercise.id) ? { ...exercise, ready: true } : exercise,
      );
    }),
  [PublishingActions.ON_MULTI_UNPUBLISH_SUCCESS]: (
    state: PlacementTestSliceType,
    { payload: { exerciseIds } }: PayloadAction<{ exerciseIds: string[] }>,
  ) =>
    produce(state, (draftState) => {
      draftState.entrypoint.exercisesData.exercises = draftState.entrypoint.exercisesData.exercises.map((exercise) =>
        exerciseIds.includes(exercise.id) ? { ...exercise, ready: false } : exercise,
      );
      draftState.navigation.structure = draftState.navigation.structure.map((exercise) =>
        exerciseIds.includes(exercise.id) ? { ...exercise, ready: false } : exercise,
      );
    }),

  // Exercise
  [PlacementTestActions.SET_PLACEMENT_EXERCISE]: (
    state: PlacementTestSliceType,
    { payload }: PayloadAction<AnyExerciseContentInterface>,
  ) => {
    return produce(state, (draft) => {
      draft.placementExercise.loaded = LoadingStage.loaded;
      draft.placementExercise.content = payload.content;
      draft.navigation.structure = draft.navigation.structure.map((structureItem) =>
        structureItem.id === payload.content.id
          ? { ...structureItem, experiment: payload.content.experiment }
          : structureItem,
      );
    });
  },
  [PlacementTestActions.SET_INITIAL_PLACEMENT_EXERCISE]: (state: PlacementTestSliceType) => {
    return produce(state, (draft) => {
      draft.placementExercise.loaded = LoadingStage.notLoaded;
      draft.placementExercise.content = placementExerciseInitialContent;
    });
  },

  // Common
  [PlacementTestActions.SET_VALIDATION_RESULT]: (
    state: PlacementTestSliceType,
    {
      payload,
    }: PayloadAction<{
      type: ContentTypesType;
      data: { validationStatus: ValidationInterface; changeStatus: ChangeStatusInterface };
    }>,
  ) => {
    const { type: contentType, data: validationResult } = payload;

    switch (contentType) {
      case ContentTypes.entrypoint: {
        return produce(state, (draft) => {
          draft.entrypoint.content.validationStatus = validationResult.validationStatus;
          draft.entrypoint.content.changeStatus = validationResult.changeStatus;
        });
      }

      default: {
        console.log(`Unknown placement test type: ${contentType}`);
      }
    }
  },

  [PublishingActions.CHANGE_READY_STATE]: (
    state: PlacementTestSliceType,
    {
      payload: { contentType, id, forUpdate },
    }: PayloadAction<{ contentType: ContentTypesType; id: DBId; forUpdate: boolean }>,
  ) => {
    switch (contentType) {
      case ContentTypes.placementTest: {
        return produce(state, ({ placementTest }) => {
          placementTest.content.ready = forUpdate || !state.placementTest.content.ready;
          placementTest.content.changeStatus.hasNewChanges = state.placementTest.content.ready && !forUpdate;
          placementTest.content.changeStatus.hasPendingChanges = true;
        });
      }
      case ContentTypes.entrypoint: {
        return produce(state, ({ placementTest, entrypoint }) => {
          entrypoint.content.ready = forUpdate || !state.entrypoint.content.ready;
          entrypoint.content.changeStatus.hasNewChanges = state.entrypoint.content.ready && !forUpdate;
          entrypoint.content.changeStatus.hasPendingChanges = true;

          placementTest.entrypointsData.entrypoints = placementTest.entrypointsData.entrypoints.map((entrypoint) =>
            entrypoint.id === id
              ? {
                  ...entrypoint,
                  ready: forUpdate || !entrypoint.ready,
                  changeStatus: { hasNewChanges: entrypoint.ready && !forUpdate, hasPendingChanges: true },
                }
              : entrypoint,
          );
        });
      }
      case ContentTypes.exercise: {
        return produce(state, ({ placementExercise, entrypoint }) => {
          placementExercise.content.ready = forUpdate || !state.placementExercise.content.ready;
          placementExercise.content.changeStatus.hasNewChanges = state.placementExercise.content.ready && !forUpdate;
          placementExercise.content.changeStatus.hasPendingChanges = true;

          entrypoint.exercisesData.exercises = entrypoint.exercisesData.exercises.map((exercise) =>
            exercise.id === id
              ? {
                  ...exercise,
                  ready: forUpdate || !exercise.ready,
                  changeStatus: { hasNewChanges: exercise.ready && !forUpdate, hasPendingChanges: true },
                }
              : exercise,
          );
        });
      }
      default: {
        console.error('Placement Test: Unknown content type on publish');
        return state;
      }
    }
  },
};
