export interface Fetchable<T> {
  isFetching: boolean;
  isFetched: boolean;
  id: string | null;
  fetchError: string | null;
  value: T | null;
  isChanged: boolean;
  isUpdating: boolean;
}

const defaults: Partial<Fetchable<any>> = {
  isFetching: false,
  isFetched: false,
  id: null,
  fetchError: null,
  value: null,
  isChanged: false,
  isUpdating: false,
};

export const createFetchable = <T>(initial: Partial<Fetchable<T>> = {}) => {
  return Object.assign({}, defaults, initial) as Fetchable<T>;
};
