import 'draft-js/dist/Draft.css';
import { ChangeEvent } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import type { DisplayContentToUserModeType } from '@common/enums/DisplayContentToUserMode';
import { Sizes } from '@common/enums/Sizes';
import { LoadedDialogueExerciseInterface } from '@common/interfaces/exercises/LoadedExerciseInterface';
import { ValidationErrorDisplayer } from '@components/ValidationErrorDisplayer';
import helpersService from '@services/HelpersService';
import { ValidationErrorInterface } from '@common/interfaces/validation/ValidationInterface';
import { PreviewModal } from '@features/content/preview';
import { Title, TitleContainer } from '@components/Exercises/Common';
import { HelpDisplayer } from '@features/help';
import { constants as contentConstants } from '@features/content';
import { useIsEditAvailable } from '@features/content/courses';
import ContentToggler from '@components/Exercises/Common/ContentToggler/ContentToggler';
import { DialogueCharacter } from './DialogueCharactersPanel';
import { UserLanguageCheckbox } from '@components/Exercises/Common/UserLanguageCheckbox';
import TranslationsTipV2 from '@components/TranslationTipV2/TranslationTipV2';
import { WritableInputText } from '@components/WritableInputText';
import ExerciseProps from '@components/Exercises/ExerciseProps';
import { RecapSelector } from '@components/Exercises/Common/RecapSelector/RecapSelector';
import { AccessWarning } from '@components/Warning';
import { DialogueExerciseActionsCreator } from '@actionCreators/DialogueExerciseActionsCreator';
import DialogueScriptLine from './DialogueScriptLine';
import plusButtonIcon from './img/plus-button.svg';
import ReplaceCharacterIcon from './img/replaceCharacter.svg';
import DialogueExerciseInterface from './interfaces/DialogueExerciseInterface';
import { Character } from './types/Character';
import { ScriptLine } from './types/ScriptLine';
import { Language } from '@features/content/languages';
import { clone } from '@helpers/clone';
import { arrayMoveImmutable } from 'array-move';
import { CommonExerciseActionsCreator } from '@actionCreators/CommonExerciseActionsCreator';
import { Instructions, removeMediaProcessingValidationError } from '@features/content/exercises';
import { useAppDispatch, useAppSelector } from '@redux/store';
import {
  selectInterfaceLanguages,
  selectLearningLanguage,
  selectLoadedDialogueExerciseCharacters,
  selectLoadedDialogueExerciseScript,
  selectLoadedExerciseData,
} from '@selectors/CoursesSelectors';
import { selectTranslationsPanelVisible } from '@selectors/UiSelectors';

const { MEDIA_PROCESSING_VALIDATION_MESSAGE } = contentConstants;

const DialogueExercise = ({ exercise: exerciseParam }: ExerciseProps<LoadedDialogueExerciseInterface>) => {
  const dispatch = useAppDispatch();
  const { isEditAvailable } = useIsEditAvailable();
  const courseLearningLanguage = useAppSelector(selectLearningLanguage);
  const interfaceLanguages = useAppSelector(selectInterfaceLanguages);

  const loadedExercise = useAppSelector(selectLoadedExerciseData) as DialogueExerciseInterface;

  const translationsPanelVisible = useAppSelector(selectTranslationsPanelVisible);

  const characters = useAppSelector(selectLoadedDialogueExerciseCharacters) ?? [];
  const script: ScriptLine[] = useAppSelector(selectLoadedDialogueExerciseScript) ?? [];

  const availableLanguagesForAudioLocalizations = Array.from(new Set([...interfaceLanguages, courseLearningLanguage]));
  const availableAudioLocalizationsByScriptLine = script.map(({ line }) =>
    line.audioLocalizations.filter((audioLocalization) =>
      availableLanguagesForAudioLocalizations.includes(audioLocalization.language),
    ),
  );

  const { errors } = loadedExercise?.content?.validation;

  const instructionsErrors = errors.filter((error) => error.field === 'instructions');
  const descriptionErrors = errors.filter((error) => error.field === 'dialogueDescription');
  const charactersErrors = errors.filter((error) => error.field === 'characters');
  const scriptErrors = errors.filter((error) => error.field === 'script' || error.field === 'scriptContents');

  const scriptAudioErrors = errors.filter(
    (error) => /^scriptContents\[\d+\]$/.test(error.field) && error.message.toLowerCase().includes('audio'),
  );

  if (scriptAudioErrors.length) {
    let updatedErrors = [...errors];

    availableAudioLocalizationsByScriptLine.forEach((audioLocalizations, lineIndex) => {
      audioLocalizations.forEach(({ language, processed, value }) => {
        if (processed && value) {
          updatedErrors = removeMediaProcessingValidationError({
            errors,
            fieldName: `scriptContents[${lineIndex}]`,
            message: MEDIA_PROCESSING_VALIDATION_MESSAGE.AUDIO.replace('%LANG%', language),
          });
        }
      });
    });

    if (updatedErrors.length < errors.length) {
      dispatch(CommonExerciseActionsCreator.updateValidationErrors({ errors: updatedErrors }));
    }
  }

  let charactersDisplayerList = (newDialogueLineinsertionIndex: number) => {
    return (
      <ul className="exercise-dialogue__characters-displayer-list">
        {characters.map((character: Character, characterIndex: number) => (
          <li key={characterIndex} className="exercise-dialogue__characters-displayer-list-item">
            <DialogueCharacter
              imgURL={character.image}
              selectable={false}
              rollovable={false}
              showAsActive={true}
              showAddButton={true}
              size={Sizes.small}
              onClick={() => {
                dispatch(
                  DialogueExerciseActionsCreator.addScriptLine(characterIndex, newDialogueLineinsertionIndex + 1),
                );
              }}
            />
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div className="exercise-dialogue">
      <PreviewModal type={loadedExercise.type} content={loadedExercise.content} />
      <TitleContainer>
        <Title>Dialogue</Title>
        <HelpDisplayer type="guideline" id="guideline-dialogue-exercise" />
      </TitleContainer>
      {!isEditAvailable && <AccessWarning />}
      <div className="exercise-dialogue__wrapper">
        <div className="exercise-dialogue__container">
          <h1 className="exercise-dialogue__box-title">{'Instruction'}</h1>
          <ValidationErrorDisplayer text={helpersService.getValidationErrorMessageText(instructionsErrors)} />
        </div>
        <Instructions />
        <UserLanguageCheckbox
          checkedOverride={true}
          checked={false}
          disabled={true}
          checkedField={exerciseParam.exercise?.content.instructionsLanguage}
          onChange={(displayContentToUserMode: DisplayContentToUserModeType) => {
            dispatch(DialogueExerciseActionsCreator.setInstructionsLanguage(displayContentToUserMode));
          }}
        />
      </div>
      <div className="exercise-dialogue__wrapper">
        <ContentToggler
          text="Description"
          onSwitch={(switched: boolean) => {
            dispatch(DialogueExerciseActionsCreator.setDialogueDescriptionNeeded(switched));
          }}
          open={!!loadedExercise.content.dialogueDescription?._id}
          onRemove={() => {
            dispatch(DialogueExerciseActionsCreator.nullifyDescription());
          }}
          errors={descriptionErrors}
        >
          <TranslationsTipV2
            visitedBranch={'dialogueDescription'}
            errors={descriptionErrors}
            showErrorDisplayer={false}
          >
            <WritableInputText id="dialogue-description-input" />
          </TranslationsTipV2>

          <UserLanguageCheckbox
            checkedOverride={true}
            checked={false}
            disabled={true}
            checkedField={exerciseParam.exercise?.content.dialogueDescriptionLanguage}
            onChange={(displayContentToUserMode: DisplayContentToUserModeType) =>
              dispatch(DialogueExerciseActionsCreator.setDialogueDescriptionLanguage(displayContentToUserMode))
            }
          />
        </ContentToggler>
      </div>
      {characters.length === 0 && (
        <>
          <div className="exercise-dialogue__wrapper">
            <div className="exercise-dialogue__creator">
              <div className="exercise-dialogue__creator--dialogues-dont-exist">
                <h1 className="exercise-dialogue__creator__title">
                  Add up to {contentConstants.DIALOGUE_MAX_PARTICIPANTS} characters
                </h1>
                <img
                  src={plusButtonIcon}
                  alt="Create a new character"
                  className="exercise-dialogue__creator-button"
                  onClick={() => {
                    if (isEditAvailable) {
                      dispatch(DialogueExerciseActionsCreator.showDialogueCharactersSelector());
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <ValidationErrorDisplayer text={helpersService.getValidationErrorMessageText(charactersErrors)} />
        </>
      )}
      {characters.length > 0 && (
        <div className="exercise-dialogue__wrapper">
          <div className="exercise-dialogue__characters-displayer-intro">
            <h1 className="exercise-dialogue__box-title">Characters</h1>

            <ul className="exercise-dialogue__characters-displayer-list">
              {characters.map((character: Character, characterIndex: number) => (
                <li key={characterIndex} className="exercise-dialogue__characters-displayer-list-item">
                  <div className="exercise-dialogue__replace-character-wrapper">
                    <div
                      className="exercise-dialogue__replace-character-background"
                      onClick={() => {
                        if (isEditAvailable) {
                          dispatch(
                            DialogueExerciseActionsCreator.showDialogueCharactersSelectorToUpdatePhoto(
                              characterIndex,
                              character.image,
                            ),
                          );
                        }
                      }}
                    >
                      <img
                        src={ReplaceCharacterIcon}
                        alt="replace character"
                        className="exercise-dialogue__replace-character"
                        onClick={() => {
                          if (isEditAvailable) {
                            dispatch(
                              DialogueExerciseActionsCreator.showDialogueCharactersSelectorToUpdatePhoto(
                                characterIndex,
                                character.image,
                              ),
                            );
                          }
                        }}
                      />
                    </div>
                    <DialogueCharacter
                      imgURL={character.image}
                      selectable={true}
                      rollovable={true}
                      showAsActive={true}
                      size={Sizes.medium}
                    />
                  </div>

                  <textarea
                    className="exercise-dialogue__characters-displayer-list-item-name"
                    placeholder={contentConstants.DIALOGUE_CHARACTER_BASIC_PLACEHOLDER}
                    value={characters[characterIndex].name}
                    disabled={!isEditAvailable}
                    onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                      dispatch(DialogueExerciseActionsCreator.updateCharacterName(characterIndex, e.target.value));
                    }}
                  />
                </li>
              ))}
              {characters.length === 1 && (
                <li key={2} className="exercise-dialogue__characters-displayer-list-item">
                  <div className="exercise-dialogue__replace-character-wrapper">
                    <img
                      src={plusButtonIcon}
                      alt="Create a new character"
                      className="exercise-dialogue__creator-button"
                      onClick={() => {
                        if (isEditAvailable) {
                          dispatch(DialogueExerciseActionsCreator.showDialogueCharactersSelector());
                        }
                      }}
                    />
                  </div>
                  <div className="exercise-dialogue__characters-displayer-list-item-name" />
                </li>
              )}
            </ul>
          </div>
        </div>
      )}
      {characters.length > 0 && script.length === 0 && (
        <>
          <div className="exercise-dialogue__wrapper">
            <div className="exercise-dialogue__creator">
              <div className="exercise-dialogue__creator--dialogues-dont-exist">
                <h1 className="exercise-dialogue__creator__title">Create one line to start the dialogue</h1>
                {charactersDisplayerList(0)}
              </div>
            </div>
          </div>
          <ValidationErrorDisplayer text={helpersService.getValidationErrorMessageText(scriptErrors)} />
        </>
      )}
      {characters.length > 0 && (
        <div className="exercise-dialogue__wrapper">
          {script.length > 0 && (
            <div className="standalone-menu">
              <div
                className={`standalone-menu__inner
                standalone-menu__inner--${characters.length}`}
              >
                {isEditAvailable ? charactersDisplayerList(-1) : null}
              </div>
              <div className={`standalone-menu__line`} />
            </div>
          )}
          <DragDropContext
            onDragEnd={(result: any) => {
              if (!result.destination) {
                return;
              }

              const reorderedScript = arrayMoveImmutable(clone(script), result.source.index, result.destination.index);

              dispatch(DialogueExerciseActionsCreator.updateScriptLinesOrder(reorderedScript));
            }}
          >
            <Droppable droppableId="droppable-1">
              {(provided: any) => (
                <div ref={provided.innerRef} className="droppable-wrapper">
                  <ul className="exercise-dialogue__dialogue-lines">
                    {script
                      .filter((scriptLine) => scriptLine.line)
                      .map((scriptLine, dialogueLineIndex) => (
                        <DialogueScriptLine
                          key={dialogueLineIndex}
                          loadedExercise={loadedExercise}
                          scriptLine={scriptLine}
                          dialogueLineIndex={dialogueLineIndex}
                          characters={characters}
                          translationsPanelVisible={translationsPanelVisible}
                          courseLearningLanguage={courseLearningLanguage as Language}
                          charactersDisplayerList={charactersDisplayerList}
                          errors={errors.filter(
                            (error: ValidationErrorInterface) => error.field === `scriptContents[${dialogueLineIndex}]`,
                          )}
                        />
                      ))}

                    {provided.placeholder}
                  </ul>
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <ValidationErrorDisplayer text={helpersService.getValidationErrorMessageText(scriptErrors)} />
        </div>
      )}
      <RecapSelector exerciseId={loadedExercise?.content?.id} />
    </div>
  );
};

export default DialogueExercise;
