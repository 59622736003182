import styled from 'styled-components/macro';
import { Tab, Tabs } from 'react-bootstrap';
import { useState } from 'react';

import { SearchDetailTabType } from '../../../types';

const TabWrapper = styled.div`
  max-height: 25rem;
  overflow: auto;
`;

const StyledTabs = styled(Tabs)`
  margin-bottom: 0;
`;

export const DetailsTabs = ({
  tabs,
  defaultTab,
}: {
  tabs: SearchDetailTabType[];
  defaultTab: SearchDetailTabType['key'] | null;
}) => {
  const [selectedTab, setSelectedTab] = useState<SearchDetailTabType['key']>(defaultTab || tabs[0].key);

  return (
    <>
      <StyledTabs
        activeKey={selectedTab}
        onSelect={(selectedTabString: SearchDetailTabType['key']) => {
          if (selectedTabString) {
            setSelectedTab(selectedTabString);
          }
        }}
      >
        {tabs.map(({ key, title, Component }) => (
          <Tab eventKey={key} title={title} key={key}>
            <TabWrapper>
              <Component />
            </TabWrapper>
          </Tab>
        ))}
      </StyledTabs>
    </>
  );
};
