import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { OtherLocationsInterface } from '@common/interfaces/exercises/OtherLocationsInterface';
import { getLink } from '@helpers/urlLinkHelper';
import { ReactComponent as ArrowIcon } from './arrow.svg';
import grammarIcon from './grammar.svg';
import placementIcon from './placement.svg';
import { ContentTypes } from '@common/enums/ContentTypes';
import { Language } from '@features/content/languages';
import { Flag, FlagTextPositionMode } from '@components/Flag';

const OtherLocationsList = styled.div`
  display: flex;
  flex-direction: column;
`;

const OtherLocationsListHeader = styled.span`
  font-weight: 700;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colorV2.otherLocationsListHeaderText};
  padding-left: 0.8rem;
  margin-bottom: 1.6rem;
`;

const OtherLocationItem = styled(Link)`
  min-height: 5.6rem;
  padding: 0.8rem 1.6rem;
  color: ${({ theme }) => theme.colorV2.otherLocationsItemText};
  box-shadow: 0 0.4rem 1.2rem rgba(0, 0, 0, 0.08);
  border-radius: 0.8rem;
  margin-bottom: 0.8rem;
  display: grid;
  grid-column-gap: 1%;
  grid-template-columns: 20% 15% 15% 20% 10% 10% 4%;
  align-items: center;
  font-size: 1.6rem;
  &:hover {
    text-decoration: none;
    color: ${({ theme }) => theme.colorV2.otherLocationsItemText};
    background: ${({ theme }) => theme.colorV2.otherLocationsItemHoverBackground};
  }
`;

const LocationItem = styled.div`
  overflow-wrap: anywhere;
  display: flex;
  align-items: center;
`;

const ArrowImage = styled(ArrowIcon)`
  grid-column: 7;
`;

const FlagStyled = styled(Flag)`
  font-size: 1rem;
  margin-right: 2.4rem;
  img {
    width: 2.4rem;
    height: 2.4rem;
    margin: 0;
  }
`;

const NotCourseImage = styled.img`
  margin-right: 1rem;
`;

export const LocationsList = ({ locations }: { locations: (OtherLocationsInterface & { language: Language })[][] }) => (
  <OtherLocationsList>
    <OtherLocationsListHeader>Used in {locations.length} locations</OtherLocationsListHeader>
    {locations.map((location, index) => (
      <OtherLocationItem to={getLink(location)} data-testid={`location-item-${index}`} key={`location-item-${index}`}>
        {location.map((locationItem, locationIndex) => (
          <LocationItem key={`${location}-${locationIndex}-${index}`}>
            {locationItem.type === ContentTypes.course && (
              <FlagStyled countries={locationItem.language} mode={FlagTextPositionMode.withoutText} />
            )}
            {(locationItem.type === ContentTypes.grammarReview || locationItem.type === ContentTypes.placementTest) && (
              <NotCourseImage src={locationItem.type === ContentTypes.grammarReview ? grammarIcon : placementIcon} />
            )}
            {locationItem.label}
          </LocationItem>
        ))}
        <ArrowImage />
      </OtherLocationItem>
    ))}
  </OtherLocationsList>
);
