import { ChangeEvent, useEffect, useState } from 'react';

import { useIsEditAvailable } from '@features/content/courses';
import { InputComponent } from './InputComponent';
import { useAppSelector } from '@redux/store';
import { selectLearningLanguage } from '@selectors/CoursesSelectors';

export type WritableInputTextProps = {
  value?: string;
  placeholder?: string;
  className?: string;
  onChange?: (availableText: string, languageForTips?: string | undefined) => void;
  onChangeInstant?: Function;
  mode?: 'text' | 'area';
  bold?: boolean;
  readOnly?: boolean;
  errorsShown?: boolean;
  isChangeBlocked?: boolean;
  currentLanguage?: string;
  disabled?: boolean;
  fontSize?: string;
  withoutBorder?: boolean;
  withGreyColor?: boolean;
  id?: string;
};

export const WritableInputText = ({
  value,
  placeholder,
  className,
  onChange,
  onChangeInstant,
  mode = 'text',
  id = 'input',
  bold,
  readOnly = false,
  errorsShown = false,
  isChangeBlocked = false,
  currentLanguage = 'EN',
  disabled = false,
  fontSize = '18',
  withoutBorder = false,
  withGreyColor = false,
}: WritableInputTextProps) => {
  const [text, setText] = useState(value);
  const { isEditAvailable } = useIsEditAvailable();
  const courseLearningLanguage = useAppSelector(selectLearningLanguage);

  useEffect(() => {
    setText(value);
  }, [value]);

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (isEditAvailable && !disabled) {
      onChangeInstant && onChangeInstant();

      if (!isChangeBlocked) {
        setText(e.target.value);
        onChange && onChange(e.target.value || '');
      }
    }
  };

  return (
    <InputComponent
      mode={mode}
      id={id}
      value={text}
      placeholder={placeholder}
      onChange={onChangeHandler}
      readOnly={readOnly}
      withBoldFont={!!bold}
      withRedBorder={errorsShown}
      withBlueBackground={!isEditAvailable || disabled}
      withRightPadding={currentLanguage === 'AR' || courseLearningLanguage !== 'EN'}
      fontSize={fontSize}
      withGreyColor={withGreyColor}
      withoutBorder={withoutBorder}
      className={className}
    />
  );
};
