import { takeLatest } from 'redux-saga/effects';

import UsersSagas from '@sagas/users/definitions/UsersSagasDefinition';
import { usersSlice } from '@redux/slices/usersSlice';

export function* UsersRelatedSagas() {
  yield takeLatest(usersSlice.actions.getUsers.type, UsersSagas.getUsers);
  yield takeLatest(usersSlice.actions.editUserRole.type, UsersSagas.editUserRole);
  yield takeLatest(usersSlice.actions.archiveUser.type, UsersSagas.archiveUser);
}
