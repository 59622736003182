import { call, put, select } from 'redux-saga/effects';
import { GenericContentInterface } from '@common/interfaces/contentTypes/GenericContentInterface';
import { ReuseDataInterface } from '@common/interfaces/contentTypes/ReuseDataInterface';
import { AnyLoadedExercise } from '@common/types/exercises/AnyLoadedExercise';
import { ContentTypesActions } from '@actions/ContentTypesActions';
import { CourseInterface } from '@common/interfaces/courses/CourseInterface';
import TranslationsPanelService from '@services/TranslationsPanelService';
import { ContentTypes, ContentType } from '@common/enums/ContentTypes';
import { SearchActions } from '@actions/SearchActions';
import type { AppDispatch } from '@redux/store';
import { SearchService } from '@features/search';
import { PayloadAction } from '@reduxjs/toolkit';
import { saveContent } from '@components/CourseEdition/saveContent';
import { AnyExerciseContentInterface } from '@common/types/exercises/AnyExerciseContentInterface';
import { LocalizationInterface } from '@common/interfaces/localization/LocalizationInterface';
import {
  selectCourse,
  selectLearningLanguage,
  selectLoadedExercise,
  selectSelectedGroupsOfParentParentContents,
  selectStringReuseModal,
} from '@selectors/CoursesSelectors';
import { Language } from '@features/content/languages';

const TranslationsPanelSagas = {
  *save(dispatch: AppDispatch, { payload }: PayloadAction<any>) {
    try {
      const loadedExercise: AnyLoadedExercise = yield select(selectLoadedExercise);

      const learningLanguage: Language = yield select(selectLearningLanguage);

      yield saveContent(dispatch, loadedExercise, learningLanguage, payload.values);
    } catch (e) {
      console.error(e);
    }
  },
  *getBundleOtherLocations({ payload }: PayloadAction<any>) {
    try {
      const { id } = payload;
      const otherLocations: Awaited<ReturnType<typeof TranslationsPanelService.getOtherLocationsForBundle>> =
        yield call(TranslationsPanelService.getOtherLocationsForBundle, id);

      yield put({
        type: ContentTypesActions.SET_ERROR_BUNDLE_PLACES,
        payload: { otherLocations: otherLocations.data },
      });
    } catch (e: any) {
      console.error(e);
    }
  },
  *checkOtherLocations() {
    try {
      const loadedCourse: CourseInterface = yield select(selectCourse);
      const loadedContent: GenericContentInterface = yield select(selectSelectedGroupsOfParentParentContents);
      const loadedExercise: AnyLoadedExercise = yield select(selectLoadedExercise);
      const stringReuseModal: ReuseDataInterface = yield select(selectStringReuseModal);

      const {
        contentType: type,
        visitedBranch,
        stringReuseModalOpenedFor: contentId,
        bundleName,
        isBundleChangeBlocked,
      } = stringReuseModal;

      let contentToChange: any = loadedExercise?.exercise?.content;
      if (type === ContentTypes.course) {
        contentToChange = loadedCourse;
      } else if (type === ContentTypes.level || type === ContentTypes.lesson || type === ContentType.levelOrLesson) {
        contentToChange = loadedContent;
      }

      if (bundleName) {
        if (contentId && !contentToChange?.[bundleName][visitedBranch]?.isReusingConfirmed) {
          const getOtherLocations = isBundleChangeBlocked
            ? TranslationsPanelService.getOtherLocationsForBundle
            : TranslationsPanelService.getOtherLocations;
          const otherLocations: Awaited<ReturnType<typeof getOtherLocations>> = yield call(
            getOtherLocations,
            contentId,
          );

          const reusedData = {
            count: otherLocations.data.length,
            contentMappings: otherLocations.data,
          };

          yield put({
            type: ContentTypesActions.SET_REUSED_DATA,
            payload: { reusedData },
          });
        }
      } else {
        if (contentId && !contentToChange?.[visitedBranch]?.isReusingConfirmed) {
          const otherLocations: Awaited<ReturnType<typeof TranslationsPanelService.getOtherLocations>> = yield call(
            TranslationsPanelService.getOtherLocations,
            contentId,
          );
          const reusedData = {
            count: otherLocations.data.length,
            contentMappings: otherLocations.data,
          };

          yield put({
            type: ContentTypesActions.SET_REUSED_DATA,
            payload: { reusedData },
          });
        }
      }
    } catch (e: any) {
      console.error(e);
    }
  },
  *makeCopy({ payload }: PayloadAction<any>) {
    try {
      const loadedCourse: CourseInterface = yield select(selectCourse);
      const loadedContent: GenericContentInterface = yield select(selectSelectedGroupsOfParentParentContents);
      const loadedExercise: AnyLoadedExercise = yield select(selectLoadedExercise);

      const { contentType, visitedBranch, row, column, bundleName, isBundleChangeBlocked } = payload;
      let content: any = loadedCourse;

      if (contentType === ContentTypes.exercise) {
        content = loadedExercise.exercise.content;
      } else if (contentType === ContentType.levelOrLesson) {
        content = loadedContent;
      }

      let newString = bundleName ? content[bundleName][visitedBranch] : content[visitedBranch];
      if (visitedBranch === 'script') {
        newString = content[visitedBranch][row].line;
      }
      if (visitedBranch === 'examples') {
        newString = content[visitedBranch][row][column];
      }

      if (!isBundleChangeBlocked) {
        const cloneResult: Awaited<ReturnType<typeof SearchService.cloneContent>> = yield call(
          SearchService.cloneContent,
          newString._id,
        );

        if (cloneResult.status === 200) {
          const finalNewString = {
            ...newString,
            mappings: { count: 1 },
            _id: cloneResult.data.id,
            id: cloneResult.data.id,
          };
          yield put({
            type: SearchActions.USE_STRING_SUCCEEDED,
            payload: {
              newString: finalNewString,
              contentType,
              visitedBranch,
              row,
              column,
              bundleName,
              isBundleReused: isBundleChangeBlocked,
            },
          });
          yield put({
            type: ContentTypesActions.SET_CURRENT_CONTENT_ID,
            payload: {
              contentId: '',
              type: '',
              visitedBranch: '',
              row: 0,
              column: 0,
              bundleName: '',
              isStringChangeBlocked: false,
              isBundleChangeBlocked: false,
            },
          });
        }
      } else {
        let newPhraseId = '';
        if (content[bundleName].phrase?._id) {
          const clonePhraseResult: Awaited<ReturnType<typeof SearchService.cloneContent>> = yield call(
            SearchService.cloneContent,
            content[bundleName].phrase._id,
          );
          if (clonePhraseResult.status === 200) {
            newPhraseId = clonePhraseResult.data.id;
          }
        }

        let newExampleId = '';
        if (content[bundleName].example?._id) {
          const cloneExampleResult: Awaited<ReturnType<typeof SearchService.cloneContent>> = yield call(
            SearchService.cloneContent,
            content[bundleName].example._id,
          );
          if (cloneExampleResult.status === 200) {
            newExampleId = cloneExampleResult.data.id;
          }
        }

        yield put({
          type: SearchActions.USE_STRING_SUCCEEDED,
          payload: {
            newString,
            contentType,
            visitedBranch,
            row,
            column,
            bundleName,
            isBundleReused: isBundleChangeBlocked,
            newPhraseId,
            newExampleId,
          },
        });
        yield put({
          type: ContentTypesActions.SET_CURRENT_CONTENT_ID,
          payload: {
            contentId: '',
            type: '',
            visitedBranch: '',
            row: 0,
            column: 0,
            bundleName: '',
            isStringChangeBlocked: false,
            isBundleChangeBlocked: false,
          },
        });
      }
    } catch (e: any) {
      console.error(e);
    }
  },
  *removeString({ payload }: PayloadAction<any>) {
    try {
      const course: CourseInterface = yield select(selectCourse);
      const loadedExercise: AnyLoadedExercise = yield select(selectLoadedExercise);

      const content: GenericContentInterface = yield select(selectSelectedGroupsOfParentParentContents);

      const { contentType, visitedBranch, row, column, bundleName } = payload;

      let stringToRemove;

      switch (contentType) {
        case ContentTypes.course: {
          stringToRemove = (course as any)[visitedBranch];
          break;
        }
        case ContentTypes.exercise: {
          if (visitedBranch === 'script') {
            stringToRemove = (loadedExercise.exercise.content as AnyExerciseContentInterface)[visitedBranch][row || 0]
              .line;
          } else {
            if (bundleName) {
              stringToRemove = (loadedExercise.exercise.content as AnyExerciseContentInterface)[bundleName][
                visitedBranch
              ];
            } else {
              stringToRemove = (loadedExercise.exercise.content as AnyExerciseContentInterface)[visitedBranch];
            }
          }
          break;
        }
        case ContentType.levelOrLesson: {
          stringToRemove = (content as any)[visitedBranch];
          break;
        }
        default: {
          console.log('Incorrect type');
        }
      }
      const newTextLocaliz =
        stringToRemove.textLocalizations?.map((item: any) => ({
          language: item.language,
          value: '',
          phoneticValue: '',
          _id: '',
        })) || [];
      const newAudioLocaliz =
        stringToRemove.audioLocalizations?.map((item: LocalizationInterface) => ({ ...item, value: '', _id: '' })) ||
        [];
      const newImageLocaliz =
        stringToRemove.imageLocalizations?.map((item: LocalizationInterface) => ({ ...item, value: '', _id: '' })) ||
        [];
      const newVideoLocaliz =
        stringToRemove.videoLocalizations?.map((item: LocalizationInterface) => ({ ...item, value: '', _id: '' })) ||
        [];

      const newString = {
        _id: '',
        id: '',
        textLocalizations: newTextLocaliz,
        audioLocalizations: newAudioLocaliz,
        imageLocalizations: newImageLocaliz,
        videoLocalizations: newVideoLocaliz,
      };

      yield put({
        type: SearchActions.USE_STRING_SUCCEEDED,
        payload: { newString, contentType, visitedBranch, row, column, bundleName },
      });
    } catch (e: any) {
      console.error(e);
    }
  },
};

export default TranslationsPanelSagas;
