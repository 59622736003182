import { useState } from 'react';
import styled from 'styled-components/macro';
import CopyToClipboard from 'react-copy-to-clipboard';

import { Button } from '@features/theme';

import { AudioRequestStatusType } from '../types';
import { ReactComponent as ExportedRequestIcon } from './img/exportedRequestIcon.svg';
import { ReactComponent as NewRequestIcon } from './img/newRequestIcon.svg';
import { DBId } from '@common/types/DBId';
import { useToast } from '@features/app/toast';

const RequestAudio = styled(Button)`
  font-size: 1.4rem;
  min-width: 13.2rem;
  padding: 0.6rem 1.4rem;
`;

const baseRequestLabelStyles = `
  cursor: pointer;
  font-weight: 700;
  padding: 0 0.8rem;
  border-radius: 3.2rem;
  min-width: 13.2rem;
  line-height: 1;
  padding: 0.6rem 1.4rem;
`;

const ExportedRequestLabel = styled.div`
  ${baseRequestLabelStyles}

  background: ${({ theme }) => theme.colorV2.uiBackgroundPrimary};
  border: 0.2rem solid ${({ theme }) => theme.colorV2.utilityAlert};
  color: ${({ theme }) => theme.colorV2.utilityAlert};
  font-size: 1.6rem;

  svg path {
    fill: ${({ theme }) => theme.colorV2.utilityAlert};
  }
`;

const NewRequestLabel = styled.div`
  ${baseRequestLabelStyles}

  background: ${({ theme }) => theme.colorV2.uiBackgroundPrimary};
  border: 0.2rem solid ${({ theme }) => theme.colorV2.accentPremium};
  color: ${({ theme }) => theme.colorV2.accentPremium};
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 2rem;
  padding-bottom: 0.4rem;
  padding-top: 0.4rem;
  text-align: center;

  svg {
    margin-right: 0.6rem;

    path {
      fill: ${({ theme }) => theme.colorV2.accentPremium};
    }
  }
`;

const CancelButton = styled(Button)`
  ${baseRequestLabelStyles}

  font-size: 1.6rem;

  &:hover {
    border-color: ${({ theme }) => theme.colorV2.buttonTertiaryBaseBorder};
    color: ${({ theme }) => theme.colorV2.buttonTertiaryBaseText};
  }
`;

type RequestAudioButtonProps = {
  contentId: string;
  disabled: boolean;
  requestStatus?: { id: string; status: AudioRequestStatusType };
  onCancelAudioRequest: (audioRequestId: DBId) => void;
  onRequestAudio: () => void;
};

export const RequestAudioButton = ({
  contentId,
  disabled,
  requestStatus,
  onCancelAudioRequest,
  onRequestAudio,
}: RequestAudioButtonProps) => {
  const [showCancelAudioRequest, setShowCancelAudioRequest] = useState(false);

  const showToast = useToast();

  return (
    <>
      {!disabled && contentId && (
        <>
          {requestStatus ? (
            <>
              {requestStatus.status === 'new' && (
                <div
                  onMouseOver={() => setShowCancelAudioRequest(true)}
                  onMouseOut={() => setShowCancelAudioRequest(false)}
                >
                  {showCancelAudioRequest ? (
                    <CancelButton
                      variant="tertiary"
                      size="S"
                      onClick={(evt) => {
                        evt.preventDefault();
                        evt.stopPropagation();

                        onCancelAudioRequest(requestStatus.id);
                      }}
                    >
                      Cancel
                    </CancelButton>
                  ) : (
                    <NewRequestLabel>
                      <NewRequestIcon />
                      New
                    </NewRequestLabel>
                  )}
                </div>
              )}
              {requestStatus.status === 'exported' && (
                <CopyToClipboard text={requestStatus.id}>
                  <ExportedRequestLabel
                    title={`Request ID: ${requestStatus.id}\nStatus: Exported`}
                    onClick={(evt) => {
                      evt.preventDefault();
                      evt.stopPropagation();

                      showToast({
                        type: 'info',
                        title: 'Content ID copied to clipboard',
                      });
                    }}
                  >
                    <ExportedRequestIcon />
                    Exported
                  </ExportedRequestLabel>
                </CopyToClipboard>
              )}
            </>
          ) : (
            <RequestAudio
              size="S"
              variant="secondary"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                onRequestAudio();
              }}
            >
              Request audio
            </RequestAudio>
          )}
        </>
      )}
    </>
  );
};
