import { ValidationErrorInterface } from '@common/interfaces/validation/ValidationInterface';
import TranslationsTipV2 from '@components/TranslationTipV2/TranslationTipV2';
import { WritableInputText } from '@components/WritableInputText';
import { useAppSelector } from '@redux/store';
import { selectLoadedExerciseData } from '@selectors/CoursesSelectors';

const INSTRUCTIONS_BRANCH = 'instructions';

export const Instructions = () => {
  const loadedExercise = useAppSelector(selectLoadedExerciseData);

  const { errors } = loadedExercise?.content?.validation;
  const instructionsErrors = errors.filter((error: ValidationErrorInterface) => error.field === INSTRUCTIONS_BRANCH);

  return (
    <TranslationsTipV2 visitedBranch={INSTRUCTIONS_BRANCH} errors={instructionsErrors} showErrorDisplayer={false}>
      <WritableInputText id="exercise-instructions" />
    </TranslationsTipV2>
  );
};
