import { Modal } from 'react-bootstrap';

import { DBId } from '@common/types/DBId';
import { NavigationItemType } from '@features/content/navigation';
import { ModalContainer } from '../common';
import { PreviewModalForListContent } from './PreviewModalForListContent';
import { usePreviewState } from '../usePreviewState';

export const ListPreviewModal = ({
  courseId,
  isOpened,
  title,
  exercisesList,
}: {
  courseId: DBId;
  isOpened: boolean;
  title: string;
  exercisesList: NavigationItemType[];
}) => {
  const { deviceType, setDeviceType, answerState, setAnswerState, onModalClose } = usePreviewState();

  return (
    <ModalContainer show={isOpened} centered onHide={onModalClose}>
      <Modal.Body>
        {isOpened && (
          <PreviewModalForListContent
            onModalClose={onModalClose}
            deviceType={deviceType}
            setDeviceType={setDeviceType}
            answerState={answerState}
            setAnswerState={setAnswerState}
            courseId={courseId}
            exercisesNavigation={exercisesList}
            title={title}
          />
        )}
      </Modal.Body>
    </ModalContainer>
  );
};
