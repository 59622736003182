import styled from 'styled-components/macro';

import { defaultTheme } from '@features/theme';
import { AudioRequestStatusType } from '../../../types';

const COLOR_PER_STATUS: Record<AudioRequestStatusType, string> = {
  new: defaultTheme.colorV2.accentPrimary,
  exported: defaultTheme.colorV2.utilityAlert,
  completed: defaultTheme.colorV2.accentSecondary,
};

const STATUS_NAME_PER_STATUS: Record<AudioRequestStatusType, string> = {
  new: 'New',
  exported: 'Exported',
  completed: 'Completed',
};

const StatusIndicator = styled.div<{ status: AudioRequestStatusType }>`
  min-width: 0.9rem;
  height: 0.9rem;
  border-radius: 50%;
  margin-right: 1rem;
  background: ${({ status }) => COLOR_PER_STATUS[status]};
`;

export const StatusContainer = ({ status, exportId }: { status: AudioRequestStatusType; exportId: string }) => (
  <>
    <StatusIndicator status={status} />
    {STATUS_NAME_PER_STATUS[status]} {status === 'exported' && exportId ? `(${exportId})` : null}
  </>
);
