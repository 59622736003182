import type { Toast, InternalToast, ToastsChangedCallback } from './types';

export const toastStorage = {
  state: [] as InternalToast[],
  callbacks: [] as ToastsChangedCallback[],
  subscribe: (callback: ToastsChangedCallback) => {
    toastStorage.callbacks.push(callback);
  },
  unsubscribe: (callback: ToastsChangedCallback) => {
    toastStorage.callbacks = toastStorage.callbacks.filter((c) => c !== callback);
  },
  addToast: (toast: Toast) => {
    const internalToast = {
      ...toast,
      id: Date.now(),
      remove: () => {
        toastStorage.state = toastStorage.state.filter((t) => t !== internalToast);

        toastStorage.callbacks.forEach((callback) => callback(toastStorage.state));
      },
    };
    toastStorage.state = [...toastStorage.state, internalToast];

    toastStorage.callbacks.forEach((callback) => callback(toastStorage.state));
  },
};
