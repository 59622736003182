import styled, { css } from 'styled-components/macro';

export const HeaderWrapper = styled.header`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    justify-content: space-between;
    height: 5.5rem;
    padding: ${theme.space.XL} ${theme.space.XL} ${theme.space.XXL};
    width: 100%;
  `}
`;

export const BreadcrumbsWrapper = styled.div`
  display: flex;
  height: 3.8rem;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.space.XL};
`;

export const Subheader = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.space.L};
`;

export const Title = styled.h2`
  font-size: 3rem;
  font-weight: 700;
  margin: 0;
`;
