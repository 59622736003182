import { useHotkeys } from 'react-hotkeys-hook';
import styled from 'styled-components/macro';

import { Button } from '@features/theme';
import { constants } from '@features/content';

const { SHORTCUT_KEYS } = constants;

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

const Title = styled.span`
  color: ${({ theme }) => theme.color.brandBlack};
  font-weight: 800;
  font-size: 2rem;
  margin-bottom: 0.8rem;
`;

const Description = styled.span`
  color: ${({ theme }) => theme.color.brandGreyscale800};
  font-weight: 400;
  font-size: 1.6rem;
  margin-bottom: 3.2rem;
`;

const ButtonArea = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: center;
`;

type UnsavedChangesModalProps = {
  onContinue: () => void;
  close: () => void;
};

export const UnsavedChangesModal = ({ onContinue, close }: UnsavedChangesModalProps) => {
  useHotkeys(SHORTCUT_KEYS.CONTINUE, () => onContinue());
  useHotkeys(SHORTCUT_KEYS.KEEP_DRAFT, () => close());

  return (
    <ModalWrapper>
      <Title>You have unsaved changes</Title>
      <Description>Please cancel to go back and save these changes, or click continue to lose them.</Description>
      <ButtonArea>
        <Button variant="secondary" onClick={close}>
          Cancel
        </Button>
        <Button onClick={onContinue}>Continue</Button>
      </ButtonArea>
    </ModalWrapper>
  );
};
