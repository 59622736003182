import styled from 'styled-components/macro';

import { ContentTypes } from '@common/enums/ContentTypes';
import { ContentCreatorWidget, type ContentCreatorWidgetItemType } from '@features/theme';

import { ReactComponent as AttachIcon } from './_img/attachIcon.svg';
import searchIcon from './_img/search.svg';

const TitleContainer = styled.div`
  align-items: center;
  display: flex;
  gap: ${({ theme }) => theme.space.S};
`;

const ROLEPLAY_SCENARIO_CREATOR_ITEM: ContentCreatorWidgetItemType<typeof ContentTypes.roleplayScenario>[] = [
  {
    label: 'Roleplay Scenario',
    iconSrc: searchIcon,
    showByDefault: true,
    type: ContentTypes.roleplayScenario,
  },
];

type RoleplayScenarioBinderProps = {
  isBindInProgress?: boolean;
  isEditAvailable: boolean;
  isPreEnabled: boolean;
  onBind: (itemType: typeof ContentTypes.roleplayScenario, itemCategory: null) => void;
};

export const RoleplayScenarioBinder = ({
  isBindInProgress = false,
  isEditAvailable,
  isPreEnabled,
  onBind,
}: RoleplayScenarioBinderProps) => (
  <ContentCreatorWidget
    description="Adding a scenario is possible only once. To change the link it is necessary to recreate the lesson."
    isCreateInProgress={isBindInProgress}
    isEditAvailable={isEditAvailable}
    isPreEnabled={isPreEnabled}
    items={ROLEPLAY_SCENARIO_CREATOR_ITEM}
    position={0}
    title={
      <TitleContainer>
        <AttachIcon /> Attach a scenario
      </TitleContainer>
    }
    onItemSelected={(item) => onBind(item.type, null)}
  />
);
