import { capitalize } from 'lodash';
import styled from 'styled-components/macro';

import { CommonFilterSelector, CommonFilterOptionType } from '@components/SelectorCommonComponents';

const GENDER_TYPES = ['female', 'male'];
const DEFAULT_GENDER = 'male';

const StyledCommonFilterSelector = styled(CommonFilterSelector)`
  width: 100% !important;
`;

type GenderSelectorPropsType = {
  className?: string;
  value?: string;
  onChange: (selectedOption: CommonFilterOptionType | null) => void;
};

export const GenderSelector = ({ className, value = DEFAULT_GENDER, onChange }: GenderSelectorPropsType) => {
  const genderOptions: CommonFilterOptionType[] = GENDER_TYPES.map((item) => ({
    label: capitalize(item),
    value: item,
  }));

  return (
    <StyledCommonFilterSelector
      className={className}
      isClearable={false}
      options={genderOptions}
      placeholder=""
      value={genderOptions.find((option) => option.value === value) ?? null}
      onChange={onChange}
    />
  );
};
