import classNames from 'classnames';
import { ReactElement } from 'react';
import classes from './Table.module.scss';

type TableProps<T> = {
  bodyClassName?: string;
  className?: string;
  items: T[];
  renderItem: (item: T) => ReactElement;
  rowClassName?: string;
};
export function Table<T>({ bodyClassName, className, items, renderItem, rowClassName }: TableProps<T>) {
  return (
    <table className={classNames(classes.listContainer, className)}>
      <tbody className={bodyClassName}>
        {items.map((item, index) => (
          <tr key={index} className={classNames(classes.listItem, rowClassName)}>
            {renderItem(item)}
          </tr>
        ))}
      </tbody>
    </table>
  );
}
