import { CSSProperties } from 'react';
import styled from 'styled-components';

type TypographyProps = Pick<CSSProperties, 'fontSize' | 'fontWeight' | 'textTransform'>;

export const Typography = styled.span<TypographyProps>(({ fontSize, fontWeight, textTransform }) => ({
  fontSize,
  fontWeight,
  textTransform,
}));
