import styled from 'styled-components/macro';

import { SearchModalActionsCreator } from '@actionCreators/SearchModalActionsCreator';
import { Button } from '@features/theme';
import { useAppDispatch } from '@redux/store';
import { userTracking } from '@features/app/tracking';

import { ReactComponent as SearchImage } from './search.svg';

const SearchIcon = styled(SearchImage)`
  width: 2rem;
`;

export const SearchButton = () => {
  const dispatch = useAppDispatch();

  return (
    <Button
      variant="secondary"
      size="M"
      icon={<SearchIcon />}
      onClick={() => {
        dispatch(SearchModalActionsCreator.showSearchV2Panel());
        userTracking.logosSearchOpened({
          source: 'header',
        });
      }}
    >
      Search
    </Button>
  );
};
