import { DBId } from '@common/types/DBId';
import { apiClient } from '@features/api';

import { LevelSearchResultDetailsType } from '../types';

export const getLevelResultDetails = async (levelId: DBId): Promise<{ data: LevelSearchResultDetailsType }> => {
  const response = await apiClient.noErrorsV2.get(`/components/${levelId}/mappings`);

  return {
    data: {
      mappings: response.data,
    },
  };
};
