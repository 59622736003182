import { FunctionComponent, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useHotkeys } from 'react-hotkeys-hook';
import styled from 'styled-components';
import Creatable from 'react-select/creatable';
import { useToast } from '@features/app/toast';

const SelectorContainer = styled.div`
  padding: 0.8rem 0.8rem 1.6rem;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

type ContentLabelModalProps = {
  labels: string[] | undefined;
  onSave: (newLabels: string[]) => Promise<void>;
};

export const ContentLabelsModal: FunctionComponent<ContentLabelModalProps> = ({
  labels,
  onSave,
}: ContentLabelModalProps) => {
  const [open, setOpen] = useState(false);
  const [saving, setSaving] = useState(false);

  // option+control+l on mac
  useHotkeys('ctrl+alt+l', () => {
    setOpen((current) => !current);
  });

  const defaultOptions = (labels || []).map((label) => ({ value: label, label: label }));

  const [currentLabels, setCurrentLabels] = useState(labels || []);

  useEffect(() => {
    setCurrentLabels(labels || []);
  }, [labels]);

  const showToast = useToast();

  const onSaveClick = async () => {
    setSaving(true);

    try {
      await onSave(currentLabels);

      setOpen(false);
    } catch (e: any) {
      showToast({
        type: 'error',
        title: 'Error',
        description: 'Labels were not saved',
      });
    }

    setSaving(false);
  };

  return (
    <Modal show={open} centered>
      <Modal.Body>
        <SelectorContainer>
          <Creatable
            value={currentLabels.map((label) => ({ value: label, label: label }))}
            onChange={(newLabels) => {
              setCurrentLabels(newLabels.map((option) => option.value));
            }}
            isMulti
            options={defaultOptions}
          />
        </SelectorContainer>
        <ButtonsContainer>
          <Button onClick={onSaveClick} disabled={saving}>
            Save
          </Button>
          <Button onClick={() => setOpen(false)} disabled={saving} variant="light">
            Cancel
          </Button>
        </ButtonsContainer>
      </Modal.Body>
    </Modal>
  );
};
