import { useAppDispatch } from '@redux/store';
import classnames from 'classnames';
import { EditorState } from 'draft-js';
import { useFormikContext } from 'formik';
import { useState, useEffect } from 'react';
import { Editor } from 'react-draft-wysiwyg';

import { Language } from '@features/content/languages';
import BidimensionalEditorUtils from '@components/BidimensionalEditor/BidimensionalEditorUtils';
import { useIsEditAvailable } from '@features/content/courses';
import { FormikValuesInterface } from '@helpers/formikInitialValuesHelper';
import { brTag, removeTableTags, processSpacingForEditor } from '@helpers/htmlTagsHelper';

import { EditorContainerProps } from './BidimensionalEditorProps';
import { BidimensionalEditorModes } from './enums/BidimensionalEditorModes';
import removeIcon from './img/remove.svg';

import 'draft-js/dist/Draft.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const EditorContainer = ({
  bundleName,
  charactersLimit = 0,
  cells,
  columnIndex,
  columns,
  dataSourceModeForEditor,
  editorRef,
  highlighted = false,
  ignoreLanguageForTips,
  intervalId,
  isChangeBlocked = false,
  language,
  languageForTips,
  localizationId,
  mode,
  placeholder,
  rowIndex,
  rows,
  translationsPanelContent,
  selectedColumn,
  selectedRow,
  selectedToolbar,
  storeBranch,
  storeColumn,
  storeRow,
  toolbarOptions,
  toolbarCustomButtons,
  onChange,
  onChangeInstant,
  onEditorFocus,
  removeColumn,
  removeRow,
  selectColumn,
  selectRow,
  setCells,
  setDataSourceModeForEditor,
  setOneCellHasBeenFocused,
}: EditorContainerProps) => {
  const { values }: { values: FormikValuesInterface } = useFormikContext();
  const fieldName = bundleName ? `${bundleName}_${storeBranch}` : storeBranch;
  const dispatch = useAppDispatch();
  const { isEditAvailable } = useIsEditAvailable();
  let [editorState, setEditorState] = useState(() => {
    return BidimensionalEditorUtils.discoverEditorStateForThisCellV2(
      language as Language,
      languageForTips,
      ignoreLanguageForTips,
      mode,
      storeRow,
      storeColumn,
      translationsPanelContent,
      localizationId,
      rowIndex,
      columnIndex,
      (values as any)[fieldName],
      charactersLimit,
    );
  });

  const [hideEditor, setHideEditor] = useState(false);
  const [isLimitExceeded, setIsLimitExceeded] = useState(false);

  useEffect(() => {
    if (mode === BidimensionalEditorModes.multi) {
      let updatedState = BidimensionalEditorUtils.discoverEditorStateForThisCell(
        language as Language,
        languageForTips,
        ignoreLanguageForTips,
        mode,
        storeRow,
        storeColumn,
        translationsPanelContent,
        localizationId,
        rowIndex,
        columnIndex,
      );
      setEditorState(updatedState);
      setHideEditor(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns, rows, languageForTips]);

  useEffect(() => {
    if (isLimitExceeded) {
      setHideEditor(true);
      setEditorState(BidimensionalEditorUtils.updateTextAccordingLimit(editorState, charactersLimit));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLimitExceeded]);

  useEffect(() => {
    if (hideEditor) {
      setHideEditor(false);
    }
  }, [hideEditor]);

  const onChangeTextHandler = (state: EditorState) => {
    const reallyChanged =
      BidimensionalEditorUtils.stateToHTMLHelper(state) === brTag ||
      (dataSourceModeForEditor === 'defaultEditorState'
        ? BidimensionalEditorUtils.stateToHTMLHelper(state) !==
          BidimensionalEditorUtils.stateToHTMLHelper(editorStateProps.defaultEditorState)
        : BidimensionalEditorUtils.stateToHTMLHelper(state) !==
          BidimensionalEditorUtils.stateToHTMLHelper(editorState));

    if (reallyChanged) {
      onChangeInstant && onChangeInstant();

      if (!isChangeBlocked) {
        if (BidimensionalEditorUtils.stateToHTMLHelper(state).includes('&lt;!--td')) {
          const updatedText = removeTableTags(BidimensionalEditorUtils.stateToHTMLHelper(state));
          const updatedState = BidimensionalEditorUtils.stringToHTMLForBidimensionalEditor(updatedText);
          setEditorState(updatedState);
          setHideEditor(true);
        } else {
          setEditorState(state);
        }
        if (charactersLimit) {
          setIsLimitExceeded(BidimensionalEditorUtils.getContentLength(state) > charactersLimit);
        }

        if (!(values as any)[fieldName]) {
          BidimensionalEditorUtils.onEditorStateChange(
            dispatch,
            cells,
            ignoreLanguageForTips,
            language,
            languageForTips,
            mode,
            storeRow,
            storeColumn,
            translationsPanelContent,
            localizationId,
            storeBranch,
            setOneCellHasBeenFocused,
            setCells,
            state,
            rowIndex,
            columnIndex,
            bundleName,
          );
        }
        onChange && onChange(processSpacingForEditor(BidimensionalEditorUtils.stateToHTMLHelper(state)));
      } else {
        setHideEditor(true);
      }
    }
  };

  let editorStateProps: any = {
    defaultEditorState: editorState,
    editorState: dataSourceModeForEditor === 'editorState' && editorState,
  };

  // due to a bug in <Editor/> only editorState shows an update in the component when modifying the Editor value, but defaultEditorState allows you write properly.
  // In the editorState mode it writes backwards because of a bug! that's why we have to pass it to this component. Whenever it is necesary to write properly use defaultEditorState
  if (dataSourceModeForEditor === 'defaultEditorState') {
    delete editorStateProps.editorState;
  } else {
    delete editorStateProps.defaultEditorState;
  }

  const contentLength = BidimensionalEditorUtils.getContentLength(
    dataSourceModeForEditor === 'defaultEditorState' ? editorStateProps.defaultEditorState : editorState,
  );

  return (
    <>
      {charactersLimit > 0 && (
        <span className="bidimensional-editor__characters-counter">
          Characters:{' '}
          <span
            className={classnames({
              'bidimensional-editor__characters-counter--warning': contentLength > charactersLimit,
            })}
          >
            {contentLength}/{charactersLimit}
          </span>
        </span>
      )}
      <div className="bidimensional-editor__editor-row-selector-wrapper" key={`$row-selector-wrapper_${columnIndex}`}>
        {isEditAvailable && mode === BidimensionalEditorModes.multi && columnIndex === 0 && rows > 1 && (
          <div
            className={classnames(
              'bidimensional-editor__row-selector',
              { 'bidimensional-editor__row-selector--enabled': selectedRow === rowIndex },
              { 'bidimensional-editor__row-selector--cursor': rows > 1 },
              { 'bidimensional-editor__row-selector--first': rowIndex === 0 },
            )}
            onMouseOver={() => {
              if (rows > 1) {
                clearTimeout(intervalId);
                selectRow(rowIndex);
              }
            }}
            onMouseOut={() => {
              intervalId = setTimeout(() => {
                selectRow(null);
              }, 1000);
            }}
          >
            <img
              src={removeIcon}
              alt="remove row"
              onClick={() => {
                if (rows !== 1) {
                  removeRow(rowIndex);
                }
              }}
              className={classnames('bidimensional-editor__row-selector-remove-icon', {
                'bidimensional-editor__row-selector-remove-icon--enabled': selectedRow === rowIndex,
              })}
            />
          </div>
        )}
        <div
          className={classnames('bidimensional-editor__editor-column-selector-wrapper', {
            'bidimensional-editor__editor-column-selector-wrapper--bidimensional-enabled':
              mode === BidimensionalEditorModes.multi,
          })}
        >
          {isEditAvailable && mode === BidimensionalEditorModes.multi && rowIndex === 0 && columns > 1 && (
            <div
              className={classnames(
                'bidimensional-editor__column-selector',
                { 'bidimensional-editor__column-selector--enabled': selectedColumn === columnIndex },
                { 'bidimensional-editor__column-selector--cursor': columns > 1 },
              )}
              onMouseOver={() => {
                if (columns > 1) {
                  clearTimeout(intervalId);
                  selectColumn(columnIndex);
                }
              }}
              onMouseOut={() => {
                intervalId = setTimeout(() => {
                  selectColumn(null);
                }, 1000);
              }}
            >
              <img
                src={removeIcon}
                alt="remove column"
                onClick={() => {
                  if (columns !== 1) {
                    removeColumn(columnIndex);
                  }
                }}
                className={classnames('bidimensional-editor__column-selector-remove-icon', {
                  'bidimensional-editor__column-selector-remove-icon--enabled': selectedColumn === columnIndex,
                })}
              />
            </div>
          )}

          <div
            className={classnames('bidimensional-editor__editor-wrapper', {
              'bidimensional-editor__editor-wrapper--highlighted': highlighted,
            })}
          >
            {hideEditor ? null : (
              <Editor
                wrapperId={`editor-${storeBranch}`}
                placeholder={placeholder}
                customStyleMap={{
                  LIMIT_EXCEEDED: {
                    color: 'red',
                  },
                }}
                handlePastedText={(text) => null}
                ref={editorRef}
                wrapperClassName={`editor__wrapper editor-${columnIndex} key-${rowIndex}_${columnIndex}`}
                editorStyle={
                  mode === BidimensionalEditorModes.mono
                    ? charactersLimit > 0
                      ? { height: '7rem' }
                      : { height: '25.2rem' }
                    : { height: '4rem' }
                }
                key={`${rowIndex}_${columnIndex}`}
                {...editorStateProps}
                toolbarClassName={`editor__toolbar ${
                  selectedToolbar === `${rowIndex}_${columnIndex}` ? '' : 'editor__toolbar--hidden'
                }`}
                toolbarCustomButtons={toolbarCustomButtons}
                editorClassName={`editor ${
                  selectedColumn === columnIndex || selectedRow === rowIndex
                    ? 'bidimensional-editor__editor--selected'
                    : ''
                }`}
                toolbar={toolbarOptions}
                onEditorStateChange={(editorState: EditorState) => {
                  onChangeTextHandler(editorState);
                }}
                onFocus={() => onEditorFocus(rowIndex, columnIndex)}
                onBlur={() => setDataSourceModeForEditor(dataSourceModeForEditor)}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default EditorContainer;
