import styled, { css } from 'styled-components/macro';

export const ExerciseCommonBoxTitle = styled.h1`
  color: ${({ theme }) => theme.colorV2.textTertiary};
  display: inline-block;
  font-size: 1.4rem;
  margin-bottom: 0.8rem;
`;

export const ExerciseCommonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ExerciseCommonWrapper = styled.div`
  margin-bottom: 3.2rem;
`;

export const MultiOptionWrapper = styled.ul<{ hasErrors: boolean }>`
  ${({ hasErrors, theme }) => css`
    align-items: center;
    border: 0.2rem dashed ${hasErrors ? theme.colorV2.utilityError : theme.colorV2.uiDivider};
    border-radius: 1.6rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    min-height: 11.7rem;
    padding: 0;
    font-size: 1.4rem;
    height: 5rem;
    justify-content: center;
    padding: 0;
    position: relative;
    width: 100%;
  `}
`;

export const MultiOptionOption = styled.li`
  color: ${({ theme }) => theme.colorV2.accentPrimary};
  cursor: pointer;
  display: inline-flex;
  font-size: 1.4rem;
  transition: background 0.5s;
  align-items: center;
  display: flex;
  height: calc(100% - 1.6rem);
  justify-content: center;
  min-height: calc(11.7rem - 1.6rem);
  padding: 0 0.6rem;
  width: 50%;
`;

export const TitleContainer = styled.header`
  align-items: center;
  display: flex;
  margin-bottom: 3rem;
`;

export const Title = styled.h1`
  font-weight: 700;
  font-size: 1.6rem;
  margin-bottom: 0;
`;
