import { Button } from 'react-bootstrap';
import { useHotkeys } from 'react-hotkeys-hook';
import styled from 'styled-components/macro';

import { constants } from '@features/content';
import { Loader } from '@features/theme';
import { useRef } from 'react';
import { useAppSelector } from '@redux/store';
import { selectIsSaveButtonEnabled, selectIsSaveInProgress } from '@selectors/CoursesSelectors';

const StyledSaveButton = styled(Button)`
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  border-radius: 3.2rem;
  padding: 1.2rem 2.4rem;
  height: 4.8rem;
  border: 2px ${({ theme }) => theme.colorV2.headerSaveButtonBorder} solid !important;
  color: ${({ theme }) => theme.colorV2.headerSaveButtonText} !important;
`;

const { SHORTCUT_KEYS } = constants;

export const SaveButton = ({ onSave }: { onSave: () => void }) => {
  const isSaveButtonEnabled = useAppSelector(selectIsSaveButtonEnabled);
  const isSaveInProgress = useAppSelector(selectIsSaveInProgress);

  const buttonRef = useRef<HTMLButtonElement>();

  useHotkeys(
    SHORTCUT_KEYS.SAVE_CONTENT,
    () => {
      if (buttonRef.current && isSaveButtonEnabled) buttonRef.current.click();
    },
    [buttonRef, isSaveButtonEnabled],
  );

  return (
    <StyledSaveButton
      id="header-save-button"
      ref={buttonRef}
      variant="light"
      onClick={onSave}
      disabled={!isSaveButtonEnabled}
    >
      {isSaveInProgress ? <Loader /> : <>Save</>}
    </StyledSaveButton>
  );
};
