import { isEmpty } from 'lodash';

import { CourseStatsType, CourseStatsDTOType } from '../types';

const LEVEL_TYPES = ['a1', 'a2', 'b1', 'b2', 'c1'];

const hasValues = (dto: CourseStatsDTOType) => {
  const { activity, exercise, lesson, overview } = dto;

  return !isEmpty(activity) || !isEmpty(exercise) || !isEmpty(lesson) || !isEmpty(overview);
};

export const mapDataToTable = (data: CourseStatsType) => {
  const dto: CourseStatsDTOType = {
    activity: {},
    exercise: {},
    lesson: {},
    meta: {},
    overview: {},
  };

  const numTableCols = data.length;

  dto.meta.colHeaders = Array(numTableCols).fill('');

  data.forEach(({ cefr, stats, title }, col) => {
    stats.forEach(({ type, subtype, count }) => {
      // Breakdown by type/subtype
      if (subtype) {
        if (typeof dto[type as keyof typeof dto][subtype] === 'undefined') {
          dto[type as keyof typeof dto][subtype] = Array(numTableCols).fill(0);
        }
        dto[type as keyof typeof dto][subtype][col] = count;
      }

      // Overview
      if (typeof dto.overview[type] === 'undefined') {
        dto.overview[type] = Array(numTableCols).fill(0);
      }

      if (type === 'chapter') {
        dto.overview[type][col] = count;
      } else {
        (dto.overview[type][col] as number) += count;
      }
    });

    dto.meta.colHeaders[col] = cefr && LEVEL_TYPES.includes(cefr) ? cefr.toUpperCase() : title;
  });

  return hasValues(dto) ? dto : null;
};
