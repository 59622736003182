import styled, { css } from 'styled-components/macro';
import { Link } from 'react-router-dom';

import { type ContentTypesType } from '@common/enums/ContentTypes';
import { DBId } from '@common/types/DBId';
import { CardPublishingStatus } from '@components/ContentTypes/BaseContent/CardPublishingStatus';
import { Loader } from '@features/theme';
import { NavigationItemType } from './types';
import { getRealId } from '@helpers/reuseHelper';

import { ReactComponent as ContentActionsMenuIcon } from './_img/content-actions-menu-icon.svg';
import { ReactComponent as UnreadCommentsIcon } from './_img/unread-comments.svg';
import { ReactComponent as MoveIcon } from './_img/move.svg';
import { CONTENT_ACTIONS_MENU_TRIGGER_PREFIX } from './constants';

const ItemLabel = styled.div<{
  contentId: DBId;
  disabled: boolean;
  isActive: boolean;
  idForContentActionsMenu: DBId | undefined;
  isSelectable: boolean | undefined;
  isSelected: boolean | undefined;
}>`
  ${({ contentId, isActive, idForContentActionsMenu, theme }) => css`
    border-radius: 0.8rem;
    position: relative;
    transition: background-color ease 0.3s;
    background: ${isActive ? theme.colorV2.navigationItemSelectedBackground : 'inherit'};

    button {
      display: ${contentId === idForContentActionsMenu ? 'block' : 'none'};
    }
  `}

  ${({ disabled, isSelectable, theme }) =>
    !disabled
      ? css`
          &:hover {
            background: ${isSelectable ? 'inherit' : theme.colorV2.navigationItemHoverBackground};

            button {
              display: block !important;
            }

            ${StyledMoveIcon} {
              opacity: 1;
              visibility: visible;
            }
          }
        `
      : css`
          margin-left: 2.4rem;
          opacity: 0.5;

          ${ExpandButton} {
            display: none;
          }

          ${NavItem} {
            padding-left: 0.8rem;
            cursor: not-allowed !important;
          }
        `}

    ${({ isSelected }) =>
    isSelected &&
    css`
      ${StyledMoveIcon} {
        opacity: 1;
        visibility: visible;
      }
    `}
`;

const NavItem = styled.div<{ isSelectable: boolean | undefined; isSelected: boolean | undefined }>`
  ${({ isSelectable, isSelected, theme }) => css`
    align-items: center;
    display: flex;
    justify-content: space-between;
    height: 3.8rem;
    margin-bottom: 0.8rem;
    padding: 0.8rem 0.8rem 0.8rem ${isSelectable ? '0.8rem' : '3rem'};
    transition: border-color ease 0.3s;
    border: solid 0.2rem ${isSelected ? theme.colorV2.accentPrimary : 'transparent'};
    border-radius: ${isSelectable ? '0.8rem' : 'none'};

    &:hover {
      border-color: ${isSelectable ? theme.colorV2.accentPrimary : 'transparent'} !important;
      cursor: pointer;
    }
  `}
`;

const NavLink = styled(Link)<{ disabled: boolean; isActive: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.4rem;
  color: black;
  flex: 1;
  font-weight: 700;
  font-size: 1.4rem;
  max-width: 20rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
  ${({ disabled }) => disabled && 'pointer-events: none;'}

  &:hover {
    color: black;
    cursor: pointer;
    text-decoration: none;
  }

  ${({ isActive, theme }) =>
    isActive &&
    css`
      color: ${theme.colorV2.navigationItemText};

      &:hover {
        color: ${theme.colorV2.navigationItemText};
      }
    `}
`;

const ChildrenLoadingContainer = styled.div`
  position: absolute;
  top: 0.8rem;
  left: 0.5rem;
`;

const ExpandButton = styled.div<{ expanded: boolean; isActive: boolean; visible: boolean }>`
  position: relative;
  width: 2.2rem;
  transition: all ease 0.5s;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};

  &:before {
    transition: all ease 0.5s;
    ${({ isActive, theme }) => isActive && `color: ${theme.colorV2.navigationItemButtonExpandText};`}
    ${({ expanded }) => expanded && 'transform: rotate(90deg);'}
    font: 2.2rem/2.2rem DXIcons;
    content: '\f04e';
    position: absolute;
    top: 0.8rem;
    left: 0.5rem;
  }

  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.colorV2.navigationItemButtonExpandText};
  }
`;

const ContentActionsMenuTrigger = styled.button`
  background: transparent;
  border: none;
  height: 2.1rem;
  padding: 0;
  unset: all;
  width: 2.1rem;
`;

const StyledCardPublishingStatus = styled(CardPublishingStatus)`
  width: 1.6rem;
  height: 1.6rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  img {
    height: 1.3rem;
    width: 1rem;
  }

  .base-content__publishing-status__image {
    height: 1.2rem;
    width: 1.2rem;
  }
`;

const StyledUnreadCommentsIcon = styled(UnreadCommentsIcon)`
  ${NavItem}:hover & {
    display: none;
  }
`;

const StyledMoveIcon = styled(MoveIcon)`
  float: left;
  margin: 0.8rem 0;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in;

  path {
    fill: ${({ theme }) => theme.colorV2.accentPrimary};
  }
`;

type CommonNavigationItemPropsType = {
  content: NavigationItemType;
  currentPathIds: string[];
  disabled?: boolean;
  exerciseId: DBId;
  expanded: boolean;
  isSelectable?: boolean;
  isSelected?: boolean;
  loadingParentId: string;
  selectedContent?: NavigationItemType | null;
  title: string;
  url: string;
  handleExpand: (type: ContentTypesType, id: DBId) => void;
  onSelect?: (contentId: DBId) => void;
  onToggleContentActionsMenu?: (content: NavigationItemType | null) => void;
};

export const CommonNavigationItem = ({
  content,
  currentPathIds,
  disabled = false,
  expanded,
  isSelectable,
  isSelected,
  loadingParentId,
  selectedContent,
  title,
  url,
  handleExpand,
  onSelect,
  onToggleContentActionsMenu,
}: CommonNavigationItemPropsType) => {
  const isActive =
    currentPathIds.includes(getRealId(content.id)) &&
    (!content.parentId || currentPathIds.includes(getRealId(content.parentId)));

  return (
    <ItemLabel
      contentId={content.id}
      disabled={!!loadingParentId || disabled}
      idForContentActionsMenu={selectedContent?.id}
      isActive={isActive}
      isSelectable={isSelectable}
      isSelected={isSelected}
      onClick={() => isSelectable && onSelect && onSelect(content.id)}
      onMouseEnter={() => {
        // close potential ContentActionsMenu already displayed from other navigation item
        if (content.id !== selectedContent?.id && onToggleContentActionsMenu) {
          onToggleContentActionsMenu(null);
        }
      }}
    >
      {loadingParentId === content.id && content.children ? (
        <ChildrenLoadingContainer>
          <Loader size="S" />
        </ChildrenLoadingContainer>
      ) : (
        <>
          {isSelectable ? (
            <StyledMoveIcon />
          ) : (
            <ExpandButton
              expanded={expanded}
              isActive={isActive}
              visible={content.children && !disabled}
              onClick={(e) => {
                e.stopPropagation();
                content.children && !isSelectable && handleExpand(content.type as ContentTypesType, content.id);
              }}
            />
          )}
        </>
      )}

      <NavItem isSelectable={isSelectable} isSelected={isSelected}>
        <NavLink
          disabled={!!selectedContent || !!loadingParentId || disabled}
          isActive={isActive}
          title={title}
          to={url}
        >
          <StyledCardPublishingStatus hasPendingChanges={false} ready={content.ready} />
          {title}
        </NavLink>
        {(!selectedContent || content.id !== selectedContent.id) && content.unreadComments && (
          <StyledUnreadCommentsIcon />
        )}
        {onToggleContentActionsMenu ? (
          <ContentActionsMenuTrigger
            aria-haspopup
            id={`${CONTENT_ACTIONS_MENU_TRIGGER_PREFIX}-${content.id}`}
            onClick={() => onToggleContentActionsMenu(content)}
          >
            <ContentActionsMenuIcon />
          </ContentActionsMenuTrigger>
        ) : null}
      </NavItem>
    </ItemLabel>
  );
};
