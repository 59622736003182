import { flattenDeep, isEqual } from 'lodash';
import styled from 'styled-components/macro';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'draft-js/dist/Draft.css';

import { LocalizationInterface } from '@common/interfaces/localization/LocalizationInterface';
import { UserLanguageCheckbox } from '@components/Exercises/Common/UserLanguageCheckbox';
import { BidimensionalEditorModes } from '@components/BidimensionalEditor/enums/BidimensionalEditorModes';
import { ValidationErrorDisplayer } from '@components/ValidationErrorDisplayer';
import { LoadedTipExerciseInterface } from '@common/interfaces/exercises/LoadedExerciseInterface';
import { ValidationErrorInterface } from '@common/interfaces/validation/ValidationInterface';
import { useIsEditorHidden } from '@helpers/useHideEditor';
import BidimensionalEditor from '@components/BidimensionalEditor/BidimensionalEditor';
import { Title, TitleContainer } from '@components/Exercises/Common';
import { CommonExerciseActionsCreator } from '@actionCreators/CommonExerciseActionsCreator';
import { HelpDisplayer } from '@features/help';
import type { DisplayContentToUserModeType } from '@common/enums/DisplayContentToUserMode';
import TranslationsTipV2 from '@components/TranslationTipV2/TranslationTipV2';
import ContentToggler from '@components/Exercises/Common/ContentToggler/ContentToggler';
import { RecapSelector } from '@components/Exercises/Common/RecapSelector/RecapSelector';
import { PreviewModal } from '@features/content/preview';
import ExerciseProps from '@components/Exercises/ExerciseProps';
import { useIsEditAvailable } from '@features/content/courses';
import helpersService from '@services/HelpersService';
import { AccessWarning } from '@components/Warning';
import { ExerciseCommonActionCreators as ExerciseCommonActions } from '@actionCreators/ExerciseCommonActionCreator';
import { ExperimentSelector } from '@features/experiment';
import { courseSlice } from '@redux/slices/courseSlice';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { constants as contentConstants } from '@features/content';
import { Instructions, LockContent, removeMediaProcessingValidationError } from '@features/content/exercises';
import { TipExerciseActionsCreator } from '@actionCreators/TipExerciseActionsCreator';
import TipExerciseInterface from './interfaces/TipExerciseInterface';
import { selectIsIssuesShown } from '@selectors/UiSelectors';
import {
  selectInterfaceLanguages,
  selectLearningLanguage,
  selectLoadedExerciseData,
} from '@selectors/CoursesSelectors';
import { ReactComponent as ExclamationIcon } from '@components/ValidationErrorDisplayer/exclamation.svg';
import { DBId } from '@common/types/DBId';

const { DEFAULT_LANGUAGE, MEDIA_PROCESSING_VALIDATION_MESSAGE } = contentConstants;

const TipExamplesWrapper = styled.div`
  position: relative;
  margin-bottom: 3.2rem;
`;

const TipExamplesWarning = styled.div`
  align-items: center;
  display: flex;
  background-color: ${({ theme }) => theme.colorV2.utilityAlertBackground};
  border-radius: 0.8rem;
  font-size: 1.4rem;
  gap: 0.8rem;
  right: 0;
  padding: 0 0.8rem;
  position: absolute;
  top: 0;
  width: fit-content;

  svg {
    display: inline-block;
    vertical-align: text-bottom;

    path {
      fill: ${({ theme }) => theme.colorV2.utilityError};
    }
  }
`;

type ParagraphEditorProps = {
  localizationId?: DBId;
  paragraphErrors: ValidationErrorInterface[];
};

const ParagraphEditor = ({ localizationId, paragraphErrors }: ParagraphEditorProps) => (
  <TranslationsTipV2
    errors={paragraphErrors}
    showLanguageSwitcher={false}
    showErrorDisplayer={false}
    visitedBranch="paragraph"
  >
    <BidimensionalEditor
      dataSourceMode="defaultEditorState"
      ignoreLanguageForTips
      language={DEFAULT_LANGUAGE}
      localizationId={localizationId}
      maxColums={1}
      mode={BidimensionalEditorModes.mono}
      storeBranch="paragraph"
      toolbarCustomButtons={[<LockContent />]}
    />
  </TranslationsTipV2>
);

type ExamplesHighlightMapType = { highlighted: boolean }[][];

type TipExamplesProps = {
  exampleErrors: ValidationErrorInterface[];
  highlightedMatrix: ExamplesHighlightMapType;
  isIssuesShown: boolean;
  shouldShowWarning: boolean;
  onChange: () => void;
};

const TipExamples = ({
  exampleErrors,
  highlightedMatrix,
  isIssuesShown,
  shouldShowWarning,
  onChange,
}: TipExamplesProps) => {
  return (
    <>
      {!isIssuesShown && shouldShowWarning && (
        <TipExamplesWarning>
          <ExclamationIcon />
          Examples should contain all necessary translations
        </TipExamplesWarning>
      )}
      <div className="exercise-tip__box-table-wrapper">
        <BidimensionalEditor
          errorsShown={isIssuesShown && !!exampleErrors.length}
          dataSourceMode="defaultEditorState"
          highlightedMatrix={highlightedMatrix}
          ignoreLanguageForTips={false}
          maxColums={3}
          mode={BidimensionalEditorModes.multi}
          storeBranch="examples"
          toolbarCustomButtons={[<LockContent />]}
          onChange={onChange}
        />
      </div>
    </>
  );
};

const setExampleHighlightedValue = (
  interfaceLanguages: string[],
  textLocalizationsLanguages: string[],
  textLocalizationsTranslations: LocalizationInterface[],
) => {
  const _interfaceLanguages = [...interfaceLanguages].sort();

  // Check the languages are the same and then if there are tranlations for all of them
  return (
    !isEqual(_interfaceLanguages, textLocalizationsLanguages) ||
    interfaceLanguages.length > textLocalizationsTranslations.length
  );
};

const TipExercise = ({ exercise: exerciseParam }: ExerciseProps<LoadedTipExerciseInterface>) => {
  const dispatch = useAppDispatch();
  const { isEditorHidden, isSearchV2Opened, translationsPanelVisible } = useIsEditorHidden({
    visitedBranch: 'paragraph',
  });
  const isIssuesShown = useAppSelector(selectIsIssuesShown);
  const courseLearningLanguage = useAppSelector(selectLearningLanguage);
  const interfaceLanguages = useAppSelector(selectInterfaceLanguages);

  const loadedExercise = useAppSelector(selectLoadedExerciseData) as TipExerciseInterface;

  const paragraphAudioLocalizations = loadedExercise?.content?.paragraph?.audioLocalizations;

  const availableLanguagesForAudioLocalizations = Array.from(new Set([...interfaceLanguages, courseLearningLanguage]));
  const availableParagraphAudioLocalizations = paragraphAudioLocalizations.filter((audioLocalization) =>
    availableLanguagesForAudioLocalizations.includes(audioLocalization.language),
  );

  const { isEditAvailable } = useIsEditAvailable();
  const { errors } = loadedExercise?.content?.validation;

  const instructionsErrors = errors.filter((error: ValidationErrorInterface) => error.field === 'instructions');
  const paragraphErrors = errors.filter((error: ValidationErrorInterface) => error.field === 'paragraph');
  const exampleErrors = errors.filter(
    (error: ValidationErrorInterface) =>
      error.field === 'examples' || error.field === 'numberOfColumns' || error.field.includes('exampleValues'),
  );

  if (paragraphErrors.length) {
    let updatedErrors = [...errors];

    availableParagraphAudioLocalizations.forEach(({ language, processed, value }) => {
      if (processed && value) {
        updatedErrors = removeMediaProcessingValidationError({
          errors,
          message: MEDIA_PROCESSING_VALIDATION_MESSAGE.AUDIO.replace('%LANG%', language),
        });
      }
    });

    if (updatedErrors.length < errors.length) {
      dispatch(CommonExerciseActionsCreator.updateValidationErrors({ errors: updatedErrors }));
    }
  }

  const examples = loadedExercise?.content?.examples;
  const examplesHighlightMap: ExamplesHighlightMapType = [[], []];

  if (examples?.length) {
    for (let rowIndex = 0; rowIndex < examples.length; rowIndex++) {
      const row = examples[rowIndex];

      for (let columnIndex = 0; columnIndex < row.length; columnIndex++) {
        const { textLocalizations_groupedByLanguage = {} } = row[columnIndex];
        const textLocalizationsLanguages = Object.keys(textLocalizations_groupedByLanguage ?? []).sort();
        const textLocalizationsTranslations = Object.values(textLocalizations_groupedByLanguage ?? {}).filter(
          (textLocalizationData) => !!textLocalizationData.value.length,
        );
        const highlighted = setExampleHighlightedValue(
          interfaceLanguages,
          textLocalizationsLanguages,
          textLocalizationsTranslations,
        );

        if (examplesHighlightMap[rowIndex]) {
          if (examplesHighlightMap[rowIndex][columnIndex]) {
            examplesHighlightMap[rowIndex][columnIndex] = { highlighted };
          } else {
            examplesHighlightMap[rowIndex].push({ highlighted });
          }
        } else {
          examplesHighlightMap.push([{ highlighted }]);
        }
      }
    }
  }

  const shouldShowTipExamplesWarning = flattenDeep(examplesHighlightMap).some(({ highlighted }) => highlighted);

  const onChangeTipExamples = () => dispatch(TipExerciseActionsCreator.setExampleChanged());

  return (
    <div className="exercise-tip">
      <PreviewModal type={loadedExercise.type} content={loadedExercise.content} />
      <TitleContainer>
        <Title>Tip</Title>
        <HelpDisplayer type="guideline" id="guideline-tip-exercise" />
      </TitleContainer>
      <ExperimentSelector
        isEditAvailable={isEditAvailable}
        currentExperiment={loadedExercise.content.experiment}
        onExperimentChange={(experiment) => dispatch(ExerciseCommonActions.setExperimentValue(experiment))}
      />
      {!isEditAvailable && <AccessWarning />}
      <div className="exercise-tip__wrapper">
        <div className="exercise-tip__container">
          <h1 className="exercise-tip__box-title">{'Instruction'}</h1>
          <ValidationErrorDisplayer text={helpersService.getValidationErrorMessageText(instructionsErrors)} />
        </div>
        <Instructions />
        <UserLanguageCheckbox
          checkedField={exerciseParam.exercise?.content.instructionsLanguage}
          onChange={(displayContentToUserMode: DisplayContentToUserModeType) => {
            dispatch(TipExerciseActionsCreator.setInstructionsLanguage(displayContentToUserMode));
          }}
        />
      </div>
      <div className="exercise-tip__wrapper">
        <div className="exercise-tip__container">
          <h1 className="exercise-tip__box-title">Paragraph</h1>
          <ValidationErrorDisplayer text={helpersService.getValidationErrorMessageText(paragraphErrors)} />
        </div>

        <div className="exercise-tip__box-table-wrapper">
          {isEditorHidden ? (
            <div>
              <ParagraphEditor
                localizationId={
                  loadedExercise?.content.paragraph.textLocalizations.find((loc) => loc.language === 'EN')?._id
                }
                paragraphErrors={paragraphErrors}
              />
            </div>
          ) : (
            <ParagraphEditor
              localizationId={
                loadedExercise?.content.paragraph.textLocalizations.find((loc) => loc.language === 'EN')?._id
              }
              paragraphErrors={paragraphErrors}
            />
          )}
          <UserLanguageCheckbox
            checkedOverride={true}
            checked={false}
            disabled={true}
            checkedField={exerciseParam.exercise?.content.paragraphLanguage}
            onChange={(displayContentToUserMode: DisplayContentToUserModeType) => {
              dispatch(TipExerciseActionsCreator.setParagraphLanguage(displayContentToUserMode));
            }}
          />
        </div>
      </div>
      <TipExamplesWrapper>
        <ContentToggler
          text="Examples"
          open={!loadedExercise.content.examplesWasNullAtLoadTime}
          errors={exampleErrors}
          onRemove={() => {
            dispatch(TipExerciseActionsCreator.setExampleChanged(undefined, true));
          }}
          onSwitch={(open) => {
            if (open) {
              dispatch(courseSlice.actions.generateTipExerciseEmptyExamples());
            }
          }}
        >
          {isSearchV2Opened || translationsPanelVisible ? (
            <div>
              <TipExamples
                exampleErrors={exampleErrors}
                highlightedMatrix={examplesHighlightMap}
                isIssuesShown={isIssuesShown}
                shouldShowWarning={shouldShowTipExamplesWarning}
                onChange={onChangeTipExamples}
              />
            </div>
          ) : (
            <>
              <TipExamples
                exampleErrors={exampleErrors}
                highlightedMatrix={examplesHighlightMap}
                isIssuesShown={isIssuesShown}
                shouldShowWarning={shouldShowTipExamplesWarning}
                onChange={onChangeTipExamples}
              />
            </>
          )}
        </ContentToggler>
        <RecapSelector exerciseId={loadedExercise?.content?.id} />
      </TipExamplesWrapper>
    </div>
  );
};

export default TipExercise;
