import styled from 'styled-components/macro';
import { Button } from '@features/theme';

const ModalWrapper = styled.div`
  text-align: center;
`;

const Title = styled.div`
  color: ${({ theme }) => theme.colorV2.textPrimary};
  font-size: 2rem;
  font-weight: 700;
  line-height: 130%;
  margin-bottom: 0.8rem;
`;

const Description = styled.div`
  color: ${({ theme }) => theme.colorV2.textSecondary};
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 150%;
`;

const ButtonArea = styled.div`
  margin-top: 3.2rem;
`;

type CancelAudioRequestConfirmModalProps = {
  onConfirm: () => void;
  onHide: () => void;
};

export const CancelAudioRequestConfirmModal = ({ onConfirm, onHide }: CancelAudioRequestConfirmModalProps) => {
  return (
    <ModalWrapper>
      <Title>Are you sure to cancel this audio request?</Title>
      <Description>This action can not be undone</Description>
      <ButtonArea>
        <Button fullWidth onClick={onConfirm}>
          Yes, cancel this request
        </Button>
        <Button fullWidth variant="text" onClick={onHide}>
          No, go back
        </Button>
      </ButtonArea>
    </ModalWrapper>
  );
};
