import SlidePdfExerciseInterface from '@components/Exercises/SlidePdf/interfaces/SlidePdfExerciseInterface';
import { ExerciseCommonActionCreators as ExerciseCommonActions } from '@actionCreators/ExerciseCommonActionCreator';
import { AppDispatch } from '@redux/store';
import { editSlidePdfExercise } from '@services/exercises/editExerciseService';
import { EditSlidePdfExerciseRequest } from '@services/exercises/editExerciseTypes';
import { addToast } from '@features/app/toast';

const SlidePdfExerciseService = {
  async save(dispatch: AppDispatch, exercise: SlidePdfExerciseInterface) {
    try {
      const { pdfFileId } = exercise.content;

      let payload: EditSlidePdfExerciseRequest = {
        pdfFileId: pdfFileId || null,
      };

      if (exercise.content?.pdfUrlChanged) {
        if (exercise.content.id) {
          await editSlidePdfExercise(exercise.content.id, payload);

          dispatch(ExerciseCommonActions.setSaveProgress({ value: false, updateData: true }));

          addToast({
            type: 'success',
            title: 'This exercise has been saved',
          });
        }
      }
    } catch (e: any) {
      if (e.response?.status === 400) {
        addToast({
          type: 'error',
          title: `${e.response.data?.detail}`,
        });
      }

      throw new Error(e as string);
    }
  },
};

export default SlidePdfExerciseService;
