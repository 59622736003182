import styled from 'styled-components';

import { ReactComponent as NoIcon } from './img/no.svg';
import { ReactComponent as YesIcon } from './img/yes.svg';

const YesNoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;
`;

export const CardYesNoStatus = ({ status, label }: { status: boolean; label?: string }) => (
  <YesNoWrapper>
    {status ? (
      <>
        <YesIcon /> {label ? label : 'Yes'}
      </>
    ) : (
      <>
        <NoIcon /> {label ? label : 'No'}
      </>
    )}
  </YesNoWrapper>
);
