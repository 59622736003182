import {
  CommonEditExerciseRequest,
  EditComprehensionExerciseRequest,
  EditConversationExerciseRequest,
  EditDialogueExerciseRequest,
  EditFillgapExerciseRequest,
  EditFlashcardExerciseRequest,
  EditHighlighterExerciseRequest,
  EditListenRepeatExerciseRequest,
  EditMatchupExerciseRequest,
  EditMultipleChoiceExerciseRequest,
  EditPhraseBuilderExerciseRequest,
  EditSlidePptxExerciseRequest,
  EditSlidePdfExerciseRequest,
  EditSpeechRecognitionExerciseRequest,
  EditSpellingExerciseRequest,
  EditTipExerciseRequest,
  EditTrueFalseExerciseRequest,
  EditTypingExerciseRequest,
} from '@services/exercises/editExerciseTypes';
import { apiClient } from '@features/api';
import {
  EXERCISE_COMPREHENSION,
  EXERCISE_CONVERSATION,
  EXERCISE_DIALOGUE,
  EXERCISE_FILLGAP,
  EXERCISE_FLASHCARD,
  EXERCISE_HIGHLIGHTER,
  EXERCISE_LISTEN_REPEAT,
  EXERCISE_MATCHUP,
  EXERCISE_MULTIPLE_CHOICE,
  EXERCISE_PHRASE_BUILDER,
  EXERCISE_SLIDE_PPTX,
  EXERCISE_SLIDE_PDF,
  EXERCISE_SPEECH_RECOGNITION,
  EXERCISE_SPELLING,
  EXERCISE_TIP,
  EXERCISE_TRUE_FALSE,
  EXERCISE_TYPING,
  ExerciseType,
} from '@common/enums/ExerciseTypes';

const EXERCISE_TO_URL_TYPE_MAP: Record<ExerciseType, string> = {
  comprehension: 'comprehension',
  conversation: 'conversation',
  dialogue: 'dialogue',
  fillgap: 'fillgap',
  flashcard: 'flashcard',
  highlighter: 'highlighter',
  listenRepeat: 'listen-repeat',
  matchup: 'matchup',
  multipleChoice: 'multiple-choice',
  phraseBuilder: 'phrase-builder',
  slidePptx: 'pptx-slide',
  slidePdf: 'pdf-slide',
  speechRecognition: 'speech-recognition',
  spelling: 'spelling',
  tip: 'tip',
  trueFalse: 'true-false',
  typing: 'typing',
};

const editExercise = async (id: string, type: ExerciseType, editRequest: unknown) => {
  const exerciseUrlType = EXERCISE_TO_URL_TYPE_MAP[type];

  await apiClient.noErrorsV2.put(`content/exercises/${exerciseUrlType}/${id}`, editRequest, {
    params: {
      type,
    },
  });
};

export const editComprehensionExercise = (id: string, editRequest: EditComprehensionExerciseRequest): Promise<void> =>
  editExercise(id, EXERCISE_COMPREHENSION, editRequest);
export const editConversationExercise = (id: string, editRequest: EditConversationExerciseRequest): Promise<void> =>
  editExercise(id, EXERCISE_CONVERSATION, editRequest);
export const editDialogueExercise = (id: string, editRequest: EditDialogueExerciseRequest): Promise<void> =>
  editExercise(id, EXERCISE_DIALOGUE, editRequest);
export const editFillgapExercise = (id: string, editRequest: EditFillgapExerciseRequest): Promise<void> =>
  editExercise(id, EXERCISE_FILLGAP, editRequest);
export const editFlashcardExercise = (id: string, editRequest: EditFlashcardExerciseRequest): Promise<void> =>
  editExercise(id, EXERCISE_FLASHCARD, editRequest);
export const editHighlighterExercise = (id: string, editRequest: EditHighlighterExerciseRequest): Promise<void> =>
  editExercise(id, EXERCISE_HIGHLIGHTER, editRequest);
export const editListenRepeatExercise = (id: string, editRequest: EditListenRepeatExerciseRequest) =>
  editExercise(id, EXERCISE_LISTEN_REPEAT, editRequest);
export const editMatchupExercise = (id: string, editRequest: EditMatchupExerciseRequest): Promise<void> =>
  editExercise(id, EXERCISE_MATCHUP, editRequest);
export const editMultipleChoiceExercise = (id: string, editRequest: EditMultipleChoiceExerciseRequest): Promise<void> =>
  editExercise(id, EXERCISE_MULTIPLE_CHOICE, editRequest);
export const editPhraseBuilderExercise = (id: string, editRequest: EditPhraseBuilderExerciseRequest): Promise<void> =>
  editExercise(id, EXERCISE_PHRASE_BUILDER, editRequest);
export const editSlidePdfExercise = (id: string, editRequest: EditSlidePdfExerciseRequest): Promise<void> =>
  editExercise(id, EXERCISE_SLIDE_PDF, editRequest);
export const editSlidePptxExercise = (id: string, editRequest: EditSlidePptxExerciseRequest): Promise<void> =>
  editExercise(id, EXERCISE_SLIDE_PPTX, editRequest);
export const editSpeechRecognitionExercise = (
  id: string,
  editRequest: EditSpeechRecognitionExerciseRequest,
): Promise<void> => editExercise(id, EXERCISE_SPEECH_RECOGNITION, editRequest);
export const editSpellingExercise = (id: string, editRequest: EditSpellingExerciseRequest): Promise<void> =>
  editExercise(id, EXERCISE_SPELLING, editRequest);
export const editTipExercise = (id: string, editRequest: EditTipExerciseRequest): Promise<void> =>
  editExercise(id, EXERCISE_TIP, editRequest);
export const editTrueFalseExercise = (id: string, editRequest: EditTrueFalseExerciseRequest): Promise<void> =>
  editExercise(id, EXERCISE_TRUE_FALSE, editRequest);
export const editTypingExercise = (id: string, editRequest: EditTypingExerciseRequest): Promise<void> =>
  editExercise(id, EXERCISE_TYPING, editRequest);

export const exerciseEditors: Record<
  ExerciseType,
  (id: string, editRequest: CommonEditExerciseRequest) => Promise<void>
> = {
  comprehension: editComprehensionExercise,
  conversation: editConversationExercise,
  dialogue: editDialogueExercise,
  fillgap: editFillgapExercise,
  flashcard: editFlashcardExercise,
  highlighter: editHighlighterExercise,
  listenRepeat: editListenRepeatExercise,
  matchup: editMatchupExercise,
  multipleChoice: editMultipleChoiceExercise,
  phraseBuilder: editPhraseBuilderExercise,
  slidePptx: editSlidePptxExercise,
  slidePdf: editSlidePdfExercise,
  speechRecognition: editSpeechRecognitionExercise,
  spelling: editSpellingExercise,
  tip: editTipExercise,
  trueFalse: editTrueFalseExercise,
  typing: editTypingExercise,
};
