import { type DisplayContentToUserModeType } from '@common/enums/DisplayContentToUserMode';
import { DBId } from '@common/types/DBId';
import { AnyExerciseInterface } from '@common/types/exercises/AnyExerciseInterface';
import { Language } from '@features/content/languages';
import { FormikValuesInterface } from '@helpers/formikInitialValuesHelper';
import { PhraseBuilderExerciseActions } from '@actions/PhraseBuilderExerciseActions';

export const PhraseBuilderExerciseActionsCreator = {
  save: (exercise: AnyExerciseInterface, learningLanguage: Language, values: FormikValuesInterface) => ({
    type: PhraseBuilderExerciseActions.SAVE,
    payload: {
      exercise,
      learningLanguage,
      values,
    },
  }),
  setInstructionsLanguage: (instructionsLanguage: DisplayContentToUserModeType) => ({
    type: PhraseBuilderExerciseActions.SET_INSTRUCTIONS_LANGUAGE,
    payload: {
      instructionsLanguage,
    },
  }),
  removeAudio: ({ contentId, language }: { contentId: DBId | undefined; language: string }) => ({
    type: PhraseBuilderExerciseActions.REMOVE_AUDIO,
    payload: { contentId, language },
  }),
};
