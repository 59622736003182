import { ExerciseTypes, type ExerciseTypesType } from '@common/enums/ExerciseTypes';
import { DBId } from '@common/types/DBId';

export const EXERCISE_URL_POSTFIXES: { [key in ExerciseTypesType]: string } = {
  [ExerciseTypes.flashcard]: 'flashcard',
  [ExerciseTypes.matchup]: 'matchup',
  [ExerciseTypes.dialogue]: 'dialogue',
  [ExerciseTypes.multipleChoice]: 'multiple-choice',
  [ExerciseTypes.trueFalse]: 'true-false',
  [ExerciseTypes.conversation]: 'conversation',
  [ExerciseTypes.speechRecognition]: 'speech-recognition',
  [ExerciseTypes.phraseBuilder]: 'phrase-builder',
  [ExerciseTypes.highlighter]: 'highlighter',
  [ExerciseTypes.typing]: 'typing',
  [ExerciseTypes.fillgap]: 'fillgap',
  [ExerciseTypes.tip]: 'tip',
  [ExerciseTypes.comprehension]: 'comprehension',
  [ExerciseTypes.spelling]: 'spelling',
  [ExerciseTypes.slidePdf]: 'pdf-slide',
  [ExerciseTypes.slidePptx]: 'pptx-slide',
  [ExerciseTypes.listenRepeat]: 'listen-repeat',
};

const URLCreatorService = {
  exercises: {
    get(contentId: DBId, courseId?: DBId, parentId?: DBId) {
      return `/exercises/${contentId}?parentId=${parentId}&courseId=${courseId}`;
    },
  },
};

export default URLCreatorService;
