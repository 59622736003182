import { FC } from 'react';

import { BundleSearchResultItem } from './BundleSearchResultItem';
import { StringSearchResultItem } from './StringSearchResultItem';
import { LessonSearchResultItem } from './LessonSearchResultItem';
import { ChapterSearchResultItem } from './ChapterSearchResultItem';
import { LevelSearchResultItem } from './LevelSearchResultItem';
import { ExerciseSearchResultItem } from './ExerciseSearchResultItem';
import { ActivitySearchResultItem } from './ActivitySearchResultItem';
import { GrammarCategorySearchResultItem } from './GrammarCategorySearchResultItem';
import { GrammarTopicSearchResultItem } from './GrammarTopicSearchResultItem';
import {
  SearchResultType,
  ResultTypes,
  StringSearchResultType,
  BundleSearchResultType,
  LessonSearchResultType,
  ChapterSearchResultType,
  LevelSearchResultType,
  ExerciseSearchResultType,
  ActivitySearchResultType,
  GrammarCategorySearchResultType,
  GrammarTopicSearchResultType,
} from './types';

const resultItemComponent: { [key in ResultTypes]: FC<{ content: SearchResultType }> | null } = {
  content: ({ content }) => <StringSearchResultItem content={content as StringSearchResultType} />,
  resourceBundle: ({ content }) => <BundleSearchResultItem content={content as BundleSearchResultType} />,
  exercise: ({ content }) => <ExerciseSearchResultItem content={content as ExerciseSearchResultType} />,
  activity: ({ content }) => <ActivitySearchResultItem content={content as ActivitySearchResultType} />,
  lesson: ({ content }) => <LessonSearchResultItem content={content as LessonSearchResultType} />,
  chapter: ({ content }) => <ChapterSearchResultItem content={content as ChapterSearchResultType} />,
  level: ({ content }) => <LevelSearchResultItem content={content as LevelSearchResultType} />,
  grammarTopic: ({ content }) => <GrammarTopicSearchResultItem content={content as GrammarTopicSearchResultType} />,
  grammarCategory: ({ content }) => (
    <GrammarCategorySearchResultItem content={content as GrammarCategorySearchResultType} />
  ),
};

export const IdSearchResultItem = ({ content }: { content: SearchResultType }) => {
  const ResultItem = resultItemComponent[content.type];

  return ResultItem ? <ResultItem content={content} /> : null;
};
