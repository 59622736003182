import styled from 'styled-components';

import { HeaderWrapper } from './HeaderWrapper';

const StringHeaderWrapper = styled(HeaderWrapper)`
  grid-template-columns: 48% 12% 12% 25%;
`;

export const StringSearchResultTypeHeader = () => (
  <StringHeaderWrapper>
    <span>String</span>
    <span>Audio</span>
    <span>Translation</span>
    <span>Location</span>
  </StringHeaderWrapper>
);
