import { ContentTypes } from '@common/enums/ContentTypes';
import { ContentCreatorWidget, type ContentCreatorWidgetItemType } from '@features/theme';
import levelIcon from '@static/svg/contentCreatorWidget/level.svg';

const LEVEL_CREATOR_ITEM: ContentCreatorWidgetItemType<typeof ContentTypes.level> = {
  label: 'Level',
  iconSrc: levelIcon,
  showByDefault: true,
  type: ContentTypes.level,
};

type LevelCreatorProps = {
  isCreateInProgress?: boolean;
  isEditAvailable: boolean;
  isPreEnabled: boolean;
  position: number;
  onCreateContent: (itemType: typeof ContentTypes.level, itemCategory: null) => void;
};

export const LevelCreator = ({
  isCreateInProgress = false,
  isEditAvailable,
  isPreEnabled,
  position,
  onCreateContent,
}: LevelCreatorProps) => (
  <ContentCreatorWidget
    isCreateInProgress={isCreateInProgress}
    isPreEnabled={isPreEnabled}
    isEditAvailable={isEditAvailable}
    items={[LEVEL_CREATOR_ITEM]}
    position={position}
    title="Add a first level to get started"
    onItemSelected={(item) => onCreateContent(item.type, null)}
  />
);
