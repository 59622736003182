import { useState } from 'react';
import { ResourceBundleModal } from './ResourceBundleModal';
import { ResourceData } from './Resources';
import { courseSlice } from '@redux/slices/courseSlice';
import { useAppDispatch } from '@redux/store';
import { Button, Typography } from './components';
import EntireBundleIcon from './entireResourceBundleIcon.svg';

type EntireBundleButtonProps = {
  setCurrentBundle: Function;
  resources: ResourceData[];
  bundleName: string;
  id?: string;
};

export const EntireBundleButton = ({
  setCurrentBundle,
  resources,
  bundleName,
  id = 'show-bundle-modal-button',
}: EntireBundleButtonProps) => {
  const dispatch = useAppDispatch();
  const [isModalVisible, setModalVisible] = useState(false);

  return (
    <>
      <Button
        id={id}
        onClick={() => {
          setCurrentBundle();
          setModalVisible(true);
        }}
      >
        <img
          src={EntireBundleIcon}
          alt="Resource Bundle modal"
          className="resource-bundle-wrapper__footer__item__image"
        />
        <Typography fontSize="14px">Entire bundle</Typography>
      </Button>
      <ResourceBundleModal
        resources={resources}
        onHide={() => {
          dispatch(courseSlice.actions.nullifyResourceBundle());
          setModalVisible(false);
        }}
        show={isModalVisible}
        bundleName={bundleName}
      />
    </>
  );
};
