import { MouseEvent, ReactNode } from 'react';
import styled, { css } from 'styled-components';

export const TOOLBAR_BUTTON_SIZE = '2rem';

const toolbarButtonActiveStyle = css`
  border: 0.1rem solid ${({ theme }) => theme.colorV2.baseEditorToolbarButtonActiveBorder};
`;

const StyledToolbarButton = styled.button<{ active: boolean }>`
  background-color: inherit;
  border: none;
  border-radius: 0.4rem;
  font-size: 1.4rem;
  height: ${TOOLBAR_BUTTON_SIZE};
  line-height: ${TOOLBAR_BUTTON_SIZE};
  padding: 0;
  width: ${TOOLBAR_BUTTON_SIZE};

  ${({ active }) => active && toolbarButtonActiveStyle}

  &:hover {
    ${toolbarButtonActiveStyle}
  }
`;

type ToolbarButtonProps = {
  active: boolean;
  children: ReactNode;
  className?: string;
  onClick: () => void;
};

export const ToolbarButton = ({ active, children, className, onClick }: ToolbarButtonProps) => {
  const handleOnClick = (evt: MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault();
    evt.stopPropagation();
    onClick();
  };

  return (
    <StyledToolbarButton active={active} className={className} onClick={handleOnClick}>
      {children}
    </StyledToolbarButton>
  );
};
