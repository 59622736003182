import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select } from 'redux-saga/effects';

import { StructureItemInterface } from '@common/interfaces/navigation/StructureInterface';
import { ContentTypesActions } from '@actions/ContentTypesActions';
import ActivitiesService from '@components/ContentTypes/ActivityCard/ActivityCardService';
import { ActivityTypeChangePayload } from '@components/ContentTypes/ActivityCard/ActivityCardTypes';
import NavigationMenuSagas from '@sagas/navigationMenu/definitions/NavigationMenuSagasDefinition';
import { ContentTypes } from '@common/enums/ContentTypes';
import { selectCourseStructure } from '@selectors/CoursesSelectors';

const ActivitySagas = {
  *onChangeActivityType({ payload }: PayloadAction<ActivityTypeChangePayload>) {
    try {
      yield call(ActivitiesService.changeActivityType, payload);

      yield put({
        type: ContentTypesActions.ACTIVITY_CHANGE_TYPE_SUCCEEDED,
        payload,
      });

      const structure: StructureItemInterface[] = yield select(selectCourseStructure);
      const activity = structure.find(({ id }) => id === payload.id);

      yield call(NavigationMenuSagas.updateNavigation, activity?.parentId || '', ContentTypes.lesson);
    } catch (e) {
      console.error(e);
    }
  },
};

export default ActivitySagas;
