import { useEffect, useMemo, useState } from 'react';

import { PublishingActionsCreator } from '@actionCreators/PublishingActionCreator';
import { ContentTypes, type ContentTypesType } from '@common/enums/ContentTypes';
import { DBId } from '@common/types/DBId';
import { useIsPublishAvailable } from '@features/auth/roles';

import { GrammarCategoryListItemType, GrammarReviewContentType } from '../GrammarReview';
import { GrammarCategoryContentType, GrammarTopicListItemType } from '../GrammarCategory';
import { GrammarTopicContentType } from '../GrammarTopic';
import { ExerciseListItemType } from '@components/ContentTypes/ExerciseCard/types';
import { AnyExerciseContentInterface } from '@common/types/exercises/AnyExerciseContentInterface';
import { ExerciseTypes, type ExerciseTypesType } from '@common/enums/ExerciseTypes';
import { CommonPublishButton } from '@features/content/header';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { selectIsPublishingInProgress } from '@selectors/CoursesSelectors';

type PublishButtonProps = {
  contentToPublish:
    | GrammarReviewContentType
    | GrammarCategoryContentType
    | GrammarTopicContentType
    | AnyExerciseContentInterface;
  parentId: DBId | undefined;
  childrenArray: (GrammarCategoryListItemType | GrammarTopicListItemType | ExerciseListItemType)[];
};

export const PublishButton = ({ contentToPublish, parentId, childrenArray }: PublishButtonProps) => {
  const dispatch = useAppDispatch();
  const [tooltipText, setTooltipText] = useState('');

  const isPublishingInProgress = useAppSelector(selectIsPublishingInProgress);
  const { publish: isPublishAvailable, unpublish: isUnpublishAvailable } = useIsPublishAvailable();

  const isContentInvalid = useMemo(() => {
    if (Object.values(ExerciseTypes).includes(contentToPublish.type as any)) {
      return !(contentToPublish as AnyExerciseContentInterface)?.validation?.valid;
    } else {
      return !contentToPublish?.validationStatus?.valid;
    }
  }, [contentToPublish]);

  const getTypeName = (type: ContentTypesType | ExerciseTypesType) => {
    if (type === ContentTypes.grammarReview) {
      return 'grammar review';
    } else if (type === ContentTypes.grammarCategory) {
      return 'grammar category';
    } else if (type === ContentTypes.grammarTopic) {
      return 'grammar topic';
    } else if (Object.values(ExerciseTypes).includes(type as any) || type === ContentTypes.exercise) {
      return 'exercise';
    } else {
      return 'unkown type';
    }
  };

  const isButtonDisabled = () => {
    if (contentToPublish.ready) {
      return false;
    }

    if (
      !Object.values(ExerciseTypes).includes(contentToPublish.type as any) &&
      !childrenArray.filter((group) => group.ready).length
    ) {
      return true;
    }
    return isContentInvalid;
  };

  useEffect(() => {
    const getChildName = (type: ContentTypesType | ExerciseTypesType) => {
      switch (type) {
        case ContentTypes.roleplay: {
          return ContentTypes.roleplayCategory;
        }
        case ContentTypes.roleplayCategory: {
          return ContentTypes.roleplayScenario;
        }
        default:
          return ContentTypes.roleplayCategory;
      }
    };

    if (contentToPublish?.ready) {
      setTooltipText('');
    } else {
      if (
        !Object.values(ExerciseTypes).includes(contentToPublish.type as any) &&
        !childrenArray.filter((group) => group.ready).length
      ) {
        setTooltipText(
          `To publish this ${getTypeName(contentToPublish.type)}, it must contain at least 1 published ${getTypeName(
            getChildName(contentToPublish.type),
          )}.`,
        );
      } else {
        setTooltipText('');
      }
    }
  }, [contentToPublish, childrenArray]);

  const handleButtonClick = (forUpdate: boolean) => {
    dispatch(
      PublishingActionsCreator.setContentToPublish(
        contentToPublish.id || '',
        contentToPublish.type,
        contentToPublish.ready,
        forUpdate,
        parentId,
        'grammarTopic',
      ),
    );
  };

  return (
    <CommonPublishButton
      contentType={getTypeName(contentToPublish.type)}
      isButtonDisabled={isButtonDisabled()}
      isPublishAvailable={isPublishAvailable}
      isPublishedContent={contentToPublish?.ready}
      isPublishingInProgress={isPublishingInProgress}
      isUpdateButtonDisabled={isContentInvalid}
      isUpdateVisible={contentToPublish?.ready && contentToPublish?.changeStatus.hasNewChanges}
      isUnpublishVisible={isUnpublishAvailable && contentToPublish?.ready}
      tooltipText={tooltipText}
      onPublishClick={handleButtonClick}
    />
  );
};
