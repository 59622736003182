import styled, { css } from 'styled-components/macro';
import { ChangeEvent } from 'react';

type InputCommonProps = {
  withBoldFont: boolean;
  withRedBorder: boolean;
  withBlueBackground: boolean;
  withRightPadding: boolean;
  fontSize: string;
  withGreyColor: boolean;
  withoutBorder: boolean;
};

type InputTextProps = InputCommonProps & {
  value?: string;
  placeholder?: string;
  className?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  id?: string;
  mode?: 'text' | 'area';
  readOnly?: boolean;
};

const Component = styled.div<InputCommonProps & { withBiggerHeight: boolean }>`
  display: block;
  border-radius: 8px;
  border: 1px solid
    ${({ withRedBorder, theme }) =>
      withRedBorder ? `${theme.color.secondaryBase} !important` : theme.color.brandGreyscale600};
  height: ${({ withBiggerHeight }) => (withBiggerHeight ? '80px' : '44px')};
  padding: 2px 8px;
  width: 100%;
  margin-bottom: 4px;

  &:focus-within,
  &:active {
    border: 1px solid ${({ theme }) => theme.color.brandPrimaryBase};
  }

  ${({ withBoldFont }) =>
    withBoldFont &&
    css`
      font-weight: 700;
    `}
  ${({ fontSize }) => `font-size: ${fontSize}px;`}
  ${({ withBlueBackground, theme }) => withBlueBackground && `background: ${theme.color.brandGreyscale500};`}
  ${({ withoutBorder, theme }) =>
    withoutBorder &&
    ` border: 0;
      &:hover {
        background: ${theme.color.brandGreyscale500};
      }
    `}
  ${({ withGreyColor, theme }) => withGreyColor && `color: ${theme.color.brandGreyscale800};`}
  ${({ withRightPadding }) => withRightPadding && 'padding-right: 70px;'}
`;

export const InputComponent = ({
  value,
  placeholder,
  className,
  onChange,
  mode = 'text',
  id = 'input',
  withBoldFont,
  readOnly = false,
  withRedBorder,
  withRightPadding,
  withBlueBackground,
  fontSize,
  withoutBorder,
  withGreyColor,
}: InputTextProps) => (
  <Component
    as={mode === 'area' ? 'textarea' : 'input'}
    id={id}
    type="text"
    dir="auto"
    value={value}
    placeholder={placeholder}
    onChange={onChange}
    autoComplete="off"
    readOnly={readOnly}
    withBiggerHeight={mode === 'area'}
    withBoldFont={withBoldFont}
    withRedBorder={withRedBorder}
    withBlueBackground={withBlueBackground}
    withRightPadding={withRightPadding}
    fontSize={fontSize}
    withGreyColor={withGreyColor}
    withoutBorder={withoutBorder}
    className={className}
  />
);
