import { CommentReactionType } from '../../../types';

import { ReactionPill } from './styles';

export const REACTION_NAME = {
  EYES: ':eyes:',
  THUMBS_UP: ':+1:',
} as const;

const reactionEmojiMap = new Map([
  [REACTION_NAME.EYES, '👀'],
  [REACTION_NAME.THUMBS_UP, '👍'],
]);

export type ReactionNameType = typeof REACTION_NAME.EYES | typeof REACTION_NAME.THUMBS_UP;

export const CommentsReactions = ({
  reactions,
  onReactionClick,
  ...other
}: {
  reactions: CommentReactionType[];
  onReactionClick: (reactionName: ReactionNameType) => void;
}) => {
  const formatSenders = (senders: CommentReactionType['senders']) => senders.map(({ fullName }) => fullName).join('\n');

  return (
    <div {...other}>
      {reactions.map(({ emoji, senders }) => {
        return (
          <ReactionPill
            key={emoji}
            data-testid={emoji}
            title={formatSenders(senders)}
            onClick={() => onReactionClick(emoji as ReactionNameType)}
          >
            {reactionEmojiMap.get(emoji as ReactionNameType)} {senders.length}
          </ReactionPill>
        );
      })}
    </div>
  );
};
