import styled from 'styled-components';

import { ValidationErrorDisplayer } from '@components/ValidationErrorDisplayer';
import { LoadingStage } from '@common/enums/LoadingStage';
import { useIsEditAvailable } from '@features/content/courses';
import IDDisplayer from '@components/IDDisplayer/IDDisplayer';
import { HelpDisplayer } from '@features/help';
import { ContentCard } from '@components/DraggableList/ContentCard';
import { CreatePlacementTestBlock } from './CreatePlacementTestBlock';
import { PublishingStatus } from '@components/Publishing/PublishingStatus';
import helpersService from '@services/HelpersService';
import { AccessWarning } from '@components/Warning';
import { Loader } from '@features/theme';
import { useAppSelector } from '@redux/store';
import { selectPlacementTest } from '@selectors/PlacementTestSelectors';

const Title = styled.div`
  font-weight: 700;
  font-size: 3rem;
  margin-bottom: 1.4rem;
  display: flex;
  align-items: center;
`;

export const PlacementTestData = () => {
  const { isEditAvailable } = useIsEditAvailable();

  const placementTest = useAppSelector(selectPlacementTest);
  const { content, entrypointsData } = placementTest;
  const childrenValidationErrors =
    content?.validationStatus?.errors.filter((error) => error.field === 'entrypoints') || [];

  const existingPlacementTest = content.id && placementTest.loaded === LoadingStage.loaded;

  if (placementTest.loaded === LoadingStage.loading) {
    return <Loader size="L" />;
  }

  return (
    <>
      <PublishingStatus
        ready={content?.ready || false}
        changeStatus={
          content
            ? content.changeStatus
            : {
                hasNewChanges: false,
                hasPendingChanges: false,
              }
        }
      />
      <IDDisplayer id={content?.id} mode="normal" />
      <Title>
        Placement Test
        <HelpDisplayer id="how-to-placement-test" type="how-to" />
      </Title>
      {!isEditAvailable && <AccessWarning />}
      {!existingPlacementTest && <CreatePlacementTestBlock />}
      {entrypointsData.loaded !== LoadingStage.loaded ? (
        <Loader size="L" />
      ) : (
        <>
          {entrypointsData.entrypoints.length ? (
            <>
              {entrypointsData.entrypoints.map((entrypoint) => (
                <ContentCard arrayOfChildren={entrypointsData.entrypoints} content={entrypoint} key={entrypoint.id} />
              ))}
            </>
          ) : (
            <>
              {content.id && (
                <ValidationErrorDisplayer
                  visible={true}
                  type="warning"
                  text="Entrypoints will be created after adding levels to the course"
                />
              )}
            </>
          )}
        </>
      )}
      <ValidationErrorDisplayer text={helpersService.getValidationErrorMessageText(childrenValidationErrors)} />
    </>
  );
};
