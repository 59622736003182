import styled from 'styled-components';

import { ReactComponent as PlaceholderIcon } from './img/placeholder.svg';

const NoImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5.6rem;
  width: 5.6rem;
  border-radius: 50%;
  background: ${({ theme }) => theme.color.brandBlue5};
`;

const Image = styled.img`
  height: 5.6rem;
  width: 5.6rem;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.color.darkBlue15};
`;

export const CardImage = ({ image, className }: { image: string | null; className?: string }) => (
  <>
    {image ? (
      <Image src={image} alt="Card" className={className} />
    ) : (
      <NoImage>
        <PlaceholderIcon />
      </NoImage>
    )}
  </>
);
