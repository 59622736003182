import { FunctionComponent, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { Button, Modal } from 'react-bootstrap';
import styled from 'styled-components';
import { OtherLocationsInterface } from '@common/interfaces/exercises/OtherLocationsInterface';
import ChevronRightIcon from '@static/svg/translationsPanel/Chevron Right.svg';
import { TranslationTipActionsCreator } from '@actionCreators/TranslationTipActionsCreator';
import { ExerciseRelatedErrorsInterface } from '@common/interfaces/contentTypes/ResourceBundleInterface';
import { Loader } from '@features/theme';
import { getLink } from '@helpers/urlLinkHelper';
import { ContentTypes } from '@common/enums/ContentTypes';
import navigateIcon from '@static/svg/search/externalLink.svg';
import { selectLoadedExerciseContent, selectResourceBundleOtherPlaces } from '@selectors/CoursesSelectors';

const LoaderContainer = styled.div`
  margin-bottom: 3rem;
`;

interface ErrorsFromOtherPlacesModalProps {
  handleClose: () => void;
  id: string;
  exerciseRelatedErrors: ExerciseRelatedErrorsInterface[];
}

export const ErrorsFromOtherPlacesModal: FunctionComponent<ErrorsFromOtherPlacesModalProps> = ({
  handleClose,
  id,
  exerciseRelatedErrors,
}: ErrorsFromOtherPlacesModalProps) => {
  const dispatch = useAppDispatch();

  const bundleOtherPlaces: OtherLocationsInterface[][] = useAppSelector(selectResourceBundleOtherPlaces);
  const loadedExercise = useAppSelector(selectLoadedExerciseContent);

  const onlyOtherPlacesWithErrors = bundleOtherPlaces.filter((place) => {
    const exercisePlaceId = place.find((location) => location.type === ContentTypes.exercise)?.id;
    return (
      exercisePlaceId !== loadedExercise.id &&
      exerciseRelatedErrors.map((item) => item.exercise).includes(exercisePlaceId || '')
    );
  });

  const openAllPlaces = () => {
    const arrayOfLinks = onlyOtherPlacesWithErrors.map((place) => getLink(place));
    arrayOfLinks.forEach((link) => window.open(link, '_blank'));
  };

  useEffect(() => {
    dispatch(TranslationTipActionsCreator.getBundleLocations(id));
  }, [dispatch, id]);

  return (
    <Modal show={true} dialogClassName={'string-reuse-modal'} centered onHide={handleClose}>
      <Modal.Body>
        <span className="string-reuse-modal__title">Warning issues found</span>
        <span className="string-reuse-modal__description">
          This Resource Bundle is shared and has errors in the places listed below. For exercise publishing you need to
          resolve all the issues.
        </span>
        {bundleOtherPlaces && bundleOtherPlaces.length ? (
          <div className="string-reuse-modal__other-locations">
            {onlyOtherPlacesWithErrors.map((place, index) => {
              return (
                <div className="string-reuse-modal__other-locations__location" key={index}>
                  {place.map((location: any, index: number) => (
                    <span
                      className="string-reuse-modal__other-locations__location__name"
                      key={`${location.id}-${index}`}
                    >
                      {location.label || 'Resource Bundle'}{' '}
                      {index !== place.length - 1 ? (
                        <>
                          <img src={ChevronRightIcon} alt="" />{' '}
                        </>
                      ) : null}
                    </span>
                  ))}
                  <a className="" href={getLink(place)} target="_blank" rel="noreferrer">
                    <img src={navigateIcon} alt="Navigate" className="string-reuse-modal__navigate" />
                  </a>
                </div>
              );
            })}
          </div>
        ) : (
          <LoaderContainer>
            <Loader size="L" />
          </LoaderContainer>
        )}
        <div className="string-reuse-modal__buttons">
          <Button
            onClick={openAllPlaces}
            className="string-reuse-modal__buttons__button string-reuse-modal__buttons__copy"
          >
            Open all in new tabs
          </Button>
          <Button
            variant="light"
            onClick={handleClose}
            className="string-reuse-modal__buttons__button string-reuse-modal__buttons__cancel"
          >
            Cancel
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
