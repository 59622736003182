import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { FORMAT_TEXT_COMMAND, $insertNodes, $createTextNode, TextFormatType, COMMAND_PRIORITY_LOW } from 'lexical';
import { INSERT_CHECK_LIST_COMMAND, REMOVE_LIST_COMMAND } from '@lexical/list';
import { mergeRegister } from '@lexical/utils';
import { useEffect, useLayoutEffect, useReducer, useRef } from 'react';
import styled from 'styled-components/macro';

import { ACTION_ITEM_BLUR_COMMAND, ACTION_ITEM_FOCUS_COMMAND } from '@features/theme';

import { EmojiSelector } from './EmojiSelector';
import { ReactComponent as EmojiIcon } from './EmojiSelector/emoji.svg';
import { TOOLBAR_BUTTON_SIZE, ToolbarButton } from './ToolbarButton';
import { ReactComponent as ActionItemIcon } from './ToolbarButton/actionItem.svg';

const TOOLBAR_HEIGHT = '4rem';
const EMOJI_SELECTOR_POSITION_TOP = `calc((${TOOLBAR_HEIGHT} - ${TOOLBAR_BUTTON_SIZE}) / 2 + ${TOOLBAR_BUTTON_SIZE})`;

const StyledEmojiSelector = styled(EmojiSelector)`
  position: absolute;
  top: ${EMOJI_SELECTOR_POSITION_TOP};
  z-index: 10000;
`;

const Toolbar = styled.div`
  background-color: ${({ theme }) => theme.colorV2.baseEditorToolbarBackground};
  border-radius: 0.8rem 0.8rem 0 0;
  box-shadow: 0 0.1rem 0 ${({ theme }) => theme.colorV2.baseEditorToolbarBorder};
  display: flex;
  flex-direction: row;
  gap: 1.6rem;
  height: ${TOOLBAR_HEIGHT};
  align-items: center;
  padding: 0 1.6rem;
`;

const ToolbarSeparator = styled.div`
  border-right: 0.1rem solid ${({ theme }) => theme.colorV2.baseEditorToolbarSeparator};
  height: 1.6rem;
  width: 0.1rem;
`;

const ToolbarButtonBold = styled(ToolbarButton)`
  font-weight: bold;
`;

const ToolbarButtonItalic = styled(ToolbarButton)`
  font-style: italic;
`;

const ToolbarButtonUnderline = styled(ToolbarButton)`
  text-decoration: underline;
`;

const ToolbarButtonStrikethrough = styled(ToolbarButton)`
  text-decoration: line-through;
`;

const ToolbarButtonEmoji = styled(ToolbarButton)`
  position: relative;
  line-height: 1rem;
`;

const ToolbarButtonActionItem = styled(ToolbarButton)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export type ToolbarConfigType = {
  actionItem: boolean;
  bold: boolean;
  emoji: boolean;
  italic: boolean;
  strikethrough: boolean;
  underline: boolean;
};

const DEFAULT_TOOLBAR_CONFIG: ToolbarConfigType = {
  actionItem: false,
  bold: true,
  emoji: true,
  italic: true,
  strikethrough: true,
  underline: true,
};

const DEFAULT_ACTIVE_BUTTONS_VALUE = {
  actionItem: false,
  bold: false,
  emoji: false,
  italic: false,
  strikethrough: false,
  underline: false,
};

export const ToolbarPlugin = ({ config = {}, reset = false }: { config?: ToolbarConfigType | {}; reset?: boolean }) => {
  const toolbarConfig = { ...DEFAULT_TOOLBAR_CONFIG, ...config };
  const { actionItem, bold, emoji, italic, underline, strikethrough } = toolbarConfig;

  const [activeButtons, setActiveButtons] = useReducer(
    (state: ToolbarConfigType, newState: Partial<ToolbarConfigType>) => ({ ...state, ...newState }),
    DEFAULT_ACTIVE_BUTTONS_VALUE,
  );

  const focused = useRef(false);
  const emojiSelectorRef = useRef<HTMLDivElement>(null);
  const [editor] = useLexicalComposerContext();

  const focusEditor = () => {
    if (!focused.current) {
      editor.focus();
      focused.current = true;
    }
  };

  const handleButtonClick = (buttonType: TextFormatType) => {
    focusEditor();
    setActiveButtons({ [buttonType]: !activeButtons[buttonType as keyof typeof DEFAULT_ACTIVE_BUTTONS_VALUE] });
    editor.dispatchCommand(FORMAT_TEXT_COMMAND, buttonType);
  };

  const handleActionItems = () => {
    const actionItemCommand = activeButtons.actionItem ? REMOVE_LIST_COMMAND : INSERT_CHECK_LIST_COMMAND;

    setActiveButtons({ actionItem: !activeButtons.actionItem });
    editor.dispatchCommand(actionItemCommand, undefined);
  };

  const handleSelectEmoji = (value: string) => {
    editor.update(() => {
      $insertNodes([$createTextNode(value)]);
    });
  };

  useEffect(() => {
    return mergeRegister(
      editor.registerCommand(
        ACTION_ITEM_FOCUS_COMMAND,
        () => {
          setActiveButtons({ actionItem: true });
          return false;
        },
        COMMAND_PRIORITY_LOW,
      ),
      editor.registerCommand(
        ACTION_ITEM_BLUR_COMMAND,
        () => {
          setActiveButtons({ actionItem: false });
          return false;
        },
        COMMAND_PRIORITY_LOW,
      ),
    );
  });

  useEffect(() => {
    if (reset) {
      setActiveButtons(DEFAULT_ACTIVE_BUTTONS_VALUE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset]);

  useLayoutEffect(() => {
    const listenerEmojiSelectorClick = (event: any) => {
      if (activeButtons.emoji && emojiSelectorRef.current && !emojiSelectorRef.current.contains(event.target)) {
        setActiveButtons({ emoji: false });
      }
    };

    document.addEventListener('click', listenerEmojiSelectorClick, true);

    return () => {
      document.removeEventListener('click', listenerEmojiSelectorClick, true);
    };
  }, [activeButtons, editor, emojiSelectorRef]);

  return (
    <Toolbar role="toolbar">
      {bold && (
        <ToolbarButtonBold active={activeButtons.bold} onClick={() => handleButtonClick('bold')}>
          B
        </ToolbarButtonBold>
      )}
      {italic && (
        <ToolbarButtonItalic active={activeButtons.italic} onClick={() => handleButtonClick('italic')}>
          I
        </ToolbarButtonItalic>
      )}
      {underline && (
        <ToolbarButtonUnderline active={activeButtons.underline} onClick={() => handleButtonClick('underline')}>
          U
        </ToolbarButtonUnderline>
      )}
      {strikethrough && (
        <ToolbarButtonStrikethrough
          active={activeButtons.strikethrough}
          onClick={() => handleButtonClick('strikethrough')}
        >
          S
        </ToolbarButtonStrikethrough>
      )}
      {emoji && (
        <ToolbarButtonEmoji
          active={activeButtons.emoji}
          onClick={() => {
            focusEditor();
            setActiveButtons({ emoji: !activeButtons.emoji });
          }}
        >
          <EmojiIcon />
          <StyledEmojiSelector ref={emojiSelectorRef} open={activeButtons.emoji} onSelect={handleSelectEmoji} />
        </ToolbarButtonEmoji>
      )}
      {actionItem && (
        <>
          <ToolbarSeparator />
          <ToolbarButtonActionItem active={activeButtons.actionItem} onClick={handleActionItems}>
            <ActionItemIcon title="Add action item" />
          </ToolbarButtonActionItem>
        </>
      )}
    </Toolbar>
  );
};
