import { useState, useCallback } from 'react';
import { useAppDispatch } from '@redux/store';

import { PreviewModalActionsCreator } from '@actionCreators/PreviewModalActionsCreator';
import { DeviceType } from './PreviewModalProps';
import { PREVIEW_DEVICE_TYPE } from './constants';

const DEFAULT_PREVIEW_DEVICE_TYPE = PREVIEW_DEVICE_TYPE.MOBILE;

export const usePreviewState = () => {
  const dispatch = useAppDispatch();
  const [deviceType, setDeviceType] = useState<DeviceType>(DEFAULT_PREVIEW_DEVICE_TYPE);
  const [answerState, setAnswerState] = useState(0);

  const onModalClose = useCallback(() => {
    dispatch(PreviewModalActionsCreator.closePreview());
    setDeviceType(DEFAULT_PREVIEW_DEVICE_TYPE);
    setAnswerState(0);
  }, [dispatch]);

  return {
    deviceType,
    setDeviceType,
    answerState,
    setAnswerState,
    onModalClose,
  };
};
