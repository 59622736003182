import { ElementNode, LexicalEditor } from 'lexical';

import HtmlEditorSerializer from './HtmlEditorSerializer';
import MarkdownEditorSerializer from './MarkdownEditorSerializer';

export type SupportedValueTypes = 'html' | 'markdown';

export interface SerializerInterface {
  editor?: LexicalEditor;
  deserialize: (value: string, node?: ElementNode) => void;
  serialize: (node?: ElementNode) => string;
}

export const createSerializer = (valueType: SupportedValueTypes, editor: LexicalEditor) => {
  const serializerMap = new Map([
    ['html', HtmlEditorSerializer],
    ['markdown', MarkdownEditorSerializer],
  ]);
  const Serializer = serializerMap.get(valueType);

  return new Serializer!(editor);
};
