import { Formik } from 'formik';
import { useEffect } from 'react';
import { useParams, withRouter } from 'react-router-dom';

import { ContentTypes } from '@common/enums/ContentTypes';
import { LoadingStage } from '@common/enums/LoadingStage';
import { DBId } from '@common/types/DBId';
import { ContentOwner } from '@features/content/contentOwnership';
import { Loader } from '@features/theme';
import { SearchModal } from '@features/search';
import { Language } from '@features/content/languages';
import { processLocalizationsForFormikValues } from '@helpers/formikInitialValuesHelper';

import { BreadcrumbsAndOwnershipWrapper } from '../styles';
import { Header } from '../Header';
import { TranslationsPanel } from '../TranslationsPanel';
import { GrammarReviewActionCreators } from '@actionCreators/GrammarReviewActionCreators';
import { GrammarTopicData } from './GrammarTopicData';
import { Breadcrumbs } from './Breadcrumbs';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { selectGrammarTopic } from '@selectors/GrammarSelectors';
import { selectGrammarTranslationsPanel } from '@selectors/UiSelectors';
import { selectCourse } from '@selectors/CoursesSelectors';

const GrammarTopic = () => {
  const dispatch = useAppDispatch();
  const { courseId, topicId } = useParams<{ courseId: DBId; topicId: DBId }>();

  const grammarTopic = useAppSelector(selectGrammarTopic);
  const grammarTopicContent = grammarTopic.content;
  const grammarTranslationsPanel = useAppSelector(selectGrammarTranslationsPanel);
  const currentCourse = useAppSelector(selectCourse);

  useEffect(() => {
    dispatch(GrammarReviewActionCreators.setInitialGrammarTopic());
  }, [dispatch, topicId]);

  useEffect(() => {
    if (grammarTopic.loaded === LoadingStage.notLoaded && courseId && topicId) {
      dispatch(GrammarReviewActionCreators.getGrammarTopic(courseId, topicId));
    }
  }, [dispatch, courseId, grammarTopic, topicId]);

  useEffect(() => {
    if (
      grammarTopic.loaded === LoadingStage.loaded &&
      grammarTopic.exercisesData.loaded === LoadingStage.notLoaded &&
      courseId &&
      topicId
    ) {
      dispatch(GrammarReviewActionCreators.getGrammarExercises(courseId, topicId));
    }
  }, [dispatch, courseId, grammarTopic, topicId]);

  const getInitialValuesForGrammarContent = () => {
    return {
      title: processLocalizationsForFormikValues(grammarTopicContent?.title?.textLocalizations),
      titleContext: grammarTopicContent?.title?.description || '',
      titleChanged: false,

      description: processLocalizationsForFormikValues(grammarTopicContent?.description?.textLocalizations),
      descriptionContext: grammarTopicContent?.description?.description || '',
      descriptionChanged: false,
    };
  };

  if (grammarTopic.loaded === LoadingStage.loading) {
    return (
      <div className="course-edition__course-data-composer">
        {currentCourse && (
          <Header
            contentName="Grammar review"
            learningLanguage={currentCourse.learningLanguage as Language}
            interfaceLanguages={currentCourse.interfaceLanguages as Language[]}
          />
        )}
        <Loader size="L" />
      </div>
    );
  }

  if (grammarTopic.loaded === LoadingStage.loaded) {
    return (
      <Formik initialValues={getInitialValuesForGrammarContent()} onSubmit={() => {}}>
        <>
          {grammarTranslationsPanel.visible && <TranslationsPanel content={grammarTopic.content} />}
          <div className="course-edition__course-data-composer">
            <SearchModal />
            {currentCourse && (
              <Header
                contentName="Grammar review"
                learningLanguage={currentCourse.learningLanguage as Language}
                interfaceLanguages={currentCourse.interfaceLanguages as Language[]}
              />
            )}
            <BreadcrumbsAndOwnershipWrapper>
              <Breadcrumbs />
              <ContentOwner
                childComponentId={topicId}
                componentId={courseId}
                contentType={ContentTypes.grammarTopic}
                dates={grammarTopic.content.dates}
                owner={grammarTopic.content.owner}
              />
            </BreadcrumbsAndOwnershipWrapper>
            <GrammarTopicData />
          </div>
        </>
      </Formik>
    );
  }

  return null;
};

export const GrammarTopicContent = withRouter(GrammarTopic);
