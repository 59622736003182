import 'draft-js/dist/Draft.css';
import { FunctionComponent, useState } from 'react';
import KTagsCreator from '@components/Exercises/Common/KTagsCreator/KTagsCreator';
import BreakIntoCharactersStrategy from '@components/Exercises/Common/KTagsCreator/strategies/KTagBreakIntoCharactersStrategy';
import GappingToolProps from './GappingToolProps';

const MAX_CHARACTERS = 7;

const GappingTool: FunctionComponent<GappingToolProps> = ({
  value,
  placeholder,
  className,
  onChange,
  showKTags = false,
  dataSourceMode = 'editorState',
  errorsShown = false,
  onChangeInstant,
  isChangeBlocked = false,
}: GappingToolProps) => {
  let [totalCharacters, setTotalCharacters] = useState(0);

  return (
    <div className={`gapping-tool ${className !== undefined ? className : ''}`}>
      <div className="gapping-tool__characters">
        Characters:
        <span
          className={`gapping-tool__available-characters ${
            totalCharacters > MAX_CHARACTERS ? 'gapping-tool__available-characters--exceeded' : ''
          }`}
        >
          {totalCharacters} / {MAX_CHARACTERS}
        </span>
      </div>
      <KTagsCreator
        value={value}
        placeholder={placeholder}
        className={className}
        onChange={onChange}
        showKTags={showKTags}
        gapMode
        charactersMode
        addGroupStrategy={BreakIntoCharactersStrategy}
        dataSourceMode={dataSourceMode}
        maxCharacters={MAX_CHARACTERS}
        onCharactersSelected={(characters: number) => {
          setTotalCharacters(characters);
        }}
        errorsShown={errorsShown}
        onChangeInstant={onChangeInstant}
        isChangeBlocked={isChangeBlocked}
      />
    </div>
  );
};

export default GappingTool;
