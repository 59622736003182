import styled from 'styled-components/macro';

import { HelpTooltip } from '@features/help';
import experimentSelectedIcon from './experimentSelectedIcon.svg';
import experimentIcon from './experimentIcon.svg';

const ExperimentImage = styled.img`
  margin-left: 8px;
`;

export const NavigationExperimentImage = ({ selected }: { selected: boolean }) => (
  <>
    <ExperimentImage
      src={selected ? experimentSelectedIcon : experimentIcon}
      alt="Experiment"
      data-tip="Experiment"
      data-for="experiment-navigation-tooltip"
    />
    <HelpTooltip id="experiment-navigation-tooltip" effect="solid" />
  </>
);
