import type { AppState } from '@redux/store';

export const selectPlacementTestNavigationStructure = ({ placementTest }: AppState) =>
  placementTest.navigation.structure;
export const selectPlacementTestNavigationLoadingParentId = ({ placementTest }: AppState) =>
  placementTest.navigation.loadingParentId;

export const selectPlacementTest = ({ placementTest }: AppState) => placementTest.placementTest;
export const selectPlacementTestId = ({ placementTest }: AppState) => placementTest.placementTest.content.id;
export const selectPlacementTestContent = ({ placementTest }: AppState) => placementTest.placementTest.content;
export const selectPlacementTestEntrypoints = ({ placementTest }: AppState) =>
  placementTest.placementTest.entrypointsData.entrypoints;

export const selectPlacementTestEntrypoint = ({ placementTest }: AppState) => placementTest.entrypoint;
export const selectPlacementTestEntrypointContent = ({ placementTest }: AppState) => placementTest.entrypoint.content;
export const selectPlacementTestEntrypointExercises = ({ placementTest }: AppState) =>
  placementTest.entrypoint.exercisesData.exercises;

export const selectPlacementTestExercise = ({ placementTest }: AppState) => placementTest.placementExercise;
export const selectPlacementTestExerciseContent = ({ placementTest }: AppState) =>
  placementTest.placementExercise.content;
