import classnames from 'classnames';

import { Comments } from '@features/comments';
import { ContentTypes } from '@common/enums/ContentTypes';
import { PreviewModalContentProps } from '../PreviewModalProps';

import { PreviewModalHeader } from './Header';
import { PreviewModalFooter, typesWithoutFooter } from './PreviewModalFooter';
import { InstructionContainer } from './PreviewContentByType';
import { Loader } from '@features/theme';

import { PREVIEW_DEVICE_TYPE } from '../constants';
import { PreviewModalContentSwitcher } from './PreviewModalContentSwitcher';
import { PreviewModalFeedbackSwitcher } from './PreviewModalFeedbackSwitcher';
import keyboardIcon from './img/keyboard.svg';
import bulbIcon from './img/bulb.svg';
import downloadIcon from './img/download.svg';
import blueBulbIcon from './img/blueBulb.svg';

// @TODO migrate all styles to styled-components
import './PreviewModalContent.scss';
import { ProgressBar, ProgressBarContainer } from './preview.styles';
import { LocalizationInterface } from '@common/interfaces/localization/LocalizationInterface';
import { useAppSelector } from '@redux/store';
import { selectIsPreviewExerciseLoaded, selectLearningLanguage } from '@selectors/CoursesSelectors';

export const PreviewModalContent = ({
  onModalClose,
  type,
  deviceType,
  setDeviceType,
  answerState,
  setAnswerState,
  content,
  isForList,
}: PreviewModalContentProps) => {
  const exerciseForPreviewLoaded: boolean = useAppSelector(selectIsPreviewExerciseLoaded);

  const courseLearningLanguage = useAppSelector(selectLearningLanguage);
  const textLocalization = content.feedback?.textLocalizations?.find(
    (localization: LocalizationInterface) => localization.language === 'EN',
  );
  const explanation = textLocalization?.value;

  return (
    <>
      <PreviewModalHeader
        onModalClose={onModalClose}
        type={type}
        deviceType={deviceType}
        setDeviceType={setDeviceType}
        answerState={answerState}
        setAnswerState={setAnswerState}
      />
      <div
        className={classnames(
          'exercise-preview__content',
          `exercise-preview__content--${deviceType}`,
          { [`exercise-preview__content--${deviceType}--correct`]: answerState === 1 },
          { [`exercise-preview__content--${deviceType}--incorrect`]: answerState === 2 },
          {
            'exercise-preview__content--without-footer':
              typesWithoutFooter.includes(type) && answerState === 0 && !isForList,
          },
        )}
      >
        <div className="preview__buttons-container">
          <img src={bulbIcon} alt="Bulb" />
          {deviceType !== PREVIEW_DEVICE_TYPE.MOBILE && <img src={keyboardIcon} alt="Keyboard" />}
          {deviceType !== PREVIEW_DEVICE_TYPE.MOBILE && <img src={downloadIcon} alt="Download" />}
        </div>
        {(isForList && exerciseForPreviewLoaded) || !isForList ? (
          <>
            <div className="preview__content">
              <ProgressBarContainer deviceType={deviceType}>
                <ProgressBar />
              </ProgressBarContainer>
              {type === ContentTypes.tip && <img src={blueBulbIcon} className="tip-icon" alt="Blue bulb" />}
              <InstructionContainer
                content={content}
                courseLearningLanguage={courseLearningLanguage}
                viaHTML={type === ContentTypes.conversation}
                customClass={type === ContentTypes.tip ? 'preview__instruction--for-tip' : ''}
                isForList={isForList}
              />
              <PreviewModalContentSwitcher
                type={type}
                content={content}
                deviceType={deviceType}
                answerState={answerState}
                isForList={isForList}
              />
            </div>
            <PreviewModalFeedbackSwitcher
              type={type}
              content={content}
              deviceType={deviceType}
              answerState={answerState}
              isForList={isForList}
            />
          </>
        ) : (
          <Loader size="L" />
        )}
      </div>
      <Comments id={content.id} />
      <PreviewModalFooter
        answerState={answerState}
        deviceType={deviceType}
        explanation={explanation}
        isForList={isForList}
        type={type}
      />
    </>
  );
};
