import {
  $convertFromMarkdownString,
  $convertToMarkdownString,
  ElementTransformer,
  Transformer,
  TRANSFORMERS,
  ORDERED_LIST,
  UNORDERED_LIST,
} from '@lexical/markdown';
import { ListNode, $createListNode, $isListNode, ListType } from '@lexical/list';
import { ElementNode, LexicalEditor, LexicalNode } from 'lexical';

import { MENTION_REGEXP, MentionNode, $createMentionNode, $isMentionNode } from '../plugins/MentionsPlugin';
import { CustomListItemNode, $createCustomListItemNode, $isCustomListItemNode } from '../nodes/CustomListItemNode';

import { SerializerInterface } from './serializer';

const LIST_INDENT_SIZE = 4;

// Based on https://github.com/facebook/lexical/blob/d4b192289d3714b34614b411d40e513d1f86f413/packages/lexical-markdown/src/MarkdownTransformers.ts#L140
const actionItemListExport = (
  listNode: ListNode,
  exportChildren: (node: ElementNode) => string,
  depth: number,
): string => {
  const output = [];
  const children = listNode.getChildren();

  for (const listItemNode of children) {
    if ($isCustomListItemNode(listItemNode)) {
      if (listItemNode.getChildrenSize() === 1) {
        const firstChild = listItemNode.getFirstChild();
        if ($isListNode(firstChild)) {
          output.push(actionItemListExport(firstChild, exportChildren, depth + 1));
          continue;
        }
      }

      const indent = ' '.repeat(depth * LIST_INDENT_SIZE);
      const prefix = `- [${listItemNode.getChecked() ? 'x' : ' '}] [action:${listItemNode.getId()}] `;
      output.push(indent + prefix + exportChildren(listItemNode));
    }
  }

  return output.join('\n');
};

// Based on https://github.com/facebook/lexical/blob/d4b192289d3714b34614b411d40e513d1f86f413/packages/lexical-markdown/src/MarkdownTransformers.ts#L101
const customListReplace = (): ElementTransformer['replace'] => {
  return (parentNode, children, match) => {
    const actionItemId = match[2];
    const isChecked = match[1] === 'x';
    const listType: ListType = 'check';
    const previousNode = parentNode.getPreviousSibling();
    const nextNode = parentNode.getNextSibling();

    const listItem = $createCustomListItemNode(undefined, isChecked, undefined, actionItemId);
    if ($isListNode(nextNode) && nextNode.getListType() === listType) {
      const firstChild = nextNode.getFirstChild();
      if (firstChild !== null) {
        firstChild.insertBefore(listItem);
      } else {
        // should never happen, but let's handle gracefully, just in case.
        nextNode.append(listItem);
      }
      parentNode.remove();
    } else if ($isListNode(previousNode) && previousNode.getListType() === listType) {
      previousNode.append(listItem);
      parentNode.remove();
    } else {
      const list = $createListNode(listType, undefined);
      list.append(listItem);
      parentNode.replace(list);
    }
    listItem.append(...children);
    listItem.select(0, 0);
    const indent = Math.floor(match[1].length / LIST_INDENT_SIZE);
    if (indent) {
      listItem.setIndent(indent);
    }
  };
};

const CUSTOM_TRANSFORMERS: Transformer[] = [
  {
    dependencies: [MentionNode],
    export: (node: LexicalNode) => {
      return $isMentionNode(node) ? `@[${node.getName()},${node.getId()}]` : null;
    },
    importRegExp: MENTION_REGEXP,
    regExp: MENTION_REGEXP,
    replace: (textNode: any, match: any) => {
      textNode.replace($createMentionNode(match[1], match[2]));
      textNode.select(0, 0);
    },
    trigger: '@',
    type: 'text-match',
  },
  {
    dependencies: [CustomListItemNode],
    export: (node, exportChildren) => {
      if ($isListNode(node)) {
        const listType = node.getListType();
        if (listType === 'check') {
          return actionItemListExport(node, exportChildren, 0);
        }

        if (listType === 'number') {
          return ORDERED_LIST.export(node, exportChildren);
        }

        return UNORDERED_LIST.export(node, exportChildren);
      }

      return null;
    },
    regExp: /^\s*(?:-\s)?\s?\[(\s|x)?\]\s\[action:([\w-]+)\]/i,
    replace: customListReplace(),
    type: 'element',
  },
  ...TRANSFORMERS,
];

class MarkdownEditorSerializer implements SerializerInterface {
  editor: LexicalEditor;

  constructor(editor: LexicalEditor) {
    this.editor = editor;
  }

  deserialize(markdownString: string, node?: ElementNode) {
    this.editor.update(() => {
      $convertFromMarkdownString(markdownString, CUSTOM_TRANSFORMERS, node);
    });
  }

  serialize(node?: ElementNode) {
    return $convertToMarkdownString(CUSTOM_TRANSFORMERS, node);
  }
}

export default MarkdownEditorSerializer;
