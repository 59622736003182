import { MouseEvent, RefObject, useCallback } from 'react';

// @TODO Move this hook to a place where it could be app-resusable from

export const useOnClickOutside = <T extends HTMLElement>(
  ref: RefObject<T>,
  onClickOutside: (evt: MouseEvent<T>) => void,
) => {
  return useCallback(
    (evt: MouseEvent<T>) => {
      if (!onClickOutside || !ref.current || evt.target === ref.current) {
        return;
      }

      const elementRef = ref.current.getBoundingClientRect();

      if (
        elementRef.left < evt.clientX &&
        evt.clientX < elementRef.right &&
        elementRef.top < evt.clientY &&
        evt.clientY < elementRef.bottom
      ) {
        return;
      }

      onClickOutside && onClickOutside(evt);
    },
    [ref, onClickOutside],
  );
};
