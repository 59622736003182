import { useState, useEffect } from 'react';
import { useTheme } from 'styled-components';

import { ChangeStatusInterface } from '@common/interfaces/contentTypes/ChangeStatusInterface';

import './PublishingStatus.scss';

type PublishingStatusImageProps = {
  fillColor: string;
};

const PublishingStatusImage = ({ fillColor }: PublishingStatusImageProps) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.22461 4.22461C2.23361 6.21661 1 8.967 1 12C1 15.033 2.23361 17.7834 4.22461 19.7754L5.63867 18.3613C4.00867 16.7313 3 14.481 3 12C3
                    9.519 4.00867 7.26867 5.63867 5.63867L4.22461 4.22461ZM19.7754 4.22461L18.3613 5.63867C19.9913 7.26867 21 9.519 21 12C21 14.481 19.9913
                    16.7313 18.3613 18.3613L19.7754 19.7754C21.7664 17.7834 23 15.033 23 12C23 8.967 21.7664 6.21661 19.7754 4.22461ZM7.05273 7.05273C5.78473
                    8.31973 5 10.07 5 12C5 13.93 5.78473 15.6803 7.05273 16.9473L8.4668 15.5332C7.5608 14.6282 7 13.378 7 12C7 10.622 7.5618 9.3728 8.4668
                    8.4668L7.05273 7.05273ZM16.9473 7.05273L15.5332 8.4668C16.4392 9.3718 17 10.622 17 12C17 13.378 16.4382 14.6272 15.5332 15.5332L16.9473
                    16.9473C18.2143 15.6793 19 13.93 19 12C19 10.07 18.2153 8.31973 16.9473 7.05273ZM12 9C11.2044 9 10.4413 9.31607 9.87868 9.87868C9.31607
                    10.4413 9 11.2044 9 12C9 12.7956 9.31607 13.5587 9.87868 14.1213C10.4413 14.6839 11.2044 15 12 15C12.7956 15 13.5587 14.6839 14.1213
                    14.1213C14.6839 13.5587 15 12.7956 15 12C15 11.2044 14.6839 10.4413 14.1213 9.87868C13.5587 9.31607 12.7956 9 12 9Z"
        fill={fillColor}
      />
    </svg>
  );
};

type PublishingStatusProps = {
  ready: boolean;
  changeStatus: ChangeStatusInterface;
};

export const PublishingStatus = ({ ready, changeStatus }: PublishingStatusProps) => {
  const theme = useTheme();

  const notLiveColor = theme.color.quaternaryDark;
  const liveColor = theme.color.primaryGreen4;
  const pendingColor = theme.color.orange;

  const [statusMessage, setStatusMessage] = useState('Not live');
  const [statusColor, setStatusColor] = useState(notLiveColor);

  useEffect(() => {
    const isContentToBePublished = ready;
    const isContentInQueue = changeStatus.hasPendingChanges;
    const isContentWithNewChanges = changeStatus.hasNewChanges;

    if (isContentToBePublished && isContentInQueue && isContentWithNewChanges) {
      setStatusMessage('Being published - unpublished changes');
      setStatusColor(pendingColor);
    } else if (isContentToBePublished && !isContentInQueue && isContentWithNewChanges) {
      setStatusMessage('Live - unpublished changes');
      setStatusColor(liveColor);
    } else if (isContentToBePublished && isContentInQueue && !isContentWithNewChanges) {
      setStatusMessage('Being published');
      setStatusColor(pendingColor);
    } else if (isContentToBePublished && !isContentInQueue && !isContentWithNewChanges) {
      setStatusMessage('Live');
      setStatusColor(liveColor);
    } else if (!isContentToBePublished && isContentInQueue && isContentWithNewChanges) {
      setStatusMessage('Being unpublished - unpublished changes');
      setStatusColor(pendingColor);
    } else if (!isContentToBePublished && !isContentInQueue && isContentWithNewChanges) {
      setStatusMessage('Not live - unpublished changes');
      setStatusColor(pendingColor);
    } else if (!isContentToBePublished && isContentInQueue && !isContentWithNewChanges) {
      setStatusMessage('Being unpublished');
      setStatusColor(pendingColor);
    } else if (!isContentToBePublished && !isContentInQueue && !isContentWithNewChanges) {
      setStatusMessage('Not live');
      setStatusColor(notLiveColor);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ready, changeStatus]);

  return (
    <div className="publishing-status">
      <PublishingStatusImage fillColor={statusColor} />
      <span className="publishing-status__value" style={{ backgroundColor: statusColor }}>
        {statusMessage}
      </span>
    </div>
  );
};
