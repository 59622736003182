import { LocalizationInterface } from '@common/interfaces/localization/LocalizationInterface';
import { Audios } from './Audios';
import { InactiveAudios } from './InactiveAudios';
import { ValidationErrorInterface } from '@common/interfaces/validation/ValidationInterface';
import { TabFooter } from '../TabFooter';
import { selectIsIssuesShown } from '@selectors/UiSelectors';
import { useAppSelector } from '@redux/store';

type AudioTabProps = {
  validationErrors: ValidationErrorInterface[];
  defaultAudios: LocalizationInterface[];
  hiddenAudios: LocalizationInterface[];
  textLocalizations: LocalizationInterface[];
  notAllLanguagesSelected: boolean;
};

export const AudiosTab = ({
  validationErrors,
  defaultAudios,
  hiddenAudios,
  textLocalizations,
  notAllLanguagesSelected,
}: AudioTabProps) => {
  const isIssuesShown = useAppSelector(selectIsIssuesShown);

  return (
    <>
      <Audios
        audios={defaultAudios}
        textLocalizations={textLocalizations}
        validationErrors={isIssuesShown ? validationErrors : []}
      />
      {notAllLanguagesSelected ? (
        <InactiveAudios
          audios={hiddenAudios}
          textLocalizations={textLocalizations}
          validationErrors={isIssuesShown ? validationErrors : []}
        />
      ) : null}
      <TabFooter />
    </>
  );
};
