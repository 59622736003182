const SAVE = 'FILLGAP_RECOGNITION_EXERCISE_SAVE';
const SET_INSTRUCTIONS_LANGUAGE = 'FILLGAP_SET_INSTRUCTIONS_LANGUAGE';
const UPLOAD_IMAGE = 'FILLGAP_UPLOAD_IMAGE';
const REMOVE_IMAGE = 'FILLGAP_REMOVE_IMAGE';
const REMOVE_AUDIO = 'FILLGAP_REMOVE_AUDIO';
const REMOVE_VIDEO = 'FILLGAP_REMOVE_VIDEO';
const UPLOAD_VIDEO_SUCCESS = 'FILLGAP_UPLOAD_VIDEO_SUCCESS';
const SET_FILLGAP_ALL_FIELDS = 'SET_FILLGAP_ALL_FIELDS';

export const FillGapExerciseActions = {
  SAVE,
  SET_INSTRUCTIONS_LANGUAGE,
  UPLOAD_IMAGE,
  REMOVE_IMAGE,
  REMOVE_AUDIO,
  REMOVE_VIDEO,
  UPLOAD_VIDEO_SUCCESS,
  SET_FILLGAP_ALL_FIELDS,
} as const;
