import { FormikContextType, useFormikContext } from 'formik';
import { useHistory, useParams } from 'react-router-dom';
import { DBId } from '@common/types/DBId';
import BreadcrumbNavigation from '@components/BreadcrumbNavigation/BreadcrumbNavigation';
import { constants as contentConstants } from '@features/content';
import { LANGUAGE_NAMES, Language } from '@features/content/languages';

import { RoleplayCategoryFormikValues } from '../../types';

export const Breadcrumbs = () => {
  const history = useHistory();
  const { languageId } = useParams<{ languageId: DBId; categoryId: DBId }>();
  const formikContext: FormikContextType<RoleplayCategoryFormikValues> = useFormikContext();

  const categoryTitle =
    formikContext?.values.title?.find((loc) => loc.language === contentConstants.DEFAULT_LANGUAGE)?.value ||
    'Roleplay Category';

  return (
    <BreadcrumbNavigation
      breadcrumbItems={[
        <span onClick={() => history.push(`/roleplay/${languageId}`)}>
          {`${LANGUAGE_NAMES[languageId.toUpperCase() as Language]} Roleplay`}
        </span>,
        <span>{categoryTitle}</span>,
      ]}
    />
  );
};
