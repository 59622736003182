import { Fragment } from 'react';
import { OtherLocationsInterface } from '@common/interfaces/exercises/OtherLocationsInterface';
import { getLink } from '@helpers/urlLinkHelper';
import ChevronRightIcon from '@static/svg/translationsPanel/Chevron Right.svg';
import ExternalLinkIcon from '@static/svg/translationsPanel/External link.svg';

type OtherLocationsTabProps = {
  otherLocations: OtherLocationsInterface[][];
};

export const OtherLocationsTab = ({ otherLocations }: OtherLocationsTabProps) => (
  <ul className="translations-panel-modal__other-locations">
    {otherLocations?.map((otherLocation, idx) => (
      <li className="translations-panel-modal__other-location" key={idx}>
        <a
          href={getLink(otherLocation)}
          target="_blank"
          rel="noopener noreferrer"
          className="translations-panel-modal__other-location-link"
        >
          <span className="translations-panel-modal__other-location-text">
            {otherLocation.map((location) => (
              <Fragment key={location.id}>
                {location.label || 'Resource Bundle'} <img src={ChevronRightIcon} alt="" />{' '}
              </Fragment>
            ))}
            <img
              src={ExternalLinkIcon}
              alt="Open in a new tab"
              className="translations-panel-modal__other-location-external-link-icon"
            />
          </span>
        </a>
      </li>
    ))}
  </ul>
);
