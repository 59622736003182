import styled from 'styled-components/macro';

import { ReactComponent as ExternalLinkIcon } from '@static/svg/search/externalLink.svg';
import { DBId } from '@common/types/DBId';

const LocationsContainer = styled.div`
  width: 100%;
  max-height: 30rem;
  overflow: auto;
  margin-bottom: 1.2rem;

  &::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
    position: absolute;
    right: 0;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 0.5rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.color.quaternaryDark};
    transition: all 0.5s;
    border-radius: 0.5rem;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.color.brandGreyscale800};
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
`;

const NavigateLink = styled.a`
  margin-left: 1rem;
  display: flex;
  height: 5rem;
  align-items: center;
  justify-content: space-between;
  font-size: 1.6rem;
  text-decoration: none !important;
`;

const LinkText = styled.span`
  max-width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const ListOfLinks = ({ ids, caption = 'Exercise' }: { ids: DBId[]; caption?: string }) => (
  <LocationsContainer>
    {ids.map((id) => (
      <NavigateLink href={`/content/${id}`} target="_blank" rel="noopener noreferrer">
        <LinkText>
          {caption}: {id}
        </LinkText>
        <ExternalLinkIcon />
      </NavigateLink>
    ))}
  </LocationsContainer>
);
