import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { ContentTypes, type ContentTypesType } from '@common/enums/ContentTypes';
import { DBId } from '@common/types/DBId';
import { allTypesMap } from '@helpers/contentCardHelper';
import { ExerciseTypes } from '@common/enums/ExerciseTypes';
import { CommonNavigationItem, NavigationItemType, ChildrenArray } from '@features/content/navigation';

export const NavigationItem = ({
  content,
  structure,
  onExpand,
  loadingParentId,
  currentPathIds,
}: {
  content: NavigationItemType;
  structure: NavigationItemType[];
  onExpand: (type: ContentTypesType, id: DBId) => void;
  loadingParentId: string;
  currentPathIds: string[];
}) => {
  const { entrypointId, exerciseId } = useParams<{ entrypointId: DBId; exerciseId: DBId }>();
  const [expanded, setExpanded] = useState(content.expanded);

  useEffect(() => {
    if (!expanded && entrypointId === content.id) {
      content.children && handleExpand(content.type as ContentTypesType, content.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entrypointId]);

  useEffect(() => {
    setExpanded(content.expanded);
  }, [content]);

  const handleExpand = (type: ContentTypesType, id: DBId) => {
    if (!loadingParentId) {
      setExpanded(!expanded);

      if (!expanded) {
        onExpand(type, id);
      }
    }
  };

  const getUrl = () => {
    const courseId = structure.find((element) => element.type === ContentTypes.placementTest)?.id;
    switch (content.type) {
      case ContentTypes.placementTest:
        return `/course/${content.id}/placement-test`;
      case ContentTypes.entrypoint:
        return `/course/${courseId}/placement-test/entrypoint/${content.id}`;
      default:
        if (Object.values(ExerciseTypes).includes(content.type as any)) {
          const entrypointId = structure.find((element) => element.id === content.id)?.parentId;
          return `/course/${courseId}/placement-test/entrypoint/${entrypointId}/exercise/${content.id}`;
        } else {
          return '';
        }
    }
  };
  const childrenArray = structure.filter((element) => element.parentId === content.id);
  const grandChildrenArray = structure.filter(
    (element) => element.parentId && childrenArray.map((element) => element.id).includes(element.parentId),
  );

  return (
    <>
      <CommonNavigationItem
        currentPathIds={currentPathIds}
        loadingParentId={loadingParentId}
        content={content}
        expanded={expanded}
        handleExpand={handleExpand}
        title={content.title || allTypesMap[content.type as ContentTypesType]}
        url={getUrl()}
        exerciseId={exerciseId}
      />
      <ChildrenArray
        visible={content.children && expanded}
        childrenAmount={
          content.type === ContentTypes.placementTest
            ? structure.filter((element) => element.type !== ContentTypes.placementTest).length
            : childrenArray.length + grandChildrenArray.length
        }
      >
        {structure
          .filter((element) => element.parentId === content.id)
          .map((element) => (
            <NavigationItem
              key={element.id}
              content={element}
              structure={structure}
              onExpand={onExpand}
              loadingParentId={loadingParentId}
              currentPathIds={currentPathIds}
            />
          ))}
      </ChildrenArray>
    </>
  );
};
