import { apiClient } from '@features/api';
import { AxiosResponse } from 'axios';

export const getExperiments = async (clearCache: boolean): Promise<AxiosResponse<{ key: string; name: string }[]>> => {
  return apiClient.noErrorsV2.get(`/experiments?clearCache=${clearCache}`);
};
export const getExperimentVariations = async (
  experimentKey: string,
  clearCache: boolean,
): Promise<AxiosResponse<{ key: string; name: string }[]>> => {
  return apiClient.noErrorsV2.get(`/experiments/${experimentKey}/variations?clearCache=${clearCache}`);
};
