import { useAppDispatch } from '@redux/store';
import { capitalize } from 'lodash';
import { useMemo } from 'react';
import styled from 'styled-components/macro';

import { LessonType, LessonTypeAllowedToChange } from '@common/enums/LessonTypes';
import { DBId } from '@common/types/DBId';
import { Dropdown, DropdownInterface } from '@components/Dropdown';
import { useIsEditAvailable } from '@features/content/courses';
import { ContentTypesActionsCreator } from '@actionCreators/ContentTypesActionsCreator';

const LessonTypeDropdown = styled(Dropdown)`
  margin-block: 0.8rem;
  min-height: unset;
  min-width: unset;
  width: fit-content;

  .dropdown-toggle {
    font-weight: 700;
    font-size: 1.6rem;
    color: ${({ theme }) => theme.color.brandBlack} !important;
    padding: 0 2.6rem 0 0.4rem !important;
    background: ${({ theme }) => theme.color.brandGreyscale500} !important;
    border: none !important;
    border-radius: 0.4rem !important;
    margin: 0;
    min-height: 3.2rem !important;
    width: auto;
  }
`;

type LessonTypeSelectorProps = {
  allowedLessonTypesToChange: LessonTypeAllowedToChange[];
  lessonType: LessonTypeAllowedToChange;
  lessonTitle: string;
  id: DBId;
};

export const LessonTypeSelector = ({
  allowedLessonTypesToChange,
  lessonType,
  lessonTitle,
  id,
}: LessonTypeSelectorProps) => {
  const dispatch = useAppDispatch();
  const { isEditAvailable } = useIsEditAvailable();

  const lessonTypeDropdownValues: DropdownInterface[] = useMemo(
    () =>
      allowedLessonTypesToChange.map((allowedLessonType) => ({
        code: allowedLessonType,
        name: capitalize(allowedLessonType),
      })),
    [allowedLessonTypesToChange],
  );

  return (
    <LessonTypeDropdown
      values={lessonTypeDropdownValues}
      value={lessonTitle}
      title={lessonTitle}
      disabled={!isEditAvailable}
      onChange={({ code }) => {
        if (code !== lessonType) {
          dispatch(ContentTypesActionsCreator.changeLessonType(id, code as LessonType));
        }
      }}
    />
  );
};
