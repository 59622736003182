import { apiClient } from '@features/api';
import { DBId } from '@common/types/DBId';
import { getRealId } from '@helpers/reuseHelper';

const NavigationService = {
  async getCourseNavigation(courseId: DBId) {
    return await apiClient.v2.get(`/navigation/courses/${courseId}/levels`);
  },
  async getLevelNavigation(levelId: DBId) {
    return await apiClient.v2.get(`/navigation/levels/${levelId}/chapters`);
  },
  async getChapterNavigation(levelOrChapterId: DBId) {
    return await apiClient.v2.get(`/navigation/chapters/${levelOrChapterId}/lessons`);
  },
  async getLessonNavigation(lessonId: DBId) {
    return await apiClient.v2.get(`/navigation/lessons/${getRealId(lessonId)}/activities`);
  },
  async getLiveLessonNavigation(lessonId: DBId) {
    return await apiClient.v2.get(`/navigation/lessons/${getRealId(lessonId)}/exercises`);
  },
  async getActivityNavigation(activityId: DBId) {
    return await apiClient.v2.get(`/navigation/activities/${getRealId(activityId)}/exercises`);
  },
};

export default NavigationService;
