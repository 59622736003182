import { Modal } from 'react-bootstrap';
import styled, { css } from 'styled-components/macro';

import { PREVIEW_DEVICE_TYPE } from '../constants';
import { DeviceType } from '../PreviewModalProps';

export const customScrollbar = css`
  &::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
    position: absolute;
    right: 0;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 0.5rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colorV2.previewScrollbarThumbBackground};
    transition: all 0.5s;
    border-radius: 0.5rem;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.colorV2.previewScrollbarThumbHoverBackground};
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
`;

export const ModalContainer = styled(Modal)`
  .modal-dialog {
    max-width: 132.8rem;
    width: 90%;
  }

  .modal-content {
    border-radius: 0.8rem;
    height: 90vh;
    max-height: 90vh;
    overflow: hidden;

    ${customScrollbar}
  }

  .modal-body {
    padding: 0;
  }
`;

export const ProgressBarContainer = styled.div<{ deviceType: DeviceType }>`
  ${({ deviceType, theme }) => {
    let progressBarContainerMaxWidth = '48rem';

    if (deviceType === PREVIEW_DEVICE_TYPE.MOBILE) {
      progressBarContainerMaxWidth = '25rem';
    } else if (deviceType === PREVIEW_DEVICE_TYPE.TABLET) {
      progressBarContainerMaxWidth = '46rem';
    }

    return css`
      background: ${theme.colorV2.uiDivider};
      border-radius: 0.8rem;
      height: 0.8rem;
      margin: 1.4rem auto 0;
      width: 100%;
      max-width: ${progressBarContainerMaxWidth};
    `;
  }};
`;

export const ProgressBar = styled.div`
  background: ${({ theme }) => theme.colorV2.accentSecondary};
  border-radius: 0.8rem;
  height: 0.8rem;
  margin-top: 0;
  width: 25%;
`;
