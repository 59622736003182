import styled from 'styled-components';

import BreadcrumbNavigation from '@components/BreadcrumbNavigation/BreadcrumbNavigation';

import { ProgressMatchingHeader } from './ProgressMatchingHeader';
import { IdentityGroupsTable } from './IdentityGroupsTable';

const ProgressMatchingWrapper = styled.div`
  margin: 0 4rem;
  width: 100%;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 3rem;
  margin-bottom: 5.6rem;
`;

export const ProgressMatchingPage = () => (
  <ProgressMatchingWrapper>
    <ProgressMatchingHeader />
    <BreadcrumbNavigation breadcrumbItems={[<span>Progress Matching</span>]} />
    <Title>Progress Matching</Title>
    <IdentityGroupsTable />
  </ProgressMatchingWrapper>
);
