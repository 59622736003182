import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { ContentTypesActionsCreator } from '@actionCreators/ContentTypesActionsCreator';
import { getUrlToNavigate } from '@helpers/contentCardHelper';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { PublishingCardButton } from '@components/ContentTypes/BaseContent/PublishingCardButton';
import { CardPendingStatus } from '@components/ContentTypes/BaseContent/CardPendingStatus';
import { RemoveCardButton } from '@components/ContentTypes/BaseContent/RemoveCardButton';
import { CardPublishingStatus } from '@components/ContentTypes/BaseContent/CardPublishingStatus';
import { CardValidationMark } from '@components/ContentTypes/BaseContent/CardValidationMark';
import { CardDragHandler } from '@components/ContentTypes/BaseContent/CardDragHandler';
import { RemoveModal } from '@components/ContentTypes/BaseContent/RemoveModal';

import { LevelCardProps } from './LevelCardProps';
import { LevelTypeSelector } from './LevelTypeSelector';

import { CardControllers, CardDataWrapper, CardWrapper, Data, DataWrapper, Title } from '../card.styles';
import { selectCourse, selectNewContentElementId } from '@selectors/CoursesSelectors';

export const LevelCard = ({ content, idx, dndProps }: LevelCardProps) => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const [hovered, setHovered] = useState(false);
  const [isRemoveModalOpened, setIsRemoveModalOpened] = useState(false);
  const { id, type, changeStatus, ready, titleWithLocalizations, validation } = content;

  const levelTitle =
    titleWithLocalizations?.textLocalizations.find((item) => item.language === 'EN')?.value || `Untitled ${type}`;

  const loadedCourse = useAppSelector(selectCourse);
  const isChildInvalid = Object.values(loadedCourse?.validation?.invalidChildEntities || {})?.includes(id);

  const newElementId = useAppSelector(selectNewContentElementId);

  const handleRemove = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsRemoveModalOpened(true);
  };

  return (
    <CardWrapper id={`level-${id}`} onMouseOver={() => setHovered(true)} onMouseOut={() => setHovered(false)}>
      <RemoveModal
        opened={isRemoveModalOpened}
        onHide={() => setIsRemoveModalOpened(false)}
        onDelete={() => {
          dispatch(ContentTypesActionsCreator.removeContent(content));
        }}
      />
      <CardDragHandler dndProps={dndProps} visible={hovered} />
      <CardDataWrapper
        to={getUrlToNavigate(params, id, type)}
        $active={dndProps?.snapshot?.isDragging || id === newElementId}
        $hovered={hovered}
      >
        <CardPublishingStatus hasPendingChanges={!!changeStatus?.hasPendingChanges} ready={!!ready} />
        <DataWrapper>
          <Data>
            <CardPendingStatus content={content} />
            <Title title={levelTitle}>
              {ready && idx !== null ? `${idx + 1} ` : ''}
              {levelTitle}
              <CardValidationMark isInvalid={isChildInvalid || !validation?.valid} />
            </Title>
          </Data>
          <CardControllers>
            <LevelTypeSelector content={content} idx={idx} />
            <PublishingCardButton hovered={hovered} content={content} />
            <RemoveCardButton hovered={hovered} content={content} onRemove={handleRemove} />
          </CardControllers>
        </DataWrapper>
      </CardDataWrapper>
    </CardWrapper>
  );
};
