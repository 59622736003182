import { Fragment } from 'react';

import { HeaderItemSeparator } from '@features/content/header';
import { HelpDisplayer } from '@features/help';
import { ContextualMenu } from '@features/theme';

import { StyledContextualMenuTriggerIcon } from '../styles';
import downloadIcon from './_img/download.svg';
import uploadIcon from './_img/upload.svg';

type UploadDownloadTranslationsOptionProps = {
  label: string;
  icon: string;
  onClick: () => void;
};

const UploadDownloadTranslationsOption = ({ label, icon, onClick }: UploadDownloadTranslationsOptionProps) => (
  <div className="context-menu__list-element" onClick={onClick}>
    <img src={icon} alt={label} />
    <span>{label}</span>
    {label.startsWith('Upload') && <HelpDisplayer type="guideline" id="guideline-upload-translations" />}
  </div>
);

type UploadDownloadTranslationsProps = {
  isUploadTranslationsAvailable: boolean;
  onDownload: () => void;
  onUpload: () => void;
};

export const UploadDownloadTranslationsMenu = ({
  isUploadTranslationsAvailable,
  onDownload,
  onUpload,
}: UploadDownloadTranslationsProps) => {
  return (
    <>
      <ContextualMenu position="left" triggerIcon={<StyledContextualMenuTriggerIcon />} variant="small" withTrigger>
        {[
          <Fragment key="0">
            {isUploadTranslationsAvailable ? (
              <UploadDownloadTranslationsOption label="Upload translations" icon={uploadIcon} onClick={onUpload} />
            ) : null}
          </Fragment>,
          <UploadDownloadTranslationsOption
            icon={downloadIcon}
            key="1"
            label="Download translation request"
            onClick={onDownload}
          />,
        ]}
      </ContextualMenu>
      <HeaderItemSeparator />
    </>
  );
};
