import { Button } from 'react-bootstrap';

import { RoleplayActionCreators } from '@actionCreators/RoleplayActionsCreators';
import { useIsEditAvailable } from '@features/content/courses';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { selectIsSaveButtonEnabled } from '@selectors/CoursesSelectors';

import { useCurrentRoleplayContent } from '../useCurrentRoleplayContent';

export const TabFooter = () => {
  const dispatch = useAppDispatch();
  const { isEditAvailable } = useIsEditAvailable();

  const isSaveButtonEnabled = useAppSelector(selectIsSaveButtonEnabled);

  const { onSave } = useCurrentRoleplayContent();

  return (
    <footer className="translations-panel-modal__save-cancel">
      <Button
        className="translations-panel-modal__cancel"
        onClick={() => dispatch(RoleplayActionCreators.closeTranslationsPanel())}
      >
        Cancel
      </Button>
      <Button
        className="translations-panel-modal__save"
        disabled={!isEditAvailable || !isSaveButtonEnabled}
        onClick={() => {
          onSave();
          dispatch(RoleplayActionCreators.closeTranslationsPanel());
        }}
      >
        Save
      </Button>
    </footer>
  );
};
