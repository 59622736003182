import styled from 'styled-components';

import { ReactComponent as VideoIcon } from './img/video.svg';

const MediaWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ImagePlaceholder = styled.div`
  margin-right: 0.8rem;
  width: 5.5rem;
`;

const Image = styled.img`
  height: 4rem;
  width: 100%;
  border-radius: 0.8rem;
  border: 0.1rem solid rgba(0, 0, 0, 0.1);
`;

const MediaDistractor = styled.div<{ visible: boolean }>`
  width: 0.1rem;
  margin-right: 0.8rem;

  ${({ visible, theme }) =>
    visible &&
    `
     height: 1rem;
     background: ${theme.color.quaternaryDark};
  `}
`;

export const CardMedia = ({ image, hasVideo }: { image: string | null; hasVideo: boolean }) => (
  <MediaWrapper>
    <ImagePlaceholder>{image && <Image src={image} alt="Card" />}</ImagePlaceholder>
    <MediaDistractor visible={!!(image && hasVideo)} />
    {hasVideo && <VideoIcon />}
  </MediaWrapper>
);
