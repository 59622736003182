import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { ContentTypes, type ContentTypesType } from '@common/enums/ContentTypes';
import { DBId } from '@common/types/DBId';
import { CommonNavigationItem, NavigationItemType, ChildrenArray } from '@features/content/navigation';

import { getTitle, getLink } from './navigationHelpers';

type NavigationItemPropsType = {
  content: NavigationItemType;
  selectedContent?: NavigationItemType | null;
  currentPathIds: string[];
  isInMoveContentModal?: boolean;
  loadingParentId: string;
  selectedTargetLocationId?: DBId;
  structure: NavigationItemType[];
  onExpand: (type: ContentTypesType, id: DBId) => void;
  onSelect?: (contentId: DBId) => void;
  onToggleContentActionsMenu?: (content: NavigationItemType | null) => void;
};

const isValidTargetToMoveContent = ({ content, selectedContent, structure }: Partial<NavigationItemPropsType>) => {
  const selectedContentParent = structure?.find(({ id }) => id === selectedContent?.parentId);

  if (
    !content ||
    !selectedContentParent ||
    content.id === selectedContentParent.id ||
    content.type === ContentTypes.roleplay
  )
    return false;

  // Is a valid target to move the selected content to?
  return content.type === selectedContentParent.type;
};

export const NavigationItem = ({
  content,
  currentPathIds,
  isInMoveContentModal,
  loadingParentId,
  selectedContent,
  selectedTargetLocationId,
  structure,
  onExpand,
  onSelect,
  onToggleContentActionsMenu,
}: NavigationItemPropsType) => {
  const { levelId, chapterId, lessonId, activityId, exerciseId } = useParams<{
    levelId: DBId;
    chapterId: DBId;
    lessonId: DBId;
    activityId: DBId;
    exerciseId: DBId;
  }>();
  const [expanded, setExpanded] = useState(content.expanded);

  const isSelectable = isInMoveContentModal && isValidTargetToMoveContent({ content, selectedContent, structure });

  useEffect(() => {
    if (
      !expanded &&
      !isSelectable &&
      content.id !== selectedContent?.parentId &&
      (levelId === content.id || chapterId === content.id || lessonId === content.id || activityId === content.id)
    ) {
      setExpanded(true);
      content.children && handleExpand(content.type as ContentTypesType, content.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [levelId, chapterId, lessonId, activityId, selectedContent]);

  const handleExpand = (type: ContentTypesType, id: DBId) => {
    if (!loadingParentId) {
      setExpanded(!expanded);

      if (!expanded) {
        onExpand(type, id);
      }
    }
  };

  const childrenArray = structure.filter((element) => element.parentId === content.id);
  const grandChildrenArray = structure.filter(
    (element) => element.parentId && childrenArray.map((element) => element.id).includes(element.parentId),
  );
  const grandGrandChildrenArray = structure.filter(
    (element) => element.parentId && grandChildrenArray.map((element) => element.id).includes(element.parentId),
  );
  const grandGrandGrandChildrenArray = structure.filter(
    (element) => element.parentId && grandGrandChildrenArray.map((element) => element.id).includes(element.parentId),
  );

  const childrenAmout =
    content.type === ContentTypes.course
      ? structure.filter((element) => element.type !== ContentTypes.course).length
      : [
          ...childrenArray,
          ...grandChildrenArray,
          ...grandGrandChildrenArray,
          ...grandGrandChildrenArray,
          ...grandGrandGrandChildrenArray,
        ].length;

  // @TODO: make common function to check is content legacy
  const isLegacyActivity = content?.type === 'entity' || (content?.type === 'quiz' && content?.oldType === 'review');

  const disabledForMoveContentModal =
    isInMoveContentModal && (content.id === selectedContent?.parentId || content.type === ContentTypes.roleplay);

  return (
    <>
      <CommonNavigationItem
        content={content}
        currentPathIds={currentPathIds}
        disabled={isLegacyActivity || disabledForMoveContentModal}
        exerciseId={exerciseId}
        expanded={expanded}
        handleExpand={handleExpand}
        isSelectable={isSelectable}
        isSelected={content.id === selectedTargetLocationId}
        loadingParentId={loadingParentId}
        selectedContent={selectedContent}
        title={getTitle(content, structure)}
        url={getLink(content, structure)}
        onSelect={onSelect}
        onToggleContentActionsMenu={onToggleContentActionsMenu}
      />
      <ChildrenArray
        visible={content.children && expanded && !disabledForMoveContentModal}
        childrenAmount={childrenAmout}
      >
        {structure
          .filter((childContent) => childContent.parentId === content.id)
          .map((childContent) => (
            <NavigationItem
              content={childContent}
              currentPathIds={currentPathIds}
              isInMoveContentModal={isInMoveContentModal}
              key={childContent.id}
              loadingParentId={loadingParentId}
              selectedContent={selectedContent}
              selectedTargetLocationId={selectedTargetLocationId}
              structure={structure}
              onExpand={onExpand}
              onSelect={onSelect}
              onToggleContentActionsMenu={onToggleContentActionsMenu}
            />
          ))}
      </ChildrenArray>
    </>
  );
};
