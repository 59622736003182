import { createSlice, createEntityAdapter, PayloadAction, createDraftSafeSelector } from '@reduxjs/toolkit';
import { LoadingStage, LoadingStageType } from '@common/enums/LoadingStage';
import { UserInterface } from '@common/interfaces/users/UserInterface';
import type { AppState } from '@redux/store';

const usersAdapter = createEntityAdapter<UserInterface>({
  selectId: (user) => user.id,
});

export const usersSlice = createSlice({
  name: 'users',
  initialState: usersAdapter.getInitialState({
    loaded: LoadingStage.notLoaded as LoadingStageType,
  }),
  reducers: {
    getUsers: (state) => {
      state.loaded = LoadingStage.loading;
    },
    setUsers: (state, { payload }: PayloadAction<UserInterface[]>) => {
      state.loaded = LoadingStage.loaded;
      usersAdapter.addMany(state, payload);
    },

    editUserRole: (state, _action: PayloadAction<any>) => state,
    updateUser: usersAdapter.updateOne,

    archiveUser: (state, _action: PayloadAction<any>) => state,
    removeUser: usersAdapter.removeOne,
  },
});

export const usersSelectors = usersAdapter.getSelectors<AppState>((state) => state.users);

export const selectIsUsersLoaded = createDraftSafeSelector(
  (state: AppState) => state.users,
  (users) => users.loaded,
);
