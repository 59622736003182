import styled from 'styled-components';
import { ValidationErrorDisplayer } from '@components/ValidationErrorDisplayer';
import { ErrorFromOtherPlacesDisplayer } from './ErrorFromOtherPlacesDisplayer/ErrorFromOtherPlacesDisplayer';
import { ValidationErrorInterface } from '@common/interfaces/validation/ValidationInterface';
import { ExerciseRelatedErrorsInterface } from '@common/interfaces/contentTypes/ResourceBundleInterface';
import helpersService from '@services/HelpersService';

const BundleNameContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
`;

const BundleName = styled.span`
  color: ${({ theme }) => theme.color.brandGreyscale800};
  font-weight: 400;
  font-size: 14px;
`;

type ResourceBundleNameProps = {
  errors: ValidationErrorInterface[];
  bundleVocabErrors: ValidationErrorInterface[];
  exerciseRelatedErrors: ExerciseRelatedErrorsInterface[];
  isErrorsInOtherPlaces: boolean;
  bundleId: string;
};

export const ResourceBundleName = ({
  errors,
  bundleVocabErrors,
  exerciseRelatedErrors,
  isErrorsInOtherPlaces,
  bundleId,
}: ResourceBundleNameProps) => (
  <BundleNameContainer>
    <BundleName>Resource Bundle</BundleName>
    {errors.length || bundleVocabErrors.length ? (
      <ValidationErrorDisplayer
        text={`${helpersService.getValidationErrorMessageText(errors)}${
          bundleVocabErrors.length
            ? `Vocabulary errors: ${helpersService.getValidationErrorMessageText(bundleVocabErrors)}`
            : ''
        }`}
      />
    ) : null}
    {exerciseRelatedErrors && isErrorsInOtherPlaces ? (
      <ErrorFromOtherPlacesDisplayer id={bundleId} exerciseRelatedErrors={exerciseRelatedErrors} />
    ) : null}
  </BundleNameContainer>
);
