import { SectionActions } from '@actions/SectionActions';
import { LoadingStage } from '@common/enums/LoadingStage';
import { ExerciseInterface } from '@common/interfaces/contentTypes/ExerciseInterface';
import { LoadedTipExerciseInterface } from '@common/interfaces/exercises/LoadedExerciseInterface';
import { CourseSliceInterface } from '@common/interfaces/slices/CourseSliceInterface';
import { PreviewModalActions } from '@actions/PreviewModalActions';
import { PayloadAction } from '@reduxjs/toolkit';
import { clone } from '@helpers/clone';

const SectionActionsCourseCourseReducers = {
  [SectionActions.SET_CURRENT_EXERCISE_AS_LOADING]: (
    state: CourseSliceInterface,
    _action: PayloadAction<any>,
  ): CourseSliceInterface => {
    let clonedState: CourseSliceInterface = clone(state);

    let loadedExercise: LoadedTipExerciseInterface = clonedState?.loadedExercise as LoadedTipExerciseInterface;

    loadedExercise.loaded = LoadingStage.loading;

    return {
      ...clonedState,
      loadedExercise,
      recapOptions: {
        loaded: LoadingStage.notLoaded,
        exercises: [],
      },
    };
  },
  [SectionActions.GET_EXERCISE_FOR_ACTIVITY_PREVIEW]: (
    state: CourseSliceInterface,
    _action: PayloadAction<any>,
  ): CourseSliceInterface => {
    let clonedCourseSlice: CourseSliceInterface = clone(state);

    return {
      ...clonedCourseSlice,
      preview: {
        ...clonedCourseSlice.preview,
        exerciseForPreviewLoaded: false,
      },
    };
  },
  [SectionActions.SET_EXERCISE_FOR_ACTIVITY_PREVIEW]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    let clonedCourseSlice: CourseSliceInterface = clone(state);

    return {
      ...clonedCourseSlice,
      preview: {
        exerciseForPreview: payload,
        exerciseForPreviewLoaded: true,
      },
    };
  },
  [PreviewModalActions.CLOSE_EXERCISE_PREVIEW]: (state: CourseSliceInterface): CourseSliceInterface => {
    return {
      ...state,
      preview: {
        ...state.preview,
        exerciseForPreview: null,
      },
    };
  },
  [SectionActions.GET_ALL_EXERCISES_OF_PARENT_ID]: (
    state: CourseSliceInterface,
    _action: PayloadAction<any>,
  ): CourseSliceInterface => {
    let clonedCourseSlice: CourseSliceInterface = clone(state);
    clonedCourseSlice.loadedExercises.loaded = LoadingStage.loading;

    return clonedCourseSlice;
  },
  [SectionActions.COURSE_EXERCISES_REQUEST_SUCCEEDED]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    let clonedCourseSlice: CourseSliceInterface = clone(state);

    return {
      ...clonedCourseSlice,
      loadedExercises: {
        loaded: LoadingStage.loaded,
        parentId: payload.parentId,
        exercises: payload.exercises.map((item: ExerciseInterface) => ({
          ...item,
          imageData: item.image,
        })) as ExerciseInterface[],
      },
    };
  },
  [SectionActions.GET_RECAP_OPTIONS_FOR_EXERCISE]: (
    state: CourseSliceInterface,
    _action: PayloadAction<any>,
  ): CourseSliceInterface => {
    let clonedCourseSlice: CourseSliceInterface = clone(state);

    return {
      ...clonedCourseSlice,
      recapOptions: {
        loaded: LoadingStage.loading,
        exercises: [],
      },
    };
  },
  [SectionActions.RECAP_OPTIONS_REQUEST_SUCCEEDED]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    let clonedCourseSlice: CourseSliceInterface = clone(state);

    return {
      ...clonedCourseSlice,
      recapOptions: {
        loaded: LoadingStage.loaded,
        exercises: payload.recapOptions,
      },
    };
  },
};

export default SectionActionsCourseCourseReducers;
