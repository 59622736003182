import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { VocabularyReviewActionsCreator } from '@actionCreators/VocabularyReviewActionsCreator';
import { layoutStyles } from '@features/app';
import type { LanguageV2 } from '@features/content/languages';
import { useAppDispatch } from '@redux/store';

import { Header } from '../Header';

export const LexicalItemContent = () => {
  const { languageId } = useParams<{ languageId: LanguageV2 }>();

  const dispatch = useAppDispatch();

  const { EditionComposerWrapper } = layoutStyles;

  useEffect(() => {
    dispatch(VocabularyReviewActionsCreator.setInitialLexicalItem(languageId));
  }, [dispatch, languageId]);

  return (
    <>
      <Header />
      <EditionComposerWrapper fullWidth>Lexical Item</EditionComposerWrapper>
    </>
  );
};
