import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useLayoutEffect } from 'react';
import { BaseEditorValueType } from '../../BaseEditor';

import { createSerializer } from '../../utils/serializer';

export const OnChangePlugin = ({ onChange }: { onChange: (editorValue: BaseEditorValueType) => void }) => {
  const [editor] = useLexicalComposerContext();

  useLayoutEffect(() => {
    const removeUpdateListener = editor.registerUpdateListener(({ editorState }) => {
      const value = {
        html: '',
        markdown: '',
      };
      const htmlSerializer = createSerializer('html', editor);
      const markdownSerializer = createSerializer('markdown', editor);

      editorState.read(() => {
        value.html = htmlSerializer.serialize();
        value.markdown = markdownSerializer.serialize();
      });

      onChange(value);
    });

    return () => {
      removeUpdateListener();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor]);

  return null;
};
