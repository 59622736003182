import { FormikContextType, useFormikContext } from 'formik';
import { ReactNode } from 'react';
import styled from 'styled-components/macro';

import { ExerciseCommonActionCreators as ExerciseCommonActions } from '@actionCreators/ExerciseCommonActionCreator';
import { SearchModalActionsCreator } from '@actionCreators/SearchModalActionsCreator';
import { TranslationTipActionsCreator } from '@actionCreators/TranslationTipActionsCreator';
import { ResourceBundleInterface } from '@common/interfaces/contentTypes/ResourceBundleInterface';
import { ValidationErrorInterface } from '@common/interfaces/validation/ValidationInterface';
import { useToast } from '@features/app/toast';
import { useIsEditAvailable } from '@features/content/courses';
import { FormikValuesInterface } from '@helpers/formikInitialValuesHelper';
import { courseSlice } from '@redux/slices/courseSlice';
import { selectIsIssuesShown } from '@selectors/UiSelectors';
import { selectLoadedExerciseData } from '@selectors/CoursesSelectors';
import { userTracking } from '@features/app/tracking';

import { ResourceData } from '../ResourceBundleModal';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { ResourceBundleName } from './ResourceBundleName';
import { ResourceBundleHeader } from './ResourceBundleHeader';

const BundleWrapper = styled.div`
  margin: 1.6rem 0 1.6rem 0;
  min-width: 44.2rem;
`;

const ResourceBundleContent = styled.div<{
  withErrors: boolean;
  withRedBackground: boolean;
}>`
  padding: 1.6rem;
  background: ${({ theme }) => theme.color.brandGreyscale500};
  border-radius: 0 0 1.6rem 1.6rem;
  ${({ theme, withErrors }) => withErrors && `border: 0.1rem solid ${theme.color.secondaryBase};`}
  ${({ theme, withRedBackground }) => withRedBackground && `background: ${theme.color.secondary3};`}
`;

type ResourceBundleProps = {
  children: ReactNode;
  bundle: ResourceBundleInterface;
  bundleName: string;
  errors?: ValidationErrorInterface[];
  withoutVocabularyBox?: boolean; // @TODO remove this and update related code once `hideAddToVocabularyToogle` feature flag is removed
  withRedBackground?: boolean;
  resources?: ResourceData[];
};

export const ResourceBundleWrapper = ({
  children,
  bundle,
  bundleName,
  errors = [],
  withRedBackground = false,
  withoutVocabularyBox = false,
  resources = [],
}: ResourceBundleProps) => {
  const dispatch = useAppDispatch();
  const { values, setFieldValue }: FormikContextType<FormikValuesInterface> = useFormikContext();

  const isIssuesShown = useAppSelector(selectIsIssuesShown);
  const loadedExercise = useAppSelector(selectLoadedExerciseData);

  const showToast = useToast();

  const bundleVocabErrors = loadedExercise.content?.[bundleName]?.validationStatus?.errors || [];

  const isBundleChangeBlocked = bundle?.mappings?.count && bundle.mappings.count > 1 && !bundle?.isReusingConfirmed;

  const { isEditAvailable } = useIsEditAvailable();

  const exerciseRelatedErrors = bundle?.validationStatus?.exerciseRelatedErrors || [];
  const isErrorsInOtherPlaces = !!exerciseRelatedErrors?.filter(
    (error) => error.exercise !== loadedExercise?.content?.id,
  ).length;

  const onVocabularyChange = () => {
    if (isEditAvailable) {
      if (isBundleChangeBlocked) {
        dispatch(
          TranslationTipActionsCreator.setCurrentContentId(
            bundle._id,
            'exercise',
            'any',
            undefined,
            undefined,
            bundleName,
            true,
          ),
        );
      } else {
        dispatch(ExerciseCommonActions.toggleVocabulary(bundleName));
      }
    }
  };

  const onSearchClick = () => {
    dispatch(
      SearchModalActionsCreator.showSearchV2Panel({
        bundleName: bundleName,
        contentType: 'bundle',
        forReusing: true,
        predefinedType: 'bundle',
      }),
    );
    userTracking.logosSearchOpened({
      source: 'resource_bundle',
    });
  };

  const onCopyIdClick = () => {
    showToast({
      type: 'info',
      title: 'Content ID copied to clipboard',
    });
  };

  const onRemoveClick = () => {
    dispatch(ExerciseCommonActions.removeBundle(bundleName));

    const newValues = (values as any)?.[`${bundleName}_phrase`]?.map((item: any) => {
      return {
        ...item,
        value: '',
      };
    });
    setFieldValue(`${bundleName}_phrase`, newValues);
    setFieldValue(`${bundleName}_example`, newValues);
  };

  const setCurrentBundle = () => {
    dispatch(courseSlice.actions.fetchResourceBundleSucceeded(bundle));
  };

  const withErrors = (!!errors.length || !!bundleVocabErrors.length) && isIssuesShown;

  return (
    <BundleWrapper>
      <ResourceBundleName
        errors={errors}
        bundleVocabErrors={bundleVocabErrors}
        exerciseRelatedErrors={exerciseRelatedErrors}
        isErrorsInOtherPlaces={isErrorsInOtherPlaces}
        bundleId={bundle._id}
      />

      <ResourceBundleHeader
        withoutVocabularyBox={withoutVocabularyBox}
        onVocabularyChange={onVocabularyChange}
        bundleName={bundleName}
        withErrors={withErrors}
        withRedBackground={withRedBackground}
        bundle={bundle}
        resources={resources}
        onSearchClick={onSearchClick}
        onRemoveClick={onRemoveClick}
        onCopyIdClick={onCopyIdClick}
        setCurrentBundle={setCurrentBundle}
      />

      <ResourceBundleContent withErrors={withErrors} withRedBackground={withRedBackground}>
        {children}
      </ResourceBundleContent>
    </BundleWrapper>
  );
};
