import styled from 'styled-components/macro';

import { ReactComponent as ExperimentIcon } from '../img/experiment.svg';

const CardPhraseWrapper = styled.div`
  align-items: center;
  display: flex;
  font-size: 1.6rem;
  max-width: 34rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StyledExperimentIcon = styled(ExperimentIcon)`
  fill: ${({ theme }) => theme.colorV2.textSecondary};
  margin-right: 0.8rem;
`;

export const CardExercisePhrase = ({ isInExperiment, phrase }: { isInExperiment: boolean; phrase: string }) => {
  return (
    <CardPhraseWrapper>
      {isInExperiment && <StyledExperimentIcon title="Experiment" />}
      {phrase}
    </CardPhraseWrapper>
  );
};
