import styled from 'styled-components';

import IDDisplayer from '@components/IDDisplayer/IDDisplayer';
import { DBId } from '@common/types/DBId';
import { EXERCISE_TITLES, ExerciseType } from '@common/enums/ExerciseTypes';

import { ReactComponent as ComprehensionIcon } from './img/comprehension.svg';
import { ReactComponent as ConversationIcon } from './img/conversation.svg';
import { ReactComponent as DialogueIcon } from './img/dialogue.svg';
import { ReactComponent as FillgapIcon } from './img/fillgap.svg';
import { ReactComponent as FlashcardIcon } from './img/flashcard.svg';
import { ReactComponent as HighlighterIcon } from './img/highlighter.svg';
import { ReactComponent as ListenRepeaticon } from './img/listenRepeat.svg';
import { ReactComponent as MatchupIcon } from './img/matchup.svg';
import { ReactComponent as MultipleChoiceIcon } from './img/multipleChoice.svg';
import { ReactComponent as PhraseBuilderIcon } from './img/phraseBuilder.svg';
import { ReactComponent as SlideshowIcon } from './img/slideshow.svg';
import { ReactComponent as SpeechRecognitionIcon } from './img/speechRecognition.svg';
import { ReactComponent as SpellingIcon } from './img/spelling.svg';
import { ReactComponent as TipIcon } from './img/tip.svg';
import { ReactComponent as TrueFalseIcon } from './img/trueFalse.svg';
import { ReactComponent as TypingIcon } from './img/typing.svg';

const EXERCISE_ICON: Record<ExerciseType, JSX.Element> = {
  comprehension: <ComprehensionIcon />,
  conversation: <ConversationIcon />,
  dialogue: <DialogueIcon />,
  fillgap: <FillgapIcon />,
  flashcard: <FlashcardIcon />,
  highlighter: <HighlighterIcon />,
  listenRepeat: <ListenRepeaticon />,
  matchup: <MatchupIcon />,
  multipleChoice: <MultipleChoiceIcon />,
  phraseBuilder: <PhraseBuilderIcon />,
  slidePdf: <SlideshowIcon />,
  slidePptx: <SlideshowIcon />,
  speechRecognition: <SpeechRecognitionIcon />,
  spelling: <SpellingIcon />,
  tip: <TipIcon />,
  trueFalse: <TrueFalseIcon />,
  typing: <TypingIcon />,
};

const TitleWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 0.6rem;

  svg {
    height: auto;
    width: 1.8rem;
  }
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 1.6rem;
  gap: 0.6rem;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  max-width: 20rem;
  white-space: nowrap;
`;

type CardExerciseTitleProps = {
  id: DBId;
  enabledMedia: string[];
  exerciseType: ExerciseType;
};

export const CardExerciseTitle = ({ id, enabledMedia, exerciseType }: CardExerciseTitleProps) => {
  const titleSuffix = enabledMedia?.length ? enabledMedia.join(', ') : '';
  const title = `${EXERCISE_TITLES[exerciseType]} ${titleSuffix}`;

  return (
    <div>
      <IDDisplayer id={id} />
      <TitleWrapper>
        {EXERCISE_ICON[exerciseType]}
        <Title>{title}</Title>
      </TitleWrapper>
    </div>
  );
};
