import { GrammarReviewActions } from '@actions/GrammarReviewActions';
import { type ContentTypesType } from '@common/enums/ContentTypes';
import type {
  GrammarCategoryEditableFieldNames,
  GrammarCategoryFormikValues,
  GrammarCategoryListItemType,
  GrammarTopicEditableFieldNames,
  GrammarTopicFormikValues,
  GrammarTopicListItemType,
} from '@features/content/grammar';
import type { DBId } from '@common/types/DBId';
import type { GrammarReviewSliceType } from '@common/interfaces/slices/GrammarReviewSliceType';
import type { LocalizationInterface } from '@common/interfaces/localization/LocalizationInterface';
import type { TranslationsPanelContentInterface } from '@common/interfaces/exercises/TranslationsPanelContentInterface';
import type { ExerciseType } from '@common/enums/ExerciseTypes';
import type { ExerciseListItemType } from '@components/ContentTypes/ExerciseCard/types';
import type { NavigationItemType } from '@features/content/navigation';

export const GrammarReviewActionCreators = {
  removeString: (contentType: ContentTypesType, fieldName: GrammarCategoryEditableFieldNames) => ({
    type: GrammarReviewActions.REMOVE_STRING,
    payload: { contentType, fieldName },
  }),
  makeStringCopy: (
    contentType: ContentTypesType,
    fieldName: GrammarCategoryEditableFieldNames | GrammarTopicEditableFieldNames,
  ) => ({
    type: GrammarReviewActions.MAKE_COPY,
    payload: { contentType, fieldName },
  }),
  setContentId: (payload: {
    contentType: ContentTypesType;
    fieldName: GrammarCategoryEditableFieldNames | GrammarTopicEditableFieldNames;
    contentId: DBId;
  }) => ({
    type: GrammarReviewActions.SET_CONTENT_ID,
    payload,
  }),
  saveCategory: (
    courseId: DBId,
    categoryId: DBId,
    values: GrammarCategoryFormikValues,
    grammarReviewSlice: Extract<GrammarReviewSliceType, 'grammarReview' | 'grammarCategory' | 'grammarTopic'>,
  ) => ({
    type: GrammarReviewActions.SAVE_CATEGORY,
    payload: { courseId, categoryId, values, grammarReviewSlice },
  }),
  saveTopic: (
    courseId: DBId,
    categoryId: DBId,
    topicId: DBId,
    values: GrammarTopicFormikValues,
    grammarReviewSlice: Extract<GrammarReviewSliceType, 'grammarReview' | 'grammarCategory' | 'grammarTopic'>,
  ) => ({
    type: GrammarReviewActions.SAVE_TOPIC,
    payload: { courseId, categoryId, topicId, values, grammarReviewSlice },
  }),
  uploadAudio: (
    localization: LocalizationInterface | undefined,
    uploadedSound: File | null,
    progressHandler?: Function,
  ) => ({
    type: GrammarReviewActions.UPLOAD_AUDIO,
    payload: {
      localization,
      uploadedSound,
      progressHandler,
    },
  }),
  removeAudio: (
    localization: LocalizationInterface | undefined,
    fieldName: GrammarCategoryEditableFieldNames,
    type: ContentTypesType,
  ) => ({
    type: GrammarReviewActions.REMOVE_AUDIO,
    payload: { localization, fieldName, type },
  }),
  setInitialGrammarExercise: () => ({ type: GrammarReviewActions.SET_INITIAL_GRAMMAR_EXERCISE }),
  removeCategory: (courseId: DBId, categoryId: DBId) => ({
    type: GrammarReviewActions.REMOVE_CATEGORY,
    payload: { courseId, categoryId },
  }),
  removeTopic: (courseId: DBId, categoryId: DBId, topicId: DBId) => ({
    type: GrammarReviewActions.REMOVE_TOPIC,
    payload: { courseId, categoryId, topicId },
  }),
  removeExercise: (courseId: DBId, topicId: DBId, exerciseId: DBId, exerciseType: string) => ({
    type: GrammarReviewActions.REMOVE_EXERCISE,
    payload: { courseId, topicId, exerciseId, exerciseType },
  }),
  getGrammarCategory: (courseId: DBId, categoryId: DBId) => ({
    type: GrammarReviewActions.GET_GRAMMAR_CATEGORY,
    payload: { courseId, categoryId },
  }),
  setInitialGrammarCategory: () => ({ type: GrammarReviewActions.SET_INITIAL_GRAMMAR_CATEGORY }),
  getGrammarTopics: (courseId: DBId, categoryId: DBId) => ({
    type: GrammarReviewActions.GET_GRAMMAR_TOPICS,
    payload: { courseId, categoryId },
  }),
  createTopic: (courseId: DBId, categoryId: DBId, position: number) => ({
    type: GrammarReviewActions.CREATE_GRAMMAR_TOPIC,
    payload: {
      courseId,
      categoryId,
      position,
    },
  }),
  setTopicsOrder: (
    orderedItems: GrammarTopicListItemType[],
    sourceIndex: number,
    destinationIndex: number,
    parentType?: ContentTypesType,
    parentId?: DBId,
    courseId?: DBId,
  ) => ({
    type: GrammarReviewActions.UPDATE_TOPICS_ORDER,
    payload: {
      orderedItems,
      sourceIndex,
      destinationIndex,
      parentType,
      parentId,
      courseId,
    },
  }),
  setGrammarCategoryTitle: (content: TranslationsPanelContentInterface) => ({
    type: GrammarReviewActions.SET_GRAMMAR_CATEGORY_TITLE,
    payload: content,
  }),
  setGrammarCategoryIcon: (selectedIcon: { svg: string; pdf: string }) => ({
    type: GrammarReviewActions.SET_GRAMMAR_CATEGORY_ICON,
    payload: selectedIcon,
  }),
  getGrammarReview: (courseId: DBId) => ({ type: GrammarReviewActions.GET_GRAMMAR_REVIEW, payload: { courseId } }),
  getGrammarCategories: (courseId: DBId, reviewId: DBId) => ({
    type: GrammarReviewActions.GET_GRAMMAR_CATEGORIES,
    payload: { courseId, reviewId },
  }),
  setInitialGrammarReview: () => ({ type: GrammarReviewActions.SET_INITIAL_GRAMMAR_REVIEW }),
  createCategory: (courseId: DBId, position: number) => ({
    type: GrammarReviewActions.CREATE_GRAMMAR_CATEGORY,
    payload: {
      courseId,
      position,
    },
  }),
  setCategoriesOrder: (
    orderedItems: GrammarCategoryListItemType[],
    sourceIndex: number,
    destinationIndex: number,
    parentType?: ContentTypesType,
    parentId?: DBId,
    courseId?: DBId,
  ) => ({
    type: GrammarReviewActions.UPDATE_CATEGORIES_ORDER,
    payload: {
      orderedItems,
      sourceIndex,
      destinationIndex,
      parentType,
      parentId,
      courseId,
    },
  }),
  getGrammarTopic: (courseId: DBId, topicId: DBId) => ({
    type: GrammarReviewActions.GET_GRAMMAR_TOPIC,
    payload: { courseId, topicId },
  }),
  getGrammarExercises: (courseId: DBId, topicId: DBId) => ({
    type: GrammarReviewActions.GET_GRAMMAR_EXERCISES,
    payload: { courseId, topicId },
  }),
  createReferenceExercise: (courseId: DBId, topicId: DBId, position: number, numExercisesToCreate: number) => ({
    type: GrammarReviewActions.CREATE_REFERENCE_EXERCISE,
    payload: {
      courseId,
      topicId,
      position,
      numExercisesToCreate,
    },
  }),
  createGrammarExercise: (
    courseId: DBId,
    topicId: DBId,
    exerciseType: ExerciseType,
    position: number,
    numExercisesToCreate: number,
  ) => ({
    type: GrammarReviewActions.CREATE_GRAMMAR_EXERCISE,
    payload: {
      courseId,
      topicId,
      exerciseType,
      position,
      numExercisesToCreate,
    },
  }),
  setReferenceExercisesOrder: (
    orderedItems: ExerciseListItemType[],
    sourceIndex: number,
    destinationIndex: number,
    parentType?: ContentTypesType,
    parentId?: DBId,
    courseId?: DBId,
  ) => ({
    type: GrammarReviewActions.UPDATE_REFERENCE_EXERCISES_ORDER,
    payload: {
      orderedItems,
      sourceIndex,
      destinationIndex,
      parentType,
      parentId,
      courseId,
    },
  }),
  setGrammarExercisesOrder: (
    orderedItems: ExerciseListItemType[],
    sourceIndex: number,
    destinationIndex: number,
    parentType?: ContentTypesType,
    parentId?: DBId,
    courseId?: DBId,
  ) => ({
    type: GrammarReviewActions.UPDATE_GRAMMAR_EXERCISES_ORDER,
    payload: {
      orderedItems,
      sourceIndex,
      destinationIndex,
      parentType,
      parentId,
      courseId,
    },
  }),
  updateCefr: (courseId: DBId, topicId: DBId, cefr: string) => ({
    type: GrammarReviewActions.UPDATE_CEFR,
    payload: {
      courseId,
      topicId,
      cefr,
    },
  }),
  reuseExercise: (
    courseId: DBId,
    topicId: DBId,
    exerciseId: DBId,
    exerciseType: ExerciseType | undefined,
    position: number,
    exerciseTopicType: 'reference' | 'grammar',
  ) => ({
    type: GrammarReviewActions.REUSE_EXERCISE,
    payload: {
      courseId,
      topicId,
      exerciseId,
      exerciseType,
      position,
      exerciseTopicType,
    },
  }),
  copyExercise: (
    courseId: DBId,
    topicId: DBId,
    exerciseId: DBId,
    exerciseType: ExerciseType | undefined,
    position: number,
    exerciseTopicType: 'reference' | 'grammar',
  ) => ({
    type: GrammarReviewActions.COPY_EXERCISE,
    payload: {
      courseId,
      topicId,
      exerciseId,
      exerciseType,
      position,
      exerciseTopicType,
    },
  }),
  attachLessonsToTopic: (
    courseId: DBId,
    topicId: DBId,
    lessonIds: DBId[],
    lessonsToAttach: DBId[],
    lessonsToDetach: DBId[],
  ) => ({
    type: GrammarReviewActions.ATTACH_LESSONS_TO_TOPIC,
    payload: {
      courseId,
      topicId,
      lessonIds,
      lessonsToAttach,
      lessonsToDetach,
    },
  }),
  setInitialGrammarTopic: () => ({ type: GrammarReviewActions.SET_INITIAL_GRAMMAR_TOPIC }),
  updateNavigation: (newNavigation: NavigationItemType[]) => ({
    type: GrammarReviewActions.UPDATE_NAVIGATION,
    payload: newNavigation,
  }),
  setLoadingParentId: (id: DBId) => ({
    type: GrammarReviewActions.SET_LOADING_PARENT_ID,
    payload: id,
  }),
  getAllNavigation: (courseId: DBId, categoryId: DBId, topicId: DBId) => ({
    type: GrammarReviewActions.GET_ALL_NAVIGATION,
    payload: { courseId, categoryId, topicId },
  }),
  openTranslationsPanel: (
    type: ContentTypesType,
    preopenedTab: 'translations' | 'audios' | 'other',
    visitedId: DBId | undefined,
    fieldName: GrammarCategoryEditableFieldNames,
    isEditBlocked?: boolean,
    isEditDisabled?: boolean,
    defaultContextForTranslators?: string,
  ) => ({
    type: GrammarReviewActions.OPEN_TRANSLATIONS_PANEL,
    payload: {
      type,
      preopenedTab,
      visitedId,
      fieldName,
      isEditBlocked,
      isEditDisabled,
      defaultContextForTranslators,
    },
  }),
  closeTranslationsPanel: () => ({
    type: GrammarReviewActions.CLOSE_TRANSLATIONS_PANEL,
  }),
};
