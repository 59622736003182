import { ReactNode, useMemo, useState } from 'react';
import { components, MultiValue, MultiValueGenericProps, OptionProps } from 'react-select';
import styled from 'styled-components/macro';

import { useUser } from '@features/auth';
import { Checkbox } from '@features/theme';
import { CommonFilterMultiSelector, CommonFilterOptionType } from '@components/SelectorCommonComponents';

import { getRequesters } from '../../../_service';

const OptionContainer = styled.div`
  display: flex;
`;

const RequesterNameContainer = styled.div`
  display: inline-block;
  max-width: 11rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const CLASSNAME_PREFIX = 'requester-selector';

const RequesterCustomOption = (props: OptionProps<CommonFilterOptionType>) => {
  const {
    data: { label, value },
    isSelected,
  } = props;

  return (
    <components.Option {...props}>
      <OptionContainer key={value}>
        <Checkbox checked={isSelected} onToggle={() => null} />
        {label}
      </OptionContainer>
    </components.Option>
  );
};

type RequestersValueContainerProps = MultiValueGenericProps<MultiValue<CommonFilterOptionType>> & {
  title: string;
  value: MultiValue<CommonFilterOptionType> | null;
};

const RequestersValueContainer = ({ children, title, value, ...props }: RequestersValueContainerProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let [_, input] = children as Array<ReactNode>;

  return (
    <components.MultiValueContainer {...props}>
      <RequesterNameContainer title={title}>
        {value && value.length > 1 ? `${value.length} selected` : `${value?.[0].label}`}
      </RequesterNameContainer>
      {input}
    </components.MultiValueContainer>
  );
};

export const RequesterFilter = ({
  selectedRequesters,
  setSelectedRequesters,
}: {
  selectedRequesters: MultiValue<CommonFilterOptionType> | null;
  setSelectedRequesters: (requesters: MultiValue<CommonFilterOptionType> | null) => void;
}) => {
  const { user } = useUser();
  const [requesters, setRequesters] = useState<CommonFilterOptionType[]>([]);
  const title = useMemo(
    () => (selectedRequesters?.length ? selectedRequesters.map((requester) => requester.label).join('\n') : ''),
    [selectedRequesters],
  );

  return (
    <CommonFilterMultiSelector
      classNamePrefix={CLASSNAME_PREFIX}
      closeMenuOnSelect={false}
      options={requesters}
      optionContainer={(props: OptionProps<CommonFilterOptionType>) => <RequesterCustomOption {...props} />}
      placeholder="Requesters"
      rule="Requesters"
      value={selectedRequesters}
      valueContainer={(props: MultiValueGenericProps<MultiValue<CommonFilterOptionType>>) => (
        <RequestersValueContainer title={title} value={selectedRequesters} {...props} />
      )}
      onChange={(requesters) => setSelectedRequesters(requesters)}
      onFocus={async () => {
        if (!requesters.length && user) {
          const requestersResult = await getRequesters();
          setRequesters([
            {
              value: user.id,
              label: 'Me',
            },
            ...requestersResult.data.users.map((requester) => ({
              value: requester.id,
              label: requester.fullName,
            })),
          ]);
        }
      }}
    />
  );
};
