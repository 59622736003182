import { FunctionComponent, useMemo } from 'react';
import ReactPlayer from 'react-player';
import { useFormikContext } from 'formik';

import { FormikValuesInterface } from '@helpers/formikInitialValuesHelper';
import { VocabularyMark, ImageContainer } from './common';
import { ComprehensionContentProps } from '../../PreviewModalProps';
import playerControllers from './img/player.svg';
import { getValueByLanguage } from '../../../../localization-utils';

import './ComprehensionContent.scss';

export const ComprehensionContent: FunctionComponent<ComprehensionContentProps> = ({
  content,
  deviceType,
  courseLearningLanguage,
  isForList,
}: ComprehensionContentProps) => {
  const { values }: { values: FormikValuesInterface } = useFormikContext();
  const mainImage = useMemo(
    () => getValueByLanguage(content.mainBundle?.image?.imageLocalizations),
    [content.mainBundle],
  );

  const isTitleOnCourseLang = useMemo(
    () => !content.titleLanguage || content.titleLanguage === 'learning',
    [content.titleLanguage],
  );
  const titleOnEnLang = useMemo(
    () => getValueByLanguage(isForList ? content.title?.textLocalizations : values.title),
    [values, isForList, content.title],
  );
  const titleOnCourseLang = useMemo(
    () => getValueByLanguage(isForList ? content.title?.textLocalizations : values.title, courseLearningLanguage),
    [values, courseLearningLanguage, isForList, content.title],
  );

  const isContentOnCourseLang = useMemo(
    () => !content.mainContentLanguage || content.mainContentLanguage === 'learning',
    [content.mainContentLanguage],
  );
  const contentOnEnLang = useMemo(
    () => getValueByLanguage(isForList ? content.mainBundle?.phrase?.textLocalizations : values.mainBundle_phrase),
    [values, isForList, content.mainBundle],
  );
  const contentOnCourseLang = useMemo(
    () =>
      getValueByLanguage(
        isForList ? content.mainBundle?.phrase?.textLocalizations : values.mainBundle_phrase,
        courseLearningLanguage,
      ),
    [values, courseLearningLanguage, isForList, content.mainBundle],
  );

  return (
    <>
      {content.mainVideoEnabled || content.mainTextEnabled ? (
        <>
          {mainImage ? (
            <ImageContainer
              deviceType={deviceType}
              isImageEnabled={!!mainImage}
              isAudioEnabled={
                content.mainVideoEnabled && !!getValueByLanguage(content.mainBundle?.video?.videoLocalizations)
              }
              imageSrc={mainImage}
              isVocabulary={content.mainBundle?.isVocabulary}
            />
          ) : (
            <div className="comprehension-video">
              <ReactPlayer
                url={getValueByLanguage(content.mainBundle?.video?.videoLocalizations)}
                controls={false}
                width={'initial'}
              />
              <VocabularyMark isVocabulary={content.mainBundle?.isVocabulary} />
              <img src={playerControllers} className="image-container__player" alt="Audio" />
            </div>
          )}
        </>
      ) : null}
      <span className="comprehension-title">
        {isTitleOnCourseLang
          ? titleOnCourseLang
          : // Need to know which language to use, when not 'learning' selected
            titleOnEnLang}
      </span>
      <span className="comprehension-provider">{values.provider}</span>
      <span
        className="comprehension-text"
        dangerouslySetInnerHTML={{
          __html:
            (isContentOnCourseLang
              ? contentOnCourseLang
              : // Need to know which language to use, when not 'learning' selected
                contentOnEnLang) || '',
        }}
      />
    </>
  );
};
