import { useHistory, useParams } from 'react-router-dom';
import { DBId } from '@common/types/DBId';
import BreadcrumbNavigation from '@components/BreadcrumbNavigation/BreadcrumbNavigation';
import { allTypesMap } from '@helpers/contentCardHelper';
import { useAppSelector } from '@redux/store';
import { selectGrammarExerciseContent, selectGrammarNavigationStructure } from '@selectors/GrammarSelectors';

export const Breadcrumbs = () => {
  const history = useHistory();
  const { courseId, categoryId, topicId } = useParams<{ courseId: DBId; categoryId: DBId; topicId: DBId }>();
  const grammarExercise = useAppSelector(selectGrammarExerciseContent);

  const navigation = useAppSelector(selectGrammarNavigationStructure);

  return (
    <BreadcrumbNavigation
      breadcrumbItems={[
        <span
          className="breadcrumb-navigation__node breadcrumb-navigation__node_link"
          onClick={() => history.push(`/course/${courseId}/grammar-review`)}
        >
          Grammar Review
        </span>,
        <span
          className="breadcrumb-navigation__node breadcrumb-navigation__node_link"
          onClick={() => history.push(`/course/${courseId}/grammar-review/category/${categoryId}`)}
        >
          {navigation.find((element) => element.id === categoryId)?.title || 'Grammar Category'}
        </span>,
        <span
          className="breadcrumb-navigation__node breadcrumb-navigation__node_link"
          onClick={() => history.push(`/course/${courseId}/grammar-review/category/${categoryId}/topic/${topicId}`)}
        >
          {navigation.find((element) => element.id === topicId)?.title || 'Grammar Topic'}
        </span>,
        <span>{allTypesMap[grammarExercise.type]}</span>,
      ]}
    />
  );
};
