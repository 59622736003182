import * as Sentry from '@sentry/react';
import { ReactNode, createContext, useContext, useMemo, useState } from 'react';

import { AvailableSearchType, SearchErrorType } from '../types';

type SearchContextType = {
  query: string | null;
  searchErrorMessage: string | null;
  searchLoading: boolean;
  searchLoaded: boolean;
  searchType: AvailableSearchType | null;
  search: <T>(searchCallback: () => Promise<T>) => Promise<T>;
  setQuery: (query: string | null) => void;
  setSearchErrorMessage: (errorMessage: string) => void;
  setSearchLoading: (loading: boolean) => void;
  setSearchLoaded: (loaded: boolean) => void;
  setSearchType: (searchType: AvailableSearchType | null) => void;
};

const SearchContext = createContext<SearchContextType>({
  query: null,
  searchErrorMessage: null,
  searchLoading: false,
  searchLoaded: false,
  searchType: null,
  search: async (searchCallback) => searchCallback(),
  setQuery: () => {},
  setSearchErrorMessage: () => {},
  setSearchLoading: () => {},
  setSearchLoaded: () => {},
  setSearchType: () => {},
});

export const SearchProvider = ({ children }: { children: ReactNode }) => {
  const [query, setQuery] = useState<string | null>(null);
  const [searchErrorMessage, setSearchErrorMessage] = useState<string | null>(null);
  const [searchLoaded, setSearchLoaded] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchType, setSearchType] = useState<AvailableSearchType | null>(null);

  const onSearchSuccess = () => {
    setSearchLoading(false);
    setSearchLoaded(true);
    setSearchErrorMessage('');
  };

  const onSearchError = (error: SearchErrorType) => {
    setSearchLoaded(true);
    setSearchLoading(false);

    if (error.response?.data) {
      const { detail, message } = error.response.data;
      const errorMessage = detail || message ? detail ?? message : 'Unknown error';

      setSearchErrorMessage(errorMessage);
    } else {
      Sentry.captureException(error, (scope) => {
        scope.setTag('logosSection', 'Search');
        scope.setExtras({
          query,
          searchType,
        });

        return scope;
      });
    }
  };

  const search = async <T,>(searchCallback: () => Promise<T>) => {
    setSearchLoading(true);

    try {
      const results = await searchCallback();

      onSearchSuccess();

      return results;
    } catch (e: unknown) {
      onSearchError(e as SearchErrorType);

      throw e;
    }
  };

  const value = useMemo(() => {
    return {
      query,
      searchErrorMessage,
      searchLoaded,
      searchLoading,
      searchType,
      setQuery,
      setSearchErrorMessage,
      setSearchLoaded,
      setSearchLoading,
      setSearchType,
      onSearchError,
      search,
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, searchErrorMessage, searchLoaded, searchLoading, searchType]);

  return <SearchContext.Provider value={value}>{children}</SearchContext.Provider>;
};

export const useSearch = () => useContext(SearchContext);
