import { takeLatest } from 'redux-saga/effects';

import { DialogueExerciseActions } from '@actions/DialogueExerciseActions';
import ExerciseSagas from '@sagas/exercises/definitions/ExerciseSagas';
import DialogueCharactersPanelSagas from '@sagas/exercises/definitions/DialogueCharactersPanelSagasDefinition';
import DialogueExerciseSagas from '@sagas/exercises/definitions/DialogueExerciseSagasDefinition';
import type { AppDispatch } from '@redux/store';

export function* DialogueExerciseRelatedSagas(dispatch: AppDispatch) {
  yield takeLatest(
    DialogueExerciseActions.SHOW_DIALOGUE_CHARACTERS_SELECTOR,
    DialogueCharactersPanelSagas.loadCharacters,
  );
  yield takeLatest(
    DialogueExerciseActions.SHOW_DIALOGUE_CHARACTERS_SELECTOR_TO_UPDATE_PHOTO,
    DialogueCharactersPanelSagas.showDialogueCharactersSelectorToUpdatePhoto,
  );
  yield takeLatest(DialogueExerciseActions.SAVE, DialogueExerciseSagas.save, dispatch);
  yield takeLatest(DialogueExerciseActions.REMOVE_SOUND_FROM_DIALOGUE_LINE, ExerciseSagas.removeAudioFromExercise);
}
