import { useEffect, useState } from 'react';
import styled from 'styled-components';

import navigateIcon from '@components/ComponentOtherPlacesDisplayer/OtherPlacesDisplayer/img/navigate.svg';
import { GrammarReviewService } from '@services/GrammarReviewService';
import { LinkedLessonsModal } from './LinkedLessonsModal/LinkedLessonsModal';
import { AvailableLessonsForTopic } from './types';
import connectionIcon from './img/connection.svg';
import { useAppSelector } from '@redux/store';
import { selectCourse } from '@selectors/CoursesSelectors';
import { selectGrammarTopicContent } from '@selectors/GrammarSelectors';

const LinkedLessonsModalOpenButton = styled.div`
  display: flex;
  background: ${({ theme }) => theme.colorV2.grammarTopicLinkedLessonsButtonBackground};
  width: fit-content;
  height: 22px;
  margin: 0 0 8px 4px;
  font-weight: 700;
  font-size: 10px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colorV2.grammarTopicLinkedLessonsButtonText};
  padding: 4px 8px;
  border-radius: 16px;

  &:hover {
    cursor: pointer;
    opacity: 0.9;
  }
`;

const ConnectionImage = styled.img`
  width: 12px;
`;

const ButtonText = styled.span`
  margin: 0 5px;
`;

export const LinkedLessonsButton = () => {
  const [opened, setOpened] = useState(false);
  const currentCourse = useAppSelector(selectCourse);
  const grammarTopicContent = useAppSelector(selectGrammarTopicContent);
  const [availableLessons, setAvailableLessons] = useState<AvailableLessonsForTopic[]>([]);
  const [isAvailableLessonsLoaded, setIsAvailableLessonsLoaded] = useState(false);

  useEffect(() => {
    if (!isAvailableLessonsLoaded && opened) {
      setTimeout(
        () =>
          GrammarReviewService.getGrammarLessons(currentCourse.id).then((result) => {
            setIsAvailableLessonsLoaded(true);
            const levelsWithLessons = result.data.filter(
              (levelObject: AvailableLessonsForTopic) => !!levelObject.lessons.length,
            );
            setAvailableLessons(levelsWithLessons);
          }),
        500,
      );
    }
  }, [isAvailableLessonsLoaded, currentCourse, opened]);

  return (
    <>
      <LinkedLessonsModalOpenButton data-testid="linked-lessons-button" onClick={() => setOpened(true)}>
        <ConnectionImage src={connectionIcon} alt="Connection" />
        <ButtonText>{grammarTopicContent.attachedLessons.length || 0} linked lessons</ButtonText>
        <img src={navigateIcon} alt="Navigate" />
      </LinkedLessonsModalOpenButton>
      <LinkedLessonsModal
        opened={opened}
        onClose={() => setOpened(false)}
        selectedLessons={grammarTopicContent.attachedLessons}
        isAvailableLessonsLoaded={isAvailableLessonsLoaded}
        saveProgress={grammarTopicContent.attachedLessonsSaveProgress}
        availableLessons={availableLessons}
      />
    </>
  );
};
