import styled from 'styled-components/macro';
import { ReactNode } from 'react';

import { GenderType } from '../types';
import { ReactComponent as MaleIcon } from './img/male.svg';
import { ReactComponent as FemaleIcon } from './img/female.svg';
import { ReactComponent as NonBonaryIcon } from './img/nonBinary.svg';
import { ReactComponent as AudioIcon } from './img/audio.svg';

const ICON_PER_GENDER: Record<GenderType, ReactNode> = {
  male: <MaleIcon />,
  female: <FemaleIcon />,
  'non-binary': <NonBonaryIcon />,
};

const GenderContainer = styled.div`
  width: 2rem;
  height: 2rem;
`;
const AudioCountContainer = styled.div`
  display: flex;
  gap: 0.6rem;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colorV2.textTertiary};
`;

const VoiceArtistOption = styled.div<{ withAudioRequestsCount: boolean }>`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.8rem;
  ${({ withAudioRequestsCount }) => withAudioRequestsCount && 'justify-content: space-between;'}
`;
const VoiceArtistDetails = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
`;
const NameContainer = styled.div`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
  color: ${({ theme }) => theme.colorV2.textPrimary};
`;
const AccentContainer = styled.span`
  color: ${({ theme }) => theme.colorV2.textTertiary};
`;

export const VoiceArtistOptionContainer = ({
  voiceArtist,
  isSelected,
}: {
  voiceArtist: {
    value: string;
    label: string;
    gender: GenderType | null;
    audioRequestsCount: number;
    accent: string | null;
  };
  isSelected: boolean;
}) => (
  <VoiceArtistOption withAudioRequestsCount={!!voiceArtist.audioRequestsCount}>
    {voiceArtist.gender && <GenderContainer>{ICON_PER_GENDER[voiceArtist.gender]}</GenderContainer>}
    <VoiceArtistDetails>
      <NameContainer>{voiceArtist.label}</NameContainer>
      {voiceArtist.accent && <AccentContainer>{voiceArtist.accent}</AccentContainer>}
    </VoiceArtistDetails>
    {voiceArtist.audioRequestsCount && !isSelected ? (
      <AudioCountContainer>
        <AudioIcon />
        {voiceArtist.audioRequestsCount}
      </AudioCountContainer>
    ) : null}
  </VoiceArtistOption>
);
