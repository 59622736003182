import styled from 'styled-components/macro';

import { ContextualMenu } from '@features/theme';

import { ReactComponent as ContextualMenuIcon } from './_img/context-menu-trigger.svg';

const RecentCoursesTitleText = styled.span`
  margin-right: 0.5rem;
`;

const RecentCoursesTitleWrapper = styled.div`
  align-items: center;
  display: flex;
  font-size: 2.4rem;
  margin-bottom: 1.6rem;
`;

const RecentCoursesRemoveModeOffButton = styled.button`
  background: ${({ theme }) => theme.colorV2.accentPrimary};
  border: none;
  border-radius: 3.2rem;
  color: ${({ theme }) => theme.colorV2.uiLightest};
  font-size: 1.2rem;
  font-weight: 700;
  padding: 0.55rem 0.8rem;
  width: 7.6rem;
`;

const StyledContextualMenuIcon = styled(ContextualMenuIcon)`
  transform: scale(1.1);
  transform-origin: left;
  margin-right: 0.8rem;
`;

const RecentCoursesRemoveButton = ({ setIsDeleteModeOn }: { setIsDeleteModeOn: Function }) => (
  <ContextualMenu triggerIcon={<StyledContextualMenuIcon />} variant="small" withTrigger>
    {[
      <div onClick={() => setIsDeleteModeOn(true)} key={0}>
        Remove from Recent access
      </div>,
    ]}
  </ContextualMenu>
);

export const RecentCoursesTitle = ({
  isDeleteModeOn,
  setIsDeleteModeOn,
}: {
  isDeleteModeOn: boolean;
  setIsDeleteModeOn: Function;
}) => (
  <RecentCoursesTitleWrapper>
    <RecentCoursesTitleText>Recent access</RecentCoursesTitleText>
    {isDeleteModeOn ? (
      <RecentCoursesRemoveModeOffButton onClick={() => setIsDeleteModeOn(false)}>Done</RecentCoursesRemoveModeOffButton>
    ) : (
      <RecentCoursesRemoveButton setIsDeleteModeOn={setIsDeleteModeOn} />
    )}
  </RecentCoursesTitleWrapper>
);
