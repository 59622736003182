import { UISliceInterface } from '@common/interfaces/slices/UISliceInterface';
import { PreviewModalActions } from '@actions/PreviewModalActions';

const ExercisePreviewUIReducers = {
  [PreviewModalActions.OPEN_EXERCISE_PREVIEW]: (state: UISliceInterface): UISliceInterface => {
    return {
      ...state,
      isPreviewOpened: true,
    };
  },
  [PreviewModalActions.CLOSE_EXERCISE_PREVIEW]: (state: UISliceInterface): UISliceInterface => {
    return {
      ...state,
      isPreviewOpened: false,
      isPreviewForExercisesListOpened: false,
    };
  },
  [PreviewModalActions.OPEN_EXERCISES_LIST_PREVIEW]: (state: UISliceInterface): UISliceInterface => {
    return {
      ...state,
      isPreviewForExercisesListOpened: true,
    };
  },
};

export default ExercisePreviewUIReducers;
