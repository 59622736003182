import { LexicalNode, NodeKey, SerializedTextNode, Spread, TextNode } from 'lexical';

export const $createMentionNode = (userName: string, userId: string): MentionNode => {
  const node = new MentionNode(userName, userId);

  node.toggleDirectionless().setMode('token').setFormat('highlight');

  return node;
};

export const $isMentionNode = (node: LexicalNode | null | undefined): node is MentionNode =>
  node instanceof MentionNode;

type SerializedMentionNode = Spread<
  {
    name: string;
    id: string;
    type: 'mention';
  },
  SerializedTextNode
>;

export class MentionNode extends TextNode {
  __name: string;
  __id: string;

  constructor(name: string, id: string, key?: NodeKey) {
    super(`@${name}`, key);
    this.__name = name;
    this.__id = id;
  }

  static getType(): string {
    return 'mention';
  }

  static clone(node: MentionNode): MentionNode {
    return new MentionNode(node.__name, node.__id, node.__key);
  }

  getName(): string {
    const self = this.getLatest();

    return self.__name;
  }

  getId(): string {
    const self = this.getLatest();

    return self.__id;
  }

  static importJSON(serializedNode: SerializedMentionNode): MentionNode {
    const node = $createMentionNode(serializedNode.name, serializedNode.id);

    node.setTextContent(serializedNode.text);
    node.setFormat(serializedNode.format);
    node.setDetail(serializedNode.detail);
    node.setMode(serializedNode.mode);
    node.setStyle(serializedNode.style);

    return node;
  }

  exportJSON(): SerializedMentionNode {
    return {
      ...super.exportJSON(),
      type: 'mention',
      name: this.__name,
      id: this.__id,
    };
  }

  createDOM(): HTMLElement {
    const dom = document.createElement('mark');
    dom.className = 'base-editor-mention';
    dom.innerText = `@${this.getName()}`;

    return dom;
  }

  updateDOM(prevNode: MentionNode, dom: HTMLElement): boolean {
    return !dom.innerText.endsWith(this.getName());
  }
}
