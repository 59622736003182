import { DBId } from '@common/types/DBId';
import { apiClient } from '@features/api';

import { LessonSearchResultDetailsType } from '../types';

export const getLessonResultDetails = async (lessonId: DBId): Promise<{ data: LessonSearchResultDetailsType }> => {
  const response = await apiClient.noErrorsV2.get(`/components/${lessonId}/mappings`);

  return {
    data: {
      mappings: response.data,
    },
  };
};
