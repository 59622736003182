import styled, { css, keyframes } from 'styled-components/macro';

import { ReactComponent as DeleteIcon } from './_img/delete.svg';

export const favouriteSearchItemIconStyles = css`
  cursor: pointer;
  height: 2.2rem !important;
  filter: brightness(0.4) grayscale(100%);
  width: 2.2rem !important;
`;

export const FavouriteSearchesList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.8rem;
`;

export const FavouriteSearchItemWrapper = styled.div<{ $busy: boolean }>`
  align-items: center;
  display: flex;
  gap: 0.8rem;
  height: 4.8rem;
  justify-content: space-between;

  ${({ $busy }) =>
    $busy &&
    css`
      cursor: not-allowed;
      opacity: 0.5;
      pointer-events: none;
    `}
`;

export const FavouriteSearchItemContainer = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    border-radius: 2.4rem;
    border: solid 0.1rem ${theme.colorV2.buttonTertiaryBaseBorder};
    padding: 1rem 2.4rem;
    transition: width 300ms;
    max-width: 100%;
  `}
`;

export const FavouriteSearchItemName = styled.div`
  color: ${({ theme }) => theme.colorV2.textTertiary};
  cursor: pointer;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 2.4rem;
  margin: 0 0.8rem;
`;

export const FavouriteSearchItemInput = styled.input`
  ${({ theme }) => css`
    border: none;
    caret-color: ${theme.colorV2.accentPrimary};
    color: ${theme.colorV2.textTertiary};
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 2.4rem;
    outline: 0;
    margin: 0 0.8rem;
  `}
`;

export const EditButtonsContainer = styled.div<{ $isEditMode: boolean }>`
  display: flex;
  gap: 0.8rem;
  justify-content: space-between;
  opacity: 0;
  overflow: hidden;
  transition:
    width 300ms,
    opacity 300ms;
  width: 0;

  ${({ $isEditMode }) =>
    $isEditMode &&
    css`
      opacity: 1;
      transition:
        width 300ms,
        opacity 300ms;
      width: auto;
    `}
`;

const fadeOut = keyframes`
  from { display: block; }
  to { transform: translateX(-2.8rem); opacity: 0; }
`;

export const StyledDeleteIcon = styled(DeleteIcon)<{ $isEditMode: boolean }>`
  transition:
    opacity 300ms,
    display 300ms;

  ${({ $isEditMode }) =>
    $isEditMode &&
    css`
      animation: ${fadeOut} 300ms linear;
      display: none;
    `}
`;
