import styled from 'styled-components/macro';

import { CommonFilterSelector, CommonFilterOptionType } from '@components/SelectorCommonComponents';

const LEVEL_TYPES = ['a1', 'a2', 'b1', 'b2', 'c1'];
const LEVEL_FULL_NAME = {
  a1: 'A1 Beginner',
  a2: 'A2 Elementary',
  b1: 'B1 Intermediate',
  b2: 'B2 Upper Intermediate',
  c1: 'C1 Advanced',
};
const DEFAULT_LEVEL = 'a1';

const StyledCommonFilterSelector = styled(CommonFilterSelector)`
  width: 100% !important;
`;

type CefrSelectorPropsType = {
  className?: string;
  showFullName?: boolean;
  value?: string;
  onChange: (selectedOption: CommonFilterOptionType | null) => void;
};

export const CefrSelector = ({
  className,
  showFullName = false,
  value = DEFAULT_LEVEL,
  onChange,
}: CefrSelectorPropsType) => {
  const levelOptions: CommonFilterOptionType[] = LEVEL_TYPES.map((item) => ({
    label: showFullName ? LEVEL_FULL_NAME[item as keyof typeof LEVEL_FULL_NAME] : item.toLocaleUpperCase(),
    value: item,
  }));

  return (
    <StyledCommonFilterSelector
      className={className}
      isClearable={false}
      options={levelOptions}
      placeholder=""
      value={levelOptions.find((option) => option.value === value) ?? null}
      onChange={onChange}
    />
  );
};
