import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';

import IDDisplayer from '@components/IDDisplayer/IDDisplayer';
import { CardPublishingStatus } from '@components/ContentTypes/BaseContent/CardPublishingStatus';
import { ChapterForEntrypoint } from '../types';
import { DBId } from '@common/types/DBId';

const ChapterCard = styled.div<{ disabled: boolean }>`
  display: flex;
  margin-bottom: 2.4rem;
  align-items: center;
  padding: 0.8rem 2rem;
  border-radius: 0.4rem;
  ${({ disabled, theme }) =>
    disabled
      ? 'width: fit-content;'
      : `&:hover {
    cursor: pointer;
    background: ${theme.colorV2.placementTestLinkedChapterModalCardHoverBackground};
  }`}

  .base-content__publishing-status__image--ready {
    height: 1.5rem;
    width: 1.5rem;
  }
`;

const ChapterInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.span`
  font-weight: 700;
  font-size: 1.6rem;
  color: ${({ theme }) => theme.colorV2.placementTestLinkedChapterModalCardTitleText};
`;

const SelectRadioButton = styled.input`
  appearance: auto !important;
  width: 2rem !important;
  height: 2rem !important;
  margin-right: 3rem !important;
  box-shadow: none !important;

  &[disabled]:after {
    background-color: ${({ theme }) => theme.colorV2.placementTestLinkedChapterModalCardRadioButtonDisabled};
    border-radius: 50%;
    content: '';
    display: block;
    height: 2rem;
    cursor: not-allowed;
    width: 2rem;
  }
`;

export const LinkedChapter = ({
  content,
  isSelected,
  onSelect,
  isChapterDisabled,
}: {
  content: ChapterForEntrypoint;
  isSelected: boolean;
  onSelect: (chapterId: DBId) => void;
  isChapterDisabled: boolean;
}) => {
  const { id, ready, title } = content;

  const chapterTitle = title || 'Untitled chapter';

  return (
    <>
      {isChapterDisabled && (
        <ReactTooltip className="tooltip" id={`attached-chapter-${id}-tooltip`} effect="solid" place="right" />
      )}
      <ChapterCard
        id={`chapter-${id}`}
        onClick={() => !isChapterDisabled && onSelect(id)}
        disabled={isChapterDisabled}
        data-tip={'The first chapter can not be midpoint.'}
        data-for={`attached-chapter-${id}-tooltip`}
      >
        <SelectRadioButton
          type="radio"
          checked={isSelected}
          onChange={() => onSelect(id)}
          disabled={isChapterDisabled}
        />
        <CardPublishingStatus hasPendingChanges={false} ready={!!ready} />
        <ChapterInfo>
          <IDDisplayer id={id} mode="normal" />
          <Title title={chapterTitle}>{chapterTitle}</Title>
        </ChapterInfo>
      </ChapterCard>
    </>
  );
};
