import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import styled from 'styled-components/macro';

export const baseEditorTheme = {
  list: {
    listItem: 'base-editor-listItem',
    listitemChecked: 'base-editor-listItemChecked',
    listitemUnchecked: 'base-editor-listItemUnchecked',
    ul: 'base-editor-ul',
  },
  paragraph: 'base-editor-paragraph',
  text: {
    bold: 'base-editor-textBold',
    italic: 'base-editor-textItalic',
    strikethrough: 'base-editor-textStrikethrough',
    underline: 'base-editor-textUnderline',
  },
};

export const EditorContainer = styled.div<{ debug: boolean }>`
  border: 0.1rem solid ${({ theme }) => theme.colorV2.baseEditorContainerBorder};
  border-radius: 0.8rem;
  height: 4.2rem;
  width: 100%;

  ${({ debug, theme }) =>
    debug &&
    `
    .tree-view-output {
      background-color: ${theme.colorV2.uiLightest};
      position: relative;
      z-index: 10000;
    }
  `}
`;

export const EditorTextWrapper = styled.div`
  border-radius: 0 0 0.8rem 0.8rem;
  font-size: inherit;
  line-height: 150%;
  position: relative;

  .base-editor-mention {
    background-color: ${({ theme }) => theme.colorV2.baseEditorMentionBackground};
    border-radius: 0.8rem;
    color: ${({ theme }) => theme.colorV2.baseEditorMentionColor};
    display: inline-block;
    padding: 0 0.4rem;
  }

  .base-editor-paragraph {
    margin: 0;
  }

  .base-editor-textBold {
    font-weight: 800;
  }

  .base-editor-textItalic {
    font-style: italic;
  }

  .base-editor-textUnderline {
    text-decoration: underline;
  }

  .base-editor-textStrikethrough {
    text-decoration: line-through;
  }

  .base-editor-action-item-placeholder {
    color: ${({ theme }) => theme.colorV2.baseEditorPlaceholderColor};
  }

  .base-editor-ul {
    list-style-position: inside;
    padding: 0;
    margin: 0;
  }

  .base-editor-listItem {
    margin: 0 3.2rem;
  }

  .base-editor-listItemChecked,
  .base-editor-listItemUnchecked {
    list-style-type: none;
    margin: 0 0.4rem 0 0;
    outline: none;
    padding: 0 2.4rem;
    position: relative;
  }

  .base-editor-listItemUnchecked:before,
  .base-editor-listItemChecked:before {
    background-size: cover;
    border-radius: 0.4rem;
    content: '';
    cursor: pointer;
    display: block;
    height: 2rem;
    left: 0;
    position: absolute;
    top: 2px;
    width: 2rem;
  }

  .base-editor-listItemUnchecked:before {
    border: 0.1rem solid ${({ theme }) => theme.colorV2.uiDivider};
  }

  .base-editor-listItemChecked:before {
    border: 1px solid ${({ theme }) => theme.colorV2.accentPrimary};
    background-color: ${({ theme }) => theme.colorV2.accentPrimary};
    background-repeat: no-repeat;
  }
  .base-editor-listItemChecked:after {
    border-color: ${({ theme }) => theme.colorV2.textLight};
    border-style: solid;
    border-width: 0 0.1rem 0.1rem 0;
    content: '' !important;
    cursor: pointer;
    display: block;
    height: 0.8rem;
    left: 0.7rem;
    position: absolute;
    right: 0.7rem;
    top: 0.6rem;
    transform: rotate(45deg);
    width: 0.5rem;
  }
`;

export const EditorPlaceholder = styled.div`
  color: ${({ theme }) => theme.colorV2.baseEditorPlaceholderColor};
  display: inline-block;
  left: 1.6rem;
  pointer-events: none;
  position: absolute;
  top: 0.8rem;
  user-select: none;
`;

export const StyledContentEditable = styled(ContentEditable)`
  min-height: 8.6rem;
  padding: 0.8rem 1.6rem;
  outline: none;
`;
