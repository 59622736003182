import { takeLatest } from 'redux-saga/effects';
import { SlidePdfExerciseActions } from '@actions/SlidePdfExerciseActions';
import MediaUploadSagas from '@sagas/mediaUpload/definitions/MediaUploadSagasDefinition';
import SlideshowExerciseSagas from '@sagas/exercises/definitions/SlidePdfExerciseSagasDefinition';
import type { AppDispatch } from '@redux/store';

export function* SlidePdfExerciseRelatedSagas(dispatch: AppDispatch) {
  yield takeLatest(SlidePdfExerciseActions.UPLOAD_PDF_FILE, MediaUploadSagas.uploadPdfToExercise);

  yield takeLatest(SlidePdfExerciseActions.SAVE, SlideshowExerciseSagas.save, dispatch);
}
