import { FunctionComponent, useState } from 'react';

import type { DisplayContentToUserModeType } from '@common/enums/DisplayContentToUserMode';
import { ValidationErrorInterface } from '@common/interfaces/validation/ValidationInterface';
import { useIsEditAvailable } from '@features/content/courses';
import { TrueFalseAnswerModes, type TrueFalseAnswerModesType } from './index';
import { TrueFalseStatementView } from './TrueFalseStatementView';
import { selectIsIssuesShown } from '@selectors/UiSelectors';
import { useAppSelector } from '@redux/store';

interface TrueFalseStatementProps {
  state?: TrueFalseAnswerModesType | boolean | null;
  value?: string | undefined;
  onToggleChange?: (e: TrueFalseAnswerModesType) => void;
  inputErrors?: ValidationErrorInterface[];
  answerErrors?: ValidationErrorInterface[];
  language: DisplayContentToUserModeType;
}

export const TrueFalseStatement: FunctionComponent<TrueFalseStatementProps> = ({
  state = TrueFalseAnswerModes.none,
  value,
  onToggleChange,
  inputErrors = [],
  answerErrors = [],
  language,
}: TrueFalseStatementProps) => {
  const { isEditAvailable } = useIsEditAvailable();
  let [selectedButton, setSelectedButton] = useState(state);

  const isIssuesShown = useAppSelector(selectIsIssuesShown);

  const isInputErrorsShown = !!inputErrors?.length && isIssuesShown;
  const isAnswerErrorsShown = !!answerErrors?.length && isIssuesShown;

  const onButtonClick = (newState: TrueFalseAnswerModesType) => {
    if (isEditAvailable) {
      setSelectedButton(newState);
      onToggleChange && onToggleChange(newState);
    }
  };

  return (
    <TrueFalseStatementView
      onButtonClick={onButtonClick}
      selectedButton={selectedButton}
      isInputErrorsShown={isInputErrorsShown}
      isAnswerErrorsShown={isAnswerErrorsShown}
      value={value}
      inputErrors={inputErrors}
      language={language}
    />
  );
};
