import { FunctionComponent, useMemo } from 'react';
import classnames from 'classnames';
import { useFormikContext } from 'formik';

import { KeyboardShortcut, ImageContainer } from './common';
import { FormikValuesInterface } from '@helpers/formikInitialValuesHelper';
import { SpellingContentProps } from '../../PreviewModalProps';
import { getWordsArrayWithMissedWords } from './getWordsArrayWithMissedWords';
import { getValueByLanguage } from '../../../../localization-utils';

import './SpellingContent.scss';

export const SpellingContent: FunctionComponent<SpellingContentProps> = ({
  content,
  deviceType,
  answerState,
  courseLearningLanguage,
  isForList,
}: SpellingContentProps) => {
  const { values }: { values: FormikValuesInterface } = useFormikContext();
  const isCorrectSelected = answerState === 1;
  const isIncorrectSelected = answerState === 2;

  const phrase = useMemo(
    () =>
      getValueByLanguage(
        isForList ? content.mainBundle?.phrase?.textLocalizations : values.mainBundle_phrase,
        courseLearningLanguage,
      ),
    [values, courseLearningLanguage, isForList, content.mainBundle],
  );
  const wordsArray = getWordsArrayWithMissedWords(phrase);

  const image = useMemo(() => getValueByLanguage(content.mainBundle?.image?.imageLocalizations), [content.mainBundle]);

  const isAudioEnabled = useMemo(
    () => Boolean(getValueByLanguage(content.mainBundle?.phrase?.audioLocalizations, courseLearningLanguage)),
    [content.mainBundle, courseLearningLanguage],
  );

  return (
    <>
      <ImageContainer
        deviceType={deviceType}
        isImageEnabled={Boolean(image)}
        isAudioEnabled={isAudioEnabled}
        imageSrc={image}
        isVocabulary={content.mainBundle?.isVocabulary}
      />
      <div className="spelling-text">
        {wordsArray?.map((word: string) => (
          <>
            {word.includes('!missed!') ? (
              <div
                className={classnames(
                  'spelling-text__missed-char',
                  { 'spelling-text__missed-char--answered': answerState },
                  { 'spelling-text__missed-char--correct': isCorrectSelected },
                  { 'spelling-text__missed-char--incorrect': isIncorrectSelected },
                )}
              >
                {answerState ? (
                  <span className="spelling-text__char">{isCorrectSelected ? word.replace('!missed!', '') : '&'}</span>
                ) : (
                  <svg className="spelling-text__empty-area">
                    <rect rx="8px" ry="8px" width="100%" height="100%"></rect>
                  </svg>
                )}
              </div>
            ) : (
              <>
                {word
                  .split(' ')
                  .map((smallWord) => smallWord && <span className="spelling-text__char">{smallWord}</span>)}
              </>
            )}
          </>
        ))}
      </div>

      {!answerState ? (
        <div className="spelling-chars">
          {wordsArray?.length
            ? wordsArray
                ?.filter((char: string) => char.includes('!missed!'))
                .map((char: string, index: number) => (
                  <>
                    {char ? (
                      <div
                        className={classnames('spelling-char', {
                          'spelling-char--answered': answerState,
                        })}
                      >
                        {char.replace('!missed!', '')}
                        <KeyboardShortcut answerState={answerState} value={index + 1} />
                      </div>
                    ) : null}
                  </>
                ))
            : null}
        </div>
      ) : null}
    </>
  );
};
