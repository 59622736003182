// Common actions
const REMOVE_STRING = 'GRAMMAR_REMOVE_STRING';
const SET_STRING = 'GRAMMAR_SET_STRING';
const MAKE_COPY = 'GRAMMAR_MAKE_COPY';
const SET_CONTENT_ID = 'SET_CONTENT_ID';
const UPLOAD_AUDIO = 'GRAMMAR_UPLOAD_AUDIO';
const SET_AUDIO = 'GRAMMAR_SET_AUDIO';
const REMOVE_AUDIO = 'GRAMMAR_REMOVE_AUDIO';
const SET_GRAMMAR_EXERCISE = 'SET_GRAMMAR_EXERCISE';
const SET_INITIAL_GRAMMAR_EXERCISE = 'SET_INITIAL_GRAMMAR_EXERCISE';
const GET_ALL_NAVIGATION = 'GET_ALL_NAVIGATION';
const UPDATE_NAVIGATION = 'UPDATE_NAVIGATION';
const SET_LOADING_PARENT_ID = 'SET_LOADING_PARENT_ID';
const SET_VALIDATION_RESULT = 'GRAMMAR_SET_VALIDATION_RESULT';

// Translations panel actions
const OPEN_TRANSLATIONS_PANEL = 'GRAMMAR_OPEN_TRANSLATIONS_PANEL';
const CLOSE_TRANSLATIONS_PANEL = 'GRAMMAR_CLOSE_TRANSLATIONS_PANEL';

// Review actions
const GET_GRAMMAR_REVIEW = 'GET_GRAMMAR_REVIEW';
const GRAMMAR_REVIEW_LOADING = 'GRAMMAR_REVIEW_LOADING';
const GRAMMAR_REVIEW_LOADED = 'GRAMMAR_REVIEW_LOADED';
const GET_GRAMMAR_CATEGORIES = 'GET_GRAMMAR_CATEGORIES';
const GRAMMAR_CATEGORIES_LOADING = 'GRAMMAR_CATEGORIES_LOADING';
const GRAMMAR_CATEGORIES_LOADED = 'GRAMMAR_CATEGORIES_LOADED';
const CREATE_GRAMMAR_CATEGORY = 'CREATE_GRAMMAR_CATEGORY';
const UPDATE_CATEGORIES_ORDER = 'UPDATE_CATEGORIES_ORDER';
const REMOVE_CATEGORY = 'REMOVE_CATEGORY';
const SET_INITIAL_GRAMMAR_REVIEW = 'SET_INITIAL_GRAMMAR_REVIEW';

// Category actions
const GET_GRAMMAR_CATEGORY = 'GET_GRAMMAR_CATEGORY';
const SET_INITIAL_GRAMMAR_CATEGORY = 'SET_INITIAL_GRAMMAR_CATEGORY';
const GRAMMAR_CATEGORY_LOADING = 'GRAMMAR_CATEGORY_LOADING';
const GRAMMAR_CATEGORY_LOADED = 'GRAMMAR_CATEGORY_LOADED';
const GET_GRAMMAR_TOPICS = 'GET_GRAMMAR_TOPICS';
const GRAMMAR_TOPICS_LOADING = 'GRAMMAR_TOPICS_LOADING';
const GRAMMAR_TOPICS_LOADED = 'GRAMMAR_TOPICS_LOADED';
const CREATE_GRAMMAR_TOPIC = 'CREATE_GRAMMAR_TOPIC';
const UPDATE_TOPICS_ORDER = 'UPDATE_TOPICS_ORDER';
const REMOVE_TOPIC = 'REMOVE_TOPIC';
const SAVE_CATEGORY = 'SAVE_CATEGORY';
const SET_CATEGORY_TO_NOT_CHANGED = 'SET_CATEGORY_TO_NOT_CHANGED';
const SET_GRAMMAR_CATEGORY_TITLE = 'SET_GRAMMAR_CATEGORY_TITLE';
const SET_GRAMMAR_CATEGORY_ICON = 'SET_GRAMMAR_CATEGORY_ICON';
const SET_CATEGORY_ALL_VALUES = 'SET_CATEGORY_ALL_VALUES';

// Topic actions
const GET_GRAMMAR_TOPIC = 'GET_GRAMMAR_TOPIC';
const SET_INITIAL_GRAMMAR_TOPIC = 'SET_INITIAL_GRAMMAR_TOPIC';
const GRAMMAR_TOPIC_LOADING = 'GRAMMAR_TOPIC_LOADING';
const GRAMMAR_TOPIC_LOADED = 'GRAMMAR_TOPIC_LOADED';
const GET_GRAMMAR_EXERCISES = 'GET_GRAMMAR_EXERCISES';
const GRAMMAR_EXERCISES_LOADING = 'GRAMMAR_EXERCISES_LOADING';
const GRAMMAR_EXERCISES_LOADED = 'GRAMMAR_EXERCISES_LOADED';
const CREATE_REFERENCE_EXERCISE = 'CREATE_REFERENCE_EXERCISE';
const CREATE_GRAMMAR_EXERCISE = 'CREATE_GRAMMAR_EXERCISE';
const UPDATE_REFERENCE_EXERCISES_ORDER = 'GRAMMAR_UPDATE_REFERENCE_EXERCISES_ORDER';
const UPDATE_GRAMMAR_EXERCISES_ORDER = 'GRAMMAR_UPDATE_GRAMMAR_EXERCISES_ORDER';
const REMOVE_EXERCISE = 'GRAMMAR_REMOVE_EXERCISE';
const SET_TOPIC_ALL_VALUES = 'SET_TOPIC_ALL_VALUES';
const REUSE_EXERCISE = 'GRAMMAR_REUSE_EXERCISE';
const COPY_EXERCISE = 'GRAMMAR_COPY_EXERCISE';
const UPDATE_CEFR = 'UPDATE_CEFR';
const SET_TOPIC_TO_NOT_CHANGED = 'SET_TOPIC_TO_NOT_CHANGED';
const SAVE_TOPIC = 'SAVE_TOPIC';
const ATTACH_LESSONS_TO_TOPIC = 'ATTACH_LESSONS_TO_TOPIC';
const SET_ATTACHED_LESSONS_STATUS = 'SET_ATTACHED_LESSONS_STATUS';
const SET_LINKED_LESSONS = 'SET_LINKED_LESSONS';

export const GrammarReviewActions = {
  REMOVE_STRING,
  SET_STRING,
  MAKE_COPY,
  SET_CONTENT_ID,
  UPLOAD_AUDIO,
  SET_AUDIO,
  REMOVE_AUDIO,
  SET_GRAMMAR_EXERCISE,
  SET_INITIAL_GRAMMAR_EXERCISE,
  GET_ALL_NAVIGATION,
  UPDATE_NAVIGATION,
  SET_LOADING_PARENT_ID,
  SET_VALIDATION_RESULT,
  OPEN_TRANSLATIONS_PANEL,
  CLOSE_TRANSLATIONS_PANEL,
  GET_GRAMMAR_REVIEW,
  GRAMMAR_REVIEW_LOADING,
  GRAMMAR_REVIEW_LOADED,
  GET_GRAMMAR_CATEGORIES,
  GRAMMAR_CATEGORIES_LOADING,
  GRAMMAR_CATEGORIES_LOADED,
  CREATE_GRAMMAR_CATEGORY,
  UPDATE_CATEGORIES_ORDER,
  REMOVE_CATEGORY,
  SET_INITIAL_GRAMMAR_REVIEW,
  GET_GRAMMAR_CATEGORY,
  SET_INITIAL_GRAMMAR_CATEGORY,
  GRAMMAR_CATEGORY_LOADING,
  GRAMMAR_CATEGORY_LOADED,
  GET_GRAMMAR_TOPICS,
  GRAMMAR_TOPICS_LOADING,
  GRAMMAR_TOPICS_LOADED,
  CREATE_GRAMMAR_TOPIC,
  UPDATE_TOPICS_ORDER,
  REMOVE_TOPIC,
  SAVE_CATEGORY,
  SET_CATEGORY_TO_NOT_CHANGED,
  SET_GRAMMAR_CATEGORY_TITLE,
  SET_GRAMMAR_CATEGORY_ICON,
  SET_CATEGORY_ALL_VALUES,
  GET_GRAMMAR_TOPIC,
  SET_INITIAL_GRAMMAR_TOPIC,
  GRAMMAR_TOPIC_LOADING,
  GRAMMAR_TOPIC_LOADED,
  GET_GRAMMAR_EXERCISES,
  GRAMMAR_EXERCISES_LOADING,
  GRAMMAR_EXERCISES_LOADED,
  CREATE_REFERENCE_EXERCISE,
  CREATE_GRAMMAR_EXERCISE,
  UPDATE_REFERENCE_EXERCISES_ORDER,
  UPDATE_GRAMMAR_EXERCISES_ORDER,
  REMOVE_EXERCISE,
  SET_TOPIC_ALL_VALUES,
  REUSE_EXERCISE,
  COPY_EXERCISE,
  UPDATE_CEFR,
  SET_TOPIC_TO_NOT_CHANGED,
  SAVE_TOPIC,
  ATTACH_LESSONS_TO_TOPIC,
  SET_ATTACHED_LESSONS_STATUS,
  SET_LINKED_LESSONS,
} as const;
