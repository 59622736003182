import {
  AnyExerciseContentBranch,
  CourseContentBranches,
  ContentBranches,
} from '@common/types/AnyExerciseContentBranch';
import { AnyExerciseContentInterface } from '@common/types/exercises/AnyExerciseContentInterface';
import { GenericContentInterface } from '@common/interfaces/contentTypes/GenericContentInterface';
import { LocalizationInterface } from '@common/interfaces/localization/LocalizationInterface';
import { CourseInterface } from '@common/interfaces/courses/CourseInterface';
import { Language, LANGUAGE_NAMES } from '@features/content/languages';
import { camelCaseSeparateWords } from '@helpers/camel-case-separator';
import { isLocalizationFilled, isLocalizationNeedsToBeCounted } from '@features/content';
import * as _ from 'lodash';

const TranslationTipV2Utils = {
  getTranslationAndAudioElements(
    interfaceLanguages: Language[],
    content: GenericContentInterface | CourseInterface | AnyExerciseContentInterface | {},
    visitedBranch: AnyExerciseContentBranch | CourseContentBranches | ContentBranches,
    row: number | undefined,
    column: number | undefined,
    courseLearningLang: Language,
    bundleName?: string,
  ) {
    let totalNeededLanguages = interfaceLanguages.length;

    if (!interfaceLanguages.includes(courseLearningLang)) {
      totalNeededLanguages = totalNeededLanguages + 1;
    }

    if (!interfaceLanguages.includes('EN') && courseLearningLang !== 'EN') {
      totalNeededLanguages = totalNeededLanguages + 1;
    }

    let chosenBranch = bundleName ? (content as any)[bundleName][visitedBranch] : (content as any)[visitedBranch];

    if (['phrase0', 'phrase1', 'phrase2', 'phrase3'].includes(visitedBranch)) {
      chosenBranch = (content as any).phrases;
    }
    const bundleId = bundleName ? (content as any)[bundleName]?._id : 'N/A';

    if (row !== undefined) {
      if (column === undefined) {
        chosenBranch = chosenBranch[row];
      } else {
        chosenBranch = chosenBranch[row][column];
      }
    }

    const isHiglighterPhrasesEmpty =
      chosenBranch === null ||
      (['phrase0', 'phrase1', 'phrase2', 'phrase3'].includes(visitedBranch) && (content as any).phrases.length === 0);

    if (chosenBranch === undefined && !isHiglighterPhrasesEmpty) {
      throw new Error(`Field "${visitedBranch}" doesn't appear in the exercise payload`);
    }

    let localizationIDForLangExperts = isHiglighterPhrasesEmpty || !chosenBranch._id ? 'N/A' : chosenBranch._id;

    if (isHiglighterPhrasesEmpty) {
      return {
        translationElements: `0/${totalNeededLanguages}`,
        audioElements: `0/${totalNeededLanguages}`,
        localizationIDForLangExperts,
        chosenBranch,
        bundleId,
      };
    } else {
      const numberOfTranslations = chosenBranch.textLocalizations.filter(
        (localization: LocalizationInterface) =>
          isLocalizationFilled(localization) &&
          isLocalizationNeedsToBeCounted(
            localization,
            interfaceLanguages as Language[],
            courseLearningLang as Language,
          ),
      ).length;

      const numberOfAudio = chosenBranch.audioLocalizations.filter(
        (localization: LocalizationInterface) =>
          localization.value &&
          isLocalizationNeedsToBeCounted(
            localization,
            interfaceLanguages as Language[],
            courseLearningLang as Language,
          ),
      ).length;

      return {
        translationElements: `${numberOfTranslations}/${totalNeededLanguages}`,
        audioElements: `${numberOfAudio}/${totalNeededLanguages}`,
        localizationIDForLangExperts,
        chosenBranch,
        bundleId,
      };
    }
  },
  formatPlaceholder(
    childrenPlaceholder: string | undefined,
    visitedBranch: AnyExerciseContentBranch | CourseContentBranches | ContentBranches,
    languageForTips: Language,
  ) {
    return childrenPlaceholder === undefined
      ? `${camelCaseSeparateWords(_.upperFirst(visitedBranch))} in ${LANGUAGE_NAMES[languageForTips as Language]}`
      : childrenPlaceholder;
  },
};

export default TranslationTipV2Utils;
