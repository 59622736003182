import styled from 'styled-components/macro';

import { Dropdown } from '@components/Dropdown';
import { ALL_ROLES, Role, ROLE_NAMES } from '@features/auth/roles';

import { DefaultPlaceholder } from '../DefaultPlaceholder/DefaultPlaceholder';

const RoleDropdownWrapper = styled.div`
  width: fit-content;
`;

const RoleDropdown = styled(Dropdown)<{ disabled: boolean }>`
  margin-right: 1rem;
  max-width: 12rem;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

  .dropdown-toggle {
    border-color: ${({ theme }) => theme.colorV2.input.border.default};
    padding: 0.2rem 2.6rem 0.2rem 0.8rem;
    margin: 0;
  }
`;

interface RoleContainerProps {
  currentRole: Role;
  disabled: boolean;
  isEditMode: boolean;
  onEditRole: () => void;
  setCurrentRole: (newRole: Role) => void;
}

export const RoleContainer = ({
  currentRole,
  disabled,
  isEditMode,
  onEditRole,
  setCurrentRole,
}: RoleContainerProps) => {
  if (isEditMode) {
    return (
      <RoleDropdownWrapper
        onClick={(evt) => {
          onEditRole();
        }}
      >
        <RoleDropdown
          disabled={disabled}
          id="role"
          title="Role"
          value={currentRole}
          values={ALL_ROLES.map((role) => ({
            code: role,
            name: ROLE_NAMES[role],
          }))}
          onChange={(selectedValue) => {
            setCurrentRole(selectedValue.code as Role);
          }}
        />
      </RoleDropdownWrapper>
    );
  } else {
    return (
      <>
        {currentRole ? <span>{ROLE_NAMES[currentRole]}</span> : <DefaultPlaceholder>Not assigned</DefaultPlaceholder>}
      </>
    );
  }
};
