import { CSSProperties, PropsWithChildren } from 'react';
import styled from 'styled-components';
import { defaultTheme } from '@features/theme';
import { Box, BoxProps } from './Box';

type LabelledContentProps = BoxProps & {
  text: string;
};

type LabelProps = Pick<CSSProperties, 'fontSize' | 'color'>;

const Label = styled.label<LabelProps>(({ fontSize = '14px', color = `${defaultTheme.color.brandGreyscale800};` }) => ({
  fontSize,
  color,
}));

export const LabelledContent = ({ children, text, ...props }: PropsWithChildren<LabelledContentProps>) => (
  <Box flexDirection="column" flexBasis={0} flexGrow={1} gap={0} {...props}>
    <Label>{text}</Label>
    {children}
  </Box>
);
