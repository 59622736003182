import { RoleplayActions } from '@actions/RoleplayActions';
import { ContentTypesType } from '@common/enums/ContentTypes';
import { MediaTypesType } from '@common/enums/MediaTypes';
import type { TranslationsPanelContentInterface } from '@common/interfaces/exercises/TranslationsPanelContentInterface';
import type { LocalizationInterface } from '@common/interfaces/localization/LocalizationInterface';
import { DBId } from '@common/types/DBId';
import { LanguageV2 } from '@features/content/languages';
import { NavigationItemType } from '@features/content/navigation';
import type {
  RoleplayListItemType,
  RoleplayCategoryEditableFieldNames,
  RoleplayCategoryFormikValues,
  RoleplayCategoryListItemType,
  RoleplayScenarioFieldWithLocalizationNames,
  RoleplayScenarioStringFieldNames,
  RoleplayScenarioNumberFieldNames,
  RoleplayScenarioFormikValues,
} from '@features/content/roleplay';

export const RoleplayActionCreators = {
  // Common
  setContentId: (payload: {
    contentType: ContentTypesType;
    fieldName:
      | RoleplayCategoryEditableFieldNames
      | RoleplayScenarioFieldWithLocalizationNames
      | RoleplayScenarioStringFieldNames
      | RoleplayScenarioNumberFieldNames;
    contentId: DBId;
  }) => ({
    type: RoleplayActions.SET_CONTENT_ID,
    payload,
  }),
  setMediaValueAfterProcessing: (contentType: DBId, mediaType: MediaTypesType, mediaUrl: string) => ({
    type: RoleplayActions.SET_MEDIA_VALUE_AFTER_PROCESSING,
    payload: { contentType, mediaType, mediaUrl },
  }),

  // Roleplay
  getRoleplay: (language: LanguageV2) => ({ type: RoleplayActions.GET_ROLEPLAY, payload: language }),
  getRoleplayCategories: (language: LanguageV2) => ({
    type: RoleplayActions.GET_ROLEPLAY_CATEGORIES,
    payload: language,
  }),
  setInitialRoleplay: () => ({ type: RoleplayActions.SET_INITIAL_ROLEPLAY }),
  setLoadingParentId: (id: DBId | LanguageV2) => ({
    type: RoleplayActions.SET_LOADING_PARENT_ID,
    payload: id,
  }),
  getAllNavigation: (language: LanguageV2, roleplayId: DBId, categoryId: DBId, topicId: DBId) => ({
    type: RoleplayActions.GET_ALL_NAVIGATION,
    payload: { language, roleplayId, categoryId, topicId },
  }),
  updateNavigation: (newNavigation: NavigationItemType[]) => ({
    type: RoleplayActions.UPDATE_NAVIGATION,
    payload: newNavigation,
  }),
  createRoleplayCategory: (language: LanguageV2, position: number) => ({
    type: RoleplayActions.CREATE_ROLEPLAY_CATEGORY,
    payload: {
      language,
      position,
    },
  }),
  removeRoleplayCategory: (language: LanguageV2, categoryId: DBId) => ({
    type: RoleplayActions.REMOVE_ROLEPLAY_CATEGORY,
    payload: { language, categoryId },
  }),
  setRoleplayCategoriesOrder: (
    language: LanguageV2,
    destinationIndex: number,
    orderedItems: RoleplayListItemType[],
  ) => ({
    type: RoleplayActions.UPDATE_ROLEPLAY_CATEGORIES_ORDER,
    payload: {
      language,
      destinationIndex,
      orderedItems,
    },
  }),

  // Roleplay Category
  getRoleplayCategory: (categoryId: DBId) => ({ type: RoleplayActions.GET_ROLEPLAY_CATEGORY, payload: categoryId }),
  getRoleplayScenarios: (categoryId: DBId) => ({ type: RoleplayActions.GET_ROLEPLAY_SCENARIOS, payload: categoryId }),
  setInitialRoleplayCategory: () => ({ type: RoleplayActions.SET_INITIAL_ROLEPLAY_CATEGORY }),
  setRoleplayCategoryTitle: (content: TranslationsPanelContentInterface) => ({
    type: RoleplayActions.SET_ROLEPLAY_CATEGORY_TITLE,
    payload: content,
  }),
  setRoleplayCategoryIcon: (selectedIcon: string) => ({
    type: RoleplayActions.SET_ROLEPLAY_CATEGORY_ICON,
    payload: selectedIcon,
  }),
  createScenario: (languageId: LanguageV2, roleplayId: DBId, categoryId: DBId, position: number) => ({
    type: RoleplayActions.CREATE_ROLEPLAY_SCENARIO,
    payload: {
      languageId,
      roleplayId,
      categoryId,
      position,
    },
  }),
  removeScenario: (categoryId: DBId, scenarioId: DBId) => ({
    type: RoleplayActions.REMOVE_ROLEPLAY_SCENARIO,
    payload: { categoryId, scenarioId },
  }),
  setScenariosOrder: (categoryId: DBId, destinationIndex: number, orderedItems: RoleplayCategoryListItemType[]) => ({
    type: RoleplayActions.UPDATE_ROLEPLAY_SCENARIOS_ORDER,
    payload: {
      categoryId,
      destinationIndex,
      orderedItems,
    },
  }),
  saveCategory: ({
    values,
    languageId,
    roleplayId,
    categoryId,
  }: {
    values: RoleplayCategoryFormikValues;
    languageId: LanguageV2;
    roleplayId: DBId;
    categoryId: DBId;
  }) => ({
    type: RoleplayActions.SAVE_CATEGORY,
    payload: { values, language: languageId, roleplayId, categoryId },
  }),

  // Scenario
  getScenario: (scenarioId: DBId) => ({ type: RoleplayActions.GET_SCENARIO, payload: scenarioId }),
  setInitialScenario: () => ({ type: RoleplayActions.SET_INITIAL_SCENARIO }),
  uploadImageToScenario: (payload: {
    scenarioId: DBId;
    contentIdBeingUpdated: DBId | undefined;
    file: File;
    progressHandler: (progress: number) => void;
  }) => ({ type: RoleplayActions.UPLOAD_IMAGE_TO_SCENARIO, payload }),
  removeImageFromScenario: (scenarioId: DBId) => ({
    type: RoleplayActions.REMOVE_IMAGE_FROM_SCENARIO,
    payload: scenarioId,
  }),
  updateScenarioField: (
    fieldName: RoleplayScenarioStringFieldNames | RoleplayScenarioNumberFieldNames,
    value: string | string[] | number | null,
  ) => ({
    type: RoleplayActions.UPDATE_SCENARIO_FIELD,
    payload: {
      fieldName,
      value,
    },
  }),
  saveScenario: ({
    values,
    languageId,
    roleplayId,
    categoryId,
    scenarioId,
  }: {
    values: RoleplayScenarioFormikValues;
    languageId: LanguageV2;
    roleplayId: DBId;
    categoryId: DBId;
    scenarioId: DBId;
  }) => ({
    type: RoleplayActions.SAVE_SCENARIO,
    payload: {
      values,
      language: languageId,
      roleplayId,
      categoryId,
      scenarioId,
    },
  }),

  // Translation Panel
  openTranslationsPanel: (
    type: ContentTypesType,
    preopenedTab: 'translations' | 'audios' | 'other',
    visitedId: DBId | undefined,
    fieldName: RoleplayCategoryEditableFieldNames | RoleplayScenarioFieldWithLocalizationNames,
    isEditBlocked?: boolean,
    isEditDisabled?: boolean,
    defaultContextForTranslators?: string,
  ) => ({
    type: RoleplayActions.OPEN_TRANSLATIONS_PANEL,
    payload: {
      type,
      preopenedTab,
      visitedId,
      fieldName,
      isEditBlocked,
      isEditDisabled,
      defaultContextForTranslators,
    },
  }),
  closeTranslationsPanel: () => ({
    type: RoleplayActions.CLOSE_TRANSLATIONS_PANEL,
  }),
  uploadAudio: (
    localization: LocalizationInterface | undefined,
    uploadedSound: File | null,
    progressHandler?: Function,
  ) => ({
    type: RoleplayActions.UPLOAD_AUDIO,
    payload: {
      localization,
      uploadedSound,
      progressHandler,
    },
  }),
  removeAudio: (
    localization: LocalizationInterface | undefined,
    fieldName: RoleplayCategoryEditableFieldNames,
    type: ContentTypesType,
  ) => ({
    type: RoleplayActions.REMOVE_AUDIO,
    payload: { localization, fieldName, type },
  }),
  removeString: (
    contentType: ContentTypesType,
    fieldName: RoleplayCategoryEditableFieldNames | RoleplayScenarioFieldWithLocalizationNames,
  ) => ({
    type: RoleplayActions.REMOVE_STRING,
    payload: { contentType, fieldName },
  }),
};
