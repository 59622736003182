import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { Box } from './Box';
import { Typography } from './Typography';

const ButtonStyled = styled.button(({ disabled, theme }) => ({
  backgroundColor: 'transparent',
  border: 'none',
  color: disabled ? theme.color.brandPrimary500 : theme.color.brandPrimaryBase,
  padding: 0,
}));

export type ActionButtonProps = {
  disabled?: boolean;
  icon: IconProp;
  text: string;
};

export const ActionButton = ({ disabled, icon, text }: ActionButtonProps) => (
  <ButtonStyled disabled={disabled}>
    <Box alignItems="center">
      <Typography fontSize="16px">{text}</Typography>
      <FontAwesomeIcon icon={icon} size="lg" />
    </Box>
  </ButtonStyled>
);
