import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import BreadcrumbNavigation from '@components/BreadcrumbNavigation/BreadcrumbNavigation';
import { Loader } from '@features/theme';
import { UserInterface } from '@common/interfaces/users/UserInterface';
import { ProfileImage } from '@components/RolesAndPermissions/ProfileImage/ProfileImage';
import { ROLE_NAMES } from '@features/auth/roles';
import { DBId } from '@common/types/DBId';
import { usersSelectors } from '@redux/slices/usersSlice';
import {
  CardDataWrapper,
  PersonalInfoCard,
  PersonalInfoHeader,
  PersonalInfoPageWrapper,
  PersonalInfoTitle,
  PersonalInfoWrapper,
  ProfileAvatar,
  ProfileItem,
  ProfileItemImage,
  ProfileName,
} from './PersonalInfo.styles';

import locationIcon from '@components/RolesAndPermissions/PersonalInfo/img/locationIcon.svg';
import emailIcon from '@components/RolesAndPermissions/PersonalInfo/img/emailIcon.svg';
import roleIcon from '@components/RolesAndPermissions/PersonalInfo/img/roleIcon.svg';
import { PersonalInfoForm } from './PersonalInfoForm';

import { Logo } from '@features/app/logo';
import { useUser } from '@features/auth';
import { useAppSelector } from '@redux/store';

export const PersonalInfo = () => {
  const history = useHistory();
  const { user } = useUser();
  const { userId } = useParams<{ userId: DBId }>();
  const isViewMode = !!userId;
  const [userInfo, setUserInfo] = useState<UserInterface | null | undefined>(null);
  const userOpened = useAppSelector((state) => usersSelectors.selectById(state, userId));

  useEffect(() => {
    if (isViewMode && userOpened) {
      setUserInfo(userOpened);
    } else {
      if (user) {
        setUserInfo(user);
      }
    }
  }, [isViewMode, user, userOpened]);

  return (
    <PersonalInfoPageWrapper>
      <PersonalInfoHeader>
        <Logo size="large" withLink />
        <ProfileImage />
      </PersonalInfoHeader>
      <BreadcrumbNavigation
        breadcrumbItems={
          isViewMode
            ? [
                <span onClick={() => history.push('/admin-settings')}>Admin Settings</span>,
                <span className="breadcrumb-navigation__node">Personal Information</span>,
              ]
            : [<span>Personal Information</span>]
        }
      />
      <PersonalInfoWrapper>
        <PersonalInfoTitle>
          {user?.id !== userInfo?.id ? `${userInfo?.firstName} ${userInfo?.lastName}` : 'Personal Information'}
        </PersonalInfoTitle>
        {!isViewMode ? <span>View and edit your profile settings</span> : null}
        {userInfo && user !== null ? (
          <>
            <PersonalInfoCard>
              <ProfileAvatar name={`${userInfo.firstName} ${userInfo.lastName}`} size="180" />
              <CardDataWrapper>
                <ProfileName>
                  {userInfo.firstName} {userInfo.lastName}
                </ProfileName>

                <ProfileItem>
                  <ProfileItemImage src={emailIcon} alt="Email" />
                  <span>{userInfo.email || 'Unknown'}</span>
                </ProfileItem>

                <ProfileItem>
                  <ProfileItemImage src={roleIcon} alt="Role" />
                  <span>{ROLE_NAMES[userInfo.role] || 'Unknown'}</span>
                </ProfileItem>

                <ProfileItem>
                  <ProfileItemImage src={locationIcon} alt="Location" />
                  <span>{userInfo.location || 'Unknown'}</span>
                </ProfileItem>
              </CardDataWrapper>
            </PersonalInfoCard>

            <PersonalInfoForm isViewMode={user?.id !== userInfo?.id} userInfo={userInfo} />
          </>
        ) : (
          <Loader size="L" />
        )}
      </PersonalInfoWrapper>
    </PersonalInfoPageWrapper>
  );
};
