import { useEffect, useState } from 'react';

import { DBId } from '@common/types/DBId';
import { Button, Loader } from '@features/theme';

import { VocabularyReviewService } from '../../../VocabularyReviewService';
import type { MatchedExercisesLocations } from '../../../types';
import {
  MatchedExercisesModalButtonArea,
  MatchedExercisesModalDescription,
  MatchedExercisesModalResultsContainer,
  MatchedExercisesModalTitle,
} from '../styles';
import { LocationItem } from './LocationItem';

type MatchedExercisesModalProps = {
  isOpen: boolean;
  lexicalItemId: DBId | null;
  close: () => void;
};

export const MatchedExercisesModal = ({ isOpen, lexicalItemId, close }: MatchedExercisesModalProps) => {
  const [matchedExercisesLocations, setMatchedExercisesLocations] = useState<MatchedExercisesLocations>([]);

  useEffect(() => {
    if (isOpen && lexicalItemId) {
      try {
        (async () => {
          const result = await VocabularyReviewService.getExercisesMatchedLocations({ lexicalItemId });
          setMatchedExercisesLocations(result.data);
        })();
      } catch (error) {
        console.error(error);
      }
    }
  }, [isOpen, lexicalItemId]);

  return (
    <div>
      <MatchedExercisesModalTitle>Matched in {matchedExercisesLocations.length} locations</MatchedExercisesModalTitle>
      <MatchedExercisesModalDescription>
        This content is used in the places listed below. Choose the one you want to open.
      </MatchedExercisesModalDescription>
      <MatchedExercisesModalResultsContainer>
        {matchedExercisesLocations.length ? (
          <>
            {matchedExercisesLocations.map((location) => (
              <LocationItem location={location} />
            ))}
          </>
        ) : (
          <Loader size="L" />
        )}
      </MatchedExercisesModalResultsContainer>
      <MatchedExercisesModalButtonArea>
        <Button size="M" variant="tertiary" onClick={close}>
          Close
        </Button>
      </MatchedExercisesModalButtonArea>
    </div>
  );
};
