import { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components/macro';

import { ContentAnalyticsTooltip } from './ContentAnalyticsTooltip';
import type { ContentAnalyticsDataType, ContentAnalyticsStatusType } from './types';

const ContentAnalyticsTrigger = styled.div`
  align-items: flex-end;
  background: ${({ theme }) => theme.colorV2.uiBackgroundTertiary};
  display: flex;
  height: 1.8rem;
  width: 1.6rem;

  &:hover {
    cursor: pointer;
  }
`;

const ContentAnalyticsTriggerStatus = styled.div<{ status: ContentAnalyticsStatusType }>`
  ${({ status, theme }) => {
    const values = {
      green: {
        bgColor: theme.colorV2.utilitySuccess,
        height: '100%',
      },
      amber: {
        bgColor: theme.colorV2.utilityAlert,
        height: '66%',
      },
      red: {
        bgColor: theme.colorV2.utilityError,
        height: '33%',
      },
      grey: {
        bgColor: 'transparent',
        height: '0',
      },
    };

    return css`
      background-color: ${values[status].bgColor};
      height: ${values[status].height};
      width: 100%;
    `;
  }}
`;

type ContentAnalyticsIndicatorProps = {
  data: ContentAnalyticsDataType;
  status: ContentAnalyticsStatusType;
  onDataDisplayed: () => void;
};

export const ContentAnalyticsIndicator = ({
  data,
  status = 'grey',
  onDataDisplayed,
}: ContentAnalyticsIndicatorProps) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const triggerRef = useRef(null);

  useEffect(() => {
    return () => {
      setIsTooltipVisible(false);
    };
  }, []);

  return (
    <div>
      <ContentAnalyticsTrigger
        ref={triggerRef}
        onMouseEnter={() => {
          setIsTooltipVisible(true);
          onDataDisplayed();
        }}
        onMouseLeave={() => setIsTooltipVisible(false)}
      >
        <ContentAnalyticsTriggerStatus status={status} />
      </ContentAnalyticsTrigger>
      <ContentAnalyticsTooltip data={data} status={status} trigger={triggerRef.current} visible={isTooltipVisible} />
    </div>
  );
};
