import { apiClient } from '@features/api';

import { SearchResultType } from '../SearchResultItem';

export const searchById = async (query: string): Promise<{ data: { results: SearchResultType[]; count: number } }> => {
  return await apiClient.noErrorsV2.get('/search/id', {
    params: {
      query,
    },
  });
};
