import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { UserInterface } from '@common/interfaces/users/UserInterface';
import { Role } from '@features/auth/roles';
import { OptionInterface } from '@components/RolesAndPermissions/AdminSettings/Table/UserItem/CourseContainer/CourseContainer';
import { generateJoinedAtMessage, generateLastLoginAtMessage } from '@helpers/dateHelper';
import { EditPanel } from '@components/RolesAndPermissions/AdminSettings/Table/UserItem/EditPanel/EditPanel';
import { NameContainer } from '@components/RolesAndPermissions/AdminSettings/Table/UserItem/NameContainer/NameContainer';
import { RoleContainer } from '@components/RolesAndPermissions/AdminSettings/Table/UserItem/RoleContainer/RoleContainer';
import {
  CourseContainer,
  courseLanguagesDropdownFilters,
} from '@components/RolesAndPermissions/AdminSettings/Table/UserItem/CourseContainer/CourseContainer';
import { useAppDispatch } from '@redux/store';
import { usersSlice } from '@redux/slices/usersSlice';
import { isFeatureEnabled } from '@helpers/featuresHelper';

import navigateIcon from '@components/RolesAndPermissions/AdminSettings/img/navigateToProfileIcon.svg';
import editIcon from '@components/RolesAndPermissions/AdminSettings/img/editUserIcon.svg';
import archiveIcon from '@components/RolesAndPermissions/AdminSettings/img/archiveUserIcon.svg';
import { UserListItem } from '@features/users';

import { StyledTableRow } from '../TableRow';
import { UserBeneficiaryArchivalModal } from '../../UserBeneficiaryArchivalModal';
import { ManagerContainer } from './ManagerContainer/ManagerContainer';

import './UserItem.scss';
import { useToast } from '@features/app/toast';

type UserItemProps = {
  activeUserId?: string;
  user: UserInterface;
  userList: UserListItem[];
  getUser: (id: string) => UserInterface | null;
  getAvailableManagersForUser: (userId: string) => UserInterface[];
};
export const UserItem = ({ activeUserId, user, userList, getUser, getAvailableManagersForUser }: UserItemProps) => {
  const dispatch = useAppDispatch();
  const [isEditMode, setIsEditMode] = useState(false);
  const [saveInProgress, setSaveInProgress] = useState(false);

  const [currentRole, setCurrentRole] = useState<Role>(user.role);
  const [currentLanguage, setCurrentLanguage] = useState<OptionInterface[]>([]);
  const [allLanguagesSelected, setAllLanguagesSelected] = useState(user.languages?.all);
  const [currentManager, setCurrentManager] = useState(user.manager);

  const [isArchiveConfirmationDisplayed, setArchiveConfirmationDisplayed] = useState(false);

  const showToast = useToast();

  useEffect(() => {
    if (user) {
      setCurrentRole(user.role);
      setCurrentLanguage(
        user.languages.list.map((code: string) => ({
          label: courseLanguagesDropdownFilters.find((option: OptionInterface) => option.value === code)?.label || '',
          value: courseLanguagesDropdownFilters.find((option: OptionInterface) => option.value === code)?.value || '',
        })),
      );
      setAllLanguagesSelected(user.languages.all);
    }
  }, [user]);

  useEffect(() => {
    if (currentRole === 'ROLE_ADMIN' || currentRole === 'ROLE_VIEWER') {
      setAllLanguagesSelected(true);
    }
  }, [currentRole]);

  const onCancel = () => {
    setCurrentManager(user.manager);
    setCurrentRole(user.role);

    setCurrentLanguage(
      user.languages.list.map((code: string) => ({
        label: courseLanguagesDropdownFilters.find((option: OptionInterface) => option.value === code)?.label || '',
        value: courseLanguagesDropdownFilters.find((option: OptionInterface) => option.value === code)?.value || '',
      })),
    );
    setAllLanguagesSelected(user.languages.all);
    setIsEditMode(false);
  };

  const onSave = () => {
    setSaveInProgress(true);

    dispatch(
      usersSlice.actions.editUserRole({
        userId: user.id,
        data: {
          role: currentRole,
          languages: {
            all: allLanguagesSelected,
            list: currentLanguage.map((lang: OptionInterface) => lang.value),
          },
          manager: currentManager,
        },
        successCallback: () => {
          setIsEditMode(false);
        },
        commonCallback: () => {
          setSaveInProgress(false);
        },
      }),
    );
  };

  const onArchive = (beneficiaryId: string) => {
    setSaveInProgress(true);

    dispatch(
      usersSlice.actions.archiveUser({
        userId: user.id,
        beneficiaryId,
        commonCallback: () => setSaveInProgress(false),
      }),
    );
  };

  const onEditRole = () => {
    if (!user.manager) {
      showToast({
        type: 'error',
        title: 'You cannot edit role without an assigned manager',
      });
    }
  };

  return (
    <StyledTableRow isEditMode={isEditMode}>
      <UserBeneficiaryArchivalModal
        managerId={user.manager}
        opened={isArchiveConfirmationDisplayed}
        userList={userList}
        onArchive={onArchive}
        onClose={() => setArchiveConfirmationDisplayed(false)}
      />
      <NameContainer name={`${user.firstName} ${user.lastName}`} />
      <span className="user-item--position">{user.position}</span>

      {isFeatureEnabled('managerField') && (
        <ManagerContainer
          isEditMode={isEditMode}
          availableManagers={getAvailableManagersForUser(user.id)}
          currentManager={currentManager ? getUser(currentManager) : null}
          setManager={setCurrentManager}
        />
      )}
      <RoleContainer
        currentRole={currentRole}
        disabled={currentManager === null}
        isEditMode={isEditMode}
        onEditRole={onEditRole}
        setCurrentRole={setCurrentRole}
      />
      <CourseContainer
        isEditMode={isEditMode}
        currentRole={currentRole}
        setCurrentLanguage={setCurrentLanguage}
        setAllLanguagesSelected={setAllLanguagesSelected}
        currentLanguage={currentLanguage}
        allLanguagesSelected={allLanguagesSelected}
      />

      {isEditMode ? (
        <EditPanel
          onCancel={onCancel}
          onSave={onSave}
          saveInProgress={saveInProgress}
          currentRole={currentRole}
          currentLanguage={currentLanguage}
          allLanguagesSelected={allLanguagesSelected}
        />
      ) : (
        <>
          <span>{generateJoinedAtMessage(user.joinedAt)}</span>
          <span>{generateLastLoginAtMessage(user.lastLoginAt)}</span>
          {activeUserId !== user.id ? (
            <div onClick={() => setIsEditMode(true)} className="user-item__edit-button">
              <img src={editIcon} alt="Edit" />
            </div>
          ) : (
            <div />
          )}
          <Link to={`/personal-info/${user.id}`}>
            <img src={navigateIcon} alt="Navigate" />
          </Link>
          {activeUserId !== user.id ? (
            <div onClick={() => setArchiveConfirmationDisplayed(true)} className="user-item__edit-button">
              <img src={archiveIcon} alt="Archive" />
            </div>
          ) : (
            <div />
          )}
        </>
      )}
    </StyledTableRow>
  );
};
