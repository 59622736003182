import styled from 'styled-components/macro';

import { ReactComponent as ContextualMenuTriggerIcon } from './Header/_img/context-menu-trigger.svg';

export const StyledContextualMenuTriggerIcon = styled(ContextualMenuTriggerIcon)`
  margin: 0 0.8rem;
  transform: scale(1.1);
  transform-origin: right;
`;

export const BreadcrumbsAndOwnershipWrapper = styled.div`
  display: flex;
  height: 3.8rem;
  justify-content: space-between;
  margin-bottom: 4rem;
`;
