import { useEffect } from 'react';

export const useEnterKeydownHandler = (handleOnSearch: () => void) => {
  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.code === 'Enter') {
        event.preventDefault();
        handleOnSearch();
      }
    };

    window.addEventListener('keydown', keyDownHandler);

    return () => {
      window.removeEventListener('keydown', keyDownHandler);
    };
  }, [handleOnSearch]);
};
