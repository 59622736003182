import styled from 'styled-components/macro';

import { Button } from '@features/theme';

const ModalTitle = styled.div`
  color: ${({ theme }) => theme.colorV2.uiDarkest};
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 0.8rem;
  text-align: center;
`;

const ModalDescription = styled.div`
  color: ${(props) => props.theme.colorV2.textTertiary};
  font-size: 1.6rem;
  margin-bottom: 3.2rem;
  text-align: center;
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
`;

const CancelButton = styled(Button)`
  color: ${({ theme }) => theme.colorV2.textTertiary};

  &:hover {
    color: ${({ theme }) => theme.colorV2.textTertiary};
    text-decoration: none;
  }
`;

type ConfirmBulkModalProps = {
  itemLabel?: string;
  numItems: number;
  onConfirm: () => void;
  onClose: () => void;
};

export const ConfirmBulkModal = ({ itemLabel = 'item', numItems, onConfirm, onClose }: ConfirmBulkModalProps) => (
  <div>
    <ModalTitle>
      Are you sure you want to add {numItems} {itemLabel}s at once?
    </ModalTitle>
    <ModalDescription>All created exercises can only be deleted manually, one by one.</ModalDescription>
    <ButtonContainer>
      <Button fullWidth onClick={onConfirm}>
        Create
      </Button>
      <CancelButton fullWidth variant="text" onClick={onClose}>
        Cancel
      </CancelButton>
    </ButtonContainer>
  </div>
);
