import { SelectedGroupsOfParentInterface } from '@common/interfaces/groups/SelectedGroupsOfParentInterface';
import { GenericContentInterface } from '@common/interfaces/contentTypes/GenericContentInterface';
import { LoadedExercisesInterface } from '@common/interfaces/exercises/LoadedExercisesInterface';
import { RecapOptionsInterface } from '@common/interfaces/contentTypes/RecapOptionsInterface';
import { PublishingInterface } from '@common/interfaces/contentTypes/PublishingInterface';
import { ReuseDataInterface } from '@common/interfaces/contentTypes/ReuseDataInterface';
import { CourseSliceInterface } from '@common/interfaces/slices/CourseSliceInterface';
import { AnyExerciseInterface } from '@common/types/exercises/AnyExerciseInterface';
import { AnyLoadedExercise } from '@common/types/exercises/AnyLoadedExercise';
import { CourseInterface } from '@common/interfaces/courses/CourseInterface';
import { ContentTypes } from '@common/enums/ContentTypes';
import { LoadingStage } from '@common/enums/LoadingStage';
import { createFetchable } from '@common/interfaces/slices/Fetchable';

export const BaseCourseContent: GenericContentInterface = {
  date: '',
  dates: {
    firstPublishedAt: null,
    lastChangedAt: null,
  },
  expanded: true,
  id: 'null',
  text: 'Untitled Course',
  title: 'Untitled Course',
  type: ContentTypes.course,
  description: '',
  position: [{ id: '1', position: 0, type: ContentTypes.course }],
  loaded: LoadingStage.loaded,
  titleWithLocalizations: undefined,
  descriptionWithLocalizations: undefined,
  mappings: 0,
  mappingsPath: [],
  validation: {
    validated: false,
    valid: false,
    errors: [],
    invalidChildEntities: [],
  },
  ready: false,
  changeStatus: {
    hasNewChanges: false,
    hasPendingChanges: false,
  },
  image: undefined,
  labels: [],
  identityGroup: null,
  owner: null,
};

export const courseInitialData: CourseInterface = {
  dates: {
    firstPublishedAt: null,
    lastChangedAt: null,
  },
  description: '',
  id: 'null',
  interfaceLanguages: [],
  isCreateButtonDisabled: false,
  learningLanguage: 'EN',
  name: BaseCourseContent.text,
  title: BaseCourseContent.text,
  loaded: LoadingStage.notLoaded,
  paywallImage: undefined,
  thumbnailImage: undefined,
  structure: [],
  loadingParentId: '',
  descriptionContentId: undefined,
  descriptionLocalizationIDForEnglish: undefined,
  titleWithLocalizations: undefined,
  descriptionWithLocalizations: undefined,
  validation: {
    validated: false,
    valid: false,
    errors: [],
    invalidChildEntities: [],
  },
  ready: false,
  changeStatus: {
    hasNewChanges: false,
    hasPendingChanges: false,
  },
  labels: [],
  isComplete: false,
  owner: null,
};

export const selectedGroupsOfParentInitialData: SelectedGroupsOfParentInterface = {
  loaded: LoadingStage.notLoaded,
  parentId: '',
  parentType: ContentTypes.course,
  parentContents: null,
  groups: [],
  groupsLoaded: LoadingStage.notLoaded,
};

export const loadedExercisesInitialData: LoadedExercisesInterface = {
  loaded: LoadingStage.notLoaded,
  parentId: '',
  exercises: [],
};

export const loadedExerciseInitialData: AnyLoadedExercise = {
  loaded: LoadingStage.notLoaded,
  exercise: null as any,
};

export const publishingInitialData: PublishingInterface = {
  preparedContent: {
    id: '',
    type: '',
    readyState: false,
    forUpdate: false,
    parentId: '',
    parentType: '',
  },
  inProgress: false,
  invalidChildren: [],
  errorInExercises: false,
  modalOpened: false,
};

export const recapOptionsInitialData: RecapOptionsInterface = {
  exercises: [],
  loaded: LoadingStage.notLoaded,
};

export const stringReuseModalInitialData: ReuseDataInterface = {
  stringReuseModalOpenedFor: '',
  contentType: '',
  visitedBranch: '',
  otherPlaces: {
    count: 0,
    contentMappings: [],
  },
};

export const activityPreviewExerciseInitialData: AnyExerciseInterface | null = null;

export const coursesInfoInitialData = {
  loaded: LoadingStage.notLoaded,
  courses: [],
};

const CourseInitialState: CourseSliceInterface = {
  course: courseInitialData,
  coursesInfo: coursesInfoInitialData,
  selectedGroupsOfParent: selectedGroupsOfParentInitialData,
  loadedExercises: loadedExercisesInitialData,
  loadedExercise: loadedExerciseInitialData,
  recapOptions: recapOptionsInitialData,
  stringReuseModal: stringReuseModalInitialData,
  preview: {
    exerciseForPreview: activityPreviewExerciseInitialData,
    exerciseForPreviewLoaded: false,
  },
  newContent: {
    newElementId: '',
    creatingContentPosition: undefined,
    isDeleteInProgress: false,
  },
  header: {
    isSaveButtonEnabled: false,
    isSaveInProgress: false,
    validationResultsLoading: false,
    publishing: publishingInitialData,
  },
  mediaUploading: {
    imageOrVideoUploadingInProcess: [],
    imageOrVideoUploadingFailed: [],
    audioUploadingInProcess: [],
    audioUploadingFailed: [],
  },
  resourceBundleData: {
    currentResourceBundle: createFetchable(),
    bundleOtherPlaces: [],
  },
};

export default CourseInitialState;
