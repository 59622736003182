import { useEffect } from 'react';
import { courseEditionAbandoned } from '@actionCreators/BusuuLogoActionCreator';
import { useAppDispatch } from '@redux/store';
import { DashboardTableWrapper } from './DashboardTable';

export const DashboardSection = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(courseEditionAbandoned());
  }, [dispatch]);

  return <DashboardTableWrapper />;
};
