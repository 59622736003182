import { ContentTypesActionsCreator } from '@actionCreators/ContentTypesActionsCreator';
import { DBId } from '@common/types/DBId';
import { Dropdown, DropdownInterface } from '@components/Dropdown';
import { useIsEditAvailable } from '@features/content/courses';
import { useAppDispatch } from '@redux/store';
import styled from 'styled-components/macro';

const LessonFocusWrapper = styled.div`
  margin-right: 2rem;

  .dropdown {
    width: 20rem;
  }
`;

const LESSON_FOCUSES: DropdownInterface[] = [
  { code: 'vocabulary', name: 'Vocabulary' },
  { code: 'grammar', name: 'Grammar' },
  { code: 'pronunciation', name: 'Pronunciation' },
  { code: 'reading', name: 'Reading' },
  { code: 'listening', name: 'Listening' },
  { code: 'writing', name: 'Writing' },
  { code: 'mixed', name: 'Mixed' },
  { code: 'review', name: 'Review' },
];

export const LessonObjetiveFocusSelector = ({ focus, id }: { focus: string; id: DBId }) => {
  const dispatch = useAppDispatch();
  const { isEditAvailable } = useIsEditAvailable();

  return (
    <LessonFocusWrapper>
      <Dropdown
        values={LESSON_FOCUSES}
        title="Select Lesson Focus"
        disabled={!isEditAvailable}
        onChange={(e) =>
          dispatch(
            ContentTypesActionsCreator.onLessonFocusChange({
              lessonId: id,
              focus: e.code,
            }),
          )
        }
        value={focus}
      />
    </LessonFocusWrapper>
  );
};
