import { useMemo } from 'react';
import styled from 'styled-components/macro';

import { BundleSearchResultType, BundleDetailsTab } from '../../types';
import {
  CardWrapper,
  CardTitle,
  CardMedia,
  CardLocations,
  CardMoreButton,
  CardTranslation,
  CardYesNoStatus,
} from '../Common';

const BundleCardWrapper = styled(CardWrapper)`
  grid-template-columns: 36% 10% 8% 8% 8% 20% 4%;
`;

export const BundleResultCard = ({
  content,
  isExpanded,
  onExpand,
}: {
  content: BundleSearchResultType;
  isExpanded: boolean;
  onExpand: (tabName: BundleDetailsTab['key'] | null) => void;
}) => {
  const plainTextTitle = useMemo(
    () => content.text?.replace(/(<([^>]+)>)|(&.+;)/gi, '') || 'Untitled bundle',
    [content.text],
  );

  return (
    <BundleCardWrapper isExpanded={isExpanded} onClick={() => onExpand(null)}>
      <CardTitle id={content.id} title={plainTextTitle} />
      <CardMedia image={content.image} hasVideo={content.hasVideo} />
      <CardYesNoStatus status={content.isVocabulary} />
      <CardTranslation type="audio" translations={content.audiosCount} />
      <CardTranslation type="text" translations={content.translationsCount} />
      <CardLocations
        courses={content.courses}
        locationsCount={content.locationsCount}
        onExpand={() => onExpand('locations')}
      />
      <CardMoreButton isExpanded={isExpanded} />
    </BundleCardWrapper>
  );
};
