import { allTypesMap } from '@helpers/contentCardHelper';
import { ExerciseTypes, type ExerciseTypesType } from '@common/enums/ExerciseTypes';

export const generateDefaultContext = (exerciseType: ExerciseTypesType, field: string, isPhrase?: boolean) => {
  const exerciseTypeForContext = allTypesMap[exerciseType];
  let fieldName = field === 'feedback' ? 'explanation' : field;

  const exerciseSpecificContextFieldName: { [exercise: string]: { [field: string]: string } } = {
    [ExerciseTypes.flashcard]: {
      learningWordBundle: isPhrase ? 'entity' : 'entity example',
    },
    [ExerciseTypes.multipleChoice]: {
      answerBundle: 'correct answer',
      distractor1: 'distractor',
      distractor2: 'distractor',
    },
    [ExerciseTypes.fillgap]: {
      mainBundle: 'phrase',
      distractor1: 'distractor',
      distractor2: 'distractor',
    },
    [ExerciseTypes.spelling]: {
      mainBundle: 'phrase',
    },
    [ExerciseTypes.speechRecognition]: {
      mainBundle: 'phrase',
    },
    [ExerciseTypes.typing]: {
      mainBundle: 'phrase',
    },
    [ExerciseTypes.phraseBuilder]: {
      mainBundle: 'phrase',
    },
    [ExerciseTypes.comprehension]: {
      mainBundle: 'text',
    },
    [ExerciseTypes.dialogue]: {
      dialogueDescription: 'description',
    },
    [ExerciseTypes.trueFalse]: {
      mainBundle: 'example',
    },
    [ExerciseTypes.matchup]: {
      fixedItem1: 'option',
      fixedItem2: 'option',
      fixedItem3: 'option',
      matchingItem1: 'option',
      matchingItem2: 'option',
      matchingItem3: 'option',
    },
  };

  return `${exerciseTypeForContext} ${exerciseSpecificContextFieldName[exerciseType]?.[field] || fieldName}.`;
};
