import { upperFirst } from 'lodash';

import type { DBId } from '@common/types/DBId';
import { CardPublishingStatus } from '@components/ContentTypes/BaseContent/CardPublishingStatus';
import IDDisplayer from '@components/IDDisplayer/IDDisplayer';

import type { VocabularyReviewListItem } from '../../types';

import { LexicalItemAudioLabel } from './LexicalItemAudioLabel';
import { LexicalItemMediaDisplay } from './LexicalItemMediaDisplay';
import {
  Ellipsed,
  ExercisesMatchedContainer,
  ExercisesMatchedCount,
  StyledExercisesMatchedIcon,
  PhraseWrapper,
  TableCell,
  TableRow,
} from './styles';
import { MouseEvent } from 'react';

export type LexicalItemsRowProps = {
  lexicalItem: VocabularyReviewListItem;
  showId: boolean;
  onClick: (lexicalItemId: DBId) => void;
  openMatchedExercisesModal: () => void;
};

export const LexicalItemRow = ({ lexicalItem, showId, onClick, openMatchedExercisesModal }: LexicalItemsRowProps) => {
  const {
    id,
    changeStatus,
    cefr,
    example,
    hasAudio,
    hasVideo,
    image,
    matchedExercisesCount,
    phrase,
    ready,
    speechPart,
    translationsCount,
  } = lexicalItem;

  const handleOnClickOnExercisesMatched = (evt: MouseEvent<HTMLDivElement>) => {
    evt.stopPropagation();

    matchedExercisesCount && openMatchedExercisesModal();
  };

  return (
    <TableRow data-testid="lexical-item-row" onClick={() => onClick(id)}>
      <TableCell>
        <CardPublishingStatus hasPendingChanges={changeStatus.hasPendingChanges} ready={ready} />
        <PhraseWrapper>
          {showId && <IDDisplayer id={id} mode="small" />}
          {upperFirst(phrase)}
        </PhraseWrapper>
      </TableCell>
      <TableCell>{translationsCount}</TableCell>
      <TableCell>
        <LexicalItemAudioLabel hasAudio={hasAudio} />
      </TableCell>
      <TableCell fontSize="1.4rem">
        <Ellipsed>
          <strong>{example}</strong>
        </Ellipsed>
      </TableCell>
      <TableCell>{upperFirst(speechPart)}</TableCell>
      <TableCell>{cefr.toUpperCase()}</TableCell>
      <TableCell>
        <LexicalItemMediaDisplay hasVideo={hasVideo} image={image} />
      </TableCell>
      <TableCell align="right" fontSize="1.4rem">
        <ExercisesMatchedContainer disabled={matchedExercisesCount === 0} onClick={handleOnClickOnExercisesMatched}>
          <ExercisesMatchedCount>{matchedExercisesCount}</ExercisesMatchedCount>
          {!!matchedExercisesCount && <StyledExercisesMatchedIcon />}
        </ExercisesMatchedContainer>
      </TableCell>
    </TableRow>
  );
};
