import { SectionActions } from '@actions/SectionActions';
import { ExerciseTypes } from '@common/enums/ExerciseTypes';
import { LoadingStage } from '@common/enums/LoadingStage';
import { LoadedFillGapExerciseInterface } from '@common/interfaces/exercises/LoadedExerciseInterface';
import { CourseSliceInterface } from '@common/interfaces/slices/CourseSliceInterface';
import ExerciseDataModelAssembler from '@components/Exercises/ExerciseDataModelAssembler';
import { UploadMediaResponse } from '@services/HelpersService';
import { updateTextField } from '@helpers/updateTextField';
import { FillGapExerciseActions } from '@actions/FillGapExerciseActions';
import FillGapExerciseInterface from '@components/Exercises/FillGap/interfaces/FillGapExerciseInterface';
import { selectedGroupsOfParentInitialData } from '@redux/initialStates/courseInitialState';
import { PayloadAction } from '@reduxjs/toolkit';
import { clone } from '@helpers/clone';
import { removeAudioDataFromBundle } from './common/helpers';
import { LocalizationInterface } from '@common/interfaces/localization/LocalizationInterface';

const FillGapExerciseCourseReducers = {
  [SectionActions.COURSE_EXERCISE_FILLGAP_REQUEST_SUCCEEDED]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    let clonedCourseSlice: CourseSliceInterface = clone(state);
    let { content }: FillGapExerciseInterface = payload;

    let exercise: FillGapExerciseInterface = {
      ...clonedCourseSlice.loadedExercise.exercise,
      content,
      type: ExerciseTypes.fillgap,
    };

    return {
      ...clonedCourseSlice,
      selectedGroupsOfParent: selectedGroupsOfParentInitialData,
      loadedExercise: {
        loaded: LoadingStage.loaded,
        exercise,
      },
    };
  },
  [FillGapExerciseActions.SET_INSTRUCTIONS_LANGUAGE]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    let clonedState: CourseSliceInterface = clone(state);

    let loadedExercise: LoadedFillGapExerciseInterface = clonedState?.loadedExercise as LoadedFillGapExerciseInterface;

    if ((loadedExercise.exercise as FillGapExerciseInterface).content !== undefined) {
      (loadedExercise.exercise as FillGapExerciseInterface).content.instructionsLanguage = payload.instructionsLanguage;
    }

    (loadedExercise.exercise as FillGapExerciseInterface).content.instructionsLanguageChanged = true;

    return {
      ...clonedState,
      loadedExercise,
    };
  },
  [FillGapExerciseActions.REMOVE_IMAGE]: (state: CourseSliceInterface): CourseSliceInterface => {
    return ExerciseDataModelAssembler.nullifyFieldInBundle({
      field: 'image',
      bundleName: 'mainBundle',
      state,
    });
  },
  [FillGapExerciseActions.REMOVE_AUDIO]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<LocalizationInterface[]>,
  ): CourseSliceInterface => {
    let clonedCourseSlice: CourseSliceInterface = clone(state);

    return removeAudioDataFromBundle(clonedCourseSlice, 'phrase', 'mainBundle', true);
  },
  [FillGapExerciseActions.SET_FILLGAP_ALL_FIELDS]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    let clonedCourseSlice: CourseSliceInterface = clone(state);

    const { values } = payload;

    clonedCourseSlice = updateTextField(clonedCourseSlice, values, 'instructions');
    clonedCourseSlice = updateTextField(clonedCourseSlice, values, 'phrase', 'mainBundle');
    clonedCourseSlice = updateTextField(clonedCourseSlice, values, 'phrase', 'distractor1');
    clonedCourseSlice = updateTextField(clonedCourseSlice, values, 'phrase', 'distractor2');
    clonedCourseSlice = updateTextField(clonedCourseSlice, values, 'feedback');

    return clonedCourseSlice;
  },

  [FillGapExerciseActions.REMOVE_VIDEO]: (state: CourseSliceInterface): CourseSliceInterface => {
    return ExerciseDataModelAssembler.nullifyFieldInBundle({
      field: 'video',
      bundleName: 'mainBundle',
      state,
    });
  },

  [FillGapExerciseActions.UPLOAD_VIDEO_SUCCESS]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<UploadMediaResponse>,
  ): CourseSliceInterface => {
    let { content, mediaId } = payload;

    state.loadedExercise.exercise.content.mainBundle = {
      ...state.loadedExercise.exercise.content.mainBundle,
      video: {
        ...content,
        _id: mediaId,
        changed: true,
      },
    };
    state.mediaUploading.imageOrVideoUploadingInProcess = state.mediaUploading.imageOrVideoUploadingInProcess.filter(
      (item) => item !== 'video',
    );

    return state;
  },
};
export const FillGapExerciseCourseReducersV2 = {
  fillDistractor1: (state: CourseSliceInterface) => {
    state.loadedExercise.exercise.content.distractor1 = {
      phrase:
        ExerciseDataModelAssembler.generateTranslationsPanelContentPopulatedWithEmptyLocalizations(
          'Fillgap distractor.',
        ),
      example: null,
      image: ExerciseDataModelAssembler.generateTranslationsPanelContentPopulatedWithEmptyLocalizations(),
      video: ExerciseDataModelAssembler.generateTranslationsPanelContentPopulatedWithEmptyLocalizations(),
      isVocabulary: false,
    };
  },
  fillDistractor2: (state: CourseSliceInterface) => {
    state.loadedExercise.exercise.content.distractor2 = {
      phrase:
        ExerciseDataModelAssembler.generateTranslationsPanelContentPopulatedWithEmptyLocalizations(
          'Fillgap distractor.',
        ),
      example: null,
      image: ExerciseDataModelAssembler.generateTranslationsPanelContentPopulatedWithEmptyLocalizations(),
      video: ExerciseDataModelAssembler.generateTranslationsPanelContentPopulatedWithEmptyLocalizations(),
      isVocabulary: false,
    };
  },
};

export default FillGapExerciseCourseReducers;
