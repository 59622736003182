import { useState, ReactNode } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { NavigationWarningModal } from '@components/ContentTypes/BaseContent/NavigationWarningModal';

type RoutingComponentProps = {
  children: ReactNode;
};
export const RoutingProvider = ({ children }: RoutingComponentProps) => {
  const [confirm, setConfirm] = useState(false);
  const [confirmCallback, setConfirmCallback] = useState<((ok: boolean) => void) | null>(null);

  const getConfirmation = (message: string, callback: (ok: boolean) => void) => {
    setConfirmCallback(() => callback);
    setConfirm(true);
  };

  return (
    <Router getUserConfirmation={getConfirmation}>
      {children}
      <NavigationWarningModal opened={confirm} confirmCallback={confirmCallback} setConfirm={setConfirm} />
    </Router>
  );
};
