import { AudioUploadActions } from '@actions/AudioUploadActions';

import type { ExerciseTypesType } from '@common/enums/ExerciseTypes';
import { CourseInterface } from '@common/interfaces/courses/CourseInterface';
import { LocalizationInterface } from '@common/interfaces/localization/LocalizationInterface';
import { AnyExerciseContentBranch, CourseContentBranches } from '@common/types/AnyExerciseContentBranch';
import { GenericContentInterface } from '@common/interfaces/contentTypes/GenericContentInterface';
import { AnyExerciseContentInterface } from '@common/types/exercises/AnyExerciseContentInterface';
import { DBId } from '@common/types/DBId';
import { AnyExerciseInterface } from '@common/types/exercises/AnyExerciseInterface';
import { Language } from '@features/content/languages';
import { TranslationsPanelContentInterface } from '@common/interfaces/exercises/TranslationsPanelContentInterface';
import { AnyLoadedExercise } from '@common/types/exercises/AnyLoadedExercise';
import { UploadMediaResponse } from '@services/HelpersService';

export const AudioUploadActionsCreator = {
  uploadSound: (
    exerciseId: DBId | undefined,
    exerciseType: ExerciseTypesType,
    courseLearningLanguage: Language,
    contentId: DBId | undefined,
    localization: LocalizationInterface | undefined,
    uploadedSound: File,
    visitedBranch: AnyExerciseContentBranch,
    row?: number | undefined,
    column?: number | undefined,
    forLearningLang?: boolean,
    bundleName?: string,
    progressHandler?: (progress: number) => void,
  ) => ({
    type: AudioUploadActions.UPLOAD_AUDIO,
    payload: {
      exerciseId,
      exerciseType,
      courseLearningLanguage,
      contentId,
      localization,
      uploadedSound,
      visitedBranch,
      row,
      column,
      forLearningLang,
      bundleName,
      progressHandler,
    },
  }),
  uploadAudioSucceeded: (payload: {
    localization?: LocalizationInterface;
    visitedBranch: AnyExerciseContentBranch;
    row?: number;
    column?: number;
    content: { id: string } & TranslationsPanelContentInterface;
    mediaURL: string;
    forLearningLang?: boolean;
    bundleName?: string;
  }) => ({
    type: AudioUploadActions.UPLOAD_AUDIO_SUCCEEDED,
    payload,
  }),
  uploadSoundForContentInTranslatorPanel: (
    exercise: AnyExerciseInterface | GenericContentInterface | CourseInterface | AnyExerciseContentInterface | {},
    exerciseId: DBId | undefined,
    exerciseType: ExerciseTypesType,
    courseLearningLanguage: string,
    contentId: DBId | undefined,
    localization: LocalizationInterface | undefined,
    uploadedSound: File | null,
    visitedBranch: AnyExerciseContentBranch | CourseContentBranches,
    row?: number | undefined,
    column?: number | undefined,
    bundleName?: string,
    progressHandler?: (progress: number) => void,
  ) => ({
    type: AudioUploadActions.UPLOAD_AUDIO_FOR_CONTENT_IN_TRANSLATOR_PANEL,
    payload: {
      exercise,
      exerciseId,
      exerciseType,
      courseLearningLanguage,
      contentId,
      localization,
      uploadedSound,
      visitedBranch,
      row,
      column,
      bundleName,
      progressHandler,
    },
  }),
  uploadAudioForContentInTranslatorPanelSucceeded: (payload: {
    exercise: AnyLoadedExercise | undefined;
    localization: LocalizationInterface | undefined;
    visitedBranch: AnyExerciseContentBranch | undefined;
    mediaURL: UploadMediaResponse['mediaURL'] | null;
    row?: number | undefined;
    column?: number | undefined;
    contentType: string;
    bundleName?: string;
    newContentId?: string;
  }) => ({
    type: AudioUploadActions.UPLOAD_AUDIO_FOR_CONTENT_IN_TRANSLATOR_PANEL_SUCCEEDED,
    payload,
  }),
};
