import { PayloadAction } from '@reduxjs/toolkit';
import produce from 'immer';

import { CourseSliceInterface } from '@common/interfaces/slices/CourseSliceInterface';
import { ContentOwnershipActions } from '@actions/ContentOwnershipActions';
import { SetContentOwnershipPayload } from '@features/content/contentOwnership';
import { ContentTypes, ContentType } from '@common/enums/ContentTypes';

const CourseContentOwnershipReducers = {
  [ContentOwnershipActions.SET_CONTENT_OWNER]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<SetContentOwnershipPayload>,
  ) => {
    const { owner, contentType } = payload;

    switch (contentType) {
      case ContentTypes.course:
        return produce(state, (draft) => {
          draft.course.owner = owner;
        });
      case ContentType.levelOrLesson:
        return produce(state, (draft) => {
          const { parentContents } = draft.selectedGroupsOfParent;

          if (parentContents) {
            parentContents.owner = owner;
          }
        });
      default:
        console.error(`Invalid content type for action ${ContentOwnershipActions.SET_CONTENT_OWNER}`);
    }
  },
};

export default CourseContentOwnershipReducers;
