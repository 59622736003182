import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHotkeys } from 'react-hotkeys-hook';
import { apiClient } from '@features/api';

import { useDialogModal, Modal } from '@features/modal';
import { Loader, Button } from '@features/theme';
import { ContentTypes } from '@common/enums/ContentTypes';
import { useAppSelector } from '@redux/store';
import { selectResourceBundleData } from '@selectors/CoursesSelectors';

type ContentJSONModalProps = {
  parentId?: string;
  contentId: string;
  type: string;
};

type ContentJSONProps = ContentJSONModalProps & {
  close: () => void;
  isOpen: boolean;
};

const JSONDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  font-size: 14px;
`;

const JSONData = styled.pre`
  height: 100%;
  max-height: 600px;
  overflow: scroll;
  margin-bottom: 30px;

  @include customStyledScrollbar();
`;

const LoaderContainer = styled.div`
  height: 100%;
`;

const StyledModal = styled(Modal)`
  width: 50rem;
  max-width: 70rem;
  height: 70rem;
`;

const ContentJSON = ({ isOpen, close, type, contentId, parentId }: ContentJSONProps) => {
  const resourceBundle = useAppSelector(selectResourceBundleData);

  const [json, setJSON] = useState('');

  useEffect(() => {
    const getJSON = async () => {
      setJSON('');

      let data;
      if (type === ContentTypes.course) {
        data = await apiClient.v2.get(`/content/courses/${contentId}/json`);
      } else if (type === ContentTypes.exercise) {
        if (resourceBundle && resourceBundle._id) {
          data = await apiClient.v2.get(`/content/resource-bundles/${resourceBundle._id}/json`);
        } else {
          data = await apiClient.v2.get(`/content/exercises/${contentId}/json?parentId=${parentId}`);
        }
      } else {
        data = await apiClient.v2.get(`/content/groups/${contentId}/json?parentId=${parentId}`);
      }

      if (data && data.status === 200) {
        setJSON(JSON.stringify(data.data, null, 2));
      }
    };

    if (contentId !== undefined && isOpen) {
      getJSON().catch(console.error);
    }
  }, [isOpen, type, contentId, parentId, resourceBundle]);

  return (
    <JSONDataContainer>
      {json ? (
        <JSONData>{json}</JSONData>
      ) : (
        <LoaderContainer>
          <Loader size="L" />
        </LoaderContainer>
      )}
      <Button onClick={close} variant="primary">
        Close
      </Button>
    </JSONDataContainer>
  );
};

export const ContentJSONModal = (props: ContentJSONModalProps) => {
  const { open, close, isOpen, modal } = useDialogModal((modalControls) => (
    <StyledModal isOpen={modalControls.isOpen} onClickOutside={modalControls.close} lockScroll>
      <ContentJSON {...props} {...modalControls} />
    </StyledModal>
  ));

  // option+control+j on mac
  useHotkeys('ctrl+alt+j', () => {
    isOpen ? close() : open();
  });

  return modal;
};
