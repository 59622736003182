import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import styled, { css } from 'styled-components/macro';

import { LoadingStage } from '@common/enums/LoadingStage';
import {
  Dropdown,
  dropdown_defaultCode,
  DropdownInterface,
  generateLanguageDropdownOptions,
} from '@components/Dropdown';
import { SearchableSelector, SelectorOptionType } from '@components/SearchableSelector/SearchableSelector';
import { useUser } from '@features/auth';
import { coursesInfoRequest } from '@actionCreators/CourseActionCreator';
import { AVAILABLE_LEARNING_LANGUAGES, Language, LANGUAGE_FLAGS, LANGUAGE_NAMES } from '@features/content/languages';
import { Button } from '@features/theme';
import { generateSAndSXlsxFile, generateXlsxFile } from '../CourseStatsService';
import { useToast } from '@features/app/toast';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { selectCoursesInfo } from '@selectors/CoursesSelectors';

type CourseStatsFiltersProps = {
  filterCourse: SelectorOptionType['value'];
  filterLanguage: DropdownInterface['code'];
  onCourseFilterChange: (code: DropdownInterface['code']) => void;
  onLanguageFilterChange: (code: DropdownInterface['code']) => void;
};

const FilterWrapper = styled.div<{ isFiltersInView: boolean }>`
  background-color: ${({ theme }) => theme.color.brandWhite};
  display: flex;
  justify-content: space-between;
  padding: 3rem 0;
  width: 100%;

  ${({ isFiltersInView }) => {
    if (!isFiltersInView) {
      return css`
        padding-left: 4rem;
        padding-right: 4rem;
        position: fixed;
        left: 0;
        top: 0;
        z-index: 100;
      `;
    }
  }}
`;
const FiltersContainer = styled.div`
  display: flex;
`;
const FilterContainer = styled.div`
  display: inline-block;
  min-width: 32rem;
  margin-right: 2.4rem;
`;
const FilterCourse = styled(SearchableSelector)`
  .searchable-selector__control {
    height: 4rem;
    font-size: 1.5rem;
  }

  .searchable-selector__value-container {
    height: 4rem;
    padding-left: 1.6rem;
    padding-right: 2.6rem;
  }

  .searchable-selector__menu {
    font-size: 1.5rem;
    border-color: #00000026 !important;
  }

  > div {
    border: 1px solid ${({ theme }) => theme.color.quaternaryDark} !important;
  }

  :after {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid;
    content: '';
    pointer-events: none;
    position: absolute;
    right: 10px;
    top: calc(50% - 2px);
  }
`;
const FilterLabel = styled.span`
  color: ${({ theme }) => theme.color.brandGreyscale800};
  font-size: 1.4rem;
  margin-left: 0.325rem;
`;
const LanguageDropdown = styled(Dropdown)`
  > button {
    margin: 0;
  }
`;

const ExportButtonsWrapper = styled.div`
  display: flex;
  gap: 1rem;
`;

const ExportButton = styled(Button)`
  display: flex;
  align-self: end;
  font-size: 1.6rem;
  font-weight: 700;
  min-width: 22.8rem;
`;

const ExportButtonAbbr = styled.abbr`
  &[title] {
    cursor: pointer;
    text-decoration: none;

    &:hover {
      text-decoration: underline dotted;
    }
  }
`;

export const CourseStatsFilters = ({
  filterCourse,
  filterLanguage,
  onCourseFilterChange,
  onLanguageFilterChange,
}: CourseStatsFiltersProps) => {
  const dispatch = useAppDispatch();
  const coursesInfo = useAppSelector(selectCoursesInfo);

  const { user } = useUser();
  const languageList = user?.languages.all ? AVAILABLE_LEARNING_LANGUAGES : (user?.languages.list as Language[]);
  const languagesDropdownFilters = generateLanguageDropdownOptions(languageList, 'All course languages');

  const [courseDropdownFilters, setCourseDropdownFilters] = useState<SelectorOptionType[]>([]);
  const [isFiltersInView, setIsFiltersInView] = useState<boolean>(true);

  const { ref } = useInView({
    threshold: 0.6,
    onChange: (inView) => setIsFiltersInView(inView),
  });

  const showToast = useToast();

  useEffect(() => {
    if (coursesInfo.loaded === LoadingStage.notLoaded) dispatch(coursesInfoRequest());
  }, [coursesInfo]);

  useEffect(() => {
    if (filterLanguage !== dropdown_defaultCode && coursesInfo.loaded === LoadingStage.loaded) {
      const coursesByLanguage = coursesInfo.courses.filter(
        ({ learningLanguage }) => learningLanguage === filterLanguage,
      );

      const coursesDropdownOptions = coursesByLanguage.map(({ id, title }) => ({
        label: title,
        value: id,
      }));

      setCourseDropdownFilters(coursesDropdownOptions);
    }
  }, [coursesInfo, filterCourse, filterLanguage]);

  return (
    <div ref={ref}>
      <FilterWrapper isFiltersInView={isFiltersInView}>
        <FiltersContainer>
          <FilterContainer>
            <FilterLabel>Language</FilterLabel>
            <LanguageDropdown
              id="course-stats-language-filter"
              values={languagesDropdownFilters}
              value={filterLanguage}
              title="All course languages"
              onChange={(selectedLanguage) => {
                setCourseDropdownFilters([]);
                onLanguageFilterChange(selectedLanguage.code);
              }}
            />
          </FilterContainer>
          {!!courseDropdownFilters.length && (
            <FilterContainer>
              <FilterLabel>Course</FilterLabel>
              <FilterCourse
                options={courseDropdownFilters}
                placeholder={`${LANGUAGE_NAMES[filterLanguage as keyof typeof LANGUAGE_FLAGS]} courses`}
                testId="course-stats-course-filter"
                value={filterCourse}
                onSelect={(selectedCourse) => {
                  if (selectedCourse) onCourseFilterChange(selectedCourse.value as string);
                }}
              />
            </FilterContainer>
          )}
        </FiltersContainer>

        <ExportButtonsWrapper>
          {filterLanguage !== dropdown_defaultCode && (
            <ExportButton
              onClick={() => {
                generateSAndSXlsxFile(filterLanguage);

                showToast({
                  type: 'info',
                  title: 'Download should start automatically in few seconds',
                });
              }}
            >
              Export {LANGUAGE_NAMES[filterLanguage as Language]}&nbsp;
              <ExportButtonAbbr title="Scope & Sequence">S&S</ExportButtonAbbr>
            </ExportButton>
          )}
          {filterLanguage !== dropdown_defaultCode && (
            <ExportButton
              onClick={() => {
                generateXlsxFile(filterLanguage);

                showToast({
                  type: 'info',
                  title: 'Download should start automatically in few seconds',
                });
              }}
            >
              Export {LANGUAGE_NAMES[filterLanguage as Language]} stats
            </ExportButton>
          )}
        </ExportButtonsWrapper>
      </FilterWrapper>
    </div>
  );
};
