import { DBId } from '@common/types/DBId';
import { NavigationItemType } from '@features/content/navigation';

type PrepareNewStructureArgs = {
  currentParentId: DBId;
  currentStructure: NavigationItemType[];
  newParentId: DBId;
  selectedContentId: DBId;
};

export const prepareNewStructure = ({
  currentParentId,
  currentStructure,
  newParentId,
  selectedContentId,
}: PrepareNewStructureArgs) =>
  currentStructure.map((structureItem) => {
    if (structureItem.id === selectedContentId) {
      return {
        ...structureItem,
        parentId: newParentId,
      };
    }

    if (structureItem.id === currentParentId) {
      // check if current parent location has more children besides the selected content to move
      const hasChildren =
        currentStructure.filter((structureItem) => structureItem.parentId === currentParentId).length > 1;

      return {
        ...structureItem,
        children: hasChildren,
        expanded: false,
      };
    }

    if (structureItem.id === newParentId) {
      return {
        ...structureItem,
        children: true,
        expanded: true,
      };
    }

    return structureItem;
  });
