import { ValidationErrorInterface } from '@common/interfaces/validation/ValidationInterface';

type RemoveMediaProcessingValidationErrorsParams = {
  errors: ValidationErrorInterface[];
  fieldName?: string;
  message: string;
};

export const removeMediaProcessingValidationError = ({
  errors,
  fieldName,
  message,
}: RemoveMediaProcessingValidationErrorsParams) => {
  let updatedErrors = [...errors];

  if (fieldName) {
    const targetValidationErrorIndex = updatedErrors.findIndex(
      (error) => error.field === fieldName && error.message.includes(message),
    );

    if (targetValidationErrorIndex !== -1) {
      updatedErrors.splice(targetValidationErrorIndex, 1);
    }
  } else {
    updatedErrors = errors.filter((error) => !error.message.includes(message));
  }

  return updatedErrors;
};
