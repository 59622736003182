import { FormikContextType, useFormikContext } from 'formik';
import { FormikValuesInterface } from '@helpers/formikInitialValuesHelper';
import BidimensionalEditor from '@components/BidimensionalEditor/BidimensionalEditor';
import { useIsEditorHidden } from '@helpers/useHideEditor';
import { constants as contentConstants } from '@features/content';
import { TranslationsPanelModes } from '@components/TranslationsPanel/enums/TranslationsPanelModes';
import { CommonExerciseActionsCreator } from '@actionCreators/CommonExerciseActionsCreator';
import { BidimensionalEditorModes } from '@components/BidimensionalEditor/enums/BidimensionalEditorModes';
import { ValidationErrorInterface } from '@common/interfaces/validation/ValidationInterface';
import TranslationsTipV2 from '@components/TranslationTipV2/TranslationTipV2';
import ContentToggler from '@components/Exercises/Common/ContentToggler/ContentToggler';
import { HelpDisplayer } from '@features/help';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { selectLoadedExerciseData } from '@selectors/CoursesSelectors';
import { LockContent } from '@features/content/exercises';

const ExplanationEditor = ({ feedbackErrors }: { feedbackErrors: ValidationErrorInterface[] }) => {
  return (
    <TranslationsTipV2
      visitedBranch="feedback"
      showLanguageSwitcher={false}
      errors={feedbackErrors}
      showErrorDisplayer={false}
      translationsPanelTranslationsMode={TranslationsPanelModes.wysiwyg}
    >
      <BidimensionalEditor
        mode={BidimensionalEditorModes.mono}
        storeBranch="feedback"
        language={contentConstants.DEFAULT_LANGUAGE}
        maxColums={1}
        dataSourceMode="editorState"
        ignoreLanguageForTips={true}
        toolbar={{
          options: ['inline'],
          inline: {
            options: ['bold'],
          },
        }}
        toolbarCustomButtons={[<LockContent />]}
        charactersLimit={120}
      />
    </TranslationsTipV2>
  );
};

export const Explanation = () => {
  const dispatch = useAppDispatch();
  const { values, setFieldValue }: FormikContextType<FormikValuesInterface> = useFormikContext();
  const { isEditorHidden } = useIsEditorHidden({ visitedBranch: 'feedback' });

  const loadedExercise = useAppSelector(selectLoadedExerciseData);

  const { errors } = loadedExercise?.content?.validation;

  const feedbackErrors = errors.filter((error: ValidationErrorInterface) => error.field === 'feedback');

  return (
    <ContentToggler
      text="Explanation"
      guidelineHelp={<HelpDisplayer type="guideline" id="guideline-explanation" />}
      open={!!loadedExercise.content.feedback?._id}
      onRemove={() => {
        dispatch(CommonExerciseActionsCreator.nullifyExplanation());
        setFieldValue(
          `feedback`,
          values.feedback?.map((item: any) => ({ ...item, value: '' })),
        );
        setFieldValue(`feedbackChanged`, true);
      }}
      errors={feedbackErrors}
    >
      {isEditorHidden ? (
        <div>
          <ExplanationEditor feedbackErrors={feedbackErrors} />
        </div>
      ) : (
        <>
          <ExplanationEditor feedbackErrors={feedbackErrors} />
        </>
      )}
    </ContentToggler>
  );
};
