import type { DBId } from '@common/types/DBId';
import type { OtherLocationsInterface } from '@common/interfaces/exercises/OtherLocationsInterface';
import type { TranslationsPanelModesType } from '@components/TranslationsPanel/enums/TranslationsPanelModes';
import { ContentTypesActions } from '@actions/ContentTypesActions';
import { ContentTypes, ContentType } from '@common/enums/ContentTypes';
import { TranslationsPanelActions } from '@actions/TranslationsPanelActions';

export const TranslationTipActionsCreator = {
  populateBranch: (visitedBranch: string, bundleName?: string, row?: number) => ({
    type: TranslationsPanelActions.POPULATE_BRANCH,
    payload: {
      visitedBranch,
      bundleName,
      row,
    },
  }),
  showTranslationsPanel(
    preopenedTab: 'translations' | 'audios',
    visitedId: DBId | undefined,
    visitedKey: string | undefined,
    visitedBranch: string,
    translationsPanelTranslationsMode: TranslationsPanelModesType,
    localizationIDForLangExpertsToBeDisplayed: DBId | undefined,
    row: number | undefined,
    column: number | undefined,
    cells: any[][] | undefined,
    showOtherLocations: boolean,
    disableLearningLanguage?: boolean,
    type?: typeof ContentType.levelOrLesson | typeof ContentTypes.course | typeof ContentTypes.exercise,
    bundleName?: string,
    isStringChangeBlocked?: boolean,
    isBundleChangeBlocked?: boolean,
    defaultContextForTranslators?: string,
  ) {
    visitedKey = visitedKey === undefined ? 'New string' : visitedKey;

    let actionType: string = TranslationsPanelActions.SHOW;

    switch (type) {
      case ContentType.levelOrLesson:
        actionType = TranslationsPanelActions.SHOW_FOR_LEVEL_LESSON;
        break;
      case ContentTypes.course:
        actionType = TranslationsPanelActions.SHOW_FOR_COURSE;
        break;
      default:
        actionType = TranslationsPanelActions.SHOW;
        break;
    }

    return {
      type: actionType,
      payload: {
        preopenedTab,
        visitedKey,
        visitedId,
        visitedBranch,
        bundleName,
        translationsPanelTranslationsMode,
        localizationIDForLangExpertsToBeDisplayed,
        row,
        column,
        cells,
        showOtherLocations,
        disableLearningLanguage,
        isStringChangeBlocked,
        isBundleChangeBlocked,
        defaultContextForTranslators,
      },
    };
  },
  getBundleLocations: (id: string) => ({
    type: TranslationsPanelActions.GET_BUNDLE_LOCATIONS,
    payload: { id },
  }),
  setErrorBundlePlaces: (otherLocations: OtherLocationsInterface[][]) => ({
    type: ContentTypesActions.SET_ERROR_BUNDLE_PLACES,
    payload: { otherLocations },
  }),
  checkOtherLocations: () => ({
    type: TranslationsPanelActions.CHECK_OTHER_LOCATIONS,
  }),
  setCurrentContentId: (
    contentId: string,
    type: string,
    visitedBranch: string,
    row?: number,
    column?: number,
    bundleName?: string,
    isBundleChangeBlocked?: boolean,
  ) => ({
    type: ContentTypesActions.SET_CURRENT_CONTENT_ID,
    payload: {
      contentId,
      type,
      visitedBranch,
      row,
      column,
      bundleName,
      isStringChangeBlocked: false,
      isBundleChangeBlocked,
    },
  }),
  editEverywhere: (
    contentType: string,
    visitedBranch: string,
    contentId: string,
    row: number,
    column: number,
    bundleName?: string,
    isStringChangeBlocked?: boolean,
    isBundleChangeBlocked?: boolean,
  ) => ({
    type: ContentTypesActions.EDIT_EVERYWHERE,
    payload: {
      contentType,
      visitedBranch,
      contentId,
      row,
      column,
      bundleName,
      isStringChangeBlocked,
      isBundleChangeBlocked,
    },
  }),
  makeCopy: (
    contentType: string,
    visitedBranch: string,
    row: number,
    column: number,
    bundleName?: string,
    isStringChangeBlocked?: boolean,
    isBundleChangeBlocked?: boolean,
  ) => ({
    type: TranslationsPanelActions.MAKE_COPY,
    payload: {
      contentType,
      visitedBranch,
      row,
      column,
      bundleName,
      isStringChangeBlocked,
      isBundleChangeBlocked,
    },
  }),
  removeString: (type: string, visitedBranch: string, row?: number, column?: number, bundleName?: string) => ({
    type: TranslationsPanelActions.REMOVE_STRING,
    payload: { contentType: type, visitedBranch, row, column, bundleName },
  }),
};
