import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { FormikContextType, useFormikContext } from 'formik';

import { TranslationsPanelContentInterface } from '@common/interfaces/exercises/TranslationsPanelContentInterface';
import { SearchableSelector, SelectorOptionType } from '@components/SearchableSelector/SearchableSelector';
import { GrammarReviewService } from '@services/GrammarReviewService';
import { DBId } from '@common/types/DBId';
import { GrammarReviewActionCreators } from '@actionCreators/GrammarReviewActionCreators';
import { GrammarCategoryFormikValues } from '../types';
import { useAppDispatch } from '@redux/store';

const SearchableSelectorWithErrorState = styled(SearchableSelector)<{ isIssuesShown: boolean }>`
  margin-bottom: 4px;
  ${({ isIssuesShown, theme }) =>
    isIssuesShown &&
    `
      border-radius: 8px;
      border: 1px solid ${theme.colorV2.grammarCategorySearchableSelectorErrorText};
    `}
`;

export const NamesSelector = ({ disabled, isIssuesShown }: { disabled: boolean; isIssuesShown: boolean }) => {
  const dispatch = useAppDispatch();
  const { values, setFieldValue }: FormikContextType<GrammarCategoryFormikValues> = useFormikContext();
  const { courseId } = useParams<{ courseId: DBId }>();

  const [isNamesLoading, setIsNamesLoading] = useState<boolean>(false);
  const [names, setNames] = useState<TranslationsPanelContentInterface[]>([]);

  useEffect(() => {
    if (!names.length && !isNamesLoading) {
      setIsNamesLoading(true);
      GrammarReviewService.getGrammarCategoryNames(courseId).then((result) => {
        setNames(result.data);
        setIsNamesLoading(false);
      });
    }
  }, [names, courseId, isNamesLoading]);

  const namesOptions = names.map((name) => ({
    label: name.textLocalizations.find((loc) => loc.language === 'EN')?.value || '',
    value: name._id || '',
  }));

  const onNameSelect = (selectedOption: SelectorOptionType | null) => {
    const title = names.find((name) => name._id === selectedOption?.value);

    if (title) {
      dispatch(GrammarReviewActionCreators.setGrammarCategoryTitle(title));

      const newValues = values.title.map((item) => {
        const titleValue = title.textLocalizations.find((loc) => loc.language === item.language);
        if (item.language === titleValue?.language) {
          return {
            ...item,
            value: item.isPhonetic ? titleValue.phoneticValue : titleValue.value,
          };
        }
        return item;
      });
      setFieldValue('title', newValues);
      setFieldValue('titleChanged', true);
      setFieldValue('titleContext', title.description);
    }
  };

  return (
    <SearchableSelectorWithErrorState
      placeholder="Grammar Category"
      onSelect={onNameSelect}
      disabled={disabled}
      options={namesOptions}
      isIssuesShown={isIssuesShown}
    />
  );
};
