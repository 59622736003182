import { FunctionComponent } from 'react';
import addFeedbackIcon from './img/addFeedback.svg';
import phonemeIcon from './img/Phoneme.svg';
import { MultiOptionChooserProps } from './MultiOptionChooserProps';

const MultiOptionChooser: FunctionComponent<MultiOptionChooserProps> = ({ onClick }: MultiOptionChooserProps) => {
  return (
    <ul className={`multi-option-chooser`}>
      <li className="multi-option-chooser__option">
        <div className="multi-option-chooser__option-inner" onClick={() => onClick && onClick(1)}>
          <img src={addFeedbackIcon} alt="" className="multi-option-chooser__option-image" />
          <span>Add Feedback</span>
        </div>
      </li>
      <li className="multi-option-chooser__option">
        <div className="multi-option-chooser__option-inner" onClick={() => onClick && onClick(2)}>
          <img src={phonemeIcon} alt="" className="multi-option-chooser__option-image" />
          <span>Use Phoneme model</span>
        </div>
      </li>
    </ul>
  );
};

export default MultiOptionChooser;
