import styled from 'styled-components/macro';

import { SlideContentProps } from '../../PreviewModalProps';
import { ReactComponent as DownloadIcon } from './img/download.svg';

const SlideContentTitle = styled.h3`
  font-weight: 700;
  margin-bottom: 1.6rem;
`;

const SlideContentLink = styled.a`
  color: ${({ theme }) => theme.colorV2.accentPrimary};
  cursor: pointer;
  font-size: 1.6rem;

  &:hover {
    span {
      text-decoration: underline;
    }
  }

  svg {
    margin-right: 0.4rem;

    path {
      fill: ${({ theme }) => theme.colorV2.accentPrimary};
    }
  }
`;

export const SlideContent = ({ content, slideFormat }: SlideContentProps) => {
  let fileUrl = '';

  if (slideFormat === 'pdf') {
    fileUrl = content.pdfUrl as string;
  } else if (slideFormat === 'pptx') {
    fileUrl = content.pptxUrl as string;
  }

  if (!fileUrl) return null;

  return (
    <div>
      <SlideContentTitle>Lesson materials</SlideContentTitle>
      <SlideContentLink download href={fileUrl}>
        <DownloadIcon />
        <span>Download material ({slideFormat.toUpperCase()})</span>
      </SlideContentLink>
    </div>
  );
};
