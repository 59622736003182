import { call, select } from 'redux-saga/effects';
import type { AppDispatch } from '@redux/store';

import SlidePptxExerciseInterface from '@components/Exercises/SlidePptx/interfaces/SlidePptxExerciseInterface';
import ExerciseSagas from '@sagas/exercises/definitions/ExerciseSagas';
import SlidePptxExerciseService from '@services/ExerciseRelatedServices/SlidePptxExerciseService';
import { selectLoadedExerciseData } from '@selectors/CoursesSelectors';

const SlidePptxExerciseSagas = {
  *save(
    dispatch: AppDispatch,
    {
      type,
      payload,
    }: {
      type: string;
      payload: {
        exercise: SlidePptxExerciseInterface;
      };
    },
  ) {
    let exercise: SlidePptxExerciseInterface = yield select(selectLoadedExerciseData);

    yield call(SlidePptxExerciseService.save, dispatch, exercise);

    yield call(ExerciseSagas.requestOneExerciseCommon);
  },
};

export default SlidePptxExerciseSagas;
