import { useAppDispatch, useAppSelector } from '@redux/store';
import { Dispatch, SetStateAction, useState } from 'react';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components/macro';

import { Button, Loader } from '@features/theme';

import { ReactComponent as DownloadCompleteIcon } from './img/download-complete.svg';
import { ReactComponent as ErrorIcon } from './img/error.svg';
import { ReactComponent as ReuploadIcon } from './img/reupload.svg';
import { AttachUploadedAudiosPayload, UploadAudiosPhase } from './types';
import { UploadAudiosActionsCreator } from '@actionCreators/UploadAudiosActionsCreator';
import { selectUploadAudiosPanel } from '@selectors/UiSelectors';

const StyledModal = styled(Modal)`
  .modal-header {
    border: none;
    height: 4rem;

    .close {
      color: ${({ theme }) => theme.colorV2.textTertiary};
      font-size: 3.2rem;
      font-weight: normal;
      margin: 0;
      margin-top: 0;
      opacity: 1;
      padding: 0;
      padding-top: 0;
      position: absolute;
      right: 1rem;
      top: 0rem;
      z-index: 100;
    }
  }

  .modal-body {
    padding: 0 4rem 4rem 4rem;
  }

  .modal-content {
    border-radius: 1.6rem;
    width: 56rem;
  }
`;

const Title = styled.h2`
  color: ${({ theme }) => theme.colorV2.textPrimary};
  font-weight: 700;
  margin-bottom: 0.8rem;
  text-align: center;
`;

const Description = styled.div`
  color: ${({ theme }) => theme.colorV2.textTertiary};
  font-size: 1.8rem;
  line-height: 1.5;
  margin-bottom: 3.2rem;
  text-align: center;
`;

const UploadArea = styled.label`
  align-items: center;
  color: ${({ theme }) => theme.colorV2.accentPrimary};
  background: ${({ theme }) => theme.colorV2.utilityInfoBackground};
  border: 0.1rem solid ${({ theme }) => theme.colorV2.filterInputBaseBorder};
  border-radius: 0.8rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 1.8rem;
  height: 8.8rem;
  justify-content: center;
  margin-bottom: 3.2rem;
  min-width: 48rem;
  padding: 1.6rem;
`;

const AudioFileName = styled.div`
  font-size: 1.4rem;
  max-width: 44rem;
  min-height: 3.2rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const ButtonArea = styled.div`
  display: flex;
  justify-content: center;
`;

const NotMatchedAudioFileList = styled.ul`
  color: ${({ theme }) => theme.colorV2.textTertiary};
  font-size: 1.6rem;
  margin-bottom: 3.2rem;
`;

const StyledDownloadCompleteIcon = styled(DownloadCompleteIcon)`
  display: block;
  margin: 0 auto 1.6rem auto;
`;

type UploadAudiosProps = {
  show: boolean;
  onHide: () => void;
};

export const UploadAudios = ({ show, onHide }: UploadAudiosProps) => {
  const dispatch = useAppDispatch();

  const { payloadText, errors, notMatchedAudioFiles, phase } = useAppSelector(selectUploadAudiosPanel);

  const [audioFiles, setAudioFiles] = useState<FileList | null>(null);

  const close = () => {
    setAudioFiles(null);
    dispatch(UploadAudiosActionsCreator.close());
    onHide();
  };

  const onUpload = () => {
    if (audioFiles) dispatch(UploadAudiosActionsCreator.upload({ audioFiles }));
  };

  return (
    <UploadAudiosModal
      audioFiles={audioFiles}
      errorText={payloadText}
      errors={errors}
      notMatchedAudioFiles={notMatchedAudioFiles}
      phase={phase}
      show={show}
      close={close}
      onUpload={onUpload}
      setAudioFiles={setAudioFiles}
    />
  );
};

type UploadAudiosModalProps = {
  audioFiles: FileList | null;
  errorText?: string;
  errors?: string[];
  notMatchedAudioFiles?: AttachUploadedAudiosPayload['results'];
  phase: UploadAudiosPhase;
  show: boolean;
  close: () => void;
  onUpload: () => void;
  setAudioFiles: Dispatch<SetStateAction<FileList | null>>;
};

const StartPhaseUploadAudios = ({ audioFiles, errorText, errors, onUpload, setAudioFiles }: UploadAudiosModalProps) => {
  const renderTitle = (errorText: string) => {
    const title = errorText ? 'Files upload error' : 'Upload Audios';

    return (
      <Title>
        {errorText && (
          <>
            <ErrorIcon />
            &nbsp;
          </>
        )}
        <span>{title}</span>
      </Title>
    );
  };

  return (
    <Modal.Body>
      {renderTitle(errorText as string)}
      <Description>
        {errorText || errors?.length ? (
          <>
            <p>
              Sorry,{' '}
              {errors?.length
                ? 'the audios below could not be uploaded'
                : 'there was an error uploading your files, please try again'}
              .
            </p>
            {errorText && <p>{errorText}</p>}
          </>
        ) : (
          <p>Choose your audio files to upload.</p>
        )}
      </Description>

      <UploadArea htmlFor="browse-file-upload">
        {audioFiles?.length ? (
          Array.from(audioFiles).map((file) => (
            <AudioFileName key={file.name}>
              {file.name}&nbsp;
              <ReuploadIcon />
            </AudioFileName>
          ))
        ) : (
          <span>Choose files</span>
        )}

        <input
          id="browse-file-upload"
          hidden
          multiple
          type="file"
          onChange={(evt) => {
            if (evt.target.files !== null) {
              setAudioFiles(evt.target.files);
            }
          }}
        />
      </UploadArea>

      <ButtonArea>
        <Button disabled={!audioFiles} onClick={onUpload}>
          Upload
        </Button>
      </ButtonArea>
    </Modal.Body>
  );
};

const DonePhaseUploadAudios = ({ notMatchedAudioFiles, close }: UploadAudiosModalProps) => {
  const descriptionText = notMatchedAudioFiles?.length
    ? 'The following audio files do not match audio request name:'
    : 'Audio files have been succesfully uploaded';

  return (
    <Modal.Body>
      <Title>Upload complete</Title>
      <Description>{descriptionText}</Description>
      {notMatchedAudioFiles?.length ? (
        <NotMatchedAudioFileList>
          {notMatchedAudioFiles.map((audioFile) => (
            <li key={audioFile.fileName}>
              <AudioFileName>{audioFile.fileName}</AudioFileName>
            </li>
          ))}
        </NotMatchedAudioFileList>
      ) : (
        <StyledDownloadCompleteIcon />
      )}

      <ButtonArea>
        <Button onClick={close}>Close</Button>
      </ButtonArea>
    </Modal.Body>
  );
};

export const UploadAudiosModal = (props: UploadAudiosModalProps) => {
  return (
    <StyledModal show={props.show} onHide={props.close} centered>
      <Modal.Header closeButton={props.phase !== 'progress'} />
      {props.phase === 'start' && <StartPhaseUploadAudios {...props} />}
      {props.phase === 'progress' && (
        <Modal.Body>
          <Title>Upload in progress</Title>
          <Description>Please wait until the uploading is complete</Description>
          <Loader />
        </Modal.Body>
      )}
      {props.phase === 'done' && <DonePhaseUploadAudios {...props} />}
    </StyledModal>
  );
};
