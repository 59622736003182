// @TODO Replace enum-like object with constants and a type
export const Sizes = {
  large: 'large',
  medium: 'medium',
  small: 'small',
  fullscreen: 'fullscreen',
  'drag-drop-large': 'drag-drop-large',
  'drag-drop-small': 'drag-drop-small',
} as const;
export type SizesType = (typeof Sizes)[keyof typeof Sizes];
