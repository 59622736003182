import { useFormik } from 'formik';
import { MouseEvent, useState } from 'react';
import styled from 'styled-components/macro';
import { ListItemNode, ListNode } from '@lexical/list';

import { ImageUploadModes } from '@common/enums/FileUploadModes';
import { ValidationErrorInterface } from '@common/interfaces/validation/ValidationInterface';
import { ExerciseCommonActionCreators as ExerciseCommonActions } from '@actionCreators/ExerciseCommonActionCreator';
import { HelpToggler } from './HelpToggler';
import { ImageUpload, VideoUpload } from '@components/MediaUpload';
import { Button, BaseEditor, InitialValuePlugin, ToolbarPlugin } from '@features/theme';
import ContentService from '@services/ContentsService';
import MediaService from '@services/MediaService';

import { HelpHeader, HelpBody, HelpFooter } from './styles';
import { HelpDataType } from '../types';
import { useAppDispatch } from '@redux/store';

const StyledBaseEditor = styled(BaseEditor)`
  background-color: ${({ theme }) => theme.colorV2.uiBackgroundPrimary};
  font-size: 1.4rem;
  height: 29.4rem;
  overflow: auto;
  margin-bottom: 1.6rem;
`;

const ContentTogglerWrapper = styled.div`
  margin-bottom: 1rem;

  .file-upload {
    height: 18.7rem !important;
    width: 31.2rem !important;
  }

  .video-upload {
    height: 18.7rem !important;
  }

  .video-upload--processing {
    min-height: 0;
  }
`;

const Input = styled.input<{ name: string }>`
  border: solid 0.1rem ${({ theme }) => theme.colorV2.input.border.default};
  border-radius: 0.8rem;
  font-size: ${({ name }) => (name === 'title' ? '1.8rem' : '1.4rem')};
  font-weight: ${({ name }) => (name === 'title' ? 700 : 400)};
  height: 4rem;
  margin-bottom: 1.6rem;
  padding: 0 1.6rem;
  width: 100%;

  &:focus {
    border-color: ${({ theme }) => theme.colorV2.input.border.active};
  }
`;

type HelpFormProps = {
  data: HelpDataType;
  title: string;
  onCancel: () => void;
  onSave: (values: HelpDataType) => void;
};

export const HelpForm = ({ data, title, onCancel, onSave }: HelpFormProps) => {
  const dispatch = useAppDispatch();
  const [uploadImageError, setUploadImageError] = useState<ValidationErrorInterface | null>(null);
  const [uploadVideoError, setUploadVideoError] = useState<ValidationErrorInterface | null>(null);

  const { values, handleChange, setFieldValue } = useFormik({
    initialValues: data,
    onSubmit: () => {},
  });

  const handleOnCancel = (evt: MouseEvent<HTMLButtonElement>) => {
    evt.stopPropagation();
    onCancel();
  };

  return (
    <form>
      <HelpHeader>{title}</HelpHeader>

      <HelpBody scroll={false}>
        <StyledBaseEditor
          headerPlugins={[<ToolbarPlugin key="_toolbarPlugin" config={{ underline: false }} />]}
          editorPlugins={[
            <InitialValuePlugin key="_initialValuePlugin" initialValue={data.description} valueType="markdown" />,
          ]}
          nodes={[ListNode, ListItemNode]}
          onChange={({ markdown }) => {
            setFieldValue('description', markdown);
          }}
        />

        <ContentTogglerWrapper>
          <HelpToggler open={!!values.link} text="Link" onRemove={() => setFieldValue('link', '')}>
            <Input name="link" type="text" value={values.link || ''} onChange={handleChange} />
          </HelpToggler>
        </ContentTogglerWrapper>

        <ContentTogglerWrapper>
          <HelpToggler open={!!values.image} text="Image" onRemove={() => setFieldValue('image', null)}>
            <ImageUpload
              errors={uploadImageError ? [uploadImageError] : []}
              imageData={{
                contentId: values.image?.id ?? '',
                mediaId: values.image?.localizationId ?? '',
                mediaValue: values.image?.value ?? '',
                processed: !!values.image?.processed,
              }}
              withHelp={false}
              fieldName="help-image"
              mode={ImageUploadModes.normal}
              trashButtonAvailableToUser
              onChange={async (uploadedImage, progressHandler) => {
                if (uploadedImage !== null) {
                  dispatch(ExerciseCommonActions.setImageLoadingStarted('help-image'));
                  try {
                    const { fileId } = await MediaService.uploadImage(uploadedImage, progressHandler);
                    const newContentId = await ContentService.contents.createNewContent({
                      imageLocalizations: [
                        {
                          language: 'en',
                          fileId: fileId,
                          type: 'course_thumbnail',
                        },
                      ],
                    });

                    setFieldValue('image', { id: newContentId, localizationId: fileId, processed: false, value: '' });
                    dispatch(ExerciseCommonActions.setImageLoadingFinished('help-image'));
                  } catch (error: any) {
                    dispatch(ExerciseCommonActions.setImageLoadingFinished('help-image'));
                    setUploadImageError({
                      field: 'image',
                      isWarning: false,
                      message: error.message || 'Image upload failed',
                    });
                  }
                }
              }}
              onProcessingFinished={(url: string) => {
                setFieldValue('image', { ...values.image, value: url });
              }}
              onRemove={() => setFieldValue('image', null)}
            />
          </HelpToggler>
        </ContentTogglerWrapper>

        <ContentTogglerWrapper>
          <HelpToggler open={!!values.video} text="Video" onRemove={() => setFieldValue('video', null)}>
            <VideoUpload
              errors={uploadVideoError ? [uploadVideoError] : []}
              videoData={{
                contentId: values.video?.id ?? '',
                mediaId: values.video?.localizationId ?? '',
                mediaValue: values.video?.url ?? '',
                processed: !!values.video?.processed,
              }}
              withHelp={false}
              fieldName="help-video"
              onChange={async (uploadedVideo, progressHandler) => {
                if (uploadedVideo !== null) {
                  dispatch(ExerciseCommonActions.setImageLoadingStarted('help-video'));
                  try {
                    const { fileId } = await MediaService.uploadVideo(uploadedVideo, progressHandler);
                    const newContentId = await ContentService.contents.createNewContent({
                      videoLocalizations: [
                        {
                          language: 'en',
                          fileId: fileId,
                          type: 'course_thumbnail',
                        },
                      ],
                    });

                    setFieldValue('video', { id: newContentId, localizationId: fileId, processed: false, value: '' });
                    dispatch(ExerciseCommonActions.setImageLoadingFinished('help-video'));
                  } catch (error: any) {
                    dispatch(ExerciseCommonActions.setImageLoadingFinished('help-video'));
                    setUploadVideoError({
                      field: 'video',
                      isWarning: false,
                      message: error.message || 'Video upload failed',
                    });
                  }
                }
              }}
              onProcessingFinished={(url: string) => {
                setFieldValue('video', { ...values.video, value: url });
              }}
              onRemove={() => setFieldValue('video', null)}
            />
          </HelpToggler>
        </ContentTogglerWrapper>
      </HelpBody>

      <HelpFooter>
        <Button size="S" variant="tertiary" onClick={handleOnCancel}>
          Cancel
        </Button>
        <Button size="S" variant="primary" onClick={() => onSave(values)}>
          Save
        </Button>
      </HelpFooter>
    </form>
  );
};
