import styled from 'styled-components/macro';

export const HelpHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.4rem;
`;

export const HelpBody = styled.div<{ scroll: boolean }>`
  font-size: 1.2rem;
  font-weight: 400;
  margin-bottom: 1.6rem;
  height: ${({ scroll }) => (scroll ? '19.6rem' : 'auto')};
  line-height: 1.5;
  overflow: auto;
`;

export const HelpFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.4rem;
`;
