import { Formik, Form, Field } from 'formik';
import { useState } from 'react';
import Select from 'react-select';
import moment from 'moment';
import 'moment-timezone';

import { UserInterface } from '@common/interfaces/users/UserInterface';
import { Loader } from '@features/theme';
import UsersService from '@services/UsersService';
import {
  PersonalInfoCardWrapper,
  FormHeader,
  EditImage,
  FormDataItem,
  ItemFieldValue,
  ItemFieldInputWrapper,
  FooterWrapper,
  CancelButton,
  SaveButton,
} from './PersonalInfo.styles';
import { useUser } from '@features/auth';

import editIcon from '@components/RolesAndPermissions/PersonalInfo/img/editIcon.svg';
import { useToast } from '@features/app/toast';

type TimeZoneOptionInterface = {
  label: string;
  value: number;
};

export type FormValuesInterface = {
  position: string;
  company: string;
  location: string;
  timeZone?: string;
};

export const PersonalInfoForm = ({
  isViewMode = false,
  userInfo,
}: {
  isViewMode: boolean;
  userInfo: UserInterface;
}) => {
  const timeZones = moment.tz.names();
  const timeZoneOptions: TimeZoneOptionInterface[] = timeZones.map((zone: string, index: number) => ({
    label: zone,
    value: index,
  }));

  const [isEditMode, setIsEditMode] = useState(false);
  const [timeZone, setTimeZone] = useState(
    timeZoneOptions.find((zone: TimeZoneOptionInterface) => zone.label === userInfo.timeZone),
  );
  const [saveInProgress, setSaveInProgress] = useState(false);
  const { user, refreshUser } = useUser();

  const defaultInitialValues: FormValuesInterface = {
    position: userInfo.position || 'Unknown',
    company: userInfo.company || 'Unknown',
    location: userInfo.location || 'Unknown',
  };

  const [initialValues, setInitialValues] = useState(defaultInitialValues);

  const showToast = useToast();

  const handleSubmit = (values: FormValuesInterface) => {
    if (user) {
      setInitialValues(values);
      setSaveInProgress(true);

      let valuesToUpdate = {};

      if (values.position !== user.position) {
        valuesToUpdate = {
          ...valuesToUpdate,
          position: values.position,
        };
      }
      if (values.company !== user.company) {
        valuesToUpdate = {
          ...valuesToUpdate,
          company: values.company,
        };
      }
      if (values.location !== user.location) {
        valuesToUpdate = {
          ...valuesToUpdate,
          location: values.location,
        };
      }
      if (timeZone?.label !== user.timeZone) {
        valuesToUpdate = {
          ...valuesToUpdate,
          timeZone: timeZone?.label,
        };
      }
      UsersService.editUser(user.id, valuesToUpdate).then(() => {
        setIsEditMode(false);
        void refreshUser();
        setSaveInProgress(false);

        showToast({
          type: 'info',
          title: 'Personal data',
          description: 'Your changes were saved',
        });
      });
    }
  };

  return (
    <PersonalInfoCardWrapper>
      <FormHeader>
        <span>Personal</span>
        {!isViewMode && !isEditMode && <EditImage src={editIcon} onClick={() => setIsEditMode(true)} alt="Edit" />}
      </FormHeader>

      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ values }) => (
          <Form>
            <FormDataItem>
              <ItemFieldValue isBold={isEditMode}>Position</ItemFieldValue>
              {isEditMode ? (
                <ItemFieldInputWrapper>
                  <Field id="position" name="position" placeholder="Position" />
                </ItemFieldInputWrapper>
              ) : (
                <ItemFieldValue isBold={true}>{initialValues.position}</ItemFieldValue>
              )}
            </FormDataItem>
            <FormDataItem>
              <ItemFieldValue isBold={isEditMode}>Company</ItemFieldValue>
              {isEditMode ? (
                <ItemFieldInputWrapper>
                  <Field id="company" name="company" placeholder="Company" />
                </ItemFieldInputWrapper>
              ) : (
                <ItemFieldValue isBold={true}>{initialValues.company}</ItemFieldValue>
              )}
            </FormDataItem>
            <FormDataItem>
              <ItemFieldValue isBold={isEditMode}>Location</ItemFieldValue>
              {isEditMode ? (
                <ItemFieldInputWrapper>
                  <Field id="location" name="location" placeholder="Location" />
                </ItemFieldInputWrapper>
              ) : (
                <ItemFieldValue isBold={true}>{initialValues.location}</ItemFieldValue>
              )}
            </FormDataItem>
            <FormDataItem>
              <ItemFieldValue isBold={isEditMode}>Timezone</ItemFieldValue>
              {isEditMode ? (
                <ItemFieldInputWrapper>
                  <Select
                    isSearchable
                    placeholder="Timezone"
                    value={timeZone}
                    onChange={(value) => setTimeZone(value as TimeZoneOptionInterface)}
                    options={timeZoneOptions}
                  />
                </ItemFieldInputWrapper>
              ) : (
                <ItemFieldValue isBold={true}>{timeZone?.label}</ItemFieldValue>
              )}
            </FormDataItem>
            {isEditMode ? (
              <FooterWrapper>
                <CancelButton onClick={() => setIsEditMode(false)} disabled={saveInProgress}>
                  Cancel
                </CancelButton>
                <SaveButton type="submit" $isInProgress={saveInProgress}>
                  {saveInProgress ? <Loader /> : 'Save'}
                </SaveButton>
              </FooterWrapper>
            ) : null}
          </Form>
        )}
      </Formik>
    </PersonalInfoCardWrapper>
  );
};
