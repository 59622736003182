import { ReactNode } from 'react';
import styled from 'styled-components/macro';

import { useIsEditAvailable } from '@features/content/courses';
import { ReactComponent as WarningIcon } from '@static/svg/translationsPanel/Warning.svg';

const WarningWrapper = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colorV2.utilityAlertBackground};
  border-radius: 1.2rem;
  display: flex;
  flex-direction: row;
  font-size: 1.4rem;
  margin: 1rem 0;
  min-height: 4.8rem;
  padding: 1.5rem 1.8rem;
  width: 100%;
`;

const StyledWarningIcon = styled(WarningIcon)`
  margin-right: 1.2rem;
`;

type WarningPropsType = {
  className?: string;
  children?: ReactNode;
  text?: string;
};

export const Warning = ({ className, children, text }: WarningPropsType) => (
  <WarningWrapper className={className}>
    <StyledWarningIcon />
    {children ? children : <span>{text}</span>}
  </WarningWrapper>
);

export const AccessWarning = () => {
  const { coursesWithoutPermissions } = useIsEditAvailable();

  if (coursesWithoutPermissions.length) {
    return (
      <Warning text="You don’t have permission to edit this content. To change your access settings, please contact your admin." />
    );
  }

  return (
    <Warning text="You don’t have permission to edit this course. To change your access settings, please contact your admin." />
  );
};
