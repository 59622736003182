import { useState } from 'react';

type SearchResponse<T> = {
  data: {
    results: T[];
    count: number;
  };
};

// Hook used for storing search results and errors
export const useResultsHandler = <T>(): {
  results: T[];
  count: number;
  setResults: (results: T[]) => void;
  clearResults: () => void;
  onSuccess: ({ data }: SearchResponse<T>) => void;
  onError: () => void;
} => {
  const [results, setResults] = useState<T[]>([]);
  const [count, setCount] = useState<number>(0);

  const onSuccess = ({ data }: SearchResponse<T>) => {
    setResults(data.results);
    setCount(data.count);
  };

  const onError = () => {
    setResults([]);
    setCount(0);
  };

  const clearResults = () => {
    setResults([]);
    setCount(0);
  };

  return {
    results,
    count,
    setResults,
    onSuccess,
    onError,
    clearResults,
  };
};
