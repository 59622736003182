import { useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { FormikContextType, useFormikContext } from 'formik';

import { OtherLocationsInterface } from '@common/interfaces/exercises/OtherLocationsInterface';
import { constants as contentConstants, formatCounterOfFilledLocalizations } from '@features/content';
import { ALL_LANGUAGES_UPPERCASE, Language } from '@features/content/languages';
import { DBId } from '@common/types/DBId';
import { LocalizationInterface } from '@common/interfaces/localization/LocalizationInterface';
import { TranslationsTab } from './TranslationsTab/TranslationsTab';
import { AudiosTab } from './AudiosTab/AudiosTab';
import { OtherLocationsTab } from './OtherLocationsTab';
import { SidePanel, SidePanelBody, SidePanelHeader } from '@features/theme';
import { SidePanelActionsCreator } from '@actionCreators/SidePanelActionsCreator';
import { useAppDispatch, useAppSelector } from '@redux/store';
import {
  selectRoleplayTranslationsPanel,
  selectRoleplayTranslationsPanelDefaultContextForTranslators,
} from '@selectors/UiSelectors';

import type {
  RoleplayCategoryContentType,
  RoleplayCategoryFormikValues,
  RoleplayScenarioContentType,
  RoleplayScenarioFormikValues,
} from '../types';
import { selectRoleplay } from '@selectors/roleplaySelectors';
import { getRealOtherLocationsForRoleplay } from '../_helpers/getRealOtherLocationsForRoleplay';

type TranslationsPanelProps = {
  content: RoleplayCategoryContentType | RoleplayScenarioContentType;
  learningLanguage: Language;
};

export const TranslationsPanel = ({ content, learningLanguage }: TranslationsPanelProps) => {
  const { values }: FormikContextType<RoleplayCategoryFormikValues | RoleplayScenarioFormikValues> = useFormikContext();
  const { categoryId, scenarioId } = useParams<{
    categoryId: DBId;
    scenarioId: DBId;
  }>();
  const translations = useAppSelector(selectRoleplayTranslationsPanel);
  const selectedRoleplay = useAppSelector(selectRoleplay);
  const defaultContextForTranslators = useAppSelector(selectRoleplayTranslationsPanelDefaultContextForTranslators);

  const dispatch = useAppDispatch();

  const { interfaceLanguages } = selectedRoleplay;
  const roleplayId = selectedRoleplay.content.id;
  const { preopenedTab, fieldName } = translations;
  const visitedContentId = translations.visitedContentId?.length ? translations.visitedContentId : 'undefined';

  const validationErrors = content?.validationStatus?.errors.filter((error) => error.field === fieldName) || [];

  const panelTitle = `Edit ${visitedContentId !== 'undefined' ? visitedContentId : 'content'}`;

  const [selectedTab, setSelectedTab] = useState(preopenedTab);

  const [otherLocationsNumberOfItems, setOtherLocationsNumberOfItems] = useState('');
  const [otherLocations, setOtherLocations] = useState<OtherLocationsInterface[][]>([]);

  useEffect(() => {
    (async () => {
      const realOtherLocations = await getRealOtherLocationsForRoleplay({
        categoryId,
        roleplayId,
        scenarioId,
        visitedContentId,
      });

      setOtherLocations(realOtherLocations);
      setOtherLocationsNumberOfItems(`${realOtherLocations.length}`);
    })();
  }, [visitedContentId, roleplayId, categoryId, scenarioId]);

  const notAllLanguagesSelected = interfaceLanguages.length < ALL_LANGUAGES_UPPERCASE.length - 1;

  const textLocalizations = (fieldName && content[fieldName]?.textLocalizations) || [];

  const audioLocalizations = (fieldName && content[fieldName]?.audioLocalizations) || [];

  const isDefaultLanguage = (localization: LocalizationInterface) => {
    return localization.language === contentConstants.DEFAULT_LANGUAGE;
  };

  const isCourseLearningLanguage = (localization: LocalizationInterface) => {
    return localization.language === learningLanguage;
  };

  const isInterfaceLanguage = (localization: LocalizationInterface) => {
    return interfaceLanguages.includes(localization.language as Language);
  };

  const defaultTranslations = textLocalizations.filter(
    (loc) => isInterfaceLanguage(loc) || isDefaultLanguage(loc) || isCourseLearningLanguage(loc),
  );
  const hiddenTranslations = textLocalizations.filter(
    (loc) => !isInterfaceLanguage(loc) && !isDefaultLanguage(loc) && !isCourseLearningLanguage(loc),
  );

  const defaultAudios = audioLocalizations.filter(
    (loc) => isInterfaceLanguage(loc) || isDefaultLanguage(loc) || isCourseLearningLanguage(loc),
  );
  const hiddenAudios = audioLocalizations.filter(
    (loc) => !isInterfaceLanguage(loc) && !isDefaultLanguage(loc) && !isCourseLearningLanguage(loc),
  );

  const totalTextLocalizations = formatCounterOfFilledLocalizations(
    fieldName && values?.[fieldName]
      ? (values[fieldName]?.filter((item) => item.value && !item.isPhonetic) as unknown as LocalizationInterface[])
      : textLocalizations,
    interfaceLanguages as Language[],
    learningLanguage as Language,
  );
  const totalAudioLocalizations = formatCounterOfFilledLocalizations(
    audioLocalizations || [],
    interfaceLanguages as Language[],
    learningLanguage as Language,
  );

  return (
    <SidePanel
      className="translations-panel-modal"
      onClose={() => dispatch(SidePanelActionsCreator.hideSidePanel())}
      header={<SidePanelHeader>{panelTitle}</SidePanelHeader>}
      body={
        <SidePanelBody>
          <Tabs
            activeKey={selectedTab}
            onSelect={(selectedTabString: string | null) => {
              if (
                selectedTabString === 'translations' ||
                selectedTabString === 'audios' ||
                selectedTabString === 'other'
              ) {
                setSelectedTab(selectedTabString);
              }
            }}
          >
            <Tab eventKey="translations" title={`Translations (${totalTextLocalizations})`}>
              <TranslationsTab
                otherLocationsNumberOfItems={+otherLocationsNumberOfItems}
                validationErrors={validationErrors}
                defaultContext={defaultContextForTranslators || ''}
                defaultTranslations={defaultTranslations}
                hiddenTranslations={hiddenTranslations}
                notAllLanguagesSelected={notAllLanguagesSelected}
                navigateToOtherTab={() => setSelectedTab('other')}
              />
            </Tab>
            <Tab eventKey="audios" title={`Audios (${totalAudioLocalizations})`}>
              <AudiosTab
                validationErrors={validationErrors}
                defaultAudios={defaultAudios}
                textLocalizations={textLocalizations}
                hiddenAudios={hiddenAudios}
                notAllLanguagesSelected={notAllLanguagesSelected}
              />
            </Tab>
            <Tab
              eventKey="other"
              title={`Other locations (${otherLocationsNumberOfItems || 0})`}
              disabled={otherLocationsNumberOfItems === '0' || !visitedContentId}
            >
              <OtherLocationsTab otherLocations={otherLocations} />
            </Tab>
          </Tabs>
        </SidePanelBody>
      }
    />
  );
};
