import Select, { Props, OptionProps, ValueContainerProps, components, ControlProps, StylesConfig } from 'react-select';
import { ComponentType } from 'react';

import { useSelectorCommonStyles, CustomDropdownIndicator, CustomClearIndicator } from '../';
import { SearchImage } from '../SearchImage';
import { CommonFilterOptionType } from '../types';

type CustomProps = { withSearchIcon: boolean };
type CustomSelectProps = Props<CommonFilterOptionType, false> & CustomProps;

const SelectWithCustomProps = (props: CustomSelectProps) => <Select {...props} />;

const CLASSNAME_PREFIX = 'common-filter-selector';

const CustomControl = (props: ControlProps<CommonFilterOptionType>) => {
  const { children, selectProps: commonSelectProps } = props;
  const selectProps = commonSelectProps as CustomSelectProps;

  return (
    <components.Control {...props}>
      {!selectProps.value && selectProps.withSearchIcon && <SearchImage isMenuOpen={!!selectProps.menuIsOpen} />}
      {children}
    </components.Control>
  );
};

type CommonFilterSelectorProps = {
  isClearable?: boolean;
  value: CommonFilterOptionType | null;
  options: CommonFilterOptionType[];
  className?: string;
  withSearchIcon?: boolean;
  placeholder?: string;
  classNamePrefix?: string;
  optionComponent?: ComponentType<OptionProps<CommonFilterOptionType, false>>;
  valueContainerComponent?: ComponentType<ValueContainerProps<CommonFilterOptionType, false>>;
  onChange: (option: CommonFilterOptionType | null) => void;
  onFocus?: () => void;
};

export const CommonFilterSelector = ({
  isClearable = true,
  value,
  className,
  options,
  withSearchIcon = false,
  placeholder = 'Common Filter',
  classNamePrefix = CLASSNAME_PREFIX,
  optionComponent,
  valueContainerComponent,
  onChange,
  onFocus,
}: CommonFilterSelectorProps) => {
  const CommonSelectorStyles = useSelectorCommonStyles<CommonFilterOptionType>({ classNamePrefix });

  const SelectorStyles: StylesConfig<CommonFilterOptionType> = {
    ...CommonSelectorStyles,
    dropdownIndicator: (defaultStyles, props) => ({
      ...defaultStyles,
      ...(CommonSelectorStyles.dropdownIndicator && CommonSelectorStyles.dropdownIndicator(defaultStyles, props)),
      display: `${value?.value && isClearable ? 'none' : 'block'}`,
    }),
    placeholder: (defaultStyles, props) => ({
      ...defaultStyles,
      ...(CommonSelectorStyles.placeholder && CommonSelectorStyles.placeholder(defaultStyles, props)),
      paddingLeft: withSearchIcon ? '2.5rem' : '0',
    }),
    valueContainer: (defaultStyles, props) => ({
      ...defaultStyles,
      ...(CommonSelectorStyles.valueContainer && CommonSelectorStyles.valueContainer(defaultStyles, props)),
      display: 'flex',
      flexWrap: 'nowrap',
      height: '3rem',
      ':before': {
        content: `"${value && placeholder ? `${placeholder}: ` : ''}"`,
        textWrap: 'nowrap',
        marginRight: '0.4rem',
      },
    }),
    input: (defaultStyles, props) => ({
      ...defaultStyles,
      ...(CommonSelectorStyles.input && CommonSelectorStyles.input(defaultStyles, props)),
      position: value?.value ? 'relative' : 'absolute',
      left: value?.value ? '0' : withSearchIcon ? '3.4rem' : '0.8rem',
    }),
  };

  return (
    <SelectWithCustomProps
      classNamePrefix={classNamePrefix}
      aria-label={classNamePrefix}
      className={className}
      isClearable={isClearable}
      value={value}
      placeholder={placeholder}
      options={options}
      styles={SelectorStyles}
      withSearchIcon={withSearchIcon}
      components={{
        DropdownIndicator: CustomDropdownIndicator,
        ClearIndicator: CustomClearIndicator,
        Control: CustomControl,
        Option: optionComponent || components.Option,
        ValueContainer: valueContainerComponent || components.ValueContainer,
      }}
      onChange={onChange}
      onFocus={onFocus}
    />
  );
};
