import { useEffect, useMemo, useState } from 'react';
import { MultiValue, MultiValueGenericProps, OptionProps } from 'react-select';

import { CommonFilterOptionType, CommonFilterMultiSelector } from '@components/SelectorCommonComponents';
import { useCache } from '@features/app/cache';

import { VocabularyReviewService } from '../../VocabularyReviewService';
import { handleOnChange, mapStateValueToFilterOptionValue } from './util';
import { FiltersState } from './types';
import { SelectorCustomOption } from './SelectorCustomOption';
import { SelectorCustomValueContainer } from './SelectorCustomValueContainer';

/**
 * Speech parts cache
 * 1. in-memory cache fallbacks to
 * 2. session storage fallbacks to
 * 3. API request
 *
 * Cache TTL by default (1 hour)
 */
const SPEECH_PARTS_STORAGE_KEY = 'speech-parts';
const SPEECH_PARTS_LAST_UPDATED_STORAGE_KEY = `${SPEECH_PARTS_STORAGE_KEY}-last-updated`;

let speechPartsCache: string[] = [];
let speechPartsCacheLastUpdated = '';

export const SpeechPartSelector = ({
  value,
  onFilter,
}: {
  value: FiltersState['speechPart'];
  onFilter: (value?: FiltersState['speechPart']) => void;
}) => {
  const [storedSpeechParts, shouldRefreshSpeechPartsCache, updateSpeechPartsStorage] = useCache({
    lastUpdated: speechPartsCacheLastUpdated,
    lastUpdatedStorageKey: SPEECH_PARTS_LAST_UPDATED_STORAGE_KEY,
    storageKey: SPEECH_PARTS_STORAGE_KEY,
  });

  const [options, setOptions] = useState<CommonFilterOptionType[]>(
    storedSpeechParts ? (mapStateValueToFilterOptionValue(storedSpeechParts) as CommonFilterOptionType[]) : [],
  );

  const currentValue = mapStateValueToFilterOptionValue(value) || null;

  useEffect(() => {
    if (shouldRefreshSpeechPartsCache() || !options?.length) {
      try {
        (async () => {
          const {
            data: { speechParts },
          } = await VocabularyReviewService.getSpeechParts();

          if (speechParts.length) {
            speechPartsCache = speechParts;
            speechPartsCacheLastUpdated = new Date().toISOString();
            updateSpeechPartsStorage(speechPartsCache, speechPartsCacheLastUpdated);
            setOptions(mapStateValueToFilterOptionValue(speechPartsCache) as CommonFilterOptionType[]);
          } else {
            throw new Error('No speech parts fetched');
          }
        })();
      } catch (error) {
        console.error(error);
      }
    }
  }, [options, shouldRefreshSpeechPartsCache, updateSpeechPartsStorage]);

  const title = useMemo(() => currentValue?.map(({ label }) => label).join('\n') ?? '', [currentValue]);

  return (
    <CommonFilterMultiSelector
      closeMenuOnSelect={false}
      optionContainer={(props: OptionProps<CommonFilterOptionType>) => <SelectorCustomOption {...props} />}
      options={options}
      placeholder="Part of speech"
      rule="Part of speech"
      value={currentValue}
      valueContainer={(props: MultiValueGenericProps<MultiValue<CommonFilterOptionType>>) => (
        <SelectorCustomValueContainer title={title} value={currentValue} {...props} />
      )}
      onChange={(selectedOption) => handleOnChange(selectedOption, onFilter)}
    />
  );
};
