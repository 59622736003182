import { useMemo } from 'react';
import styled from 'styled-components/macro';

import { GrammarCategorySearchResultType, GrammarCategoryDetailsTab } from '../../types';
import { CardWrapper, CardTitle, CardLocations, CardMoreButton, CardImage } from '../Common';

const CategoryCardWrapper = styled(CardWrapper)`
  grid-template-columns: 5% 68% 20% 4%;
`;

const CategoryCardImage = styled(CardImage)`
  padding: 0.8rem;
`;

export const GrammarCategoryResultCard = ({
  content,
  isExpanded,
  onExpand,
}: {
  content: GrammarCategorySearchResultType;
  isExpanded: boolean;
  onExpand: (tabName: GrammarCategoryDetailsTab['key'] | null) => void;
}) => {
  const plainTextTitle = useMemo(
    () => content.title?.replace(/(<([^>]+)>)|(&.+;)/gi, '') || 'Untitled grammar category',
    [content.title],
  );

  return (
    <CategoryCardWrapper isExpanded={isExpanded} onClick={() => onExpand(null)}>
      <CategoryCardImage image={content.image} />
      <CardTitle id={content.id} title={plainTextTitle} />
      <CardLocations
        courses={content.courses}
        locationsCount={content.locationsCount}
        onExpand={() => onExpand('locations')}
      />
      <CardMoreButton isExpanded={isExpanded} />
    </CategoryCardWrapper>
  );
};
