import type { DBId } from '@common/types/DBId';
import { CourseEditionActions } from '@actions/CourseEditionActions';
import { SectionActions } from '@actions/SectionActions';

export const CourseEditionActionsCreator = {
  getCourseWithGroups: (courseId: DBId) => ({
    type: SectionActions.GET_COURSE_WITH_ITS_GROUPS,
    courseId,
  }),
  getCourse: (courseId: DBId) => ({ type: SectionActions.GET_COURSE, courseId }),
  getGroup: (contentId: DBId, parentId: DBId) => ({
    type: SectionActions.GET_CONTENT,
    payload: { contentId, parentId },
  }),
  getExercise: (courseId: DBId, exerciseId: DBId, parentId?: DBId) => ({
    type: SectionActions.GET_EXERCISE,
    payload: { courseId, exerciseId, parentId },
  }),
  getExerciseForActivityPreview: (courseId: DBId, exerciseId: DBId) => ({
    type: SectionActions.GET_EXERCISE_FOR_ACTIVITY_PREVIEW,
    payload: { courseId, exerciseId },
  }),
  getRecapOptions: (exerciseId: DBId) => ({
    type: SectionActions.GET_RECAP_OPTIONS_FOR_EXERCISE,
    payload: { exerciseId },
  }),
  toggleIssuesVisibility: () => ({
    type: CourseEditionActions.TOGGLE_ISSUES_VISIBILITY,
  }),
  clearCurrentExercise: () => ({ type: CourseEditionActions.CLEAR_CURRENT_EXERCISE }),
};
