import { AxiosResponse } from 'axios';

import { TranslationsPanelContentInterface } from '@common/interfaces/exercises/TranslationsPanelContentInterface';
import { AnyExerciseContentInterface } from '@common/types/exercises/AnyExerciseContentInterface';
import { LocalizationInterface } from '@common/interfaces/localization/LocalizationInterface';
import { AnyExerciseInterface } from '@common/types/exercises/AnyExerciseInterface';
import { AnyExerciseContentBranch } from '@common/types/AnyExerciseContentBranch';
import { ExerciseCommonActionCreators as ExerciseCommonActions } from '@actionCreators/ExerciseCommonActionCreator';
import { FormikValuesInterface } from '@helpers/formikInitialValuesHelper';
import { ExerciseTypes, type ExerciseTypesType } from '@common/enums/ExerciseTypes';
import { apiClient } from '@features/api';
import { selectTextLocalizationsForSave, selectMediaLocalizationsForSave } from '@helpers/localizationSaveHelper';
import { DBId } from '@common/types/DBId';
import { AppDispatch } from '@redux/store';
import {
  htmlEntitiesRegExp,
  decodeHtmlEntities,
  processSpacingForEditor,
  removeTableTags,
} from '@helpers/htmlTagsHelper';
import ContentsService from './ContentsService';
import URLCreatorService from './URLCreatorService';
import TipExerciseService from './ExerciseRelatedServices/TipExerciseService';
import { EXERCISE_URL_POSTFIXES } from '@services/URLCreatorService';

type SaveFieldInBundleProps = {
  bundleName: string;
  field: string;
  dispatch: AppDispatch;
  exercise: AnyExerciseInterface;
  payload: any;
};

const sanitizeFieldValue = (textLocalizations: LocalizationInterface[]) => {
  return textLocalizations.map((localization) => {
    const hasHtmlEntities = htmlEntitiesRegExp.test(localization.value);

    return {
      ...localization,
      value: hasHtmlEntities ? decodeHtmlEntities(localization.value) : localization.value,
    };
  });
};

const ExercisesService = {
  misc: {
    getExerciseId(exercise: AnyExerciseInterface) {
      return exercise?.content?.id === null || exercise?.content?.id === undefined ? 'N/A' : exercise?.content?.id;
    },
    async getExercise(contentId: DBId | undefined, courseId?: DBId, parentId?: DBId) {
      return apiClient.v1
        .get(URLCreatorService.exercises.get(contentId as DBId, courseId, parentId))
        .then((data: AxiosResponse) => {
          let exercise: AnyExerciseContentInterface = data.data;

          // Remove html entities potentially stored in DB (see INTO-3402 and INTO-3404)
          if (exercise.instructions) {
            exercise.instructions.textLocalizations = sanitizeFieldValue(exercise.instructions.textLocalizations);
          }

          if (
            exercise.type === ExerciseTypes.conversation ||
            exercise.type === ExerciseTypes.phraseBuilder ||
            exercise.type === ExerciseTypes.multipleChoice ||
            exercise.type === ExerciseTypes.trueFalse ||
            exercise.type === ExerciseTypes.speechRecognition ||
            exercise.type === ExerciseTypes.spelling ||
            exercise.type === ExerciseTypes.highlighter ||
            exercise.type === ExerciseTypes.fillgap ||
            exercise.type === ExerciseTypes.comprehension ||
            exercise.type === ExerciseTypes.matchup ||
            exercise.type === ExerciseTypes.slidePdf ||
            exercise.type === ExerciseTypes.slidePptx ||
            exercise.type === ExerciseTypes.listenRepeat
          ) {
            return {
              content: {
                ...exercise,
                mappings: exercise.mappings.length,
                mappingsPath: exercise.mappings,
              },
            };
          }

          if (exercise.type === ExerciseTypes.dialogue) {
            return {
              content: {
                ...exercise,
                mappings: exercise.mappings.length,
                mappingsPath: exercise.mappings,
                script: exercise.script.filter((item: any) => item.line && item.line._id).length
                  ? exercise.script.filter((item: any) => item.line && item.line._id)
                  : [],
              },
            };
          }

          if (exercise.type === ExerciseTypes.flashcard) {
            return {
              content: {
                ...exercise,
                mappings: exercise.mappings.length,
                mappingsPath: exercise.mappings,
                learningWordBundle: exercise.learningWordBundle
                  ? {
                      ...exercise.learningWordBundle,
                      phrase: exercise.learningWordBundle.phrase
                        ? {
                            ...exercise.learningWordBundle.phrase,
                            textLocalizations: exercise.learningWordBundle.phrase.textLocalizations.map(
                              (loc: LocalizationInterface) => ({
                                ...loc,
                                alternativeValues: loc.extraData.alternative_values,
                              }),
                            ),
                          }
                        : null,
                    }
                  : null,
              },
            };
          }
          if (exercise.type === ExerciseTypes.typing) {
            return {
              content: {
                ...exercise,
                mappings: exercise.mappings.length,
                mappingsPath: exercise.mappings,
                mainBundle: exercise.mainBundle
                  ? {
                      ...exercise.mainBundle,
                      phrase: exercise.mainBundle.phrase
                        ? {
                            ...exercise.mainBundle.phrase,
                            textLocalizations: exercise.mainBundle.phrase.textLocalizations.map(
                              (loc: LocalizationInterface) => ({
                                ...loc,
                                alternativeValues: loc.extraData.alternative_values,
                              }),
                            ),
                          }
                        : null,
                    }
                  : null,
              },
            };
          }

          if (exercise.type === ExerciseTypes.tip) {
            return {
              content: TipExerciseService.sanitizeExercise(exercise, data),
            };
          }
        });
    },
    async updateExercise(payload: any, contentId: DBId | undefined, exerciseType: ExerciseTypesType) {
      if (contentId !== undefined) {
        return await apiClient.noErrorsV2.put(
          `/content/exercises/${EXERCISE_URL_POSTFIXES[exerciseType]}/${contentId}`,
          payload,
        );
      }
    },
    async saveField(
      dispatch: AppDispatch,
      field: AnyExerciseContentBranch,
      exercise: AnyExerciseInterface,
      payload: any,
    ) {
      // For default instructions
      if (
        field === 'instructions' &&
        (exercise.content[field]._id?.includes('default') || !exercise.content[field]._id) &&
        !exercise.content[field].textLocalizations.filter((loc) => loc.value).length
      ) {
        return { ...payload, [field]: null };
      }
      if (field === 'instructions' && exercise.content[field]._id?.includes('default')) {
        return { ...payload, [field]: exercise.content[field]._id };
      }

      // TODO: Specific for Comprehension exercise default instructions, need to remove after decoupling reading and video comprehension
      if (
        field === 'instructions' &&
        exercise.content.type === ExerciseTypes.comprehension &&
        !exercise.content.instructions.textLocalizations.filter((loc) => loc.value).length
      ) {
        return {
          ...payload,
          [field]: null,
        };
      }

      if (exercise.content[field] === null) {
        return { ...payload, [field]: null };
      }

      const selectedContent: TranslationsPanelContentInterface = {
        ...(exercise.content as any)[field],
      };

      if (selectedContent === null) return { ...payload, [field]: null };

      let payloadForContent = {
        textLocalizations: selectTextLocalizationsForSave(selectedContent.textLocalizations),
        audioLocalizations: selectMediaLocalizationsForSave(
          selectedContent.audioLocalizations.filter((audio) => !audio.value), // don't pick already uploaded audios
        ),
        description: selectedContent.description || null,
      };

      if (
        !payloadForContent.textLocalizations.length &&
        !payloadForContent.audioLocalizations.length &&
        !payloadForContent.description
      ) {
        return { [field]: null };
      }

      if (
        (selectedContent?._id === undefined && (selectedContent as any)?.id === undefined) ||
        selectedContent?._id === '' ||
        (selectedContent as any)?.id === ''
      ) {
        const newContentId = await ContentsService.contents.createNewContent(payloadForContent);
        dispatch(ExerciseCommonActions.setExerciseNewContentId({ field, newContentId }));

        return {
          ...payload,
          [field]: newContentId,
        };
      } else {
        const id = selectedContent._id === undefined ? (selectedContent as any).id : selectedContent._id;

        await ContentsService.contents.update(id, payloadForContent);

        return {
          ...payload,
          [field]: id,
        };
      }
    },
    async saveFieldInBundle({ bundleName, dispatch, exercise, field, payload }: SaveFieldInBundleProps) {
      if ((exercise.content as AnyExerciseContentInterface)[bundleName][field] === null)
        return { ...payload, [field]: null };

      const selectedContent: TranslationsPanelContentInterface = {
        ...(exercise.content as AnyExerciseContentInterface)[bundleName][field],
      };

      if (selectedContent.videoLocalizations) {
        selectedContent.videoLocalizations = selectedContent.videoLocalizations.map((loc: any) => {
          let newLoc = { ...loc };
          if (newLoc.rawData) {
            newLoc.value = newLoc.rawData;
            delete newLoc.rawData;
          }
          return newLoc;
        });
      }

      if (selectedContent.imageLocalizations) {
        selectedContent.imageLocalizations = selectedContent.imageLocalizations.map((loc: any) => {
          let newLoc = { ...loc };
          if (newLoc.rawData) {
            newLoc.value = newLoc.rawData;
            delete newLoc.rawData;
          }

          return newLoc;
        });
      }

      const isNewContent =
        (selectedContent?._id === undefined &&
          (selectedContent as TranslationsPanelContentInterface)?.id === undefined) ||
        selectedContent?._id === '' ||
        (selectedContent as any)?.id === '' ||
        selectedContent?._id === null;

      const payloadForContent = {
        textLocalizations: selectTextLocalizationsForSave(selectedContent.textLocalizations),
        audioLocalizations: selectMediaLocalizationsForSave(selectedContent.audioLocalizations),
        imageLocalizations: selectMediaLocalizationsForSave(selectedContent.imageLocalizations),
        videoLocalizations: selectMediaLocalizationsForSave(selectedContent.videoLocalizations),
        description: selectedContent.description || null,
      };

      if (
        !payloadForContent.textLocalizations.length &&
        !payloadForContent.audioLocalizations.length &&
        !payloadForContent.imageLocalizations.length &&
        !payloadForContent.videoLocalizations.length &&
        !payloadForContent.description
      ) {
        return { [field]: null };
      }

      const processedTextLocalizations = selectTextLocalizationsForSave(
        payloadForContent.textLocalizations.map((text) => ({
          ...text,
          value: removeTableTags(processSpacingForEditor(text.value)),
        })),
      );
      const processedTextLocalizationsWithExtraData = selectTextLocalizationsForSave(
        payloadForContent.textLocalizations.map((text) => ({
          ...text,
          extraData: {},
          value: removeTableTags(processSpacingForEditor(text.value)),
        })),
      );

      const defaultLocalizationsForSave = [{ language: 'EN', value: '' }];
      const defaultImageLocalizationsForSave = [{ ...defaultLocalizationsForSave[0], type: 'exercise' }];

      let processedAudioLocalizations: LocalizationInterface[] = [];

      if (!['image', 'video'].includes(field) && selectedContent.changed) {
        if (isNewContent) {
          processedAudioLocalizations = selectMediaLocalizationsForSave(
            payloadForContent.audioLocalizations.map((audio) => {
              return {
                fileId: audio._id || null,
                language: audio.language,
                value: '',
              };
            }),
          );
        } else {
          // don't pick already uploaded audios
          processedAudioLocalizations = selectMediaLocalizationsForSave(
            payloadForContent.audioLocalizations.filter((audio) => !audio.value),
          );
        }
      }

      const processedImageLocalizations =
        field === 'image' && selectedContent.changed
          ? selectMediaLocalizationsForSave(
              payloadForContent.imageLocalizations.map((image) => {
                if (isNewContent) {
                  return {
                    fileId: image._id || null,
                    language: image.language,
                    type: 'exercise',
                    value: '',
                  };
                }

                return {
                  ...image,
                  fileId: image._id || null,
                  type: 'exercise',
                };
              }),
            )
          : [];

      const processedVideoLocalizations =
        field === 'video' && selectedContent.changed
          ? selectMediaLocalizationsForSave(
              payloadForContent.videoLocalizations.map((video) => {
                if (isNewContent) {
                  return {
                    fileId: video._id || null,
                    language: video.language,
                    value: '',
                  };
                }

                return {
                  ...video,
                  fileId: video._id || null,
                };
              }),
            )
          : [];

      if (isNewContent) {
        const newContentId = await ContentsService.contents.createNewContent({
          ...payloadForContent,
          textLocalizations: processedTextLocalizations.length
            ? processedTextLocalizations
            : defaultLocalizationsForSave,
        });
        dispatch(ExerciseCommonActions.setExerciseNewContentId({ field, bundleName, newContentId }));

        return {
          ...payload,
          [field]: newContentId,
        };
      } else {
        const id = selectedContent._id || selectedContent.id;

        await ContentsService.contents.update(id, {
          ...payloadForContent,
          textLocalizations: processedTextLocalizationsWithExtraData
            ? processedTextLocalizationsWithExtraData
            : defaultLocalizationsForSave,
          audioLocalizations: processedAudioLocalizations.length ? processedAudioLocalizations : [],
          imageLocalizations: processedImageLocalizations.length
            ? processedImageLocalizations
            : defaultImageLocalizationsForSave,
          videoLocalizations: processedVideoLocalizations.length
            ? processedVideoLocalizations
            : defaultLocalizationsForSave,
        });

        return {
          ...payload,
          [field]: id,
        };
      }
    },
    async saveBundle(
      dispatch: AppDispatch,
      bundleName: AnyExerciseContentBranch,
      exercise: AnyExerciseInterface,
      payload: any,
      values?: FormikValuesInterface,
    ) {
      if (exercise.content[bundleName] === null) {
        return { ...payload, [bundleName]: null };
      }

      const selectedContent: {
        _id: string;
        phrase: TranslationsPanelContentInterface;
        example: TranslationsPanelContentInterface;
        video: TranslationsPanelContentInterface;
        image: TranslationsPanelContentInterface;
        changed: boolean;
        isVocabulary: boolean;
      } = {
        ...(exercise.content as any)[bundleName],
      };
      let bundlePayload: any = {
        isVocabulary: exercise.content[bundleName].isVocabulary,
      };

      const phraseChanged =
        values?.[`${bundleName}_phraseChanged` as keyof FormikValuesInterface] ||
        exercise.content[bundleName].phrase?.changed ||
        exercise.content[bundleName].phraseChanged;

      const exampleChanged =
        values?.[`${bundleName}_exampleChanged` as keyof FormikValuesInterface] ||
        exercise.content[bundleName].example?.changed ||
        exercise.content[bundleName].exampleChanged;

      const shouldUpdatePhrase =
        phraseChanged &&
        !exercise.content[bundleName].phrase?.isReused &&
        !exercise.content[bundleName].phrase?.isCopied;

      const shouldUpdateExample =
        exampleChanged &&
        !exercise.content[bundleName].phrase?.isReused &&
        !exercise.content[bundleName].phrase?.isCopied;

      // @TODO Add audio-specific state to the bundle and handle like image and audio
      const updateBundlePayload = await Promise.all([
        shouldUpdatePhrase
          ? ExercisesService.misc.saveFieldInBundle({
              bundleName,
              dispatch,
              field: 'phrase',
              exercise,
              payload: bundlePayload,
            })
          : { phrase: exercise.content[bundleName].phrase?._id || null },

        shouldUpdateExample
          ? ExercisesService.misc.saveFieldInBundle({
              bundleName,
              dispatch,
              field: 'example',
              exercise,
              payload: bundlePayload,
            })
          : { example: exercise.content[bundleName].example?._id || null },

        exercise.content[bundleName].image?.changed || exercise.content[bundleName].imageChanged
          ? ExercisesService.misc.saveFieldInBundle({
              bundleName,
              dispatch,
              field: 'image',
              exercise,
              payload: bundlePayload,
            })
          : { image: exercise.content[bundleName].image?._id || null },
        exercise.content[bundleName].video?.changed || exercise.content[bundleName].videoChanged
          ? ExercisesService.misc.saveFieldInBundle({
              bundleName,
              dispatch,
              field: 'video',
              exercise,
              payload: bundlePayload,
            })
          : { video: exercise.content[bundleName].video?._id || null },
      ]);

      bundlePayload = {
        ...bundlePayload,
        ...updateBundlePayload.reduce((sum: any, item: any) => ({ ...sum, ...item }), {}),
      };

      const isBundleRemoved =
        selectedContent._id === null &&
        bundlePayload.phrase === null &&
        bundlePayload.example === null &&
        bundlePayload.image === null &&
        bundlePayload.video === null;

      const isNewBundle = !selectedContent?._id;

      if (isBundleRemoved) return { ...payload, [bundleName]: null };

      if (isNewBundle) {
        const newContentId = await ContentsService.contents.createNewBundle(bundlePayload);
        dispatch(ExerciseCommonActions.setExerciseNewContentId({ field: bundleName, newContentId }));

        return {
          ...payload,
          [bundleName]: newContentId,
        };
      } else {
        if (
          bundlePayload.phrase ||
          bundlePayload.phrase === null ||
          bundlePayload.image ||
          bundlePayload.image === null ||
          bundlePayload.example ||
          bundlePayload.example === null ||
          bundlePayload.video ||
          bundlePayload.video === null ||
          exercise.content[bundleName].isVocabularyChanged
        ) {
          await ContentsService.contents.updateBundle(exercise.content[bundleName]._id, {
            ...bundlePayload,
          });
        }

        return {
          ...payload,
          [bundleName]: selectedContent._id,
        };
      }
    },
  },
};

export default ExercisesService;
