import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { getUrlToNavigate } from '@helpers/contentCardHelper';
import { EntrypointListItemType } from '../PlacementTest';
import { DBId } from '@common/types/DBId';
import { CardPublishingStatus } from '@components/ContentTypes/BaseContent/CardPublishingStatus';
import { CardValidationMark } from '@components/ContentTypes/BaseContent/CardValidationMark';
import { PublishCardButton } from './PublishCardButton';
import { CardControllers, DataWrapper, Data, Title } from '@components/ContentTypes/card.styles';

const CardWrapper = styled(Link)`
  display: flex;
  width: 100%;
  padding: 0 0 0 1rem;
  cursor: pointer;
  height: 6.4rem;
  margin-bottom: 2.4rem;
  color: ${({ theme }) => theme.colorV2.placementTestCardText};
  &:hover {
    color: ${({ theme }) => theme.colorV2.placementTestCardText};
    text-decoration: none;
    background: ${({ theme }) => theme.colorV2.placementTestCardHoverBackground};
    box-shadow: ${({ theme }) => `0 0.1rem 0 ${theme.colorV2.uiDivider}, inset 0 0.1rem 0 ${theme.colorV2.uiDivider};`}
`;

const EntrypointData = styled(Data)`
  justify-content: center;
`;

type EntrypointCardProps = {
  content: EntrypointListItemType;
};

export const EntrypointCard = ({ content }: EntrypointCardProps) => {
  const params = useParams<{ courseId: DBId }>();
  const { courseId } = params;
  const [hovered, setHovered] = useState(false);
  const { id, type, changeStatus, ready, title, validationStatus } = content;
  const finalTitle = title ? `${title} - Entrypoint` : 'Entrypoint';

  return (
    <CardWrapper
      to={getUrlToNavigate(params, id, type)}
      id={`entrypoint-${id}`}
      onMouseOver={() => setHovered(true)}
      onMouseOut={() => setHovered(false)}
    >
      <CardPublishingStatus hasPendingChanges={!!changeStatus?.hasPendingChanges} ready={!!ready} />
      <DataWrapper>
        <EntrypointData>
          <Title title={title || 'Entrypoint'}>
            {finalTitle}
            <CardValidationMark isInvalid={!validationStatus?.valid} />
          </Title>
        </EntrypointData>
        <CardControllers>
          <PublishCardButton hovered={hovered} content={content} parentId={courseId} />
        </CardControllers>
      </DataWrapper>
    </CardWrapper>
  );
};
