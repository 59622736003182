import { useState } from 'react';
import classnames from 'classnames';
import arrowIcon from '@static/svg/translationsPanel/Dropup.svg';
import { ValidationErrorInterface } from '@common/interfaces/validation/ValidationInterface';
import { LocalizationInterface } from '@common/interfaces/localization/LocalizationInterface';
import type { TranslationsPanelModesType } from '@components/TranslationsPanel/enums/TranslationsPanelModes';
import { TranslationsBlock } from '@components/TranslationsPanel/TranslationsBlock';
import { useIsAdmin } from '@features/auth/roles';

interface InactiveTranslationsProps {
  bundleId?: string;
  bundleName?: string;
  column?: number | undefined;
  contentId: string;
  courseLearningLanguage?: string;
  disableLearningLanguage?: boolean;
  exerciseType: string;
  isBundleChangeBlocked?: boolean;
  isChangeBlocked?: boolean;
  otherLocationsNumber: number;
  row?: number | undefined;
  storeBranch?: string | undefined;
  translationsBlockValuesArray: LocalizationInterface[];
  translationsPanelMode: TranslationsPanelModesType;
  type: string;
  validationErrors?: ValidationErrorInterface[];
  onTranslationChange: Function;
}

export const InactiveTranslations = ({
  bundleId,
  bundleName,
  column,
  contentId,
  courseLearningLanguage,
  disableLearningLanguage,
  exerciseType,
  isBundleChangeBlocked,
  isChangeBlocked,
  otherLocationsNumber,
  row,
  storeBranch,
  translationsBlockValuesArray,
  translationsPanelMode,
  type,
  validationErrors,
  onTranslationChange,
}: InactiveTranslationsProps) => {
  let [inactiveShown, setInactiveShown] = useState(false);

  const isAdmin = useIsAdmin();

  const handleToggle = () => {
    setInactiveShown(!inactiveShown);
  };

  return (
    <>
      <div
        className={classnames('inactive-languages-toggler', {
          'inactive-languages-toggler--hide': inactiveShown,
        })}
        onClick={handleToggle}
      >
        {inactiveShown ? 'Hide' : 'Show'} other languages
        <img src={arrowIcon} className="arrow-icon" alt="" />
      </div>
      <TranslationsBlock
        bundleId={bundleId}
        bundleName={bundleName}
        column={column}
        contentId={contentId}
        courseLearningLanguage={courseLearningLanguage}
        disabled={contentId?.includes('default_') && !isAdmin}
        disableLearningLanguage={disableLearningLanguage}
        exerciseType={exerciseType}
        isBundleChangeBlocked={isBundleChangeBlocked}
        isChangeBlocked={isChangeBlocked}
        otherPlacesCount={otherLocationsNumber}
        row={row}
        show={inactiveShown}
        storeBranch={storeBranch || ''}
        translationsBlockValuesArray={translationsBlockValuesArray}
        translationsPanelMode={translationsPanelMode}
        validationErrors={validationErrors}
        type={type}
        onTranslationChange={onTranslationChange}
      />
    </>
  );
};
