import { EditorState, Modifier, SelectionState } from 'draft-js';
import 'draft-js/dist/Draft.css';
import { KTagStrategyReturn } from '../Types';
import KTagStrategiesCommonUtils from './KTagStrategiesCommonUtils';

const KTagSelectionStrategy = (editorState: EditorState): KTagStrategyReturn => {
  let selectionState: SelectionState = editorState.getSelection();
  let currentContent = editorState.getCurrentContent();
  let contentState;
  let theClass = '';

  const totalBlocks = KTagStrategiesCommonUtils.calculateTotalBlocks(currentContent);

  theClass = totalBlocks % 2 ? 'KTAG_GROUP' : 'KTAG_GROUP2';
  contentState = Modifier.applyInlineStyle(currentContent, selectionState, theClass);

  editorState = EditorState.push(editorState, contentState, 'change-inline-style');

  return {
    editorState,
    totalBlocks: KTagStrategiesCommonUtils.calculateTotalBlocks(contentState),
  };
};

export default KTagSelectionStrategy;
