import { useCallback, useEffect } from 'react';
import { FormikContextType, useFormikContext } from 'formik';
import styled from 'styled-components/macro';

import { ContentTypes } from '@common/enums/ContentTypes';
import { ContentTypesActionsCreator } from '@actionCreators/ContentTypesActionsCreator';
import helpersService from '@services/HelpersService';
import { CourseInterface } from '@common/interfaces/courses/CourseInterface';
import { FormikValuesInterface } from '@helpers/formikInitialValuesHelper';
import { IssuesToggler } from '@components/IssuesToggler';
import { PublishButton } from '@components/Publishing/PublishButton';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { LoadingStage, type LoadingStageType } from '@common/enums/LoadingStage';
import {
  ButtonsList,
  ContentData,
  HeaderItemSeparator,
  HeaderWrapper,
  PendingChangesButton,
  SaveButton,
} from '@features/content/header';
import { ListPreviewModal, LiveLessonPreviewModal, PreviewButton } from '@features/content/preview';
import { PreviewModalActionsCreator } from '@actionCreators/PreviewModalActionsCreator';
import { useShowDebugUI } from '@features/app/debug';
import { ValidationButton } from '@features/content/forceValidation';
import { ContextualMenu } from '@features/theme';

import {
  selectCourse,
  selectGroupsOfSelectedGroupsOfParent,
  selectIsSaveButtonEnabled,
  selectLoadedExercises,
  selectSelectedGroupsOfParentParentContents,
} from '@selectors/CoursesSelectors';
import { saveContent } from './saveContent';

import { ReactComponent as ContextualMenuTriggerIcon } from './img/context-menu-trigger.svg';

const StyledContextualMenuTriggerIcon = styled(ContextualMenuTriggerIcon)`
  margin: 0 0.8rem;
  transform: scale(1.1);
  transform-origin: right;
`;

type CourseEditionExerciseHeaderProps = {
  courseName: string;
  isCourse: boolean;
  isExercise: boolean;
  contextMenuItems: any;
  selectedGroupsOfParent: any;
  loadedExercise: any;
  learningLanguage: any;
  interfaceLanguages: any;
  onInterfaceLanguagesClick: () => void;
};

type ParentContentTypes = {
  isCourse: boolean;
  isLevel: boolean;
  isChapter: boolean;
  isLesson: boolean;
  isActivity: boolean;
  isExercise: boolean;
};

const getContentType = ({ isCourse, isLevel, isChapter, isLesson, isActivity, isExercise }: ParentContentTypes) => {
  switch (true) {
    case isCourse:
      return ContentTypes.course;
    case isLevel:
      return ContentTypes.level;
    case isChapter:
      return ContentTypes.chapter;
    case isLesson:
      return ContentTypes.lesson;
    case isActivity:
      return ContentTypes.activity;
    case isExercise:
      return ContentTypes.exercise;
    default:
      return ContentTypes.course;
  }
};

const CourseEditionExerciseHeader = ({
  courseName,
  isCourse,
  isExercise,
  contextMenuItems,
  selectedGroupsOfParent,
  loadedExercise,
  learningLanguage,
  interfaceLanguages,
  onInterfaceLanguagesClick,
}: CourseEditionExerciseHeaderProps) => {
  const { values, dirty, resetForm }: FormikContextType<FormikValuesInterface> = useFormikContext();
  const { parentType, parentId, loaded, parentContents } = selectedGroupsOfParent;
  const dispatch = useAppDispatch();
  const isLesson = parentType === ContentTypes.lesson;
  const isLevel = parentType === ContentTypes.level;
  const isChapter = parentType === ContentTypes.chapter;
  const isActivity = parentType === ContentTypes.activity;

  const loadedCourse: CourseInterface = useAppSelector(selectCourse);

  const loadedContent: any = useAppSelector(selectSelectedGroupsOfParentParentContents);

  const loadedExercisesContainer = useAppSelector(selectLoadedExercises);
  const isExercisesLoaded: LoadingStageType = loadedExercisesContainer?.loaded;
  const exercisesCount = loadedExercisesContainer?.exercises?.length || 0;
  const loadedExercises = loadedExercisesContainer?.exercises;

  const groups = useAppSelector(selectGroupsOfSelectedGroupsOfParent);

  const showDebugUI = useShowDebugUI();

  const isDataForActivityPreviewReady =
    loaded === LoadingStage.loaded &&
    parentType === ContentTypes.activity &&
    isExercisesLoaded === LoadingStage.loaded &&
    exercisesCount > 0;
  const isDataForLiveLessonPreviewReady =
    loaded === LoadingStage.loaded &&
    parentType === ContentTypes.lesson &&
    loadedContent?.lessonType === ContentTypes.liveLesson;

  const isSaveButtonEnabled = useAppSelector(selectIsSaveButtonEnabled);

  const shouldShowPreview =
    (isDataForActivityPreviewReady && loadedContent?.activityType !== ContentTypes.speakingActivity) ||
    isDataForLiveLessonPreviewReady ||
    (isExercise && loadedExercise?.exercise?.type !== ContentTypes.listenRepeat);

  const openPreview = useCallback(() => {
    if (isExercise) {
      dispatch(PreviewModalActionsCreator.openPreview());
    } else if (isActivity) {
      dispatch(PreviewModalActionsCreator.openPreviewForExercisesList());
    } else {
      dispatch(PreviewModalActionsCreator.openPreview());
    }
  }, [isExercise, dispatch, isActivity]);

  useEffect(() => {
    const isExerciseChanged =
      isExercise &&
      (dirty ||
        helpersService.checkIsExerciseChanged(loadedExercise?.exercise?.content, loadedExercise?.exercise?.type));
    const isExerciseNotChanged =
      isExercise &&
      !dirty &&
      !helpersService.checkIsExerciseChanged(loadedExercise?.exercise?.content, loadedExercise?.exercise?.type);
    const isCourseChanged = isCourse && dirty;
    const isContentChanged =
      !isCourse &&
      !isExercise &&
      (dirty ||
        loadedContent?.labelsChanged ||
        loadedContent?.certificateTypeChanged ||
        loadedContent?.identityGroupChanged);

    if (isExerciseChanged && !isSaveButtonEnabled) {
      dispatch(ContentTypesActionsCreator.setSaveButtonState(true));
    } else if (isExerciseNotChanged && isSaveButtonEnabled) {
      dispatch(ContentTypesActionsCreator.setSaveButtonState(false));
    } else if (isCourseChanged && !isSaveButtonEnabled) {
      dispatch(ContentTypesActionsCreator.setSaveButtonState(true));
    } else if (isContentChanged && !isSaveButtonEnabled) {
      dispatch(ContentTypesActionsCreator.setSaveButtonState(true));
    }
  }, [isExercise, isCourse, loadedExercise, loadedCourse, loadedContent, dirty, isSaveButtonEnabled, dispatch]);

  const getValidation = () => {
    if (isCourse) {
      return loadedCourse.validation;
    } else if (isExercise) {
      return loadedExercise.exercise.content.validation;
    } else if ((isLesson || isLevel || isChapter || isActivity) && parentContents) {
      return parentContents.validation;
    }
  };

  const getInvalidChildrenArray = () => {
    if (isActivity) {
      return !!loadedExercises.filter((exercise) => !exercise.validation.valid).length;
    } else if (isCourse || isLevel || isChapter || isLesson) {
      return !!groups.filter((group) => !group.validation.valid).length;
    }
    return false;
  };

  const onContentSave = () => {
    if (isCourse) {
      dispatch(
        ContentTypesActionsCreator.saveCourseNameAndDescription({
          courseId: parentId,
          values,
        }),
      );
    } else if (isExercise) {
      saveContent(dispatch, loadedExercise, learningLanguage, values);
    } else {
      dispatch(
        ContentTypesActionsCreator.saveContentNameAndDescription({
          courseId: parentId,
          values,
        }),
      );
    }

    resetForm({ values });
    dispatch(ContentTypesActionsCreator.setSaveButtonState(false));
  };

  const contentType = getContentType({ isCourse, isLevel, isChapter, isLesson, isActivity, isExercise });

  return (
    <HeaderWrapper className="header">
      {isDataForLiveLessonPreviewReady && <LiveLessonPreviewModal />}
      {isDataForActivityPreviewReady && <ListPreviewModal />}
      <ContentData
        contentName={courseName}
        learningLanguage={learningLanguage}
        interfaceLanguages={interfaceLanguages}
        onInterfaceLanguagesClick={onInterfaceLanguagesClick}
      />
      <ButtonsList>
        {shouldShowPreview ? (
          <>
            <PreviewButton onPreviewClick={openPreview} />
            <HeaderItemSeparator />
          </>
        ) : null}
        {contextMenuItems?.length ? (
          <>
            <ContextualMenu
              position="left"
              triggerIcon={<StyledContextualMenuTriggerIcon />}
              variant="small"
              withTrigger
            >
              {contextMenuItems}
            </ContextualMenu>
            <HeaderItemSeparator />
          </>
        ) : null}
        {showDebugUI ? (
          <ValidationButton id={parentId} type={contentType} />
        ) : (
          <IssuesToggler
            type={contentType}
            validation={getValidation()}
            hasChildrenArrayInvalidItems={getInvalidChildrenArray()}
          />
        )}
        <HeaderItemSeparator />
        <PendingChangesButton />
        <SaveButton onSave={onContentSave} />
        <PublishButton type={parentType} isExercise={isExercise} />
      </ButtonsList>
    </HeaderWrapper>
  );
};

export default CourseEditionExerciseHeader;
