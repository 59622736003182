import { StateConfig } from '../useFiltersState';

type FilterFieldProps<T, K extends keyof T> = {
  state: StateConfig<T>;
  name: K;
  render: (value: T[K], onChange: (value: T[K]) => void) => JSX.Element;
};
export const FilterField = <T, K extends keyof T>({ name, render, state }: FilterFieldProps<T, K>) => {
  return render(state.getField(name), state.setField(name));
};
