const TOGGLE_QUEUE_SIDEBAR_VISIBILITY = 'TOGGLE_QUEUE_SIDEBAR_VISIBILITY';
const PUBLISH_OR_UNPUBLISH_CONTENT = 'PUBLISH_OR_UNPUBLISH_CONTENT';
const SET_CONTENT_TO_PUBLISH = 'SET_CONTENT_TO_PUBLISH';
const SET_INITIAL_CONTENT_TO_PUBLISH = 'SET_INITIAL_CONTENT_TO_PUBLISH';
const CHANGE_READY_STATE = 'CHANGE_READY_STATE';
const SET_PUBLISHING_IN_PROGRESS = 'SET_PUBLISHING_IN_PROGRESS';
const SET_INVALID_CHILDREN_MODAL_OPENED = 'SET_INVALID_CHILDREN_MODAL_OPENED';
const ON_MULTI_PUBLISH_SUCCESS = 'ON_MULTI_PUBLISH_SUCCESS';
const ON_MULTI_UNPUBLISH_SUCCESS = 'ON_MULTI_UNPUBLISH_SUCCESS';

export const PublishingActions = {
  TOGGLE_QUEUE_SIDEBAR_VISIBILITY,
  PUBLISH_OR_UNPUBLISH_CONTENT,
  SET_CONTENT_TO_PUBLISH,
  SET_INITIAL_CONTENT_TO_PUBLISH,
  CHANGE_READY_STATE,
  SET_PUBLISHING_IN_PROGRESS,
  SET_INVALID_CHILDREN_MODAL_OPENED,
  ON_MULTI_PUBLISH_SUCCESS,
  ON_MULTI_UNPUBLISH_SUCCESS,
} as const;
