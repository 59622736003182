import { ActivityTypeChangePayload } from '@components/ContentTypes/ActivityCard/ActivityCardTypes';
import { apiClient } from '@features/api';

const ActivitiesService = {
  async changeActivityType({ id, type }: ActivityTypeChangePayload) {
    return await apiClient.v2.post(`/content/activities/${id}/change-activity-type`, { activityType: type });
  },
};

export default ActivitiesService;
