import { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components/macro';

import { DBId } from '@common/types/DBId';
import { WritableInputText } from '@components/WritableInputText';
import IDDisplayer from '@components/IDDisplayer/IDDisplayer';
import { Warning } from '@components/Warning';

import { getLessons, updateIdentityGroup } from '../ProgressMatchingService';
import { LessonAttachedToIdentityGroupType } from '../types';
import { IdentityGroupModalFooter } from './IdentityGroupModalFooter';
import { IdentityGroupLessonsList } from './IdentityGroupLessonsList';

const IdentityGroupModalWrapper = styled(Modal)`
  .modal-dialog {
    max-width: 94rem;
  }

  .modal-content {
    min-height: 73rem;
    border-radius: 1.6rem;
  }
`;

const IdentityGroupModalBody = styled(Modal.Body)`
  padding: 4rem;
`;

const IdentityGroupInput = styled(WritableInputText)`
  font-size: 3rem;
  margin: 0.8rem 0 1rem 0;
`;

export const IdentityGroupModal = ({
  opened,
  identityGroup,
  onClose,
  onChange,
}: {
  opened: boolean;
  identityGroup: { id: DBId; title: string };
  onClose: () => void;
  onChange: (title?: string) => void;
}) => {
  const [newTitle, setNewTitle] = useState('');
  const [lessons, setLessons] = useState<LessonAttachedToIdentityGroupType[]>([]);
  const [loaded, setLoaded] = useState(false);
  const [isSaveInProgress, setIsSaveInProgress] = useState(false);
  const [showPublishedLessonsWarning, setShowPublishedLessonsWarning] = useState(false);

  useEffect(() => {
    if (opened) {
      getLessons(identityGroup.id).then((result) => {
        setLessons(result.data.lessons);
        setShowPublishedLessonsWarning(result.data.publishedLessonsCount > 1);
        setLoaded(true);
      });
    } else {
      setLoaded(false);
      setLessons([]);
      setShowPublishedLessonsWarning(false);
      setIsSaveInProgress(false);
    }
  }, [identityGroup, opened]);

  return (
    <IdentityGroupModalWrapper show={opened} centered onHide={onClose}>
      <IdentityGroupModalBody data-testid="identity-group-lessons-modal-body">
        <IDDisplayer id={identityGroup.id} />
        <IdentityGroupInput value={identityGroup.title} onChange={(value) => setNewTitle(value)} bold withoutBorder />
        {showPublishedLessonsWarning && (
          <Warning text="There are more than one published lesson for this identity group" />
        )}
        <IdentityGroupLessonsList isNotLoadedYet={!loaded} lessons={lessons} />
        <IdentityGroupModalFooter
          isSaveInProgress={isSaveInProgress}
          isChanged={!!newTitle}
          onClose={onClose}
          onSave={() => {
            setIsSaveInProgress(true);
            updateIdentityGroup(identityGroup.id, newTitle).then((result) => {
              if (result.status === 200) {
                onChange(newTitle);
                onClose();
              }
            });
          }}
        />
      </IdentityGroupModalBody>
    </IdentityGroupModalWrapper>
  );
};
