import { ComponentType, useEffect, useState } from 'react';
import { Alert, Button } from 'react-bootstrap';
import moment from 'moment';
import { PublishingActionsCreator } from '@actionCreators/PublishingActionCreator';
import { PublishingQueueItemInterface } from '@common/interfaces/contentTypes/PublishingInterface';
import { Loader, SidePanel, SidePanelBody, SidePanelHeader } from '@features/theme';
import { Flag, FlagTextPositionMode } from '@components/Flag';
import { TableFooter } from '@components/RolesAndPermissions/AdminSettings/TableFooter/TableFooter';
import PublishingService from '@services/PublishingService';
import { Table } from './Table';
import classes from './PublishingQueueSidebar.module.scss';

import { useShowDebugUI } from '@features/app/debug';
import { useIsAdmin } from '@features/auth/roles';
import { isFeatureEnabled } from '@helpers/featuresHelper';
import styled from 'styled-components/macro';
import { SidePanelActionsCreator } from '@actionCreators/SidePanelActionsCreator';
import { useAppDispatch } from '@redux/store';
import { Language } from '@features/content/languages';

const defaultRowSize = 10;
const rowSizes = [10, 25, 50, 100];

const StyledSidePanel = styled(SidePanel)`
  width: 50vw;

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    position: absolute;
    right: 0;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 5px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.color.quaternaryDark};
    transition: all 0.5s;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.color.brandGreyscale800};
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  .modal-content {
    padding: 48px !important;
  }

  .modal-header {
    padding: 0;
  }

  .modal-body {
    gap: 16px;
  }

  .publishing-queue-modal__cancel {
    display: flex;
    position: absolute;
    top: -70px;
    right: 0px;
    background-color: transparent !important;
    border: none !important;
    border-radius: 50% !important;
    margin: 0 !important;
    padding: 10px !important;

    &:hover,
    &:active,
    &:focus {
      background-color: transparent !important;
    }

    &__image {
      height: 16px;
      width: 16px;
    }
  }

  .publishing-queue-modal__list {
    margin-top: 16px;
    padding: 0;

    &__item {
      align-items: center;
      min-height: 54px;
      height: fit-content;
      font-size: 14px;

      .flag__country-flag {
        border: 1px solid rgba(0, 0, 0, 0.1);
        width: 24px;
        height: 24px;
      }

      &__title {
        font-weight: 800;
        margin-right: 16px;
      }

      &__message {
        color: ${(props) => props.theme.color.brandGreyscale800};
        font-weight: normal;
      }
    }
  }

  .publishing-queue-modal__content {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
    padding: 16px;

    &__message {
      color: ${(props) => props.theme.color.brandGreyscale800};
      font-weight: 800;
    }
  }
`;

export const PublishingQueueSidebar: ComponentType = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowSize, setRowSize] = useState(defaultRowSize);
  const [isItemsFetched, setItemsFetched] = useState(false);
  const [items, setItems] = useState<PublishingQueueItemInterface[] | null>(null);
  const [hasNextPage, setHasNextPage] = useState<boolean | null>(null);
  const [isDeploymentFinished, setDeploymentFinished] = useState<boolean | null>(null);

  const dispatch = useAppDispatch();

  const isAdmin = useIsAdmin();

  useEffect(() => {
    if (isAdmin) {
      PublishingService.isDeploymentFinished().then(setDeploymentFinished);
    }
  }, [isAdmin]);

  useEffect(() => {
    PublishingService.getQueue(currentPage, rowSize)
      .then(({ hasNextPage, items }) => {
        setItems(items);
        setHasNextPage(hasNextPage);
      })
      .finally(() => {
        setItemsFetched(true);
      });
  }, [currentPage, rowSize]);

  const renderActionButtons = () => {
    if (isDeploymentFinished !== true) {
      return null;
    }

    if (!isFeatureEnabled('vpn')) {
      return null;
    }

    return (
      <>
        <Button
          className={classes.button}
          disabled={!isItemsFetched}
          onClick={() => {
            if (currentPage === 1) {
              if (isItemsFetched && items !== null && items.length > 0) {
                PublishingService.startDeployment(items[0].commitId);
              }
            } else {
              PublishingService.getQueue(1, 1).then(({ items }) => {
                PublishingService.startDeployment(items[0].commitId);
              });
            }
            setDeploymentFinished(false);
          }}
        >
          Deploy all
        </Button>
      </>
    );
  };

  return (
    <StyledSidePanel
      onClose={() => dispatch(SidePanelActionsCreator.hideSidePanel())}
      header={<SidePanelHeader>Publication queue</SidePanelHeader>}
      body={
        <SidePanelBody>
          <PublishingQueue isItemsFetched={isItemsFetched} items={items} />
          <TableFooter
            currentPage={currentPage}
            rowSize={rowSize}
            rowSizes={rowSizes}
            setCurrentPage={setCurrentPage}
            setRowSize={setRowSize}
            hasNextPage={hasNextPage}
          />
          <div className={classes.buttons}>
            {isDeploymentFinished === false && (
              <Alert variant="warning">Content deploy in progress. Keep an eye on Logos Bot updates.</Alert>
            )}
            <Button
              className={classes.button}
              variant="light"
              onClick={() => dispatch(PublishingActionsCreator.togglePublishingQueuePanel())}
            >
              Close
            </Button>
            {renderActionButtons()}
          </div>
        </SidePanelBody>
      }
    />
  );
};

type PublishingQueueProps = {
  isItemsFetched: boolean;
  items: PublishingQueueItemInterface[] | null;
};
const PublishingQueue: ComponentType<PublishingQueueProps> = ({ isItemsFetched, items }) => {
  const displayProcessedAtDate = useShowDebugUI();

  if (!isItemsFetched || items === null) {
    return <Loader size="L" />;
  }

  if (items.length === 0) {
    return (
      <div className="publishing-queue-modal__content">
        <span className="publishing-queue-modal__content__message">Nothing to show</span>
      </div>
    );
  }

  return (
    <Table
      className="publishing-queue-modal__list"
      items={items}
      renderItem={(item) => <QueueItem displayProcessedAtDate={displayProcessedAtDate} item={item} />}
      rowClassName="publishing-queue-modal__list__item"
    />
  );
};

type QueueItemProps = {
  item: PublishingQueueItemInterface;
  displayProcessedAtDate: boolean;
};

const renderItemDate = (item: PublishingQueueItemInterface, displayProcessedAtDate: boolean) => {
  if (displayProcessedAtDate) {
    return moment(item.date).format('LLL');
  }

  if (!item.requestDate) {
    return '???';
  }

  return moment(item.requestDate).format('LLL');
};

const QueueItem: ComponentType<QueueItemProps> = ({ item, displayProcessedAtDate }) => {
  const course = item.courses[0];

  return (
    <>
      <td className={classes.queueItemFlag}>
        {course && <Flag mode={FlagTextPositionMode.withoutText} countries={[course.lang.toUpperCase() as Language]} />}
      </td>
      <td className={classes.queueItemTitle}>
        {course && <span className="publishing-queue-modal__list__item__title">{course.title}</span>}
      </td>
      <td className="publishing-queue-modal__list__item__message">
        {item.message} {item.username ? `by ${item.username}` : ''}
      </td>
      <td>{renderItemDate(item, displayProcessedAtDate)}</td>
    </>
  );
};
