import { LoadingStage } from '@common/enums/LoadingStage';
import ExerciseDataModelAssembler from '@components/Exercises/ExerciseDataModelAssembler';
import { VocabularyReviewSliceType } from '@common/interfaces/slices/VocabularyReviewSliceType';

export const lexicalItemInitialContent = {
  cefr: null,
  cefrChanged: false,
  changeStatus: {
    hasNewChanges: false,
    hasPendingChanges: false,
  },
  definition: '',
  definitionChanged: false,
  example: {
    ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranches(['textLocalizations', 'audioLocalizations']),
  },
  exampleChanged: false,
  id: '',
  image: null,
  imageChanged: false,
  phrase: {
    ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranches(['textLocalizations', 'audioLocalizations']),
  },
  phraseChanged: false,
  ready: false,
  speechPart: '',
  speechPartChanged: false,
  video: {
    ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranches(['videoLocalizations']),
  },
  videoChanged: false,
  validationStatus: {
    validated: false,
    valid: false,
    errors: [],
    invalidChildEntities: [],
  },
};

export const vocabularyReviewInitialState: VocabularyReviewSliceType = {
  lexicalItem: {
    content: lexicalItemInitialContent,
    language: undefined,
    loaded: LoadingStage.notLoaded,
  },
  vocabularyReview: {
    language: undefined,
    loaded: LoadingStage.notLoaded,
    totalCount: 0,
  },
};
