import { useMemo } from 'react';
import { OptionProps, components, MultiValue, MultiValueGenericProps } from 'react-select';
import styled from 'styled-components/macro';

import { Checkbox } from '@features/theme';

import { CommonFilterOptionType } from '../types';
import { CommonFilterMultiSelector } from '@components/SelectorCommonComponents';

const OptionContainer = styled.div`
  display: flex;
`;

const TypeNameContainer = styled.div`
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 14rem;
`;

const OptionCourseNameContainer = styled(TypeNameContainer)`
  max-width: 20rem;
`;

const TypeSelectorOption = (props: OptionProps<CommonFilterOptionType>) => {
  const {
    data: { label, value },
    isSelected,
  } = props;

  return (
    <components.Option {...props}>
      <OptionContainer key={value}>
        <Checkbox checked={isSelected} onToggle={() => null} />
        <OptionCourseNameContainer>{label}</OptionCourseNameContainer>
      </OptionContainer>
    </components.Option>
  );
};

type TypeSelectorValueContainerProps = MultiValueGenericProps<MultiValue<CommonFilterOptionType>> & {
  title: string;
  value: MultiValue<CommonFilterOptionType> | null;
};

const TypeSelectorValueContainer = ({ children, title, value, ...props }: TypeSelectorValueContainerProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let [_, input] = children as any;

  return (
    <components.MultiValueContainer {...props}>
      <TypeNameContainer title={title}>
        {value && value.length > 1 ? `${value.length} selected` : `${value?.[0].label}`}
      </TypeNameContainer>
      {input}
    </components.MultiValueContainer>
  );
};

const CLASSNAME_PREFIX = 'rule-selector';

type TypeSelectorProps = {
  className?: string;
  rule?: string;
  options: CommonFilterOptionType[];
  placeholder?: string;
  value: MultiValue<CommonFilterOptionType> | null;
  onChange: (option: MultiValue<CommonFilterOptionType> | null) => void;
};

export const TypeSelector = ({
  className,
  rule = 'Type',
  options,
  placeholder,
  value,
  onChange,
}: TypeSelectorProps) => {
  const title = useMemo(() => (value?.length ? value.map((course) => course.label).join('\n') : ''), [value]);

  return (
    <CommonFilterMultiSelector
      classNamePrefix={CLASSNAME_PREFIX}
      className={className}
      closeMenuOnSelect={false}
      value={value}
      options={options}
      placeholder={placeholder ?? rule}
      rule={rule}
      valueContainer={(props: MultiValueGenericProps<MultiValue<CommonFilterOptionType>>) => (
        <TypeSelectorValueContainer title={title} value={value} {...props} />
      )}
      optionContainer={(props: OptionProps<CommonFilterOptionType>) => <TypeSelectorOption {...props} />}
      onChange={onChange}
    />
  );
};

type SimpleTypeSelectorProps<T extends string> = Omit<TypeSelectorProps, 'value' | 'onChange' | 'options'> & {
  types: readonly T[];
  labelGenerator: (type: T) => string;
  value: T[] | null;
  onChange: (value: T[] | null) => void;
};
export const SimpleTypeSelector = <T extends string>({
  types,
  labelGenerator,
  value,
  onChange,
  ...restProps
}: SimpleTypeSelectorProps<T>) => {
  const options = types.map((type) => ({
    label: labelGenerator(type),
    value: type,
  }));

  const selectedTypes = value
    ? value.map((type) => ({
        label: labelGenerator(type),
        value: type,
      }))
    : null;

  return (
    <TypeSelector
      options={options}
      value={selectedTypes}
      onChange={(options) => onChange(options ? options.map((option) => option.value as T) : null)}
      {...restProps}
    />
  );
};
