import { PreviewModalActions } from '@actions/PreviewModalActions';

export const PreviewModalActionsCreator = {
  openPreview: () => ({
    type: PreviewModalActions.OPEN_EXERCISE_PREVIEW,
  }),
  closePreview: () => ({
    type: PreviewModalActions.CLOSE_EXERCISE_PREVIEW,
  }),
  openPreviewForExercisesList: () => ({
    type: PreviewModalActions.OPEN_EXERCISES_LIST_PREVIEW,
  }),
};
