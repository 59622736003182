import { PlacementTestSliceType } from '@common/interfaces/slices/PlacementTestSliceType';
import { LoadingStage } from '@common/enums/LoadingStage';
import { ContentTypes } from '@common/enums/ContentTypes';
import ExerciseDataModelAssembler from '@components/Exercises/ExerciseDataModelAssembler';
import { DisplayContentToUserMode } from '@common/enums/DisplayContentToUserMode';
import { ExerciseTypes } from '@common/enums/ExerciseTypes';

export const entrypointInitialContent = {
  changeStatus: {
    hasNewChanges: false,
    hasPendingChanges: false,
  },
  chapter: null,
  cefr: null,
  dates: {
    firstPublishedAt: null,
    lastChangedAt: null,
  },
  id: '',
  name: '',
  midpoint: false,
  owner: null,
  ready: false,
  title: '',
  type: ContentTypes.entrypoint,
  validationStatus: {
    validated: false,
    valid: false,
    errors: [{ field: 'children', message: 'This collection should contain 1 element or more.', isWarning: false }],
    invalidChildEntities: [],
  },
};

export const placementExerciseInitialContent = {
  id: '',
  changeStatus: {
    hasNewChanges: false,
    hasPendingChanges: false,
  },
  validation: {
    validated: false,
    valid: false,
    errors: [],
    invalidChildEntities: [],
  },
  description: '',
  instructions: {
    ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranches(['textLocalizations', 'audioLocalizations']),
  },
  ready: false,
  instructionsLanguage: DisplayContentToUserMode.interface,
  name: '',
  pdfUrl: '',
  type: ExerciseTypes.flashcard,
  labels: [],
  experiment: null,
};

export const placementTestInitialContent = {
  changeStatus: {
    hasNewChanges: false,
    hasPendingChanges: false,
  },
  dates: {
    firstPublishedAt: null,
    lastChangedAt: null,
  },
  id: '',
  owner: null,
  ready: false,
  type: ContentTypes.placementTest,
  validationStatus: {
    validated: false,
    valid: false,
    errors: [],
    invalidChildEntities: [],
  },
};

export const placementTestInitialState: PlacementTestSliceType = {
  entrypoint: {
    content: entrypointInitialContent,
    loaded: LoadingStage.notLoaded,
    exercisesData: {
      exercises: [],
      loaded: LoadingStage.notLoaded,
    },
  },
  placementTest: {
    content: placementTestInitialContent,
    loaded: LoadingStage.notLoaded,
    entrypointsData: {
      entrypoints: [],
      loaded: LoadingStage.notLoaded,
    },
  },
  placementExercise: { content: placementExerciseInitialContent, loaded: LoadingStage.notLoaded },
  navigation: {
    structure: [],
    loadingParentId: '',
  },
};
