import 'draft-js/dist/Draft.css';
import { Draggable } from 'react-beautiful-dnd';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { ExerciseTypes } from '@common/enums/ExerciseTypes';
import { Sizes } from '@common/enums/Sizes';
import { LocalizationInterface } from '@common/interfaces/localization/LocalizationInterface';
import {
  constants as contentConstants,
  findLocalizationInSearchedLanguage,
  formatCounterOfFilledLocalizations,
} from '@features/content';
import { AudioUploadActionsCreator } from '@actionCreators/AudioUploadActionsCreator';
import { CommonExerciseActionsCreator } from '@actionCreators/CommonExerciseActionsCreator';
import { ContentTypes } from '@common/enums/ContentTypes';
import ContentToggler from '@components/Exercises/Common/ContentToggler/ContentToggler';
import { useIsEditAvailable } from '@features/content/courses';
import { DialogueCharacter } from './DialogueCharactersPanel';
import KTagsWithAudioCreator from './KTagsWithAudioCreator/KTagsWithAudioCreator';
import TranslationsTip from '@components/TranslationTip/TranslationTip';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { DialogueExerciseActionsCreator } from '@actionCreators/DialogueExerciseActionsCreator';
import DialogueExerciseInterface from '@components/Exercises/Dialogue/interfaces/DialogueExerciseInterface';
import { ScriptLine } from '@components/Exercises/Dialogue/types/ScriptLine';
import { ValidationErrorInterface } from '@common/interfaces/validation/ValidationInterface';
import { Language, LANGUAGE_NAMES } from '@features/content/languages';
import { useCallback } from 'react';
import { selectInterfaceLanguages } from '@selectors/CoursesSelectors';

type DialogueScriptLineProps = {
  loadedExercise: DialogueExerciseInterface;
  scriptLine: ScriptLine;
  dialogueLineIndex: number;
  characters: any;
  translationsPanelVisible: boolean;
  courseLearningLanguage: Language;
  charactersDisplayerList: any;
  errors?: ValidationErrorInterface[];
};

const DialogueScriptLine = ({
  loadedExercise,
  scriptLine,
  dialogueLineIndex,
  characters,
  translationsPanelVisible,
  courseLearningLanguage,
  charactersDisplayerList,
  errors = [],
}: DialogueScriptLineProps) => {
  const dispatch = useAppDispatch();
  const { isEditAvailable } = useIsEditAvailable();
  const otherPlacesCount: number = scriptLine.line?.mappings?.count || scriptLine.line?.mappings?.length || 0;
  const interfaceLanguages = useAppSelector(selectInterfaceLanguages);

  const getValueFromLocalization = () => {
    const localization = findLocalizationInSearchedLanguage(
      scriptLine.line.textLocalizations,
      courseLearningLanguage,
    ) as LocalizationInterface;

    return localization.value;
  };

  const getAudioData = useCallback(() => {
    const localizations = scriptLine?.line?.audioLocalizations;

    if (localizations) {
      const foundLocalization = localizations.find((localization) => {
        return localization.language === courseLearningLanguage;
      });

      return {
        audioRequest: foundLocalization?.audioRequest,
        contentId: scriptLine?.line?.id || scriptLine?.line?._id || '',
        mediaId: foundLocalization?._id || '',
        mediaValue: foundLocalization?.value || '',
        processed: foundLocalization?.processed || false,
      };
    }
  }, [courseLearningLanguage, scriptLine]);

  return (
    <Draggable
      draggableId={scriptLine.line._id as string}
      index={dialogueLineIndex}
      key={scriptLine.line._id as string}
    >
      {(provided: any, snapshot: any) => {
        const draggableProps = isEditAvailable ? { ...provided.draggableProps } : {};
        const dragHandleProps = isEditAvailable ? { ...provided.dragHandleProps } : {};
        return (
          <li
            className={`exercise-dialogue__dialogue-line ${
              snapshot.isDragging ? 'exercise-dialogue__dialogue-line--dragging' : ''
            }`}
            key={dialogueLineIndex}
            ref={provided.innerRef}
            {...draggableProps}
            {...dragHandleProps}
          >
            <div className="exercise-dialogue__dialogue-line-contents">
              <DialogueCharacter
                imgURL={characters[scriptLine.characterIndex].image}
                selectable={false}
                rollovable={false}
                showAsActive={true}
                showAddButton={false}
                showAsDraggable={true}
                size={Sizes.small}
              />
              <div className="exercise-dialogue__dialogue-name-and-dialogue-wrapper">
                <ContentToggler
                  open={true}
                  text={
                    characters[scriptLine.characterIndex].name.length > 0
                      ? characters[scriptLine.characterIndex].name
                      : contentConstants.DIALOGUE_CHARACTER_BASIC_PLACEHOLDER
                  }
                  showSwitchCharacter={characters.length > 1 ? true : false}
                  showRemoveItem={true}
                  showRemoveByToggling={false}
                  onSwitch={() => {
                    dispatch(DialogueExerciseActionsCreator.switchCharacterForDialogueLine(dialogueLineIndex));
                  }}
                  onRemove={() => {
                    dispatch(DialogueExerciseActionsCreator.deleteDialogueLine(dialogueLineIndex));
                  }}
                  errors={errors}
                />
                <TranslationsTip
                  localizationIDForLangExperts={!scriptLine.line._id ? 'N/A' : scriptLine.line._id}
                  translationElements={formatCounterOfFilledLocalizations(
                    scriptLine.line.textLocalizations,
                    interfaceLanguages as Language[],
                    courseLearningLanguage as Language,
                  )}
                  audioElements={formatCounterOfFilledLocalizations(
                    scriptLine.line.audioLocalizations,
                    interfaceLanguages as Language[],
                    courseLearningLanguage as Language,
                  )}
                  visitedContentId={scriptLine.line._id}
                  visitedBranch={contentConstants.DIALOGUE_EXERCISE_SCRIPT_STATE_BRANCH_NAME}
                  languageForTips={courseLearningLanguage}
                  row={dialogueLineIndex}
                  showLanguageSwitcher={false}
                  isStringChangeBlocked={
                    !otherPlacesCount || otherPlacesCount === 1 ? false : !scriptLine.line.isReusingConfirmed
                  }
                  isBundleChangeBlocked={false}
                  isExercise={true}
                  suggestionsQuery={getValueFromLocalization()}
                >
                  <KTagsWithAudioCreator
                    audio={getAudioData()}
                    dataSourceMode={translationsPanelVisible ? 'editorState' : 'defaultEditorState'}
                    value={getValueFromLocalization()}
                    onAudioRequestSuccess={(audioRequestId: string) => {
                      dispatch(
                        CommonExerciseActionsCreator.setAudioValueAfterRequestOrCancelAudio({
                          audioRequestData: {
                            id: audioRequestId,
                            status: 'new',
                          },
                          field: 'script',
                          language: courseLearningLanguage,
                          row: dialogueLineIndex,
                          type: ContentTypes.exercise,
                        }),
                      );
                    }}
                    onCancelAudioRequestSuccess={() => {
                      dispatch(
                        CommonExerciseActionsCreator.setAudioValueAfterRequestOrCancelAudio({
                          field: 'script',
                          language: courseLearningLanguage,
                          row: dialogueLineIndex,
                          type: ContentTypes.exercise,
                        }),
                      );
                    }}
                    onChange={(newText: string) => {
                      dispatch(
                        DialogueExerciseActionsCreator.updateDialogueLine(
                          newText,
                          dialogueLineIndex,
                          courseLearningLanguage,
                        ),
                      );
                    }}
                    onProcessingFinished={(url: string) => {
                      dispatch(
                        CommonExerciseActionsCreator.setValueAfterProcessing({
                          url,
                          mediaType: 'audio',
                          type: ContentTypes.exercise,
                          field: 'script',
                          language: courseLearningLanguage,
                          row: dialogueLineIndex,
                        }),
                      );
                    }}
                    placeholder={`Write in ${LANGUAGE_NAMES[courseLearningLanguage]}`}
                    onAudioUpload={(uploadedSound, progressHandler) => {
                      dispatch(
                        AudioUploadActionsCreator.uploadSound(
                          loadedExercise.content.id,
                          ExerciseTypes.dialogue,
                          courseLearningLanguage,
                          scriptLine?.line._id,
                          findLocalizationInSearchedLanguage(
                            scriptLine?.line?.audioLocalizations,
                            courseLearningLanguage,
                          ),
                          uploadedSound,
                          'script',
                          dialogueLineIndex,
                          undefined,
                          undefined,
                          undefined,
                          progressHandler,
                        ),
                      );
                    }}
                    onAudioRemove={() => {
                      dispatch(
                        DialogueExerciseActionsCreator.removeAudioFromScript(
                          scriptLine?.line._id,
                          courseLearningLanguage,
                        ),
                      );
                    }}
                    errors={errors}
                    currentLanguage={courseLearningLanguage}
                    fieldName={'script'}
                    row={dialogueLineIndex}
                  />
                </TranslationsTip>
              </div>
            </div>
            <div className="standalone-menu">
              <div className={`standalone-menu__inner standalone-menu__inner--${characters.length}`}>
                {isEditAvailable ? charactersDisplayerList(dialogueLineIndex) : null}
              </div>
              <div className={`standalone-menu__line`} />
            </div>
          </li>
        );
      }}
    </Draggable>
  );
};

export default DialogueScriptLine;
