import styled from 'styled-components/macro';

import { ActivitySearchResultType, ActivityDetailsTab } from '../../types';
import { CardWrapper, CardLocations, CardMoreButton } from '../Common';
import { CardActivityTitle } from './CardActivityTitle';

const ActivityCardWrapper = styled(CardWrapper)`
  grid-template-columns: 74% 20% 4%;
`;

export const ActivityResultCard = ({
  content,
  isExpanded,
  onExpand,
}: {
  content: ActivitySearchResultType;
  isExpanded: boolean;
  onExpand: (tabName: ActivityDetailsTab['key'] | null) => void;
}) => (
  <ActivityCardWrapper isExpanded={isExpanded} onClick={() => onExpand(null)}>
    <CardActivityTitle id={content.id} activityType={content.class} />
    <CardLocations
      courses={content.courses}
      locationsCount={content.locationsCount}
      onExpand={() => onExpand('locations')}
    />
    <CardMoreButton isExpanded={isExpanded} />
  </ActivityCardWrapper>
);
