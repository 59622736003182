import styled from 'styled-components/macro';

import { OtherLocationsInterface } from '@common/interfaces/exercises/OtherLocationsInterface';
import { OtherLocations } from '@components/OtherLocations';
import { Button } from '@features/theme';

const Title = styled.div`
  color: ${({ theme }) => theme.color.brandBlack};
  font-weight: 800;
  font-size: 2rem;
  margin-bottom: 0.8rem;
  text-align: center;
`;

const Description = styled.div`
  color: ${({ theme }) => theme.color.brandGreyscale800};
  font-weight: 400;
  font-size: 1.6rem;
  margin-bottom: 3.2rem;
  text-align: center;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

type OtherPlacesModalProps = {
  mappingsDetails: OtherLocationsInterface[][];
  mappings: number;
  close: () => void;
};

export const OtherPlacesModal = ({ mappingsDetails, mappings, close }: OtherPlacesModalProps) => (
  <>
    <Title>{`Used in ${mappings} Locations`}</Title>
    <Description>This content is used in the places listed below. Choose the one you want to open.</Description>
    <OtherLocations mappingsDetails={mappingsDetails} />
    <ButtonsContainer>
      <Button size="M" variant="tertiary" onClick={close}>
        Close
      </Button>
    </ButtonsContainer>
  </>
);
