import { OtherLocationsInterface } from '@common/interfaces/exercises/OtherLocationsInterface';
import { DBId } from '@common/types/DBId';
import TranslationsPanelService from '@services/TranslationsPanelService';

export const getRealOtherLocationsForRoleplay = async ({
  categoryId = '',
  roleplayId,
  scenarioId = '',
  visitedContentId,
}: {
  categoryId: DBId;
  roleplayId: DBId;
  scenarioId: DBId;
  visitedContentId: DBId;
}) => {
  const otherLocationsPayload = await TranslationsPanelService.getOtherLocations(visitedContentId);

  const realOtherLocations = otherLocationsPayload.data.filter((mappings: OtherLocationsInterface[]) => {
    const idsInMapping = mappings.map((mapping) => mapping.id);

    return (
      !idsInMapping.includes(scenarioId) || !idsInMapping.includes(categoryId) || !idsInMapping.includes(roleplayId)
    );
  });

  return realOtherLocations;
};
