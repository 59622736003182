import { useEffect, useState } from 'react';

import UsersService from '@services/UsersService';
import { Role } from '@features/auth/roles';

export type UserListItem = {
  id: string;
  fullName: string;
  manager?: string;
};

const ONE_WEEK_MS = 1000 * 60 * 60 * 24 * 7;
const DEFAULT_USER_LIST_STORAGE_KEY = 'users-all';

const userListCache: Record<string, UserListItem[]> = {};
const userListLastUpdatedCache: Record<string, string> = {};

const shouldRefreshUserList = (lastUpdatedStorageKey: string, expirationInterval: number): boolean => {
  const userListLastUpdatedString =
    userListLastUpdatedCache[lastUpdatedStorageKey] || sessionStorage.getItem(lastUpdatedStorageKey);

  if (!userListLastUpdatedString) return true;

  const userListLastUpdatedTime = new Date(userListLastUpdatedString).getTime();
  const currentTime = new Date().getTime();

  return currentTime - userListLastUpdatedTime > expirationInterval;
};

type UseUserListConfigType = {
  excludedRoles?: Role[];
  expirationInterval?: number;
  storageKey?: string;
};

export const useUserList = ({
  excludedRoles = [],
  expirationInterval = ONE_WEEK_MS,
  storageKey = DEFAULT_USER_LIST_STORAGE_KEY,
}: UseUserListConfigType = {}) => {
  const [userList, setUserList] = useState<UserListItem[]>(
    userListCache[storageKey] || JSON.parse(sessionStorage.getItem(storageKey) || '[]'),
  );

  const lastUpdatedStorageKey = `${storageKey}-last-updated`;

  useEffect(() => {
    if (shouldRefreshUserList(lastUpdatedStorageKey, expirationInterval) || !userList?.length) {
      try {
        (async () => {
          const {
            data: { users },
          } = await UsersService.getUserList(excludedRoles);

          if (users) {
            userListCache[storageKey] = users;
            userListLastUpdatedCache[lastUpdatedStorageKey] = new Date().toISOString();

            sessionStorage.setItem(storageKey, JSON.stringify(users));
            sessionStorage.setItem(lastUpdatedStorageKey, userListLastUpdatedCache[lastUpdatedStorageKey]);

            setUserList(userListCache[storageKey]);
          } else {
            throw new Error('No user list fetched');
          }
        })();
      } catch (error) {
        console.error(error);
      }
    }
  }, [excludedRoles, expirationInterval, lastUpdatedStorageKey, storageKey, userList]);

  return { userList };
};
