import styled, { css } from 'styled-components';

import { Dropdown, DropdownInterface } from '@components/Dropdown';
import { SearchableSelector, SelectorOptionType } from '@components/SearchableSelector/SearchableSelector';

import refreshIcon from './refreshIcon';

const ExperimentContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const dropdownButtonStyle = css`
  margin: 0;
  max-width: 250px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const refreshIconStyle = css`
  background: url(${refreshIcon}) no-repeat 12px center / 18px 18px;
  color: inherit;
  padding-left: 36px;
`;

const ExperimentSelector = styled(SearchableSelector)`
  .searchable-selector__control {
    height: 40px;
    width: 250px;
    font-size: 14px;
  }
  .searchable-selector__value-container {
    height: 40px;
    padding-left: 16px;
    padding-right: 26px;
  }
  .searchable-selector__placeholder {
    color: ${({ theme }) => theme.color.brandBlack} !important;
    ${dropdownButtonStyle}
  }
  .searchable-selector__menu {
    font-size: 15px;
    border-color: #00000026 !important;
  }
  .searchable-selector__option:nth-child(2) {
    ${refreshIconStyle}
  }
  .searchable-selector__option--is-selected:hover {
    background-color: ${({ theme }) => theme.color.brandPrimaryBase} !important;
  }
  :after {
    border-top: 4px solid;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
    content: '';
    pointer-events: none;
    position: absolute;
    right: 10px;
    top: calc(50% - 2px);
    z-index: 0;
  }
  > div {
    border: 1px solid ${({ theme }) => theme.color.quaternaryDark} !important;
  }
  min-width: 250px;
  margin-right: 16px;

  > button {
    ${dropdownButtonStyle}
  }
`;

const ExperimentDropdown = styled(Dropdown)`
  height: 40px;
  min-width: 250px;
  margin-right: 16px;

  > button {
    height: 100%;
    ${dropdownButtonStyle}
  }

  .dropdown-item-refresh {
    ${refreshIconStyle}
  }
`;

type ExperimentSelectorViewProps = {
  experiments: SelectorOptionType[];
  selectedExperiment: string;
  experimentSelectorDisabled: boolean;
  onExperimentSelect: (selectedOption: SelectorOptionType | null) => void;

  isExperimentVariationSelectorVisible: boolean;
  experimentVariations: DropdownInterface[];
  selectedExperimentVariation: string;
  experimentVariationSelectorDisabled: boolean;
  onExperimentVariationSelect: (experimentVariation: DropdownInterface) => void;
};

export const ExperimentSelectorView = ({
  experiments,
  selectedExperiment,
  experimentSelectorDisabled,
  onExperimentSelect,
  isExperimentVariationSelectorVisible,
  experimentVariations,
  selectedExperimentVariation,
  experimentVariationSelectorDisabled,
  onExperimentVariationSelect,
}: ExperimentSelectorViewProps) => (
  <ExperimentContainer data-testid="experiment-selector">
    <ExperimentSelector
      testId="experiment-dropdown"
      options={experiments}
      disabled={experimentSelectorDisabled}
      onSelect={onExperimentSelect}
      placeholder={selectedExperiment || 'No experiment'}
      value={selectedExperiment || undefined}
    />
    {isExperimentVariationSelectorVisible && (
      <ExperimentDropdown
        id="experiment-variation-dropdown"
        title={selectedExperimentVariation || 'Select experiment variation'}
        values={experimentVariations}
        disabled={experimentVariationSelectorDisabled}
        onChange={onExperimentVariationSelect}
        value={selectedExperimentVariation}
      />
    )}
  </ExperimentContainer>
);
