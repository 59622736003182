import styled, { css } from 'styled-components/macro';

import type { AudioUploadProps } from './AudioUpload';
import { ReactComponent as UploadIcon } from './img/Upload.svg';

type AudioUploadWrapperProps = Partial<AudioUploadProps> & {
  isDragActive?: boolean;
  isUploadInProgress?: boolean;
  processing?: boolean;
  withError?: boolean;
  withErrorFailed?: boolean;
  withFile?: boolean;
};

export const AudioUploadWrapper = styled.div<AudioUploadWrapperProps>`
  audio::-webkit-media-controls-play-button,
  audio::-webkit-media-controls-panel {
    background-color: ${({ theme }) => theme.colorV2.uiBackgroundPrimary};
    color: ${({ theme }) => theme.colorV2.textPrimary};
  }

  ${({
    disabled,
    fullScreen,
    isDragActive,
    isUploadInProgress,
    processing,
    theme,
    withError,
    withErrorFailed,
    withFile,
  }) => {
    return css`
      align-items: center;
      background: ${disabled ? theme.colorV2.uiDivider : withFile ? theme.colorV2.uiBackgroundPrimary : 'transparent'};
      border: ${withFile ? `0.1rem solid ${theme.colorV2.uiDivider}` : 'none'};
      border-radius: 0.4rem;
      display: flex;
      flex-direction: row;
      justify-content: ${processing ? 'center' : 'flex-start'};
      padding-left: 0.1rem;
      padding-right: 0.1rem;
      position: ${isUploadInProgress ? 'relative' : 'static'};
      height: ${isUploadInProgress ? '5rem' : 'auto'};

      &:hover {
        cursor: default;
      }

      audio {
        height: 3rem;
        width: ${fullScreen ? 'calc(100% - 8rem)' : 'auto'};
      }

      ${AudioUploadInnerWrapper} {
        height: ${withFile ? '5rem' : 'inherit'};
      }

      ${AudioUploadInstructions} {
        border-color: ${isDragActive
          ? theme.colorV2.accentPrimary
          : withError
            ? theme.colorV2.utilityError
            : theme.colorV2.input.border.default};
        border-style: ${withErrorFailed ? 'solid' : 'dashed'};
      }
    `;
  }}
`;

export const AudioUploadInnerWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 0.8rem;
  position: relative;
  width: 100%;
`;

export const AudioUploadInstructions = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colorV2.uiBackgroundPrimary};
  border: 0.1rem dashed ${({ theme }) => theme.colorV2.input.border.default};
  border-radius: 0.8rem;
  display: flex;
  font-size: 1.4rem;
  text-align: center;
  padding: 1rem 1.6rem 1rem 0.8rem;
  width: 100%;

  &:hover {
    cursor: pointer;
  }
`;

export const AudioUploadInstructionsDescription = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  color: ${({ theme }) => theme.colorV2.textPrimary};
  font-weight: 700;

  label {
    margin-bottom: 0;
  }
`;

export const AudioUploadInstructionsDetails = styled.span`
  color: ${({ theme }) => theme.colorV2.textSecondary};
  font-size: 1.2rem;
  font-weight: 400;
  margin-left: 0.8rem;
`;

export const AudioUploadInProgress = styled.div`
  border: solid 0.1rem ${({ theme }) => theme.colorV2.utilitySuccess};
  border-radius: 0.4rem;
  height: 100%;
  overflow: hidden;
  width: 100%;

  progress {
    width: 100%;
    height: 100%;
    border: none;

    &::-webkit-progress-bar {
      background-color: ${({ theme }) => theme.colorV2.uiBackgroundPrimary};
      width: 100%;
      height: 100%;
    }

    &::-webkit-progress-value {
      background-color: ${({ theme }) => theme.colorV2.utilitySuccess};
      transition: all 0.3s;
    }
  }

  span {
    font-weight: 700;
    font-size: 14px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    top: 12px;
  }
`;

export const AudioUploadProcessing = styled.div`
  background-color: ${({ theme }) => theme.colorV2.uiBackgroundPrimary};
  border: 0.1rem solid ${({ theme }) => theme.colorV2.uiDivider};
  border-radius: 0.4rem;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const AudioUploadProcessingInfo = styled.div`
  align-items: center;
  display: flex;
  font-size: 1.5rem;
  gap: 0.8rem;
  padding-left: 1.6rem;

  img {
    height: 2rem;
    width: 2rem;
  }
`;

export const StyledUploadIcon = styled(UploadIcon)`
  width: 7.2rem;
`;
