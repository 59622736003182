const CHANGE_COMPONENT_OWNER = 'CHANGE_COMPONENT_OWNER';
const SET_CONTENT_OWNER = 'SET_CONTENT_OWNER';
const SET_EXERCISE_OWNER = 'SET_EXERCISE_OWNER';
const SET_GRAMMAR_REVIEW_OWNER = 'SET_GRAMMAR_REVIEW_OWNER';
const SET_GRAMMAR_CATEGORY_OWNER = 'SET_GRAMMAR_CATEGORY_OWNER';
const SET_GRAMMAR_TOPIC_OWNER = 'SET_GRAMMAR_TOPIC_OWNER';
const SET_PLACEMENT_TEST_OWNER = 'SET_PLACEMENT_TEST_OWNER';
const SET_ENTRYPOINT_OWNER = 'SET_ENTRYPOINT_OWNER';

export const ContentOwnershipActions = {
  CHANGE_COMPONENT_OWNER,
  SET_CONTENT_OWNER,
  SET_EXERCISE_OWNER,
  SET_GRAMMAR_REVIEW_OWNER,
  SET_GRAMMAR_CATEGORY_OWNER,
  SET_GRAMMAR_TOPIC_OWNER,
  SET_PLACEMENT_TEST_OWNER,
  SET_ENTRYPOINT_OWNER,
} as const;
