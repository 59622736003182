import { useState } from 'react';

import { SearchModalActionsCreator } from '@actionCreators/SearchModalActionsCreator';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { selectSelectedGroupsOfParentParentContents } from '@selectors/CoursesSelectors';
import { selectSearchPanelPosition } from '@selectors/UiSelectors';
import { userTracking } from '@features/app/tracking';

import { ActivityResultCard } from './SearchResultItemCard';
import { ActivityResultDetails } from './SearchResultItemDetails';
import { getActivityResultDetails } from './services';
import { ActivityDetailsTab, ActivitySearchResultDetailsType, ActivitySearchResultType } from './types';

export const ActivitySearchResultItem = ({ content }: { content: ActivitySearchResultType }) => {
  const dispatch = useAppDispatch();
  const [isExpanded, setIsExpanded] = useState(false);
  const [details, setDetails] = useState<ActivitySearchResultDetailsType | null>(null);
  const [isDetailsLoaded, setIsDetailsLoaded] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [defaultTab, setDefaultTab] = useState<ActivityDetailsTab['key'] | null>(null);

  const currentContent = useAppSelector(selectSelectedGroupsOfParentParentContents);
  const position = useAppSelector(selectSearchPanelPosition) || 0;

  const onExpand = (tabName: ActivityDetailsTab['key'] | null) => {
    setDefaultTab(tabName);
    setIsExpanded(!isExpanded);
    if (!isExpanded && !details) {
      getActivityResultDetails(content.id)
        .then(({ data }) => {
          setIsDetailsLoaded(true);
          setDetails(data);
        })
        .catch((error) => {
          setIsDetailsLoaded(true);
          setDetails(null);
          if (error.response.data.detail) {
            setErrorMessage(error.response.data.detail);
          } else {
            setErrorMessage(error.response.data.message || 'Unknown error');
          }
        });
    }
  };

  const onCopyContent = () => {
    currentContent?.id && dispatch(SearchModalActionsCreator.copyActivity(content.id, currentContent.id, position));
  };

  const onUseContent = () => {
    currentContent?.id && dispatch(SearchModalActionsCreator.reuseActivity(content.id, currentContent.id, position));
    userTracking.logosSearchReuseSelected({
      type: 'activity',
    });
  };

  return (
    <div data-testid="search-result-item-activity">
      <ActivityResultCard content={content} isExpanded={isExpanded} onExpand={onExpand} />
      <ActivityResultDetails
        defaultTab={defaultTab}
        details={details}
        errorMessage={errorMessage}
        isExpanded={isExpanded}
        isLoaded={isDetailsLoaded}
        resultId={content.id}
        onCopyContent={currentContent ? onCopyContent : undefined}
        onUseContent={currentContent ? onUseContent : undefined}
      />
    </div>
  );
};
