import { isExerciseReadyForPublish, isExerciseReadyForUnpublish } from '@features/content/exercises';
import { ExerciseListItemType } from '@components/ContentTypes/ExerciseCard/types';
import { useAppSelector } from '@redux/store';
import { selectBulkPublishingSelection, selectBulkPublishingState } from '@selectors/BulkPublishingSelectors';

export const useBulkPublishing = () => {
  const selectedForBulkPublishing = useAppSelector(selectBulkPublishingSelection);
  const currentBulkPublishingState = useAppSelector(selectBulkPublishingState);

  const getIsExerciseDisabledForBulkPublishing = (exercise: ExerciseListItemType) => {
    if (currentBulkPublishingState === 'publish') {
      return !isExerciseReadyForPublish(exercise);
    } else if (currentBulkPublishingState === 'unpublish') {
      return !isExerciseReadyForUnpublish(exercise);
    } else {
      return false;
    }
  };

  return { selectedForBulkPublishing, currentBulkPublishingState, getIsExerciseDisabledForBulkPublishing };
};
