import { ContentTypes } from '@common/enums/ContentTypes';
import { ContentCreatorWidget, type ContentCreatorWidgetItemType } from '@features/theme';
import roleplayScenarioIcon from '@static/svg/contentCreatorWidget/roleplayScenario.svg';

const ROLEPLAY_SCENARIO_CREATOR_ITEM: ContentCreatorWidgetItemType<typeof ContentTypes.roleplayScenario>[] = [
  {
    label: 'Roleplay Scenario',
    iconSrc: roleplayScenarioIcon,
    showByDefault: true,
    type: ContentTypes.roleplayScenario,
  },
];

type RoleplayScenarioCreatorProps = {
  isCreateInProgress?: boolean;
  isEditAvailable: boolean;
  isPreEnabled: boolean;
  position: number;
  onCreateContent: (itemType: typeof ContentTypes.roleplayScenario, itemCategory: null) => void;
};

export const RoleplayScenarioCreator = ({
  isCreateInProgress = false,
  isEditAvailable,
  isPreEnabled,
  position,
  onCreateContent,
}: RoleplayScenarioCreatorProps) => (
  <ContentCreatorWidget
    isCreateInProgress={isCreateInProgress}
    isEditAvailable={isEditAvailable}
    isPreEnabled={isPreEnabled}
    items={ROLEPLAY_SCENARIO_CREATOR_ITEM}
    position={position}
    title="Create a first roleplay scenario to get started"
    onItemSelected={(item) => onCreateContent(item.type, null)}
  />
);
