import { FC } from 'react';

import { ResultTypes, SearchResultType } from '../SearchResultItem';
import { StringSearchResultTypeHeader } from './StringSearchResultTypeHeader';
import { BundleSearchResultTypeHeader } from './BundleSearchResultTypeHeader';
import { LessonSearchResultTypeHeader } from './LessonSearchResultTypeHeader';
import { ChapterSearchResultTypeHeader } from './ChapterSearchResultTypeHeader';
import { LevelSearchResultTypeHeader } from './LevelSearchResultTypeHeader';
import { ActivitySearchResultTypeHeader } from './ActivitySearchResultTypeHeader';
import { ExerciseSearchResultTypeHeader } from './ExerciseSearchResultTypeHeader';
import { GrammarCategorySearchResultTypeHeader } from './GrammarCategorySearchResultTypeHeader';
import { GrammarTopicSearchResultTypeHeader } from './GrammarTopicSearchResultTypeHeader';

const headerComponent: { [key in ResultTypes]: FC | null } = {
  content: () => <StringSearchResultTypeHeader />,
  resourceBundle: () => <BundleSearchResultTypeHeader />,
  exercise: () => <ExerciseSearchResultTypeHeader />,
  activity: () => <ActivitySearchResultTypeHeader />,
  lesson: () => <LessonSearchResultTypeHeader />,
  chapter: () => <ChapterSearchResultTypeHeader />,
  level: () => <LevelSearchResultTypeHeader />,
  grammarTopic: () => <GrammarTopicSearchResultTypeHeader />,
  grammarCategory: () => <GrammarCategorySearchResultTypeHeader />,
};

export const IdSearchResultTypeHeader = ({ results }: { results: SearchResultType[] }) => {
  const HeaderComponent = results.length ? headerComponent[results[0].type] : null;

  return HeaderComponent ? <HeaderComponent /> : null;
};
