import { apiClient } from '@features/api';
import { DBId } from '@common/types/DBId';

export const bulkPublish = async (exercises: DBId[], parentId: DBId): Promise<void> => {
  return await apiClient.noErrorsV2.post('/content/exercises/bulk-publish', { exercises, parentId });
};

export const bulkUnpublish = async (exercises: DBId[], parentId: DBId, parentType: string): Promise<void> => {
  return await apiClient.noErrorsV2.post('/content/exercises/bulk-unpublish', { exercises, parentId, parentType });
};
