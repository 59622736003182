import { takeLatest } from 'redux-saga/effects';

import { MultipleChoiceExerciseActions } from '@actions/MultipleChoiceExerciseActions';
import ExerciseSagas from '@sagas/exercises/definitions/ExerciseSagas';
import MediaUploadSagas from '@sagas/mediaUpload/definitions/MediaUploadSagasDefinition';
import MultipleChoiceExerciseSagas from '@sagas/exercises/definitions/MultipleChoiceExerciseSagasDefinition';
import type { AppDispatch } from '@redux/store';

export function* MultipleChoiceExerciseRelatedSagas(dispatch: AppDispatch) {
  yield takeLatest(MultipleChoiceExerciseActions.SAVE, MultipleChoiceExerciseSagas.save, dispatch);
  yield takeLatest(MultipleChoiceExerciseActions.UPLOAD_MAIN_CONTENT_IMAGE, MediaUploadSagas.uploadImageToExercise);
  yield takeLatest(MultipleChoiceExerciseActions.UPLOAD_ANSWER_IMAGE, MediaUploadSagas.uploadImageToExercise);
  yield takeLatest(MultipleChoiceExerciseActions.UPLOAD_DISTRACTOR1_IMAGE, MediaUploadSagas.uploadImageToExercise);
  yield takeLatest(MultipleChoiceExerciseActions.UPLOAD_DISTRACTOR2_IMAGE, MediaUploadSagas.uploadImageToExercise);
  yield takeLatest(MultipleChoiceExerciseActions.REMOVE_AUDIO, ExerciseSagas.removeAudioFromExercise);
}
