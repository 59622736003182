import { Modal } from 'react-bootstrap';
import styled from 'styled-components/macro';
import { ReactNode } from 'react';

const StyledModal = styled(Modal)`
  min-width: 60rem;
  right: 0;
  width: 33vw;
  background: ${(props) => props.theme.color.brandWhite};
  height: 100%;
  left: initial;
  z-index: 8000;

  .modal-dialog {
    margin: 0;
    max-width: initial;

    .modal-content {
      border: 0;
      border-radius: 0;

      font-size: 1.5rem;
      padding-left: 5.5rem;
      padding-top: 2rem;
    }
  }
`;

const StyledModalHeader = styled(Modal.Header)`
  font-size: 3rem;
  font-weight: 700;
  padding-left: 0;
  padding-bottom: 2.5rem;
  border: 0;
  position: relative;

  align-items: center;
  display: flex;
  width: 100%;
`;

const StyledModalBody = styled(Modal.Body)`
  display: flex;
  flex-direction: column;
  padding-top: 0;
  padding-left: 0;
`;

export const SidePanelHeader = StyledModalHeader;

export const SidePanelBody = StyledModalBody;

type SidePanelProps = {
  className?: string;
  header?: ReactNode;
  body: ReactNode;
  onClose?: () => void;
};

export const SidePanel = ({ className, header, body, onClose }: SidePanelProps) => (
  <StyledModal show={true} animation={false} className={className} onHide={() => onClose && onClose()}>
    {header}
    {body}
  </StyledModal>
);
