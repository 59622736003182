import { ToastType } from './types';
import styled, { css, keyframes } from 'styled-components/macro';
import infoIcon from './_img/info.svg';
import successIcon from './_img/success.svg';
import errorIcon from './_img/error.svg';
import { Theme } from '@features/theme';
import { useEffect, useRef, useState } from 'react';

const toastIcons: Record<ToastType, string> = {
  info: infoIcon,
  success: successIcon,
  error: errorIcon,
};

const slideAnimation = keyframes`
  from {
    transform: translateX(200%);
  }

  to {
    transform: translateX(0);
  }
`;

const progressAnimation = keyframes`
  from {
    background-position-x: 100%;
  }
  to {
    background-position-x: 0;
  }
`;

const TOAST_DURATION = 3500;
const SLIDE_DURATION = 400;

const StyledToast = styled.div<{ state: ToastState; type: ToastType }>`
  ${({ theme }) => css`
    width: 38.8rem;
    padding: 1.2rem;

    box-shadow: 0 0.6rem 1rem rgba(0, 0, 0, 0.1);
    background-color: ${theme.color.brandWhite};
    border: 0.1rem solid ${theme.color.brandGreyscale600};
    border-bottom: solid 0.3rem transparent;
    background-clip: padding-box;
    border-radius: 0.8rem;
    box-sizing: border-box;

    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 0.8rem;

    &:hover::before {
      animation-play-state: paused;
    }

    &::before {
      content: '';
      position: absolute;
      height: 50%;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      margin: -0.1rem -0.1rem -0.3rem;
      border-radius: inherit;
      background: linear-gradient(to right, ${theme.color.brandPrimaryBase} 50%, ${theme.color.brandGreyscale600} 50%);
      background-size: 200%;
    }
  `}

  ${({ theme, state }) => {
    if (state === 'progress') {
      return css`
        &::before {
          animation: ${progressAnimation} ${TOAST_DURATION}ms linear forwards;
        }
      `;
    } else {
      return css`
        animation: ${slideAnimation} ${SLIDE_DURATION}ms ease-in-out forwards;
        animation-direction: ${state === 'in' ? 'normal' : 'reverse'};

        border-bottom: solid 0.3rem ${state === 'in' ? theme.color.brandGreyscale600 : theme.color.brandPrimaryBase};

        &::before {
          display: none;
        }
      `;
    }
  }}
`;

const ToastIcon = styled.img`
  padding: 0.4rem;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
`;

const titleColor: Record<ToastType, keyof Theme['color']> = {
  info: 'brandPrimaryBase',
  success: 'brandBlack',
  error: 'secondaryBase',
};

const ToastTitle = styled.h3<{ type: ToastType }>`
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 2.7rem;
  color: ${({ theme, type }) => theme.color[titleColor[type]]};
  margin: 0;
  padding: 0;
`;

const ToastDescription = styled.p`
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin: 0;
  padding: 0;
`;

type ToastState = 'in' | 'progress' | 'out';

type ToastProps = {
  title: string;
  description?: string;
  type: ToastType;
  remove: () => void;
};
export const ToastComponent = ({ remove, title, description, type }: ToastProps) => {
  const [state, setState] = useState<ToastState>('in');

  // This handles animation states in -> progress -> out and then removes toast from the provider
  const wrapperRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (wrapperRef.current) {
      const listener = (event: AnimationEvent) => {
        if (slideAnimation.getName() === event.animationName && state === 'in') {
          setState('progress');
        } else if (progressAnimation.getName() === event.animationName) {
          setState('out');
          setTimeout(() => {
            remove();
          }, SLIDE_DURATION);
        }
      };

      const wrapperElement = wrapperRef.current;
      wrapperElement.addEventListener('animationend', listener);

      return () => {
        wrapperElement.removeEventListener('animationend', listener);
      };
    }
  }, [state, remove, wrapperRef]);

  return (
    <StyledToast state={state} type={type} ref={wrapperRef}>
      <ToastIcon src={toastIcons[type]} />
      <TextContainer>
        <ToastTitle type={type}>{title}</ToastTitle>
        <ToastDescription>{description}</ToastDescription>
      </TextContainer>
    </StyledToast>
  );
};
