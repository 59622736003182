export const brTag = '<br>';

export const removeTableTags = (value: string) => {
  return value.replace(/&lt;!--td.*?--&gt;/, '');
};

export const removePTag = (value: string) => {
  return value.replace(/<p>|<\/p>/gi, '');
};

export const removeBrTag = (value: string) => {
  const brTagRegExp = new RegExp(brTag, 'gi');

  return value ? value.replace(brTagRegExp, '') : '';
};

export const processSpacingForEditor = (value: string) => {
  return value.replaceAll(`${brTag}\n`, brTag).replaceAll('\n', brTag);
};

export const decodeHtmlEntities = (value: string) => {
  const ltRegExp = new RegExp('&lt;', 'g');
  const gtRegExp = new RegExp('&gt;', 'g');

  return value ? value.replace(ltRegExp, '<').replace(gtRegExp, '>') : '';
};

export const htmlEntitiesRegExp = new RegExp('&lt;|&gt;', 'g');
