import { apiClient } from '@features/api';
import { VoiceArtistsType } from '../types';

export const getVoiceArtists = async (): Promise<{ data: { voiceArtists: VoiceArtistsType[] } }> => {
  return await apiClient.noErrorsV2.get(`/voice-artists`, {
    params: {
      isActive: true, // this isActive means that archived voice artists will not be displayed
    },
  });
};
