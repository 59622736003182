import { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';

import { layoutStyles } from '@features/app';
import { isFeatureEnabled } from '@helpers/featuresHelper';

export const VocabularyReviewPage = ({ children }: { children: ReactElement }) => {
  const history = useHistory();

  const { MainWrapper } = layoutStyles;

  if (!isFeatureEnabled('vocabularyReview')) {
    history.replace('/');
  }

  return <MainWrapper fullWidth>{children}</MainWrapper>;
};
