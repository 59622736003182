import { ListItemNode, SerializedListItemNode } from '@lexical/list';
import { EditorConfig, LexicalNode, NodeKey, Spread } from 'lexical';
import { v4 as uuidv4 } from 'uuid';

import { MentionNode } from '../plugins/MentionsPlugin';

type SerializedCustomListItemNode = Spread<
  {
    id: string;
    key: string;
    type: 'custom-listitem';
  },
  SerializedListItemNode
>;
/**
 * Extends the default ListItemNode to add an id and specific methods to handle action items by ActionItemPlugin.
 * Note it's used by @lexical/lists because BaseEditor overrides the default ListItemNode with this one.
 */
export class CustomListItemNode extends ListItemNode {
  __id: string;

  static getType(): string {
    return 'custom-listitem';
  }

  static clone = (node: CustomListItemNode): CustomListItemNode => {
    return new CustomListItemNode(node.__value, node.__checked, node.__key, node.__id);
  };

  constructor(value?: number, checked?: boolean, key?: NodeKey, id?: string) {
    super(value, checked, key);
    this.__id = id ?? uuidv4();
  }

  getId(): string {
    const self = this.getLatest();

    return self.__id;
  }

  // Disable toogle checked into BaseEditor
  toggleChecked(): void {}

  extractMentions(): MentionNode[] {
    const children = this.getChildren();

    if (!children.length) return [];

    const mentions = children.filter((child: LexicalNode | null | undefined) => child instanceof MentionNode);

    if (mentions.length) {
      return mentions.map((mention: LexicalNode) => mention as MentionNode);
    }

    return [];
  }

  createDOM(config: EditorConfig): HTMLElement {
    const dom = super.createDOM(config);

    dom.classList.toggle('base-editor-listItemPlaceholder', this.isEmpty());

    return dom;
  }

  updateDOM(prevNode: ListItemNode, dom: HTMLElement, config: EditorConfig): boolean {
    const updated = super.updateDOM(prevNode, dom, config);

    dom.classList.toggle('base-editor-listItemPlaceholder', this.isEmpty());

    return updated;
  }

  static importJSON(serializedNode: SerializedCustomListItemNode): CustomListItemNode {
    const node = $createCustomListItemNode(serializedNode.value, serializedNode.checked, serializedNode.key);
    return node;
  }

  // @ts-ignore
  exportJSON(): SerializedCustomListItemNode {
    return {
      ...super.exportJSON(),
      id: this.getId(),
      checked: this.getChecked(),
      key: this.getKey(),
      type: 'custom-listitem',
    };
  }
}

export const $createCustomListItemNode = (
  value?: number,
  checked?: boolean,
  key?: NodeKey,
  id?: string,
): CustomListItemNode => {
  return new CustomListItemNode(value, checked, key, id);
};

export const $isCustomListItemNode = (node: LexicalNode | null | undefined): node is CustomListItemNode => {
  return node instanceof CustomListItemNode;
};
