import { useAppDispatch, useAppSelector } from '@redux/store';
import classnames from 'classnames';
import { random } from 'lodash';
import React, { Fragment, useEffect, useRef, useState } from 'react';

import { ContentTypes } from '@common/enums/ContentTypes';
import { TranslationsPanelContentInterface } from '@common/interfaces/exercises/TranslationsPanelContentInterface';
import TranslationsTip from '@components/TranslationTip/TranslationTip';
import { TranslationTipActionsCreator } from '@actionCreators/TranslationTipActionsCreator';
import { TranslationsPanelModes } from '@components/TranslationsPanel/enums/TranslationsPanelModes';
import { constants as contentConstants, formatCounterOfFilledLocalizations } from '@features/content';
import { useIsEditAvailable } from '@features/content/courses';
import { Language } from '@features/content/languages';
import { removeTableTags } from '@helpers/htmlTagsHelper';

import { BidimensionalEditorModes } from './enums/BidimensionalEditorModes';
import plusIcon from './img/plus.svg';
import tipIcon from './img/tip.svg';
import BidimensionalEditorProps from './BidimensionalEditorProps';
import BidimensionalEditorUtils from './BidimensionalEditorUtils';
import EditorContainer from './EditorContainer';

import 'draft-js/dist/Draft.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {
  selectInterfaceLanguages,
  selectLearningLanguage,
  selectLoadedExerciseContent,
  selectSelectedGroupsOfParentParentContents,
} from '@selectors/CoursesSelectors';

const BIDIMENSIONAL_EDITOR_MAX_COLUMNS = 3;
const TOOLBAR_DEFAULT_OPTIONS = {
  options: ['inline', 'emoji'],
  inline: {
    options: ['bold', 'italic', 'underline', 'strikethrough'],
  },
};

const BidimensionalEditor = ({
  bundleName,
  charactersLimit = 0,
  dataSourceMode = 'editorState',
  errorsShown = false,
  fullWidth = false,
  highlightedMatrix = [],
  ignoreLanguageForTips,
  isChangeBlocked = false,
  isLesson = false,
  language,
  localizationId,
  maxColums = BIDIMENSIONAL_EDITOR_MAX_COLUMNS,
  mode,
  placeholder,
  singleLine = false,
  storeBranch,
  storeColumn,
  storeRow,
  toolbar,
  toolbarCustomButtons,
  onChange,
  onChangeInstant,
}: BidimensionalEditorProps) => {
  const dispatch = useAppDispatch();
  const { isEditAvailable } = useIsEditAvailable();
  if (maxColums > BIDIMENSIONAL_EDITOR_MAX_COLUMNS)
    throw new RangeError(`BidimensionalEditor is limited to ${BIDIMENSIONAL_EDITOR_MAX_COLUMNS} colums max`);

  const editorRef = useRef<HTMLDivElement>();

  const courseMainLanguage = useAppSelector(selectLearningLanguage);
  const interfaceLanguages = useAppSelector(selectInterfaceLanguages);

  const loadedExerciseContent = useAppSelector(selectLoadedExerciseContent);
  const loadedContent = useAppSelector(selectSelectedGroupsOfParentParentContents);

  let translationsPanelContent: TranslationsPanelContentInterface | TranslationsPanelContentInterface[][] = bundleName
    ? loadedExerciseContent[bundleName][storeBranch]
    : isLesson
      ? loadedContent?.[storeBranch as keyof typeof loadedContent]
      : loadedExerciseContent[storeBranch];

  let [dataSourceModeForEditor, setDataSourceModeForEditor] = useState(dataSourceMode);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [oneCellHasBeenFocused, setOneCellHasBeenFocused] = useState(false); // this state is used to force the re-render when typing in a cell
  const [selectedToolbar, setSelectedToolbar] = useState('0_0');
  const [currentRow, setCurrentRow] = useState(0);
  const [currentColumn, setCurrentColumn] = useState(0);
  const [selectedRow, setSelectedRow] = useState<number | null>(null);
  const [selectedColumn, setSelectedColumn] = useState<number | null>(null);
  const [columns, setColumns] = useState(() => {
    if (mode === BidimensionalEditorModes.mono) return 1;
    else {
      translationsPanelContent = translationsPanelContent as TranslationsPanelContentInterface[][];

      if (translationsPanelContent === undefined || translationsPanelContent === null) return 1;

      if (translationsPanelContent[0].length === 0) return 1;
      else return translationsPanelContent[0].length;
    }
  });

  const [rows, setRows] = useState(
    mode === BidimensionalEditorModes.multi && translationsPanelContent
      ? (translationsPanelContent as TranslationsPanelContentInterface[][]).length
      : 1,
  );

  const [cells, setCells] = useState<ReturnType<typeof BidimensionalEditorUtils.generateInitialCellsState>>(() => {
    let initialCellsState = BidimensionalEditorUtils.generateInitialCellsState(
      mode,
      translationsPanelContent,
      courseMainLanguage,
      storeRow as number,
      storeColumn as number,
    );

    return initialCellsState;
  });

  let [languageForTips, setLanguageForTips] = useState(() => {
    const languageForTips: Language[][] = BidimensionalEditorUtils.mirrorCellsForLanguageTip(
      cells,
      [],
      courseMainLanguage,
    );

    return languageForTips;
  });

  const [displayedValueInChildren, setDisplayedValueInChildren] = useState('');

  let [translationsPanelContentInterfaceOfCurrentCell, setTranslationsPanelContentInterfaceOfCurrentCell] =
    useState<TranslationsPanelContentInterface | null>(() => null);

  const setEditorToIdleState = () => {
    setOneCellHasBeenFocused(false);
    setSelectedToolbar('0_0');
  };

  const addColumn = (insertAt?: number) =>
    BidimensionalEditorUtils.addColumn(
      dispatch,
      columns,
      maxColums,
      setColumns,
      setEditorToIdleState,
      storeBranch,
      insertAt,
    );

  const addRow = (insertAt?: number) =>
    BidimensionalEditorUtils.addRow(dispatch, setRows, rows, setEditorToIdleState, storeBranch, insertAt);

  const selectRow = (row: number | null) => {
    setSelectedColumn(null);
    setSelectedRow(row);
  };

  const selectColumn = (column: number | null) => {
    setSelectedRow(null);
    setSelectedColumn(column);
  };

  const removeColumn = (columnToRemove: number) => {
    BidimensionalEditorUtils.removeColumn(
      dispatch,
      cells,
      columnToRemove,
      setColumns,
      setCells,
      setEditorToIdleState,
      storeBranch,
    );
    setCurrentColumn(0);
  };

  const removeRow = (rowToRemove: number) => {
    BidimensionalEditorUtils.removeRow(
      dispatch,
      cells,
      rows,
      rowToRemove,
      setRows,
      setCells,
      setEditorToIdleState,
      storeBranch,
    );
    setCurrentRow(0);
  };

  const onEditorFocus = (row: number, column: number) => {
    BidimensionalEditorUtils.onEditorFocus(
      setDataSourceModeForEditor,
      setDisplayedValueInChildren,
      languageForTips,
      setSelectedToolbar,
      setCurrentRow,
      setCurrentColumn,
      mode,
      setTranslationsPanelContentInterfaceOfCurrentCell,
      translationsPanelContent,
      row,
      column,
    );
  };

  useEffect(() => {
    BidimensionalEditorUtils.updateCellsOnRowOrColumnChange(cells, setCells, rows, columns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows, columns]);

  useEffect(() => {
    setLanguageForTips(BidimensionalEditorUtils.mirrorCellsForLanguageTip(cells, languageForTips, courseMainLanguage));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cells]);

  const toolbarOptions = toolbar || TOOLBAR_DEFAULT_OPTIONS;

  let intervalId = 0;

  let otherPlacesCount =
    mode === BidimensionalEditorModes.multi
      ? (translationsPanelContent as TranslationsPanelContentInterface[][])[currentRow][currentColumn]?.mappings
          ?.count ||
        (translationsPanelContent as TranslationsPanelContentInterface[][])[currentRow][currentColumn]?.mappings
          ?.length ||
        0
      : 0;

  let isChangeBlockedNew =
    !isEditAvailable ||
    (mode === BidimensionalEditorModes.multi
      ? !otherPlacesCount || otherPlacesCount === 1
        ? false
        : !(translationsPanelContent as TranslationsPanelContentInterface[][])[currentRow][currentColumn]
            .isReusingConfirmed
      : isChangeBlocked);

  const _onChange = (text: string, languageForTips: string | undefined) => {
    const cleanText = removeTableTags(text.replace(/(&nbsp;)+ /g, ' ').replace(/ (&nbsp;)+/g, ' '));

    setDisplayedValueInChildren(cleanText);
    onChange && onChange(cleanText, languageForTips);
  };

  const renderBidimensionalEditor = () => (
    <div
      className={classnames(
        'bidimensional-editor',
        { 'bidimensional-editor--one-element': mode === BidimensionalEditorModes.mono },
        { 'bidimensional-editor--multiple-elements': mode !== BidimensionalEditorModes.mono },
        { 'bidimensional-editor--fullWidth': fullWidth },
        { 'bidimensional-editor--single-line': singleLine },
        { 'bidimensional-editor--one-element--with-error': errorsShown },
      )}
    >
      {isEditAvailable &&
        maxColums > 1 &&
        columns < maxColums &&
        [...Array(columns + 1)].map((_: unknown, columnIndex: number) => {
          let offsets: number[] = [];
          if (columns === 1) {
            offsets = [99, 0];
          } else if (columns === 2) {
            offsets = [99, 50, 0];
          }
          let tipStyle = { '--right': `calc(${offsets[columnIndex]}% - 3px)` } as React.CSSProperties;
          let plusStyle = { '--right': `calc(${offsets[columnIndex]}% - 12px)` } as React.CSSProperties;

          return (
            <Fragment key={random(0, 10000)}>
              <img
                src={tipIcon}
                className="bidimensional-editor__add-column-tip"
                style={tipStyle}
                alt="add column tip"
              />
              <img
                src={plusIcon}
                className="bidimensional-editor__add-column"
                style={plusStyle}
                alt="add column"
                onClick={() => {
                  addColumn(columnIndex);
                }}
              />
            </Fragment>
          );
        })}
      {isEditAvailable &&
        mode === BidimensionalEditorModes.multi &&
        [...Array(rows + 1)].map((_: unknown, rowIndex: number) => {
          let vertOffset;
          if (rowIndex === rows) {
            vertOffset = -4;
          } else if (rowIndex === 0) {
            vertOffset = rows * 42 - 4;
          } else {
            vertOffset = (rows - rowIndex) * 42 - 1;
          }
          let tipStyle = { '--bottom': vertOffset + 'px' } as React.CSSProperties;
          let plusStyle = { '--bottom': vertOffset - 12 + 'px' } as React.CSSProperties;

          return (
            <Fragment key={random(0, 10000)}>
              <img src={tipIcon} className="bidimensional-editor__add-row-tip" style={tipStyle} alt="add row tip" />
              <img
                src={plusIcon}
                alt="add row"
                className="bidimensional-editor__add-row"
                style={plusStyle}
                onClick={() => {
                  addRow(rowIndex);
                }}
              />
            </Fragment>
          );
        })}
      <div
        className={classnames(
          'bidimensional-editor__box-table-wrapper',
          `bidimensional-editor__box-table-wrapper--${columns - 1}`,
          { 'bidimensional-editor__box-table-wrapper--bidimensional-enabled': mode === BidimensionalEditorModes.multi },
        )}
      >
        {cells.map((_: unknown, rowIndex: number) => {
          return cells[rowIndex].map((_: unknown, columnIndex: number) => {
            return (
              <EditorContainer
                bundleName={bundleName}
                cells={cells}
                charactersLimit={charactersLimit}
                columns={columns}
                columnIndex={columnIndex}
                dataSourceModeForEditor={dataSourceModeForEditor}
                editorRef={editorRef}
                highlighted={highlightedMatrix[rowIndex]?.[columnIndex]?.highlighted}
                ignoreLanguageForTips={ignoreLanguageForTips}
                intervalId={intervalId}
                isChangeBlocked={isChangeBlockedNew}
                key={`cell-editor-${rowIndex}-${columnIndex}`}
                language={language}
                languageForTips={languageForTips}
                localizationId={localizationId}
                mode={mode}
                placeholder={placeholder}
                rows={rows}
                rowIndex={rowIndex}
                selectedColumn={selectedColumn}
                selectedRow={selectedRow}
                selectedToolbar={selectedToolbar}
                storeBranch={storeBranch}
                storeColumn={storeColumn}
                storeRow={storeRow}
                translationsPanelContent={translationsPanelContent}
                toolbarOptions={toolbarOptions}
                toolbarCustomButtons={toolbarCustomButtons}
                onChange={_onChange}
                onChangeInstant={
                  onChangeInstant
                    ? onChangeInstant
                    : () => {
                        if (otherPlacesCount > 1 && isChangeBlockedNew) {
                          dispatch(
                            TranslationTipActionsCreator.setCurrentContentId(
                              translationsPanelContentInterfaceOfCurrentCell?._id || '',
                              ContentTypes.exercise,
                              storeBranch,
                              currentRow,
                              currentColumn,
                              bundleName,
                            ),
                          );
                        }
                      }
                }
                onEditorFocus={onEditorFocus}
                removeColumn={removeColumn}
                removeRow={removeRow}
                setCells={setCells}
                setDataSourceModeForEditor={setDataSourceModeForEditor}
                setOneCellHasBeenFocused={setOneCellHasBeenFocused}
                selectColumn={selectColumn}
                selectRow={selectRow}
              />
            );
          });
        })}
      </div>
    </div>
  );

  return (
    <>
      {mode === BidimensionalEditorModes.multi ? (
        <TranslationsTip
          localizationIDForLangExperts={
            translationsPanelContentInterfaceOfCurrentCell?._id === undefined
              ? 'N/A'
              : translationsPanelContentInterfaceOfCurrentCell?._id
          }
          translationElements={formatCounterOfFilledLocalizations(
            (translationsPanelContent as TranslationsPanelContentInterface[][])[currentRow][currentColumn]
              .textLocalizations,
            interfaceLanguages as Language[],
            courseMainLanguage as Language,
          )}
          audioElements={formatCounterOfFilledLocalizations(
            (translationsPanelContent as TranslationsPanelContentInterface[][])[currentRow][currentColumn]
              .audioLocalizations,
            interfaceLanguages as Language[],
            courseMainLanguage as Language,
          )}
          visitedContentId={
            (translationsPanelContent as TranslationsPanelContentInterface[][])[currentRow][currentColumn]._id
          }
          visitedBranch={storeBranch}
          bundleName={bundleName}
          row={currentRow}
          column={currentColumn}
          translationsPanelTranslationsMode={TranslationsPanelModes.wysiwyg}
          externallyDrivenLanguageString={languageForTips[currentRow][currentColumn]}
          onLanguageSwitchToggled={() => {
            let clonedLanguageForTips = [...languageForTips];

            if (clonedLanguageForTips[currentRow][currentColumn] === courseMainLanguage) {
              clonedLanguageForTips[currentRow][currentColumn] = contentConstants.DEFAULT_LANGUAGE;
            } else {
              clonedLanguageForTips[currentRow][currentColumn] = courseMainLanguage;
            }

            setLanguageForTips(clonedLanguageForTips);
          }}
          alwaysVisible
          showLanguageSwitcher={false}
          hideRemove={true}
          isStringChangeBlocked={isChangeBlockedNew}
          isBundleChangeBlocked={false}
          suggestionsQuery={displayedValueInChildren}
        >
          {renderBidimensionalEditor()}
        </TranslationsTip>
      ) : (
        <>{renderBidimensionalEditor()}</>
      )}
    </>
  );
};

export default BidimensionalEditor;
