import styled from 'styled-components';

import { useIsEditAvailable } from '@features/content/courses';
import { DragAndDropType } from '@components/DraggableList/DragAndDropType';
import dragHandler from '@static/svg/drag-handler.svg';

const DragImage = styled.img<{ isDragging: boolean; visible: boolean }>`
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  ${({ isDragging }) =>
    isDragging &&
    `
      visibility: visible !important;
      width: 2.4rem !important;
      top: 0px !important;
      left: 0px !important;
      transform: none !important;
      position: relative !important;
  `}
`;

type CardDragHandlerProps = {
  visible: boolean;
  dndProps?: DragAndDropType;
  className?: string;
};

export const CardDragHandler = ({ visible, dndProps, className }: CardDragHandlerProps) => {
  const { isEditAvailable } = useIsEditAvailable();

  if (isEditAvailable) {
    return (
      <DragImage
        src={dragHandler}
        alt="drag and drop"
        isDragging={!!dndProps?.snapshot?.isDragging}
        className={className}
        visible={visible}
        {...dndProps?.provided?.draggableProps}
        {...dndProps?.provided?.dragHandleProps}
      />
    );
  }
  return null;
};
