import { v4 as uuidv4 } from 'uuid';

import styled, { css } from 'styled-components/macro';

const CheckboxWrapper = styled.div<{ $disabled: boolean }>`
  align-items: center;
  display: flex;
  font-size: 1.4rem;

  ${({ $disabled }) =>
    $disabled &&
    css`
      opacity: 0.7;
    `}
`;

const CheckboxLabel = styled.label`
  line-height: 1.4rem;
  margin: 0;

  &:hover {
    cursor: pointer;
  }
`;

type CheckboxProps = {
  className?: string;
  checked: boolean;
  disabled?: boolean;
  id?: string;
  label?: string;
  title?: string;
  onToggle: (newCheckedValue: boolean) => void;
};

export const Checkbox = ({
  className,
  checked,
  disabled = false,
  id = uuidv4(),
  label,
  title,
  onToggle,
}: CheckboxProps) => (
  <CheckboxWrapper $disabled={disabled} className={className}>
    <input
      checked={checked}
      disabled={disabled}
      id={id}
      title={title}
      type="checkbox"
      onChange={() => onToggle(!checked)}
    />
    {label && <CheckboxLabel htmlFor={id}>{label}</CheckboxLabel>}
  </CheckboxWrapper>
);
