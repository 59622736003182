import styled from 'styled-components/macro';

import { useSearch } from '../../../../SearchContext';
import { ReactComponent as DefaultSearchImage } from './img/searchEmpty.svg';
import { ReactComponent as SearchErrorImage } from './img/searchError.svg';
import { ReactComponent as SearchNotFoundImage } from './img/searchNotFound.svg';

const SearchFeedbackWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  text-align: center;
  width: 100%;
`;

const SearchFeedbackTitle = styled.div`
  font-size: 2.4rem;
  font-weight: 700;
  margin-bottom: 0.8rem;
`;

const SearchFeedbackText = styled.div`
  color: ${({ theme }) => theme.color.brandGreyscale800};
  font-size: 1.6rem;
`;

export const SearchFeedback = ({ notFound }: { notFound: boolean }) => {
  const { searchErrorMessage } = useSearch();

  if (searchErrorMessage) {
    return (
      <SearchFeedbackWrapper>
        <SearchErrorImage />
        <SearchFeedbackTitle>An error occured</SearchFeedbackTitle>
        <SearchFeedbackText>{searchErrorMessage}</SearchFeedbackText>
      </SearchFeedbackWrapper>
    );
  }

  if (notFound) {
    return (
      <SearchFeedbackWrapper>
        <SearchNotFoundImage />
        <SearchFeedbackTitle>No results found</SearchFeedbackTitle>
        <SearchFeedbackText>Please check your search criteria and try again</SearchFeedbackText>
      </SearchFeedbackWrapper>
    );
  }

  return (
    <SearchFeedbackWrapper>
      <DefaultSearchImage />
      <SearchFeedbackTitle>Search for content easily</SearchFeedbackTitle>
    </SearchFeedbackWrapper>
  );
};
