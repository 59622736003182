import { SidePanelActions } from '@actions/SidePanelActions';

// @TODO Replace global sidebar state in Redux with local in useState or using useModal hook
export const SidePanelActionsCreator = {
  /**
   * @description This action hides ALL open panels connected to Redux
   */
  hideSidePanel: () => ({
    type: SidePanelActions.HIDE_SIDE_PANEL,
  }),
};
