import { useEffect } from 'react';
import { Modal } from 'react-bootstrap';

import { PreviewModalForLiveLessonContent } from './PreviewModalForLiveLessonContent';
import { ModalContainer } from '../common/preview.styles';
import { PREVIEW_DEVICE_TYPE } from '../constants';
import { usePreviewState } from '../usePreviewState';
import { useAppSelector } from '@redux/store';
import { selectIsPreviewOpened } from '@selectors/UiSelectors';

export const LiveLessonPreviewModal = () => {
  const { deviceType, setDeviceType, answerState, setAnswerState, onModalClose } = usePreviewState();

  const isPreviewOpened = useAppSelector(selectIsPreviewOpened);

  useEffect(() => {
    setDeviceType(PREVIEW_DEVICE_TYPE.DESKTOP);
  }, [isPreviewOpened, setDeviceType]);

  return (
    <ModalContainer show={isPreviewOpened} centered onHide={onModalClose}>
      <Modal.Body>
        {isPreviewOpened && (
          <PreviewModalForLiveLessonContent
            onModalClose={onModalClose}
            deviceType={deviceType}
            setDeviceType={setDeviceType}
            answerState={answerState}
            setAnswerState={setAnswerState}
          />
        )}
      </Modal.Body>
    </ModalContainer>
  );
};
