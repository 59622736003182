import { Modal } from 'react-bootstrap';

import { searchById } from '../../service';
import { useSearch } from '../../../SearchContext';
import { ReactComponent as IdIcon } from '../../img/id.svg';
import { IdSearchResultItem, type SearchResultType } from '../../SearchResultItem';
import { SearchResults } from '../../SearchResults';
import { SearchHeader, SearchResultsWrapper, useResultsHandler } from '../Common';
import { IdSearchResultTypeHeader } from '../../SearchResultTypeHeader';
import type { CommonSearchComponentProps } from '../SearchContent';

/**
 * This component is responsible for:
 * - Modal layout for Id search
 * - All logic that is specific to Id search
 * - Calling Id search API
 * - Storing search filters (in case of other search types)
 * - Storing search results
 */
export const IdSearch = ({ trackSearch, onBack }: CommonSearchComponentProps) => {
  const { search } = useSearch();
  const { results, count, clearResults, onSuccess, onError } = useResultsHandler<SearchResultType>();

  const onSearch = async (query?: string | null) => {
    if (query) {
      search(() => searchById(query))
        .then(onSuccess)
        .catch(onError);
      trackSearch();
    } else {
      clearResults();
    }
  };

  return (
    <>
      <Modal.Header closeButton>
        <SearchHeader icon={<IdIcon />} title="ID-based search" onBack={onBack} onSearch={onSearch} />
      </Modal.Header>

      <Modal.Body>
        <SearchResultsWrapper onPageCount={results.length}>
          <SearchResults<SearchResultType>
            results={results}
            count={count}
            header={<IdSearchResultTypeHeader results={results} />}
            renderItem={(item) => <IdSearchResultItem content={item} key={item.id} />}
          />
        </SearchResultsWrapper>
      </Modal.Body>
    </>
  );
};
