import { takeLatest } from 'redux-saga/effects';

import { TrueFalseExerciseActions } from '@actions/TrueFalseExerciseActions';
import ExerciseSagas from '../definitions/ExerciseSagas';
import MediaUploadSagas from '@sagas/mediaUpload/definitions/MediaUploadSagasDefinition';
import TrueFalseExerciseSagas from '@sagas/exercises/definitions/TrueFalseExerciseSagasDefinition';
import type { AppDispatch } from '@redux/store';

export function* TrueFalseExerciseRelatedSagas(dispatch: AppDispatch) {
  yield takeLatest(TrueFalseExerciseActions.SAVE, TrueFalseExerciseSagas.save, dispatch);
  yield takeLatest(TrueFalseExerciseActions.UPLOAD_IMAGE, MediaUploadSagas.uploadImageToExercise);
  yield takeLatest(TrueFalseExerciseActions.REMOVE_AUDIO, ExerciseSagas.removeAudioFromExercise);
}
