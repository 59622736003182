import { createSlice, ActionCreator } from '@reduxjs/toolkit';
import { placementTestInitialState } from '@redux/initialStates/placementTestInitialState';
import { PlacementTestReducers } from '@redux/reducers/placementTestSlice/PlacementTestReducers';

const extraReducers = {
  ...PlacementTestReducers,
} as Record<string, ActionCreator<any>>;

export const placementTestSlice = createSlice({
  name: 'placementTest',
  initialState: placementTestInitialState,
  reducers: {},

  extraReducers: (builder) => {
    Object.keys(extraReducers).forEach((actionType) => {
      builder.addCase(actionType, extraReducers[actionType]);
    });
  },
});
