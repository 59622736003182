import { useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { DBId } from '@common/types/DBId';

import { Modal, useDialogModal } from '@features/modal';

import type { VocabularyReviewListItem } from '../../types';
import { LexicalItemRow } from './LexicalItemRow';
import { MatchedExercisesModal } from './MatchedExercisesModal';
import { StyledTable, TableHeader, TableRow } from './styles';

type LexicalItemsTableProps = {
  lexicalItems: VocabularyReviewListItem[];
  onLexicalItemClick: (lexicalItemId: DBId) => void;
};

export const LexicalItemsTable = ({ lexicalItems, onLexicalItemClick }: LexicalItemsTableProps) => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const showId = !!searchParams.get('showId');

  const selectedLexicalId = useRef<DBId | null>(null);

  const { open: openMatchedExercisesModal, modal: matchedExercisesModal } = useDialogModal((modalControls) => (
    <Modal lockScroll size="M" {...modalControls}>
      <MatchedExercisesModal
        isOpen={modalControls.isOpen}
        lexicalItemId={selectedLexicalId.current}
        close={() => {
          modalControls.close();
        }}
      />
    </Modal>
  ));

  return (
    <>
      <StyledTable>
        <thead>
          <TableRow>
            <TableHeader data-testid="column-header">Phrase</TableHeader>
            <TableHeader data-testid="column-header">Translations</TableHeader>
            <TableHeader data-testid="column-header">Audio</TableHeader>
            <TableHeader data-testid="column-header">Example</TableHeader>
            <TableHeader data-testid="column-header">Part of speech</TableHeader>
            <TableHeader data-testid="column-header">CEFR</TableHeader>
            <TableHeader data-testid="column-header">Media</TableHeader>
            <TableHeader align="right" data-testid="column-header">
              Exercise matched
            </TableHeader>
          </TableRow>
        </thead>
        <tbody>
          {lexicalItems.map((lexicalItem) => (
            <LexicalItemRow
              key={lexicalItem.id}
              lexicalItem={lexicalItem}
              showId={showId}
              onClick={onLexicalItemClick}
              openMatchedExercisesModal={() => {
                selectedLexicalId.current = lexicalItem.id;
                openMatchedExercisesModal();
              }}
            />
          ))}
        </tbody>
      </StyledTable>
      {matchedExercisesModal}
    </>
  );
};
