import { ContentTypes, type ContentTypesType } from '@common/enums/ContentTypes';
import { ContentCreatorWidget, type ContentCreatorWidgetItemType } from '@features/theme';
import chapterIcon from '@static/svg/contentCreatorWidget/chapter.svg';

const CHAPTER_CREATOR_ITEM: ContentCreatorWidgetItemType<typeof ContentTypes.chapter> = {
  label: 'Chapter',
  iconSrc: chapterIcon,
  showByDefault: true,
  type: ContentTypes.chapter,
};

type ChapterCreatorProps = {
  isCreateInProgress?: boolean;
  isEditAvailable: boolean;
  isPreEnabled: boolean;
  position: number;
  onCreateContent: (itemType: ContentTypesType, itemCategory: null) => void;
};

export const ChapterCreator = ({
  isCreateInProgress = false,
  isEditAvailable,
  isPreEnabled,
  position,
  onCreateContent,
}: ChapterCreatorProps) => (
  <ContentCreatorWidget
    isCreateInProgress={isCreateInProgress}
    isPreEnabled={isPreEnabled}
    isEditAvailable={isEditAvailable}
    items={[CHAPTER_CREATOR_ITEM]}
    position={position}
    title="Create a first chapter to get started"
    onItemSelected={(item) => onCreateContent(item.type, null)}
  />
);
