import { createSlice, ActionCreator } from '@reduxjs/toolkit';

import { bulkPublishingInitialContent } from '@redux/initialStates/bulkPublishingInitialState';
import { BulkPublishingReducers } from '@redux/reducers/bulkPublishingSlice/BulkPublishingReducers';

const extraReducers = {
  ...BulkPublishingReducers,
} as Record<string, ActionCreator<any>>;

export const bulkPublishingSlice = createSlice({
  name: 'bulkPublishing',
  initialState: bulkPublishingInitialContent,
  reducers: {},

  extraReducers: (builder) => {
    Object.keys(extraReducers).forEach((actionType) => {
      builder.addCase(actionType, extraReducers[actionType]);
    });
  },
});
