import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components/macro';

import { useUser } from '@features/auth';
import { ActionItemType, Pagination } from '@features/theme';

import type { CommentType } from '../../types';
import { CommentEditor } from '../CommentEditor';
import { CommentItem, ReactionNameType } from './CommentItem';

export const NoCommentsMessage = styled.span`
  display: flex;
  width: 100%;
  justify-content: center;
  padding-top: 20px;
  font-size: 14px;
  font-weight: bold;
`;

type CommentsListProps = {
  comments: CommentType[];
  pageSize?: number;
  onActionItemUpdate: (commentId: string, actionItemId: string, state: boolean) => void;
  onAddReaction: (commentId: string, reactionName: ReactionNameType) => void;
  onCommentUpdate: (commentId: string, message: string, mentions: string[], actionItems: ActionItemType[]) => void;
  onCommentDelete: (commentId: string) => void;
  onRemoveReaction: (commentId: string, reactionName: ReactionNameType) => void;
};

const DEFAULT_PAGE_SIZE = 10;

export const CommentsList = ({
  comments,
  pageSize = DEFAULT_PAGE_SIZE,
  onActionItemUpdate,
  onAddReaction,
  onCommentDelete,
  onCommentUpdate,
  onRemoveReaction,
}: CommentsListProps) => {
  const [currentCommentToEdit, setCurrentCommentToEdit] = useState<CommentType | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [isCurrentCommentEditMode, setIsCurrentCommentEditMode] = useState(false);
  const [isCurrentCommentBusy, setIsCurrentCommentBusy] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [visibleComments, setVisibleComments] = useState<CommentType[]>([]);

  const { user } = useUser();

  const isAuthor = (comment: CommentType) => user?.id === comment.author.id;

  useEffect(() => {
    if (comments.length) {
      const index = (currentPage - 1) * pageSize;
      setVisibleComments(comments.slice(index, index + pageSize));
      setTotalPages(Math.ceil(comments.length / pageSize));
    } else {
      setVisibleComments([]);
      setTotalPages(0);
    }
  }, [currentPage, comments, pageSize]);

  useEffect(() => {
    if (totalPages) {
      setHasNextPage(currentPage <= totalPages);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalPages]);

  return (
    <>
      {visibleComments.length ? (
        <>
          {visibleComments.map((comment) => (
            <CommentItem
              content={comment}
              editable={isAuthor(comment)}
              id={`comment-item-${comment.id}`}
              isEditMode={comment.id === currentCommentToEdit?.id && isCurrentCommentEditMode}
              key={comment.id}
              user={user}
              setIsEditMode={setIsCurrentCommentEditMode}
              onActionItemUpdate={onActionItemUpdate}
              onAddReaction={onAddReaction}
              onCommentDelete={onCommentDelete}
              onEdit={setCurrentCommentToEdit}
              onRemoveReaction={onRemoveReaction}
            />
          ))}
          {currentCommentToEdit &&
            createPortal(
              <CommentEditor
                busy={isCurrentCommentBusy}
                content={currentCommentToEdit.message}
                id={`edit-comment-${currentCommentToEdit.id}`}
                onCancelComment={() => {
                  setIsCurrentCommentEditMode(false);
                  setCurrentCommentToEdit(null);
                }}
                onSaveComment={(message: string, mentions, actionItems) => {
                  setIsCurrentCommentBusy(true);
                  onCommentUpdate(currentCommentToEdit.id, message, mentions, actionItems);
                  setIsCurrentCommentBusy(false);
                  setIsCurrentCommentEditMode(false);
                  setCurrentCommentToEdit(null);
                }}
              />,
              document.getElementById(`comment-item-${currentCommentToEdit.id}`) as HTMLDivElement,
            )}
        </>
      ) : (
        <NoCommentsMessage>No comments yet</NoCommentsMessage>
      )}
      {totalPages > 1 && (
        <Pagination currentPage={currentPage} hasNextPage={hasNextPage} onPageChange={setCurrentPage} />
      )}
    </>
  );
};
