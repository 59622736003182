import { Feature } from '@features/index';

const config: Partial<{ [key in Feature]: unknown }> = {};

export const registerConfig = <T = unknown>(feature: Feature, slice: T) => {
  config[feature] = slice;
};

export const getConfig = <T>(feature: Feature): T => {
  const featureConfig = config[feature];

  if (!featureConfig) {
    throw new Error(`"${feature}" feature config not registered`);
  }

  return featureConfig as T;
};
