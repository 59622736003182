import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as NavigateImage } from '@components/ComponentOtherPlacesDisplayer/OtherPlacesDisplayer/img/navigate.svg';
import { LinkedChapterModal } from './LinkedChapterModal';
import { ChapterForEntrypoint } from './types';
import { ReactComponent as ConnectionIcon } from './img/connection.svg';
import { PlacementTestService } from '@services/PlacementTestService';
import { useParams } from 'react-router-dom';
import { DBId } from '@common/types/DBId';
import { useAppSelector } from '@redux/store';
import { selectPlacementTestEntrypointContent } from '@selectors/PlacementTestSelectors';

const LinkedLessonsModalOpenButton = styled.div`
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.colorV2.placementTestLinkedChapterModaButtonBackground};
  width: fit-content;
  height: 2.2rem;
  margin: 0 0 0.8rem 0.4rem;
  font-weight: 700;
  font-size: 1rem;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colorV2.placementTestLinkedChapterModaButtonText};
  padding: 0.4rem 0.8rem;
  border-radius: 1.6rem;

  &:hover {
    cursor: pointer;
    opacity: 0.9;
  }
`;

const ConnectionImage = styled(ConnectionIcon)`
  width: 1.2rem;
`;

const ButtonText = styled.span`
  margin: 0 0.5rem;
`;

export const LinkedChapterButton = () => {
  const { courseId, entrypointId } = useParams<{ courseId: DBId; entrypointId: DBId }>();
  const [opened, setOpened] = useState(false);
  const entrypointContent = useAppSelector(selectPlacementTestEntrypointContent);
  const [availableChapters, setAvailableChapters] = useState<ChapterForEntrypoint[]>([]);
  const [isChaptersLoaded, setIsChaptersLoaded] = useState(false);

  useEffect(() => {
    if (!isChaptersLoaded && opened) {
      PlacementTestService.getChapters(courseId, entrypointId).then((result) => {
        setIsChaptersLoaded(true);
        setAvailableChapters(result.data.chapters);
      });
    }
  }, [isChaptersLoaded, courseId, entrypointId, opened]);

  return (
    <>
      <LinkedLessonsModalOpenButton data-testid="linked-chapter-button" onClick={() => setOpened(true)}>
        <ConnectionImage />
        <ButtonText>{entrypointContent.chapter?.title || 'Link chapter'}</ButtonText>
        <NavigateImage />
      </LinkedLessonsModalOpenButton>
      <LinkedChapterModal
        opened={opened}
        onClose={() => setOpened(false)}
        selectedChapter={entrypointContent.chapter}
        isChaptersLoaded={isChaptersLoaded}
        entrypointContent={entrypointContent}
        availableChapters={availableChapters}
      />
    </>
  );
};
