import { SectionActions } from '@actions/SectionActions';
import produce from 'immer';
import { ExerciseTypes } from '@common/enums/ExerciseTypes';
import { LoadingStage } from '@common/enums/LoadingStage';
import { LoadedComprehensionExerciseInterface } from '@common/interfaces/exercises/LoadedExerciseInterface';
import { CourseSliceInterface } from '@common/interfaces/slices/CourseSliceInterface';
import ExerciseDataModelAssembler from '@components/Exercises/ExerciseDataModelAssembler';
import { updateTextField } from '@helpers/updateTextField';
import { ComprehensionExerciseActions } from '@actions/ComprehensionExerciseActions';
import ComprehensionExerciseInterface from '@components/Exercises/Comprehension/interfaces/ComprehensionExerciseInterface';
import { ComprehensionOptions } from '@components/Exercises/Comprehension/types/ComprehensionOptions';
import { UploadMediaResponse } from '@services/HelpersService';
import { selectedGroupsOfParentInitialData } from '@redux/initialStates/courseInitialState';
import { PayloadAction } from '@reduxjs/toolkit';
import { clone } from '@helpers/clone';

const ComprehensionExerciseCourseReducers = {
  [SectionActions.COURSE_EXERCISE_COMPREHENSION_REQUEST_SUCCEEDED]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    let clonedCourseSlice: CourseSliceInterface = clone(state);
    let { content }: ComprehensionExerciseInterface = payload;

    let exercise: ComprehensionExerciseInterface = {
      ...clonedCourseSlice.loadedExercise.exercise,
      content,
      type: ExerciseTypes.comprehension,
    };

    return {
      ...clonedCourseSlice,
      selectedGroupsOfParent: selectedGroupsOfParentInitialData,
      loadedExercise: {
        loaded: LoadingStage.loaded,
        exercise,
      },
    };
  },
  [ComprehensionExerciseActions.SET_COMPREHENSION_OPTION]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<ComprehensionOptions>,
  ): CourseSliceInterface => {
    let clonedState: CourseSliceInterface = clone(state);

    let loadedExercise: LoadedComprehensionExerciseInterface =
      clonedState?.loadedExercise as LoadedComprehensionExerciseInterface;

    loadedExercise.exercise.content.mainVideoEnabled = payload === 'video' ? true : false;

    // loadedExercise.exercise.content.mainImageEnabled =
    //     payload === 'reading' ? true : false;

    return {
      ...clonedState,
    };
  },
  [ComprehensionExerciseActions.SET_INSTRUCTIONS_LANGUAGE]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    let clonedState: CourseSliceInterface = clone(state);

    let loadedExercise: LoadedComprehensionExerciseInterface =
      clonedState?.loadedExercise as LoadedComprehensionExerciseInterface;

    if ((loadedExercise.exercise as ComprehensionExerciseInterface).content !== undefined) {
      (loadedExercise.exercise as ComprehensionExerciseInterface).content.instructionsLanguage =
        payload.instructionsLanguage;
      (loadedExercise.exercise as ComprehensionExerciseInterface).content.instructionsLanguageChanged =
        payload.instructionsLanguage;
    }

    return {
      ...clonedState,
      loadedExercise,
    };
  },
  [ComprehensionExerciseActions.SET_TITLE_LANGUAGE]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    let clonedState: CourseSliceInterface = clone(state);

    let loadedExercise: LoadedComprehensionExerciseInterface =
      clonedState?.loadedExercise as LoadedComprehensionExerciseInterface;

    if ((loadedExercise.exercise as ComprehensionExerciseInterface).content !== undefined) {
      (loadedExercise.exercise as ComprehensionExerciseInterface).content.titleLanguage = payload.titleLanguage;

      if (!payload.withoutUpdateButtonState) {
        (loadedExercise.exercise as ComprehensionExerciseInterface).content.titleLanguageChanged = true;
      }
    }

    return {
      ...clonedState,
      loadedExercise,
    };
  },
  [ComprehensionExerciseActions.SET_MAIN_CONTENT_LANGUAGE]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    return produce(state, (draft) => {
      if (draft.loadedExercise.exercise.content !== undefined) {
        const isChanged = draft.loadedExercise.exercise.content.mainContentLanguage !== payload.mainContentLanguage;
        draft.loadedExercise.exercise.content.mainContentLanguageChanged = isChanged;
        draft.loadedExercise.exercise.content.mainContentLanguage = payload.mainContentLanguage;
      }
    });
  },
  [ComprehensionExerciseActions.UPLOAD_VIDEO_SUCCESS]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<UploadMediaResponse>,
  ): CourseSliceInterface => {
    let { content, mediaId } = payload;

    state.loadedExercise.exercise.content.mainBundle = {
      ...state.loadedExercise.exercise.content.mainBundle,
      video: {
        ...content,
        _id: mediaId,
        changed: true,
      },
    };
    state.mediaUploading.imageOrVideoUploadingInProcess = state.mediaUploading.imageOrVideoUploadingInProcess.filter(
      (item) => item !== 'video',
    );

    return state;
  },
  [ComprehensionExerciseActions.REMOVE_MAIN_TEXT]: (state: CourseSliceInterface): CourseSliceInterface => {
    return ExerciseDataModelAssembler.nullifyFieldInBundle({
      field: 'phrase',
      bundleName: 'mainBundle',
      state,
    });
  },
  [ComprehensionExerciseActions.REMOVE_READING_IMAGE]: (state: CourseSliceInterface): CourseSliceInterface => {
    return ExerciseDataModelAssembler.nullifyFieldInBundle({
      field: 'image',
      bundleName: 'mainBundle',
      state,
    });
  },
  [ComprehensionExerciseActions.REMOVE_VIDEO]: (state: CourseSliceInterface): CourseSliceInterface => {
    return ExerciseDataModelAssembler.nullifyFieldInBundle({
      field: 'video',
      bundleName: 'mainBundle',
      state,
    });
  },
  [ComprehensionExerciseActions.SET_COMPREHENSION_ALL_FIELDS]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    let clonedCourseSlice: CourseSliceInterface = clone(state);

    const { values } = payload;

    clonedCourseSlice = updateTextField(clonedCourseSlice, values, 'instructions');
    clonedCourseSlice = updateTextField(clonedCourseSlice, values, 'title');
    clonedCourseSlice = updateTextField(clonedCourseSlice, values, 'phrase', 'mainBundle');
    clonedCourseSlice.loadedExercise.exercise.content.provider = values.provider;

    return clonedCourseSlice;
  },
};

export default ComprehensionExerciseCourseReducers;
