import styled from 'styled-components/macro';

import { Button } from '@features/theme';
import { Modal, useDialogModal } from '@features/modal';
import { DBId } from '@common/types/DBId';
import { BulkPublishingActionsCreator } from '@actionCreators/BulkPublishingActionsCreator';
import { useBulkPublishing } from '../_hooks';
import { BulkPublishingModalContent } from './BulkPublishingModalContent';
import { useAppDispatch } from '@redux/store';

const FooterContainer = styled.div`
  position: fixed;
  bottom: 0;
  background: ${({ theme }) => theme.color.brandWhite};
  height: 10rem;
  left: 0;
  width: 100%;
  z-index: 4000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2.4rem 4rem;
  box-shadow: 0 -0.1rem 0.2rem rgba(0, 0, 0, 0.1);
`;

const FooterDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const FooterTitle = styled.span`
  color: ${({ theme }) => theme.color.brandBlack};
  font-weight: 800;
  font-size: 2.4rem;
`;

const FooterDescription = styled.span`
  color: ${({ theme }) => theme.color.brandGreyscale800};
  font-weight: 400;
  font-size: 1.6rem;
`;

const FooterButtonsContainer = styled.div`
  display: flex;
  gap: 0.8rem;
`;

export const BulkPublishingFooter = ({ parentId, parentType }: { parentId: DBId; parentType: string }) => {
  const { open: openBulkPublishModal, modal: publishMultipleModal } = useDialogModal((modalControls) => (
    <Modal {...modalControls}>
      <BulkPublishingModalContent parentId={parentId} parentType={parentType} {...modalControls} />
    </Modal>
  ));

  const dispatch = useAppDispatch();

  const { selectedForBulkPublishing, currentBulkPublishingState } = useBulkPublishing();

  if (!currentBulkPublishingState) {
    return null;
  }

  return (
    <FooterContainer>
      {publishMultipleModal}
      <FooterDescriptionContainer>
        <FooterTitle>
          Select exercises to {currentBulkPublishingState === 'publish' ? 'publish' : 'unpublish'}
        </FooterTitle>
        <FooterDescription>
          {currentBulkPublishingState === 'publish'
            ? 'Only valid exercises can be published.'
            : 'Only already published exercises can be unpublished.'}
        </FooterDescription>
      </FooterDescriptionContainer>
      <FooterButtonsContainer>
        <Button variant="secondary" onClick={() => dispatch(BulkPublishingActionsCreator.disableAll())}>
          Cancel
        </Button>
        <Button variant="primary" onClick={openBulkPublishModal} disabled={!selectedForBulkPublishing.length}>
          {currentBulkPublishingState === 'publish' ? 'Publish' : 'Unpublish'}{' '}
          {selectedForBulkPublishing.length ? `${selectedForBulkPublishing.length} ` : ' '}
          selected
        </Button>
      </FooterButtonsContainer>
    </FooterContainer>
  );
};
