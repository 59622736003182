import { FunctionComponent } from 'react';
import classnames from 'classnames';

import playbackIcon from './img/playback.svg';
import playIcon from './img/play.svg';

import './AudioController.scss';

export const AudioController: FunctionComponent = () => {
  return (
    <div className="audio-controller">
      <img
        src={playbackIcon}
        className={classnames('audio-controller__playback', 'audio-controller__playback--rotated')}
        alt="Playback rotated"
      />
      <img src={playIcon} className="audio-controller__play" alt="Play" />
      <img src={playbackIcon} className="audio-controller__playback" alt="Playback" />
    </div>
  );
};
