import { useState } from 'react';
import styled from 'styled-components/macro';

import { InfoModalContent, Modal, useDialogModal } from '@features/modal';
import { IdentityGroupDeleteModal } from '../../IdentityGroupDeleteModal';
import { IdentityGroupModal } from '../../IdentityGroupModal';
import { IdentityGroupType } from '../../types';
import deleteIcon from './img/deleteIcon.svg';
import editIcon from './img/editIcon.svg';

const TableItem = styled.tr`
  font-size: 1.6rem;
  box-shadow: 0 0.1rem 0 ${({ theme }) => theme.color.brandGreyscale600};
  display: grid;
  grid-template-columns: 50% 30% 20%;
  align-items: center;
`;

const NumberOfLessons = styled.td`
  width: 100%;
  text-align: center;
  font-weight: 700;
`;

const ButtonsContainer = styled.td`
  display: flex;
  justify-content: end;
`;

const GroupButton = styled.img`
  width: 2.4rem;
  margin-right: 2.4rem;
  cursor: pointer;
`;

export const IdentityGroup = ({
  identityGroup,
  onChange,
}: {
  identityGroup: IdentityGroupType;
  onChange: () => void;
}) => {
  const [isEditModalOpened, setIsEditModalOpened] = useState(false);

  const { open: openDeleteModal, modal: deleteModal } = useDialogModal((modalControls) => (
    <Modal {...modalControls} onClickOutside={modalControls.close}>
      <IdentityGroupDeleteModal identityGroup={identityGroup} onChange={onChange} onClose={modalControls.close} />
    </Modal>
  ));
  const { open: openDeleteUnavailableModal, modal: deleteUnavailableModal } = useDialogModal((modalControls) => (
    <Modal {...modalControls} onClickOutside={modalControls.close}>
      <InfoModalContent
        testIdPrefix="identity-group-delete"
        title="You can't delete this identity group while it is mapped with lessons."
        description="Reassign the lesson from the identity group and repeat the action"
        close={modalControls.close}
      />
    </Modal>
  ));

  const onDelete = () => {
    if (identityGroup.lessonCount > 0) {
      openDeleteUnavailableModal();
    } else {
      openDeleteModal();
    }
  };

  return (
    <TableItem key={identityGroup.id}>
      <IdentityGroupModal
        opened={isEditModalOpened}
        identityGroup={identityGroup}
        onChange={onChange}
        onClose={() => setIsEditModalOpened(false)}
      />
      {deleteUnavailableModal}
      {deleteModal}
      <td>{identityGroup.title}</td>
      <NumberOfLessons>{identityGroup.lessonCount || '-'}</NumberOfLessons>
      <ButtonsContainer>
        <GroupButton src={editIcon} onClick={() => setIsEditModalOpened(true)} />
        <GroupButton src={deleteIcon} onClick={onDelete} />
      </ButtonsContainer>
    </TableItem>
  );
};
