import { PreparedPublishContentInterface } from '@common/interfaces/contentTypes/PublishingInterface';
import { PublishingActionsCreator } from '@actionCreators/PublishingActionCreator';
import { PublishModal } from '@components/Publishing/PublishModal';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { selectPublishingPreparedContent } from '@selectors/CoursesSelectors';

export const PublishModalContainer = () => {
  const dispatch = useAppDispatch();
  const preparedPublishContent: PreparedPublishContentInterface = useAppSelector(selectPublishingPreparedContent);

  return (
    <PublishModal
      opened={!!preparedPublishContent.id}
      onHide={() => dispatch(PublishingActionsCreator.setInitialContentToPublish())}
      onSubmit={(forAllInstances: boolean) =>
        dispatch(PublishingActionsCreator.publishOrUnpublishContent(forAllInstances, preparedPublishContent.readyState))
      }
      forUnpublish={preparedPublishContent.readyState && !preparedPublishContent.forUpdate}
      forUpdate={preparedPublishContent.forUpdate}
      forReusedContent={false}
      type={preparedPublishContent.type}
    />
  );
};
