import { MouseEvent } from 'react';
import ReactMarkdown from 'react-markdown';
import ReactPlayer from 'react-player';
import remarkGfm from 'remark-gfm';
import styled from 'styled-components/macro';

import { useIsAdmin } from '@features/auth/roles';
import { Button } from '@features/theme';

import { ReactComponent as EditIcon } from './img/edit.svg';
import { HelpHeader, HelpBody, HelpFooter } from './styles';
import { HelpDataType } from '../types';

const StyledEditIcon = styled(EditIcon)`
  cursor: pointer;
`;

export const HelpTitle = styled.div`
  align-items: center;
  display: flex;
  font-size: 1.8rem;
  font-weight: 700;
  gap: 0.4rem;
  margin-bottom: 1.2rem;
`;

const HelpImage = styled.img`
  width: 30rem;
  height: 18rem;
  border-radius: 0.8rem;
  margin-bottom: 1.6rem;
`;

const HelpVideo = styled(ReactPlayer)`
  width: 30rem !important;
  height: auto !important;

  video {
    border-radius: 1.6rem;
  }
`;

type HelpContentProps = {
  data: HelpDataType;
  title: string;
  titleIcon: JSX.Element;
  onEdit: () => void;
  onClose: () => void;
};

export const HelpContent = ({ data, titleIcon, title, onEdit, onClose }: HelpContentProps) => {
  const isAdmin = useIsAdmin();
  const { description, image, link, video } = data;

  const handleOnEdit = (evt: MouseEvent<SVGElement>) => {
    evt.stopPropagation();
    evt.preventDefault();
    onEdit();
  };

  return (
    <>
      <HelpHeader>
        <HelpTitle>
          {titleIcon}
          {title}
        </HelpTitle>
        {isAdmin && <StyledEditIcon onClick={handleOnEdit} />}
      </HelpHeader>

      <HelpBody scroll={true}>
        <ReactMarkdown remarkPlugins={[remarkGfm]}>{description as string}</ReactMarkdown>
        {image?.value && <HelpImage src={image?.value} />}
        {video?.url && <HelpVideo url={video.url} controls={true} />}
      </HelpBody>

      <HelpFooter>
        <Button size="S" variant="tertiary" onClick={onClose}>
          Close
        </Button>
        {link && (
          <Button size="S" variant="primary" onClick={() => window.open(link)}>
            Learn more
          </Button>
        )}
      </HelpFooter>
    </>
  );
};
