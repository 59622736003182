import styled from 'styled-components';

import IDDisplayer from '@components/IDDisplayer/IDDisplayer';
import { DBId } from '@common/types/DBId';

const Title = styled.div`
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 1.8;
  max-width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Context = styled.div`
  color: ${({ theme }) => theme.colorV2.textTertiary};
  font-size: 1.2rem;
  line-height: 1.5;
`;

type CardTitlePropsType = {
  context?: string;
  id: DBId;
  title: string;
};

export const CardTitle = ({ context, id, title }: CardTitlePropsType) => (
  <div>
    <IDDisplayer id={id} />
    <Title>{title}</Title>
    {context && <Context>{context}</Context>}
  </div>
);
