import { LocalizationInterface } from '@common/interfaces/localization/LocalizationInterface';
import { brTag, processSpacingForEditor, removeTableTags } from '@helpers/htmlTagsHelper';

export const selectTextLocalizationsForSave = (localizations: LocalizationInterface[]) => {
  if (localizations) {
    return localizations
      .filter((localization) => {
        return localization.value || localization.phoneticValue || localization._id || localization?.alternativeValues;
      })
      .map((localization) => ({
        ...localization,
        value: localization.value === brTag ? '' : removeTableTags(processSpacingForEditor(localization.value)),
      }));
  }
  return [];
};

export const selectMediaLocalizationsForSave = (localizations: LocalizationInterface[]) => {
  if (localizations) {
    return localizations.filter((localization) => {
      return localization.value || localization._id || localization.fileId || localization.fileId === null;
    });
  }
  return [];
};

// filter media localizations to be saved and map them to match expected payload by backend
export const mapMediaLocalizationsForSave = (localizations: LocalizationInterface[] = [], type?: string) => {
  return selectMediaLocalizationsForSave(
    localizations.map((loc) => ({
      alternativeValues: loc.alternativeValues,
      fileId: loc._id,
      language: loc.language,
      phoneticValue: loc.phoneticValue,
      type: type || undefined,
      value: loc.value,
    })),
  );
};
