import { ContentState, EditorState, Modifier, SelectionState } from 'draft-js';
import { ContentBlock } from 'react-draft-wysiwyg';
import { stateToHTML } from 'draft-js-export-html';

import BidimensionalEditorUtils from '@components/BidimensionalEditor/BidimensionalEditorUtils';
import { removePTag, brTag } from '@helpers/htmlTagsHelper';

const removeFontTags = (value: string) => {
  return value
    .replace(/<strong>|<\/strong>/gi, '')
    .replace(/<em>|<\/em>/gi, '')
    .replace(/<ins>|<\/ins>/gi, '')
    .replace(/<b>|<\/b>/gi, '')
    .replace(/<i>|<\/i>/gi, '')
    .replace(/<u>|<\/u>/gi, '');
};

// normalize text value for a correct editor state and selection management
const normalizeText = (value: string) => value.toLowerCase().replaceAll(/<br[/]*>/gi, '\n');

export const computeEditorState = (charactersMode: boolean, maxCharacters: number, value?: string | undefined) => {
  let contentValue = value === undefined ? '' : removeFontTags(value);
  let replacedTags = contentValue.replace(/\[k\]|\[\/k\]/gi, '');
  let editorState = BidimensionalEditorUtils.stringToHTMLForBidimensionalEditor(replacedTags);
  let totalBlocksStylized = 0;

  editorState
    .getCurrentContent()
    .getBlocksAsArray()
    .forEach((block: ContentBlock) => {
      let normalizedText = normalizeText(contentValue);
      let start = 0;
      let end = 0;

      while (start !== -1 && end !== -1) {
        start = normalizedText.indexOf('[k]');
        normalizedText = normalizedText.replace('[k]', '');
        end = normalizedText.indexOf('[/k]');
        normalizedText = normalizedText.replace('[/k]', '');

        if (start !== -1 && end !== -1) {
          let contentState;
          let currentContent = editorState.getCurrentContent();

          let selection = SelectionState.createEmpty(block.getKey());

          let plainTextLength = currentContent.getPlainText().length;
          if (end > plainTextLength) {
            end = plainTextLength;
          }

          selection = selection.merge({
            anchorOffset: start,
            focusOffset: end,
          });

          if (charactersMode) {
            let className = '';

            if (totalBlocksStylized <= (maxCharacters as number)) {
              totalBlocksStylized % 2 ? (className = 'KTAG_GROUP') : (className = 'KTAG_GROUP2');
            } else {
              totalBlocksStylized % 2 ? (className = 'KTAG_BAD') : (className = 'KTAG_BAD2');
            }

            contentState = Modifier.applyInlineStyle(currentContent, selection, className);
          } else {
            contentState = Modifier.applyInlineStyle(
              currentContent,
              selection,
              totalBlocksStylized % 2 ? 'KTAG_GROUP' : 'KTAG_GROUP2',
            );
          }

          totalBlocksStylized++;

          editorState = EditorState.push(editorState, contentState, 'change-inline-style');
        }
      }
    });

  return editorState;
};

const replaceTag = (output: string, tag: string) => {
  const ktagRegxp = new RegExp(`<span class="${tag}">`, 'gi');

  return removePTag(output)
    .replace(ktagRegxp, '[k]')
    .replace(/<\/span>/gi, '[/k]')
    .replace(/&nbsp;/gi, ' ')
    .replace(/&amp;/gi, '&');
};

export const generateOutputForAPI = (contentState: ContentState) => {
  let output = stateToHTML(contentState, {
    inlineStyles: {
      KTAG_GROUP: {
        attributes: { class: 'KTAG_GROUP' },
      },
      KTAG_GROUP2: {
        attributes: { class: 'KTAG_GROUP2' },
      },
      KTAG_BAD: {
        attributes: { class: 'KTAG_BAD' },
      },
      KTAG_BAD2: {
        attributes: { class: 'KTAG_BAD2' },
      },
    },
    defaultBlockTag: '',
  });

  output = replaceTag(output, 'KTAG_GROUP');
  output = replaceTag(output, 'KTAG_GROUP2');
  output = replaceTag(output, 'KTAG_BAD');
  output = replaceTag(output, 'KTAG_BAD2');

  output = `${removeFontTags(output)}`;

  return output === brTag ? '' : output;
};
