export const ACTIVITY_VOCABULARY: ActivityType = 'vocabulary';
export const ACTIVITY_MEMORISE: ActivityType = 'memorise';
export const ACTIVITY_DIALOGUE: ActivityType = 'dialogue';
export const ACTIVITY_QUIZ: ActivityType = 'quiz';
export const ACTIVITY_DISCOVER: ActivityType = 'discover';
export const ACTIVITY_DEVELOP: ActivityType = 'develop';
export const ACTIVITY_PRACTICE: ActivityType = 'practice';
export const ACTIVITY_CONVERSATION: ActivityType = 'conversation';
export const ACTIVITY_PRONUNCIATION: ActivityType = 'pronunciation';
export const ACTIVITY_VIDEO: ActivityType = 'video';
export const ACTIVITY_READING: ActivityType = 'reading';
export const ACTIVITY_COMPREHENSION_SKILLS: ActivityType = 'comprehensionSkills';
export const ACTIVITY_PRODUCTIVE_SKILLS: ActivityType = 'productiveSkills';
export const ACTIVITY_ENTITY: ActivityType = 'entity';
export const ACTIVITY_SPEAKING: ActivityType = 'speakingActivity';

export const ACTIVITIES: ActivityType[] = [
  ACTIVITY_VOCABULARY,
  ACTIVITY_MEMORISE,
  ACTIVITY_DIALOGUE,
  ACTIVITY_QUIZ,
  ACTIVITY_DISCOVER,
  ACTIVITY_DEVELOP,
  ACTIVITY_PRACTICE,
  ACTIVITY_CONVERSATION,
  ACTIVITY_PRONUNCIATION,
  ACTIVITY_VIDEO,
  ACTIVITY_READING,
  ACTIVITY_COMPREHENSION_SKILLS,
  ACTIVITY_PRODUCTIVE_SKILLS,
  ACTIVITY_ENTITY,
  ACTIVITY_SPEAKING,
];

export type ActivityType =
  | 'vocabulary'
  | 'memorise'
  | 'dialogue'
  | 'quiz'
  | 'discover'
  | 'develop'
  | 'practice'
  | 'conversation'
  | 'pronunciation'
  | 'video'
  | 'reading'
  | 'comprehensionSkills'
  | 'productiveSkills'
  | 'entity'
  | 'speakingActivity';

export const ACTIVITY_TITLES: { [key in ActivityType]: string } = {
  [ACTIVITY_VOCABULARY]: 'Vocabulary',
  [ACTIVITY_MEMORISE]: 'Memorise',
  [ACTIVITY_DIALOGUE]: 'Dialogue',
  [ACTIVITY_QUIZ]: 'Quiz',
  [ACTIVITY_DISCOVER]: 'Discover',
  [ACTIVITY_DEVELOP]: 'Develop',
  [ACTIVITY_PRACTICE]: 'Practice',
  [ACTIVITY_CONVERSATION]: 'Conversation',
  [ACTIVITY_PRONUNCIATION]: 'Pronunciation',
  [ACTIVITY_VIDEO]: 'Video',
  [ACTIVITY_READING]: 'Reading',
  [ACTIVITY_COMPREHENSION_SKILLS]: 'Comprehension Skills',
  [ACTIVITY_PRODUCTIVE_SKILLS]: 'Productive Skills',
  [ACTIVITY_ENTITY]: 'Entity',
  [ACTIVITY_SPEAKING]: 'Speaking',
};
