export const generateJoinedAtMessage = (joinedAt: string) => {
  return `${new Date(joinedAt).toLocaleString('en-us', {
    month: 'short',
  })} ${new Date(joinedAt).getDate()}, ${new Date(joinedAt).getFullYear()}`;
};

export const generateLastLoginAtMessage = (lastLoginAt: string) => {
  const todaysDate = `${new Date().toLocaleString('en-us', { month: 'short' })} ${new Date().getDate()}`;

  const loginDate = `${new Date(lastLoginAt).toLocaleString('en-us', { month: 'short' })} ${new Date(
    lastLoginAt,
  ).getDate()}`;

  const loginTime = `${new Date(lastLoginAt).toLocaleString('en-us', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  })}`;

  const isTodayLastLogin = loginDate === todaysDate;

  return `${isTodayLastLogin ? 'Today' : loginDate}, ${loginTime}`;
};
