import { Language, LanguageV2 } from './languages';

export const LANGUAGE_NAMES: { [key in Language]: string } = {
  AR: 'Arabic',
  ZH: 'Chinese',
  EN: 'English',
  en_US: 'English (US)',
  FR: 'French',
  DE: 'German',
  IT: 'Italian',
  JA: 'Japanese',
  PT: 'Portuguese',
  PL: 'Polish',
  RU: 'Russian',
  ES: 'Spanish',
  TR: 'Turkish',
  VI: 'Vietnamese',
  ID: 'Indonesian',
  KO: 'Korean',
  NL: 'Dutch',
};

export const LANGUAGE_NAMES_V2: { [key in LanguageV2]: string } = {
  ar: 'Arabic',
  de: 'German',
  en: 'English',
  'en-US': 'English (US)',
  es: 'Spanish',
  fr: 'French',
  id: 'Indonesian',
  it: 'Italian',
  ja: 'Japanese',
  ko: 'Korean',
  nl: 'Dutch',
  pl: 'Polish',
  pt: 'Portuguese',
  ru: 'Russian',
  tr: 'Turkish',
  vi: 'Vietnamese',
  zh: 'Chinese',
};

export const LANGUAGE_SHORT_NAMES: { [key in Language]: string } = {
  AR: 'AR',
  ZH: 'ZH',
  EN: 'EN',
  en_US: 'EN (US)',
  FR: 'FR',
  DE: 'DE',
  IT: 'IT',
  JA: 'JA',
  PT: 'PT',
  PL: 'PL',
  RU: 'RU',
  ES: 'ES',
  TR: 'TR',
  VI: 'VI',
  ID: 'ID',
  KO: 'KO',
  NL: 'NL',
};
