import { takeLatest } from 'redux-saga/effects';
import { SearchActions } from '@actions/SearchActions';
import SearchV2Sagas from '@sagas/search/definitions/SearchV2SagasDefinition';

export function* SearchRelatedSagas() {
  yield takeLatest(SearchActions.USE_STRING_V2, SearchV2Sagas.useString);
  yield takeLatest(SearchActions.COPY_STRING_V2, SearchV2Sagas.copyString);
  yield takeLatest(SearchActions.USE_BUNDLE_V2, SearchV2Sagas.useBundle);
  yield takeLatest(SearchActions.COPY_BUNDLE_V2, SearchV2Sagas.copyBundle);
  yield takeLatest(SearchActions.USE_EXERCISE_V2, SearchV2Sagas.useExercise);
  yield takeLatest(SearchActions.COPY_EXERCISE_V2, SearchV2Sagas.copyExercise);
  yield takeLatest(SearchActions.USE_LESSON_V2, SearchV2Sagas.useLesson);
  yield takeLatest(SearchActions.COPY_LESSON_V2, SearchV2Sagas.copyLesson);
  yield takeLatest(SearchActions.USE_ACTIVITY_V2, SearchV2Sagas.useActivity);
  yield takeLatest(SearchActions.COPY_ACTIVITY_V2, SearchV2Sagas.copyActivity);
}
