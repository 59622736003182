import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Button } from '@features/theme';
import { ReactComponent as GrammarIcon } from './grammarIcon.svg';
import { useAppSelector } from '@redux/store';
import { selectCourse } from '@selectors/CoursesSelectors';

const StyledButton = styled(Button)`
  background-color: ${({ theme }) => theme.colorV2.navigationButtonBackground};
  border-width: 0.1rem;
  font-size: 1.4rem;
  margin-bottom: 1.6rem;

  &:hover {
    background-color: ${({ theme }) => theme.colorV2.navigationButtonHoverBackground};
    color: ${({ theme }) => theme.colorV2.navigationButtonHoverText};

    svg {
      path {
        fill: ${({ theme }) => theme.colorV2.navigationButtonHoverText};
      }
    }
  }
`;

export const GrammarReviewButton = ({ collapsed }: { collapsed?: boolean }) => {
  const currentCourse = useAppSelector(selectCourse);
  const history = useHistory();

  if (!currentCourse.isComplete) {
    return null;
  }

  return (
    <StyledButton
      icon={<GrammarIcon />}
      fullWidth
      size="S"
      variant="secondary"
      collapsed={collapsed}
      onClick={() => {
        history.push(`/course/${currentCourse.id}/grammar-review`);
      }}
    >
      {!collapsed && 'Grammar Review'}
    </StyledButton>
  );
};
