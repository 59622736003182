// Placement test
const GET_PLACEMENT_TEST = 'GET_PLACEMENT_TEST';
const CREATE_PLACEMENT_TEST = 'CREATE_PLACEMENT_TEST';
const PLACEMENT_TEST_LOADING = 'PLACEMENT_TEST_LOADING';
const PLACEMENT_TEST_LOADED = 'PLACEMENT_TEST_LOADED';
const GET_ENTRYPOINTS = 'GET_ENTRYPOINTS';
const ENTRYPOINTS_LOADING = 'ENTRYPOINTS_LOADING';
const ENTRYPOINTS_LOADED = 'ENTRYPOINTS_LOADED';
const SET_INITIAL_PLACEMENT_TEST = 'SET_INITIAL_PLACEMENT_TEST';

// Entrypoint
const COPY_EXERCISE = 'ENTRYPOINT_COPY_EXERCISE';
const CREATE_EXERCISE = 'ENTRYPOINT_CREATE__EXERCISE';
const SET_INITIAL_ENTRYPOINT = 'SET_INITIAL_ENTRYPOINT';
const GET_ENTRYPOINT = 'GET_ENTRYPOINT';
const GET_ENTRYPOINT_EXERCISES = 'GET_ENTRYPOINT_EXERCISES';
const ENTRYPOINT_EXERCISES_LOADED = 'ENTRYPOINT_EXERCISES_LOADED';
const ENTRYPOINT_EXERCISES_LOADING = 'ENTRYPOINT_EXERCISES_LOADING';
const ENTRYPOINT_LOADED = 'ENTRYPOINT_LOADED';
const ENTRYPOINT_LOADING = 'ENTRYPOINT_LOADING';
const REMOVE_EXERCISE = 'ENTRYPOINT_REMOVE_EXERCISE';
const REUSE_EXERCISE = 'ENTRYPOINT_REUSE_EXERCISE';
const SAVE_ENTRYPOINT = 'SAVE_ENTRYPOINT';
const SET_LINKED_CHAPTER = 'SET_LINKED_CHAPTER';

// Exercise
const SET_PLACEMENT_EXERCISE = 'SET_PLACEMENT_EXERCISE';
const SET_INITIAL_PLACEMENT_EXERCISE = 'SET_INITIAL_PLACEMENT_EXERCISE';

// Navigation
const SET_LOADING_PARENT_ID = 'PLACEMENT_TEST_SET_LOADING_PARENT_ID';
const UPDATE_NAVIGATION = 'PLACEMENT_TEST_UPDATE_NAVIGATION';
const GET_ALL_NAVIGATION = 'PLACEMENT_TEST_GET_ALL_NAVIGATION';

// Common
const SET_VALIDATION_RESULT = 'PLACEMENT_SET_VALIDATION_RESULT';

export const PlacementTestActions = {
  GET_PLACEMENT_TEST,
  CREATE_PLACEMENT_TEST,
  PLACEMENT_TEST_LOADING,
  PLACEMENT_TEST_LOADED,
  GET_ENTRYPOINTS,
  ENTRYPOINTS_LOADING,
  ENTRYPOINTS_LOADED,
  SET_INITIAL_PLACEMENT_TEST,
  COPY_EXERCISE,
  CREATE_EXERCISE,
  SET_INITIAL_ENTRYPOINT,
  GET_ENTRYPOINT,
  GET_ENTRYPOINT_EXERCISES,
  ENTRYPOINT_EXERCISES_LOADED,
  ENTRYPOINT_EXERCISES_LOADING,
  ENTRYPOINT_LOADED,
  ENTRYPOINT_LOADING,
  REMOVE_EXERCISE,
  REUSE_EXERCISE,
  SAVE_ENTRYPOINT,
  SET_LINKED_CHAPTER,
  SET_PLACEMENT_EXERCISE,
  SET_INITIAL_PLACEMENT_EXERCISE,
  SET_LOADING_PARENT_ID,
  UPDATE_NAVIGATION,
  GET_ALL_NAVIGATION,
  SET_VALIDATION_RESULT,
} as const;
