import { VocabularyReviewListItem } from '../../types';
import { MediaImage, MediaSeparator, StyledVideoIcon } from './styles';

export const LexicalItemMediaDisplay = ({
  hasVideo,
  image,
}: {
  hasVideo: VocabularyReviewListItem['hasVideo'];
  image: VocabularyReviewListItem['image'];
}) => (
  <>
    {!!image && <MediaImage src={image} />}
    <MediaSeparator visible={!!(image && hasVideo)} />
    {hasVideo && <StyledVideoIcon />}
  </>
);
