export const noExperimentOption = {
  value: null,
  label: 'No experiment',
};
export const REFRESH_CODE = 'refresh';
export const refreshExperimentOption = {
  value: REFRESH_CODE,
  label: 'Refresh list',
};
export const refreshExperimentVariationOption = {
  code: REFRESH_CODE,
  name: 'Refresh list',
};
export const OFF_VARIATION_CODE = 'off';
export const offVariationOption = {
  code: OFF_VARIATION_CODE,
  name: 'Off',
};
