import { Col, Row } from 'react-bootstrap';
import styled, { css } from 'styled-components/macro';

import { TranslationsTipWrapper } from '../TranslationsTip';

export const ScenarioTitleWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
`;

export const StyledTranslationsTipWrapper = styled(TranslationsTipWrapper)`
  flex: 1;
`;

export const StyledCol = styled(Col)`
  padding: 0;
  margin: 0;
`;

export const StyledRow = styled(Row)`
  gap: ${({ theme }) => theme.space.S};
  padding: 0;
  margin: 0 0 ${({ theme }) => theme.space.S} 0;
`;

export const ScenarioDataFieldsWrapper = styled.div`
  ${({ theme }) => css`
    min-width: 68.4rem;
    max-width: 100rem;
    padding: ${theme.space.XL} ${theme.space.S};
  `};
`;

export const FieldGroupHeader = styled.h4`
  ${({ theme }) => css`
    color: ${theme.colorV2.textTertiary};
    font-size: 1.4rem;
    font-weight: 700;
    text-transform: uppercase;
    margin: ${theme.space.L} 0 ${theme.space.S} 0;
  `}
`;

export const FieldLabel = styled.div`
  ${({ theme }) => css`
    color: ${theme.colorV2.textTertiary};
    font-size: 1.4rem;
    font-weight: 400;
    margin-bottom: ${theme.space.XS};
  `}
`;

export const RangeInput = styled.input`
  ${({ theme }) => css`
    border: solid 0.1rem ${theme.colorV2.input.border.default};
    border-radius: 0.8rem;
    font-size: 1.6rem;
    line-height: 2.8rem;
    padding: ${theme.space.XS} ${theme.space.M};
    width: 100%;
  `}
`;
