import type { AnyExerciseContentBranch } from '@common/types/AnyExerciseContentBranch';
import type { AnyExerciseInterface } from '@common/types/exercises/AnyExerciseInterface';
import { VideoUploadActions } from '@actions/VideoUploadActions';

export const VideoUploadActionsCreator = {
  uploadVideo: (
    exercise: AnyExerciseInterface,
    uploadedVideo: File,
    visitedBranch: AnyExerciseContentBranch,
    progressHandler: (progress: number) => void,
  ) => ({
    type: VideoUploadActions.UPLOAD_VIDEO,
    payload: {
      exercise,
      uploadedVideo,
      visitedBranch,
      progressHandler,
    },
  }),
};
