import { useState } from 'react';

import { StringResultCard } from './SearchResultItemCard';
import { StringResultDetails } from './SearchResultItemDetails';
import { getStringResultDetails } from './services';
import { StringSearchResultType, StringSearchResultDetailsType, StringDetailsTab } from './types';

export const StringSearchResultItem = ({ content }: { content: StringSearchResultType }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [details, setDetails] = useState<StringSearchResultDetailsType | null>(null);
  const [isDetailsLoaded, setIsDetailsLoaded] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [defaultTab, setDefaultTab] = useState<StringDetailsTab['key'] | null>(null);

  const onExpand = (tabName: StringDetailsTab['key'] | null) => {
    setDefaultTab(tabName);
    setIsExpanded(!isExpanded);
    if (!isExpanded && !details) {
      getStringResultDetails(content.id)
        .then(({ data }) => {
          setIsDetailsLoaded(true);
          setDetails(data);
        })
        .catch((error) => {
          setIsDetailsLoaded(true);
          setDetails(null);
          if (error.response.data.detail) {
            setErrorMessage(error.response.data.detail);
          } else {
            setErrorMessage(error.response.data.message || 'Unknown error');
          }
        });
    }
  };

  return (
    <div data-testid="search-result-item-string">
      <StringResultCard content={content} isExpanded={isExpanded} onExpand={onExpand} />
      <StringResultDetails
        errorMessage={errorMessage}
        defaultTab={defaultTab}
        details={details}
        isExpanded={isExpanded}
        isLoaded={isDetailsLoaded}
        isPopular={content.isPopular}
        isDefaultInstruction={content.isDefaultInstruction}
      />
    </div>
  );
};
