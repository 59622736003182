import { takeLatest } from 'redux-saga/effects';
import { SlidePptxExerciseActions } from '@actions/SlidePptxExerciseActions';
import MediaUploadSagas from '@sagas/mediaUpload/definitions/MediaUploadSagasDefinition';
import SlidePptxExerciseSagas from '@sagas/exercises/definitions/SlidePptxExerciseSagasDefinition';
import type { AppDispatch } from '@redux/store';

export function* SlidePptxExerciseRelatedSagas(dispatch: AppDispatch) {
  yield takeLatest(SlidePptxExerciseActions.UPLOAD_PPTX_FILE, MediaUploadSagas.uploadPptxToExercise);

  yield takeLatest(SlidePptxExerciseActions.SAVE, SlidePptxExerciseSagas.save, dispatch);
}
