import styled from 'styled-components';

import failIcon from '@components/ContentTypes/BaseContent/img/fail.svg';
import successIcon from '@components/ContentTypes/BaseContent/img/success.svg';
import { selectIsIssuesShown } from '@selectors/UiSelectors';
import { useAppSelector } from '@redux/store';

const ValidationMark = styled.div`
  margin-left: 1rem;
`;

export const CardValidationMark = ({ isInvalid }: { isInvalid: boolean }) => {
  const isIssuesShown = useAppSelector(selectIsIssuesShown);

  if (isIssuesShown) {
    return (
      <ValidationMark>
        {isInvalid ? <img src={failIcon} alt="Invalid" /> : <img src={successIcon} alt="Valid" />}
      </ValidationMark>
    );
  }

  return null;
};
