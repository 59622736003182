import { useMemo } from 'react';
import styled from 'styled-components/macro';

import { StringSearchResultType, StringDetailsTab } from '../../types';
import { CardWrapper, CardTitle, CardLocations, CardMoreButton, CardTranslation } from '../Common';

const StringCardWrapper = styled(CardWrapper)`
  grid-template-columns: 48% 12% 12% 20% 4%;
`;

export const StringResultCard = ({
  content,
  isExpanded,
  onExpand,
}: {
  content: StringSearchResultType;
  isExpanded: boolean;
  onExpand: (tabName: StringDetailsTab['key'] | null) => void;
}) => {
  const {
    audiosCount,
    context,
    courses,
    id,
    isDefaultInstruction,
    isPopular,
    locationsCount,
    text,
    translationsCount,
  } = content;

  const plainTextTitle = useMemo(() => text?.replace(/(<([^>]+)>)|(&.+;)/gi, '') || 'Empty string', [text]);

  return (
    <StringCardWrapper isExpanded={isExpanded} onClick={() => onExpand(null)}>
      <CardTitle context={context} id={id} title={plainTextTitle} />
      <CardTranslation type="audio" translations={audiosCount} />
      <CardTranslation type="text" translations={translationsCount} />
      <CardLocations
        courses={courses}
        isPopular={isPopular}
        isDefaultInstruction={isDefaultInstruction}
        id={id}
        locationsCount={locationsCount}
        onExpand={() => onExpand(isPopular || isDefaultInstruction ? 'audioAndTranslation' : 'locations')}
      />
      <CardMoreButton isExpanded={isExpanded} />
    </StringCardWrapper>
  );
};
