import { FunctionComponent } from 'react';
import { Button } from 'react-bootstrap';
import classnames from 'classnames';
import { useFormikContext } from 'formik';

import { SelectedGroupsOfParentInterface } from '@common/interfaces/groups/SelectedGroupsOfParentInterface';
import { FormikValuesInterface } from '@helpers/formikInitialValuesHelper';
import { ContentTypes } from '@common/enums/ContentTypes';
import { getSelfStudyLessons } from '@helpers/liveLessonPreviewHelper';

import { getValueByLanguage } from '../../../localization-utils';
import { PreviewModalForLiveLessonContentProps } from '../PreviewModalProps';
import { PreviewModalHeader } from '../common';
import { PREVIEW_DEVICE_TYPE } from '../constants';

import keyboardIcon from './img/keyboard.svg';
import checkedIcon from './img/checked.svg';
import blueDownloadIcon from './img/blueDownload.svg';
import bulbIcon from './img/bulb.svg';
import downloadIcon from './img/download.svg';

import './PreviewModalForLiveLessonContent.scss';
import { useAppSelector } from '@redux/store';
import { selectCourseStructure, selectSelectedGroupsOfParent } from '@selectors/CoursesSelectors';

export const PreviewModalForLiveLessonContent: FunctionComponent<PreviewModalForLiveLessonContentProps> = ({
  onModalClose,
  deviceType,
  setDeviceType,
  answerState,
  setAnswerState,
}: PreviewModalForLiveLessonContentProps) => {
  const { values }: { values: FormikValuesInterface } = useFormikContext();

  const selectedGroupsOfParent: SelectedGroupsOfParentInterface = useAppSelector(selectSelectedGroupsOfParent);

  const structure = useAppSelector(selectCourseStructure);

  const parentItem = structure.find(
    (structureItem) => structureItem.id === selectedGroupsOfParent?.parentContents?.categoryId,
  );
  const selfStudyLessons = getSelfStudyLessons(structure, selectedGroupsOfParent);

  return (
    <>
      <PreviewModalHeader
        onModalClose={onModalClose}
        type={ContentTypes.liveLesson}
        deviceType={deviceType}
        setDeviceType={setDeviceType}
        answerState={answerState}
        setAnswerState={setAnswerState}
      />
      <div
        className={classnames(
          'exercise-preview__content',
          `exercise-preview__content--${deviceType}`,
          { [`exercise-preview__content--${deviceType}--correct`]: answerState === 1 },
          { [`exercise-preview__content--${deviceType}--incorrect`]: answerState === 2 },
          'exercise-preview__content--without-footer',
        )}
      >
        <div className="preview__buttons-container">
          {deviceType !== PREVIEW_DEVICE_TYPE.MOBILE && <img src={keyboardIcon} alt="Keyboard" />}
          <img src={bulbIcon} alt="Bulb" />
          {deviceType !== PREVIEW_DEVICE_TYPE.MOBILE && <img src={downloadIcon} alt="Download" />}
        </div>
        <div className="preview__content">
          <div className="preview__progress-bar">
            <div className="preview__progress-bar--passed" />
          </div>
          <div className={classnames('live-lesson-preview', `live-lesson-preview--${deviceType}`)}>
            <img
              className="live-lesson-preview__image"
              src={getValueByLanguage(selectedGroupsOfParent?.parentContents?.image?.imageLocalizations)}
              alt="Live"
            />
            <div className="live-lesson-preview__label">{parentItem?.title?.toUpperCase() || 'UNTITLED LEVEL'}</div>
            <div className="live-lesson-preview__block live-lesson-preview__header">
              <span className="live-lesson-preview__block__text-main">
                {getValueByLanguage(values?.titleWithLocalizations)}
              </span>
              <span className="live-lesson-preview__block__text">
                {getValueByLanguage(values?.descriptionWithLocalizations)}
              </span>
            </div>

            <div className="live-lesson-preview__block live-lesson-preview__description">
              <span className="live-lesson-preview__block__text-main">What will I do in this lesson?</span>
              <div className="live-lesson-preview__description__details">
                {getValueByLanguage(values?.learningOutcomes)
                  .split('*')
                  .map((outcome: string, index: number) => {
                    if (outcome && outcome.replace('\n', '')) {
                      return (
                        <div className="live-lesson-preview__description__details__item" key={index}>
                          <img src={checkedIcon} alt="Checked" />
                          <span className="">{outcome.replace('\n', '')}</span>
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })}
              </div>
            </div>

            <div className="live-lesson-preview__block live-lesson-preview__study">
              <span className="live-lesson-preview__block__text-main">Self-study</span>
              <span>
                {selfStudyLessons
                  ? `Practice with self-led lessons ${selfStudyLessons}.`
                  : 'No published lessons before this live lesson.'}
              </span>
            </div>

            <div className="live-lesson-preview__block live-lesson-preview__material">
              <span className="live-lesson-preview__block__text-main">Lesson material</span>
              <div className="live-lesson-preview__block__link-container">
                <img src={blueDownloadIcon} alt="Download" />
                <span>Download material (PDF)</span>
              </div>
            </div>

            <div className="live-lesson-preview__footer">
              <span>Up to 5 students</span>
              <span>45 min</span>
              <Button className="live-lesson-preview__footer__button" onClick={() => console.log('Is preview')}>
                Choose a date and time
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
