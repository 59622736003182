import Accordion from 'react-bootstrap/Accordion';
import { components, MenuListProps } from 'react-select';

import { CommonFilterOptionType } from '../../types';

export const TranslationSelectorMenuList = (props: MenuListProps<CommonFilterOptionType>) => {
  return (
    <components.MenuList {...props}>
      <Accordion>{props.children}</Accordion>
    </components.MenuList>
  );
};
