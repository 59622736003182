import { random } from 'lodash';
import { Dropdown } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';

import type { ContentCreatorWidgetProps } from '../ContentCreatorWidget';
import {
  CreatorButton,
  CreatorButtonWithText,
  CreatorButtonWithTextImage,
  CreatorIcon,
  MoreButton,
  MenuItemText,
} from '../styles';

import moreIcon from './_img/More.svg';
import { Dispatch, SetStateAction } from 'react';

type ContentCreatorItemsProps<T extends string> = Pick<
  ContentCreatorWidgetProps<T>,
  'items' | 'isCreateInProgress' | 'onItemSelected'
> & { resetMoreMenu: boolean; setResetMoreMenu: Dispatch<SetStateAction<boolean>> };

export const ContentCreatorItems = <T extends string>({
  items,
  isCreateInProgress,
  resetMoreMenu,
  setResetMoreMenu,
  onItemSelected,
}: ContentCreatorItemsProps<T>) => {
  const visibleItems = items.filter(({ showByDefault }) => showByDefault);
  const hiddenItems = items.filter(({ showByDefault }) => !showByDefault);
  const shouldShowTooltip = visibleItems.some(({ showOnlyIcon }) => !!showOnlyIcon);
  const tooltipId = `creator-tooltip-${random(0, 10000)}`;

  return (
    <>
      {shouldShowTooltip && <ReactTooltip className="tooltip" id={tooltipId} effect="solid" />}

      {visibleItems.map((item) => {
        if (item.showOnlyIcon) {
          return (
            <CreatorButton
              data-for={tooltipId}
              data-tip={item.label}
              disabled={item.disabled || isCreateInProgress}
              key={item.type}
              onClick={() => onItemSelected(item)}
            >
              <CreatorIcon src={item.iconSrc} alt={`${item.label} icon`} />
            </CreatorButton>
          );
        } else {
          return (
            <CreatorButtonWithText
              disabled={item.disabled || isCreateInProgress}
              key={item.type}
              onClick={() => onItemSelected(item)}
            >
              <CreatorButtonWithTextImage src={item.iconSrc} alt={`Add ${item.label}`} />
              <span>{item.label}</span>
            </CreatorButtonWithText>
          );
        }
      })}

      {hiddenItems.length > 0 && (
        <MoreButton
          data-testid="contentCreatorDropdownMenu"
          title={<img src={moreIcon} alt="More" />}
          variant="link"
          disabled={isCreateInProgress}
          show={!resetMoreMenu}
          onClick={() => setResetMoreMenu(!resetMoreMenu)}
        >
          {hiddenItems.map((item) => (
            <Dropdown.Item onClick={() => onItemSelected(item)} key={item.type}>
              <CreatorIcon src={item.iconSrc} alt={`${item.label} icon`} />
              <MenuItemText>{item.label}</MenuItemText>
            </Dropdown.Item>
          ))}
        </MoreButton>
      )}
    </>
  );
};
