import { PayloadAction } from '@reduxjs/toolkit';
import produce from 'immer';

import { CommonActions } from '@actions/CommonActions';
import { CourseSliceInterface } from '@common/interfaces/slices/CourseSliceInterface';
import { ValidationErrorInterface } from '@common/interfaces/validation/ValidationInterface';

const ExerciseValidationErrorsReducers = {
  [CommonActions.UPDATE_VALIDATION_ERRORS]: (
    state: CourseSliceInterface,
    { payload: { errors } }: PayloadAction<{ errors: ValidationErrorInterface[] }>,
  ) => {
    return produce(state, (draft) => {
      draft.loadedExercise.exercise.content.validation.errors = errors;

      if (!errors.length) {
        draft.loadedExercise.exercise.content.validation.valid = true;
      }
    });
  },
};

export default ExerciseValidationErrorsReducers;
