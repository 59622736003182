const SAVE = 'COMPREHENSION_EXERCISE_SAVE';
const SET_INSTRUCTIONS_LANGUAGE = 'COMPREHENSION_SET_INSTRUCTIONS_LANGUAGE';
const SET_COMPREHENSION_OPTION = 'COMPREHENSION_SET_COMPREHENSION_OPTION';
const UPLOAD_VIDEO_SUCCESS = 'COMPREHENSION_UPLOAD_VIDEO_SUCCESS';
const UPLOAD_READING_IMAGE = 'COMPREHENSION_UPLOAD_READING_IMAGE';
const SET_TITLE_LANGUAGE = 'COMPREHENSION_SET_TITLE_LANGUAGE';
const SET_MAIN_CONTENT_LANGUAGE = 'COMPREHENSION_SET_MAIN_CONTENT_LANGUAGE';
const REMOVE_READING_IMAGE = 'COMPREHENSION_REMOVE_READING_IMAGE';
const REMOVE_VIDEO = 'COMPREHENSION_REMOVE_VIDEO';
const REMOVE_MAIN_TEXT = 'REMOVE_MAIN_TEXT';
const SET_COMPREHENSION_ALL_FIELDS = 'SET_COMPREHENSION_ALL_FIELDS';

export const ComprehensionExerciseActions = {
  SAVE,
  SET_INSTRUCTIONS_LANGUAGE,
  SET_COMPREHENSION_OPTION,
  UPLOAD_VIDEO_SUCCESS,
  UPLOAD_READING_IMAGE,
  SET_TITLE_LANGUAGE,
  SET_MAIN_CONTENT_LANGUAGE,
  REMOVE_READING_IMAGE,
  REMOVE_VIDEO,
  REMOVE_MAIN_TEXT,
  SET_COMPREHENSION_ALL_FIELDS,
} as const;
