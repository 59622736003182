import styled from 'styled-components/macro';

import { Button } from '@features/theme';

export const SlideFileUploadWrapper = styled.div<{
  isSlideFileUploaded: boolean;
  withError?: boolean;
  withErrorFailed?: boolean;
}>`
  align-items: center;
  background: ${({ theme }) => theme.colorV2.uiBackgroundPrimary}
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: 50%;
  background-position-x: 50%;
  border-color: ${({ theme, withError }) =>
    withError ? theme.colorV2.utilityError : theme.colorV2.input.border.default};
  border-radius: 0.5rem;
  border-style: ${({ withErrorFailed }) => (withErrorFailed ? 'solid' : 'dashed')};
  border-width: ${({ isSlideFileUploaded }) => (isSlideFileUploaded ? '0' : '0.2rem')};
  color: ${({ theme }) => theme.colorV2.textPrimary};
  display: flex;
  fill: ${({ theme }) => theme.colorV2.textPrimary};
  flex-direction: column;
  font-size: 1.4rem;
  height: ${({ isSlideFileUploaded }) => (isSlideFileUploaded ? 'fit-content' : '27rem')};
  justify-content: center;
  position: relative;
  text-align: center;
  width: 100%;
}
`;

export const UploadInstructions = styled.span`
  color: ${({ theme }) => theme.colorV2.textPrimary};
  font-size: 1.4rem;
  text-align: center;
`;

export const UploadDimensions = styled.span`
  color: ${({ theme }) => theme.colorV2.textPrimary};
  font-size: 1.2rem;
`;

export const UploadDescription = styled.span`
  color: ${({ theme }) => theme.colorV2.textPrimary};
`;

export const UploadLabel = styled.label`
  color: ${({ theme }) => theme.colorV2.accentPrimary};
  cursor: pointer;
  margin: 0;
`;

export const UploadInput = styled.input`
  display: inline;
  font-size: 0.8rem !important;
  position: fixed;
  transform: scale(0);
`;

export const FileUploaded = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-left: 1.6rem;
  width: 100%;
`;

export const FileUploadedDataContainer = styled.div`
  align-items: center;
  display: flex;
`;

export const FileUploadedImageContainer = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.colorV2.uiBackgroundSecondary};
  border: solid 0.1rem ${({ theme }) => theme.color.brandGreyscale500};
  border-radius: 0.8rem;
  display: flex;
  justify-content: center;
  height: 5.6rem;
  position: relative;
  width: 5.6rem;
`;

export const FileUploadedStatus = styled.span`
  color: ${({ theme }) => theme.colorV2.textPrimary};
  font-size: 1.4rem;
  margin-left: 2.4rem;
  max-width: 80rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const FileUploadedRemoveButton = styled(Button)`
  background-color: ${({ theme }) => theme.colorV2.uiBackgroundPrimary};
  border: solid 1px ${({ theme }) => theme.colorV2.buttonTertiaryBaseBorder};
  border-radius: 50%;
  display: flex;
  height: 2rem;
  justify-content: center;
  padding: 0;
  position: absolute;
  right: -0.8rem;
  top: -0.6rem;
  width: 2rem;

  &:hover {
    background-color: ${({ theme }) => theme.colorV2.uiBackgroundPrimary};
  }

  svg {
    margin-right: 0;
  }
`;

export const FileUploadedDownloadLink = styled.a`
  color: ${({ theme }) => theme.colorV2.accentPrimary};
  padding-left: 1.6rem;
  text-decoration: none;

  &:hover {
    color: ${({ theme }) => theme.colorV2.accentPrimary};
  }

  svg path {
    fill: ${({ theme }) => theme.colorV2.accentPrimary};
  }

  span {
    text-decoration: underline;
  }
`;
