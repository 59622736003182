import { Redirect, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import NavigationService from '@services/NavigationService';
import { DBId } from '@common/types/DBId';
import { PreloaderFullscreen } from '@components/Loaders/PreloaderFullscreen';
import { captureMessage } from '@sentry/react';

type ChildrenElement = {
  children: boolean;
  id: string;
  ready: boolean;
  title: string;
  type: string;
};

export const RedirectFromOldURL = () => {
  const [newURL, setNewURL] = useState('');
  const { courseId, levelId, lessonId, activityId, exerciseId } = useParams<{
    courseId: DBId;
    levelId: DBId;
    lessonId: DBId;
    activityId: DBId;
    exerciseId: DBId;
  }>();

  useEffect(() => {
    const generateNewURL = async () => {
      let chapterId;

      if (levelId) {
        const chapters: ChildrenElement[] = (await NavigationService.getLevelNavigation(levelId)).data.chapters;
        const chaptersWithChildren = chapters.filter((chapter) => chapter.children);
        for (let i = 0; i < chaptersWithChildren.length; i++) {
          const lessons: ChildrenElement[] = (await NavigationService.getChapterNavigation(chaptersWithChildren[i].id))
            .data.lessons;
          if (lessons.map((lesson) => lesson.id).includes(lessonId)) {
            chapterId = chaptersWithChildren[i].id;
            break;
          }
        }
      }

      if (chapterId) {
        if (lessonId && exerciseId && !activityId) {
          setNewURL(
            `/course/${courseId}/level/${levelId}/chapter/${chapterId}/lesson/${lessonId}/exercise/${exerciseId}`,
          );
        } else if (exerciseId && activityId) {
          setNewURL(
            `/course/${courseId}/level/${levelId}/chapter/${chapterId}/lesson/${lessonId}/activity/${activityId}/exercise/${exerciseId}`,
          );
        } else if (!exerciseId && activityId) {
          setNewURL(
            `/course/${courseId}/level/${levelId}/chapter/${chapterId}/lesson/${lessonId}/activity/${activityId}`,
          );
        } else if (lessonId && !exerciseId && !activityId) {
          setNewURL(`/course/${courseId}/level/${levelId}/chapter/${chapterId}/lesson/${lessonId}`);
        }
      } else {
        setNewURL('');
      }
    };

    generateNewURL();

    captureMessage('Redirect from Old URL', {
      extra: {
        courseId,
        levelId,
        lessonId,
        activityId,
        exerciseId,
      },
    });
  }, [courseId, levelId, lessonId, activityId, exerciseId]);

  if (!newURL) {
    return <PreloaderFullscreen />;
  }

  return <Redirect to={newURL} />;
};
