import { useAppDispatch, useAppSelector } from '@redux/store';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { courseEditionAbandoned } from '@actionCreators/BusuuLogoActionCreator';
import { Button } from '@features/theme';
import { selectCourse } from '@selectors/CoursesSelectors';

const StyledButton = styled(Button)`
  background-color: ${({ theme }) => theme.colorV2.navigationButtonBackground};
  border-width: 0.1rem;
  font-size: 1.4rem;
  margin-bottom: 1.6rem;

  &:hover {
    background-color: ${({ theme }) => theme.colorV2.navigationButtonHoverBackground};
    color: ${({ theme }) => theme.colorV2.navigationButtonHoverText};

    svg {
      path {
        fill: ${({ theme }) => theme.colorV2.navigationButtonHoverText};
      }
    }
  }
`;

export const CourseButton = () => {
  const currentCourse = useAppSelector(selectCourse);
  const dispatch = useAppDispatch();
  const history = useHistory();

  return (
    <StyledButton
      fullWidth
      size="S"
      variant="secondary"
      onClick={() => {
        dispatch(courseEditionAbandoned());
        history.push(`/course/${currentCourse.id}`);
      }}
    >
      {currentCourse.title}
    </StyledButton>
  );
};
