import { trim } from 'lodash';
import styled, { css, keyframes } from 'styled-components/macro';

import { SearchBox } from '@components/Search';
import { Button } from '@features/theme';

import { useSearch } from '../../../../SearchContext';
import { ReactComponent as BackIcon } from './img/back.svg';
import { useEnterKeydownHandler } from '../../Common';

const animationSvg = keyframes`
  0% {
    transform: translate(0, 0) rotate(-15deg);
  }

  50% {
    transform: translate(0, -4.5rem) rotate(0deg);
  }

  100% {
    transform: translate(0, -3.2rem) rotate(15deg);
  }
`;

const animationWrapper = keyframes`
  0% {
    z-index: 10;
  }

  75% {
    z-index: 0;
  }

  100% {
    z-index: 0;
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  left: 1rem;
  margin-right: 0.8rem;
  height: 18rem;
  width: 18rem;

  animation-name: ${animationWrapper};
  animation-duration: 0.5s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;

  & > svg {
    width: 100%;
    height: 100%;
    animation-name: ${animationSvg};
    animation-timing-function: linear;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
  }
`;

const animationBackground = (fromColor: string, toColor: string) => keyframes`
  from {
    background-color: ${fromColor};
  }

  to {
    background-color: ${toColor};
  }
`;

const StyledSearchHeader = styled.div<{ withFilters: boolean }>`
  ${({ theme }) => css`
    animation-name: ${animationBackground(theme.color.brandWhite, theme.color.brandBlue5)};
  `};
  animation-timing-function: ease-in;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;

  border-top-left-radius: 1.6rem;
  border-top-right-radius: 1.6rem;
  display: flex;
  ${({ withFilters }) => !withFilters && 'height: 16.8rem;'};
  ${({ withFilters }) => withFilters && 'flex-direction: column;'};
  padding: 4rem ${({ withFilters }) => (withFilters ? '1rem' : '22.4rem')} 3rem
    ${({ withFilters }) => (withFilters ? '1rem' : '22.4rem')};
  width: 100%;
`;

const SearchHeaderWrapper = styled.div<{ withFilters: boolean }>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  ${({ withFilters }) => withFilters && 'padding: 0 20rem;'};
`;

const SearchHeaderTitleWrapper = styled.div`
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 2.4rem;
`;

const SearchTypeBackButton = styled(BackIcon)`
  cursor: pointer;

  height: auto;
  margin: 0 0.4rem 0 0;
  transform: none;
  vertical-align: text-bottom;
  width: 2.4rem;

  path {
    fill: ${({ theme }) => theme.color.deepBlue65};
  }
`;

const SearchBoxWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledSearchBox = styled(SearchBox)`
  margin-right: 0.8rem;
  flex-grow: 1;
`;

export type SearchHeaderProps = {
  icon: JSX.Element;
  title: string;
  onBack?: (() => void) | null;
  onSearch: (query: string | null) => void;
  filtersArea?: JSX.Element;
};

export const SearchHeader = ({ icon, title, onBack = null, onSearch, filtersArea }: SearchHeaderProps) => {
  const { query, searchLoading, setQuery } = useSearch();

  const isSearchButtonDisabled = query && trim(query).length ? searchLoading : true;

  const onClear = () => {
    setQuery(null);
    onSearch(null);
  };

  // Handle Enter key press
  useEnterKeydownHandler(() => onSearch(query));

  return (
    <StyledSearchHeader data-testid="search-header" withFilters={Boolean(filtersArea)}>
      <IconWrapper>{icon}</IconWrapper>
      <SearchHeaderWrapper withFilters={Boolean(filtersArea)}>
        <SearchHeaderTitleWrapper>
          {onBack && <SearchTypeBackButton data-testid="search-back" onClick={onBack} />}
          {title}
        </SearchHeaderTitleWrapper>
        <SearchBoxWrapper data-testid="search-box">
          <StyledSearchBox filterTerm={query || ''} placeholder="" onClear={onClear} onSearchChange={setQuery} />
          <Button disabled={isSearchButtonDisabled} size="S" onClick={() => onSearch(query)}>
            Search
          </Button>
        </SearchBoxWrapper>
      </SearchHeaderWrapper>
      {filtersArea}
    </StyledSearchHeader>
  );
};
