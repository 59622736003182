import { CourseSliceInterface } from '@common/interfaces/slices/CourseSliceInterface';
import { LocalizationInterface } from '@common/interfaces/localization/LocalizationInterface';
import { FormikValueInterface, FormikValuesInterface } from '@helpers/formikInitialValuesHelper';

export const updateTextField = (
  clonedState: CourseSliceInterface,
  values: FormikValuesInterface,
  visitedBranch: string,
  bundleName?: string,
) => {
  let state = clonedState;
  const fieldName = bundleName ? `${bundleName}_${visitedBranch}` : visitedBranch;
  if (bundleName) {
    if (
      (values as any)[`${fieldName}Changed`] &&
      state.loadedExercise.exercise.content[bundleName] &&
      state.loadedExercise.exercise.content[bundleName][visitedBranch]?.textLocalizations
    ) {
      if (state.loadedExercise.exercise.content[bundleName][visitedBranch].isReused) {
        state.loadedExercise.exercise.content[bundleName][visitedBranch].isReused = false;
      }
      state.loadedExercise.exercise.content[bundleName][visitedBranch].textLocalizations =
        state.loadedExercise.exercise.content[bundleName][visitedBranch]?.textLocalizations.map(
          (loc: LocalizationInterface) => {
            return {
              ...loc,
              value: (values as any)[fieldName]
                .find((value: FormikValueInterface) => value.language === loc.language && !value.isPhonetic)
                .value.replaceAll('&nbsp;', ' '),
              phoneticValue:
                (values as any)[fieldName].find(
                  (value: FormikValueInterface) => value.language === loc.language && value.isPhonetic,
                ).value || '',
            };
          },
        );
    }
  } else {
    if (
      (values as any)[`${fieldName}Changed`] &&
      state.loadedExercise.exercise.content[visitedBranch]?.textLocalizations
    ) {
      if (state.loadedExercise.exercise.content[visitedBranch].isReused) {
        state.loadedExercise.exercise.content[visitedBranch].isReused = false;
      }
      state.loadedExercise.exercise.content[visitedBranch].textLocalizations = state.loadedExercise.exercise.content[
        visitedBranch
      ]?.textLocalizations.map((loc: LocalizationInterface) => {
        return {
          ...loc,
          value: (values as any)[fieldName]
            .find((value: FormikValueInterface) => value.language === loc.language && !value.isPhonetic)
            .value.replaceAll('&nbsp;', ' '),
          phoneticValue:
            (values as any)[fieldName].find(
              (value: FormikValueInterface) => value.language === loc.language && value.isPhonetic,
            ).value || '',
        };
      });
    }
  }
  return state;
};
