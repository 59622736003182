import produce from 'immer';
import { PayloadAction } from '@reduxjs/toolkit';

import { UISliceInterface } from '@common/interfaces/slices/UISliceInterface';
import { UploadCommonActions_UPLOAD_FAIL } from '@sagas/mediaUpload/definitions/MediaUploadSagasDefinition';
import { UploadAudiosActions } from '@actions/UploadAudiosActions';
import { UploadAudiosSuccessPayload } from '@features/audioManagement';

const UploadAudiosUIReducers = {
  [UploadAudiosActions.UPLOAD_SUCCESS]: (
    state: UISliceInterface,
    { type, payload: { notMatchedAudioFiles } }: PayloadAction<UploadAudiosSuccessPayload>,
  ): UISliceInterface =>
    produce(state, (draft) => {
      draft.panels.uploadAudios.phase = 'done';
      draft.panels.uploadAudios.payloadText = undefined;
      draft.panels.uploadAudios.notMatchedAudioFiles = notMatchedAudioFiles;
    }),
  [UploadAudiosActions.UPLOAD_FAIL]: (
    state: UISliceInterface,
    { type, payload: { payloadText, errors } }: PayloadAction<UploadCommonActions_UPLOAD_FAIL>,
  ): UISliceInterface =>
    produce(state, (draft) => {
      draft.panels.uploadAudios.phase = 'start';
      draft.panels.uploadAudios.payloadText = payloadText;
      draft.panels.uploadAudios.errors = errors;
      draft.panels.uploadAudios.notMatchedAudioFiles = [];
    }),
  [UploadAudiosActions.UPLOAD]: (state: UISliceInterface): UISliceInterface =>
    produce(state, (draft) => {
      draft.panels.uploadAudios.phase = 'progress';
      draft.panels.uploadAudios.payloadText = undefined;
      draft.panels.uploadAudios.errors = [];
      draft.panels.uploadAudios.notMatchedAudioFiles = [];
    }),
  [UploadAudiosActions.CLOSE]: (state: UISliceInterface): UISliceInterface =>
    produce(state, (draft) => {
      draft.panels.uploadAudios.phase = 'start';
      draft.panels.uploadAudios.payloadText = undefined;
      draft.panels.uploadAudios.errors = [];
      draft.panels.uploadAudios.notMatchedAudioFiles = [];
    }),
};

export default UploadAudiosUIReducers;
