import { startCase } from 'lodash';
import { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { components, MultiValue, MultiValueGenericProps } from 'react-select';

import { Flag } from '@components/Flag';

import { CommonFilterOptionType } from '../../types';
import { TRANSLATION_SELECTOR_GROUP } from '../constants';
import { getLanguagesByGroup } from '../util';
import { Language } from '@features/content/languages';

const INDICATORS_AREA_WIDTH = {
  MENU_CLOSED: '3.2rem',
  MENU_OPEN: '4rem',
};

const GroupValueWrapper = styled.div`
  align-items: center;
  display: inline-flex;
  margin-left: 0.4rem;
`;

const StyledFlag = styled(Flag)<{ isMenuOpen: boolean }>`
  display: inline-block;
  font-size: 0;
  margin-left: 0.4rem;

  .flag__more-countries-circle {
    color: ${({ theme }) => theme.color.deepBlue};
  }
`;

const ValueContainerInnerWrapper = styled.div<{ isMenuOpen: boolean; rule: string }>`
  align-items: center;
  color: ${({ theme, isMenuOpen }) => (isMenuOpen ? theme.colorV2.textLight : 'inherit')};
  display: none;
  flex: 1;
  flex-direction: row;
  max-width: calc(
    100% - ${({ isMenuOpen }) => (isMenuOpen ? INDICATORS_AREA_WIDTH.MENU_OPEN : INDICATORS_AREA_WIDTH.MENU_CLOSED)}
  );
  overflow: hidden;
  padding-left: 0.8rem;

  ::before {
    content: '${({ rule }) => rule}: ';
    display: inline;
  }

  :first-child {
    display: flex;
  },
`;

const MAX_FLAGS_TO_SHOW = 5;

export const TranslationSelectorValueContainer = (
  props: MultiValueGenericProps<MultiValue<CommonFilterOptionType>> & { rule: string },
) => {
  const { selectProps } = props;
  const { value } = selectProps;

  const [withLangs, setWithLangs] = useState<Language[]>([]);
  const [withoutLangs, setWithoutLangs] = useState<Language[]>([]);

  useEffect(() => {
    if (value && value.length) {
      const newWithLangs = getLanguagesByGroup(
        value as MultiValue<CommonFilterOptionType>,
        TRANSLATION_SELECTOR_GROUP.WITH,
      ).slice(0, MAX_FLAGS_TO_SHOW) as Language[];

      const newWithoutLangs = getLanguagesByGroup(
        value as MultiValue<CommonFilterOptionType>,
        TRANSLATION_SELECTOR_GROUP.WITHOUT,
      ).slice(0, MAX_FLAGS_TO_SHOW) as Language[];

      setWithLangs(() => (newWithLangs.length ? newWithLangs : []));
      setWithoutLangs(() => (newWithoutLangs.length ? newWithoutLangs : []));
    }
  }, [value]);

  return (
    <>
      {value && !!value.length ? (
        <ValueContainerInnerWrapper isMenuOpen={selectProps.menuIsOpen} rule={props.rule}>
          {!!withLangs.length && (
            <GroupValueWrapper>
              {startCase(TRANSLATION_SELECTOR_GROUP.WITH)}
              <StyledFlag isMenuOpen={selectProps.menuIsOpen} countries={withLangs} tooltipEnabled={false} />
            </GroupValueWrapper>
          )}
          {!!withoutLangs.length && (
            <GroupValueWrapper>
              {startCase(TRANSLATION_SELECTOR_GROUP.WITHOUT)}
              <StyledFlag isMenuOpen={selectProps.menuIsOpen} countries={withoutLangs} tooltipEnabled={false} />
            </GroupValueWrapper>
          )}
        </ValueContainerInnerWrapper>
      ) : (
        <components.MultiValueContainer {...props} />
      )}
    </>
  );
};
