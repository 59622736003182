import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { GrammarReviewActionCreators } from '@actionCreators/GrammarReviewActionCreators';
import { getUrlToNavigate } from '@helpers/contentCardHelper';
import { DBId } from '@common/types/DBId';
import { CardPendingStatus } from '@components/ContentTypes/BaseContent/CardPendingStatus';
import { RemoveCardButton } from '@components/ContentTypes/BaseContent/RemoveCardButton';
import { CardPublishingStatus } from '@components/ContentTypes/BaseContent/CardPublishingStatus';
import { CardValidationMark } from '@components/ContentTypes/BaseContent/CardValidationMark';
import { CardDragHandler } from '@components/ContentTypes/BaseContent/CardDragHandler';
import { RemoveModal } from '@components/ContentTypes/BaseContent/RemoveModal';
import { GrammarCategoryListItemType } from '../GrammarReview';
import { DragAndDropType } from '@components/DraggableList/DragAndDropType';
import { PublishCardButton } from './PublishCardButton';
import {
  CardControllers,
  CardDataWrapper,
  CardWrapper,
  Data,
  DataWrapper,
  Description,
  Title,
} from '@components/ContentTypes/card.styles';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { selectNewContentElementId } from '@selectors/CoursesSelectors';

type GrammarCategoryCardProps = {
  content: GrammarCategoryListItemType;
  idx: number | null;
  dndProps?: DragAndDropType;
};

export const GrammarCategoryCard = ({ content, idx, dndProps }: GrammarCategoryCardProps) => {
  const dispatch = useAppDispatch();
  const params = useParams<{ courseId: DBId }>();
  const { courseId } = params;
  const [hovered, setHovered] = useState(false);
  const [isRemoveModalOpened, setIsRemoveModalOpened] = useState(false);
  const { id, type, changeStatus, ready, title, validationStatus, description } = content;

  const categoryTitle = title || 'Grammar Category';
  const categoryDescription = description || 'Category description';

  const newElementId = useAppSelector(selectNewContentElementId);

  const handleRemove = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsRemoveModalOpened(true);
  };

  return (
    <CardWrapper id={`category-${id}`} onMouseOver={() => setHovered(true)} onMouseOut={() => setHovered(false)}>
      <RemoveModal
        opened={isRemoveModalOpened}
        onHide={() => setIsRemoveModalOpened(false)}
        onDelete={() => {
          dispatch(GrammarReviewActionCreators.removeCategory(courseId, content.id));
        }}
      />
      <CardDragHandler dndProps={dndProps} visible={hovered} />
      <CardDataWrapper
        to={getUrlToNavigate(params, id, type)}
        $active={dndProps?.snapshot?.isDragging || id === newElementId}
        $hovered={hovered}
      >
        <CardPublishingStatus hasPendingChanges={!!changeStatus?.hasPendingChanges} ready={!!ready} />
        <DataWrapper>
          <Data>
            <CardPendingStatus content={content} />
            <Title title={categoryTitle}>
              {ready && idx !== null ? `${idx + 1} - ` : ''}
              {categoryTitle}
              <CardValidationMark isInvalid={!validationStatus?.valid} />
            </Title>
            <Description title={categoryDescription}>{categoryDescription}</Description>
          </Data>
          <CardControllers>
            <PublishCardButton hovered={hovered} content={content} parentId={courseId} />
            <RemoveCardButton hovered={hovered} content={content} onRemove={handleRemove} />
          </CardControllers>
        </DataWrapper>
      </CardDataWrapper>
    </CardWrapper>
  );
};
