import { useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import classnames from 'classnames';
import styled from 'styled-components';
import { ReuseDataInterface } from '@common/interfaces/contentTypes/ReuseDataInterface';
import ChevronRightIcon from '@static/svg/translationsPanel/Chevron Right.svg';
import { TranslationTipActionsCreator } from '@actionCreators/TranslationTipActionsCreator';
import { GrammarReviewActionCreators } from '@actionCreators/GrammarReviewActionCreators';
import { GrammarCategoryEditableFieldNames } from '@features/content/grammar';
import { Loader } from '@features/theme';
import { useIsEditAvailable } from '@features/content/courses';
import './StringReuseModal.scss';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { ContentTypes } from '@common/enums/ContentTypes';
import { selectStringReuseModal } from '@selectors/CoursesSelectors';
import { selectSearchPanelColumn, selectSearchPanelRow } from '@selectors/UiSelectors';

const LoaderContainer = styled.div`
  margin-bottom: 3rem;
`;

export const StringReuseModal = () => {
  const dispatch = useAppDispatch();
  const currentReuseData: ReuseDataInterface = useAppSelector(selectStringReuseModal);
  const row: number | undefined = useAppSelector(selectSearchPanelRow);
  const column: number | undefined = useAppSelector(selectSearchPanelColumn);

  const {
    stringReuseModalOpenedFor,
    contentType,
    visitedBranch,
    bundleName,
    isStringChangeBlocked,
    isBundleChangeBlocked,
  } = currentReuseData;

  const { isEditAvailable, coursesWithoutPermissions } = useIsEditAvailable(visitedBranch);

  useEffect(() => {
    dispatch(TranslationTipActionsCreator.checkOtherLocations());
  }, [dispatch]);

  const handleClose = () => {
    dispatch(TranslationTipActionsCreator.setCurrentContentId('', '', '', 0, 0, '', false));
  };

  const makeCopy = () => {
    if (contentType === ContentTypes.grammarCategory || contentType === ContentTypes.grammarTopic) {
      dispatch(
        GrammarReviewActionCreators.makeStringCopy(
          contentType,
          visitedBranch as GrammarCategoryEditableFieldNames | GrammarCategoryEditableFieldNames,
        ),
      );
    } else {
      dispatch(
        TranslationTipActionsCreator.makeCopy(
          contentType || '',
          visitedBranch || '',
          row || 0,
          column || 0,
          bundleName,
          isStringChangeBlocked,
          isBundleChangeBlocked,
        ),
      );
    }
  };

  const editEverywhere = () => {
    dispatch(
      TranslationTipActionsCreator.editEverywhere(
        contentType || '',
        visitedBranch || '',
        stringReuseModalOpenedFor || '',
        row || 0,
        column || 0,
        bundleName,
        isStringChangeBlocked,
        isBundleChangeBlocked,
      ),
    );

    handleClose();
  };

  return (
    <Modal show={!!stringReuseModalOpenedFor} dialogClassName={'string-reuse-modal'} centered onHide={handleClose}>
      <Modal.Body>
        <ReactTooltip className="tooltip" id="edit-everywhere-button-tooltip" effect="solid" place="bottom" />
        <span className="string-reuse-modal__title">Are you sure you want to edit this?</span>
        <span className="string-reuse-modal__description">
          This content is shared in the places listed below. By editing, you will change this content everywhere it is
          being used.
        </span>
        {currentReuseData?.otherPlaces?.contentMappings && currentReuseData?.otherPlaces?.contentMappings.length ? (
          <div className="string-reuse-modal__other-locations">
            {currentReuseData.otherPlaces.contentMappings.map((place, placeIndex) => {
              return (
                <div className="string-reuse-modal__other-locations__location" key={placeIndex}>
                  {place.map((location: any, index: number) => (
                    <span className="string-reuse-modal__other-locations__location__name" key={index}>
                      {location.label || 'Resource Bundle'}{' '}
                      {index !== place.length - 1 ? (
                        <>
                          <img src={ChevronRightIcon} alt="" />{' '}
                        </>
                      ) : null}
                    </span>
                  ))}
                </div>
              );
            })}
          </div>
        ) : (
          <LoaderContainer>
            <Loader size="L" />
          </LoaderContainer>
        )}
        <div className="string-reuse-modal__buttons">
          <Button
            onClick={makeCopy}
            className={classnames('string-reuse-modal__buttons__button', 'string-reuse-modal__buttons__copy')}
          >
            Make a copy
          </Button>
          <Button
            variant="light"
            onClick={() => isEditAvailable && editEverywhere()}
            data-for="edit-everywhere-button-tooltip"
            data-tip={
              !isEditAvailable
                ? `You cannot edit this content because you don’t have permission to edit courses (${coursesWithoutPermissions.join(
                    ', ',
                  )}), where this content is reused.`
                : ''
            }
            className={classnames('string-reuse-modal__buttons__button', 'string-reuse-modal__buttons__edit', {
              'string-reuse-modal__buttons__edit--disabled':
                (currentReuseData?.otherPlaces?.contentMappings &&
                  !currentReuseData?.otherPlaces?.contentMappings.length) ||
                !isEditAvailable,
            })}
          >
            Edit everywhere
          </Button>
          <Button
            variant="light"
            onClick={handleClose}
            className={classnames('string-reuse-modal__buttons__button', 'string-reuse-modal__buttons__cancel')}
          >
            Cancel
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
