import { FunctionComponent, useMemo } from 'react';
import { useFormikContext } from 'formik';

import { ContentTypes } from '@common/enums/ContentTypes';
import { TranslationsPanelContentInterface } from '@common/interfaces/exercises/TranslationsPanelContentInterface';
import { ScriptLine } from '@components/Exercises/Dialogue/types/ScriptLine';
import { FormikValuesInterface } from '@helpers/formikInitialValuesHelper';
import { PreviewModalFeedbackSwitcherProps } from '../PreviewModalProps';

import { DialogueMissedWords, IncorrectFeedback } from './PreviewContentByType';
import { closedKTag, getCorrectPhrase, getValueByLanguage, openKTag } from '../../../localization-utils';
import { useAppSelector } from '@redux/store';
import { selectLearningLanguage } from '@selectors/CoursesSelectors';

export const PreviewModalFeedbackSwitcher: FunctionComponent<PreviewModalFeedbackSwitcherProps> = ({
  type,
  content,
  deviceType,
  answerState,
  isForList,
}: PreviewModalFeedbackSwitcherProps) => {
  const { values }: { values: FormikValuesInterface } = useFormikContext();
  const isIncorrectSelected = answerState === 2;

  const courseLearningLanguage = useAppSelector(selectLearningLanguage);
  const phrase = useMemo(
    () =>
      getValueByLanguage(
        isForList ? content?.mainBundle?.phrase?.textLocalizations : values?.mainBundle_phrase,
        courseLearningLanguage,
      ),
    [content?.mainBundle?.phrase?.textLocalizations, courseLearningLanguage, isForList, values],
  );
  const correctPhrase = getCorrectPhrase(phrase);

  const FeedbackPreviewByType = useMemo(() => {
    switch (type) {
      case ContentTypes.multipleChoice:
        const answerText =
          content.choicesLanguage === 'learning'
            ? getValueByLanguage(
                isForList ? content?.answerBundle?.phrase?.textLocalizations : values?.answerBundle_phrase,
                courseLearningLanguage,
              )
            : getValueByLanguage(
                isForList ? content?.answerBundle?.phrase?.textLocalizations : values?.answerBundle_phrase,
              );
        return <>{isIncorrectSelected ? <IncorrectFeedback deviceType={deviceType} text={answerText} /> : null}</>;
      case ContentTypes.phraseBuilder:
      case ContentTypes.spelling:
      case ContentTypes.fillgap:
        return (
          <>
            {isIncorrectSelected ? (
              <IncorrectFeedback deviceType={deviceType} text={correctPhrase} withUnderline={false} />
            ) : null}
          </>
        );
      case ContentTypes.typing:
        const lowerCasePhrase = phrase
          .replaceAll(openKTag.toUpperCase(), openKTag)
          .replaceAll(closedKTag.toUpperCase(), closedKTag);

        const correctText = lowerCasePhrase.substring(
          lowerCasePhrase.indexOf(openKTag) + 3,
          lowerCasePhrase.indexOf(closedKTag),
        );
        return (
          <>
            {isIncorrectSelected ? (
              <IncorrectFeedback deviceType={deviceType} text={correctText} withUnderline={false} />
            ) : null}
          </>
        );
      case ContentTypes.highlighter:
        const correctAnswer = getCorrectPhrase(
          content.phrases
            ?.map((phrase: TranslationsPanelContentInterface) =>
              getValueByLanguage(phrase.textLocalizations, courseLearningLanguage)
                .split(' ')
                .filter((word: string) => word.includes(openKTag) || word.includes(openKTag.toUpperCase()))
                .join(', '),
            )
            .join(', '),
        );

        return <>{isIncorrectSelected ? <IncorrectFeedback deviceType={deviceType} text={correctAnswer} /> : null}</>;
      case ContentTypes.dialogue:
        // All missed words from all dialogue
        const allMissedWords = content.script.reduce((result: string[], phrase: ScriptLine) => {
          let missedWords: string[] = [];
          const lineText = getValueByLanguage(phrase.line.textLocalizations, courseLearningLanguage);
          if (lineText.includes(openKTag)) {
            missedWords = getValueByLanguage(phrase.line.textLocalizations, courseLearningLanguage)
              .replaceAll(openKTag, `!missed!${openKTag}`)
              .split(closedKTag)
              .reduce(
                (result: string[], item: string) =>
                  item.includes('!missed!') ? [...result, ...item.split('!missed!')] : [...result, item],
                [],
              )
              .filter((item: string) => item.includes(openKTag));
          } else {
            lineText
              .replaceAll(openKTag.toUpperCase(), `!missed!${openKTag.toUpperCase()}`)
              .split(closedKTag.toUpperCase())
              .reduce(
                (result: string[], item: string) =>
                  item.includes('!missed!') ? [...result, ...item.split('!missed!')] : [...result, item],
                [],
              )
              .filter((item: string) => item.includes(openKTag.toUpperCase()));
          }
          if (missedWords?.length) {
            return [...result, ...missedWords];
          } else {
            return [...result];
          }
        }, []);

        return (
          <DialogueMissedWords answerState={answerState} deviceType={deviceType} allMissedWords={allMissedWords} />
        );
      default:
        return null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answerState, deviceType, content, type]);

  return FeedbackPreviewByType;
};
