import styled from 'styled-components';

import { HeaderWrapper } from './HeaderWrapper';

const CategoryHeaderWrapper = styled(HeaderWrapper)`
  grid-template-columns: 74% 25%;
`;

export const GrammarCategorySearchResultTypeHeader = () => (
  <CategoryHeaderWrapper>
    <span>Grammar Category</span>
    <span>Location</span>
  </CategoryHeaderWrapper>
);
