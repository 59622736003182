import { apiClient } from '@features/api';
import { AxiosResponse } from 'axios';
import fileDownload from 'js-file-download';
import type { ActivityType } from '@features/content/activities';
import { ACTIVITY_SPEAKING, DEFAULT_ACTIVITY_TYPE } from '@features/content/activities';
import {
  ContentTypes,
  ContentTypesForCreateContent,
  type ContentTypesForCreateContentType,
  type ContentTypesType,
} from '@common/enums/ContentTypes';
import { type ExerciseTypesType } from '@common/enums/ExerciseTypes';
import { LocalizationInterface } from '@common/interfaces/localization/LocalizationInterface';
import { DBId } from '@common/types/DBId';
import {
  mapMediaLocalizationsForSave,
  selectMediaLocalizationsForSave,
  selectTextLocalizationsForSave,
} from '@helpers/localizationSaveHelper';
import CourseAdapterService from './CourseSanitizerService';
import { UploadMediaResponse } from './HelpersService';
import * as POST from './interfaces/POST/Group';
import { Language } from '@features/content/languages';

const ContentsService = {
  contents: {
    async createAndAddUploadedMedia(
      uploadedMediaData: { fileId: string; type?: string; language: string },
      mediaUploadAndContentAssociationKey: any,
    ): Promise<UploadMediaResponse> {
      let mediaURL = uploadedMediaData.fileId;
      const mediaDataLocal = {
        language: uploadedMediaData.language,
        _id: uploadedMediaData.fileId,
      };

      let newContent: any;
      let contentForAPI: any;

      if (mediaUploadAndContentAssociationKey === 'imageLocalizations') {
        newContent = {
          [mediaUploadAndContentAssociationKey]: [mediaDataLocal],
        };
        contentForAPI = {
          [mediaUploadAndContentAssociationKey]: [{ ...uploadedMediaData }],
        };
      } else if (
        mediaUploadAndContentAssociationKey === 'audioLocalizations' ||
        mediaUploadAndContentAssociationKey === 'videoLocalizations'
      ) {
        const { type, ...uploadedMediaDataWithoutType } = uploadedMediaData;
        newContent = {
          [mediaUploadAndContentAssociationKey]: [mediaDataLocal],
        };
        contentForAPI = {
          [mediaUploadAndContentAssociationKey]: [{ ...uploadedMediaDataWithoutType }],
        };
      }

      let newContentID = await ContentsService.contents.createNewContent(contentForAPI);

      const outputInPromise = {
        mediaId: newContentID,
        mediaURL: mediaURL,
        content: newContent,
      };

      return Promise.resolve(outputInPromise);
    },
    async getAllExercisesOfContent(parentId: DBId, courseId: DBId) {
      return apiClient.v1.get(`/exercises?parentId=${parentId}&courseId=${courseId}`).then((data: AxiosResponse) => {
        return CourseAdapterService.sanitizeExercisesFromGroup(data.data.exercises);
      });
    },
    async getRecapOptions(exerciseId: DBId) {
      return apiClient.v2.get(`/content/exercises/${exerciseId}/recap-options`).then((data: AxiosResponse) => {
        return data.data.exercises;
      });
    },
    async updateExistingContentWithUploadedMedia(
      contentId: DBId,
      uploadedMediaData: { fileId: string; type?: string; language: string },
      mediaUploadAndContentAssociationKey: string,
      contentData?: any,
    ): Promise<UploadMediaResponse> {
      let updatedContent;
      let contentForAPI;
      let mediaURL = uploadedMediaData.fileId;
      const mediaDataLocal = {
        language: uploadedMediaData.language,
        _id: uploadedMediaData.fileId,
      };

      if (
        mediaUploadAndContentAssociationKey === 'imageLocalizations' ||
        mediaUploadAndContentAssociationKey === 'videoLocalizations'
      ) {
        updatedContent = {
          ...contentData,
          [mediaUploadAndContentAssociationKey]: [mediaDataLocal],
        };
        contentForAPI = {
          ...contentData,
          [mediaUploadAndContentAssociationKey]: [{ ...uploadedMediaData }],
        };
      } else if (mediaUploadAndContentAssociationKey === 'audioLocalizations') {
        const prevValueForThisLanguage = contentData[mediaUploadAndContentAssociationKey].find(
          (localization: LocalizationInterface) => localization.language === uploadedMediaData.language,
        );

        const mappedImageLocalizations = mapMediaLocalizationsForSave(contentData.imageLocalizations, 'exercise');
        const mappedVideoLocalizations = mapMediaLocalizationsForSave(contentData.videoLocalizations);

        if (prevValueForThisLanguage) {
          updatedContent = {
            ...contentData,
            [mediaUploadAndContentAssociationKey]: contentData[mediaUploadAndContentAssociationKey].map(
              (localization: LocalizationInterface) => {
                if (localization.language === uploadedMediaData.language) {
                  return { ...localization, _id: uploadedMediaData.fileId, fileId: uploadedMediaData.fileId };
                }
                return localization;
              },
            ),
          };
          contentForAPI = {
            ...contentData,
            textLocalizations: selectTextLocalizationsForSave(contentData.textLocalizations),
            imageLocalizations: mappedImageLocalizations,
            videoLocalizations: mappedVideoLocalizations,
            [mediaUploadAndContentAssociationKey]: [
              ...mapMediaLocalizationsForSave(contentData[mediaUploadAndContentAssociationKey]),
              { ...uploadedMediaData },
            ],
          };
        } else {
          updatedContent = {
            ...contentData,
            [mediaUploadAndContentAssociationKey]: [
              ...contentData[mediaUploadAndContentAssociationKey],
              mediaDataLocal,
            ],
          };
          contentForAPI = {
            ...contentData,
            textLocalizations: selectTextLocalizationsForSave(contentData.textLocalizations),
            imageLocalizations: selectMediaLocalizationsForSave(contentData.imageLocalizations),
            videoLocalizations: selectMediaLocalizationsForSave(contentData.videoLocalizations),
            [mediaUploadAndContentAssociationKey]: [
              ...mapMediaLocalizationsForSave(contentData[mediaUploadAndContentAssociationKey]),
              { ...uploadedMediaData },
            ],
          };
        }
      }

      await apiClient.noErrorsV2.put(`content/resources/${contentId}`, contentForAPI);

      const outputInPromise = {
        mediaId: contentId,
        mediaURL: mediaURL,
        content: updatedContent,
      };

      return Promise.resolve(outputInPromise);
    },
    async removeImageFromLesson(contentId: DBId, updatedContent: any) {
      const payload = updatedContent.imageLocalizations.map((imageLocalization: LocalizationInterface) => ({
        ...imageLocalization,
        type: 'lesson',
      }));

      await apiClient.noErrorsV2.put(`content/resources/${contentId}`, payload);
    },
    async getById(id: DBId, courseId: DBId, parentId: DBId, learningLanguage: Language) {
      if (id !== undefined) {
        return apiClient.v1
          .get(`/groups/${id}`, {
            params: {
              parentId,
              courseId,
              language: learningLanguage,
            },
          })
          .then((data) => {
            return CourseAdapterService.sanitizeGenericContent(data.data);
          });
      }
    },
    async update(contentId: DBId | undefined, payload: any) {
      await apiClient.noErrorsV2.put(`content/resources/${contentId}`, payload);
    },
    async updateBundle(contentId: DBId | undefined, payload: any) {
      await apiClient.v2.put(`content/resource-bundles/${contentId}`, payload);
    },
    async updateOrder(payload: { parentId: DBId; parentType: ContentTypesType; groupId: DBId; newIndex: number }) {
      const requestBody = {
        childId: payload.groupId,
        newPosition: payload.newIndex,
      };

      if (payload.parentType === ContentTypes.course) {
        await apiClient.v2.put(`content/courses/${payload.parentId}/group-order`, requestBody);
      } else if (payload.parentType === ContentTypes.activity) {
        await apiClient.v2.put(`content/groups/${payload.parentId}/exercise-order`, requestBody);
      } else {
        await apiClient.v2.put(`content/groups/${payload.parentId}/group-order`, requestBody);
      }
    },
    async createNewContent(payload: any) {
      return await apiClient.v2.post('content/resources', payload).then((data: any) => {
        return data.data.id;
      });
    },
    async createNewBundle(bundleData: {
      phrase?: string;
      example?: string;
      video?: string;
      image?: string;
      isVocabulary: boolean;
    }) {
      return await apiClient.v2.post('content/resource-bundles', bundleData).then((data: any) => {
        return data.data.id;
      });
    },
    createContent(contentPayload: {
      parentId: DBId | undefined;
      typeParentOfContentToCreate: ContentTypesType;
      type: ContentTypesType;
      contentCategory: ActivityType | ExerciseTypesType | ContentTypesForCreateContentType | null;
      position: number;
    }) {
      let { parentId, typeParentOfContentToCreate, type, position, contentCategory } = contentPayload;

      if (type === ContentTypes.exercise) {
        return apiClient.v2.post(`content/exercises`, {
          type: contentCategory,
          parentId: parentId,
          parentType: 'group',
          position: position,
        });
      } else {
        let postPayload: POST.POSTGroup;

        if (type === ContentTypes.activity) {
          const mappedContentCategory =
            contentCategory === ACTIVITY_SPEAKING ? ContentTypesForCreateContent.speaking : contentCategory;

          postPayload = {
            type,
            parentId,
            parentType: typeParentOfContentToCreate,
            position,
            class: mappedContentCategory || DEFAULT_ACTIVITY_TYPE,
          };
        } else if (type === ContentTypes.slidePdf) {
          return apiClient.v2.post(`content/exercises`, {
            type: 'slidePdf',
            parentId: parentId,
            parentType: 'group',
            position: position,
          });
        } else if (type === ContentTypes.slidePptx) {
          return apiClient.v2.post(`content/exercises`, {
            type: 'slidePptx',
            parentId: parentId,
            parentType: 'group',
            position: position,
          });
        } else {
          postPayload = {
            type,
            parentId,
            parentType: typeParentOfContentToCreate,
            position,
            class: contentCategory,
          };
        }

        return apiClient.noErrorsV2.post('/content/groups', postPayload);
      }
    },
    removeLevel(levelId: string, courseId: string) {
      return apiClient.v2.post(`content/courses/${courseId}/detach-group`, {
        childId: levelId,
      });
    },
    removeGroup(groupId: string, parentId: string) {
      return apiClient.v2.post(`content/groups/${parentId}/detach-group`, {
        childId: groupId,
      });
    },
    removeExercise(exerciseId: string, activityId: string) {
      return apiClient.v2.post(`content/groups/${activityId}/detach-exercise`, {
        childId: exerciseId,
      });
    },
  },
  groups: {
    async getGroupsOfContent(parentId: DBId, courseId: DBId, learningLanguage: Language) {
      return apiClient.v1
        .get(`/groups/${parentId}/structure`, {
          params: {
            courseId,
            language: learningLanguage,
          },
        })
        .then((data) => {
          return CourseAdapterService.sanitizeGenericContents(data.data.groups);
        });
    },
  },
  csv: {
    async download(
      contentId: DBId,
      learningLanguage: Language,
      interfaceLanguages: Language[],
      contentName: string,
      contentType: 'grammarTopic' | 'group' | 'placementTest' | 'roleplayCategory',
    ) {
      let normalizedInterfaceLanguages = [...interfaceLanguages];

      if (interfaceLanguages.includes('en_US')) {
        // @TODO Check with backend if this is still needed
        // @ts-ignore
        normalizedInterfaceLanguages.splice(interfaceLanguages.indexOf('en_US'), 1, 'EN_US');
      }

      const response = await apiClient.v2.get('content/resources/translations-request', {
        params: {
          componentId: contentId,
          componentType: contentType,
          learningLanguage: learningLanguage,
          interfaceLanguages: normalizedInterfaceLanguages,
        },
        responseType: 'blob',
      });

      fileDownload(
        response.data,
        `translations_${contentName || 'untitled_content'}.csv`,
        'text/csv;charset=utf-8',
        '\uFEFF',
      );
    },
  },
};

export default ContentsService;
