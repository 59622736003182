import { arrayMoveImmutable } from 'array-move';
import { inRange } from 'lodash';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components/macro';

import { GrammarReviewActionCreators } from '@actionCreators/GrammarReviewActionCreators';
import { ContentTypes } from '@common/enums/ContentTypes';
import { ExerciseType } from '@common/enums/ExerciseTypes';
import { LoadingStage } from '@common/enums/LoadingStage';
import { DBId } from '@common/types/DBId';
import { DraggableList } from '@components/DraggableList/DraggableList';
import { Dropdown } from '@components/Dropdown';
import IDDisplayer from '@components/IDDisplayer/IDDisplayer';
import { PublishingStatus } from '@components/Publishing/PublishingStatus';
import { ValidationErrorDisplayer } from '@components/ValidationErrorDisplayer';
import { AccessWarning } from '@components/Warning';
import { WritableInputText } from '@components/WritableInputText';
import { useIsEditAvailable } from '@features/content/courses';
import { BulkPublishingWrapper } from '@features/content/exercises';
import { Loader } from '@features/theme';
import { clone } from '@helpers/clone';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { selectGrammarTopic } from '@selectors/GrammarSelectors';
import helpersService from '@services/HelpersService';
import { userTracking } from '@features/app/tracking';

import { ExercisesWarning } from './ExercisesWarning';
import { LinkedLessonsButton } from './LinkedLessons';
import { TranslationsTipWrapper } from '../TranslationsTip';

const DEFAULT_DROPDOWN_VALUE = '1';
const EXERCISES_MIN_COUNT = 50;
const LEVEL_TYPES = ['a1', 'a2', 'b1', 'b2', 'c1'];
const TYPING_EXERCISES_MAX_PERCENTAGE = 40;
const TYPING_EXERCISES_MIN_PERCENTAGE = 30;

const ChildrenListTitle = styled.span`
  margin-left: 15px;
  font-weight: 700;
  font-size: 14px;
  color: ${({ theme }) => theme.colorV2.grammarTopicChildrenTitleText};
`;
const ChildrenListsContainer = styled.div`
  margin-top: 30px;
`;

const CefrAndValidationErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MIN_EXERCISES_NUM_TO_TRACK = 2;

const TypingExercisesWarningContent = ({ percentage }: { percentage: number }) => {
  const exceedsMaxThreshold = percentage > TYPING_EXERCISES_MAX_PERCENTAGE;
  const threshold = exceedsMaxThreshold ? TYPING_EXERCISES_MAX_PERCENTAGE : TYPING_EXERCISES_MIN_PERCENTAGE;

  return (
    <>
      Typing exercises are {exceedsMaxThreshold ? 'more' : 'less'} than <strong>{threshold}%</strong> of the total
      gradable exercises (currently {percentage}%)
    </>
  );
};

export const GrammarTopicData = () => {
  const dispatch = useAppDispatch();
  const { isEditAvailable } = useIsEditAvailable();
  const { courseId, topicId } = useParams<{ courseId: DBId; topicId: DBId }>();

  const grammarTopic = useAppSelector(selectGrammarTopic);
  const { content, exercisesData } = grammarTopic;

  const titleValidationErrors = content?.validationStatus?.errors.filter((error) => error.field === 'title');
  const descriptionValidationErrors = content?.validationStatus?.errors.filter(
    (error) => error.field === 'description',
  );
  const cefrValidationErrors = content?.validationStatus?.errors.filter((error) => error.field === 'cefr') || [];
  const referenceExercisesValidationErrors =
    content?.validationStatus?.errors.filter((error) => error.field === 'referenceExercises') || [];
  const exercisesBankValidationErrors =
    content?.validationStatus?.errors.filter((error) => error.field === 'exercisesBank') || [];
  const lessonsValidationErrors = content?.validationStatus?.errors.filter((error) => error.field === 'lessons') || [];

  const levelTypes = LEVEL_TYPES.map((item) => ({ code: item, name: item }));
  const tipExercises = grammarTopic.exercisesData.exercises.referenceExercises;
  const gradableExercises = grammarTopic.exercisesData.exercises.bankExercises;

  const [exercisesCustomWarnings, setExercisesCustomWarnings] = useState<JSX.Element[]>([]);

  useEffect(() => {
    if (grammarTopic.exercisesData.loaded === LoadingStage.loaded) {
      const exercisesTotalCount = gradableExercises.length + tipExercises.length;
      const typingExercisesCount = gradableExercises.filter((exercise) => exercise.type === ContentTypes.typing).length;
      const typingExercisesPercentage = Math.floor((typingExercisesCount / exercisesTotalCount) * 100);
      const warningsValue = [];

      if (exercisesTotalCount < EXERCISES_MIN_COUNT) {
        warningsValue.push(
          <>
            Grammar Topic should contain at least <strong>{EXERCISES_MIN_COUNT}</strong> exercises (currently&nbsp;
            {exercisesTotalCount})
          </>,
        );
      }

      // Is the existing Typing exercises percentage out of the recommended thresholds?
      if (!inRange(typingExercisesPercentage, TYPING_EXERCISES_MIN_PERCENTAGE, TYPING_EXERCISES_MAX_PERCENTAGE)) {
        warningsValue.push(<TypingExercisesWarningContent percentage={typingExercisesPercentage} />);
      }

      setExercisesCustomWarnings(warningsValue);
    }
  }, [gradableExercises, grammarTopic, tipExercises]);

  return (
    <>
      <div className="course-composer">
        <div className="course-edition__first-top-element">
          <div className="course-edition__publish-status-and-other-places-container">
            <PublishingStatus
              ready={content ? content.ready : false}
              changeStatus={
                content
                  ? content.changeStatus
                  : {
                      hasNewChanges: false,
                      hasPendingChanges: false,
                    }
              }
            />
            <LinkedLessonsButton />
          </div>
          <ValidationErrorDisplayer text={helpersService.getValidationErrorMessageText(lessonsValidationErrors)} />
          <Row style={{ padding: '0', margin: '0' }}>
            <Col style={{ padding: '0', margin: '0' }}>
              <div>
                <IDDisplayer id={content?.id} mode="normal" />
                <TranslationsTipWrapper
                  type={ContentTypes.grammarTopic}
                  fieldName={'title'}
                  content={content.title}
                  errors={titleValidationErrors}
                  className="content-name"
                  defaultContextForTranslators="Grammar review topic"
                >
                  <WritableInputText
                    fontSize="30"
                    bold
                    withoutBorder
                    placeholder="Grammar Topic"
                    id="category-title-input"
                  />
                </TranslationsTipWrapper>
                <TranslationsTipWrapper
                  type={ContentTypes.grammarTopic}
                  fieldName={'description'}
                  content={content.description}
                  errors={descriptionValidationErrors}
                  className="content-name"
                  defaultContextForTranslators="Grammar topic description"
                >
                  <WritableInputText
                    withGreyColor
                    withoutBorder
                    placeholder="Topic Description"
                    id="category-description-input"
                  />
                </TranslationsTipWrapper>
              </div>
            </Col>

            <Col sm={4} className="course-edition__file-upload">
              <CefrAndValidationErrorContainer>
                <Dropdown
                  values={levelTypes}
                  title="Topic type"
                  disabled={!isEditAvailable}
                  onChange={(e) => dispatch(GrammarReviewActionCreators.updateCefr(courseId, topicId, e.code))}
                  value={
                    levelTypes.find((type) => type.name === grammarTopic.content.cefr)?.code || DEFAULT_DROPDOWN_VALUE
                  }
                  className="level-content__type-selector"
                />
                <ValidationErrorDisplayer text={helpersService.getValidationErrorMessageText(cefrValidationErrors)} />
              </CefrAndValidationErrorContainer>
            </Col>
          </Row>
          {!isEditAvailable && <AccessWarning />}
        </div>

        {exercisesData.loaded !== LoadingStage.loaded ? (
          <Loader size="L" />
        ) : (
          <BulkPublishingWrapper
            items={[...tipExercises, ...gradableExercises]}
            parentId={topicId}
            parentType={ContentTypes.grammarTopic}
          >
            <ChildrenListsContainer>
              {!!exercisesCustomWarnings.length && <ExercisesWarning warnings={exercisesCustomWarnings} />}
              <ChildrenListTitle>GRAMMAR TIPS</ChildrenListTitle>
              <DraggableList
                parentType={ContentTypes.grammarTopic}
                arrayOfChildren={tipExercises}
                onDragEnd={(result) => {
                  result.destination &&
                    dispatch(
                      GrammarReviewActionCreators.setReferenceExercisesOrder(
                        arrayMoveImmutable(clone(tipExercises), result.source.index, result.destination.index),
                        result.source.index,
                        result.destination.index,
                        ContentTypes.grammarTopic,
                        topicId,
                        courseId,
                      ),
                    );
                }}
                onContentCreate={(contentTypeToCreate, contentCategory, position, numItemsToCreate = 1) => {
                  dispatch(
                    GrammarReviewActionCreators.createReferenceExercise(courseId, topicId, position, numItemsToCreate),
                  );

                  if (numItemsToCreate >= MIN_EXERCISES_NUM_TO_TRACK) {
                    userTracking.logosGrammarExercisesAdded({
                      grammar_exercises_num: numItemsToCreate,
                    });
                  }
                }}
                creatorAcceptableButtons={{
                  onlyTip: true,
                }}
              />
              <ValidationErrorDisplayer
                text={helpersService.getValidationErrorMessageText(referenceExercisesValidationErrors)}
              />
              <ChildrenListTitle>GRAMMAR EXERCISES</ChildrenListTitle>
              <DraggableList
                parentType={ContentTypes.grammarTopic}
                arrayOfChildren={gradableExercises}
                onDragEnd={(result) => {
                  result.destination &&
                    dispatch(
                      GrammarReviewActionCreators.setGrammarExercisesOrder(
                        arrayMoveImmutable(clone(gradableExercises), result.source.index, result.destination.index),
                        result.source.index,
                        result.destination.index,
                        ContentTypes.grammarTopic,
                        topicId,
                        courseId,
                      ),
                    );
                }}
                onContentCreate={(contentTypeToCreate, contentCategory, position, numItemsToCreate = 1) => {
                  dispatch(
                    GrammarReviewActionCreators.createGrammarExercise(
                      courseId,
                      topicId,
                      contentCategory as ExerciseType,
                      position,
                      numItemsToCreate,
                    ),
                  );

                  if (numItemsToCreate >= MIN_EXERCISES_NUM_TO_TRACK) {
                    userTracking.logosGrammarExercisesAdded({
                      grammar_exercises_num: numItemsToCreate,
                    });
                  }
                }}
                creatorAcceptableButtons={{
                  onlyGradable: true,
                }}
              />
              <ValidationErrorDisplayer
                text={helpersService.getValidationErrorMessageText(exercisesBankValidationErrors)}
              />
            </ChildrenListsContainer>
          </BulkPublishingWrapper>
        )}
      </div>
    </>
  );
};
