import { useState } from 'react';
import styled from 'styled-components';
import { Loader, Button } from '@features/theme';
import trashIcon from './trash.svg';

import { IdentityGroupType } from '../types';
import { deleteIdentityGroup } from '../ProgressMatchingService';

const Icon = styled.img`
  margin-bottom: 2.8rem;
`;

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 68rem;
  align-items: center;
`;

const ModalTitle = styled.span`
  color: ${({ theme }) => theme.color.brandBlack};
  font-weight: 800;
  font-size: 2rem;
  margin-bottom: 0.8rem;
  text-align: center;
`;

const ButtonsArea = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
`;

const DeleteTitle = styled(ModalTitle)`
  width: 55%;
`;

const ModalDescription = styled.span`
  color: ${(props) => props.theme.color.brandGreyscale800};
  font-size: 1.6rem;
  margin-bottom: 3.2rem;
  text-align: center;
`;

type IdentityGroupDeleteModalProps = {
  identityGroup: IdentityGroupType;
  onChange: () => void;
  onClose: () => void;
};

export const IdentityGroupDeleteModal = ({ identityGroup, onChange, onClose }: IdentityGroupDeleteModalProps) => {
  const [isDeleteInProgress, setIsDeleteInProgress] = useState(false);

  return (
    <ModalWrapper>
      <Icon src={trashIcon} />
      <DeleteTitle>Are you sure you want to remove this item?</DeleteTitle>
      <ModalDescription>You can't undo this action</ModalDescription>
      {isDeleteInProgress ? (
        <Loader />
      ) : (
        <ButtonsArea>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              setIsDeleteInProgress(true);
              deleteIdentityGroup(identityGroup.id).then((result) => {
                if (result.status === 200) {
                  setIsDeleteInProgress(false);
                  onChange();
                  onClose();
                }
              });
            }}
          >
            Delete
          </Button>
        </ButtonsArea>
      )}
    </ModalWrapper>
  );
};
