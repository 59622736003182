const SHOW_SEARCH_PANEL_V2 = 'SHOW_SEARCH_PANEL_V2';
const HIDE_SEARCH_PANEL_V2 = 'HIDE_SEARCH_PANEL_V2';

const USE_LESSON_V2 = 'USE_LESSON_V2';
const COPY_LESSON_V2 = 'COPY_LESSON_V2';
const USE_ACTIVITY_V2 = 'USE_ACTIVITY_V2';
const COPY_ACTIVITY_V2 = 'COPY_ACTIVITY_V2';
const USE_EXERCISE_V2 = 'USE_EXERCISE_V2';
const COPY_EXERCISE_V2 = 'COPY_EXERCISE_V2';
const USE_BUNDLE_V2 = 'USE_BUNDLE_V2';
const COPY_BUNDLE_V2 = 'COPY_BUNDLE_V2';
const USE_STRING_V2 = 'USE_STRING_V2';
const COPY_STRING_V2 = 'COPY_STRING_V2';

const USE_STRING_SUCCEEDED = 'USE_STRING_SUCCEEDED';
const SET_REUSE_IN_PROGRESS_V2 = 'SET_REUSE_IN_PROGRESS_V2';

const RESET_SEARCH_FILTERS = 'RESET_SEARCH_FILTERS';

export const SearchActions = {
  SHOW_SEARCH_PANEL_V2,
  HIDE_SEARCH_PANEL_V2,
  USE_LESSON_V2,
  COPY_LESSON_V2,
  USE_ACTIVITY_V2,
  COPY_ACTIVITY_V2,
  USE_EXERCISE_V2,
  COPY_EXERCISE_V2,
  USE_BUNDLE_V2,
  COPY_BUNDLE_V2,
  USE_STRING_V2,
  COPY_STRING_V2,
  USE_STRING_SUCCEEDED,
  SET_REUSE_IN_PROGRESS_V2,
  RESET_SEARCH_FILTERS,
} as const;
