import styled from 'styled-components';

export const HeaderWrapper = styled.div`
  padding: 0 3.2rem 1rem 3.2rem;
  display: grid;
  grid-column-gap: 1%;
  font-weight: 700;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.color.deepBlue65};
`;
