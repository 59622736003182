import { ActionCreator, createSlice } from '@reduxjs/toolkit';
import initialState from '@redux/initialStates/uiInitialState';
import CourseEditionUIReducers from '@redux/reducers/uiSlice/courseEdition/CourseEditionUIReducers';
import DialogueUIReducers from '@redux/reducers/uiSlice/exercises/DialogueUIReducers';
import { NewSearchUIReducers } from '@redux/reducers/uiSlice/panels/search/NewSearchUIReducers';
import SidePanelUIReducers from '@redux/reducers/uiSlice/panels/sidePanel/SidePanelUIReducers';
import TranslationsPanelUIReducers from '@redux/reducers/uiSlice/panels/translationsPanel/TranslationsPanelUIReducers';
import PublishingQueueUIReducers from '@redux/reducers/uiSlice/panels/publishingQueue/PublishingQueueUIReducers';
import UploadAudiosUIReducers from '@redux/reducers/uiSlice/panels/uploadAudios/UploadAudiosUIReducers';
import UploadTranslationsUIReducers from '@redux/reducers/uiSlice/panels/uploadTranslations/UploadTranslationsUIReducers';
import ExercisePreviewUIReducers from '@redux/reducers/uiSlice/preview/ExercisePreviewUIReducers';

const extraReducers = {
  ...CourseEditionUIReducers,
  ...DialogueUIReducers,
  ...NewSearchUIReducers,
  ...SidePanelUIReducers,
  ...TranslationsPanelUIReducers,
  ...PublishingQueueUIReducers,
  ...UploadAudiosUIReducers,
  ...UploadTranslationsUIReducers,
  ...ExercisePreviewUIReducers,
} as Record<string, ActionCreator<any>>;

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    Object.keys(extraReducers).forEach((actionType) => {
      builder.addCase(actionType, extraReducers[actionType]);
    });
  },
});
