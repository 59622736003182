import Avatar from 'react-avatar';
import styled from 'styled-components/macro';

import { useUser } from '@features/auth';
import { Modal, useDialogModal } from '@features/modal';
import { ContextualMenu } from '@features/theme';

import { DEFAULT_CONTENT_DATES_VALUE } from '../constants';
import { ReactComponent as ContextualMenuIcon } from './_img/menu-toggler.svg';
import { ReactComponent as AssignIcon } from './_img/assign-icon.svg';
import { AssignOwnerModalContent } from './AssignOwnerModalContent';
import { ContentDatesType, ContentOwnerProps } from './types';

const ContentOwnerWrapper = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colorV2.uiBackgroundPrimary};
  display: flex;
  gap: 0.8rem;
  height: 3.8rem;
  justify-content: flex-end;
  width: fit-content;

  .dropdown-toggle {
    border-radius: 0.4rem;
    transform: scale(1.2);

    &[aria-expanded='true'] {
      background-color: ${({ theme }) => theme.colorV2.uiBackgroundSecondary} !important;
    }

    svg path {
      fill: ${({ theme }) => theme.colorV2.textSecondary};
    }
  }
`;

const ContentOwnerData = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContentOwnerText = styled.div`
  color: ${({ theme }) => theme.colorV2.textTertiary};
  font-size: 1.2rem;
  line-height: 120%;
  margin-bottom: 0.4rem;
  text-align: right;
`;

const ContentDateText = styled(ContentOwnerText)`
  font-size: 1rem;
  margin-bottom: 0;
`;

const ContextualMenuOption = styled.div`
  display: flex;
  gap: 0.8rem;
`;

const ContextualMenuTriggerWrapper = styled.div`
  border-radius: 0.4rem;
  cursor: pointer;
  height: 2.4rem;
  width: 2.4rem;

  svg path {
    fill: ${({ theme }) => theme.colorV2.textSecondary};
  }
`;

const formatDate = (dates: ContentDatesType) =>
  Object.fromEntries(
    Object.entries(dates).map(([dateKey, rawDateValue]) => {
      let dateValue = null;

      if (rawDateValue) {
        const dateValueObj = new Date(rawDateValue);
        dateValue = dateValueObj.toLocaleDateString('en', {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
        });
      }

      return [dateKey, dateValue];
    }),
  );

const ContextualMenuTrigger = () => (
  <ContextualMenuTriggerWrapper>
    <ContextualMenuIcon />
  </ContextualMenuTriggerWrapper>
);

export const ContentOwner = ({ componentId, childComponentId, dates, owner, contentType }: ContentOwnerProps) => {
  const { open: openAssignOwnerModal, modal: assignOwnerModal } = useDialogModal((modalControls) => (
    <Modal {...modalControls} onClickOutside={modalControls.close}>
      <AssignOwnerModalContent
        close={modalControls.close}
        contentType={contentType}
        componentId={componentId}
        childComponentId={childComponentId}
      />
    </Modal>
  ));

  const { user } = useUser();

  if (!owner) return null;

  const formattedDates = dates ? formatDate(dates) : DEFAULT_CONTENT_DATES_VALUE;

  const isEditable =
    user?.role === 'ROLE_ADMIN' ||
    (user?.role === 'ROLE_EDITOR' && (user.id === owner?.id || user.id === owner?.manager));

  return (
    <ContentOwnerWrapper data-testid="content-owner">
      <Avatar name={owner.fullName} size="38" round />
      <ContentOwnerData>
        <ContentOwnerText>Owned by {owner.fullName}</ContentOwnerText>
        {!!formattedDates.firstPublishedAt && (
          <ContentDateText>First Published {formattedDates.firstPublishedAt}</ContentDateText>
        )}
        {!!formattedDates.lastChangedAt && (
          <ContentDateText>Last Changed {formattedDates.lastChangedAt}</ContentDateText>
        )}
      </ContentOwnerData>
      {isEditable && (
        <>
          <ContextualMenu position="down" triggerIcon={<ContextualMenuTrigger />} variant="large" withTrigger>
            {[
              <ContextualMenuOption key="assign-to" onClick={openAssignOwnerModal}>
                <AssignIcon />
                Assign to
              </ContextualMenuOption>,
            ]}
          </ContextualMenu>
          {assignOwnerModal}
        </>
      )}
    </ContentOwnerWrapper>
  );
};
