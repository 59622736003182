import { useHotkeys } from 'react-hotkeys-hook';

import { constants as contentConstants } from '@features/content';
import { SearchModalActionsCreator } from '@actionCreators/SearchModalActionsCreator';
import { useAppDispatch } from '@redux/store';
import { userTracking } from '@features/app/tracking';

const { SHORTCUT_KEYS } = contentConstants;

export const useOpenSearchShortcut = () => {
  const dispatch = useAppDispatch();

  useHotkeys(SHORTCUT_KEYS.OPEN_SEARCH, () => {
    dispatch(SearchModalActionsCreator.showSearchV2Panel());
    userTracking.logosSearchOpened({
      source: 'shortcut',
    });
  });
};
