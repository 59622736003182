import { takeLatest } from 'redux-saga/effects';
import { SectionActions } from '@actions/SectionActions';
import { ContentTypesActions } from '@actions/ContentTypesActions';
import { CourseImageUploaderActions } from '@actions/CourseImageUploaderActions';
import ExerciseSagas from '@sagas/exercises/definitions/ExerciseSagas';
import CourseSagas from '@sagas/courses/definitions/CourseSagasDefinition';
import { CoursesActions } from '@actions/CoursesActions';
import type { AppDispatch } from '@redux/store';

export function* CourseRelatedSagas(dispatch: AppDispatch) {
  yield takeLatest(SectionActions.GET_EXERCISE, ExerciseSagas.requestOneExercise);
  yield takeLatest(SectionActions.GET_EXERCISE_FOR_ACTIVITY_PREVIEW, ExerciseSagas.requestExerciseForActivityPreview);
  yield takeLatest(SectionActions.GET_COURSE, CourseSagas.loadCourse);
  yield takeLatest(SectionActions.GET_COURSE_WITH_ITS_GROUPS, CourseSagas.loadCourseWithItsGroups);
  yield takeLatest(SectionActions.GET_CONTENT, CourseSagas.loadOneContent);
  yield takeLatest(CoursesActions.COURSES_INFO_REQUEST, CourseSagas.coursesInfoRequest);
  yield takeLatest(SectionActions.GET_ALL_EXERCISES_OF_PARENT_ID, CourseSagas.requestAllExercisesOfContentId);
  yield takeLatest(SectionActions.GET_RECAP_OPTIONS_FOR_EXERCISE, CourseSagas.requestRecapOptions);
  yield takeLatest(ContentTypesActions.COURSE_CREATE_CONTENT, CourseSagas.onCreateContent);
  yield takeLatest(
    ContentTypesActions.COURSE_SAVE_NAME_AND_DESCRIPTION,
    CourseSagas.saveCourseNameAndDescription,
    dispatch,
  );
  yield takeLatest(
    ContentTypesActions.CONTENT_SAVE_NAME_AND_DESCRIPTION,
    CourseSagas.saveContentNameAndDescription,
    dispatch,
  );
  yield takeLatest(CourseImageUploaderActions.UPLOAD_THUMBNAIL_IMAGE, CourseSagas.uploadThumbnailImage);
  yield takeLatest(CourseImageUploaderActions.UPLOAD_PAYWALL_IMAGE, CourseSagas.uploadPaywallImage);
  yield takeLatest(ContentTypesActions.COURSE_UPDATE_CONTENTS_ORDER, CourseSagas.updateContentsOrder);
  yield takeLatest(ContentTypesActions.COURSE_UPDATE_LESSON_FOCUS, CourseSagas.updateLessonFocus);
  yield takeLatest(ContentTypesActions.COURSE_UPDATE_LEVEL_TYPE, CourseSagas.updateLevelType);
  yield takeLatest(ContentTypesActions.REMOVE_CONTENT, CourseSagas.removeContent);
  yield takeLatest(ContentTypesActions.CHANGE_LESSON_TYPE, CourseSagas.updateLessonType);
}
