import { FunctionComponent } from 'react';
import classnames from 'classnames';

import paperSheetIcon from '@components/ContentTypes/BaseContent/img/paperSheet.svg';
import clockIcon from '@components/ContentTypes/BaseContent/img/clock.svg';

interface CardPublishingStatusProps {
  className?: string;
  hasPendingChanges: boolean;
  ready: boolean;
}

export const CardPublishingStatus: FunctionComponent<CardPublishingStatusProps> = ({
  className,
  hasPendingChanges,
  ready,
}: CardPublishingStatusProps) => {
  return (
    <div className={classnames('base-content__publishing-status', className)}>
      {hasPendingChanges ? (
        <img src={clockIcon} alt="Pending changes" />
      ) : (
        <>
          {ready ? (
            <div
              className={classnames(
                'base-content__publishing-status__image',
                'base-content__publishing-status__image--ready',
              )}
            />
          ) : (
            <img src={paperSheetIcon} alt="Draft" />
          )}
        </>
      )}
    </div>
  );
};
