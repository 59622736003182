const ADD_ROW = 'BidimensionalEditorActions_ADD_ROW';
const ADD_COLUMN = 'BidimensionalEditorActions_ADD_COLUMN';
const REMOVE_ROW = 'BidimensionalEditorActions_REMOVE_ROW';
const REMOVE_COLUMN = 'BidimensionalEditorActions_REMOVE_COLUMN';
const UPDATE_REDUX_STORE_CELL = 'BidimensionalEditorActions_UPDATE_REDUX_STORE_CELL';

export const BidimensionalEditorActions = {
  ADD_ROW,
  ADD_COLUMN,
  REMOVE_ROW,
  REMOVE_COLUMN,
  UPDATE_REDUX_STORE_CELL,
} as const;
