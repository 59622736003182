import { MouseEvent, useState, useRef } from 'react';
import styled from 'styled-components/macro';

import { BasePopoverPosition } from '@features/theme';

import { HelpType, HelpDataType } from '../types';
import { ReactComponent as InfoIcon } from '../_img/Info.svg';
import { ReactComponent as LightBulbIcon } from '../_img/Lightbulb.svg';
import { HelpContainer, useHelpRefs } from '../Common';
import { HelpTooltip } from '../HelpTooltip';
import { getHelpArticle } from '../_services/getHelpArticle';
import { DEFAULTS } from '../constants';

const HelpDisplayerWrapper = styled.div`
  flex: 1;
  position: relative;
`;

type HelpDisplayerProps = {
  className?: string;
  contentPosition?: BasePopoverPosition;
  id: string;
  title?: string;
  type: HelpType;
  onShow?: () => void;
};

const helpTypeTitle: Record<HelpType, string> = {
  'how-to': 'How To Article',
  guideline: 'Guideline',
};

const baseTriggerIconStyles = `
  cursor: pointer;
  margin-left: 0.8rem;
  outline: none;
`;

const StyledInfoIcon = styled(InfoIcon)`
  ${baseTriggerIconStyles}
`;

const StyledLightBulbIcon = styled(LightBulbIcon)`
  ${baseTriggerIconStyles}
`;

const TriggerIcon = ({
  role,
  type,
  onClick,
}: Pick<HelpDisplayerProps, 'type'> & {
  role: string;
  onClick: (evt: MouseEvent<HTMLElement>) => void;
}) => {
  switch (type) {
    case 'guideline':
      return (
        <span role={role} onClick={onClick}>
          <StyledLightBulbIcon data-tip={helpTypeTitle[type]} data-for="help-tooltip" />
        </span>
      );
    case 'how-to':
      return (
        <span role={role} onClick={onClick}>
          <StyledInfoIcon data-tip={helpTypeTitle[type]} data-for="help-tooltip" />
        </span>
      );
    default:
      return <></>;
  }
};

const helpContentsCache: Record<string, HelpDataType> = {};

export const HelpDisplayer = ({ className, contentPosition, id, title, type }: HelpDisplayerProps) => {
  const [helpContents, setHelpContents] = useState<HelpDataType | null>(null);
  const [showHelpContent, setShowHelpContent] = useState(false);
  const [triggerDOMNode, setTriggerDOMNode] = useState<HTMLElement | null>(null);

  if (!title) {
    title = id; // @TODO Replace with correct titles
  }

  const ref = useRef<HTMLDivElement>(null);
  const { refs } = useHelpRefs();
  if (refs && ref.current) {
    refs.current[id] = ref.current;
  }

  const onShowHelp = (id: string) => {
    if (refs) {
      for (const key in refs.current) {
        if (key !== id) {
          const helpContent = refs.current[key].querySelector('[role="complementary"]');

          if (helpContent) {
            const helpTrigger = refs.current[key].querySelector('[role="button"]') as HTMLElement;
            helpTrigger.click();
          }
        }
      }
    }
  };

  const handleTriggerOnClick = (evt: MouseEvent<HTMLElement>) => {
    evt.stopPropagation();
    evt.preventDefault();

    if (!showHelpContent) {
      if (helpContentsCache[id]) {
        setHelpContents(helpContentsCache[id]);
      } else {
        getHelpArticle(id)
          .then((result) => {
            helpContentsCache[id] = result.data;
            setHelpContents(result.data);
          })
          .catch((err) => {
            setHelpContents({
              description: DEFAULTS[type].DESCRIPTION,
            });
            console.error(err);
          });
      }

      setShowHelpContent(true);
      setTriggerDOMNode(evt.currentTarget);

      onShowHelp(id);
    } else {
      setShowHelpContent(false);
      setTriggerDOMNode(null);
    }
  };

  const onHide = () => {
    setShowHelpContent(false);
    setTriggerDOMNode(null);
  };

  return (
    <HelpDisplayerWrapper className={className} ref={ref}>
      <TriggerIcon role="button" type={type} onClick={handleTriggerOnClick} />
      <HelpTooltip id="help-tooltip" effect="solid" />
      <HelpContainer
        data={helpContents}
        title={title}
        id={id}
        isRelativeToParent
        position={contentPosition}
        type={type}
        show={showHelpContent}
        triggerDOMNode={triggerDOMNode}
        onHide={onHide}
      />
    </HelpDisplayerWrapper>
  );
};
