import { AnyExerciseContentInterface } from '@common/types/exercises/AnyExerciseContentInterface';
import { IssuesToggler } from '@components/IssuesToggler';
import { PublishButton } from './PublishButton';
import {
  ButtonsList,
  ContentData,
  HeaderItemSeparator,
  HeaderWrapper,
  PendingChangesButton,
  SaveButton,
} from '@features/content/header';
import { useAppDispatch } from '@redux/store';
import { ListPreviewModal, PreviewButton } from '@features/content/preview';
import { PreviewModalActionsCreator } from '@actionCreators/PreviewModalActionsCreator';
import { ExerciseTypes } from '@common/enums/ExerciseTypes';
import { ContentTypes } from '@common/enums/ContentTypes';
import { Language } from '@features/content/languages';
import { UploadTranslationMenu } from './UploadTranslationMenu';
import { useCurrentPlacementContent } from '../useCurrentPlacementContent';
import { LoadingStage } from '@common/enums/LoadingStage';
import { useShowDebugUI } from '@features/app/debug';
import { ValidationButton } from '@features/content/forceValidation';

type HeaderProps = {
  contentName: string;
  learningLanguage: Language;
  interfaceLanguages: Language[];
};

export const Header = ({ contentName, learningLanguage, interfaceLanguages }: HeaderProps) => {
  const dispatch = useAppDispatch();
  const { placementType, childrenArray, placementContent, isPlacementContentLoaded, parentId, onSave } =
    useCurrentPlacementContent();

  const showDebugUI = useShowDebugUI();

  const isDataForEntrypointPreviewReady = isPlacementContentLoaded && childrenArray.length > 0;

  return (
    <HeaderWrapper>
      {isDataForEntrypointPreviewReady && <ListPreviewModal />}
      <ContentData
        contentName={contentName}
        learningLanguage={learningLanguage}
        interfaceLanguages={interfaceLanguages}
      />
      <ButtonsList>
        {(placementType === ContentTypes.exercise ||
          (placementType === ContentTypes.entrypoint && isDataForEntrypointPreviewReady)) && (
          <>
            <PreviewButton
              onPreviewClick={() => {
                if (placementType === ContentTypes.exercise) {
                  dispatch(PreviewModalActionsCreator.openPreview());
                } else if (placementType === ContentTypes.entrypoint) {
                  dispatch(PreviewModalActionsCreator.openPreviewForExercisesList());
                }
              }}
            />
            <HeaderItemSeparator />
          </>
        )}
        <UploadTranslationMenu learningLanguage={learningLanguage} interfaceLanguages={interfaceLanguages} />
        {isPlacementContentLoaded === LoadingStage.loaded &&
          placementType !== ContentTypes.placementTest &&
          (showDebugUI && placementContent.id ? (
            <ValidationButton
              id={placementContent.id}
              type={placementContent.type === ContentTypes.entrypoint ? ContentTypes.entrypoint : ContentTypes.exercise}
            />
          ) : (
            <IssuesToggler
              validation={
                Object.values(ExerciseTypes).includes(placementContent.type as any)
                  ? (placementContent as AnyExerciseContentInterface).validation
                  : placementContent.validationStatus
              }
              type={placementType}
              hasChildrenArrayInvalidItems={
                !!childrenArray.filter((children) => !children.validationStatus?.valid).length
              }
            />
          ))}
        <HeaderItemSeparator />
        <PendingChangesButton />
        <SaveButton onSave={onSave} />
        <PublishButton contentToPublish={placementContent} parentId={parentId} childrenArray={childrenArray} />
      </ButtonsList>
    </HeaderWrapper>
  );
};
