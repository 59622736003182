import { takeLatest } from 'redux-saga/effects';

import { ListenRepeatExerciseActions } from '@actions/ListenRepeatExerciseActions';
import ListenRepeatExerciseSagas from '@sagas/exercises/definitions/ListenRepeatExerciseSagasDefinition';
import MediaUploadSagas from '@sagas/mediaUpload/definitions/MediaUploadSagasDefinition';
import type { AppDispatch } from '@redux/store';

export function* ListenRepeatExerciseRelatedSagas(dispatch: AppDispatch) {
  yield takeLatest(ListenRepeatExerciseActions.UPLOAD_IMAGE, MediaUploadSagas.uploadImageToExercise);
  yield takeLatest(ListenRepeatExerciseActions.SAVE, ListenRepeatExerciseSagas.save, dispatch);
}
