import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { mergeRegister } from '@lexical/utils';
import { COMMAND_PRIORITY_LOW, INSERT_PARAGRAPH_COMMAND, KEY_ENTER_COMMAND } from 'lexical';
import { useEffect, useLayoutEffect, useState } from 'react';
import { createGlobalStyle } from 'styled-components/macro';

/**
 * This plugin capture user key events based on the passed callbacks
 */

const FOOTNOTE_CLASSNAME_SUFFIX = 'keyboardActionsFootnote';

const KeyboardActionPluginGlobalStyles = createGlobalStyle`
  .base-editor-${FOOTNOTE_CLASSNAME_SUFFIX} {
    color: ${({ theme }) => theme.colorV2.textTertiary};
    font-size: 1rem;
    padding: 0.4rem 0;
    text-align: right;
  }
`;

export const KeyboardActionsPlugin = ({ footnote, onKeyEnter }: { footnote?: string; onKeyEnter?: () => void }) => {
  const [editor] = useLexicalComposerContext();
  const [isFootnoteInserted, setIsFootnoteInserted] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const keyCommands: Array<() => void> = [];

  useEffect(() => {
    if (onKeyEnter) {
      keyCommands.push(
        editor.registerCommand(
          KEY_ENTER_COMMAND,
          (event) => {
            if (!event) return false;

            event.preventDefault();

            if (event.shiftKey) {
              onKeyEnter();
            } else {
              editor.dispatchCommand(INSERT_PARAGRAPH_COMMAND, undefined);
            }

            return true;
          },
          COMMAND_PRIORITY_LOW,
        ),
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor, onKeyEnter]);

  useEffect(() => {
    return mergeRegister(...keyCommands);
  }, [keyCommands]);

  useLayoutEffect(() => {
    const { namespace } = editor._config;

    if (footnote && !isFootnoteInserted) {
      const editorWrapper = document.getElementById(namespace);

      if (editorWrapper) {
        const footnoteClassName = `base-editor-${FOOTNOTE_CLASSNAME_SUFFIX}`;
        const footnoteEl = editorWrapper.parentNode?.querySelector(`.${footnoteClassName}`);

        if (footnoteEl) footnoteEl.remove();
        editorWrapper.insertAdjacentHTML('afterend', `<div class="${footnoteClassName}" role="note">${footnote}</div>`);
      }

      setIsFootnoteInserted(true);
    }
  }, [editor, footnote, isFootnoteInserted]);

  if (footnote) return <KeyboardActionPluginGlobalStyles />;

  return null;
};
