import { FunctionComponent, SVGProps } from 'react';

import {
  EXERCISE_COMPREHENSION,
  EXERCISE_CONVERSATION,
  EXERCISE_DIALOGUE,
  EXERCISE_FLASHCARD,
  EXERCISE_FILLGAP,
  EXERCISE_HIGHLIGHTER,
  EXERCISE_LISTEN_REPEAT,
  EXERCISE_MATCHUP,
  EXERCISE_MULTIPLE_CHOICE,
  EXERCISE_PHRASE_BUILDER,
  EXERCISE_SLIDE_PPTX,
  EXERCISE_SLIDE_PDF,
  EXERCISE_SPEECH_RECOGNITION,
  EXERCISE_SPELLING,
  EXERCISE_TIP,
  EXERCISE_TRUE_FALSE,
  EXERCISE_TYPING,
  ExerciseType,
} from '@common/enums/ExerciseTypes';
import { ReactComponent as ComprehensionIcon } from '@components/ContentTypes/BaseContent/img/comprehension.svg';
import { ReactComponent as ConversationIcon } from '@components/ContentTypes/BaseContent/img/conversation.svg';
import { ReactComponent as DialogueIcon } from '@components/ContentTypes/BaseContent/img/dialogue.svg';
import { ReactComponent as FillgapIcon } from '@components/ContentTypes/BaseContent/img/fillgap.svg';
import { ReactComponent as FlashcardIcon } from '@components/ContentTypes/BaseContent/img/flashcard.svg';
import { ReactComponent as ListenRepeatIcon } from '@components/ContentTypes/BaseContent/img/listenRepeat.svg';
import { ReactComponent as HighlighterIcon } from '@components/ContentTypes/BaseContent/img/highlighter.svg';
import { ReactComponent as MatchupIcon } from '@components/ContentTypes/BaseContent/img/matchup.svg';
import { ReactComponent as MultipleChoiceIcon } from '@components/ContentTypes/BaseContent/img/multiChoice.svg';
import { ReactComponent as PhraseBuilderIcon } from '@components/ContentTypes/BaseContent/img/phraseBuilder.svg';
import { ReactComponent as SpeechRecognitionIcon } from '@components/ContentTypes/BaseContent/img/speechRecognition.svg';
import { ReactComponent as TrueFalseIcon } from '@components/ContentTypes/BaseContent/img/truefalse.svg';
import { ReactComponent as SlidePdfIcon } from '@components/ContentTypes/BaseContent/img/slidePdf.svg';
import { ReactComponent as SlidePptxIcon } from '@components/ContentTypes/BaseContent/img/slidePptx.svg';
import { ReactComponent as SpellingIcon } from '@components/ContentTypes/BaseContent/img/spelling.svg';
import { ReactComponent as TipIcon } from '@components/ContentTypes/BaseContent/img/tip.svg';
import { ReactComponent as TypingIcon } from '@components/ContentTypes/BaseContent/img/typing.svg';

// @TODO: Move this file to BaseContent/ and reuse it for ExerciseCreatorButtons component

const EXERCISE_ICON: Record<ExerciseType, FunctionComponent<SVGProps<SVGSVGElement>>> = {
  [EXERCISE_COMPREHENSION]: ComprehensionIcon,
  [EXERCISE_CONVERSATION]: ConversationIcon,
  [EXERCISE_DIALOGUE]: DialogueIcon,
  [EXERCISE_FLASHCARD]: FlashcardIcon,
  [EXERCISE_FILLGAP]: FillgapIcon,
  [EXERCISE_HIGHLIGHTER]: HighlighterIcon,
  [EXERCISE_LISTEN_REPEAT]: ListenRepeatIcon,
  [EXERCISE_MATCHUP]: MatchupIcon,
  [EXERCISE_MULTIPLE_CHOICE]: MultipleChoiceIcon,
  [EXERCISE_PHRASE_BUILDER]: PhraseBuilderIcon,
  [EXERCISE_SLIDE_PDF]: SlidePdfIcon,
  [EXERCISE_SLIDE_PPTX]: SlidePptxIcon,
  [EXERCISE_SPELLING]: SpellingIcon,
  [EXERCISE_SPEECH_RECOGNITION]: SpeechRecognitionIcon,
  [EXERCISE_TIP]: TipIcon,
  [EXERCISE_TRUE_FALSE]: TrueFalseIcon,
  [EXERCISE_TYPING]: TypingIcon,
};

export const ExerciseIcon = ({ className, type }: { className?: string; type: ExerciseType }) => {
  const ExerciseIconComponent = EXERCISE_ICON[type];

  return <ExerciseIconComponent className={className} />;
};
