import { useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';

import { Loader } from '@features/theme';
import { PlacementTestActionCreators } from '@actionCreators/PlacementTestActionCreators';
import { useAppDispatch } from '@redux/store';

const CreatePlacementTestBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4rem;
  border: 0.2rem dashed ${({ theme }) => theme.colorV2.placementTestCreateBlockBorder};
  border-radius: 1.6rem;
  padding: 4.6rem 0 5.2rem 0;
  font-size: 1.6rem;
  color: ${({ theme }) => theme.colorV2.placementTestCreateBlockText};
`;

const CreatePlacementTestButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 27.8rem;
  height: 4.4rem;
  margin-top: 2.4rem;
  border-radius: 2.4rem;
  font-weight: 700;
`;

export const CreatePlacementTestBlock = () => {
  const dispatch = useAppDispatch();
  const { courseId } = useParams<{ courseId: string }>();
  const [isCreateInProgress, setIsCreateInProgress] = useState<boolean>(false);

  const onPlacementTestCreate = () => {
    setIsCreateInProgress(true);
    dispatch(PlacementTestActionCreators.createPlacementTest(courseId));
  };

  return (
    <CreatePlacementTestBlockWrapper>
      <span>A placement test for this course doesn’t exist yet</span>
      <CreatePlacementTestButton
        onClick={onPlacementTestCreate}
        disabled={isCreateInProgress}
        id="create-placement-test"
      >
        {isCreateInProgress ? <Loader /> : 'Create a new placement test'}
      </CreatePlacementTestButton>
    </CreatePlacementTestBlockWrapper>
  );
};
