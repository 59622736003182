import styled from 'styled-components/macro';
import Table from 'react-bootstrap/Table';

import { useUser } from '@features/auth';
import { Pagination } from '@features/theme';

import { AudioRequestType } from '../../types';
import { MessageItem } from '../../commonStyles';
import { AudioRequestTableItem } from './AudioRequestTableItem';
import { TableHeader } from './TableHeader';
import { DBId } from '@common/types/DBId';

const AudioRequestsPagination = styled(Pagination)`
  margin: 3.6rem 0;
`;

type TableContentPropsType = {
  audioRequests: AudioRequestType[];
  currentPage: number;
  totalPages: number;
  onCancelAudioRequest: (AudioRequestId: DBId) => void;
  onPageChange: (page: number) => void;
};

export const TableContent = ({
  audioRequests,
  currentPage,
  totalPages,
  onCancelAudioRequest,
  onPageChange,
}: TableContentPropsType) => {
  const { user } = useUser();

  const shouldShowCancelAudioRequest = ({ requestStatus, requester }: AudioRequestType) =>
    requestStatus === 'new' && (user?.role === 'ROLE_ADMIN' || requester.id === user?.id);

  return (
    <Table>
      {audioRequests.length ? (
        <>
          <TableHeader />
          <tbody>
            {audioRequests.map((audioRequest) => (
              <AudioRequestTableItem
                audioRequest={audioRequest}
                key={audioRequest.id}
                showCancelAudioRequest={shouldShowCancelAudioRequest(audioRequest)}
                onCancelAudioRequest={(audioRequestId: DBId) => onCancelAudioRequest(audioRequestId)}
              />
            ))}
          </tbody>
          {totalPages > 1 && (
            <AudioRequestsPagination currentPage={currentPage} totalPages={totalPages} onPageChange={onPageChange} />
          )}
        </>
      ) : (
        <MessageItem>No audio requests</MessageItem>
      )}
    </Table>
  );
};
