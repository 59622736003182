import { useTheme } from 'styled-components';
import { StylesConfig } from 'react-select';

import checkedIcon from './img/checkedIcon.svg';

type useSelectorCommonStylesProps = {
  classNamePrefix: string;
};

export const useSelectorCommonStyles = <T extends unknown>({ classNamePrefix }: useSelectorCommonStylesProps) => {
  const theme = useTheme();

  const CommonSelectorStyles: StylesConfig<T> = {
    container: (defaultStyles) => ({
      ...defaultStyles,
      width: '24rem',
      fontSize: '1.5rem',
    }),
    valueContainer: (defaultStyles) => ({
      ...defaultStyles,
      textTransform: 'none',
    }),
    control: (defaultStyles, { isFocused, menuIsOpen }) => ({
      ...defaultStyles,
      transition: 'all 0.3s ease',
      fontSize: '1.5rem',
      border: `0.1rem solid ${theme.colorV2.searchFilterSelectorControlBaseBorder}`,
      boxShadow: 'none',
      borderRadius: '0.8rem',
      ':hover': {
        border: `0.1rem solid ${theme.colorV2.searchFilterSelectorControlBaseBorder}`,
      },
      background:
        menuIsOpen && isFocused
          ? theme.colorV2.searchFilterSelectorControlFocusedBackground
          : theme.colorV2.searchFilterSelectorControlBaseBackground,
      [`.${classNamePrefix}__input`]: {
        color:
          menuIsOpen && isFocused
            ? theme.colorV2.searchFilterSelectorControlFocusedText
            : theme.colorV2.searchFilterSelectorControlBaseText,
      },
      [`.${classNamePrefix}__input-container`]: {
        color:
          menuIsOpen && isFocused
            ? theme.colorV2.searchFilterSelectorControlFocusedText
            : theme.colorV2.searchFilterSelectorControlBaseText,
      },
      [`.${classNamePrefix}__single-value`]: {
        color:
          menuIsOpen && isFocused
            ? theme.colorV2.searchFilterSelectorControlFocusedText
            : theme.colorV2.searchFilterSelectorControlBaseText,
      },
      [`.${classNamePrefix}__value-container`]: {
        color:
          menuIsOpen && isFocused
            ? theme.colorV2.searchFilterSelectorControlFocusedText
            : theme.colorV2.searchFilterSelectorControlBaseText,
      },
      [`.${classNamePrefix}__multi-value`]: {
        display: 'none',
        ':first-child': {
          display: 'flex',
        },
        transition: 'all 0.3s ease',
        backgroundColor: menuIsOpen && isFocused ? theme.colorV2.accentPrimary : theme.colorV2.uiBackgroundPrimary,
      },
      [`.${classNamePrefix}__multi-value__label`]: {
        backgroundColor: menuIsOpen && isFocused ? theme.colorV2.uiBackgroundPrimary : theme.colorV2.accentPrimary,
        borderRadius: '0',
        color: menuIsOpen && isFocused ? theme.colorV2.accentPrimary : theme.colorV2.uiBackgroundPrimary,
      },
      [`.${classNamePrefix}__multi-value__remove`]: {
        display: 'none',
      },
      cursor: 'pointer',
    }),
    menu: (defaultStyles) => ({
      ...defaultStyles,
      margin: '0',
      border: `0.1rem solid ${theme.colorV2.searchFilterSelectorMenuBorder}`,
      boxShadow: '0 0.4rem 0.8rem rgba(0, 0, 0, 0.05)',
      borderRadius: '0.8rem',
    }),
    menuList: (defaultStyles) => ({
      ...defaultStyles,
      padding: '0',
      borderRadius: '0.7rem',
    }),
    option: (defaultStyles, { isFocused, isSelected }) => ({
      ...defaultStyles,
      transition: 'background-color 0.3s ease',
      backgroundColor: isFocused
        ? theme.colorV2.searchFilterSelectorOptionFocusedBackground
        : theme.colorV2.searchFilterSelectorOptionBaseBackground,
      position: 'relative',
      color: isSelected ? theme.colorV2.searchFilterSelectorSelectedOptionColor : 'inherit',
      ':after': {
        content: '""',
        backgroundImage: isSelected ? `url(${checkedIcon})` : 'none',
        display: 'block',
        width: '1.5rem',
        position: 'absolute',
        height: '1rem',
        right: '1rem',
        top: '40%',
      },
      cursor: 'pointer',
    }),
    placeholder: (defaultStyles, { isFocused, selectProps }) => ({
      ...defaultStyles,
      transition: 'color 0.3s ease',
      color:
        selectProps.menuIsOpen && isFocused
          ? theme.colorV2.searchFilterSelectorPlaceholderFocusedText
          : theme.colorV2.searchFilterSelectorPlaceholderBaseText,
    }),
    dropdownIndicator: (defaultStyles, { isFocused, selectProps }) => ({
      ...defaultStyles,
      padding: '0.4rem',
      transition: 'transform 0.3s ease',
      transform: `rotate(${selectProps.menuIsOpen ? '180deg' : '0deg'})`,
      path: {
        transition: 'fill 0.3s ease',
        fill:
          selectProps.menuIsOpen && isFocused
            ? theme.colorV2.searchFilterSelectorDropdownIndicatorFocusedColor
            : theme.colorV2.searchFilterSelectorDropdownIndicatorBaseColor,
      },
    }),
    clearIndicator: (defaultStyles, { isFocused, selectProps }) => ({
      ...defaultStyles,
      position: 'absolute',
      right: '1rem',
      padding: '0',
      cursor: 'pointer',
      path: {
        transition: 'fill 0.3s ease',
        fill:
          selectProps.menuIsOpen && isFocused
            ? theme.colorV2.searchFilterSelectorClearIndicatorFocusedColor
            : theme.colorV2.searchFilterSelectorClearIndicatorBaseColor,
      },
    }),
    indicatorSeparator: (defaultStyles) => ({
      ...defaultStyles,
      display: 'none',
    }),
  };

  return CommonSelectorStyles;
};
