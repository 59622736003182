import axios, { AxiosInstance, AxiosRequestConfig, AxiosRequestHeaders } from 'axios';
import { getConfig } from '@features/config';
import { ApiConfig } from './config';
import { getAuthorizationHeader } from '@features/auth';
import { addToast } from '@features/app/toast';

const applyAuthorization = (client: AxiosInstance): AxiosInstance => {
  client.interceptors.request.use((config: AxiosRequestConfig) => ({
    ...config,
    headers: {
      ...config.headers,
      Authorization: `${getAuthorizationHeader()}`,
    } as AxiosRequestHeaders,
  }));

  return client;
};

export const createClients = () => {
  const baseUrl = getConfig<ApiConfig>('api').baseUrl;

  const clients = {
    /** @deprecated Use noErrorsV1 instead */
    v1: applyAuthorization(
      axios.create({
        baseURL: baseUrl + '/courses-service',
      }),
    ),
    noErrorsV1: applyAuthorization(
      axios.create({
        baseURL: baseUrl + '/courses-service',
      }),
    ),
    /** @deprecated Use noErrorsV2 instead */
    v2: applyAuthorization(
      axios.create({
        baseURL: baseUrl + '/v2',
      }),
    ),
    noErrorsV2: applyAuthorization(
      axios.create({
        baseURL: baseUrl + '/v2',
      }),
    ),
  };

  clients.v1.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response?.status === 422) {
        addToast({
          type: 'error',
          title: 'API 422',
          description: `${error.response.data?.detail}`,
        });
      } else if (error.response?.status === 400) {
        // @TODO Global interceptor should know nothing about features (publishing, etc)
        // Check is for publishing content with invalid children
        if (error.response.data?.title === 'Some entities need to be updated') {
          return error.response.data;
        }

        addToast({
          type: 'error',
          title: 'API 400',
          description: `${error.response.data?.detail}`,
        });
      } else if (error.response?.status === 404) {
        addToast({
          type: 'error',
          title: 'API 404',
          description: `${error.response.data?.detail}. Check the IDs of course and/or content.`,
        });
      } else if (error.response?.status === 403) {
        if (error.response.data?.detail === 'Access Denied. Not have the required permissions') {
          addToast({
            type: 'error',
            title: 'API 403',
            description: 'Don’t have access to this action',
          });

          setTimeout(() => {
            window.location.replace('/');
          }, 1000);
        }
      }

      return Promise.reject(error);
    },
  );

  clients.v2.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response?.status === 422) {
        addToast({
          type: 'error',
          title: 'API 422',
          description: `${error.response.data?.detail}`,
        });
      } else if (error.response?.status === 400) {
        addToast({
          type: 'error',
          title: 'API 400',
          description: `${error.response.data?.detail}`,
        });
      } else if (error.response?.status === 404) {
        if (error.response?.data?.title !== 'Grammar review not found') {
          addToast({
            type: 'error',
            title: 'API 404',
            description: `${error.response.data?.detail}. Check the IDs of course and/or content.`,
          });
        }
      } else if (error.response?.status === 403) {
        if (error.response.data?.detail === 'Access Denied. Not have the required permissions') {
          addToast({
            type: 'error',
            title: 'API 403',
            description: 'Don’t have access to this action',
          });

          setTimeout(() => {
            window.location.replace('/');
          }, 1000);
        }
      }

      return Promise.reject(error);
    },
  );

  return clients;
};
