import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { DBId } from '@common/types/DBId';
import BreadcrumbNavigation from '@components/BreadcrumbNavigation/BreadcrumbNavigation';
import { allTypesMap } from '@helpers/contentCardHelper';
import { useAppSelector } from '@redux/store';
import {
  selectPlacementTestExerciseContent,
  selectPlacementTestNavigationStructure,
} from '@selectors/PlacementTestSelectors';

const BreadcrumbItemWithLink = styled.span`
  color: ${({ theme }) => theme.colorV2.breadcrumbsText};
  cursor: pointer;
  font-size: 1.2rem;
`;

export const Breadcrumbs = () => {
  const history = useHistory();
  const { courseId, entrypointId } = useParams<{ courseId: DBId; entrypointId: DBId }>();
  const placementExercise = useAppSelector(selectPlacementTestExerciseContent);

  const navigation = useAppSelector(selectPlacementTestNavigationStructure);

  return (
    <BreadcrumbNavigation
      breadcrumbItems={[
        <BreadcrumbItemWithLink onClick={() => history.push(`/course/${courseId}/placement-test`)}>
          Placement Test
        </BreadcrumbItemWithLink>,
        <BreadcrumbItemWithLink
          onClick={() => history.push(`/course/${courseId}/placement-test/entrypoint/${entrypointId}`)}
        >
          {navigation.find((element) => element.id === entrypointId)?.title || 'Entrypoint'}
        </BreadcrumbItemWithLink>,
        <span>{allTypesMap[placementExercise.type]}</span>,
      ]}
    />
  );
};
