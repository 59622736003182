import { apiClient } from '@features/api';
import { AudioRequestParamsType, getAudioRequestParams } from './helpers';
import { AudioRequestType } from '../types';

export const getAudioRequests = async (
  page: number,
  params: AudioRequestParamsType,
): Promise<{ data: { count: number; audioRequests: AudioRequestType[] } }> => {
  const queryParams = getAudioRequestParams(params);
  return await apiClient.noErrorsV2.get(`/audio-requests?page=${page}${queryParams}`);
};
