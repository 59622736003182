import { PayloadAction } from '@reduxjs/toolkit';
import produce from 'immer';

import { LessonTypeAllowedToChange } from '@common/enums/LessonTypes';
import { CourseSliceInterface } from '@common/interfaces/slices/CourseSliceInterface';
import { DBId } from '@common/types/DBId';
import { ContentTypesActions } from '@actions/ContentTypesActions';
import { BaseContentActionsCreator } from '@actionCreators/BaseContentActionsCreator';

const LessonReducers = {
  [ContentTypesActions.CHANGE_LESSON_CERTIFICATE_TYPE]: (
    state: CourseSliceInterface,
    { payload }: ReturnType<typeof BaseContentActionsCreator.changeCertificateLessonType>,
  ) => {
    return produce(state, (draft) => {
      if (draft.selectedGroupsOfParent.parentContents) {
        draft.selectedGroupsOfParent.parentContents.certificateType = payload;
        draft.selectedGroupsOfParent.parentContents.certificateTypeChanged = true;
      }
    });
  },
  [ContentTypesActions.CHANGE_LESSON_TYPE_SUCCEEDED]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<{ lessonId: DBId; lessonType: LessonTypeAllowedToChange }>,
  ) => {
    const { lessonId, lessonType } = payload;

    return produce(state, (draft) => {
      if (draft.selectedGroupsOfParent.groups.length) {
        const lessonIndex = draft.selectedGroupsOfParent.groups.findIndex(({ id }) => id === lessonId);

        draft.selectedGroupsOfParent.groups[lessonIndex].lessonType = lessonType;
      }
    });
  },
};

export default LessonReducers;
