import { FunctionComponent, useMemo, useCallback } from 'react';
import classnames from 'classnames';
import { useFormikContext } from 'formik';

import { FormikValuesInterface } from '@helpers/formikInitialValuesHelper';
import { FillgapContentProps } from '../../PreviewModalProps';
import KTagsCreatorHelpers from '@components/Exercises/Common/KTagsCreator/KTagsCreatorHelpers';
import { getWordsArrayWithMissedWords } from './getWordsArrayWithMissedWords';
import { getValueByLanguage } from '../../../../localization-utils';
import { KeyboardShortcut, ImageContainer } from './common';

import './FillgapContent.scss';

export const FillgapContent: FunctionComponent<FillgapContentProps> = ({
  content,
  deviceType,
  answerState,
  courseLearningLanguage,
  isForList,
}: FillgapContentProps) => {
  const { values }: { values: FormikValuesInterface } = useFormikContext();
  const isCorrectSelected = answerState === 1;
  const isIncorrectSelected = answerState === 2;

  const phrase = useMemo(
    () =>
      getValueByLanguage(
        isForList ? content.mainBundle?.phrase?.textLocalizations : values.mainBundle_phrase,
        courseLearningLanguage,
      ),
    [values, courseLearningLanguage, isForList, content.mainBundle],
  );
  const kTagsCount = KTagsCreatorHelpers.countKTags(phrase);
  const distractor1Text = useMemo(
    () =>
      getValueByLanguage(
        isForList ? content.distractor1?.phrase?.textLocalizations : values.distractor1_phrase,
        courseLearningLanguage,
      ),
    [values, courseLearningLanguage, isForList, content.distractor1],
  );
  const distractor2Text = useMemo(
    () =>
      getValueByLanguage(
        isForList ? content.distractor2?.phrase?.textLocalizations : values.distractor2_phrase,
        courseLearningLanguage,
      ),
    [values, courseLearningLanguage, isForList, content.distractor2],
  );

  const wordsArray = getWordsArrayWithMissedWords(phrase);

  const mainImage = useMemo(
    () => getValueByLanguage(content.mainBundle?.image?.imageLocalizations),
    [content.mainBundle],
  );
  const mainAudio = useMemo(
    () => getValueByLanguage(content.mainBundle?.phrase?.audioLocalizations),
    [content.mainBundle],
  );

  // This function returns all missed words with distactor words
  const getAllPossibleWords = useCallback(
    (words: string[]) => {
      if (kTagsCount < 1) {
        return [...words?.filter((word) => word.includes('!missed!'))];
      } else if (kTagsCount === 1) {
        return [...words?.filter((word) => word.includes('!missed!')), distractor1Text];
      } else {
        return [...words?.filter((word) => word.includes('!missed!')), distractor1Text, distractor2Text];
      }
    },
    [distractor1Text, distractor2Text, kTagsCount],
  );

  return (
    <>
      <ImageContainer
        deviceType={deviceType}
        isImageEnabled={!!mainImage}
        isAudioEnabled={!!mainAudio}
        imageSrc={mainImage}
        isVocabulary={content.mainBundle?.isVocabulary}
      />

      <div className="fillgap-text">
        {wordsArray?.map((word: string) => (
          <>
            {word.includes('!missed!') ? (
              <>
                {answerState ? (
                  <div
                    className={classnames(
                      'fillgap-text__missed-word',
                      { 'fillgap-text__missed-word--correct': isCorrectSelected },
                      { 'fillgap-text__missed-word--incorrect': isIncorrectSelected },
                    )}
                  >
                    <span className="fillgap-text__word">
                      {isCorrectSelected ? word.replace('!missed!', '') : 'fdhd'}
                    </span>
                  </div>
                ) : (
                  <svg className="fillgap-text__empty-place">
                    <rect rx="8px" ry="8px" width="100%" height="100%"></rect>
                  </svg>
                )}
              </>
            ) : (
              <>
                {word
                  .split(' ')
                  .map((smallWord) => smallWord && <span className="fillgap-text__word">{smallWord}</span>)}
              </>
            )}
          </>
        ))}
      </div>

      <div className="fillgap-words">
        {wordsArray?.length
          ? getAllPossibleWords(wordsArray).map((word: string, index: number) => (
              <>
                {word ? (
                  <div
                    className={classnames('fillgap-word', {
                      'fillgap-word--answered': answerState && word !== distractor1Text && word !== distractor2Text,
                    })}
                  >
                    {word.replace('!missed!', '')}
                    <KeyboardShortcut answerState={answerState} value={index + 1} />
                  </div>
                ) : null}
              </>
            ))
          : null}
      </div>
    </>
  );
};
