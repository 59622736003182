import { FunctionComponent, useMemo } from 'react';
import classnames from 'classnames';
import { useFormikContext } from 'formik';

import { LocalizationInterface } from '@common/interfaces/localization/LocalizationInterface';
import { FormikValueInterface, FormikValuesInterface } from '@helpers/formikInitialValuesHelper';
import { TypingContentProps } from '../../PreviewModalProps';
import { getWordsArrayWithMissedWords } from './getWordsArrayWithMissedWords';
import { getValueByLanguage } from '../../../../localization-utils';
import { ImageContainer } from './common';

import './TypingContent.scss';
import { useAppSelector } from '@redux/store';
import { selectInterfaceLanguages } from '@selectors/CoursesSelectors';

const getTypingHintByInterfaceLanguage = (
  localizedContent: (LocalizationInterface | FormikValueInterface)[] | undefined,
  interfaceLanguages: string[],
): string[] => {
  if (localizedContent && localizedContent.length) {
    const englishValue = localizedContent
      .filter((localization) => localization.language === 'EN' && localization.value)
      .map((localization) => localization.value);

    if (englishValue.length) {
      return englishValue;
    }
    return localizedContent
      .filter((localization) => interfaceLanguages.includes(localization.language) && localization.value)
      .map((localization) => localization.value);
  }
  return [];
};

export const TypingContent: FunctionComponent<TypingContentProps> = ({
  content,
  deviceType,
  answerState,
  courseLearningLanguage,
  isForList,
}: TypingContentProps) => {
  const { values }: { values: FormikValuesInterface } = useFormikContext();
  const isCorrectSelected = answerState === 1;
  const isIncorrectSelected = answerState === 2;

  const interfaceLanguages = useAppSelector(selectInterfaceLanguages);

  const phrase = useMemo(
    () =>
      getValueByLanguage(
        isForList ? content.mainBundle?.phrase?.textLocalizations : values.mainBundle_phrase,
        courseLearningLanguage,
      ),
    [values, courseLearningLanguage, isForList, content.mainBundle],
  );

  const wordsArray = getWordsArrayWithMissedWords(phrase);

  const image = useMemo(() => getValueByLanguage(content.mainBundle?.image?.imageLocalizations), [content.mainBundle]);

  const hintTextInInterfaceLanguages = useMemo(
    () =>
      getTypingHintByInterfaceLanguage(isForList ? content.hint?.textLocalizations : values.hint, interfaceLanguages),
    [values, interfaceLanguages, isForList, content.hint],
  );

  return (
    <>
      <ImageContainer
        deviceType={deviceType}
        isImageEnabled={content.mainImageEnabled && image}
        isAudioEnabled={
          content.mainAudioEnabled &&
          getValueByLanguage(content.mainBundle?.phrase?.audioLocalizations, courseLearningLanguage)
        }
        imageSrc={image}
        isVocabulary={content.mainBundle?.isVocabulary}
      />
      <span className="typing-hint">{hintTextInInterfaceLanguages[0]}</span>
      <div className="typing-text">
        {wordsArray?.map((word: string) => (
          <>
            {word.includes('!missed!') ? (
              <div
                className={classnames(
                  'typing-text__missed-word',
                  { 'typing-text__missed-word--answered': answerState },
                  { 'typing-text__missed-word--correct': isCorrectSelected },
                  { 'typing-text__missed-word--incorrect': isIncorrectSelected },
                )}
                style={{ minWidth: `${word.replace('!missed!', '').length * 10 + 10}px` }}
              >
                {answerState ? (
                  <span className="typing-text__word">
                    {isCorrectSelected
                      ? word.replace('!missed!', '')
                      : word.replace('!missed!', '').split('').reverse().join('')}
                  </span>
                ) : (
                  'Type here'
                )}
              </div>
            ) : (
              <>
                {word
                  .split(' ')
                  .map((smallWord) => smallWord && <span className="typing-text__word">{smallWord}</span>)}
              </>
            )}
          </>
        ))}
      </div>
    </>
  );
};
