import { UISliceInterface } from '@common/interfaces/slices/UISliceInterface';
import { SidePanelActions } from '@actions/SidePanelActions';
import { clone } from '@helpers/clone';

const SidePanelUIReducers = {
  [SidePanelActions.HIDE_SIDE_PANEL]: (state: UISliceInterface): UISliceInterface => {
    const clonedState: UISliceInterface = clone(state);

    for (let panel in clonedState.panels) {
      (clonedState.panels as any)[panel].visible = false;
      (clonedState.panels as any)[panel].bundleName = '';
    }
    for (let panel in clonedState.panels) {
      (clonedState.panels as any)[panel].visibleForCourse = false;
    }
    for (let panel in clonedState.panels) {
      (clonedState.panels as any)[panel].visibleForLevelAndLesson = false;
    }

    return {
      ...clonedState,
      panels: {
        ...clonedState.panels,
      },
    };
  },
};

export default SidePanelUIReducers;
