import { SectionActions } from '@actions/SectionActions';
import { ExerciseTypes } from '@common/enums/ExerciseTypes';
import { LoadingStage } from '@common/enums/LoadingStage';
import { CourseSliceInterface } from '@common/interfaces/slices/CourseSliceInterface';
import ExerciseDataModelAssembler from '@components/Exercises/ExerciseDataModelAssembler';
import { updateTextField } from '@helpers/updateTextField';
import { PhraseBuilderExerciseActions } from '@actions/PhraseBuilderExerciseActions';
import PhraseBuilderExerciseInterface from '@components/Exercises/PhraseBuilder/interfaces/PhraseBuilderExerciseInterface';
import { selectedGroupsOfParentInitialData } from '@redux/initialStates/courseInitialState';
import { PayloadAction } from '@reduxjs/toolkit';
import { clone } from '@helpers/clone';
import { removeAudioDataFromBundle } from './common/helpers';

const PhraseBuilderExerciseCourseReducers = {
  [SectionActions.COURSE_EXERCISE_PHRASE_BUILDER_REQUEST_SUCCEEDED]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    let clonedCourseSlice: CourseSliceInterface = clone(state);
    let { content }: PhraseBuilderExerciseInterface = payload;

    let exercise: PhraseBuilderExerciseInterface = {
      ...clonedCourseSlice.loadedExercise.exercise,
      content,
      type: ExerciseTypes.phraseBuilder,
    };

    return {
      ...clonedCourseSlice,
      selectedGroupsOfParent: selectedGroupsOfParentInitialData,
      loadedExercise: {
        loaded: LoadingStage.loaded,
        exercise,
      },
    };
  },
  [PhraseBuilderExerciseActions.SET_INSTRUCTIONS_LANGUAGE]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    return ExerciseDataModelAssembler.updateCheckbox('instructionsLanguage', state, payload);
  },
  [PhraseBuilderExerciseActions.SET_PHRASE_BUILDER_ALL_FIELDS]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    let clonedCourseSlice: CourseSliceInterface = clone(state);

    const { values } = payload;

    clonedCourseSlice = updateTextField(clonedCourseSlice, values, 'instructions');
    clonedCourseSlice = updateTextField(clonedCourseSlice, values, 'phrase', 'mainBundle');
    clonedCourseSlice = updateTextField(clonedCourseSlice, values, 'feedback');

    return clonedCourseSlice;
  },

  [PhraseBuilderExerciseActions.REMOVE_AUDIO]: (state: CourseSliceInterface): CourseSliceInterface => {
    let clonedCourseSlice: CourseSliceInterface = clone(state);

    return removeAudioDataFromBundle(clonedCourseSlice, 'phrase', 'mainBundle', true);
  },
};

export default PhraseBuilderExerciseCourseReducers;
