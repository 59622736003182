import { Loader } from '@features/theme';
import { useSearch } from '../../../SearchContext';
import { SearchFeedback } from './SearchFeedback';

type SearchResultsWrapperProps = {
  onPageCount: number;
  children: JSX.Element;
};
export const SearchResultsWrapper = ({ onPageCount, children }: SearchResultsWrapperProps) => {
  const { query, searchLoading, searchLoaded } = useSearch();

  // @TODO This causes a bug where if you clear search results and start typing into search box - it will say No results before you search
  const notFound = query ? onPageCount === 0 && searchLoaded : false;

  if (searchLoading) {
    return <Loader size="L" />;
  }

  return onPageCount ? children : <SearchFeedback notFound={notFound} />;
};
