import { CourseSliceInterface } from '@common/interfaces/slices/CourseSliceInterface';
import {
  courseInitialData,
  loadedExerciseInitialData,
  loadedExercisesInitialData,
  selectedGroupsOfParentInitialData,
  activityPreviewExerciseInitialData,
  stringReuseModalInitialData,
} from '@redux/initialStates/courseInitialState';
import { clone } from '@helpers/clone';

import { COURSE_EDITION_ABANDONED } from '@actions/BusuuLogoActions';

const BusuuLogoActionsCourseCourseReducers = {
  [COURSE_EDITION_ABANDONED]: (state: CourseSliceInterface): CourseSliceInterface => {
    const clonedState: CourseSliceInterface = clone(state);

    return {
      ...clonedState,
      course: courseInitialData,
      selectedGroupsOfParent: selectedGroupsOfParentInitialData,
      loadedExercises: loadedExercisesInitialData,
      loadedExercise: loadedExerciseInitialData,
      preview: {
        exerciseForPreview: activityPreviewExerciseInitialData,
        exerciseForPreviewLoaded: false,
      },
      newContent: {
        ...clonedState.newContent,
        newElementId: '',
      },
      header: {
        isSaveButtonEnabled: false,
        isSaveInProgress: false,
        validationResultsLoading: false,
        publishing: clonedState.header.publishing,
      },
      stringReuseModal: stringReuseModalInitialData,
    };
  },
};

export default BusuuLogoActionsCourseCourseReducers;
