import { CourseImageUploaderActions } from '@actions/CourseImageUploaderActions';

export const CourseImageUploaderActionsCreator = {
  uploadThumbnail: (image: File | null, progressHandler: Function) => ({
    type: CourseImageUploaderActions.UPLOAD_THUMBNAIL_IMAGE,
    payload: {
      image,
      progressHandler,
    },
  }),
  uploadPaywall: (image: File | null, progressHandler: Function) => ({
    type: CourseImageUploaderActions.UPLOAD_PAYWALL_IMAGE,
    payload: {
      image,
      progressHandler,
    },
  }),
  removeImage: (branch: string) => ({
    type: CourseImageUploaderActions.REMOVE_IMAGE,
    payload: {
      branch,
    },
  }),
};
