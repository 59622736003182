import { createContext } from 'react';
import { UserInterface } from '@common/interfaces/users/UserInterface';

type SecurityContextType = {
  user: UserInterface | null;
  setUser: (user: UserInterface | null) => void;
  refreshUser: () => Promise<void>;
};

export const SecurityContext = createContext<SecurityContextType>({
  user: null,
  setUser: () => {},
  refreshUser: () => Promise.resolve(),
});
SecurityContext.displayName = 'SecurityContext';
