import { useState } from 'react';
import { useAppDispatch } from '@redux/store';
import styled from 'styled-components/macro';

import { CreatableSearchableSelector, Loader } from '@features/theme';
import { BaseContentActionsCreator } from '@actionCreators/BaseContentActionsCreator';
import { createIdentityGroup, getIdentityGroups } from '../ProgressMatchingService';
import { IdentityGroupForLessonType } from '../types';
import { HelpTooltip } from '@features/help';
import { HelpDisplayer } from '@features/help';
import linkIcon from './img/linkIcon.svg';
import progerssMatchingIcon from '../img/progressMatchingIcon.svg';
import { IdentityGroupModal } from '../IdentityGroupModal';

type IdentityGroupOptionType = { value: string; label: string };

const IdentityGroupSelectorWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0.8rem 0 1.6rem 0;

  #help-tooltip,
  #progress-matching-selector-tooltip {
    z-index: 4000;
  }
`;

const IdentityGroupSelect = styled(CreatableSearchableSelector)`
  z-index: 3999;
  min-width: 25rem;
  max-width: 25rem;
  min-heigth: 4rem;
  font-size: 1.5rem;
`;

const ProgressMatchingImage = styled.img`
  margin: 0 1.6rem;
`;

const LinkedLessonsButton = styled.div`
  display: flex;
  cursor: pointer;
  margin-left: 1.6rem;
  font-weight: 700;
  font-size: 16px;
  color: ${({ theme }) => theme.color.brandPrimaryBase};
`;

const LinkImage = styled.img`
  margin-right: 0.8rem;
`;

const LoaderContainer = styled.div`
  position: absolute;
  left: 25rem;
  z-index: 4000;
`;

export const IdentityGroupSelector = ({ identityGroup }: { identityGroup: IdentityGroupForLessonType | null }) => {
  const dispatch = useAppDispatch();
  const [identityGroups, setIdentityGroups] = useState<IdentityGroupOptionType[]>([]);
  const [identityGroupsLoading, setIdentityGroupsLoading] = useState<boolean>(false);
  const [isEditModalOpened, setIsEditModalOpened] = useState<boolean>(false);

  const onFocusHandle = () => {
    if (!identityGroups.length) {
      setIdentityGroupsLoading(true);
      getIdentityGroups().then((result) => {
        setIdentityGroups(
          result.data.identityGroups.map((identityGroup) => ({ value: identityGroup.id, label: identityGroup.title })),
        );
        setIdentityGroupsLoading(false);
      });
    }
  };

  const onCreateHandle = (newIdentityGroup: string) => {
    createIdentityGroup(newIdentityGroup).then((result) => {
      if (result.status === 200) {
        setIdentityGroups([...identityGroups, { value: result.data.id, label: newIdentityGroup }]);
      }
    });
  };

  const onChangeHandle = (selectedOption: IdentityGroupOptionType | null) => {
    dispatch(
      BaseContentActionsCreator.changeIdentityGroup(
        selectedOption ? { title: selectedOption.label, id: selectedOption.value } : null,
      ),
    );
  };

  const onIdentityGroupNameChange = (newTitle?: string) => {
    setIdentityGroups(
      identityGroups.map((identityGroupItem) =>
        identityGroupItem.value === identityGroup?.id
          ? { ...identityGroupItem, label: newTitle || '' }
          : identityGroupItem,
      ),
    );
    if (identityGroup && newTitle) {
      onChangeHandle({ value: identityGroup.id, label: newTitle });
    }
  };

  return (
    <IdentityGroupSelectorWrapper>
      <ProgressMatchingImage
        src={progerssMatchingIcon}
        data-tip="Progress match"
        data-for="progress-matching-selector-tooltip"
      />
      <HelpTooltip id="progress-matching-selector-tooltip" effect="solid" />
      <IdentityGroupSelect
        value={identityGroup ? { label: identityGroup.title, value: identityGroup.id } : null}
        placeholder="Select identity group..."
        options={identityGroups}
        onChange={onChangeHandle}
        onCreateOption={onCreateHandle}
        onFocus={onFocusHandle}
      />
      {identityGroupsLoading && (
        <LoaderContainer>
          <Loader size="S" />
        </LoaderContainer>
      )}
      {identityGroup?.id && (
        <>
          <IdentityGroupModal
            opened={isEditModalOpened}
            identityGroup={identityGroup}
            onChange={onIdentityGroupNameChange}
            onClose={() => setIsEditModalOpened(false)}
          />
          <LinkedLessonsButton onClick={() => setIsEditModalOpened(true)}>
            <LinkImage src={linkIcon} />
            <div>Mapped lessons</div>
          </LinkedLessonsButton>
        </>
      )}
      <HelpDisplayer id="how-to-progress-matching" type="how-to" />
    </IdentityGroupSelectorWrapper>
  );
};
