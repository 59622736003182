import { useState } from 'react';

import { GrammarReviewActionCreators } from '@actionCreators/GrammarReviewActionCreators';
import { PlacementTestActionCreators } from '@actionCreators/PlacementTestActionCreators';
import { SearchModalActionsCreator } from '@actionCreators/SearchModalActionsCreator';
import { EXERCISE_TIP, GRADABLE_EXERCISES } from '@common/enums/ExerciseTypes';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { selectCourse, selectSelectedGroupsOfParentParentContents } from '@selectors/CoursesSelectors';
import { selectGrammarTopicContent } from '@selectors/GrammarSelectors';
import { selectPlacementTestEntrypointContent } from '@selectors/PlacementTestSelectors';
import { selectSearchPanel } from '@selectors/UiSelectors';
import { userTracking } from '@features/app/tracking';

import { ExerciseResultCard } from './SearchResultItemCard';
import { ExerciseResultDetails } from './SearchResultItemDetails';
import { getExerciseResultDetails } from './services';
import { ExerciseDetailsTab, ExerciseSearchResultDetailsType, ExerciseSearchResultType } from './types';

export const ExerciseSearchResultItem = ({ content }: { content: ExerciseSearchResultType }) => {
  const dispatch = useAppDispatch();
  const [isExpanded, setIsExpanded] = useState(false);
  const [details, setDetails] = useState<ExerciseSearchResultDetailsType | null>(null);
  const [isDetailsLoaded, setIsDetailsLoaded] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [defaultTab, setDefaultTab] = useState<ExerciseDetailsTab['key'] | null>(null);

  const currentCourse = useAppSelector(selectCourse);
  const grammarTopicContent = useAppSelector(selectGrammarTopicContent);
  const entrypointContent = useAppSelector(selectPlacementTestEntrypointContent);
  const currentContent = useAppSelector(selectSelectedGroupsOfParentParentContents);
  const searchParams = useAppSelector(selectSearchPanel);

  const { contentType, parentType, position = 0 } = searchParams;

  const onExpand = (tabName: ExerciseDetailsTab['key'] | null) => {
    setDefaultTab(tabName);
    setIsExpanded(!isExpanded);
    if (!isExpanded && !details) {
      getExerciseResultDetails(content.id)
        .then(({ data }) => {
          setIsDetailsLoaded(true);
          setDetails(data);
        })
        .catch((error) => {
          setIsDetailsLoaded(true);
          setDetails(null);
          if (error.response.data.detail) {
            setErrorMessage(error.response.data.detail);
          } else {
            setErrorMessage(error.response.data.message || 'Unknown error');
          }
        });
    }
  };

  const getIsReuseAvailable = () => {
    if (contentType === 'onlyTip') {
      return content.exerciseType === EXERCISE_TIP;
    } else if (contentType === 'onlyGradable' && content.exerciseType) {
      return GRADABLE_EXERCISES.includes(content.exerciseType);
    } else {
      return true;
    }
  };

  const isReuseAvailable = getIsReuseAvailable();

  const onCopyContent = () => {
    if (parentType === 'grammarTopic') {
      dispatch(
        GrammarReviewActionCreators.copyExercise(
          currentCourse.id,
          grammarTopicContent.id,
          content.id,
          content.exerciseType,
          position,
          contentType === 'onlyTip' ? 'reference' : 'grammar',
        ),
      );
    } else if (parentType === 'placementTestEntrypoint') {
      dispatch(
        PlacementTestActionCreators.copyExercise(
          currentCourse.id,
          entrypointContent.id,
          content.id,
          content.exerciseType,
          position,
        ),
      );
    } else {
      if (currentContent) {
        dispatch(SearchModalActionsCreator.copyExercise(content.id, currentContent.id, position));
      }
    }
  };

  const onUseContent = () => {
    if (parentType === 'grammarTopic') {
      dispatch(
        GrammarReviewActionCreators.reuseExercise(
          currentCourse.id,
          grammarTopicContent.id,
          content.id,
          content.exerciseType,
          position,
          contentType === 'onlyTip' ? 'reference' : 'grammar',
        ),
      );
    } else if (parentType === 'placementTestEntrypoint') {
      dispatch(
        PlacementTestActionCreators.reuseExercise(
          currentCourse.id,
          entrypointContent.id,
          content.id,
          content.exerciseType,
          position,
        ),
      );
    } else {
      if (currentContent) {
        dispatch(SearchModalActionsCreator.reuseExercise(content.id, currentContent.id, position));
      }
    }

    userTracking.logosSearchReuseSelected({
      type: 'exercise',
    });
  };

  return (
    <div data-testid="search-result-item-exercise">
      <ExerciseResultCard content={content} isExpanded={isExpanded} onExpand={onExpand} />
      <ExerciseResultDetails
        defaultTab={defaultTab}
        isExpanded={isExpanded}
        isLoaded={isDetailsLoaded}
        errorMessage={errorMessage}
        details={details}
        resultId={content.id}
        onCopyContent={isReuseAvailable ? onCopyContent : undefined}
        onUseContent={isReuseAvailable ? onUseContent : undefined}
      />
    </div>
  );
};
