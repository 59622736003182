import { SlidePptxExerciseActions } from '@actions/SlidePptxExerciseActions';
import SlidePptxExerciseInterface from '@components/Exercises/SlidePptx/interfaces/SlidePptxExerciseInterface';

export const SlidePptxExerciseActionsCreator = {
  save: (exercise: SlidePptxExerciseInterface) => ({
    type: SlidePptxExerciseActions.SAVE,
    payload: {
      exercise,
    },
  }),
  setPptxFile: (pptxFile: string) => ({
    type: SlidePptxExerciseActions.SET_PPTX,
    payload: {
      pptxFile,
    },
  }),
  uploadPptxFile: (pptx: File) => ({
    type: SlidePptxExerciseActions.UPLOAD_PPTX_FILE,
    payload: {
      pptx,
    },
  }),
};
