import { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import lodash from 'lodash';

import { Button, Loader, FilterInput } from '@features/theme';

import { VoiceArtistsType } from '../../types';
import { getVoiceArtists } from '../../_service';
import { MessageItem } from '../../commonStyles';
import { NewVoiceArtistModal } from '../../NewVoiceArtistModal';
import { TableContent } from './TableContent';

const NameFilter = styled(FilterInput)`
  width: 25rem;
  margin: 0.8rem 0;
  display: flex;
  align-self: flex-end;
`;

const VoiceArtistButton = styled(Button)`
  position: absolute;
  right: 0;
  top: -7.2rem;
`;

const VoiceArtistAbbr = styled.abbr`
  text-decoration: none !important;
`;

const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_CURRENT_PAGE = 1;
const DEFAULT_TOTAL_PAGES = 0;

export const VoiceArtistsTable = () => {
  const [voiceArtists, setVoiceArtists] = useState<VoiceArtistsType[]>([]);
  const [filteredVoiceArtists, setFilteredVoiceArtists] = useState<VoiceArtistsType[]>([]);
  const [visibleVoiceArtists, setVisibleVoiceArtists] = useState<VoiceArtistsType[]>([]);
  const [voiceArtistsLoading, setVoiceArtistsLoading] = useState<boolean>(false);
  const [voiceArtistsLoaded, setVoiceArtistsLoaded] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [voiceArtistName, setVoiceArtistName] = useState<string>('');
  const [showNewVoiceArtistModal, setShowNewVoiceArtistModal] = useState<boolean>(false);
  const [rowSize, setRowSize] = useState<number>(DEFAULT_PAGE_SIZE);

  // Pagination
  const [currentPage, setCurrentPage] = useState(DEFAULT_CURRENT_PAGE);
  const [totalPages, setTotalPages] = useState(DEFAULT_TOTAL_PAGES);

  const requestVoiceArtists = async () => {
    setVoiceArtistsLoaded(false);
    setVoiceArtistsLoading(true);

    getVoiceArtists()
      .then(({ data }) => {
        setVoiceArtists(data.voiceArtists);
        setTotalPages(Math.ceil(data.voiceArtists.length / rowSize));
      })
      .catch((error) => {
        setVoiceArtists([]);
        setTotalPages(0);
        setErrorMessage(error.response.data.detail);
      })
      .finally(() => {
        setVoiceArtistsLoaded(true);
        setVoiceArtistsLoading(false);
      });
  };

  useEffect(() => {
    requestVoiceArtists();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (voiceArtistName) {
      const filteredArtists = voiceArtists.filter((voiceArtist) =>
        voiceArtist.name.toLocaleLowerCase().includes(voiceArtistName.toLocaleLowerCase()),
      );
      setFilteredVoiceArtists(filteredArtists);
      setCurrentPage(DEFAULT_CURRENT_PAGE);
      setTotalPages(Math.ceil(filteredArtists.length / rowSize));
    } else {
      setFilteredVoiceArtists(voiceArtists);
      setCurrentPage(DEFAULT_CURRENT_PAGE);
      setTotalPages(Math.ceil(voiceArtists.length / rowSize));
    }
  }, [voiceArtists, voiceArtistName, rowSize]);

  useEffect(() => {
    setVisibleVoiceArtists(
      filteredVoiceArtists.slice(rowSize * (currentPage - 1), rowSize * (currentPage - 1) + rowSize),
    );
  }, [currentPage, filteredVoiceArtists, rowSize]);

  return (
    <>
      <NameFilter
        placeholder="Filter by name"
        value={voiceArtistName}
        onChange={lodash.debounce((exportId) => setVoiceArtistName(exportId), 300)}
      />
      <VoiceArtistButton onClick={() => setShowNewVoiceArtistModal(true)}>
        Add new <VoiceArtistAbbr title="Voice Artist">VA</VoiceArtistAbbr>
      </VoiceArtistButton>
      <NewVoiceArtistModal show={showNewVoiceArtistModal} onHide={() => setShowNewVoiceArtistModal(false)} />
      {voiceArtistsLoading && !voiceArtistsLoaded ? (
        <Loader size="L" />
      ) : (
        <>
          {errorMessage ? (
            <MessageItem>{errorMessage}</MessageItem>
          ) : (
            <TableContent
              voiceArtists={visibleVoiceArtists}
              currentPage={currentPage}
              totalPages={totalPages}
              rowSize={rowSize}
              onPageChange={setCurrentPage}
              setRowSize={setRowSize}
            />
          )}
        </>
      )}
    </>
  );
};
