import { takeLatest } from 'redux-saga/effects';
import { TranslationsPanelActions } from '@actions/TranslationsPanelActions';
import TranslationsPanelSagas from '@sagas/translationsPanel/definitions/TranslationsPanelSagasDefinition';
import type { AppDispatch } from '@redux/store';

export function* TranslationPanelRelatedSagas(dispatch: AppDispatch) {
  yield takeLatest(TranslationsPanelActions.SAVE, TranslationsPanelSagas.save, dispatch);
  yield takeLatest(TranslationsPanelActions.CHECK_OTHER_LOCATIONS, TranslationsPanelSagas.checkOtherLocations);
  yield takeLatest(TranslationsPanelActions.GET_BUNDLE_LOCATIONS, TranslationsPanelSagas.getBundleOtherLocations);
  yield takeLatest(TranslationsPanelActions.MAKE_COPY, TranslationsPanelSagas.makeCopy);
  yield takeLatest(TranslationsPanelActions.REMOVE_STRING, TranslationsPanelSagas.removeString);
}
