import React, { FunctionComponent, useEffect, useState } from 'react';
import { Sizes } from '@common/enums/Sizes';
import DraggableIcon from '@static/svg/drag-handler.svg';
import preloaderIcon from '@static/svg/preloader/preloaderSpinner.svg';
import { useIsEditAvailable } from '@features/content/courses';
import { DialogueCharacterProps } from './DialogueCharacterProps';
import AddIcon from './img/Plus.svg';

const DialogueCharacter: FunctionComponent<DialogueCharacterProps> = ({
  imgURL,
  onClick,
  selectable,
  onToggled,
  size = Sizes.large,
  rollovable = true,
  showAsActive = true,
  showAddButton = false,
  isToggled,
  showAsDraggable = false,
}: DialogueCharacterProps) => {
  const { isEditAvailable } = useIsEditAvailable();
  let [selected, setSelected] = useState(false);
  let [selectableFlag, setSelectableFlag] = useState(selectable);

  const imgEl = React.useRef<HTMLImageElement>(null);
  useEffect(() => {
    setSelectableFlag(selectable);
  }, [selectable]);

  useEffect(() => {
    if (onToggled) {
      onToggled(selected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  useEffect(() => {
    setSelected(!!isToggled);
  }, [isToggled]);

  const [loaded, setLoaded] = React.useState(false);

  const onImageLoaded = () => setLoaded(true);

  React.useEffect(() => {
    const imgElCurrent = imgEl.current;

    if (imgElCurrent) {
      imgElCurrent.addEventListener('load', onImageLoaded);
      return () => imgElCurrent.removeEventListener('load', onImageLoaded);
    }
  }, [imgEl]);

  let className = `${selected ? 'dialogue-character__photo--selected' : 'dialogue-character__photo'} ${
    selectableFlag || selected ? 'dialogue-character__photo--selectable' : 'dialogue-character__photo--non-selectable'
  }
  ${rollovable ? 'dialogue-character__photo--rollovable' : 'dialogue-character__photo--non-rollovable'}
  
  ${showAsActive ? 'dialogue-character__photo--show-as-active' : 'dialogue-character__photo--non-show-as-active'}`;

  return (
    <div
      className={`dialogue-character dialogue-character--${size}`}
      onClick={() => {
        if (!showAddButton) {
          if (selectable) {
            onClick && onClick(!selected);
          } else if (selected) {
            onClick && onClick(!selected);
          }
        }
      }}
    >
      {isToggled && <div className={`dialogue-character__border${selected ? '--visible' : '--invisible'}`} />}
      {showAddButton && (
        <div className="dialogue-character__add-icon-wrapper" onClick={() => onClick && onClick(!selected)}>
          <img src={AddIcon} alt="character" className="dialogue-character__add-icon" />
        </div>
      )}
      {isEditAvailable && showAsDraggable && (
        <div className="dialogue-character__draggable-icon-wrapper" onClick={() => onClick && onClick(!selected)}>
          <img src={DraggableIcon} alt="character" className="dialogue-character__draggable-icon" />
        </div>
      )}
      {!loaded && (
        <div className="dialogue-character__preloader">
          <img
            ref={imgEl}
            src={preloaderIcon}
            alt="Character preloading"
            className="dialogue-character__preloader-inner"
          />
        </div>
      )}
      {loaded && <img src={imgURL} alt="Character" className={className} />}
    </div>
  );
};

export default DialogueCharacter;
