import { FunctionComponent } from 'react';

import { KeyboardShortcutProps } from '../../../PreviewModalProps';

import './KeyboardShortcut.scss';

export const KeyboardShortcut: FunctionComponent<KeyboardShortcutProps> = ({
  answerState,
  value,
}: KeyboardShortcutProps) => {
  return <>{!answerState ? <div className="keyboard-shortcut">{value}</div> : null}</>;
};
