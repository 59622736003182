import { SelectedGroupsOfParentInterface } from '@common/interfaces/groups/SelectedGroupsOfParentInterface';
import { ValidationErrorDisplayer } from '@components/ValidationErrorDisplayer';
import { ValidationErrorInterface } from '@common/interfaces/validation/ValidationInterface';
import { useIsEditAvailable } from '@features/content/courses';
import { Loader } from '@features/theme';
import { WritableInputText } from '@components/WritableInputText';
import TranslationsTipV2 from '@components/TranslationTipV2/TranslationTipV2';
import { PublishingStatus } from '@components/Publishing/PublishingStatus';
import { ContentType, ContentTypes } from '@common/enums/ContentTypes';
import IDDisplayer from '@components/IDDisplayer/IDDisplayer';
import { DraggableList } from '@components/DraggableList/DraggableList';
import { LoadingStage } from '@common/enums/LoadingStage';
import helpersService from '@services/HelpersService';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { ContentTypesActionsCreator } from '@actionCreators/ContentTypesActionsCreator';
import { AccessWarning } from '@components/Warning';
import {
  selectCourse,
  selectSelectedGroupsOfParent,
  selectSelectedGroupsOfParentParentContents,
} from '@selectors/CoursesSelectors';
import { arrayMoveImmutable } from 'array-move';
import { clone } from '@helpers/clone';

export const LevelMainData = () => {
  const dispatch = useAppDispatch();
  const selectedGroupsOfParent: SelectedGroupsOfParentInterface = useAppSelector(selectSelectedGroupsOfParent);
  const { isEditAvailable } = useIsEditAvailable();

  const loadedCourse = useAppSelector(selectCourse);
  const loadedContent = useAppSelector(selectSelectedGroupsOfParentParentContents);

  const titleValidationErrors = loadedContent?.validation?.errors.filter(
    (error: ValidationErrorInterface) => error.field === 'title',
  );
  const childrenValidationErrors =
    loadedContent?.validation?.errors.filter((error: ValidationErrorInterface) => error.field === 'children') || [];

  if (!loadedContent) {
    return null;
  }

  const levelCEFRErrors = loadedContent.validation.errors.filter((error) => error.field === 'type.class');

  return (
    <div className="course-composer">
      <div className="course-edition__first-top-element">
        <PublishingStatus ready={loadedContent.ready} changeStatus={loadedContent.changeStatus} />
        <div id={`level-${loadedContent.id}`}>
          <IDDisplayer id={loadedContent.id} mode="normal" />
          <TranslationsTipV2
            visitedBranch={'titleWithLocalizations'}
            type={ContentType.levelOrLesson}
            content={loadedContent}
            errors={titleValidationErrors}
            isExercise={false}
            isLesson={false}
            className="content-name"
          >
            <WritableInputText fontSize="30" bold withoutBorder placeholder={'Untitled level'} id="level-title-input" />
          </TranslationsTipV2>
          <ValidationErrorDisplayer
            text={levelCEFRErrors.length > 0 ? 'CEFR is missing. Go back to course page to select it.' : ''}
          />
        </div>
        {!isEditAvailable && <AccessWarning />}
      </div>
      {selectedGroupsOfParent.groupsLoaded !== LoadingStage.loaded ? (
        <Loader size="L" />
      ) : (
        <DraggableList
          arrayOfChildren={selectedGroupsOfParent.groups}
          onDragEnd={(result) => {
            result.destination &&
              dispatch(
                ContentTypesActionsCreator.setOrder(
                  arrayMoveImmutable(
                    clone(selectedGroupsOfParent.groups),
                    result.source.index,
                    result.destination.index,
                  ),
                  result.source.index,
                  result.destination.index,
                  ContentTypes.level,
                  loadedContent.id,
                  loadedCourse.id,
                ),
              );
          }}
          onContentCreate={(contentTypeToCreate, contentCategory, position) => {
            dispatch(
              ContentTypesActionsCreator.onCreateContent(
                loadedCourse.id,
                contentTypeToCreate,
                ContentTypes.level,
                loadedContent.id,
                contentCategory,
                position,
              ),
            );
          }}
          creatorAcceptableButtons={{
            [ContentTypes.chapter]: true,
          }}
        />
      )}
      <ValidationErrorDisplayer text={helpersService.getValidationErrorMessageText(childrenValidationErrors)} />
    </div>
  );
};
