import moment from 'moment';

const CSAT_STORAGE_KEY = 'csat';

const initCSATState = () => ({
  ignoreTill: moment().add(1, 'months').toDate(), // ignore csat state for new users
  timeoutSequence: 0,
});

export const useCSATState = () => {
  const csatStorageItem = localStorage.getItem(CSAT_STORAGE_KEY);

  if (csatStorageItem === null) {
    localStorage.setItem(CSAT_STORAGE_KEY, JSON.stringify(initCSATState()));
  }

  const csatState = JSON.parse(csatStorageItem || '{}') || {};

  return {
    ready: !csatState.ignoreTill || Date.parse(csatState.ignoreTill) < Date.now(),
    timeoutSequence: csatState.timeoutSequence || 0,
    complete: () => {
      const monthLater = new Date();
      monthLater.setDate(monthLater.getDate() + 30);

      csatState.ignoreTill = monthLater;
      csatState.timeoutSequence = 0;
      localStorage.setItem(CSAT_STORAGE_KEY, JSON.stringify(csatState));
    },
    ignore: () => {
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);

      csatState.ignoreTill = tomorrow;
      csatState.timeoutSequence = csatState.timeoutSequence + 1;
      localStorage.setItem(CSAT_STORAGE_KEY, JSON.stringify(csatState));
    },
  };
};
