import { FunctionComponent } from 'react';
import { ActivityInterface } from '@common/interfaces/contentTypes/ActivityInterface';
import { GenericContentInterface } from '@common/interfaces/contentTypes/GenericContentInterface';
import { GrammarCategoryListItemType, GrammarTopicListItemType } from '@features/content/grammar';
import { ExerciseListItemType } from '@components/ContentTypes/ExerciseCard/types';
import { useIsEditAvailable } from '@features/content/courses';
import trashIcon from './img/trashNew.svg';
import { RoleplayListItemType } from '@features/content/roleplay';

interface RemoveCardButtonProps {
  hovered: boolean;
  onRemove: (e: React.MouseEvent<HTMLElement>) => void;
  content:
    | GenericContentInterface
    | ActivityInterface
    | GrammarCategoryListItemType
    | GrammarTopicListItemType
    | ExerciseListItemType
    | RoleplayListItemType;
}

export const RemoveCardButton: FunctionComponent<RemoveCardButtonProps> = ({
  hovered,
  onRemove,
  content,
}: RemoveCardButtonProps) => {
  const { isEditAvailable } = useIsEditAvailable();
  if (!isEditAvailable) {
    return null;
  }

  return (
    <>
      {!content.ready ? (
        <div
          style={{
            visibility: hovered ? 'visible' : 'hidden',
          }}
        >
          <img
            id={`remove-level-${content.id}`}
            onClick={onRemove}
            src={trashIcon}
            className="base-content__options-and-link__remove-button"
            alt=""
          />
        </div>
      ) : (
        <div className="base-content__options-and-link__empty-remove-button" />
      )}
    </>
  );
};
