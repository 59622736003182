import { CourseInterface } from '@common/interfaces/courses/CourseInterface';
import { TranslationsPanelContentInterface } from '@common/interfaces/exercises/TranslationsPanelContentInterface';
import { CourseSliceInterface } from '@common/interfaces/slices/CourseSliceInterface';
import { AnyLoadedExercise } from '@common/types/exercises/AnyLoadedExercise';
import ExerciseDataModelAssembler from '@components/Exercises/ExerciseDataModelAssembler';
import { TranslationsPanelActions } from '@actions/TranslationsPanelActions';
import { generateDefaultContext } from '@helpers/contextHelper';
import { clone } from '@helpers/clone';
import { TranslationTipActionsCreator } from '@actionCreators/TranslationTipActionsCreator';

const TranslationsPanelCourseReducers = {
  [TranslationsPanelActions.POPULATE_BRANCH]: (
    state: CourseSliceInterface,
    { payload }: ReturnType<typeof TranslationTipActionsCreator.populateBranch>,
  ): CourseSliceInterface => {
    let clonedState: CourseSliceInterface = clone(state);
    let loadedExercise: AnyLoadedExercise = clonedState.loadedExercise as AnyLoadedExercise;
    let loadedCourse: CourseInterface = clonedState.course as CourseInterface;

    const { visitedBranch, bundleName, row } = payload;

    if (loadedExercise.exercise?.content) {
      let contentsBranch;
      if (bundleName) {
        contentsBranch = loadedExercise.exercise.content[bundleName][visitedBranch];
      } else {
        contentsBranch = ['phrase0', 'phrase1', 'phrase2', 'phrase3'].includes(visitedBranch)
          ? loadedExercise.exercise.content.phrases[row || 0]
          : loadedExercise.exercise.content[visitedBranch];
      }

      if (contentsBranch === null) {
        if (bundleName) {
          loadedExercise.exercise.content[bundleName][visitedBranch] =
            ExerciseDataModelAssembler.generateTranslationsPanelContentPopulatedWithEmptyLocalizations();
        } else {
          const defaultDescription = generateDefaultContext(loadedExercise.exercise.content.type, visitedBranch);
          loadedExercise.exercise.content[visitedBranch] =
            ExerciseDataModelAssembler.generateTranslationsPanelContentPopulatedWithEmptyLocalizations(
              defaultDescription,
            );
        }
      } else {
        if (bundleName) {
          if (
            loadedExercise.exercise.content[bundleName][visitedBranch].audioLocalizations === null ||
            (Array.isArray(contentsBranch.audioLocalizations) && contentsBranch.audioLocalizations.length === 0)
          ) {
            loadedExercise.exercise.content[bundleName][visitedBranch].audioLocalizations =
              ExerciseDataModelAssembler.generateTranslationsPanelContentPopulatedWithEmptyLocalizations().audioLocalizations;
          }
        } else {
          if (['phrase0', 'phrase1', 'phrase2', 'phrase3'].includes(visitedBranch)) {
            if (
              loadedExercise.exercise.content.phrases[row || 0].audioLocalizations === null ||
              (Array.isArray(contentsBranch.audioLocalizations) && contentsBranch.audioLocalizations.length === 0)
            ) {
              loadedExercise.exercise.content.phrases[row || 0].audioLocalizations =
                ExerciseDataModelAssembler.generateTranslationsPanelContentPopulatedWithEmptyLocalizations().audioLocalizations;
            }
          } else {
            if (
              loadedExercise.exercise.content[visitedBranch].audioLocalizations === null ||
              (Array.isArray(contentsBranch.audioLocalizations) && contentsBranch.audioLocalizations.length === 0)
            ) {
              loadedExercise.exercise.content[visitedBranch].audioLocalizations =
                ExerciseDataModelAssembler.generateTranslationsPanelContentPopulatedWithEmptyLocalizations().audioLocalizations;
            }
          }
        }
      }

      return {
        ...clonedState,
        loadedExercise,
      };
    } else {
      if (visitedBranch !== 'learningOutcomes' && visitedBranch !== 'completeMessage') {
        let contentsBranch: TranslationsPanelContentInterface = (loadedCourse as any)[visitedBranch];

        if (contentsBranch === null) {
          (loadedCourse as any)[visitedBranch] =
            ExerciseDataModelAssembler.generateTranslationsPanelContentPopulatedWithEmptyLocalizations();
        } else {
          if (
            (loadedCourse as any)[visitedBranch].audioLocalizations === null ||
            (Array.isArray(contentsBranch.audioLocalizations) && contentsBranch.audioLocalizations.length === 0)
          ) {
            (loadedCourse as any)[visitedBranch].audioLocalizations =
              ExerciseDataModelAssembler.generateTranslationsPanelContentPopulatedWithEmptyLocalizations().audioLocalizations;
          }
        }
      }

      return {
        ...clonedState,
        course: loadedCourse,
      };
    }
  },
};

export default TranslationsPanelCourseReducers;
