import styled from 'styled-components';

import { HeaderWrapper } from './HeaderWrapper';

const ChapterHeaderWrapper = styled(HeaderWrapper)`
  grid-template-columns: 74% 25%;
`;

export const ChapterSearchResultTypeHeader = () => (
  <ChapterHeaderWrapper>
    <span>Chapter</span>
    <span>Location</span>
  </ChapterHeaderWrapper>
);
