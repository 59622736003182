const isStartWith = (file: File, bytes: (number | number[])[]) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();

    fileReader.onload = () => {
      const fileArrayBuffer = fileReader.result;
      const fileUint8Array = new Uint8Array(fileArrayBuffer as ArrayBufferLike);
      const ifStartWith = bytes.every((value, index) => {
        if (Array.isArray(value)) {
          return value.includes(fileUint8Array[index]);
        }
        return value === fileUint8Array[index];
      });

      resolve(ifStartWith);
    };

    fileReader.onerror = (error) => {
      reject(false);
    };

    fileReader.readAsArrayBuffer(file);
  });
};

export const isMp4 = async (file: File) => {
  const mp4MagicNumber = [0, 0, 0, [20, 24, 28, 32], 102, 116, 121, 112];
  const isFileStartsWithMp4MagicNumber = await isStartWith(file, mp4MagicNumber);
  return isFileStartsWithMp4MagicNumber;
};

const getImageSizeInPixels = (URL: string): Promise<number> => {
  return new Promise((resolve) => {
    const img: HTMLImageElement = document.createElement('img');
    img.style.display = 'none';
    img.src = URL;
    img.onload = () => resolve(img.width * img.height);
  });
};

const checkIfLimitExceeded = (file: File, limit: number): Promise<boolean> => {
  return new Promise((resolve) => {
    const fileReader = new FileReader();

    fileReader.onload = async () => {
      const imageSizeInPixels = await getImageSizeInPixels(fileReader.result as string);
      resolve(imageSizeInPixels > limit);
    };

    fileReader.readAsDataURL(file);
  });
};

export const checkIsImageMoreThanLimit = async (file: File) => {
  const limit = 16800000; // 16.8 megapixels
  const isLimitExceeded = await checkIfLimitExceeded(file, limit);
  return isLimitExceeded;
};
