import { PayloadAction } from '@reduxjs/toolkit';
import produce from 'immer';

import { GrammarReviewSliceType } from '@common/interfaces/slices/GrammarReviewSliceType';
import {
  GrammarCategoryListItemType,
  GrammarReviewContentType,
  GrammarCategoryContentType,
  GrammarCategoryEditableFieldNames,
  GrammarTopicContentType,
  GrammarTopicEditableFieldNames,
  GrammarCategoryFormikValues,
  GrammarTopicFormikValues,
  GrammarTopicListItemType,
} from '@features/content/grammar';
import { ExerciseListItemType } from '@components/ContentTypes/ExerciseCard/types';
import { ValidationInterface } from '@common/interfaces/validation/ValidationInterface';
import { ChangeStatusInterface } from '@common/interfaces/contentTypes/ChangeStatusInterface';
import { LoadingStage, type LoadingStageType } from '@common/enums/LoadingStage';
import { DBId } from '@common/types/DBId';
import { ContentTypesActions } from '@actions/ContentTypesActions';
import ExerciseDataModelAssembler from '@components/Exercises/ExerciseDataModelAssembler';
import { GrammarReviewActions } from '@actions/GrammarReviewActions';
import { ContentTypes, type ContentTypesType } from '@common/enums/ContentTypes';
import { TranslationsPanelContentInterface } from '@common/interfaces/exercises/TranslationsPanelContentInterface';
import { LocalizationInterface } from '@common/interfaces/localization/LocalizationInterface';
import {
  grammarReviewInitialContent,
  grammarCategoryInitialContent,
  grammarExerciseInitialContent,
  grammarTopicInitialContent,
} from '@redux/initialStates/grammarReviewInitialState';
import { PublishingActions } from '@actions/PublishingActions';
import { AnyExerciseContentInterface } from '@common/types/exercises/AnyExerciseContentInterface';
import { ContentOwnershipActions } from '@actions/ContentOwnershipActions';
import { SetContentOwnershipPayload } from '@features/content/contentOwnership';

export const GrammarReducers = {
  // Review reducers
  [GrammarReviewActions.GRAMMAR_REVIEW_LOADING]: (state: GrammarReviewSliceType) => {
    return produce(state, (draft) => {
      draft.grammarReview.loaded = LoadingStage.loading;
    });
  },
  [GrammarReviewActions.GRAMMAR_CATEGORIES_LOADING]: (state: GrammarReviewSliceType) => {
    return produce(state, (draft) => {
      draft.grammarReview.categoriesData.loaded = LoadingStage.loading;
    });
  },
  [GrammarReviewActions.SET_INITIAL_GRAMMAR_REVIEW]: (state: GrammarReviewSliceType) => {
    return produce(state, (draft) => {
      draft.grammarReview.loaded = LoadingStage.notLoaded;
      draft.grammarReview.content = grammarReviewInitialContent;
      draft.grammarReview.categoriesData.loaded = LoadingStage.notLoaded;
      draft.grammarReview.categoriesData.categories = [];
    });
  },
  [GrammarReviewActions.GRAMMAR_REVIEW_LOADED]: (
    state: GrammarReviewSliceType,
    { payload }: PayloadAction<GrammarReviewContentType>,
  ) => {
    return produce(state, (draft) => {
      draft.grammarReview.loaded = LoadingStage.loaded;
      draft.grammarReview.content = { ...payload, type: ContentTypes.grammarReview };
    });
  },
  [GrammarReviewActions.GRAMMAR_CATEGORIES_LOADED]: (
    state: GrammarReviewSliceType,
    { payload }: PayloadAction<GrammarCategoryListItemType[]>,
  ) => {
    return produce(state, (draft) => {
      draft.grammarReview.categoriesData.loaded = LoadingStage.loaded;
      draft.grammarReview.categoriesData.categories = payload;
      draft.grammarReview.categoriesData.categories = payload.map((category) => ({
        ...category,
        type: ContentTypes.grammarCategory,
      }));
    });
  },

  // Category reducers
  [GrammarReviewActions.GRAMMAR_CATEGORY_LOADING]: (state: GrammarReviewSliceType) => {
    return produce(state, (draft) => {
      draft.grammarCategory.loaded = LoadingStage.loading;
    });
  },
  [GrammarReviewActions.SET_INITIAL_GRAMMAR_CATEGORY]: (state: GrammarReviewSliceType) => {
    return produce(state, (draft) => {
      draft.grammarCategory.loaded = LoadingStage.notLoaded;
      draft.grammarCategory.content = grammarCategoryInitialContent;
      draft.grammarCategory.topicsData.loaded = LoadingStage.notLoaded;
      draft.grammarCategory.topicsData.topics = [];
    });
  },
  [GrammarReviewActions.GRAMMAR_CATEGORY_LOADED]: (
    state: GrammarReviewSliceType,
    { payload }: PayloadAction<GrammarCategoryContentType>,
  ) => {
    return produce(state, (draft) => {
      draft.grammarCategory.loaded = LoadingStage.loaded;
      draft.grammarCategory.content = {
        ...payload,
        title:
          payload.title?.textLocalizations?.length || payload.title?.audioLocalizations?.length
            ? {
                ...payload.title,
                ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranchesForSomeLanguages(
                  ['textLocalizations'],
                  payload.title?.textLocalizations,
                ),
                ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranchesForSomeLanguages(
                  ['audioLocalizations'],
                  payload.title?.audioLocalizations,
                ),
              }
            : {
                ...payload.title,
                ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranches([
                  'textLocalizations',
                  'audioLocalizations',
                ]),
              },
        description:
          payload.description?.textLocalizations?.length || payload.description?.audioLocalizations?.length
            ? {
                ...payload.description,
                ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranchesForSomeLanguages(
                  ['textLocalizations'],
                  payload.description?.textLocalizations,
                ),
                ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranchesForSomeLanguages(
                  ['audioLocalizations'],
                  payload.description?.audioLocalizations,
                ),
              }
            : {
                ...payload.description,
                ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranches([
                  'textLocalizations',
                  'audioLocalizations',
                ]),
              },
        type: ContentTypes.grammarCategory,
      };
    });
  },
  [GrammarReviewActions.SET_CATEGORY_ALL_VALUES]: (
    state: GrammarReviewSliceType,
    { payload: { values } }: PayloadAction<{ values: GrammarCategoryFormikValues }>,
  ): GrammarReviewSliceType => {
    return produce(state, (draft) => {
      if (values.titleChanged && draft.grammarCategory.content.title?.textLocalizations) {
        if (draft.grammarCategory.content.title?.isReused) {
          draft.grammarCategory.content.title.isReused = false;
        }
        draft.grammarCategory.content.title.textLocalizations =
          draft.grammarCategory.content.title.textLocalizations.map((loc) => ({
            ...loc,
            value: values.title.find((value) => value.language === loc.language && !value.isPhonetic)?.value || '',
            phoneticValue:
              values.title.find((value) => value.language === loc.language && value.isPhonetic)?.value || '',
          }));
      }
      if (values.descriptionChanged && draft.grammarCategory.content.description?.textLocalizations) {
        if (draft.grammarCategory.content.description?.isReused) {
          draft.grammarCategory.content.description.isReused = false;
        }
        draft.grammarCategory.content.description.textLocalizations =
          draft.grammarCategory.content.description?.textLocalizations.map((loc) => ({
            ...loc,
            value:
              values.description.find((value) => value.language === loc.language && !value.isPhonetic)?.value || '',
            phoneticValue:
              values.description.find((value) => value.language === loc.language && value.isPhonetic)?.value || '',
          }));
      }
    });
  },
  [GrammarReviewActions.GRAMMAR_TOPICS_LOADING]: (state: GrammarReviewSliceType) => {
    return produce(state, (draft) => {
      draft.grammarCategory.topicsData.loaded = LoadingStage.loading;
    });
  },
  [GrammarReviewActions.GRAMMAR_TOPICS_LOADED]: (
    state: GrammarReviewSliceType,
    { payload }: PayloadAction<GrammarTopicListItemType[]>,
  ) => {
    return produce(state, (draft) => {
      draft.grammarCategory.topicsData.loaded = LoadingStage.loaded;
      draft.grammarCategory.topicsData.topics = payload.map((topic) => ({ ...topic, type: ContentTypes.grammarTopic }));
    });
  },
  [GrammarReviewActions.SET_GRAMMAR_CATEGORY_TITLE]: (
    state: GrammarReviewSliceType,
    { payload }: PayloadAction<TranslationsPanelContentInterface>,
  ) => {
    return produce(state, (draft) => {
      draft.grammarCategory.content.title = {
        ...payload,
        ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranchesForSomeLanguages(
          ['textLocalizations'],
          payload.textLocalizations,
        ),
        ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranchesForSomeLanguages(
          ['audioLocalizations'],
          payload.audioLocalizations,
        ),
      };
    });
  },
  [GrammarReviewActions.SET_GRAMMAR_CATEGORY_ICON]: (
    state: GrammarReviewSliceType,
    { payload }: PayloadAction<{ svg: string; pdf: string }>,
  ) => {
    return produce(state, (draft) => {
      draft.grammarCategory.content.icon = payload;
      draft.grammarCategory.content.iconChanged = true;
    });
  },
  [GrammarReviewActions.SET_CATEGORY_TO_NOT_CHANGED]: (state: GrammarReviewSliceType) => {
    return produce(state, (draft) => {
      draft.grammarCategory.content.iconChanged = false;
      draft.grammarCategory.content.titleChanged = false;
      draft.grammarCategory.content.descriptionChanged = false;
    });
  },

  // Topic reducers
  [GrammarReviewActions.SET_INITIAL_GRAMMAR_TOPIC]: (state: GrammarReviewSliceType) => {
    return produce(state, (draft) => {
      draft.grammarTopic.loaded = LoadingStage.notLoaded;
      draft.grammarTopic.content = grammarTopicInitialContent;
      draft.grammarTopic.exercisesData.loaded = LoadingStage.notLoaded;
      draft.grammarTopic.exercisesData.exercises.referenceExercises = [];
      draft.grammarTopic.exercisesData.exercises.bankExercises = [];
    });
  },
  [GrammarReviewActions.UPDATE_CEFR]: (state: GrammarReviewSliceType, { payload }: PayloadAction<{ cefr: string }>) => {
    return produce(state, (draft) => {
      draft.grammarTopic.content.cefr = payload.cefr;
    });
  },
  [GrammarReviewActions.SET_LINKED_LESSONS]: (
    state: GrammarReviewSliceType,
    { payload: { lessonIds } }: PayloadAction<{ lessonIds: string[] }>,
  ) => {
    return produce(state, (draft) => {
      draft.grammarTopic.content.attachedLessons = lessonIds;
    });
  },
  [GrammarReviewActions.SET_ATTACHED_LESSONS_STATUS]: (
    state: GrammarReviewSliceType,
    { payload }: PayloadAction<LoadingStageType>,
  ) =>
    produce(state, (draft) => {
      draft.grammarTopic.content.attachedLessonsSaveProgress = payload;
    }),
  [GrammarReviewActions.SET_TOPIC_ALL_VALUES]: (
    state: GrammarReviewSliceType,
    { payload: { values } }: PayloadAction<{ values: GrammarTopicFormikValues }>,
  ): GrammarReviewSliceType => {
    return produce(state, (draft) => {
      if (values.titleChanged && draft.grammarTopic.content.title?.textLocalizations) {
        if (draft.grammarTopic.content.title?.isReused) {
          draft.grammarTopic.content.title.isReused = false;
        }
        draft.grammarTopic.content.title.textLocalizations = draft.grammarTopic.content.title.textLocalizations.map(
          (loc) => ({
            ...loc,
            value: values.title.find((value) => value.language === loc.language && !value.isPhonetic)?.value || '',
            phoneticValue:
              values.title.find((value) => value.language === loc.language && value.isPhonetic)?.value || '',
          }),
        );
      }
      if (values.descriptionChanged && draft.grammarTopic.content.description?.textLocalizations) {
        if (draft.grammarTopic.content.description?.isReused) {
          draft.grammarTopic.content.description.isReused = false;
        }
        draft.grammarTopic.content.description.textLocalizations =
          draft.grammarTopic.content.description?.textLocalizations.map((loc) => ({
            ...loc,
            value:
              values.description.find((value) => value.language === loc.language && !value.isPhonetic)?.value || '',
            phoneticValue:
              values.description.find((value) => value.language === loc.language && value.isPhonetic)?.value || '',
          }));
      }
    });
  },
  [GrammarReviewActions.GRAMMAR_TOPIC_LOADING]: (state: GrammarReviewSliceType) => {
    return produce(state, (draft) => {
      draft.grammarTopic.loaded = LoadingStage.loading;
    });
  },
  [GrammarReviewActions.GRAMMAR_TOPIC_LOADED]: (
    state: GrammarReviewSliceType,
    { payload }: PayloadAction<GrammarTopicContentType>,
  ) => {
    return produce(state, (draft) => {
      draft.grammarTopic.loaded = LoadingStage.loaded;
      draft.grammarTopic.content = {
        ...payload,
        title:
          payload.title?.textLocalizations?.length || payload.title?.audioLocalizations?.length
            ? {
                ...payload.title,
                ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranchesForSomeLanguages(
                  ['textLocalizations'],
                  payload.title?.textLocalizations,
                ),
                ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranchesForSomeLanguages(
                  ['audioLocalizations'],
                  payload.title?.audioLocalizations,
                ),
              }
            : {
                ...payload.title,
                ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranches([
                  'textLocalizations',
                  'audioLocalizations',
                ]),
              },
        description:
          payload.description?.textLocalizations?.length || payload.description?.audioLocalizations?.length
            ? {
                ...payload.description,
                ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranchesForSomeLanguages(
                  ['textLocalizations'],
                  payload.description?.textLocalizations,
                ),
                ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranchesForSomeLanguages(
                  ['audioLocalizations'],
                  payload.description?.audioLocalizations,
                ),
              }
            : {
                ...payload.description,
                ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranches([
                  'textLocalizations',
                  'audioLocalizations',
                ]),
              },
        type: ContentTypes.grammarTopic,
      };
    });
  },
  [GrammarReviewActions.SET_TOPIC_TO_NOT_CHANGED]: (state: GrammarReviewSliceType) => {
    return produce(state, (draft) => {
      draft.grammarTopic.content.titleChanged = false;
      draft.grammarTopic.content.descriptionChanged = false;
    });
  },
  [GrammarReviewActions.GRAMMAR_EXERCISES_LOADING]: (state: GrammarReviewSliceType) => {
    return produce(state, (draft) => {
      draft.grammarTopic.exercisesData.loaded = LoadingStage.loading;
    });
  },
  [GrammarReviewActions.GRAMMAR_EXERCISES_LOADED]: (
    state: GrammarReviewSliceType,
    {
      payload: { bankExercises, referenceExercises },
    }: PayloadAction<{
      referenceExercises?: ExerciseListItemType[];
      bankExercises?: ExerciseListItemType[];
    }>,
  ) => {
    return produce(state, (draft) => {
      draft.grammarTopic.exercisesData.loaded = LoadingStage.loaded;
      draft.grammarTopic.exercisesData.exercises.referenceExercises = referenceExercises
        ? referenceExercises
        : draft.grammarTopic.exercisesData.exercises.referenceExercises;
      draft.grammarTopic.exercisesData.exercises.bankExercises = bankExercises
        ? bankExercises
        : draft.grammarTopic.exercisesData.exercises.bankExercises;
    });
  },

  [PublishingActions.ON_MULTI_PUBLISH_SUCCESS]: (
    state: GrammarReviewSliceType,
    { payload: { exerciseIds } }: PayloadAction<{ exerciseIds: string[] }>,
  ) =>
    produce(state, (draftState) => {
      draftState.grammarTopic.exercisesData.exercises.bankExercises =
        draftState.grammarTopic.exercisesData.exercises.bankExercises.map((exercise) =>
          exerciseIds.includes(exercise.id) ? { ...exercise, ready: true } : exercise,
        );
      draftState.grammarTopic.exercisesData.exercises.referenceExercises =
        draftState.grammarTopic.exercisesData.exercises.referenceExercises.map((exercise) =>
          exerciseIds.includes(exercise.id) ? { ...exercise, ready: true } : exercise,
        );
      draftState.navigation.structure = draftState.navigation.structure.map((exercise) =>
        exerciseIds.includes(exercise.id) ? { ...exercise, ready: true } : exercise,
      );
    }),
  [PublishingActions.ON_MULTI_UNPUBLISH_SUCCESS]: (
    state: GrammarReviewSliceType,
    { payload: { exerciseIds } }: PayloadAction<{ exerciseIds: string[] }>,
  ) =>
    produce(state, (draftState) => {
      draftState.grammarTopic.exercisesData.exercises.bankExercises =
        draftState.grammarTopic.exercisesData.exercises.bankExercises.map((exercise) =>
          exerciseIds.includes(exercise.id) ? { ...exercise, ready: false } : exercise,
        );
      draftState.grammarTopic.exercisesData.exercises.referenceExercises =
        draftState.grammarTopic.exercisesData.exercises.referenceExercises.map((exercise) =>
          exerciseIds.includes(exercise.id) ? { ...exercise, ready: false } : exercise,
        );
      draftState.navigation.structure = draftState.navigation.structure.map((exercise) =>
        exerciseIds.includes(exercise.id) ? { ...exercise, ready: false } : exercise,
      );
    }),

  // Exercise reducers
  [GrammarReviewActions.SET_GRAMMAR_EXERCISE]: (
    state: GrammarReviewSliceType,
    { payload }: PayloadAction<AnyExerciseContentInterface>,
  ) => {
    return produce(state, (draft) => {
      draft.grammarExercise.loaded = LoadingStage.loaded;
      draft.grammarExercise.content = payload.content;
      draft.navigation.structure = draft.navigation.structure.map((structureItem) =>
        structureItem.id === payload.content.id
          ? { ...structureItem, experiment: payload.content.experiment }
          : structureItem,
      );
    });
  },
  [GrammarReviewActions.SET_INITIAL_GRAMMAR_EXERCISE]: (state: GrammarReviewSliceType) => {
    return produce(state, (draft) => {
      draft.grammarExercise.loaded = LoadingStage.notLoaded;
      draft.grammarExercise.content = grammarExerciseInitialContent;
    });
  },

  // Navigation reducers
  [GrammarReviewActions.UPDATE_NAVIGATION]: (state: GrammarReviewSliceType, { payload }: PayloadAction<any>) => {
    return produce(state, (draft) => {
      draft.navigation.structure = payload;
    });
  },
  [GrammarReviewActions.SET_LOADING_PARENT_ID]: (state: GrammarReviewSliceType, { payload }: PayloadAction<DBId>) => {
    return produce(state, (draft) => {
      draft.navigation.loadingParentId = payload;
    });
  },

  // Content Ownership reducers
  [ContentOwnershipActions.SET_GRAMMAR_REVIEW_OWNER]: (
    state: GrammarReviewSliceType,
    { payload: { owner } }: PayloadAction<SetContentOwnershipPayload>,
  ) => {
    return produce(state, (draft) => {
      draft.grammarReview.content.owner = owner;
    });
  },
  [ContentOwnershipActions.SET_GRAMMAR_CATEGORY_OWNER]: (
    state: GrammarReviewSliceType,
    { payload: { owner } }: PayloadAction<SetContentOwnershipPayload>,
  ) => {
    return produce(state, (draft) => {
      draft.grammarCategory.content.owner = owner;
    });
  },
  [ContentOwnershipActions.SET_GRAMMAR_TOPIC_OWNER]: (
    state: GrammarReviewSliceType,
    { payload: { owner } }: PayloadAction<SetContentOwnershipPayload>,
  ) => {
    return produce(state, (draft) => {
      draft.grammarTopic.content.owner = owner;
    });
  },

  // Common reducers
  [GrammarReviewActions.SET_VALIDATION_RESULT]: (
    state: GrammarReviewSliceType,
    {
      payload,
    }: PayloadAction<{
      type: ContentTypesType;
      data: { validationStatus: ValidationInterface; changeStatus: ChangeStatusInterface };
    }>,
  ) => {
    const { type: contentType, data: validationResult } = payload;

    switch (contentType) {
      case ContentTypes.grammarReview: {
        return produce(state, (draft) => {
          draft.grammarReview.content.validationStatus = validationResult.validationStatus;
          draft.grammarReview.content.changeStatus = validationResult.changeStatus;
        });
      }
      case ContentTypes.grammarCategory: {
        return produce(state, (draft) => {
          draft.grammarCategory.content.validationStatus = validationResult.validationStatus;
          draft.grammarCategory.content.changeStatus = validationResult.changeStatus;
        });
      }
      case ContentTypes.grammarTopic: {
        return produce(state, (draft) => {
          draft.grammarTopic.content.validationStatus = validationResult.validationStatus;
          draft.grammarTopic.content.changeStatus = validationResult.changeStatus;
        });
      }

      default: {
        console.log('Unknown grammar type');
      }
    }
  },
  [GrammarReviewActions.SET_STRING]: (
    state: GrammarReviewSliceType,
    {
      payload: { newString: notFilledNewString, contentType, fieldName, isReuse = false },
    }: PayloadAction<{
      newString: TranslationsPanelContentInterface;
      contentType: ContentTypesType;
      fieldName: GrammarCategoryEditableFieldNames | GrammarTopicEditableFieldNames;
      isReuse?: boolean;
    }>,
  ): GrammarReviewSliceType => {
    const newString = {
      ...notFilledNewString,
      ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranchesForSomeLanguages(
        ['textLocalizations'],
        notFilledNewString.textLocalizations,
      ),
      ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranchesForSomeLanguages(
        ['audioLocalizations'],
        notFilledNewString.audioLocalizations,
      ),
      isReused: isReuse,
    };

    switch (contentType) {
      case ContentTypes.grammarCategory: {
        return produce(state, (draft) => {
          draft.grammarCategory.content[fieldName] = newString;
          draft.grammarCategory.content[`${fieldName}Changed`] = true;
        });
      }
      case ContentTypes.grammarTopic: {
        return produce(state, (draft) => {
          draft.grammarTopic.content[fieldName] = newString;
          draft.grammarTopic.content[`${fieldName}Changed`] = true;
        });
      }
      default: {
        console.log('Unknown type');
        return state;
      }
    }
  },
  [ContentTypesActions.EDIT_EVERYWHERE]: (
    state: GrammarReviewSliceType,
    {
      payload: { contentType, visitedBranch },
    }: PayloadAction<{
      contentType: ContentTypesType;
      visitedBranch: GrammarCategoryEditableFieldNames | GrammarTopicEditableFieldNames;
      contentId: DBId;
    }>,
  ): GrammarReviewSliceType => {
    switch (contentType) {
      case ContentTypes.grammarCategory: {
        return produce(state, (draft) => {
          const fieldToUpdate = draft.grammarCategory.content[visitedBranch];
          if (fieldToUpdate) {
            fieldToUpdate.isReusingConfirmed = true;
          }
        });
      }
      case ContentTypes.grammarTopic: {
        return produce(state, (draft) => {
          const fieldToUpdate = draft.grammarTopic.content[visitedBranch];
          if (fieldToUpdate) {
            fieldToUpdate.isReusingConfirmed = true;
          }
        });
      }
      default: {
        console.log('Unknown type');
        return state;
      }
    }
  },
  [GrammarReviewActions.SET_CONTENT_ID]: (
    state: GrammarReviewSliceType,
    {
      payload: { contentId, contentType, fieldName },
    }: PayloadAction<{
      contentType: ContentTypesType;
      fieldName: GrammarCategoryEditableFieldNames;
      contentId: DBId;
    }>,
  ): GrammarReviewSliceType => {
    switch (contentType) {
      case ContentTypes.grammarCategory: {
        return produce(state, (draft) => {
          const fieldToUpdate = draft.grammarCategory.content[fieldName];
          if (fieldToUpdate) {
            fieldToUpdate.id = contentId;
          }
        });
      }
      case ContentTypes.grammarTopic: {
        return produce(state, (draft) => {
          const fieldToUpdate = draft.grammarTopic.content[fieldName];
          if (fieldToUpdate) {
            fieldToUpdate.id = contentId;
          }
        });
      }
      default: {
        console.log('Unknown type');
        return state;
      }
    }
  },
  [GrammarReviewActions.SET_AUDIO]: (
    state: GrammarReviewSliceType,
    {
      payload: { localization, fieldName, mediaURL, type, contentId },
    }: PayloadAction<{
      localization: LocalizationInterface;
      fieldName: GrammarCategoryEditableFieldNames;
      mediaURL: string;
      type: ContentTypesType;
      contentId: DBId;
    }>,
  ): GrammarReviewSliceType => {
    switch (type) {
      case ContentTypes.grammarCategory:
        return produce(state, (draft) => {
          draft.grammarCategory.content[`${fieldName}Changed`] = true;
          if (contentId) {
            const fieldToUpdate = draft.grammarCategory.content[fieldName];
            if (fieldToUpdate) {
              fieldToUpdate.id = contentId;
            }
          }

          let foundLocalization = draft.grammarCategory.content[fieldName]?.audioLocalizations.find(
            (loc) => loc.language === localization.language,
          );

          if (foundLocalization) {
            foundLocalization = {
              ...foundLocalization,
              value: !mediaURL ? '' : foundLocalization.value,
              fileId: mediaURL || null,
              _id: mediaURL || undefined,
              type: 'audio',
              processed: false,
            };
          }
        });
      case ContentTypes.grammarTopic:
        return produce(state, (draft) => {
          draft.grammarTopic.content[`${fieldName}Changed`] = true;
          if (contentId) {
            const fieldToUpdate = draft.grammarTopic.content[fieldName];
            if (fieldToUpdate) {
              fieldToUpdate.id = contentId;
            }
          }

          let foundLocalization = draft.grammarTopic.content[fieldName]?.audioLocalizations.find(
            (loc) => loc.language === localization.language,
          );

          if (foundLocalization) {
            foundLocalization = {
              ...foundLocalization,
              value: !mediaURL ? '' : foundLocalization.value,
              fileId: mediaURL || null,
              _id: mediaURL || undefined,
              type: 'audio',
              processed: false,
            };
          }
        });
      default:
        return state;
    }
  },
  [GrammarReviewActions.REMOVE_AUDIO]: (
    state: GrammarReviewSliceType,
    {
      payload: { localization, fieldName, type },
    }: PayloadAction<{
      localization: LocalizationInterface;
      fieldName: GrammarCategoryEditableFieldNames;
      type: ContentTypesType;
    }>,
  ): GrammarReviewSliceType => {
    switch (type) {
      case ContentTypes.grammarCategory:
        return produce(state, (draft) => {
          draft.grammarCategory.content[`${fieldName}Changed`] = true;

          draft.grammarCategory.content[fieldName].audioLocalizations = draft.grammarCategory.content[
            fieldName
          ].audioLocalizations.map((loc) =>
            loc.language === localization.language
              ? {
                  ...loc,
                  value: '',
                  fileId: null,
                  _id: undefined,
                  processed: false,
                }
              : loc,
          );
        });
      case ContentTypes.grammarTopic:
        return produce(state, (draft) => {
          draft.grammarTopic.content[`${fieldName}Changed`] = true;

          draft.grammarTopic.content[fieldName].audioLocalizations = draft.grammarTopic.content[
            fieldName
          ].audioLocalizations.map((loc) =>
            loc.language === localization.language
              ? {
                  ...loc,
                  value: '',
                  fileId: null,
                  _id: undefined,
                  processed: false,
                }
              : loc,
          );
        });
      default:
        return state;
    }
  },
  [PublishingActions.CHANGE_READY_STATE]: (
    state: GrammarReviewSliceType,
    {
      payload: { contentType, id, forUpdate },
    }: PayloadAction<{ contentType: ContentTypesType; id: DBId; forUpdate: boolean }>,
  ) => {
    switch (contentType) {
      case ContentTypes.grammarReview: {
        return produce(state, ({ grammarReview }) => {
          grammarReview.content.ready = forUpdate || !state.grammarReview.content.ready;
          grammarReview.content.changeStatus.hasNewChanges = state.grammarReview.content.ready && !forUpdate;
          grammarReview.content.changeStatus.hasPendingChanges = true;
        });
      }
      case ContentTypes.grammarCategory: {
        return produce(state, ({ grammarReview, grammarCategory }) => {
          grammarCategory.content.ready = forUpdate || !state.grammarCategory.content.ready;
          grammarCategory.content.changeStatus.hasNewChanges = state.grammarCategory.content.ready && !forUpdate;
          grammarCategory.content.changeStatus.hasPendingChanges = true;

          grammarReview.categoriesData.categories = grammarReview.categoriesData.categories.map((category) =>
            category.id === id
              ? {
                  ...category,
                  ready: forUpdate || !category.ready,
                  changeStatus: { hasNewChanges: category.ready && !forUpdate, hasPendingChanges: true },
                }
              : category,
          );
        });
      }
      case ContentTypes.grammarTopic: {
        return produce(state, ({ grammarTopic, grammarCategory }) => {
          grammarTopic.content.ready = forUpdate || !state.grammarTopic.content.ready;
          grammarTopic.content.changeStatus.hasNewChanges = state.grammarTopic.content.ready && !forUpdate;
          grammarTopic.content.changeStatus.hasPendingChanges = true;

          grammarCategory.topicsData.topics = grammarCategory.topicsData.topics.map((topic) =>
            topic.id === id
              ? {
                  ...topic,
                  ready: forUpdate || !topic.ready,
                  changeStatus: { hasNewChanges: topic.ready && !forUpdate, hasPendingChanges: true },
                }
              : topic,
          );
        });
      }
      case ContentTypes.exercise: {
        return produce(state, ({ grammarExercise, grammarTopic }) => {
          grammarExercise.content.ready = forUpdate || !state.grammarExercise.content.ready;
          grammarExercise.content.changeStatus.hasNewChanges = state.grammarExercise.content.ready && !forUpdate;
          grammarExercise.content.changeStatus.hasPendingChanges = true;

          grammarTopic.exercisesData.exercises.referenceExercises =
            grammarTopic.exercisesData.exercises.referenceExercises.map((exercise) =>
              exercise.id === id
                ? {
                    ...exercise,
                    ready: forUpdate || !exercise.ready,
                    changeStatus: { hasNewChanges: exercise.ready && !forUpdate, hasPendingChanges: true },
                  }
                : exercise,
            );
          grammarTopic.exercisesData.exercises.bankExercises = grammarTopic.exercisesData.exercises.bankExercises.map(
            (exercise) =>
              exercise.id === id
                ? {
                    ...exercise,
                    ready: forUpdate || !exercise.ready,
                    changeStatus: { hasNewChanges: exercise.ready && !forUpdate, hasPendingChanges: true },
                  }
                : exercise,
          );
        });
      }
      default: {
        console.error('Grammar Review: Unknown content type on publish');
        return state;
      }
    }
  },
};
