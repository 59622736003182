/* @TODO Move to @features/content/_utils/localization */

import { LocalizationInterface } from '@common/interfaces/localization/LocalizationInterface';
import { brTag, removeBrTag } from '@helpers/htmlTagsHelper';
import { FormikValueInterface } from '@helpers/formikInitialValuesHelper';
import { Language } from '@features/content/languages';

import { DEFAULT_LANGUAGE } from './constants';

export const openKTag = '[k]';
export const closedKTag = '[/k]';

export const getValueByLanguage = (
  localizedContent: (LocalizationInterface | FormikValueInterface)[] | undefined,
  language?: string,
): string => {
  if (localizedContent && localizedContent.length) {
    if (language) {
      return (
        localizedContent
          .find((localization) => localization.language === language)
          // Need to replace '\n' to brTag to display string the same like for clients
          ?.value?.replace(/\n/g, brTag)
          ?.replace(/&amp;/gi, '&') || ''
      );
    }

    return (
      localizedContent
        .find((localization) => localization.language === DEFAULT_LANGUAGE)
        // Need to replace '\n' to brTag to display string the same like for clients
        ?.value?.replace(/\n/g, brTag)
        ?.replace(/&amp;/gi, '&') || ''
    );
  }
  return '';
};

export const getIdByLanguage = (localizedContent: LocalizationInterface[] | undefined, language?: string): string => {
  if (localizedContent && localizedContent.length) {
    if (language) {
      return (
        localizedContent.find((localization: LocalizationInterface) => localization.language === language)?._id || ''
      );
    }
    return (
      localizedContent.find((localization: LocalizationInterface) => localization.language === DEFAULT_LANGUAGE)?._id ||
      ''
    );
  }
  return '';
};

export const getProcessedByLanguage = (localizedContent?: LocalizationInterface[], language?: string): boolean => {
  if (localizedContent && localizedContent.length) {
    if (language) {
      return localizedContent.find((localization) => localization.language === language)?.processed || false;
    }
    return localizedContent.find((localization) => localization.language === DEFAULT_LANGUAGE)?.processed || false;
  }
  return false;
};

export const getCorrectPhrase = (phrase: string): string => {
  if (phrase && phrase.length) {
    return phrase
      .replaceAll(openKTag, '')
      .replaceAll(closedKTag, '')
      .replaceAll(openKTag.toUpperCase(), '')
      .replaceAll(closedKTag.toUpperCase(), '');
  }
  return '';
};

export const findLocalizationInSearchedLanguage = (
  localizations: LocalizationInterface[],
  language: Language,
): LocalizationInterface | undefined => {
  return (localizations || []).find((localization) => {
    return localization.language === language;
  });
};

export const createGhostLocalization = (language: Language): LocalizationInterface => {
  return {
    _id: '',
    language,
    value: '',
    processed: true,
  };
};

export const isLocalizationNeedsToBeCounted = (
  localization: LocalizationInterface,
  interfaceLanguages: Language[],
  learningLanguage: Language,
): boolean => {
  const allCountedLanguages = new Set<Language>([...interfaceLanguages, learningLanguage, DEFAULT_LANGUAGE]);

  return allCountedLanguages.has(localization.language as Language);
};

export const isLocalizationFilled = (localization: LocalizationInterface): boolean => {
  return Boolean(removeBrTag(localization.value));
};

export const formatCounterOfFilledLocalizations = (
  localizations: LocalizationInterface[],
  interfaceLanguages: Language[],
  learningLanguage: Language,
): string => {
  const allRequiredLanguages = new Set<Language>([...interfaceLanguages, learningLanguage, DEFAULT_LANGUAGE]);

  const numberOfFilledLocalizations = localizations.filter(
    (localization: LocalizationInterface) =>
      isLocalizationFilled(localization) &&
      isLocalizationNeedsToBeCounted(localization, interfaceLanguages, learningLanguage),
  ).length;

  return `${numberOfFilledLocalizations}/${allRequiredLanguages.size}`;
};
