import { Button } from 'react-bootstrap';
import styled, { css, keyframes } from 'styled-components/macro';

import { Flag } from '@components/Flag';

import { ReactComponent as SuggestionsSpinner } from './img/suggestionsSpinner.svg';

export const TranslationsTipWrapper = styled.div`
  position: relative;
`;

export const TranslationsTipWidget = styled.div<{
  isWidgetActive: boolean;
  isWidgetVisible: boolean;
}>`
  ${({ isWidgetActive, isWidgetVisible }) => css`
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    opacity: ${isWidgetActive ? '1' : '0'};
    padding-bottom: 0.5rem;
    position: absolute;
    right: 0;
    transition: opacity 0.3s;
    width: 100%;
    z-index: ${isWidgetVisible ? '4000' : '-1'};
  `}
`;

export const TranslationsLanguageSwitch = styled.div<{ forTrueFalse?: boolean; withImage?: boolean }>`
  align-items: center;
  border: 0.1rem solid ${({ theme }) => theme.colorV2.input.border.default};
  border-radius: 0.8rem;
  box-shadow: 0 0.4rem 0.8rem rgba(0, 0, 0, 0.1);
  justify-content: space-between;
  margin: 0 0.8rem 0.8rem 0;
  display: flex;
  position: absolute;
  top: -4.4rem;

  ${({ forTrueFalse }) =>
    forTrueFalse &&
    `
    margin-right: 1.6rem;
  `}

  ${({ withImage }) =>
    withImage &&
    `
    bottom: 7rem;
    right: 12rem;
  `}
`;

export const LanguageSwitchButton = styled(Button)`
  background: transparent;
  border-radius: 0 0.6rem 0.6rem 0;
  border: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  width: 2.8rem;

  &:first-child {
    border-radius: 0.6rem 0 0 0.6rem;
  }
`;

export const StyledFlag = styled(Flag)<{ isActive: boolean }>`
  background-color: ${({ isActive, theme }) =>
    isActive ? theme.colorV2.uiBackgroundPrimary : theme.colorV2.uiBackgroundSecondary};
  border-radius: 0;
  padding: 0.6rem;

  img {
    opacity: ${({ isActive }) => (isActive ? '1' : '0.5')};
  }
`;

export const TranslationTipOptions = styled.ul<{
  compact: boolean;
  hideContentSuggestions?: boolean;
  hideRemove?: boolean;
  hideSearch?: boolean;
}>`
  ${({ hideContentSuggestions, theme }) => css`
    background-color: ${theme.colorV2.uiBackgroundPrimary};
    border: 0.1rem solid ${theme.colorV2.input.border.default};
    border-radius: 0.8rem;
    box-shadow: 0 0.4rem 0.8rem rgba(0, 0, 0, 0.1);
    color: ${theme.colorV2.textSecondary};
    cursor: pointer;
    display: flex;
    font-size: 1.2rem;
    justify-content: flex-end;
    list-style: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    text-indent: 0;
    width: ${hideContentSuggestions ? 'auto' : '100%'};
  `}

  ${({ compact, hideContentSuggestions, theme }) => {
    if (compact) {
      return `
        display: grid;
        grid-template-columns: repeat(3, 1fr) 4rem;
        grid-template-rows: ${hideContentSuggestions ? 'none' : 'repeat(2, 4rem)'};

        ${TranslationTipOptionSuggestions} {
          border-bottom: 0.1rem solid ${theme.colorV2.input.border.default};
          border-right: 0;
          grid-area: 1 / 1 / 2 / 5;
        }
      `;
    }
  }}
`;

export const TranslationTipOption = styled.li<{ onlyIcon?: boolean }>`
  ${({ onlyIcon, theme }) => css`
    align-items: center;
    border-right: 0.1rem solid ${theme.colorV2.input.border.default};
    display: flex;
    line-height: 1;
    padding: 0.8rem 1.2rem;
    width: ${onlyIcon ? '4rem' : 'auto'};

    &:last-child {
      border-right: 0;
    }

    svg {
      display: inline-block;
      margin: ${onlyIcon ? '0 auto' : '0 0.6rem 0 0'};
      min-width: 1.6rem;
      height: 1.6rem;
      width: 1.6rem;

      &:hover {
        cursor: pointer;
      }
    }
  `}
`;

export const TranslationTipOptionSuggestions = styled(TranslationTipOption)`
  color: ${({ theme }) => theme.colorV2.accentPrimary};
  cursor: auto;
  flex-grow: 1;
`;

export const TruncatedContentId = styled.span`
  display: inline-block;
  margin-bottom: 0;
  margin-top: 0;
  max-width: 8.6rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
`;

export const SuggestionWrapper = styled.div<{ showMatches: boolean }>`
  cursor: ${({ showMatches }) => (showMatches ? 'pointer' : 'default')};
  overflow: hidden;
  text-overflow: ellipsis;
  opacity: ${({ showMatches }) => (showMatches ? '1' : '0')};
  white-space: pre;

  strong {
    color: ${({ theme }) => theme.colorV2.textPrimary};
  }
`;

const suggestionsSpinnerAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }`;

export const SuggestionsSpinnerAnimated = styled(SuggestionsSpinner)`
  animation-name: ${suggestionsSpinnerAnimation};
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
`;

export const SuggestionsQuery = styled.strong`
  color: ${({ theme }) => theme.colorV2.textPrimary};
  display: inline-block;
  max-width: 24rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;

  strong {
    color: ${({ theme }) => theme.colorV2.textPrimary};
  }
`;
