import { useEffect, useState } from 'react';
import styled from 'styled-components/macro';

import { BasePopover, BasePopoverPosition, Loader } from '@features/theme';

import { HelpContent } from './HelpContent';
import { HelpForm } from './HelpForm';
import { editHelpArticle } from '../_services/editHelpArticle';
import { Modal, useDialogModal } from '@features/modal';
import { UnsavedChangesModal } from './UnsavedChangesModal';
import { ReactComponent as InfoIcon } from './img/info.svg';
import { ReactComponent as BulbIcon } from './img/bulb.svg';
import { HelpDataType, HelpType } from '../types';

const StyledPopover = styled(BasePopover)<{ isEditing: boolean; withCustomBackground: boolean }>`
  height: ${({ isEditing }) => (isEditing ? '50rem' : '33.8rem')};
  ${({ withCustomBackground, theme }) =>
    withCustomBackground && `background: ${theme.colorV2.guidelineBackgroundColor};`}
`;

type HelpContainerProps = {
  className?: string;
  data: HelpDataType | null;
  title: string;
  id: string;
  isRelativeToParent?: boolean;
  type: HelpType;
  position?: BasePopoverPosition;
  show: boolean;
  triggerDOMNode: HTMLElement | null;
  onHide: () => void;
};

export const HelpContainer = ({
  className,
  data,
  title,
  id,
  type,
  isRelativeToParent,
  position,
  show,
  triggerDOMNode,
  onHide,
}: HelpContainerProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataState, setDataState] = useState<HelpDataType | null>(data);

  const { open: openUnsavedChangesModal, modal: unsavedChangesModal } = useDialogModal((modalControls) => (
    <Modal {...modalControls}>
      <UnsavedChangesModal
        close={modalControls.close}
        onContinue={() => {
          modalControls.close();
          onHide();
        }}
      />
    </Modal>
  ));

  const handleOnSave = (values: HelpDataType) => {
    setLoading(true);
    const params = {
      description: values.description,
      link: values.link || '',
      image: values.image?.id || null,
      video: values.video?.id || null,
    };
    editHelpArticle(id, params)
      .then(() => {
        setIsEditing(false);
        setDataState(values);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(!dataState);
  }, [dataState]);

  useEffect(() => {
    setDataState(data);
  }, [data]);

  useEffect(() => {
    if (!show) {
      setIsEditing(false);
    }
  }, [show]);

  return (
    <StyledPopover
      className={className}
      isEditing={isEditing}
      withCustomBackground={type === 'guideline'}
      isRelativeToParent={isRelativeToParent}
      position={position}
      show={show}
      triggerDOMNode={triggerDOMNode as HTMLElement}
      onHide={isEditing ? openUnsavedChangesModal : onHide}
    >
      {unsavedChangesModal}
      {loading ? (
        <Loader />
      ) : (
        <>
          {dataState ? (
            <>
              {isEditing ? (
                <HelpForm data={dataState} title={title} onCancel={() => setIsEditing(false)} onSave={handleOnSave} />
              ) : (
                <HelpContent
                  data={dataState}
                  titleIcon={type === 'how-to' ? <InfoIcon /> : <BulbIcon />}
                  title={title}
                  onEdit={() => setIsEditing(true)}
                  onClose={onHide}
                />
              )}
            </>
          ) : null}
        </>
      )}
    </StyledPopover>
  );
};
