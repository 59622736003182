import { ContentState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';

const KTagStrategiesCommonUtils = {
  calculateTotalBlocks: (contentState: ContentState): number => {
    let output = stateToHTML(contentState, {
      inlineStyles: {
        KTAG_GROUP: {
          attributes: { class: 'KTAG_GROUP' },
        },
        KTAG_GROUP2: {
          attributes: { class: 'KTAG_GROUP2' },
        },
        KTAG_BAD: {
          attributes: { class: 'KTAG_BAD' },
        },
        KTAG_BAD2: {
          attributes: { class: 'KTAG_BAD2' },
        },
      },
      defaultBlockTag: '',
    });

    const totalBlocks = (output.match(/<span/g) || []).length;

    return totalBlocks;
  },
};

export default KTagStrategiesCommonUtils;
