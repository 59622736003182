import produce from 'immer';
import { PayloadAction } from '@reduxjs/toolkit';

import { UISliceInterface } from '@common/interfaces/slices/UISliceInterface';
import { DBId } from '@common/types/DBId';
import { SearchPanelParamsType } from '@features/search';
import { SearchActions } from '@actions/SearchActions';
import { ContentTypesActions } from '@actions/ContentTypesActions';

export const NewSearchUIReducers = {
  [SearchActions.SHOW_SEARCH_PANEL_V2]: (state: UISliceInterface, { payload }: PayloadAction<SearchPanelParamsType>) =>
    produce(state, (draft) => {
      draft.panels.searchV2.opened = true;
      draft.panels.searchV2.bundleName = payload.bundleName;
      draft.panels.searchV2.column = payload.column;
      draft.panels.searchV2.courseId = payload.courseId;
      draft.panels.searchV2.filtersPreset = payload.filtersPreset;

      draft.panels.searchV2.contentType = payload.contentType;
      draft.panels.searchV2.forReusing = payload.forReusing;
      draft.panels.searchV2.predefinedType = payload.predefinedType;

      draft.panels.searchV2.language = payload.language;
      draft.panels.searchV2.query = payload.query;
      draft.panels.searchV2.parentType = payload.parentType;
      draft.panels.searchV2.position = payload.position;
      draft.panels.searchV2.row = payload.row;
      draft.panels.searchV2.visitedBranch = payload.visitedBranch;
    }),

  [SearchActions.HIDE_SEARCH_PANEL_V2]: (state: UISliceInterface) =>
    produce(state, (draft) => {
      draft.panels.searchV2.opened = false;
      draft.panels.searchV2.bundleName = '';
      draft.panels.searchV2.column = undefined;
      draft.panels.searchV2.courseId = undefined;
      draft.panels.searchV2.contentType = '';
      draft.panels.searchV2.forReusing = false;
      draft.panels.searchV2.predefinedType = undefined;
      draft.panels.searchV2.language = undefined;
      draft.panels.searchV2.query = undefined;
      draft.panels.searchV2.parentType = '';
      draft.panels.searchV2.position = undefined;
      draft.panels.searchV2.row = undefined;
      draft.panels.searchV2.reuseInProgress = undefined;
      draft.panels.searchV2.visitedBranch = '';
    }),
  [SearchActions.RESET_SEARCH_FILTERS]: (state: UISliceInterface) =>
    produce(state, (draft) => {
      if (draft.panels.searchV2.filtersPreset) {
        delete draft.panels.searchV2.filtersPreset;
      }
    }),
  [SearchActions.SET_REUSE_IN_PROGRESS_V2]: (state: UISliceInterface, { payload }: PayloadAction<DBId>) =>
    produce(state, (draft) => {
      draft.panels.searchV2.reuseInProgress = payload;
    }),
  [ContentTypesActions.SET_CURRENT_CONTENT_ID]: (
    state: UISliceInterface,
    { payload: { row, column } }: PayloadAction<{ row: number; column: number }>,
  ): UISliceInterface =>
    produce(state, (draft) => {
      draft.panels.searchV2.column = column;
      draft.panels.searchV2.row = row;
    }),
};
