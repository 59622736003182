import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { DBId } from '@common/types/DBId';
import BreadcrumbNavigation from '@components/BreadcrumbNavigation/BreadcrumbNavigation';
import { useAppSelector } from '@redux/store';
import { selectPlacementTestEntrypointContent } from '@selectors/PlacementTestSelectors';

const BreadcrumbItemWithLink = styled.span`
  color: ${({ theme }) => theme.colorV2.breadcrumbsText};
  cursor: pointer;
  font-size: 1.2rem;
`;

export const Breadcrumbs = () => {
  const history = useHistory();
  const { courseId } = useParams<{ courseId: DBId; entrypointId: DBId }>();

  const entrypoint = useAppSelector(selectPlacementTestEntrypointContent);

  return (
    <BreadcrumbNavigation
      breadcrumbItems={[
        <BreadcrumbItemWithLink onClick={() => history.push(`/course/${courseId}/placement-test`)}>
          Placement Test
        </BreadcrumbItemWithLink>,
        <span>{entrypoint?.title ? entrypoint.title : 'Entrypoint'}</span>,
      ]}
    />
  );
};
