import { ContentTypes } from '@common/enums/ContentTypes';
import {
  DialogueCharactersPanelModes,
  UISliceInterface,
  GrammarTranslationsPanelType,
} from '@common/interfaces/slices/UISliceInterface';
import { TranslationsPanelModes } from '@components/TranslationsPanel/enums/TranslationsPanelModes';
import { constants as contentConstants } from '@features/content';
import { RoleplayCategoryTranslationsPanelType } from '@features/content/roleplay';

export const grammarTranslationsPanelInitialState: GrammarTranslationsPanelType = {
  type: ContentTypes.grammarReview,
  visible: false,
  preopenedTab: 'translations',
  visitedContentId: '',
  fieldName: 'title',
};

export const roleplayTranslationsPanelInitialState: RoleplayCategoryTranslationsPanelType = {
  type: ContentTypes.roleplay,
  visible: false,
  preopenedTab: 'translations',
  visitedContentId: '',
  fieldName: 'title',
};

const UIInitialState: UISliceInterface = {
  panels: {
    searchV2: {
      opened: false,
    },
    dialogueCharacters: {
      visible: false,
      characters: [],
      title: '',
      MAX_CHARACTERS: contentConstants.DIALOGUE_MAX_PARTICIPANTS,
      mode: DialogueCharactersPanelModes.none,
      characterIndex: null,
    },
    translations: {
      visible: false,
      visibleForCourse: false,
      visibleForLevelAndLesson: false,
      preopenedTab: 'translations',
      visitedKey: '',
      visitedContentId: '',
      visitedBranch: 'paragraph',
      bundleName: '',
      translationsPanelTranslationsMode: TranslationsPanelModes.normal,
      localizationIdUserView: '',
      row: undefined,
      column: undefined,
      cells: undefined,
      showOtherLocations: true,
      disableLearningLanguage: false,
    },
    grammarTranslations: grammarTranslationsPanelInitialState,
    roleplayTranslations: roleplayTranslationsPanelInitialState,
    publishing: {
      visible: false,
    },
    uploadAudios: {
      phase: 'start',
    },
    uploadTranslations: {
      phase: 'start',
    },
  },
  isPreviewOpened: false,
  isPreviewForExercisesListOpened: false,
  isIssuesShown: false,
};

export default UIInitialState;
