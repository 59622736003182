import { apiClient } from '@features/api';
import { DBId } from '@common/types/DBId';
import { ExerciseService } from '@common/types/ExerciseService';
import ExerciseDataModelAssembler from '@components/Exercises/ExerciseDataModelAssembler';
import { ExerciseCommonActionCreators as ExerciseCommonActions } from '@actionCreators/ExerciseCommonActionCreator';
import { LocalizationInterface } from '@common/interfaces/localization/LocalizationInterface';
import DialogueExerciseInterface from '@components/Exercises/Dialogue/interfaces/DialogueExerciseInterface';
import { Character } from '@components/Exercises/Dialogue/types/Character';
import { FormikValuesInterface } from '@helpers/formikInitialValuesHelper';
import { ScriptLine } from '@components/Exercises/Dialogue/types/ScriptLine';
import { selectTextLocalizationsForSave, selectMediaLocalizationsForSave } from '@helpers/localizationSaveHelper';
import { ScriptLinesPayload } from '@components/Exercises/Dialogue/types/ScriptLinesPayload';
import { constants as contentConstants } from '@features/content';
import ContentsService from '@services/ContentsService';
import ExercisesService from '@services/ExercisesService';
import { AppDispatch } from '@redux/store';
import { clone } from '@helpers/clone';
import { editDialogueExercise } from '@services/exercises/editExerciseService';
import { EditDialogueExerciseRequest, DisplayedLanguageType } from '@services/exercises/editExerciseTypes';
import { addToast } from '@features/app/toast';

export type DialogueExerciseServiceType = ExerciseService<DialogueExerciseInterface> & {
  updateScriptLineInDB: (contentId: DBId, scriptLine: ScriptLine) => void;
  getAllDialogueLinesIds: (
    dispatch: AppDispatch,
    scriptLinesPayload: any,
    exercise: DialogueExerciseInterface,
  ) => Promise<DBId[]>;
  saveScriptLinesAndCharacters: (dispatch: AppDispatch, exercise: DialogueExerciseInterface) => void;
  saveCharacters: (exercise: DialogueExerciseInterface) => void;
  requestCharacters: () => Promise<Character[]>;
};

const DialogueExerciseService: DialogueExerciseServiceType = {
  ensureExerciseFieldsAreReadyForUse(
    exerciseAndEmptyLocalizationBranchesPayload: any,
    exercise: DialogueExerciseInterface,
  ) {
    let output = clone(exerciseAndEmptyLocalizationBranchesPayload);
    exercise = exercise as DialogueExerciseInterface;

    return ExerciseDataModelAssembler.ensureFieldIsReadyForUse(
      exercise,
      output,
      ['instructions', 'dialogueDescription'],
      exerciseAndEmptyLocalizationBranchesPayload,
    );
  },
  async updateScriptLineInDB(contentId: DBId, scriptLine: ScriptLine) {
    const { audioLocalizations, description } = scriptLine.line;

    // don't pick already uploaded audios
    const processedAudioLocalizations: LocalizationInterface[] = selectMediaLocalizationsForSave(
      audioLocalizations.filter((audio: LocalizationInterface) => !audio.value),
    );

    await apiClient.noErrorsV2.put(`/content/resources/${contentId}`, {
      textLocalizations: selectTextLocalizationsForSave(scriptLine.line.textLocalizations),
      audioLocalizations: processedAudioLocalizations,
      description,
    });
  },
  async getAllDialogueLinesIds(dispatch: AppDispatch, scriptLinesPayload: any, exercise: any): Promise<DBId[]> {
    return Promise.all(
      scriptLinesPayload.map(async (scriptLine: ScriptLinesPayload, idx: number) => {
        const contentId = scriptLine.line;

        if (exercise.content.script[idx].line.changed) {
          if (!contentId) {
            const { audioLocalizations, description } = exercise.content.script[idx].line;

            const processedAudioLocalizations: LocalizationInterface[] = selectMediaLocalizationsForSave(
              audioLocalizations.map((audio: LocalizationInterface) => {
                return {
                  fileId: audio._id || null,
                  language: audio.language,
                  value: '',
                };
              }),
            );

            const payloadForContent = {
              textLocalizations: selectTextLocalizationsForSave(exercise.content.script[idx].line.textLocalizations),
              audioLocalizations: processedAudioLocalizations,
              imageLocalizations: [],
              videoLocalizations: [],
              description,
            };
            let newContentId = await ContentsService.contents.createNewContent(payloadForContent);

            dispatch(ExerciseCommonActions.setExerciseNewContentId({ field: 'script', newContentId, index: idx }));

            return newContentId;
          } else {
            await DialogueExerciseService.updateScriptLineInDB(contentId, exercise.content.script[idx]);
            return contentId;
          }
        } else {
          return contentId;
        }
      }),
    );
  },
  async saveScriptLinesAndCharacters(dispatch: AppDispatch, exercise: DialogueExerciseInterface) {
    let scriptLinesPayload: ScriptLinesPayload[] = [];

    if (exercise.content.script !== null) {
      scriptLinesPayload = exercise.content.script
        // .filter((scriptLine: ScriptLine) => scriptLine.line && scriptLine.line._id)
        .map((scriptLine: ScriptLine) => {
          const contentId = scriptLine.line._id as string;

          return {
            characterIndex: scriptLine.characterIndex,
            line: contentId,
          };
        });

      let allDialogueLinesIds: DBId[] = await DialogueExerciseService.getAllDialogueLinesIds(
        dispatch,
        scriptLinesPayload,
        exercise,
      );

      scriptLinesPayload = scriptLinesPayload.map((scriptLine: ScriptLinesPayload, idx: number) => {
        return {
          characterIndex: scriptLine.characterIndex,
          line: (allDialogueLinesIds as any)[idx],
        };
      });
    }

    let payload = { script: {} };
    if (scriptLinesPayload.length > 0) payload.script = scriptLinesPayload;
    return payload;
  },
  async saveCharacters(exercise: DialogueExerciseInterface) {
    if (exercise.content.characters !== null) {
      return {
        characters: exercise.content.characters.map((character: Character, idx: number) => {
          return {
            name: character.name.length >= 2 ? character.name : `Character ${idx + 1}`,
            image: character.image,
          };
        }),
      };
    } else {
      return { characters: {} };
    }
  },
  async save(dispatch: AppDispatch, exercise: DialogueExerciseInterface, values: FormikValuesInterface) {
    try {
      let payload: EditDialogueExerciseRequest = {
        instructionsLanguage: exercise.content.instructionsLanguage as DisplayedLanguageType,
        dialogueDescriptionLanguage: exercise.content.dialogueDescriptionLanguage as DisplayedLanguageType,
        recapExerciseId: exercise.content.recapExerciseId,
      };

      const updatePayload = await Promise.all([
        (values.instructionsChanged || exercise.content.instructions.changed) && !exercise.content.instructions.isReused
          ? ExercisesService.misc.saveField(dispatch, 'instructions', exercise, payload)
          : { instructions: exercise.content.instructions._id },
        (values.dialogueDescriptionChanged ||
          exercise.content.dialogueDescription?.changed ||
          exercise.content.dialogueDescriptionChanged) &&
        !exercise.content.dialogueDescription?.isReused
          ? ExercisesService.misc.saveField(dispatch, 'dialogueDescription', exercise, payload)
          : { dialogueDescription: exercise.content.dialogueDescription?._id || null },
        exercise.content.scriptChanged
          ? DialogueExerciseService.saveScriptLinesAndCharacters(dispatch, exercise)
          : null,
        exercise.content.charactersChanged ? DialogueExerciseService.saveCharacters(exercise) : null,
      ]);

      payload = {
        ...payload,
        ...updatePayload.reduce((sum: any, item: any) => ({ ...sum, ...item }), {}),
      };

      if (exercise.content.id) {
        await editDialogueExercise(exercise.content.id, payload);

        dispatch(ExerciseCommonActions.setSaveProgress({ value: false, updateData: true }));

        addToast({
          type: 'success',
          title: 'This exercise has been saved',
        });
      }
    } catch (e: any) {
      if (e.response?.status === 400) {
        addToast({
          type: 'error',
          title: `${e.response.data?.detail}`,
        });
      }

      throw new Error(e as string);
    }
  },
  async requestCharacters(): Promise<Character[]> {
    let images = await (await apiClient.v2.get('content/exercises/dialogue/character-images')).data;

    images = images.splice(0, contentConstants.DIALOGUE_MAX_CHARACTER_PHOTOS);

    return images.map((image: string) => {
      let characterOutput: Character = {
        name: '',
        image,
      };

      return characterOutput;
    });
  },
};

export default DialogueExerciseService;
