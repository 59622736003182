import { apiClient } from '@features/api';

type RequesterType = {
  id: string;
  fullName: string;
};

export const getRequesters = async (): Promise<{ data: { users: RequesterType[] } }> => {
  return await apiClient.noErrorsV2.get('/audio-requests/users');
};
