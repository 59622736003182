import classnames from 'classnames';
import { ContentState, DraftEditorCommand, EditorState, Modifier } from 'draft-js';
import { useEffect, useRef, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'draft-js/dist/Draft.css';

import BidimensionalEditorUtils from '@components/BidimensionalEditor/BidimensionalEditorUtils';
import { useIsEditAvailable } from '@features/content/courses';
import { computeEditorState, generateOutputForAPI } from '@helpers/kTagHelper';

import KTagStrategiesCommonUtils from './strategies/KTagStrategiesCommonUtils';
import KTagStandardStrategy from './strategies/KTagStandardStrategy';
import { ReplacementsProps } from './types/ReplacementsProps';
import KTagsCreatorHelpers from './KTagsCreatorHelpers';
import KTagsCreatorProps from './KTagsCreatorProps';
import { selectIsIssuesShown } from '@selectors/UiSelectors';
import { useAppSelector } from '@redux/store';
import { selectStringReuseModalOpenedFor } from '@selectors/CoursesSelectors';

const KTagsCreator = ({
  value,
  placeholder,
  className,
  onChange,
  showKTags = false,
  gapMode = false,
  charactersMode = false,
  onCharactersSelected,
  maxCharacters = 8,
  highlighterMode = false,
  addGroupStrategy,
  errorsShown = false,
  errors = [],
  onChangeInstant,
  isChangeBlocked = false,
}: KTagsCreatorProps) => {
  const { isEditAvailable } = useIsEditAvailable();
  const editorRef = useRef<any>();

  const isIssuesShown = useAppSelector(selectIsIssuesShown);

  const isErrorsShown = (errors && errors.some((el) => !el.isWarning) && isIssuesShown) || errorsShown;
  const isWarningsShown = (errors && errors.some((el) => el.isWarning) && isIssuesShown) || errorsShown;

  const stringReuseModalOpenedFor: string = useAppSelector(selectStringReuseModalOpenedFor);

  let styleMap = KTagsCreatorHelpers.getStyleMap(gapMode, highlighterMode);

  let [editorState, setEditorState] = useState(() => {
    return computeEditorState(charactersMode, maxCharacters, value);
  });

  let [totalCharacters, setTotalCharacters] = useState(0);

  let editorInternalMode = 'editorState';

  let [output, setOutput] = useState('');

  let [contentState, setContentState] = useState<ContentState>();

  const toolbar = {
    options: [],
  };

  const AddGroup = ({ onChange, editorState }: ReplacementsProps) => {
    const addGroup = (): void => {
      let strategyOutput =
        addGroupStrategy === undefined
          ? KTagStandardStrategy(editorState, charactersMode, totalCharacters, maxCharacters)
          : addGroupStrategy(editorState, maxCharacters);

      onCharactersSelected && onCharactersSelected(strategyOutput.totalBlocks);

      editorState = strategyOutput.editorState;
      const newTotalBlocks = strategyOutput.totalBlocks;

      setTotalCharacters(newTotalBlocks);
      onCharactersSelected && onCharactersSelected(newTotalBlocks);

      if (onChange && editorState) {
        onChangeInstant && onChangeInstant();

        if (!isChangeBlocked) {
          onChange(editorState);
          setContentState(contentState);
        }
      }
    };

    return (
      <button className="ktags-creator__group-creator" onClick={() => addGroup()} disabled={!isEditAvailable}>
        <div className={'ktags-creator__gap-image'} />
        {highlighterMode ? 'Highlight' : gapMode ? 'Gap' : 'Group'}
      </button>
    );
  };

  const RemoveGroup = ({ onChange, editorState }: ReplacementsProps) => {
    const removeGroup = (): void => {
      let result;
      let characterClasses = ['KTAG_GROUP', 'KTAG_GROUP2', 'KTAG_BAD', 'KTAG_BAD2'];
      let contentState = editorState.getCurrentContent();

      characterClasses.forEach((characterClass: string) => {
        contentState = Modifier.removeInlineStyle(contentState, editorState.getSelection(), characterClass);
      });

      const newTotalBlocks = KTagStrategiesCommonUtils.calculateTotalBlocks(contentState);
      setTotalCharacters(newTotalBlocks);
      onCharactersSelected && onCharactersSelected(newTotalBlocks);

      result = EditorState.push(editorState, contentState, 'change-inline-style');

      if (onChange) {
        onChangeInstant && onChangeInstant();

        if (!isChangeBlocked) {
          onChange(result);
          setContentState(contentState);
        }
      }
    };

    return (
      <button className="ktags-creator__group-creator" onClick={() => removeGroup()} disabled={!isEditAvailable}>
        <div className={'ktags-creator__gap-image'} />
        {highlighterMode ? 'Unhighlight' : gapMode ? 'Ungap' : 'Ungroup'}
      </button>
    );
  };

  function computeTotalStylisedCharacters(editorState: EditorState) {
    let totalStylisedCharacters = 0;

    for (
      let oneCharacter = 0;
      oneCharacter < editorState.getCurrentContent().getBlocksAsArray()[0].getCharacterList().size;
      oneCharacter++
    ) {
      let character = editorState.getCurrentContent().getBlocksAsArray()[0].getCharacterList().get(oneCharacter);

      let characterStyle = character.getStyle().values().next();

      if (characterStyle.value !== undefined) totalStylisedCharacters++;
    }

    return totalStylisedCharacters;
  }

  const onChangeTextHandler = (state: EditorState, forceChange: boolean = false) => {
    const newValue = BidimensionalEditorUtils.stateToHTMLHelper(state);
    const prevValue = BidimensionalEditorUtils.stateToHTMLHelper(editorState);

    const reallyChanged = prevValue !== newValue ? true : forceChange;

    if (reallyChanged || !isChangeBlocked) {
      onChangeInstant && onChangeInstant();

      if (!isChangeBlocked) {
        customEditorStateChangeHandler(state);
      }
    }
  };

  const customEditorStateChangeHandler = (state: EditorState) => {
    setEditorState(state);

    setContentState(state.getCurrentContent());
    onChange && onChange(generateOutputForAPI(state.getCurrentContent()));
  };

  const customHandleKeyCommand = (command: DraftEditorCommand, editorState: EditorState) => {
    if (command === 'backspace') {
      if (!stringReuseModalOpenedFor) handleOnEditorStateChange(editorState, true);

      return stringReuseModalOpenedFor ? 'handled' : 'not-handled';
    }

    return 'not-handled';
  };

  const handleOnEditorStateChange = (editorState: EditorState, forceEditorStateChange: boolean = false) => {
    if (isEditAvailable) {
      onChangeTextHandler(editorState, forceEditorStateChange);

      setTotalGroups(KTagsCreatorHelpers.countKTags(generateOutputForAPI(editorState.getCurrentContent())));
    }
  };

  useEffect(() => {
    if (showKTags) {
      setOutput(generateOutputForAPI(editorState.getCurrentContent()));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorState]);

  useEffect(() => {
    let totalStylisedCharacters = computeTotalStylisedCharacters(editorState);

    setTotalCharacters(totalStylisedCharacters);
    onCharactersSelected && onCharactersSelected(totalStylisedCharacters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  let props: any = {
    defaultEditorState: editorState, // types well, not refreshing state
    editorState: editorInternalMode === 'editorState' && editorState,
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let [totalGroups, setTotalGroups] = useState(
    KTagsCreatorHelpers.countKTags(generateOutputForAPI(editorState.getCurrentContent())),
  );

  if (editorInternalMode === 'defaultEditorState') delete props.editorState;
  if (editorInternalMode === 'editorState') delete props.defaultEditorState;

  return (
    <div
      className={classnames(
        'ktags-creator',
        { [`${className}`]: className !== undefined },
        {
          'ktags-creator--with-error': isErrorsShown,
          'ktags-creator--with-warning': !isErrorsShown && isWarningsShown,
        },
      )}
    >
      <Editor
        customStyleMap={styleMap}
        handleKeyCommand={customHandleKeyCommand}
        placeholder={placeholder}
        ref={editorRef}
        stripPastedStyles={true}
        toolbar={toolbar}
        toolbarCustomButtons={[<AddGroup editorState={editorState} />, <RemoveGroup editorState={editorState} />]}
        wrapperClassName={`editor__wrapper`}
        onEditorStateChange={(editorState: EditorState) => {
          handleOnEditorStateChange(editorState, false);
        }}
        {...props}
      />
      {showKTags && <div className="ktags-creator__output">{output}</div>}
    </div>
  );
};

export default KTagsCreator;
