import { ReactNode, createContext, useContext, useMemo, useRef, MutableRefObject } from 'react';

type HelpRefsContextType = {
  refs: MutableRefObject<Record<string, HTMLDivElement>> | null;
};

const HelpRefsContext = createContext<HelpRefsContextType>({
  refs: null,
});

export const HelpRefsProvider = ({ children }: { children: ReactNode }) => {
  const refs = useRef({});

  const value = useMemo(
    () => ({
      refs,
    }),
    [refs],
  );

  return <HelpRefsContext.Provider value={value}>{children}</HelpRefsContext.Provider>;
};

export const useHelpRefs = () => useContext(HelpRefsContext);
