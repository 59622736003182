import { DBId } from '@common/types/DBId';
import { apiClient } from '@features/api';

import { ActivitySearchResultDetailsType } from '../types';

export const getActivityResultDetails = async (
  activityId: DBId,
): Promise<{ data: ActivitySearchResultDetailsType }> => {
  const response = await apiClient.noErrorsV2.get(`/components/${activityId}/mappings`);

  return {
    data: {
      mappings: response.data,
    },
  };
};
