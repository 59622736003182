import './wdyr';
import { Types as AmplitudeTypes } from '@amplitude/analytics-browser';
import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent';
import * as Sentry from '@sentry/react';
import React from 'react';
import { createRoot } from 'react-dom/client';

import { App } from '@features/app';
import { ampli, ApiKey as AmplitudeApiKey } from '@vendor/ampli';

import * as serviceWorker from './serviceWorker';

const isProduction = process.env.REACT_APP_LOGOS_ENV === 'prod';

if (process.env.REACT_APP_NEW_RELIC_APP_ID) {
  new BrowserAgent({
    init: {
      ajax: {
        deny_list: [process.env.REACT_APP_NEW_RELIC_BEACON],
      },
      distributed_tracing: {
        enabled: true,
      },
      privacy: {
        cookies_enabled: true,
      },
    },
    info: {
      applicationID: process.env.REACT_APP_NEW_RELIC_APP_ID,
      beacon: process.env.REACT_APP_NEW_RELIC_BEACON,
      errorBeacon: process.env.REACT_APP_NEW_RELIC_BEACON,
      licenseKey: process.env.REACT_APP_NEW_RELIC_LICENSE_KEY,
      sa: 1,
    },
    loader_config: {
      accountID: process.env.REACT_APP_NEW_RELIC_ACCOUNT_ID,
      agentID: process.env.REACT_APP_NEW_RELIC_AGENT_ID,
      applicationID: process.env.REACT_APP_NEW_RELIC_APP_ID,
      licenseKey: process.env.REACT_APP_NEW_RELIC_LICENSE_KEY,
      trustKey: process.env.REACT_APP_NEW_RELIC_TRUST_KEY,
    },
  });
}

if (process.env.REACT_APP_SENTRY_DSN) {
  const environment = process.env.REACT_APP_LOGOS_ENV;
  const release = process.env.REACT_APP_SENTRY_RELEASE;

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment,
    release,
    ignoreErrors: [/^canceled$/], // ignore cancelled request error
  });

  console.info(`Initializing Sentry on '${environment}', release: '${release}'`);
}

// User analytics
if (process.env.REACT_APP_AMPLITUDE_ENABLED === 'true') {
  ampli.load({
    client: {
      apiKey: AmplitudeApiKey[isProduction ? 'production' : 'staging'],
      configuration: {
        defaultTracking: true,
        logLevel: !isProduction ? AmplitudeTypes.LogLevel.Debug : undefined, // let Amplitude set default log level for Prod
      },
    },
  });
}

createRoot(document.getElementById('root') as Element).render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
