import { DropdownButton } from 'react-bootstrap';
import styled, { css } from 'styled-components/macro';

export const CreatorContainer = styled.div<{ isPreEnabled: boolean }>`
  width: 100%;

  ${({ isPreEnabled, theme }) =>
    isPreEnabled &&
    css`
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 5rem;
      border: 0.2rem dashed ${theme.colorV2.buttonTertiaryBaseBorder};
      border-radius: 1.6rem;
    `};
`;

export const CreatorTitle = styled.h1`
  margin-bottom: 2.4rem;
  color: ${({ theme }) => theme.colorV2.textPrimary};
  font-size: 1.8rem;
  text-align: center;
`;

export const CreatorDescription = styled.h1`
  ${({ theme }) => css`
    color: ${theme.colorV2.textSecondary};
    font-size: 1.4rem;
    text-align: center;
    margin: -1.6rem 0 ${theme.space.L} 0;
  `}
`;

const getZIndex = (defaultZIndex: number, position: number) => {
  if (position === 0) {
    return defaultZIndex + 4;
  } else {
    return position === 1 ? defaultZIndex : defaultZIndex - 2 * (position - 1);
  }
};

export const CreatorMenu = styled.div<{ allowBulk: boolean; isPreenabled: boolean; position: number }>`
  ${({ allowBulk, isPreenabled, position, theme }) => css`
    align-items: center;
    display: flex;
    max-width: ${isPreenabled ? 'fit-content' : 'unset'};
    opacity: ${isPreenabled ? '1' : '0'};
    flex-direction: ${isPreenabled && !allowBulk ? 'column' : 'row'};
    font-size: 1.8rem;
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
    z-index: ${getZIndex(theme.zIndex.defaultCreatorMenu, position)};

    &:hover {
      opacity: 1;
    }
  `}
`;

export const CreatorButtonsContainer = styled.div<{ isMultiPublishState?: boolean }>`
  align-items: center;
  display: flex;
  height: 4rem;
  width: fit-content;
  background: ${({ theme }) => theme.colorV2.uiLightest};
  border: ${({ theme, isMultiPublishState }) =>
    isMultiPublishState ? 'none' : `0.1rem solid ${theme.colorV2.buttonTertiaryBaseBorder}`};
  border-radius: 5rem;
  box-shadow: 0 0.4rem 0.8rem rgb(0 0 0 / 5%);
  padding: 0;
  transition: 0.5s;
  z-index: 1;
`;

export const CreatorButton = styled.button`
  background: transparent;
  border: none;
  border-right: 0.1rem solid ${({ theme }) => theme.colorV2.buttonTertiaryBaseBorder};
  height: 4rem;
  padding: 0 1rem;

  &:hover {
    background-color: rgba(214, 222, 230, 0.5);
  }

  &:first-of-type {
    width: 5rem;
    padding-left: 1.5rem;
    border-bottom-left-radius: 5rem;
    border-top-left-radius: 5rem;
  }

  &:last-child {
    width: 5rem;
    border-bottom-right-radius: 5rem;
    border-top-right-radius: 5rem;
    border-right: none;
  }

  &[disabled] {
    opacity: 0.5;

    &:hover {
      cursor: default;
    }
  }
`;

export const CreatorButtonWithText = styled.button`
  align-items: center;
  background: none;
  border: 0;
  display: flex;
  font-size: 1.4rem;
  line-height: 4rem;
  height: 4rem;
  justify-content: center;
  min-width: 14rem;
  transition: 0.5s;
  padding: 0 2rem;
  border-right: 0.1rem solid ${({ theme }) => theme.colorV2.buttonTertiaryBaseBorder};

  &:hover {
    background-color: rgba(214, 222, 230, 0.5);
  }

  &:first-child {
    border-bottom-left-radius: 5rem;
    border-top-left-radius: 5rem;
  }

  &:last-child {
    border-bottom-right-radius: 5rem;
    border-top-right-radius: 5rem;
    border-right: none;
  }

  &[disabled] {
    opacity: 0.5;

    &:hover {
      cursor: default;
    }
  }
`;

export const CreatorButtonWithTextImage = styled.img`
  margin-right: 1rem;
`;

export const CreatorIcon = styled.img`
  width: 2rem;
`;

export const CreatorSeparator = styled.div`
  background: ${({ theme }) => theme.colorV2.accentPrimary};
  height: 0.2rem;
  position: absolute;
  top: 50%;
  width: 100%;
`;

export const MoreButton = styled(DropdownButton)`
  min-width: initial;
  width: 5rem;

  .dropdown-menu {
    transform: translate3d(0, 4rem, 0) !important;
  }

  & > button {
    min-height: unset !important;
    height: 3.8rem !important;
    border-radius: 0 !important;
    border-top-right-radius: 5rem !important;
    border-bottom-right-radius: 5rem !important;

    padding: 0 0 0 0.5rem !important;
    margin: 0.2rem 0;
    border: 0 !important;

    &:hover {
      background-color: rgba(214, 222, 230, 0.5) !important;
    }

    &:focus {
      outline: 0;
      box-shadow: none;
    }
  }
`;

export const MenuItemText = styled.span`
  margin-left: 1rem;
`;

export const QuantityHandlerWrapper = styled.div`
  ${({ theme }) => css`
    align-items: center;
    background-color: ${theme.colorV2.uiBackgroundPrimary};
    border-color: ${theme.colorV2.uiDivider};
    border-radius: 4.8rem;
    border-width: 0.1rem 0;
    border-style: solid;
    display: flex;
    gap: 2rem;
    z-index: 1;

    > div {
      margin: -0.1rem 0;
    }
  `}
`;
