import { capitalize } from 'lodash';
import { useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';

import { getValueByLanguage, getCorrectPhrase } from '@features/content';
import { ContentTypesActionsCreator } from '@actionCreators/ContentTypesActionsCreator';
import {
  EXERCISE_CONVERSATION,
  EXERCISE_DIALOGUE,
  EXERCISE_FILLGAP,
  EXERCISE_FLASHCARD,
  EXERCISE_HIGHLIGHTER,
  EXERCISE_MATCHUP,
  EXERCISE_MULTIPLE_CHOICE,
  EXERCISE_PHRASE_BUILDER,
  EXERCISE_SPELLING,
  EXERCISE_TIP,
  EXERCISE_TYPING,
  ExerciseType,
} from '@common/enums/ExerciseTypes';
import { removeBrTag } from '@helpers/htmlTagsHelper';
import { allTypesMap, getUrlToNavigate } from '@helpers/contentCardHelper';
import { ExerciseListItemType } from '@components/ContentTypes/ExerciseCard/types';
import { DragAndDropType } from '@components/DraggableList/DragAndDropType';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { PublishingCardButton } from './PublishingCardButton';
import { RemoveCardButton } from '@components/ContentTypes/BaseContent/RemoveCardButton';
import { RemoveModal } from '@components/ContentTypes/BaseContent/RemoveModal';
import { CardPublishingStatus } from '@components/ContentTypes/BaseContent/CardPublishingStatus';
import { CardValidationMark } from '@components/ContentTypes/BaseContent/CardValidationMark';
import { CardDragHandler } from '@components/ContentTypes/BaseContent/CardDragHandler';
import { CardPendingStatus } from '@components/ContentTypes/BaseContent/CardPendingStatus';
import { ReactComponent as AudioIcon } from '@components/ContentTypes/BaseContent/img/audioNew.svg';
import { ReactComponent as VideoIcon } from '@components/ContentTypes/BaseContent/img/video.svg';
import { GrammarReviewActionCreators } from '@actionCreators/GrammarReviewActionCreators';
import { PlacementTestActionCreators } from '@actionCreators/PlacementTestActionCreators';
import { useBulkPublishing } from '@features/content/exercises';
import { BulkPublishingActionsCreator } from '@actionCreators/BulkPublishingActionsCreator';
import { Checkbox } from '@features/theme';

import {
  CardControllers,
  CardDataWrapper,
  CardWrapper,
  Data,
  DataWrapper,
  ExerciseImage,
  ExerciseImagePlaceholder,
  ExerciseMedia,
  ExercisePhrase,
  ExercisePhraseContainer,
  Title,
} from '../card.styles';
import { ExerciseIcon } from './ExerciseIcon';
import { selectIsIssuesShown } from '@selectors/UiSelectors';
import { selectLearningLanguage, selectNewContentElementId } from '@selectors/CoursesSelectors';

const MultiPublishCheckbox = styled(Checkbox)<{ unavailable: boolean }>`
  cursor: pointer;

  ${({ theme, unavailable }) => css`
    input {
      background: ${unavailable ? theme.colorV2.uiBackgroundTertiary : 'inherit'};
      cursor: ${unavailable ? 'not-allowed' : 'inherit'};
    }
  `}
`;

const StyledDataWrapper = styled(DataWrapper)`
  display: grid;
  grid-template-columns: 32rem minmax(37.2rem, 1fr) 29rem;
`;

const StyledExerciseIcon = styled(ExerciseIcon)`
  margin-right: 0.6rem;
`;

const EXERCISES_WITH_GAPS = [
  EXERCISE_DIALOGUE,
  EXERCISE_PHRASE_BUILDER,
  EXERCISE_FILLGAP,
  EXERCISE_HIGHLIGHTER,
  EXERCISE_SPELLING,
  EXERCISE_TYPING,
];
const EXERCISES_WITH_COURSE_LANG_DEFAULT_PHRASE = [
  EXERCISE_FILLGAP,
  EXERCISE_FLASHCARD,
  EXERCISE_HIGHLIGHTER,
  EXERCISE_MATCHUP,
  EXERCISE_MULTIPLE_CHOICE,
  EXERCISE_SPELLING,
];
const EXERCISES_WITH_HTML = [EXERCISE_TIP, EXERCISE_CONVERSATION];

const getExerciseTitleForCard = ({
  enabledMedia,
  idx,
  ready,
  type,
}: {
  enabledMedia: string[];
  idx: number | null;
  ready: boolean;
  type: ExerciseType;
}) => {
  let title = `${ready && idx !== null ? idx + 1 : ''} ${allTypesMap[type as keyof typeof allTypesMap]} ${
    enabledMedia
      ? enabledMedia
          .map((media: string) => capitalize(media))
          .reverse()
          .join(' & ')
      : ''
  }`;

  return title;
};

export const ExerciseCard = ({
  content,
  idx,
  dndProps,
}: {
  content: ExerciseListItemType;
  idx: number | null;
  dndProps?: DragAndDropType;
}) => {
  const dispatch = useAppDispatch();
  const params: {
    courseId?: string;
    activityId?: string;
    topicId?: string;
    entrypointId?: string;
  } = useParams();
  const isIssuesShown = useAppSelector(selectIsIssuesShown);
  const courseLearningLanguage = useAppSelector(selectLearningLanguage);
  const newElementId = useAppSelector(selectNewContentElementId);
  const { selectedForBulkPublishing, currentBulkPublishingState, getIsExerciseDisabledForBulkPublishing } =
    useBulkPublishing();
  const isExerciseDisabledForBulkPublishing = getIsExerciseDisabledForBulkPublishing(content);

  const [hovered, setHovered] = useState(false);
  const [isRemoveModalOpened, setIsRemoveModalOpened] = useState(false);

  const {
    audio,
    changeStatus,
    enabledMedia,
    hasVideo,
    id,
    imageData: imageFromContent,
    previewText,
    ready,
    type,
    validationStatus,
  } = content;

  const handleRemove = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsRemoveModalOpened(true);
  };

  const exerciseData = useMemo(() => {
    const exerciseImage = getValueByLanguage(imageFromContent?.imageLocalizations);
    const hasAudio = getValueByLanguage(audio?.audioLocalizations);
    const commonCardText = EXERCISES_WITH_COURSE_LANG_DEFAULT_PHRASE.includes(type)
      ? getValueByLanguage(previewText?.currentVersion?.textLocalizations, courseLearningLanguage)
      : getValueByLanguage(previewText?.currentVersion?.textLocalizations);
    const phrase = EXERCISES_WITH_GAPS.includes(type) ? getCorrectPhrase(commonCardText) : commonCardText;
    const viaHtml = EXERCISES_WITH_HTML.includes(type);

    return (
      <>
        <Data>
          <CardPendingStatus content={content} />
          <Title>
            <StyledExerciseIcon type={type} />
            {getExerciseTitleForCard({ enabledMedia, idx, ready, type })}
            <CardValidationMark isInvalid={!validationStatus?.valid} />
          </Title>
        </Data>
        <ExercisePhraseContainer>
          {viaHtml ? (
            <ExercisePhrase dangerouslySetInnerHTML={{ __html: removeBrTag(phrase) }} />
          ) : (
            <ExercisePhrase>{removeBrTag(phrase)}</ExercisePhrase>
          )}
        </ExercisePhraseContainer>
        {!hovered ? (
          <ExerciseMedia hasImage={!!exerciseImage}>
            <ExerciseImagePlaceholder>
              {exerciseImage && <ExerciseImage src={exerciseImage} alt="Exercise image" />}
            </ExerciseImagePlaceholder>
            {hasVideo && (
              <div>
                <VideoIcon title="Video" />
              </div>
            )}
            {hasAudio && (
              <div>
                <AudioIcon title="Audio" />
              </div>
            )}
          </ExerciseMedia>
        ) : (
          <CardControllers>
            {!currentBulkPublishingState && (
              <>
                <PublishingCardButton hovered content={content} />
                <RemoveCardButton hovered content={content} onRemove={handleRemove} />
              </>
            )}
          </CardControllers>
        )}
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBulkPublishingState, idx, isIssuesShown, hasVideo, hovered, type]);

  return (
    <CardWrapper id={`exercise-${id}`} onMouseOver={() => setHovered(true)} onMouseOut={() => setHovered(false)}>
      <RemoveModal
        opened={isRemoveModalOpened}
        onHide={() => setIsRemoveModalOpened(false)}
        onDelete={() => {
          if (params.topicId) {
            dispatch(
              GrammarReviewActionCreators.removeExercise(
                params.courseId || '',
                params.topicId,
                content.id,
                content.type,
              ),
            );
          } else if (params.entrypointId) {
            dispatch(
              PlacementTestActionCreators.removeExercise(params.courseId || '', params.entrypointId, content.id),
            );
          } else {
            dispatch(ContentTypesActionsCreator.removeContent(content));
          }
        }}
      />
      {currentBulkPublishingState ? (
        <MultiPublishCheckbox
          disabled={isExerciseDisabledForBulkPublishing}
          unavailable={isExerciseDisabledForBulkPublishing}
          checked={isExerciseDisabledForBulkPublishing ? false : selectedForBulkPublishing.includes(content.id)}
          onToggle={() => dispatch(BulkPublishingActionsCreator.toggleSelectedChildren(content.id))}
        />
      ) : (
        <CardDragHandler dndProps={dndProps} visible={hovered} />
      )}
      <CardDataWrapper
        to={currentBulkPublishingState ? '#' : getUrlToNavigate(params, id, type)}
        $active={dndProps?.snapshot?.isDragging || id === newElementId}
        $hovered={hovered}
        $isDisabled={isExerciseDisabledForBulkPublishing}
        onClick={() => {
          if (currentBulkPublishingState && !isExerciseDisabledForBulkPublishing) {
            dispatch(BulkPublishingActionsCreator.toggleSelectedChildren(content.id));
          }
        }}
      >
        <CardPublishingStatus hasPendingChanges={!!changeStatus?.hasPendingChanges} ready={!!ready} />
        <StyledDataWrapper>{exerciseData}</StyledDataWrapper>
      </CardDataWrapper>
    </CardWrapper>
  );
};
