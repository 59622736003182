/** @deprecated Use ExerciseType type instead */
export const ExerciseTypes = {
  comprehension: 'comprehension',
  conversation: 'conversation',
  dialogue: 'dialogue',
  fillgap: 'fillgap',
  flashcard: 'flashcard',
  highlighter: 'highlighter',
  listenRepeat: 'listenRepeat',
  matchup: 'matchup',
  multipleChoice: 'multipleChoice',
  phraseBuilder: 'phraseBuilder',
  spelling: 'spelling',
  speechRecognition: 'speechRecognition',
  slidePdf: 'slidePdf',
  slidePptx: 'slidePptx',
  tip: 'tip',
  trueFalse: 'trueFalse',
  typing: 'typing',
} as const;
/** @deprecated Use ExerciseType type instead */
export type ExerciseTypesType = (typeof ExerciseTypes)[keyof typeof ExerciseTypes];

export type ExerciseComprehensionType = 'comprehension';
export type ExerciseConversationType = 'conversation';
export type ExerciseDialogueType = 'dialogue';
export type ExerciseFillgapType = 'fillgap';
export type ExerciseFlashcardType = 'flashcard';
export type ExerciseHighlighterType = 'highlighter';
export type ExerciseListenRepeatType = 'listenRepeat';
export type ExerciseMatchupType = 'matchup';
export type ExerciseMultipleChoiceType = 'multipleChoice';
export type ExercisePhraseBuilderType = 'phraseBuilder';
export type ExerciseSlidePptxType = 'slidePptx';
export type ExerciseSlidePdfType = 'slidePdf';
export type ExerciseSpeechRecognitionType = 'speechRecognition';
export type ExerciseSpellingType = 'spelling';
export type ExerciseTipType = 'tip';
export type ExerciseTrueFalseType = 'trueFalse';
export type ExerciseTypingType = 'typing';

export type ExerciseType =
  | ExerciseComprehensionType
  | ExerciseConversationType
  | ExerciseDialogueType
  | ExerciseFillgapType
  | ExerciseFlashcardType
  | ExerciseHighlighterType
  | ExerciseListenRepeatType
  | ExerciseMatchupType
  | ExerciseMultipleChoiceType
  | ExercisePhraseBuilderType
  | ExerciseSlidePptxType
  | ExerciseSlidePdfType
  | ExerciseSpeechRecognitionType
  | ExerciseSpellingType
  | ExerciseTipType
  | ExerciseTrueFalseType
  | ExerciseTypingType;

export const EXERCISE_COMPREHENSION = 'comprehension';
export const EXERCISE_CONVERSATION = 'conversation';
export const EXERCISE_DIALOGUE = 'dialogue';
export const EXERCISE_FILLGAP = 'fillgap';
export const EXERCISE_FLASHCARD = 'flashcard';
export const EXERCISE_HIGHLIGHTER = 'highlighter';
export const EXERCISE_LISTEN_REPEAT = 'listenRepeat';
export const EXERCISE_MATCHUP = 'matchup';
export const EXERCISE_MULTIPLE_CHOICE = 'multipleChoice';
export const EXERCISE_PHRASE_BUILDER = 'phraseBuilder';
export const EXERCISE_SLIDE_PPTX = 'slidePptx';
export const EXERCISE_SLIDE_PDF = 'slidePdf';
export const EXERCISE_SPEECH_RECOGNITION = 'speechRecognition';
export const EXERCISE_SPELLING = 'spelling';
export const EXERCISE_TIP = 'tip';
export const EXERCISE_TRUE_FALSE = 'trueFalse';
export const EXERCISE_TYPING = 'typing';

export const EXERCISES: ExerciseType[] = [
  EXERCISE_COMPREHENSION,
  EXERCISE_CONVERSATION,
  EXERCISE_DIALOGUE,
  EXERCISE_FILLGAP,
  EXERCISE_FLASHCARD,
  EXERCISE_HIGHLIGHTER,
  EXERCISE_LISTEN_REPEAT,
  EXERCISE_MATCHUP,
  EXERCISE_MULTIPLE_CHOICE,
  EXERCISE_PHRASE_BUILDER,
  EXERCISE_SLIDE_PPTX,
  EXERCISE_SLIDE_PDF,
  EXERCISE_SPEECH_RECOGNITION,
  EXERCISE_SPELLING,
  EXERCISE_TIP,
  EXERCISE_TRUE_FALSE,
  EXERCISE_TYPING,
];

export const GRADABLE_EXERCISES: ExerciseType[] = [
  EXERCISE_FILLGAP,
  EXERCISE_HIGHLIGHTER,
  EXERCISE_MATCHUP,
  EXERCISE_MULTIPLE_CHOICE,
  EXERCISE_PHRASE_BUILDER,
  EXERCISE_SPEECH_RECOGNITION,
  EXERCISE_SPELLING,
  EXERCISE_TRUE_FALSE,
  EXERCISE_TYPING,
];

export const EXERCISE_TITLES: { [key in ExerciseType]: string } = {
  [EXERCISE_COMPREHENSION]: 'Comprehension',
  [EXERCISE_CONVERSATION]: 'Conversation',
  [EXERCISE_DIALOGUE]: 'Dialogue',
  [EXERCISE_FILLGAP]: 'Fillgap',
  [EXERCISE_FLASHCARD]: 'Flashcard',
  [EXERCISE_HIGHLIGHTER]: 'Highlighter',
  [EXERCISE_LISTEN_REPEAT]: 'Listen & Repeat',
  [EXERCISE_MATCHUP]: 'Matchup',
  [EXERCISE_MULTIPLE_CHOICE]: 'Multiple Choice',
  [EXERCISE_PHRASE_BUILDER]: 'Phrase Builder',
  [EXERCISE_SLIDE_PPTX]: 'Presentation',
  [EXERCISE_SLIDE_PDF]: 'Slideshow',
  [EXERCISE_SPEECH_RECOGNITION]: 'Speech Recognition',
  [EXERCISE_SPELLING]: 'Spelling',
  [EXERCISE_TIP]: 'Tip',
  [EXERCISE_TRUE_FALSE]: 'True False',
  [EXERCISE_TYPING]: 'Typing',
};
