import { useMemo } from 'react';

import { ContentTypes } from '@common/enums/ContentTypes';

import TipExerciseContent from '@components/Exercises/Tip/interfaces/TipExerciseContent';
import FlashcardExerciseContent from '@components/Exercises/Flashcard/interfaces/FlashcardExerciseContent';
import ConversationExerciseContent from '@components/Exercises/Conversation/interfaces/ConversationExerciseContent';
import ComprehensionExerciseContent from '@components/Exercises/Comprehension/interfaces/ComprehensionExerciseContent';
import TrueFalseExerciseContent from '@components/Exercises/TrueFalse/interfaces/TrueFalseExerciseContent';
import MultipleChoiceExerciseContent from '@components/Exercises/MultipleChoice/interfaces/MultipleChoiceExerciseContent';
import PhraseBuilderExerciseContent from '@components/Exercises/PhraseBuilder/interfaces/PhraseBuilderExerciseContent';
import TypingExerciseContent from '@components/Exercises/Typing/interfaces/TypingExerciseContent';
import SpellingExerciseContent from '@components/Exercises/Spelling/interfaces/SpellingExerciseContent';
import FillgapExerciseContent from '@components/Exercises/FillGap/interfaces/FillGapExerciseContent';
import HighlighterExerciseContent from '@components/Exercises/Highlighter/interfaces/HighlighterExerciseContent';
import SpeechRecognitionExerciseContent from '@components/Exercises/SpeechRecognition/interfaces/SpeechRecognitionExerciseContent';
import MatchupExerciseContent from '@components/Exercises/MatchUp/interfaces/MatchUpExerciseContent';
import DialogueExerciseContent from '@components/Exercises/Dialogue/interfaces/DialogueExerciseContent';
import SlidePdfExerciseContent from '@components/Exercises/SlidePdf/interfaces/SlidePdfExerciseContent';
import SlidePptxExerciseContent from '@components/Exercises/SlidePptx/interfaces/SlidePptxExerciseContent';

import {
  ComprehensionContent,
  ConversationContent,
  DialogueContent,
  FillgapContent,
  FlashcardContent,
  HighlighterContent,
  MatchupContent,
  MultipleChoiceContent,
  PhraseBuilderContent,
  SlideContent,
  SpeechRecognitionContent,
  SpellingContent,
  TipContent,
  TrueFalseContent,
  TypingContent,
} from './PreviewContentByType';
import { PreviewModalContentSwitcherProps } from '../PreviewModalProps';
import { useAppSelector } from '@redux/store';
import { selectLearningLanguage } from '@selectors/CoursesSelectors';

export const PreviewModalContentSwitcher = ({
  type,
  content,
  deviceType,
  answerState,
  isForList,
}: PreviewModalContentSwitcherProps) => {
  const courseLearningLanguage = useAppSelector(selectLearningLanguage);

  const ContentPreviewByType = useMemo(() => {
    switch (type) {
      case ContentTypes.tip:
        return (
          <TipContent
            content={content as TipExerciseContent}
            courseLearningLanguage={courseLearningLanguage}
            isForList={isForList}
          />
        );
      case ContentTypes.flashcard:
        return (
          <FlashcardContent
            content={content as FlashcardExerciseContent}
            deviceType={deviceType}
            courseLearningLanguage={courseLearningLanguage}
            isForList={isForList}
          />
        );
      case ContentTypes.conversation:
        return (
          <ConversationContent
            content={content as ConversationExerciseContent}
            deviceType={deviceType}
            courseLearningLanguage={courseLearningLanguage}
            isForList={isForList}
          />
        );
      case ContentTypes.comprehension:
        return (
          <ComprehensionContent
            content={content as ComprehensionExerciseContent}
            deviceType={deviceType}
            courseLearningLanguage={courseLearningLanguage}
            isForList={isForList}
          />
        );
      case ContentTypes.trueFalse:
        return (
          <TrueFalseContent
            content={content as TrueFalseExerciseContent}
            deviceType={deviceType}
            answerState={answerState}
            courseLearningLanguage={courseLearningLanguage}
            isForList={isForList}
          />
        );
      case ContentTypes.multipleChoice:
        return (
          <MultipleChoiceContent
            content={content as MultipleChoiceExerciseContent}
            deviceType={deviceType}
            answerState={answerState}
            courseLearningLanguage={courseLearningLanguage}
            isForList={isForList}
          />
        );
      case ContentTypes.phraseBuilder:
        return (
          <PhraseBuilderContent
            content={content as PhraseBuilderExerciseContent}
            deviceType={deviceType}
            answerState={answerState}
            courseLearningLanguage={courseLearningLanguage}
            isForList={isForList}
          />
        );
      case ContentTypes.typing:
        return (
          <TypingContent
            content={content as TypingExerciseContent}
            deviceType={deviceType}
            answerState={answerState}
            courseLearningLanguage={courseLearningLanguage}
            isForList={isForList}
          />
        );
      case ContentTypes.spelling:
        return (
          <SpellingContent
            content={content as SpellingExerciseContent}
            deviceType={deviceType}
            answerState={answerState}
            courseLearningLanguage={courseLearningLanguage}
            isForList={isForList}
          />
        );
      case ContentTypes.fillgap:
        return (
          <FillgapContent
            content={content as FillgapExerciseContent}
            deviceType={deviceType}
            answerState={answerState}
            courseLearningLanguage={courseLearningLanguage}
            isForList={isForList}
          />
        );
      case ContentTypes.highlighter:
        return (
          <HighlighterContent
            content={content as HighlighterExerciseContent}
            deviceType={deviceType}
            answerState={answerState}
            courseLearningLanguage={courseLearningLanguage}
          />
        );
      case ContentTypes.speechRecognition:
        return (
          <SpeechRecognitionContent
            content={content as SpeechRecognitionExerciseContent}
            deviceType={deviceType}
            courseLearningLanguage={courseLearningLanguage}
            isForList={isForList}
          />
        );
      case ContentTypes.matchup:
        return (
          <MatchupContent
            content={content as MatchupExerciseContent}
            deviceType={deviceType}
            answerState={answerState}
            courseLearningLanguage={courseLearningLanguage}
            isForList={isForList}
          />
        );
      case ContentTypes.dialogue:
        return (
          <DialogueContent
            content={content as DialogueExerciseContent}
            deviceType={deviceType}
            answerState={answerState}
            courseLearningLanguage={courseLearningLanguage}
          />
        );
      case ContentTypes.slidePdf:
        return <SlideContent content={content as SlidePdfExerciseContent} slideFormat="pdf" />;
      case ContentTypes.slidePptx:
        return <SlideContent content={content as SlidePptxExerciseContent} slideFormat="pptx" />;
      default:
        return null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, deviceType, answerState]);

  return ContentPreviewByType;
};
