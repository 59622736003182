import { Button } from 'react-bootstrap';
import styled from 'styled-components';
import { PublishingActionsCreator } from '@actionCreators/PublishingActionCreator';
import { ReactComponent as PendingQueueIcon } from './img/pendingQueue.svg';
import { useAppDispatch } from '@redux/store';

const PendingQueueImage = styled(PendingQueueIcon)`
  margin-right: 8px;
`;

const PendingQueueButton = styled(Button)`
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  border-radius: 3.2rem;
  padding: 1.2rem 2.4rem;
  height: 4.8rem;
`;

export const PendingChangesButton = () => {
  const dispatch = useAppDispatch();

  return (
    <PendingQueueButton
      id="header-pending-changes-button"
      variant="light"
      onClick={() => {
        dispatch(PublishingActionsCreator.togglePublishingQueuePanel());
      }}
    >
      <PendingQueueImage />
      <span>Pending changes</span>
    </PendingQueueButton>
  );
};
