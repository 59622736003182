import { ExerciseService } from '@common/types/ExerciseService';
import ExerciseDataModelAssembler from '@components/Exercises/ExerciseDataModelAssembler';
import { ExerciseCommonActionCreators as ExerciseCommonActions } from '@actionCreators/ExerciseCommonActionCreator';
import { FormikValuesInterface } from '@helpers/formikInitialValuesHelper';
import SpellingExerciseInterface from '@components/Exercises/Spelling/interfaces/SpellingExerciseInterface';
import ExercisesService from '@services/ExercisesService';
import { AppDispatch } from '@redux/store';
import { clone } from '@helpers/clone';
import { editSpellingExercise } from '@services/exercises/editExerciseService';
import { EditSpellingExerciseRequest, DisplayedLanguageType } from '@services/exercises/editExerciseTypes';
import { addToast } from '@features/app/toast';

type SpellingExerciseServiceType = ExerciseService<SpellingExerciseInterface>;

const SpellingExerciseService: SpellingExerciseServiceType = {
  ensureExerciseFieldsAreReadyForUse(
    exerciseAndEmptyLocalizationBranchesPayload: any,
    exercise: SpellingExerciseInterface,
  ) {
    let output = clone(exerciseAndEmptyLocalizationBranchesPayload);

    return ExerciseDataModelAssembler.ensureFieldIsReadyForUse(
      exercise,
      output,
      ['instructions', 'mainBundle', 'feedback'],
      exerciseAndEmptyLocalizationBranchesPayload,
    );
  },
  async save(dispatch: AppDispatch, exercise: SpellingExerciseInterface, values: FormikValuesInterface) {
    try {
      let payload: EditSpellingExerciseRequest = {
        instructionsLanguage: exercise.content.instructionsLanguage as DisplayedLanguageType,
        recapExerciseId: exercise.content.recapExerciseId,
        experiment: exercise.content.experiment,
      };

      const updatePayload = await Promise.all([
        (exercise.content.instructions?.changed || values.instructionsChanged) &&
        !exercise.content.instructions.isReused
          ? ExercisesService.misc.saveField(dispatch, 'instructions', exercise, payload)
          : { instructions: exercise.content.instructions._id },
        (exercise.content.feedback?.changed || exercise.content.feedbackChanged || values.feedbackChanged) &&
        !exercise.content.feedback?.isReused
          ? ExercisesService.misc.saveField(dispatch, 'feedback', exercise, payload)
          : { feedback: exercise.content.feedback?._id || null },
        exercise.content?.mainBundle?.isVocabularyChanged ||
        exercise.content?.mainBundle?.image?.changed ||
        exercise.content?.mainBundle?.imageChanged ||
        values.mainBundle_phraseChanged ||
        exercise.content?.mainBundle?.phrase?.changed ||
        exercise.content?.mainBundle?.phrase?.isReused
          ? ExercisesService.misc.saveBundle(dispatch, 'mainBundle', exercise, payload, values)
          : null,
      ]);

      payload = {
        ...payload,
        ...updatePayload.reduce((sum: any, item: any) => ({ ...sum, ...item }), {}),
      };

      if (exercise.content.id) {
        await editSpellingExercise(exercise.content.id, payload);

        dispatch(ExerciseCommonActions.setSaveProgress({ value: false, updateData: true }));

        addToast({
          type: 'success',
          title: 'This exercise has been saved',
        });
      }
    } catch (e: any) {
      if (e.response?.status === 400) {
        addToast({
          type: 'error',
          title: `${e.response.data?.detail}`,
        });
      }

      throw new Error(e as string);
    }
  },
};

export default SpellingExerciseService;
