import { ContentTypes } from '@common/enums/ContentTypes';
import { OtherLocationsInterface } from '@common/interfaces/exercises/OtherLocationsInterface';
import { FlagTextPositionMode } from '@components/Flag';
import { Language } from '@features/content/languages';
import { getLink } from '@helpers/urlLinkHelper';

import {
  MatchedExercisesModalLocation,
  MatchedExercisesModalLocationtItem,
  MatchedExercisesModalNotCourseImage,
  MatchedExercisesModalNavigate,
  StyledArrowIcon,
  StyledFlag,
} from '../styles';

import grammarIcon from '../../_img/grammar.svg';
import placementIcon from '../../_img/placement.svg';

export const LocationItem = ({ location }: { location: (OtherLocationsInterface & { language: Language })[] }) => {
  return (
    <MatchedExercisesModalLocation>
      {location.map((locationItem) => (
        <>
          {locationItem.type !== 'resourceBundle' && (
            <MatchedExercisesModalLocationtItem key={`${locationItem.id}`}>
              {locationItem.type === ContentTypes.course && (
                <StyledFlag countries={locationItem.language || 'EN'} mode={FlagTextPositionMode.withoutText} />
              )}
              {(locationItem.type === ContentTypes.grammarReview ||
                locationItem.type === ContentTypes.placementTest) && (
                <MatchedExercisesModalNotCourseImage
                  src={locationItem.type === ContentTypes.grammarReview ? grammarIcon : placementIcon}
                />
              )}
              {locationItem.label}
            </MatchedExercisesModalLocationtItem>
          )}
        </>
      ))}
      <MatchedExercisesModalNavigate to={getLink(location)} target="_blank">
        <StyledArrowIcon />
      </MatchedExercisesModalNavigate>
    </MatchedExercisesModalLocation>
  );
};
