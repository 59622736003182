import { Redirect, RouteProps } from 'react-router-dom';
import React, { Children, ReactElement, useContext } from 'react';
import { SecurityContext } from './context';
import { SecureRouteTemplate } from './SecureRoute';
import { SecurityInterface } from './security';

const hardcodedApiToken = localStorage.getItem('apiToken');

export const isLocalAuthEnabled = Boolean(hardcodedApiToken);

const SecurityProvider = ({ children }: { children: ReactElement }) => {
  return Children.only(children);
};

const LoginCallback = () => <Redirect to="/" />;

const LogoutCallback = () => {
  const { setUser } = useContext(SecurityContext);

  setUser(null);

  return <Redirect to="/" />;
};

const SecureRoute = (props: RouteProps) => {
  const { user, refreshUser } = useContext(SecurityContext);

  return <SecureRouteTemplate routeProps={props} user={user} handleAuthorization={refreshUser} />;
};

export class LocalSecurity implements SecurityInterface {
  SecurityProvider = SecurityProvider;
  LoginCallback = LoginCallback;
  LogoutCallback = LogoutCallback;
  SecureRoute = SecureRoute;

  getAuthorizationHeader(): string {
    return `Token ${hardcodedApiToken}`;
  }

  logout(): void {
    window.location.replace(`${window.location.origin}/logout`);
  }
}
