import { EditorState, Modifier, SelectionState } from 'draft-js';
import { useEffect, useState } from 'react';

import { LockContentButton } from './LockContentButton';
import { getOriginalLikeSelection, getSelectedText, getSelectionWithTextBoundaries, isLockedContent } from './helpers';
import { LockContentOption } from './styles';

type LockContentProps = {
  // Optional props because react-draft-wysiwyg passes them automatically
  editorState?: EditorState;
  onChange?: (editorState: EditorState) => void;
};

const LOCKED_CONTENT_DELIMITER_LEFT = '{';
const LOCKED_CONTENT_DELIMITER_RIGHT = '}';
const LOCKED_CONTENT_REGEX = /{[\w\d\s]+}/;

const toggleLockContent = (editorState: EditorState, originalSelection: SelectionState, shouldLock: boolean) => {
  const currentContent = editorState.getCurrentContent();
  let updatedContent = currentContent;
  let text;

  if (shouldLock) {
    // update text with locked content delimiters
    text =
      LOCKED_CONTENT_DELIMITER_LEFT +
      getSelectedText(currentContent, originalSelection) +
      LOCKED_CONTENT_DELIMITER_RIGHT;
    updatedContent = Modifier.replaceText(currentContent, originalSelection, text, editorState.getCurrentInlineStyle());
  } else {
    // create a new selection range
    const updatedSelection = getSelectionWithTextBoundaries(currentContent, originalSelection);
    const selectedText = getSelectedText(currentContent, updatedSelection);

    if (isLockedContent(selectedText, LOCKED_CONTENT_REGEX)) {
      // update text removing locked content delimiters
      text = selectedText.replace(/[{|}]/g, '');
      updatedContent = Modifier.replaceText(
        currentContent,
        updatedSelection,
        text,
        editorState.getCurrentInlineStyle(),
      );
    }
  }

  const newEditorState = EditorState.push(editorState, updatedContent, 'insert-characters');

  // recreate original selection for the new EditorState
  const newBlockMap = newEditorState.getCurrentContent().getBlockMap();
  const originalLikeSelection = getOriginalLikeSelection(newBlockMap, originalSelection, shouldLock);

  return EditorState.forceSelection(newEditorState, originalLikeSelection);
};

export const LockContent = ({ editorState, onChange }: LockContentProps) => {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (editorState) {
      const currentSelection = editorState?.getSelection();

      if (currentSelection && !currentSelection.isCollapsed()) {
        const currentContent = editorState?.getCurrentContent();
        const selectionWithTextBoundaries = getSelectionWithTextBoundaries(currentContent, currentSelection);
        const text = getSelectedText(editorState?.getCurrentContent(), selectionWithTextBoundaries);

        setIsActive(isLockedContent(text, LOCKED_CONTENT_REGEX));
      } else {
        setIsActive(false);
      }
    }
  }, [editorState]);

  const handleClick = () => {
    if (!editorState || !onChange) return;

    const selection = editorState.getSelection();

    // require an actual selection range
    if (!selection.isCollapsed()) {
      onChange(toggleLockContent(editorState, selection, !isActive));
      setIsActive(!isActive);
    }
  };

  return (
    <LockContentOption>
      <LockContentButton active={isActive} onClick={handleClick} />
    </LockContentOption>
  );
};
