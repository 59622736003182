import { ActivityInterface } from '@common/interfaces/contentTypes/ActivityInterface';
import { ContentTypes } from '@common/enums/ContentTypes';

import { ACTIVITY_VOCABULARY } from './ActivityTypes';

export type { ActivityType } from './ActivityTypes';

export {
  ACTIVITY_CONVERSATION,
  ACTIVITY_COMPREHENSION_SKILLS,
  ACTIVITY_DEVELOP,
  ACTIVITY_DIALOGUE,
  ACTIVITY_DISCOVER,
  ACTIVITY_ENTITY,
  ACTIVITY_MEMORISE,
  ACTIVITY_PRACTICE,
  ACTIVITY_PRODUCTIVE_SKILLS,
  ACTIVITY_PRONUNCIATION,
  ACTIVITY_QUIZ,
  ACTIVITY_READING,
  ACTIVITY_SPEAKING,
  ACTIVITY_VIDEO,
  ACTIVITY_VOCABULARY,
  ACTIVITIES,
  ACTIVITY_TITLES,
} from './ActivityTypes';

export const DEFAULT_ACTIVITY_TYPE = ACTIVITY_VOCABULARY;

export { ActivityTypeIcon } from './ActivityTypeIcon/ActivityTypeIcon';
export { ActivityCreator } from './ActivityCreator';

export { default as ACTIVITY_ICONS } from './ActivityTypeIcon/_img';

export const isLegacyActivity = (content: ActivityInterface): boolean => {
  return (
    content?.activityType === 'entity' || (content?.activityType === 'quiz' && content?.oldType === ContentTypes.review)
  );
};
