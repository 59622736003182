import { Col, Row } from 'react-bootstrap';
import { SelectedGroupsOfParentInterface } from '@common/interfaces/groups/SelectedGroupsOfParentInterface';
import { ValidationErrorDisplayer } from '@components/ValidationErrorDisplayer';
import { ValidationErrorInterface } from '@common/interfaces/validation/ValidationInterface';
import ImageUploadUtils from '@components/MediaUpload/ImageUploadUtils';
import { useIsEditAvailable } from '@features/content/courses';
import { ContentTypesActionsCreator } from '@actionCreators/ContentTypesActionsCreator';
import { ContentTypes } from '@common/enums/ContentTypes';
import { CommonExerciseActionsCreator } from '@actionCreators/CommonExerciseActionsCreator';
import { Loader } from '@features/theme';
import { WritableInputText } from '@components/WritableInputText';
import TranslationsTipV2 from '@components/TranslationTipV2/TranslationTipV2';
import { CourseInterface } from '@common/interfaces/courses/CourseInterface';
import { PublishingStatus } from '@components/Publishing/PublishingStatus';
import { ImageUploadModes } from '@common/enums/FileUploadModes';
import IDDisplayer from '@components/IDDisplayer/IDDisplayer';
import { ImageUpload } from '@components/MediaUpload';
import { DraggableList } from '@components/DraggableList/DraggableList';
import { LoadingStage } from '@common/enums/LoadingStage';
import helpersService from '@services/HelpersService';
import { AccessWarning } from '@components/Warning';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { selectCourse, selectSelectedGroupsOfParent } from '@selectors/CoursesSelectors';
import { arrayMoveImmutable } from 'array-move';
import { clone } from '@helpers/clone';

export const CourseMainData = ({ openUploadCourseImage }: { openUploadCourseImage: () => void }) => {
  const dispatch = useAppDispatch();

  const loadedCourse: CourseInterface = useAppSelector(selectCourse);
  const selectedGroupsOfParent: SelectedGroupsOfParentInterface = useAppSelector(selectSelectedGroupsOfParent);

  const { isEditAvailable } = useIsEditAvailable();

  const courseTitleValidationErrors = loadedCourse?.validation?.errors.filter(
    (error: ValidationErrorInterface) => error.field === 'title',
  );
  const courseDescriptionValidationErrors = loadedCourse?.validation?.errors.filter(
    (error: ValidationErrorInterface) => error.field === 'description',
  );
  const courseImagesValidationErrors = loadedCourse?.validation?.errors.filter(
    (error: ValidationErrorInterface) => error.field === 'thumbnailImage' || error.field === 'paywallImage',
  );
  const courseChildrenValidationErrors = loadedCourse?.validation?.errors.filter(
    (error: ValidationErrorInterface) => error.field === 'groups',
  );

  return (
    <>
      <Row style={{ padding: '0', margin: '0' }}>
        <Col style={{ padding: '0', margin: '0' }}>
          <div id={`course-${loadedCourse.id}`} className="course-composer">
            <PublishingStatus ready={loadedCourse.ready} changeStatus={loadedCourse.changeStatus} />
            <IDDisplayer id={loadedCourse.id} mode="normal" />
            <TranslationsTipV2
              visitedBranch={'titleWithLocalizations'}
              type={ContentTypes.course}
              content={loadedCourse}
              errors={courseTitleValidationErrors}
              isExercise={false}
              className="content-name"
            >
              <WritableInputText
                fontSize="30"
                bold
                withoutBorder
                placeholder={'Untitled course'}
                id="course-title-input"
              />
            </TranslationsTipV2>
            <TranslationsTipV2
              visitedBranch={'descriptionWithLocalizations'}
              type={ContentTypes.course}
              content={loadedCourse}
              errors={courseDescriptionValidationErrors}
              isExercise={false}
              className="content-description"
            >
              <WritableInputText
                withGreyColor
                withoutBorder
                placeholder={'Course description'}
                id="course-description-input"
              />
            </TranslationsTipV2>
          </div>
        </Col>

        <Col sm={4} className="course-edition__file-upload">
          <ImageUpload
            mode={ImageUploadModes['no-text']}
            text="Upload course images"
            onClick={openUploadCourseImage}
            trashButtonAvailableToUser={false}
            imageData={ImageUploadUtils.getImageDataOutsideExercise(loadedCourse.thumbnailImage)}
            onProcessingFinished={(url: string) => {
              dispatch(
                CommonExerciseActionsCreator.setValueAfterProcessing({
                  url,
                  mediaType: 'image',
                  type: ContentTypes.course,
                  field: 'thumbnailImage',
                  language: 'EN',
                }),
              );
            }}
            errors={courseImagesValidationErrors}
          />
          <ValidationErrorDisplayer text={helpersService.getValidationErrorMessageText(courseImagesValidationErrors)} />
        </Col>
        {!isEditAvailable && <AccessWarning />}
      </Row>
      <Row>
        <Col style={{ padding: '0', margin: '0' }}>
          <div className="course-composer">
            {selectedGroupsOfParent.groupsLoaded !== LoadingStage.loaded ? (
              <Loader size="L" />
            ) : (
              <DraggableList
                arrayOfChildren={selectedGroupsOfParent.groups}
                onDragEnd={(result) => {
                  result.destination &&
                    dispatch(
                      ContentTypesActionsCreator.setOrder(
                        arrayMoveImmutable(
                          clone(selectedGroupsOfParent.groups),
                          result.source.index,
                          result.destination.index,
                        ),
                        result.source.index,
                        result.destination.index,
                        ContentTypes.course,
                        loadedCourse.id,
                        loadedCourse.id,
                      ),
                    );
                }}
                onContentCreate={(contentTypeToCreate, contentCategory, position) => {
                  dispatch(
                    ContentTypesActionsCreator.onCreateContent(
                      loadedCourse.id,
                      contentTypeToCreate,
                      ContentTypes.course,
                      loadedCourse.id,
                      contentCategory,
                      position,
                    ),
                  );
                }}
                creatorAcceptableButtons={{
                  [ContentTypes.level]: true,
                }}
              />
            )}
            <ValidationErrorDisplayer
              text={helpersService.getValidationErrorMessageText(courseChildrenValidationErrors)}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};
