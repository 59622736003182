import { FunctionComponent } from 'react';
import styled from 'styled-components';

import addToVocabularyIcon from './img/addToVocabulary.svg';

const VocabularyMarkContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  background: ${({ theme }) => theme.colorV2.previewVocabularyMarkBackground};
  border: 2px solid ${({ theme }) => theme.colorV2.previewVocabularyMarkBorder};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const VocabularyMark: FunctionComponent<{ isVocabulary: boolean }> = ({
  isVocabulary,
}: {
  isVocabulary: boolean;
}) => {
  if (isVocabulary) {
    return (
      <VocabularyMarkContainer>
        <img src={addToVocabularyIcon} alt="Vocabulary" />
      </VocabularyMarkContainer>
    );
  }

  return null;
};
