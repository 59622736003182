import { FunctionComponent, useMemo } from 'react';
import { useFormikContext } from 'formik';

import { FormikValuesInterface } from '@helpers/formikInitialValuesHelper';
import { SpeechRecognitionContentProps } from '../../PreviewModalProps';
import speakerIcon from './img/speaker.svg';
import { ImageContainer } from './common';
import { getValueByLanguage } from '../../../../localization-utils';

import './SpeechRecognitionContent.scss';

export const SpeechRecognitionContent: FunctionComponent<SpeechRecognitionContentProps> = ({
  content,
  deviceType,
  courseLearningLanguage,
  isForList,
}: SpeechRecognitionContentProps) => {
  const { values }: { values: FormikValuesInterface } = useFormikContext();
  const image = useMemo(() => getValueByLanguage(content.mainBundle?.image?.imageLocalizations), [content.mainBundle]);

  const isAudioEnabled = useMemo(
    () => Boolean(getValueByLanguage(content.mainBundle?.phrase?.audioLocalizations, courseLearningLanguage)),
    [content.mainBundle, courseLearningLanguage],
  );

  const phraseToSpeak = useMemo(
    () =>
      getValueByLanguage(
        isForList ? content.mainBundle?.phrase?.textLocalizations : values.mainBundle_phrase,
        courseLearningLanguage,
      ),
    [values, courseLearningLanguage, isForList, content.mainBundle],
  );

  return (
    <>
      <ImageContainer
        deviceType={deviceType}
        isImageEnabled={Boolean(image)}
        isAudioEnabled={isAudioEnabled}
        imageSrc={image}
        isVocabulary={content.mainBundle?.isVocabulary}
      />
      <span className="speech-recognition-phrase">{phraseToSpeak}</span>
      <div className="speech-recognition-controller">
        <span className="speech-recognition-controller__hint">Press to speak</span>
        <img src={speakerIcon} className="speech-recognition-controller__button" alt="Speaker" />
        <span className="speech-recognition-controller__skip">Skip this for now</span>
      </div>
    </>
  );
};
