import styled from 'styled-components';

import { ExerciseDetailsTab, ExerciseSearchResultType } from '../../types';
import { CardWrapper, CardMedia, CardLocations, CardMoreButton } from '../Common';
import { CardExerciseTitle } from './CardExerciseTitle';
import { CardExercisePhrase } from './CardExercisePhrase';

const ExerciseCardWrapper = styled(CardWrapper)`
  grid-template-columns: 20% 36% 12% 22% 4%;
`;

export const ExerciseResultCard = ({
  content,
  isExpanded,
  onExpand,
}: {
  content: ExerciseSearchResultType;
  isExpanded: boolean;
  onExpand: (tabName: ExerciseDetailsTab['key'] | null) => void;
}) => {
  return (
    <ExerciseCardWrapper isExpanded={isExpanded} onClick={() => onExpand(null)}>
      <CardExerciseTitle id={content.id} enabledMedia={content.enabledMedia} exerciseType={content.exerciseType} />
      <CardExercisePhrase isInExperiment={content.isInExperiment} phrase={content.phrase} />
      <CardMedia image={content.image} hasVideo={content.hasVideo} />
      <CardLocations
        courses={content.courses}
        locationsCount={content.locationsCount}
        onExpand={() => onExpand('locations')}
      />
      <CardMoreButton isExpanded={isExpanded} />
    </ExerciseCardWrapper>
  );
};
