import { DBId } from '@common/types/DBId';
import { apiClient } from '@features/api';

import { GrammarCategorySearchResultDetailsType } from '../types';

export const getGrammarCategoryResultDetails = async (
  categoryId: DBId,
): Promise<{ data: GrammarCategorySearchResultDetailsType }> => {
  const response = await apiClient.noErrorsV2.get(`/components/${categoryId}/mappings`);

  return {
    data: {
      mappings: response.data,
    },
  };
};
