import styled from 'styled-components';

import plusIcon from './plusIcon.svg';
import editIcon from './editIcon.svg';
import { useAppSelector } from '@redux/store';
import { selectGrammarCategoryContent } from '@selectors/GrammarSelectors';

const IconSelector = styled.img`
  :hover {
    cursor: pointer;
  }
`;

const SelectedIconContainer = styled.div`
  position: relative;
  width: 120px;
  height: 120px;
  border: 1px solid ${({ theme }) => theme.colorV2.grammarCategoryIconsSelectorSelectedIconBorder};
  border-radius: 8px;
  padding: 8px;
`;

const Icon = styled.img`
  width: 100%;
`;

const EditButton = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colorV2.grammarCategoryIconsSelectorSelectedIconButtonEditBackground};
  border: 1px solid ${({ theme }) => theme.colorV2.grammarCategoryIconsSelectorSelectedIconButtonEditBorder};
  border-radius: 8px;

  :hover {
    background: ${({ theme }) => theme.colorV2.grammarCategoryIconsSelectorSelectedIconButtonEditHoverBackground};
    cursor: pointer;
  }
`;

export const SelectedIcon = ({ openPanel }: { openPanel: () => void }) => {
  const grammarContent = useAppSelector(selectGrammarCategoryContent);

  return (
    <>
      {grammarContent.icon ? (
        <SelectedIconContainer>
          <Icon src={grammarContent.icon.svg} alt="Selected icon" />
          <EditButton onClick={openPanel}>
            <img src={editIcon} alt="Selected icon" />
          </EditButton>
        </SelectedIconContainer>
      ) : (
        <IconSelector src={plusIcon} alt="Select icon" onClick={openPanel} />
      )}
    </>
  );
};
