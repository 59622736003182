import { Theme } from '@features/theme';
import Dropdown from 'react-bootstrap/Dropdown';
import styled from 'styled-components/macro';

export const StyledDropdown = styled(Dropdown)`
  min-height: 0;

  &.show .dropdown-toggle {
    background-color: ${({ theme }) => theme.colorV2.accentPrimary};
    border-color: ${({ theme }) => theme.colorV2.accentPrimary} !important;
    color: ${({ theme }) => theme.colorV2.textLight} !important;

    &::after {
      transform: rotate(180deg);
    }

    [role='button'] {
      background-color: ${({ theme }) => theme.colorV2.accentPrimary};

      svg path {
        fill: ${({ theme }) => theme.colorV2.textLight};
      }
    }
  }
`;

export const StyledDropdownToogle = styled(Dropdown.Toggle)`
  border-color: ${({ theme }) => theme.color.brandGreyscale600} !important;
  color: ${({ theme }) => theme.colorV2.textSecondary} !important;
  height: 3.8rem;
  line-height: 1 !important;
  margin: 0;
  padding: 0.6rem 1.6rem 0.6rem 0.8rem !important;
  width: 24.4rem !important;

  &::after {
    border-width: 0.6rem;
    right: 1.4rem !important;
    transform: rotate(0deg);
    transition: transform 0.3s ease;
  }

  :focus {
    box-shadow: none !important;
    outline: 0 !important;
  }
`;

export const StyledDropdownMenu = styled(Dropdown.Menu)`
  border-radius: 0.8rem;
  color: ${({ theme }) => theme.colorV2.textTertiary};
  font-size: 1.5rem;
  max-width: 24.4rem;
  min-width: 0 !important;
  padding: 0.8rem 1.6rem 1.6rem;
`;

export const ClearIndicatorWrapper = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colorV2.uiBackgroundPrimary};
  display: flex;
  height: 2.4rem;
  justify-content: center;
  position: absolute;
  right: 0.8rem;
  top: 0.7rem;
  width: 2.4rem;
  z-index: 10;

  svg path {
    fill: ${({ theme }) => theme.colorV2.textTertiary};
  }
`;

export const RangeInputsWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 1.6rem;
  justify-content: space-between;
  margin-bottom: 1.6rem;
`;

export const RangeInput = styled.input`
  border: solid 0.1rem ${({ theme }) => theme.color.brandGreyscale600};
  border-radius: 0.8rem;
  font-size: 1.8rem;
  padding: 0.4rem 1.6rem;
  flex: 1;
`;

export const getSliderStyles = (theme: Theme) => ({
  handleStyle: {
    backgroundColor: theme.colorV2.uiBackgroundPrimary,
    border: 'none',
    boxShadow: '0 0.2rem 0.4rem rgba(0, 0, 0, 0.09)',
    height: '1.6rem',
    opacity: 1,
    width: '1.6rem',
  },
  railStyle: {
    color: theme.colorV2.uiBackgroundSecondary,
    top: '0.7rem',
  },
  trackStyle: {
    backgroundColor: theme.colorV2.accentPrimary,
    top: '0.7rem',
  },
});
