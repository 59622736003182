import { useMemo } from 'react';
import styled from 'styled-components/macro';

import { GrammarTopicSearchResultType, GrammarTopicDetailsTab } from '../../types';
import { CardWrapper, CardTitle, CardLocations, CardMoreButton, CardYesNoStatus } from '../Common';

const TopicCardWrapper = styled(CardWrapper)`
  grid-template-columns: 63% 10% 20% 4%;
`;

export const GrammarTopicResultCard = ({
  content,
  isExpanded,
  onExpand,
}: {
  content: GrammarTopicSearchResultType;
  isExpanded: boolean;
  onExpand: (tabName: GrammarTopicDetailsTab['key'] | null) => void;
}) => {
  const plainTextTitle = useMemo(
    () => content.title?.replace(/(<([^>]+)>)|(&.+;)/gi, '') || 'Untitled grammar topic',
    [content.title],
  );

  const hasLinkedLessons = content.linkedLessonsCount > 0;

  return (
    <TopicCardWrapper isExpanded={isExpanded} onClick={() => onExpand(null)}>
      <CardTitle id={content.id} title={plainTextTitle} />
      <CardYesNoStatus
        status={hasLinkedLessons}
        label={hasLinkedLessons ? `${content.linkedLessonsCount}` : undefined}
      />
      <CardLocations
        courses={content.courses}
        locationsCount={content.locationsCount}
        onExpand={() => onExpand('locations')}
      />
      <CardMoreButton isExpanded={isExpanded} />
    </TopicCardWrapper>
  );
};
