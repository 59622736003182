import { ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import type { InternalToast } from './types';
import { ToastComponent } from './Toast';
import { toastStorage } from './storage';

export const addToast = toastStorage.addToast;

export const useToast = () => {
  return toastStorage.addToast;
};

const ToastContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 7000;
  gap: 1.6rem;
  margin: 5rem;
`;

type ToastProviderProps = {
  children: ReactNode;
};
export const ToastProvider = ({ children }: ToastProviderProps) => {
  const [toasts, setToasts] = useState<InternalToast[]>([]);

  useEffect(() => {
    toastStorage.subscribe(setToasts);

    return () => {
      toastStorage.unsubscribe(setToasts);
    };
  }, []);

  return (
    <>
      <ToastContainer>
        {toasts.map((toast) => (
          <ToastComponent key={toast.id} {...toast} />
        ))}
      </ToastContainer>
      {children}
    </>
  );
};
