import React, { ReactNode, useEffect, useState } from 'react';
import classnames from 'classnames';
import { Dropdown } from 'react-bootstrap';
import { ValidationErrorDisplayer } from '@components/ValidationErrorDisplayer';
import helpersService from '@services/HelpersService';
import { useIsEditAvailable } from '@features/content/courses';
import ContentTogglerProps from './ContentTogglerProps';
import CloseIcon from './img/close.svg';
import DotsIcon from './img/dots.svg';
import PlusIcon from './img/plus.svg';
import SwitchIcon from './img/switch.svg';
import TrashIcon from './img/trash.svg';

const ContentToggler = ({
  open = false,
  forAltValues = false,
  field,
  text,
  guidelineHelp,
  children,
  noMargin = false,
  showSwitchCharacter = false,
  onSwitch,
  onRemove,
  showRemoveItem = false,
  showRemoveByToggling = true,
  showOptionsOnHover = true,
  errors = [],
  isChangeBlocked = false,
}: ContentTogglerProps) => {
  const { isEditAvailable } = useIsEditAvailable();
  let [toggled, setToggled] = useState(() => {
    if (field === undefined) {
      return open;
    } else {
      return (
        field !== null &&
        field.backendIsNullForThisTranslationsPanelContent !== true &&
        field.textLocalizations.length > 0 &&
        field.textLocalizations[0]._id
      );
    }
  });

  useEffect(() => {
    if (forAltValues) {
      setToggled(open);
    }
  }, [forAltValues, open]);

  type Ref = HTMLButtonElement;
  type props = { children: ReactNode; onClick: Function };

  const CustomToggle = React.forwardRef<Ref, props>(({ children, onClick }, ref) => (
    <div
      ref={ref as any}
      onClick={(evt) => {
        evt.preventDefault();
        onClick(evt);
      }}
    >
      {children}
    </div>
  ));

  return (
    <div
      className={`content-toggler ${noMargin ? 'content-toggler--no-margin' : ''} ${
        showOptionsOnHover ? 'content-toggler--show-options-on-over' : ''
      }`}
    >
      {!toggled ? (
        <div className="content-toggler__container">
          <span
            className="content-toggler__toggle"
            id={`content-toggler-${text}`}
            onClick={(evt) => {
              evt.stopPropagation();
              evt.preventDefault();

              if (isEditAvailable) {
                setToggled(!toggled);
                onSwitch && onSwitch(!toggled);
              }
            }}
          >
            Add {text} {isEditAvailable && <img src={PlusIcon} alt="toggle" />}
          </span>
          {guidelineHelp}
          <ValidationErrorDisplayer text={helpersService.getValidationErrorMessageText(errors)} />
        </div>
      ) : (
        <React.Fragment>
          <div className="content-toggler__toggled-text-wrapper">
            <div
              className={classnames('content-toggler__container', {
                'content-toggler__container--for-alt-values': forAltValues,
              })}
            >
              <div className="content-toggler__title">
                <span className="content-toggler__toggled-text">{text}</span>
                {isEditAvailable ? (
                  <Dropdown>
                    <Dropdown.Toggle as={CustomToggle}>
                      <img src={DotsIcon} alt="Actions" className="content-toggler__toggled-text-dots" />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {showSwitchCharacter && (
                        <React.Fragment>
                          <Dropdown.Item
                            onClick={() => {
                              onSwitch && onSwitch(!toggled);
                            }}
                            className="content-toggler__toggled-text-switch-text"
                          >
                            <img src={SwitchIcon} alt="switch character" className="content-toggler__icon" />
                            Switch character
                          </Dropdown.Item>

                          <Dropdown.Divider />
                        </React.Fragment>
                      )}

                      {showRemoveItem && (
                        <Dropdown.Item
                          onClick={() => {
                            onRemove && onRemove();
                            if (!isChangeBlocked) {
                              onSwitch && onSwitch(!toggled);
                            }
                          }}
                          className={classnames({ 'content-toggler__toggled-text-trash-text': !forAltValues })}
                        >
                          {forAltValues ? (
                            <>
                              <img src={CloseIcon} alt="close" className="content-toggler__icon" /> Close
                            </>
                          ) : (
                            <>
                              <img src={TrashIcon} alt="remove" className="content-toggler__icon" /> Remove
                            </>
                          )}
                        </Dropdown.Item>
                      )}

                      {showRemoveByToggling && (
                        <Dropdown.Item
                          onClick={() => {
                            onRemove && onRemove();
                            if (!isChangeBlocked) {
                              setToggled(!toggled);
                              onSwitch && onSwitch(!toggled);
                            }
                          }}
                          className={classnames({ 'content-toggler__toggled-text-trash-text': !forAltValues })}
                        >
                          {forAltValues ? (
                            <>
                              <img src={CloseIcon} alt="close" className="content-toggler__icon" /> Close
                            </>
                          ) : (
                            <>
                              <img src={TrashIcon} alt="remove" className="content-toggler__icon" /> Remove
                            </>
                          )}
                        </Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                ) : null}
                {guidelineHelp}
              </div>
              <ValidationErrorDisplayer text={helpersService.getValidationErrorMessageText(errors)} />
            </div>
          </div>

          {children && children}
        </React.Fragment>
      )}
    </div>
  );
};

export default ContentToggler;
