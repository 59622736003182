import * as Sentry from '@sentry/react';
import { FormikContextType, useFormikContext } from 'formik';
import { useEffect, useRef, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

import { ContentTypes, type ContentTypesType } from '@common/enums/ContentTypes';
import {
  EXERCISE_FILLGAP,
  EXERCISE_HIGHLIGHTER,
  EXERCISE_PHRASE_BUILDER,
  EXERCISE_TYPING,
} from '@common/enums/ExerciseTypes';
import { DBId } from '@common/types/DBId';
import { ReactComponent as TrashIcon } from '@components/ContentTypes/BaseContent/img/trashNew.svg';
import {
  SuggestionsSpinnerAnimated,
  SuggestionWrapper,
  TranslationTipOption,
  TranslationTipOptions,
  TranslationTipOptionSuggestions,
  TruncatedContentId,
} from '@components/TranslationTip/styles';
import { debouncedGetStringCount, filterActualError } from '@components/TranslationTip/TranslationTipUtils';

import { useIsAdmin } from '@features/auth/roles';
import { useIsEditAvailable } from '@features/content/courses';
import { Language } from '@features/content/languages';
import { SearchPanelParamsType } from '@features/search';
import { SearchModalActionsCreator } from '@actionCreators/SearchModalActionsCreator';

import { GrammarReviewActionCreators } from '@actionCreators/GrammarReviewActionCreators';
import { GrammarCategoryEditableFieldNames, GrammarCategoryFormikValues } from '../../GrammarCategory';
import { ReactComponent as AudioIcon } from './img/Audio.svg';
import { ReactComponent as LockIcon } from './img/Lock.svg';
import { ReactComponent as SearchIcon } from './img/search.svg';
import { ReactComponent as TranslationIcon } from './img/Translation.svg';
import { useToast } from '@features/app/toast';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { selectLoadedExercise } from '@selectors/CoursesSelectors';
import { userTracking } from '@features/app/tracking';

const SUGGESTION_QUERY_MIN_CHARS = 5;

type WidgetOptionsProps = {
  visitedContentId: DBId | undefined;
  translationElements: string;
  audioElements: string;
  fieldName: GrammarCategoryEditableFieldNames;
  isEditDisabled: boolean;
  isChangeBlocked: boolean;
  isWidgetActive: boolean;
  type: ContentTypesType;
  currentLanguage: Language;
  suggestionsQuery: string;
  compact: boolean;
  defaultContextForTranslators?: string;
};

export const WidgetOptions = ({
  visitedContentId,
  translationElements,
  audioElements,
  fieldName,
  isEditDisabled,
  isChangeBlocked,
  isWidgetActive,
  type,
  currentLanguage,
  suggestionsQuery,
  compact,
  defaultContextForTranslators,
}: WidgetOptionsProps) => {
  const dispatch = useAppDispatch();
  const { values, setFieldValue }: FormikContextType<GrammarCategoryFormikValues> = useFormikContext();
  const { isEditAvailable } = useIsEditAvailable();

  const isAdmin = useIsAdmin();
  const showToast = useToast();

  const localizationId = visitedContentId || 'N/A';
  const isNewString = !localizationId || localizationId === 'N/A';

  const debounceTimer = useRef<ReturnType<typeof setTimeout> | undefined>();
  const controller = useRef<AbortController | undefined>();

  const showDefaultStringWarning = () => {
    showToast({
      type: 'info',
      title: 'Default instructions cannot be edited',
      description: 'To remove the default instruction, clear it using the trash can',
    });
  };

  const loadedExercise = useAppSelector(selectLoadedExercise);

  const shouldAddSearchKtagsFilterPreset =
    type === ContentTypes.exercise &&
    [EXERCISE_FILLGAP, EXERCISE_HIGHLIGHTER, EXERCISE_PHRASE_BUILDER, EXERCISE_TYPING].includes(
      loadedExercise.exercise.type,
    ) &&
    fieldName.startsWith('phrase');

  const contentSuggestionsSearchFiltersPreset = {
    string: {
      translations: {
        with: ['EN'],
        without: [],
      },
      hasKTags: shouldAddSearchKtagsFilterPreset ? true : undefined,
    },
  } as SearchPanelParamsType['filtersPreset'];

  if (currentLanguage !== 'EN' && contentSuggestionsSearchFiltersPreset?.string?.translations) {
    contentSuggestionsSearchFiltersPreset.string.translations.with.push(currentLanguage as Language);
  }

  const [showContentSuggestions, setShowContentSuggestions] = useState(false);
  const [suggestionsCount, setSuggestionsCount] = useState(0);
  const [suggestionQueryLoading, setSuggestionQueryLoading] = useState(false);

  useEffect(() => {
    if (isNewString && isWidgetActive && suggestionsQuery && suggestionsQuery.length >= SUGGESTION_QUERY_MIN_CHARS) {
      (async () => {
        try {
          debouncedGetStringCount({
            controller,
            debounceTimer,
            filtersPreset: contentSuggestionsSearchFiltersPreset,
            query: suggestionsQuery.trim(),
            setSuggestionsCount,
            setSuggestionQueryLoading,
          });
        } catch (error) {
          Sentry.captureException(error);
          setSuggestionsCount(0);
          setSuggestionQueryLoading(false);
        }
      })();
    }

    if (!suggestionsQuery || suggestionsQuery.length < SUGGESTION_QUERY_MIN_CHARS) {
      try {
        controller.current?.abort();
        setSuggestionsCount(0);
        setSuggestionQueryLoading(false);
      } catch (error) {
        filterActualError(error);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNewString, isWidgetActive, suggestionsQuery]);

  useEffect(() => {
    if (isNewString) {
      setShowContentSuggestions(true);
    }

    return () => {
      setShowContentSuggestions(false);
    };
  }, [isNewString]);

  return (
    <TranslationTipOptions
      compact={compact}
      hideContentSuggestions={!showContentSuggestions}
      hideSearch={isEditDisabled}
    >
      {showContentSuggestions && (
        <TranslationTipOptionSuggestions>
          <SuggestionWrapper
            showMatches={suggestionsCount > 0}
            onClick={() => {
              if (suggestionsCount === 0) return;

              dispatch(
                SearchModalActionsCreator.showSearchV2Panel({
                  filtersPreset: contentSuggestionsSearchFiltersPreset,
                  contentType: type,
                  forReusing: true,
                  predefinedType: 'string',
                  language: currentLanguage,
                  query: suggestionsQuery,
                }),
              );

              userTracking.logosSearchTriggered({
                source: 'string_suggestions',
                type: 'string',
              });
            }}
          >
            {suggestionQueryLoading ? (
              <SuggestionsSpinnerAnimated />
            ) : (
              <>
                ~ {suggestionsCount} matches with&nbsp;<strong>"{suggestionsQuery}"</strong>
              </>
            )}
          </SuggestionWrapper>
        </TranslationTipOptionSuggestions>
      )}
      <TranslationTipOption
        onClick={() => {
          dispatch(
            GrammarReviewActionCreators.openTranslationsPanel(
              type,
              'translations',
              visitedContentId,
              fieldName,
              isChangeBlocked,
              isEditDisabled,
              defaultContextForTranslators,
            ),
          );
        }}
      >
        <TranslationIcon title="Translation" />
        <span>trns: {translationElements}</span>
      </TranslationTipOption>
      <TranslationTipOption
        onClick={() => {
          dispatch(
            GrammarReviewActionCreators.openTranslationsPanel(
              type,
              'audios',
              visitedContentId,
              fieldName,
              isChangeBlocked,
              isEditDisabled,
            ),
          );
        }}
      >
        <AudioIcon title="Audio" />
        <span>audio: {audioElements}</span>
      </TranslationTipOption>
      <TranslationTipOption
        title={localizationId}
        onClick={() => {
          if (localizationId?.includes('default_') && !isAdmin) {
            showDefaultStringWarning();
          }
        }}
      >
        {localizationId && (
          <CopyToClipboard text={localizationId}>
            {localizationId.includes('default_') && !isAdmin ? (
              <LockIcon title="Lock" />
            ) : (
              <TruncatedContentId
                onClick={() => {
                  showToast({
                    type: 'info',
                    title: 'Content ID copied to clipboard',
                  });
                }}
              >
                {localizationId}
              </TruncatedContentId>
            )}
          </CopyToClipboard>
        )}
      </TranslationTipOption>
      {!translationElements?.includes('0/') && isEditAvailable ? (
        <TranslationTipOption
          onlyIcon
          onClick={() => {
            dispatch(GrammarReviewActionCreators.removeString(type, fieldName));
            const newValues = values?.[fieldName]?.map((item) => {
              return {
                ...item,
                value: '',
              };
            });
            setFieldValue(fieldName, newValues);
            setFieldValue(`${fieldName}Changed`, false);
            setFieldValue(`${fieldName}Context`, '');
          }}
        >
          <TrashIcon title="Remove" />
        </TranslationTipOption>
      ) : null}
      {!isEditDisabled && (
        <TranslationTipOption
          onlyIcon
          onClick={() => {
            dispatch(
              SearchModalActionsCreator.showSearchV2Panel({
                contentType: type,
                forReusing: true,
                predefinedType: 'string',
                language: currentLanguage,
                visitedBranch: fieldName,
              }),
            );
            userTracking.logosSearchOpened({
              source: 'resource',
            });
          }}
        >
          <SearchIcon title="Search" />
        </TranslationTipOption>
      )}
    </TranslationTipOptions>
  );
};
