import { useEffect, useState } from 'react';

import { RoleplayActionCreators } from '@actionCreators/RoleplayActionsCreators';
import { useCache } from '@features/app/cache';
import { Button, SidePanelBody, SidePanelHeader } from '@features/theme';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { selectRoleplayCategoryContent } from '@selectors/roleplaySelectors';

import { RoleplayService } from '../../RoleplayService';
import { IconContainer, IconsContainer, PanelFooter, StyledSidePanel } from './styles';

/**
 * Category icons cache
 * 1. in-memory cache fallbacks to
 * 2. session storage fallbacks to
 * 3. API request
 *
 * Cache TTL is 1 day
 */

const ONE_DAY_MS = 1000 * 60 * 60 * 24;
const ROLEPLAY_CATEGORY_ICONS_STORAGE_KEY = 'roleplay-category-icons';
const ROLEPLAY_CATEGORY_ICONS_LAST_UPDATED_STORAGE_KEY = `${ROLEPLAY_CATEGORY_ICONS_STORAGE_KEY}-last-updated`;

let iconsCache: string[] = [];
let iconsCacheLastUpdated = '';

export const IconsPanel = ({ closePanel }: { closePanel: () => void }) => {
  const [storedIcons, shouldRefreshIconsCache, updateIconsStorage] = useCache({
    expirationInterval: ONE_DAY_MS,
    lastUpdated: iconsCacheLastUpdated,
    lastUpdatedStorageKey: ROLEPLAY_CATEGORY_ICONS_LAST_UPDATED_STORAGE_KEY,
    storageKey: ROLEPLAY_CATEGORY_ICONS_STORAGE_KEY,
  });

  const [icons, setIcons] = useState(iconsCache ?? storedIcons);

  const dispatch = useAppDispatch();
  const roleplayContent = useAppSelector(selectRoleplayCategoryContent);

  const [selectedIcon, setSelectedIcon] = useState<string | null>(roleplayContent.icon);

  useEffect(() => {
    if (shouldRefreshIconsCache() || !icons.length) {
      try {
        (async () => {
          const { data } = await RoleplayService.getRoleplayCategoryIcons();

          iconsCache = data;
          iconsCacheLastUpdated = new Date().toISOString();
          updateIconsStorage(data, iconsCacheLastUpdated);
          setIcons(data);
        })();
      } catch (error) {
        throw new Error('No Roleplay Category icons fetched');
      }
    }
  }, [icons, shouldRefreshIconsCache, updateIconsStorage]);

  return (
    <StyledSidePanel
      onClose={closePanel}
      header={<SidePanelHeader>Select category image</SidePanelHeader>}
      body={
        <>
          <SidePanelBody>
            <IconsContainer>
              {icons.map((icon, index) => (
                <IconContainer
                  key={index}
                  selected={!!selectedIcon && icon === selectedIcon}
                  onClick={() => setSelectedIcon(icon)}
                >
                  <img src={icon} alt="Roleplay Category icon" />
                </IconContainer>
              ))}
            </IconsContainer>
          </SidePanelBody>
          <PanelFooter>
            <Button variant="tertiary" onClick={closePanel}>
              Close
            </Button>
            <Button
              disabled={!selectedIcon}
              onClick={() => {
                selectedIcon && dispatch(RoleplayActionCreators.setRoleplayCategoryIcon(selectedIcon));
                closePanel();
              }}
            >
              Save
            </Button>
          </PanelFooter>
        </>
      }
    />
  );
};
