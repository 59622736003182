import { ContentTypes } from '@common/enums/ContentTypes';
import { ContentCreatorWidget, type ContentCreatorWidgetItemType } from '@features/theme';
import grammarCategoryIcon from '@static/svg/contentCreatorWidget/grammarCategory.svg';

const GRAMMAR_CATEGORY_CREATOR_ITEM: ContentCreatorWidgetItemType<typeof ContentTypes.grammarCategory>[] = [
  {
    label: 'Grammar Category',
    iconSrc: grammarCategoryIcon,
    showByDefault: true,
    type: ContentTypes.grammarCategory,
  },
];

type GrammarCategoryCreatorProps = {
  isCreateInProgress?: boolean;
  isEditAvailable: boolean;
  isPreEnabled: boolean;
  position: number;
  onCreateContent: (itemType: typeof ContentTypes.grammarCategory, itemCategory: null) => void;
};

export const GrammarCategoryCreator = ({
  isCreateInProgress = false,
  isEditAvailable,
  isPreEnabled,
  position,
  onCreateContent,
}: GrammarCategoryCreatorProps) => (
  <ContentCreatorWidget
    isCreateInProgress={isCreateInProgress}
    isEditAvailable={isEditAvailable}
    isPreEnabled={isPreEnabled}
    items={GRAMMAR_CATEGORY_CREATOR_ITEM}
    position={position}
    title="Create a first grammar category to get started"
    onItemSelected={(item) => onCreateContent(item.type, null)}
  />
);
