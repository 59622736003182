import { DBId } from '@common/types/DBId';

import type { FavouriteSearch } from '../../SearchContent/Common/favouriteSearches';

import { FavouriteSearchItem } from './FavouriteSearchItem';
import { FavouriteSearchesList } from './styles';

type FavouriteSearchesPropsType = {
  favouriteSearches: FavouriteSearch<any>[];
  onRemove: (favouriteSearchId: DBId) => void;
  onSearch: (favouriteSearch: FavouriteSearch<any>) => void;
  onUpdate: (favouriteSearchId: DBId, name: string) => void;
};

export const FavouriteSearches = ({ favouriteSearches, onRemove, onSearch, onUpdate }: FavouriteSearchesPropsType) => {
  return (
    <FavouriteSearchesList>
      {favouriteSearches.map((favouriteSearch) => (
        <FavouriteSearchItem
          favouriteSearch={favouriteSearch}
          key={favouriteSearch.id}
          onRemove={async () => onRemove(favouriteSearch.id)}
          onSearch={() => onSearch(favouriteSearch)}
          onUpdate={async (name: string) => onUpdate(favouriteSearch.id, name)}
        />
      ))}
    </FavouriteSearchesList>
  );
};
