import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';

import IDDisplayer from '@components/IDDisplayer/IDDisplayer';
import { CardPublishingStatus } from '@components/ContentTypes/BaseContent/CardPublishingStatus';
import { Checkbox } from '@features/theme';
import { LessonForTopic } from '../types';
import paperSheetIcon from '@components/ContentTypes/BaseContent/img/paperSheet.svg';
import { DBId } from '@common/types/DBId';

const LessonCard = styled.div<{ disabled: boolean }>`
  display: flex;
  margin-bottom: 24px;
  align-items: center;
  padding: 8px 20px;
  border-radius: 4px;
  ${({ disabled }) =>
    disabled
      ? 'opacity: 0.5;'
      : `&:hover {
    cursor: pointer;
  }`}
`;

const LessonInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const Image = styled.img`
  margin-right: 32px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
`;

const Title = styled.span`
  font-weight: 700;
  font-size: 16px;
  color: ${({ theme }) => theme.colorV2.grammarTopicLinkedLessonCardTitleText};
`;

const Description = styled.span`
  font-weight: 400;
  font-size: 14px;
  color: ${({ theme }) => theme.colorV2.grammarTopicLinkedLessonCardDescription};
`;

const SelectCheckbox = styled(Checkbox)`
  margin-right: 30px !important;
`;

export const LinkedLesson = ({
  content,
  isSelected,
  currentTopicId,
  onToggle,
}: {
  content: LessonForTopic;
  isSelected: boolean;
  currentTopicId: DBId;
  onToggle: (newValue: boolean) => void;
}) => {
  const { id, ready, title, description, image, changeStatus, attachedToTopicId } = content;
  const isLessonDisabled = !!attachedToTopicId && attachedToTopicId !== currentTopicId;

  const lessonTitle = title || 'Untitled lesson';
  const lessonDescription = description || 'Lesson description';

  return (
    <>
      {isLessonDisabled && (
        <ReactTooltip className="tooltip" id={`attached-lesson-${id}-tooltip`} effect="solid" place="right" />
      )}
      <LessonCard
        id={`lesson-${id}`}
        onClick={() => !isLessonDisabled && onToggle(!isSelected)}
        disabled={isLessonDisabled}
        data-tip={'This lesson is already attached to another topic.'}
        data-for={`attached-lesson-${id}-tooltip`}
      >
        <SelectCheckbox checked={isSelected} onToggle={onToggle} disabled={isLessonDisabled} />
        <CardPublishingStatus hasPendingChanges={!!changeStatus?.hasPendingChanges} ready={!!ready} />
        <Image src={image || paperSheetIcon} alt="" />
        <LessonInfo>
          <IDDisplayer id={id} mode="normal" />
          <Title title={lessonTitle}>{lessonTitle}</Title>
          <Description title={lessonDescription}>{lessonDescription}</Description>
        </LessonInfo>
      </LessonCard>
    </>
  );
};
