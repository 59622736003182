import type { AppState } from '@redux/store';

export const selectRoleplayNavigationLoadingParentId = ({ roleplay }: AppState) => roleplay.navigation.loadingParentId;
export const selectRoleplayNavigationStructure = ({ roleplay }: AppState) => roleplay.navigation.structure;

export const selectRoleplay = ({ roleplay }: AppState) => roleplay.roleplay;
export const selectRoleplayContent = ({ roleplay }: AppState) => roleplay.roleplay.content;
export const selectRoleplayCategories = ({ roleplay }: AppState) => roleplay.roleplay.categoriesData.categories;

export const selectRoleplayCategory = ({ roleplay }: AppState) => roleplay.roleplayCategory;
export const selectRoleplayCategoryContent = ({ roleplay }: AppState) => roleplay.roleplayCategory.content;
export const selectRoleplayCategoryScenarios = ({ roleplay }: AppState) =>
  roleplay.roleplayCategory.scenariosData.scenarios;

export const selectRoleplayScenario = ({ roleplay }: AppState) => roleplay.roleplayScenario;
export const selectRoleplayScenarioContent = ({ roleplay }: AppState) => roleplay.roleplayScenario.content;
