import { useParams } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';

import { ValidationErrorDisplayer } from '@components/ValidationErrorDisplayer';
import { LoadingStage } from '@common/enums/LoadingStage';
import { DBId } from '@common/types/DBId';
import { useIsEditAvailable } from '@features/content/courses';
import IDDisplayer from '@components/IDDisplayer/IDDisplayer';
import { PublishingStatus } from '@components/Publishing/PublishingStatus';
import { DraggableList } from '@components/DraggableList/DraggableList';
import helpersService from '@services/HelpersService';
import { ContentTypes } from '@common/enums/ContentTypes';
import { TranslationsTipWrapper } from '../TranslationsTip';
import { GrammarReviewActionCreators } from '@actionCreators/GrammarReviewActionCreators';
import { AccessWarning } from '@components/Warning';
import { WritableInputText } from '@components/WritableInputText';
import { Loader } from '@features/theme';
import { NamesSelector } from './NamesSelector';
import { IconsSelector } from './IconsSelector';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { selectIsIssuesShown } from '@selectors/UiSelectors';
import { selectGrammarCategory } from '@selectors/GrammarSelectors';
import { arrayMoveImmutable } from 'array-move';
import { clone } from '@helpers/clone';

export const GrammarCategoryData = () => {
  const dispatch = useAppDispatch();
  const { isEditAvailable } = useIsEditAvailable();
  const { courseId, categoryId } = useParams<{ courseId: DBId; categoryId: DBId }>();

  const isIssuesShown = useAppSelector(selectIsIssuesShown);
  const grammarCategory = useAppSelector(selectGrammarCategory);
  const { content, topicsData } = grammarCategory;

  const titleValidationErrors = content?.validationStatus?.errors.filter((error) => error.field === 'title');
  const descriptionValidationErrors = content?.validationStatus?.errors.filter(
    (error) => error.field === 'description',
  );
  const iconValidationErrors = content?.validationStatus?.errors.filter((error) => error.field === 'icon');
  const childrenValidationErrors =
    content?.validationStatus?.errors.filter((error) => error.field === 'grammarTopics') || [];

  return (
    <>
      <div className="course-composer">
        <div className="course-edition__first-top-element">
          <div className="course-edition__publish-status-and-other-places-container">
            <PublishingStatus
              ready={content ? content.ready : false}
              changeStatus={
                content
                  ? content.changeStatus
                  : {
                      hasNewChanges: false,
                      hasPendingChanges: false,
                    }
              }
            />
          </div>
          <Row style={{ padding: '0', margin: '0' }}>
            <Col style={{ padding: '0', margin: '0' }}>
              <div>
                <IDDisplayer id={content?.id} mode="normal" />
                {content.title._id || content.title.id ? (
                  <TranslationsTipWrapper
                    type={ContentTypes.grammarCategory}
                    fieldName={'title'}
                    content={content.title}
                    errors={titleValidationErrors}
                    className="content-name"
                    isChangeDisabled
                  >
                    <WritableInputText fontSize="30" bold withoutBorder id="category-title-input" />
                  </TranslationsTipWrapper>
                ) : (
                  <>
                    <NamesSelector disabled={!isEditAvailable} isIssuesShown={isIssuesShown} />
                    <ValidationErrorDisplayer
                      text={helpersService.getValidationErrorMessageText(titleValidationErrors)}
                    />
                  </>
                )}
                <TranslationsTipWrapper
                  type={ContentTypes.grammarCategory}
                  fieldName={'description'}
                  content={content.description}
                  errors={descriptionValidationErrors}
                  className="content-name"
                >
                  <WritableInputText
                    withGreyColor
                    withoutBorder
                    placeholder="Category Description"
                    id="category-description-input"
                  />
                </TranslationsTipWrapper>
              </div>
            </Col>

            <Col sm={4} className="course-edition__file-upload">
              <IconsSelector errors={iconValidationErrors} />
            </Col>
          </Row>
          {!isEditAvailable && <AccessWarning />}
        </div>

        {topicsData.loaded !== LoadingStage.loaded ? (
          <Loader size="L" />
        ) : (
          <DraggableList
            arrayOfChildren={topicsData.topics}
            onDragEnd={(result) => {
              result.destination &&
                dispatch(
                  GrammarReviewActionCreators.setTopicsOrder(
                    arrayMoveImmutable(clone(topicsData.topics), result.source.index, result.destination.index),
                    result.source.index,
                    result.destination.index,
                    ContentTypes.grammarCategory,
                    categoryId,
                    courseId,
                  ),
                );
            }}
            onContentCreate={(contentTypeToCreate, contentCategory, position) => {
              dispatch(GrammarReviewActionCreators.createTopic(courseId, categoryId, position));
            }}
            creatorAcceptableButtons={{
              [ContentTypes.grammarTopic]: true,
            }}
          />
        )}
        <ValidationErrorDisplayer text={helpersService.getValidationErrorMessageText(childrenValidationErrors)} />
      </div>
    </>
  );
};
