import { apiClient } from '@features/api';
import { SearchPanelParamsType } from '@features/search';

import { MAX_QUERY_CHARS_LENGTH } from '../constants';
import { truncateString } from '../util';

const ONE_HOUR_MS = 1000 * 60 * 60;

const stringCountCache: Record<string, { count: number; lastRequested: number }> = {};

const shouldFlushCache = (stringCountLastRequested: number) => {
  const stringCountLastRequestedTime = new Date(stringCountLastRequested).getTime();
  const currentTime = new Date().getTime();

  return currentTime - stringCountLastRequestedTime > ONE_HOUR_MS;
};

type GetStringCountParamsType = {
  query: string;
  filtersPreset: SearchPanelParamsType['filtersPreset'];
  signal: AbortSignal;
};

export const getStringCount = async ({ query, filtersPreset, signal }: GetStringCountParamsType) => {
  if (stringCountCache[query] && shouldFlushCache(stringCountCache[query].lastRequested)) {
    delete stringCountCache[query];
  }

  if (stringCountCache[query]) {
    return stringCountCache[query].count;
  } else {
    // truncate query string to avoid a server error when long strings
    const sanitizedQuery = truncateString(query, MAX_QUERY_CHARS_LENGTH);

    const { headers } = await apiClient.noErrorsV2.head('/search/strings', {
      params: {
        query: sanitizedQuery,
        hasTextTranslations: filtersPreset?.string?.translations?.with,
        hasKTags: filtersPreset?.string?.hasKTags === null ? undefined : filtersPreset?.string?.hasKTags,
      },
      signal,
    });

    const count = headers['x-total-count'] ?? 0;
    stringCountCache[query] = {
      count,
      lastRequested: new Date().getTime(),
    };

    return count;
  }
};
