import { ContentTypes } from '@common/enums/ContentTypes';
import { LoadingStage } from '@common/enums/LoadingStage';
import { ExerciseTypes } from '@common/enums/ExerciseTypes';

interface NewElementDataInterface {
  contentCategory: string;
  parentId: string;
  type: string;
}

export const getNewStructureElement = (newElementId: string, newElementData: NewElementDataInterface) => {
  const { contentCategory, parentId, type } = newElementData;

  const newStructureElement: any = {
    type: contentCategory,
    id: newElementId,
    children: false,
    expanded: false,
    selected: false,
    disabled: false,
    ready: false,
    parentId,
    name: '',
  };

  const isLiveLesson = contentCategory === ContentTypes.liveLesson;
  const isCheckpointLesson = contentCategory === ContentTypes.checkpoint;

  const getLessonPrefix = () => {
    if (isLiveLesson) {
      return 'Live Lesson - ';
    } else if (isCheckpointLesson) {
      return 'Checkpoint';
    }
    return '';
  };

  const lessonPrefix = getLessonPrefix();
  const untitledName = `Untitled ${type}`;

  switch (type) {
    case ContentTypes.level:
      return {
        ...newStructureElement,
        typeGroup: ContentTypes.level,
        name: untitledName,
        type,
      };
    case ContentTypes.chapter:
      return {
        ...newStructureElement,
        name: contentCategory,
        typeGroup: ContentTypes.chapter,
      };
    case ContentTypes.lesson:
      return {
        ...newStructureElement,
        name: isCheckpointLesson ? lessonPrefix : `${lessonPrefix}${untitledName}`,
        typeGroup: ContentTypes.lesson,
      };
    case ContentTypes.activity:
      return {
        ...newStructureElement,
        typeGroup: ContentTypes.activity,
        name: contentCategory,
      };
    case ContentTypes.exercise:
      return {
        ...newStructureElement,
        typeGroup: ContentTypes.exercise,
        name: contentCategory,
      };
    case ContentTypes.slidePdf:
      return {
        ...newStructureElement,
        typeGroup: ContentTypes.slidePdf,
        name: 'Slideshow',
      };
    case ContentTypes.slidePptx:
      return {
        ...newStructureElement,
        typeGroup: ContentTypes.slidePptx,
        name: 'Presentation',
      };
    default:
      console.info('Unknown content type!');
      return null;
  }
};

const addElements = (arr: any, newElements: any[], toIndex: number) => {
  const before = arr.slice(0, toIndex);
  const after = arr.slice(toIndex);
  return [...before, ...newElements, ...after];
};

export const getNewArray = (oldArray: any[], newElements: any | any[], position: number) => {
  let newArray: any[] = [];
  const elementsToAdd = !Array.isArray(newElements) ? [newElements] : newElements;

  if (position === 0) {
    newArray = [...elementsToAdd, ...oldArray];
  } else if (position !== oldArray.length) {
    newArray = addElements(oldArray, elementsToAdd, position);
  } else if (position === oldArray.length) {
    newArray = [...oldArray, ...elementsToAdd];
  }

  return newArray;
};
export const getNewGroupElement = (newElementId: string, newElementData: NewElementDataInterface) => {
  const { contentCategory, parentId, type } = newElementData;

  const newGroupElement: any = {
    id: newElementId,
    loaded: LoadingStage.loaded,
    validation: {
      invalidChildEntities: [],
      validated: false,
      valid: false,
      errors: [],
    },
    changeStatus: {
      hasPendingChanges: false,
      hasNewChanges: true,
    },
    ready: false,
    image: null,
    text: null,
    type,
  };

  switch (type) {
    case ContentTypes.level:
      return {
        ...newGroupElement,
        levelType: null,
        categoryId: parentId,
        title: null,
      };
    case ContentTypes.chapter:
      return {
        ...newGroupElement,
        categoryId: parentId,
        title: null,
      };
    case ContentTypes.lesson:
      return {
        ...newGroupElement,
        lessonType: contentCategory,
        focus: null,
        categoryId: parentId,
        title: null,
      };
    case ContentTypes.activity:
      return {
        ...newGroupElement,
        activityType: contentCategory,
        categoryId: parentId,
        title: null,
      };
    case ContentTypes.exercise:
      return {
        ...newGroupElement,
        type: contentCategory,
        validation: {
          invalidChildEntities: [],
          validated: false,
          valid: false,
          errors: [],
        },
        previewText: null,
        imageData: null,
        audio: null,
        name: null,
      };
    case ContentTypes.slidePdf:
      return {
        ...newGroupElement,
        categoryId: parentId,
        title: 'Slideshow',
      };
    case ContentTypes.slidePptx:
      return {
        ...newGroupElement,
        categoryId: parentId,
        title: 'Presentation',
      };
    case ContentTypes.grammarCategory:
    case ContentTypes.grammarTopic:
      return newGroupElement;
    default:
      console.log('Unknown content type!');
      return null;
  }
};

export const getParentType = (type: string) => {
  if (Object.values(ExerciseTypes).includes(type as any)) {
    return ContentTypes.activity;
  }
  switch (type) {
    case ContentTypes.level:
      return ContentTypes.course;
    case ContentTypes.chapter:
      return ContentTypes.level;
    case ContentTypes.lesson:
      return ContentTypes.chapter;
    case ContentTypes.activity:
      return ContentTypes.lesson;
    case ContentTypes.exercise:
      return ContentTypes.activity;
    case ContentTypes.slidePdf:
      return ContentTypes.liveLesson;
    case ContentTypes.slidePptx:
      return ContentTypes.liveLesson;
    case ContentTypes.grammarCategory:
      return ContentTypes.grammarReview;
    default:
      console.log('Unknown content type!');
      return null;
  }
};
