import moment from 'moment';

import { CourseInterface } from '@common/interfaces/courses/CourseInterface';
import { ContentTypes } from '@common/enums/ContentTypes';
import { UserInterface } from '@common/interfaces/users/UserInterface';
import { CourseInfoInterface } from '@common/interfaces/courses/CourseInfoInterface';
import { SortableFieldType } from './SortControllers';
import styled from 'styled-components';

const SortingMark = styled.span`
  display: inline-block;
  width: 1rem;
`;

export const orderTriangle = (orderByField: SortableFieldType, fieldName: string) => {
  if (orderByField.field === fieldName) {
    return <SortingMark>{orderByField.ascending ? '▲' : '▼'}</SortingMark>;
  } else {
    return <SortingMark />;
  }
};

export const sortBy = (
  elementsToSort: (CourseInfoInterface | CourseInterface | UserInterface)[],
  fieldToSortBy: string,
  fieldType: string,
  orderAsc: boolean,
) => {
  elementsToSort = [...elementsToSort];

  if (elementsToSort[0].hasOwnProperty(fieldToSortBy) === false)
    throw new Error(`Trying to sort by ${fieldToSortBy} is not possible. It is not present in the database payload`);

  switch (fieldType) {
    case ContentTypes.string:
      if (orderAsc) {
        elementsToSort = elementsToSort.sort((item1: any, item2: any) => {
          return item1[fieldToSortBy]?.toString().localeCompare(item2[fieldToSortBy]?.toString());
        });
      } else {
        elementsToSort = elementsToSort.sort((item1: any, item2: any) => {
          return item2[fieldToSortBy]?.toString().localeCompare(item1[fieldToSortBy]?.toString());
        });
      }

      break;

    case 'date':
      if (orderAsc) {
        elementsToSort = elementsToSort.sort((item1: any, item2: any) => {
          const date1InEpochFormat = moment(item1[fieldToSortBy]).valueOf();
          const date2InEpochFormat = moment(item2[fieldToSortBy]).valueOf();

          if (date1InEpochFormat > date2InEpochFormat) return 1;
          else return -1;
        });
      } else {
        elementsToSort = elementsToSort.sort((item1: any, item2: any) => {
          const date1InEpochFormat = moment(item1[fieldToSortBy]).valueOf();
          const date2InEpochFormat = moment(item2[fieldToSortBy]).valueOf();

          if (date1InEpochFormat > date2InEpochFormat) return -1;
          else return 1;
        });
      }

      break;

    default:
      if (orderAsc) {
        elementsToSort = elementsToSort.sort((item1: any, item2: any) => {
          if (item1[fieldToSortBy] > item2[fieldToSortBy]) return 1;
          else return -1;
        });
      } else {
        elementsToSort = elementsToSort.sort((item1: any, item2: any) => {
          if (item1[fieldToSortBy] > item2[fieldToSortBy]) return -1;
          else return 1;
        });
      }

      break;
  }

  return elementsToSort;
};
