import styled from 'styled-components';

import { DBId } from '@common/types/DBId';
import { Loader } from '@features/theme';
import { LinkedChapter } from './LinkedChapter';
import { ChapterForEntrypoint } from '../types';

const ChaptersList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 49rem;
  max-height: 49rem;
  justify-content: start;
  margin-bottom: 2.4rem;
  overflow: auto;
`;

const LevelTitle = styled.span`
  font-weight: 700;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colorV2.placementTestLinkedChapterModalListText};
  text-transform: uppercase;
  margin-bottom: 2.4rem;
  align-self: start;
`;

const NoChapters = styled.div`
  display: flex;
  align-self: center;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colorV2.placementTestLinkedChapterModalListText};
`;

export const LinkedChaptersList = ({
  isNotLoadedYet,
  availableChapters,
  levelName,
  currentSelectedChapter,
  onChapterSelect,
}: {
  isNotLoadedYet: boolean;
  availableChapters: ChapterForEntrypoint[];
  levelName: string;
  currentSelectedChapter: { id: DBId; title: string } | null;
  onChapterSelect: (chapterId: { id: DBId; title: string }) => void;
}) => (
  <>
    <LevelTitle>{levelName}</LevelTitle>
    <ChaptersList id="chapters-list">
      {isNotLoadedYet ? (
        <Loader size="L" />
      ) : (
        <>
          {availableChapters.length ? (
            <>
              {availableChapters.map((chapter, index) => (
                <LinkedChapter
                  key={`${chapter.id}`}
                  content={chapter}
                  isSelected={currentSelectedChapter?.id === chapter.id}
                  isChapterDisabled={index === 0}
                  onSelect={() => onChapterSelect({ id: chapter.id, title: chapter?.title || 'Untitled chapter' })}
                />
              ))}
            </>
          ) : (
            <NoChapters>No available chapters</NoChapters>
          )}
        </>
      )}
    </ChaptersList>
  </>
);
