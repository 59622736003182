import type { MediaDataInterface } from '@common/interfaces/contentTypes/MediaDataInterface';
import type { ValidationErrorInterface } from '@common/interfaces/validation/ValidationInterface';
import { CommonActions } from '@actions/CommonActions';

export const CommonExerciseActionsCreator = {
  nullifyExplanation: () => ({
    type: CommonActions.NULLIFY_FIELD,
    payload: {
      field: 'feedback',
    },
  }),
  setAudioValueAfterRequestOrCancelAudio: (payload: {
    audioRequestData?: MediaDataInterface['audioRequest'];
    bundleName?: string;
    column?: number;
    field: string;
    lessonId?: string;
    language: string;
    type: string;
    row?: number;
  }) => ({
    type: CommonActions.SET_AUDIO_VALUE_AFTER_REQUEST_OR_CANCEL_AUDIO,
    payload,
  }),
  setValueAfterProcessing: (payload: {
    url: string;
    mediaType: string;
    type: string;
    field: string;
    language: string;
    bundleName?: string;
    lessonId?: string;
    row?: number;
    column?: number;
  }) => ({
    type: CommonActions.SET_VALUE_AFTER_PROCESSING,
    payload,
  }),
  updateValidationErrors: (payload: { errors: ValidationErrorInterface[] }) => ({
    type: CommonActions.UPDATE_VALIDATION_ERRORS,
    payload,
  }),
};
