import styled from 'styled-components';

import { HeaderWrapper } from './HeaderWrapper';

const BundleHeaderWrapper = styled(HeaderWrapper)`
  grid-template-columns: 36% 10% 8% 8% 8% 25%;
`;

export const BundleSearchResultTypeHeader = () => (
  <BundleHeaderWrapper>
    <span>Resource Bundle</span>
    <span>Media</span>
    <span>Vocab Item</span>
    <span>Audio</span>
    <span>Translation</span>
    <span>Location</span>
  </BundleHeaderWrapper>
);
