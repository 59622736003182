import React, { ReactElement, useState } from 'react';
import { FormikContextType, useFormikContext } from 'formik';

import { ValidationErrorDisplayer } from '@components/ValidationErrorDisplayer';
import {
  constants as contentConstants,
  formatCounterOfFilledLocalizations,
  isLocalizationFilled,
  isLocalizationNeedsToBeCounted,
} from '@features/content';
import { type ContentTypesType } from '@common/enums/ContentTypes';
import { useIsAdmin } from '@features/auth/roles';
import { camelCaseSeparateWords } from '@helpers/camel-case-separator';
import { useIsEditAvailable } from '@features/content/courses';
import helpersService from '@services/HelpersService';
import { ValidationErrorInterface } from '@common/interfaces/validation/ValidationInterface';
import { Language, LANGUAGE_NAMES } from '@features/content/languages';
import { LocalizationInterface } from '@common/interfaces/localization/LocalizationInterface';
import { TranslationsPanelContentInterface } from '@common/interfaces/exercises/TranslationsPanelContentInterface';
import { GrammarCategoryEditableFieldNames, GrammarCategoryFormikValues } from '../GrammarCategory';
import { TranslationsTip } from './TranslationsTip';
import * as _ from 'lodash';
import { selectIsIssuesShown } from '@selectors/UiSelectors';
import { useAppSelector } from '@redux/store';
import { selectInterfaceLanguages, selectLearningLanguage } from '@selectors/CoursesSelectors';

type TranslationsTipWrapperProps = {
  children: ReactElement;
  fieldName: GrammarCategoryEditableFieldNames;
  content: TranslationsPanelContentInterface | null;
  type: ContentTypesType;
  errors?: ValidationErrorInterface[];
  className?: string;
  isChangeDisabled?: boolean;
  defaultContextForTranslators?: string;
};

export const TranslationsTipWrapper = ({
  children,
  fieldName,
  content,
  type,
  errors = [],
  className,
  isChangeDisabled = false,
  defaultContextForTranslators,
}: TranslationsTipWrapperProps) => {
  const isAdmin = useIsAdmin();
  const { values, setFieldValue }: FormikContextType<GrammarCategoryFormikValues> = useFormikContext();
  const { isEditAvailable } = useIsEditAvailable();
  const isIssuesShown = useAppSelector(selectIsIssuesShown);
  const isErrorsShown = isIssuesShown && errors?.length;
  const hasErrors = errors?.some((e) => !e.isWarning);
  const hasWarnings = errors?.some((e) => e.isWarning);

  const interfaceLanguages = useAppSelector(selectInterfaceLanguages);
  const courseLearningLanguage: Language = useAppSelector(selectLearningLanguage) as Language;

  let totalNeededLanguages = interfaceLanguages.length;

  if (!interfaceLanguages.includes(courseLearningLanguage)) {
    totalNeededLanguages = totalNeededLanguages + 1;
  }

  if (!interfaceLanguages.includes('EN') && courseLearningLanguage !== 'EN') {
    totalNeededLanguages = totalNeededLanguages + 1;
  }

  const numberOfTranslations =
    content?.audioLocalizations.filter(
      (localization) =>
        isLocalizationFilled(localization) &&
        isLocalizationNeedsToBeCounted(
          localization,
          interfaceLanguages as Language[],
          courseLearningLanguage as Language,
        ),
    ).length || 0;
  const audioElements = `${numberOfTranslations}/${totalNeededLanguages}`;
  const translationElements = formatCounterOfFilledLocalizations(
    (values?.[fieldName]?.filter((item) => item.value && !item.isPhonetic) as unknown as LocalizationInterface[]) || [],
    interfaceLanguages as Language[],
    courseLearningLanguage as Language,
  );

  const [currentLanguage, setCurrentLanguage] = useState(contentConstants.DEFAULT_LANGUAGE as Language);
  const [isPhonetic, setIsPhonetic] = useState(false);

  const displayedValueInChildren =
    values?.[fieldName].find((item) => item.language === currentLanguage && !item.isPhonetic)?.value || '';

  const placeholder =
    children.props.placeholder === undefined
      ? `${camelCaseSeparateWords(_.upperFirst(fieldName))} in ${LANGUAGE_NAMES[currentLanguage as Language]}`
      : children.props.placeholder;

  const isDefaultString = (content?._id || content?.id)?.includes('default_');
  const isEditDisabled = isChangeDisabled || !isEditAvailable;

  const isChangeBlocked =
    (isDefaultString && isAdmin) ||
    (!isEditDisabled && (content?.mappings?.count > 1 || !!content?.mappings?.length) && !content?.isReusingConfirmed);

  return (
    <>
      <TranslationsTip
        translationElements={translationElements}
        audioElements={audioElements}
        visitedContentId={content?._id || content?.id}
        fieldName={fieldName}
        isEditDisabled={isEditDisabled}
        isChangeBlocked={isChangeBlocked}
        onLanguageSwitchToggled={(switchedToEnglishLanguage: boolean, isPhoneticSelected: boolean) => {
          setCurrentLanguage(
            switchedToEnglishLanguage ? (contentConstants.DEFAULT_LANGUAGE as Language) : courseLearningLanguage,
          );
          setIsPhonetic(isPhoneticSelected);
        }}
        type={type}
        currentLanguage={currentLanguage}
        className={className}
        suggestionsQuery={displayedValueInChildren}
        defaultContextForTranslators={defaultContextForTranslators}
      >
        {React.cloneElement(children, {
          placeholder: placeholder,
          value: displayedValueInChildren,
          currentLanguage,
          onChange: (availableText: string) => {
            const newValues = values?.[fieldName]?.map((item) =>
              item.language === currentLanguage && item.isPhonetic === isPhonetic
                ? {
                    ...item,
                    value: availableText,
                  }
                : item,
            );

            setFieldValue(fieldName, newValues);
            setFieldValue(`${fieldName}Changed`, true);
          },
          disabled: isEditDisabled,
          errorsShown: isErrorsShown,
          errorType: hasErrors ? 'error' : hasWarnings ? 'warning' : undefined,
        })}
      </TranslationsTip>
      <ValidationErrorDisplayer text={helpersService.getValidationErrorMessageText(errors)} />
    </>
  );
};
