import styled from 'styled-components/macro';

import { Button } from '@features/theme';

export const PublishingButton = styled(Button)<{ hovered: boolean }>`
  visibility: ${({ hovered }) => (hovered ? 'visible' : 'hidden')};
  width: 12rem;
  margin: 0 2.5rem 0 0;
  justify-content: center;
  transition: none;
`;

export const EmptyButton = styled.div`
  width: 12rem;
  margin-right: 2.5rem;
`;
