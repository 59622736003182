import { takeLatest } from 'redux-saga/effects';

import { SpeechRecognitionExerciseActions } from '@actions/SpeechRecognitionExerciseActions';
import ExerciseSagas from '@sagas/exercises/definitions/ExerciseSagas';
import MediaUploadSagas from '@sagas/mediaUpload/definitions/MediaUploadSagasDefinition';
import SpeechRecognitionExerciseSagas from '@sagas/exercises/definitions/SpeechRecognitionExerciseSagasDefinition';
import type { AppDispatch } from '@redux/store';

export function* SpeechRecognitionExerciseRelatedSagas(dispatch: AppDispatch) {
  yield takeLatest(SpeechRecognitionExerciseActions.SAVE, SpeechRecognitionExerciseSagas.save, dispatch);
  yield takeLatest(SpeechRecognitionExerciseActions.UPLOAD_IMAGE, MediaUploadSagas.uploadImageToExercise);
  yield takeLatest(SpeechRecognitionExerciseActions.REMOVE_AUDIO, ExerciseSagas.removeAudioFromExercise);
}
