import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { ContentTypes, type ContentTypesType } from '@common/enums/ContentTypes';
import { DBId } from '@common/types/DBId';
import { ExerciseTypes } from '@common/enums/ExerciseTypes';
import { allTypesMap } from '@helpers/contentCardHelper';
import { CommonNavigationItem, NavigationItemType, ChildrenArray } from '@features/content/navigation';

export const NavigationItem = ({
  content,
  structure,
  onExpand,
  loadingParentId,
  currentPathIds,
}: {
  content: NavigationItemType;
  structure: NavigationItemType[];
  onExpand: (type: ContentTypesType, id: DBId) => void;
  loadingParentId: string;
  currentPathIds: string[];
}) => {
  const { categoryId, topicId, exerciseId } = useParams<{ categoryId: DBId; topicId: DBId; exerciseId: DBId }>();
  const [expanded, setExpanded] = useState(content.expanded);

  useEffect(() => {
    if (!expanded && (categoryId === content.id || topicId === content.id)) {
      setExpanded(true);
      content.children && handleExpand(content.type as ContentTypesType, content.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryId, topicId]);

  const handleExpand = (type: ContentTypesType, id: DBId) => {
    if (!loadingParentId) {
      setExpanded(!expanded);

      if (!expanded) {
        onExpand(type, id);
      }
    }
  };

  const getUrl = () => {
    const courseId = structure.find((element) => element.type === ContentTypes.grammarReview)?.id;
    switch (content.type) {
      case ContentTypes.grammarReview:
        return `/course/${content.id}/grammar-review`;
      case ContentTypes.grammarCategory:
        return `/course/${courseId}/grammar-review/category/${content.id}`;
      case ContentTypes.grammarTopic:
        const categoryId = structure.find((element) => element.id === content.parentId)?.id;
        return `/course/${courseId}/grammar-review/category/${categoryId}/topic/${content.id}`;
      default:
        if (Object.values(ExerciseTypes).includes(content.type as any)) {
          const topicId = structure.find((element) => element.id === content.id)?.parentId;
          const categoryIdForExercise = structure.find((element) => element.id === topicId)?.parentId;
          return `/course/${courseId}/grammar-review/category/${categoryIdForExercise}/topic/${topicId}/exercise/${content.id}`;
        } else {
          return '';
        }
    }
  };
  const childrenArray = structure.filter((element) => element.parentId === content.id);
  const grandChildrenArray = structure.filter(
    (element) => element.parentId && childrenArray.map((element) => element.id).includes(element.parentId),
  );

  return (
    <>
      <CommonNavigationItem
        currentPathIds={currentPathIds}
        loadingParentId={loadingParentId}
        content={content}
        expanded={expanded}
        handleExpand={handleExpand}
        url={getUrl()}
        exerciseId={exerciseId}
        title={content.title || allTypesMap[content.type as ContentTypesType]}
      />
      <ChildrenArray
        visible={content.children && expanded}
        childrenAmount={
          content.type === ContentTypes.grammarReview
            ? structure.filter((element) => element.type !== ContentTypes.grammarReview).length
            : childrenArray.length + grandChildrenArray.length
        }
      >
        {structure
          .filter((element) => element.parentId === content.id)
          .map((element) => (
            <NavigationItem
              key={element.id}
              content={element}
              structure={structure}
              onExpand={onExpand}
              loadingParentId={loadingParentId}
              currentPathIds={currentPathIds}
            />
          ))}
      </ChildrenArray>
    </>
  );
};
