import { createSlice, ActionCreator } from '@reduxjs/toolkit';
import { roleplayInitialState } from '@redux/initialStates/roleplayInitialState';
import { RoleplayReducers } from '@redux/reducers/roleplaySlice/RoleplayReducers';

const extraReducers = {
  ...RoleplayReducers,
} as Record<string, ActionCreator<any>>;

export const roleplaySlice = createSlice({
  name: 'roleplay',
  initialState: roleplayInitialState,
  reducers: {},

  extraReducers: (builder) => {
    Object.keys(extraReducers).forEach((actionType) => {
      builder.addCase(actionType, extraReducers[actionType]);
    });
  },
});
