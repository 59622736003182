import { useEffect } from 'react';
import { useDialogModal, Modal } from '@features/modal';
import { useCSATState } from './useCSATState';
import { CSATContent } from './CSATContent';
import { useUser } from '@features/auth';
import { UserInterface } from '@common/interfaces/users/UserInterface';
import { isFeatureEnabled } from '@helpers/featuresHelper';

export const CSAT_URL = 'https://forms.gle/mnNBxEw58SfFDixz8';

// Seconds to wait to ignore the modal
export const TIMEOUT_SEQUENCE = [5, 10, 15, 20, 25, 30, 45, 60];

const shouldOpenCSATForUser = (user: UserInterface | null): boolean => {
  if (!isFeatureEnabled('vpn')) {
    return false;
  }

  if (user === null) {
    return false;
  }

  return user.role !== 'ROLE_ADMIN' && user.role !== 'ROLE_VIEWER';
};

const wait = (time: number) => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(null), time);
  });
};

const timeoutGenerator = async function* (timeout: number, close: () => void) {
  while (timeout > 0) {
    yield timeout;
    timeout--;
    await wait(1000);
  }

  close();
};

export const CSATModal = () => {
  const { ready, timeoutSequence, complete, ignore } = useCSATState();

  const broadcastChannel = new BroadcastChannel('csat-status');

  const { open: openCSATModal, modal: csatModal } = useDialogModal((modalControls) => {
    broadcastChannel.onmessage = () => {
      modalControls.close();
    };

    return (
      <Modal isOpen={modalControls.isOpen}>
        <CSATContent
          open={() => {
            window.open(CSAT_URL, '_blank')?.focus();

            complete();
            broadcastChannel.postMessage('completed');
            modalControls.close();
          }}
          ignore={() => {
            return timeoutGenerator(TIMEOUT_SEQUENCE[Math.min(timeoutSequence, TIMEOUT_SEQUENCE.length - 1)], () => {
              ignore();
              broadcastChannel.postMessage('ignored');
              modalControls.close();
            });
          }}
        />
      </Modal>
    );
  });

  useEffect(() => {
    if (ready) {
      openCSATModal();
    }
  }, [ready, openCSATModal]);

  const { user } = useUser();
  if (!shouldOpenCSATForUser(user)) {
    return null;
  }

  return csatModal;
};
