import { CourseSliceInterface } from '@common/interfaces/slices/CourseSliceInterface';
import { ContentTypes, ContentType } from '@common/enums/ContentTypes';
import { PublishingActions } from '@actions/PublishingActions';
import produce from 'immer';
import { PayloadAction } from '@reduxjs/toolkit';

const PublishingActionsCourseReducers = {
  [PublishingActions.SET_CONTENT_TO_PUBLISH]: (state: CourseSliceInterface, { payload }: PayloadAction<any>) => {
    const { contentId, contentType, readyState, forUpdate, parentId, parentType, language } = payload;

    return produce(state, (draftState) => {
      draftState.header.publishing.preparedContent = {
        id: contentId,
        type: contentType,
        readyState,
        forUpdate,
        parentId,
        parentType: parentType || '',
        language,
      };
    });
  },
  [PublishingActions.SET_INITIAL_CONTENT_TO_PUBLISH]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ) => {
    return produce(state, (draftState) => {
      draftState.header.publishing.preparedContent = {
        id: '',
        type: '',
        readyState: false,
        forUpdate: false,
        parentId: '',
        parentType: '',
        language: undefined,
      };
    });
  },
  [PublishingActions.SET_PUBLISHING_IN_PROGRESS]: (state: CourseSliceInterface, { payload }: PayloadAction<any>) => {
    return produce(state, (draftState) => {
      draftState.header.publishing.inProgress = payload;
    });
  },
  [PublishingActions.SET_INVALID_CHILDREN_MODAL_OPENED]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ) => {
    return produce(state, (draftState) => {
      draftState.header.publishing.modalOpened = payload.opened;
      draftState.header.publishing.invalidChildren = payload.data;
      draftState.header.publishing.errorInExercises = payload.errorInExercises;
    });
  },
  [PublishingActions.ON_MULTI_PUBLISH_SUCCESS]: (
    state: CourseSliceInterface,
    { payload: { exerciseIds } }: PayloadAction<{ exerciseIds: string[] }>,
  ) =>
    produce(state, (draftState) => {
      draftState.loadedExercises.exercises = draftState.loadedExercises.exercises.map((exercise) =>
        exerciseIds.includes(exercise.id) ? { ...exercise, ready: true } : exercise,
      );
      draftState.course.structure = draftState.course.structure.map((exercise) =>
        exerciseIds.includes(exercise.id) ? { ...exercise, ready: true } : exercise,
      );
    }),
  [PublishingActions.ON_MULTI_UNPUBLISH_SUCCESS]: (
    state: CourseSliceInterface,
    { payload: { exerciseIds } }: PayloadAction<{ exerciseIds: string[] }>,
  ) =>
    produce(state, (draftState) => {
      draftState.loadedExercises.exercises = draftState.loadedExercises.exercises.map((exercise) =>
        exerciseIds.includes(exercise.id) ? { ...exercise, ready: false } : exercise,
      );
      draftState.course.structure = draftState.course.structure.map((exercise) =>
        exerciseIds.includes(exercise.id) ? { ...exercise, ready: false } : exercise,
      );
    }),
  [PublishingActions.CHANGE_READY_STATE]: (state: CourseSliceInterface, { payload }: PayloadAction<any>) => {
    const { contentType, id, forUpdate } = payload;

    if (contentType === ContentTypes.exercise) {
      return {
        ...state,
        loadedExercise: {
          ...state.loadedExercise,
          exercise: state.loadedExercise.exercise
            ? {
                ...state.loadedExercise.exercise,
                content: {
                  ...state.loadedExercise.exercise.content,
                  ready: forUpdate || !state.loadedExercise.exercise.content.ready,
                  changeStatus: {
                    hasNewChanges: state.loadedExercise.exercise.content.ready && !forUpdate,
                    hasPendingChanges: true,
                  },
                },
              }
            : null,
        },
        loadedExercises: {
          ...state.loadedExercises,
          exercises: state.loadedExercises.exercises.map((exercise) =>
            exercise.id === id
              ? {
                  ...exercise,
                  ready: forUpdate || !exercise.ready,
                  changeStatus: {
                    hasNewChanges: exercise.ready && !forUpdate,
                    hasPendingChanges: true,
                  },
                }
              : { ...exercise },
          ),
        },
      };
    } else if (contentType === ContentTypes.course) {
      return {
        ...state,
        course: {
          ...state.course,
          ready: forUpdate || !state.course.ready,
          changeStatus: {
            hasNewChanges: state.course.ready && !forUpdate,
            hasPendingChanges: true,
          },
        },
      };
    } else if (contentType === ContentType.group) {
      return {
        ...state,
        selectedGroupsOfParent: {
          ...state.selectedGroupsOfParent,
          parentContents:
            state.selectedGroupsOfParent.parentContents && state.selectedGroupsOfParent.parentContents.id === id
              ? {
                  ...state.selectedGroupsOfParent.parentContents,
                  ready: forUpdate || !state.selectedGroupsOfParent.parentContents.ready,
                  changeStatus: {
                    hasNewChanges: state.selectedGroupsOfParent.parentContents.ready && !forUpdate,
                    hasPendingChanges: true,
                  },
                }
              : state.selectedGroupsOfParent.parentContents,
          groups: state.selectedGroupsOfParent.groups.map((group) =>
            group.id === id
              ? {
                  ...group,
                  ready: forUpdate || !group.ready,
                  changeStatus: {
                    hasNewChanges: group.ready && !forUpdate,
                    hasPendingChanges: true,
                  },
                }
              : { ...group },
          ),
        },
      };
    }
  },
};

export default PublishingActionsCourseReducers;
