import { FunctionComponent, useState, useMemo } from 'react';
import classnames from 'classnames';
import { useFormikContext } from 'formik';

import { FormikValuesInterface } from '@helpers/formikInitialValuesHelper';
import { ConversationContentProps } from '../../PreviewModalProps';
import { getValueByLanguage } from '../../../../localization-utils';

import arrowIcon from './img/arrow.svg';
import writeIcon from './img/write.svg';
import speakIcon from './img/speak.svg';

import './ConversationContent.scss';

export const ConversationContent: FunctionComponent<ConversationContentProps> = ({
  content,
  deviceType,
  courseLearningLanguage,
  isForList,
}: ConversationContentProps) => {
  const { values }: { values: FormikValuesInterface } = useFormikContext();
  const [isHintOpened, setIsHintOpened] = useState(false);

  const image1Value = useMemo(
    () => getValueByLanguage(content.image1?.imageLocalizations) || content.image1?.imageLocalizations[0]?.value,
    [content.image1],
  );
  const isImage1Loaded = useMemo(
    () => (content.image1?._id || content.image1?.id) && image1Value,
    [content.image1, image1Value],
  );
  const image2Value = useMemo(
    () => getValueByLanguage(content.image2?.imageLocalizations) || content.image2?.imageLocalizations[0]?.value,
    [content.image2],
  );
  const isImage2Loaded = useMemo(
    () => (content.image2?._id || content.image2?.id) && image2Value,
    [content.image2, image2Value],
  );
  const image3Value = useMemo(
    () => getValueByLanguage(content.image3?.imageLocalizations) || content.image3?.imageLocalizations[0]?.value,
    [content.image3],
  );
  const isImage3Loaded = useMemo(
    () => (content.image3?._id || content.image3?.id) && image3Value,
    [content.image3, image3Value],
  );
  const hintText = useMemo(
    () => getValueByLanguage(isForList ? content.hint?.textLocalizations : values.hint, courseLearningLanguage),
    [values, courseLearningLanguage, isForList, content.hint],
  );
  const isAll3ImagesLoaded = useMemo(
    () => isImage1Loaded && isImage2Loaded && isImage3Loaded,
    [isImage1Loaded, isImage2Loaded, isImage3Loaded],
  );

  return (
    <>
      <div
        className={classnames('conversation-images', {
          'conversation-images--wide': deviceType === 'desktop' && isAll3ImagesLoaded,
        })}
      >
        {isImage1Loaded ? <img src={image1Value} className="conversation-images__image" alt="Conversation 1" /> : null}
        {isImage2Loaded ? <img src={image2Value} className="conversation-images__image" alt="Conversation 2" /> : null}
        {isImage3Loaded ? <img src={image3Value} className="conversation-images__image" alt="Conversation 3" /> : null}
      </div>
      {content.hint ? (
        <div className="conversation-hint">
          <div className="conversation-hint__container" onClick={() => setIsHintOpened(!isHintOpened)}>
            <span>Click here to get a hint</span>
            <img
              alt="Conversation"
              src={arrowIcon}
              className={classnames('conversation-arrow', { 'conversation-arrow--opened': isHintOpened })}
            />
          </div>
          {isHintOpened && <span className="conversation-hint__text">{hintText}</span>}
        </div>
      ) : null}
      <span className="conversation-question">How would you like to complete this exercise?</span>
      <div className={classnames('conversation-buttons', `conversation-buttons--${deviceType}`)}>
        <div className="conversation-button">
          <img src={writeIcon} alt="Write" />
          <span className="conversation-button__caption">Write</span>
        </div>
        <div className="conversation-button">
          <img src={speakIcon} alt="Speak" />
          <span className="conversation-button__caption">Speak</span>
        </div>
      </div>
    </>
  );
};
