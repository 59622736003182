import { call, put, select } from 'redux-saga/effects';
import FlashcardExerciseService from '@services/ExerciseRelatedServices/FlashcardExerciseService';
import FlashcardExerciseInterface from '@components/Exercises/Flashcard/interfaces/FlashCardExerciseInterface';
import ExerciseSagas from '@sagas/exercises/definitions/ExerciseSagas';
import { FlashcardExerciseActions } from '@actions/FlashcardExerciseActions';
import type { AppDispatch } from '@redux/store';
import { PayloadAction } from '@reduxjs/toolkit';
import { selectLoadedExerciseData } from '@selectors/CoursesSelectors';
import { selectLearningLanguage } from '@selectors/CoursesSelectors';

const FlashcardExerciseSagas = {
  *save(dispatch: AppDispatch, { payload }: PayloadAction<any>) {
    try {
      let { values } = payload;

      yield put({
        type: FlashcardExerciseActions.SET_FLASHCARD_ALL_FIELDS,
        payload: {
          values,
        },
      });
      let exercise: FlashcardExerciseInterface = yield select(selectLoadedExerciseData);
      const learningLanguage: string = yield select(selectLearningLanguage);

      yield call(FlashcardExerciseService.save, dispatch, exercise, values, learningLanguage);

      yield call(ExerciseSagas.requestOneExerciseCommon);
    } catch (e: any) {
      console.error(e);
    }
  },
};

export default FlashcardExerciseSagas;
