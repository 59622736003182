import { apiClient } from '@features/api';

import type { StringSearchResultType } from '../SearchResultItem';
import type { StringQueryParamsType } from './types';

import { MAX_QUERY_CHARS_LENGTH } from '../constants';
import { truncateString } from '../util';

const getQueryParams = ({
  languages,
  courses,
  translations,
  audios,
  isReused,
  hasKTags,
  isDefaultInstruction,
}: StringQueryParamsType) => {
  const withTranslations = translations?.with.map((language) => language.toUpperCase()) || [];
  const withoutTranslations = translations?.without.map((language) => language.toUpperCase()) || [];

  const withAudio = audios?.with.map((language) => language.toUpperCase()) || [];
  const withoutAudio = audios?.without.map((language) => language.toUpperCase()) || [];

  return {
    languages: languages || undefined,
    courses: courses || undefined,
    hasTextTranslations: withTranslations.length > 0 ? withTranslations : undefined,
    hasNoTextTranslations: withoutTranslations.length > 0 ? withoutTranslations : undefined,
    hasAudioTranslations: withAudio.length > 0 ? withAudio : undefined,
    hasNoAudioTranslations: withoutAudio.length > 0 ? withoutAudio : undefined,
    isReused: isReused !== null ? isReused : undefined,
    isDefaultInstruction: isDefaultInstruction !== null ? isDefaultInstruction : undefined,
    hasKTags: hasKTags !== null ? hasKTags : undefined,
  };
};

export const searchByString = async (
  query: string,
  page: number,
  filtersParams: StringQueryParamsType,
): Promise<{ data: { count: number; results: StringSearchResultType[] } }> => {
  // truncate query string to avoid a server error when long strings
  const sanitizedQuery = truncateString(query, MAX_QUERY_CHARS_LENGTH);

  return await apiClient.v2.get('/search/strings', {
    params: {
      query: sanitizedQuery,
      page,
      ...getQueryParams(filtersParams),
    },
  });
};
