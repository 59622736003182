import type { ActivityType } from '../../ActivityTypes';

import activityDevelopIcon from './main/Develop.svg';
import activityDialogueIcon from './main/Dialogue.svg';
import activityDiscoverIcon from './main/Discover.svg';
import activityEntityIcon from './main/Entity.svg';
import activityMemoriseIcon from './main/Memorise.svg';
import activityQuizIcon from './main/Quiz.svg';
import activityVocabularyIcon from './main/Vocabulary.svg';
import activityComprehensionIcon from './others/Comprehension.svg';
import activityConversationIcon from './others/Conversation.svg';
import activityPracticeIcon from './others/Practice.svg';
import activityProductiveIcon from './others/Productive.svg';
import activityPronunciationIcon from './others/Pronunciation.svg';
import activityReadingIcon from './others/Reading.svg';
import activityVideoIcon from './others/Video.svg';
import activitySpeakingIcon from './others/Speaking.svg';

const ACTIVITY_ICONS: { [key in ActivityType]: string } = {
  develop: activityDevelopIcon,
  dialogue: activityDialogueIcon,
  discover: activityDiscoverIcon,
  entity: activityEntityIcon,
  memorise: activityMemoriseIcon,
  quiz: activityQuizIcon,
  vocabulary: activityVocabularyIcon,
  comprehensionSkills: activityComprehensionIcon,
  conversation: activityConversationIcon,
  practice: activityPracticeIcon,
  productiveSkills: activityProductiveIcon,
  pronunciation: activityPronunciationIcon,
  reading: activityReadingIcon,
  video: activityVideoIcon,
  speakingActivity: activitySpeakingIcon,
};

export default ACTIVITY_ICONS;
