const SAVE = 'DIALOGUE_SAVE';
const SHOW_DIALOGUE_CHARACTERS_SELECTOR = 'DIALOGUE_SHOW-DIALOGUE-CHARACTERS-SELECTOR';
const SHOW_DIALOGUE_CHARACTERS_SELECTOR_SUCCESS = 'DIALOGUE_SHOW-DIALOGUE-CHARACTERS-SELECTOR_SUCCESS';
const SET_SELECTED_CHARACTERS_SELECTOR = 'DIALOGUE_SET_SELECTED_CHARACTERS_SELECTOR';
const ADD_SCRIPT_LINE = 'DIALOGUE_ADD_SCRIPT_LINE';
const UPDATE_CHARACTER_NAME = 'DIALOGUE_UPDATE_CHARACTER_NAME';
const SWITCH_CHARACTER_FOR_DIALOGUE_LINE = 'DIALOGUE_SWITCH_CHARACTER_FOR_DIALOGUE_LINE';
const SET_INSTRUCTIONS_LANGUAGE = 'DIALOGUE_SET_INSTRUCTIONS_LANGUAGE';
const SHOW_DIALOGUE_CHARACTERS_SELECTOR_TO_UPDATE_PHOTO = 'DIALOGUE_SHOW_DIALOGUE_CHARACTERS_SELECTOR_TO_UPDATE_PHOTO';
const SHOW_DIALOGUE_CHARACTERS_SELECTOR_TO_UPDATE_PHOTO_SUCCESS =
  'DIALOGUE_SHOW_DIALOGUE_CHARACTERS_SELECTOR_TO_UPDATE_PHOTO_SUCCESS';
const UPDATE_SELECTED_CHARACTERS = 'DIALOGUE_UPDATE_SELECTED_CHARACTERS';
const DELETE_DIALOGUE_LINE = 'DIALOGUE_DELETE_DIALOGUE_LINE';
const UPDATE_SCRIPT_LINES_ORDER = 'DIALOGUE_UPDATE_SCRIPT_LINES_ORDER';
const UPDATE_DIALOGUE_LINE = 'DIALOGUE_UPDATE_DIALOGUE_LINE';
const SET_DIALOGUE_DESCRIPTION_LANGUAGE = 'DIALOGUE_SET_DIALOGUE_DESCRIPTION_LANGUAGE';
const SET_DIALOGUE_DESCRIPTION_NEEDED = 'DIALOGUE_SET_DIALOGUE_DESCRIPTION_NEEDED';
const REMOVE_SOUND_FROM_DIALOGUE_LINE = 'REMOVE_SOUND_FROM_DIALOGUE_LINE';
const SET_DIALOGUE_ALL_FIELDS = 'SET_DIALOGUE_ALL_FIELDS';

export const DialogueExerciseActions = {
  SAVE,
  SHOW_DIALOGUE_CHARACTERS_SELECTOR,
  SHOW_DIALOGUE_CHARACTERS_SELECTOR_SUCCESS,
  SET_SELECTED_CHARACTERS_SELECTOR,
  ADD_SCRIPT_LINE,
  UPDATE_CHARACTER_NAME,
  SWITCH_CHARACTER_FOR_DIALOGUE_LINE,
  SET_INSTRUCTIONS_LANGUAGE,
  SHOW_DIALOGUE_CHARACTERS_SELECTOR_TO_UPDATE_PHOTO,
  SHOW_DIALOGUE_CHARACTERS_SELECTOR_TO_UPDATE_PHOTO_SUCCESS,
  UPDATE_SELECTED_CHARACTERS,
  DELETE_DIALOGUE_LINE,
  UPDATE_SCRIPT_LINES_ORDER,
  UPDATE_DIALOGUE_LINE,
  SET_DIALOGUE_DESCRIPTION_LANGUAGE,
  SET_DIALOGUE_DESCRIPTION_NEEDED,
  REMOVE_SOUND_FROM_DIALOGUE_LINE,
  SET_DIALOGUE_ALL_FIELDS,
} as const;
