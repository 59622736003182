import { MouseEvent, useCallback, useEffect, useState } from 'react';
import ReactAudioPlayer from 'react-audio-player';
import { useDropzone } from 'react-dropzone';
import { Button } from 'react-bootstrap';

import { AudioUploadingFailedInterface } from '@common/interfaces/contentTypes/UploadingFailedInterface';
import { FailedUploadingMessage } from '../FailedUploadingMessage/FailedUploadingMessage';
import { AudioUploadingInterface } from '@common/interfaces/contentTypes/AudioUploadingInterface';
import { apiClient } from '@features/api';
import { AudioRequestModal, cancelAudioRequest, RequestAudioButton } from '@features/audioManagement';
import { useIsEditAvailable } from '@features/content/courses';

import TrashIcon from '@static/svg/Trash.svg';
import DownloadIcon from './img/Download.svg';
import InfoIcon from './img/Info.svg';
import { random } from 'lodash';
import { Language } from '@features/content/languages';
import { LoadingStage } from '@common/enums/LoadingStage';
import {
  AudioUploadInnerWrapper,
  AudioUploadInProgress,
  AudioUploadInstructions,
  AudioUploadInstructionsDescription,
  AudioUploadInstructionsDetails,
  AudioUploadProcessing,
  AudioUploadProcessingInfo,
  AudioUploadWrapper,
  StyledUploadIcon,
} from './styles';
import { HelpDisplayer } from '@features/help';
import { DBId } from '@common/types/DBId';
import { constants as contentConstants } from '@features/content';
import { useToast } from '@features/app/toast';
import { selectIsIssuesShown } from '@selectors/UiSelectors';
import { useAppSelector } from '@redux/store';
import {
  selectAudioUploadingFailed,
  selectAudioUploadingInProgress,
  selectCourse,
  selectIsSaveButtonEnabled,
} from '@selectors/CoursesSelectors';
import { selectPlacementTestEntrypoint } from '@selectors/PlacementTestSelectors';
import { selectGrammarTopic } from '@selectors/GrammarSelectors';
import { MediaDataInterface } from '@common/interfaces/contentTypes/MediaDataInterface';
import { ValidationErrorInterface } from '@common/interfaces/validation/ValidationInterface';

const { MEDIA_STATUS_REQUEST_INTERVAL, MEDIA_STATUS_REQUEST_ATTEMPTS } = contentConstants;

export type AudioUploadProps = {
  disabled?: boolean;
  onChange?: (file: File, progressHandler: (progress: number) => void) => void;
  onRemove?: Function;
  audioFile?: string;
  audioData?: MediaDataInterface;
  fullScreen?: boolean;
  compactMode?: boolean;
  showTrashCan?: boolean;
  errors?: ValidationErrorInterface[];
  isChangeBlocked?: boolean;
  currentLanguage?: string;
  fieldName?: string;
  row?: number;
  onAudioRequestSuccess?: (audioRequestId: string) => void;
  onCancelAudioRequestSuccess?: () => void;
  onChangeInstant?: Function;
  onProcessingFinished?: Function;
};

const AudioUpload = ({
  disabled = false,
  onChange,
  onRemove,
  audioData,
  fullScreen = false,
  compactMode = false,
  showTrashCan = true,
  errors = [],
  isChangeBlocked = false,
  currentLanguage = 'EN',
  fieldName = '',
  row,
  onAudioRequestSuccess,
  onCancelAudioRequestSuccess,
  onChangeInstant,
  onProcessingFinished,
}: AudioUploadProps) => {
  const { isEditAvailable } = useIsEditAvailable();
  const [uniqueId] = useState(random(0, 10000));
  const [audioFile, setAudioFile] = useState(audioData?.mediaValue || '');
  const [runInterval, setRunInterval] = useState(false);
  const [isFirstRequestPending, setIsFirstRequestPending] = useState(false);
  const [processing, setProcessing] = useState(audioData?.mediaId && !audioData?.processed);
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);
  const [progress, setProgress] = useState(0);
  const [showAudioRequestModal, setShowAudioRequestModal] = useState(false);

  const course = useAppSelector(selectCourse);
  const entrypoint = useAppSelector(selectPlacementTestEntrypoint);
  const grammarTopic = useAppSelector(selectGrammarTopic);
  const isIssuesShown = useAppSelector(selectIsIssuesShown);

  const showToast = useToast();

  const audioUploadingFailed: AudioUploadingFailedInterface[] = useAppSelector(selectAudioUploadingFailed);
  const audioUploadingInProcess: AudioUploadingInterface[] = useAppSelector(selectAudioUploadingInProgress);

  const isSaveButtonEnabled = useAppSelector(selectIsSaveButtonEnabled);

  const errorDetails = audioUploadingFailed.find((item) => {
    if (row) {
      return item.language === currentLanguage && item.field === fieldName && item.row === row;
    }
    return item.language === currentLanguage && item.field === fieldName;
  });

  const isValidationErrorShown = errors && errors.length && isIssuesShown;

  const isDragAndDropDisabled = audioFile !== '' || !isEditAvailable || disabled;

  const onDropHandler = (file: File) => {
    if (isChangeBlocked) {
      onChangeInstant && onChangeInstant();
    } else {
      onChange && onChange(file, setProgress);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop: (files) => onDropHandler(files[0]) });

  const removeAudio = () => {
    if (isChangeBlocked) {
      onChangeInstant && onChangeInstant();
    } else {
      onRemove && onRemove();
      setProcessing(false);
      setAudioFile('');
      setProgress(0);
      intervalId && clearInterval(intervalId);
      setIntervalId(null);
    }
  };

  const { contentId, mediaId } = audioData || {};

  const getStatus = useCallback(() => {
    apiClient.v2
      .get(`content/resources/${contentId}/audio/${mediaId}/status`)
      .then((data) => {
        if (data.data.processed) {
          setAudioFile(data.data.url);
          onProcessingFinished && onProcessingFinished(data.data.url);
          setProcessing(false);
        } else {
          setRunInterval(true);
        }
      })
      .catch((error) => {
        console.error('error', error);
        if (error.response.status === 404) {
          setProcessing(false);
        }
      });
  }, [contentId, mediaId, onProcessingFinished]);

  const getCEFRLevel = useCallback(() => {
    if (window.location.pathname.includes('entrypoint') && entrypoint.loaded === LoadingStage.loaded) {
      return entrypoint.content.cefr || null;
    }

    if (window.location.pathname.includes('grammar') && grammarTopic.loaded === LoadingStage.loaded) {
      return grammarTopic.content.cefr || null;
    }

    if (window.location.pathname.includes('level') && course.loaded === LoadingStage.loaded) {
      const levelId = window.location.pathname.match(/level\/([\w-]+)/)?.[1];

      if (levelId) {
        return course.structure.find((item) => item.id === levelId)?.cefr || null;
      }
    }

    return null;
  }, [course, entrypoint, grammarTopic]);

  const onCancelAudioRequest = (audioRequestIdToCancel: DBId) => {
    cancelAudioRequest(audioRequestIdToCancel as string)
      .then(() => {
        showToast({
          type: 'success',
          title: 'Audio request was successfully cancelled',
        });

        onCancelAudioRequestSuccess && onCancelAudioRequestSuccess();
      })
      .catch(() => {
        showToast({
          type: 'error',
          title: 'An error occurred on cancelling request',
        });
      });
  };

  useEffect(() => {
    if (processing && !isFirstRequestPending) {
      setIsFirstRequestPending(true);
      getStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFirstRequestPending, processing]);

  useEffect(() => {
    if (runInterval) {
      const intervalId = setInterval(() => {
        apiClient.v2
          .get(`content/resources/${contentId}/audio/${mediaId}/status`)
          .then((data) => {
            if (data.data.processed) {
              setAudioFile(data.data.url);
              onProcessingFinished && onProcessingFinished(data.data.url);
              setProcessing(false);
              clearInterval(intervalId);
              setRunInterval(false);
            }
          })
          .catch((error) => {
            console.error('error', error);
          });
      }, MEDIA_STATUS_REQUEST_INTERVAL);
      setIntervalId(intervalId);

      setTimeout(() => {
        intervalId && clearInterval(intervalId);
        setIntervalId(null);
      }, MEDIA_STATUS_REQUEST_INTERVAL * MEDIA_STATUS_REQUEST_ATTEMPTS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [runInterval]);

  useEffect(() => {
    setAudioFile(audioData?.mediaValue || '');
    setProcessing(audioData?.mediaId && !audioData?.processed);
  }, [audioData]);

  const isUploadInProgress = audioUploadingInProcess.find(
    (item) => item.field === fieldName && item.language === currentLanguage && item.row === row,
  );

  return (
    <div>
      <AudioUploadWrapper
        compactMode={compactMode}
        disabled={disabled}
        fullScreen={fullScreen}
        isDragActive={isDragActive}
        isUploadInProgress={isUploadInProgress}
        processing={processing}
        title={audioFile}
        withError={isValidationErrorShown || errorDetails}
        withErrorFailed={errorDetails}
        withFile={audioFile !== ''}
        {...(isDragAndDropDisabled ? null : getRootProps())}
        onClick={(e: MouseEvent<HTMLElement>) => {
          if (isDragAndDropDisabled) {
            return null;
          } else {
            if (isChangeBlocked) {
              e.stopPropagation();
              onChangeInstant && onChangeInstant();
            } else {
              const { onClick } = getRootProps();
              onClick && onClick(e);
            }
          }
        }}
      >
        {!audioFile && !processing && (
          <>
            {isUploadInProgress ? (
              <AudioUploadInProgress>
                <progress id="progressBar" value={progress} max="100"></progress>
                <span>Uploading ... {progress}%</span>
              </AudioUploadInProgress>
            ) : (
              <AudioUploadInnerWrapper>
                {isEditAvailable ? (
                  <>
                    <AudioUploadInstructions>
                      <StyledUploadIcon />
                      <AudioUploadInstructionsDescription>
                        Drag & drop your audio here, or&nbsp;
                        <label htmlFor={isChangeBlocked ? '' : `browse-${uniqueId}`} className="file-upload__browse">
                          browse
                        </label>
                        &nbsp;to upload.&nbsp;
                        <AudioUploadInstructionsDetails>
                          (25MB max, .wav and .xwav supported)
                        </AudioUploadInstructionsDetails>
                        <HelpDisplayer type="guideline" id="guideline-upload-audio" />
                      </AudioUploadInstructionsDescription>
                      <input
                        {...getInputProps()}
                        type="file"
                        className="file-upload__file"
                        id={`browse-${uniqueId}`}
                        name="browse"
                        disabled={disabled || !isEditAvailable}
                      />
                    </AudioUploadInstructions>
                    <RequestAudioButton
                      disabled={disabled}
                      contentId={audioData?.contentId || ''}
                      requestStatus={audioData?.audioRequest}
                      onCancelAudioRequest={onCancelAudioRequest}
                      onRequestAudio={() => setShowAudioRequestModal(true)}
                    />
                  </>
                ) : (
                  <span>Get edit permissions to upload audio.</span>
                )}
              </AudioUploadInnerWrapper>
            )}
          </>
        )}
        {processing && (
          <AudioUploadProcessing>
            <AudioUploadProcessingInfo>
              <img src={InfoIcon} alt="" className="audio-upload__info-icon" />
              <span>
                {isSaveButtonEnabled
                  ? 'We are processing this audio. Please press “Save” and check back later.'
                  : 'We are processing this audio.'}
              </span>
            </AudioUploadProcessingInfo>
            {showTrashCan && !compactMode && (
              <Button
                variant="light"
                disabled={!isEditAvailable || disabled}
                onClick={() => removeAudio()}
                className="audio-upload__trash"
              >
                <img src={TrashIcon} alt="Remove audio" />
              </Button>
            )}
          </AudioUploadProcessing>
        )}
        {audioFile !== '' && !processing && (
          <AudioUploadInnerWrapper>
            <ReactAudioPlayer src={audioFile} controls />

            {!compactMode && (
              <a href={audioFile} target="_blank" rel="noopener noreferrer">
                <img src={DownloadIcon} alt="" className="audio-upload__download-icon" />
              </a>
            )}
            {showTrashCan && !compactMode && (
              <Button
                variant="light"
                disabled={!isEditAvailable || disabled}
                onClick={() => removeAudio()}
                className="audio-upload__trash"
              >
                <img src={TrashIcon} alt="Remove audio" />
              </Button>
            )}
          </AudioUploadInnerWrapper>
        )}
      </AudioUploadWrapper>
      <FailedUploadingMessage errorDetails={errorDetails} />

      <AudioRequestModal
        language={currentLanguage as Language}
        level={getCEFRLevel()}
        phrase={audioData?.phraseText || ''}
        resourceId={audioData?.contentId || ''}
        show={showAudioRequestModal}
        onHide={() => setShowAudioRequestModal(false)}
        onSuccess={(requestId: string) => {
          onAudioRequestSuccess && onAudioRequestSuccess(requestId);
        }}
      />
    </div>
  );
};

export default AudioUpload;
