import { type DisplayContentToUserModeType } from '@common/enums/DisplayContentToUserMode';
import { ExerciseTypes } from '@common/enums/ExerciseTypes';
import { DBId } from '@common/types/DBId';
import { AnyExerciseInterface } from '@common/types/exercises/AnyExerciseInterface';
import { Language } from '@features/content/languages';
import { FormikValuesInterface } from '@helpers/formikInitialValuesHelper';
import { SpeechRecognitionExerciseActions } from '@actions/SpeechRecognitionExerciseActions';
import SpeechRecognitionExerciseInterface from '@components/Exercises/SpeechRecognition/interfaces/SpeechRecognitionExerciseInterface';
import { SpeechRecognitionExerciseFeedbackOptions } from '@components//Exercises/SpeechRecognition/types/SpeechRecognitionExerciseFeedbackOptions';

export const SpeechRecognitionExerciseActionsCreator = {
  save: (exercise: SpeechRecognitionExerciseInterface, learningLanguage: Language, values: FormikValuesInterface) => ({
    type: SpeechRecognitionExerciseActions.SAVE,
    payload: {
      exercise,
      learningLanguage,
      values,
    },
  }),
  setImage: (exercise: AnyExerciseInterface, image: File, progressHandler: Function) => ({
    type: SpeechRecognitionExerciseActions.UPLOAD_IMAGE,
    payload: {
      exercise,
      image,
      exerciseType: ExerciseTypes.speechRecognition,
      progressHandler,
    },
  }),
  setInstructionsLanguage: (instructionsLanguage: DisplayContentToUserModeType) => ({
    type: SpeechRecognitionExerciseActions.SET_INSTRUCTIONS_LANGUAGE,
    payload: {
      instructionsLanguage,
    },
  }),
  updatePhonemes: (phonemes: string[]) => ({
    type: SpeechRecognitionExerciseActions.UPDATE_PHONEMES,
    payload: {
      phonemes,
    },
  }),
  removeMultiOptionChooserOption: () => ({
    type: SpeechRecognitionExerciseActions.REMOVE_MULTI_OPTION_CHOOSER_OPTION,
  }),
  setMultiOptionChooserOptionAs: (feedbackOption: SpeechRecognitionExerciseFeedbackOptions) => ({
    type: SpeechRecognitionExerciseActions.SET_MULTI_OPTION_CHOOSER_OPTION_AS,
    payload: {
      feedbackOption,
    },
  }),
  removeImage: () => ({
    type: SpeechRecognitionExerciseActions.REMOVE_IMAGE,
  }),
  removeAudio: ({ contentId, language }: { contentId: DBId | undefined; language: string }) => ({
    type: SpeechRecognitionExerciseActions.REMOVE_AUDIO,
    payload: { contentId, language },
  }),
  setFeedbackOption: () => ({
    type: SpeechRecognitionExerciseActions.SET_FEEDBACK_TEXT_OPTION,
  }),
  removeVideo: () => ({
    type: SpeechRecognitionExerciseActions.REMOVE_VIDEO,
  }),
};
