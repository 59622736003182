import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import EnglishPhonemeTable from './EnglishPhonemeTable';
import EnglishPhonemeTableMap from './EnglishPhonemeTableMap';

type PhonemeTableProps = {
  show: boolean;
  onSave: (phonemes: string[]) => void;
  preselectedPhonemes: string[] | null;
  close: () => void;
};

export const PhonemeTable = ({ show, onSave, preselectedPhonemes, close }: PhonemeTableProps) => {
  let [chosenPhonemes, setChosenPhonemes] = useState<Set<number>>(() => {
    if (preselectedPhonemes === null) return new Set();
    else {
      const setOfPositions = new Set<number>();

      preselectedPhonemes.forEach((phoneme: string) => setOfPositions.add(EnglishPhonemeTableMap.lastIndexOf(phoneme)));

      return setOfPositions;
    }
  });

  return (
    <Modal show={show} className="phoneme-table" centered animation={false}>
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">Select up to 2 phonemes</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="phoneme-table__phonemes phoneme-table__phonemes--english">
          <EnglishPhonemeTable
            chosenPhonemes={chosenPhonemes}
            onChange={(chosenPhonemes: Set<number>) => {
              setChosenPhonemes(chosenPhonemes);
            }}
          />
        </div>
      </Modal.Body>
      <footer className="phoneme-table__save-cancel">
        <Button className="translations-panel-modal__cancel" onClick={close}>
          Cancel
        </Button>
        <Button
          className="phoneme-table__save"
          disabled={chosenPhonemes.size === 0}
          onClick={() => {
            let mappedValues = Array.from(chosenPhonemes).map(
              (phonemePosition: number) => EnglishPhonemeTableMap[phonemePosition],
            );
            onSave && onSave(mappedValues);
            close();
          }}
        >
          Save
        </Button>
      </footer>
    </Modal>
  );
};
