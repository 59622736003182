/**
 * Browser cache utils
 *
 * @param expirationInterval - ttl after flushing the cache, defaults to 1 hour
 * @param lastUpdated - last updated timestamp
 * @param lastUpdatedStorageKey - name of browser storage item which contains the last updated timestamp
 * @param storageKey - name of browser storage item which contains the stored data
 *
 * @returns Array [storedCache, shouldRefreshCache, updateStorage]
 */

const DEFAULT_EXPIRATION_INTERVAL = 1000 * 60 * 60; // ms

type UseCacheConfig = {
  expirationInterval?: number;
  lastUpdated: string | undefined;
  lastUpdatedStorageKey: string;
  storageKey: string;
};

export const useCache = ({
  expirationInterval = DEFAULT_EXPIRATION_INTERVAL,
  lastUpdated,
  lastUpdatedStorageKey,
  storageKey,
}: UseCacheConfig) => {
  const storedCache = JSON.parse(sessionStorage.getItem(storageKey) ?? 'null');

  const shouldRefreshCache = () => {
    const lastUpdatedString = lastUpdated || sessionStorage.getItem(lastUpdatedStorageKey);

    if (!lastUpdatedString) return true;

    const lastUpdatedTime = new Date(lastUpdatedString).getTime();
    const currentTime = new Date().getTime();

    return currentTime - lastUpdatedTime > expirationInterval;
  };

  const updateStorage = (data: unknown, lastUpdatedTimestamp: string) => {
    sessionStorage.setItem(storageKey, JSON.stringify(data));
    sessionStorage.setItem(lastUpdatedStorageKey, lastUpdatedTimestamp);
  };

  return [storedCache, shouldRefreshCache, updateStorage];
};
