import { useMemo } from 'react';

import { ContentTypes } from '@common/enums/ContentTypes';
import {
  EXERCISE_DIALOGUE,
  EXERCISE_LISTEN_REPEAT,
  EXERCISE_TITLES,
  GRADABLE_EXERCISES,
  ExerciseType,
} from '@common/enums/ExerciseTypes';
import { ActivityInterface } from '@common/interfaces/contentTypes/ActivityInterface';
import { ContentCreatorWidget, type ContentCreatorWidgetItemType } from '@features/theme';
import { useAppSelector } from '@redux/store';
import { selectSelectedGroupsOfParentParentContents } from '@selectors/CoursesSelectors';

import { useBulkPublishing } from '../bulkPublishing';
import { EXERCISE_TYPES_USER_ORDERED, MAX_NUMBER_OF_VISIBLE_BUTTONS, ICON_PER_EXERCISE } from './constants';

const EXERCISE_CREATOR_ITEMS: ContentCreatorWidgetItemType<ExerciseType>[] = EXERCISE_TYPES_USER_ORDERED.map(
  (exerciseType, index) => ({
    label: EXERCISE_TITLES[exerciseType],
    iconSrc: ICON_PER_EXERCISE[exerciseType] as string,
    showByDefault: index < MAX_NUMBER_OF_VISIBLE_BUTTONS,
    type: exerciseType,
  }),
);

type ExerciseCreatorProps = {
  allowBulk?: boolean;
  isCreateInProgress?: boolean;
  isEditAvailable: boolean;
  isPreEnabled: boolean;
  onlyGradable?: boolean;
  parentType?: typeof ContentTypes.entrypoint | typeof ContentTypes.grammarTopic;
  position: number;
  onCreateContent: (itemCategory: ExerciseType, numItemsToCreate: number) => void;
  onSearch: () => void;
};

export const ExerciseCreator = ({
  allowBulk = false,
  isCreateInProgress = false,
  isEditAvailable,
  isPreEnabled,
  onlyGradable = false,
  position,
  onCreateContent,
  onSearch,
}: ExerciseCreatorProps) => {
  const { currentBulkPublishingState } = useBulkPublishing();
  const loadedContent = useAppSelector(selectSelectedGroupsOfParentParentContents) as ActivityInterface;

  const isDialogueActivity = loadedContent?.activityType === ContentTypes.dialogue;
  const isSpeakingActivity = loadedContent?.activityType === ContentTypes.speakingActivity;

  const exerciseCreatorItems: ContentCreatorWidgetItemType<ExerciseType>[] = useMemo(() => {
    let _exerciseCreatorItems = EXERCISE_CREATOR_ITEMS;

    if (!isDialogueActivity && !isSpeakingActivity) {
      if (onlyGradable) {
        _exerciseCreatorItems = EXERCISE_CREATOR_ITEMS.filter((item) => GRADABLE_EXERCISES.includes(item.type));
      } else {
        _exerciseCreatorItems = EXERCISE_CREATOR_ITEMS.filter(
          (item) => item.type !== EXERCISE_DIALOGUE && item.type !== EXERCISE_LISTEN_REPEAT,
        );
      }
    }

    if (isDialogueActivity) {
      _exerciseCreatorItems = EXERCISE_CREATOR_ITEMS.filter((item) => item.type === EXERCISE_DIALOGUE);
    }

    if (isSpeakingActivity) {
      _exerciseCreatorItems = EXERCISE_CREATOR_ITEMS.filter((item) => item.type === EXERCISE_LISTEN_REPEAT);
    }

    return _exerciseCreatorItems.map((item, index) => ({
      ...item,
      showByDefault: index < MAX_NUMBER_OF_VISIBLE_BUTTONS,
      showOnlyIcon: _exerciseCreatorItems.length > 1 && index < MAX_NUMBER_OF_VISIBLE_BUTTONS,
    }));
  }, [isDialogueActivity, isSpeakingActivity, onlyGradable]);

  const title = `Create ${allowBulk ? 'exercise(s)' : 'a first exercise'} to get started`;

  return (
    <ContentCreatorWidget
      allowBulk={allowBulk}
      isCreateInProgress={isCreateInProgress}
      isEditAvailable={isEditAvailable}
      isPreEnabled={isPreEnabled}
      items={exerciseCreatorItems}
      position={position}
      title={title}
      getItemLabel={(exerciseType: ExerciseType) => EXERCISE_TITLES[exerciseType]}
      onItemSelected={(item, numItemsToCreate = 1) => onCreateContent(item.type, numItemsToCreate)}
      onSearch={!currentBulkPublishingState ? onSearch : undefined}
    />
  );
};
