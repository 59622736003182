import { CSSProperties, useEffect, useState } from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { DropdownInterface } from './DropdownInterface';
import { DBId } from '@common/types/DBId';

export type DropdownInterfaceCode = DropdownInterface['code'];
export interface DropdownProps<T extends string> {
  value?: DropdownInterface<T>['code'];
  values: DropdownInterface<T>[];
  onChange: (selectedValue: DropdownInterface<T>) => void;
  title?: string | JSX.Element;
  className?: string;
  style?: CSSProperties;
  id?: DBId;
  disabled?: boolean;
}

export const dropdown_defaultCode = '0' as const;

export const DropdownWrapper = <T extends string>({
  value = dropdown_defaultCode,
  values,
  onChange,
  title,
  className,
  style,
  id,
  disabled = false,
}: DropdownProps<T>) => {
  const [selectedDropdownValueId, setSelectedDropdownValueId] = useState(value);

  useEffect(() => {
    setSelectedDropdownValueId(value);
  }, [value, selectedDropdownValueId]);

  const onChanged = (value: any) => {
    onChange && onChange(value);
    setSelectedDropdownValueId(value.code);
  };

  const getName = () => {
    let foundId = values.find((value: any) => {
      return value.code === selectedDropdownValueId;
    });

    if (foundId !== undefined) {
      return foundId.name;
    } else return title;
  };

  return (
    <DropdownButton
      className={className}
      data-testid={id}
      disabled={disabled}
      id={id}
      style={style}
      title={getName()}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      {values.map((value: DropdownInterface) => (
        <Dropdown.Item
          as="button"
          className={`dropdown-item-${value.code.toLowerCase()}`}
          key={value.code}
          active={selectedDropdownValueId === value.code}
          onClick={() => onChanged(value)}
        >
          {value?.name}
        </Dropdown.Item>
      ))}
    </DropdownButton>
  );
};
