import { FunctionComponent, useEffect, useState } from 'react';

import { DisplayContentToUserMode, type DisplayContentToUserModeType } from '@common/enums/DisplayContentToUserMode';
import { useIsEditAvailable } from '@features/content/courses';
import { UserLanguageCheckboxView } from './UserLanguageCheckboxView';
import { Language, LANGUAGE_NAMES } from '@features/content/languages';
import { useAppSelector } from '@redux/store';
import { selectLearningLanguage } from '@selectors/CoursesSelectors';

interface UserLanguageCheckboxProps {
  checked?: boolean;
  disabled?: boolean;
  bothModeEnabled?: boolean;
  onChange?: Function;
  checkedField?: DisplayContentToUserModeType | undefined;
  checkedOverride?: boolean; // INTO-1108
}

export const UserLanguageCheckbox: FunctionComponent<UserLanguageCheckboxProps> = ({
  checked: checkedParam,
  disabled = false,
  bothModeEnabled = false,
  onChange,
  checkedField,
  checkedOverride,
}: UserLanguageCheckboxProps) => {
  const { isEditAvailable } = useIsEditAvailable();
  let [checked, setChecked] = useState(
    checkedOverride
      ? checkedParam
      : checkedParam || bothModeEnabled
        ? checkedField === DisplayContentToUserMode.both
          ? true
          : false
        : checkedField === DisplayContentToUserMode.learning
          ? true
          : false,
  );

  // INTO-1108 - Run callback to update Redux value returned by the server
  useEffect(() => {
    if (checkedOverride) {
      if (onChange) {
        if (bothModeEnabled) {
          if (checkedParam) onChange(DisplayContentToUserMode.both);
          else onChange(DisplayContentToUserMode.learning);
        } else {
          if (checkedParam) onChange(DisplayContentToUserMode.learning);
          else onChange(DisplayContentToUserMode.interface);
        }
      }
      setChecked(checkedParam);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedOverride]);

  const courseLearningLanguage = useAppSelector(selectLearningLanguage);

  const courseLanguageName = LANGUAGE_NAMES[courseLearningLanguage as Language];

  const toggle = () => {
    let newCheckedState: boolean = !checked;

    if (onChange) {
      if (bothModeEnabled) {
        if (newCheckedState) onChange(DisplayContentToUserMode.both);
        else onChange(DisplayContentToUserMode.learning);
      } else {
        if (newCheckedState) onChange(DisplayContentToUserMode.learning);
        else onChange(DisplayContentToUserMode.interface);
      }
    }

    setChecked(newCheckedState);
  };

  if (checkedOverride) {
    return null;
  }

  return (
    <UserLanguageCheckboxView
      isEditAvailable={isEditAvailable}
      toggle={toggle}
      checked={!!checked}
      courseLanguageName={courseLanguageName}
      disabled={disabled}
    />
  );
};
