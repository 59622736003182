import {
  EXERCISE_COMPREHENSION,
  EXERCISE_CONVERSATION,
  EXERCISE_DIALOGUE,
  EXERCISE_FLASHCARD,
  EXERCISE_FILLGAP,
  EXERCISE_HIGHLIGHTER,
  EXERCISE_MATCHUP,
  EXERCISE_MULTIPLE_CHOICE,
  EXERCISE_PHRASE_BUILDER,
  EXERCISE_SLIDE_PPTX,
  EXERCISE_SLIDE_PDF,
  EXERCISE_SPEECH_RECOGNITION,
  EXERCISE_SPELLING,
  EXERCISE_TIP,
  EXERCISE_TRUE_FALSE,
  EXERCISE_TYPING,
  ExerciseType,
  EXERCISE_LISTEN_REPEAT,
} from '@common/enums/ExerciseTypes';
import {
  comprehensionIcon,
  conversationIcon,
  dialogueIcon,
  flashcardIcon,
  fillgapIcon,
  highlighterIcon,
  listenRepeatIcon,
  matchupIcon,
  multipleChoiceIcon,
  phraseBuilderIcon,
  slidePdfIcon,
  slidePptxIcon,
  speechRecognitionIcon,
  spellingIcon,
  tipIcon,
  trueFalseIcon,
  typingIcon,
} from '@components/ContentTypes/ExerciseCard/exerciseIcons';

// Exercise types to be shown in this exact order by the exercise creator widget
export const EXERCISE_TYPES_USER_ORDERED: ExerciseType[] = [
  EXERCISE_FILLGAP,
  EXERCISE_FLASHCARD,
  EXERCISE_TIP,
  EXERCISE_MULTIPLE_CHOICE,
  EXERCISE_TYPING,
  EXERCISE_TRUE_FALSE,
  EXERCISE_MATCHUP,
  EXERCISE_PHRASE_BUILDER,
  EXERCISE_HIGHLIGHTER,
  EXERCISE_SPELLING,
  EXERCISE_SPEECH_RECOGNITION,
  EXERCISE_COMPREHENSION,
  EXERCISE_CONVERSATION,
  EXERCISE_DIALOGUE,
  EXERCISE_LISTEN_REPEAT,
];

export const MAX_NUMBER_OF_VISIBLE_BUTTONS = 5;

export const ICON_PER_EXERCISE: Partial<Record<ExerciseType, string>> = {
  [EXERCISE_COMPREHENSION]: comprehensionIcon,
  [EXERCISE_CONVERSATION]: conversationIcon,
  [EXERCISE_DIALOGUE]: dialogueIcon,
  [EXERCISE_FLASHCARD]: flashcardIcon,
  [EXERCISE_FILLGAP]: fillgapIcon,
  [EXERCISE_HIGHLIGHTER]: highlighterIcon,
  [EXERCISE_LISTEN_REPEAT]: listenRepeatIcon,
  [EXERCISE_MATCHUP]: matchupIcon,
  [EXERCISE_MULTIPLE_CHOICE]: multipleChoiceIcon,
  [EXERCISE_PHRASE_BUILDER]: phraseBuilderIcon,
  [EXERCISE_SLIDE_PDF]: slidePdfIcon,
  [EXERCISE_SLIDE_PPTX]: slidePptxIcon,
  [EXERCISE_SPELLING]: spellingIcon,
  [EXERCISE_SPEECH_RECOGNITION]: speechRecognitionIcon,
  [EXERCISE_TIP]: tipIcon,
  [EXERCISE_TRUE_FALSE]: trueFalseIcon,
  [EXERCISE_TYPING]: typingIcon,
};
