import { call, put, select } from 'redux-saga/effects';
import FillGapExerciseInterface from '@components/Exercises/FillGap/interfaces/FillGapExerciseInterface';
import FillGapExerciseService from '@services/ExerciseRelatedServices/FillGapExerciseService';
import ExerciseSagas from '@sagas/exercises/definitions/ExerciseSagas';
import { Language } from '@features/content/languages';
import { FillGapExerciseActions } from '@actions/FillGapExerciseActions';
import { FormikValuesInterface } from '@helpers/formikInitialValuesHelper';
import type { AppDispatch } from '@redux/store';
import { selectLoadedExerciseData } from '@selectors/CoursesSelectors';

const FillGapExerciseSagas = {
  *save(
    dispatch: AppDispatch,
    {
      type,
      payload,
    }: {
      type: string;
      payload: {
        exercise: FillGapExerciseInterface;
        learningLanguage: Language;
        values: FormikValuesInterface;
      };
    },
  ) {
    let { values } = payload;

    yield put({
      type: FillGapExerciseActions.SET_FILLGAP_ALL_FIELDS,
      payload: {
        values,
      },
    });
    let exercise: FillGapExerciseInterface = yield select(selectLoadedExerciseData);

    yield call(FillGapExerciseService.save, dispatch, exercise, values);

    yield call(ExerciseSagas.requestOneExerciseCommon);
  },
};

export default FillGapExerciseSagas;
