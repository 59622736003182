import { PublishingActionsCreator } from '@actionCreators/PublishingActionCreator';
import { Loader } from '@features/theme';
import { useIsPublishAvailable } from '@features/auth/roles';

import { EntrypointListItemType } from '../PlacementTest';
import { ContentTypes } from '@common/enums/ContentTypes';
import { EmptyButton, PublishingButton } from '@components/Publishing/cardPublish.styles';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { selectIsPublishingInProgress } from '@selectors/CoursesSelectors';

type PublishCardButtonProps = {
  content: EntrypointListItemType;
  hovered: boolean;
  parentId: string;
};

export const PublishCardButton = ({ content, hovered, parentId }: PublishCardButtonProps) => {
  const dispatch = useAppDispatch();
  const { id, ready, type, validationStatus, changeStatus } = content;
  const isPublishingInProgress = useAppSelector(selectIsPublishingInProgress);

  const { publish: isPublishAvailable, unpublish: isUnpublishAvailable } = useIsPublishAvailable();

  const isUpdateButtonVisible = isPublishAvailable && ready && validationStatus.valid && changeStatus.hasNewChanges;
  const isUnplublishVisible = isUnpublishAvailable && ready;
  const isPublishButtonVisible = isPublishAvailable && (ready || validationStatus.valid);

  return (
    <>
      {isUpdateButtonVisible ? (
        <PublishingButton
          variant="primary"
          hovered={hovered}
          size="S"
          onClick={(e: React.MouseEvent<HTMLElement>) => {
            e.preventDefault();
            e.stopPropagation();
            dispatch(PublishingActionsCreator.setContentToPublish(id, type, ready, true, parentId));
          }}
        >
          {isPublishingInProgress ? <Loader size="S" /> : 'Update'}
        </PublishingButton>
      ) : (
        <EmptyButton />
      )}
      {isPublishButtonVisible ? (
        <PublishingButton
          variant={ready ? 'secondary' : 'primary'}
          hovered={hovered}
          size="S"
          onClick={(e: React.MouseEvent<HTMLElement>) => {
            e.preventDefault();
            e.stopPropagation();
            dispatch(
              PublishingActionsCreator.setContentToPublish(id, type, ready, false, parentId, ContentTypes.entrypoint),
            );
          }}
        >
          {isPublishingInProgress ? <Loader size="S" /> : ready ? 'Unpublish' : 'Publish'}
        </PublishingButton>
      ) : (
        <>
          {isUnplublishVisible ? (
            <PublishingButton
              variant="secondary"
              hovered={hovered}
              size="S"
              onClick={(e: any) => {
                e.preventDefault();
                e.stopPropagation();
                dispatch(PublishingActionsCreator.setContentToPublish(id, type, ready, false, parentId, 'group'));
              }}
            >
              {isPublishingInProgress ? <Loader size="S" /> : 'Unpublish'}
            </PublishingButton>
          ) : (
            <EmptyButton />
          )}
        </>
      )}
    </>
  );
};
