import { useMemo } from 'react';
import classnames from 'classnames';
import { useFormikContext } from 'formik';

import { FormikValuesInterface } from '@helpers/formikInitialValuesHelper';
import { KeyboardShortcut, ImageContainer } from './common';
import { TrueFalseContentProps } from '../../PreviewModalProps';
import { getValueByLanguage } from '../../../../localization-utils';

import './TrueFalseContent.scss';

export const TrueFalseContent = ({
  content,
  deviceType,
  answerState,
  courseLearningLanguage,
  isForList,
}: TrueFalseContentProps) => {
  const { values }: { values: FormikValuesInterface } = useFormikContext();
  const isCorrectSelected = answerState === 1;
  const isIncorrectSelected = answerState === 2;

  const mainImage = useMemo(
    () => getValueByLanguage(content.mainBundle?.image?.imageLocalizations),
    [content.mainBundle],
  );
  const mainAudio = useMemo(
    () => getValueByLanguage(content.mainBundle?.phrase?.audioLocalizations, courseLearningLanguage),
    [content.mainBundle, courseLearningLanguage],
  );

  const contentOnCourseLang = useMemo(
    () =>
      getValueByLanguage(
        isForList ? content.mainBundle?.phrase?.textLocalizations : values.mainBundle_phrase,
        courseLearningLanguage,
      ),
    [values, courseLearningLanguage, isForList, content.mainBundle],
  );

  const isStatementOnCourseLang = useMemo(
    () => !content.statementLanguage || content.statementLanguage === 'learning',
    [content.statementLanguage],
  );
  const statementOnEnLang = useMemo(
    () => getValueByLanguage(isForList ? content.statement?.textLocalizations : values.statement),
    [values, isForList, content.statement],
  );
  const statementOnCourseLang = useMemo(
    () =>
      getValueByLanguage(isForList ? content.statement?.textLocalizations : values.statement, courseLearningLanguage),
    [values, courseLearningLanguage, isForList, content.statement],
  );

  const statementValue = isStatementOnCourseLang ? statementOnCourseLang : statementOnEnLang;

  return (
    <>
      <ImageContainer
        deviceType={deviceType}
        isImageEnabled={!!mainImage}
        isAudioEnabled={!!mainAudio}
        imageSrc={mainImage}
        isVocabulary={content.mainBundle?.isVocabulary}
      />
      <div className="true-false-text">{contentOnCourseLang}</div>
      <div className="true-false-statement-preview" dangerouslySetInnerHTML={{ __html: statementValue }} />
      <div className={classnames('true-false-buttons', `true-false-buttons--${deviceType}`)}>
        <div
          className={classnames(
            'true-false-button-view',
            { 'true-false-button-view--correct': isCorrectSelected && content.answer },
            { 'true-false-button-view--incorrect': isIncorrectSelected && !content.answer },
          )}
        >
          True
          <KeyboardShortcut answerState={answerState} value={1} />
        </div>

        <div
          className={classnames(
            'true-false-button-view',
            { 'true-false-button-view--correct': isCorrectSelected && !content.answer },
            { 'true-false-button-view--incorrect': isIncorrectSelected && content.answer },
          )}
        >
          False
          <KeyboardShortcut answerState={answerState} value={2} />
        </div>
      </div>
    </>
  );
};
