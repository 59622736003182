import { TranslationsPanelContentInterface } from '@common/interfaces/exercises/TranslationsPanelContentInterface';
import FlashcardExerciseContent from '@components/Exercises/Flashcard/interfaces/FlashCardExerciseInterface';
import TrueFalseExerciseContent from '@components/Exercises/TrueFalse/interfaces/TrueFalseExerciseContent';
import { AnyExerciseContentInterface } from '@common/types/exercises/AnyExerciseContentInterface';
import { ValidationErrorInterface } from '@common/interfaces/validation/ValidationInterface';
import TipExerciseContent from '@components/Exercises/Tip/interfaces/TipExerciseContent';
import { AnyExerciseContentBranch } from '@common/types/AnyExerciseContentBranch';
import { ExerciseTypes } from '@common/enums/ExerciseTypes';
import { ContentTypes, type ContentTypesType } from '@common/enums/ContentTypes';
import { DBId } from '@common/types/DBId';
import { clone } from '@helpers/clone';

export type UploadMediaResponse = {
  mediaId: DBId;
  mediaURL: string;
  content: TranslationsPanelContentInterface;
};

export type UploadAudioResponse = {
  fileId: DBId;
};

export type UploadSlideResponse = {
  fileId: DBId;
  url: string;
};

const HelpersService = {
  localizationGroupNames: ['textLocalizations', 'audioLocalizations', 'videoLocalizations', 'imageLocalizations'],
  chainGroup: <T extends { language: string }>(group: T[]) => {
    return group.reduce(
      (buckets, localization) => {
        if (!buckets[localization.language]) {
          buckets[localization.language] = localization;
        }

        return buckets;
      },
      {} as { [key in string]: T },
    );
  },
  groupByLanguageInBranch: (
    branchesToGroup: AnyExerciseContentBranch[],
    exercise: FlashcardExerciseContent | TipExerciseContent | TrueFalseExerciseContent,
  ) => {
    let clonedExercise: FlashcardExerciseContent | TipExerciseContent | TrueFalseExerciseContent | null = null;

    if (exercise.type === ExerciseTypes.flashcard) {
      clonedExercise = clone(exercise) as FlashcardExerciseContent;
    }

    if (exercise.type === ExerciseTypes.tip) {
      clonedExercise = clone(exercise) as TipExerciseContent;
    }

    if (exercise.type === ExerciseTypes.trueFalse) {
      clonedExercise = clone(exercise) as TrueFalseExerciseContent;
    }

    branchesToGroup.forEach((branchToGroup: string) => {
      HelpersService.localizationGroupNames.forEach((localization: string) => {
        let chosenBranch: any = (clonedExercise as any)[branchToGroup];

        if (chosenBranch === undefined) {
          throw new Error(`${branchToGroup} doesnt exist`);
        }

        if (chosenBranch === null) return;

        (clonedExercise as AnyExerciseContentInterface)[branchToGroup][localization + '_groupedByLanguage'] =
          HelpersService.chainGroup((clonedExercise as any)[branchToGroup][localization]);
      });
    });

    return clonedExercise;
  },

  groupLocalization: (chosenLocalization: any) => {
    if (chosenLocalization === undefined) {
      return {};
    }

    let groups = HelpersService.localizationGroupNames.forEach((localization: string) => {
      chosenLocalization[localization + '_groupedByLanguage'] = HelpersService.chainGroup(
        chosenLocalization[localization],
      );
    });

    let output = { ...chosenLocalization, groups };

    return output;
  },

  getValidationErrorMessageText: (validationErrorArray: ValidationErrorInterface[]) => {
    return validationErrorArray?.map((error: ValidationErrorInterface) => error.message).join(', ');
  },
  checkIsExerciseChanged: (content: AnyExerciseContentInterface, type: ContentTypesType) => {
    switch (type) {
      case ContentTypes.comprehension: {
        return (
          content?.instructions.changed ||
          content?.title?.changed ||
          content?.titleChanged ||
          content?.titleLanguageChanged ||
          content?.providerChanged ||
          content?.experimentChanged ||
          content?.mainContentLanguageChanged ||
          content?.mainBundle?.isVocabularyChanged ||
          content?.mainBundle?.phraseChanged ||
          content?.mainBundle?.phrase?.changed ||
          content?.mainBundle?.image?.changed ||
          content?.mainBundle?.imageChanged ||
          content?.mainBundle?.video?.changed ||
          content?.mainBundle?.videoChanged ||
          content?.mainTextChanged ||
          content?.mainContentChanged ||
          content?.instructionsLanguageChanged
        );
      }

      case ContentTypes.conversation: {
        return (
          content?.instructions.changed ||
          content?.instructionsLanguageChanged ||
          content?.hint?.changed ||
          content?.hintChanged ||
          content?.minimumNumberOfWordsChanged ||
          content?.image1?.changed ||
          content?.image1Changed ||
          content?.image2?.changed ||
          content?.image2Changed ||
          content?.image3?.changed ||
          content?.image3Changed ||
          content?.image1Changed ||
          content?.image2Changed ||
          content?.image3Changed ||
          content?.experimentChanged ||
          content?.recapExerciseIdChanged
        );
      }

      case ContentTypes.dialogue: {
        return (
          content?.instructions.changed ||
          content?.instructionsLanguageChanged ||
          content?.dialogueDescription?.changed ||
          content?.dialogueDescriptionChanged ||
          content?.dialogueDescriptionLanguageChanged ||
          content?.charactersChanged ||
          content?.scriptChanged ||
          content?.recapExerciseIdChanged
        );
      }

      case ContentTypes.fillgap: {
        return (
          content?.instructions.changed ||
          content?.instructionsLanguageChanged ||
          content?.mainAudioChanged ||
          content.mainVideoChanged ||
          content?.mainBundle?.isVocabularyChanged ||
          content?.mainBundle?.image?.changed ||
          content?.mainBundle?.imageChanged ||
          content?.mainBundle?.video?.changed ||
          content?.mainBundle?.videoChanged ||
          content?.mainBundle?.phrase?.changed ||
          content?.distractor1?.phrase?.changed ||
          content?.distractor2?.phrase?.changed ||
          content?.distractor1Changed ||
          content?.distractor2Changed ||
          content?.feedback?.changed ||
          content?.feedbackChanged ||
          content?.experimentChanged ||
          content?.recapExerciseIdChanged
        );
      }

      case ContentTypes.flashcard: {
        return (
          content?.instructions.changed ||
          content?.learningWordBundle?.isVocabularyChanged ||
          content?.learningWordBundle?.image?.changed ||
          content?.learningWordBundle?.imageChanged ||
          content?.learningWordBundle?.phrase?.changed ||
          content?.learningWordBundle?.video?.changed ||
          content?.learningWordBundle?.videoChanged ||
          content?.learningWordBundle?.example?.changed ||
          content?.learningWordBundle?.exampleChanged ||
          content?.instructionsLanguageChanged ||
          content?.experimentChanged ||
          content?.recapExerciseIdChanged
        );
      }

      case ContentTypes.highlighter: {
        return (
          content?.instructions.changed ||
          content?.instructionsLanguageChanged ||
          content?.phrasesChanged ||
          content?.feedbackChanged ||
          content?.feedback?.changed ||
          content?.experimentChanged ||
          content?.recapExerciseIdChanged
        );
      }

      case ContentTypes.listenRepeat: {
        return (
          content?.instructions.changed ||
          content?.instructionsLanguageChanged ||
          content?.mainBundle?.isVocabularyChanged ||
          content?.mainBundle?.image?.changed ||
          content?.mainBundle?.imageChanged ||
          content?.mainBundle?.phrase?.changed ||
          content?.mainBundle?.video?.changed ||
          content?.mainBundle?.videoChanged
        );
      }

      case ContentTypes.matchup: {
        return (
          content?.instructions.changed ||
          content?.instructionsLanguageChanged ||
          content?.feedbackChanged ||
          content?.feedback?.changed ||
          content?.fixedItem1?.phrase?.changed ||
          content?.matchingItem1?.phrase?.changed ||
          content?.fixedItem2?.phrase?.changed ||
          content?.matchingItem2?.phrase?.changed ||
          content?.fixedItem3?.phrase?.changed ||
          content?.matchingItem3?.phrase?.changed ||
          content?.fixedItem3Changed ||
          content?.matchingItem3Changed ||
          content?.experimentChanged ||
          content?.recapExerciseIdChanged
        );
      }

      case ContentTypes.multipleChoice: {
        return (
          content?.instructions.changed ||
          content?.instructionsLanguageChanged ||
          content?.choicesImagesEnabledChanged ||
          content?.mainImageChanged ||
          content?.mainAudioChanged ||
          content?.answerBundle?.isVocabularyChanged ||
          content?.answerBundle?.image?.changed ||
          content?.answerBundle?.imageChanged ||
          content?.answerBundle?.phrase?.changed ||
          content?.distractor1?.isVocabularyChanged ||
          content?.distractor1?.image?.changed ||
          content?.distractor1?.imageChanged ||
          content?.distractor1?.phrase?.changed ||
          content?.distractor2?.isVocabularyChanged ||
          content?.distractor2?.image?.changed ||
          content?.distractor2?.imageChanged ||
          content?.distractor2?.phrase?.changed ||
          content?.choicesLanguageChanged ||
          content?.feedbackChanged ||
          content?.feedback?.changed ||
          content?.experimentChanged ||
          content?.recapExerciseIdChanged
        );
      }

      case ContentTypes.phraseBuilder: {
        return (
          content?.instructions.changed ||
          content?.displayAudioChanged ||
          content?.instructionsLanguageChanged ||
          content?.mainBundle?.isVocabularyChanged ||
          content?.mainBundle?.phrase?.changed ||
          content?.feedback?.changed ||
          content?.feedbackChanged ||
          content?.experimentChanged ||
          content?.recapExerciseIdChanged
        );
      }

      case ContentTypes.speechRecognition: {
        return (
          content?.instructions.changed ||
          content?.instructionsLanguageChanged ||
          content?.mainBundle?.isVocabularyChanged ||
          content?.mainBundle?.image?.changed ||
          content?.mainBundle?.imageChanged ||
          content?.mainBundle?.video?.changed ||
          content?.mainBundle?.videoChanged ||
          content?.mainBundle?.phrase?.changed ||
          content?.feedbackOptionChanged ||
          content?.feedback?.changed ||
          content?.feedbackPhonemesChanged ||
          content?.experimentChanged ||
          content?.recapExerciseIdChanged
        );
      }

      case ContentTypes.spelling: {
        return (
          content?.instructions.changed ||
          content?.instructionsLanguageChanged ||
          content?.mainBundle?.isVocabularyChanged ||
          content?.mainBundle?.image?.changed ||
          content?.mainBundle?.imageChanged ||
          content?.mainBundle?.phrase?.changed ||
          content?.feedback?.changed ||
          content?.feedbackChanged ||
          content?.experimentChanged ||
          content?.recapExerciseIdChanged
        );
      }

      case ContentTypes.tip: {
        return (
          content?.instructions.changed ||
          content?.instructionsLanguageChanged ||
          content?.paragraph?.changed ||
          content?.paragraphChanged ||
          content?.paragraphLanguageChanged ||
          content?.examplesLanguageChanged ||
          content?.examplesChanged ||
          content?.experimentChanged ||
          content?.recapExerciseIdChanged
        );
      }

      case ContentTypes.trueFalse: {
        return (
          content?.instructions.changed ||
          content?.instructionsLanguageChanged ||
          content?.mainBundle?.isVocabularyChanged ||
          content?.mainBundle?.image?.changed ||
          content?.mainBundle?.imageChanged ||
          content?.mainBundle?.phrase?.changed ||
          content?.mainBundle?.phraseChanged ||
          content?.mainImageChanged ||
          content?.mainAudioChanged ||
          content?.statement.changed ||
          content?.answerChanged ||
          content?.statementLanguageChanged ||
          content?.feedback?.changed ||
          content?.feedbackChanged ||
          content?.experimentChanged ||
          content?.recapExerciseIdChanged
        );
      }
      case ContentTypes.typing: {
        return (
          content?.instructions.changed ||
          content?.instructionsLanguageChanged ||
          content?.mainBundle?.isVocabularyChanged ||
          content?.mainBundle?.image?.changed ||
          content?.mainBundle?.imageChanged ||
          content?.mainBundle?.phrase?.changed ||
          content?.hint?.changed ||
          content?.hintChanged ||
          content?.feedback?.changed ||
          content?.feedbackChanged ||
          content?.experimentChanged ||
          content?.recapExerciseIdChanged
        );
      }
      case ContentTypes.slidePdf: {
        return content?.pdfUrlChanged;
      }
      case ContentTypes.slidePptx: {
        return content?.pptxUrlChanged;
      }

      default: {
        return false;
      }
    }
  },
};

export default HelpersService;
