import { LocalizationInterface } from '@common/interfaces/localization/LocalizationInterface';
import { CourseSliceInterface } from '@common/interfaces/slices/CourseSliceInterface';
import DialogueExerciseInterface from '@components/Exercises/Dialogue/interfaces/DialogueExerciseInterface';
import { ScriptLine } from '@components/Exercises/Dialogue/types/ScriptLine';
import { SidePanelActions } from '@actions/SidePanelActions';
import { PayloadAction } from '@reduxjs/toolkit';
import { clone } from '@helpers/clone';

const SidePanelCourseCourseReducers = {
  [SidePanelActions.UPDATE_LOCALIZATION]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    const clonedState: CourseSliceInterface = clone(state);

    let { localizationLanguageCode, updatedLocalizationString, row, column, isPhonetic } = payload;

    let localizationToUpdate: LocalizationInterface;

    if (clonedState.loadedExercise.exercise.content.script) {
      let visitedBranch = clonedState.loadedExercise.exercise.content.script;
      let scriptLine: ScriptLine = visitedBranch[row] as ScriptLine;

      localizationToUpdate = scriptLine.line.textLocalizations.find(
        (l: LocalizationInterface) => l.language === localizationLanguageCode,
      ) as LocalizationInterface;
      (clonedState.loadedExercise.exercise as DialogueExerciseInterface).content.scriptChanged = true;

      if (localizationToUpdate !== undefined) {
        if (isPhonetic) {
          localizationToUpdate.phoneticValue = updatedLocalizationString;
        } else {
          localizationToUpdate.value = updatedLocalizationString;
        }
      }
    } else {
      let visitedBranch = clonedState.loadedExercise.exercise.content.examples;
      let example = visitedBranch[row][column];

      localizationToUpdate = example.textLocalizations.find(
        (l: LocalizationInterface) => l.language === localizationLanguageCode,
      ) as LocalizationInterface;
      (clonedState.loadedExercise.exercise as DialogueExerciseInterface).content.examplesChanged = true;

      if (localizationToUpdate !== undefined) {
        if (isPhonetic) {
          localizationToUpdate.phoneticValue = updatedLocalizationString;
        } else {
          localizationToUpdate.value = updatedLocalizationString;
        }
      }
    }

    return {
      ...clonedState,
    };
  },
};

export default SidePanelCourseCourseReducers;
