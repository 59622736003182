import Color from 'color';
import { ReactElement, ReactNode, SVGProps, forwardRef } from 'react';
import styled from 'styled-components';

import { Loader } from '@features/theme';

type ButtonProps = {
  children?: ReactNode;
  className?: string;
  collapsed?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  icon?: ReactElement<SVGProps<SVGSVGElement>>;
  loading?: boolean;
  size?: 'L' | 'M' | 'S';
  variant?: 'primary' | 'secondary' | 'tertiary' | 'text';
  onClick: React.MouseEventHandler<HTMLButtonElement>;
};

const StyledButton = styled.button<ButtonProps>`
  all: unset;
  align-items: center;
  border-radius: 3.2rem;
  border-style: solid;
  border-width: 0.2rem;
  box-sizing: border-box;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  font-weight: 700;
  justify-content: center;
  line-height: 1.4;
  overflow: hidden;
  transition: all 0.3s ease-out;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};

  svg {
    path {
      transition: all 0.3s ease-out;
    }
  }

  ${({ disabled, variant, theme }) => {
    if (variant === 'primary') {
      if (disabled) {
        return `
          background-color: ${Color(theme.colorV2.buttonPrimaryBaseBackground).lighten(0.5).hex()};
          border-color: ${Color(theme.colorV2.buttonPrimaryBaseBorder).lighten(0.5).hex()};
          color: ${theme.colorV2.buttonPrimaryBaseText};

          svg {
            fill: ${theme.colorV2.buttonPrimaryBaseText};
          }
        `;
      }

      return `
        background-color: ${theme.colorV2.buttonPrimaryBaseBackground};
        border-color: ${theme.colorV2.buttonPrimaryBaseBorder};
        color: ${theme.colorV2.buttonPrimaryBaseText};

        &:hover {
          background-color: ${theme.colorV2.buttonPrimaryHoverBackground};
          border-color: ${theme.colorV2.buttonPrimaryHoverBackground};
        }

        &:active {
          background-color: ${theme.colorV2.buttonPrimaryPressedBackground};
          border-color: ${theme.colorV2.buttonPrimaryPressedBackground};
        }

        svg {
          fill: ${theme.colorV2.buttonPrimaryBaseText};
        }
      `;
    }

    if (variant === 'secondary') {
      if (disabled) {
        return `
          background-color: ${theme.colorV2.buttonSecondaryBaseBackground};
          border-color: ${Color(theme.colorV2.buttonSecondaryBaseBorder).lighten(0.5).hex()};
          color: ${Color(theme.colorV2.buttonSecondaryBaseText).lighten(0.5).hex()};

          svg {
            path {
              fill: ${Color(theme.colorV2.buttonSecondaryBaseText).lighten(0.5).hex()};
            }
          }
        `;
      }

      return `
        background-color: ${theme.colorV2.buttonSecondaryBaseBackground};
        border-color: ${theme.colorV2.buttonSecondaryBaseBorder};
        color: ${theme.colorV2.buttonSecondaryBaseText};

        &:hover {
          background-color: ${theme.colorV2.buttonSecondaryHoverBackground};
          border-color: ${theme.colorV2.buttonSecondaryHoverBorder};
          color: ${theme.colorV2.buttonSecondaryHoverText};

          svg {
            path {
              fill: ${theme.colorV2.buttonSecondaryHoverText};
            }
          }
        }

        &:active {
          background-color: ${theme.colorV2.buttonSecondaryPressedBackground};
          border-color: ${theme.colorV2.buttonSecondaryPressedBorder};
          color: ${theme.colorV2.buttonSecondaryPressedText};

          svg {
            path {
              fill:  ${theme.colorV2.buttonSecondaryPressedText};
            }
          }
        }

        svg {
          path {
            fill: ${theme.colorV2.buttonSecondaryBaseText};
          }
        }
      `;
    }

    if (variant === 'tertiary') {
      if (disabled) {
        return `
          background-color: ${theme.colorV2.buttonTertiaryBaseBackground};
          border-color: ${Color(theme.colorV2.buttonTertiaryBaseBorder).lighten(0.1).hex()};
          color:  ${Color(theme.colorV2.buttonTertiaryBaseText).lighten(1.5).hex()};

          svg {
            fill: ${Color(theme.colorV2.buttonTertiaryBaseText).lighten(1.5).hex()};
          }
        `;
      }

      return `
        background-color: ${theme.colorV2.buttonTertiaryBaseBackground};
        border-color: ${theme.colorV2.buttonTertiaryBaseBorder};
        color: ${theme.colorV2.buttonTertiaryBaseText};

        svg {
          fill: ${theme.colorV2.buttonTertiaryBaseText};
        }

        &:hover {
          border-color: ${theme.colorV2.buttonTertiaryHoverBorder};
          color: ${theme.colorV2.buttonTertiaryHoverText};

          svg {
            fill: ${theme.colorV2.buttonTertiaryHoverText};
          }
        }

        &:active {
          border-color: ${theme.colorV2.buttonTertiaryPressedBorder};
          color: ${theme.colorV2.buttonTertiaryPressedText};

          svg {
            fill: ${theme.colorV2.buttonTertiaryPressedText};
          }
        }
      `;
    }

    if (variant === 'text') {
      if (disabled) {
        return `
        background-color: transparent;
          border-color: transparent;
          color: ${Color(theme.colorV2.buttonOnlyTextBaseText).lighten(0.5).hex()};

          svg {
            fill: ${Color(theme.colorV2.buttonOnlyTextBaseText).lighten(0.5).hex()};
          }
        `;
      }

      return `
        background-color: transparent;
        border-color: transparent;
        color: ${theme.colorV2.buttonOnlyTextBaseText};

        svg {
          fill: ${theme.colorV2.buttonOnlyTextBaseText};
        }

        &:active {
          color: ${theme.colorV2.buttonOnlyTextPressedText};

          svg {
            fill: ${theme.colorV2.buttonOnlyTextPressedText};
          }
        }
      `;
    }
  }}

  ${({ size }) => {
    if (size === 'L') {
      return `
        font-size: 2rem;
        padding: 1.9rem 4rem;
      `;
    }

    if (size === 'M') {
      return `
        font-size: 1.6rem;
        padding: 1.2rem 3.2rem;
      `;
    }

    if (size === 'S') {
      return `
        font-size: 1.2rem;
        padding: 0.6rem 1.6rem;
      `;
    }
  }}

  span {
    display: flex;
    gap: 0.5rem;
    justify-content: center;
    width: ${({ fullWidth }) => (fullWidth ? 'auto' : '100%')};
    opacity: 1;
    text-align: center;
    overflow: hidden;
    transition:
      width 0.3s ease,
      opacity 0.3s ease;

    ${({ collapsed, icon }) => {
      if (collapsed && icon) {
        return `
          opacity: 0;
          width: 0;
        `;
      }

      return '';
    }}
  }

  svg {
    margin-right: ${({ collapsed }) => (collapsed ? '0' : '0.8rem')};
  }
`;

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      className,
      collapsed,
      disabled,
      fullWidth,
      icon,
      loading,
      size = 'M',
      variant = 'primary',
      onClick,
      ...other
    },
    ref,
  ) => {
    return (
      <StyledButton
        className={className}
        collapsed={collapsed}
        disabled={disabled}
        fullWidth={fullWidth}
        icon={icon}
        loading={loading}
        ref={ref}
        size={size}
        variant={variant}
        onClick={onClick}
        {...other}
      >
        {loading ? (
          <Loader size={size === 'L' ? 'M' : size} />
        ) : (
          <>
            {icon}
            {children && <span>{children}</span>}
          </>
        )}
      </StyledButton>
    );
  },
);
