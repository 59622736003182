import { components, MultiValue, MultiValueGenericProps } from 'react-select';

import { CommonFilterOptionType } from '@components/SelectorCommonComponents';

import { CustomOptionNameContainer } from './styles';
import { ReactElement } from 'react';

const { MultiValueContainer } = components;

type SelectorCustomValueContainerProps = MultiValueGenericProps<MultiValue<CommonFilterOptionType>> & {
  title: string;
  value: MultiValue<CommonFilterOptionType> | null;
};

export const SelectorCustomValueContainer = ({
  children,
  title,
  value,
  ...props
}: SelectorCustomValueContainerProps) => {
  let [, input] = children as ReactElement[];

  return (
    <MultiValueContainer {...props}>
      <CustomOptionNameContainer title={title}>
        {value && value.length > 1 ? `${value.length} selected` : `${value?.[0].label ?? ''}`}
      </CustomOptionNameContainer>
      {input}
    </MultiValueContainer>
  );
};
