import { MouseEvent, useRef } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import styled, { css } from 'styled-components/macro';

import { NAVIGATION_PANEL_WIDTH } from '@features/content/navigation';
import { reuseConstants } from '@features/content/reuse';
import { ContextualMenu } from '@features/theme';
import { isFeatureEnabled } from '@helpers/featuresHelper';

import { ReactComponent as CopyIdIcon } from './_img/copy.svg';
import { ReactComponent as MoveIcon } from './_img/move.svg';
import { CONTENT_ACTIONS_MENU_TRIGGER_PREFIX } from './constants';
import type { NavigationItemType } from './types';
import { useOnClickOutside } from './useOnClickOutside';

const ContentActionsOption = styled.div`
  align-items: center;
  display: flex;
  gap: 0.8rem;
  min-width: 22.8rem;
`;

const ContentActionsMenuBackdrop = styled.div<{ show: boolean }>`
  ${({ show, theme }) => css`
    bottom: 0;
    display: ${show ? 'block' : 'none'};
    left: ${NAVIGATION_PANEL_WIDTH};
    right: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: ${theme.zIndex.backDrop};
  `}
`;

const isMoveable = (contentType: NavigationItemType['type'] | undefined) => {
  if (!contentType) return false;

  const { MOVEABLE_CONTENT_TYPES } = reuseConstants;

  return MOVEABLE_CONTENT_TYPES.includes(contentType);
};

type ContentActionsMenuPropsType = {
  selectedContent: NavigationItemType | null;
  show: boolean;
  onClickOutside: (evt: MouseEvent<any>) => void;
  onCopyId: (evt: MouseEvent<HTMLDivElement>) => void;
  onMoveContent: () => void;
};

export const ContentActionsMenu = ({
  selectedContent,
  show,
  onClickOutside,
  onCopyId,
  onMoveContent,
}: ContentActionsMenuPropsType) => {
  const selectedContentId = selectedContent?.id;
  const triggerClientRect = selectedContent
    ? document.getElementById(`${CONTENT_ACTIONS_MENU_TRIGGER_PREFIX}-${selectedContentId}`)?.getBoundingClientRect()
    : undefined;

  const contentActionsMenuRef = useRef(null);
  const onClick = useOnClickOutside<HTMLDivElement>(contentActionsMenuRef, onClickOutside);

  return (
    <>
      <ContextualMenu position={triggerClientRect} ref={contentActionsMenuRef} show={show} variant="small">
        <CopyToClipboard text={selectedContentId as string}>
          <ContentActionsOption onClick={onCopyId}>
            <CopyIdIcon />
            Copy ID
          </ContentActionsOption>
        </CopyToClipboard>
        {isFeatureEnabled('reusingInNewLocation') && isMoveable(selectedContent?.type) ? (
          <ContentActionsOption onClick={onMoveContent}>
            <MoveIcon />
            Move
          </ContentActionsOption>
        ) : null}
      </ContextualMenu>
      <ContentActionsMenuBackdrop show={show} onClick={onClick} />
    </>
  );
};
