import classnames from 'classnames';
import { ChangeEvent, useState } from 'react';
import { FormikContextType, useFormikContext } from 'formik';

import { ValidationErrorDisplayer } from '@components/ValidationErrorDisplayer';
import { TranslationTipActionsCreator } from '@actionCreators/TranslationTipActionsCreator';
import { LocalizationInterface } from '@common/interfaces/localization/LocalizationInterface';
import { ValidationErrorInterface } from '@common/interfaces/validation/ValidationInterface';
import { Flag, FlagTextPositionMode } from '@components/Flag';
import PhoneticIcon from '@static/svg/translationsPanel/Phonetic.svg';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { Language, PHONETIC_LANGUAGES } from '@features/content/languages';
import { useIsAdmin } from '@features/auth/roles';
import { useToast } from '@features/app/toast';
import { selectRoleplayTranslationsPanel } from '@selectors/UiSelectors';

import { RoleplayCategoryFormikValues } from '../../types';

export type CommonTranslationProps = {
  validationErrors?: ValidationErrorInterface[];
};

type TranslationInstanceProps = CommonTranslationProps & {
  localization: LocalizationInterface;
  idx: number;
};

export const TranslationInstance = ({ validationErrors, localization, idx }: TranslationInstanceProps) => {
  const isAdmin = useIsAdmin();
  const { values, setFieldValue }: FormikContextType<RoleplayCategoryFormikValues> = useFormikContext();
  const dispatch = useAppDispatch();
  const isPhoneticLanguage = PHONETIC_LANGUAGES.includes(localization.language as Language);
  const translations = useAppSelector(selectRoleplayTranslationsPanel);

  const showToast = useToast();

  const { visitedContentId: contentId, isEditBlocked, type, fieldName, isEditDisabled } = translations;

  const setReusedData = () => {
    dispatch(TranslationTipActionsCreator.setCurrentContentId(contentId || '', type || '', fieldName || ''));
  };

  const [text, setText] = useState(
    (fieldName &&
      values[fieldName]?.find((item) => item.language === localization.language && !item.isPhonetic)?.value) ||
      '',
  );

  const [phoneticText, setPhoneticText] = useState(
    (fieldName &&
      values[fieldName]?.find((item) => item.language === localization.language && item.isPhonetic)?.value) ||
      '',
  );

  const changeText = (e: ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value);
    const newValues =
      fieldName &&
      values?.[fieldName]?.map((item) => {
        if (item.language === localization.language && !item.isPhonetic) {
          return {
            ...item,
            value: e.target.value || '',
          };
        }
        return item;
      });
    setFieldValue(fieldName, newValues);
    setFieldValue(`${fieldName}Changed`, true);
  };

  const changePhoneticText = (e: ChangeEvent<HTMLInputElement>) => {
    setPhoneticText(e.target.value);
    const newValues =
      fieldName &&
      values?.[fieldName]?.map((item) => {
        if (item.language === localization.language && item.isPhonetic) {
          return {
            ...item,
            value: e.target.value || '',
          };
        }
        return item;
      });

    setFieldValue(fieldName, newValues);
    setFieldValue(`${fieldName}Changed`, true);
  };

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>, isPhonetic?: boolean) => {
    if (contentId?.includes('default_') && !isAdmin) {
      showToast({
        type: 'info',
        title: 'Default instructions cannot be edited',
        description: 'To remove the default instruction, clear it using the trash can',
      });
    } else {
      if (isEditBlocked) {
        setReusedData();
      } else {
        isPhonetic ? changePhoneticText(e) : changeText(e);
      }
    }
  };

  const validationError = validationErrors
    ?.filter((error) => error.message.includes('text'))
    .find((error) => error.message.includes(localization.language));

  return (
    <li key={idx} className="translations-panel-modal__translation">
      <div
        className={classnames('translations-panel-modal__translation-data', {
          'translations-panel-modal__translation-data--with-error': validationError,
        })}
      >
        <Flag mode={FlagTextPositionMode.before} countries={localization.language} />
        {isPhoneticLanguage && (
          <div className="translations-panel-modal__translation-text-phonetic">
            <input
              id={`translation-${localization.language}`}
              type="text"
              dir="auto"
              className="translations-panel-modal__translation-input"
              placeholder="Translation"
              value={text}
              disabled={isEditDisabled}
              onChange={onChangeHandler}
            />
            <input
              type="text"
              className="translations-panel-modal__translation-input"
              placeholder="Phonetic"
              disabled={isEditDisabled}
              value={phoneticText}
              onChange={(e) => onChangeHandler(e, true)}
            />
            <img src={PhoneticIcon} alt="Phonetic" className="translations-panel-modal__translation-phonetic-icon" />
          </div>
        )}

        {!isPhoneticLanguage && (
          <div className="translations-panel-modal__translation-text">
            <input
              id={`translation-${localization.language}`}
              type="text"
              dir="auto"
              className="translations-panel-modal__translation-input"
              placeholder="Translation"
              value={text}
              disabled={isEditDisabled}
              onChange={onChangeHandler}
            />
          </div>
        )}
      </div>
      <ValidationErrorDisplayer text={validationError?.message} />
    </li>
  );
};
