import { useState } from 'react';
import { Modal } from 'react-bootstrap';

import { searchByLesson } from '../../service';
import { useSearch } from '../../../SearchContext';
import { ReactComponent as LessonIcon } from '../../img/lesson.svg';
import { LessonSearchResultItem, LessonSearchResultType } from '../../SearchResultItem';
import { SearchResults } from '../../SearchResults';
import {
  DEFAULT_PAGINATION_PAGE,
  SearchHeader,
  SearchResultsWrapper,
  useFiltersState,
  useResultsHandler,
} from '../Common';
import { LessonSearchResultTypeHeader } from '../../SearchResultTypeHeader';
import { useAppSelector } from '@redux/store';
import { selectCourses } from '@selectors/CoursesSelectors';
import { saveFavouriteSearch } from '../Common/favouriteSearches';
import { SEARCH_TYPE_LESSON } from '../../../../types';
import type { CommonSearchComponentProps } from '../SearchContent';
import type { LessonQueryParamsType } from '../../service/types';
import { LessonSearchFilters } from './LessonSearchFilters';
import { selectSearchPanel } from '@selectors/UiSelectors';

const defaultFilters: LessonQueryParamsType = {
  languages: null,
  courses: null,
  isReused: null,
  activityTypes: null,
  hasIdentityGroup: null,
  hasImage: null,
  hasThumbnailImage: null,
  isAttachedToGrammarTopic: null,
  isPublished: null,
  lessonFocuses: null,
  lessonTypes: null,
  numActivities: null,
};

/**
 * This component is responsible for:
 * - Modal layout for Lesson search
 * - All logic that is specific to Lesson search
 * - Calling Lesson search API
 * - Storing search filters
 * - Storing search results
 */
export const LessonSearch = ({ trackSearch, onBack }: CommonSearchComponentProps) => {
  const availableCourses = useAppSelector(selectCourses);
  const { filtersPreset } = useAppSelector(selectSearchPanel);

  const { search, query } = useSearch();

  const { results, count, clearResults, onSuccess, onError } = useResultsHandler<LessonSearchResultType>();
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGINATION_PAGE);

  const filtersState = useFiltersState(defaultFilters, filtersPreset?.lesson || {});

  const onPaginatedSearch = (query: string | null, page: number) => {
    if (query) {
      search(() => searchByLesson(query, page, filtersState.state))
        .then(onSuccess)
        .catch(onError);

      trackSearch();
    } else {
      clearResults();
    }
  };

  const onFreshSearch = (query: string | null) => {
    setCurrentPage(DEFAULT_PAGINATION_PAGE);
    onPaginatedSearch(query, DEFAULT_PAGINATION_PAGE);
  };
  const onPageChange = (page: number) => {
    setCurrentPage(page);
    onPaginatedSearch(query, page);
  };

  return (
    <>
      <Modal.Header closeButton>
        <SearchHeader
          filtersArea={
            <LessonSearchFilters
              filtersState={filtersState}
              availableCourses={availableCourses}
              onSaveFilters={async (name) => saveFavouriteSearch(name, SEARCH_TYPE_LESSON, filtersState.state)}
            />
          }
          icon={<LessonIcon />}
          title="Lesson search"
          onSearch={onFreshSearch}
          onBack={onBack}
        />
      </Modal.Header>

      <Modal.Body>
        <SearchResultsWrapper onPageCount={results.length}>
          <SearchResults<LessonSearchResultType>
            results={results}
            count={count}
            currentPage={currentPage}
            setCurrentPage={onPageChange}
            header={<LessonSearchResultTypeHeader />}
            renderItem={(item) => <LessonSearchResultItem content={item} key={item.id} />}
          />
        </SearchResultsWrapper>
      </Modal.Body>
    </>
  );
};
