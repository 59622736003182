import styled from 'styled-components';

import connectionIcon from './img/connection.svg';
import navigateIcon from './img/navigate.svg';

const DisplayerContainer = styled.div<{ isResourceBundle: boolean }>`
  width: fit-content;
  padding: 4px 8px;
  border-radius: 16px;
  height: ${({ isResourceBundle }) => (isResourceBundle ? 'unset' : '22px')};
  margin: ${({ isResourceBundle }) => (isResourceBundle ? '0' : '0 0 8px 4px')};
  font-weight: ${({ isResourceBundle }) => (isResourceBundle ? '400' : '700')};
  font-size: ${({ isResourceBundle }) => (isResourceBundle ? '14px' : '10px')};
  text-transform: ${({ isResourceBundle }) => (isResourceBundle ? 'none' : 'uppercase')};
  color: ${({ isResourceBundle, theme }) => (isResourceBundle ? theme.color.purple100 : theme.color.brandWhite)};
  background: ${({ isResourceBundle, theme }) => (isResourceBundle ? 'transparent' : theme.color.purple100)};

  &:hover {
    cursor: pointer;
    opacity: 0.9;
  }
`;

const DisplayerImage = styled.img`
  margin-top: -4px;
`;

const DisplayerText = styled.span`
  margin: 0 5px;
`;

type OtherPlacesDisplayerProps = {
  className?: string;
  isResourceBundle: boolean;
  mappings: number;
  openModal: () => void;
};

export const OtherPlacesDisplayer = ({
  className,
  isResourceBundle,
  mappings,
  openModal,
}: OtherPlacesDisplayerProps) => (
  <DisplayerContainer
    className={className}
    data-testid="other-places-button"
    isResourceBundle={isResourceBundle}
    onClick={openModal}
  >
    <DisplayerImage src={connectionIcon} alt="Connection" />
    <DisplayerText>Used in {mappings} locations</DisplayerText>
    {!isResourceBundle && <DisplayerImage src={navigateIcon} alt="Navigate" />}
  </DisplayerContainer>
);
