import Table from 'react-bootstrap/Table';
import styled, { css } from 'styled-components';

const baseStylesTableHeader = css`
  user-select: text !important;
`;

const baseStylesSticky = css`
  padding-bottom: 1.6rem !important;
  padding-top: 3.2rem !important;
  position: sticky;
  top: 9rem;
`;

const NoDataWrapper = styled.div`
  align-items: center;
  display: flex;
  font-size: 1.4rem;
  font-weight: 700;
  justify-content: center;
  padding: 9rem 0;
`;

const TableWrapper = styled(Table)`
  font-size: 1.6rem;
  font-weight: 700 !important;
  margin-bottom: 7rem;
  position: relative;
  top: -2.4rem;
`;

const TableColHeader = styled.th`
  ${baseStylesTableHeader}
  ${baseStylesSticky}

  background-color: ${({ theme }) => theme.color.brandWhite};
  border-top: 0 !important;
  font-weight: 700;
  font-size: 1.4rem !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:first-child {
    max-width: 4.8rem;
  }
`;
const TableRow = styled.tr`
  &:hover {
    transition: all 0.3s ease-in-out !important;
  }
`;

const TableRowHeader = styled.th`
  ${baseStylesTableHeader}

  font-size: 1.6rem !important;
`;

const TableRowWithExpandedCell = styled.tr`
  &:hover {
    background-color: transparent !important;
  }
`;

const TableCellSticky = styled.td`
  ${baseStylesSticky}

  background-color: ${({ theme }) => theme.color.brandWhite};
  font-size: 1.4rem;
`;

const TableCellExpanded = styled.td`
  font-size: 1.4rem;
  padding-bottom: 1.6rem !important;
  padding-top: 3.2rem !important;
`;

export {
  NoDataWrapper,
  TableCellSticky,
  TableCellExpanded,
  TableColHeader,
  TableRow,
  TableRowHeader,
  TableRowWithExpandedCell,
  TableWrapper,
};
