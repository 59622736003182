import { useParams } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';

import { ImageUploadModes } from '@common/enums/FileUploadModes';
import { ImageUpload } from '@components/MediaUpload';
import { BaseContentActionsCreator } from '@actionCreators/BaseContentActionsCreator';
import ImageUploadUtils from '@components/MediaUpload/ImageUploadUtils';
import { CommonExerciseActionsCreator } from '@actionCreators/CommonExerciseActionsCreator';
import { ContentTypes } from '@common/enums/ContentTypes';
import { Sizes } from '@common/enums/Sizes';
import { DBId } from '@common/types/DBId';
import { useAppDispatch } from '@redux/store';
import { LESSON_CHECKPOINT, LESSON_ROLEPLAY } from '@common/enums/LessonTypes';
import { LessonInterface } from '@common/interfaces/contentTypes/LessonInterface';
import checkpointImage from '@components/ContentTypes/BaseContent/img/checkpoint.svg';
import { ReactComponent as GenericImageIcon } from '@static/svg/image-generic.svg';

const GenericImageWrapper = styled.div`
  ${({ theme }) => css`
    align-items: center;
    background-color: ${theme.colorV2.uiBackgroundSecondary};
    border-radius: 3rem;
    display: flex;
    justify-content: center;
    height: 6rem;
    margin-right: ${theme.space.M};
    min-width: 6rem;
    width: 6rem;
  `}
`;

export const LessonCardImage = ({ content }: { content: LessonInterface }) => {
  const params: { courseId?: string; levelId?: string; lessonId?: string; activityId?: string } = useParams();
  const { courseId } = params;

  const { id, lessonType, image, categoryId } = content;

  const dispatch = useAppDispatch();

  if (lessonType === LESSON_CHECKPOINT) {
    return (
      <img src={checkpointImage} alt="Checkpoint" className="content-common-header__content-and-image__static-image" />
    );
  }

  if (lessonType === LESSON_ROLEPLAY) {
    // @TODO replace this with roleplayImage when provided by design
    return (
      <GenericImageWrapper>
        <GenericImageIcon title="Roleplay" />
      </GenericImageWrapper>
    );
  }

  return (
    <div onClick={(evt) => evt.stopPropagation()}>
      <ImageUpload
        text="Lesson images must be exactly 2048x2048"
        mode={ImageUploadModes['no-text']}
        size={Sizes.small}
        imageData={ImageUploadUtils.getImageDataOutsideExercise(image)}
        onProcessingFinished={(url: string) => {
          dispatch(
            CommonExerciseActionsCreator.setValueAfterProcessing({
              url,
              mediaType: 'image',
              type: ContentTypes.lesson,
              field: 'image',
              language: 'EN',
              lessonId: id,
            }),
          );
        }}
        previewMode={false}
        onChange={(file, progressHandler) => {
          dispatch(
            BaseContentActionsCreator.uploadImageToLesson({
              lessonId: categoryId as DBId,
              contentId: id,
              contentIdBeingUpdated: image?._id,
              file,
              courseId: courseId as DBId,
              progressHandler,
            }),
          );
        }}
        onRemove={() => {
          dispatch(
            BaseContentActionsCreator.removeImageFromLesson({
              lessonId: categoryId as DBId,
              contentId: id,
              courseId: courseId as DBId,
              contentIdBeingUpdated: image?._id,
            }),
          );
        }}
        className="content-common-header__file-upload"
        isForLesson
        fieldName={id}
        viaTooltip={true}
      />
    </div>
  );
};
