import { ContentTypes } from '@common/enums/ContentTypes';
import { EXERCISE_TIP, EXERCISE_TITLES } from '@common/enums/ExerciseTypes';
import { tipIcon } from '@components/ContentTypes/ExerciseCard/exerciseIcons';
import { useBulkPublishing } from '@features/content/exercises';
import { ContentCreatorWidget, type ContentCreatorWidgetItemType } from '@features/theme';

const TIP_CREATOR_ITEM: ContentCreatorWidgetItemType<typeof ContentTypes.tip> = {
  label: 'Tip',
  iconSrc: tipIcon,
  showByDefault: true,
  type: ContentTypes.tip,
};

type TipCreatorProps = {
  isCreateInProgress?: boolean;
  isPreEnabled: boolean;
  isEditAvailable: boolean;
  position: number;
  onCreateContent: (itemType: typeof EXERCISE_TIP, itemCategory: null, numItemsToCreate: number) => void;
  onSearch: () => void;
};

export const TipCreator = ({
  isCreateInProgress = false,
  isEditAvailable,
  isPreEnabled,
  position,
  onCreateContent,
  onSearch,
}: TipCreatorProps) => {
  const { currentBulkPublishingState } = useBulkPublishing();

  return (
    <ContentCreatorWidget
      allowBulk
      isCreateInProgress={isCreateInProgress}
      isEditAvailable={isEditAvailable}
      isPreEnabled={isPreEnabled}
      items={[TIP_CREATOR_ITEM]}
      position={position}
      title="Create a first topic reference"
      getItemLabel={() => EXERCISE_TITLES[EXERCISE_TIP]}
      onItemSelected={(item, numItemsToCreate = 1) => onCreateContent(item.type, null, numItemsToCreate)}
      onSearch={!currentBulkPublishingState ? onSearch : undefined}
    />
  );
};
