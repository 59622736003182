import React from 'react';
import { Dropdown } from 'react-bootstrap';
import styled from 'styled-components/macro';

import { Button, Loader } from '@features/theme';
import { HelpDisplayer } from '@features/help';

import { ReactComponent as DropdownIcon } from './dropdownIndicator.svg';

const ReuseContentWrapper = styled(Dropdown)`
  align-items: end;
  display: flex;
  flex-direction: column;
  min-width: unset;
  position: absolute;
  right: 2.4rem;
  top: 0;
`;

const HelperContainer = styled.div`
  position: absolute;
  right: 14rem;
  top: 0.5rem;
`;

const StyledButton = styled(Button)`
  font-size: 1.4rem;
  padding-right: 0.8rem;

  svg {
    width: 1.8rem;
    height: 1.8rem;
    margin-right: 0.4rem;
    vertical-align: text-top;
  }
`;

const ReuseContentDropdownMenu = styled(Dropdown.Menu)`
  border: 0.1rem solid ${({ theme }) => theme.color.quaternaryBase};
  border-radius: 0.8rem;
  box-shadow: 0 0.4rem 0.8rem rgba(0, 0, 0, 0.05);
  padding: 0;
  position: static !important;
  transform: none !important;
`;

const ReuseContentDropdownItem = styled(Dropdown.Item)`
  display: flex;
  align-items: center;
  font-size: 1.4rem !important;
  height: 4.4rem;
`;

const StringReuseDropdownToogle = React.forwardRef(
  (
    { onClick }: { onClick: (event: React.MouseEvent<HTMLButtonElement>) => void },
    ref: React.Ref<HTMLButtonElement>,
  ) => (
    <StyledButton variant="secondary" ref={ref} size="S" onClick={onClick}>
      Reuse | <DropdownIcon />
    </StyledButton>
  ),
);

type ReuseContentButtonProps = {
  contentLabel: string;
  inProgress: boolean;
  onCopyContent: () => void;
  onUseContent: () => void;
};

export const ReuseContentButton = ({
  contentLabel,
  inProgress,
  onCopyContent,
  onUseContent,
}: ReuseContentButtonProps) => (
  <>
    <HelperContainer>
      <HelpDisplayer id="guideline-content-reuse" type="guideline" />
    </HelperContainer>
    <ReuseContentWrapper>
      {inProgress ? (
        <Loader />
      ) : (
        <>
          <Dropdown.Toggle as={StringReuseDropdownToogle} />
          <ReuseContentDropdownMenu>
            <ReuseContentDropdownItem onClick={onUseContent}>Use {contentLabel}</ReuseContentDropdownItem>
            <ReuseContentDropdownItem onClick={onCopyContent}>Make a copy</ReuseContentDropdownItem>
          </ReuseContentDropdownMenu>
        </>
      )}
    </ReuseContentWrapper>
  </>
);
