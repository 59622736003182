import { useEffect, useState } from 'react';

import { useUser } from '@features/auth';

export const DEV_IDS = [
  '00u1kosubb5EEWdTb1d8', // M. Dovydenok
  '00u1kosuijqC8MO5w1d8', // N. Linkevich
  '00u1mj8f5kjK4oPUf1d8', // S. Martín-Cleto
];

export const useShowDebugUI = (): boolean => {
  const [keyPressed, setKeyPressed] = useState(false);
  const { user } = useUser();

  const isAllowedToDebug = DEV_IDS.includes(user?.id as string);

  useEffect(() => {
    if (isAllowedToDebug) {
      const listener = (event: KeyboardEvent) => {
        setKeyPressed(event.altKey);
      };

      document.addEventListener('keydown', listener);
      document.addEventListener('keyup', listener);

      return () => {
        document.removeEventListener('keydown', listener);
        document.removeEventListener('keyup', listener);
      };
    }
  });

  return keyPressed;
};
