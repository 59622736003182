import styled from 'styled-components/macro';

import { HelpTooltip } from '@features/help';
import { ReactComponent as PreviewIcon } from './preview.svg';

export const ButtonContainer = styled.div`
  cursor: pointer;
  margin-right: 0.8rem;
`;

export const PreviewButton = ({ onPreviewClick }: { onPreviewClick: () => void }) => (
  <>
    <ButtonContainer
      id="header-preview-button"
      data-tip="Preview"
      data-for="preview-button-tooltip"
      onClick={onPreviewClick}
    >
      <PreviewIcon />
    </ButtonContainer>
    <HelpTooltip id="preview-button-tooltip" effect="solid" />
  </>
);
