import { DependencyList, useCallback, useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { debounce, DebouncedFunc, DebounceSettings } from 'lodash';

import { Button, Loader } from '@features/theme';
import { ReactComponent as CommentsIcon } from './comments.svg';

const useScroll = (callback: (scrollY: number) => void) => {
  useEffect(() => {
    const callbackWithNoArgs = () => {
      callback(window.scrollY);
    };

    document.addEventListener('scroll', callbackWithNoArgs);

    return () => {
      document.removeEventListener('scroll', callbackWithNoArgs);
    };
  }, [callback]);
};

const useDebounce: <T extends (...args: any[]) => void>(
  callback: T,
  deps: DependencyList,
  wait: number,
  options: DebounceSettings,
) => DebouncedFunc<T> = (callback, deps, wait, options) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const callbackMemorized = useCallback(callback, deps);

  return useMemo(() => {
    return debounce(callbackMemorized, wait, options);
  }, [callbackMemorized, wait, options]);
};

const DEBOUNCE_WAIT = 150;
const DEBOUNCE_OPTIONS = { leading: true };

const CommentsButtonWrapper = styled(Button)<{ inModalMode: boolean }>`
  ${({ inModalMode, theme }) => css`
    position: ${inModalMode ? 'absolute' : 'fixed'};
    bottom: ${inModalMode ? '10rem' : '11rem'};
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    right: 1.6rem;
    padding: 0.8rem 2.4rem;
    z-index: ${theme.zIndex.commentsButtonWrapper};

    svg {
      width: 2.8rem;
      height: 2.8rem;
    }
  `};
`;

const TotalCountNumber = styled.div`
  position: relative;
`;

const UnreadCountIndicator = styled.div`
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: -0.4rem;
  background: ${({ theme }) => theme.colorV2.utilityError};
`;

type CommentsButtonProps = {
  loaded: boolean;
  unreadCount: number;
  totalCount: number;
  onClick: () => void;
  modalMode: boolean;
};
export const CommentsButton = ({ loaded, unreadCount, totalCount, onClick, modalMode }: CommentsButtonProps) => {
  const [collapsed, setCollapsed] = useState(window.scrollY > 0);

  useScroll(
    useDebounce(
      (scrollY: number) => {
        setCollapsed(scrollY > 0);
      },
      [],
      DEBOUNCE_WAIT,
      DEBOUNCE_OPTIONS,
    ),
  );

  return (
    <CommentsButtonWrapper collapsed={collapsed} inModalMode={modalMode} icon={<CommentsIcon />} onClick={onClick}>
      <TotalCountNumber>
        {loaded ? totalCount > 0 ? totalCount : '' : <Loader size="S" />}
        {loaded && totalCount > 0 && unreadCount > 0 && <UnreadCountIndicator />}
      </TotalCountNumber>
      {loaded ? `Comment${totalCount === 1 ? '' : 's'}` : 'Comments'}
    </CommentsButtonWrapper>
  );
};
