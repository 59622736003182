import { ContentTypes, type ContentTypesType } from '@common/enums/ContentTypes';
import { ActivityInterface } from '@common/interfaces/contentTypes/ActivityInterface';
import { GenericContentInterface } from '@common/interfaces/contentTypes/GenericContentInterface';
import { LessonInterface } from '@common/interfaces/contentTypes/LessonInterface';
import { LevelInterface } from '@common/interfaces/contentTypes/LevelInterface';
import { CourseInterface } from '@common/interfaces/courses/CourseInterface';
import { AvailableLocalizations } from '@common/enums/AvailableLocalizations';
import { DBId } from '@common/types/DBId';
import { findLocalizationInSearchedLanguage, constants as contentConstants } from '@features/content';
import { ContentDatesType, ContentOwnerType } from '@features/content/contentOwnership';
import { BaseCourseContent } from '@redux/initialStates/courseInitialState';
import { TranslationsPanelContentInterface } from '@common/interfaces/exercises/TranslationsPanelContentInterface';
import { APICourse } from './types/CourseServiceTypes';
import { ChangeStatusInterface } from '@common/interfaces/contentTypes/ChangeStatusInterface';
import { random } from 'lodash';
import ExerciseDataModelAssembler from '@components/Exercises/ExerciseDataModelAssembler';
import { LocalizationInterface } from '@common/interfaces/localization/LocalizationInterface';
import { LoadingStage } from '@common/enums/LoadingStage';
import { LESSON_CERTIFICATE, LESSON_LIVE, LESSON_ROLEPLAY } from '@common/enums/LessonTypes';
import { RoleplayCategoryListItemType } from '@features/content/roleplay';

const createGenericContentSkeleton = (
  requestedContentTypeToBeCreated: ContentTypesType,
  id: DBId,
  title: string,
  position: any,
  titleWithLocalizations: TranslationsPanelContentInterface,
  descriptionWithLocalizations: TranslationsPanelContentInterface,
  ready: boolean,
  changeStatus: ChangeStatusInterface,
  image: TranslationsPanelContentInterface | undefined,
  thumbnailImage: TranslationsPanelContentInterface | undefined,
  labels: string[],
  mappings: number,
  mappingsPath: any,
  learningOutcomes: TranslationsPanelContentInterface | undefined,
  completeMessage: TranslationsPanelContentInterface | undefined,
  owner: ContentOwnerType | null,
  dates: ContentDatesType,
  roleplayScenario?: RoleplayCategoryListItemType,
): GenericContentInterface => {
  let output: GenericContentInterface = {
    id,
    date: `${random(1, 30)}/${random(1, 12)}/${random(2019, 2021)} ${random(1, 12)}:05pm`,
    expanded: false,
    type: requestedContentTypeToBeCreated,
    text: title,
    title,
    categoryId: position[0].id,
    description: '',
    validation: {
      validated: false,
      valid: false,
      invalidChildEntities: [],
      errors: [],
    },
    ready,
    changeStatus,
    position: [
      {
        id: 'null',
        position: 0,
        type: ContentTypes.course,
      },
    ],
    titleWithLocalizations:
      titleWithLocalizations?.textLocalizations?.length || titleWithLocalizations?.audioLocalizations?.length
        ? {
            ...titleWithLocalizations,
            ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranchesForSomeLanguages(
              ['textLocalizations'],
              titleWithLocalizations?.textLocalizations,
            ),
            ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranchesForSomeLanguages(
              ['audioLocalizations'],
              titleWithLocalizations?.audioLocalizations,
            ),
          }
        : {
            ...titleWithLocalizations,
            ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranches(['textLocalizations', 'audioLocalizations']),
          },
    descriptionWithLocalizations:
      descriptionWithLocalizations?.textLocalizations?.length ||
      descriptionWithLocalizations?.audioLocalizations?.length
        ? {
            ...descriptionWithLocalizations,
            ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranchesForSomeLanguages(
              ['textLocalizations'],
              descriptionWithLocalizations?.textLocalizations,
            ),
            ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranchesForSomeLanguages(
              ['audioLocalizations'],
              descriptionWithLocalizations?.audioLocalizations,
            ),
          }
        : {
            ...descriptionWithLocalizations,
            ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranches(['textLocalizations', 'audioLocalizations']),
          },
    image,
    mappings,
    mappingsPath,
    thumbnailImage,
    loaded: LoadingStage.loaded,
    labels: labels,
    // @ts-ignore
    learningOutcomes:
      learningOutcomes?.textLocalizations?.length || learningOutcomes?.audioLocalizations?.length
        ? {
            ...learningOutcomes,
            ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranchesForSomeLanguages(
              ['textLocalizations'],
              learningOutcomes?.textLocalizations,
            ),
            ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranchesForSomeLanguages(
              ['audioLocalizations'],
              learningOutcomes?.audioLocalizations,
            ),
          }
        : {
            ...learningOutcomes,
            _id: '',
            ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranches(['textLocalizations', 'audioLocalizations']),
          },
    // @ts-ignore
    completeMessage:
      completeMessage?.textLocalizations?.length || completeMessage?.audioLocalizations?.length
        ? {
            ...completeMessage,
            ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranchesForSomeLanguages(
              ['textLocalizations'],
              completeMessage?.textLocalizations,
            ),
            ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranchesForSomeLanguages(
              ['audioLocalizations'],
              completeMessage?.audioLocalizations,
            ),
          }
        : {
            ...completeMessage,
            _id: '',
            ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranches(['textLocalizations', 'audioLocalizations']),
          },
    identityGroup: null,
    owner,
    dates,
    roleplayScenario,
  };

  return output;
};

const findLocatizationTextInDefaultCourseLanguage = (
  localizations: LocalizationInterface[] | undefined,
  notFoundReturnString: string | undefined = 'N/A',
): string => {
  if (localizations === undefined || localizations.length === 0) {
    return notFoundReturnString;
  }

  let localizationInDefaultLanguage = findLocalizationInSearchedLanguage(
    localizations,
    contentConstants.DEFAULT_LANGUAGE,
  )?.value;

  if (localizationInDefaultLanguage === undefined) {
    return notFoundReturnString;
  } else {
    return localizationInDefaultLanguage;
  }
};

const extractFirstLocalization = (
  type: typeof AvailableLocalizations.text | typeof AvailableLocalizations.image,
  element: any,
) => {
  if (!element) {
    return null;
  }

  if (type === AvailableLocalizations.text) {
    return element.textLocalizations !== undefined && element.textLocalizations.length
      ? element.textLocalizations[0].value
      : element;
  }

  if (type === AvailableLocalizations.image) {
    if (element === '') {
      return '';
    }

    return element.imageLocalizations !== undefined ? element.imageLocalizations[0].value : element;
  }
};

const CourseSanitizerService = {
  sanitizeCourse: (course: APICourse): CourseInterface => {
    // @ts-ignore
    let courseProcessed: CourseInterface = { ...course };

    if (course.title === null) {
      courseProcessed.title = BaseCourseContent.text;
    } else {
      let textLocalizations = course?.title?.textLocalizations;

      if (textLocalizations === undefined) {
        courseProcessed.title = BaseCourseContent.text;
      } else {
        let title = findLocalizationInSearchedLanguage(
          course?.title?.textLocalizations,
          contentConstants.DEFAULT_LANGUAGE,
        )?.value;

        courseProcessed.title = title !== undefined ? title : BaseCourseContent.text;
      }
    }

    courseProcessed.name = course.name;
    courseProcessed.descriptionContentId = course.description?._id as DBId;

    courseProcessed.descriptionLocalizationIDForEnglish =
      (course as any).description === null
        ? undefined
        : findLocalizationInSearchedLanguage(course?.description?.textLocalizations, contentConstants.DEFAULT_LANGUAGE)
            ?._id;

    courseProcessed.description = extractFirstLocalization(AvailableLocalizations.text, course.description);

    return courseProcessed;
  },
  sanitizeGenericContent: (content: GenericContentInterface) => {
    let {
      id,
      title,
      type,
      position,
      description,
      levelType,
      ready,
      changeStatus,
      mappings,
      image,
      thumbnailImage,
      labels,
      learningOutcomes,
      completeMessage,
      owner,
      dates,
      roleplayScenario,
    } = content;

    let newContent: GenericContentInterface = createGenericContentSkeleton(
      type,
      id,
      extractFirstLocalization(AvailableLocalizations.text, title),
      position,
      content.titleWithLocalizations || (title as unknown as TranslationsPanelContentInterface),
      content.descriptionWithLocalizations || (description as unknown as TranslationsPanelContentInterface),
      ready,
      changeStatus,
      image,
      thumbnailImage,
      labels,
      (mappings as any).length,
      mappings,
      learningOutcomes,
      completeMessage,
      owner,
      dates,
      roleplayScenario,
    );

    switch (type) {
      case ContentTypes.level: {
        newContent = {
          ...newContent,
          levelType: levelType || content.class,
          name: content.name,
          validation: content.validation,
        } as LevelInterface;
        break;
      }

      case ContentTypes.chapter: {
        newContent = {
          ...newContent,
          validation: content.validation,
        };
        break;
      }

      case ContentTypes.lesson: {
        // let image =
        //     findLocatizationTextInDefaultCourseLanguage(
        //         (content.image as any)?.imageLocalizations,
        //         ''
        //     );

        let description = findLocatizationTextInDefaultCourseLanguage((content.description as any)?.textLocalizations);

        newContent = {
          ...newContent,
          lessonType: (content as any).class,
          description,
          name: content.name,
          focus: content.focus,
          validation: content.validation,
          grammarTopic: content.grammarTopic,
          identityGroup: content.identityGroup,
        } as LessonInterface;

        if (newContent.lessonType === LESSON_CERTIFICATE) {
          (newContent as LessonInterface).certificateType = (content as LessonInterface).certificateType;
        }

        if (newContent.lessonType === LESSON_ROLEPLAY) {
          (newContent as LessonInterface).roleplayScenario = (content as LessonInterface).roleplayScenario;
        }

        if (newContent.lessonType !== LESSON_LIVE) {
          (newContent as LessonInterface).contentAnalytics = (content as LessonInterface).contentAnalytics;
        }

        break;
      }

      case ContentTypes.activity: {
        let activityType = content.class || 'vocabulary';

        newContent = {
          ...newContent,
          selectedActivityFocusId: '1',
          activityType,
          text: (content as any).name,
          validation: content.validation,
          oldType: content?.oldType,
        } as ActivityInterface;
        break;
      }
    }

    return newContent;
  },
  sanitizeGenericContents: (allCourseContents: GenericContentInterface[]) => {
    allCourseContents = allCourseContents.map((courseContent: GenericContentInterface) => {
      return CourseSanitizerService.sanitizeGenericContent(courseContent);
    });

    return allCourseContents;
  },
  sanitizeExercisesFromGroup: (exercises: any) =>
    exercises.map((exercise: any) => {
      exercise.text = exercise.name;
      return exercise;
    }),
};

export default CourseSanitizerService;
