import { Prompt, useParams } from 'react-router-dom';
import { ReactElement, useEffect } from 'react';

import { LoadingStage } from '@common/enums/LoadingStage';
import { DBId } from '@common/types/DBId';
import { CourseEditionActionsCreator } from '@actionCreators/CourseEditionActionsCreator';
import { PublishModalContainer } from '@components/CourseEdition/PublishModalContainer';
import { StringReuseModal } from '@components/StringReuseModal/StringReuseModal';
import { PublishingErrorsModal } from '@components/Publishing/PublishingErrorsModal/PublishingErrorsModal';
import { PublishingQueueSidebar } from '@components/Publishing/PublishingQueueSidebar';
import { coursesInfoRequest } from '@actionCreators/CourseActionCreator';
import { Comments } from '@features/comments';
import { useOpenSearchShortcut } from '@features/search';

import { GrammarReviewActionCreators } from '@actionCreators/GrammarReviewActionCreators';
import { NavigationPanel } from './NavigationPanel';
import { useAppDispatch, useAppSelector } from '@redux/store';
import {
  selectCourse,
  selectCoursesInfo,
  selectIsSaveButtonEnabled,
  selectStringReuseModalOpenedFor,
} from '@selectors/CoursesSelectors';
import { selectPublishingPanelVisible } from '@selectors/UiSelectors';
import { selectGrammarReview } from '@selectors/GrammarSelectors';

export const GrammarContent = ({ children }: { children: ReactElement }) => {
  const dispatch = useAppDispatch();
  const { courseId } = useParams<{ courseId: DBId }>();
  const coursesInfo = useAppSelector(selectCoursesInfo);
  const publishingPanelVisible = useAppSelector(selectPublishingPanelVisible);
  const stringReuseModalOpenedFor = useAppSelector(selectStringReuseModalOpenedFor);
  const currentCourse = useAppSelector(selectCourse);
  const isSaveButtonEnabled = useAppSelector(selectIsSaveButtonEnabled);
  const grammarReview = useAppSelector(selectGrammarReview);

  useOpenSearchShortcut();

  useEffect(() => {
    if (coursesInfo.loaded === LoadingStage.notLoaded) dispatch(coursesInfoRequest());
  }, [dispatch, coursesInfo]);

  useEffect(() => {
    if (currentCourse.loaded === LoadingStage.notLoaded && courseId) {
      dispatch(CourseEditionActionsCreator.getCourse(courseId));
    }
  }, [dispatch, courseId, currentCourse]);

  useEffect(() => {
    if (grammarReview.loaded === LoadingStage.notLoaded && courseId) {
      dispatch(GrammarReviewActionCreators.getGrammarReview(courseId));
    }
  }, [dispatch, courseId, grammarReview]);

  return (
    <div className="course-edition">
      <Prompt when={isSaveButtonEnabled} message={() => `Are you sure you want to navigate?`} />
      <PublishModalContainer />
      {publishingPanelVisible && <PublishingQueueSidebar />}
      <PublishingErrorsModal />
      {stringReuseModalOpenedFor && <StringReuseModal />}
      <NavigationPanel />
      {currentCourse.loaded === LoadingStage.loaded && <Comments />}
      {children}
    </div>
  );
};
