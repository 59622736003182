import { ReactNode } from 'react';
import styled from 'styled-components/macro';

import { Loader } from '@features/theme';

const StyledDetailsWrapper = styled.div<{ isExpanded: boolean }>`
  width: 100%;
  margin-top: 1.6rem;
  position: relative;
  padding: 0 2.4rem;
  transition: height 0.2s ease-out;
  height: ${({ isExpanded }) => (isExpanded ? '30rem' : '0')};
`;

export const DetailsWrapper = ({
  errorMessage,
  isExpanded,
  children,
  isLoaderDisplayed,
}: {
  errorMessage: string;
  isExpanded: boolean;
  children: ReactNode;
  isLoaderDisplayed: boolean;
}) => (
  <StyledDetailsWrapper isExpanded={isExpanded}>
    {isExpanded && (
      <>{errorMessage ? <div>{errorMessage}</div> : <>{isLoaderDisplayed ? <Loader size="L" /> : children}</>}</>
    )}
  </StyledDetailsWrapper>
);
