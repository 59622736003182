import { takeLatest } from 'redux-saga/effects';

import { FillGapExerciseActions } from '@actions/FillGapExerciseActions';
import ExerciseSagas from '@sagas/exercises/definitions/ExerciseSagas';
import FillGapExerciseSagas from '@sagas/exercises/definitions/FillGapExerciseSagasDefinition';
import MediaUploadSagas from '@sagas/mediaUpload/definitions/MediaUploadSagasDefinition';
import type { AppDispatch } from '@redux/store';

export function* FillGapExerciseRelatedSagas(dispatch: AppDispatch) {
  yield takeLatest(FillGapExerciseActions.UPLOAD_IMAGE, MediaUploadSagas.uploadImageToExercise);
  yield takeLatest(FillGapExerciseActions.SAVE, FillGapExerciseSagas.save, dispatch);
  yield takeLatest(FillGapExerciseActions.REMOVE_AUDIO, ExerciseSagas.removeAudioFromExercise);
}
