import produce from 'immer';

import { SectionActions } from '@actions/SectionActions';
import { ExerciseTypes } from '@common/enums/ExerciseTypes';
import { LoadingStage } from '@common/enums/LoadingStage';
import { CourseSliceInterface } from '@common/interfaces/slices/CourseSliceInterface';
import ExerciseDataModelAssembler from '@components/Exercises/ExerciseDataModelAssembler';
import { updateTextField } from '@helpers/updateTextField';
import { UploadMediaResponse } from '@services/HelpersService';
import { SpeechRecognitionExerciseActions } from '@actions/SpeechRecognitionExerciseActions';
import SpeechRecognitionExerciseInterface from '@components/Exercises/SpeechRecognition/interfaces/SpeechRecognitionExerciseInterface';
import { selectedGroupsOfParentInitialData } from '@redux/initialStates/courseInitialState';
import { PayloadAction } from '@reduxjs/toolkit';
import { clone } from '@helpers/clone';
import { removeAudioDataFromBundle } from './common/helpers';

const SpeechRecognitionExerciseCourseReducers = {
  [SectionActions.COURSE_EXERCISE_SPEECH_RECOGNITION_REQUEST_SUCCEEDED]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    let clonedCourseSlice: CourseSliceInterface = clone(state);
    let { content }: SpeechRecognitionExerciseInterface = payload;

    let exercise: SpeechRecognitionExerciseInterface = {
      ...clonedCourseSlice.loadedExercise.exercise,
      content,
      type: ExerciseTypes.speechRecognition,
    };

    return {
      ...clonedCourseSlice,
      selectedGroupsOfParent: selectedGroupsOfParentInitialData,
      loadedExercise: {
        loaded: LoadingStage.loaded,
        exercise,
      },
    };
  },
  [SpeechRecognitionExerciseActions.SET_INSTRUCTIONS_LANGUAGE]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    return ExerciseDataModelAssembler.updateCheckbox('instructionsLanguage', state, payload);
  },
  [SpeechRecognitionExerciseActions.UPDATE_PHONEMES]: (
    state: CourseSliceInterface,
    { type, payload: { phonemes } }: PayloadAction<any>,
  ): CourseSliceInterface => {
    let clonedState: CourseSliceInterface = clone(state);

    const loadedExercise: SpeechRecognitionExerciseInterface = clonedState.loadedExercise
      .exercise as SpeechRecognitionExerciseInterface;

    loadedExercise.content.feedbackPhonemes = phonemes;
    loadedExercise.content.feedbackPhonemesChanged = true;
    loadedExercise.content.feedbackOption = 'phoneme';
    loadedExercise.content.feedbackOptionChanged = true;

    return clonedState;
  },
  [SpeechRecognitionExerciseActions.REMOVE_MULTI_OPTION_CHOOSER_OPTION]: (
    state: CourseSliceInterface,
    { type }: PayloadAction<any>,
  ): CourseSliceInterface => {
    let clonedState: CourseSliceInterface = clone(state);

    const loadedExercise: SpeechRecognitionExerciseInterface = clonedState.loadedExercise
      .exercise as SpeechRecognitionExerciseInterface;

    loadedExercise.content.feedbackOption = null;
    loadedExercise.content.feedbackPhonemes = null;
    loadedExercise.content.feedbackOptionChanged = true;
    loadedExercise.content.feedbackPhonemesChanged = true;

    return clonedState;
  },
  [SpeechRecognitionExerciseActions.SET_FEEDBACK_TEXT_OPTION]: (
    state: CourseSliceInterface,
    { type }: PayloadAction<any>,
  ): CourseSliceInterface => {
    let clonedState: CourseSliceInterface = clone(state);

    const loadedExercise: SpeechRecognitionExerciseInterface = clonedState.loadedExercise
      .exercise as SpeechRecognitionExerciseInterface;

    loadedExercise.content.feedbackOption = 'text';
    loadedExercise.content.feedbackOptionChanged = true;

    return clonedState;
  },
  [SpeechRecognitionExerciseActions.SET_MULTI_OPTION_CHOOSER_OPTION_AS]: (
    state: CourseSliceInterface,
    { type, payload: { feedbackOption } }: PayloadAction<any>,
  ): CourseSliceInterface => {
    let clonedState: CourseSliceInterface = clone(state);

    const loadedExercise: SpeechRecognitionExerciseInterface = clonedState.loadedExercise
      .exercise as SpeechRecognitionExerciseInterface;

    loadedExercise.content.feedbackOption = feedbackOption;
    loadedExercise.content.feedbackOptionChanged = true;

    return clonedState;
  },
  [SpeechRecognitionExerciseActions.REMOVE_IMAGE]: (state: CourseSliceInterface): CourseSliceInterface => {
    return ExerciseDataModelAssembler.nullifyFieldInBundle({
      field: 'image',
      bundleName: 'mainBundle',
      state,
    });
  },
  [SpeechRecognitionExerciseActions.REMOVE_AUDIO]: (state: CourseSliceInterface): CourseSliceInterface => {
    let clonedCourseSlice: CourseSliceInterface = clone(state);

    return removeAudioDataFromBundle(clonedCourseSlice, 'phrase', 'mainBundle', true);
  },
  [SpeechRecognitionExerciseActions.SET_SPEECH_RECOGNITION_ALL_FIELDS]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    let clonedCourseSlice: CourseSliceInterface = clone(state);

    const { values } = payload;

    clonedCourseSlice = updateTextField(clonedCourseSlice, values, 'instructions');
    clonedCourseSlice = updateTextField(clonedCourseSlice, values, 'phrase', 'mainBundle');
    clonedCourseSlice = updateTextField(clonedCourseSlice, values, 'feedback');

    return clonedCourseSlice;
  },
  [SpeechRecognitionExerciseActions.REMOVE_VIDEO]: (state: CourseSliceInterface): CourseSliceInterface => {
    return ExerciseDataModelAssembler.nullifyFieldInBundle({
      field: 'video',
      bundleName: 'mainBundle',
      state,
    });
  },
  [SpeechRecognitionExerciseActions.UPLOAD_VIDEO_SUCCESS]: (
    state: CourseSliceInterface,
    { payload: { content, mediaId } }: PayloadAction<UploadMediaResponse>,
  ): CourseSliceInterface => {
    return produce(state, (draft) => {
      draft.loadedExercise.exercise.content.mainBundle.video = {
        ...content,
        _id: mediaId,
        changed: true,
      };

      draft.mediaUploading.imageOrVideoUploadingInProcess = draft.mediaUploading.imageOrVideoUploadingInProcess.filter(
        (item) => item !== 'video',
      );
    });
  },
};

export default SpeechRecognitionExerciseCourseReducers;
