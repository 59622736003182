import React, { FunctionComponent } from 'react';
import { Button } from 'react-bootstrap';

import { Loader } from '@features/theme';
import { Role } from '@features/auth/roles';
import { OptionInterface } from '@components/RolesAndPermissions/AdminSettings/Table/UserItem/CourseContainer/CourseContainer';

interface EditPanelProps {
  onCancel: React.MouseEventHandler<HTMLButtonElement>;
  onSave: React.MouseEventHandler<HTMLButtonElement>;
  saveInProgress: boolean;
  currentRole: Role;
  currentLanguage: OptionInterface[];
  allLanguagesSelected: boolean;
}

export const EditPanel: FunctionComponent<EditPanelProps> = ({
  onCancel,
  onSave,
  saveInProgress,
  currentRole,
  currentLanguage,
  allLanguagesSelected,
}: EditPanelProps) => {
  return (
    <div className="user-item__buttons">
      <Button
        className="user-item__buttons__button user-item__buttons__cancel-button"
        onClick={onCancel}
        disabled={saveInProgress}
      >
        Cancel
      </Button>
      <Button
        onClick={onSave}
        className="user-item__buttons__button user-item__buttons__save-button"
        disabled={!currentRole || !(currentLanguage.length || allLanguagesSelected)}
      >
        {saveInProgress ? <Loader /> : 'Save'}
      </Button>
    </div>
  );
};
