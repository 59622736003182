import { CommonFilterSelector } from '@components/SelectorCommonComponents';
import { AudioRequestStatusType } from '../../../types';

const CLASSNAME_PREFIX = 'status-selector';

type StatusOptionType = { value: AudioRequestStatusType; label: string };

const statusOptions: StatusOptionType[] = [
  {
    value: 'new',
    label: 'New',
  },
  {
    value: 'exported',
    label: 'Exported',
  },
  {
    value: 'completed',
    label: 'Completed',
  },
];

export const StatusFilter = ({
  selectedStatus,
  setSelectedStatus,
}: {
  selectedStatus: StatusOptionType | null;
  setSelectedStatus: (language: StatusOptionType | null) => void;
}) => (
  <CommonFilterSelector
    placeholder="Status"
    classNamePrefix={CLASSNAME_PREFIX}
    value={selectedStatus}
    options={statusOptions}
    onChange={(status) => setSelectedStatus(status as StatusOptionType)}
  />
);
