import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useEffect } from 'react';

export const ReadOnlyPlugin = ({ readonly }: { readonly: boolean }) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    editor.setEditable(!readonly);
  }, [editor, readonly]);

  return null;
};
