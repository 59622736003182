import type { FormikValuesInterface } from '@helpers/formikInitialValuesHelper';
import { CommonActions } from '@actions/CommonActions';
import { ContentTypesActions } from '@actions/ContentTypesActions';
import { SidePanelActions } from '@actions/SidePanelActions';
import { TranslationsPanelActions } from '@actions/TranslationsPanelActions';

export const TranslationsPanelActionsCreators = {
  save: (values: FormikValuesInterface) => ({
    type: TranslationsPanelActions.SAVE,
    payload: { values },
  }),
  saveCourse: (values: FormikValuesInterface) => ({
    type: ContentTypesActions.COURSE_SAVE_NAME_AND_DESCRIPTION,
    payload: { values },
  }),
  saveLevelAndLesson: (values: FormikValuesInterface) => ({
    type: ContentTypesActions.CONTENT_SAVE_NAME_AND_DESCRIPTION,
    payload: { values },
  }),
  updateTranslatorContext: (
    description: string,
    visitedBranch: string,
    type: string,
    row?: number,
    column?: number,
    bundleName?: string,
  ) => ({
    type: CommonActions.SET_TRANSLATOR_CONTEXT,
    payload: {
      description,
      visitedBranch,
      type,
      row,
      column,
      bundleName,
    },
  }),
  updateLocalization: (
    localizationLanguageCode: string,
    updatedLocalizationString: string,
    visitedBranch: string,
    row?: number,
    column?: number,
    isPhonetic?: boolean,
    bundleName?: string,
  ) => ({
    type: SidePanelActions.UPDATE_LOCALIZATION,
    payload: {
      localizationLanguageCode,
      updatedLocalizationString,
      visitedBranch,
      row,
      column,
      isPhonetic,
      bundleName,
    },
  }),
};
