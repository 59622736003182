import { ExerciseService } from '@common/types/ExerciseService';
import ExerciseDataModelAssembler from '@components/Exercises/ExerciseDataModelAssembler';
import { ExerciseCommonActionCreators as ExerciseCommonActions } from '@actionCreators/ExerciseCommonActionCreator';
import { selectTextLocalizationsForSave, selectMediaLocalizationsForSave } from '@helpers/localizationSaveHelper';
import { FormikValuesInterface } from '@helpers/formikInitialValuesHelper';
import HighlighterExerciseInterface from '@components/Exercises/Highlighter/interfaces/HighlighterExerciseInterface';
import ContentsService from '@services/ContentsService';
import ExercisesService from '@services/ExercisesService';
import { AppDispatch } from '@redux/store';
import { clone } from '@helpers/clone';
import { editHighlighterExercise } from '@services/exercises/editExerciseService';
import { EditHighlighterExerciseRequest, DisplayedLanguageType } from '@services/exercises/editExerciseTypes';
import { addToast } from '@features/app/toast';

export type HighlighterExerciseServiceType = ExerciseService<HighlighterExerciseInterface> & {
  savePhraseLine: (
    exercise: HighlighterExerciseInterface,
    lineNumber: number,
    payload: EditHighlighterExerciseRequest,
  ) => Promise<EditHighlighterExerciseRequest>;
};

const HighlighterExerciseService: HighlighterExerciseServiceType = {
  ensureExerciseFieldsAreReadyForUse(
    exerciseAndEmptyLocalizationBranchesPayload: any,
    exercise: HighlighterExerciseInterface,
  ) {
    let output = clone(exerciseAndEmptyLocalizationBranchesPayload);

    return ExerciseDataModelAssembler.ensureFieldIsReadyForUse(
      exercise,
      output,
      ['instructions', 'feedback'],
      exerciseAndEmptyLocalizationBranchesPayload,
    );
  },
  async savePhraseLine(
    exercise: HighlighterExerciseInterface,
    lineNumber: number,
    payload: EditHighlighterExerciseRequest,
  ) {
    if (exercise.content.phrases === null) return payload;
    let currentPhrase = exercise.content.phrases[lineNumber];

    if (currentPhrase === null || currentPhrase === undefined) {
      return {
        ...payload,
      };
    }

    let currentPhraseId = currentPhrase?._id;

    const defaultTextLocalizationsForSave = [{ value: '', language: 'EN' }];
    const payloadForContent = {
      description: currentPhrase.description,
      textLocalizations: selectTextLocalizationsForSave(currentPhrase.textLocalizations).length
        ? selectTextLocalizationsForSave(currentPhrase.textLocalizations)
        : defaultTextLocalizationsForSave,
      audioLocalizations: selectMediaLocalizationsForSave(currentPhrase.audioLocalizations),
      imageLocalizations: [],
      videoLocalizations: [],
    };

    if (payload.phrases) {
      if (currentPhraseId === undefined) {
        const newContentId = await ContentsService.contents.createNewContent(payloadForContent);

        payload = {
          ...payload,
          phrases: [...payload.phrases, newContentId],
        };

        return payload;
      } else {
        await ContentsService.contents.update(currentPhrase._id, payloadForContent);

        payload = {
          ...payload,
          phrases: [...payload.phrases, currentPhrase._id || null],
        };

        return payload;
      }
    }

    return payload;
  },
  async save(dispatch: AppDispatch, exercise: HighlighterExerciseInterface, values: FormikValuesInterface) {
    try {
      let payload: EditHighlighterExerciseRequest = {
        instructionsLanguage: exercise.content.instructionsLanguage as DisplayedLanguageType,
        recapExerciseId: exercise.content.recapExerciseId,
        experiment: exercise.content.experiment,
      };

      const updatePayload = await Promise.all([
        (values.instructionsChanged || exercise.content.instructions.changed) && !exercise.content.instructions.isReused
          ? ExercisesService.misc.saveField(dispatch, 'instructions', exercise, payload)
          : { instructions: exercise.content.instructions._id },
        (values.feedbackChanged || exercise.content.feedback?.changed || exercise.content.feedbackChanged) &&
        !exercise.content.feedback?.isReused
          ? ExercisesService.misc.saveField(dispatch, 'feedback', exercise, payload)
          : { feedback: exercise.content.feedback?._id || null },
      ]);

      payload.phrases = [];

      payload = await HighlighterExerciseService.savePhraseLine(exercise, 0, payload);
      payload = await HighlighterExerciseService.savePhraseLine(exercise, 1, payload);
      payload = await HighlighterExerciseService.savePhraseLine(exercise, 2, payload);

      if (exercise.content.phrases?.[3]) {
        payload = await HighlighterExerciseService.savePhraseLine(exercise, 3, payload);
      }

      payload = {
        ...payload,
        ...updatePayload.reduce((sum: any, item: any) => ({ ...sum, ...item }), {}),
      };

      if (exercise.content.id) {
        await editHighlighterExercise(exercise.content.id, payload);

        dispatch(ExerciseCommonActions.setSaveProgress({ value: false, updateData: true }));

        addToast({
          type: 'success',
          title: 'This exercise has been saved',
        });
      }
    } catch (e: any) {
      if (e.response?.status === 400) {
        addToast({
          type: 'error',
          title: `${e.response.data?.detail}`,
        });
      }

      throw new Error(e as string);
    }
  },
};

export default HighlighterExerciseService;
