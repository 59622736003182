import { FunctionComponent } from 'react';
import classnames from 'classnames';

import { DialogueContentProps } from '../../PreviewModalProps';
import { AudioController } from './common';
import { getValueByLanguage, openKTag, closedKTag } from '../../../../localization-utils';

import './DialogueContent.scss';

export const DialogueContent: FunctionComponent<DialogueContentProps> = ({
  content,
  deviceType,
  answerState,
  courseLearningLanguage,
}: DialogueContentProps) => {
  const isCorrectSelected = answerState === 1;
  const isIncorrectSelected = answerState === 2;

  return (
    <>
      <AudioController />
      <span className="dialogue-translation">Show translation</span>
      <div className="dialogue-script-container">
        {content.script.map((phrase) => {
          const line = getValueByLanguage(phrase.line.textLocalizations, courseLearningLanguage);
          // All words in line
          const wordsArray =
            line.includes(openKTag.toUpperCase()) || line.includes(closedKTag.toUpperCase())
              ? line
                  ?.split(openKTag.toUpperCase())
                  .map((item: string) => (item.includes(closedKTag.toUpperCase()) ? `!missed!${item}` : item))
                  .reduce((result: string[], item: string) => [...result, ...item.split(closedKTag.toUpperCase())], [])
              : line
                  ?.split(openKTag)
                  .map((item: string) => (item.includes(closedKTag) ? `!missed!${item}` : item))
                  .reduce((result: string[], item: string) => [...result, ...item.split(closedKTag)], []);

          return (
            <div
              className={classnames(
                'dialogue-script',
                { 'dialogue-script--left': !phrase.characterIndex },
                { 'dialogue-script--right': phrase.characterIndex },
              )}
            >
              {!phrase.characterIndex ? (
                <img
                  src={content.characters[phrase.characterIndex].image}
                  className="dialogue-script__photo"
                  alt="User"
                />
              ) : null}
              <div className="dialogue-script__content">
                <span className="dialogue-script__content__name">{content.characters[phrase.characterIndex].name}</span>
                <div className="dialogue-script-text">
                  {wordsArray?.map((word: string) => (
                    <>
                      {word.includes('!missed!') ? (
                        <div
                          className={classnames(
                            'dialogue-script-text__missed-word',
                            { 'dialogue-script-text__missed-word--answered': answerState },
                            { 'dialogue-script-text__missed-word--correct': isCorrectSelected },
                            { 'dialogue-script-text__missed-word--incorrect': isIncorrectSelected },
                          )}
                        >
                          {answerState ? (
                            <span className="dialogue-script-text__word">
                              {isCorrectSelected ? word.replace('!missed!', '') : 'fdhdf'}
                            </span>
                          ) : (
                            <svg className="dialogue-script__empty-place">
                              <rect rx="8px" ry="8px" width="100%" height="100%"></rect>
                            </svg>
                          )}
                        </div>
                      ) : (
                        <>
                          {word
                            .split(' ')
                            .map(
                              (smallWord) =>
                                smallWord && <span className="dialogue-script-text__word">{smallWord}</span>,
                            )}
                        </>
                      )}
                    </>
                  ))}
                </div>
              </div>
              {phrase.characterIndex ? (
                <img
                  src={content.characters[phrase.characterIndex].image}
                  className="dialogue-script__photo"
                  alt="User"
                />
              ) : null}
            </div>
          );
        })}
      </div>
    </>
  );
};
