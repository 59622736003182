import styled from 'styled-components/macro';
import Table from 'react-bootstrap/Table';

import { Pagination } from '@features/theme';
import { Dropdown } from '@components/Dropdown';
import { VoiceArtistsType } from '../../types';
import { MessageItem } from '../../commonStyles';
import { VoiceArtistTableItem } from './VoiceArtistTableItem';
import { TableHeader } from './TableHeader';

const AudioRequestsPagination = styled(Pagination)`
  margin: 3.6rem 0;
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: end;
  gap: 1rem;
`;

const RowNumberContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
`;

const RowNumberCaption = styled.span`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.color.brandGreyscale800};
`;

const defaultRowSizes = [5, 10, 25, 50];

export const TableContent = ({
  voiceArtists,
  currentPage,
  totalPages,
  rowSize,
  onPageChange,
  setRowSize,
}: {
  voiceArtists: VoiceArtistsType[];
  currentPage: number;
  totalPages: number;
  rowSize: number;
  onPageChange: (page: number) => void;
  setRowSize: (rowSize: number) => void;
}) => (
  <Table>
    {voiceArtists.length ? (
      <>
        <TableHeader />
        <tbody>
          {voiceArtists.map((voiceArtist) => (
            <VoiceArtistTableItem voiceArtist={voiceArtist} key={voiceArtist.id} />
          ))}
        </tbody>

        <PaginationContainer>
          <RowNumberContainer>
            <RowNumberCaption>Number of rows:</RowNumberCaption>
            <Dropdown
              id="rowSize"
              values={defaultRowSizes.map(String).map((rowSize) => ({ name: rowSize, code: rowSize }))}
              value={`${rowSize}`}
              title="Rows"
              onChange={(selectedValue) => {
                setRowSize(+selectedValue.code);
              }}
            />
          </RowNumberContainer>
          {totalPages > 1 && (
            <AudioRequestsPagination currentPage={currentPage} totalPages={totalPages} onPageChange={onPageChange} />
          )}
        </PaginationContainer>
      </>
    ) : (
      <MessageItem>No voice artists</MessageItem>
    )}
  </Table>
);
