import ReactTooltip from 'react-tooltip';

import { LockContentButtonWrapper, StyledLockContentIcon } from './styles';

export const LockContentButton = ({ active, onClick }: { active: boolean; onClick: () => void }) => {
  return (
    <>
      <ReactTooltip id="lock-content-tooltip" effect="solid" place="top" />
      <LockContentButtonWrapper
        aria-selected={active}
        data-for="lock-content-tooltip"
        data-tip="Lock/unlock content"
        onClick={onClick}
      >
        <StyledLockContentIcon />
      </LockContentButtonWrapper>
    </>
  );
};
