import { useAppDispatch } from '@redux/store';
import { Button, Modal } from 'react-bootstrap';
import { useHotkeys } from 'react-hotkeys-hook';

import { ContentTypesActionsCreator } from '@actionCreators/ContentTypesActionsCreator';
import { courseEditionAbandoned } from '@actionCreators/BusuuLogoActionCreator';
import { constants } from '@features/content';

import './NavigationWarningModal.scss';

export default interface NavigationWarningModalProps {
  opened: boolean;
  setConfirm: any;
  confirmCallback: any;
}

const { SHORTCUT_KEYS } = constants;

export const NavigationWarningModal = ({ opened, setConfirm, confirmCallback }: NavigationWarningModalProps) => {
  const dispatch = useAppDispatch();

  const allowTransition = () => {
    setConfirm(false);
    confirmCallback(true);
    dispatch(ContentTypesActionsCreator.setSaveButtonState(false));

    dispatch(courseEditionAbandoned());
  };

  const blockTransition = () => {
    setConfirm(false);
    confirmCallback(false);
  };

  useHotkeys(SHORTCUT_KEYS.CONTINUE, () => allowTransition());
  useHotkeys(SHORTCUT_KEYS.KEEP_DRAFT, () => blockTransition());

  return (
    <Modal dialogClassName={'navigation-warning-modal'} centered show={opened} onHide={blockTransition}>
      <Modal.Body>
        <span className="navigation-warning-modal__title">You have unsaved changes</span>
        <span className="navigation-warning-modal__description">
          Please cancel to go back and save these changes, or click continue to lose them.
        </span>
        <div className="navigation-warning-modal__buttons">
          <Button variant="light" onClick={blockTransition}>
            Cancel
          </Button>
          <Button onClick={allowTransition}>Continue</Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
