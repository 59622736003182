import type { Language } from '@features/content/languages';
import { SimpleLanguageSelector } from '@components/SelectorCommonComponents';
import type { CourseInfoInterface } from '@common/interfaces/courses/CourseInfoInterface';

import type { StateConfig } from '../Common/useFiltersState';
import type { StringQueryParamsType } from '../../service/types';
import { FiltersContainer } from '../Common/SearchHeader/FiltersContainer';
import { FilterField, SimpleCourseSelector, SimpleTranslationSelector, SimpleYesNoSelector } from '../Common';

type StringSearchFiltersProps = {
  filtersState: StateConfig<StringQueryParamsType>;
  availableCourses: CourseInfoInterface[];
  stringFiltersPreset?: Partial<StringQueryParamsType>;
  onSaveFilters?: (name: string) => Promise<void>;
};

export const StringSearchFilters = ({
  filtersState,
  availableCourses,
  stringFiltersPreset,
  onSaveFilters,
}: StringSearchFiltersProps) => {
  const filters = [
    <FilterField
      state={filtersState}
      name="languages"
      render={(value, onChange) => <SimpleLanguageSelector value={value} onChange={onChange} />}
    />,
    <FilterField
      state={filtersState}
      name="courses"
      render={(value, onChange) => (
        <SimpleCourseSelector
          courses={availableCourses}
          courseFilter={(course) =>
            filtersState.state.languages === null ||
            filtersState.state.languages.includes(course.learningLanguage as Language)
          }
          value={value}
          onChange={onChange}
        />
      )}
    />,
    <FilterField
      state={filtersState}
      name="translations"
      render={(value, onChange) => (
        <SimpleTranslationSelector rule="Translation" placeholder="Translation" value={value} onChange={onChange} />
      )}
    />,
    <FilterField
      state={filtersState}
      name="audios"
      render={(value, onChange) => (
        <SimpleTranslationSelector rule="Audio" placeholder="Audio" value={value} onChange={onChange} />
      )}
    />,
    stringFiltersPreset?.hasKTags ? (
      <FilterField
        state={filtersState}
        name="hasKTags"
        render={(value, onChange) => (
          <SimpleYesNoSelector placeholder="Has K-Tags" rule="Has K-Tags" value={value} onChange={onChange} />
        )}
      />
    ) : null,
  ];
  const secondaryFilters = [
    {
      filter: (
        <FilterField
          state={filtersState}
          name="isReused"
          render={(value, onChange) => (
            <SimpleYesNoSelector placeholder="Is Reused" rule="Is Reused" value={value} onChange={onChange} />
          )}
        />
      ),
      label: 'Is Reused',
      onRemove: () => filtersState.resetField('isReused'),
    },
    !stringFiltersPreset?.hasKTags
      ? {
          filter: (
            <FilterField
              state={filtersState}
              name="hasKTags"
              render={(value, onChange) => (
                <SimpleYesNoSelector placeholder="Has K-Tags" rule="Has K-Tags" value={value} onChange={onChange} />
              )}
            />
          ),
          label: 'Has K-Tags',
          onRemove: () => filtersState.resetField('hasKTags'),
        }
      : null,
    {
      filter: (
        <FilterField
          state={filtersState}
          name="isDefaultInstruction"
          render={(value, onChange) => (
            <SimpleYesNoSelector
              placeholder="Is Default Instruction"
              rule="Is Default Instruction"
              value={value}
              onChange={onChange}
            />
          )}
        />
      ),
      label: 'Is Default Instruction',
      onRemove: () => filtersState.resetField('isDefaultInstruction'),
    },
  ];

  return (
    <FiltersContainer
      filters={filters.filter((filter) => Boolean(filter)) as NonNullable<(typeof filters)[number]>[]}
      secondaryFilters={
        (secondaryFilters || []).filter((filter) => Boolean(filter)) as NonNullable<(typeof secondaryFilters)[number]>[]
      }
      emptyFilters={filtersState.isEmpty}
      onSaveFilters={onSaveFilters}
    />
  );
};
