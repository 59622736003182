const SAVE = 'SPELLING_RECOGNITION_EXERCISE_SAVE';
const SET_INSTRUCTIONS_LANGUAGE = 'SPELLING_SET_INSTRUCTIONS_LANGUAGE';
const UPLOAD_IMAGE = 'SPELLING_UPLOAD_IMAGE';
const REMOVE_IMAGE = 'SPELLING_REMOVE_IMAGE';
const REMOVE_AUDIO = 'SPELLING_REMOVE_AUDIO';
const SET_SPELLING_ALL_FIELDS = 'SET_SPELLING_ALL_FIELDS';

export const SpellingExerciseActions = {
  SAVE,
  SET_INSTRUCTIONS_LANGUAGE,
  UPLOAD_IMAGE,
  REMOVE_IMAGE,
  REMOVE_AUDIO,
  SET_SPELLING_ALL_FIELDS,
} as const;
