import styled from 'styled-components/macro';

import { CommonFilterOptionType, CommonFilterSelector } from '@components/SelectorCommonComponents';

const StyledSelector = styled(CommonFilterSelector)`
  .gender-selector__value-container {
    height: 4.2rem !important;
  }
`;

const CLASSNAME_PREFIX = 'gender-selector';

const GENDER_OPTIONS = [
  { value: 'female', label: 'Female' },
  { value: 'male', label: 'Male' },
  { value: 'non-binary', label: 'Other' },
];

export const GenderSelector = ({
  selectedGender,
  onChange,
}: {
  selectedGender: CommonFilterOptionType | null;
  onChange: (option: CommonFilterOptionType | null) => void;
}) => {
  return (
    <StyledSelector
      classNamePrefix={CLASSNAME_PREFIX}
      placeholder="Gender"
      value={selectedGender}
      options={GENDER_OPTIONS}
      onChange={onChange}
    />
  );
};
