import { useState } from 'react';

import { Modal, useDialogModal } from '@features/modal';
import { OtherLocationsInterface } from '@common/interfaces/exercises/OtherLocationsInterface';
import TranslationsPanelService from '@services/TranslationsPanelService';
import { OtherPlacesDisplayer } from './OtherPlacesDisplayer';
import { OtherPlacesModal } from './OtherPlacesModal';

export type ComponentOtherPlacesDisplayerProps = {
  className?: string;
  mappings: number;
  mappingsPath: OtherLocationsInterface[][];
  isResourceBundle?: boolean;
  bundleId?: string;
};

export const ComponentOtherPlacesDisplayer = ({
  className,
  isResourceBundle = false,
  mappings,
  mappingsPath,
  bundleId,
}: ComponentOtherPlacesDisplayerProps) => {
  const [mappingsDetails, setMappingsDetails] = useState<OtherLocationsInterface[][]>([]);

  const { open: openOtherPlacesModal, modal: otherPlacesModal } = useDialogModal((modalControls) => (
    <Modal lockScroll size="M" {...modalControls}>
      <OtherPlacesModal
        close={() => {
          setMappingsDetails([]);
          modalControls.close();
        }}
        mappings={mappings}
        mappingsDetails={mappingsDetails}
      />
    </Modal>
  ));

  if (mappings <= 1) return null;

  return (
    <>
      <OtherPlacesDisplayer
        className={className}
        openModal={() => {
          openOtherPlacesModal();
          if (isResourceBundle && bundleId) {
            TranslationsPanelService.getOtherLocationsForBundle(bundleId).then(({ data }) => {
              setMappingsDetails(data);
            });
          } else {
            setMappingsDetails(mappingsPath);
          }
        }}
        isResourceBundle={isResourceBundle}
        mappings={mappings}
      />
      {otherPlacesModal}
    </>
  );
};
