import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

import { DBId } from '@common/types/DBId';
import { SearchBox } from '@components/Search';
import { Modal } from 'react-bootstrap';
import { AvailableLessonsForTopic } from '../types';
import filterIcon from '../img/filter.svg';
import { LinkedLessonsModalFooter } from './LinkedLessonsModalFooter';
import { LinkedLessonsList } from './LinkedLessonsList';
import { GrammarReviewActionCreators } from '@actionCreators/GrammarReviewActionCreators';
import type { LoadingStageType } from '@common/enums/LoadingStage';
import { useAppDispatch } from '@redux/store';

const LinkedModal = styled(Modal)`
  .modal-dialog {
    max-width: 940px;
  }
  .modal-content {
    height: 730px;
    border-radius: 16px;
  }
`;

const LinkedModalBody = styled(Modal.Body)`
  padding: 40px;
`;

const LinkedModalContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const ModalTitle = styled.span`
  font-weight: 800;
  font-size: 20px;
  color: ${({ theme }) => theme.colorV2.grammarTopicLinkedLessonsModalTitleText};
  margin-bottom: 24px;
`;

const LinkedModalFilters = styled.div`
  width: 100%;
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LinkedModalLevel = styled.span<{ selected: boolean }>`
  font-weight: 400;
  font-size: 14px;
  border-radius: 12px;
  padding: 8px 10px;
  margin-right: 12px;
  text-transform: uppercase;
  transition: all ease 0.2s;
  &:hover {
    cursor: pointer;
    background: ${({ theme }) => theme.colorV2.grammarTopicLinkedLessonsModalLevelHoverBackground};
  }
  ${({ selected, theme }) =>
    selected &&
    `
      background: ${theme.colorV2.grammarTopicLinkedLessonsModalLevelSelectedHoverBackground};
      color: ${theme.colorV2.grammarTopicLinkedLessonsModalLevelSelectedHoverText};
    `}
`;

const LinkedModalSearch = styled(SearchBox)`
  width: 320px;
  height: 40px;
  background: ${({ theme }) => theme.colorV2.grammarTopicLinkedLessonsModalSearchBackground};
  border: 1px solid ${({ theme }) => theme.colorV2.grammarTopicLinkedLessonsModalSearchBorder};
  border-radius: 8px;

  .search-box__filter {
    padding-left: 5px;
    background: ${({ theme }) => theme.colorV2.grammarTopicLinkedLessonsModalSearchBackground};
  }
`;

export const LinkedLessonsModal = ({
  opened,
  onClose,
  selectedLessons,
  isAvailableLessonsLoaded,
  saveProgress,
  availableLessons,
}: {
  opened: boolean;
  onClose: () => void;
  isAvailableLessonsLoaded: boolean;
  selectedLessons: DBId[];
  saveProgress: LoadingStageType;
  availableLessons: AvailableLessonsForTopic[];
}) => {
  const dispatch = useAppDispatch();
  const { courseId, topicId } = useParams<{ courseId: DBId; topicId: DBId }>();
  const [selectedLevel, setSelectedLevel] = useState<string>(availableLessons[0]?.id || '');
  const [currentSelectedLessons, setCurrentSelectedLessons] = useState(selectedLessons);
  const [isChanged, setIsChanged] = useState(false);
  const [filteredName, setFilteredName] = useState('');

  useEffect(() => {
    if (!opened) {
      setSelectedLevel('');
      setFilteredName('');
      setIsChanged(false);
      setCurrentSelectedLessons(selectedLessons);
    }
  }, [opened, selectedLessons]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting === true && entries[0].target) {
          setSelectedLevel(entries[0].target.id);
        }
      },
      { threshold: [0] },
    );
    availableLessons.forEach((lessonsData) => {
      const selectedLevelElem = document.getElementById(lessonsData.id);
      if (selectedLevelElem) {
        observer.observe(selectedLevelElem);
      }
    });
  }, [availableLessons, opened]);

  return (
    <LinkedModal show={opened} centered onHide={onClose}>
      <LinkedModalBody data-testid="grammar-lessons-modal-body">
        <LinkedModalContent>
          <ModalTitle>Choose grammar lessons, which you want to link to this topic</ModalTitle>
          <LinkedModalFilters>
            <div>
              {availableLessons.map((lessonsData) => (
                <LinkedModalLevel
                  key={lessonsData.id}
                  selected={lessonsData.id === selectedLevel}
                  onClick={() => {
                    const selectedLevelElem = document.getElementById(lessonsData.id);
                    if (selectedLevelElem) {
                      selectedLevelElem.scrollIntoView();
                    }
                  }}
                >
                  {lessonsData.cefr}
                </LinkedModalLevel>
              ))}
            </div>
            <LinkedModalSearch
              filterTerm={filteredName}
              onSearchChange={(value: string) => setFilteredName(value)}
              placeholder="Filter by name"
              inputIcon={filterIcon}
            />
          </LinkedModalFilters>
          <LinkedLessonsList
            isNotLoadedYet={!isAvailableLessonsLoaded && opened}
            availableLessons={availableLessons.map((lessonsData) => ({
              ...lessonsData,
              lessons: lessonsData.lessons.filter((lesson) =>
                filteredName
                  ? lesson.title?.includes(filteredName) || lesson.title?.toLowerCase()?.includes(filteredName)
                  : true,
              ),
            }))}
            currentTopicId={topicId}
            currentSelectedLessons={currentSelectedLessons}
            onLessonToggle={(newValue: boolean, lessonId: DBId) => {
              if (newValue) {
                setCurrentSelectedLessons([...currentSelectedLessons, lessonId]);
              } else {
                setCurrentSelectedLessons(currentSelectedLessons.filter((oldLessonId) => oldLessonId !== lessonId));
              }
              setIsChanged(true);
            }}
          />
          <LinkedLessonsModalFooter
            onClose={onClose}
            saveProgress={saveProgress}
            onSave={() => {
              const lessonsToAttach = currentSelectedLessons.filter(
                (currentSelectedLesson) => !selectedLessons.includes(currentSelectedLesson),
              );
              const lessonsToDetach = selectedLessons.filter(
                (selectedLesson) => !currentSelectedLessons.includes(selectedLesson),
              );
              dispatch(
                GrammarReviewActionCreators.attachLessonsToTopic(
                  courseId,
                  topicId,
                  currentSelectedLessons,
                  lessonsToAttach,
                  lessonsToDetach,
                ),
              );
            }}
            isChanged={isChanged}
          />
        </LinkedModalContent>
      </LinkedModalBody>
    </LinkedModal>
  );
};
