import { ExerciseService } from '@common/types/ExerciseService';
import ExerciseDataModelAssembler from '@components/Exercises/ExerciseDataModelAssembler';
import { ExerciseCommonActionCreators as ExerciseCommonActions } from '@actionCreators/ExerciseCommonActionCreator';
import { FormikValuesInterface } from '@helpers/formikInitialValuesHelper';
import MatchUpExerciseInterface from '@components/Exercises/MatchUp/interfaces/MatchUpExerciseInterface';
import ExercisesService from '@services/ExercisesService';
import { AppDispatch } from '@redux/store';
import { clone } from '@helpers/clone';
import { editMatchupExercise } from '@services/exercises/editExerciseService';
import { EditMatchupExerciseRequest, DisplayedLanguageType } from '@services/exercises/editExerciseTypes';
import { addToast } from '@features/app/toast';

type MatchUpExerciseServiceType = ExerciseService<MatchUpExerciseInterface>;

const MatchUpExerciseService: MatchUpExerciseServiceType = {
  ensureExerciseFieldsAreReadyForUse(
    exerciseAndEmptyLocalizationBranchesPayload: any,
    exercise: MatchUpExerciseInterface,
  ) {
    let output = clone(exerciseAndEmptyLocalizationBranchesPayload);

    return ExerciseDataModelAssembler.ensureFieldIsReadyForUse(
      exercise,
      output,
      [
        'instructions',
        'fixedItem1',
        'matchingItem1',
        'fixedItem2',
        'matchingItem2',
        'fixedItem3',
        'matchingItem3',
        'feedback',
      ],
      exerciseAndEmptyLocalizationBranchesPayload,
    );
  },
  async save(dispatch: AppDispatch, exercise: MatchUpExerciseInterface, values: FormikValuesInterface) {
    try {
      let payload: EditMatchupExerciseRequest = {
        instructionsLanguage: exercise.content.instructionsLanguage as DisplayedLanguageType,
        matchingItemsLanguage: exercise.content.matchingItemsLanguage,
        recapExerciseId: exercise.content.recapExerciseId,
        experiment: exercise.content.experiment,
      };

      const updatePayload = await Promise.all([
        (values.instructionsChanged || exercise.content.instructions.changed) && !exercise.content.instructions.isReused
          ? ExercisesService.misc.saveField(dispatch, 'instructions', exercise, payload)
          : { instructions: exercise.content.instructions._id },
        (values.feedbackChanged || exercise.content.feedback?.changed || exercise.content.feedbackChanged) &&
        !exercise.content.feedback?.isReused
          ? ExercisesService.misc.saveField(dispatch, 'feedback', exercise, payload)
          : { feedback: exercise.content.feedback?._id || null },
      ]);
      const pairs = await Promise.all([
        values.fixedItem1_phraseChanged ||
        exercise.content?.fixedItem1?.phrase?.changed ||
        exercise.content?.fixedItem1Changed ||
        exercise.content?.fixedItem1?.phrase?.isReused
          ? ExercisesService.misc.saveBundle(dispatch, 'fixedItem1', exercise, {}, values)
          : { fixedItem1: exercise.content?.fixedItem1?._id || null },
        values.matchingItem1_phraseChanged ||
        exercise.content?.matchingItem1?.phrase?.changed ||
        exercise.content?.matchingItem1Changed ||
        exercise.content?.matchingItem1?.phrase?.isReused
          ? ExercisesService.misc.saveBundle(dispatch, 'matchingItem1', exercise, {}, values)
          : { matchingItem1: exercise.content?.matchingItem1?._id || null },
        values.fixedItem2_phraseChanged ||
        exercise.content?.fixedItem2?.phrase?.changed ||
        exercise.content?.fixedItem2Changed ||
        exercise.content?.fixedItem2?.phrase?.isReused
          ? ExercisesService.misc.saveBundle(dispatch, 'fixedItem2', exercise, {}, values)
          : { fixedItem2: exercise.content?.fixedItem2?._id || null },
        values.matchingItem2_phraseChanged ||
        exercise.content?.matchingItem2?.phrase?.changed ||
        exercise.content?.matchingItem2Changed ||
        exercise.content?.matchingItem2?.phrase?.isReused
          ? ExercisesService.misc.saveBundle(dispatch, 'matchingItem2', exercise, {}, values)
          : { matchingItem2: exercise.content?.matchingItem2?._id || null },
        values.fixedItem3_phraseChanged ||
        exercise.content?.fixedItem3?.phrase?.changed ||
        exercise.content?.fixedItem3Changed ||
        exercise.content?.fixedItem3?.phrase?.isReused
          ? ExercisesService.misc.saveBundle(dispatch, 'fixedItem3', exercise, {}, values)
          : { fixedItem3: exercise.content?.fixedItem3?._id || null },
        values.matchingItem3_phraseChanged ||
        exercise.content?.matchingItem3?.phrase?.changed ||
        exercise.content?.matchingItem3Changed ||
        exercise.content?.matchingItem3?.phrase?.isReused
          ? ExercisesService.misc.saveBundle(dispatch, 'matchingItem3', exercise, {}, values)
          : { matchingItem3: exercise.content?.matchingItem3?._id || null },
      ]);

      const pairsPayload = pairs.reduce(
        (sum: { [key: string]: string | null }, item: { [key: string]: string | null }) => ({ ...sum, ...item }),
        {},
      );

      payload = {
        ...payload,
        ...updatePayload.reduce((sum: any, item: any) => ({ ...sum, ...item }), {}),
        pairs: [
          { fixedItem: pairsPayload.fixedItem1, matchingItem: pairsPayload.matchingItem1 },
          { fixedItem: pairsPayload.fixedItem2, matchingItem: pairsPayload.matchingItem2 },
          { fixedItem: pairsPayload.fixedItem3, matchingItem: pairsPayload.matchingItem3 },
        ],
      };

      if (exercise.content.id) {
        await editMatchupExercise(exercise.content.id, payload);

        dispatch(ExerciseCommonActions.setSaveProgress({ value: false, updateData: true }));

        addToast({
          type: 'success',
          title: 'This exercise has been saved',
        });
      }
    } catch (e: any) {
      if (e.response?.status === 400) {
        addToast({
          type: 'error',
          title: `${e.response.data?.detail}`,
        });
      }

      throw new Error(e as string);
    }
  },
};

export default MatchUpExerciseService;
