import { LocalizationInterface } from '@common/interfaces/localization/LocalizationInterface';
import { CourseInterface } from '@common/interfaces/courses/CourseInterface';
import { GenericContentInterface } from '@common/interfaces/contentTypes/GenericContentInterface';
import { AnyExerciseInterface } from '@common/types/exercises/AnyExerciseInterface';
import { Language } from '@features/content/languages';
import { ExerciseTypes } from '@common/enums/ExerciseTypes';
import { ContentTypes } from '@common/enums/ContentTypes';
import { ALL_LANGUAGES_UPPERCASE } from '@features/content/languages';

export const processLocalizationsForFormikValues = (localizations?: LocalizationInterface[]) => {
  if (localizations?.length) {
    return [
      ...localizations.map((loc: LocalizationInterface) => ({
        language: loc.language,
        value: loc.value,
        isPhonetic: false,
      })),
      ...ALL_LANGUAGES_UPPERCASE.filter(
        (language) => !localizations.map((lang) => lang.language).includes(language),
      ).map((language) => ({
        language,
        value: '',
        isPhonetic: false,
      })),
      ...localizations.map((loc: LocalizationInterface) => ({
        language: loc.language,
        value: loc.phoneticValue || '',
        isPhonetic: true,
      })),
      ...ALL_LANGUAGES_UPPERCASE.filter(
        (language) => !localizations.map((lang) => lang.language).includes(language),
      ).map((language) => ({
        language,
        value: '',
        isPhonetic: true,
      })),
    ];
  }
  return [
    ...ALL_LANGUAGES_UPPERCASE.map((language) => ({
      language,
      value: '',
      isPhonetic: false,
    })),
    ...ALL_LANGUAGES_UPPERCASE.map((language) => ({
      language,
      value: '',
      isPhonetic: true,
    })),
  ];
};

export const getInitialValues = ({
  isCourse,
  course,
  isLevel,
  isChapter,
  isLesson,
  group,
  isExercise,
  exercise,
}: {
  isCourse: boolean;
  course: CourseInterface;
  isLevel: boolean;
  isChapter: boolean;
  isLesson: boolean;
  group: GenericContentInterface;
  isExercise: boolean;
  exercise?: AnyExerciseInterface;
}) => {
  if (isCourse) {
    return {
      titleWithLocalizations: processLocalizationsForFormikValues(course?.titleWithLocalizations?.textLocalizations),
      descriptionWithLocalizations: processLocalizationsForFormikValues(
        course?.descriptionWithLocalizations?.textLocalizations,
      ),
    };
  } else if (isLevel || isChapter) {
    return {
      titleWithLocalizations: processLocalizationsForFormikValues(group?.titleWithLocalizations?.textLocalizations),
    };
  } else if (isLesson) {
    const lessonInitialValues: {
      titleWithLocalizations: ReturnType<typeof processLocalizationsForFormikValues>;
      descriptionWithLocalizations: ReturnType<typeof processLocalizationsForFormikValues>;
      learningOutcomes: ReturnType<typeof processLocalizationsForFormikValues>;
      completeMessage?: ReturnType<typeof processLocalizationsForFormikValues>;
    } = {
      titleWithLocalizations: processLocalizationsForFormikValues(group?.titleWithLocalizations?.textLocalizations),
      descriptionWithLocalizations: processLocalizationsForFormikValues(
        group?.descriptionWithLocalizations?.textLocalizations,
      ),
      learningOutcomes: processLocalizationsForFormikValues(group?.learningOutcomes?.textLocalizations),
    };

    if (group?.lessonType === ContentTypes.checkpoint) {
      lessonInitialValues.completeMessage = processLocalizationsForFormikValues(
        group?.completeMessage?.textLocalizations,
      );
    }

    return lessonInitialValues;
  } else if (isExercise) {
    return getInitialValuesForExercise(exercise);
  } else {
    return {};
  }
};

export const getInitialValuesForExercise = (exercise?: AnyExerciseInterface) => {
  switch (exercise?.type) {
    case ExerciseTypes.flashcard:
      return {
        instructions: processLocalizationsForFormikValues(exercise?.content?.instructions?.textLocalizations),
        learningWordBundle_phrase: processLocalizationsForFormikValues(
          exercise?.content?.learningWordBundle?.phrase?.textLocalizations,
        ),
        learningWordBundle_example: processLocalizationsForFormikValues(
          exercise?.content?.learningWordBundle?.example?.textLocalizations,
        ),
      };
    case ExerciseTypes.spelling:
    case ExerciseTypes.speechRecognition:
    case ExerciseTypes.phraseBuilder:
      return {
        instructions: processLocalizationsForFormikValues(exercise?.content?.instructions?.textLocalizations),
        mainBundle_phrase: processLocalizationsForFormikValues(
          exercise?.content?.mainBundle?.phrase?.textLocalizations,
        ),
        feedback: processLocalizationsForFormikValues(exercise?.content?.feedback?.textLocalizations),
      };
    case ExerciseTypes.typing:
      return {
        instructions: processLocalizationsForFormikValues(exercise?.content?.instructions?.textLocalizations),
        mainBundle_phrase: processLocalizationsForFormikValues(
          exercise?.content?.mainBundle?.phrase?.textLocalizations,
        ),
        hint: processLocalizationsForFormikValues(exercise?.content?.hint?.textLocalizations),
        feedback: processLocalizationsForFormikValues(exercise?.content?.feedback?.textLocalizations),
      };
    case ExerciseTypes.conversation:
      return {
        instructions: processLocalizationsForFormikValues(exercise?.content?.instructions?.textLocalizations),
        hint: processLocalizationsForFormikValues(exercise?.content?.hint?.textLocalizations),
      };
    case ExerciseTypes.multipleChoice:
      return {
        instructions: processLocalizationsForFormikValues(exercise?.content?.instructions?.textLocalizations),
        answerBundle_phrase: processLocalizationsForFormikValues(
          exercise?.content?.answerBundle?.phrase?.textLocalizations,
        ),
        distractor1_phrase: processLocalizationsForFormikValues(
          exercise?.content?.distractor1?.phrase?.textLocalizations,
        ),
        distractor2_phrase: processLocalizationsForFormikValues(
          exercise?.content?.distractor2?.phrase?.textLocalizations,
        ),
        feedback: processLocalizationsForFormikValues(exercise?.content?.feedback?.textLocalizations),
      };
    case ExerciseTypes.trueFalse:
      return {
        instructions: processLocalizationsForFormikValues(exercise?.content?.instructions?.textLocalizations),
        mainBundle_phrase: processLocalizationsForFormikValues(
          exercise?.content?.mainBundle?.phrase?.textLocalizations,
        ),
        statement: processLocalizationsForFormikValues(exercise?.content?.statement?.textLocalizations),
        feedback: processLocalizationsForFormikValues(exercise?.content?.feedback?.textLocalizations),
      };
    case ExerciseTypes.matchup:
      return {
        instructions: processLocalizationsForFormikValues(exercise?.content?.instructions?.textLocalizations),
        fixedItem1_phrase: processLocalizationsForFormikValues(
          exercise?.content?.fixedItem1?.phrase?.textLocalizations,
        ),
        fixedItem2_phrase: processLocalizationsForFormikValues(
          exercise?.content?.fixedItem2?.phrase?.textLocalizations,
        ),
        fixedItem3_phrase: processLocalizationsForFormikValues(
          exercise?.content?.fixedItem3?.phrase?.textLocalizations,
        ),
        matchingItem1_phrase: processLocalizationsForFormikValues(
          exercise?.content?.matchingItem1?.phrase?.textLocalizations,
        ),
        matchingItem2_phrase: processLocalizationsForFormikValues(
          exercise?.content?.matchingItem2?.phrase?.textLocalizations,
        ),
        matchingItem3_phrase: processLocalizationsForFormikValues(
          exercise?.content?.matchingItem3?.phrase?.textLocalizations,
        ),
        feedback: processLocalizationsForFormikValues(exercise?.content?.feedback?.textLocalizations),
      };
    case ExerciseTypes.highlighter:
      return {
        instructions: processLocalizationsForFormikValues(exercise?.content?.instructions?.textLocalizations),
        feedback: processLocalizationsForFormikValues(exercise?.content?.feedback?.textLocalizations),
        phrase0: processLocalizationsForFormikValues(exercise?.content?.phrases?.[0]?.textLocalizations),
        phrase1: processLocalizationsForFormikValues(exercise?.content?.phrases?.[1]?.textLocalizations),
        phrase2: processLocalizationsForFormikValues(exercise?.content?.phrases?.[2]?.textLocalizations),
        phrase3: processLocalizationsForFormikValues(exercise?.content?.phrases?.[3]?.textLocalizations),
      };
    case ExerciseTypes.fillgap:
      return {
        instructions: processLocalizationsForFormikValues(exercise?.content?.instructions?.textLocalizations),
        mainBundle_phrase: processLocalizationsForFormikValues(
          exercise?.content?.mainBundle?.phrase?.textLocalizations,
        ),
        distractor1_phrase: processLocalizationsForFormikValues(
          exercise?.content?.distractor1?.phrase?.textLocalizations,
        ),
        distractor2_phrase: processLocalizationsForFormikValues(
          exercise?.content?.distractor2?.phrase?.textLocalizations,
        ),
        feedback: processLocalizationsForFormikValues(exercise?.content?.feedback?.textLocalizations),
      };
    case ExerciseTypes.tip:
      return {
        instructions: processLocalizationsForFormikValues(exercise?.content?.instructions?.textLocalizations),
        paragraph: processLocalizationsForFormikValues(exercise?.content?.paragraph?.textLocalizations),
      };
    case ExerciseTypes.comprehension:
      return {
        instructions: processLocalizationsForFormikValues(exercise?.content?.instructions?.textLocalizations),
        mainBundle_phrase: processLocalizationsForFormikValues(
          exercise?.content?.mainBundle?.phrase?.textLocalizations,
        ),
        title: processLocalizationsForFormikValues(exercise?.content?.title?.textLocalizations),
        provider: exercise?.content?.provider,
      };
    case ExerciseTypes.dialogue:
      return {
        instructions: processLocalizationsForFormikValues(exercise?.content?.instructions?.textLocalizations),
        dialogueDescription: processLocalizationsForFormikValues(
          exercise?.content?.dialogueDescription?.textLocalizations,
        ),
      };
    case ExerciseTypes.listenRepeat:
      return {
        instructions: processLocalizationsForFormikValues(exercise?.content?.instructions?.textLocalizations),
        mainBundle_phrase: processLocalizationsForFormikValues(
          exercise?.content?.mainBundle?.phrase?.textLocalizations,
        ),
      };

    default:
      return {};
  }
};

export interface FormikValueInterface {
  language: Language;
  value: string;
  isPhonetic: boolean;
}

export interface FormikValuesInterface {
  titleWithLocalizations?: FormikValueInterface[];
  descriptionWithLocalizations?: FormikValueInterface[];
  instructions?: FormikValueInterface[];
  learningWordBundle_phrase?: FormikValueInterface[];
  learningWordBundle_example?: FormikValueInterface[];
  mainBundle_phrase?: FormikValueInterface[];
  feedback?: FormikValueInterface[];
  hint?: FormikValueInterface[];
  answerBundle_phrase?: FormikValueInterface[];
  distractor1_phrase?: FormikValueInterface[];
  distractor2_phrase?: FormikValueInterface[];
  statement?: FormikValueInterface[];
  fixedItem1_phrase?: FormikValueInterface[];
  fixedItem2_phrase?: FormikValueInterface[];
  fixedItem3_phrase?: FormikValueInterface[];
  matchingItem1_phrase?: FormikValueInterface[];
  matchingItem2_phrase?: FormikValueInterface[];
  matchingItem3_phrase?: FormikValueInterface[];
  paragraph?: FormikValueInterface[];
  title?: FormikValueInterface[];
  provider?: string;
  dialogueDescription?: FormikValueInterface[];
  learningOutcomes?: FormikValueInterface[];
  completeMessage?: FormikValueInterface[];
  cefr?: string;
  chatbotGoal?: string;
  chatbotRole?: string;
  chatbotScenario?: string;
  triggerMessages?: string[];
  grammar?: string;
  messageLimit?: number;
  sampleDialogue?: string;
  vocabulary?: string;
  voiceAccent?: string;
  voiceGender?: string;

  titleWithLocalizationsChanged?: boolean;
  descriptionWithLocalizationsChanged?: boolean;
  instructionsChanged?: boolean;
  learningWordBundle_phraseChanged?: boolean;
  learningWordBundle_exampleChanged?: boolean;
  mainBundle_phraseChanged?: boolean;
  feedbackChanged?: boolean;
  hintChanged?: boolean;
  answerBundle_phraseChanged?: boolean;
  distractor1_phraseChanged?: boolean;
  distractor2_phraseChanged?: boolean;
  statementChanged?: boolean;
  feedback_phraseChanged?: boolean;
  fixedItem1_phraseChanged?: boolean;
  fixedItem2_phraseChanged?: boolean;
  fixedItem3_phraseChanged?: boolean;
  matchingItem1_phraseChanged?: boolean;
  matchingItem2_phraseChanged?: boolean;
  matchingItem3_phraseChanged?: boolean;
  paragraphChanged?: boolean;
  titleChanged?: boolean;
  title_phraseChanged?: boolean;
  title_exampleChanged?: boolean;
  providerChanged?: boolean;
  dialogueDescriptionChanged?: boolean;
  learningOutcomesChanged?: boolean;
  completeMessageChanged?: boolean;
  statement_phraseChanged?: boolean;
  cefrChanged?: boolean;
  chatbotGoalChanged?: boolean;
  chatbotRoleChanged?: boolean;
  chatbotScenarioChanged?: boolean;
  triggerMessagesChanged?: boolean;
  grammarChanged?: boolean;
  messageLimitChanged?: boolean;
  sampleDialogueChanged?: boolean;
  vocabularyChanged?: boolean;
  voiceAccentChanged?: boolean;
  voiceGenderChanged?: boolean;
}
