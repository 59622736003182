import { useState } from 'react';
import styled from 'styled-components/macro';

import { UserArchivalModal } from '@features/users';
import { Language } from '@features/content/languages';
import { VoiceArtistsType } from '../../../types';
import { updateVoiceArtist, archiveVoiceArtist } from '../../../_service';
import { EditPanel } from './EditPanel';
import { ReactComponent as EditIcon } from './img/edit.svg';
import { ReactComponent as RemoveIcon } from './img/remove.svg';
import { LanguagesContainer } from './LanguagesContainer';
import { AccentContainer } from './AccentContainer';
import { useToast } from '@features/app/toast';

const TableRow = styled.tr<{ isEditMode: boolean }>`
  height: 5.6rem;
  font-size: 1.6rem;
  box-shadow: 0 0.1rem 0 ${({ theme }) => theme.color.brandGreyscale600};
  display: grid;
  grid-template-columns: ${({ isEditMode }) => (isEditMode ? '20% 15% 20% 21% 20%' : '20% 15% 20% 34% 3% 3%')};
  grid-column-gap: 1%;
  align-items: center;
`;

const TableItem = styled.td`
  height: 100%;
  border: none !important;
  display: flex;
  align-items: center;
`;

const ButtonItem = styled(TableItem)`
  cursor: pointer;
`;

export const VoiceArtistTableItem = ({ voiceArtist }: { voiceArtist: VoiceArtistsType }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [saveInProgress, setSaveInProgress] = useState(false);

  const [accent, setAccent] = useState<string>(voiceArtist.accent);
  const [languages, setLanguages] = useState<Language[]>(voiceArtist.languages);

  const [isArchiveConfirmationDisplayed, setArchiveConfirmationDisplayed] = useState(false);

  const showToast = useToast();

  const onCancel = () => {
    setAccent(voiceArtist.accent);
    setLanguages(voiceArtist.languages);
    setIsEditMode(false);
  };

  const onSave = () => {
    setSaveInProgress(true);

    updateVoiceArtist(voiceArtist.id, { languages: languages as Language[], accent })
      .then(() => {
        setIsEditMode(false);

        showToast({
          type: 'success',
          title: 'Your changes were applied',
        });
      })
      .catch((e) =>
        showToast({
          type: 'error',
          title: e?.response?.data?.detail || 'Try again later',
        }),
      )
      .finally(() => setSaveInProgress(false));
  };

  const onArchive = () => {
    setSaveInProgress(true);
    archiveVoiceArtist(voiceArtist.id)
      .then(() => {
        showToast({
          type: 'success',
          title: 'Voice artist archived',
        });
      })
      .catch((e) =>
        showToast({
          type: 'error',
          title: e?.response?.data?.detail || 'Try again later',
        }),
      )
      .finally(() => {
        setSaveInProgress(false);
      });
  };

  return (
    <TableRow isEditMode={isEditMode}>
      <UserArchivalModal
        opened={isArchiveConfirmationDisplayed}
        onArchive={onArchive}
        onClose={() => setArchiveConfirmationDisplayed(false)}
      />
      <TableItem title={voiceArtist.name}>{voiceArtist.name}</TableItem>
      <TableItem title={voiceArtist.gender}>{voiceArtist.gender}</TableItem>
      <TableItem>
        <LanguagesContainer isEditMode={isEditMode} languages={languages} setLanguages={setLanguages} />
      </TableItem>
      <TableItem title={voiceArtist.accent}>
        <AccentContainer isEditMode={isEditMode} accent={accent} setAccent={setAccent} />
      </TableItem>

      {isEditMode ? (
        <EditPanel
          saveInProgress={saveInProgress}
          languages={languages as Language[]}
          onCancel={onCancel}
          onSave={onSave}
        />
      ) : (
        <>
          <ButtonItem onClick={() => setIsEditMode(true)}>
            <EditIcon />
          </ButtonItem>
          <ButtonItem onClick={() => setArchiveConfirmationDisplayed(true)}>
            <RemoveIcon />
          </ButtonItem>
        </>
      )}
    </TableRow>
  );
};
