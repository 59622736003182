import styled from 'styled-components';

import { HeaderWrapper } from './HeaderWrapper';

const LessonHeaderWrapper = styled(HeaderWrapper)`
  grid-template-columns: 48% 12% 12% 25%;
`;

export const LessonSearchResultTypeHeader = () => (
  <LessonHeaderWrapper>
    <span>Lesson</span>
    <span>Type</span>
    <span>Focus</span>
    <span>Location</span>
  </LessonHeaderWrapper>
);
