import { Route, Switch } from 'react-router-dom';

import { RolesDescription } from '@components/RolesAndPermissions/RolesDescription/RolesDescription';
import { AdminSettings } from '@components/RolesAndPermissions/AdminSettings/AdminSettings';
import { PersonalInfo } from '@components/RolesAndPermissions/PersonalInfo/PersonalInfo';
import { RedirectFromOldURL } from '@components/RedirectFromOldURL/RedirectFromOldURL';
import { SecureRoute, LoginCallback, LogoutCallback } from '@features/auth';
import { CourseStatsPage } from '@features/courseStats';
import { ProgressMatchingPage } from '@features/progressMatching';
import { AudioManagementPage } from '@features/audioManagement';
import { DashboardPage } from '@features/dashboard';
import {
  GrammarContent,
  GrammarReviewContent,
  GrammarCategoryContent,
  GrammarTopicContent,
  GrammarExerciseContent,
} from '@features/content/grammar';
import { ContentMappingsPage } from '@features/content/contentMappings';
import {
  EntrypointContent,
  PlacementTestContainer,
  PlacementTestContent,
  PlacementExerciseContent,
} from '@features/content/placementTest';
import {
  RoleplayCategoryContent,
  RoleplayContent,
  RoleplayPage,
  RoleplayScenarioContent,
} from '@features/content/roleplay';
import { LexicalItemContent, VocabularyReviewContent, VocabularyReviewPage } from '@features/content/vocabularyReview';

import { ErrorPage } from './errors/ErrorPage';
import { MainContent } from './MainContent';
import { CSATModal } from '@features/csat';

export const Routes = () => (
  <div className="App">
    <CSATModal />
    <Switch>
      <Route path="/login/callback" exact>
        <LoginCallback />
      </Route>
      <Route path="/logout" exact>
        <LogoutCallback />
      </Route>

      <SecureRoute path="/" exact>
        <DashboardPage />
      </SecureRoute>

      <SecureRoute path={['/personal-info/:userId', '/personal-info']}>
        <PersonalInfo />
      </SecureRoute>

      <SecureRoute path="/admin-settings">
        <AdminSettings />
      </SecureRoute>

      <SecureRoute path="/roles-description">
        <RolesDescription />
      </SecureRoute>

      <SecureRoute exact path="/content/:contentId">
        <ContentMappingsPage />
      </SecureRoute>

      <SecureRoute exact path="/course-stats/:languageId?/:courseId?">
        <CourseStatsPage />
      </SecureRoute>

      <SecureRoute exact path="/progress-matching">
        <ProgressMatchingPage />
      </SecureRoute>

      <SecureRoute exact path="/audio-management">
        <AudioManagementPage />
      </SecureRoute>

      <SecureRoute exact path="/course/:courseId/grammar-review">
        <GrammarContent>
          <GrammarReviewContent />
        </GrammarContent>
      </SecureRoute>
      <SecureRoute exact path="/course/:courseId/grammar-review/category/:categoryId">
        <GrammarContent>
          <GrammarCategoryContent />
        </GrammarContent>
      </SecureRoute>
      <SecureRoute exact path="/course/:courseId/grammar-review/category/:categoryId/topic/:topicId">
        <GrammarContent>
          <GrammarTopicContent />
        </GrammarContent>
      </SecureRoute>
      <SecureRoute
        exact
        path="/course/:courseId/grammar-review/category/:categoryId/topic/:topicId/exercise/:exerciseId"
      >
        <GrammarContent>
          <GrammarExerciseContent />
        </GrammarContent>
      </SecureRoute>

      <SecureRoute exact path="/course/:courseId/placement-test">
        <PlacementTestContainer>
          <PlacementTestContent />
        </PlacementTestContainer>
      </SecureRoute>

      <SecureRoute exact path="/course/:courseId/placement-test/entrypoint/:entrypointId">
        <PlacementTestContainer>
          <EntrypointContent />
        </PlacementTestContainer>
      </SecureRoute>
      <SecureRoute exact path="/course/:courseId/placement-test/entrypoint/:entrypointId/exercise/:exerciseId">
        <PlacementTestContainer>
          <PlacementExerciseContent />
        </PlacementTestContainer>
      </SecureRoute>

      <SecureRoute
        exact
        path={[
          '/course/:courseId/content/:contentId',
          '/course/:courseId/level/:levelId/chapter/:chapterId/lesson/:lessonId/activity/:activityId/exercise/:exerciseId',
          '/course/:courseId/level/:levelId/chapter/:chapterId/lesson/:lessonId/exercise/:exerciseId',
          '/course/:courseId/level/:levelId/chapter/:chapterId/lesson/:lessonId/activity/:activityId',
          '/course/:courseId/level/:levelId/chapter/:chapterId/lesson/:lessonId',
          '/course/:courseId/level/:levelId/chapter/:chapterId',
          '/course/:courseId/level/:levelId',
          '/course/:courseId',
        ]}
      >
        <MainContent />
      </SecureRoute>

      <SecureRoute
        exact
        path={[
          '/course/:courseId/level/:levelId/lesson/:lessonId/activity/:activityId/exercise/:exerciseId',
          '/course/:courseId/level/:levelId/lesson/:lessonId/exercise/:exerciseId',
          '/course/:courseId/level/:levelId/lesson/:lessonId/activity/:activityId',
          '/course/:courseId/level/:levelId/lesson/:lessonId',
        ]}
      >
        <RedirectFromOldURL />
      </SecureRoute>

      <SecureRoute exact path="/roleplay/:languageId">
        <RoleplayPage>
          <RoleplayContent />
        </RoleplayPage>
      </SecureRoute>

      <SecureRoute exact path="/roleplay/:languageId/category/:categoryId">
        <RoleplayPage>
          <RoleplayCategoryContent />
        </RoleplayPage>
      </SecureRoute>

      <SecureRoute exact path="/roleplay/:languageId/category/:categoryId/scenario/:scenarioId">
        <RoleplayPage>
          <RoleplayScenarioContent />
        </RoleplayPage>
      </SecureRoute>

      <SecureRoute exact path="/vocabulary-review/:languageId">
        <VocabularyReviewPage>
          <VocabularyReviewContent />
        </VocabularyReviewPage>
      </SecureRoute>

      <SecureRoute exact path="/vocabulary-review/:languageId/lexical-item/:lexicalItemId?">
        <VocabularyReviewPage>
          <LexicalItemContent />
        </VocabularyReviewPage>
      </SecureRoute>

      <SecureRoute path="*">
        <ErrorPage code={404} />
      </SecureRoute>
    </Switch>
  </div>
);
