import {
  NAVIGATION_PANEL_WIDTH,
  OTHER_ELEMENTS_HEIGHT,
  NAVIGATION_ELEMENT_HEIGHT_REM,
  NAVIGATION_PANEL_COLLAPSED_WIDTH,
} from './constants';
import styled, { css } from 'styled-components/macro';

export const NavigationContainer = styled.div<{ buttonsAmount: number; isDisabled: boolean }>`
  ${({ buttonsAmount, isDisabled }) => css`
    height: calc(100vh - (${OTHER_ELEMENTS_HEIGHT} + ${buttonsAmount * NAVIGATION_ELEMENT_HEIGHT_REM}rem));
    opacity: ${isDisabled ? '0.6' : '1'};
    overflow: auto;
    width: 100%;
  `}
`;

export const NavigationPanelContainer = styled.div<{ $collapsed?: boolean }>`
  ${({ theme, $collapsed }) => css`
    background: ${theme.colorV2.navigationBackground};
    width: ${$collapsed ? NAVIGATION_PANEL_COLLAPSED_WIDTH : NAVIGATION_PANEL_WIDTH};
    z-index: ${theme.zIndex.navigationSidebar};
  `}
  min-height: 100vh;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: width 100ms;
`;

export const LogoAndNavigationContainer = styled.div<{ $collapsed?: boolean }>`
  align-items: ${({ $collapsed }) => ($collapsed ? 'center' : 'flex-start')};
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
`;

export const ChildrenArray = styled.div<{ visible: boolean; childrenAmount: number }>`
  ${({ childrenAmount, visible }) => css`
    max-height: ${visible ? `${(childrenAmount + 1) * NAVIGATION_ELEMENT_HEIGHT_REM}` : '0'}rem;
    overflow: auto;
    padding-left: 1.5rem;
    transition: max-height 0.2s ease;
  `}
`;
