import { UploadAudiosActions } from '@actions/UploadAudiosActions';

export const UploadAudiosActionsCreator = {
  upload: ({ audioFiles }: { audioFiles: FileList }) => ({
    type: UploadAudiosActions.UPLOAD,
    payload: {
      audioFiles,
    },
  }),
  close: () => ({
    type: UploadAudiosActions.CLOSE,
  }),
};
