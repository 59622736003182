import * as Sentry from '@sentry/react';

import { apiClient } from '@features/api';
import {
  GrammarCategoryContentType,
  GrammarCategoryEditableFieldNames,
  GrammarTopicContentType,
  GrammarCategoryFormikValues,
  GrammarTopicFormikValues,
  GrammarTopicEditableFieldNames,
  GrammarCategoryChangableFieldNames,
} from '@features/content/grammar';
import { type ExerciseTypesType } from '@common/enums/ExerciseTypes';
import ContentsService from '@services/ContentsService';
import { selectTextLocalizationsForSave, selectMediaLocalizationsForSave } from '@helpers/localizationSaveHelper';
import { DBId } from '@common/types/DBId';
import { ContentTypes, type ContentTypesType } from '@common/enums/ContentTypes';

export const GrammarReviewService = {
  // Grammar review requests
  async getGrammarReview(courseId: DBId) {
    return await apiClient.v2.get(`/courses/${courseId}/grammar-review`);
  },
  async getReviewValidationResult(courseId: DBId) {
    return await apiClient.v2.get(`/courses/${courseId}/grammar-review/status`);
  },
  async getGrammarCategories(courseId: DBId) {
    return await apiClient.v2.get(`/courses/${courseId}/grammar-review/categories`);
  },
  async createCategory(courseId: DBId, position: number) {
    return await apiClient.v2.post(`/courses/${courseId}/grammar-review/categories`, { position });
  },
  async removeCategory(courseId: DBId, categoryId: DBId) {
    return await apiClient.v2.delete(`/courses/${courseId}/grammar-review/categories/${categoryId}`);
  },
  async reorderCategories({ courseId, categoryId, newIndex }: { courseId: DBId; categoryId: DBId; newIndex: number }) {
    return await apiClient.v2.put(`/courses/${courseId}/grammar-review/categories-order`, {
      categoryId,
      newPosition: newIndex,
    });
  },
  async publishReview(courseId: DBId) {
    return await apiClient.v2.post(`/courses/${courseId}/grammar-review/publish`);
  },
  async unpublishReview(courseId: DBId) {
    return await apiClient.v2.post(`/courses/${courseId}/grammar-review/unpublish`);
  },

  // Grammar category requests
  async getGrammarCategory(courseId: DBId, categoryId: DBId) {
    return await apiClient.v2.get(`/courses/${courseId}/grammar-review/categories/${categoryId}`);
  },
  async getCategoryValidationResult(courseId: DBId, categoryId: DBId) {
    return await apiClient.v2.get(`/courses/${courseId}/grammar-review/categories/${categoryId}/status`);
  },
  async getGrammarCategoryNames(courseId: DBId) {
    return await apiClient.v2.get(`/courses/${courseId}/grammar-review/categories/names`);
  },
  async getGrammarCategoryIcons(courseId: DBId) {
    return await apiClient.v2.get(`/courses/${courseId}/grammar-review/categories/icons`);
  },
  async getGrammarTopics(courseId: DBId, categoryId: DBId) {
    return await apiClient.v2.get(`/courses/${courseId}/grammar-review/categories/${categoryId}/topics`);
  },
  async createTopic(courseId: DBId, categoryId: DBId, position: number) {
    return await apiClient.v2.post(`/courses/${courseId}/grammar-review/topics`, { categoryId, position });
  },
  async removeTopic(courseId: DBId, categoryId: DBId, topicId: DBId) {
    return await apiClient.v2.post(
      `/courses/${courseId}/grammar-review/categories/${categoryId}/detach-topic/${topicId}`,
    );
  },
  async reorderTopics({
    courseId,
    categoryId,
    topicId,
    newIndex,
  }: {
    courseId: DBId;
    categoryId: DBId;
    topicId: DBId;
    newIndex: number;
  }) {
    return await apiClient.v2.put(`/courses/${courseId}/grammar-review/categories/${categoryId}/topics-order`, {
      topicId,
      newPosition: newIndex,
    });
  },
  async updateGrammarContentField(
    grammarContent: GrammarCategoryContentType | GrammarTopicContentType,
    fieldName: GrammarCategoryEditableFieldNames | GrammarTopicEditableFieldNames,
    values: GrammarCategoryFormikValues,
    contentType: ContentTypesType,
    grammarSlice: any,
    setContentId: (
      contentType: ContentTypesType,
      fieldName: GrammarCategoryEditableFieldNames | GrammarTopicEditableFieldNames,
      contentId: DBId,
    ) => void,
  ): Promise<Partial<Record<GrammarCategoryEditableFieldNames | GrammarTopicEditableFieldNames, string | null>>> {
    const textLocalizationsFilledFromFormik = grammarContent[fieldName]?.textLocalizations.map((loc) => ({
      ...loc,
      value: values[fieldName].find((value) => value.language === loc.language && !value.isPhonetic)?.value || '',
      phoneticValue:
        values[fieldName].find((value) => value.language === loc.language && value.isPhonetic)?.value || '',
    }));

    const contentPayload = {
      description: values[`${fieldName}Context`] || '',
      textLocalizations: selectTextLocalizationsForSave(textLocalizationsFilledFromFormik || []),
      audioLocalizations: selectMediaLocalizationsForSave(grammarContent[fieldName]?.audioLocalizations || []),
      imageLocalizations: [],
      videoLocalizations: [],
    };
    if (
      !contentPayload.textLocalizations.length &&
      !contentPayload.audioLocalizations.length &&
      !contentPayload.description
    ) {
      return { [fieldName]: null };
    }

    let contentId = grammarContent[fieldName]?.id || grammarContent[fieldName]?._id || '';
    if (!contentId) {
      try {
        contentId = await ContentsService.contents.createNewContent(contentPayload);

        setContentId(contentType, fieldName, contentId);
      } catch (error) {
        Sentry.captureException(error, (scope) => {
          scope.setTag('logosSection', 'GrammarReview');
          scope.setExtras({
            contentType,
            fieldName,
            grammarSlice,
          });

          return scope;
        });
      }
    } else {
      await ContentsService.contents.update(contentId, contentPayload);
    }

    return {
      [fieldName]: contentId,
    };
  },
  async getPayloadForCategoryUpdate(
    grammarCategory: GrammarCategoryContentType,
    values: GrammarCategoryFormikValues,
    setContentId: (
      contentType: ContentTypesType,
      fieldName: GrammarCategoryEditableFieldNames | GrammarTopicEditableFieldNames,
      contentId: DBId,
    ) => void,
  ) {
    try {
      const isDescriptionChanged =
        (values?.descriptionChanged || grammarCategory.descriptionChanged || grammarCategory.description?.changed) &&
        !grammarCategory.description?.isReused;
      const isIconChanged = grammarCategory.iconChanged;

      const payloadAccordingChangedFields = await Promise.all([
        { title: grammarCategory.title._id || grammarCategory.title.id },
        isDescriptionChanged
          ? GrammarReviewService.updateGrammarContentField(
              grammarCategory,
              'description',
              values,
              ContentTypes.grammarCategory,
              {},
              setContentId,
            )
          : { description: grammarCategory.description.id },
        isIconChanged ? { icon: grammarCategory.icon } : null,
      ]);

      const payload: Partial<
        | Record<GrammarCategoryEditableFieldNames, string | null>
        | Record<GrammarCategoryChangableFieldNames, { svg: string; pdf: string } | null>
      > = {
        ...payloadAccordingChangedFields.reduce((sum, item) => ({ ...sum, ...item }), {}),
      };

      return payload;
    } catch (e) {
      throw new Error(e as string);
    }
  },
  async saveCategory(
    courseId: DBId,
    categoryId: DBId,
    payload: Partial<
      | Record<GrammarCategoryEditableFieldNames, string | null>
      | Record<GrammarCategoryChangableFieldNames, { pdf: string; svg: string } | null>
    >,
  ) {
    return await apiClient.v2.put(`/courses/${courseId}/grammar-review/categories/${categoryId}`, payload);
  },
  async publishCategory(courseId: DBId, categoryId: DBId, parentId: DBId) {
    return await apiClient.v2.post(`/courses/${courseId}/grammar-review/categories/${categoryId}/publish`, {
      parentId,
    });
  },
  async unpublishCategory(courseId: DBId, categoryId: DBId, parentId: DBId) {
    return await apiClient.v2.post(`/courses/${courseId}/grammar-review/categories/${categoryId}/unpublish`, {
      parentId,
    });
  },

  // Grammar topic requests
  async getGrammarTopic(courseId: DBId, topicId: DBId) {
    return await apiClient.v2.get(`/courses/${courseId}/grammar-review/topics/${topicId}`);
  },
  async getTopicValidationResult(courseId: DBId, topicId: DBId) {
    return await apiClient.v2.get(`/courses/${courseId}/grammar-review/topics/${topicId}/status`);
  },
  async getGrammarExercises(courseId: DBId, topicId: DBId) {
    return await apiClient.v2.get(`/courses/${courseId}/grammar-review/topics/${topicId}/exercises`);
  },
  async getPayloadForTopicUpdate(
    grammarTopic: GrammarTopicContentType,
    values: GrammarTopicFormikValues,
    setContentId: (
      contentType: ContentTypesType,
      fieldName: GrammarCategoryEditableFieldNames | GrammarTopicEditableFieldNames,
      contentId: DBId,
    ) => void,
  ) {
    try {
      const isTitleChanged =
        (values?.titleChanged || grammarTopic.titleChanged || grammarTopic.title?.changed) &&
        !grammarTopic.title.isReused;
      const isDescriptionChanged =
        (values?.descriptionChanged || grammarTopic.descriptionChanged || grammarTopic.description?.changed) &&
        !grammarTopic.description.isReused;

      const payloadAccordingChangedFields = await Promise.all([
        isTitleChanged
          ? GrammarReviewService.updateGrammarContentField(
              grammarTopic,
              'title',
              values,
              ContentTypes.grammarTopic,
              {},
              setContentId,
            )
          : { title: grammarTopic.title.id },
        isDescriptionChanged
          ? GrammarReviewService.updateGrammarContentField(
              grammarTopic,
              'description',
              values,
              ContentTypes.grammarTopic,
              {},
              setContentId,
            )
          : { description: grammarTopic.description.id },
      ]);

      const payload: Partial<Record<GrammarCategoryEditableFieldNames, string | null>> = {
        ...payloadAccordingChangedFields.reduce((sum, item) => ({ ...sum, ...item }), {}),
      };

      return payload;
    } catch (e) {
      throw new Error(e as string);
    }
  },
  async updateTopicCefr(courseId: DBId, topicId: DBId, cefr: string) {
    return await apiClient.v2.put(`/courses/${courseId}/grammar-review/topics/${topicId}`, { cefr });
  },
  async saveTopic(
    courseId: DBId,
    topicId: DBId,
    payload: Partial<Record<GrammarTopicEditableFieldNames, string | null>>,
  ) {
    return await apiClient.v2.put(`/courses/${courseId}/grammar-review/topics/${topicId}`, payload);
  },
  async createExercise(
    exerciseType: ExerciseTypesType,
    payload: {
      numExercisesToCreate: number;
      position: number;
      topicId: DBId;
      topicExerciseType: 'grammar' | 'reference';
    },
  ) {
    return await apiClient.v2.post(`content/exercises`, {
      count: payload.numExercisesToCreate,
      field: payload.topicExerciseType,
      parentId: payload.topicId,
      parentType: 'grammarTopic',
      position: payload.position,
      type: exerciseType,
    });
  },
  async removeExercise(courseId: DBId, topicId: DBId, exerciseId: DBId, exerciseType: 'reference' | 'grammar') {
    return await apiClient.v2.post(
      `/courses/${courseId}/grammar-review/topics/${topicId}/detach-exercise/${exerciseId}`,
      { type: exerciseType },
    );
  },
  async useExercise(
    courseId: DBId,
    topicId: DBId,
    exerciseId: DBId,
    position: number,
    exerciseType: 'reference' | 'grammar',
  ) {
    return await apiClient.v2.post(
      `/courses/${courseId}/grammar-review/topics/${topicId}/attach-exercise/${exerciseId}`,
      { position, type: exerciseType },
    );
  },
  async reorderExercises({
    courseId,
    topicId,
    exerciseId,
    newIndex,
    exerciseType,
  }: {
    courseId: DBId;
    topicId: DBId;
    exerciseId: DBId;
    newIndex: number;
    exerciseType: 'reference' | 'grammar';
  }) {
    return await apiClient.v2.put(`/courses/${courseId}/grammar-review/topics/${topicId}/exercises-order`, {
      exerciseId,
      newPosition: newIndex,
      type: exerciseType,
    });
  },
  async getGrammarLessons(courseId: DBId) {
    return await apiClient.v2.get(`/courses/${courseId}/grammar-review/grammar-lessons`);
  },
  async attachLessons(courseId: DBId, topicId: DBId, lessonsToAttach: DBId[], lessonsToDetach: DBId[]) {
    return await apiClient.v2.post(`/courses/${courseId}/grammar-review/topics/${topicId}/attach-to-lessons`, {
      lessonsToAttach,
      lessonsToDetach,
    });
  },
  async publishTopic(courseId: DBId, topicId: DBId, parentId: DBId) {
    return await apiClient.v2.post(`/courses/${courseId}/grammar-review/topics/${topicId}/publish`, { parentId });
  },
  async unpublishTopic(courseId: DBId, topicId: DBId, parentId: DBId) {
    return await apiClient.v2.post(`/courses/${courseId}/grammar-review/topics/${topicId}/unpublish`, { parentId });
  },

  // Navigation requests
  async getReviewNavigation(courseId: DBId) {
    return await apiClient.v2.get(`/navigation/courses/${courseId}/grammar-review/categories`);
  },
  async getCategoryNavigation(courseId: DBId, categoryId: DBId) {
    return await apiClient.v2.get(`/navigation/courses/${courseId}/grammar-review/categories/${categoryId}/topics`);
  },
  async getTopicNavigation(courseId: DBId, topicId: DBId) {
    return await apiClient.v2.get(`/navigation/courses/${courseId}/grammar-review/topics/${topicId}/exercises`);
  },
};
