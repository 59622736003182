import { ReactNode, forwardRef } from 'react';
import { Dropdown } from 'react-bootstrap';
import styled, { css } from 'styled-components/macro';

import { ReactComponent as DefaultTriggerIcon } from './_img/context-menu-default-trigger.svg';

const BASE_FONT_SIZE = 10; // px

const CONTEXTUAL_MENU_CLASSNAME_PREFIX = 'contextual-menu';
const CONTEXTUAL_MENU_GAP = 6; // px

const CONTEXTUAL_MENU_POSITION_DOWN = 'down';
const CONTEXTUAL_MENU_POSITION_LEFT = 'left';
const CONTEXTUAL_MENU_POSITION_RIGHT = 'right';
const CONTEXTUAL_MENU_POSITION_UP = 'up';

export type ContextualMenuPositionType =
  | typeof CONTEXTUAL_MENU_POSITION_DOWN
  | typeof CONTEXTUAL_MENU_POSITION_LEFT
  | typeof CONTEXTUAL_MENU_POSITION_RIGHT
  | typeof CONTEXTUAL_MENU_POSITION_UP;

type ContextualMenuVariantType = 'large' | 'small';

const calculatePositionFromClientRect = (triggerClientRect: DOMRect, cssPosition: 'left' | 'top') => {
  if (cssPosition === 'left') {
    const { width, x } = triggerClientRect;

    return `${(x + width + CONTEXTUAL_MENU_GAP) / BASE_FONT_SIZE}rem`;
  }

  if (cssPosition === 'top') {
    const { height, y } = triggerClientRect;

    return `${(y + height / 2) / BASE_FONT_SIZE}rem`;
  }
};

/**
 * @TODO Replace react-bootstrap/Dropdown with standard Popover API once stable
 * @see https://busuucom.atlassian.net/browse/INTO-3436
 */

const StyledDropdown = styled(Dropdown)<{
  drop: ContextualMenuPositionType | DOMRect;
  withTrigger: boolean;
  $variant: ContextualMenuVariantType;
}>`
  ${({ drop, theme, withTrigger, $variant }) => css`
    & {
      position: ${withTrigger ? 'relative' : 'initial'};
    }

    .${CONTEXTUAL_MENU_CLASSNAME_PREFIX}-toggle {
      background-color: transparent;
      border: none;
      border-radius: 0;
      color: ${theme.colorV2.textPrimary};
      cursor: pointer;
      font-size: 1.6rem;
      line-height: 1;
      margin: 0;
      padding: 0;
      width: auto;

      &:active {
        background-color: transparent !important;
        border-color: transparent !important;
      }

      &:focus {
        box-shadow: none !important;
      }
    }

    .${CONTEXTUAL_MENU_CLASSNAME_PREFIX}-dropdown {
      background-color: ${theme.colorV2.uiBackgroundPrimary};
      border: solid 0.1rem ${theme.colorV2.dropdown.border};
      border-radius: 0.8rem;
      box-shadow: 0 0.4rem 0.8rem 0 rgba(0, 0, 0, 0.05);
      font-size: ${$variant === 'large' ? '1.6rem' : '1.4rem'};
      width: max-content;
      z-index: 10000;

      ${drop === CONTEXTUAL_MENU_POSITION_DOWN &&
      withTrigger &&
      `top: ${CONTEXTUAL_MENU_GAP / BASE_FONT_SIZE}rem !important;`}

      ${drop === CONTEXTUAL_MENU_POSITION_LEFT &&
      withTrigger &&
      `right: ${CONTEXTUAL_MENU_GAP / BASE_FONT_SIZE}rem !important;`}

      ${drop === CONTEXTUAL_MENU_POSITION_RIGHT &&
      withTrigger &&
      `left: ${CONTEXTUAL_MENU_GAP / BASE_FONT_SIZE} !important;`}

      ${drop === CONTEXTUAL_MENU_POSITION_UP &&
      withTrigger &&
      `bottom: ${CONTEXTUAL_MENU_GAP / BASE_FONT_SIZE} !important;`}

      ${drop &&
      !withTrigger &&
      `
        /* Set position according the external trigger size and position in the viewport */
        left: ${calculatePositionFromClientRect(drop as DOMRect, 'left')} !important;
        top: ${calculatePositionFromClientRect(drop as DOMRect, 'top')} !important;
      `}

      &.show {
        opacity: 1 !important;
        pointer-events: auto !important;
      }
    }

    .${CONTEXTUAL_MENU_CLASSNAME_PREFIX}-item {
      color: ${theme.colorV2.textPrimary} !important;
      cursor: pointer;
      padding: 0.8rem 1.6rem;

      &:hover {
        background-color: ${theme.colorV2.uiBackgroundSecondary};
      }

      a {
        color: ${theme.colorV2.textPrimary};

        &:hover {
          text-decoration: none;
        }
      }
    }
  `}
`;

const DefaultTriggerIconContainer = styled.div`
  align-items: center;
  display: flex;
  height: 2rem;
  justify-content: center;
  width: 2rem;
`;

export type ContextualMenuPropsType = {
  className?: string;
  children: ReactNode[];
  position?: ContextualMenuPositionType | DOMRect;
  show?: boolean;
  triggerIcon?: ReactNode;
  variant?: ContextualMenuVariantType;
  withTrigger?: boolean;
};

export const ContextualMenu = forwardRef(
  (
    {
      className,
      children,
      position = CONTEXTUAL_MENU_POSITION_RIGHT,
      show,
      triggerIcon,
      variant = 'large',
      withTrigger = false,
    }: ContextualMenuPropsType,
    ref,
  ) => (
    <StyledDropdown
      bsPrefix={CONTEXTUAL_MENU_CLASSNAME_PREFIX}
      className={className}
      data-testid="contextualMenu"
      drop={position}
      ref={ref}
      show={show}
      $variant={variant}
    >
      {withTrigger ? (
        <Dropdown.Toggle bsPrefix={`${CONTEXTUAL_MENU_CLASSNAME_PREFIX}-toggle`}>
          {triggerIcon ?? (
            <DefaultTriggerIconContainer>
              <DefaultTriggerIcon />
            </DefaultTriggerIconContainer>
          )}
        </Dropdown.Toggle>
      ) : null}

      <Dropdown.Menu align="left" bsPrefix={`${CONTEXTUAL_MENU_CLASSNAME_PREFIX}-dropdown`} role="menu">
        {children?.map((child: ReactNode, index: number) =>
          child ? (
            <Dropdown.Item as="div" bsPrefix={`${CONTEXTUAL_MENU_CLASSNAME_PREFIX}-item`} key={index}>
              {child}
            </Dropdown.Item>
          ) : null,
        )}
      </Dropdown.Menu>
    </StyledDropdown>
  ),
);
