import styled from 'styled-components';
import { Link } from 'react-router-dom';

import grammarIcon from './grammarIcon.svg';
import navigateIcon from '@components/ComponentOtherPlacesDisplayer/OtherPlacesDisplayer/img/navigate.svg';

const TopicLabelContainer = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
  padding: 4px 8px;
  height: 22px;
  background: ${({ theme }) => theme.colorV2.grammarTopicLabelContainerBackground};
  border-radius: 16px;
  font-weight: 700;
  font-size: 10px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colorV2.grammarTopicLabelContainerText};

  &:hover {
    color: ${({ theme }) => theme.colorV2.grammarTopicLabelContainerText};
    text-decoration: none;
  }
`;

const TopicLabelText = styled.span`
  margin: 0 5px;
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

type GrammarTopicLabelProps = {
  link: string;
  title?: string;
};

export const GrammarTopicLabel = ({ title = 'Grammar topic name', link }: GrammarTopicLabelProps) => (
  <TopicLabelContainer data-testid="topic-name-button" to={link} target="_blank">
    <img src={grammarIcon} alt="Grammar" />
    <TopicLabelText>{title}</TopicLabelText>
    <img src={navigateIcon} alt="Navigate" />
  </TopicLabelContainer>
);
