import { GenericContentInterface } from '@common/interfaces/contentTypes/GenericContentInterface';
import { AnyExerciseContentInterface } from '@common/types/exercises/AnyExerciseContentInterface';
import { CourseInterface } from '@common/interfaces/courses/CourseInterface';
import {
  AnyExerciseContentBranch,
  CourseContentBranches,
  ContentBranches,
} from '@common/types/AnyExerciseContentBranch';
import { NavigationItemType } from '@features/content/navigation';

export const getReuseData = ({
  contentForTranslation,
  bundleName,
  visitedBranch,
  isEditAvailable,
  localizationIDForLangExperts,
}: {
  contentForTranslation: GenericContentInterface | CourseInterface | AnyExerciseContentInterface | {};
  bundleName: string;
  visitedBranch: AnyExerciseContentBranch | CourseContentBranches | ContentBranches;
  isEditAvailable: boolean;
  localizationIDForLangExperts: string;
}) => {
  const otherPlacesBundleCount: number = bundleName ? (contentForTranslation as any)[bundleName]?.mappings?.count : 1;
  const getOtherPlacesStringCount = () => {
    if (bundleName) {
      return (
        (contentForTranslation as any)[bundleName][visitedBranch]?.mappings?.count ||
        (contentForTranslation as any)[bundleName][visitedBranch]?.mappings?.length ||
        0
      );
    } else {
      return (
        (contentForTranslation as any)[visitedBranch]?.mappings?.count ||
        (contentForTranslation as any)[visitedBranch]?.mappings?.length ||
        0
      );
    }
  };

  const otherPlacesStringCount: number = getOtherPlacesStringCount();

  const isStringChangeBlocked =
    !isEditAvailable ||
    (localizationIDForLangExperts.includes('default_') &&
    !(contentForTranslation as any)[visitedBranch].isReusingConfirmed
      ? true
      : !otherPlacesStringCount || otherPlacesStringCount === 1
      ? false
      : bundleName
      ? !(contentForTranslation as any)[bundleName][visitedBranch].isReusingConfirmed
      : !(contentForTranslation as any)[visitedBranch].isReusingConfirmed);

  const isBundleChangeBlocked = !isEditAvailable
    ? true
    : bundleName
    ? otherPlacesBundleCount > 1 && !(contentForTranslation as any)[bundleName].isReusingConfirmed
    : false;

  return {
    isStringChangeBlocked,
    isBundleChangeBlocked,
  };
};

export const getRealId = (id: string) => {
  const reusedIdPostfix = '_reused';
  if (id) {
    return id.includes(reusedIdPostfix) ? id.substr(0, id.indexOf(reusedIdPostfix)) : id;
  }
  return id;
};

export const processStructureForReuse = (prevStructure: NavigationItemType[], newElements: NavigationItemType[]) => {
  const prevStructureIds = prevStructure.map((struc) => struc.id);
  const loadedStructureIds = newElements.map((struc) => struc.id);
  const updatedPrevStructure = prevStructure.map((struc) => {
    if (loadedStructureIds.includes(struc.id)) {
      return {
        ...struc,
        id: `${struc.id}_reused_0`,
      };
    }
    return struc;
  });

  const newStructure = newElements.map((struc) => {
    const otherReusedIds = prevStructureIds.filter((strucId) => strucId.includes(struc.id));
    if (otherReusedIds.length) {
      return {
        ...struc,
        id: `${struc.id}_reused${otherReusedIds ? `_${otherReusedIds.length}` : ''}`,
      };
    }
    return struc;
  });

  return [...updatedPrevStructure, ...newStructure];
};
