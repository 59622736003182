import { ReactElement, useEffect } from 'react';

import { ExerciseInterface } from '@common/interfaces/contentTypes/ExerciseInterface';
import { ExerciseListItemType } from '@components/ContentTypes/ExerciseCard/types';
import { isExerciseReadyForPublish, isExerciseReadyForUnpublish } from '@features/content/exercises';
import { DBId } from '@common/types/DBId';
import { BulkPublishingActionsCreator } from '@actionCreators/BulkPublishingActionsCreator';
import { BulkPublishingButtons } from './BulkPublishingButtons';
import { BulkPublishingFooter } from './BulkPublishingFooter';
import { useAppDispatch } from '@redux/store';

export const BulkPublishingWrapper = ({
  children,
  items,
  parentId,
  parentType,
}: {
  children: ReactElement;
  items: (ExerciseListItemType | ExerciseInterface)[];
  parentId: DBId;
  parentType: string;
}) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(BulkPublishingActionsCreator.disableAll());
  }, [dispatch]);

  return (
    <>
      <BulkPublishingButtons
        itemsReadyForPublish={items.filter((item) => isExerciseReadyForPublish(item))}
        itemsReadyForUnpublish={items.filter((item) => isExerciseReadyForUnpublish(item))}
      />
      {children}
      <BulkPublishingFooter parentId={parentId} parentType={parentType} />
    </>
  );
};
