import React from 'react';
import { Link } from 'react-router-dom';
import logoLetter from '@static/svg/logo-letter.svg';
import logoLarge from '@static/svg/logo.svg';
import logoStagingLarge from '@static/svg/logo-staging.svg';
import logoLetterStaging from '@static/svg/logo-letter-staging.svg';

type Environment = 'prod' | 'non-prod' | 'local';

const logos: { [key in Environment]: { [key in ConfigurableLogoProps['size']]: string } } = {
  prod: {
    large: logoLarge,
    small: logoLetter,
  },
  'non-prod': {
    large: logoStagingLarge,
    small: logoLetterStaging,
  },
  local: {
    large: logoStagingLarge,
    small: logoLetterStaging,
  },
};

interface ConfigurableLogoProps {
  size: 'large' | 'small';
  className?: string;
  withLink: boolean;
  environment: Environment;
  onClickHandler?: () => void;
}
export const ConfigurableLogo = ({ size, className, withLink, environment, onClickHandler }: ConfigurableLogoProps) => {
  const logo = (
    <img
      src={logos[environment][size]}
      alt="Busuu logo"
      className={className}
      width={size === 'large' ? 150 : undefined}
      onClick={withLink ? undefined : onClickHandler}
    />
  );

  if (withLink) {
    return (
      <Link to="/" className="backlink" onClick={onClickHandler}>
        {logo}
      </Link>
    );
  }

  return logo;
};

type LogoProps = Omit<ConfigurableLogoProps, 'environment'>;
export const Logo = (props: LogoProps) => {
  const environment = (process.env.REACT_APP_LOGOS_ENV as Environment) || 'prod';

  return <ConfigurableLogo environment={environment} {...props} />;
};
