import styled, { useTheme } from 'styled-components';
import CreatableSelect from 'react-select/creatable';
import { ClearIndicatorProps, components, DropdownIndicatorProps, StylesConfig } from 'react-select';

import { ReactComponent as DropdownIndicator } from './img/dropdownIndicator.svg';
import { ReactComponent as ClearIndicator } from './img/clearIndicator.svg';
import checkedIcon from './img/checkedIcon.svg';

type CreatableSearchableOptionType = { value: string; label: string };

const CLASSNAME_PREFIX = 'creatable-searchable-selector';

export const TEST_LABEL = CLASSNAME_PREFIX;

const StyledCreateOption = styled.span`
  color: ${({ theme }) => theme.colorV2.creatableSearchableSelectorCreateOptionColor};
  font-weight: 700;
  font-size: 1.6rem;
`;

type SearchableSelectorProps = {
  options: CreatableSearchableOptionType[];
  value: CreatableSearchableOptionType | null;
  className?: string;
  placeholder?: string;
  onChange: (option: CreatableSearchableOptionType | null) => void;
  onCreateOption: (value: string) => void;
  onFocus: () => void;
};

export const CreatableSearchableSelector = ({
  options,
  value,
  className,
  placeholder = 'Select option',
  onChange,
  onCreateOption,
  onFocus,
}: SearchableSelectorProps) => {
  const theme = useTheme();

  const SearchableSelectorStyles: StylesConfig<CreatableSearchableOptionType> = {
    control: (defaultStyles, { isFocused }) => ({
      ...defaultStyles,
      transition: 'all 0.3s ease',
      fontSize: '1.5rem',
      border: `0.1rem solid ${theme.colorV2.creatableSearchableSelectorControlBaseBorder}`,
      boxShadow: 'none',
      borderRadius: '0.8rem',
      ':hover': {
        border: `0.1rem solid ${theme.colorV2.creatableSearchableSelectorControlBaseBorder}`,
      },
      background: isFocused
        ? theme.colorV2.creatableSearchableSelectorControlFocusedBackground
        : theme.colorV2.creatableSearchableSelectorControlBaseBackground,
      [`.${CLASSNAME_PREFIX}__input`]: {
        color: isFocused
          ? theme.colorV2.creatableSearchableSelectorControlFocusedText
          : theme.colorV2.creatableSearchableSelectorControlBaseText,
      },
      [`.${CLASSNAME_PREFIX}__input-container`]: {
        color: isFocused
          ? theme.colorV2.creatableSearchableSelectorControlFocusedText
          : theme.colorV2.creatableSearchableSelectorControlBaseText,
      },
      [`.${CLASSNAME_PREFIX}__single-value`]: {
        color: isFocused
          ? theme.colorV2.creatableSearchableSelectorControlFocusedText
          : theme.colorV2.creatableSearchableSelectorControlBaseText,
      },
    }),
    menu: (defaultStyles) => ({
      ...defaultStyles,
      margin: '0',
      border: `0.1rem solid ${theme.colorV2.creatableSearchableSelectorMenuBorder}`,
      boxShadow: '0 0.4rem 0.8rem rgba(0, 0, 0, 0.05)',
      borderRadius: '0.8rem',
    }),
    menuList: (defaultStyles) => ({
      ...defaultStyles,
      padding: '0',
      borderRadius: '0.7rem',
    }),
    option: (defaultStyles, { isFocused, isSelected }) => ({
      ...defaultStyles,
      transition: 'background-color 0.3s ease',
      backgroundColor: isFocused
        ? theme.colorV2.creatableSearchableSelectorOptionFocusedBackground
        : theme.colorV2.creatableSearchableSelectorOptionBaseBackground,
      position: 'relative',
      color: 'inherit',
      ':after': {
        content: '""',
        backgroundImage: isSelected ? `url(${checkedIcon})` : 'none',
        display: 'block',
        width: '2rem',
        position: 'absolute',
        height: '1.5rem',
        right: '1rem',
        top: '1.2rem',
      },
    }),
    placeholder: (defaultStyles, { isFocused }) => ({
      ...defaultStyles,
      transition: 'color 0.3s ease',
      color: isFocused
        ? theme.colorV2.creatableSearchableSelectorPlaceholderFocusedText
        : theme.colorV2.creatableSearchableSelectorPlaceholderBaseText,
    }),
    dropdownIndicator: (defaultStyles, { isFocused }) => ({
      ...defaultStyles,
      padding: '0.4rem',
      path: {
        transition: 'fill 0.3s ease',
        fill: isFocused
          ? theme.colorV2.creatableSearchableSelectorDropdownIndicatorFocusedColor
          : theme.colorV2.creatableSearchableSelectorDropdownIndicatorBaseColor,
      },
    }),
    clearIndicator: (defaultStyles, { isFocused }) => ({
      ...defaultStyles,
      padding: '0',
      cursor: 'pointer',
      path: {
        transition: 'fill 0.3s ease',
        fill: isFocused
          ? theme.colorV2.creatableSearchableSelectorClearIndicatorFocusedColor
          : theme.colorV2.creatableSearchableSelectorClearIndicatorBaseColor,
      },
    }),
    indicatorSeparator: (defaultStyles) => ({
      ...defaultStyles,
      display: 'none',
    }),
  };

  const DropdownIndicatorCustom = (props: DropdownIndicatorProps<CreatableSearchableOptionType>) => (
    <components.DropdownIndicator {...props}>
      <DropdownIndicator />
    </components.DropdownIndicator>
  );

  const ClearIndicatorCustom = (props: ClearIndicatorProps<CreatableSearchableOptionType>) => (
    <components.ClearIndicator {...props}>
      <ClearIndicator aria-label="selector-clear-value" />
    </components.ClearIndicator>
  );

  return (
    <CreatableSelect<CreatableSearchableOptionType>
      classNamePrefix={CLASSNAME_PREFIX}
      aria-label={TEST_LABEL}
      className={className}
      isClearable
      value={value}
      placeholder={placeholder}
      options={options}
      styles={SearchableSelectorStyles}
      components={{ DropdownIndicator: DropdownIndicatorCustom, ClearIndicator: ClearIndicatorCustom }}
      noOptionsMessage={() => 'No data found'}
      formatCreateLabel={(userInput) => <StyledCreateOption>Create "{userInput}"</StyledCreateOption>}
      onChange={onChange}
      onCreateOption={onCreateOption}
      onFocus={onFocus}
    />
  );
};
