import { useCallback } from 'react';
import { FormikContextType, useFormikContext } from 'formik';
import { UserLanguageCheckbox } from '@components/Exercises/Common/UserLanguageCheckbox';
import { ValidationErrorDisplayer } from '@components/ValidationErrorDisplayer';
import { AlternativeValueCreator } from '@components/Exercises/Common/AlternativeValueCreator/AlternativeValueCreator';
import { TranslationTipActionsCreator } from '@actionCreators/TranslationTipActionsCreator';
import { LoadedTypingExerciseInterface } from '@common/interfaces/exercises/LoadedExerciseInterface';
import { ResourceBundleWrapper } from '@components/Exercises/Common/ResourceBundle';
import ImageUploadDimensionDescriptors from '@common/enums/FileUploadDimensionDescriptors';
import { ValidationErrorInterface } from '@common/interfaces/validation/ValidationInterface';
import { AudioUploadActionsCreator } from '@actionCreators/AudioUploadActionsCreator';
import { CommonExerciseActionsCreator } from '@actionCreators/CommonExerciseActionsCreator';
import { WritableInputText } from '@components/WritableInputText';
import type { DisplayContentToUserModeType } from '@common/enums/DisplayContentToUserMode';
import { ExerciseCommonActionCreators as ExerciseCommonActions } from '@actionCreators/ExerciseCommonActionCreator';
import TranslationsTipV2 from '@components/TranslationTipV2/TranslationTipV2';
import { FormikValuesInterface } from '@helpers/formikInitialValuesHelper';
import ContentToggler from '@components/Exercises/Common/ContentToggler/ContentToggler';
import { RecapSelector } from '@components/Exercises/Common/RecapSelector/RecapSelector';
import { Explanation, Title, TitleContainer } from '@components/Exercises/Common';
import { HelpDisplayer } from '@features/help';
import { ExperimentSelector } from '@features/experiment';
import { PreviewModal } from '@features/content/preview';
import ExerciseProps from '@components/Exercises/ExerciseProps';
import { constants as contentConstants, findLocalizationInSearchedLanguage } from '@features/content';
import { ImageUploadModes } from '@common/enums/FileUploadModes';
import { useIsEditorHidden } from '@helpers/useHideEditor';
import ImageUploadUtils from '@components/MediaUpload/ImageUploadUtils';
import { AudioUpload, ImageUpload } from '@components/MediaUpload';
import FillgapTool from '../FillGap/FillgapTool/FillgapTool';
import { ExerciseTypes } from '@common/enums/ExerciseTypes';
import { ContentTypes } from '@common/enums/ContentTypes';
import { useIsEditAvailable } from '@features/content/courses';
import helpersService from '@services/HelpersService';
import { AccessWarning } from '@components/Warning';
import { getResources } from '@helpers/getResourcesHelper';
import { Sizes } from '@common/enums/Sizes';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { TypingExerciseActionsCreator } from '@actionCreators/TypingExerciseActionsCreator';
import TypingExerciseInterface from './interfaces/TypingExerciseInterface';
import { Language } from '@features/content/languages';
import { Instructions, removeMediaProcessingValidationError } from '@features/content/exercises';
import {
  selectInterfaceLanguages,
  selectLearningLanguage,
  selectLoadedExerciseData,
} from '@selectors/CoursesSelectors';
import { apiClient } from '@features/api';

const getAlternativeValueSuggestions = async (language: Language, phrase: string): Promise<string[]> => {
  const response = await apiClient.noErrorsV2.post('ai/alternative-values', {
    phrase,
    language,
  });

  return response.data.alternativeValues as string[];
};

const { DEFAULT_LANGUAGE, MEDIA_PROCESSING_VALIDATION_MESSAGE } = contentConstants;
const MAX_GROUPS = 1;

const TypingExercise = ({ exercise: exerciseParam }: ExerciseProps<LoadedTypingExerciseInterface>) => {
  const { values, setFieldValue }: FormikContextType<FormikValuesInterface> = useFormikContext();
  const { isEditorHidden } = useIsEditorHidden({ bundleName: 'mainBundle', visitedBranch: 'phrase' });
  const dispatch = useAppDispatch();
  const courseLearningLanguage = useAppSelector(selectLearningLanguage);
  const interfaceLanguages = useAppSelector(selectInterfaceLanguages);

  const loadedExercise = useAppSelector(selectLoadedExerciseData) as TypingExerciseInterface;

  const audioLocalizations = loadedExercise?.content?.mainBundle?.phrase.audioLocalizations;

  const availableLanguagesForAudioLocalizations = Array.from(new Set([...interfaceLanguages, courseLearningLanguage]));
  const availableAudioLocalizations = audioLocalizations.filter((audioLocalization) =>
    availableLanguagesForAudioLocalizations.includes(audioLocalization.language),
  );

  // Image only has EN localization
  const imageLocalization = loadedExercise?.content?.mainBundle?.image?.imageLocalizations?.find(
    (imageLocalization) => imageLocalization.language === DEFAULT_LANGUAGE,
  );

  const { isEditAvailable } = useIsEditAvailable();

  const { errors } = loadedExercise?.content?.validation;

  const instructionsErrors = errors.filter((error: ValidationErrorInterface) => error.field === 'instructions');
  const mainAudioErrors = errors.filter((error: ValidationErrorInterface) => error.field === 'mainAudioEnabled');
  const mainBundleErrors = errors.filter((error: ValidationErrorInterface) => error.field === 'mainBundle');
  const imageErrors = errors.filter((error: ValidationErrorInterface) => error.field === 'mainBundle.image');

  if (imageErrors.length && imageLocalization?.processed && imageLocalization?.value) {
    const updatedErrors = removeMediaProcessingValidationError({
      errors,
      message: MEDIA_PROCESSING_VALIDATION_MESSAGE.IMAGE.replace('%LANG%', DEFAULT_LANGUAGE),
    });

    if (updatedErrors.length < errors.length) {
      dispatch(CommonExerciseActionsCreator.updateValidationErrors({ errors: updatedErrors }));
    }
  }

  const phraseErrors = errors.filter(
    (error: ValidationErrorInterface) =>
      error.field === 'mainBundle.phrase' && !error.message.toLowerCase().includes('audio'),
  );
  const phraseAudioErrors = errors.filter(
    (error: ValidationErrorInterface) =>
      error.field === 'mainBundle.phrase' && error.message.toLowerCase().includes('audio'),
  );

  if (phraseAudioErrors.length) {
    let updatedErrors = [...errors];

    availableAudioLocalizations.forEach(({ language, processed, value }) => {
      if (processed && value) {
        updatedErrors = removeMediaProcessingValidationError({
          errors,
          message: MEDIA_PROCESSING_VALIDATION_MESSAGE.AUDIO.replace('%LANG%', language),
        });
      }
    });

    if (updatedErrors.length < errors.length) {
      dispatch(CommonExerciseActionsCreator.updateValidationErrors({ errors: updatedErrors }));
    }
  }

  const hintErrors = errors.filter((error: ValidationErrorInterface) => error.field === 'hint');

  const isPhraseChangeBlocked =
    (loadedExercise.content?.mainBundle?.phrase?.mappings?.count > 1 ||
      loadedExercise.content?.mainBundle?.phrase?.mappings?.length > 1) &&
    !loadedExercise.content?.mainBundle?.phrase?.isReusingConfirmed;

  const isMainBundleChangeBlocked =
    loadedExercise.content.mainBundle?.mappings?.count &&
    loadedExercise.content.mainBundle?.mappings?.count > 1 &&
    !loadedExercise.content.mainBundle?.isReusingConfirmed;

  const requestOrCancelAudioPayload = {
    bundleName: 'mainBundle',
    field: 'phrase',
    language: courseLearningLanguage,
    type: ContentTypes.exercise,
  };

  const setReusedData = (id: string, field: string, isBundle: boolean) => {
    dispatch(
      TranslationTipActionsCreator.setCurrentContentId(
        id,
        ContentTypes.exercise,
        field,
        undefined,
        undefined,
        'mainBundle',
        isBundle,
      ),
    );
  };

  const renderEditor = () => (
    <TranslationsTipV2
      bundleName={'mainBundle'}
      visitedBranch={'phrase'}
      showLanguageSwitcher={false}
      errors={phraseErrors}
      showErrorDisplayer={false}
      flipped={false}
    >
      <FillgapTool gapMode={false} maxGroups={MAX_GROUPS} errors={phraseErrors} />
    </TranslationsTipV2>
  );

  const onProcessingFinished = useCallback(
    (url: string) => {
      dispatch(
        CommonExerciseActionsCreator.setValueAfterProcessing({
          url,
          mediaType: 'audio',
          type: ContentTypes.exercise,
          field: 'phrase',
          language: courseLearningLanguage,
          bundleName: 'mainBundle',
        }),
      );
    },
    [dispatch, courseLearningLanguage],
  );

  const learningLanguagePhrase = (values.learningWordBundle_phrase || []).find(
    (loc) => loc.language === courseLearningLanguage,
  );
  const loadAlternativeValues = useCallback(async () => {
    if (!learningLanguagePhrase?.value) {
      return [];
    }

    return getAlternativeValueSuggestions(courseLearningLanguage as Language, learningLanguagePhrase.value);
  }, [learningLanguagePhrase, courseLearningLanguage]);

  return (
    <div className="exercise-typing">
      <PreviewModal type={loadedExercise.type} content={loadedExercise.content} />
      <TitleContainer>
        <Title>Typing</Title>
        <HelpDisplayer type="guideline" id="guideline-typing-exercise" />
      </TitleContainer>
      <ExperimentSelector
        isEditAvailable={isEditAvailable}
        currentExperiment={loadedExercise.content.experiment}
        onExperimentChange={(experiment) => dispatch(ExerciseCommonActions.setExperimentValue(experiment))}
      />
      {!isEditAvailable && <AccessWarning />}
      <div className="exercise-typing__main-wrapper">
        <div className="exercise-typing__wrapper">
          <div className="exercise-typing__container">
            <h1 className="exercise-typing__box-title">{'Instruction'}</h1>
            <ValidationErrorDisplayer text={helpersService.getValidationErrorMessageText(instructionsErrors)} />
          </div>
          <Instructions />
          <UserLanguageCheckbox
            checkedField={exerciseParam.exercise?.content.instructionsLanguage}
            onChange={(displayContentToUserMode: DisplayContentToUserModeType) => {
              dispatch(TypingExerciseActionsCreator.setInstructionsLanguage(displayContentToUserMode));
            }}
          />
        </div>
        <div className="exercise-conversation__wrapper">
          <ContentToggler
            text="Hint"
            open={!!loadedExercise.content.hint?._id}
            onRemove={() => {
              dispatch(TypingExerciseActionsCreator.nullifyHint());
              setFieldValue(
                `hint`,
                values.hint?.map((item: any) => ({ ...item, value: '' })),
              );
              setFieldValue(`hintChanged`, true);
            }}
            errors={hintErrors}
          >
            <TranslationsTipV2 visitedBranch={'hint'} errors={hintErrors} showErrorDisplayer={false}>
              <WritableInputText id="typing-hint-input" />
            </TranslationsTipV2>
          </ContentToggler>
        </div>
        <ResourceBundleWrapper
          bundle={loadedExercise.content.mainBundle}
          bundleName={'mainBundle'}
          errors={mainBundleErrors}
          resources={getResources(loadedExercise, courseLearningLanguage)}
        >
          <>
            <div className="exercise-phrase-builder__wrapper">
              <div className="exercise-flashcard__alternative-container">
                <div className="exercise-typing__container">
                  <h1 className="exercise-phrase-builder__box-title">Phrase</h1>
                  <ValidationErrorDisplayer
                    type={phraseErrors.some((e) => !e.isWarning) ? 'error' : 'warning'}
                    text={helpersService.getValidationErrorMessageText(phraseErrors)}
                  />
                </div>
                {isEditorHidden ? <div>{renderEditor()}</div> : <>{renderEditor()}</>}
                <AlternativeValueCreator
                  bundleName={'mainBundle'}
                  loadSuggestions={loadAlternativeValues}
                  visitedBranch={'phrase'}
                  isChangeBlocked={isMainBundleChangeBlocked || isPhraseChangeBlocked}
                  onChange={(values: any) => {
                    dispatch(TypingExerciseActionsCreator.setPhraseTextAlt(values, courseLearningLanguage));
                  }}
                  onChangeInstant={() =>
                    isMainBundleChangeBlocked
                      ? setReusedData(loadedExercise.content.mainBundle?._id || '', 'phrase', true)
                      : setReusedData(
                          loadedExercise.content.mainBundle?.phrase?._id ||
                            loadedExercise.content.mainBundle?.phrase?.id ||
                            '',
                          'phrase',
                          false,
                        )
                  }
                />
              </div>
            </div>
            <div className="exercise-typing__wrapper">
              <ContentToggler
                text="Audio"
                open={loadedExercise.content.mainAudioEnabled}
                onSwitch={() => {
                  dispatch(ExerciseCommonActions.setAudioEnabled(!loadedExercise.content.mainAudioEnabled));
                }}
                onRemove={() => {
                  if (isMainBundleChangeBlocked) {
                    setReusedData(loadedExercise.content.mainBundle?._id || '', 'phrase', true);
                  } else if (isPhraseChangeBlocked) {
                    setReusedData(
                      loadedExercise.content.mainBundle?.phrase?._id ||
                        loadedExercise.content.mainBundle?.phrase?.id ||
                        '',
                      'phrase',
                      false,
                    );
                  } else {
                    dispatch(
                      TypingExerciseActionsCreator.removeAudio({
                        contentId:
                          loadedExercise.content.mainBundle?.phrase?._id ||
                          loadedExercise.content.mainBundle?.phrase?.id,
                        language: courseLearningLanguage,
                      }),
                    );
                  }
                }}
                isChangeBlocked={isMainBundleChangeBlocked || isPhraseChangeBlocked}
                errors={[...mainAudioErrors, ...phraseAudioErrors]}
              >
                <AudioUpload
                  audioData={ImageUploadUtils.getAudioForFileUpload(
                    loadedExercise,
                    'phrase',
                    courseLearningLanguage,
                    'mainBundle',
                  )}
                  onAudioRequestSuccess={(audioRequestId: string) => {
                    dispatch(
                      CommonExerciseActionsCreator.setAudioValueAfterRequestOrCancelAudio({
                        ...requestOrCancelAudioPayload,
                        audioRequestData: {
                          id: audioRequestId,
                          status: 'new',
                        },
                      }),
                    );
                  }}
                  onCancelAudioRequestSuccess={() => {
                    dispatch(
                      CommonExerciseActionsCreator.setAudioValueAfterRequestOrCancelAudio(requestOrCancelAudioPayload),
                    );
                  }}
                  onProcessingFinished={onProcessingFinished}
                  onChange={(uploadedSound, progressHandler) => {
                    dispatch(
                      AudioUploadActionsCreator.uploadSound(
                        loadedExercise.content.id,
                        ExerciseTypes.typing,
                        courseLearningLanguage,
                        loadedExercise.content?.mainBundle?.phrase?._id,
                        findLocalizationInSearchedLanguage(
                          loadedExercise?.content?.mainBundle?.phrase?.audioLocalizations as any,
                          courseLearningLanguage as Language,
                        ),
                        uploadedSound,
                        'phrase',
                        undefined,
                        undefined,
                        true,
                        'mainBundle',
                        progressHandler,
                      ),
                    );
                  }}
                  onRemove={() => {
                    dispatch(
                      TypingExerciseActionsCreator.removeAudio({
                        contentId:
                          loadedExercise.content.mainBundle?.phrase?._id ||
                          loadedExercise.content.mainBundle?.phrase?.id,
                        language: courseLearningLanguage,
                      }),
                    );
                  }}
                  onChangeInstant={() => {
                    if (isMainBundleChangeBlocked) {
                      setReusedData(loadedExercise.content.mainBundle?._id || '', 'phrase', true);
                    } else {
                      setReusedData(
                        loadedExercise.content.mainBundle?.phrase?._id ||
                          loadedExercise.content.mainBundle?.phrase?.id ||
                          '',
                        'phrase',
                        false,
                      );
                    }
                  }}
                  isChangeBlocked={isMainBundleChangeBlocked || isPhraseChangeBlocked}
                  fullScreen
                  currentLanguage={courseLearningLanguage}
                  fieldName={'phrase'}
                  errors={[...mainAudioErrors, ...phraseAudioErrors]}
                />
              </ContentToggler>
            </div>
            <div className="exercise-typing__wrapper">
              <ContentToggler
                text="Image"
                open={loadedExercise.content.mainImageEnabled}
                onSwitch={() => {
                  dispatch(ExerciseCommonActions.setImageEnabled(!loadedExercise.content.mainImageEnabled));
                }}
                onRemove={() => {
                  if (isMainBundleChangeBlocked) {
                    setReusedData(loadedExercise.content.mainBundle?._id || '', 'image', true);
                  } else {
                    dispatch(TypingExerciseActionsCreator.removeImage());
                  }
                }}
                isChangeBlocked={!!isMainBundleChangeBlocked}
                errors={[...mainAudioErrors, ...imageErrors]}
              >
                <ImageUpload
                  width={ImageUploadDimensionDescriptors.typing.image.width}
                  height={ImageUploadDimensionDescriptors.typing.image.height}
                  size={Sizes.fullscreen}
                  mode={ImageUploadModes.normal}
                  onChange={(file: File, progressHandler: Function) => {
                    if (file !== null && loadedExercise.content.id !== undefined) {
                      dispatch(TypingExerciseActionsCreator.setImage(loadedExercise, file, progressHandler));
                    }
                  }}
                  onRemove={() => {
                    dispatch(TypingExerciseActionsCreator.removeImage());
                  }}
                  imageData={ImageUploadUtils.getDisplayImageForFileUpload(
                    loadedExercise,
                    'image',
                    undefined,
                    'mainBundle',
                  )}
                  onProcessingFinished={(url: string) => {
                    dispatch(
                      CommonExerciseActionsCreator.setValueAfterProcessing({
                        url,
                        mediaType: 'image',
                        type: ContentTypes.exercise,
                        field: 'image',
                        language: 'EN',
                        bundleName: 'mainBundle',
                      }),
                    );
                  }}
                  previewMode={false}
                  isForExercise
                  errors={[...mainAudioErrors, ...imageErrors]}
                  isChangeBlocked={!!isMainBundleChangeBlocked}
                  onChangeInstant={() => setReusedData(loadedExercise.content.mainBundle?._id || '', 'image', true)}
                />
              </ContentToggler>
            </div>
          </>
        </ResourceBundleWrapper>
        <Explanation />
        <RecapSelector exerciseId={loadedExercise?.content?.id} />
      </div>
    </div>
  );
};

export default TypingExercise;
