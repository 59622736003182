import styled from 'styled-components/macro';

import { ReactComponent as AudioCaseImg } from './_img/audioCase.svg';
import { ReactComponent as ImageAndAudioCaseImg } from './_img/imageAndAudioCase.svg';
import { ReactComponent as ImageCaseImg } from './_img/imageCase.svg';
import { ReactComponent as NoneCaseImg } from './_img/noneCase.svg';

const DisplayMediaSelectorContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 25%);
  padding: 1rem;
`;

const DisplayMediaOption = styled.label`
  display: block;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1;
  margin-bottom: 1.2rem;

  input {
    vertical-align: bottom;

    &:checked {
      appearance: auto;
      background-color: initial;
      box-shadow: none;
      cursor: default;
    }
  }
`;

type DisplayMediaSelectorPropsType = {
  isAudioEnabled: boolean;
  isImageEnabled: boolean;
  onChange: ({ audioEnabled, imageEnabled }: { audioEnabled: boolean; imageEnabled: boolean }) => void;
};

export const DisplayMediaSelector = ({ isAudioEnabled, isImageEnabled, onChange }: DisplayMediaSelectorPropsType) => (
  <DisplayMediaSelectorContainer>
    <div>
      <DisplayMediaOption>
        <input
          type="radio"
          onChange={() =>
            onChange({
              audioEnabled: true,
              imageEnabled: false,
            })
          }
          checked={isAudioEnabled && !isImageEnabled}
        />
        <span>Audio</span>
      </DisplayMediaOption>

      <AudioCaseImg />
    </div>
    <div>
      <DisplayMediaOption>
        <input
          type="radio"
          onChange={() =>
            onChange({
              audioEnabled: false,
              imageEnabled: true,
            })
          }
          checked={!isAudioEnabled && isImageEnabled}
        />
        <span>Image</span>
      </DisplayMediaOption>
      <ImageCaseImg />
    </div>
    <div>
      <DisplayMediaOption>
        <input
          type="radio"
          onChange={() =>
            onChange({
              audioEnabled: true,
              imageEnabled: true,
            })
          }
          checked={isAudioEnabled && isImageEnabled}
        />
        <span>Image & Audio</span>
      </DisplayMediaOption>
      <ImageAndAudioCaseImg />
    </div>
    <div>
      <DisplayMediaOption>
        <input
          type="radio"
          onChange={() =>
            onChange({
              audioEnabled: false,
              imageEnabled: false,
            })
          }
          checked={!isAudioEnabled && !isImageEnabled}
        />
        <span>None</span>
      </DisplayMediaOption>
      <NoneCaseImg />
    </div>
  </DisplayMediaSelectorContainer>
);
