import { SectionActions } from '@actions/SectionActions';
import { ExerciseTypes } from '@common/enums/ExerciseTypes';
import { LoadingStage } from '@common/enums/LoadingStage';
import { LoadedMatchUpExerciseInterface } from '@common/interfaces/exercises/LoadedExerciseInterface';
import { CourseSliceInterface } from '@common/interfaces/slices/CourseSliceInterface';
import { MatchUpExerciseActions } from '@actions/MatchUpExerciseActions';
import { updateTextField } from '@helpers/updateTextField';
import MatchUpExerciseInterface from '@components/Exercises/MatchUp/interfaces/MatchUpExerciseInterface';
import { selectedGroupsOfParentInitialData } from '@redux/initialStates/courseInitialState';
import { PayloadAction } from '@reduxjs/toolkit';
import { clone } from '@helpers/clone';

const MatchUpExerciseCourseReducers = {
  [SectionActions.COURSE_EXERCISE_MATCHUP_REQUEST_SUCCEEDED]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    let clonedCourseSlice: CourseSliceInterface = clone(state);
    let { content }: MatchUpExerciseInterface = payload;

    let exercise: MatchUpExerciseInterface = {
      ...clonedCourseSlice.loadedExercise.exercise,
      content,
      type: ExerciseTypes.matchup,
    };

    return {
      ...clonedCourseSlice,
      selectedGroupsOfParent: selectedGroupsOfParentInitialData,
      loadedExercise: {
        loaded: LoadingStage.loaded,
        exercise,
      },
    };
  },
  [MatchUpExerciseActions.SET_MATCH_UP_OPTION]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    let clonedState: CourseSliceInterface = clone(state);

    let loadedExercise: LoadedMatchUpExerciseInterface = clonedState?.loadedExercise as LoadedMatchUpExerciseInterface;

    if ((loadedExercise.exercise as MatchUpExerciseInterface).content !== undefined) {
      (loadedExercise.exercise as MatchUpExerciseInterface).content.matchingItemsLanguage = payload.option;
    }

    return {
      ...clonedState,
      loadedExercise,
    };
  },
  [MatchUpExerciseActions.SET_INSTRUCTIONS_LANGUAGE]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    let clonedState: CourseSliceInterface = clone(state);

    let loadedExercise: LoadedMatchUpExerciseInterface = clonedState?.loadedExercise as LoadedMatchUpExerciseInterface;

    if ((loadedExercise.exercise as MatchUpExerciseInterface).content !== undefined) {
      (loadedExercise.exercise as MatchUpExerciseInterface).content.instructionsLanguage = payload.instructionsLanguage;
    }

    (loadedExercise.exercise as MatchUpExerciseInterface).content.instructionsLanguageChanged = true;

    return {
      ...clonedState,
      loadedExercise,
    };
  },
  [MatchUpExerciseActions.SET_MATCHUP_ALL_FIELDS]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    let clonedCourseSlice: CourseSliceInterface = clone(state);

    const { values } = payload;

    clonedCourseSlice = updateTextField(clonedCourseSlice, values, 'instructions');
    clonedCourseSlice = updateTextField(clonedCourseSlice, values, 'phrase', 'fixedItem1');
    clonedCourseSlice = updateTextField(clonedCourseSlice, values, 'phrase', 'fixedItem2');
    clonedCourseSlice = updateTextField(clonedCourseSlice, values, 'phrase', 'fixedItem3');
    clonedCourseSlice = updateTextField(clonedCourseSlice, values, 'phrase', 'matchingItem1');
    clonedCourseSlice = updateTextField(clonedCourseSlice, values, 'phrase', 'matchingItem2');
    clonedCourseSlice = updateTextField(clonedCourseSlice, values, 'phrase', 'matchingItem3');
    clonedCourseSlice = updateTextField(clonedCourseSlice, values, 'feedback');

    return clonedCourseSlice;
  },
};

export default MatchUpExerciseCourseReducers;
