import { random, upperFirst } from 'lodash';
import { Fragment, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { ACTIVITY_TITLES, type ActivityType } from '@features/content/activities';
import { EXERCISE_TITLES, ExerciseType } from '@common/enums/ExerciseTypes';
import { LESSON_TITLES, type LessonTypesType } from '@common/enums/LessonTypes';
import { DBId } from '@common/types/DBId';
import { DropdownInterface, dropdown_defaultCode } from '@components/Dropdown';
import { SelectorOptionType } from '@components/SearchableSelector/SearchableSelector';
import { Loader } from '@features/theme';

import { getCourseStats } from '../CourseStatsService';
import { CourseStatsDTOItemType, CourseStatsDTOType } from '../types';
import {
  NoDataWrapper,
  TableCellSticky,
  TableCellExpanded,
  TableColHeader,
  TableRow,
  TableRowHeader,
  TableRowWithExpandedCell,
  TableWrapper,
} from './component.styles';
import { CourseStatsFilters } from './CourseStatsFilters';
import { mapDataToTable } from './CourseStatsTableMapper';

export const CourseStatsTable = () => {
  const [filterCourse, setFilterCourse] = useState<SelectorOptionType['value']>(null);
  const [filterLanguage, setFilterLanguage] = useState<DropdownInterface['code']>(dropdown_defaultCode);
  const [dirty, setDirty] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<CourseStatsDTOType | null>(null);

  const history = useHistory();
  const { courseId, languageId } = useParams<{ courseId: DBId; languageId: DBId }>();

  const onCourseFilterChange = (courseId: SelectorOptionType['value']) => {
    if (courseId) {
      setFilterCourse(courseId);
      history.replace(`/course-stats/${filterLanguage.toLocaleLowerCase()}/${courseId}`);
    }
  };

  const onLanguageFilterChange = (code: DropdownInterface['code']) => {
    setFilterLanguage(code);
    setFilterCourse(null);
    history.replace('/course-stats');
  };

  useEffect(() => {
    if (languageId && courseId) {
      setFilterLanguage(languageId.toLocaleUpperCase());
      setFilterCourse(courseId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTableData(null);
  }, [filterLanguage]);

  useEffect(() => {
    if (filterCourse) {
      setLoading(true);

      getCourseStats(filterCourse as DBId).then((result) => {
        setTableData(mapDataToTable(result));
        setLoading(false);

        if (!dirty) setDirty(true);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterCourse]);

  const getRowHeaderTitle = (key: ActivityType | ExerciseType | LessonTypesType | string, prefix?: string) => {
    if (prefix) {
      const OVERVIEW_TITLES = {
        activity: 'activities',
        chapter: 'chapters',
        exercise: 'exercises',
        lesson: 'lessons',
        grammarTopic: 'grammar topics',
      };

      return `${prefix} ${OVERVIEW_TITLES[key as keyof typeof OVERVIEW_TITLES]}`;
    }

    if (ACTIVITY_TITLES[key as ActivityType]) {
      return ACTIVITY_TITLES[key as ActivityType];
    }

    if (EXERCISE_TITLES[key as ExerciseType]) {
      return EXERCISE_TITLES[key as ExerciseType];
    }

    if (LESSON_TITLES[key as LessonTypesType]) {
      return LESSON_TITLES[key as LessonTypesType];
    }
  };

  const renderRows = (statsGroupData: CourseStatsDTOItemType = {}, options: { [key: string]: any } = {}) => {
    return Object.keys(statsGroupData).map((key) => {
      const values = statsGroupData[key];
      const rowHeaderTitle = getRowHeaderTitle(key, options.rowHeaderPrefix);
      const parentType = options.parentType || '';

      return (
        <TableRow data-testid={`row-${parentType}-${key}`} key={key}>
          <TableRowHeader scope="row">{rowHeaderTitle}</TableRowHeader>
          {values.map((value) => (
            <td key={random(0, 100000)}>{Number(value) > 0 ? value : '-'}</td>
          ))}
        </TableRow>
      );
    });
  };

  const renderTableHeader = () => {
    const tableHeaders = tableData?.meta?.colHeaders;

    return tableHeaders ? (
      <thead>
        <TableRow>
          <TableColHeader>Overview</TableColHeader>
          {tableHeaders?.map((colHeader) => (
            <TableColHeader key={colHeader} scope="col">
              {colHeader}
            </TableColHeader>
          ))}
        </TableRow>
      </thead>
    ) : null;
  };

  const renderTableBody = () => {
    const { meta, overview } = tableData || {};
    const rowHeaderColspan = meta?.colHeaders.length ? meta.colHeaders.length : undefined;

    if (tableData) {
      return (
        <tbody>
          {overview && renderRows(overview, { parentType: 'overview', rowHeaderPrefix: 'Number of' })}

          {['lesson', 'activity', 'exercise'].map((statsGroupName) => {
            const statsGroupData = tableData[statsGroupName as keyof typeof tableData];

            if (statsGroupData) {
              return (
                <Fragment key={random(0, 100000)}>
                  <TableRowWithExpandedCell>
                    <TableCellSticky>{`${upperFirst(statsGroupName)} breakdown`}</TableCellSticky>
                    <TableCellExpanded colSpan={rowHeaderColspan} />
                  </TableRowWithExpandedCell>
                  {renderRows(tableData[statsGroupName as keyof typeof tableData], { parentType: statsGroupName })}
                </Fragment>
              );
            }

            return null;
          })}
        </tbody>
      );
    }
  };

  return (
    <>
      <CourseStatsFilters
        filterCourse={filterCourse}
        filterLanguage={filterLanguage}
        onCourseFilterChange={onCourseFilterChange}
        onLanguageFilterChange={onLanguageFilterChange}
      />
      {loading && <Loader />}
      {!loading && !tableData && dirty && <NoDataWrapper>No published content</NoDataWrapper>}
      {tableData && (
        <TableWrapper hover>
          {renderTableHeader()}
          {renderTableBody()}
        </TableWrapper>
      )}
    </>
  );
};
