import { useEffect, ReactElement } from 'react';
import { Prompt, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { LoadingStage } from '@common/enums/LoadingStage';
import { CourseEditionActionsCreator } from '@actionCreators/CourseEditionActionsCreator';
import { PublishModalContainer } from '@components/CourseEdition/PublishModalContainer';
import { StringReuseModal } from '@components/StringReuseModal/StringReuseModal';
import { PublishingErrorsModal } from '@components/Publishing/PublishingErrorsModal/PublishingErrorsModal';
import { PublishingQueueSidebar } from '@components/Publishing/PublishingQueueSidebar';
import { coursesInfoRequest } from '@actionCreators/CourseActionCreator';
import { Comments } from '@features/comments';

import { PlacementTestActionCreators } from '@actionCreators/PlacementTestActionCreators';
import { NavigationPanel } from './NavigationPanel';
import { useAppDispatch, useAppSelector } from '@redux/store';
import {
  selectCourse,
  selectCoursesInfo,
  selectIsSaveButtonEnabled,
  selectStringReuseModalOpenedFor,
} from '@selectors/CoursesSelectors';
import { selectPublishingPanelVisible } from '@selectors/UiSelectors';
import { selectPlacementTest } from '@selectors/PlacementTestSelectors';

const PlacementTestWrapper = styled.div`
  width: 100%;
`;

export const PlacementTestContainer = ({ children }: { children: ReactElement }) => {
  const dispatch = useAppDispatch();
  const { courseId } = useParams<{ courseId: string }>();

  const coursesInfo = useAppSelector(selectCoursesInfo);
  const publishingPanelVisible = useAppSelector(selectPublishingPanelVisible);
  const stringReuseModalOpenedFor = useAppSelector(selectStringReuseModalOpenedFor);
  const currentCourse = useAppSelector(selectCourse);
  const isSaveButtonEnabled = useAppSelector(selectIsSaveButtonEnabled);
  const placementTest = useAppSelector(selectPlacementTest);

  useEffect(() => {
    if (coursesInfo.loaded === LoadingStage.notLoaded) dispatch(coursesInfoRequest());
  }, [dispatch, coursesInfo]);

  useEffect(() => {
    if (currentCourse.loaded === LoadingStage.notLoaded && courseId) {
      dispatch(CourseEditionActionsCreator.getCourse(courseId));
    }
  }, [dispatch, currentCourse, courseId]);

  useEffect(() => {
    if (placementTest.loaded === LoadingStage.notLoaded && courseId) {
      dispatch(PlacementTestActionCreators.getPlacementTest(courseId));
    }
  }, [dispatch, courseId, placementTest]);

  return (
    <PlacementTestWrapper>
      <Prompt when={isSaveButtonEnabled} message={() => `Are you sure you want to navigate?`} />
      <PublishModalContainer />
      {publishingPanelVisible && <PublishingQueueSidebar />}
      <PublishingErrorsModal />
      {stringReuseModalOpenedFor && <StringReuseModal />}
      <NavigationPanel />
      {placementTest.loaded === LoadingStage.loaded && placementTest.content.id && <Comments />}
      {children}
    </PlacementTestWrapper>
  );
};
