import { orderBy } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import Avatar from 'react-avatar';
import styled, { css, keyframes } from 'styled-components/macro';

import { ActionItemType, Loader } from '@features/theme';
import { useUser } from '@features/auth';
import { HelpDisplayer } from '@features/help';

import {
  addComment,
  addReaction,
  deleteComment,
  getComments,
  removeReaction,
  updateActionItemStatus,
  updateComment,
} from '../CommentsService';
import type { CommentType } from '../types';
import { CommentEditor } from './CommentEditor';
import { CommentsList } from './CommentsList';
import { useComponentId } from '../useComponentId';
import { ReactionNameType } from './CommentsList/CommentItem';

const BackDrop = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colorV2.systemOverlayBlack};
    left: 0;
    height: 100vh;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: ${theme.zIndex.backDrop};
  `}
`;

const slideAnimation = keyframes`
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0);
    }
`;

/* @TODO: Use SidePanel molecule */
const CommentsPanelWrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colorV2.commentsPanelBackground};
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: scroll;
    padding: 3.2rem 4.8rem;
    position: fixed;
    animation: ${slideAnimation} 0.3s ease-in-out forwards;
    top: 0;
    right: 0;
    width: 72.4rem;
    z-index: ${theme.zIndex.backDrop + 1};
  `}
`;
const CommentsSectionTitle = styled.h2`
  font-size: 3rem;
  font-weight: 700;
`;
const NewCommentBlock = styled.div`
  display: flex;
  margin-bottom: 2.4rem;
`;
const UserAvatar = styled(Avatar)`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-right: 1.6rem;
`;

const TitleContainer = styled.div`
  display: flex;
  gap: 0.8rem;
  align-items: center;
  margin-bottom: 2.4rem;
`;

const getSyncCommentActionItems = (actionItems: CommentType['actionItems'], actionItemId: string, state: boolean) => {
  if (!actionItems) return [];

  return actionItems.map((actionItem) => {
    if (actionItem.id === actionItemId) {
      return {
        ...actionItem,
        state,
      };
    } else {
      return actionItem;
    }
  });
};

const getCommentMessageSyncWithActionItemState = (
  message: string,
  actionItem?: Pick<ActionItemType, 'id' | 'state'>,
) => {
  if (!actionItem) return message;

  return message.replace(
    new RegExp(`-\\s\\[(\\s|x)?]\\s\\[action:${actionItem.id}\\]`, 'g'),
    `- [${actionItem.state ? 'x' : ' '}] [action:${actionItem.id}]`,
  );
};

const getCommentsSyncWithActionItems = (comments: CommentType[]) =>
  comments.map((comment) => {
    const { actionItems, message } = comment;

    if (actionItems?.length) {
      let updatedMessage = message;

      actionItems.forEach((actionItem) => {
        updatedMessage = getCommentMessageSyncWithActionItemState(message, actionItem);
      });

      return {
        ...comment,
        message: updatedMessage,
      };
    } else {
      return comment;
    }
  });

export const CommentsPanel = ({ visible, id, close }: { visible: boolean; id?: string; close: () => void }) => {
  const [busy, setBusy] = useState(false);
  const [comments, setComments] = useState<CommentType[]>([]);
  const [isCommentsLoaded, setIsCommentsLoaded] = useState(false);
  const locationBasedComponentId = useComponentId();
  const componentId = id || locationBasedComponentId;

  const { user } = useUser();

  // @TODO: Handle errors to provide a feedback to user

  const onSaveComment = useCallback(
    (message: string, mentions: string[], actionItems: ActionItemType[]) => {
      if (componentId) {
        setBusy(true);

        addComment(componentId, { message, mentions, actionItems })
          .then((result) => {
            const _actionItems: CommentType['actionItems'] = actionItems.map(({ id, state }) => ({ id, state }));

            setComments([
              {
                actionItems: _actionItems,
                author: { firstName: user?.firstName, lastName: user?.lastName, id: user?.id },
                id: result.data.id,
                message,
                reactions: [],
                sentAt: new Date().toISOString(),
              },
              ...comments,
            ]);
          })
          .catch((err) => {
            console.error(err);
          })
          .finally(() => {
            setBusy(false);
          });
      }
    },
    [componentId, comments, user],
  );

  const onCommentDelete = useCallback(
    (commentId: string) => {
      if (componentId) {
        deleteComment(componentId, commentId)
          .then(() => {
            setComments(comments.filter((comment) => comment.id !== commentId));
          })
          .catch((err) => {
            console.error(err);
          });
      }
    },
    [componentId, comments],
  );

  const onCommentUpdate = useCallback(
    (commentId: string, message: string, mentions: string[], actionItems: ActionItemType[]) => {
      if (componentId) {
        updateComment(componentId, commentId, { message, mentions, actionItems })
          .then(() => {
            setComments(
              comments.map((comment) => {
                if (comment.id === commentId) {
                  return {
                    ...comment,
                    message,
                  };
                }

                return comment;
              }),
            );
          })
          .catch((err) => {
            console.error(err);
          });
      }
    },
    [componentId, comments],
  );

  const onActionItemUpdate = useCallback(
    (commentId: string, actionItemId: string, state: boolean) => {
      if (componentId) {
        updateActionItemStatus(componentId, commentId, actionItemId, state)
          .then(() => {
            // Update `comments` state accordingly to the latest action item update
            const targetComment = comments.find((comment) => comment.id === commentId);

            if (targetComment?.actionItems) {
              const _comments = comments.map((comment) => {
                if (comment.id === targetComment.id) {
                  const _actionItems = getSyncCommentActionItems(targetComment.actionItems, actionItemId, state);
                  const updatedActionItem = _actionItems.find((_actionItem) => _actionItem.id === actionItemId);

                  return {
                    ...comment,
                    actionItems: _actionItems,
                    message: getCommentMessageSyncWithActionItemState(comment.message, updatedActionItem),
                  };
                } else {
                  return comment;
                }
              });

              setComments(_comments);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    },
    [componentId, comments],
  );

  const onAddReaction = useCallback(
    (commentId: string, reactionName: ReactionNameType) => {
      if (componentId) {
        void addReaction(componentId, commentId, reactionName);
      }
    },
    [componentId],
  );

  const onRemoveReaction = useCallback(
    (commentId: string, reactionName: ReactionNameType) => {
      if (componentId) {
        void removeReaction(componentId, commentId, reactionName);
      }
    },
    [componentId],
  );

  useEffect(() => {
    if (visible && componentId) {
      getComments(componentId).then(({ data: { comments } }) => {
        const _comments = getCommentsSyncWithActionItems(comments);

        setComments(orderBy(_comments, ['sentAt'], ['desc']));
        setIsCommentsLoaded(true);
      });
    }
  }, [componentId, visible]);

  if (!visible) {
    return null;
  }

  return (
    <>
      <CommentsPanelWrapper role="dialog">
        <TitleContainer>
          <CommentsSectionTitle>Comments</CommentsSectionTitle>
          <HelpDisplayer id="how-to-comments" type="how-to" />
        </TitleContainer>
        <NewCommentBlock>
          <UserAvatar name={`${user?.firstName} ${user?.lastName}`} size="38" />
          <CommentEditor busy={busy} id="add-comment" saveLabel="Send" onSaveComment={onSaveComment} />
        </NewCommentBlock>
        {isCommentsLoaded ? (
          <CommentsList
            comments={comments}
            onActionItemUpdate={onActionItemUpdate}
            onAddReaction={onAddReaction}
            onCommentUpdate={onCommentUpdate}
            onCommentDelete={onCommentDelete}
            onRemoveReaction={onRemoveReaction}
          />
        ) : (
          <Loader />
        )}
      </CommentsPanelWrapper>
      <BackDrop onClick={close} />
    </>
  );
};
