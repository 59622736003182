import classnames from 'classnames';
import styled, { css } from 'styled-components/macro';

import { ContentTypes } from '@common/enums/ContentTypes';
import { Button } from '@features/theme';

import { PREVIEW_DEVICE_TYPE } from '../../constants';
import { PreviewModalHeaderProps } from '../../PreviewModalProps';
import desktopIcon from './img/desktop.svg';
import tabletIcon from './img/tablet.svg';
import mobileIcon from './img/mobile.svg';
import desktopActiveIcon from './img/desktopActive.svg';
import tabletActiveIcon from './img/tabletActive.svg';
import mobileActiveIcon from './img/mobileActive.svg';

import { ReactComponent as CorrectIcon } from './img/correct.svg';
import { ReactComponent as CorrectActiveIcon } from './img/correctActive.svg';
import { ReactComponent as IncorrectIcon } from './img/incorrect.svg';
import { ReactComponent as IncorrectActiveIcon } from './img/incorrectActive.svg';
import { ReactComponent as PencilIcon } from './img/pencil.svg';
import { ReactComponent as PencilActiveIcon } from './img/pencilActive.svg';

// @TODO migrate all styles to styled-components
import './PreviewModalHeader.scss';

const AnswerStatesContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 1.2rem;
`;

const AnswerStateButton = styled(Button)<{ active: boolean }>`
  ${({ theme, active }) => css`
    background-color: ${active ? theme.colorV2.navigationButtonHoverBackground : 'inherit'};
    border-color: ${active ? theme.colorV2.navigationButtonHoverBackground : theme.colorV2.buttonTertiaryBaseBorder};
    font-size: 1.4rem;
    font-weight: 400;
    pointer-events: ${active ? 'none' : 'auto'};

    svg {
      height: 2.4rem;
      margin-right: 0.4rem;
      width: 2.4rem;

      path {
        fill: ${active ? theme.colorV2.accentPrimary : theme.colorV2.textTertiary};
      }
    }

    &:hover {
      svg path {
        fill: ${active ? 'ihnerit' : theme.colorV2.accentPrimary};
      }
    }
  `}
`;

export const PreviewModalHeader = ({
  answerState,
  deviceType,
  type,
  onModalClose,
  setDeviceType,
  setAnswerState,
}: PreviewModalHeaderProps) => {
  const typesWithoutAnswerStates: string[] = [
    ContentTypes.tip as string,
    ContentTypes.flashcard as string,
    ContentTypes.conversation as string,
    ContentTypes.comprehension as string,
    ContentTypes.speechRecognition as string,
    ContentTypes.slidePdf as string,
    ContentTypes.liveLesson as string,
  ];

  return (
    <div className="exercise-preview__header">
      <div className="exercise-preview__header__devices-types-container">
        <div
          id="preview-mobile-button"
          className={classnames('exercise-preview__header__device-container', {
            'exercise-preview__header__device-container--active': deviceType === PREVIEW_DEVICE_TYPE.MOBILE,
          })}
          onClick={() => setDeviceType(PREVIEW_DEVICE_TYPE.MOBILE)}
        >
          {deviceType === PREVIEW_DEVICE_TYPE.MOBILE ? (
            <img src={mobileActiveIcon} alt="Mobile active" />
          ) : (
            <img src={mobileIcon} alt="Mobile" />
          )}
        </div>
        <div
          id="preview-desktop-button"
          className={classnames('exercise-preview__header__device-container', {
            'exercise-preview__header__device-container--active': deviceType === 'desktop',
          })}
          onClick={() => setDeviceType(PREVIEW_DEVICE_TYPE.DESKTOP)}
        >
          {deviceType === 'desktop' ? (
            <img src={desktopActiveIcon} alt="Desktop active" />
          ) : (
            <img src={desktopIcon} alt="Desktop" />
          )}
        </div>
        <div
          id="preview-tablet-button"
          className={classnames('exercise-preview__header__device-container', {
            'exercise-preview__header__device-container--active': deviceType === 'tablet',
          })}
          onClick={() => setDeviceType(PREVIEW_DEVICE_TYPE.TABLET)}
        >
          {deviceType === 'tablet' ? (
            <img src={tabletActiveIcon} alt="Tablet active" />
          ) : (
            <img src={tabletIcon} alt="Tablet" />
          )}
        </div>
      </div>

      {!typesWithoutAnswerStates.includes(type) && (
        <AnswerStatesContainer>
          <AnswerStateButton
            active={answerState === 0}
            icon={
              answerState === 0 ? <PencilActiveIcon title="Unanswered active" /> : <PencilIcon title="Unanswered" />
            }
            size="S"
            variant={answerState === 0 ? 'secondary' : 'tertiary'}
            onClick={() => setAnswerState(0)}
          >
            Unanswered
          </AnswerStateButton>
          <AnswerStateButton
            active={answerState === 1}
            icon={
              answerState === 0 ? (
                <CorrectActiveIcon title="Correct answer active" />
              ) : (
                <CorrectIcon title="Correct answer active" />
              )
            }
            size="S"
            variant={answerState === 1 ? 'secondary' : 'tertiary'}
            onClick={() => setAnswerState(1)}
          >
            Correct
          </AnswerStateButton>
          <AnswerStateButton
            active={answerState === 2}
            icon={
              answerState === 0 ? (
                <IncorrectActiveIcon title="Incorrect answer active" />
              ) : (
                <IncorrectIcon title="Incorrect answer" />
              )
            }
            size="S"
            variant={answerState === 2 ? 'secondary' : 'tertiary'}
            onClick={() => setAnswerState(2)}
          >
            Incorrect
          </AnswerStateButton>
        </AnswerStatesContainer>
      )}
      <Button onClick={() => onModalClose()}>Close</Button>
    </div>
  );
};
