import styled from 'styled-components';

import { useOpenSearchShortcut } from '@features/search';

import { DashboardHeader } from './DashboardHeader/component';
import { DashboardSection } from './DashboardSection/component';
import { DashboardRecentCourses } from './DashboardRecentCourses/component';

const DashboardWrapper = styled.div`
  width: 100%;
`;

export const DashboardPage = () => {
  useOpenSearchShortcut();

  return (
    <DashboardWrapper>
      <DashboardHeader />
      <DashboardRecentCourses />
      <DashboardSection />
    </DashboardWrapper>
  );
};
