import { useState } from 'react';
import styled from 'styled-components/macro';

import { CERTIFICATE_LESSON_TYPE_TITLES, CertificateLessonType } from '@common/enums/LessonTypes';
import { LessonInterface } from '@common/interfaces/contentTypes/LessonInterface';
import { CommonFilterSelector, CommonFilterOptionType } from '@components/SelectorCommonComponents';
import { BaseContentActionsCreator } from '@actionCreators/BaseContentActionsCreator';
import { useDispatch } from 'react-redux';

const CertificateLessonTypeWrapper = styled.div`
  align-items: center;
  display: flex;
  font-size: 1.4rem;
  gap: 0.6rem;
  margin: 3.2rem 0 1.6rem 1.6rem;
  position: relative;
  z-index: 4000;
`;

const StyledFilterSelector = styled(CommonFilterSelector)`
  width: 25rem !important;
`;

const certificateLessonTypeOptions: CommonFilterOptionType[] = [
  {
    label: CERTIFICATE_LESSON_TYPE_TITLES.regular,
    value: 'regular',
  },
  {
    label: CERTIFICATE_LESSON_TYPE_TITLES.business,
    value: 'business',
  },
];

const DEFAULT_SELECTED_OPTION = certificateLessonTypeOptions.find((option) => option.value === 'regular');

export const CertificateLessonTypeSelector = ({ content }: { content: LessonInterface }) => {
  const { certificateType } = content;
  const initialSelectorValue = certificateType
    ? certificateLessonTypeOptions.find((option) => option.value === certificateType)
    : DEFAULT_SELECTED_OPTION;

  const [selectedCertificateType, setSelectedCertificateType] = useState<CommonFilterOptionType>(
    initialSelectorValue as CommonFilterOptionType,
  );

  const dispatch = useDispatch();

  const onSelectCertificateType = (selectedOption: CommonFilterOptionType) => {
    setSelectedCertificateType(selectedOption);

    dispatch(BaseContentActionsCreator.changeCertificateLessonType(selectedOption.value as CertificateLessonType));
  };

  return (
    <CertificateLessonTypeWrapper>
      <span>Type:</span>
      <StyledFilterSelector
        isClearable={false}
        options={certificateLessonTypeOptions}
        placeholder=""
        value={selectedCertificateType}
        onChange={(selectedOption) => onSelectCertificateType(selectedOption as CommonFilterOptionType)}
      />
    </CertificateLessonTypeWrapper>
  );
};
