import { EditorState, Modifier, SelectionState } from 'draft-js';
import 'draft-js/dist/Draft.css';
import { ContentBlock } from 'react-draft-wysiwyg';
import { KTagStrategyReturn, StartEndType } from '../Types';
import KTagStrategiesCommonUtils from './KTagStrategiesCommonUtils';

const KTagBreakIntoCharactersStrategy = (editorState: EditorState, maxCharacters: number): KTagStrategyReturn => {
  let selectionState: SelectionState = editorState.getSelection();
  let currentContent = editorState.getCurrentContent();
  let contentState: any;

  type CharacterPosition = StartEndType;

  let characterNonWhitespacePositions: number[] = [];
  let characterPositions: CharacterPosition[] = [];
  let plainText = currentContent.getPlainText();

  let block: ContentBlock = editorState.getCurrentContent().getBlocksAsArray()[0];

  function detectNonWhitespaces() {
    for (let i = selectionState.getStartOffset(); i < selectionState.getEndOffset(); i++) {
      let isNotWhitespace = plainText.split('').splice(i, 1)[0] !== ' ';
      if (isNotWhitespace) {
        characterNonWhitespacePositions.push(i);
      }
    }
  }

  function findCorrectSelectionDomains() {
    for (let i = -1; i < characterNonWhitespacePositions.length; i++) {
      if (i === -1) {
        characterPositions.push({
          start: selectionState.getStartOffset(),
          end: characterNonWhitespacePositions[0] + 1,
        });
      } else {
        if (i === 0) continue;

        let currentWhitespace = characterNonWhitespacePositions[i];
        let nextCharacterNonWhitespace =
          i + 1 === characterNonWhitespacePositions.length
            ? selectionState.getEndOffset()
            : characterNonWhitespacePositions[i] + 1;

        characterPositions.push({
          start: currentWhitespace,
          end: nextCharacterNonWhitespace,
        });
      }
    }
  }

  function createVisualSelections() {
    characterPositions.forEach((selectionDomain: CharacterPosition, newFoundBlocks: number) => {
      let CSSClassName;
      let selection = SelectionState.createEmpty(block.getKey());
      contentState = editorState.getCurrentContent();

      selection = selection.merge({
        anchorOffset: selectionDomain.start,
        focusOffset: selectionDomain.end,
      });

      const totalBlocks = KTagStrategiesCommonUtils.calculateTotalBlocks(contentState);

      if (totalBlocks < maxCharacters) {
        if (totalBlocks % 2) {
          CSSClassName = 'KTAG_GROUP';
        } else {
          CSSClassName = 'KTAG_GROUP2';
        }
      } else {
        if (totalBlocks % 2) {
          CSSClassName = 'KTAG_BAD';
        } else {
          CSSClassName = 'KTAG_BAD2';
        }
      }

      contentState = Modifier.applyInlineStyle(contentState, selection, CSSClassName);

      if (contentState !== undefined) {
        editorState = EditorState.push(editorState, contentState, 'change-inline-style');
      }
    });
  }

  detectNonWhitespaces();
  findCorrectSelectionDomains();
  createVisualSelections();

  return {
    editorState,
    totalBlocks: KTagStrategiesCommonUtils.calculateTotalBlocks(contentState),
  };
};

export default KTagBreakIntoCharactersStrategy;
