import fileDownload from 'js-file-download';

import { apiClient } from '@features/api';
import { DBId } from '@common/types/DBId';
import { CourseStatsType } from './types';

export const getCourseStats = async (courseId: DBId) => {
  const response = await apiClient.noErrorsV2.get(`/courses/${courseId}/stats`);

  return response.data as CourseStatsType;
};

export const generateXlsxFile = async (language: string) => {
  const response = await apiClient.noErrorsV2.get(`/courses/stats/${language}.xlsx`, { responseType: 'blob' });
  fileDownload(response.data, `${language}_stats.xlsx`);

  return response;
};

export const generateSAndSXlsxFile = async (language: string) => {
  const response = await apiClient.noErrorsV2.get(`/courses/scope-sequence/${language}.xlsx`, { responseType: 'blob' });
  fileDownload(response.data, `${language}_scope-sequence.xlsx`);

  return response;
};
