import { apiClient } from '@features/api';

import { LessonSearchResultType } from '../SearchResultItem';

import type { LessonQueryParamsType } from './types';

const getQueryParams = ({
  languages,
  courses,
  lessonTypes,
  lessonFocuses,
  hasImage,
  hasThumbnailImage,
  hasIdentityGroup,
  isAttachedToGrammarTopic,
  isPublished,
  isReused,
  activityTypes,
  numActivities,
}: LessonQueryParamsType) => {
  return {
    languages: languages || undefined,
    courses: courses || undefined,
    types: lessonTypes || undefined,
    focuses: lessonFocuses || undefined,
    activityTypes: activityTypes || undefined,
    hasImage: hasImage !== null ? hasImage : undefined,
    hasThumbnailImage: hasThumbnailImage !== null ? hasThumbnailImage : undefined,
    hasIdentityGroup: hasIdentityGroup !== null ? hasIdentityGroup : undefined,
    isAttachedToGrammarTopic: isAttachedToGrammarTopic !== null ? isAttachedToGrammarTopic : undefined,
    isPublished: isPublished !== null ? isPublished : undefined,
    isReused: isReused !== null ? isReused : undefined,
    fromActivitiesCount: typeof numActivities?.min === 'number' ? numActivities.min : undefined,
    toActivitiesCount: typeof numActivities?.max === 'number' ? numActivities.max : undefined,
  };
};

export const searchByLesson = async (
  query: string,
  page: number,
  filtersParams: LessonQueryParamsType,
): Promise<{ data: { results: LessonSearchResultType[]; count: number } }> => {
  return await apiClient.noErrorsV2.get('/search/lessons', {
    params: {
      query,
      page,
      ...getQueryParams(filtersParams),
    },
  });
};
