import { call, put, select } from 'redux-saga/effects';
import SpeechRecognitionExerciseInterface from '@components/Exercises/SpeechRecognition/interfaces/SpeechRecognitionExerciseInterface';
import SpeechRecognitionExerciseService from '@services/ExerciseRelatedServices/SpeechRecognitionExerciseService';
import ExerciseSagas from '@sagas/exercises/definitions/ExerciseSagas';
import { Language } from '@features/content/languages';
import { SpeechRecognitionExerciseActions } from '@actions/SpeechRecognitionExerciseActions';
import { FormikValuesInterface } from '@helpers/formikInitialValuesHelper';
import type { AppDispatch } from '@redux/store';
import { selectLoadedExerciseData } from '@selectors/CoursesSelectors';

const SpeechRecognitionExerciseSagas = {
  *save(
    dispatch: AppDispatch,
    {
      type,
      payload,
    }: {
      type: string;
      payload: {
        exercise: SpeechRecognitionExerciseInterface;
        learningLanguage: Language;
        values: FormikValuesInterface;
      };
    },
  ) {
    let { values } = payload;

    yield put({
      type: SpeechRecognitionExerciseActions.SET_SPEECH_RECOGNITION_ALL_FIELDS,
      payload: {
        values,
      },
    });
    let exercise: SpeechRecognitionExerciseInterface = yield select(selectLoadedExerciseData);
    yield call(SpeechRecognitionExerciseService.save, dispatch, exercise, values);

    yield call(ExerciseSagas.requestOneExerciseCommon);
  },
};

export default SpeechRecognitionExerciseSagas;
