import { ContentTypes } from '@common/enums/ContentTypes';
import slidePdfIcon from '@components/ContentTypes/BaseContent/img/slidePdf.svg';
import slidePptxIcon from '@components/ContentTypes/BaseContent/img/slidePptx.svg';
import { ContentCreatorWidget, type ContentCreatorWidgetItemType } from '@features/theme';

type SlideshowCreatorItemType = typeof ContentTypes.slidePdf | typeof ContentTypes.slidePptx;

const SLIDESHOW_CREATOR_ITEMS: ContentCreatorWidgetItemType<SlideshowCreatorItemType>[] = [
  {
    label: 'PDF Slideshow',
    iconSrc: slidePdfIcon,
    showByDefault: true,
    type: ContentTypes.slidePdf,
  },
  {
    label: 'PPTX Presentation',
    iconSrc: slidePptxIcon,
    showByDefault: true,
    type: ContentTypes.slidePptx,
  },
];

type SlideshowCreatorProps = {
  isCreateInProgress?: boolean;
  isPreEnabled: boolean;
  isEditAvailable: boolean;
  position: number;
  onCreateContent: (itemType: SlideshowCreatorItemType, itemCategory: null) => void;
};

export const SlideshowCreator = ({
  isCreateInProgress = false,
  isEditAvailable,
  isPreEnabled,
  position,
  onCreateContent,
}: SlideshowCreatorProps) => (
  <ContentCreatorWidget
    isCreateInProgress={isCreateInProgress}
    isEditAvailable={isEditAvailable}
    isPreEnabled={isPreEnabled}
    items={SLIDESHOW_CREATOR_ITEMS}
    position={position}
    title="Add a live lesson material"
    onItemSelected={(item) => onCreateContent(item.type, null)}
  />
);
