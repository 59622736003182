import styled from 'styled-components';

import { ProfileImage } from '@components/RolesAndPermissions/ProfileImage/ProfileImage';
import { Logo } from '@features/app/logo';

const ProgressMatchingHeaderWrapper = styled.header`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 4rem 0 6rem;
  max-height: 5.5rem;
`;

export const ProgressMatchingHeader = () => (
  <ProgressMatchingHeaderWrapper>
    <Logo size="large" withLink={true} />
    <ProfileImage />
  </ProgressMatchingHeaderWrapper>
);
