import { call, put, select } from 'redux-saga/effects';
import MatchUpExerciseInterface from '@components/Exercises/MatchUp/interfaces/MatchUpExerciseInterface';
import MatchUpExerciseService from '@services/ExerciseRelatedServices/MatchUpExerciseService';
import ExerciseSagas from '@sagas/exercises/definitions/ExerciseSagas';
import { Language } from '@features/content/languages';
import { MatchUpExerciseActions } from '@actions/MatchUpExerciseActions';
import { FormikValuesInterface } from '@helpers/formikInitialValuesHelper';
import type { AppDispatch } from '@redux/store';
import { selectLoadedExerciseData } from '@selectors/CoursesSelectors';

const MatchUpExerciseSagas = {
  *save(
    dispatch: AppDispatch,
    {
      type,
      payload,
    }: {
      type: string;
      payload: {
        exercise: MatchUpExerciseInterface;
        learningLanguage: Language;
        values: FormikValuesInterface;
      };
    },
  ) {
    let { values } = payload;

    yield put({
      type: MatchUpExerciseActions.SET_MATCHUP_ALL_FIELDS,
      payload: {
        values,
      },
    });
    let exercise: MatchUpExerciseInterface = yield select(selectLoadedExerciseData);

    yield call(MatchUpExerciseService.save, dispatch, exercise, values);

    yield call(ExerciseSagas.requestOneExerciseCommon);
  },
};

export default MatchUpExerciseSagas;
