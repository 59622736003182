import { useContext, ReactElement } from 'react';
import { Redirect, RouteProps, useHistory } from 'react-router-dom';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { LoginCallback as OktaLoginCallback, Security } from '@okta/okta-react';
import { RestoreOriginalUriFunction } from '@okta/okta-react/bundles/types/OktaContext';
import { getConfig } from '@features/config';
import { SecurityContext } from './context';
import { AuthConfig } from './config';
import { SecureRouteTemplate } from './SecureRoute';
import { SecurityInterface } from './security';

let client: OktaAuth;

const getClient = (): OktaAuth => {
  if (!client) {
    const config = getConfig<AuthConfig>('auth');

    client = new OktaAuth({
      scopes: ['openid', 'profile', 'email', 'offline_access'],
      issuer: config.issuer as string,
      tokenManager: {
        autoRenew: true,
      },
      clientId: config.clientId,
      redirectUri: window.location.origin + '/login/callback',
    });

    client.start().then(() => console.info('Okta is handling token expiration'));
  }

  client.tokenManager.on('expired', (key: string) => {
    console.info('Expired token: ', key);
    client.tokenManager.renew(key);
  });

  return client;
};

const LoginCallback = () => <OktaLoginCallback />;

const LogoutCallback = () => {
  const { setUser } = useContext(SecurityContext);

  setUser(null);

  return <Redirect to="/" />;
};

const SecurityProvider = ({ children }: { children: ReactElement }) => {
  const history = useHistory();

  const restoreOriginalUri: RestoreOriginalUriFunction = async (oktaAuth: OktaAuth, originalUri: string) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  return (
    <Security oktaAuth={getClient()} restoreOriginalUri={restoreOriginalUri}>
      {children}
    </Security>
  );
};

const SecureRoute = (props: RouteProps) => {
  const { user, refreshUser } = useContext(SecurityContext);

  return (
    <SecureRouteTemplate
      routeProps={props}
      user={user}
      handleAuthorization={async () => {
        const client = getClient();
        const isAuthenticated = await client.isAuthenticated();

        if (isAuthenticated) {
          await refreshUser();
        } else {
          await client.signInWithRedirect({
            redirectUri: window.location.origin + '/login/callback',
          });
        }
      }}
    />
  );
};

export class OktaSecurity implements SecurityInterface {
  SecurityProvider = SecurityProvider;
  LoginCallback = LoginCallback;
  LogoutCallback = LogoutCallback;
  SecureRoute = SecureRoute;

  getAuthorizationHeader(): string {
    const accessToken = getClient().getAccessToken();

    // @TODO I don't think we should fallback to hardcoded token
    return accessToken ? `Bearer ${accessToken}` : process.env.REACT_APP_AUTHORIZATION_TOKEN || '';
  }

  logout(): void {
    const logoutUrl = window.location.origin + '/logout';

    getClient().signOut({ postLogoutRedirectUri: logoutUrl });

    localStorage.removeItem('okta-token-storage');
  }
}
