import { useEffect, useMemo, useState } from 'react';

import { PublishingActionsCreator } from '@actionCreators/PublishingActionCreator';
import { ContentTypes, type ContentTypesType } from '@common/enums/ContentTypes';
import { DBId } from '@common/types/DBId';
import { useIsPublishAvailable } from '@features/auth/roles';

import { AnyExerciseContentInterface } from '@common/types/exercises/AnyExerciseContentInterface';
import { EntrypointListItemType, PlacementTestContentType } from '../PlacementTest';
import { ExerciseListItemType } from '@components/ContentTypes/ExerciseCard/types';
import { ExerciseTypes, type ExerciseTypesType } from '@common/enums/ExerciseTypes';
import { CommonPublishButton } from '@features/content/header';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { selectIsPublishingInProgress } from '@selectors/CoursesSelectors';

const PUBLISHED_EXERCISES_MIN_FOR_ENTRYPOINT = 3;

type PublishButtonProps = {
  contentToPublish: PlacementTestContentType | AnyExerciseContentInterface;
  parentId: DBId | undefined;
  childrenArray: (EntrypointListItemType | ExerciseListItemType)[];
};

export const PublishButton = ({ contentToPublish, parentId, childrenArray }: PublishButtonProps) => {
  const [tooltipText, setTooltipText] = useState('');
  const dispatch = useAppDispatch();

  const isPublishingInProgress = useAppSelector(selectIsPublishingInProgress);
  const { publish: isPublishAvailable, unpublish: isUnpublishAvailable } = useIsPublishAvailable();

  const isContentInvalid = useMemo(() => {
    if (Object.values(ExerciseTypes).includes(contentToPublish.type as any)) {
      return !(contentToPublish as AnyExerciseContentInterface)?.validation?.valid;
    } else {
      return !contentToPublish?.validationStatus?.valid;
    }
  }, [contentToPublish]);

  const getTypeName = (type: ContentTypesType | ExerciseTypesType) => {
    if (type === ContentTypes.placementTest) {
      return 'placement test';
    } else if (type === ContentTypes.entrypoint) {
      return 'entrypoint';
    } else if (Object.values(ExerciseTypes).includes(type as any) || type === ContentTypes.exercise) {
      return 'exercise';
    } else {
      return 'unkown type';
    }
  };

  const isButtonDisabled = () => {
    if (contentToPublish.ready) {
      return false;
    }

    if (
      contentToPublish.type === ContentTypes.entrypoint &&
      childrenArray.filter((group) => group.ready).length < PUBLISHED_EXERCISES_MIN_FOR_ENTRYPOINT
    ) {
      return true;
    }

    if (
      !Object.values(ExerciseTypes).includes(contentToPublish.type as any) &&
      !childrenArray.filter((group) => group.ready).length
    ) {
      return true;
    }

    return isContentInvalid;
  };

  useEffect(() => {
    const getChildName = (type: ContentTypesType | ExerciseTypesType) => {
      switch (type) {
        case ContentTypes.placementTest: {
          return ContentTypes.entrypoint;
        }
        case ContentTypes.entrypoint: {
          return ContentTypes.exercise;
        }
        default:
          return ContentTypes.entrypoint;
      }
    };

    if (contentToPublish?.ready) {
      setTooltipText('');
    } else {
      if (
        contentToPublish.type === ContentTypes.entrypoint &&
        childrenArray.filter((group) => group.ready).length < PUBLISHED_EXERCISES_MIN_FOR_ENTRYPOINT
      ) {
        setTooltipText(
          `To publish this ${getTypeName(contentToPublish.type)}, it must contain at least 3 published ${getTypeName(
            getChildName(contentToPublish.type),
          )}.`,
        );
      } else if (
        !Object.values(ExerciseTypes).includes(contentToPublish.type as any) &&
        !childrenArray.filter((group) => group.ready).length
      ) {
        setTooltipText(
          `To publish this ${getTypeName(contentToPublish.type)}, it must contain at least 1 published ${getTypeName(
            getChildName(contentToPublish.type),
          )}.`,
        );
      } else {
        setTooltipText('');
      }
    }
  }, [contentToPublish, childrenArray]);

  const handleButtonClick = (forUpdate: boolean) => {
    dispatch(
      PublishingActionsCreator.setContentToPublish(
        contentToPublish.id || '',
        contentToPublish.type,
        contentToPublish.ready,
        forUpdate,
        parentId,
        ContentTypes.entrypoint,
      ),
    );
  };

  return (
    <CommonPublishButton
      contentType={getTypeName(contentToPublish.type)}
      isButtonDisabled={isButtonDisabled()}
      isPublishAvailable={isPublishAvailable}
      isPublishedContent={contentToPublish?.ready}
      isPublishingInProgress={isPublishingInProgress}
      isUpdateVisible={contentToPublish?.ready && contentToPublish?.changeStatus.hasNewChanges}
      isUpdateButtonDisabled={isContentInvalid}
      isUnpublishVisible={isUnpublishAvailable && contentToPublish?.ready}
      tooltipText={tooltipText}
      onPublishClick={handleButtonClick}
    />
  );
};
