import { SectionActions } from '@actions/SectionActions';
import { ExerciseTypes } from '@common/enums/ExerciseTypes';
import { LoadingStage } from '@common/enums/LoadingStage';
import { CourseSliceInterface } from '@common/interfaces/slices/CourseSliceInterface';
import SlidePdfExerciseInterface from '@components/Exercises/SlidePdf/interfaces/SlidePdfExerciseInterface';
import { selectedGroupsOfParentInitialData } from '@redux/initialStates/courseInitialState';
import { SlidePdfExerciseActions } from '@actions/SlidePdfExerciseActions';
import { PayloadAction } from '@reduxjs/toolkit';

const SlideshowExerciseCourseReducers = {
  [SectionActions.COURSE_EXERCISE_SLIDE_PDF_REQUEST_SUCCEEDED]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    let { content }: SlidePdfExerciseInterface = payload;

    let exercise: SlidePdfExerciseInterface = {
      ...state.loadedExercise.exercise,
      content,
      type: ExerciseTypes.slidePdf,
    };

    return {
      ...state,
      selectedGroupsOfParent: selectedGroupsOfParentInitialData,
      loadedExercise: {
        loaded: LoadingStage.loaded,
        exercise,
      },
    };
  },
  [SlidePdfExerciseActions.SET_PDF]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    const { pdfFile, pdfFileId } = payload;

    state.loadedExercise.exercise.content.pdfFileId = pdfFileId;
    state.loadedExercise.exercise.content.pdfUrl = pdfFile;
    state.loadedExercise.exercise.content.pdfUrlChanged = true;

    return state;
  },
};

export default SlideshowExerciseCourseReducers;
