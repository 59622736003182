import { useState } from 'react';

import { GrammarCategoryResultCard } from './SearchResultItemCard';
import { GrammarCategoryResultDetails } from './SearchResultItemDetails';
import { getGrammarCategoryResultDetails } from './services';
import {
  GrammarCategorySearchResultType,
  GrammarCategorySearchResultDetailsType,
  GrammarCategoryDetailsTab,
} from './types';

export const GrammarCategorySearchResultItem = ({ content }: { content: GrammarCategorySearchResultType }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [details, setDetails] = useState<GrammarCategorySearchResultDetailsType | null>(null);
  const [isDetailsLoaded, setIsDetailsLoaded] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [defaultTab, setDefaultTab] = useState<GrammarCategoryDetailsTab['key'] | null>(null);

  const onExpand = (tabName: GrammarCategoryDetailsTab['key'] | null) => {
    setDefaultTab(tabName);
    setIsExpanded(!isExpanded);
    if (!isExpanded && !details) {
      getGrammarCategoryResultDetails(content.id)
        .then(({ data }) => {
          setIsDetailsLoaded(true);
          setDetails(data);
        })
        .catch((error) => {
          setIsDetailsLoaded(true);
          setDetails(null);
          if (error.response.data.detail) {
            setErrorMessage(error.response.data.detail);
          } else {
            setErrorMessage(error.response.data.message || 'Unknown error');
          }
        });
    }
  };

  return (
    <div data-testid="search-result-item-category">
      <GrammarCategoryResultCard content={content} isExpanded={isExpanded} onExpand={onExpand} />
      <GrammarCategoryResultDetails
        defaultTab={defaultTab}
        isExpanded={isExpanded}
        isLoaded={isDetailsLoaded}
        errorMessage={errorMessage}
        details={details}
      />
    </div>
  );
};
