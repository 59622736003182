import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import { Loader } from '@features/theme';

const LinkedModalFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
`;

const LinkedModalButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4.8rem;
  border-radius: 2.4rem;
  margin: 0 0 0 1.6rem;
  font-weight: 700;
  font-size: 1.6rem;
`;

const LinkedModalCancelButton = styled(LinkedModalButton)`
  border: 0.2rem solid ${({ theme }) => theme.color.brandPrimaryBase};
  background: ${({ theme }) => theme.color.brandWhite};
  color: ${({ theme }) => theme.color.brandPrimaryBase};

  &:hover {
    opacity: 0.9;
    background: ${({ theme }) => theme.color.brandWhite};
    color: ${({ theme }) => theme.color.brandPrimaryBase};
  }
`;

const LinkedModalSaveButton = styled(LinkedModalButton)`
  color: ${({ theme }) => theme.color.brandWhite};
  ${({ disabled }) =>
    disabled &&
    `
      opacity: 0.5;

      &:hover {
        cursor: default;
      }
    `}
`;

export const IdentityGroupModalFooter = ({
  isChanged = false,
  isSaveInProgress,
  onClose,
  onSave,
}: {
  isChanged: boolean;
  isSaveInProgress: boolean;
  onClose: () => void;
  onSave: () => void;
}) => (
  <LinkedModalFooter>
    <LinkedModalCancelButton data-testid="identity-group-lessons-modal-close-button" onClick={onClose}>
      Cancel
    </LinkedModalCancelButton>
    <LinkedModalSaveButton
      data-testid="identity-group-lessons-modal-save-button"
      disabled={!isChanged}
      onClick={onSave}
    >
      {isSaveInProgress ? <Loader /> : 'Save'}
    </LinkedModalSaveButton>
  </LinkedModalFooter>
);
