import styled from 'styled-components/macro';

const DefaultPlaceholderContainer = styled.span`
  font-style: italic;
  font-size: 1.6rem;
  color: ${({ theme }) => theme.colorV2.textQuaternary};
`;

export const DefaultPlaceholder = ({ children }: { children: React.ReactNode }) => {
  return <DefaultPlaceholderContainer>{children}</DefaultPlaceholderContainer>;
};
