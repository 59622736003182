import { useEffect, useState } from 'react';

import { DropdownInterface } from '@components/Dropdown';
import { SelectorOptionType } from '@components/SearchableSelector/SearchableSelector';
import { ExperimentSelectorView } from './ExperimentSelectorView';
import { getExperiments, getExperimentVariations } from './ExperimentService';
import {
  REFRESH_CODE,
  OFF_VARIATION_CODE,
  noExperimentOption,
  refreshExperimentOption,
  refreshExperimentVariationOption,
  offVariationOption,
} from './constants';

export type ExperimentType = { key: string; name: string; variationKey: string; variationName: string };

export type ExperimentSelectorProps = {
  isEditAvailable: boolean;
  currentExperiment: ExperimentType | null;
  onExperimentChange?: (experiment: ExperimentType | null) => void;
};

export const ExperimentSelector = ({
  onExperimentChange,
  isEditAvailable,
  currentExperiment,
}: ExperimentSelectorProps) => {
  const [selectedExperiment, setSelectedExperiment] = useState(currentExperiment?.key || null);
  const [experiments, setExperiments] = useState<SelectorOptionType[]>([]);
  const [isExperimentsLoaded, setIsExperimentsLoaded] = useState(false);

  const [selectedExperimentVariation, setSelectedExperimentVariation] = useState(
    currentExperiment?.variationKey || OFF_VARIATION_CODE,
  );
  const [experimentVariations, setExperimentVariations] = useState<DropdownInterface[]>([offVariationOption]);
  const [isExperimentVariationsLoaded, setIsExperimentVariationsLoaded] = useState(false);

  useEffect(() => {
    if (!isExperimentsLoaded) {
      getExperiments(selectedExperiment === REFRESH_CODE).then(({ data }) => {
        setIsExperimentsLoaded(true);
        setExperiments(data.map((result) => ({ value: result.key, label: result.name })));
      });
    }
  }, [isExperimentsLoaded, selectedExperiment]);

  useEffect(() => {
    if (selectedExperiment !== null && selectedExperiment !== REFRESH_CODE && !isExperimentVariationsLoaded) {
      getExperimentVariations(selectedExperiment, selectedExperimentVariation === REFRESH_CODE).then(({ data }) => {
        setIsExperimentVariationsLoaded(true);
        setExperimentVariations(data.map((result) => ({ code: result.key, name: result.name })));
      });
    }
  }, [selectedExperiment, isExperimentVariationsLoaded, selectedExperimentVariation]);

  const onExperimentSelect = (option: SelectorOptionType | null) => {
    if (option) {
      setSelectedExperiment(option.value);

      if (option.value === REFRESH_CODE) {
        setIsExperimentsLoaded(false);
      } else {
        const optionValue = option.value
          ? { key: option.value, name: option.label, variationKey: 'off', variationName: 'Off' }
          : null;
        onExperimentChange && onExperimentChange(optionValue);
      }

      setIsExperimentVariationsLoaded(false);
      setSelectedExperimentVariation('off');
    }
  };

  const onExperimentVariationSelect = (option: DropdownInterface) => {
    setSelectedExperimentVariation(option.code);

    if (option.code === REFRESH_CODE) {
      setIsExperimentVariationsLoaded(false);
    } else {
      if (currentExperiment) {
        onExperimentChange &&
          onExperimentChange({
            key: currentExperiment.key,
            name: currentExperiment.name,
            variationKey: option.code,
            variationName: option.name as string,
          });
      }
    }
  };

  return (
    <ExperimentSelectorView
      experiments={[noExperimentOption, refreshExperimentOption, ...experiments]}
      experimentSelectorDisabled={!isEditAvailable || !isExperimentsLoaded}
      onExperimentSelect={onExperimentSelect}
      selectedExperiment={
        experiments.find((experiment) => experiment.value === selectedExperiment)?.label ||
        currentExperiment?.name ||
        ''
      }
      isExperimentVariationSelectorVisible={selectedExperiment !== null && selectedExperiment !== REFRESH_CODE}
      experimentVariations={[refreshExperimentVariationOption, ...experimentVariations]}
      experimentVariationSelectorDisabled={!isEditAvailable || !isExperimentVariationsLoaded}
      onExperimentVariationSelect={onExperimentVariationSelect}
      selectedExperimentVariation={
        (experimentVariations.find((experimentVariation) => experimentVariation.code === selectedExperimentVariation)
          ?.name as string) ||
        currentExperiment?.variationName ||
        ''
      }
    />
  );
};
