import { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import ReactTooltip from 'react-tooltip';
import { Button } from 'react-bootstrap';
import classnames from 'classnames';
import styled from 'styled-components/macro';
import { apiClient } from '@features/api';
import { FailedUploadingMessage } from '../FailedUploadingMessage/FailedUploadingMessage';
import { ImageUploadModes } from '@common/enums/FileUploadModes';
import { useIsEditAvailable } from '@features/content/courses';

import { ReactComponent as ImgUploadIcon } from '@static/svg/upload.svg';
import TrashIcon from '@static/svg/Trash.svg';
import { Sizes } from '@common/enums/Sizes';
import XIcon from '@static/svg/x-icon.svg';
import ImageUploadProps from './ImageUploadProps';
import { random } from 'lodash';
import { HelpDisplayer } from '@features/help';
import { constants as contentConstants } from '@features/content';
import { selectIsIssuesShown } from '@selectors/UiSelectors';
import { useAppSelector } from '@redux/store';
import { selectImageOrVideoUploadingFailed, selectImageOrVideoUploadingInProgress } from '@selectors/CoursesSelectors';

const { MEDIA_STATUS_REQUEST_INTERVAL, MEDIA_STATUS_REQUEST_ATTEMPTS } = contentConstants;

const Image = styled('img')({
  position: 'absolute',
  height: '100%',
  width: '100%',
});

const RemoveImage = styled.img`
  margin: 0 !important;
`;

const Instructions = styled.div<{ isFlex: boolean }>`
  display: ${({ isFlex }) => (isFlex ? 'flex' : 'block')};
  align-items: center;
  gap: 0.5rem;
  color: ${({ theme }) => theme.color.brandGreyscale800};
  font-size: 1.4rem;
  text-align: center;
`;

const ImageUpload = ({
  text = '(Images must be at least 1920 pixels in width, 5MB max, .jpg supported)',
  size = Sizes.large,
  onClick,
  onChange,
  onRemove,
  imageData,
  withHelp = true,
  mode = ImageUploadModes.normal,
  onProcessingFinished,
  trashButtonAvailableToUser = true,
  previewMode = true,
  className,
  isForExercise = false,
  errors = [],
  isForLesson = false,
  fieldName = '',
  viaTooltip = false,
  disabled = false,
  isChangeBlocked = false,
  onChangeInstant,
  accept = 'image/jpeg',
}: ImageUploadProps) => {
  // map new media format fields
  const contentId = imageData?.contentId || imageData?.id;
  const mediaId = imageData?.mediaId || imageData?.localizationId;
  const mediaValue = imageData?.mediaValue || imageData?.value;
  const processed = imageData?.processed;

  const { isEditAvailable } = useIsEditAvailable();
  const [uniqueId] = useState(random(0, 10000));
  const [progress, setProgress] = useState(0);
  const [runInterval, setRunInterval] = useState(false);
  const [isFirstRequestPending, setIsFirstRequestPending] = useState(false);
  const [image, setImage] = useState(mediaValue || '');
  const [processing, setProcessing] = useState(mediaId && !processed);

  const isIssuesShown = useAppSelector(selectIsIssuesShown);

  const imageOrVideoUploadingInProcess: string[] = useAppSelector(selectImageOrVideoUploadingInProgress);
  const imageOrVideoUploadingFailed = useAppSelector(selectImageOrVideoUploadingFailed);
  const errorDetails = imageOrVideoUploadingFailed.find((item) => item.field === fieldName);

  const removeImage = () => {
    setImage('');
    setProcessing(false);
  };

  const getStatus = useCallback(() => {
    apiClient.v2.get(`content/resources/${contentId}/image/${mediaId}/status`).then((data) => {
      if (data.data.processed) {
        setImage(data.data.url);
        onProcessingFinished && onProcessingFinished(data.data.url);
        setProcessing(false);
      } else {
        setRunInterval(true);
      }
    });
  }, [contentId, mediaId, onProcessingFinished]);

  useEffect(() => {
    if (processing && !isFirstRequestPending) {
      setIsFirstRequestPending(true);
      getStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFirstRequestPending, processing]);

  useEffect(() => {
    if (runInterval) {
      const intervalId = setInterval(() => {
        apiClient.v2.get(`content/resources/${contentId}/image/${mediaId}/status`).then((data) => {
          if (data.data.processed) {
            setImage(data.data.url);
            onProcessingFinished && onProcessingFinished(data.data.url);
            setProcessing(false);
            clearInterval(intervalId);
            setRunInterval(false);
          }
        });
      }, MEDIA_STATUS_REQUEST_INTERVAL);

      setTimeout(() => {
        clearInterval(intervalId);
      }, MEDIA_STATUS_REQUEST_INTERVAL * MEDIA_STATUS_REQUEST_ATTEMPTS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentId, mediaId, runInterval]);

  useEffect(() => {
    setImage(mediaValue || '');
    setProcessing(mediaId && !processed);
  }, [imageData, mediaId, mediaValue, processed]);

  const isValidationErrorsShown = errors && errors.length && isIssuesShown;

  const isDragAndDropDisabled = image || !isEditAvailable;
  const onDropHandler = (file: File) => {
    if (isChangeBlocked) {
      onChangeInstant && onChangeInstant();
    } else {
      onChange && onChange(file, setProgress);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop: (files) => onDropHandler(files[0]) });

  let trashFragment = (
    <>
      {trashButtonAvailableToUser && (
        <Button
          variant="light"
          disabled={disabled}
          onClick={() => {
            if (isChangeBlocked) {
              onChangeInstant && onChangeInstant();
            } else {
              removeImage();
              onRemove && onRemove();
              setProgress(0);
            }
          }}
          className={isForLesson ? 'file-upload__remove-image' : 'file-upload__trash'}
        >
          <RemoveImage src={isForLesson ? XIcon : TrashIcon} alt="Upload different media" />
        </Button>
      )}
    </>
  );

  const progressCircleValue = 180 + (360 * (1 - progress / 100)) / 2;

  return (
    <>
      <div
        className={classnames(
          `${className}`,
          'file-upload',
          `file-upload--${size}`,
          `file-upload--${mode}`,
          { 'file-uploaded': image && isForExercise },
          { 'file-upload--without-border': image },
          { 'file-upload--for-lesson': isForLesson },
          { 'file-upload--hoverable': !!onClick },
          { 'file-upload--with-error': isValidationErrorsShown || errorDetails },
          { 'file-upload--with-error--failed': errorDetails },
          { 'file-upload--dragging': isDragActive },
          { 'file-upload--with-rounded-progress': isForLesson && mode === ImageUploadModes['no-text'] },
          { 'file-upload--upload-in-progress': imageOrVideoUploadingInProcess.includes(fieldName) },
        )}
        data-tip={text}
        data-for={`image-uploader-${fieldName}`}
        {...(isDragAndDropDisabled ? null : getRootProps())}
        onClick={(e) => {
          if (isDragAndDropDisabled) {
            onClick && onClick();
          } else {
            if (isChangeBlocked) {
              onChangeInstant && onChangeInstant();
            } else {
              const { onClick: dropOnClick } = getRootProps();
              if (onClick) {
                onClick();
              } else {
                dropOnClick && dropOnClick(e);
              }
            }
          }
        }}
      >
        {image && !isForExercise && !isForLesson && <Image src={image} alt="" />}
        {viaTooltip && !image && (
          <ReactTooltip className="tooltip" id={`image-uploader-${fieldName}`} effect="solid" place="bottom" />
        )}
        {image && (isForExercise || isForLesson) ? (
          <img
            className={classnames(
              { 'file-upload__uploaded-image': isForExercise },
              { 'file-upload__uploaded-image--for-lesson': isForLesson },
            )}
            src={image}
            alt=""
          />
        ) : null}
        {mode === ImageUploadModes['normal'] && !image && (
          <>
            {imageOrVideoUploadingInProcess.includes(fieldName) ? (
              <div className="file-upload__progress-container">
                <span>Uploading ... {progress}%</span>
                <progress id="progressBar" value={progress} max="100"></progress>
              </div>
            ) : (
              <>
                {isEditAvailable ? (
                  <>
                    <ImgUploadIcon />

                    <Instructions isFlex={withHelp}>
                      <span className="file-upload__instructions__description">Drag & drop your image here, or </span>
                      <label htmlFor={isChangeBlocked ? '' : `browse-${uniqueId}`} className="file-upload__browse">
                        browse
                      </label>
                      <span className="file-upload__instructions__description"> to upload.</span>
                      <input
                        {...getInputProps()}
                        type="file"
                        className="file-upload__file"
                        disabled={!isEditAvailable || disabled}
                        id={`browse-${uniqueId}`}
                        name="browse"
                        accept={accept}
                      />
                      {withHelp && <HelpDisplayer type="guideline" id="guideline-upload-image" />}
                    </Instructions>

                    {!viaTooltip && <span className="file-upload__dimensions">{text}</span>}
                  </>
                ) : (
                  <span className="file-upload__instructions__description">Get edit permissions to upload images</span>
                )}
              </>
            )}
          </>
        )}
        {mode === ImageUploadModes['normal'] && image && isEditAvailable && trashFragment}
        {mode === ImageUploadModes['no-text'] && !image && (
          <>
            {imageOrVideoUploadingInProcess.includes(fieldName) ? (
              <div className="file-upload__progress-container">
                <span>{progress}%</span>
                {isForLesson ? (
                  <svg className="file-upload__progress-container__image">
                    <circle className="file-upload__progress-container__image__layout" cx="30" cy="30" r="29" />
                    <circle
                      className="file-upload__progress-container__image__progress"
                      cx="30"
                      cy="30"
                      r="29"
                      style={{ strokeDashoffset: progressCircleValue }}
                    />
                  </svg>
                ) : (
                  <progress id="progressBar" value={progress} max="100"></progress>
                )}
              </div>
            ) : (
              <>
                {!viaTooltip && <span className="file-upload__instructions">{text !== undefined && text}</span>}

                {!disabled ? (
                  <label
                    htmlFor={isChangeBlocked || !!onClick ? '' : `browse-${uniqueId}`}
                    className="file-upload__browse"
                  >
                    <ImgUploadIcon />
                  </label>
                ) : null}

                <input
                  {...getInputProps()}
                  type="file"
                  className="file-upload__file"
                  disabled={!isEditAvailable || disabled}
                  id={`browse-${uniqueId}`}
                  name="browse"
                  accept={accept}
                />
              </>
            )}
          </>
        )}
        {mode === ImageUploadModes['no-text'] && image && isEditAvailable && trashFragment}
      </div>
      <FailedUploadingMessage errorDetails={errorDetails} viaTooltip={isForLesson} />
    </>
  );
};

export default ImageUpload;
