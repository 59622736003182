import { throttle } from 'lodash';
import { useEffect, useState } from 'react';

import { DBId } from '@common/types/DBId';
import { CommonFilterOptionType, CommonFilterSelector } from '@components/SelectorCommonComponents';
import { SearchBox } from '@components/Search';
import { constants as contentConstants, type CEFRKey } from '@features/content';
import type { LanguageV2 } from '@features/content/languages';

import { RoleplayService } from '../../RoleplayService';
import { CEFRSelectorButton, CEFRSelectorWrapper, FilterContainer } from './styles';

const { CEFR } = contentConstants;

const FILTER_TITLE_MIN_CHARS = 3;
const FILTER_TITLE_THROTTLE_STEP = 300; // ms

const CategorySelector = ({
  learningLanguage,
  value,
  onFilter,
}: {
  learningLanguage: LanguageV2;
  value: DBId | undefined;
  onFilter: (value?: DBId) => void;
}) => {
  const [categories, setCategories] = useState<CommonFilterOptionType[]>([]);

  useEffect(() => {
    RoleplayService.getRoleplayCategories(learningLanguage)
      .then((result) => {
        const categoryFilterOptions =
          result.data?.roleplayCategories.map(({ id, title }) => ({
            label: title ?? 'Roleplay Category',
            value: id,
          })) || [];

        setCategories(categoryFilterOptions);
      })
      .catch((error) => console.error(error));
  }, [learningLanguage]);

  return (
    <>
      {categories.length && (
        <CommonFilterSelector
          placeholder="Category"
          value={categories.find((category) => category.value === value) || null}
          options={categories}
          withSearchIcon
          onChange={(selectedCategory) => onFilter(selectedCategory?.value)}
        />
      )}
    </>
  );
};

const CEFRSelector = ({ value, onFilter }: { value: CEFRKey | undefined; onFilter: (value?: CEFRKey) => void }) => {
  const CEFRKeys = Object.keys(CEFR);

  return (
    <CEFRSelectorWrapper>
      <CEFRSelectorButton $active={!value} onClick={() => onFilter(undefined)}>
        All
      </CEFRSelectorButton>
      {CEFRKeys.map((key, index) => {
        const CEFRLabel = CEFR[key as CEFRKey];

        return (
          <CEFRSelectorButton
            $active={value === key}
            key={index}
            title={`Filter Scenarios by CEFR ${CEFRLabel}`}
            onClick={() => onFilter(key as CEFRKey)}
          >
            {CEFRLabel}
          </CEFRSelectorButton>
        );
      })}
    </CEFRSelectorWrapper>
  );
};

export type FiltersState = {
  category: DBId | undefined;
  cefr: CEFRKey | undefined;
  title: string | undefined;
};

export const AttachScenarioFilters = ({
  filters,
  learningLanguage,
  onFilter,
}: {
  filters: FiltersState;
  learningLanguage: LanguageV2;
  onFilter: (filterType: keyof FiltersState, value: FiltersState['category' | 'cefr' | 'title']) => void;
}) => {
  const throttledOnFilterTitle = throttle((value: string) => {
    onFilter('title', value);
  }, FILTER_TITLE_THROTTLE_STEP);

  return (
    <FilterContainer>
      <CategorySelector
        learningLanguage={learningLanguage}
        value={filters.category}
        onFilter={(value) => onFilter('category', value)}
      />
      <CEFRSelector value={filters.cefr} onFilter={(value) => onFilter('cefr', value)} />
      <SearchBox
        filterTerm={filters.title}
        onSearchChange={(value: string) => {
          if (value.length >= FILTER_TITLE_MIN_CHARS) {
            throttledOnFilterTitle(value);
          } else if (value.length === 0) {
            onFilter('title', undefined);
          }
        }}
        placeholder="Filter by title"
      />
    </FilterContainer>
  );
};
