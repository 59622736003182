import styled from 'styled-components';

import plusIcon from './plusIcon.svg';
import editIcon from './editIcon.svg';
import { useAppSelector } from '@redux/store';
import { selectRoleplayCategoryContent } from '@selectors/roleplaySelectors';

const IconSelector = styled.img`
  :hover {
    cursor: pointer;
  }
`;

const SelectedIconContainer = styled.div`
  align-items: center;
  border: solid 0.1rem ${({ theme }) => theme.colorV2.input.border.default};
  border-radius: 0.8rem;
  display: flex;
  font-size: 6rem;
  height: 12rem;
  justify-content: center;
  padding: 0.8rem;
  position: relative;
  width: 12rem;
`;

const EditButton = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.colorV2.uiBackgroundPrimary};
  border: solid 0.1rem ${({ theme }) => theme.colorV2.input.border.default};
  border-radius: 0.8rem;
  display: flex;
  height: 3.2rem;
  justify-content: center;
  position: absolute;
  right: 1rem;
  top: 1rem;
  width: 3.2rem;

  :hover {
    background: ${({ theme }) => theme.colorV2.uiBackgroundSecondary};
    cursor: pointer;
  }
`;

export const SelectedIcon = ({ openPanel }: { openPanel: () => void }) => {
  const roleplayContent = useAppSelector(selectRoleplayCategoryContent);

  return (
    <>
      {roleplayContent.icon ? (
        <SelectedIconContainer>
          <img src={roleplayContent.icon} alt="Roleplay Category icon" />
          <EditButton onClick={openPanel}>
            <img src={editIcon} alt="Edit icon" />
          </EditButton>
        </SelectedIconContainer>
      ) : (
        <IconSelector src={plusIcon} alt="Select icon" onClick={openPanel} />
      )}
    </>
  );
};
