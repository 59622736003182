import { BlockMap, ContentState, SelectionState } from 'draft-js';

export const getSelectedText = (currentContent: ContentState, currentSelection: SelectionState) => {
  const anchorKey = currentSelection.getAnchorKey();
  const currentContentBlock = currentContent.getBlockForKey(anchorKey);
  const start = currentSelection.getStartOffset();
  const end = currentSelection.getEndOffset();

  return currentContentBlock.getText().slice(start, end);
};

export const isLockedContent = (text: string, regexp: RegExp) => regexp.test(text);

export const getOriginalLikeSelection = (
  blockMap: BlockMap,
  originalSelection: SelectionState,
  shouldLock: boolean,
) => {
  let newBlockKey;

  blockMap.forEach((contentBlock) => {
    newBlockKey = contentBlock?.getKey();
  });

  const originalAnchorOffset = originalSelection.getAnchorOffset();
  const orginalFocusOffset = originalSelection.getFocusOffset();

  return SelectionState.createEmpty(newBlockKey || '').merge({
    anchorKey: newBlockKey,
    anchorOffset: shouldLock ? originalAnchorOffset + 1 : originalAnchorOffset - 1,
    focusKey: newBlockKey,
    focusOffset: shouldLock ? orginalFocusOffset + 1 : orginalFocusOffset - 1,
    isBackward: false,
  });
};

export const getSelectionWithTextBoundaries = (currentContent: ContentState, initialSelection: SelectionState) => {
  const blockKey = currentContent.getBlockForKey(initialSelection.getAnchorKey()).getKey();

  return SelectionState.createEmpty(blockKey).merge({
    anchorOffset: initialSelection.getStartOffset() - 1,
    focusOffset: initialSelection.getEndOffset() + 1,
  });
};
