import { useEffect, useReducer, useRef, useState } from 'react';

import { DBId } from '@common/types/DBId';
import { CardPublishingStatus } from '@components/ContentTypes/BaseContent/CardPublishingStatus';
import { CardPendingStatus } from '@components/ContentTypes/BaseContent/CardPendingStatus';
import {
  CardWrapper,
  Data,
  DataWrapper,
  Description,
  ScenarioImage,
  ScenarioImagePlaceholder,
} from '@components/ContentTypes/card.styles';
import { CEFRKey } from '@features/content';
import type { Language, LanguageV2 } from '@features/content/languages';
import { Button, Checkbox, Loader } from '@features/theme';
import { useAppSelector } from '@redux/store';
import { selectCourse } from '@selectors/CoursesSelectors';
import { ReactComponent as ImageGenericIcon } from '@static/svg/image-generic.svg';
import { SearchFeedback } from '@features/search';

import { RoleplayService } from '../../RoleplayService';
import { RoleplayCategoryListItemType } from '../../types';
import { AttachScenarioFilters } from './AttachScenarioFilters';
import {
  ButtonsContainer,
  ScenarioListItemContainer,
  ScenarioListItemDataWrapper,
  ScenarioListItemTitle,
  ScenarioListWrapper,
  Title,
} from './styles';

type ScenarioListItemPropsType = {
  content: RoleplayCategoryListItemType;
  idx: number;
  selected: boolean;
  onSelect: (scenarioId: DBId | undefined) => void;
};

const ScenarioListItem = ({ content, idx, selected, onSelect }: ScenarioListItemPropsType) => {
  const { changeStatus, cefr, id, image, ready, title } = content;
  const scenarioTitle = title || 'Roleplay Scenario';

  return (
    <ScenarioListItemContainer key={idx} $selected={selected} onClick={() => onSelect(selected ? undefined : id)}>
      <Checkbox
        checked={selected}
        onToggle={() => {
          onSelect(selected ? undefined : id);
        }}
      />
      <CardWrapper id={`roleplay-scenario-${content.id}`}>
        <ScenarioListItemDataWrapper>
          <CardPublishingStatus hasPendingChanges={!!changeStatus?.hasPendingChanges} ready={!!ready} />
          <DataWrapper>
            {image ? (
              <ScenarioImage src={image} />
            ) : (
              <ScenarioImagePlaceholder>
                <ImageGenericIcon />
              </ScenarioImagePlaceholder>
            )}
            <Data>
              <CardPendingStatus content={content} />
              <ScenarioListItemTitle title={scenarioTitle}>{scenarioTitle}</ScenarioListItemTitle>
              {!!cefr && <Description title={cefr}>{cefr.toUpperCase()}</Description>}
            </Data>
          </DataWrapper>
        </ScenarioListItemDataWrapper>
      </CardWrapper>
    </ScenarioListItemContainer>
  );
};

type FiltersState = {
  category: DBId | undefined;
  cefr: CEFRKey | undefined;
  title: string | undefined;
};

const filtersInitialState: FiltersState = {
  category: undefined,
  cefr: undefined,
  title: undefined,
};

type AttachScenarioModalPropsType = {
  isOpen: boolean;
  close: () => void;
  onAttach: (scenarioId: DBId) => void;
};

export const AttachScenarioModal = ({ isOpen, close, onAttach }: AttachScenarioModalPropsType) => {
  const [selectedScenarioId, setSelectedScenarioId] = useState<DBId>();
  const [scenarios, setScenarios] = useState<RoleplayCategoryListItemType[]>([]);
  const [filters, setFilters] = useReducer(
    (state: FiltersState, newState: Partial<FiltersState>) => ({ ...state, ...newState }),
    filtersInitialState,
  );
  const [noResults, setNoResults] = useState(false);

  const course = useAppSelector(selectCourse);
  const learningLanguage = course.learningLanguage;

  let allScenarios = useRef<RoleplayCategoryListItemType[]>([]);

  useEffect(() => {
    if (isOpen) {
      RoleplayService.getRoleplayScenariosByLanguage(learningLanguage as Language).then((result) => {
        allScenarios.current = result.data;
        setScenarios(result.data);
      });
    }
  }, [isOpen, learningLanguage]);

  useEffect(() => {
    setNoResults(false);

    let filteredScenarios = allScenarios.current;

    if (filters.category) {
      filteredScenarios = filteredScenarios.filter((scenario) =>
        scenario.categories?.includes(filters.category as string),
      );
    }

    if (filters.cefr) {
      filteredScenarios = filteredScenarios.filter((scenario) => scenario.cefr === filters.cefr);
    }

    if (filters.title) {
      filteredScenarios = filteredScenarios.filter((scenario) => {
        const scenarioTitle = scenario.title || 'Roleplay Scenario';

        return scenarioTitle.includes(filters.title as string);
      });
    }

    setScenarios(filteredScenarios);

    if (!filteredScenarios.length) {
      setNoResults(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const onClose = () => {
    setFilters(filtersInitialState);
    close();
  };

  return (
    <>
      <Title>Choose the scenario which you want to link to this AI Roleplay</Title>
      <AttachScenarioFilters
        learningLanguage={learningLanguage as LanguageV2}
        filters={filters}
        onFilter={(filterType, value) => setFilters({ [filterType]: value })}
      />
      <ScenarioListWrapper>
        {!!scenarios.length ? (
          <>
            {scenarios.map((scenario, idx) => (
              <ScenarioListItem
                content={scenario}
                idx={idx}
                selected={scenario.id === selectedScenarioId}
                onSelect={setSelectedScenarioId}
              />
            ))}
          </>
        ) : (
          <>{noResults && allScenarios.current.length ? <SearchFeedback notFound /> : <Loader size="L" />}</>
        )}
      </ScenarioListWrapper>
      <ButtonsContainer>
        <Button size="M" variant="tertiary" onClick={onClose}>
          Close
        </Button>
        <Button
          disabled={!selectedScenarioId}
          size="M"
          onClick={() => selectedScenarioId && onAttach(selectedScenarioId)}
        >
          Save
        </Button>
      </ButtonsContainer>
    </>
  );
};
