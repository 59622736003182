import { useParams } from 'react-router-dom';

import { PublishingActionsCreator } from '@actionCreators/PublishingActionCreator';
import { ExerciseListItemType } from '@components/ContentTypes/ExerciseCard/types';
import { Loader } from '@features/theme';
import { useIsPublishAvailable } from '@features/auth/roles';
import { DBId } from '@common/types/DBId';
import { PublishingButton, EmptyButton } from '@components/Publishing/cardPublish.styles';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { selectIsPublishingInProgress } from '@selectors/CoursesSelectors';

export const PublishingCardButton = ({ content, hovered }: { content: ExerciseListItemType; hovered: boolean }) => {
  const dispatch = useAppDispatch();
  const { entrypointId, topicId, activityId } = useParams<{
    entrypointId: DBId;
    topicId: DBId;
    activityId: DBId;
  }>();
  const { id, ready, type, validationStatus, changeStatus } = content;
  const isPublishingInProgress = useAppSelector(selectIsPublishingInProgress);

  const { publish: isPublishAvailable, unpublish: isUnpublishAvailable } = useIsPublishAvailable();

  const isUpdateButtonVisible = isPublishAvailable && ready && validationStatus.valid && changeStatus.hasNewChanges;
  const isUnplublishVisible = isUnpublishAvailable && ready;
  const isPublishButtonVisible = isPublishAvailable && (ready || validationStatus.valid);

  const parentId = entrypointId || topicId || activityId;

  return (
    <>
      {isUpdateButtonVisible ? (
        <PublishingButton
          variant="primary"
          hovered={hovered}
          size="S"
          onClick={(e: any) => {
            e.preventDefault();
            e.stopPropagation();
            dispatch(PublishingActionsCreator.setContentToPublish(id, type, ready, true, parentId));
          }}
        >
          {isPublishingInProgress ? <Loader size="S" /> : 'Update'}
        </PublishingButton>
      ) : (
        <EmptyButton />
      )}
      {isPublishButtonVisible ? (
        <PublishingButton
          variant={ready ? 'secondary' : 'primary'}
          hovered={hovered}
          size="S"
          onClick={(e: any) => {
            e.preventDefault();
            e.stopPropagation();
            dispatch(PublishingActionsCreator.setContentToPublish(id, type, ready, false, parentId, 'group'));
          }}
        >
          {isPublishingInProgress ? <Loader size="S" /> : ready ? 'Unpublish' : 'Publish'}
        </PublishingButton>
      ) : (
        <>
          {isUnplublishVisible ? (
            <PublishingButton
              variant="secondary"
              hovered={hovered}
              size="S"
              onClick={(e: any) => {
                e.preventDefault();
                e.stopPropagation();
                dispatch(PublishingActionsCreator.setContentToPublish(id, type, ready, false, parentId, 'group'));
              }}
            >
              {isPublishingInProgress ? <Loader size="S" /> : 'Unpublish'}
            </PublishingButton>
          ) : (
            <EmptyButton />
          )}
        </>
      )}
    </>
  );
};
