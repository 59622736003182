import styled, { css } from 'styled-components/macro';

import { ContentTypes } from '@common/enums/ContentTypes';
import { OtherLocationsInterface } from '@common/interfaces/exercises/OtherLocationsInterface';
import { Flag } from '@components/Flag';
import { Loader } from '@features/theme';
import type { Language } from '@features/content/languages';
import { GrammarIcon } from '@features/content/grammar';
import { PlacementTestIcon } from '@features/content/placementTest';
import { getLink } from '@helpers/urlLinkHelper';
import { ReactComponent as NavigateIcon } from '@static/svg/translationsPanel/Arrow left.svg';

const LocationsContainer = styled.div`
  padding: 0.4rem;
  margin-bottom: 2.4rem;
  max-height: 30rem;
  overflow: auto;
  text-align: initial;
  width: 100%;

  &::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
    position: absolute;
    right: 0;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 0.5rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.color.quaternaryDark};
    transition: all 0.3s;
    border-radius: 0.5rem;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.color.brandGreyscale800};
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
`;

const LocationContainer = styled.div`
  ${({ theme }) => css`
    backgroud-color: ${theme.colorV2.uiBackgroundPrimary};
    border-radius: 0.8rem;
    box-shadow: 0 0.4rem 1.2rem 0 rgba(0, 0, 0, 0.08);
    display: grid;
    grid-template-columns: 3.2rem repeat(auto-fit, minmax(10rem, 1fr)) 5.6rem;
    grid-column-gap: 0.5rem;
    margin-bottom: 0.8rem;
    padding: 0.4rem 1.6rem;

    &:hover {
      backgroud-color: ${theme.colorV2.uiBackgroundSecondary};
    }
  `}
`;

const LocationName = styled.div`
  display: -webkit-box;
  color: ${({ theme }) => theme.colorV2.uiDark};
  font-weight: 400;
  font-size: 1.4rem;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`;

const NavigateIconWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
`;

const StyledNavigatenIcon = styled(NavigateIcon)`
  height: 2.4rem;
  width: 2.4rem;

  path {
    fill: ${({ theme }) => theme.colorV2.accentPrimary};
  }
`;

const StyledFlag = styled(Flag)`
  img {
    height: 2.4rem;
    width: 2.4rem;
  }
`;

const LocationIconWrapper = styled.div`
  ${({ theme }) => css`
    align-items: center;
    align-self: center;
    background-color: ${theme.colorV2.accentPrimary};
    border-radius: 50%;
    display: flex;
    height: 2.4rem;
    justify-content: center;
    width: 2.4rem;

    svg {
      height: 1.8rem !important;
      width: 1.8rem !important;

      path {
        fill: ${theme.colorV2.uiLightest};
      }
    }
  `}
`;

const OtherLocationIcon = ({ location }: { location: OtherLocationsInterface & { language?: Language } }) => {
  if (location.type === ContentTypes.course) {
    return <StyledFlag countries={[location.language as Language]} showCountryName={false} />;
  }

  if (location.type === ContentTypes.grammarReview) {
    return (
      <LocationIconWrapper>
        <GrammarIcon />
      </LocationIconWrapper>
    );
  }

  if (location.type === ContentTypes.placementTest) {
    return (
      <LocationIconWrapper>
        <PlacementTestIcon />
      </LocationIconWrapper>
    );
  }

  return <div></div>;
};

export const OtherLocations = ({ mappingsDetails }: { mappingsDetails: OtherLocationsInterface[][] }) => (
  <>
    {mappingsDetails.length ? (
      <LocationsContainer>
        {mappingsDetails.map((place, placeIndex) => (
          <LocationContainer data-testid="other-places-modal-location" key={placeIndex}>
            <OtherLocationIcon location={place[0]} />
            {place.map(
              (location, index) => location.label && <LocationName key={index}>{location.label}</LocationName>,
            )}
            <NavigateIconWrapper>
              <a href={getLink(place)} target="_blank" rel="noopener noreferrer">
                <StyledNavigatenIcon />
              </a>
            </NavigateIconWrapper>
          </LocationContainer>
        ))}
      </LocationsContainer>
    ) : (
      <Loader />
    )}
  </>
);
