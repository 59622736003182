import React from 'react';
import classnames from 'classnames';
import ReactTooltip from 'react-tooltip';
import {
  ALL_LANGUAGES_UPPERCASE,
  Language,
  LANGUAGE_FLAGS,
  LANGUAGE_NAMES,
  LANGUAGE_SHORT_NAMES,
} from '@features/content/languages';
import { FlagTextPositionMode, type FlagTextPositionModeType } from './enums/FlagTextPositionMode';
import { clone } from '@helpers/clone';
import { random } from 'lodash';

type FlagCountryMode = 'multi' | 'one';

type FlagProps = {
  countries: Language | Language[];
  mode?: FlagTextPositionModeType;
  onClick?: Function;
  className?: string;
  showCountryName?: boolean;
  tooltipEnabled?: boolean;
  forCoursesList?: boolean;
};
export const Flag = ({
  countries,
  mode = FlagTextPositionMode.before,
  onClick,
  className,
  showCountryName = true,
  tooltipEnabled = true,
  forCoursesList = false,
}: FlagProps) => {
  const tooltipId = `${random(0, 10000)}`;
  let img;
  let altText: string;
  let displayText: string;
  let imgClassName = 'flag__country-flag';
  let languages = clone(countries) as Language[];

  if (Array.isArray(languages)) {
    languages = languages.sort((countryCode1: Language, countryCode2: Language) => {
      return countryCode1.localeCompare(countryCode2);
    });
  } else {
    languages = [countries] as Language[];
  }

  let maxItems: number = 4;

  let flagType: FlagCountryMode;

  if (Array.isArray(languages) && languages.length > 1) {
    flagType = 'multi';
  } else {
    flagType = 'one';
  }

  const isCountriesNumberLessThanMaximalNumber = languages.length < ALL_LANGUAGES_UPPERCASE.length - 1;

  const languagesForDisplayText = languages.map((lang) => LANGUAGE_SHORT_NAMES[lang]);
  if (flagType === 'multi') {
    const countriesCloneFullName = languages.map((language: Language) => LANGUAGE_NAMES[language]);

    imgClassName += '--multi';

    if (languagesForDisplayText.length > maxItems) {
      if (languagesForDisplayText.length === ALL_LANGUAGES_UPPERCASE.length - 1) {
        displayText = 'All languages';
      } else {
        displayText = `${[...languagesForDisplayText].splice(0, maxItems).join(', ')} ...`;
      }
    } else {
      displayText = `${languagesForDisplayText.join(', ')}`;
    }

    altText = `${countriesCloneFullName.map((countryName: string) => countryName).join(', ')} flags`;

    if (languages.length > maxItems) {
      img = (
        <div data-tip={tooltipEnabled ? altText : ''} data-for={tooltipId}>
          <ul className={imgClassName}>
            {languages.map((language: Language, idx: number) => {
              if (idx < maxItems) {
                return (
                  <li key={language}>
                    <img src={LANGUAGE_FLAGS[language]} className={imgClassName} alt={`${language} ${idx} flag`} />
                  </li>
                );
              } else {
                return <React.Fragment key={idx}></React.Fragment>;
              }
            })}
            <li key="countries-circle" className="flag__more-countries-circle">
              +{languages.length - maxItems}
            </li>
          </ul>
        </div>
      );
    } else {
      img = (
        <div data-tip={tooltipEnabled ? altText : ''} data-for={tooltipId}>
          <ul className={imgClassName}>
            {languages.map((language: Language) => {
              return (
                <li key={language}>
                  <img src={LANGUAGE_FLAGS[language]} className={imgClassName} alt={`${language} flag`} />
                </li>
              );
            })}
          </ul>
        </div>
      );
    }
  } else {
    altText = `${LANGUAGE_NAMES[languages[0] as Language]} flag`;
    displayText = `${languagesForDisplayText}`;

    img = (
      <div data-tip={tooltipEnabled ? altText : ''} data-for={tooltipId}>
        <img src={LANGUAGE_FLAGS[languages[0]]} className={imgClassName} alt={altText} />
      </div>
    );
  }

  return (
    <div
      className={classnames('flag', { [`${className}`]: className }, { 'flag--no-language-name': showCountryName })}
      onClick={() => onClick !== undefined && onClick()}
    >
      {mode === FlagTextPositionMode.before && (
        <>
          {isCountriesNumberLessThanMaximalNumber && img}

          <span className={`flag__text ${flagType === 'multi' ? 'flag__text--before-multi' : 'flag__text--before'}`}>
            {showCountryName && displayText}
          </span>
        </>
      )}
      {mode === FlagTextPositionMode.after && (
        <>
          <span className={`flag__text ${flagType === 'multi' ? 'flag__text--after-multi' : 'flag__text--after'}`}>
            {showCountryName && displayText}
          </span>

          {isCountriesNumberLessThanMaximalNumber && img}
        </>
      )}
      {mode === FlagTextPositionMode.withoutText && <>{isCountriesNumberLessThanMaximalNumber && img}</>}
      {mode === FlagTextPositionMode.withoutText && isCountriesNumberLessThanMaximalNumber && (
        <>
          <ReactTooltip className="tooltip" effect="solid" id={tooltipId} scrollHide={false} />
        </>
      )}
    </div>
  );
};

export default Flag;
