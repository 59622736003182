import { REMOVE_LIST_COMMAND, $isListNode } from '@lexical/list';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { CheckListPlugin } from '@lexical/react/LexicalCheckListPlugin';
import { mergeRegister } from '@lexical/utils';
import {
  COMMAND_PRIORITY_LOW,
  FOCUS_COMMAND,
  KEY_ENTER_COMMAND,
  LexicalEditor,
  $createParagraphNode,
  $getNodeByKey,
  $getRoot,
  $getSelection,
  $insertNodes,
  $isRangeSelection,
  $nodesOfType,
  LexicalCommand,
  createCommand,
} from 'lexical';
import { useEffect } from 'react';
import { createGlobalStyle } from 'styled-components/macro';

import { CustomListItemNode } from '../../nodes';
import { MENTION_REGEXP_GLOBAL } from '../MentionsPlugin';
import { SupportedValueTypes, createSerializer } from '../../utils/serializer';
import { $isCustomListItemNode } from '../../nodes/CustomListItemNode';

export const ACTION_ITEM_FOCUS_COMMAND: LexicalCommand<void> = createCommand();
export const ACTION_ITEM_BLUR_COMMAND: LexicalCommand<void> = createCommand();

const ActionItemPluginGlobalStyles = createGlobalStyle<{ placeholder: string | undefined }>`
  ${({ placeholder, theme }) => {
    if (placeholder) {
      return `
        .base-editor-listItemPlaceholder:after {
          color: ${theme.colorV2.baseEditorPlaceholderColor};
          content: "${placeholder}" !important;
          position: absolute;
          top: 0;
        }

        .base-editor-listItemUnchecked:before,
        .base-editor-listItemChecked:before {
          cursor: not-allowed !important;
        }
      `;
    }
  }}
`;

export type ActionItemType = {
  id: string;
  assignees: string[];
  message?: string;
  state: boolean;
};

type ActionItemPluginProps = {
  placeholder?: string;
  valueType?: SupportedValueTypes;
  onChange: (actionItems: ActionItemType[]) => void;
};

export const ActionItemPlugin = ({ placeholder, valueType = 'markdown', onChange }: ActionItemPluginProps) => {
  const [editor] = useLexicalComposerContext();

  const serializer = createSerializer(valueType, editor);

  useEffect(() => {
    return mergeRegister(
      editor.registerCommand(FOCUS_COMMAND, () => onFocus(editor), COMMAND_PRIORITY_LOW),
      editor.registerCommand(KEY_ENTER_COMMAND, (event) => onKeyEnter(event, editor), COMMAND_PRIORITY_LOW),
      editor.registerCommand(REMOVE_LIST_COMMAND, () => onRemoveList(editor), COMMAND_PRIORITY_LOW),
    );
  });

  useEffect(() => {
    const removeUpdateListener = editor.registerUpdateListener(({ editorState }) => {
      editorState.read(() => {
        const actionItemsNodes = $nodesOfType(CustomListItemNode).filter((actionItem) => !actionItem.isEmpty());
        let actionItems: ActionItemType[] = [];

        if (actionItemsNodes.length) {
          actionItemsNodes.forEach((actionItem) => {
            const actionItemId = actionItem.getId();
            const assignees = actionItem.extractMentions().map((mention) => mention.getId());
            const message = getActionItemMessage(actionItemId, serializer.serialize())?.trim();
            const containsDescription = message?.replaceAll(MENTION_REGEXP_GLOBAL, '').length;

            if (containsDescription) {
              actionItems.push({
                id: actionItemId,
                assignees,
                state: actionItem.getChecked() || false,
                message,
              });
            }
          });
        }

        onChange(actionItems);
      });
    });

    return () => {
      removeUpdateListener();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor]);

  return (
    <>
      <ActionItemPluginGlobalStyles placeholder={placeholder} />
      <CheckListPlugin />
    </>
  );
};

// Workaround to get the serialized message for and action item as `serializer.serialize(actionItem)` not working as expected
function getActionItemMessage(actionItemId: string, markdown: string) {
  const matches = markdown.matchAll(/- \[[\s|x]\] \[action:([\w-]+)\] (.+)/g);
  const match = Array.from(matches).find((match) => match[1] === actionItemId);

  if (match) return match[2];
}

function getActionItemNodeByKey(key: string) {
  const node = $getNodeByKey(key);

  if (node) {
    if ($isCustomListItemNode(node)) {
      return node as CustomListItemNode;
    }
    const parentNode = node.getParent();
    if (parentNode && $isCustomListItemNode(parentNode)) {
      return parentNode as CustomListItemNode;
    }
  }

  return null;
}

function onKeyEnter(event: KeyboardEvent | null, editor: LexicalEditor) {
  if (event && event.shiftKey) return false;

  const selection = $getSelection();

  if (selection && $isRangeSelection(selection)) {
    const actionItemNode = getActionItemNodeByKey(selection.focus.key);

    if (actionItemNode && actionItemNode.isEmpty()) {
      const root = $getRoot();

      root.getLastChild()?.selectNext();
      actionItemNode.remove();
      editor.dispatchCommand(ACTION_ITEM_BLUR_COMMAND, undefined);
    }
  }

  return false;
}

function onRemoveList(editor: LexicalEditor) {
  const selection = $getSelection();

  if (selection && $isRangeSelection(selection)) {
    const actionItemNode = getActionItemNodeByKey(selection.focus.key);
    const parentNode = actionItemNode?.getParent();

    if (parentNode && $isListNode(parentNode)) {
      const actionItemNodeChildren = actionItemNode?.getChildren();

      parentNode.insertAfter($createParagraphNode());
      parentNode.selectNext();
      actionItemNode?.remove();

      if (actionItemNodeChildren?.length) {
        $insertNodes(actionItemNodeChildren);
      }
    }
  }

  return false;
}

function onFocus(editor: LexicalEditor) {
  const selection = $getSelection();

  if (selection && $isRangeSelection(selection)) {
    const actionItemNode = getActionItemNodeByKey(selection.focus.key);

    if (actionItemNode) {
      editor.dispatchCommand(ACTION_ITEM_FOCUS_COMMAND, undefined);
    }
  }

  return false;
}
