import produce from 'immer';

import type { BulkPublishSliceType } from '@features/content/exercises';
import { BulkPublishingActions } from '@actions/BulkPublishingActions';
import { BulkPublishingActionsCreator } from '@actionCreators/BulkPublishingActionsCreator';

export const BulkPublishingReducers = {
  [BulkPublishingActions.ENABLE_BULK_PUBLISHING_STATE]: (
    state: BulkPublishSliceType,
    { payload: { selectedOption } }: ReturnType<typeof BulkPublishingActionsCreator.enableBulkPublishingState>,
  ) =>
    produce(state, (draft) => {
      draft.currentState = selectedOption;
    }),
  [BulkPublishingActions.DISABLE_ALL]: (state: BulkPublishSliceType) =>
    produce(state, (draft) => {
      draft.currentState = null;
      draft.selectedChildren = [];
    }),
  [BulkPublishingActions.TOGGLE_SELECTED_CHILDREN]: (
    state: BulkPublishSliceType,
    { payload: { selectedChildren } }: ReturnType<typeof BulkPublishingActionsCreator.toggleSelectedChildren>,
  ) =>
    produce(state, (draft) => {
      if (draft.selectedChildren.includes(selectedChildren)) {
        draft.selectedChildren = draft.selectedChildren.filter((children) => children !== selectedChildren);
      } else {
        draft.selectedChildren = [...draft.selectedChildren, selectedChildren];
      }
    }),
  [BulkPublishingActions.SET_SELECTED_CHILDREN]: (
    state: BulkPublishSliceType,
    { payload: { selectedChildren } }: ReturnType<typeof BulkPublishingActionsCreator.setSelectedChildren>,
  ) =>
    produce(state, (draft) => {
      draft.selectedChildren = selectedChildren;
    }),
};
