const SHOW = 'SHOW';
const SHOW_FOR_COURSE = 'SHOW_FOR_COURSE';
const SHOW_FOR_LEVEL_LESSON = 'SHOW_FOR_LEVEL_LESSON';
const POPULATE_BRANCH = 'POPULATE_BRANCH';
const SAVE = 'TranslationsPanelActions_SAVE';
const CHECK_OTHER_LOCATIONS = 'CHECK_OTHER_LOCATIONS';
const GET_BUNDLE_LOCATIONS = 'GET_BUNDLE_LOCATIONS';
const MAKE_COPY = 'MAKE_COPY';
const REMOVE_STRING = 'REMOVE_STRING';

export const TranslationsPanelActions = {
  SHOW,
  SHOW_FOR_COURSE,
  SHOW_FOR_LEVEL_LESSON,
  POPULATE_BRANCH,
  SAVE,
  CHECK_OTHER_LOCATIONS,
  GET_BUNDLE_LOCATIONS,
  MAKE_COPY,
  REMOVE_STRING,
} as const;
