import * as Sentry from '@sentry/react';
import { throttle } from 'lodash';
import { MouseEvent, useEffect, useState, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { ContentTypes, type ContentTypesType } from '@common/enums/ContentTypes';
import { DBId } from '@common/types/DBId';
import { ACTIVITIES, type ActivityType } from '@features/content/activities';
import { Modal, useDialogModal } from '@features/modal';
import { useToast } from '@features/app/toast';
import { MoveContentModalContent, MoveContentService } from '@features/content/reuse';
import { Loader } from '@features/theme';
import { CourseNavigationActionsCreator } from '@actionCreators/CourseNavigationActionsCreator';
import NavigationService from '@services/NavigationService';
import { ContentActionsMenu, NavigationContainer, NavigationItemType } from '@features/content/navigation';
import { getRealId, processStructureForReuse } from '@helpers/reuseHelper';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { selectCourse, selectCourseStructure, selectLoadingCourseParentId } from '@selectors/CoursesSelectors';
import { LessonType } from '@common/enums/LessonTypes';
import { ExerciseType } from '@common/enums/ExerciseTypes';

import { getLink } from './navigationHelpers';
import { NavigationItem } from './NavigationItem';

export type NavigationItemFromBackType = {
  children: boolean;
  id: DBId;
  experiment?: boolean;
  parentId?: DBId;
  ready: boolean;
  title: string | null;
  type: ContentTypesType | LessonType | ActivityType | ExerciseType;
};

export const Navigation = () => {
  const [isMoveContentProcessing, setIsMoveContentProcessing] = useState(false);
  const [selectedContent, setSelectedContent] = useState<NavigationItemType | null>(null);

  const navigationContainerRef = useRef(null);

  const dispatch = useAppDispatch();

  const params = useParams<{
    courseId: DBId;
    levelId: DBId;
    chapterId: DBId;
    lessonId: DBId;
    activityId: DBId;
    exerciseId: DBId;
  }>();
  const { courseId, levelId, chapterId, lessonId, activityId, exerciseId } = params;

  const currentPathIds = [
    courseId,
    levelId,
    chapterId,
    getRealId(lessonId),
    getRealId(activityId),
    getRealId(exerciseId),
  ];

  const history = useHistory();

  const showToast = useToast();

  const courseStructure = useAppSelector(selectCourseStructure);
  const loadingParentId = useAppSelector(selectLoadingCourseParentId);
  const course = useAppSelector(selectCourse);

  const courseItem = courseStructure.find((content) => content.type === ContentTypes.course);

  const getChildrenArray = (type: ContentTypesType, id: DBId) => {
    if (!courseStructure.map((content) => content.parentId).includes(id)) {
      dispatch(CourseNavigationActionsCreator.setLoadingParentId(id));
      switch (type) {
        case ContentTypes.course:
          return NavigationService.getCourseNavigation(id).then((result) => {
            return result.data.levels.map((level: NavigationItemFromBackType) => ({
              ...level,
              parentId: id,
              type: ContentTypes.level,
              expanded: levelId === level.id,
            }));
          });
        case ContentTypes.level:
          return NavigationService.getLevelNavigation(id).then((result) => {
            return result.data.chapters.map((chapter: NavigationItemFromBackType) => ({
              ...chapter,
              parentId: id,
              type: ContentTypes.chapter,
              expanded: chapterId === chapter.id,
            }));
          });
        case ContentTypes.chapter:
          return NavigationService.getChapterNavigation(id).then((result) =>
            result.data.lessons.map((lesson: NavigationItemFromBackType) => ({
              ...lesson,
              parentId: id,
              expanded: lessonId === lesson.id,
              type: lesson.type === 'speaking' ? ContentTypes.speakingLesson : lesson.type,
            })),
          );

        case ContentTypes.lesson:
        case ContentTypes.certificate:
        case ContentTypes.review:
        case ContentTypes.roleplay:
        case ContentTypes.speakingLesson:
          return NavigationService.getLessonNavigation(id).then((result) =>
            result.data.activities.map((activity: NavigationItemFromBackType) => ({
              ...activity,
              parentId: id,
              expanded: activityId === activity.id,
              type: activity.type === 'speaking' ? ContentTypes.speakingActivity : activity.type,
            })),
          );
        case ContentTypes.liveLesson:
          return NavigationService.getLiveLessonNavigation(id).then((result) =>
            result.data.exercises.map((exercise: NavigationItemFromBackType) => ({
              ...exercise,
              parentId: id,
            })),
          );
        default:
          if (ACTIVITIES.includes(type as ActivityType)) {
            return NavigationService.getActivityNavigation(id).then((result) =>
              result.data.exercises.map((exercise: NavigationItemFromBackType) => ({
                ...exercise,
                parentId: id,
              })),
            );
          }
          return [];
      }
    }
    return [];
  };

  const getChildren = async (type: ContentTypesType, id: DBId) => {
    const childrenArray = await getChildrenArray(type, id);
    dispatch(CourseNavigationActionsCreator.updateNavigation(processStructureForReuse(courseStructure, childrenArray)));
    dispatch(CourseNavigationActionsCreator.setLoadingParentId(''));
  };

  const onMoveContentError = (error: Error) => {
    showToast({
      type: 'error',
      title: 'Error',
      description: 'An error occurred when trying to move content.',
    });

    Sentry.captureException(error, (scope) => {
      scope.setTag('logosFeature', 'Move content');
      return scope;
    });
  };

  const onMoveContentConfirm = ({
    selectedContentId,
    currentParentId,
    newParentId,
    isExercise,
    newNavigationStructure,
  }: {
    selectedContentId: DBId;
    currentParentId: DBId;
    newParentId: DBId;
    isExercise: boolean;
    newNavigationStructure: NavigationItemType[];
  }) => {
    const moveContent = isExercise ? MoveContentService.moveExercise : MoveContentService.moveGroup;

    setIsMoveContentProcessing(true);

    moveContent(selectedContentId, currentParentId, newParentId)
      .then(() => {
        // Check if moving content action was performed from the selected content own page and replace the outdated path with the new one
        if (Object.values(params).includes(selectedContentId)) {
          const updatedSelectedContent = newNavigationStructure.find(
            (structureItem) => structureItem.id === selectedContentId,
          )!;
          const newUrl = getLink(updatedSelectedContent, newNavigationStructure);

          history.replace(newUrl, { shallow: true });
        }

        dispatch(CourseNavigationActionsCreator.setLoadingParentId(courseId));
        dispatch(CourseNavigationActionsCreator.updateNavigation(newNavigationStructure));

        showToast({
          type: 'success',
          title: 'Content was moved sucessfully',
        });
      })
      .catch((error) => {
        onMoveContentError(error);
      })
      .finally(() => {
        setSelectedContent(null);
        setIsMoveContentProcessing(false);
        dispatch(CourseNavigationActionsCreator.setLoadingParentId(''));
      });
  };

  const { open: openMoveContentModal, modal: moveContentModal } = useDialogModal((modalControls) => (
    <Modal lockScroll size="L" {...modalControls} onClickOutside={modalControls.close}>
      <MoveContentModalContent
        busy={isMoveContentProcessing}
        courseItem={courseItem as NavigationItemType}
        courseStructure={courseStructure}
        isOpen={modalControls.isOpen}
        selectedContent={selectedContent}
        onCancel={() => {
          setSelectedContent(null);
          modalControls.close();
        }}
        onConfirm={({ selectedContentId, currentParentId, newParentId, isExercise, newNavigationStructure }) => {
          onMoveContentConfirm({ selectedContentId, currentParentId, newParentId, isExercise, newNavigationStructure });
          modalControls.close();
        }}
        onError={(error) => {
          onMoveContentError(error);
          modalControls.close();
        }}
      />
    </Modal>
  ));

  useEffect(() => {
    if (course.id) {
      dispatch(CourseNavigationActionsCreator.getAllNavigation(courseId, levelId, chapterId, lessonId, activityId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, course.id]);

  useEffect(() => {
    if (navigationContainerRef.current) {
      const navigationContainerElement = navigationContainerRef.current as HTMLDivElement;

      // close potential ContentActionsMenu already displayed
      const onNavigationContainerScroll = throttle(() => {
        if (selectedContent) setSelectedContent(null);
      }, 250);

      navigationContainerElement.addEventListener('scroll', onNavigationContainerScroll);

      return () => {
        navigationContainerElement.removeEventListener('scroll', onNavigationContainerScroll);
      };
    }
  });

  if (!courseItem) {
    return <Loader />;
  }

  const getButtonsAmount = () => {
    if (!course.isComplete) {
      return 0;
    } else {
      return 2;
    }
  };

  const onToggleContentActionsMenu = (contentFromToggler: NavigationItemType | null) => {
    if (!selectedContent) {
      setSelectedContent(contentFromToggler);
    } else if (contentFromToggler?.id === selectedContent?.id || !contentFromToggler) {
      setSelectedContent(null);
    }
  };

  const onCopyId = (evt: MouseEvent<HTMLDivElement>) => {
    evt.preventDefault();
    evt.stopPropagation();
    setSelectedContent(null);
    showToast({
      type: 'info',
      title: 'Copied to clipboard',
      description: 'ID has been copied to clipboard',
    });
  };

  return (
    <>
      <NavigationContainer
        isDisabled={!!loadingParentId}
        buttonsAmount={getButtonsAmount()}
        ref={navigationContainerRef}
      >
        {courseItem && (
          <NavigationItem
            content={courseItem}
            selectedContent={selectedContent}
            currentPathIds={currentPathIds}
            loadingParentId={loadingParentId}
            structure={courseStructure}
            onExpand={getChildren}
            onToggleContentActionsMenu={onToggleContentActionsMenu}
          />
        )}
      </NavigationContainer>
      {courseItem && (
        <>
          <ContentActionsMenu
            selectedContent={selectedContent}
            show={!!selectedContent}
            onClickOutside={() => setSelectedContent(null)}
            onCopyId={onCopyId}
            onMoveContent={openMoveContentModal}
          />
          {!!selectedContent && moveContentModal}
        </>
      )}
    </>
  );
};
