import styled from 'styled-components';

export const Button = styled.button(({ theme }) => ({
  alignItems: 'center',
  border: 'none',
  backgroundColor: 'transparent',
  color: theme.color.brandGreyscale800,
  display: 'flex',
  gap: '8px',
}));
