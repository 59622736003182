import { FunctionComponent } from 'react';
import { Pagination } from 'react-bootstrap';
import classnames from 'classnames';

import { Dropdown } from '@components/Dropdown';

import arrowIcon from '@components/RolesAndPermissions/AdminSettings/img/arrowIcon.svg';

import './TableFooter.scss';

const defaultRowSizes = [5, 10, 25, 50];

interface TableFooterProps {
  rowSize: number;
  rowSizes?: number[];
  setRowSize: (newSize: number) => void;
  setCurrentPage: (newPage: number) => void;
  currentPage: number;
  totalPages?: number | null;
  hasNextPage?: boolean | null;
}
export const TableFooter: FunctionComponent<TableFooterProps> = ({
  rowSizes = defaultRowSizes,
  setRowSize,
  rowSize,
  setCurrentPage,
  currentPage,
  totalPages = null,
  hasNextPage = null,
}: TableFooterProps) => {
  const renderPageLink = (page: number) => {
    return (
      <Pagination.Item
        className={classnames({ 'table-pagination--active': currentPage === page })}
        onClick={() => setCurrentPage(page)}
      >
        {page}
      </Pagination.Item>
    );
  };

  return (
    <div className="table-footer">
      <span className="table-footer__caption">Number of rows:</span>
      <Dropdown
        id="rowSize"
        values={rowSizes.map(String).map((rowSize) => ({ name: rowSize, code: rowSize }))}
        value={`${rowSize}`}
        title="Rows"
        onChange={(selectedValue) => {
          setRowSize(+selectedValue.code);
        }}
        className="table-footer__row-selector"
      />

      <Pagination className="table-pagination">
        <Pagination.Prev
          disabled={currentPage === 1}
          onClick={() => setCurrentPage(currentPage - 1)}
          className={classnames('table-pagination__arrow', {
            'table-pagination__arrow--disabled': currentPage === 1,
          })}
        >
          <img src={arrowIcon} style={{ transform: 'rotate(180deg)' }} alt="Arrow" />
        </Pagination.Prev>
        {renderPageLink(1)}
        {currentPage > 3 && currentPage - 1 > 2 && <Pagination.Ellipsis />}

        {currentPage > 2 && currentPage - 1 !== 1 && renderPageLink(currentPage - 1)}

        {currentPage !== 1 && currentPage !== totalPages && renderPageLink(currentPage)}

        {totalPages &&
          currentPage < totalPages - 1 &&
          currentPage + 1 !== totalPages &&
          renderPageLink(currentPage + 1)}

        {totalPages && currentPage < totalPages - 2 && currentPage + 1 < totalPages - 1 && <Pagination.Ellipsis />}
        {totalPages && totalPages > 1 && renderPageLink(totalPages)}
        <Pagination.Next
          disabled={currentPage === totalPages || hasNextPage === false}
          onClick={() => setCurrentPage(currentPage + 1)}
          className={classnames('table-pagination__arrow', {
            'table-pagination__arrow--disabled': currentPage === totalPages || hasNextPage === false,
          })}
        >
          <img src={arrowIcon} alt="Arrow Next" />
        </Pagination.Next>
      </Pagination>
    </div>
  );
};
