import { GrammarCategorySearchResultDetailsType, GrammarCategoryDetailsTab } from '../../types';
import { DetailsWrapper, DetailsTabs, LocationsContent } from '../Common';

type DetailsProps = {
  isLoaded: boolean;
  isExpanded: boolean;
  errorMessage: string;
  details: GrammarCategorySearchResultDetailsType | null;
  defaultTab: GrammarCategoryDetailsTab['key'] | null;
};

export const GrammarCategoryResultDetails = ({
  isLoaded,
  isExpanded,
  errorMessage,
  details,
  defaultTab,
}: DetailsProps) => {
  const isLoaderDisplayed = !isLoaded && !details;

  const tabs: GrammarCategoryDetailsTab[] = [
    {
      key: 'locations',
      title: 'Location(s)',
      Component: () => <LocationsContent locations={details?.mappings || []} />,
    },
  ];

  return (
    <DetailsWrapper isExpanded={isExpanded} errorMessage={errorMessage} isLoaderDisplayed={isLoaderDisplayed}>
      <DetailsTabs tabs={tabs} defaultTab={defaultTab} />
    </DetailsWrapper>
  );
};
