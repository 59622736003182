import CopyToClipboard from 'react-copy-to-clipboard';
import { DBId } from '@common/types/DBId';
import { useToast } from '@features/app/toast';

const DEFAULT_TRUNCATE_LENGTH = 7;

type IDDisplayerProps = {
  id: DBId | null | undefined;
  mode?: 'normal' | 'small';
  truncateAt?: number;
};

const IDDisplayer = ({ id, mode = 'normal', truncateAt = DEFAULT_TRUNCATE_LENGTH }: IDDisplayerProps) => {
  let displayedId = id ? id : 'N/A';
  if (truncateAt !== 0 && id) {
    displayedId = displayedId.split('').splice(0, truncateAt).join('') + '...';
  }

  const showToast = useToast();

  return (
    <CopyToClipboard text={id || 'N/A'}>
      <div
        id={id || 'N/A'}
        className={`id-displayer id-displayer--${mode}`}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          showToast({
            type: 'info',
            title: 'Copied to clipboard',
            description: 'ID has been copied to clipboard',
          });
        }}
      >
        <div>
          <strong className="id-displayer__title">ID:</strong>{' '}
          <span className="id-displayer__id" title={id || 'N/A'}>
            {displayedId}
          </span>
        </div>
      </div>
    </CopyToClipboard>
  );
};

export default IDDisplayer;
