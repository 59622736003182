import { ReactElement } from 'react';
import { Overlay, Popover } from 'react-bootstrap';
import styled, { css } from 'styled-components/macro';

import { ReactComponent as AmberStatusIcon } from './_img/AmberStatus.svg';
import { ReactComponent as GreenStatusIcon } from './_img/GreenStatus.svg';
import { ReactComponent as GreyStatusIcon } from './_img/GreyStatus.svg';
import { ReactComponent as RedStatusIcon } from './_img/RedStatus.svg';

import type { ContentAnalyticsDataType, ContentAnalyticsStatusType } from './types';

const DataContainer = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colorV2.textTertiary};
  flex-direction: column;
  font-size: 1.2rem;
  gap: 0.6rem;
`;

const DataBlockTitle = styled.h4`
  color: ${({ theme }) => theme.colorV2.textPrimary};
  font-size: 1.4rem;
  font-weight: 700;
  margin: 0;
`;

const StyledPopover = styled(Popover)`
  ${({ theme }) => css`
    background-color: ${theme.colorV2.uiBackgroundPrimary};
    border: none;
    border-radius: 2rem;
    box-shadow: 0 0.2rem 0.4rem 0 rgba(0, 0, 0, 0.25);
    gap: 1.2rem;
    padding: 1.2rem 1.8rem;
    position: relative;
    width: 100%;
    z-index: ${theme.zIndex.contentAnalyticsTooltip};

    .popover-body {
      align-items: center;
      display: flex;
      padding: 0;
    }
  `}
`;

type ContentAnalyticsTooltipProps = {
  container?: HTMLElement | null;
  data: ContentAnalyticsDataType;
  status: ContentAnalyticsStatusType;
  trigger: HTMLElement | null;
  visible: boolean;
};

const STATUS_ICON: Record<ContentAnalyticsStatusType, ReactElement> = {
  green: <GreenStatusIcon />,
  amber: <AmberStatusIcon />,
  red: <RedStatusIcon />,
  grey: <GreyStatusIcon />,
};

const formatCompact = (value: number) =>
  value > 999 ? Intl.NumberFormat('en-US', { notation: 'compact' }).format(value) : value;

export const ContentAnalyticsTooltip = ({ data, status, trigger, visible }: ContentAnalyticsTooltipProps) => {
  return (
    <Overlay placement="top" show={visible} target={trigger}>
      <StyledPopover id="content-analytics-tooltip">
        <Popover.Content>
          {STATUS_ICON[status]}
          <DataContainer>
            {status === 'grey' ? (
              <div>
                <DataBlockTitle>Lesson completion rate cannot be counted</DataBlockTitle>
                Check if content is published
              </div>
            ) : (
              <>
                {data.shortTerm.lcr > 0 && (
                  <div>
                    <DataBlockTitle>
                      {data.shortTerm.lcr}% / {formatCompact(data.shortTerm.users)} users
                    </DataBlockTitle>
                    Lesson completion rate
                    <br />
                    since last content deploy
                  </div>
                )}
                {data.longTerm.lcr > 0 && (
                  <div>
                    <DataBlockTitle>
                      {data.longTerm.lcr}% / {formatCompact(data.longTerm.users)} users
                    </DataBlockTitle>
                    Lesson completion rate
                    <br />
                    since last 30 days
                  </div>
                )}
              </>
            )}
          </DataContainer>
        </Popover.Content>
      </StyledPopover>
    </Overlay>
  );
};
