import { FunctionComponent, useMemo } from 'react';
import classnames from 'classnames';
import { useFormikContext } from 'formik';

import { VocabularyMark, KeyboardShortcut, ImageContainer } from './common';
import { FormikValuesInterface } from '@helpers/formikInitialValuesHelper';
import { MultipleChoiceContentProps } from '../../PreviewModalProps';
import { getValueByLanguage } from '../../../../localization-utils';

import './MultipleChoiceContent.scss';

export const MultipleChoiceContent: FunctionComponent<MultipleChoiceContentProps> = ({
  content,
  deviceType,
  answerState,
  courseLearningLanguage,
  isForList,
}: MultipleChoiceContentProps) => {
  const { values }: { values: FormikValuesInterface } = useFormikContext();
  const isCorrectSelected = answerState === 1;
  const isIncorrectSelected = answerState === 2;

  const answerText = useMemo(
    () => getValueByLanguage(isForList ? content.answerBundle?.phrase?.textLocalizations : values.answerBundle_phrase),
    [values, isForList, content.answerBundle],
  );

  const answerTextOnCourseLang = useMemo(
    () =>
      getValueByLanguage(
        isForList ? content.answerBundle?.phrase?.textLocalizations : values.answerBundle_phrase,
        courseLearningLanguage,
      ),
    [values, courseLearningLanguage, isForList, content.answerBundle],
  );

  const answerImage = useMemo(
    () => getValueByLanguage(content.answerBundle?.image?.imageLocalizations),
    [content.answerBundle],
  );

  const distractor1Text = useMemo(
    () => getValueByLanguage(isForList ? content.distractor1?.phrase?.textLocalizations : values.distractor1_phrase),
    [values, isForList, content.distractor1],
  );

  const distractor1TextOnCourseLang = useMemo(
    () =>
      getValueByLanguage(
        isForList ? content.distractor1?.phrase?.textLocalizations : values.distractor1_phrase,
        courseLearningLanguage,
      ),
    [values, courseLearningLanguage, isForList, content.distractor1],
  );

  const distractor1Image = useMemo(
    () => getValueByLanguage(content.distractor1?.image?.imageLocalizations),
    [content.distractor1],
  );

  const distractor2Text = useMemo(
    () => getValueByLanguage(isForList ? content.distractor2?.phrase?.textLocalizations : values.distractor2_phrase),
    [values, isForList, content.distractor2],
  );

  const distractor2TextOnCourseLang = useMemo(
    () =>
      getValueByLanguage(
        isForList ? content.distractor2?.phrase?.textLocalizations : values.distractor2_phrase,
        courseLearningLanguage,
      ),
    [values, courseLearningLanguage, isForList, content.distractor2],
  );

  const distractor2Image = useMemo(
    () => getValueByLanguage(content.distractor2?.image?.imageLocalizations),
    [content.distractor2],
  );

  const isChoicesOnCourseLang = useMemo(
    () => !content.choicesLanguage || content.choicesLanguage === 'learning',
    [content.choicesLanguage],
  );

  const isImagesEnabled = content.choicesImagesEnabled;

  return (
    <>
      <ImageContainer
        deviceType={deviceType}
        isImageEnabled={content.mainImageEnabled}
        isAudioEnabled={content.mainAudioEnabled}
        imageSrc={answerImage}
        isVocabulary={content.answerBundle?.isVocabulary}
      />
      <div
        className={classnames(
          'multiple-choice-distractors',
          { 'multiple-choice-distractors--images': isImagesEnabled },
          `multiple-choice-distractors--${deviceType}`,
        )}
      >
        {isImagesEnabled ? (
          <>
            <div
              className={classnames('multiple-choice-image-button', `multiple-choice-image-button--${deviceType}`, {
                'multiple-choice-image-button--unknown': isCorrectSelected,
              })}
            >
              <img src={distractor1Image} className="multiple-choice-image-button__image" alt="Distractor 1" />
              <VocabularyMark isVocabulary={content.distractor1?.isVocabulary} />
              <div
                className={classnames('multiple-choice-image-button__caption', {
                  'multiple-choice-image-button__caption--incorrect': isIncorrectSelected,
                })}
              >
                {isChoicesOnCourseLang
                  ? distractor1TextOnCourseLang
                  : // Need to know which language to use, when not 'learning' selected
                    distractor1Text}
              </div>
            </div>
            <div
              className={classnames('multiple-choice-image-button', `multiple-choice-image-button--${deviceType}`, {
                'multiple-choice-image-button--unknown': isCorrectSelected || isIncorrectSelected,
              })}
            >
              <img src={distractor2Image} className="multiple-choice-image-button__image" alt="Distractor 2" />
              <VocabularyMark isVocabulary={content.distractor2?.isVocabulary} />
              <div className={classnames('multiple-choice-image-button__caption')}>
                {isChoicesOnCourseLang
                  ? distractor2TextOnCourseLang
                  : // Need to know which language to use, when not 'learning' selected
                    distractor2Text}
              </div>
            </div>
            <div className={classnames('multiple-choice-image-button', `multiple-choice-image-button--${deviceType}`)}>
              <img src={answerImage} className="multiple-choice-image-button__image" alt="Answer" />
              <VocabularyMark isVocabulary={content.answerBundle?.isVocabulary} />
              <div
                className={classnames('multiple-choice-image-button__caption', {
                  'multiple-choice-image-button__caption--correct': isCorrectSelected,
                })}
              >
                {isChoicesOnCourseLang
                  ? answerTextOnCourseLang
                  : // Need to know which language to use, when not 'learning' selected
                    answerText}
              </div>
            </div>
          </>
        ) : (
          <>
            <div
              className={classnames('multiple-choice-button', {
                'multiple-choice-button--incorrect': isIncorrectSelected,
              })}
            >
              {isChoicesOnCourseLang
                ? distractor1TextOnCourseLang
                : // Need to know which language to use, when not 'learning' selected
                  distractor1Text}
              <KeyboardShortcut answerState={answerState} value={1} />
            </div>
            <div className={classnames('multiple-choice-button')}>
              {isChoicesOnCourseLang
                ? distractor2TextOnCourseLang
                : // Need to know which language to use, when not 'learning' selected
                  distractor2Text}
              <KeyboardShortcut answerState={answerState} value={2} />
            </div>
            <div
              className={classnames('multiple-choice-button', {
                'multiple-choice-button--correct': isCorrectSelected,
              })}
            >
              {isChoicesOnCourseLang
                ? answerTextOnCourseLang
                : // Need to know which language to use, when not 'learning' selected
                  answerText}
              <KeyboardShortcut answerState={answerState} value={3} />
            </div>
          </>
        )}
      </div>
    </>
  );
};
