import { useState } from 'react';
import { Modal } from 'react-bootstrap';

import { searchByExercise } from '../../service';
import { useSearch } from '../../../SearchContext';
import { ReactComponent as ExerciseIcon } from '../../img/exercise.svg';
import { ExerciseSearchResultItem, ExerciseSearchResultType } from '../../SearchResultItem';
import { SearchResults } from '../../SearchResults';
import {
  DEFAULT_PAGINATION_PAGE,
  SearchHeader,
  SearchResultsWrapper,
  useFiltersState,
  useResultsHandler,
} from '../Common';
import { ExerciseSearchResultTypeHeader } from '../../SearchResultTypeHeader';
import { useAppSelector } from '@redux/store';
import { selectCourses } from '@selectors/CoursesSelectors';
import { selectSearchPanel } from '@selectors/UiSelectors';
import { saveFavouriteSearch } from '../Common/favouriteSearches';
import { SEARCH_TYPE_EXERCISE } from '../../../../types';
import type { CommonSearchComponentProps } from '../SearchContent';
import type { ExerciseQueryParamsType } from '../../service/types';
import { ExerciseSearchFilters } from './ExerciseSearchFilters';

const defaultFilters: ExerciseQueryParamsType = {
  languages: null,
  courses: null,
  hasImage: null,
  isReused: null,
  isPublished: null,
  exerciseTypes: null,
  isInExperiment: null,
  isUsedInGrammarReview: null,
  isUsedInPlacementTest: null,
};

/**
 * This component is responsible for:
 * - Modal layout for Exercise search
 * - All logic that is specific to Exercise search
 * - Calling Exercise search API
 * - Storing search filters
 * - Storing search results
 */
export const ExerciseSearch = ({ trackSearch, onBack }: CommonSearchComponentProps) => {
  const availableCourses = useAppSelector(selectCourses);
  const { filtersPreset } = useAppSelector(selectSearchPanel);

  const { search, query } = useSearch();

  const { results, count, clearResults, onSuccess, onError } = useResultsHandler<ExerciseSearchResultType>();
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGINATION_PAGE);

  const filtersState = useFiltersState(defaultFilters, {
    exerciseTypes: filtersPreset?.exercise?.exerciseTypes ?? null,
    courses: filtersPreset?.exercise?.courses ?? null,
    languages: filtersPreset?.exercise?.languages ?? null,
  });

  const onPaginatedSearch = (query: string | null, page: number) => {
    if (query) {
      search(() => searchByExercise(query, page, filtersState.state))
        .then(onSuccess)
        .catch(onError);

      trackSearch();
    } else {
      clearResults();
    }
  };

  const onFreshSearch = (query: string | null) => {
    setCurrentPage(DEFAULT_PAGINATION_PAGE);
    onPaginatedSearch(query, DEFAULT_PAGINATION_PAGE);
  };
  const onPageChange = (page: number) => {
    setCurrentPage(page);
    onPaginatedSearch(query, page);
  };

  return (
    <>
      <Modal.Header closeButton>
        <SearchHeader
          filtersArea={
            <ExerciseSearchFilters
              filtersState={filtersState}
              availableCourses={availableCourses}
              onSaveFilters={async (name) => saveFavouriteSearch(name, SEARCH_TYPE_EXERCISE, filtersState.state)}
            />
          }
          icon={<ExerciseIcon />}
          title="Exercise search"
          onBack={onBack}
          onSearch={onFreshSearch}
        />
      </Modal.Header>

      <Modal.Body>
        <SearchResultsWrapper onPageCount={results.length}>
          <SearchResults<ExerciseSearchResultType>
            results={results}
            count={count}
            currentPage={currentPage}
            setCurrentPage={onPageChange}
            header={<ExerciseSearchResultTypeHeader />}
            renderItem={(item) => <ExerciseSearchResultItem content={item} key={item.id} />}
          />
        </SearchResultsWrapper>
      </Modal.Body>
    </>
  );
};
