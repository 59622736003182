import { GenericContentInterface } from '@common/interfaces/contentTypes/GenericContentInterface';
import { ContentTypes } from '@common/enums/ContentTypes';
import { ExerciseTypes } from '@common/enums/ExerciseTypes';
import { useParams } from 'react-router-dom';
import { getPositionOfLesson } from '@helpers/contentCardHelper';
import { LevelCard } from '@components/ContentTypes/LevelCard/LevelCard';
import { ChapterCard } from '@components/ContentTypes/ChapterCard/ChapterCard';
import { LessonCard } from '@components/ContentTypes/LessonCard/LessonCard';
import { ActivityCard } from '@components/ContentTypes/ActivityCard/ActivityCard';
import { ExerciseCard } from '@components/ContentTypes/ExerciseCard/ExerciseCard';
import { ActivityInterface } from '@common/interfaces/contentTypes/ActivityInterface';
import { LessonInterface } from '@common/interfaces/contentTypes/LessonInterface';
import { LevelInterface } from '@common/interfaces/contentTypes/LevelInterface';
import {
  GrammarCategoryCard,
  GrammarTopicCard,
  GrammarCategoryListItemType,
  GrammarTopicListItemType,
} from '@features/content/grammar';
import { ExerciseListItemType } from '@components/ContentTypes/ExerciseCard/types';
import { DragAndDropType } from './DragAndDropType';
import { DBId } from '@common/types/DBId';
import { EntrypointCard, EntrypointListItemType } from '@features/content/placementTest';
import {
  RoleplayCategoryCard,
  RoleplayScenarioCard,
  type RoleplayListItemType,
  type RoleplayCategoryListItemType,
} from '@features/content/roleplay';

type ContentCardProps = {
  content:
    | GenericContentInterface
    | GrammarCategoryListItemType
    | GrammarTopicListItemType
    | ExerciseListItemType
    | EntrypointListItemType
    | RoleplayListItemType;
  dragProps?: DragAndDropType;
  arrayOfChildren: (
    | GenericContentInterface
    | GrammarCategoryListItemType
    | GrammarTopicListItemType
    | ExerciseListItemType
    | EntrypointListItemType
    | RoleplayListItemType
  )[];
  showRemove?: boolean;
};

export const ContentCard = ({ content, dragProps, arrayOfChildren, showRemove = true }: ContentCardProps) => {
  const { topicId } = useParams<{ topicId: DBId }>();
  const { id, type } = content;
  const isExerciseCard = Object.values(ExerciseTypes).includes(type as any);

  const getPositionOfContentCard = () => {
    const contentPosition = arrayOfChildren?.filter((children) => children.ready).map((children) => children.id);

    const parentTypesWithSimpleCounting = [ContentTypes.level, ContentTypes.chapter, ContentTypes.activity];

    let cardPosition = getPositionOfLesson(id, arrayOfChildren as LessonInterface[]);
    if (parentTypesWithSimpleCounting.includes(type as any) || isExerciseCard) {
      cardPosition = contentPosition.indexOf(id) === -1 ? null : contentPosition.indexOf(id);
    }

    return cardPosition;
  };

  const position = getPositionOfContentCard();

  if (type === ContentTypes.level) {
    return <LevelCard content={content as LevelInterface} idx={position} dndProps={dragProps} />;
  } else if (type === ContentTypes.chapter) {
    return <ChapterCard content={content as GenericContentInterface} idx={position} dndProps={dragProps} />;
  } else if (type === ContentTypes.lesson) {
    return <LessonCard content={content as LessonInterface} idx={position} dndProps={dragProps} />;
  } else if (isExerciseCard) {
    return (
      <ExerciseCard
        content={
          {
            ...content,
            validationStatus: topicId
              ? (content as ExerciseListItemType).validationStatus
              : (content as GenericContentInterface).validation,
          } as ExerciseListItemType
        }
        idx={position}
        dndProps={dragProps}
      />
    );
  } else if (type === ContentTypes.activity) {
    return <ActivityCard content={content as ActivityInterface} dndProps={dragProps} />;
  } else if (type === ContentTypes.grammarCategory) {
    return <GrammarCategoryCard content={content as GrammarCategoryListItemType} idx={position} dndProps={dragProps} />;
  } else if (type === ContentTypes.grammarTopic) {
    return <GrammarTopicCard content={content as GrammarTopicListItemType} idx={position} dndProps={dragProps} />;
  } else if (type === ContentTypes.entrypoint) {
    return <EntrypointCard content={content as EntrypointListItemType} />;
  } else if (type === ContentTypes.roleplayCategory) {
    return <RoleplayCategoryCard content={content as RoleplayListItemType} idx={position} dndProps={dragProps} />;
  } else if (type === ContentTypes.roleplayScenario) {
    return (
      <RoleplayScenarioCard
        content={content as RoleplayCategoryListItemType}
        idx={position}
        dndProps={dragProps}
        showRemove={showRemove}
      />
    );
  }

  return null;
};
