import styled from 'styled-components/macro';

import { ReactComponent as ExclamationIcon } from '../img/exclamation.svg';

const ExercisesWarningWrapper = styled.div`
  background-color: ${({ theme }) => theme.colorV2.utilityAlertBackground};
  border-radius: 0.6rem;
  font-size: 1.4rem;
  gap: 0.8rem;
  padding: 0.8rem 0.8rem 0 0.8rem;
  margin: 0 0 1.6rem 1rem;
  width: fit-content;

  svg {
    display: inline-block;
    vertical-align: text-bottom;

    path {
      fill: ${({ theme }) => theme.colorV2.utilityError};
    }
  }
`;

const WarningItem = styled.div`
  padding-bottom: 0.8rem;
`;

const StyledExclamationIcon = styled(ExclamationIcon)`
  margin-right: 0.4rem;
`;

export const ExercisesWarning = ({ warnings }: { warnings: JSX.Element[] }) => {
  return (
    <ExercisesWarningWrapper>
      {warnings.map((warning, index) => (
        <WarningItem key={`warning-item-${index}`}>
          <StyledExclamationIcon />
          {warning}
        </WarningItem>
      ))}
    </ExercisesWarningWrapper>
  );
};
