import { Button } from 'react-bootstrap';
import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
import { useAppSelector } from '@redux/store';
import { Loader } from '@features/theme';
import trashIcon from './img/trash.svg';

import './RemoveModal.scss';
import { selectNewContentIsDeleteInProgress } from '@selectors/CoursesSelectors';

const Icon = styled.img`
  margin-bottom: 28px;
`;

const Title = styled.span`
  font-weight: 800;
  font-size: 20px;
  margin-bottom: 8px;
  width: 55%;
  text-align: center;
`;

const Description = styled.span`
  color: ${({ theme }) => theme.color.brandGreyscale800};
  font-size: 16px;
  margin-bottom: 32px;
`;

type RemoveModalProps = {
  opened: boolean;
  onHide: () => void;
  onDelete: () => void;
};

export const RemoveModal = ({ opened, onHide, onDelete }: RemoveModalProps) => {
  const isDeleteInProgress = useAppSelector(selectNewContentIsDeleteInProgress);

  return (
    <Modal show={opened} dialogClassName={'remove-modal'} centered onHide={() => !isDeleteInProgress && onHide()}>
      <Modal.Body>
        <Icon src={trashIcon} />
        <Title>Are you sure you want to remove this item?</Title>
        <Description>You can't undo this action</Description>
        {isDeleteInProgress ? (
          <Loader />
        ) : (
          <div>
            <Button variant="light" onClick={onHide}>
              Cancel
            </Button>
            <Button onClick={() => onDelete()}>Delete</Button>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};
