import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Sizes } from '@common/enums/Sizes';
import { DialogueCharactersPanelModes } from '@common/interfaces/slices/UISliceInterface';
import DialogueCharacter from './DialogueCharacter/DialogueCharacter';
import DialogueCharacterWithNumber from './DialogueCharacterWithNumber/DialogueCharacterWithNumber';
import { DialogueExerciseActionsCreator } from '@actionCreators/DialogueExerciseActionsCreator';
import { Character } from '@components/Exercises/Dialogue/types/Character';
import { SidePanelActionsCreator } from '@actionCreators/SidePanelActionsCreator';
import styled from 'styled-components/macro';
import { SidePanel, SidePanelBody, SidePanelHeader } from '@features/theme';
import { useAppDispatch, useAppSelector } from '@redux/store';
import {
  selectDialogueCharactersPanelCharacterIndex,
  selectDialogueCharactersPanelCharacters,
  selectDialogueCharactersPanelMaxCharacters,
  selectDialogueCharactersPanelMode,
  selectDialogueCharactersPanelTitle,
} from '@selectors/UiSelectors';
import { selectLoadedDialogueExerciseCharacters } from '@selectors/CoursesSelectors';

const StyledSidePanel = styled(SidePanel)`
  height: calc(100% - 8rem) !important;
  width: 59rem !important;

  .dialogue-characters-panel-modal__characters {
    padding: 0;
    text-indent: 0;
    display: grid;
    gap: 4.2rem;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin: 2.8rem 0 0;
    list-style: none;
  }

  .dialogue-characters-panel-modal__character-being-edited {
    left: 28rem;
    position: absolute;
    top: -6.5rem;
  }

  footer {
    width: calc(59rem) !important;
  }
`;

const DialogueCharactersPanel = () => {
  let [totalSelectedCharacters, setTotalSelectedCharacters] = useState(0);
  let [selectedCharacterIds, setSelectedCharacterIds] = useState<number[]>([]);
  let [charactersAreSelectable, setCharactersAreSelectable] = useState(true);
  let [isSaveUpdateButtonDisabled, setIsSaveUpdateButtonDisabled] = useState<boolean | undefined>(true);

  const dispatch = useAppDispatch();

  const dialogueCharacters: Character[] = useAppSelector(selectDialogueCharactersPanelCharacters);
  const title: string = useAppSelector(selectDialogueCharactersPanelTitle);
  const MAX_CHARACTERS: number = useAppSelector(selectDialogueCharactersPanelMaxCharacters);
  const mode = useAppSelector(selectDialogueCharactersPanelMode);
  const characterIndex: number | null = useAppSelector(selectDialogueCharactersPanelCharacterIndex);

  const characters: Character[] = useAppSelector(selectLoadedDialogueExerciseCharacters);

  useEffect(() => {
    if (characters?.length && mode === DialogueCharactersPanelModes.assignCharacters) {
      setTotalSelectedCharacters(characters.length);
      let indexOfFirstCharacter: number = 0;
      dialogueCharacters.forEach((char, index) =>
        char.image === characters[0].image ? (indexOfFirstCharacter = index) : null,
      );
      setSelectedCharacterIds([indexOfFirstCharacter]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [characters, mode]);

  const characterBeingEdited: Character | null = characterIndex !== null ? characters[characterIndex] : null;

  function checkSaveUpdateButtonIsDisabled() {
    if (mode === DialogueCharactersPanelModes.assignCharacters) {
      return false;
    }

    if (mode === DialogueCharactersPanelModes.changeCharacter) {
      return selectedCharacterIds.length === 0 ? true : false;
    }

    return undefined;
  }

  useEffect(() => {
    setIsSaveUpdateButtonDisabled(checkSaveUpdateButtonIsDisabled());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCharacterIds]);

  return (
    <StyledSidePanel
      onClose={() => dispatch(SidePanelActionsCreator.hideSidePanel())}
      header={<SidePanelHeader>{title}</SidePanelHeader>}
      body={
        <SidePanelBody>
          {mode === DialogueCharactersPanelModes.changeCharacter && characterBeingEdited !== null && (
            <div className="dialogue-characters-panel-modal__character-being-edited">
              <DialogueCharacter
                imgURL={characterBeingEdited.image}
                selectable={false}
                rollovable={false}
                size={Sizes.small}
              />
            </div>
          )}
          <ul className="dialogue-characters-panel-modal__characters">
            {dialogueCharacters.map((character: Character, idx: number) => {
              const isSelected = selectedCharacterIds.includes(idx);

              return (
                <li className="dialogue-characters-panel-modal__character" key={idx}>
                  <DialogueCharacterWithNumber
                    number={
                      selectedCharacterIds.lastIndexOf(idx) > -1 ? selectedCharacterIds.lastIndexOf(idx) + 1 : undefined
                    }
                    showAsActive={mode === DialogueCharactersPanelModes.changeCharacter ? true : false}
                    showNumber={mode === DialogueCharactersPanelModes.assignCharacters}
                    isSelectedCharacter={isSelected}
                    imgURL={character.image}
                    selectable={charactersAreSelectable}
                    onClick={(selected: boolean) => {
                      if (mode === DialogueCharactersPanelModes.assignCharacters) {
                        let clonedSelectedCharacterIds = [...selectedCharacterIds];
                        if (clonedSelectedCharacterIds.includes(idx)) {
                          clonedSelectedCharacterIds = clonedSelectedCharacterIds.filter((char) => char !== idx);
                          setSelectedCharacterIds(clonedSelectedCharacterIds);
                          setTotalSelectedCharacters(totalSelectedCharacters - 1);
                          setCharactersAreSelectable(true);
                        } else {
                          clonedSelectedCharacterIds[totalSelectedCharacters] = idx;
                          setSelectedCharacterIds(clonedSelectedCharacterIds);

                          if (MAX_CHARACTERS > totalSelectedCharacters) {
                            setTotalSelectedCharacters(totalSelectedCharacters + 1);

                            if (MAX_CHARACTERS === totalSelectedCharacters + 1) {
                              setCharactersAreSelectable(false);
                            } else {
                              setCharactersAreSelectable(true);
                            }
                          } else {
                            setCharactersAreSelectable(false);
                          }
                        }
                      }

                      if (mode === DialogueCharactersPanelModes.changeCharacter) {
                        const clonedSelectedCharacterIds = [idx];

                        setCharactersAreSelectable(true);
                        setSelectedCharacterIds(clonedSelectedCharacterIds);
                        setTotalSelectedCharacters(1);
                      }
                    }}
                  />
                </li>
              );
            })}
          </ul>
          <footer className="translations-panel-modal__save-cancel">
            <Button
              className="translations-panel-modal__cancel"
              onClick={() => {
                dispatch(SidePanelActionsCreator.hideSidePanel());
              }}
            >
              Cancel
            </Button>
            <Button
              className="translations-panel-modal__save"
              disabled={isSaveUpdateButtonDisabled}
              onClick={() => {
                if (mode === DialogueCharactersPanelModes.assignCharacters) {
                  dispatch(
                    DialogueExerciseActionsCreator.setSelectedCharacters(
                      dialogueCharacters[selectedCharacterIds[0]],
                      dialogueCharacters[selectedCharacterIds[1]],
                    ),
                  );
                }

                if (mode === DialogueCharactersPanelModes.changeCharacter) {
                  dispatch(
                    DialogueExerciseActionsCreator.updateSelectedCharacters(
                      dialogueCharacters[selectedCharacterIds[0]],
                      characterIndex as number,
                    ),
                  );
                }

                dispatch(SidePanelActionsCreator.hideSidePanel());
              }}
            >
              {mode === DialogueCharactersPanelModes.assignCharacters ? 'Save' : 'Update'}
            </Button>
          </footer>
        </SidePanelBody>
      }
    />
  );
};

export default DialogueCharactersPanel;
