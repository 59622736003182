import { type DisplayContentToUserModeType } from '@common/enums/DisplayContentToUserMode';
import { ExerciseTypes } from '@common/enums/ExerciseTypes';
import { AnyExerciseInterface } from '@common/types/exercises/AnyExerciseInterface';
import { Language } from '@features/content/languages';
import { FormikValuesInterface } from '@helpers/formikInitialValuesHelper';
import { SpellingExerciseActions } from '@actions/SpellingExerciseActions';
import SpellingExerciseInterface from '@components/Exercises/Spelling/interfaces/SpellingExerciseInterface';

export const SpellingExerciseActionsCreator = {
  save: (exercise: SpellingExerciseInterface, learningLanguage: Language, values: FormikValuesInterface) => ({
    type: SpellingExerciseActions.SAVE,
    payload: {
      exercise,
      learningLanguage,
      values,
    },
  }),
  setInstructionsLanguage: (instructionsLanguage: DisplayContentToUserModeType) => ({
    type: SpellingExerciseActions.SET_INSTRUCTIONS_LANGUAGE,
    payload: {
      instructionsLanguage,
    },
  }),
  setImage: (exercise: AnyExerciseInterface, image: File, progressHandler: Function) => ({
    type: SpellingExerciseActions.UPLOAD_IMAGE,
    payload: {
      exercise,
      image,
      exerciseType: ExerciseTypes.spelling,
      progressHandler,
    },
  }),
  removeImage: () => ({
    type: SpellingExerciseActions.REMOVE_IMAGE,
  }),
  removeAudio: () => ({
    type: SpellingExerciseActions.REMOVE_AUDIO,
  }),
};
