import { takeLatest } from 'redux-saga/effects';
import { AudioUploadActions } from '@actions/AudioUploadActions';
import { ContentTypesActions } from '@actions/ContentTypesActions';
import { RoleplayActions } from '@actions/RoleplayActions';
import { VideoUploadActions } from '@actions/VideoUploadActions';
import { UploadTranslationActions } from '@actions/UploadTranslationActions';

import { UploadAudiosActions } from '@actions/UploadAudiosActions';
import ExerciseSagas from '@sagas/exercises/definitions/ExerciseSagas';
import MediaUploadSagas from '@sagas/mediaUpload/definitions/MediaUploadSagasDefinition';

export function* MediaUploadRelatedSagas() {
  yield takeLatest(AudioUploadActions.UPLOAD_AUDIO, MediaUploadSagas.uploadSoundToExercise);
  yield takeLatest(
    AudioUploadActions.UPLOAD_AUDIO_FOR_CONTENT_IN_TRANSLATOR_PANEL,
    ExerciseSagas.uploadSoundForContentInTranslatorPanel,
  );
  yield takeLatest(VideoUploadActions.UPLOAD_VIDEO, MediaUploadSagas.uploadVideoToExercise);
  yield takeLatest(UploadTranslationActions.UPLOAD, MediaUploadSagas.uploadTranslations);
  yield takeLatest(ContentTypesActions.LESSON_UPLOAD_IMAGE, MediaUploadSagas.uploadImageToLesson);
  yield takeLatest(ContentTypesActions.LESSON_UPLOAD_UNIT_IMAGE, MediaUploadSagas.uploadUnitImageToLesson);
  yield takeLatest(ContentTypesActions.LESSON_REMOVE_IMAGE, MediaUploadSagas.removeImageFromLesson);
  yield takeLatest(ContentTypesActions.LESSON_REMOVE_UNIT_IMAGE, MediaUploadSagas.removeUnitImageFromLesson);
  yield takeLatest(UploadAudiosActions.UPLOAD, MediaUploadSagas.uploadAudios);
  yield takeLatest(RoleplayActions.UPLOAD_IMAGE_TO_SCENARIO, MediaUploadSagas.uploadImageToScenario);
  yield takeLatest(RoleplayActions.REMOVE_IMAGE_FROM_SCENARIO, MediaUploadSagas.removeImageFromScenario);
}
