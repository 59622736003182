import { useEffect, useRef } from 'react';
import { FormControl } from 'react-bootstrap';
import SearchIcon from './img/search.svg';
import CloseIcon from './img/SearchClose.svg';
import { SearchBoxProps } from './SearchBoxProps';

export const SearchBox = ({
  filterTerm,
  placeholder = 'Search',
  onSearchChange,
  className,
  readOnly,
  onClick,
  onClear,
  inputIcon = SearchIcon,
}: SearchBoxProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  function filterTable(e: any) {
    onSearchChange && onSearchChange(e.target.value);
  }

  function clearFilterTerm() {
    onSearchChange && onSearchChange('');
    onClear && onClear();
  }

  return (
    <div
      className={`search-box ${className} ${readOnly ? 'search-box--readOnly' : ''} ${
        !readOnly ? 'search-box--applyFocus' : ''
      }`}
    >
      <div className="search-box__icon">
        <img src={inputIcon} alt="" />
      </div>
      <img
        src={CloseIcon}
        alt=""
        data-testid="search-box-clear"
        onClick={() => {
          clearFilterTerm();
          inputRef.current && inputRef.current.focus();
        }}
        className={filterTerm && filterTerm.length > 0 ? 'search-box__icon-close show' : 'search-box__icon-close hide'}
      />

      <FormControl
        ref={inputRef}
        onClick={onClick && onClick}
        placeholder={placeholder}
        aria-label={placeholder}
        aria-describedby="basic-addon2"
        onChange={(e) => filterTable(e)}
        className="search-box__filter"
        value={filterTerm}
        readOnly={readOnly}
      />
    </div>
  );
};
