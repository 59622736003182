import { DBId } from '@common/types/DBId';
import { apiClient } from '@features/api';
import { OtherLocationsInterface } from '@common/interfaces/exercises/OtherLocationsInterface';
import { Language } from '../languages';

export const getLocations = async (
  componentId: DBId,
): Promise<{ data: { mappings: (OtherLocationsInterface & { language: Language })[][] } }> => {
  return apiClient.noErrorsV2.get(`/content/components/${componentId}`);
};
