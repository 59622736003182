import styled from 'styled-components';
import CopyToClipboard from 'react-copy-to-clipboard';

import { ComponentOtherPlacesDisplayer } from '@components/ComponentOtherPlacesDisplayer';
import { ResourceBundleInterface } from '@common/interfaces/contentTypes/ResourceBundleInterface';
import { CommonSwitcher } from '@components/Exercises/Common/CommonSwitcher/CommonSwitcher';
import TrashIcon from '@components/ContentTypes/BaseContent/img/trashNew.svg';
import { HelpDisplayer } from '@features/help';
import { isFeatureEnabled } from '@helpers/featuresHelper';

import SearchIcon from './search.svg';
import { EntireBundleButton } from '../ResourceBundleModal';
import { ResourceData } from '../ResourceBundleModal';

const BundleHeaderWrapper = styled.div`
  display: flex;
  height: 3.6rem;
  color: ${({ theme }) => theme.color.brandGreyscale800};
  font-size: 1.4rem;
`;

const BundleHeader = styled.div<{
  withErrors: boolean;
  withRedBackground: boolean;
}>`
  display: flex;
  border: 0.1rem solid ${({ theme }) => theme.color.brandGreyscale600};
  border-radius: 1.6rem 1.6rem 0 0;
  background: ${({ theme }) => theme.color.brandGreyscale500};
  width: 100%;

  ${({ theme, withErrors }) =>
    withErrors &&
    `
    border-color: ${theme.color.secondaryBase};
    border-bottom: none;
  `}
  ${({ withRedBackground, theme }) => withRedBackground && `background: ${theme.color.secondary3};`}
`;

const BundleHeaderItem = styled.div`
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  min-width: 1.6rem;
  padding: 0;
  border-left: 1px solid ${({ theme }) => theme.color.brandGreyscale600};

  &:first-child {
    border: none;
    flex: 1 1 auto;
  }
`;

const BundleHeaderItemImage = styled.img`
  width: 1.6rem;
  margin: 0 1.6rem;

  &:hover {
    cursor: pointer;
  }
`;

const BundleHeaderCopyIdContainer = styled.span`
  &:hover {
    cursor: pointer;
  }
`;

const VocabularySwitcher = styled(CommonSwitcher)`
  margin: 0 0 0 1.6rem;
  flex: 1 1 auto;
`;

const StyledComponentOtherPlacesDisplayer = styled((props) => <ComponentOtherPlacesDisplayer {...props} />)`
  width: auto;
  max-width: 14.6rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledCopyToClipboard = styled(CopyToClipboard)`
  padding: 0 0.8rem;
  width: 6rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

type ResourceBundleHeaderProps = {
  withoutVocabularyBox: boolean;
  onVocabularyChange: () => void;
  onSearchClick: () => void;
  onRemoveClick: () => void;
  onCopyIdClick: () => void;
  setCurrentBundle: () => void;
  bundleName: string;
  withErrors: boolean;
  withRedBackground: boolean;
  bundle: ResourceBundleInterface;
  resources: ResourceData[];
};

export const ResourceBundleHeader = ({
  withoutVocabularyBox,
  onVocabularyChange,
  onSearchClick,
  onRemoveClick,
  onCopyIdClick,
  setCurrentBundle,
  bundleName,
  withErrors,
  withRedBackground,
  bundle,
  resources,
}: ResourceBundleHeaderProps) => {
  const bundleId = bundle._id;

  return (
    <BundleHeaderWrapper>
      <BundleHeader withErrors={withErrors} withRedBackground={withRedBackground}>
        {!withoutVocabularyBox && !isFeatureEnabled('hideAddToVocabularyToogle') ? (
          <VocabularySwitcher
            id={`${bundleName}-vocabulary-switcher`}
            value={bundle.isVocabulary}
            onChange={onVocabularyChange}
            guidelineHelp={<HelpDisplayer type="guideline" id="guideline-toggle-vocabulary-review" />}
            text={'Add to Vocabulary Review'}
          />
        ) : (
          <BundleHeaderItem />
        )}
        {bundle?.mappings?.count && bundle?.mappings?.count > 1 && (
          <BundleHeaderItem>
            <StyledComponentOtherPlacesDisplayer
              isResourceBundle={true}
              mappings={bundle.mappings.count}
              mappingsPath={[]}
              bundleId={bundleId}
            />
          </BundleHeaderItem>
        )}
        <BundleHeaderItem>
          <EntireBundleButton
            id={`show-${bundleName}-modal-button`}
            resources={resources}
            setCurrentBundle={setCurrentBundle}
            bundleName={bundleName}
          />
        </BundleHeaderItem>
        <BundleHeaderItem>
          {bundleId ? (
            <StyledCopyToClipboard text={bundleId}>
              <BundleHeaderCopyIdContainer id={`copy-${bundleName}-id`} title={bundleId} onClick={onCopyIdClick}>
                {bundleId}
              </BundleHeaderCopyIdContainer>
            </StyledCopyToClipboard>
          ) : (
            'N/A'
          )}
        </BundleHeaderItem>
        {bundleId && (
          <BundleHeaderItem id={`remove-${bundleName}-button`} onClick={onRemoveClick}>
            <BundleHeaderItemImage src={TrashIcon} alt="Remove" />
          </BundleHeaderItem>
        )}
        <BundleHeaderItem id={`search-${bundleName}-button`} onClick={onSearchClick}>
          <BundleHeaderItemImage src={SearchIcon} alt="Search" />
        </BundleHeaderItem>
      </BundleHeader>
    </BundleHeaderWrapper>
  );
};
