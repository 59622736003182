import styled from 'styled-components';
import { NAVIGATION_PANEL_WIDTH } from '@features/content/navigation';

export const HeaderWrapper = styled.header`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  box-shadow: 0 0.2rem 0.8rem rgb(0 0 0 / 10%);
  color: ${({ theme }) => theme.colorV2.headerText};
  background: ${({ theme }) => theme.colorV2.headerBackground};
  z-index: 4000;
  height: 7.2rem;
  position: fixed;
  left: ${NAVIGATION_PANEL_WIDTH};
  top: 0;
  padding: 1.2rem 2rem 0 2rem;
  width: calc(100% - ${NAVIGATION_PANEL_WIDTH});
`;

export const ButtonsList = styled.div`
  display: flex;
  align-items: center;
`;
