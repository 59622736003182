import { ReactElement, useState } from 'react';
import styled from 'styled-components/macro';

import { isFeatureEnabled } from '@helpers/featuresHelper';

import { AddFiltersButton } from './AddFiltersButton';
import { SaveFilters } from './SaveFilters';
import { ReactComponent as RemoveIcon } from './img/remove.svg';

const FiltersLabel = styled.span`
  font-weight: 800;
  font-size: 1.4rem;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colorV2.textQuaternary};
`;

const FiltersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  padding: 0 2rem;
  min-width: 50%;
  z-index: 1;
  margin: 2.4rem auto auto;
`;

const FiltersWithActions = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 0.8rem;
`;

const Filters = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.8rem;

  & > * {
    width: 24rem;
  }
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
`;

const FilterWrapper = styled.div`
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 0.7rem;

  & > div {
    width: 100%;
  }
`;

const RemoveFilter = styled(RemoveIcon)`
  cursor: pointer;
`;

export const FiltersContainer = ({
  filters,
  secondaryFilters = [],
  emptyFilters,
  onSaveFilters,
}: {
  filters: ReactElement[];
  secondaryFilters?: { filter: ReactElement; label: string; onRemove: () => void }[];
  emptyFilters: boolean;
  onSaveFilters?: (name: string) => Promise<void>;
}) => {
  const [addedFilters, setAddedFilters] = useState<string[]>([]);

  if (!filters.length) return null;

  return (
    <FiltersWrapper>
      <FiltersLabel>Filters</FiltersLabel>
      <FiltersWithActions>
        <Filters>
          {filters.map((filter, index) => (
            <FilterWrapper key={index}>{filter}</FilterWrapper>
          ))}
        </Filters>
        <Actions>
          {secondaryFilters.length > 0 && (
            <AddFiltersButton
              isDisabled={addedFilters.length === secondaryFilters.length}
              filterOptions={secondaryFilters
                .filter(({ label }) => !addedFilters.includes(label))
                .map(({ label }) => label)}
              onFilterAdd={(newFilterLabel) => setAddedFilters((addedFilters) => [...addedFilters, newFilterLabel])}
            />
          )}
          {isFeatureEnabled('favouriteSearches') && filters.length > 0 && onSaveFilters && (
            <SaveFilters disabled={emptyFilters} onSave={onSaveFilters} />
          )}
        </Actions>
      </FiltersWithActions>

      <Filters>
        {secondaryFilters
          .filter(({ label }) => addedFilters.includes(label))
          .map(({ filter, label, onRemove }, index) => (
            <FilterWrapper key={`secondary-${index}`}>
              {filter}
              <RemoveFilter
                onClick={() => {
                  setAddedFilters((addedFilters) => addedFilters.filter((addedFilter) => addedFilter !== label));
                  onRemove();
                }}
              />
            </FilterWrapper>
          ))}
      </Filters>
    </FiltersWrapper>
  );
};
