// @TODO Replace enum-like object with constants and a type
export const ContentTypes = {
  course: 'course',
  level: 'level',
  chapter: 'chapter',
  roleplayLesson: 'roleplayLesson',
  certificate: 'certificate',
  lesson: 'lesson',
  liveLesson: 'live',
  speakingLesson: 'speakingLesson',
  checkpoint: 'checkpoint',
  slidePdf: 'slidePdf',
  slidePptx: 'slidePptx',
  review: 'review',
  unit: 'unit',
  activity: 'activity',
  speakingActivity: 'speakingActivity',
  exercise: 'exercise',
  speechRecognition: 'speechRecognition',
  multipleChoice: 'multipleChoice',
  phraseBuilder: 'phraseBuilder',
  comprehension: 'comprehension',
  conversation: 'conversation',
  highlighter: 'highlighter',
  trueFalse: 'trueFalse',
  flashcard: 'flashcard',
  dialogue: 'dialogue',
  spelling: 'spelling',
  fillgap: 'fillgap',
  matchup: 'matchup',
  string: 'string',
  typing: 'typing',
  tip: 'tip',
  listenRepeat: 'listenRepeat',
  grammarReview: 'grammarReview',
  grammarCategory: 'grammarCategory',
  grammarTopic: 'grammarTopic',
  placementTest: 'placementTest',
  entrypoint: 'placementTestEntrypoint',
  roleplay: 'roleplay',
  roleplayCategory: 'roleplayCategory',
  roleplayScenario: 'scenario',
  lexicalItem: 'lexicalItem',
} as const;
export type ContentTypesType = (typeof ContentTypes)[keyof typeof ContentTypes];

// @TODO Replace enum-like object with constants and a type
export const ContentType = {
  levelOrLesson: 'content',
  group: 'group',
} as const;
export type ContentTypeType = (typeof ContentType)[keyof typeof ContentType];

// @TODO Replace enum-like object with constants and a type
export const ContentTypesForCreateContent = {
  certificate: 'certificate',
  checkpoint: 'checkpoint',
  level: 'level',
  lesson: 'lesson',
  liveLesson: 'live',
  review: 'review',
  speaking: 'speaking',
  roleplay: 'roleplay', // used for roleplay lessons
  roleplayCategory: 'roleplayCategory',
  roleplayScenario: 'Scenario',
} as const;
export type ContentTypesForCreateContentType =
  (typeof ContentTypesForCreateContent)[keyof typeof ContentTypesForCreateContent];
