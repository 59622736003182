import { Link } from 'react-router-dom';

import infoIcon from '@components/RolesAndPermissions/AdminSettings/img/infoIcon.svg';

import './LinkToRolesPage.scss';

export const LinkToRolesPage = () => {
  return (
    <div className="link-to-roles-container">
      <img src={infoIcon} className="link-to-roles-container__image" alt="Info" />
      <span>
        Users have View access to all courses by default. More about{' '}
        <Link to="/roles-description">Roles & Permissions</Link>.
      </span>
    </div>
  );
};
