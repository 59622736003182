import { ChangeEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
import filterIcon from './img/filterIcon.svg';
import closeIcon from './img/closeIcon.svg';

const FilterInputWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colorV2.filterInputColor};
  background: ${({ theme }) => theme.colorV2.filterInputBackground};
  border: 0.1rem solid ${({ theme }) => theme.colorV2.filterInputBaseBorder};
  border-radius: 0.8rem;
  height: 4rem;
  font-size: 1.6rem;
  position: relative;

  &:focus-within,
  &:active {
    border: 0.1rem solid ${({ theme }) => theme.colorV2.filterInputFocusedBorder};
  }
`;

const FilterInputBlock = styled.input`
  padding: 0.8rem 3rem 0.8rem 3.2rem;
  border: none;
  background: transparent;
  width: 100%;
  height: 100%;
  color: inherit;
`;

const FilterImage = styled.img`
  position: absolute;
  left: 0.8rem;
`;

const CloseButton = styled.img`
  cursor: pointer;
  width: 1.4rem;
  position: absolute;
  right: 0.8rem;
`;

export const FilterInput = ({
  value = '',
  placeholder = 'Filter input',
  className,
  onChange,
}: {
  value?: string;
  placeholder?: string;
  className?: string;
  onChange: (value: string) => void;
}) => {
  const [filterValue, setFilterValue] = useState<string>(value || '');

  useEffect(() => {
    setFilterValue(value);
  }, [value]);

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setFilterValue(e.target.value);
    onChange(e.target.value);
  };

  const onClearHandler = () => {
    setFilterValue('');
    onChange('');
  };

  return (
    <FilterInputWrapper className={className} data-testid="filter-input-wrapper">
      <FilterImage src={filterIcon} alt="Filter" />
      <FilterInputBlock
        data-testid="filter-input"
        placeholder={placeholder}
        value={filterValue}
        onChange={onChangeHandler}
      />
      {filterValue && <CloseButton data-testid="clear-filter-input" src={closeIcon} alt="" onClick={onClearHandler} />}
    </FilterInputWrapper>
  );
};
