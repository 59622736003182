import { SectionActions } from '@actions/SectionActions';
import { ExerciseTypes } from '@common/enums/ExerciseTypes';
import { LoadingStage } from '@common/enums/LoadingStage';
import { LoadedDialogueExerciseInterface } from '@common/interfaces/exercises/LoadedExerciseInterface';
import { LocalizationInterface } from '@common/interfaces/localization/LocalizationInterface';
import { CourseSliceInterface } from '@common/interfaces/slices/CourseSliceInterface';
import ExerciseDataModelAssembler from '@components/Exercises/ExerciseDataModelAssembler';
import { findLocalizationInSearchedLanguage } from '@features/content';
import { updateTextField } from '@helpers/updateTextField';
import { DialogueExerciseActions } from '@actions/DialogueExerciseActions';
import DialogueExerciseInterface from '@components/Exercises/Dialogue/interfaces/DialogueExerciseInterface';
import { ScriptLine } from '@components/Exercises/Dialogue/types/ScriptLine';
import { selectedGroupsOfParentInitialData } from '@redux/initialStates/courseInitialState';
import { PayloadAction } from '@reduxjs/toolkit';
import { clone } from '@helpers/clone';

const DialogueExerciseCourseReducers = {
  [SectionActions.COURSE_EXERCISE_DIALOGUE_REQUEST_SUCCEEDED]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    let clonedCourseSlice: CourseSliceInterface = clone(state);
    let { content }: DialogueExerciseInterface = clone(payload);
    const {
      specifiedLocationsBranchInTranslationsPanel,
    }: {
      specifiedLocationsBranchInTranslationsPanel: LocalizationInterface[];
    } = ExerciseDataModelAssembler.prepareEmptyLocalizationBranches(['specifiedLocationsBranchInTranslationsPanel']);

    content.script.forEach((scriptElement) => {
      const prevDescription = scriptElement.line.description;
      if (Array.isArray(scriptElement.line.audioLocalizations) && scriptElement.line.audioLocalizations.length === 0) {
        scriptElement.line.audioLocalizations = specifiedLocationsBranchInTranslationsPanel;
      } else {
        const existingAudioLanguages = scriptElement.line.audioLocalizations.map((loc) => loc.language);

        const filledAudioLocalizations = specifiedLocationsBranchInTranslationsPanel.map((loc) => {
          if (existingAudioLanguages.includes(loc.language)) {
            return (
              scriptElement.line.audioLocalizations.find((existingLoc) => existingLoc.language === loc.language) || loc
            );
          }
          return loc;
        });

        scriptElement.line.audioLocalizations = filledAudioLocalizations;
      }
      scriptElement.line.description = prevDescription || 'Dialogue line.';

      if (Array.isArray(scriptElement.line.textLocalizations) && scriptElement.line.textLocalizations.length === 0) {
        scriptElement.line.textLocalizations = specifiedLocationsBranchInTranslationsPanel;
      } else {
        const existingTextLanguages = scriptElement.line.textLocalizations.map((loc) => loc.language);

        const filledTextLocalizations = specifiedLocationsBranchInTranslationsPanel.map((loc) => {
          if (existingTextLanguages.includes(loc.language)) {
            return (
              scriptElement.line.textLocalizations.find((existingLoc) => existingLoc.language === loc.language) || loc
            );
          }
          return loc;
        });

        scriptElement.line.textLocalizations = filledTextLocalizations;
      }
    });

    let exercise: DialogueExerciseInterface = {
      ...clonedCourseSlice.loadedExercise.exercise,
      content,
      type: ExerciseTypes.dialogue,
    };

    let output = {
      ...clonedCourseSlice,
      selectedGroupsOfParent: selectedGroupsOfParentInitialData,
      loadedExercise: {
        loaded: LoadingStage.loaded,
        exercise,
      },
    };

    return output;
  },
  [DialogueExerciseActions.UPDATE_DIALOGUE_LINE]: (
    state: CourseSliceInterface,
    { type, payload: { newText, scriptLineIndex, courseLearningLanguage } }: PayloadAction<any>,
  ): CourseSliceInterface => {
    let clonedState: CourseSliceInterface = clone(state);
    let loadedExercise: LoadedDialogueExerciseInterface =
      clonedState?.loadedExercise as LoadedDialogueExerciseInterface;

    let localization = findLocalizationInSearchedLanguage(
      loadedExercise.exercise.content.script[scriptLineIndex].line.textLocalizations,
      courseLearningLanguage,
    );

    if (localization !== undefined) localization.value = newText;

    loadedExercise.exercise.content.scriptChanged = true;
    loadedExercise.exercise.content.script[scriptLineIndex].line.changed = true;

    return {
      ...clonedState,
      loadedExercise,
    };
  },
  [DialogueExerciseActions.SET_INSTRUCTIONS_LANGUAGE]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    return ExerciseDataModelAssembler.updateCheckboxWithoutAffectingSaveButton('instructionsLanguage', state, payload);
  },
  [DialogueExerciseActions.SET_DIALOGUE_DESCRIPTION_LANGUAGE]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    return ExerciseDataModelAssembler.updateCheckboxWithoutAffectingSaveButton(
      'dialogueDescriptionLanguage',
      state,
      payload,
    );
  },
  [DialogueExerciseActions.SET_DIALOGUE_DESCRIPTION_NEEDED]: (
    state: CourseSliceInterface,
    { type, payload: { needed } }: PayloadAction<any>,
  ): CourseSliceInterface => {
    let clonedState: CourseSliceInterface = clone(state);

    let loadedExercise: LoadedDialogueExerciseInterface =
      clonedState?.loadedExercise as LoadedDialogueExerciseInterface;

    return {
      ...clonedState,
      loadedExercise,
    };
  },
  [DialogueExerciseActions.UPDATE_CHARACTER_NAME]: (
    state: CourseSliceInterface,
    { type, payload: { characterIndex, name } }: PayloadAction<any>,
  ): CourseSliceInterface => {
    let clonedState: CourseSliceInterface = clone(state);

    let loadedExercise: LoadedDialogueExerciseInterface =
      clonedState?.loadedExercise as LoadedDialogueExerciseInterface;
    loadedExercise.exercise.content.characters[characterIndex].name = name;
    loadedExercise.exercise.content.charactersChanged = true;

    return {
      ...clonedState,
      loadedExercise,
    };
  },
  [DialogueExerciseActions.ADD_SCRIPT_LINE]: (
    state: CourseSliceInterface,
    { type, payload: { characterIndex, position } }: PayloadAction<any>,
  ): CourseSliceInterface => {
    let clonedState: CourseSliceInterface = clone(state);

    let loadedExercise: LoadedDialogueExerciseInterface =
      clonedState?.loadedExercise as LoadedDialogueExerciseInterface;
    let script = loadedExercise.exercise.content.script;
    let newLine: ScriptLine = {
      characterIndex,
      line: ExerciseDataModelAssembler.generateTranslationsPanelContentPopulatedWithEmptyLocalizations(
        'Dialogue line.',
      ),
    };

    newLine.line._id = '';

    const updatedScript = [...script];
    updatedScript.splice(position, 0, newLine);
    loadedExercise.exercise.content.script = updatedScript;
    loadedExercise.exercise.content.scriptChanged = true;

    return {
      ...clonedState,
      loadedExercise,
    };
  },
  [DialogueExerciseActions.SET_SELECTED_CHARACTERS_SELECTOR]: (
    state: CourseSliceInterface,
    { type, payload: { characters } }: PayloadAction<any>,
  ): CourseSliceInterface => {
    let clonedState: CourseSliceInterface = clone(state);

    let loadedExercise: LoadedDialogueExerciseInterface =
      clonedState?.loadedExercise as LoadedDialogueExerciseInterface;

    loadedExercise.exercise.content.characters = characters;
    loadedExercise.exercise.content.charactersChanged = true;

    return {
      ...clonedState,
      loadedExercise,
    };
  },
  [DialogueExerciseActions.UPDATE_SELECTED_CHARACTERS]: (
    state: CourseSliceInterface,
    { type, payload: { updatedCharacter, characterIndex } }: PayloadAction<any>,
  ): CourseSliceInterface => {
    let clonedState: CourseSliceInterface = clone(state);

    let loadedExercise: LoadedDialogueExerciseInterface =
      clonedState?.loadedExercise as LoadedDialogueExerciseInterface;

    loadedExercise.exercise.content.characters[characterIndex].image = updatedCharacter.image;
    loadedExercise.exercise.content.charactersChanged = true;

    return {
      ...clonedState,
      loadedExercise,
    };
  },
  [DialogueExerciseActions.SWITCH_CHARACTER_FOR_DIALOGUE_LINE]: (
    state: CourseSliceInterface,
    { type, payload: { dialogueLineIndex } }: PayloadAction<any>,
  ): CourseSliceInterface => {
    let clonedState: CourseSliceInterface = clone(state);

    let loadedExercise: LoadedDialogueExerciseInterface =
      clonedState?.loadedExercise as LoadedDialogueExerciseInterface;

    if (loadedExercise.exercise.content.script[dialogueLineIndex]) {
      let currentCharacter = loadedExercise.exercise.content.script[dialogueLineIndex].characterIndex;

      loadedExercise.exercise.content.script[dialogueLineIndex].characterIndex = currentCharacter === 0 ? 1 : 0;
      loadedExercise.exercise.content.scriptChanged = true;

      return {
        ...clonedState,
        loadedExercise,
      };
    }
    return clonedState;
  },
  [DialogueExerciseActions.DELETE_DIALOGUE_LINE]: (
    state: CourseSliceInterface,
    { type, payload: { dialogueLine } }: PayloadAction<any>,
  ): CourseSliceInterface => {
    let clonedState: CourseSliceInterface = clone(state);

    let loadedExercise: LoadedDialogueExerciseInterface =
      clonedState?.loadedExercise as LoadedDialogueExerciseInterface;

    loadedExercise.exercise.content.script.splice(dialogueLine, 1);
    loadedExercise.exercise.content.scriptChanged = true;

    return {
      ...clonedState,
      loadedExercise,
    };
  },
  [DialogueExerciseActions.UPDATE_SCRIPT_LINES_ORDER]: (
    state: CourseSliceInterface,
    { type, payload: { sortedScript } }: PayloadAction<any>,
  ): CourseSliceInterface => {
    let clonedState: CourseSliceInterface = clone(state);

    let loadedExercise: LoadedDialogueExerciseInterface =
      clonedState?.loadedExercise as LoadedDialogueExerciseInterface;

    loadedExercise.exercise.content.script = sortedScript;

    return {
      ...clonedState,
      loadedExercise,
    };
  },
  [DialogueExerciseActions.SET_DIALOGUE_ALL_FIELDS]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    let clonedCourseSlice: CourseSliceInterface = clone(state);

    const { values } = payload;

    clonedCourseSlice = updateTextField(clonedCourseSlice, values, 'instructions');
    clonedCourseSlice = updateTextField(clonedCourseSlice, values, 'dialogueDescription');

    return clonedCourseSlice;
  },
  [DialogueExerciseActions.REMOVE_SOUND_FROM_DIALOGUE_LINE]: (
    state: CourseSliceInterface,
    { type, payload: { scriptLineId, courseLearningLanguage } }: PayloadAction<any>,
  ): CourseSliceInterface => {
    let clonedState: CourseSliceInterface = clone(state);

    return {
      ...clonedState,
      loadedExercise: {
        ...clonedState.loadedExercise,
        // @ts-ignore
        exercise: {
          ...clonedState.loadedExercise.exercise,
          content: {
            ...clonedState.loadedExercise.exercise.content,
            scriptChanged: true,
            script: clonedState.loadedExercise.exercise.content.script.map((line: ScriptLine) => {
              if (line.line?._id === scriptLineId) {
                return {
                  ...line,
                  line: {
                    ...line.line,
                    audioLocalizations: line.line.audioLocalizations.map((loc: LocalizationInterface) =>
                      loc.language === courseLearningLanguage ? { ...loc, value: '', _id: '', fileId: null } : loc,
                    ),
                  },
                };
              } else {
                return line;
              }
            }),
          },
        },
      },
    };
  },
};

export default DialogueExerciseCourseReducers;
