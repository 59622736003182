import { FocusEvent, ReactElement, useCallback, useEffect, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { useIsEditAvailable } from '@features/content/courses';
import { LocalizationInterface } from '@common/interfaces/localization/LocalizationInterface';
import { AnyExerciseContentBranch } from '@common/types/AnyExerciseContentBranch';
import './AlternativeValueCreator.scss';
import { useAppSelector } from '@redux/store';
import { selectLearningLanguage, selectLoadedExerciseContent } from '@selectors/CoursesSelectors';
import styled from 'styled-components/macro';

const AlternativeValueCreatorField = styled.div`
  display: flex;
  gap: 0.4rem;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1.8rem;
  position: relative;
`;

const AlternativeValueCreatorSuggestions = styled.div`
  z-index: 1000;
  width: 100%;
  min-height: 3.4rem;
  border-radius: 0.8rem;
  padding: 0.5rem 0.8rem;
  border: 0.1rem solid ${({ theme }) => theme.color.brandGreyscale600};
  box-shadow: 0 0.4rem 1.2rem 0 rgba(0, 0, 0, 0.08);
  background: ${({ theme }) => theme.colorV2.uiLightest};
  display: flex;
  gap: 0.8rem;
  align-items: center;
  flex-direction: row;
  position: absolute;
  bottom: 0;
  transform: translateY(calc(100% + 0.4rem));

  & > span {
    font-size: 1.2rem;
    line-height: 1.8rem;
  }
`;

const AlternativeValueCreatorSuggestionsList = styled.ul`
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
`;

const AlternativeValueCreatorSuggestionsItem = styled.li`
  background: ${({ theme }) => theme.colorV2.uiLightest};
  height: 2.4rem;
  padding: 0 0.8rem 0 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1.2rem;
  border: 0.1rem solid ${({ theme }) => theme.colorV2.accentPrimary};
  color: ${({ theme }) => theme.colorV2.accentPrimary};
  font-size: 1.4rem;
  line-height: 2.1rem;
`;

// @TODO Move to @features/content/exercises

interface ConfigurableAlternativeValueCreatorProps {
  values: string[];
  onChange: (values: string[]) => void;
  /** @description Called on changes if `blocked` is true */
  onChangeInstant?: () => void;
  /** @description Component will still display as active, but onChange won't receive new values */
  blocked?: boolean;
  disabled?: boolean;
  title: string;
  panels: ReactElement[];
}
export const ConfigurableAlternativeValueCreator = ({
  onChange,
  onChangeInstant,
  blocked,
  disabled,
  values,
  title,
  panels,
}: ConfigurableAlternativeValueCreatorProps) => {
  const altValuesOptions = values.map((value: string) => ({ label: value, value }));
  const [focused, setFocused] = useState(false);

  const onBlur = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      if (event.target.value) {
        if (blocked) {
          onChangeInstant && onChangeInstant();
          onChange(values);
        } else {
          onChange([...values, event.target.value]);
        }
      }
    },
    [blocked, onChangeInstant, onChange, values],
  );

  const onInputChange = useCallback(
    (value: string) => {
      if (blocked && value) {
        onChangeInstant && onChangeInstant();
      }
    },
    [blocked, onChangeInstant],
  );

  const onChangeHandler = useCallback(
    (newValues: readonly { value: string; label: string }[]) => {
      if (blocked) {
        onChangeInstant && onChangeInstant();
        onChange(values);
      } else {
        onChange(newValues.map((option) => option.value));
      }
    },
    [blocked, onChangeInstant, onChange, values],
  );

  return (
    <div className="alternative-value-creator" data-testid="alternative-value-creator">
      <h3 className="exercise-flashcard__box-title">{title}</h3>
      <AlternativeValueCreatorField>
        <CreatableSelect
          inputId="alternative-value-creator"
          placeholder="New value..."
          defaultValue={altValuesOptions}
          isDisabled={disabled}
          value={altValuesOptions}
          isMulti
          onBlur={(e) => {
            onBlur(e);
            // this is needed to avoid panels closing before action completes there
            setTimeout(() => setFocused(false), 200);
          }}
          onFocus={() => {
            setFocused(true);
          }}
          onInputChange={onInputChange}
          onChange={onChangeHandler}
          options={altValuesOptions}
          className="basic-multi-select"
          classNamePrefix="select"
        />
        {focused && panels}
      </AlternativeValueCreatorField>
    </div>
  );
};

export type AlternativeValueSuggestionsPanelProps = {
  enabled: boolean;
  values: string[];
  load?: () => Promise<string[]>;
  onSelect: (newValue: string) => void;
};
const AlternativeValueSuggestionsPanel = ({
  enabled,
  values,
  load,
  onSelect,
}: AlternativeValueSuggestionsPanelProps) => {
  const [suggestions, setSuggestions] = useState<string[] | null>(null);

  const availableSuggestions = (suggestions || []).filter((suggestion) => !values.includes(suggestion));

  useEffect(() => {
    if (enabled && load) {
      load()
        .then((suggestions) => {
          setSuggestions(suggestions);
        })
        .catch(() => {
          setSuggestions([]);
        });
    }
  }, [load, enabled]);

  if (availableSuggestions.length === 0) {
    return null;
  }

  return (
    <AlternativeValueCreatorSuggestions>
      <span>Suggested by AI:</span>{' '}
      {suggestions ? (
        <AlternativeValueCreatorSuggestionsList>
          {availableSuggestions.map((suggestion) => (
            <AlternativeValueCreatorSuggestionsItem onClick={() => onSelect(suggestion)} key={suggestion}>
              {suggestion}
            </AlternativeValueCreatorSuggestionsItem>
          ))}
        </AlternativeValueCreatorSuggestionsList>
      ) : (
        'Loading'
      )}
    </AlternativeValueCreatorSuggestions>
  );
};

export type AlternativeValueCreatorInterface = {
  visitedBranch: AnyExerciseContentBranch;
  bundleName?: string;
  onChange: (values: { value: string; label: string }[]) => void;
  onChangeInstant?: () => void;
  isChangeBlocked?: boolean;
  loadSuggestions?: () => Promise<string[]>;
};
export const AlternativeValueCreator = ({
  bundleName,
  visitedBranch,
  isChangeBlocked,
  onChange,
  loadSuggestions,
  ...props
}: AlternativeValueCreatorInterface) => {
  const { isEditAvailable } = useIsEditAvailable();
  const courseLearningLanguage = useAppSelector(selectLearningLanguage);
  const loadedExercise = useAppSelector(selectLoadedExerciseContent);

  const altValues: string[] = bundleName
    ? loadedExercise[bundleName][visitedBranch]?.textLocalizations.find(
        (loc: LocalizationInterface) => loc.language === courseLearningLanguage,
      )?.alternativeValues || []
    : loadedExercise[visitedBranch]?.textLocalizations.find(
        (loc: LocalizationInterface) => loc.language === courseLearningLanguage,
      )?.alternativeValues || [];

  const customPanels =
    !isChangeBlocked && loadSuggestions !== null
      ? [
          <AlternativeValueSuggestionsPanel
            values={altValues}
            enabled={!isChangeBlocked}
            load={loadSuggestions}
            onSelect={(suggestion) => onChange([...altValues, suggestion].map((value) => ({ label: value, value })))}
          />,
        ]
      : [];

  return (
    <ConfigurableAlternativeValueCreator
      {...props}
      values={altValues}
      title={`${courseLearningLanguage} alternative values`}
      disabled={!isEditAvailable}
      blocked={isChangeBlocked}
      panels={customPanels}
      onChange={(newValues) => onChange(newValues.map((value) => ({ label: value, value })))}
    />
  );
};
