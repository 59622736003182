import * as Sentry from '@sentry/react';
import { Analytics } from '@vercel/analytics/react';
import { SpeedInsights } from '@vercel/speed-insights/react';

import { UserInterface } from '@common/interfaces/users/UserInterface';
import { SecurityProvider } from '@features/auth';
import { HelpRefsProvider } from '@features/help';
import { ThemeProvider } from '@features/theme';

import { Routes } from './Routes';
import { RoutingProvider } from './router';
import { StoreProvider } from './store';
import { ToastProvider } from './toast';
import { userTracking } from './tracking';

import '@scss/main.scss';

const onSetUser = (user: UserInterface | null) => {
  // Error monitoring
  Sentry.setUser(
    user
      ? {
          id: user.id,
          email: user.email,
        }
      : null,
  );

  // User tracking
  userTracking.identify(user ? user.id : undefined);
};

export const App = () => {
  return (
    <>
      <StoreProvider>
        <RoutingProvider>
          <ThemeProvider>
            <SecurityProvider onSetUser={onSetUser}>
              <HelpRefsProvider>
                <ToastProvider>
                  <Routes />
                </ToastProvider>
              </HelpRefsProvider>
            </SecurityProvider>
          </ThemeProvider>
        </RoutingProvider>
      </StoreProvider>
      <Analytics />
      <SpeedInsights />
    </>
  );
};
