import { useFormikContext } from 'formik';

import { SearchModalActionsCreator } from '@actionCreators/SearchModalActionsCreator';
import { useIsEditAvailable } from '@features/content/courses';
import { SearchPanelSliceType } from '@features/search';
import { processLocalizationsForFormikValues } from '@helpers/formikInitialValuesHelper';
import { userTracking } from '@features/app/tracking';

import { StringDetailsTab, StringSearchResultDetailsType } from '../../types';
import {
  AudiosAndTranslationsContent,
  DetailsTabs,
  DetailsWrapper,
  LocationsContent,
  ReuseContentButton,
} from '../Common';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { selectSearchPanel } from '@selectors/UiSelectors';

type DetailsProps = {
  errorMessage: string;
  defaultTab: StringDetailsTab['key'] | null;
  details: StringSearchResultDetailsType | null;
  isLoaded: boolean;
  isExpanded: boolean;
  isPopular?: boolean;
  isDefaultInstruction?: boolean;
};

export const StringResultDetails = ({
  errorMessage,
  details,
  defaultTab,
  isLoaded,
  isExpanded,
  isPopular = false,
  isDefaultInstruction = false,
}: DetailsProps) => {
  const dispatch = useAppDispatch();
  const isLoaderDisplayed = !isLoaded && !details;
  const formikProps = useFormikContext();
  const { isEditAvailable } = useIsEditAvailable();
  const searchParams = useAppSelector(selectSearchPanel);

  const { bundleName, forReusing, visitedBranch } = searchParams;
  const showReuseContentButton = isEditAvailable && forReusing;

  const tabs: StringDetailsTab[] = [
    {
      key: 'audioAndTranslation',
      title: 'Audio & Translation',
      Component: () => (
        <AudiosAndTranslationsContent
          audioLocalizations={details?.audioLocalizations || []}
          textLocalizations={details?.textLocalizations || []}
        />
      ),
    },
  ];

  if (!isPopular && !isDefaultInstruction) {
    tabs.push({
      key: 'locations',
      title: 'Location(s)',
      Component: () => <LocationsContent locations={details?.mappings || []} />,
    });
  }

  const updateFieldValue = ({
    bundleName,
    visitedBranch,
    details,
  }: Partial<SearchPanelSliceType> & { details: StringSearchResultDetailsType }) => {
    const fieldName = bundleName ? `${bundleName}_${visitedBranch}` : visitedBranch;
    formikProps.setFieldValue(fieldName || '', processLocalizationsForFormikValues(details?.textLocalizations));
    formikProps.setFieldValue(`${fieldName}Changed`, false);
  };

  const onCopyContent = () => {
    if (!details) return;

    dispatch(SearchModalActionsCreator.copyString(details));

    if ('setFieldValue' in formikProps) {
      updateFieldValue({ bundleName, visitedBranch, details });
    }
  };

  const onUseContent = () => {
    if (!details) return;

    dispatch(SearchModalActionsCreator.reuseString(details));

    if ('setFieldValue' in formikProps && visitedBranch !== 'examples') {
      updateFieldValue({ bundleName, visitedBranch, details });
    }

    userTracking.logosSearchReuseSelected({
      type: 'string',
    });
  };

  return (
    <DetailsWrapper isExpanded={isExpanded} errorMessage={errorMessage} isLoaderDisplayed={isLoaderDisplayed}>
      {showReuseContentButton && (
        <ReuseContentButton
          contentLabel="String"
          inProgress={false}
          onCopyContent={onCopyContent}
          onUseContent={onUseContent}
        />
      )}
      <DetailsTabs tabs={tabs} defaultTab={defaultTab} />
    </DetailsWrapper>
  );
};
