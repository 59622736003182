import { useState } from 'react';
import classnames from 'classnames';
import arrowIcon from '@static/svg/translationsPanel/Dropup.svg';
import { Audios, AudiosProps } from './Audios';

export const InactiveAudios = ({ ...props }: AudiosProps) => {
  const [inactiveShown, setInactiveShown] = useState(false);

  const handleToggle = () => {
    setInactiveShown(!inactiveShown);
  };

  return (
    <>
      <div
        className={classnames('inactive-languages-toggler', {
          'inactive-languages-toggler--hide': inactiveShown,
        })}
        onClick={handleToggle}
      >
        {inactiveShown ? 'Hide' : 'Show'} other languages
        <img src={arrowIcon} className="arrow-icon" alt="" />
      </div>
      {inactiveShown && <Audios {...props} />}
    </>
  );
};
