import { PlacementTestActions } from '@actions/PlacementTestActions';
import type { DBId } from '@common/types/DBId';
import type { ExerciseType } from '@common/enums/ExerciseTypes';
import type { NavigationItemType } from '@features/content/navigation';

export const PlacementTestActionCreators = {
  removeExercise: (courseId: DBId, entrypointId: DBId, exerciseId: DBId) => ({
    type: PlacementTestActions.REMOVE_EXERCISE,
    payload: { courseId, entrypointId, exerciseId },
  }),
  setInitialEntrypoint: () => ({ type: PlacementTestActions.SET_INITIAL_ENTRYPOINT }),
  createExercise: (courseId: DBId, entrypointId: DBId, exerciseType: any, position: number) => ({
    type: PlacementTestActions.CREATE_EXERCISE,
    payload: {
      courseId,
      entrypointId,
      exerciseType,
      position,
    },
  }),
  getEntrypoint: (courseId: DBId, entrypointId: DBId) => ({
    type: PlacementTestActions.GET_ENTRYPOINT,
    payload: { courseId, entrypointId },
  }),
  getEntrypointExercises: (courseId: DBId, entrypointId: DBId) => ({
    type: PlacementTestActions.GET_ENTRYPOINT_EXERCISES,
    payload: { courseId, entrypointId },
  }),
  saveEntrypoint: (
    courseId: DBId,
    entrypointId: DBId,
    requestPayload: {
      chapter: { id: DBId; title: string } | null;
    },
  ) => ({ type: PlacementTestActions.SAVE_ENTRYPOINT, payload: { courseId, entrypointId, requestPayload } }),
  getPlacementTest: (courseId: DBId) => ({ type: PlacementTestActions.GET_PLACEMENT_TEST, payload: { courseId } }),
  createPlacementTest: (courseId: DBId) => ({
    type: PlacementTestActions.CREATE_PLACEMENT_TEST,
    payload: { courseId },
  }),
  getEntrypoints: (courseId: DBId, placementTestId: DBId) => ({
    type: PlacementTestActions.GET_ENTRYPOINTS,
    payload: { courseId, placementTestId },
  }),
  setInitialPlacementTest: () => ({ type: PlacementTestActions.SET_INITIAL_PLACEMENT_TEST }),
  reuseExercise: (
    courseId: DBId,
    entrypointId: DBId,
    exerciseId: DBId,
    exerciseType: ExerciseType | undefined,
    position: number,
  ) => ({
    type: PlacementTestActions.REUSE_EXERCISE,
    payload: {
      courseId,
      entrypointId,
      exerciseId,
      exerciseType,
      position,
    },
  }),
  copyExercise: (
    courseId: DBId,
    entrypointId: DBId,
    exerciseId: DBId,
    exerciseType: ExerciseType | undefined,
    position: number,
  ) => ({
    type: PlacementTestActions.COPY_EXERCISE,
    payload: {
      courseId,
      entrypointId,
      exerciseId,
      exerciseType,
      position,
    },
  }),
  setLoadingParentId: (id: DBId) => ({
    type: PlacementTestActions.SET_LOADING_PARENT_ID,
    payload: id,
  }),
  updateNavigation: (newNavigation: NavigationItemType[]) => ({
    type: PlacementTestActions.UPDATE_NAVIGATION,
    payload: newNavigation,
  }),
  getAllNavigation: (courseId: DBId, entrypointId: DBId) => ({
    type: PlacementTestActions.GET_ALL_NAVIGATION,
    payload: { courseId, entrypointId },
  }),
  setInitialPlacementExercise: () => ({ type: PlacementTestActions.SET_INITIAL_PLACEMENT_EXERCISE }),
};
