import styled from 'styled-components';

import IDDisplayer from '@components/IDDisplayer/IDDisplayer';
import { DBId } from '@common/types/DBId';
import { ACTIVITY_SPEAKING, ACTIVITY_TITLES, type ActivityType } from '@features/content/activities';

import { ReactComponent as ComprehensionIcon } from './img/Comprehension.svg';
import { ReactComponent as ConversationIcon } from './img/Conversation.svg';
import { ReactComponent as DevelopIcon } from './img/Develop.svg';
import { ReactComponent as DialogueIcon } from './img/Dialogue.svg';
import { ReactComponent as DiscoverIcon } from './img/Discover.svg';
import { ReactComponent as EntityIcon } from './img/Entity.svg';
import { ReactComponent as MemoriseIcon } from './img/Memorise.svg';
import { ReactComponent as PracticeIcon } from './img/Practice.svg';
import { ReactComponent as ProductiveIcon } from './img/Productive.svg';
import { ReactComponent as PronunciationIcon } from './img/Pronunciation.svg';
import { ReactComponent as QuizIcon } from './img/Quiz.svg';
import { ReactComponent as ReadingIcon } from './img/Reading.svg';
import { ReactComponent as SpeakingIcon } from './img/Speaking.svg';
import { ReactComponent as VideoIcon } from './img/Video.svg';
import { ReactComponent as VocabularyIcon } from './img/Vocabulary.svg';

const ACTIVITY_ICON: Record<ActivityType, JSX.Element> = {
  comprehensionSkills: <ComprehensionIcon />,
  conversation: <ConversationIcon />,
  develop: <DevelopIcon />,
  dialogue: <DialogueIcon />,
  discover: <DiscoverIcon />,
  entity: <EntityIcon />,
  memorise: <MemoriseIcon />,
  practice: <PracticeIcon />,
  productiveSkills: <ProductiveIcon />,
  pronunciation: <PronunciationIcon />,
  quiz: <QuizIcon />,
  reading: <ReadingIcon />,
  video: <VideoIcon />,
  vocabulary: <VocabularyIcon />,
  speakingActivity: <SpeakingIcon />,
};

const ActivityIconWrapper = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.color.brandPrimaryBase};
  border-radius: 50%;
  display: flex;
  height: 5.6rem;
  justify-content: center;
  width: 5.6rem;

  svg {
    height: auto;
    width: 3.2rem;

    path {
      fill: ${({ theme }) => theme.color.brandWhite};
    }
  }
`;

const CardActivityWrapper = styled.div`
  display: flex;
  gap: 1.6rem;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 1.6rem;
  gap: 0.6rem;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 18rem;
  white-space: nowrap;
`;

export const CardActivityTitle = ({ id, activityType }: { id: DBId; activityType: ActivityType }) => {
  const _activityType = activityType === ('speaking' as ActivityType) ? ACTIVITY_SPEAKING : activityType;

  return (
    <CardActivityWrapper>
      <ActivityIconWrapper>{ACTIVITY_ICON[_activityType]}</ActivityIconWrapper>
      <TitleWrapper>
        <IDDisplayer id={id} mode="small" truncateAt={0} />
        <Title>{ACTIVITY_TITLES[_activityType]}</Title>
      </TitleWrapper>
    </CardActivityWrapper>
  );
};
