import { useMemo } from 'react';
import Avatar from 'react-avatar';
import { OptionProps, components } from 'react-select';
import styled from 'styled-components/macro';

import { UserInterface } from '@common/interfaces/users/UserInterface';
import { NameContainer } from '@components/RolesAndPermissions/AdminSettings/Table/UserItem/NameContainer/NameContainer';
import { CommonFilterOptionType, CommonFilterSelector } from '@components/SelectorCommonComponents';

import { DefaultPlaceholder } from '../DefaultPlaceholder/DefaultPlaceholder';

const ManagerSelector = styled(CommonFilterSelector)`
  .common-filter-selector__value-container::before {
    content: '' !important;
  }
`;

const OptionContainer = styled.div`
  align-items: center;
  display: flex;
`;

const ManagerCustomOption = (props: OptionProps<CommonFilterOptionType>) => {
  const {
    data: { label, value },
  } = props;

  return (
    <components.Option {...props}>
      <OptionContainer key={value}>
        <Avatar name={label} className="name-container__image" size="32" />
        <span>{label}</span>
      </OptionContainer>
    </components.Option>
  );
};

type ManagerContainerProps = {
  isEditMode: boolean;
  availableManagers: UserInterface[];
  currentManager: UserInterface | null;
  setManager: (newManager: string | null) => void;
};

export const ManagerContainer = ({
  isEditMode,
  availableManagers,
  currentManager,
  setManager,
}: ManagerContainerProps) => {
  const currentManagerValue = useMemo(() => {
    if (!currentManager) return null;

    return {
      label: `${currentManager?.firstName} ${currentManager?.lastName}`,
      value: currentManager?.id,
    };
  }, [currentManager]);

  if (isEditMode) {
    return (
      <ManagerSelector
        options={availableManagers.map((availableManager) => ({
          label: `${availableManager.firstName} ${availableManager.lastName}`,
          value: availableManager.id,
        }))}
        optionComponent={ManagerCustomOption}
        placeholder="Manager"
        value={currentManagerValue}
        withSearchIcon
        onChange={(selectedValue) => {
          setManager(selectedValue?.value || null);
        }}
      />
    );
  } else {
    return (
      <>
        {currentManager ? (
          <NameContainer name={`${currentManager.firstName} ${currentManager.lastName}`} />
        ) : (
          <DefaultPlaceholder>Not assigned</DefaultPlaceholder>
        )}
      </>
    );
  }
};
