import { useEffect, useState } from 'react';
import classnames from 'classnames';
import ReactTooltip from 'react-tooltip';
import { ExerciseCommonActionCreators as ExerciseCommonActions } from '@actionCreators/ExerciseCommonActionCreator';
import { ExerciseForRecap } from '@components/Exercises/Common/RecapSelector/ExerciseForRecap';
import QuestionIcon from '@components/Exercises/Common/RecapSelector/question.svg';
import ArrowIcon from '@components/Exercises/Common/RecapSelector/arrow.svg';

import { LoadingStage } from '@common/enums/LoadingStage';
import { CourseEditionActionsCreator } from '@actionCreators/CourseEditionActionsCreator';
import { RecapOptionsInterface } from '@common/interfaces/contentTypes/RecapOptionsInterface';
import { ValidationErrorDisplayer } from '@components/ValidationErrorDisplayer';
import './RecapSelector.scss';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { HelpDisplayer } from '@features/help';
import { selectLoadedExerciseContent, selectRecapOptions } from '@selectors/CoursesSelectors';

export const RecapSelector = ({ exerciseId }: { exerciseId?: string }) => {
  const dispatch = useAppDispatch();
  const [opened, setOpened] = useState(false);

  const recapOptions: RecapOptionsInterface = useAppSelector(selectRecapOptions);

  useEffect(() => {
    if (exerciseId && recapOptions.loaded === LoadingStage.notLoaded) {
      dispatch(CourseEditionActionsCreator.getRecapOptions(exerciseId));
    }
  }, [dispatch, exerciseId, recapOptions]);

  const loadedExercise = useAppSelector(selectLoadedExerciseContent);

  const currentId = loadedExercise.recapExerciseId;

  const selectedExercise = recapOptions.exercises.find((exercise: any) => exercise.id === currentId);
  let selectedExerciseIndex = 0;
  recapOptions.exercises.map((exercise: any, index: number) =>
    exercise.id === currentId ? (selectedExerciseIndex = index) : exercise,
  );

  return (
    <>
      {recapOptions.exercises.length ? (
        <div>
          <ReactTooltip className="tooltip" id="recap-tooltip" effect="solid" place="bottom" />

          <div className="recap-title">
            <img
              alt="More info icon"
              src={QuestionIcon}
              data-for="recap-tooltip"
              data-tip={`An exercise can contain a recap to a previous Video or
                                Reading Comprehension exercise from the same activity.`}
              className="recap-tooltip-image"
            />
            <h3 className="recap-title__box-title">Exercise recap ID</h3>
            <HelpDisplayer type="guideline" id="guideline-recap" />
          </div>

          {!currentId ? (
            <div
              className={classnames('recap-selector', { 'recap-selector--opened': opened })}
              onClick={() => {
                setOpened(!opened);
              }}
            >
              <span>Choose the exercise you want to recap.</span>
              <img src={ArrowIcon} alt="" className={classnames('arrow-image', { 'arrow-image--opened': opened })} />
            </div>
          ) : (
            <>
              <div
                className={classnames('recap-selector', 'recap-selector--selected', {
                  'recap-selector--opened': opened,
                })}
                onClick={() => {
                  setOpened(!opened);
                }}
              >
                <ExerciseForRecap exercise={selectedExercise} number={selectedExerciseIndex + 1} />
                <img src={ArrowIcon} alt="" className={classnames('arrow-image', { 'arrow-image--opened': opened })} />
              </div>
              {!selectedExercise?.ready ? (
                <ValidationErrorDisplayer
                  visible={true}
                  text={'Oops! Learners won’t see the recap option until the comprehension exercise is published.'}
                />
              ) : null}
            </>
          )}
          {opened && (
            <div className="recap-options">
              <div
                className={classnames('recap-options__item', {
                  'recap-options__item--active': !currentId,
                })}
                onClick={() => {
                  dispatch(ExerciseCommonActions.setRecapId(null));
                  setOpened(false);
                }}
              >
                No recap
              </div>
              {recapOptions.exercises.map((exercise: any, index: number) => {
                return (
                  <div
                    key={exercise.id}
                    className={classnames('recap-options__item', 'recap-options__item--exercise', {
                      'recap-options__item--active': exercise.id === currentId,
                    })}
                    onClick={() => {
                      dispatch(ExerciseCommonActions.setRecapId(exercise.id));
                      setOpened(false);
                    }}
                  >
                    <ExerciseForRecap exercise={exercise} number={index + 1} />
                  </div>
                );
              })}
            </div>
          )}
        </div>
      ) : null}
    </>
  );
};
