import { useAppDispatch, useAppSelector } from '@redux/store';
import * as Sentry from '@sentry/react';
import { FormikContextType, useFormikContext } from 'formik';
import React, { FocusEvent, useEffect, useRef, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

import { ContentTypes } from '@common/enums/ContentTypes';
import {
  EXERCISE_FILLGAP,
  EXERCISE_HIGHLIGHTER,
  EXERCISE_PHRASE_BUILDER,
  EXERCISE_TYPING,
} from '@common/enums/ExerciseTypes';
import { ReactComponent as TrashIcon } from '@components/ContentTypes/BaseContent/img/trashNew.svg';
import { TranslationsPanelModes } from '@components/TranslationsPanel/enums/TranslationsPanelModes';
import { FlagTextPositionMode } from '@components/Flag';
import { useIsAdmin } from '@features/auth/roles';
import { constants as contentConstants } from '@features/content';
import { Language } from '@features/content/languages';
import { useIsEditAvailable } from '@features/content/courses';
import { SearchPanelParamsType } from '@features/search';
import { SearchModalActionsCreator } from '@actionCreators/SearchModalActionsCreator';
import { FormikValuesInterface } from '@helpers/formikInitialValuesHelper';
import { userTracking } from '@features/app/tracking';

import { ReactComponent as AudioIcon } from './img/Audio.svg';
import { ReactComponent as LockIcon } from './img/Lock.svg';
import { ReactComponent as SearchIcon } from './img/search.svg';
import { ReactComponent as TranslationIcon } from './img/Translation.svg';
import {
  LanguageSwitchButton,
  StyledFlag,
  SuggestionsSpinnerAnimated,
  SuggestionWrapper,
  TranslationsLanguageSwitch,
  TranslationsTipWidget,
  TranslationsTipWrapper,
  TranslationTipOption,
  TranslationTipOptions,
  TranslationTipOptionSuggestions,
  TruncatedContentId,
} from './styles';
import { TranslationTipActionsCreator } from '@actionCreators/TranslationTipActionsCreator';
import { TranslationTipProps as TranslationsTipProps } from './TranslationTipProps';
import { debouncedGetStringCount, filterActualError } from './TranslationTipUtils';
import { useToast } from '@features/app/toast';
import { selectLearningLanguage, selectLoadedExercise } from '@selectors/CoursesSelectors';

const SUGGESTION_QUERY_MIN_CHARS = 5;

const TranslationsTip = ({
  children,
  localizationIDForLangExperts,
  translationElements,
  audioElements,
  visitedContentId,
  visitedBranch,
  bundleName,
  bundleId,
  isExercise,
  isLesson,
  isStringChangeBlocked,
  isBundleChangeBlocked,
  translationsPanelTranslationsMode = TranslationsPanelModes.normal,
  alwaysVisible = false,
  row,
  column,
  cells,
  onLanguageSwitchToggled,
  showLanguageSwitcher = true,
  externallyDrivenLanguageString,
  showOtherLocations = true,
  disableLearningLanguage = false,
  flipped = false,
  type = ContentTypes.exercise,
  languageForTips = 'EN',
  withImage = false,
  forTrueFalse = false,
  hideRemove = false,
  hideSearch = false,
  className,
  suggestionsQuery,
  compact = false,
  defaultContextForTranslators,
}: TranslationsTipProps) => {
  const { values, setFieldValue }: FormikContextType<FormikValuesInterface> = useFormikContext();
  const dispatch = useAppDispatch();
  const { isEditAvailable } = useIsEditAvailable();

  let [isWidgetActive, setIsWidgetActive] = useState(false);
  let [isWidgetVisible, setIsWidgetVisible] = useState(false);
  const [showContentSuggestions, setShowContentSuggestions] = useState(false);
  const [suggestionsCount, setSuggestionsCount] = useState(0);
  const [suggestionQueryLoading, setSuggestionQueryLoading] = useState(false);

  const courseLearningLanguage = useAppSelector(selectLearningLanguage);
  const loadedExercise = useAppSelector(selectLoadedExercise);

  const isAdmin = useIsAdmin();

  const showWidget = () => {
    setIsWidgetActive(true);
    if (alwaysVisible) {
      setTimeout(() => {
        setIsWidgetVisible(true);
      }, 500);
    } else {
      setIsWidgetVisible(true);
    }
  };

  const hideWidget = () => {
    setIsWidgetActive(false);
    setTimeout(() => {
      setIsWidgetVisible(false);
    }, 500);
  };

  let localizationIDForLangExpertsToBeDisplayed =
    localizationIDForLangExperts === '' ? 'N/A' : localizationIDForLangExperts;
  const isNewString = !localizationIDForLangExpertsToBeDisplayed || localizationIDForLangExpertsToBeDisplayed === 'N/A';

  let [switchedToEnglishLanguage, setSwitchedToEnglishLanguage] = useState(flipped ? true : false);
  let [isPhonetic, setIsPhonetic] = useState(false);

  const debounceTimer = useRef<ReturnType<typeof setTimeout> | undefined>();
  const controller = useRef<AbortController | undefined>();

  const showToast = useToast();

  const shouldAddSearchKtagsFilterPreset =
    type === ContentTypes.exercise &&
    [EXERCISE_FILLGAP, EXERCISE_HIGHLIGHTER, EXERCISE_PHRASE_BUILDER, EXERCISE_TYPING].includes(
      loadedExercise.exercise.type,
    ) &&
    visitedBranch.startsWith('phrase');

  const contentSuggestionsSearchFiltersPreset = {
    string: {
      translations: {
        with: ['EN'],
        without: [],
      },
      hasKTags: shouldAddSearchKtagsFilterPreset ? true : undefined,
    },
  } as SearchPanelParamsType['filtersPreset'];

  if (courseLearningLanguage !== 'EN' && contentSuggestionsSearchFiltersPreset?.string?.translations) {
    contentSuggestionsSearchFiltersPreset.string.translations.with.push(courseLearningLanguage as Language);
  }

  const handleChangeLanguage = (toEnglish: boolean, isPhoneticSelected: boolean) => {
    setSwitchedToEnglishLanguage(toEnglish);
    setIsPhonetic(isPhoneticSelected);
    onLanguageSwitchToggled && onLanguageSwitchToggled(toEnglish, isPhoneticSelected);
  };

  const showDefaultStringWarning = () => {
    showToast({
      type: 'info',
      title: 'Default instructions cannot be edited',
      description: 'To remove the default instruction, clear it using the trash can',
    });
  };

  useEffect(() => {
    if (isNewString && isWidgetActive && suggestionsQuery && suggestionsQuery.length >= SUGGESTION_QUERY_MIN_CHARS) {
      (async () => {
        try {
          debouncedGetStringCount({
            controller,
            debounceTimer,
            filtersPreset: contentSuggestionsSearchFiltersPreset,
            query: suggestionsQuery.trim(),
            setSuggestionsCount,
            setSuggestionQueryLoading,
          });
        } catch (error) {
          Sentry.captureException(error);
          setSuggestionsCount(0);
          setSuggestionQueryLoading(false);
        }
      })();
    }

    if (!suggestionsQuery || suggestionsQuery.length < SUGGESTION_QUERY_MIN_CHARS) {
      try {
        controller.current?.abort();
        setSuggestionsCount(0);
        setSuggestionQueryLoading(false);
      } catch (error) {
        filterActualError(error);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNewString, isWidgetActive, suggestionsQuery]);

  useEffect(() => {
    if (isNewString) {
      setShowContentSuggestions(true);
    }

    return () => {
      setShowContentSuggestions(false);
    };
  }, [isNewString]);

  return (
    <TranslationsTipWrapper className={className}>
      <div
        onBlur={(e: FocusEvent<HTMLDivElement>) => hideWidget()}
        onFocus={(e: FocusEvent<HTMLDivElement>) => {
          // to avoid conflict on translation tip being shown when clicking in file uploaders contained like in WritableInputTextWithImageUploader
          if (e.target.getAttribute('type') === 'file' || e.target.classList.contains('file-upload__trash')) {
            return;
          }

          showWidget();
        }}
      >
        {children &&
          React.cloneElement(children, {
            ...children.props,
            onChangeInstant: () => {
              if (bundleName && isBundleChangeBlocked) {
                dispatch(
                  TranslationTipActionsCreator.setCurrentContentId(
                    isBundleChangeBlocked ? bundleId || '' : localizationIDForLangExperts || '',
                    type,
                    visitedBranch,
                    row,
                    column,
                    bundleName,
                    isBundleChangeBlocked,
                  ),
                );
              } else {
                if (
                  isStringChangeBlocked &&
                  (!localizationIDForLangExpertsToBeDisplayed?.includes('default_') ||
                    (localizationIDForLangExpertsToBeDisplayed?.includes('default_') && isAdmin))
                ) {
                  dispatch(
                    TranslationTipActionsCreator.setCurrentContentId(
                      localizationIDForLangExperts || '',
                      type,
                      visitedBranch,
                      row,
                      column,
                      bundleName,
                      isBundleChangeBlocked,
                    ),
                  );
                }
                if (localizationIDForLangExpertsToBeDisplayed?.includes('default_') && !isAdmin) {
                  showDefaultStringWarning();
                }
              }
            },
            isChangeBlocked: isStringChangeBlocked || isBundleChangeBlocked,
          })}
      </div>
      <TranslationsTipWidget isWidgetActive={isWidgetActive} isWidgetVisible={isWidgetVisible}>
        {showLanguageSwitcher && courseLearningLanguage !== contentConstants.DEFAULT_LANGUAGE && (
          <TranslationsLanguageSwitch forTrueFalse={forTrueFalse} withImage={withImage}>
            <LanguageSwitchButton
              onClick={() => handleChangeLanguage(true, false)}
              onMouseDown={(evt: MouseEvent) => evt.preventDefault()}
            >
              <StyledFlag
                isActive={switchedToEnglishLanguage}
                mode={FlagTextPositionMode.withoutText}
                countries={[contentConstants.DEFAULT_LANGUAGE]}
              />
            </LanguageSwitchButton>
            <LanguageSwitchButton
              onClick={() => handleChangeLanguage(false, false)}
              onMouseDown={(evt: MouseEvent) => evt.preventDefault()}
            >
              <StyledFlag
                isActive={!switchedToEnglishLanguage && !isPhonetic}
                mode={FlagTextPositionMode.withoutText}
                countries={[courseLearningLanguage]}
              />
            </LanguageSwitchButton>
            {/* INTO-1104: For phonetic not sure do we need to implement it now */}
            {/* {AvailableLanguagesFullDetails.filter(lang => lang.isPhonetic).find(lang => lang.code === courseLearningLanguage) ? (
                                <Button
                                    className={classnames(
                                        "translation-tip__language-switch__button",
                                        "translation-tip__language-switch__button__phonetic",
                                        {"translation-tip__language-switch__button__phonetic--active": isPhonetic}
                                    )}
                                    onClick={() => handleChangeLanguage(false, true)}
                                    onMouseDown={e => e.preventDefault()}
                                >
                                    <img
                                        className="phonetic-image"
                                        src={PhoneticIcon}
                                        alt="Phonetic"
                                    />
                                </Button>
                            ) : null} */}
          </TranslationsLanguageSwitch>
        )}
        <TranslationTipOptions
          compact={compact}
          hideContentSuggestions={!showContentSuggestions}
          hideRemove={hideRemove}
          hideSearch={hideSearch}
        >
          {showContentSuggestions && (
            <TranslationTipOptionSuggestions>
              <SuggestionWrapper
                showMatches={suggestionsCount > 0}
                onClick={() => {
                  if (suggestionsCount === 0) return;

                  dispatch(
                    SearchModalActionsCreator.showSearchV2Panel({
                      bundleName,
                      column: column,
                      filtersPreset: contentSuggestionsSearchFiltersPreset,
                      contentType: type,
                      forReusing: true,
                      predefinedType: 'string',
                      language: languageForTips,
                      row: row,
                      query: suggestionsQuery,
                      visitedBranch: visitedBranch,
                    }),
                  );

                  userTracking.logosSearchTriggered({
                    source: 'string_suggestions',
                    type: 'string',
                  });
                }}
              >
                {suggestionQueryLoading ? (
                  <SuggestionsSpinnerAnimated />
                ) : (
                  <>
                    ~ {suggestionsCount} matches with&nbsp;<strong>"{suggestionsQuery}"</strong>
                  </>
                )}
              </SuggestionWrapper>
            </TranslationTipOptionSuggestions>
          )}

          <TranslationTipOption
            onClick={() => {
              dispatch(TranslationTipActionsCreator.populateBranch(visitedBranch, bundleName, row));
              dispatch(
                TranslationTipActionsCreator.showTranslationsPanel(
                  'translations',
                  visitedContentId,
                  localizationIDForLangExpertsToBeDisplayed,
                  visitedBranch,
                  translationsPanelTranslationsMode,
                  localizationIDForLangExpertsToBeDisplayed,
                  row,
                  column,
                  cells,
                  showOtherLocations,
                  disableLearningLanguage,
                  type,
                  bundleName,
                  isStringChangeBlocked,
                  isBundleChangeBlocked,
                  defaultContextForTranslators,
                ),
              );
            }}
          >
            <TranslationIcon title="Translation" />
            <span>trns: {translationElements === undefined ? 'N/A' : translationElements}</span>
          </TranslationTipOption>
          <TranslationTipOption
            onClick={() => {
              dispatch(TranslationTipActionsCreator.populateBranch(visitedBranch, bundleName, row));
              dispatch(
                TranslationTipActionsCreator.showTranslationsPanel(
                  'audios',
                  visitedContentId,
                  localizationIDForLangExpertsToBeDisplayed,
                  visitedBranch,
                  translationsPanelTranslationsMode,
                  localizationIDForLangExpertsToBeDisplayed,
                  row,
                  column,
                  cells,
                  showOtherLocations,
                  false,
                  type,
                  bundleName,
                  isStringChangeBlocked,
                  isBundleChangeBlocked,
                ),
              );
            }}
          >
            <AudioIcon title="Audio" />
            <span>audio: {audioElements === undefined ? 'N/A' : audioElements}</span>
          </TranslationTipOption>
          <TranslationTipOption
            title={localizationIDForLangExpertsToBeDisplayed}
            onClick={() => {
              if (localizationIDForLangExpertsToBeDisplayed?.includes('default_') && !isAdmin) {
                showDefaultStringWarning();
              }
            }}
          >
            {localizationIDForLangExpertsToBeDisplayed !== undefined && (
              <CopyToClipboard text={localizationIDForLangExpertsToBeDisplayed}>
                {localizationIDForLangExpertsToBeDisplayed.includes('default_') && !isAdmin ? (
                  <LockIcon title="Lock" />
                ) : (
                  <TruncatedContentId
                    onClick={() => {
                      showToast({
                        type: 'info',
                        title: 'Content ID copied to clipboard',
                      });
                    }}
                  >
                    {localizationIDForLangExpertsToBeDisplayed}
                  </TruncatedContentId>
                )}
              </CopyToClipboard>
            )}
          </TranslationTipOption>
          {localizationIDForLangExpertsToBeDisplayed !== 'N/A' && !hideRemove && isEditAvailable ? (
            <TranslationTipOption
              onlyIcon
              title="Remove"
              onClick={() => {
                if (bundleName && isBundleChangeBlocked) {
                  dispatch(
                    TranslationTipActionsCreator.setCurrentContentId(
                      isBundleChangeBlocked ? bundleId || '' : localizationIDForLangExperts || '',
                      type,
                      visitedBranch,
                      row,
                      column,
                      bundleName,
                      isBundleChangeBlocked,
                    ),
                  );
                } else {
                  const fieldName = bundleName ? `${bundleName}_${visitedBranch}` : visitedBranch;

                  dispatch(TranslationTipActionsCreator.removeString(type, visitedBranch, row, column, bundleName));

                  const newValues = (values as any)?.[fieldName]?.map((item: any) => {
                    return {
                      ...item,
                      value: '',
                    };
                  });
                  setFieldValue(fieldName, newValues);
                  setFieldValue(`${fieldName}Changed`, false);
                }
              }}
            >
              <TrashIcon title="Remove" />
            </TranslationTipOption>
          ) : null}
          {!hideSearch && (
            <TranslationTipOption
              onlyIcon
              title="Search"
              onClick={() => {
                if (bundleName && isBundleChangeBlocked) {
                  dispatch(
                    TranslationTipActionsCreator.setCurrentContentId(
                      isBundleChangeBlocked ? bundleId || '' : localizationIDForLangExperts || '',
                      type,
                      visitedBranch,
                      row,
                      column,
                      bundleName,
                      isBundleChangeBlocked,
                    ),
                  );
                } else {
                  dispatch(
                    SearchModalActionsCreator.showSearchV2Panel({
                      visitedBranch: visitedBranch,
                      row: row,
                      column: column,
                      forReusing: true,
                      contentType: type,
                      predefinedType: 'string',
                      language: languageForTips,
                      bundleName,
                    }),
                  );
                  userTracking.logosSearchOpened({
                    source: 'resource',
                  });
                }
              }}
            >
              <SearchIcon title="Search" />
            </TranslationTipOption>
          )}
        </TranslationTipOptions>
      </TranslationsTipWidget>
    </TranslationsTipWrapper>
  );
};

export default TranslationsTip;
