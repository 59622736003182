import { FunctionComponent, useState } from 'react';
import classnames from 'classnames';
import { ValidationErrorInterface } from '@common/interfaces/validation/ValidationInterface';
import { LocalizationInterface } from '@common/interfaces/localization/LocalizationInterface';
import { type ExerciseTypesType } from '@common/enums/ExerciseTypes';
import arrowIcon from '@static/svg/translationsPanel/Dropup.svg';
import { AnyExerciseContentBranch, CourseContentBranches } from '@common/types/AnyExerciseContentBranch';
import { GenericContentInterface } from '@common/interfaces/contentTypes/GenericContentInterface';
import { AnyExerciseContentInterface } from '@common/types/exercises/AnyExerciseContentInterface';
import { AudiosBlock } from '@components/TranslationsPanel/AudiosBlock';
import { DBId } from '@common/types/DBId';
import { CourseInterface } from '@common/interfaces/courses/CourseInterface';
import { useIsAdmin } from '@features/auth/roles';

interface InactiveAudiosProps {
  content: GenericContentInterface | CourseInterface | AnyExerciseContentInterface | {};
  exerciseId: DBId | undefined;
  exerciseType: ExerciseTypesType;
  courseLearningLanguage: string;
  contentId: DBId | undefined;
  visitedBranch: AnyExerciseContentBranch | CourseContentBranches;
  row?: number | undefined;
  column?: number | undefined;
  validationErrors?: ValidationErrorInterface[];
  translationsBlockValuesArray: LocalizationInterface[];
  bundleName?: string;
  isBundleChangeBlocked?: boolean;
  type?: string;
}

export const InactiveAudios: FunctionComponent<InactiveAudiosProps> = ({
  content,
  exerciseId,
  exerciseType,
  courseLearningLanguage,
  contentId,
  visitedBranch,
  row,
  column,
  validationErrors,
  translationsBlockValuesArray,
  bundleName,
  isBundleChangeBlocked,
  type,
}) => {
  let [inactiveShown, setInactiveShown] = useState(false);

  const isAdmin = useIsAdmin();

  const handleToggle = () => {
    setInactiveShown(!inactiveShown);
  };

  return (
    <>
      <div
        className={classnames('inactive-languages-toggler', {
          'inactive-languages-toggler--hide': inactiveShown,
        })}
        onClick={handleToggle}
      >
        {inactiveShown ? 'Hide' : 'Show'} other languages
        <img src={arrowIcon} className="arrow-icon" alt="" />
      </div>
      <AudiosBlock
        content={content}
        exerciseId={exerciseId}
        exerciseType={exerciseType as ExerciseTypesType}
        disabled={Boolean(contentId?.includes('default_') && !isAdmin)}
        courseLearningLanguage={courseLearningLanguage}
        contentId={contentId}
        visitedBranch={visitedBranch}
        row={row}
        column={column}
        validationErrors={validationErrors}
        translationsBlockValuesArray={translationsBlockValuesArray}
        show={inactiveShown}
        bundleName={bundleName}
        isBundleChangeBlocked={isBundleChangeBlocked}
        type={type}
      />
    </>
  );
};
