import classnames from 'classnames';
import ReactTooltip from 'react-tooltip';

import { Loader } from '@features/theme';
import { CourseEditionActionsCreator } from '@actionCreators/CourseEditionActionsCreator';
import { ValidationInterface } from '@common/interfaces/validation/ValidationInterface';
import { ContentTypes } from '@common/enums/ContentTypes';

import eyeIcon from './img/eye.svg';
import eyeCrossedIcon from './img/eyeCrossed.svg';

import './IssuesToggler.scss';
import { selectIsIssuesShown } from '@selectors/UiSelectors';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { selectLoadingValidationResults } from '@selectors/CoursesSelectors';

const isArrayAndNotEmpty = (object: any) => {
  return Array.isArray(object) && object.length > 0;
};

type PossibleTypes =
  | typeof ContentTypes.course
  | typeof ContentTypes.level
  | typeof ContentTypes.chapter
  | typeof ContentTypes.lesson
  | typeof ContentTypes.activity
  | typeof ContentTypes.exercise
  | typeof ContentTypes.grammarReview
  | typeof ContentTypes.grammarCategory
  | typeof ContentTypes.grammarTopic
  | typeof ContentTypes.placementTest
  | typeof ContentTypes.entrypoint
  | typeof ContentTypes.roleplay
  | typeof ContentTypes.roleplayCategory
  | typeof ContentTypes.roleplayScenario;

type IssuesTogglerProps = {
  type: PossibleTypes;
  validation: ValidationInterface;
  hasChildrenArrayInvalidItems: boolean;
};

const getChildrenNames = (parentType: PossibleTypes) => {
  switch (parentType) {
    case ContentTypes.course:
      return 'levels';
    case ContentTypes.level:
      return 'chapters';
    case ContentTypes.chapter:
      return 'lessons';
    case ContentTypes.lesson:
      return 'activities';
    case ContentTypes.activity:
    case ContentTypes.grammarTopic:
      return 'exercises';
    case ContentTypes.grammarReview:
      return 'categories';
    case ContentTypes.grammarCategory:
      return 'topics';
    case ContentTypes.placementTest:
      return 'entrypoints';
    case ContentTypes.entrypoint:
      return 'exercises';
    case ContentTypes.roleplay:
      return 'categories';
    case ContentTypes.roleplayCategory:
      return 'scenarios';
  }
};

export const IssuesToggler = ({ type, validation, hasChildrenArrayInvalidItems }: IssuesTogglerProps) => {
  const dispatch = useAppDispatch();
  const isIssuesShown = useAppSelector(selectIsIssuesShown);
  const validationResultsLoading = useAppSelector(selectLoadingValidationResults);

  const childrenName = getChildrenNames(type);
  const isNotValidated = !validation?.validated;
  const isValid = validation?.validated && validation?.valid;
  const isInvalid = validation?.validated && !validation?.valid;

  const hasWarning = validation?.errors.some((e) => e.isWarning);

  const hasInvalidChildren =
    validation?.validated &&
    validation?.valid &&
    (isArrayAndNotEmpty(validation?.invalidChildEntities) || hasChildrenArrayInvalidItems);

  let text = 'No issues found';
  if (isNotValidated) {
    text = 'Not validated';
  } else if (isInvalid) {
    text = 'Issues found';
  } else if (hasWarning) {
    text = 'Warnings found';
  } else if (hasInvalidChildren) {
    text = `Issues found in ${childrenName}`;
  }

  return (
    <div
      id="header-issues-toggle-button"
      className={classnames(
        'issues-toggler',
        { 'issues-toggler--success': isValid },
        { 'issues-toggler--error': isInvalid },
        { 'issues-toggler--warning': hasWarning },
        { 'issues-toggler--has-invalid-children': hasInvalidChildren },
        { 'issues-toggler--not-validated': isNotValidated },
      )}
      data-tip={'You need to save content to validate it.'}
      data-for="not-validated-tooltip"
      onClick={() => dispatch(CourseEditionActionsCreator.toggleIssuesVisibility())}
    >
      {isNotValidated && <ReactTooltip className="tooltip" id="not-validated-tooltip" effect="solid" />}
      {validationResultsLoading ? (
        <Loader />
      ) : (
        <>
          <img className="issues-toggler__icon" src={isIssuesShown ? eyeIcon : eyeCrossedIcon} alt="" />
          <span className="issues-toggler__text">{text}</span>
        </>
      )}
    </div>
  );
};
