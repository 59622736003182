import { PayloadAction } from '@reduxjs/toolkit';
import { put, call } from 'redux-saga/effects';

import UsersService from '@services/UsersService';
import { usersSlice } from '@redux/slices/usersSlice';
import { addToast } from '@features/app/toast';

const UsersSagas = {
  *getUsers() {
    try {
      const result: Awaited<ReturnType<typeof UsersService.getUsers>> = yield call(UsersService.getUsers);

      if (result.status === 200) {
        yield put(usersSlice.actions.setUsers(result.data));
      }
    } catch (e: any) {
      console.error(e);
    }
  },
  *editUserRole({ payload: { userId, data, successCallback, commonCallback } }: PayloadAction<any>) {
    try {
      const result: Awaited<ReturnType<typeof UsersService.editUserRole>> = yield call(
        UsersService.editUserRole,
        userId,
        data,
      );

      if (result.status === 200) {
        yield put(usersSlice.actions.updateUser({ id: userId, changes: data }));

        successCallback();

        addToast({
          type: 'success',
          title: 'The role and course language were assigned successfully',
        });
      }

      commonCallback();
    } catch (e: any) {
      console.error(e);
    }
  },
  *archiveUser({ payload: { userId, beneficiaryId, commonCallback } }: PayloadAction<any>) {
    try {
      const result: Awaited<ReturnType<typeof UsersService.archiveUser>> = yield call(
        UsersService.archiveUser,
        userId,
        { beneficiary: beneficiaryId },
      );

      if (result.status === 200) {
        yield put(usersSlice.actions.removeUser(userId));

        addToast({
          type: 'success',
          title: 'User was archived successfully',
        });
      }

      commonCallback();
    } catch (e: any) {
      console.error(e);
    }
  },
};

export default UsersSagas;
