import { useState } from 'react';

import { ChapterResultCard } from './SearchResultItemCard';
import { ChapterResultDetails } from './SearchResultItemDetails';
import { getChapterResultDetails } from './services';
import { ChapterSearchResultType, ChapterSearchResultDetailsType, ChapterDetailsTab } from './types';

export const ChapterSearchResultItem = ({ content }: { content: ChapterSearchResultType }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [details, setDetails] = useState<ChapterSearchResultDetailsType | null>(null);
  const [isDetailsLoaded, setIsDetailsLoaded] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [defaultTab, setDefaultTab] = useState<ChapterDetailsTab['key'] | null>(null);

  const onExpand = (tabName: ChapterDetailsTab['key'] | null) => {
    setDefaultTab(tabName);
    setIsExpanded(!isExpanded);
    if (!isExpanded && !details) {
      getChapterResultDetails(content.id)
        .then(({ data }) => {
          setIsDetailsLoaded(true);
          setDetails(data);
        })
        .catch((error) => {
          setIsDetailsLoaded(true);
          setDetails(null);
          if (error.response.data.detail) {
            setErrorMessage(error.response.data.detail);
          } else {
            setErrorMessage(error.response.data.message || 'Unknown error');
          }
        });
    }
  };

  return (
    <div data-testid="search-result-item-chapter">
      <ChapterResultCard content={content} isExpanded={isExpanded} onExpand={onExpand} />
      <ChapterResultDetails
        defaultTab={defaultTab}
        isExpanded={isExpanded}
        isLoaded={isDetailsLoaded}
        errorMessage={errorMessage}
        details={details}
      />
    </div>
  );
};
