import { FunctionComponent } from 'react';
import IDDisplayer from '@components/IDDisplayer/IDDisplayer';
import { ActivityInterface } from '@common/interfaces/contentTypes/ActivityInterface';
import { GenericContentInterface } from '@common/interfaces/contentTypes/GenericContentInterface';
import { GrammarCategoryListItemType, GrammarTopicListItemType } from '@features/content/grammar';
import { ExerciseListItemType } from '@components/ContentTypes/ExerciseCard/types';
import arrowIcon from './img/arrow.svg';
import { RoleplayListItemType } from '@features/content/roleplay';

interface CardPendingStatusProps {
  content:
    | GenericContentInterface
    | ActivityInterface
    | GrammarCategoryListItemType
    | GrammarTopicListItemType
    | ExerciseListItemType
    | RoleplayListItemType;
}

export const CardPendingStatus: FunctionComponent<CardPendingStatusProps> = ({ content }: CardPendingStatusProps) => {
  const { id, ready, changeStatus } = content;

  return (
    <div className="content-common-header__content__id-container">
      <IDDisplayer id={id} mode="normal" />
      {changeStatus?.hasPendingChanges && (
        <div className="content-common-header__content__id-container__status">
          <img src={arrowIcon} alt="arrow" />
          <span className="content-common-header__content__id-container__status__text">
            Being {ready ? 'published' : 'unpublished'}
          </span>
        </div>
      )}
    </div>
  );
};
