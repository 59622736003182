import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Table from 'react-bootstrap/Table';

import { Loader } from '@features/theme';
import { IdentityGroupType } from '../../types';
import { Pagination, FilterInput } from '@features/theme';
import { getIdentityGroups } from '../../ProgressMatchingService';
import { IdentityGroup } from './IdentityGroup';

const ProgressMatchingTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
`;

const Filter = styled(FilterInput)`
  width: 32rem;
  margin-bottom: 2.4rem;
`;

const TableHeaderRow = styled.tr`
  border: none !important;
  padding: 0 0 0.8rem 0 !important;
  box-shadow: 0 0.1rem 0 ${({ theme }) => theme.color.brandGreyscale600};
  display: grid;
  grid-template-columns: 50% 30% 20%;
`;

const TableWrapper = styled(Table)`
  width: 100%;
`;

const TableHeader = styled.th`
  border: none !important;
  font-weight: 700 !important;
  font-size: 1.4rem !important;
`;

const NumberOfLessons = styled(TableHeader)`
  width: 100%;
  text-align: center;
  font-weight: 700;
`;

const NoItems = styled(NumberOfLessons)`
  width: 100%;
  font-size: 2rem;
`;

const GroupsPagination = styled(Pagination)`
  margin: 3.6rem 0;
`;

const DEFAULT_PAGE_SIZE = 10;

export const IdentityGroupsTable = () => {
  const [identityGroups, setIdentityGroups] = useState<IdentityGroupType[]>([]);
  const [filteredIdentityGroups, setFilteredIdentityGroups] = useState<IdentityGroupType[]>([]);
  const [visibleIdentityGroups, setVisibleIdentityGroups] = useState<IdentityGroupType[]>([]);
  const [identityGroupsLoading, setIdentityGroupsLoading] = useState<boolean>(false);
  const [identityGroupsLoaded, setIdentityGroupsLoaded] = useState<boolean>(false);
  const [isListUpdated, setIsListUpdated] = useState<boolean>(false);
  // Filtering
  const [filterValue, setFilterValue] = useState<string>('');
  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  // Get identity groups
  useEffect(() => {
    if ((!identityGroupsLoading && !identityGroupsLoaded) || isListUpdated) {
      setIdentityGroupsLoading(true);

      getIdentityGroups().then((result) => {
        setIdentityGroups(result.data.identityGroups);
        setFilteredIdentityGroups(result.data.identityGroups);
        setTotalPages(Math.ceil(result.data.identityGroups.length / DEFAULT_PAGE_SIZE));
        setIdentityGroupsLoading(false);
        setIdentityGroupsLoaded(true);
        setIsListUpdated(false);
      });
    }
  }, [identityGroupsLoading, identityGroupsLoaded, setIdentityGroupsLoaded, isListUpdated]);

  // Handle filtering
  useEffect(() => {
    if (filterValue) {
      setFilteredIdentityGroups(
        identityGroups.filter((identityGroup) => identityGroup.title.toLowerCase().includes(filterValue.toLowerCase())),
      );
    } else {
      setFilteredIdentityGroups(identityGroups);
    }
    setCurrentPage(1);
  }, [filterValue, identityGroups]);

  // Handle pagination
  useEffect(() => {
    if (filteredIdentityGroups.length) {
      const index = (currentPage - 1) * DEFAULT_PAGE_SIZE;
      setVisibleIdentityGroups(filteredIdentityGroups.slice(index, index + DEFAULT_PAGE_SIZE));
      setTotalPages(Math.ceil(filteredIdentityGroups.length / DEFAULT_PAGE_SIZE));
    } else {
      setVisibleIdentityGroups([]);
      setTotalPages(0);
    }
  }, [currentPage, filteredIdentityGroups]);

  return (
    <ProgressMatchingTableWrapper>
      <Filter placeholder="Filter by identity group" value={filterValue} onChange={setFilterValue} />
      {identityGroupsLoading && !identityGroupsLoaded ? (
        <Loader size="L" />
      ) : (
        <TableWrapper>
          {filteredIdentityGroups.length ? (
            <>
              <thead>
                <TableHeaderRow>
                  <TableHeader>Identity groups</TableHeader>
                  <NumberOfLessons>Number of mapped lessons</NumberOfLessons>
                  <TableHeader />
                </TableHeaderRow>
              </thead>
              <tbody>
                {visibleIdentityGroups.map((identityGroup) => (
                  <IdentityGroup
                    identityGroup={identityGroup}
                    key={identityGroup.id}
                    onChange={() => setIsListUpdated(true)}
                  />
                ))}
              </tbody>
              <GroupsPagination currentPage={currentPage} totalPages={totalPages} onPageChange={setCurrentPage} />
            </>
          ) : (
            <NoItems>No identity groups</NoItems>
          )}
        </TableWrapper>
      )}
    </ProgressMatchingTableWrapper>
  );
};
