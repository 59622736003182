import { apiClient } from '@features/api';

import { AvailableSearchType } from '../../../../../types';

export type FavouriteSearch<T> = {
  id: string;
  type: AvailableSearchType;
  name: string;
  filters: T;
};

const ONE_DAY_MS = 1000 * 60 * 60 * 24;
const FAVOURITE_SEARCHES_STORAGE_KEY = 'favourite-searches';
const FAVOURITE_SEARCHES_LAST_FETCHED_STORAGE_KEY = `${FAVOURITE_SEARCHES_STORAGE_KEY}-last-fetched`;

let favouriteSearchesCache: FavouriteSearch<any>[] = [];
let favouriteSearchesLastUpdatedCache: string;

const getFavouriteSearchesFromCache = (cache: typeof favouriteSearchesCache): FavouriteSearch<any>[] => {
  if (cache.length) return cache;

  if (sessionStorage.getItem(FAVOURITE_SEARCHES_STORAGE_KEY) !== null) {
    const storedCache = JSON.parse(sessionStorage.getItem(FAVOURITE_SEARCHES_STORAGE_KEY) as string);

    if (storedCache.length) return storedCache;
  }

  return [];
};

const shouldRefreshFavouriteSearches = () => {
  const favouriteSearchesLastUpdatedString =
    favouriteSearchesLastUpdatedCache || sessionStorage.getItem(FAVOURITE_SEARCHES_LAST_FETCHED_STORAGE_KEY) !== null
      ? JSON.parse(sessionStorage.getItem(FAVOURITE_SEARCHES_LAST_FETCHED_STORAGE_KEY) as string)
      : undefined;

  if (!favouriteSearchesLastUpdatedString) return true;

  const favouriteSearchesLastUpdatedTime = new Date(favouriteSearchesLastUpdatedString).getTime();
  const currentTime = new Date().getTime();

  return currentTime - favouriteSearchesLastUpdatedTime > ONE_DAY_MS;
};

export const saveFavouriteSearch = async <T extends object>(name: string, type: AvailableSearchType, filters: T) => {
  const {
    data: { id },
  } = await apiClient.noErrorsV2.post('/search/favourite', {
    name,
    type,
    filters,
  });

  const currentFavouriteSearches = getFavouriteSearchesFromCache(favouriteSearchesCache);

  favouriteSearchesCache = [...currentFavouriteSearches, { id, name, type, filters }];
  sessionStorage.setItem(FAVOURITE_SEARCHES_STORAGE_KEY, JSON.stringify(favouriteSearchesCache));
};

export const updateFavouriteSearch = async (id: string, name: string) => {
  await apiClient.noErrorsV2.patch(`/search/favourite/${id}`, {
    name,
  });

  const currentFavouriteSearches = getFavouriteSearchesFromCache(favouriteSearchesCache);

  favouriteSearchesCache = currentFavouriteSearches.map((search) => {
    if (search.id === id) search.name = name;

    return search;
  });
  sessionStorage.setItem(FAVOURITE_SEARCHES_STORAGE_KEY, JSON.stringify(favouriteSearchesCache));

  return Promise.resolve(favouriteSearchesCache);
};

export const removeFavouriteSearch = async (id: string) => {
  await apiClient.noErrorsV2.delete(`/search/favourite/${id}`);

  const currentFavouriteSearches = getFavouriteSearchesFromCache(favouriteSearchesCache);

  favouriteSearchesCache = currentFavouriteSearches.filter((search) => search.id !== id);
  sessionStorage.setItem(FAVOURITE_SEARCHES_STORAGE_KEY, JSON.stringify(favouriteSearchesCache));

  return Promise.resolve(favouriteSearchesCache);
};

export const getFavouriteSearches = async <T extends object>(): Promise<FavouriteSearch<T>[]> => {
  if (shouldRefreshFavouriteSearches()) {
    const response = await apiClient.noErrorsV2.get(`/search/favourite`);

    if (response?.data?.searches) {
      favouriteSearchesCache = response.data.searches;
      favouriteSearchesLastUpdatedCache = new Date().toISOString();
      sessionStorage.setItem(FAVOURITE_SEARCHES_STORAGE_KEY, JSON.stringify(response.data.searches));
      sessionStorage.setItem(
        FAVOURITE_SEARCHES_LAST_FETCHED_STORAGE_KEY,
        JSON.stringify(favouriteSearchesLastUpdatedCache),
      );
    } else {
      sessionStorage.setItem(FAVOURITE_SEARCHES_STORAGE_KEY, JSON.stringify([]));
      sessionStorage.setItem(FAVOURITE_SEARCHES_LAST_FETCHED_STORAGE_KEY, JSON.stringify(''));
    }
  }

  if (!favouriteSearchesCache.length && sessionStorage.getItem(FAVOURITE_SEARCHES_STORAGE_KEY) !== null) {
    return JSON.parse(sessionStorage.getItem(FAVOURITE_SEARCHES_STORAGE_KEY) as string);
  }

  return favouriteSearchesCache;
};
