import styled from 'styled-components/macro';

import { CommonFilterSelector, CommonFilterOptionType } from '@components/SelectorCommonComponents';

const BENEFICIARY_SELECTOR_CLASSNAME_PREFIX = 'beneficiary-selector';

const StyledBeneficiarySelector = styled(CommonFilterSelector)`
  width: 100% !important;

  .${BENEFICIARY_SELECTOR_CLASSNAME_PREFIX}__value-container::before {
    display: none;
  }
  .${BENEFICIARY_SELECTOR_CLASSNAME_PREFIX}__option {
    text-align: left;
  }
`;

const Description = styled.div`
  color: ${({ theme }) => theme.colorV2.textSecondary};
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 150%;
  margin: 3.2rem 0 0.8rem 0;
`;

type BeneficiarySelectorProps = {
  options: CommonFilterOptionType[];
  value: CommonFilterOptionType | null;
  onChange: (value: CommonFilterOptionType | null) => void;
};

export const BeneficiarySelector = ({ options, value, onChange }: BeneficiarySelectorProps) => {
  return (
    <>
      <Description>
        By default content owned by archived user transfer to user's manager. You can also reassign ownership here:
      </Description>
      <StyledBeneficiarySelector
        classNamePrefix={BENEFICIARY_SELECTOR_CLASSNAME_PREFIX}
        options={options}
        placeholder="Choose owner"
        value={value}
        withSearchIcon
        onChange={onChange}
      />
    </>
  );
};
