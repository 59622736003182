import { call, put, select } from 'redux-saga/effects';
import ConversationExerciseInterface from '@components/Exercises/Conversation/interfaces/ConversationExerciseInterface';
import ConversationExerciseService from '@services/ExerciseRelatedServices/ConversationExerciseService';
import { ExerciseCommonActionCreators as ExerciseCommonActions } from '@actionCreators/ExerciseCommonActionCreator';
import { Language } from '@features/content/languages';
import ExerciseSagas from '@sagas/exercises/definitions/ExerciseSagas';
import { ConversationExerciseActions } from '@actions/ConversationExerciseActions';
import { FormikValuesInterface } from '@helpers/formikInitialValuesHelper';
import type { AppDispatch } from '@redux/store';
import { selectLoadedExerciseData } from '@selectors/CoursesSelectors';

const ConversationExerciseSagas = {
  *save(
    dispatch: AppDispatch,
    {
      type,
      payload,
    }: {
      type: string;
      payload: {
        exercise: ConversationExerciseInterface;
        learningLanguage: Language;
        values: FormikValuesInterface;
      };
    },
  ) {
    try {
      let { values } = payload;

      yield put({
        type: ConversationExerciseActions.SET_CONVERSATION_ALL_FIELDS,
        payload: {
          values,
        },
      });
      let exercise: ConversationExerciseInterface = yield select(selectLoadedExerciseData);
      yield call(ConversationExerciseService.save, dispatch, exercise, values);

      yield call(ExerciseSagas.requestOneExerciseCommon);
    } catch (e: any) {
      dispatch(ExerciseCommonActions.setSaveProgress({ value: false, updateData: false }));
    }
  },
};

export default ConversationExerciseSagas;
