import { FunctionComponent } from 'react';
import classnames from 'classnames';

import { IncorrectFeedbackProps } from '../../../PreviewModalProps';

import './IncorrectFeedback.scss';

export const IncorrectFeedback: FunctionComponent<IncorrectFeedbackProps> = ({
  deviceType,
  text,
  withUnderline = true,
}: IncorrectFeedbackProps) => {
  return (
    <>
      <div className={classnames('incorrect-feedback', `incorrect-feedback--${deviceType}`)}>
        <span className="incorrect-feedback__description">Correct answer</span>
        <div className="incorrect-feedback__text">
          <span
            className={classnames('incorrect-feedback__text__value', {
              'incorrect-feedback__text__value--with-underline': withUnderline,
            })}
          >
            {text}
          </span>
        </div>
      </div>
    </>
  );
};
