import { type DisplayContentToUserModeType } from '@common/enums/DisplayContentToUserMode';
import { ExerciseTypes } from '@common/enums/ExerciseTypes';
import { AnyExerciseInterface } from '@common/types/exercises/AnyExerciseInterface';
import { DBId } from '@common/types/DBId';
import { Language } from '@features/content/languages';
import { FormikValuesInterface } from '@helpers/formikInitialValuesHelper';
import { MultipleChoiceExerciseActions } from '@actions/MultipleChoiceExerciseActions';

export const MultipleChoiceExerciseActionsCreator = {
  save: (exercise: AnyExerciseInterface, learningLanguage: Language, values: FormikValuesInterface) => ({
    type: MultipleChoiceExerciseActions.SAVE,
    payload: {
      exercise,
      learningLanguage,
      values,
    },
  }),
  setImage: (exercise: AnyExerciseInterface, image: File, progressHandler: Function) => ({
    type: MultipleChoiceExerciseActions.UPLOAD_MAIN_CONTENT_IMAGE,
    payload: {
      exercise,
      image,
      exerciseType: ExerciseTypes.multipleChoice,
      section: MultipleChoiceExerciseActions.UPLOAD_MAIN_CONTENT_IMAGE,
      progressHandler,
    },
  }),
  setDistractor1Image: (exercise: AnyExerciseInterface, image: File, progressHandler: Function) => ({
    type: MultipleChoiceExerciseActions.UPLOAD_DISTRACTOR1_IMAGE,
    payload: {
      exercise,
      image,
      exerciseType: ExerciseTypes.multipleChoice,
      section: MultipleChoiceExerciseActions.UPLOAD_DISTRACTOR1_IMAGE,
      progressHandler,
    },
  }),
  setDistractor2Image: (exercise: AnyExerciseInterface, image: File, progressHandler: Function) => ({
    type: MultipleChoiceExerciseActions.UPLOAD_DISTRACTOR2_IMAGE,
    payload: {
      exercise,
      image,
      exerciseType: ExerciseTypes.multipleChoice,
      section: MultipleChoiceExerciseActions.UPLOAD_DISTRACTOR2_IMAGE,
      progressHandler,
    },
  }),
  setInstructionsLanguage: (instructionsLanguage: DisplayContentToUserModeType) => ({
    type: MultipleChoiceExerciseActions.SET_INSTRUCTIONS_LANGUAGE,
    payload: {
      instructionsLanguage,
    },
  }),
  setChoicesLanguage: (choicesLanguage: DisplayContentToUserModeType) => ({
    type: MultipleChoiceExerciseActions.SET_CHOICES_LANGUAGE,
    payload: {
      choicesLanguage,
    },
  }),
  setImagesEnabled: () => ({
    type: MultipleChoiceExerciseActions.SET_IMAGES_ENABLED,
  }),
  removeImage: () => ({
    type: MultipleChoiceExerciseActions.REMOVE_IMAGE,
  }),
  removeAudio: ({ contentId, language }: { contentId: DBId | undefined; language: string }) => ({
    type: MultipleChoiceExerciseActions.REMOVE_AUDIO,
    payload: { contentId, language },
  }),
  removeDistractor1Image: () => ({
    type: MultipleChoiceExerciseActions.REMOVE_DISTRACTOR1_IMAGE,
  }),
  removeDistractor2Image: () => ({
    type: MultipleChoiceExerciseActions.REMOVE_DISTRACTOR2_IMAGE,
  }),
};
