import { useLayoutEffect, useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRewrite from 'rehype-rewrite';
import remarkGfm from 'remark-gfm';

import { UserInterface } from '@common/interfaces/users/UserInterface';
import { rehypeRewriteOptions, remarkMention } from '@features/theme';

import type { CommentType, UserInfoType } from '../../../types';
import { ReactComponent as EditIcon } from './_img/edit.svg';
import { ReactComponent as RemoveIcon } from './_img/remove.svg';

import { CommentsReactions, ReactionNameType, REACTION_NAME } from './CommentReactions';

import {
  CancelButton,
  Comment,
  CommentData,
  CommentMeta,
  CommentSentAt,
  CommentToolbar,
  CommentToolbarButton,
  ConfirmButton,
  ConfirmDeleteBlock,
  DeleteButtonsContainer,
  Message,
  ShowMore,
  UserAvatar,
  UserName,
} from './styles';

const MAX_DEFAULT_COMMENT_SIZE = 210;

type CommentItemProps = {
  content: CommentType;
  editable: boolean;
  id: string;
  isEditMode: boolean;
  user: UserInterface | null;
  setIsEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  onAddReaction: (commentId: string, reaction: ReactionNameType) => void;
  onActionItemUpdate: (commentId: string, actionItemId: string, checked: boolean) => void;
  onCommentDelete: (commentId: string) => void;
  onRemoveReaction: (commentId: string, reaction: ReactionNameType) => void;
  onEdit: (comment: CommentType) => void;
};

const formatDate = (dateStr: string) =>
  new Date(dateStr).toLocaleString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });

export const CommentItem = ({
  content,
  editable,
  id,
  isEditMode,
  user,
  onActionItemUpdate,
  onAddReaction,
  onCommentDelete,
  onEdit,
  onRemoveReaction,
  setIsEditMode,
}: CommentItemProps) => {
  const isLongMessage = content.message.length > MAX_DEFAULT_COMMENT_SIZE;

  const [collapsed, setCollapsed] = useState(isLongMessage);
  const [hovered, setHovered] = useState(false);
  const [isMarkedToDelete, setIsMarkedToDelete] = useState(false);
  const [reactions, setReactions] = useState(content.reactions || []);

  const messageRef = useRef<HTMLDivElement>(null);

  const authorFullName = `${content.author.firstName} ${content.author.lastName}`;

  const handleClickOnActionItem = (evt: React.MouseEvent<HTMLElement>) => {
    const target = evt.target as HTMLElement;
    const actionItemId = target.dataset.actionItemId;
    const checked = !target.classList.contains('action-item-checked');

    target.classList.toggle('action-item-checked', checked);

    if (actionItemId) onActionItemUpdate(content.id, actionItemId, checked);
  };

  const handleClickOnReaction = (reactionName: ReactionNameType) => {
    const reactionExists = reactions.some(({ emoji }) => emoji === reactionName);
    const hasPreviouslyReacted = reactions.some(
      ({ emoji, senders }) => emoji === reactionName && senders.some(({ id }) => id === user?.id),
    );
    let newReactionsState = [...reactions];

    if (hasPreviouslyReacted) {
      newReactionsState = reactions
        .map((reaction) => {
          if (reaction.emoji === reactionName) {
            return {
              ...reaction,
              senders: reaction.senders.filter(({ id }) => id !== user?.id),
            };
          }

          return reaction;
        })
        .filter((reaction) => reaction.senders.length > 0);

      onRemoveReaction(content.id, reactionName);
    } else {
      const senderToAdd: UserInfoType = {
        id: user?.id as string,
        fullName: `${user?.firstName} ${user?.lastName}`,
      };

      if (reactionExists) {
        newReactionsState = reactions.map((reaction) => {
          if (reaction.emoji === reactionName) {
            return {
              ...reaction,
              senders: [...reaction.senders, senderToAdd],
            };
          }

          return reaction;
        });
      } else {
        newReactionsState = [
          ...reactions,
          {
            emoji: reactionName,
            senders: [senderToAdd],
          },
        ];
      }

      onAddReaction(content.id, reactionName);
    }

    setReactions(newReactionsState);
  };

  useLayoutEffect(() => {
    if (messageRef.current) {
      const { actionItems } = content;
      const htmlActionItems = messageRef.current?.querySelectorAll('.action-item');

      htmlActionItems.forEach((htmlActionItem) => {
        const actionItemId = (htmlActionItem as HTMLElement).dataset.actionItemId;
        const actionItemData = actionItems?.find((item) => item.id === actionItemId);

        if (actionItemData) {
          htmlActionItem.classList.toggle('action-item-checked', actionItemData.state);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditMode, content, messageRef, onActionItemUpdate]);

  if (isMarkedToDelete) {
    return (
      <ConfirmDeleteBlock data-testid="deletion-block">
        <span>Are you sure you want to delete this comment?</span>
        <DeleteButtonsContainer>
          <CancelButton onClick={() => setIsMarkedToDelete(false)} data-testid="cancel-button">
            Cancel
          </CancelButton>
          <ConfirmButton onClick={() => onCommentDelete(content.id)} data-testid="confirm-button">
            Confirm
          </ConfirmButton>
        </DeleteButtonsContainer>
      </ConfirmDeleteBlock>
    );
  }
  const handleClickEdit = (content: CommentType) => {
    setIsEditMode(true);
    onEdit(content);
  };

  return (
    <Comment
      editable={editable && !isEditMode}
      data-testid={id}
      onMouseOver={() => !isEditMode && setHovered(true)}
      onMouseOut={() => !isEditMode && setHovered(false)}
    >
      <>
        {hovered && !isEditMode && (
          <CommentToolbar>
            <CommentToolbarButton
              title={REACTION_NAME.THUMBS_UP}
              variant="text"
              onClick={() => handleClickOnReaction(REACTION_NAME.THUMBS_UP)}
            >
              👍
            </CommentToolbarButton>
            <CommentToolbarButton
              title={REACTION_NAME.EYES}
              variant="text"
              onClick={() => handleClickOnReaction(REACTION_NAME.EYES)}
            >
              👀
            </CommentToolbarButton>
            {editable && (
              <>
                <CommentToolbarButton title="Edit comment" variant="text" onClick={() => handleClickEdit(content)}>
                  <EditIcon />
                </CommentToolbarButton>
                <CommentToolbarButton title="Delete comment" variant="text" onClick={() => setIsMarkedToDelete(true)}>
                  <RemoveIcon />
                </CommentToolbarButton>
              </>
            )}
          </CommentToolbar>
        )}
        <UserAvatar name={authorFullName} size="38" />
        <CommentData>
          <CommentMeta>
            <UserName>{authorFullName}</UserName>
            <CommentSentAt>{formatDate(content.sentAt)}</CommentSentAt>
          </CommentMeta>
          {!isEditMode && (
            <>
              <Message collapsed={collapsed} ref={messageRef} onClick={handleClickOnActionItem}>
                <ReactMarkdown
                  rehypePlugins={[[rehypeRewrite, rehypeRewriteOptions]]}
                  remarkPlugins={[remarkGfm, remarkMention]}
                >
                  {content.message}
                </ReactMarkdown>
              </Message>
              {isLongMessage && (
                <ShowMore onClick={() => setCollapsed(!collapsed)}>See {collapsed ? 'more' : 'less'}</ShowMore>
              )}
            </>
          )}

          {!isEditMode && !!reactions.length && (
            <CommentsReactions
              data-testid={`comment-reactions-${content.id}`}
              reactions={reactions}
              onReactionClick={handleClickOnReaction}
            />
          )}

          <div id={id}></div>
        </CommentData>
      </>
    </Comment>
  );
};
