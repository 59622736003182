import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { Formik } from 'formik';

import { ContentTypes } from '@common/enums/ContentTypes';
import { LoadingStage } from '@common/enums/LoadingStage';
import { SearchModal } from '@features/search';
import { ContentOwner } from '@features/content/contentOwnership';
import { Language } from '@features/content/languages';
import { NAVIGATION_PANEL_WIDTH } from '@features/content/navigation';
import { Loader } from '@features/theme';

import { BreadcrumbsAndOwnershipWrapper } from '../styles';
import { Header } from '../Header';
import { PlacementTestActionCreators } from '@actionCreators/PlacementTestActionCreators';
import { EntrypointData } from './EntrypointData';
import { Breadcrumbs } from './Breadcrumbs';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { selectCourse } from '@selectors/CoursesSelectors';
import { selectPlacementTestEntrypoint } from '@selectors/PlacementTestSelectors';

const ContentWrapper = styled.div`
  left: ${NAVIGATION_PANEL_WIDTH};
  margin-top: 10.5rem;
  padding: 0 6rem 5rem 6rem;
  position: relative;
  width: calc(100% - ${NAVIGATION_PANEL_WIDTH});
`;

export const EntrypointContent = () => {
  const dispatch = useAppDispatch();
  const { courseId, entrypointId } = useParams<{ courseId: string; entrypointId: string }>();

  const currentCourse = useAppSelector(selectCourse);
  const entrypoint = useAppSelector(selectPlacementTestEntrypoint);

  useEffect(() => {
    dispatch(PlacementTestActionCreators.setInitialEntrypoint());
  }, [entrypointId, dispatch]);

  useEffect(() => {
    if (entrypoint.loaded === LoadingStage.notLoaded && courseId && entrypointId) {
      dispatch(PlacementTestActionCreators.getEntrypoint(courseId, entrypointId));
    }
  }, [dispatch, courseId, entrypoint, entrypointId]);

  useEffect(() => {
    if (
      entrypoint.loaded === LoadingStage.loaded &&
      entrypoint.exercisesData.loaded === LoadingStage.notLoaded &&
      courseId &&
      entrypointId
    ) {
      dispatch(PlacementTestActionCreators.getEntrypointExercises(courseId, entrypointId));
    }
  }, [dispatch, courseId, entrypoint, entrypointId]);

  return (
    <Formik initialValues={{}} onSubmit={() => {}}>
      <ContentWrapper>
        <SearchModal />
        {currentCourse && (
          <Header
            contentName="Placement test"
            learningLanguage={currentCourse.learningLanguage as Language}
            interfaceLanguages={currentCourse.interfaceLanguages as Language[]}
          />
        )}
        {entrypoint.loaded === LoadingStage.loaded ? (
          <>
            <BreadcrumbsAndOwnershipWrapper>
              <Breadcrumbs />
              <ContentOwner
                childComponentId={entrypointId}
                componentId={courseId}
                contentType={ContentTypes.entrypoint}
                dates={entrypoint.content.dates}
                owner={entrypoint.content.owner}
              />
            </BreadcrumbsAndOwnershipWrapper>
            <EntrypointData />
          </>
        ) : (
          <Loader size="L" />
        )}
      </ContentWrapper>
    </Formik>
  );
};
