import { UISliceInterface } from '@common/interfaces/slices/UISliceInterface';
import { UploadTranslationActions } from '@actions/UploadTranslationActions';
import { clone } from '@helpers/clone';
import { PayloadAction } from '@reduxjs/toolkit';
import { UploadCommonActions_UPLOAD_FAIL } from '@sagas/mediaUpload/definitions/MediaUploadSagasDefinition';

const UploadTranslationsUIReducers = {
  [UploadTranslationActions.UPLOAD_SUCCESS]: (state: UISliceInterface): UISliceInterface => {
    const clonedState: UISliceInterface = clone(state);

    clonedState.panels.uploadTranslations.phase = 'done';
    clonedState.panels.uploadTranslations.payloadText = undefined;

    return clonedState;
  },
  [UploadTranslationActions.UPLOAD_FAIL]: (
    state: UISliceInterface,
    { type, payload: { payloadText, errors } }: PayloadAction<UploadCommonActions_UPLOAD_FAIL>,
  ): UISliceInterface => {
    const clonedState: UISliceInterface = clone(state);

    clonedState.panels.uploadTranslations.phase = 'start';
    clonedState.panels.uploadTranslations.payloadText = 'Uploading failed';
    clonedState.panels.uploadTranslations.errors = errors;

    return clonedState;
  },
  [UploadTranslationActions.UPLOAD]: (state: UISliceInterface): UISliceInterface => {
    const clonedState: UISliceInterface = clone(state);

    clonedState.panels.uploadTranslations.phase = 'progress';
    clonedState.panels.uploadTranslations.payloadText = undefined;
    clonedState.panels.uploadTranslations.errors = [];

    return clonedState;
  },
  [UploadTranslationActions.CLOSE]: (state: UISliceInterface): UISliceInterface => {
    const clonedState: UISliceInterface = clone(state);

    clonedState.panels.uploadTranslations.phase = 'start';
    clonedState.panels.uploadTranslations.payloadText = undefined;
    clonedState.panels.uploadTranslations.errors = [];

    return clonedState;
  },
};

export default UploadTranslationsUIReducers;
