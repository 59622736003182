import { apiClient } from '@features/api';

import { ExerciseSearchResultType } from '../SearchResultItem';

import type { ExerciseQueryParamsType } from './types';

const getQueryParams = ({
  languages,
  courses,
  exerciseTypes,
  hasImage,
  isInExperiment,
  isPublished,
  isReused,
  isUsedInGrammarReview,
  isUsedInPlacementTest,
}: ExerciseQueryParamsType) => {
  return {
    languages: languages || undefined,
    courses: courses || undefined,
    types: exerciseTypes || undefined,
    isInExperiment: isInExperiment !== null ? isInExperiment : undefined,
    hasImage: hasImage !== null ? hasImage : undefined,
    isPublished: isPublished !== null ? isPublished : undefined,
    isReused: isReused !== null ? isReused : undefined,
    isUsedInGrammarReview: isUsedInGrammarReview !== null ? isUsedInGrammarReview : undefined,
    isUsedInPlacementTest: isUsedInPlacementTest !== null ? isUsedInPlacementTest : undefined,
  };
};

export const searchByExercise = async (
  query: string,
  page: number,
  filtersParams: ExerciseQueryParamsType,
): Promise<{ data: { count: number; results: ExerciseSearchResultType[] } }> => {
  return await apiClient.v2.get('/search/exercises', {
    params: {
      query,
      page,
      ...getQueryParams(filtersParams),
    },
  });
};
