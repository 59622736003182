import { PublishingActions } from '@actions/PublishingActions';
import { LanguageV2 } from '@features/content/languages';

export const PublishingActionsCreator = {
  togglePublishingQueuePanel: () => ({
    type: PublishingActions.TOGGLE_QUEUE_SIDEBAR_VISIBILITY,
  }),
  publishOrUnpublishContent: (forAllInstances: boolean, readyState: boolean) => ({
    type: PublishingActions.PUBLISH_OR_UNPUBLISH_CONTENT,
    payload: { forAllInstances, readyState },
  }),
  setContentToPublish: (
    contentId: string,
    contentType: string,
    readyState: boolean,
    forUpdate: boolean,
    parentId?: string,
    parentType?: string,
    language?: LanguageV2,
  ) => ({
    type: PublishingActions.SET_CONTENT_TO_PUBLISH,
    payload: { contentId, contentType, readyState, forUpdate, parentId, parentType, language },
  }),
  setInitialContentToPublish: () => ({
    type: PublishingActions.SET_INITIAL_CONTENT_TO_PUBLISH,
  }),
  closeChildrenErrorsModal: () => ({
    type: PublishingActions.SET_INVALID_CHILDREN_MODAL_OPENED,
    payload: { opened: false, data: [], errorInExercises: false },
  }),
  changeReadyStateOnMultiPublish: (exerciseIds: string[]) => ({
    type: PublishingActions.ON_MULTI_PUBLISH_SUCCESS,
    payload: { exerciseIds },
  }),
  changeReadyStateOnMultiUnpublish: (exerciseIds: string[]) => ({
    type: PublishingActions.ON_MULTI_UNPUBLISH_SUCCESS,
    payload: { exerciseIds },
  }),
};
