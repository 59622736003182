import { LoadingStage } from '@common/enums/LoadingStage';
import { CourseInterface } from '@common/interfaces/courses/CourseInterface';
import { SelectedGroupsOfParentInterface } from '@common/interfaces/groups/SelectedGroupsOfParentInterface';
import { DBId } from '@common/types/DBId';
import { AnyLoadedExercise } from '@common/types/exercises/AnyLoadedExercise';
import { CourseEditionActionsCreator } from '@actionCreators/CourseEditionActionsCreator';
import { AppDispatch } from '@redux/store';

const CourseEditionService = {
  loadCourseOrGroupOrExercise(
    dispatch: AppDispatch,
    selectedCourse: CourseInterface,
    loadedExercise: AnyLoadedExercise,
    selectedGroupsOfParent: SelectedGroupsOfParentInterface,
    courseId: DBId,
    contentId: DBId,
    isExercise: boolean,
    parentId?: DBId,
    forceLoad?: boolean,
  ) {
    if (selectedCourse.loaded === LoadingStage.notLoaded) {
      if (contentId === undefined) {
        dispatch(CourseEditionActionsCreator.getCourseWithGroups(courseId));
      } else {
        dispatch(CourseEditionActionsCreator.getCourse(courseId));
      }
    } else if (selectedCourse.loaded === LoadingStage.loaded) {
      if (isExercise) {
        if ((loadedExercise?.loaded === LoadingStage.notLoaded && loadedExercise.exercise === null) || forceLoad) {
          dispatch(CourseEditionActionsCreator.getExercise(courseId, contentId, parentId));
        }
      } else {
        if (selectedGroupsOfParent.loaded === LoadingStage.notLoaded || forceLoad) {
          if (contentId !== undefined && parentId !== undefined) {
            dispatch(CourseEditionActionsCreator.getGroup(contentId, parentId));
          }
        }
      }
    }
  },
};

export default CourseEditionService;
