import { ContentTypes, type ContentTypesForCreateContentType, type ContentTypesType } from '@common/enums/ContentTypes';
import type { ExerciseType } from '@common/enums/ExerciseTypes';
import { ActivityType } from '@features/content/activities';
import { ContentCreatorWidget, type ContentCreatorWidgetItemType } from '@features/theme';
import grammarTopicIcon from '@static/svg/contentCreatorWidget/grammarTopic.svg';

const GRAMMAR_TOPIC_CREATOR_ITEM: ContentCreatorWidgetItemType<typeof ContentTypes.grammarTopic> = {
  label: 'Grammar Topic',
  iconSrc: grammarTopicIcon,
  showByDefault: true,
  type: ContentTypes.grammarTopic,
};

type GrammarTopicCreatorProps = {
  isCreateInProgress?: boolean;
  isEditAvailable: boolean;
  isPreEnabled: boolean;
  position: number;
  onCreateContent: (
    itemType: ContentTypesType,
    itemCategory: ActivityType | ExerciseType | ContentTypesForCreateContentType | null,
  ) => void;
};

export const GrammarTopicCreator = ({
  isCreateInProgress = false,
  isEditAvailable,
  isPreEnabled,
  position,
  onCreateContent,
}: GrammarTopicCreatorProps) => {
  return (
    <ContentCreatorWidget
      isCreateInProgress={isCreateInProgress}
      isEditAvailable={isEditAvailable}
      isPreEnabled={isPreEnabled}
      items={[GRAMMAR_TOPIC_CREATOR_ITEM]}
      position={position}
      title="Create a first grammar topic to get started"
      onItemSelected={(item) => onCreateContent(item.type, null)}
    />
  );
};
