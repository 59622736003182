import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { RoleplayActionCreators } from '@actionCreators/RoleplayActionsCreators';
import { CardPendingStatus } from '@components/ContentTypes/BaseContent/CardPendingStatus';
import { RemoveCardButton } from '@components/ContentTypes/BaseContent/RemoveCardButton';
import { CardPublishingStatus } from '@components/ContentTypes/BaseContent/CardPublishingStatus';
import { CardValidationMark } from '@components/ContentTypes/BaseContent/CardValidationMark';
import { CardDragHandler } from '@components/ContentTypes/BaseContent/CardDragHandler';
import { RemoveModal } from '@components/ContentTypes/BaseContent/RemoveModal';
import { DragAndDropType } from '@components/DraggableList/DragAndDropType';
import { LanguageV2 } from '@features/content/languages';
import { getUrlToNavigate } from '@helpers/contentCardHelper';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { selectNewContentElementId } from '@selectors/CoursesSelectors';

import {
  CardControllers,
  CardDataWrapper,
  CardWrapper,
  Data,
  DataWrapper,
  Title,
} from '@components/ContentTypes/card.styles';

import { RoleplayListItemType } from '../../types';

type RoleplayCardPropsType = {
  content: RoleplayListItemType;
  idx: number | null;
  dndProps?: DragAndDropType;
};

export const RoleplayCategoryCard = ({ content, idx, dndProps }: RoleplayCardPropsType) => {
  const dispatch = useAppDispatch();
  const params = useParams<{ languageId: LanguageV2 }>();
  const { languageId } = params;
  const [hovered, setHovered] = useState(false);
  const [isRemoveModalOpened, setIsRemoveModalOpened] = useState(false);
  const { changeStatus, id, ready, title, type, validationStatus } = content;

  const newElementId = useAppSelector(selectNewContentElementId);

  const categoryTitle = title || 'Roleplay Category';

  const handleRemove = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsRemoveModalOpened(true);
  };

  return (
    <CardWrapper
      id={`roleplay-category-${id}`}
      onMouseOver={() => setHovered(true)}
      onMouseOut={() => setHovered(false)}
    >
      <RemoveModal
        opened={isRemoveModalOpened}
        onHide={() => setIsRemoveModalOpened(false)}
        onDelete={() => {
          dispatch(RoleplayActionCreators.removeRoleplayCategory(languageId, id));
        }}
      />
      <CardDragHandler dndProps={dndProps} visible={hovered} />
      <CardDataWrapper
        to={getUrlToNavigate(params, id, type)}
        $active={dndProps?.snapshot?.isDragging || id === newElementId}
        $hovered={hovered}
      >
        <CardPublishingStatus hasPendingChanges={!!changeStatus?.hasPendingChanges} ready={!!ready} />
        <DataWrapper>
          <Data>
            <CardPendingStatus content={content} />
            <Title title={categoryTitle}>
              {ready && idx !== null ? `${idx + 1} ` : ''}
              {categoryTitle}
              <CardValidationMark isInvalid={!validationStatus?.valid} />
            </Title>
          </Data>
          <CardControllers>
            {/* @TODO Add <PublishCardButton /> once BE is ready  */}
            <RemoveCardButton hovered={hovered} content={content} onRemove={handleRemove} />
          </CardControllers>
        </DataWrapper>
      </CardDataWrapper>
    </CardWrapper>
  );
};
