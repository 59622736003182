import { CourseSliceInterface } from '@common/interfaces/slices/CourseSliceInterface';
import { CoursesActions } from '@actions/CoursesActions';
import { LoadingStage } from '@common/enums/LoadingStage';
import { loadedExercisesInitialData } from '@redux/initialStates/courseInitialState';
import { PayloadAction } from '@reduxjs/toolkit';
import { clone } from '@helpers/clone';
import { APICourse } from '@services/types/CourseServiceTypes';
import { CourseInterface } from '@common/interfaces/courses/CourseInterface';
import CourseAdapterService from '@services/CourseSanitizerService';
import ExerciseDataModelAssembler from '@components/Exercises/ExerciseDataModelAssembler';
import { ContentTypes } from '@common/enums/ContentTypes';

export const updateCourseState = (
  state: CourseSliceInterface,
  loadedCourse: APICourse,
  isCourse: boolean,
  groupsAreEmpty?: boolean,
) => {
  const clonedState: CourseSliceInterface = clone(state);

  let createdCourse: CourseInterface = {
    ...CourseAdapterService.sanitizeCourse(loadedCourse),
  };

  let output: CourseSliceInterface = {
    ...clonedState,
    course: {
      ...createdCourse,
      dates: loadedCourse.dates,
      structure: [],
      loadingParentId: '',
      loaded: LoadingStage.loaded,
      interfaceLanguages: loadedCourse.interfaceLanguages.sort((a, b) => a.localeCompare(b)),
      titleWithLocalizations:
        (loadedCourse.title?.textLocalizations && loadedCourse.title.textLocalizations.length) ||
        (loadedCourse.title?.audioLocalizations && loadedCourse.title.audioLocalizations.length)
          ? {
              ...loadedCourse.title,
              ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranchesForSomeLanguages(
                ['textLocalizations'],
                loadedCourse.title?.textLocalizations,
              ),
              ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranchesForSomeLanguages(
                ['audioLocalizations'],
                loadedCourse.title?.audioLocalizations,
              ),
            }
          : {
              ...loadedCourse.title,
              ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranches([
                'textLocalizations',
                'audioLocalizations',
              ]),
            },
      descriptionWithLocalizations:
        (loadedCourse.description?.textLocalizations && loadedCourse.description.textLocalizations.length) ||
        (loadedCourse.description?.audioLocalizations && loadedCourse.description.audioLocalizations.length)
          ? {
              ...loadedCourse.description,
              ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranchesForSomeLanguages(
                ['textLocalizations'],
                loadedCourse.description?.textLocalizations,
              ),
              ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranchesForSomeLanguages(
                ['audioLocalizations'],
                loadedCourse.description?.audioLocalizations,
              ),
            }
          : {
              ...loadedCourse.description,
              ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranches([
                'textLocalizations',
                'audioLocalizations',
              ]),
            },
    },
    selectedGroupsOfParent: {
      ...clonedState.selectedGroupsOfParent,
      groups: groupsAreEmpty ? [] : clonedState.selectedGroupsOfParent.groups,
      groupsLoaded: groupsAreEmpty ? LoadingStage.loaded : clonedState.selectedGroupsOfParent.groupsLoaded,
      parentContents: isCourse ? null : clonedState.selectedGroupsOfParent.parentContents,
      parentId: isCourse ? createdCourse.id : clonedState.selectedGroupsOfParent.parentId,
      parentType: isCourse ? ContentTypes.course : clonedState.selectedGroupsOfParent.parentType,
    },
  };

  return output;
};

const CourseLoadCourseCourseReducers = {
  [CoursesActions.COURSE_LOADING]: (state: CourseSliceInterface, _action: PayloadAction<any>): CourseSliceInterface => {
    const clonedState: CourseSliceInterface = clone(state);

    clonedState.course.loaded = LoadingStage.loading;

    return clonedState;
  },
  [CoursesActions.GROUPS_LOADING]: (state: CourseSliceInterface, _action: PayloadAction<any>): CourseSliceInterface => {
    const clonedState: CourseSliceInterface = clone(state);

    clonedState.selectedGroupsOfParent.groupsLoaded = LoadingStage.loading;

    return clonedState;
  },
  [CoursesActions.GROUPS_LOADED_SUCCEEDED]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    const clonedState: CourseSliceInterface = clone(state);

    clonedState.selectedGroupsOfParent.groupsLoaded = LoadingStage.loaded;
    clonedState.selectedGroupsOfParent.groups = payload.groups;

    return clonedState;
  },
  [CoursesActions.COURSE_LOADED_SUCCEEDED]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    return updateCourseState(state, payload.course, payload.isCourse);
  },
  [CoursesActions.COURSE_GROUPS_LOADED_SUCCEEDED]: (
    state: CourseSliceInterface,
    _action: PayloadAction<any>,
  ): CourseSliceInterface => {
    const clonedState: CourseSliceInterface = clone(state);

    return {
      ...clonedState,
      course: {
        ...clonedState.course,
        loaded: LoadingStage.loaded,
      },
    };
  },
  [CoursesActions.ONE_COURSE_CONTENT_LOADING]: (
    state: CourseSliceInterface,
    _action: PayloadAction<any>,
  ): CourseSliceInterface => {
    const clonedState: CourseSliceInterface = clone(state);

    clonedState.selectedGroupsOfParent.loaded = LoadingStage.loading;

    return clonedState;
  },
  [CoursesActions.SET_POSITION_OF_NEW_CONTENT]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    const clonedState: CourseSliceInterface = clone(state);

    return {
      ...clonedState,
      newContent: {
        ...clonedState.newContent,
        newElementId: payload.newElementId || '',
      },
    };
  },
  [CoursesActions.SET_THUMBNAIL_IMAGE]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    return {
      ...state,
      course: {
        ...state.course,
        thumbnailImage: {
          ...payload.content,
        },
        thumbnailImageChanged: true,
      },
    };
  },
  [CoursesActions.SET_PAYWALL_IMAGE]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    return {
      ...state,
      course: {
        ...state.course,
        paywallImage: {
          ...payload.content,
        },
        paywallImageChanged: true,
      },
    };
  },
  [CoursesActions.UPDATE_GROUPS]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    const clonedState: CourseSliceInterface = clone(state);

    return {
      ...clonedState,
      selectedGroupsOfParent: {
        ...clonedState.selectedGroupsOfParent,
        groups: payload,
      },
    };
  },
  [CoursesActions.UPDATE_EXERCISES]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    const clonedState: CourseSliceInterface = clone(state);

    return {
      ...clonedState,
      loadedExercises: {
        ...clonedState.loadedExercises,
        exercises: payload,
      },
    };
  },
  [CoursesActions.ONE_COURSE_CONTENT_LOADED_SUCCEEDED]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    const clonedState: CourseSliceInterface = clone(state);

    return {
      ...clonedState,
      selectedGroupsOfParent: {
        ...clonedState.selectedGroupsOfParent,
        loaded: LoadingStage.loaded,
        parentContents: payload.parentContents,
        parentType: payload.parentContents.type,
        parentId: payload.parentId,
      },
      loadedExercises: loadedExercisesInitialData,
    };
  },
};

export default CourseLoadCourseCourseReducers;
