import { PayloadAction } from '@reduxjs/toolkit';
import produce from 'immer';

import { CourseSliceInterface } from '@common/interfaces/slices/CourseSliceInterface';
import { ActivityTypeChangePayload } from '@components/ContentTypes/ActivityCard/ActivityCardTypes';
import { ContentTypesActions } from '@actions/ContentTypesActions';

const ActivityReducers = {
  [ContentTypesActions.ACTIVITY_CHANGE_TYPE_SUCCEEDED]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<ActivityTypeChangePayload>,
  ) => {
    const { id, type } = payload;

    return produce(state, (draft) => {
      const activity = draft.course.structure.find((activity) => activity.id === id);
      const group = draft.selectedGroupsOfParent.groups.find((group) => group.id === id);

      if (activity) activity.type = type;

      if (group) {
        group.activityType = type;
      }
    });
  },
};

export default ActivityReducers;
