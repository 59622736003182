import { GrammarReviewSliceType } from '@common/interfaces/slices/GrammarReviewSliceType';
import { LoadingStage } from '@common/enums/LoadingStage';
import { ContentTypes } from '@common/enums/ContentTypes';
import ExerciseDataModelAssembler from '@components/Exercises/ExerciseDataModelAssembler';
import { DisplayContentToUserMode } from '@common/enums/DisplayContentToUserMode';
import { ExerciseTypes } from '@common/enums/ExerciseTypes';

export const grammarReviewInitialContent = {
  changeStatus: {
    hasNewChanges: false,
    hasPendingChanges: false,
  },
  dates: {
    firstPublishedAt: null,
    lastChangedAt: null,
  },
  id: '',
  owner: null,
  ready: false,
  type: ContentTypes.grammarReview,
  validationStatus: {
    validated: false,
    valid: false,
    errors: [{ field: 'children', message: 'This collection should contain 1 element or more.', isWarning: false }],
    invalidChildEntities: [],
  },
};

export const grammarCategoryInitialContent = {
  description: {
    ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranches(['textLocalizations', 'audioLocalizations']),
  },
  descriptionChanged: false,
  changeStatus: {
    hasNewChanges: false,
    hasPendingChanges: false,
  },
  dates: {
    firstPublishedAt: null,
    lastChangedAt: null,
  },
  icon: null,
  iconChanged: false,
  id: '',
  ready: false,
  title: {
    ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranches(['textLocalizations', 'audioLocalizations']),
  },
  titleChanged: false,
  type: ContentTypes.grammarCategory,
  owner: null,
  validationStatus: {
    validated: false,
    valid: false,
    errors: [{ field: 'children', message: 'This collection should contain 1 element or more.', isWarning: false }],
    invalidChildEntities: [],
  },
};

export const grammarTopicInitialContent = {
  attachedLessons: [],
  attachedLessonsSaveProgress: LoadingStage.notLoaded,
  changeStatus: {
    hasNewChanges: false,
    hasPendingChanges: false,
  },
  cefr: 'a1',
  dates: {
    firstPublishedAt: null,
    lastChangedAt: null,
  },
  description: {
    ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranches(['textLocalizations', 'audioLocalizations']),
  },
  descriptionChanged: false,
  id: '',
  owner: null,
  ready: false,
  type: ContentTypes.grammarTopic,
  title: {
    ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranches(['textLocalizations', 'audioLocalizations']),
  },
  titleChanged: false,
  validationStatus: {
    valid: false,
    validated: false,
    errors: [{ field: 'children', message: 'This collection should contain 1 element or more.', isWarning: false }],
    invalidChildEntities: [],
  },
};

export const grammarExerciseInitialContent = {
  changeStatus: {
    hasNewChanges: false,
    hasPendingChanges: false,
  },
  dates: {
    firstPublishedAt: null,
    lastChangedAt: null,
  },
  description: '',
  experiment: null,
  id: '',
  instructions: {
    ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranches(['textLocalizations', 'audioLocalizations']),
  },
  instructionsLanguage: DisplayContentToUserMode.interface,
  labels: [],
  name: '',
  pdfUrl: '',
  ready: false,
  type: ExerciseTypes.flashcard,
  validation: {
    validated: false,
    valid: false,
    errors: [{ field: 'children', message: 'This collection should contain 1 element or more.', isWarning: false }],
    invalidChildEntities: [],
  },
};

export const grammarReviewInitialState: GrammarReviewSliceType = {
  grammarReview: {
    content: grammarReviewInitialContent,
    loaded: LoadingStage.notLoaded,
    categoriesData: {
      categories: [],
      loaded: LoadingStage.notLoaded,
    },
  },
  grammarCategory: {
    content: grammarCategoryInitialContent,
    loaded: LoadingStage.notLoaded,
    topicsData: {
      topics: [],
      loaded: LoadingStage.notLoaded,
    },
  },
  grammarTopic: {
    content: grammarTopicInitialContent,
    loaded: LoadingStage.notLoaded,
    exercisesData: {
      exercises: {
        referenceExercises: [],
        bankExercises: [],
      },
      loaded: LoadingStage.notLoaded,
    },
  },
  grammarExercise: {
    content: grammarExerciseInitialContent,
    loaded: LoadingStage.notLoaded,
  },
  navigation: {
    structure: [],
    loadingParentId: '',
  },
};
