import { FunctionComponent } from 'react';

import comprehensionIcon from '@components/ContentTypes/BaseContent/img/comprehension.svg';
import { getValueByLanguage } from '@features/content';
import IDDisplayer from '@components/IDDisplayer/IDDisplayer';

interface ExerciseForRecapInterface {
  number: number;
  exercise: any;
}

export const ExerciseForRecap: FunctionComponent<ExerciseForRecapInterface> = ({
  exercise,
  number,
}: ExerciseForRecapInterface) => {
  return (
    <div className="content-common-header__content__excercise-data">
      <div className="content-common-header__content__image-container">
        <img className="content-common-header__content__image" src={comprehensionIcon} alt="logo" />
      </div>
      <div className="content-common-header__content__excercise-info">
        <div className="content-common-header__content__id-container">
          <IDDisplayer id={exercise?.id} mode="normal" />
        </div>
        <div className="content-common-header__content-title content-common-header__content-title--fixed-height">
          <span className="content-common-header__content-title__index">{number}</span>
          <span className="content-common-header__content-title__name">Comprehension</span>
        </div>
        <span className="content-common-header__content-title__common-text--bigger">{exercise?.previewText || ''}</span>
      </div>
      <div className="content-common-header__content__controllers">
        {getValueByLanguage(exercise?.imageData?.imageLocalizations) ? (
          <img
            src={getValueByLanguage(exercise?.imageData?.imageLocalizations)}
            alt="Card"
            className="content-common-header__card-image"
          />
        ) : (
          <div className="content-common-header__empty-card-image" />
        )}
        {exercise?.image ? (
          <img src={exercise?.image} alt="Card" className="content-common-header__card-image" />
        ) : (
          <div className="content-common-header__empty-card-image" />
        )}
      </div>
    </div>
  );
};
