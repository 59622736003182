import { useEffect } from 'react';
import { Formik } from 'formik';
import { useParams, withRouter } from 'react-router-dom';

import { ContentTypes } from '@common/enums/ContentTypes';
import { LoadingStage } from '@common/enums/LoadingStage';
import { DBId } from '@common/types/DBId';
import { ContentOwner } from '@features/content/contentOwnership';
import { Language } from '@features/content/languages';
import { SearchModal } from '@features/search';
import { Loader } from '@features/theme';
import { processLocalizationsForFormikValues } from '@helpers/formikInitialValuesHelper';

import { TranslationsPanel } from '../TranslationsPanel';
import { BreadcrumbsAndOwnershipWrapper } from '../styles';
import { Header } from '../Header';
import { GrammarReviewActionCreators } from '@actionCreators/GrammarReviewActionCreators';
import { GrammarCategoryData } from './GrammarCategoryData';
import { Breadcrumbs } from './Breadcrumbs';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { selectCourse } from '@selectors/CoursesSelectors';
import { selectGrammarCategory } from '@selectors/GrammarSelectors';
import { selectGrammarTranslationsPanel } from '@selectors/UiSelectors';

const GrammarCategory = () => {
  const dispatch = useAppDispatch();
  const { courseId, categoryId } = useParams<{ courseId: DBId; categoryId: DBId }>();

  const currentCourse = useAppSelector(selectCourse);
  const grammarCategory = useAppSelector(selectGrammarCategory);
  const grammarCategoryContent = grammarCategory.content;
  const grammarTranslationsPanel = useAppSelector(selectGrammarTranslationsPanel);

  useEffect(() => {
    dispatch(GrammarReviewActionCreators.setInitialGrammarCategory());
  }, [dispatch, categoryId]);

  useEffect(() => {
    if (grammarCategory.loaded === LoadingStage.notLoaded && courseId && categoryId) {
      dispatch(GrammarReviewActionCreators.getGrammarCategory(courseId, categoryId));
    }
  }, [dispatch, courseId, grammarCategory, categoryId]);

  useEffect(() => {
    if (
      grammarCategory.loaded === LoadingStage.loaded &&
      grammarCategory.topicsData.loaded === LoadingStage.notLoaded &&
      courseId &&
      categoryId
    ) {
      dispatch(GrammarReviewActionCreators.getGrammarTopics(courseId, categoryId));
    }
  }, [dispatch, courseId, categoryId, grammarCategory]);

  const getInitialValuesForGrammarContent = () => {
    return {
      title: processLocalizationsForFormikValues(grammarCategoryContent?.title?.textLocalizations),
      titleContext: grammarCategoryContent?.title?.description || '',
      titleChanged: false,

      description: processLocalizationsForFormikValues(grammarCategoryContent?.description?.textLocalizations),
      descriptionContext: grammarCategoryContent?.description?.description || '',
      descriptionChanged: false,
    };
  };

  if (grammarCategory.loaded === LoadingStage.loading) {
    return (
      <div className="course-edition__course-data-composer">
        {currentCourse && (
          <Header
            contentName="Grammar review"
            learningLanguage={currentCourse.learningLanguage as Language}
            interfaceLanguages={currentCourse.interfaceLanguages as Language[]}
          />
        )}
        <Loader size="L" />
      </div>
    );
  }

  if (grammarCategory.loaded === LoadingStage.loaded) {
    return (
      <Formik initialValues={getInitialValuesForGrammarContent()} onSubmit={() => {}}>
        <>
          {grammarTranslationsPanel.visible && <TranslationsPanel content={grammarCategory.content} />}
          <div className="course-edition__course-data-composer">
            <SearchModal />
            {currentCourse && (
              <Header
                contentName="Grammar review"
                learningLanguage={currentCourse.learningLanguage as Language}
                interfaceLanguages={currentCourse.interfaceLanguages as Language[]}
              />
            )}
            <BreadcrumbsAndOwnershipWrapper>
              <Breadcrumbs />
              <ContentOwner
                childComponentId={grammarCategoryContent.id}
                contentType={ContentTypes.grammarCategory}
                componentId={courseId}
                dates={grammarCategoryContent.dates}
                owner={grammarCategoryContent.owner}
              />
            </BreadcrumbsAndOwnershipWrapper>
            <GrammarCategoryData />
          </div>
        </>
      </Formik>
    );
  }

  return null;
};

export const GrammarCategoryContent = withRouter(GrammarCategory);
