import { useMemo } from 'react';
import styled from 'styled-components/macro';
import _ from 'lodash';

import { LessonSearchResultType, LessonDetailsTab } from '../../types';
import { CardWrapper, CardTitle, CardText, CardLocations, CardMoreButton, CardImage } from '../Common';
import { LESSON_TITLES } from '@common/enums/LessonTypes';

const LessonCardWrapper = styled(CardWrapper)`
  grid-template-columns: 6% 41% 12% 12% 20% 4%;
`;

export const LessonResultCard = ({
  content,
  isExpanded,
  onExpand,
}: {
  content: LessonSearchResultType;
  isExpanded: boolean;
  onExpand: (tabName: LessonDetailsTab['key'] | null) => void;
}) => {
  const plainTextTitle = useMemo(
    () => content.title?.replace(/(<([^>]+)>)|(&.+;)/gi, '') || 'Untitled lesson',
    [content.title],
  );

  return (
    <LessonCardWrapper isExpanded={isExpanded} onClick={() => onExpand(null)}>
      <CardImage image={content.image} />
      <CardTitle id={content.id} title={plainTextTitle} />
      <CardText text={LESSON_TITLES[content.class]} />
      <CardText text={_.startCase(content.focus)} />
      <CardLocations
        courses={content.courses}
        locationsCount={content.locationsCount}
        onExpand={() => onExpand('locations')}
      />
      <CardMoreButton isExpanded={isExpanded} />
    </LessonCardWrapper>
  );
};
