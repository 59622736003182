import Select, { StylesConfig } from 'react-select';

import {
  useSelectorCommonStyles,
  CustomDropdownIndicator,
  CustomClearIndicator,
} from '@components/SelectorCommonComponents';
import { CommonFilterOptionType } from '../types';

const CLASSNAME_PREFIX = 'yes-no-selector';

type YesNoSelectorProps = {
  value: CommonFilterOptionType | null;
  rule: string;
  className?: string;
  placeholder?: string;
  onChange: (option: CommonFilterOptionType | null) => void;
};

export const YesNoSelector = ({
  value,
  className,
  rule,
  placeholder = 'Select yes or no',
  onChange,
}: YesNoSelectorProps) => {
  const CommonSelectorStyles = useSelectorCommonStyles<CommonFilterOptionType>({ classNamePrefix: CLASSNAME_PREFIX });

  const SelectorStyles: StylesConfig<CommonFilterOptionType> = {
    ...CommonSelectorStyles,
    dropdownIndicator: (defaultStyles, props) => ({
      ...defaultStyles,
      ...(CommonSelectorStyles.dropdownIndicator && CommonSelectorStyles.dropdownIndicator(defaultStyles, props)),
      display: `${value?.value ? 'none' : 'block'}`,
    }),
    singleValue: (defaultStyles, props) => ({
      ...defaultStyles,
      ...(CommonSelectorStyles.singleValue && CommonSelectorStyles.singleValue(defaultStyles, props)),
      ':before': {
        content: `"${value?.value && `${rule}: `}"`,
      },
    }),
  };

  const options = [
    { label: 'Yes', value: 'yes' },
    { label: 'No', value: 'no' },
  ];

  return (
    <Select<CommonFilterOptionType>
      classNamePrefix={CLASSNAME_PREFIX}
      className={className}
      value={value}
      placeholder={placeholder}
      options={options}
      isSearchable={false}
      isClearable
      isMulti={false}
      styles={SelectorStyles}
      components={{ DropdownIndicator: CustomDropdownIndicator, ClearIndicator: CustomClearIndicator }}
      onChange={onChange}
    />
  );
};

type SimpleYesNoSelectorProps = Omit<YesNoSelectorProps, 'value' | 'onChange'> & {
  value: boolean | null;
  onChange: (value: boolean | null) => void;
};
export const SimpleYesNoSelector = ({ value, onChange, ...restProps }: SimpleYesNoSelectorProps) => {
  return (
    <YesNoSelector
      value={value !== null ? { value: 'yes', label: 'Yes' } : null}
      onChange={(value) => onChange(value !== null ? value.value === 'yes' : null)}
      {...restProps}
    />
  );
};
