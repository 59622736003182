const ENABLE_BULK_PUBLISHING_STATE = 'ENABLE_BULK_PUBLISHING_STATE';
const DISABLE_ALL = 'DISABLE_ALL';
const TOGGLE_SELECTED_CHILDREN = 'TOGGLE_SELECTED_CHILDREN';
const SET_SELECTED_CHILDREN = 'SET_SELECTED_CHILDREN';

export const BulkPublishingActions = {
  ENABLE_BULK_PUBLISHING_STATE,
  DISABLE_ALL,
  TOGGLE_SELECTED_CHILDREN,
  SET_SELECTED_CHILDREN,
} as const;
