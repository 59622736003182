import { createSlice, ActionCreator } from '@reduxjs/toolkit';
import { vocabularyReviewInitialState } from '@redux/initialStates/vocabularyReviewInitialState';
import { VocabularyReviewReducers } from '@redux/reducers/vocabularyReviewSlice/VocabularyReviewReducers';

const extraReducers = {
  ...VocabularyReviewReducers,
} as Record<string, ActionCreator<any>>;

export const vocabularyReviewSlice = createSlice({
  name: 'vocabularyReview',
  initialState: vocabularyReviewInitialState,
  reducers: {},

  extraReducers: (builder) => {
    Object.keys(extraReducers).forEach((actionType) => {
      builder.addCase(actionType, extraReducers[actionType]);
    });
  },
});
