import { MouseEvent, ReactNode, RefObject, useCallback, useState } from 'react';

export type ModalControls = {
  close: () => void;
  isOpen: boolean;
};
type ModalChildren = (modalControls: ModalControls) => ReactNode;

export const useModal = (children: ModalChildren) => {
  const [isOpen, setIsOpen] = useState(false);

  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);

  return {
    open,
    close,
    isOpen,
    modal: <>{children({ close, isOpen })}</>,
  };
};

export const useOnClickOutside = (
  ref: RefObject<HTMLDialogElement>,
  onClickOutside: (e: MouseEvent<HTMLDialogElement>) => void,
) => {
  return useCallback(
    (e: MouseEvent<HTMLDialogElement>) => {
      if (!onClickOutside || !ref.current || e.target !== ref.current) {
        return;
      }

      const dialogRect = ref.current.getBoundingClientRect();
      if (
        dialogRect.left < e.clientX &&
        e.clientX < dialogRect.right &&
        dialogRect.top < e.clientY &&
        e.clientY < dialogRect.bottom
      ) {
        return;
      }

      onClickOutside && onClickOutside(e);
    },
    [ref, onClickOutside],
  );
};
