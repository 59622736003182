import React from 'react';
import { SimpleLanguageSelector } from '@components/SelectorCommonComponents';
import type { CourseInfoInterface } from '@common/interfaces/courses/CourseInfoInterface';

import type { StateConfig } from '../Common/useFiltersState';
import type { LessonQueryParamsType } from '../../service/types';
import { FiltersContainer } from '../Common/SearchHeader/FiltersContainer';
import {
  FilterField,
  SimpleCourseSelector,
  SimpleRangeSelector,
  SimpleTypeSelector,
  SimpleYesNoSelector,
} from '../Common';
import { ACTIVITIES, ACTIVITY_TITLES } from '@features/content/activities';
import { LESSON_TITLES, LESSONS, LESSON_FOCUS_TITLES, LESSON_FOCUSES } from '@common/enums/LessonTypes';
import { isFeatureEnabled } from '@helpers/featuresHelper';
import { Language } from '@features/content/languages';

let lessonTypes = LESSONS;
if (!isFeatureEnabled('speakingBites')) {
  lessonTypes = lessonTypes.filter((type) => type !== 'speaking');
}

type LessonSearchFiltersProps = {
  filtersState: StateConfig<LessonQueryParamsType>;
  availableCourses: CourseInfoInterface[];
  onSaveFilters?: (name: string) => Promise<void>;
};
export const LessonSearchFilters = ({ filtersState, availableCourses, onSaveFilters }: LessonSearchFiltersProps) => {
  const filters = [
    <FilterField
      state={filtersState}
      name="languages"
      render={(value, onChange) => <SimpleLanguageSelector value={value} onChange={onChange} />}
    />,
    <FilterField
      state={filtersState}
      name="courses"
      render={(value, onChange) => (
        <SimpleCourseSelector
          courses={availableCourses}
          courseFilter={(course) =>
            filtersState.state.languages === null ||
            filtersState.state.languages.includes(course.learningLanguage as Language)
          }
          value={value}
          onChange={onChange}
        />
      )}
    />,
    <FilterField
      state={filtersState}
      name="lessonTypes"
      render={(value, onChange) => (
        <SimpleTypeSelector
          types={lessonTypes}
          labelGenerator={(type) => LESSON_TITLES[type]}
          value={value}
          onChange={onChange}
        />
      )}
    />,
    <FilterField
      state={filtersState}
      name="lessonFocuses"
      render={(value, onChange) => (
        <SimpleTypeSelector
          rule="Focus"
          types={LESSON_FOCUSES}
          labelGenerator={(focus) => LESSON_FOCUS_TITLES[focus]}
          value={value}
          onChange={onChange}
        />
      )}
    />,
  ];
  const secondaryFilters = [
    {
      filter: (
        <FilterField
          state={filtersState}
          name="hasImage"
          render={(value, onChange) => (
            <SimpleYesNoSelector placeholder="Has image" rule="Has image" value={value} onChange={onChange} />
          )}
        />
      ),
      label: 'Has image',
      onRemove: () => filtersState.resetField('hasImage'),
    },
    {
      filter: (
        <FilterField
          state={filtersState}
          name="hasThumbnailImage"
          render={(value, onChange) => (
            <SimpleYesNoSelector
              placeholder="Has thumbnail image"
              rule="Has thumbnail image"
              value={value}
              onChange={onChange}
            />
          )}
        />
      ),
      label: 'Has thumbnail image',
      onRemove: () => filtersState.resetField('hasThumbnailImage'),
    },
    {
      filter: (
        <FilterField
          state={filtersState}
          name="hasIdentityGroup"
          render={(value, onChange) => (
            <SimpleYesNoSelector
              placeholder="Has identity group"
              rule="Has identity group"
              value={value}
              onChange={onChange}
            />
          )}
        />
      ),
      label: 'Has identity group',
      onRemove: () => filtersState.resetField('hasIdentityGroup'),
    },
    {
      filter: (
        <FilterField
          state={filtersState}
          name="isAttachedToGrammarTopic"
          render={(value, onChange) => (
            <SimpleYesNoSelector
              placeholder="Att. to grammar topic"
              rule="Att. to grammar topic"
              value={value}
              onChange={onChange}
            />
          )}
        />
      ),
      label: 'Attached to grammar topic',
      onRemove: () => filtersState.resetField('isAttachedToGrammarTopic'),
    },
    {
      filter: (
        <FilterField
          state={filtersState}
          name="isPublished"
          render={(value, onChange) => (
            <SimpleYesNoSelector placeholder="Is Published" rule="Is Published" value={value} onChange={onChange} />
          )}
        />
      ),
      label: 'Is Published',
      onRemove: () => filtersState.resetField('isPublished'),
    },
    {
      filter: (
        <FilterField
          state={filtersState}
          name="isReused"
          render={(value, onChange) => (
            <SimpleYesNoSelector placeholder="Is Reused" rule="Is Reused" value={value} onChange={onChange} />
          )}
        />
      ),
      label: 'Is Reused',
      onRemove: () => filtersState.resetField('isReused'),
    },
    {
      filter: (
        <FilterField
          state={filtersState}
          name="activityTypes"
          render={(value, onChange) => (
            <SimpleTypeSelector
              rule="Activity type"
              types={ACTIVITIES}
              labelGenerator={(type) => ACTIVITY_TITLES[type]}
              value={value}
              onChange={onChange}
            />
          )}
        />
      ),
      label: 'Activity type',
      onRemove: () => filtersState.resetField('activityTypes'),
    },
    {
      filter: (
        <FilterField
          state={filtersState}
          name="numActivities"
          render={(value, onChange) => (
            <SimpleRangeSelector rule="Num. activities" maxLimit={50} value={value} onChange={onChange} />
          )}
        />
      ),
      label: 'Number of activities',
      onRemove: () => filtersState.resetField('numActivities'),
    },
  ];

  return (
    <FiltersContainer
      filters={filters.filter((filter) => Boolean(filter)) as NonNullable<(typeof filters)[number]>[]}
      secondaryFilters={
        (secondaryFilters || []).filter((filter) => Boolean(filter)) as NonNullable<(typeof secondaryFilters)[number]>[]
      }
      emptyFilters={filtersState.isEmpty}
      onSaveFilters={onSaveFilters}
    />
  );
};
