import styled from 'styled-components/macro';

import { Loader, Button } from '@features/theme';
import { Language } from '@features/content/languages';

const ButtonsContainer = styled.div`
  gap: 0.8rem;
  display: flex;
  justify-content: end;
`;

type EditPanelProps = {
  saveInProgress: boolean;
  languages: Language[];
  onCancel: () => void;
  onSave: () => void;
};

export const EditPanel = ({ saveInProgress, languages, onCancel, onSave }: EditPanelProps) => (
  <ButtonsContainer>
    <Button variant="secondary" onClick={onCancel} disabled={saveInProgress}>
      Cancel
    </Button>
    <Button onClick={onSave} disabled={!languages.length}>
      {saveInProgress ? <Loader /> : 'Save'}
    </Button>
  </ButtonsContainer>
);
