import { ExerciseService } from '@common/types/ExerciseService';
import ExerciseDataModelAssembler from '@components/Exercises/ExerciseDataModelAssembler';
import { ExerciseCommonActionCreators as ExerciseCommonActions } from '@actionCreators/ExerciseCommonActionCreator';
import { FormikValuesInterface } from '@helpers/formikInitialValuesHelper';
import ConversationExerciseInterface from '@components/Exercises/Conversation/interfaces/ConversationExerciseInterface';
import ExercisesService from '@services/ExercisesService';
import { AppDispatch } from '@redux/store';
import { clone } from '@helpers/clone';
import { editConversationExercise } from '@services/exercises/editExerciseService';
import { EditConversationExerciseRequest, DisplayedLanguageType } from '@services/exercises/editExerciseTypes';
import { addToast } from '@features/app/toast';

type ConversationExerciseServiceType = ExerciseService<ConversationExerciseInterface>;

const ConversationExerciseService: ConversationExerciseServiceType = {
  ensureExerciseFieldsAreReadyForUse(
    exerciseAndEmptyLocalizationBranchesPayload: any,
    exercise: ConversationExerciseInterface,
  ) {
    let output = clone(exerciseAndEmptyLocalizationBranchesPayload);

    return ExerciseDataModelAssembler.ensureFieldIsReadyForUse(
      exercise,
      output,
      ['instructions', 'hint', 'image1', 'image2', 'image3'],
      exerciseAndEmptyLocalizationBranchesPayload,
    );
  },
  async save(dispatch: AppDispatch, exercise: ConversationExerciseInterface, values: FormikValuesInterface) {
    try {
      let payload: EditConversationExerciseRequest = {
        instructionsLanguage: exercise.content.instructionsLanguage as DisplayedLanguageType | undefined,
        minimumNumberOfWords: exercise.content.minimumNumberOfWords,
        recapExerciseId: exercise.content.recapExerciseId,
        experiment: exercise.content.experiment,
      };

      const updatePayload = await Promise.all([
        (values.instructionsChanged || exercise.content.instructions.changed) && !exercise.content.instructions.isReused
          ? ExercisesService.misc.saveField(dispatch, 'instructions', exercise, payload)
          : { instructions: exercise.content.instructions._id },
        (values.hintChanged || exercise.content.hint?.changed || exercise.content.hintChanged) &&
        !exercise.content.hint?.isReused
          ? ExercisesService.misc.saveField(dispatch, 'hint', exercise, payload)
          : { hint: exercise.content.hint?._id || null },
      ]);

      const imagesPayload = await Promise.all([
        exercise.content.image1?.changed || exercise.content.image1Changed
          ? ExercisesService.misc.saveField(dispatch, 'image1', exercise, payload)
          : { image1: exercise.content?.image1?._id || null },
        exercise.content.image2?.changed || exercise.content.image2Changed
          ? ExercisesService.misc.saveField(dispatch, 'image2', exercise, payload)
          : { image2: exercise.content?.image2?._id || null },
        exercise.content.image3?.changed || exercise.content.image3Changed
          ? ExercisesService.misc.saveField(dispatch, 'image3', exercise, payload)
          : { image3: exercise.content?.image3?._id || null },
      ]);

      payload = {
        ...payload,
        ...updatePayload.reduce((sum: any, item: any) => ({ ...sum, ...item }), {}),
        images: [imagesPayload[0].image1, imagesPayload[1].image2, imagesPayload[2].image3],
      };

      if (exercise.content.id) {
        await editConversationExercise(exercise.content.id, payload);

        dispatch(ExerciseCommonActions.setSaveProgress({ value: false, updateData: true }));

        addToast({
          type: 'success',
          title: 'This exercise has been saved',
        });
      }
    } catch (e: any) {
      if (e.response?.status === 400) {
        addToast({
          type: 'error',
          title: `${e.response.data?.detail}`,
        });
      }

      throw new Error(e as string);
    }
  },
};

export default ConversationExerciseService;
