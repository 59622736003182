import type { DBId } from '@common/types/DBId';
import type { NavigationItemType } from '@features/content/navigation';
import { NavigationActions } from '@actions/NavigationActions';

export const CourseNavigationActionsCreator = {
  updateNavigation: (newNavigation: NavigationItemType[]) => ({
    type: NavigationActions.UPDATE_NAVIGATION,
    payload: newNavigation,
  }),
  setLoadingParentId: (id: DBId) => ({
    type: NavigationActions.SET_LOADING_PARENT_ID,
    payload: id,
  }),
  getAllNavigation: (courseId: DBId, levelId: DBId, chapterId: DBId, lessonId: DBId, activityId: DBId) => ({
    type: NavigationActions.GET_ALL_NAVIGATION,
    payload: { courseId, levelId, chapterId, lessonId, activityId },
  }),
};
