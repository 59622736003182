import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

import { DBId } from '@common/types/DBId';
import { Modal } from 'react-bootstrap';
import { ChapterForEntrypoint } from '../types';
import { LinkedChapterModalFooter } from './LinkedChapterModalFooter';
import { LinkedChaptersList } from './LinkedChaptersList';
import { EntrypointContentType } from '../../types';
import { PlacementTestActionCreators } from '@actionCreators/PlacementTestActionCreators';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { selectIsSaveInProgress } from '@selectors/CoursesSelectors';

const LinkedModal = styled(Modal)`
  .modal-dialog {
    max-width: 94rem;
  }

  .modal-content {
    height: 73rem;
    border-radius: 1.6rem;
  }
`;

const LinkedModalBody = styled(Modal.Body)`
  padding: 4rem;
`;

const LinkedModalContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const ModalTitle = styled.span`
  font-weight: 800;
  font-size: 2rem;
  color: ${({ theme }) => theme.colorV2.placementTestLinkedChapterModalTitleText};
  margin-bottom: 2.4rem;
`;

const levelNames: Record<string, string> = {
  A1: 'Beginner A1',
  'Midpoint A1': 'Beginner A1',
  A2: 'Elementary A2',
  'Midpoint A2': 'Elementary A2',
  B1: 'Intermediate B1',
  B2: 'Upper Intermediate B2',
  C1: 'Advanced C1',
};

export const LinkedChapterModal = ({
  opened,
  onClose,
  selectedChapter,
  isChaptersLoaded,
  entrypointContent,
  availableChapters,
}: {
  opened: boolean;
  onClose: () => void;
  isChaptersLoaded: boolean;
  selectedChapter: { id: DBId; title: string } | null;
  entrypointContent: EntrypointContentType;
  availableChapters: ChapterForEntrypoint[];
}) => {
  const dispatch = useAppDispatch();
  const isSaveInProgress = useAppSelector(selectIsSaveInProgress);
  const { courseId, entrypointId } = useParams<{ courseId: DBId; entrypointId: DBId }>();
  const [currentSelectedChapter, setCurrentSelectedChapter] = useState(selectedChapter);
  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    if (!opened) {
      setIsChanged(false);
      setCurrentSelectedChapter(selectedChapter);
    }
  }, [opened, selectedChapter]);

  return (
    <LinkedModal show={opened} centered onHide={onClose}>
      <LinkedModalBody data-testid="grammar-chapters-modal-body">
        <LinkedModalContent>
          <ModalTitle>{`Choose chapter, which you want to mark as ${entrypointContent.title}`}</ModalTitle>
          <LinkedChaptersList
            levelName={levelNames[entrypointContent.title]}
            isNotLoadedYet={!isChaptersLoaded && opened}
            availableChapters={availableChapters}
            currentSelectedChapter={currentSelectedChapter}
            onChapterSelect={(chapter: { id: DBId; title: string }) => {
              setCurrentSelectedChapter(chapter);
              setIsChanged(chapter.id !== selectedChapter?.id);
            }}
          />
          <LinkedChapterModalFooter
            isSaveInProgress={isSaveInProgress}
            isChanged={isChanged}
            onClose={onClose}
            onSave={() => {
              dispatch(
                PlacementTestActionCreators.saveEntrypoint(courseId, entrypointId, { chapter: currentSelectedChapter }),
              );
              onClose();
            }}
          />
        </LinkedModalContent>
      </LinkedModalBody>
    </LinkedModal>
  );
};
