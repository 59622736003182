import styled from 'styled-components/macro';

const TableHeaderRow = styled.tr`
  padding: 0 0 0.8rem 0 !important;
  box-shadow: 0 0.1rem 0 ${({ theme }) => theme.color.brandGreyscale600};
  display: grid;
  grid-template-columns: 10% 6% 14% 20% 14% 18% 8% 4%;
  grid-column-gap: 1%;
`;

const TableHeaderItem = styled.th`
  border: none !important;
  font-weight: 700 !important;
  font-size: 1.4rem !important;
  color: ${({ theme }) => theme.colorV2.textTertiary};
`;

export const TableHeader = () => (
  <thead>
    <TableHeaderRow>
      <TableHeaderItem>ID</TableHeaderItem>
      <TableHeaderItem>Language</TableHeaderItem>
      <TableHeaderItem>Voice Artist</TableHeaderItem>
      <TableHeaderItem>Phrase</TableHeaderItem>
      <TableHeaderItem>Status</TableHeaderItem>
      <TableHeaderItem>Requester</TableHeaderItem>
      <TableHeaderItem>Request date</TableHeaderItem>
      <TableHeaderItem></TableHeaderItem>
    </TableHeaderRow>
  </thead>
);
