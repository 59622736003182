const SAVE = 'HIGHLIGHTER_RECOGNITION_EXERCISE_SAVE';
const SET_INSTRUCTIONS_LANGUAGE = 'HIGHLIGHTER_SET_INSTRUCTIONS_LANGUAGE';
const SET_PHRASE0_TEXT = 'HIGHLIGHTER_SET_PHRASE0_TEXT';
const SET_PHRASE1_TEXT = 'HIGHLIGHTER_SET_PHRASE1_TEXT';
const SET_PHRASE2_TEXT = 'HIGHLIGHTER_SET_PHRASE2_TEXT';
const SET_PHRASE3_TEXT = 'HIGHLIGHTER_SET_PHRASE3_TEXT';
const SET_HIGHLIGHTER_ALL_FIELDS = 'SET_HIGHLIGHTER_ALL_FIELDS';

export const HighlighterExerciseActions = {
  SAVE,
  SET_INSTRUCTIONS_LANGUAGE,
  SET_PHRASE0_TEXT,
  SET_PHRASE1_TEXT,
  SET_PHRASE2_TEXT,
  SET_PHRASE3_TEXT,
  SET_HIGHLIGHTER_ALL_FIELDS,
} as const;
