import { apiClient } from '@features/api';

export const SearchService = {
  async useGroup(id: string, parentId: string, position: number) {
    return await apiClient.v2.post(`content/groups/${parentId}/attach-group`, { childId: id, position });
  },
  async cloneGroup(id: string) {
    return await apiClient.v2.post(`content/groups/${id}/clone`);
  },
  async useExercise(id: string, parentId: string, position: number) {
    return await apiClient.v2.post(`content/groups/${parentId}/attach-exercise`, { childId: id, position });
  },
  async cloneExercise(id: string) {
    return await apiClient.v2.post(`content/exercises/${id}/clone`);
  },
  async cloneBundle(id: string) {
    return await apiClient.v2.post(`content/resource-bundles/${id}/clone`);
  },
  async cloneContent(id: string) {
    return await apiClient.v2.post(`content/resources/${id}/clone`);
  },
};
