import { HelpDisplayer } from '@features/help';
import { SidePanelBody, SidePanelHeader } from '@features/theme';
import CourseCreationStage from './CourseCreationStage';

type CourseLanguagesPanelProps = {
  close: () => void;
  courseId?: string;
  onUpdated?: (courseId: string) => void;
};
export const CourseLanguagesPanel = ({ close, courseId, onUpdated }: CourseLanguagesPanelProps) => {
  const isEditingCourse = Boolean(courseId);

  return (
    <>
      <SidePanelHeader>
        {isEditingCourse ? 'Edit interface languages' : 'Create new course'}
        <HelpDisplayer contentPosition="bottom-right" id="how-to-create-course" type="how-to" />
      </SidePanelHeader>
      <SidePanelBody>
        <CourseCreationStage isEditingCourse={isEditingCourse} close={close} onUpdated={onUpdated} />
      </SidePanelBody>
    </>
  );
};
