import React, { ReactElement } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';

import { DBId } from '@common/types/DBId';
import { getLink, getTitle } from '@components/CourseNavigation/navigationHelpers';
import { courseEditionAbandoned } from '@actionCreators/BusuuLogoActionCreator';
import { getRealId } from '@helpers/reuseHelper';
import { useAppDispatch, useAppSelector } from '@redux/store';

import { ReactComponent as ArrowIcon } from './img/arrow.svg';
import { ReactComponent as HomeIcon } from './img/home.svg';
import { selectCourseStructure, selectIsSaveButtonEnabled } from '@selectors/CoursesSelectors';

const BreadcrumbNavigationContainer = styled.div`
  align-items: center;
  display: flex;
  padding: 0.8rem 0 3.2rem 0;
`;

const BreadcrumbNavigationItem = styled.span<{ withLink?: boolean }>`
  ${({ theme, withLink }) => css`
    color: ${theme.colorV2[withLink ? 'accentPrimary' : 'textPrimary']};
    cursor: ${withLink ? 'pointer' : 'auto'};
    font-size: 1.2rem;

    &:hover {
      opacity: ${withLink ? '0.6' : '1'};
    }
  `}
`;

const StyledHomeIcon = styled(HomeIcon)`
  margin-bottom: 0.2rem;

  &:hover {
    opacity: 0.5;
  }
`;

const StyledArrowIcon = styled(ArrowIcon)`
  margin: 0 0.8rem;
`;

type BreadcrumbNavigationProps = {
  onCourseNavigationChange?: Function;
  breadcrumbItems?: ReactElement[];
};

const BreadcrumbNavigation = ({ onCourseNavigationChange, breadcrumbItems }: BreadcrumbNavigationProps) => {
  const dispatch = useAppDispatch();
  let { courseId, levelId, chapterId, lessonId, activityId, exerciseId } = useParams<{
    courseId: DBId;
    levelId: DBId;
    chapterId: DBId;
    lessonId: DBId;
    activityId: DBId;
    exerciseId: DBId;
  }>();

  let contentId = courseId;
  if (exerciseId) {
    contentId = exerciseId;
  } else if (activityId) {
    contentId = activityId;
  } else if (lessonId) {
    contentId = lessonId;
  } else if (chapterId) {
    contentId = chapterId;
  } else if (levelId) {
    contentId = levelId;
  }
  const isSaveButtonEnabled = useAppSelector(selectIsSaveButtonEnabled);
  const courseStructure = useAppSelector(selectCourseStructure);

  const history = useHistory();

  const getNodesChain = () => {
    let resultNodes = [];
    let currentNode = courseStructure.find(
      (structureElement) => structureElement.id === contentId || structureElement.id === getRealId(contentId),
    );

    const updateCurrentNode = (_currentNode: typeof currentNode) =>
      courseStructure.find((structureElement) => structureElement.id === _currentNode?.parentId);

    while (currentNode) {
      resultNodes.unshift(currentNode);
      currentNode = updateCurrentNode(currentNode);
    }
    return resultNodes;
  };

  const homeClickHandler = () => {
    if (!isSaveButtonEnabled) {
      dispatch(courseEditionAbandoned());
    }
  };

  const nodeClickHandler = (node: any) => {
    const newURL = getLink(node, courseStructure);

    history.push(newURL);
    if (!isSaveButtonEnabled) {
      onCourseNavigationChange && onCourseNavigationChange(node);
    }
  };

  let breadcrumbsChain = getNodesChain().map((node, index, arr) => {
    return (
      <React.Fragment key={node.id}>
        <StyledArrowIcon title="Next navigation" />
        {index !== arr.length - 1 ? (
          <BreadcrumbNavigationItem withLink onClick={() => nodeClickHandler(node)}>
            {getTitle(node, courseStructure)}
          </BreadcrumbNavigationItem>
        ) : (
          <BreadcrumbNavigationItem>{getTitle(node, courseStructure)}</BreadcrumbNavigationItem>
        )}
      </React.Fragment>
    );
  });

  return (
    <BreadcrumbNavigationContainer>
      <Link to="/" onClick={homeClickHandler}>
        <StyledHomeIcon title="Home" />
      </Link>
      {breadcrumbItems ? (
        <>
          {breadcrumbItems.map((item: ReactElement, index: number) => (
            <React.Fragment key={index}>
              <StyledArrowIcon title="Next navigation" />
              <BreadcrumbNavigationItem withLink={index < breadcrumbItems.length - 1}>{item}</BreadcrumbNavigationItem>
            </React.Fragment>
          ))}
        </>
      ) : (
        <>{breadcrumbsChain}</>
      )}
    </BreadcrumbNavigationContainer>
  );
};

export default BreadcrumbNavigation;
