import * as _ from 'lodash';

import { dropdown_defaultCode, DropdownInterface } from '@components/Dropdown';
import { Language, LANGUAGE_NAMES, LANGUAGE_FLAGS } from '@features/content/languages';

export const generateLanguageDropdownOptions = (languages: Language[], defaultText?: string, termination?: string) => {
  const stringTypesDropdownValues: DropdownInterface<Language>[] = [
    ...languages.map((language: Language) => {
      return {
        name: (
          <>
            <img src={LANGUAGE_FLAGS[language]} className="dropdown__country-flag" alt="Country flag" />{' '}
            {_.upperFirst(LANGUAGE_NAMES[language])} {termination && termination}
          </>
        ),
        code: language,
      };
    }),
  ];

  if (defaultText !== undefined) {
    stringTypesDropdownValues.unshift({
      name: defaultText,
      code: dropdown_defaultCode,
    });
  }

  return stringTypesDropdownValues;
};
