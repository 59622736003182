import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { RoleplayActionCreators } from '@actionCreators/RoleplayActionsCreators';
import { DBId } from '@common/types/DBId';
import { CardDragHandler } from '@components/ContentTypes/BaseContent/CardDragHandler';
import { CardPendingStatus } from '@components/ContentTypes/BaseContent/CardPendingStatus';
import { CardPublishingStatus } from '@components/ContentTypes/BaseContent/CardPublishingStatus';
import { CardValidationMark } from '@components/ContentTypes/BaseContent/CardValidationMark';
import { RemoveCardButton } from '@components/ContentTypes/BaseContent/RemoveCardButton';
import { RemoveModal } from '@components/ContentTypes/BaseContent/RemoveModal';
import { DragAndDropType } from '@components/DraggableList/DragAndDropType';
import { Language, LanguageV2 } from '@features/content/languages';
import { getUrlToNavigate } from '@helpers/contentCardHelper';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { selectLearningLanguage, selectNewContentElementId } from '@selectors/CoursesSelectors';
import { ReactComponent as ImageGenericIcon } from '@static/svg/image-generic.svg';

import {
  CardControllers,
  CardDataWrapper,
  CardWrapper,
  Data,
  DataWrapper,
  Description,
  ScenarioImage,
  ScenarioImagePlaceholder,
  Title,
} from '@components/ContentTypes/card.styles';

import { PublishCardButton } from '../../PublishCardButton';
import { RoleplayCategoryListItemType } from '../../types';
import { mapLanguageToLanguageV2 } from '@features/content/languages';

type RoleplayCardPropsType = {
  content: RoleplayCategoryListItemType;
  idx: number | null;
  dndProps?: DragAndDropType;
  showRemove?: boolean;
};

export const RoleplayScenarioCard = ({ content, idx, dndProps, showRemove = true }: RoleplayCardPropsType) => {
  const dispatch = useAppDispatch();
  let params = useParams<{ languageId: LanguageV2; categoryId: DBId }>();

  const courseLearningLanguage = useAppSelector(selectLearningLanguage);

  // Param fallbacks when scenario is listed into a Roleplay Lesson
  if (!params.languageId) {
    params.languageId = mapLanguageToLanguageV2(courseLearningLanguage as Language);
  }

  if (!params.categoryId) {
    params.categoryId = content.categoryId as string;
  }

  let { categoryId } = params;

  const [hovered, setHovered] = useState(false);
  const [isRemoveModalOpened, setIsRemoveModalOpened] = useState(false);
  const { cefr, changeStatus, id, image, ready, title, type, validationStatus } = content;

  const newElementId = useAppSelector(selectNewContentElementId);

  const scenarioTitle = title || 'Roleplay Scenario';

  const handleRemove = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsRemoveModalOpened(true);
  };

  return (
    <CardWrapper
      id={`roleplay-scenario-${id}`}
      onMouseOver={() => setHovered(true)}
      onMouseOut={() => setHovered(false)}
    >
      <RemoveModal
        opened={isRemoveModalOpened}
        onHide={() => setIsRemoveModalOpened(false)}
        onDelete={() => {
          dispatch(RoleplayActionCreators.removeScenario(categoryId, id));
        }}
      />
      <CardDragHandler dndProps={dndProps} visible={hovered} />
      <CardDataWrapper
        to={getUrlToNavigate(params, id, type)}
        $active={dndProps?.snapshot?.isDragging || id === newElementId}
        $hovered={hovered}
      >
        <CardPublishingStatus hasPendingChanges={!!changeStatus?.hasPendingChanges} ready={!!ready} />
        <DataWrapper>
          {image ? (
            <ScenarioImage src={image} />
          ) : (
            <ScenarioImagePlaceholder>
              <ImageGenericIcon />
            </ScenarioImagePlaceholder>
          )}
          <Data>
            <CardPendingStatus content={content} />
            <Title title={scenarioTitle}>
              {ready && idx !== null ? `${idx + 1} ` : ''}
              {scenarioTitle}
              <CardValidationMark isInvalid={!validationStatus?.valid} />
            </Title>
            {!!cefr && <Description title={cefr}>{cefr.toUpperCase()}</Description>}
          </Data>
          <CardControllers>
            <PublishCardButton hovered={hovered} content={content} parentId={categoryId} />
            {showRemove && <RemoveCardButton hovered={hovered} content={content} onRemove={handleRemove} />}
          </CardControllers>
        </DataWrapper>
      </CardDataWrapper>
    </CardWrapper>
  );
};
