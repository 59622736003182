const SAVE = 'MATCHUP_RECOGNITION_EXERCISE_SAVE';
const SET_INSTRUCTIONS_LANGUAGE = 'MATCHUP_SET_INSTRUCTIONS_LANGUAGE';
const SET_MATCH_UP_OPTION = 'MATCHUP_SET_MATCH_UP_OPTION';
const SET_MATCHUP_ALL_FIELDS = 'SET_MATCHUP_ALL_FIELDS';

export const MatchUpExerciseActions = {
  SAVE,
  SET_INSTRUCTIONS_LANGUAGE,
  SET_MATCH_UP_OPTION,
  SET_MATCHUP_ALL_FIELDS,
} as const;
