import styled from 'styled-components';

const SwitcherContainer = styled.div`
  display: flex;
  align-items: center;
`;

const SwitcherText = styled.span`
  font-size: 14px;
  margin-left: 4px;
  color: ${(props) => props.theme.color.brandGreyscale800};
`;

const SwitcherBlock = styled.div<{ switched: boolean }>`
  width: 36px;
  height: 22px;
  border-radius: 16px;
  background: ${({ switched, theme }) => (switched ? theme.color.primaryLighter : theme.color.brandGreyscale600)};
  position: relative;
  transition: all 0.3s linear;

  &:hover {
    cursor: pointer;
  }
`;

const SwitcherDot = styled.div<{ switched: boolean }>`
  width: 20px;
  height: 20px;
  background: ${({ theme }) => theme.color.brandWhite};
  position: absolute;
  top: 1px;
  left: ${({ switched }) => (switched ? 'unset' : '2px')};
  ${({ switched }) => switched && 'right: 2px;'}
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  box-shadow:
    0px 3px 1px rgba(0, 0, 0, 0.0510643),
    0px 2px 2px rgba(0, 0, 0, 0.1),
    0px 3px 3px rgba(0, 0, 0, 0.05);
`;

export type CommonSwitcherProps = {
  className?: string;
  onChange: (value: boolean) => void;
  text: string;
  value: boolean;
  id?: string;
  guidelineHelp?: JSX.Element;
};

export const CommonSwitcher = ({
  className,
  onChange,
  text,
  guidelineHelp,
  value,
  id = 'switcher',
}: CommonSwitcherProps) => (
  <SwitcherContainer className={className} data-testid="common-switcher-container">
    <SwitcherBlock id={id} switched={!!value} onClick={() => onChange(!value)} data-testid="common-switcher">
      <SwitcherDot switched={!!value} />
    </SwitcherBlock>
    <SwitcherText>{text}</SwitcherText>
    {guidelineHelp}
  </SwitcherContainer>
);
