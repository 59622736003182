import styled from 'styled-components';
import { defaultTheme } from '@features/theme';

const OneRecentPreloader = ({ index }: { index: number }) => (
  <g filter={`url(#filterRecent${index}_i)`}>
    <rect width="807" height="160" fill="white" />
    <rect x={index * 210} y="5" width="170" height="105" rx="4" fill={defaultTheme.color.brandGreyscale500} />
    <rect x={index * 210} y="115" width="90" height="15" rx="4" fill={defaultTheme.color.brandGreyscale500} />
    <rect x={index * 210} y="135" width="80" height="10" rx="4" fill={defaultTheme.color.brandGreyscale500} />
  </g>
);

const OneRecentPreloaderFilter = ({ index }: { index: number }) => (
  <filter id={`filterRecent${index}_i`} x={index * 210} y="0" width="807" height="160" filterUnits="userSpaceOnUse">
    <feFlood result="BackgroundImageFix" />
    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
    <feColorMatrix
      in="SourceAlpha"
      type="matrix"
      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
      result="hardAlpha"
    />
    <feOffset dy="-1" />
    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
    <feColorMatrix type="matrix" values="0 0 0 0 0.839216 0 0 0 0 0.870588 0 0 0 0 0.901961 0 0 0 1 0" />
    <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
  </filter>
);

const RecentPreloaderWrapper = styled.div`
  margin: 0 8px;
`;

export const RecentPreloader = () => {
  const helperArray = Array(4).fill(null);

  return (
    <RecentPreloaderWrapper>
      <svg className="courses-table-preloader" viewBox="0 0 807 158" fill="none">
        {helperArray.map((_, index) => (
          <OneRecentPreloader key={index} index={index} />
        ))}
        <defs>
          {helperArray.map((_, index) => (
            <OneRecentPreloaderFilter key={index} index={index} />
          ))}
        </defs>
      </svg>
    </RecentPreloaderWrapper>
  );
};
