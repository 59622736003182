import styled from 'styled-components/macro';
import ReactTooltip from 'react-tooltip';

import { DBId } from '@common/types/DBId';
import { Flag, FlagTextPositionMode } from '@components/Flag';
import { CourseInfoInterface } from '@common/interfaces/courses/CourseInfoInterface';
import { useAppSelector } from '@redux/store';
import { selectCoursesInfo } from '@selectors/CoursesSelectors';
import { Language } from '@features/content/languages';

const Locations = styled.div`
  align-items: flex-start;
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  gap: 0.4rem;
`;

const Tag = styled.div`
  background-color: ${({ theme }) => theme.colorV2.uiBackgroundSecondary};
  border-radius: 1.6rem;
  color: ${({ theme }) => theme.colorV2.accentPrimary};
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.4rem;
  padding: 0.2rem 0.8rem;
  text-transform: uppercase;
`;

export const CardLocations = ({
  courses,
  locationsCount,
  id,
  isPopular = false,
  isDefaultInstruction = false,
  onExpand,
}: {
  courses: DBId[];
  isPopular?: boolean;
  isDefaultInstruction?: boolean;
  id?: DBId;
  locationsCount: number;
  onExpand: () => void;
}) => {
  const coursesInfo = useAppSelector(selectCoursesInfo);
  const countries: Language[] = [];

  for (const course of courses) {
    const courseInState = coursesInfo.courses.find((_course: CourseInfoInterface) => _course.id === course);

    if (courseInState && !countries.includes(courseInState.learningLanguage)) {
      countries.push(courseInState.learningLanguage);
    }
  }

  return (
    <Locations
      onClick={(e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        onExpand();
      }}
    >
      {isPopular ? (
        <>
          <ReactTooltip id={`popular-${id}-tooltip`} place="top" effect="solid" />
          <Tag data-tip={`Used in ${locationsCount} locations`} data-for={`popular-${id}-tooltip`}>
            Popular
          </Tag>
        </>
      ) : (
        <>
          {isDefaultInstruction ? (
            <Tag>Default instruction</Tag>
          ) : (
            <>
              {!!countries.length && (
                <Flag countries={countries} mode={FlagTextPositionMode.withoutText} tooltipEnabled={false} />
              )}
              {locationsCount > 0 && `Used in ${locationsCount} location${locationsCount > 1 ? 's' : ''}`}
            </>
          )}
        </>
      )}
    </Locations>
  );
};
