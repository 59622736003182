import { Formik } from 'formik';
import { useEffect } from 'react';
import { useParams, withRouter } from 'react-router-dom';

import { RoleplayActionCreators } from '@actionCreators/RoleplayActionsCreators';
import { ContentTypes } from '@common/enums/ContentTypes';
import { LoadingStage } from '@common/enums/LoadingStage';
import { DBId } from '@common/types/DBId';
import { ContentOwner } from '@features/content/contentOwnership';
import { LANGUAGE_NAMES_V2, mapLanguageV2ToLanguage, type LanguageV2 } from '@features/content/languages';
import { SearchModal } from '@features/search';
import { Loader } from '@features/theme';
import { processLocalizationsForFormikValues } from '@helpers/formikInitialValuesHelper';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { selectRoleplayScenario } from '@selectors/roleplaySelectors';

import { TranslationsPanel } from '../TranslationsPanel';
import { Header } from '../Header';
import { BreadcrumbsAndOwnershipWrapper } from '../styles';
import { Breadcrumbs } from './Breadcrumbs';
import { RoleplayScenarioData } from './RoleplayScenarioData';
import { DEFAULT_CEFR_LEVEL, MESSAGE_LIMIT } from './constants';
import { selectRoleplayTranslationsPanel } from '@selectors/UiSelectors';

export const RoleplayScenario = () => {
  const dispatch = useAppDispatch();
  const { languageId, categoryId, scenarioId } = useParams<{
    languageId: LanguageV2;
    categoryId: DBId;
    scenarioId: DBId;
  }>();

  const scenario = useAppSelector(selectRoleplayScenario);
  const scenarioContent = scenario.content;
  const roleplayTranslationsPanel = useAppSelector(selectRoleplayTranslationsPanel);

  useEffect(() => {
    dispatch(RoleplayActionCreators.setInitialScenario());
  }, [dispatch, scenarioId]);

  useEffect(() => {
    if (scenario.loaded === LoadingStage.notLoaded && scenarioId) {
      dispatch(RoleplayActionCreators.getScenario(scenarioId));
    }
  }, [dispatch, scenario.loaded, scenarioId]);

  const getInitialValuesForScenarioContent = () => {
    return {
      // Fields with localization
      title: processLocalizationsForFormikValues(scenarioContent?.title?.textLocalizations),
      titleContext: scenarioContent?.title?.description || '',
      titleChanged: false,
      description: processLocalizationsForFormikValues(scenarioContent?.description?.textLocalizations),
      descriptionContext: scenarioContent?.description?.description || '',
      descriptionChanged: false,
      goal: processLocalizationsForFormikValues(scenarioContent?.goal?.textLocalizations),
      goalContext: scenarioContent?.goal?.description || '',
      goalChanged: false,
      triggerMessage1: processLocalizationsForFormikValues(scenarioContent?.triggerMessage1?.textLocalizations),
      triggerMessage1Context: scenarioContent?.triggerMessage1?.description || '',
      triggerMessage1Changed: false,
      // Fields without localization
      cefr: scenarioContent?.cefr || DEFAULT_CEFR_LEVEL,
      cefrChanged: false,
      chatbotGoal: scenarioContent?.chatbotGoal || '',
      chatbotGoalChanged: false,
      chatbotRole: scenarioContent?.chatbotRole || '',
      chatbotRoleChanged: false,
      chatbotScenario: scenarioContent?.chatbotScenario || '',
      chatbotScenarioChanged: false,
      grammar: scenarioContent?.grammar || '',
      grammarChanged: false,
      messageLimit: scenarioContent?.messageLimit || MESSAGE_LIMIT.MAX,
      messageLimitChanged: false,
      sampleDialogue: scenarioContent?.sampleDialogue || '',
      sampleDialogueChanged: false,
      triggerMessagesChanged: false,
      vocabulary: scenarioContent?.vocabulary || '',
      vocabularyChanged: false,
      voiceAccent: scenarioContent?.voiceAccent,
      voiceGender: scenarioContent?.voiceGender,
    };
  };

  const contentName = `${LANGUAGE_NAMES_V2[languageId]} Roleplay`;

  if (scenario.loaded === LoadingStage.loading) {
    return (
      <div className="course-edition__course-data-composer">
        {languageId && <Header contentId={scenarioId} contentName={contentName} learningLanguage={languageId} />}
        <Loader size="L" />
      </div>
    );
  }

  if (scenario.loaded === LoadingStage.loaded) {
    return (
      <Formik initialValues={getInitialValuesForScenarioContent()} onSubmit={() => {}}>
        <>
          <div className="course-edition__course-data-composer">
            {roleplayTranslationsPanel.visible && (
              <TranslationsPanel content={scenarioContent} learningLanguage={mapLanguageV2ToLanguage(languageId)} />
            )}
            <SearchModal />
            {languageId && <Header contentId={scenarioId} contentName={contentName} learningLanguage={languageId} />}
            <BreadcrumbsAndOwnershipWrapper>
              <Breadcrumbs />
              <ContentOwner
                childComponentId={scenarioContent.id}
                contentType={ContentTypes.roleplayScenario}
                componentId={categoryId}
                dates={scenarioContent.dates}
                owner={scenarioContent.owner}
              />
            </BreadcrumbsAndOwnershipWrapper>
            <RoleplayScenarioData />
          </div>
        </>
      </Formik>
    );
  }

  return null;
};

export const RoleplayScenarioContent = withRouter(RoleplayScenario);
