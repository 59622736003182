import styled, { css } from 'styled-components/macro';

import { NAVIGATION_PANEL_WIDTH } from '@features/content/navigation';

/** Global layout styled components */

/**
 * replaces legacy 'course-edition'
 */

export const MainWrapper = styled.div<{ fullWidth?: boolean }>`
  ${({ fullWidth }) => css`
    align-items: flex-start;
    display: flex;
    flex-direction: ${fullWidth ? 'column' : 'row'};
    flex-wrap: nowrap;
    justify-content: flex-start;
    position: relative;
    width: 100%;
  `}
`;

/**
 * replaces legacy 'course-edition__course-data-composer'
 */
export const EditionComposerWrapper = styled.div<{ fullWidth?: boolean }>`
  ${({ fullWidth, theme }) => css`
    background: ${theme.colorV2.uiBackgroundPrimary};
    left: ${fullWidth ? 0 : NAVIGATION_PANEL_WIDTH};
    margin-top: ${fullWidth ? '0' : '10.5rem'};
    padding: 0 ${fullWidth ? '4rem' : '6rem'};
    padding-bottom: 5rem;
    position: relative;
    width: calc(100% - ${fullWidth ? '0rem' : NAVIGATION_PANEL_WIDTH});
  `}
`;
