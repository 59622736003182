import { FunctionComponent, useMemo, useCallback } from 'react';
import classnames from 'classnames';
import { useFormikContext } from 'formik';

import { FormikValuesInterface } from '@helpers/formikInitialValuesHelper';
import { KeyboardShortcut } from './common';
import { MatchupContentProps } from '../../PreviewModalProps';
import { getValueByLanguage } from '../../../../localization-utils';

import './MatchupContent.scss';

export const MatchupContent: FunctionComponent<MatchupContentProps> = ({
  content,
  deviceType,
  answerState,
  courseLearningLanguage,
  isForList,
}: MatchupContentProps) => {
  const { values }: { values: FormikValuesInterface } = useFormikContext();
  const isCorrectSelected = answerState === 1;
  const isIncorrectSelected = answerState === 2;

  const firstCardFixedText = useMemo(
    () =>
      getValueByLanguage(
        isForList ? content.fixedItem1?.phrase?.textLocalizations : values.fixedItem1_phrase,
        courseLearningLanguage,
      ),
    [values, courseLearningLanguage, isForList, content.fixedItem1],
  );

  const secondCardFixedText = useMemo(
    () =>
      getValueByLanguage(
        isForList ? content.fixedItem2?.phrase?.textLocalizations : values.fixedItem2_phrase,
        courseLearningLanguage,
      ),
    [values, courseLearningLanguage, isForList, content.fixedItem2],
  );

  const thirdCardFixedText = useMemo(
    () =>
      getValueByLanguage(
        isForList ? content.fixedItem3?.phrase?.textLocalizations : values.fixedItem3_phrase,
        courseLearningLanguage,
      ),
    [values, courseLearningLanguage, isForList, content.fixedItem3],
  );

  const firstCardAnswerTextOnCourseLang = useMemo(
    () =>
      getValueByLanguage(
        isForList ? content.matchingItem1?.phrase?.textLocalizations : values.matchingItem1_phrase,
        courseLearningLanguage,
      ),
    [values, courseLearningLanguage, isForList, content.matchingItem1],
  );

  const secondCardAnswerTextOnCourseLang = useMemo(
    () =>
      getValueByLanguage(
        isForList ? content.matchingItem2?.phrase?.textLocalizations : values.matchingItem2_phrase,
        courseLearningLanguage,
      ),
    [values, courseLearningLanguage, isForList, content.matchingItem2],
  );

  const thirdCardAnswerTextOnCourseLang = useMemo(
    () =>
      getValueByLanguage(
        isForList ? content.matchingItem3?.phrase?.textLocalizations : values.matchingItem3_phrase,
        courseLearningLanguage,
      ),
    [values, courseLearningLanguage, isForList, content.matchingItem3],
  );

  const firstCardAnswerText = useMemo(
    () =>
      getValueByLanguage(isForList ? content.matchingItem1?.phrase?.textLocalizations : values.matchingItem1_phrase),
    [values, isForList, content.matchingItem1],
  );

  const secondCardAnswerText = useMemo(
    () =>
      getValueByLanguage(isForList ? content.matchingItem2?.phrase?.textLocalizations : values.matchingItem2_phrase),
    [values, isForList, content.matchingItem2],
  );

  const thirdCardAnswerText = useMemo(
    () =>
      getValueByLanguage(isForList ? content.matchingItem3?.phrase?.textLocalizations : values.matchingItem3_phrase),
    [values, isForList, content.matchingItem3],
  );

  const isThirdPairActive = useMemo(
    () =>
      (isForList
        ? !!content.fixedItem3?.phrase?.textLocalizations?.filter((loc) => loc.value).length
        : !!values.fixedItem3_phrase?.filter((loc) => loc.value).length) ||
      (isForList
        ? !!content.matchingItem3?.phrase?.textLocalizations?.filter((loc) => loc.value).length
        : !!values.matchingItem3_phrase?.filter((loc) => loc.value).length),
    [values, isForList, content.fixedItem3, content.matchingItem3],
  );

  const renderFixedCard = useCallback(
    (fixedText: string, correctText: string, incorrectText: string) => {
      return (
        <div className="matchup-fixed-card">
          <div className="matchup-fixed-card__text">{fixedText}</div>
          {answerState ? (
            <div
              className={classnames(
                'matchup-variant-card',
                { 'matchup-variant-card--correct': isCorrectSelected },
                { 'matchup-variant-card--incorrect': isIncorrectSelected },
              )}
            >
              <div className="matchup-variant-card__area">{isCorrectSelected ? correctText : incorrectText}</div>
            </div>
          ) : (
            <svg className="matchup-fixed-card__area">
              <rect rx="8px" ry="8px" width="100%" height="100%"></rect>
            </svg>
          )}
        </div>
      );
    },
    [answerState, isCorrectSelected, isIncorrectSelected],
  );

  const renderVariantCard = useCallback(
    (variantTextOnCourseLang: string, variantText: string, index: number) => (
      <>
        {answerState ? (
          <div className="matchup-variant-card--empty" />
        ) : (
          <div className="matchup-variant-card">
            <div className="matchup-variant-card__area">
              {content.matchingItemsLanguage === 'learning' ? variantTextOnCourseLang : variantText}
            </div>
            <KeyboardShortcut answerState={answerState} value={index} />
          </div>
        )}
      </>
    ),
    [answerState, content.matchingItemsLanguage],
  );

  return (
    <>
      <div
        className={classnames(
          'matchup-cards',
          { 'matchup-cards--for-two-pairs': !isThirdPairActive },
          'matchup-cards--for-fixed',
          `matchup-cards--${deviceType}`,
        )}
      >
        {renderFixedCard(
          firstCardFixedText,
          content.matchingItemsLanguage === 'learning' ? firstCardAnswerTextOnCourseLang : firstCardAnswerText,
          content.matchingItemsLanguage === 'learning'
            ? isThirdPairActive
              ? thirdCardAnswerTextOnCourseLang
              : secondCardAnswerTextOnCourseLang
            : isThirdPairActive
            ? thirdCardAnswerText
            : secondCardAnswerText,
        )}
        {renderFixedCard(
          secondCardFixedText,
          content.matchingItemsLanguage === 'learning' ? secondCardAnswerTextOnCourseLang : secondCardAnswerText,
          content.matchingItemsLanguage === 'learning' ? firstCardAnswerTextOnCourseLang : firstCardAnswerText,
        )}
        {isThirdPairActive &&
          renderFixedCard(
            thirdCardFixedText,
            content.matchingItemsLanguage === 'learning' ? thirdCardAnswerTextOnCourseLang : thirdCardAnswerText,
            content.matchingItemsLanguage === 'learning' ? secondCardAnswerTextOnCourseLang : secondCardAnswerText,
          )}
      </div>

      <div
        className={classnames(
          'matchup-cards',
          { 'matchup-cards--for-two-pairs': !isThirdPairActive },
          'matchup-cards--for-variants',
          `matchup-cards--${deviceType}`,
        )}
      >
        {renderVariantCard(
          isThirdPairActive ? thirdCardAnswerTextOnCourseLang : secondCardAnswerTextOnCourseLang,
          isThirdPairActive ? thirdCardAnswerText : secondCardAnswerText,
          1,
        )}
        {renderVariantCard(firstCardAnswerTextOnCourseLang, firstCardAnswerText, 2)}
        {isThirdPairActive && renderVariantCard(secondCardAnswerTextOnCourseLang, secondCardAnswerText, 3)}
      </div>
    </>
  );
};
