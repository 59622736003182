import { ReactComponent as WithAudioIcon } from '../_img/withAudio.svg';
import { ReactComponent as WithoutAudioIcon } from '../_img/withoutAudio.svg';

export const LexicalItemAudioLabel = ({ hasAudio }: { hasAudio: boolean }) => (
  <>
    {hasAudio ? (
      <>
        <WithAudioIcon />
        &nbsp;Yes
      </>
    ) : (
      <>
        <WithoutAudioIcon />
        &nbsp;No
      </>
    )}
  </>
);
