import styled from 'styled-components';

import { Language } from '@features/content/languages';
import { LanguagesDisplayer } from './LanguagesDisplayer';

type ContentDataProps = {
  contentName: string;
  learningLanguage: Language;
  interfaceLanguages: Language[];
  onInterfaceLanguagesClick?: () => void;
};

const ContentDataWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const ContentTitle = styled.h2`
  font-weight: 700;
  font-size: 1.4rem;
`;

export const ContentData = ({
  contentName,
  learningLanguage,
  interfaceLanguages,
  onInterfaceLanguagesClick,
}: ContentDataProps) => (
  <ContentDataWrapper>
    <ContentTitle>{contentName}</ContentTitle>
    {!!interfaceLanguages.length && (
      <LanguagesDisplayer
        learningLanguage={learningLanguage}
        interfaceLanguages={interfaceLanguages}
        onInterfaceLanguagesClick={onInterfaceLanguagesClick}
      />
    )}
  </ContentDataWrapper>
);
