import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useEffect, useState } from 'react';

import { SupportedValueTypes, createSerializer } from '../../utils/serializer';

type InitialValuePluginProps = {
  forceClear?: boolean;
  initialValue: string | undefined;
  valueType: SupportedValueTypes;
};

export const InitialValuePlugin = ({ forceClear = false, initialValue, valueType }: InitialValuePluginProps) => {
  const [done, setDone] = useState(false);
  const [editor] = useLexicalComposerContext();

  const createEmptyEditorState = () => {
    const emptyEditorState = editor.parseEditorState(
      '{"root":{"children":[{"children":[],"direction":null,"format":"","indent":0,"type":"paragraph","version":1}],"direction":null,"format":"","indent":0,"type":"root","version":1}}',
    );
    editor.setEditorState(emptyEditorState.clone(null));
  };

  useEffect(() => {
    if (typeof initialValue !== 'undefined' && !done) {
      if (initialValue.length) {
        const serializer = createSerializer(valueType, editor);

        serializer.deserialize(initialValue);
      } else {
        createEmptyEditorState();
      }
    }

    if (!forceClear) setDone(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor, initialValue]);

  return null;
};
