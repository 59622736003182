const EnglishPhonemeTableMap = [
  'i',
  'ɪ',
  'ʊ',
  'u',
  'eɪ',
  'ɛ',
  'ə',
  'ɜ:',
  'ɔ',
  'ɔɪ',
  'əʊ',
  'æ',
  'ʌ',
  'ɒ',
  'aɪ',
  'aʊ',
  'p',
  'b',
  't',
  'd',
  'tʃ',
  'dʒ',
  'k',
  'g',
  'f',
  'v',
  'θ',
  'ð',
  's',
  'z',
  'ʃ',
  'ʒ',
  'm',
  'n',
  'ŋ',
  'h',
  'l',
  'r',
  'w',
  'j',
];

export default EnglishPhonemeTableMap;
