import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

import { searchByString } from '../../service';
import { useSearch } from '../../../SearchContext';
import { ReactComponent as StringIcon } from '../../img/string.svg';
import { StringSearchResultItem, StringSearchResultType } from '../../SearchResultItem';
import { SearchResults } from '../../SearchResults';
import {
  DEFAULT_PAGINATION_PAGE,
  SearchHeader,
  SearchResultsWrapper,
  useFiltersState,
  useResultsHandler,
} from '../Common';
import { StringSearchResultTypeHeader } from '../../SearchResultTypeHeader';
import { useAppSelector } from '@redux/store';
import { selectCourses } from '@selectors/CoursesSelectors';
import { selectSearchPanel } from '@selectors/UiSelectors';
import { saveFavouriteSearch } from '../Common/favouriteSearches';
import { SEARCH_TYPE_STRING } from '../../../../types';
import type { CommonSearchComponentProps } from '../SearchContent';
import type { StringQueryParamsType } from '../../service/types';
import { StringSearchFilters } from './StringSearchFilters';

const defaultFilters: StringQueryParamsType = {
  languages: null,
  courses: null,
  translations: null,
  audios: null,
  hasKTags: null,
  isDefaultInstruction: null,
  isReused: null,
};

/**
 * This component is responsible for:
 * - Modal layout for String search
 * - All logic that is specific to String search
 * - Calling String search API
 * - Storing search filters
 * - Storing search results
 */
export const StringSearch = ({ trackSearch, onBack }: CommonSearchComponentProps) => {
  const availableCourses = useAppSelector(selectCourses);
  const { filtersPreset, query: predefinedQuery } = useAppSelector(selectSearchPanel);

  const { search, query, setQuery } = useSearch();

  const { results, count, clearResults, onSuccess, onError } = useResultsHandler<StringSearchResultType>();
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGINATION_PAGE);

  const filtersState = useFiltersState(defaultFilters, filtersPreset?.string || {});

  // @TODO Simplify this logic, rename predefinedQuery to initialQuery
  const [predefinedQuerySearched, setPredefinedQuerySearched] = useState(false);

  useEffect(() => {
    if (
      Boolean(
        filtersState.state.languages ||
          filtersState.state.courses ||
          filtersState.state.hasKTags ||
          filtersState.state.translations,
      ) &&
      predefinedQuery &&
      !predefinedQuerySearched
    ) {
      setQuery(predefinedQuery);

      if (query) {
        onPaginatedSearch(query, 1);
        setPredefinedQuerySearched(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersState.state, query, predefinedQuery, predefinedQuerySearched]);

  const onPaginatedSearch = (query: string | null, page: number) => {
    if (query) {
      search(() => searchByString(query, page, filtersState.state))
        .then(onSuccess)
        .catch(onError);

      trackSearch();
    } else {
      clearResults();
    }
  };

  const onFreshSearch = (query: string | null) => {
    setCurrentPage(DEFAULT_PAGINATION_PAGE);
    onPaginatedSearch(query, DEFAULT_PAGINATION_PAGE);
  };
  const onPageChange = (page: number) => {
    setCurrentPage(page);
    onPaginatedSearch(query, page);
  };

  return (
    <>
      <Modal.Header closeButton>
        <SearchHeader
          filtersArea={
            <StringSearchFilters
              filtersState={filtersState}
              availableCourses={availableCourses}
              stringFiltersPreset={filtersPreset?.string}
              onSaveFilters={async (name) => saveFavouriteSearch(name, SEARCH_TYPE_STRING, filtersState.state)}
            />
          }
          icon={<StringIcon />}
          title="String search"
          onBack={onBack}
          onSearch={onFreshSearch}
        />
      </Modal.Header>

      <Modal.Body>
        <SearchResultsWrapper onPageCount={results.length}>
          <SearchResults<StringSearchResultType>
            results={results}
            count={count}
            currentPage={currentPage}
            setCurrentPage={onPageChange}
            header={<StringSearchResultTypeHeader />}
            renderItem={(item) => <StringSearchResultItem content={item} key={item.id} />}
          />
        </SearchResultsWrapper>
      </Modal.Body>
    </>
  );
};
