import { type ExerciseTypesType } from '@common/enums/ExerciseTypes';
import { type ContentTypesForCreateContentType, type ContentTypesType } from '@common/enums/ContentTypes';
import { ContentTypesActions } from '@actions/ContentTypesActions';

import type { DBId } from '@common/types/DBId';
import type { GenericContentInterface } from '@common/interfaces/contentTypes/GenericContentInterface';
import type { ExerciseListItemType } from '@components/ContentTypes/ExerciseCard/types';
import type { ActivityType } from '@features/content/activities';
import type { GrammarCategoryListItemType, GrammarTopicListItemType } from '@features/content/grammar';
import type { EntrypointListItemType } from '@features/content/placementTest';
import type { GroupsService_updateLessonFocus } from '@services/types/GroupsServiceTypes';
import { LessonType } from '@common/enums/LessonTypes';

export type GroupActionCreators_saveCourseNameAndDescription = {
  courseId?: DBId;
  values: any;
};

export const ContentTypesActionsCreator = {
  changeActivityType: (id: DBId, newType: ActivityType) => ({
    type: ContentTypesActions.ACTIVITY_CHANGE_TYPE,
    payload: {
      id,
      type: newType,
    },
  }),
  changeLessonType: (id: DBId, newType: LessonType) => ({
    type: ContentTypesActions.CHANGE_LESSON_TYPE,
    payload: {
      lessonId: id,
      lessonType: newType,
    },
  }),
  onCreateContent: (
    courseId: DBId,
    type: ContentTypesType,
    typeParentOfContentToCreate: ContentTypesType,
    parentId: DBId,
    contentCategory: ActivityType | ExerciseTypesType | ContentTypesForCreateContentType | null,
    position: number,
  ) => ({
    type: ContentTypesActions.COURSE_CREATE_CONTENT,
    payload: {
      courseId,
      type,
      typeParentOfContentToCreate,
      parentId,
      contentCategory,
      position,
    },
  }),
  onLevelTypeChange: (id: DBId, levelTypeId: string) => ({
    type: ContentTypesActions.COURSE_UPDATE_LEVEL_TYPE,
    payload: {
      levelId: id,
      levelType: levelTypeId,
    },
  }),
  onLessonFocusChange: (payload: GroupsService_updateLessonFocus) => ({
    type: ContentTypesActions.COURSE_UPDATE_LESSON_FOCUS,
    payload,
  }),
  saveCourseNameAndDescription: (payload: GroupActionCreators_saveCourseNameAndDescription) => ({
    type: ContentTypesActions.COURSE_SAVE_NAME_AND_DESCRIPTION,
    payload,
  }),
  saveContentNameAndDescription: (payload: GroupActionCreators_saveCourseNameAndDescription) => ({
    type: ContentTypesActions.CONTENT_SAVE_NAME_AND_DESCRIPTION,
    payload,
  }),
  setOrder: (
    orderedItems: (GenericContentInterface | GrammarCategoryListItemType)[],
    sourceIndex: number,
    destinationIndex: number,
    parentType?: ContentTypesType,
    parentId?: DBId,
    courseId?: DBId,
  ) => ({
    type: ContentTypesActions.COURSE_UPDATE_CONTENTS_ORDER,
    payload: {
      orderedItems,
      sourceIndex,
      destinationIndex,
      parentType,
      parentId,
      courseId,
    },
  }),
  onCourseNavigationChange: (
    content:
      | GenericContentInterface
      | GrammarCategoryListItemType
      | GrammarTopicListItemType
      | ExerciseListItemType
      | EntrypointListItemType,
  ) => ({
    type: ContentTypesActions.COURSE_NAVIGATION_ITEM_SELECTED,
    payload: {
      id: content.id,
      type: content.type,
    },
  }),
  removeContent: (content: GenericContentInterface | ExerciseListItemType) => ({
    type: ContentTypesActions.REMOVE_CONTENT,
    payload: content,
  }),
  setSaveButtonState: (value: boolean) => ({
    type: ContentTypesActions.SET_SAVE_BUTTON_STATE,
    payload: value,
  }),
};
