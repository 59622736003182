import { Element } from 'hast';
import { RehypeRewriteOptions } from 'rehype-rewrite';

// Class name `task-list-item` set by remark-gfm plugin
const LIST_ITEM_SELECTOR = 'li.task-list-item';

const rewriteTaskListToActionItem: RehypeRewriteOptions['rewrite'] = (node: Element, index, parent) => {
  const children = node.children;

  if (children.length) {
    let actionItemId: string | undefined;
    let checkbox: Element | undefined;

    children.forEach((child: any) => {
      if (child.properties?.type === 'checkbox') {
        checkbox = child;
      } else if (child.value?.trim().length) {
        const regExpActionItemToken = /\[action:([\w-]+)\]\s*/;
        const match = child.value.match(regExpActionItemToken);

        if (match) {
          actionItemId = match[1];
          child.value = child.value.replace(regExpActionItemToken, '');
        }
      }
    });

    if (parent?.type === 'element' && parent?.properties) {
      parent.properties.className = ['action-item-list'];
    }

    if (node.properties) {
      node.properties.className = ['action-item'];
      node.properties.dataActionItemId = actionItemId;
    }

    if (checkbox?.properties) {
      checkbox.properties.disabled = false;
    }
  }
};

// Options to be passed to rehype-rewrite plugin
export const rehypeRewriteOptions: RehypeRewriteOptions = {
  selector: LIST_ITEM_SELECTOR,
  rewrite: rewriteTaskListToActionItem,
};
