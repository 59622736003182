import { Button, Modal } from 'react-bootstrap';
import styled from 'styled-components/macro';

const CertificateWarningModalWrapper = styled(Modal)`
  .modal-dialog {
    max-width: 50rem;
  }
  .modal-content {
    height: 36.4rem;
    border-radius: 1.6rem;
  }
`;
const CertificateWarningModalBody = styled(Modal.Body)`
  padding: 4rem;
`;
const ModalTitle = styled.div`
  color: ${({ theme }) => theme.colorV2.uiDarkest};
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 0.8rem;
  text-align: center;
`;
const ModalDescription = styled.div`
  color: ${(props) => props.theme.colorV2.textTertiary};
  font-size: 1.6rem;
  margin-bottom: 3.2rem;
  text-align: center;
`;
const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
const CancelButton = styled(Button)`
  color: ${({ theme }) => theme.colorV2.textTertiary};

  &:hover {
    color: ${({ theme }) => theme.colorV2.textTertiary};
    text-decoration: none;
  }
`;
const ConfirmButton = styled(Button)`
  border-radius: 3.2rem;
  margin-bottom: 0.8rem;
`;

type CertificateWarningModalProps = {
  opened: boolean;
  onConfirm: () => void;
  onClose: () => void;
};

export const CertificateWarningModal = ({ opened, onConfirm, onClose }: CertificateWarningModalProps) => {
  return (
    <CertificateWarningModalWrapper show={opened} centered onHide={onClose}>
      <CertificateWarningModalBody>
        <ModalTitle>Non-complete courses don't support certificates by default.</ModalTitle>
        <ModalDescription>
          You need to write to LX Habit squad, create a ticket and wait for its completion (
          <a href="https://busuucom.atlassian.net/browse/LXH" target="_blank" rel="noreferrer">
            LXH board in Jira
          </a>
          ) before publishing new certificates.
        </ModalDescription>
        <ButtonContainer>
          <ConfirmButton onClick={onConfirm}>Ok</ConfirmButton>
          <CancelButton variant="link" onClick={onClose}>
            Cancel
          </CancelButton>
        </ButtonContainer>
      </CertificateWarningModalBody>
    </CertificateWarningModalWrapper>
  );
};
