import styled from 'styled-components/macro';

export const MessageItem = styled.th`
  border: none !important;
  font-weight: 700 !important;
  font-size: 1.4rem !important;
  width: 100%;
  text-align: center;
  font-weight: 700;
  width: 100%;
  font-size: 2rem;
`;
