import { SectionActions } from '@actions/SectionActions';
import { ExerciseTypes } from '@common/enums/ExerciseTypes';
import { LoadingStage } from '@common/enums/LoadingStage';
import { LoadedMultipleChoiceExerciseInterface } from '@common/interfaces/exercises/LoadedExerciseInterface';
import { CourseSliceInterface } from '@common/interfaces/slices/CourseSliceInterface';
import ExerciseDataModelAssembler from '@components/Exercises/ExerciseDataModelAssembler';
import { updateTextField } from '@helpers/updateTextField';
import { MultipleChoiceExerciseActions } from '@actions/MultipleChoiceExerciseActions';
import MultipleChoiceExerciseInterface from '@components/Exercises/MultipleChoice/interfaces/MultipleChoiceExerciseInterface';
import { selectedGroupsOfParentInitialData } from '@redux/initialStates/courseInitialState';
import { PayloadAction } from '@reduxjs/toolkit';
import { clone } from '@helpers/clone';
import { removeAudioDataFromBundle } from './common/helpers';

const MultipleChoiceExerciseCourseReducers = {
  [SectionActions.COURSE_EXERCISE_MULTIPLE_CHOICE_REQUEST_SUCCEEDED]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    let clonedCourseSlice: CourseSliceInterface = clone(state);
    let { content }: MultipleChoiceExerciseInterface = payload;

    let exercise: MultipleChoiceExerciseInterface = {
      ...clonedCourseSlice.loadedExercise.exercise,
      content,
      type: ExerciseTypes.multipleChoice,
    };

    return {
      ...clonedCourseSlice,
      selectedGroupsOfParent: selectedGroupsOfParentInitialData,
      loadedExercise: {
        loaded: LoadingStage.loaded,
        exercise,
      },
    };
  },
  [MultipleChoiceExerciseActions.SET_INSTRUCTIONS_LANGUAGE]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    return ExerciseDataModelAssembler.updateCheckbox('instructionsLanguage', state, payload);
  },
  [MultipleChoiceExerciseActions.SET_CHOICES_LANGUAGE]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    return ExerciseDataModelAssembler.updateCheckbox('choicesLanguage', state, payload);
  },
  [MultipleChoiceExerciseActions.SET_IMAGES_ENABLED]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    let clonedState: CourseSliceInterface = clone(state);
    let loadedExercise: LoadedMultipleChoiceExerciseInterface =
      clonedState.loadedExercise as LoadedMultipleChoiceExerciseInterface;

    loadedExercise.exercise.content.choicesImagesEnabled = !loadedExercise.exercise.content.choicesImagesEnabled;
    loadedExercise.exercise.content.choicesImagesEnabledChanged = true;

    return {
      ...clonedState,
      loadedExercise,
    };
  },
  [MultipleChoiceExerciseActions.REMOVE_IMAGE]: (state: CourseSliceInterface): CourseSliceInterface => {
    return ExerciseDataModelAssembler.nullifyFieldInBundle({
      field: 'image',
      bundleName: 'answerBundle',
      state,
    });
  },
  [MultipleChoiceExerciseActions.REMOVE_DISTRACTOR1_IMAGE]: (state: CourseSliceInterface): CourseSliceInterface => {
    return ExerciseDataModelAssembler.nullifyFieldInBundle({
      field: 'image',
      bundleName: 'distractor1',
      state,
    });
  },
  [MultipleChoiceExerciseActions.REMOVE_DISTRACTOR2_IMAGE]: (state: CourseSliceInterface): CourseSliceInterface => {
    return ExerciseDataModelAssembler.nullifyFieldInBundle({
      field: 'image',
      bundleName: 'distractor2',
      state,
    });
  },
  [MultipleChoiceExerciseActions.REMOVE_AUDIO]: (state: CourseSliceInterface): CourseSliceInterface => {
    let clonedCourseSlice: CourseSliceInterface = clone(state);

    return removeAudioDataFromBundle(clonedCourseSlice, 'phrase', 'answerBundle', true);
  },
  [MultipleChoiceExerciseActions.SET_MULTIPLE_CHOICE_ALL_FIELDS]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    let clonedCourseSlice: CourseSliceInterface = clone(state);

    const { values } = payload;

    clonedCourseSlice = updateTextField(clonedCourseSlice, values, 'instructions');
    clonedCourseSlice = updateTextField(clonedCourseSlice, values, 'phrase', 'answerBundle');
    clonedCourseSlice = updateTextField(clonedCourseSlice, values, 'phrase', 'distractor1');
    clonedCourseSlice = updateTextField(clonedCourseSlice, values, 'phrase', 'distractor2');
    clonedCourseSlice = updateTextField(clonedCourseSlice, values, 'feedback');

    return clonedCourseSlice;
  },
};

export default MultipleChoiceExerciseCourseReducers;
