import { CommonActions } from '@actions/CommonActions';
import { type DisplayContentToUserModeType } from '@common/enums/DisplayContentToUserMode';
import { Language } from '@features/content/languages';
import { FormikValuesInterface } from '@helpers/formikInitialValuesHelper';
import { MatchUpExerciseActions } from '@actions/MatchUpExerciseActions';
import { MatchingItemsLanguageType } from '@components/Exercises/MatchUp/enums/MatchingItemsLanguageType';
import MatchUpExerciseInterface from '@components/Exercises/MatchUp/interfaces/MatchUpExerciseInterface';

export const MatchUpExerciseActionsCreator = {
  save: (exercise: MatchUpExerciseInterface, learningLanguage: Language, values: FormikValuesInterface) => ({
    type: MatchUpExerciseActions.SAVE,
    payload: {
      exercise,
      learningLanguage,
      values,
    },
  }),
  setInstructionsLanguage: (instructionsLanguage: DisplayContentToUserModeType) => ({
    type: MatchUpExerciseActions.SET_INSTRUCTIONS_LANGUAGE,
    payload: {
      instructionsLanguage,
    },
  }),
  removeThirdFixedItem: () => ({
    type: CommonActions.NULLIFY_FIELD,
    payload: {
      field: 'fixedItem3',
    },
  }),
  removeThirdMatchingItem: () => ({
    type: CommonActions.NULLIFY_FIELD,
    payload: {
      field: 'matchingItem3',
    },
  }),
  setMatchUpOption: (option: MatchingItemsLanguageType) => ({
    type: MatchUpExerciseActions.SET_MATCH_UP_OPTION,
    payload: {
      option,
    },
  }),
};
