import { SlidePdfExerciseActions } from '@actions/SlidePdfExerciseActions';
import SlidePdfExerciseInterface from '@components/Exercises/SlidePdf/interfaces/SlidePdfExerciseInterface';

export const SlidePdfExerciseActionsCreator = {
  save: (exercise: SlidePdfExerciseInterface) => ({
    type: SlidePdfExerciseActions.SAVE,
    payload: {
      exercise,
    },
  }),
  setPdfFile: (pdfFile: string) => ({
    type: SlidePdfExerciseActions.SET_PDF,
    payload: {
      pdfFile,
    },
  }),
  uploadPdfFile: (pdf: File) => ({
    type: SlidePdfExerciseActions.UPLOAD_PDF_FILE,
    payload: {
      pdf,
    },
  }),
};
