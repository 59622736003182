import { Button } from '@features/theme';
import addIcon from './add.svg';

export const NewCourseButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <Button onClick={onClick}>
      <img src={addIcon} className="plus" alt="Create new course" /> New
    </Button>
  );
};
