import 'draft-js/dist/Draft.css';
import { defaultTheme } from '@features/theme';

const KTagsCreatorHelpers = {
  countKTags(text: string) {
    const computedTotalKTags = text.match(/\[k\]/gi)?.length;

    return computedTotalKTags === undefined ? 0 : computedTotalKTags;
  },
  createKTagsFormat(localizationValue: string) {
    let parts: any = localizationValue.split(/(\[k\].*?\[\/k])/gi);

    for (let i = 0; i < parts.length; i++) {
      if (parts[i].indexOf('[k]') > -1 || parts[i].indexOf('[K]') > -1) {
        parts[i] = (
          <span className="translations-panel-modal__translation-text-ktag" key={i}>
            {parts[i].replace(/\[k\]/gi, '').replace(/\[\/k\]/gi, '')}
          </span>
        );
      } else {
        parts[i] = <span key={i}>{parts[i]}</span>;
      }
    }
    return parts;
  },
  getStyleMap(gapMode: boolean, highlighterMode: boolean) {
    let styleMap: any = {
      KTAG_GROUP: {
        padding: '.2rem .4rem',
        backgroundColor: defaultTheme.color.brandGreyscale500,
        borderRadius: '.4rem',
        border: `.1rem solid ${defaultTheme.color.brandGreyscale600}`,
        whiteSpace: 'nowrap',
        display: 'inline-block',
        marginBottom: '.5rem',
      },
    };

    styleMap['KTAG_GROUP2'] = { ...styleMap.KTAG_GROUP };

    if (highlighterMode) {
      styleMap.KTAG_GROUP.backgroundColor = defaultTheme.color.primaryLighter;
      styleMap.KTAG_GROUP2.backgroundColor = defaultTheme.color.primaryLighter;
    }

    const badBorder = `.1rem dashed ${defaultTheme.color.secondaryBase}`;

    const badTag = {
      border: badBorder,
      backgroundColor: defaultTheme.color.pink2,
    };

    styleMap['KTAG_BAD'] = {
      ...styleMap.KTAG_GROUP,
      ...badTag,
    };

    styleMap['KTAG_BAD2'] = {
      ...styleMap.KTAG_GROUP,
      ...badTag,
    };

    if (gapMode) {
      const gapBorder = `.1rem dashed ${defaultTheme.color.quaternaryDark}`;
      const gapBackgroundColor = defaultTheme.color.brandWhite;

      styleMap.KTAG_GROUP.backgroundColor = gapBackgroundColor;
      styleMap.KTAG_GROUP.border = gapBorder;

      // due to limitations in the Editor, if we want to create characters we have to provide 2 alternate KTAG_GROUP
      styleMap.KTAG_GROUP2.backgroundColor = gapBackgroundColor;
      styleMap.KTAG_GROUP2.border = gapBorder;
    }

    return styleMap;
  },
};

export default KTagsCreatorHelpers;
