import { Button, Container } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import ImageUploadDimensionDescriptors from '@common/enums/FileUploadDimensionDescriptors';
import { ImageUploadModes } from '@common/enums/FileUploadModes';
import { Sizes } from '@common/enums/Sizes';
import { CourseInterface } from '@common/interfaces/courses/CourseInterface';
import { ContentTypesActionsCreator } from '@actionCreators/ContentTypesActionsCreator';
import { ContentTypes } from '@common/enums/ContentTypes';
import { ImageUpload } from '@components/MediaUpload';
import ImageUploadUtils from '@components/MediaUpload/ImageUploadUtils';
import { CommonExerciseActionsCreator } from '@actionCreators/CommonExerciseActionsCreator';
import { ValidationErrorInterface } from '@common/interfaces/validation/ValidationInterface';
import { ValidationErrorDisplayer } from '@components/ValidationErrorDisplayer';
import { useAppDispatch, useAppSelector } from '@redux/store';
import helpersService from '@services/HelpersService';

import { CourseImageUploaderActionsCreator } from '@actionCreators/CourseImageUploaderActionsCreator';
import styled from 'styled-components/macro';
import { SidePanel, SidePanelBody, SidePanelHeader } from '@features/theme';
import { selectIsIssuesShown } from '@selectors/UiSelectors';
import { selectCourse } from '@selectors/CoursesSelectors';

const StyledSidePanel = styled(SidePanel)`
  min-width: 62rem !important;

  .course-image-uploader {
    &__image-block {
      margin-bottom: 4rem;
    }

    &__image-block-name {
      display: flex;

      font-size: 1.8rem;

      font-style: normal;
      font-weight: 500;
      line-height: 130%;

      margin-bottom: 1.6rem;
    }
  }

  .search-filters {
    align-items: flex-start;
    display: flex;
    justify-content: flex-start;
    margin: 0;
    padding: 0;
    flex-direction: column;
    margin-bottom: 2rem !important;
  }
`;

type CourseImageUploaderProps = {
  className?: string;
  close: () => void;
};

const CourseImageUploader = ({ className, close }: CourseImageUploaderProps) => {
  const formikProps = useFormikContext();
  const dispatch = useAppDispatch();
  const loadedCourse: CourseInterface = useAppSelector(selectCourse);
  const isIssuesShown = useAppSelector(selectIsIssuesShown);
  let paywallImageFromStore = loadedCourse.paywallImage;
  let thumbnailImageFromStore = loadedCourse.thumbnailImage;

  const thumbnailValidationErrors = loadedCourse?.validation?.errors.filter(
    (error: ValidationErrorInterface) => error.field === 'thumbnailImage',
  );
  const paywallValidationErrors = loadedCourse?.validation?.errors.filter(
    (error: ValidationErrorInterface) => error.field === 'paywallImage',
  );

  const showThumbnailValidationError = isIssuesShown && thumbnailValidationErrors?.length > 0;
  const showPaywallValidationError = isIssuesShown && paywallValidationErrors?.length > 0;

  return (
    <div className={`course-image-uploader ${className}`}>
      <StyledSidePanel
        onClose={close}
        header={<SidePanelHeader>Upload course images</SidePanelHeader>}
        body={
          <SidePanelBody>
            <Container className="search-filters">
              <div className="course-image-uploader__image-block">
                <div className="course-image-uploader__image-block-name">Course thumbnail image</div>

                <ImageUpload
                  text="(Course thumbnail images must be at least 500 pixels in width)"
                  width={ImageUploadDimensionDescriptors.courseImageUploader.thumbnail.width}
                  height={ImageUploadDimensionDescriptors.courseImageUploader.thumbnail.height}
                  size={Sizes['drag-drop-small']}
                  mode={ImageUploadModes['normal']}
                  onRemove={() => dispatch(CourseImageUploaderActionsCreator.removeImage('thumbnailImage'))}
                  imageData={ImageUploadUtils.getImageDataOutsideExercise(thumbnailImageFromStore)}
                  onProcessingFinished={(url: string) => {
                    dispatch(
                      CommonExerciseActionsCreator.setValueAfterProcessing({
                        url,
                        mediaType: 'image',
                        type: ContentTypes.course,
                        field: 'thumbnailImage',
                        language: 'EN',
                      }),
                    );
                  }}
                  onChange={(file: File | null, progressHandler: Function) => {
                    if (file !== null) {
                      dispatch(CourseImageUploaderActionsCreator.uploadThumbnail(file, progressHandler));
                    }
                  }}
                  previewMode={false}
                  fieldName="thumbnailImage"
                  errors={thumbnailValidationErrors}
                />
                {showThumbnailValidationError && (
                  <ValidationErrorDisplayer
                    text={helpersService.getValidationErrorMessageText(thumbnailValidationErrors)}
                  />
                )}
              </div>
              <div className="course-image-uploader__image-block">
                <div className="course-image-uploader__image-block-name">Course paywall image</div>

                <ImageUpload
                  text="(Course paywall images must be at least 1000 pixels in width)"
                  height={ImageUploadDimensionDescriptors.courseImageUploader.thumbnail.height}
                  width={ImageUploadDimensionDescriptors.courseImageUploader.thumbnail.width}
                  size={Sizes['drag-drop-large']}
                  mode={ImageUploadModes['normal']}
                  imageData={ImageUploadUtils.getImageDataOutsideExercise(paywallImageFromStore)}
                  onProcessingFinished={(url: string) => {
                    dispatch(
                      CommonExerciseActionsCreator.setValueAfterProcessing({
                        url,
                        mediaType: 'image',
                        type: ContentTypes.course,
                        field: 'paywallImage',
                        language: 'EN',
                      }),
                    );
                  }}
                  onChange={(file: File | null, progressHandler: Function) => {
                    if (file !== null) {
                      dispatch(CourseImageUploaderActionsCreator.uploadPaywall(file, progressHandler));
                    }
                  }}
                  onRemove={() => {
                    dispatch(CourseImageUploaderActionsCreator.removeImage('paywallImage'));
                  }}
                  errors={paywallValidationErrors}
                  previewMode={false}
                  fieldName="paywallImage"
                />
                {showPaywallValidationError && (
                  <ValidationErrorDisplayer
                    text={helpersService.getValidationErrorMessageText(paywallValidationErrors)}
                  />
                )}
              </div>

              <Button
                onClick={() => {
                  dispatch(
                    ContentTypesActionsCreator.saveCourseNameAndDescription({
                      courseId: loadedCourse.id,
                      values: formikProps.values,
                    }),
                  );
                  close();
                }}
                disabled={!loadedCourse.thumbnailImageChanged && !loadedCourse.paywallImageChanged}
              >
                Save
              </Button>
            </Container>
          </SidePanelBody>
        }
      />
    </div>
  );
};

export default CourseImageUploader;
