import { ContentTypes } from '@common/enums/ContentTypes';
import { type DisplayContentToUserModeType } from '@common/enums/DisplayContentToUserMode';
import { Language } from '@features/content/languages';
import { DBId } from '@common/types/DBId';
import { FormikValuesInterface } from '@helpers/formikInitialValuesHelper';
import { FlashcardExerciseActions } from '@actions/FlashcardExerciseActions';
import FlashcardExerciseInterface from '@components/Exercises/Flashcard/interfaces/FlashCardExerciseInterface';

export const FlashcardExerciseActionsCreator = {
  save: (exercise: FlashcardExerciseInterface, learningLanguage: Language, values: FormikValuesInterface) => ({
    type: FlashcardExerciseActions.SAVE,
    payload: {
      exercise,
      learningLanguage,
      values,
    },
  }),
  setInstructionsLanguage: (instructionsLanguage: DisplayContentToUserModeType) => ({
    type: FlashcardExerciseActions.SET_INSTRUCTIONS_LANGUAGE,
    payload: {
      instructionsLanguage,
    },
  }),
  setFlashcardImage: (exercise: FlashcardExerciseInterface, image: File, progressHandler: Function) => ({
    type: FlashcardExerciseActions.UPLOAD_IMAGE,
    payload: {
      exercise,
      image,
      exerciseType: ContentTypes.flashcard,
      progressHandler,
    },
  }),
  removeFlashcardImage: () => ({
    type: FlashcardExerciseActions.REMOVE_IMAGE,
  }),
  removeFlashcardAudio: ({ contentId, language }: { contentId: DBId | undefined; language: string }) => ({
    type: FlashcardExerciseActions.REMOVE_AUDIO,
    payload: { contentId, language },
  }),
  removeFlashcardExampleAudio: ({ contentId, language }: { contentId: DBId | undefined; language: string }) => ({
    type: FlashcardExerciseActions.REMOVE_EXAMPLE_AUDIO,
    payload: { contentId, language },
  }),
  setLearningExampleText: (value: string, language: string) => ({
    type: FlashcardExerciseActions.SET_LEARNING_EXAMPLE_TEXT,
    payload: {
      value,
      language,
    },
  }),
  setLearningWordTextAlt: (values: any, language: string) => ({
    type: FlashcardExerciseActions.SET_LEARNING_WORD_TEXT_ALT,
    payload: { values, language },
  }),
  removeExample: () => ({
    type: FlashcardExerciseActions.NULLIFY_EXAMPLE,
  }),
  removeVideo: () => ({
    type: FlashcardExerciseActions.REMOVE_VIDEO,
  }),
};
