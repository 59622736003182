import styled from 'styled-components/macro';

import { Pagination } from '@features/theme';

const PaginationWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.space.L};
`;

type LexicalItemsPaginationProps = {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
};

export const LexicalItemsPagination = ({ currentPage, totalPages, onPageChange }: LexicalItemsPaginationProps) => (
  <PaginationWrapper>
    {totalPages > 1 && <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={onPageChange} />}
  </PaginationWrapper>
);
