import { CourseSliceInterface } from '@common/interfaces/slices/CourseSliceInterface';
import { CourseImageUploaderActions } from '@actions/CourseImageUploaderActions';
import { PayloadAction } from '@reduxjs/toolkit';
import { clone } from '@helpers/clone';

const ImageMediaUploadCourseReducers = {
  [CourseImageUploaderActions.REMOVE_IMAGE]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    let clonedCourseSlice: CourseSliceInterface = clone(state);
    const { branch } = payload;

    return {
      ...clonedCourseSlice,
      course: {
        ...clonedCourseSlice.course,
        [branch]: null,
        [`${branch}Changed`]: true,
      },
    };
  },
};

export default ImageMediaUploadCourseReducers;
