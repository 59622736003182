import { ContentTypes } from '@common/enums/ContentTypes';
import { ContentCreatorWidget, type ContentCreatorWidgetItemType } from '@features/theme';
import roleplayCategoryIcon from '@static/svg/contentCreatorWidget/grammarCategory.svg';

const ROLEPLAY_CATEGORY_CREATOR_ITEM: ContentCreatorWidgetItemType<typeof ContentTypes.roleplayCategory>[] = [
  {
    label: 'Roleplay Category',
    iconSrc: roleplayCategoryIcon,
    showByDefault: true,
    type: ContentTypes.roleplayCategory,
  },
];

type RoleplayCategoryCreatorProps = {
  isCreateInProgress?: boolean;
  isEditAvailable: boolean;
  isPreEnabled: boolean;
  position: number;
  onCreateContent: (itemType: typeof ContentTypes.roleplayCategory, itemCategory: null) => void;
};

export const RoleplayCategoryCreator = ({
  isCreateInProgress = false,
  isEditAvailable,
  isPreEnabled,
  position,
  onCreateContent,
}: RoleplayCategoryCreatorProps) => (
  <ContentCreatorWidget
    isCreateInProgress={isCreateInProgress}
    isEditAvailable={isEditAvailable}
    isPreEnabled={isPreEnabled}
    items={ROLEPLAY_CATEGORY_CREATOR_ITEM}
    position={position}
    title="Create a first roleplay category to get started"
    onItemSelected={(item) => onCreateContent(item.type, null)}
  />
);
