import { useEffect, useState } from 'react';
import { FormikContextType, useFormikContext } from 'formik';
import { FormikValueInterface, FormikValuesInterface } from '@helpers/formikInitialValuesHelper';
import { useAppSelector } from '@redux/store';
import { selectSearchPanelOpened, selectTranslationsPanelVisibleOrLevelLesson } from '@selectors/UiSelectors';

export const useIsEditorHidden = ({ bundleName, visitedBranch }: { bundleName?: string; visitedBranch: string }) => {
  const { values }: FormikContextType<FormikValuesInterface> = useFormikContext();
  const isSearchV2Opened = useAppSelector(selectSearchPanelOpened);
  const translationsPanelVisible = useAppSelector(selectTranslationsPanelVisibleOrLevelLesson);

  const [hideEditor, setHideEditor] = useState(false);
  const [wasSettedToEmptyBefore, setWasSettedToEmptyBefore] = useState(false);
  const fieldName = bundleName ? `${bundleName}_${visitedBranch}` : visitedBranch;

  const valuesFieldName = (values as any)[fieldName];
  const valuesFieldNameChanged = (values as any)[`${fieldName}Changed`];

  useEffect(() => {
    if (
      (valuesFieldName?.filter((loc: FormikValueInterface) => loc.value).length !== 0 && !valuesFieldNameChanged) ||
      (!wasSettedToEmptyBefore && valuesFieldName?.filter((loc: FormikValueInterface) => loc.value).length === 0)
    ) {
      if (valuesFieldName?.filter((loc: FormikValueInterface) => loc.value).length === 0) {
        setWasSettedToEmptyBefore(true);
      } else {
        setWasSettedToEmptyBefore(false);
      }
      setHideEditor(true);
    }
    if (valuesFieldName?.filter((loc: FormikValueInterface) => loc.value).length !== 0) {
      setWasSettedToEmptyBefore(false);
    }
  }, [valuesFieldName, valuesFieldNameChanged, wasSettedToEmptyBefore]);

  useEffect(() => {
    if (hideEditor) {
      setHideEditor(false);
    }
  }, [hideEditor]);

  return {
    isEditorHidden: hideEditor || isSearchV2Opened || translationsPanelVisible,
    isSearchV2Opened,
    translationsPanelVisible,
  };
};
