import { AppState } from '@redux/store';

export const selectCoursesInfo = ({ courses }: AppState) => courses.coursesInfo;
export const selectCourses = ({ courses }: AppState) => courses.coursesInfo.courses;
export const selectCourse = ({ courses }: AppState) => courses.course;
export const selectLoadingCourseParentId = ({ courses }: AppState) => courses.course.loadingParentId;
export const selectLearningLanguage = ({ courses }: AppState) => courses.course.learningLanguage;
export const selectInterfaceLanguages = ({ courses }: AppState) => courses.course.interfaceLanguages;

export const selectIsSaveButtonEnabled = ({ courses }: AppState) => courses.header.isSaveButtonEnabled;
export const selectIsSaveInProgress = ({ courses }: AppState) => courses.header.isSaveInProgress;

export const selectCourseStructure = ({ courses }: AppState) => courses.course?.structure;

export const selectSelectedGroupsOfParent = ({ courses }: AppState) => courses.selectedGroupsOfParent;
export const selectParentTypeOfSelectedGroupsOfParent = ({ courses }: AppState) =>
  courses.selectedGroupsOfParent.parentType;
export const selectGroupsOfSelectedGroupsOfParent = ({ courses }: AppState) => courses.selectedGroupsOfParent?.groups;
export const selectSelectedGroupsOfParentParentContents = ({ courses }: AppState) =>
  courses.selectedGroupsOfParent?.parentContents;
export const selectSelectedGroupsOfParentParentContentsLessonType = ({ courses }: AppState) =>
  courses.selectedGroupsOfParent?.parentContents?.lessonType;
export const selectSelectedGroupsOfParentParentContentsCategoryId = ({ courses }: AppState) =>
  courses.selectedGroupsOfParent?.parentContents?.categoryId;
export const selectSelectedGroupsOfParentParentContentsRoleplayScenario = ({ courses }: AppState) =>
  courses.selectedGroupsOfParent?.parentContents?.roleplayScenario;

export const selectCreatingContentPosition = ({ courses }: AppState) => courses.newContent.creatingContentPosition;
export const selectNewContentElementId = ({ courses }: AppState) => courses.newContent.newElementId;
export const selectNewContentIsDeleteInProgress = ({ courses }: AppState) => courses.newContent.isDeleteInProgress;

export const selectResourceBundleData = ({ courses }: AppState) =>
  courses.resourceBundleData.currentResourceBundle.value;
export const selectResourceBundleOtherPlaces = ({ courses }: AppState) => courses.resourceBundleData.bundleOtherPlaces;

export const selectPublishingStatus = ({ courses }: AppState) => courses.header.publishing;
export const selectIsPublishingInProgress = ({ courses }: AppState) => courses.header.publishing.inProgress;
export const selectPublishingPreparedContent = ({ courses }: AppState) => courses.header.publishing.preparedContent;
export const selectLoadingValidationResults = ({ courses }: AppState) => courses.header.validationResultsLoading;

export const selectLoadedExercises = ({ courses }: AppState) => courses.loadedExercises;
export const selectLoadedExercisesExercises = ({ courses }: AppState) => courses.loadedExercises?.exercises;

export const selectLoadedExercise = ({ courses }: AppState) => courses.loadedExercise;
export const selectLoadedExerciseLoaded = ({ courses }: AppState) => courses.loadedExercise.loaded;
export const selectLoadedExerciseData = ({ courses }: AppState) => courses.loadedExercise.exercise;
export const selectLoadedExerciseContent = ({ courses }: AppState) => courses.loadedExercise?.exercise?.content;
export const selectLoadedDialogueExerciseCharacters = ({ courses }: AppState) =>
  courses.loadedExercise.exercise.content.characters;
export const selectLoadedDialogueExerciseScript = ({ courses }: AppState) =>
  courses.loadedExercise.exercise.content.script;

export const selectStringReuseModal = ({ courses }: AppState) => courses.stringReuseModal;
export const selectStringReuseModalOpenedFor = ({ courses }: AppState) =>
  courses.stringReuseModal.stringReuseModalOpenedFor;

export const selectAudioUploadingFailed = ({ courses }: AppState) => courses.mediaUploading.audioUploadingFailed;
export const selectAudioUploadingInProgress = ({ courses }: AppState) => courses.mediaUploading.audioUploadingInProcess;
export const selectImageOrVideoUploadingFailed = ({ courses }: AppState) =>
  courses.mediaUploading.imageOrVideoUploadingFailed;
export const selectImageOrVideoUploadingInProgress = ({ courses }: AppState) =>
  courses.mediaUploading.imageOrVideoUploadingInProcess;

export const selectRecapOptions = ({ courses }: AppState) => courses.recapOptions;

export const selectIsPreviewExerciseLoaded = ({ courses }: AppState) => courses.preview.exerciseForPreviewLoaded;
export const selectPreviewExercise = ({ courses }: AppState) => courses.preview.exerciseForPreview;
