import styled from 'styled-components/macro';
import { Button } from '@features/theme';
import { useState } from 'react';
import { ReactComponent as GreenCheckIcon } from './img/green-check.svg';
import { ReactComponent as RedCrossIcon } from './img/red-cross.svg';
import { addToast } from '@features/app/toast';

const StyledContainer = styled.div`
  padding: 3rem;
  width: 50rem;
  box-shadow: 0 0.8rem 1.2rem rgba(0, 0, 0, 0.1);
  border-radius: 1.6rem;
  background: ${({ theme }) => theme.colorV2.uiBackgroundModal};

  display: flex;
  flex-direction: column;
  gap: 2.4rem;
`;

const LayoutWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const ExplanationWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.span`
  color: ${({ theme }) => theme.colorV2.textPrimary};
  font-size: 2rem;
  font-weight: 700;
  line-height: 130%;
  margin-bottom: 0.8rem;
`;

const Description = styled.div`
  color: ${({ theme }) => theme.colorV2.textSecondary};
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.8rem;
`;

const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.4rem;
  align-items: flex-start;
`;

const NameInput = styled.input`
  width: 100%;
  height: 4.8rem;
  padding: 0.8rem;
  line-height: 2.7rem;
  font-size: 1.8rem;
  border: 1px solid ${({ theme }) => theme.color.quaternaryDark};
  border-radius: 0.8rem;
`;

type SaveFiltersDialogProps = {
  onSave: (name: string) => Promise<void>;
  close: () => void;
  className?: string;
};

export const SaveFiltersDialog = ({ close, onSave, className }: SaveFiltersDialogProps) => {
  const [name, setName] = useState('');
  const [saving, setSaving] = useState(false);

  const save = async () => {
    setSaving(true);
    try {
      await onSave(name);

      addToast({
        type: 'success',
        title: 'Your search was successully saved',
      });
    } catch (e: any) {
      addToast({
        type: 'error',
        title: e.message,
      });
    } finally {
      setSaving(false);
      close();
    }
  };

  return (
    <StyledContainer className={className}>
      <LayoutWrapper>
        <ExplanationWrapper>
          <Title>Favourite Search</Title>
          <Description>
            Save the selected filters and use them in the future by selecting your favourite search
          </Description>
        </ExplanationWrapper>
        <ActionsWrapper>
          <Button collapsed size="S" icon={<RedCrossIcon />} variant="tertiary" onClick={!saving ? close : () => {}} />
          <Button
            collapsed
            size="S"
            icon={<GreenCheckIcon />}
            variant="tertiary"
            loading={saving}
            onClick={!saving ? save : () => {}}
          />
        </ActionsWrapper>
      </LayoutWrapper>
      <NameInput placeholder="Enter name" value={name} onChange={(e) => setName(e.target.value)} />
    </StyledContainer>
  );
};
