import styled from 'styled-components';

import { HeaderWrapper } from './HeaderWrapper';

const ExerciseHeaderWrapper = styled(HeaderWrapper)`
  grid-template-columns: 20% 36% 12% 26%;
`;

export const ExerciseSearchResultTypeHeader = () => (
  <ExerciseHeaderWrapper>
    <span>Exercise</span>
    <span>Phrase</span>
    <span>Media</span>
    <span>Location</span>
  </ExerciseHeaderWrapper>
);
