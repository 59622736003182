import { useState } from 'react';
import styled from 'styled-components/macro';

import { ReactComponent as PlusIcon } from './img/plus.svg';
import { ReactComponent as TrashIcon } from './img/trash.svg';

const CommonContainer = styled.div`
  display: flex;
  font-size: 1.4rem;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.8rem;
`;

const AddContainer = styled(CommonContainer)`
  color: #116eee;
  cursor: pointer;
`;

const RemoveIcon = styled(TrashIcon)`
  cursor: pointer;
`;

type HelpTogglerProps = {
  open?: boolean;
  text: string;
  children?: JSX.Element;
  onRemove?: () => void;
};

export const HelpToggler = ({ open = false, text, children, onRemove }: HelpTogglerProps) => {
  const [toggled, setToggled] = useState(open);

  return (
    <>
      {!toggled ? (
        <AddContainer onClick={() => setToggled(true)}>
          Add {text} <PlusIcon />
        </AddContainer>
      ) : (
        <>
          <CommonContainer>
            {text}
            <RemoveIcon
              onClick={() => {
                onRemove && onRemove();
                setToggled(false);
              }}
            />
          </CommonContainer>

          {children && children}
        </>
      )}
    </>
  );
};
