import React from 'react';
import { Pagination } from '@features/theme';
import { BottomPaginationContainer, ResultsListWrapper, ResultsTitle, ResultsTitleContainer } from './results.styles';

const DEFAULT_PAGE_SIZE = 10;

type SearchResultProps<T> = {
  results: T[];
  count: number;
  currentPage?: number;
  setCurrentPage?: (page: number) => void;
  renderItem: (item: T) => React.ReactNode;
  header: React.ReactNode;
};

export const SearchResults = <T extends unknown>({
  header,
  results,
  count,
  currentPage = 1,
  setCurrentPage,
  renderItem,
}: SearchResultProps<T>) => {
  const totalPages = count ? Math.ceil(count / DEFAULT_PAGE_SIZE) : 0;

  return (
    <>
      <ResultsTitleContainer>
        <ResultsTitle>
          {count} Result{count > 1 ? 's' : ''}
        </ResultsTitle>

        {totalPages > 1 && (
          <Pagination
            totalPages={totalPages}
            currentPage={currentPage}
            onPageChange={(page) => setCurrentPage && setCurrentPage(page)}
          />
        )}
      </ResultsTitleContainer>

      {header}

      <ResultsListWrapper data-testid="search-results-list">{results.map(renderItem)}</ResultsListWrapper>

      <BottomPaginationContainer>
        {totalPages > 1 && (
          <Pagination
            totalPages={totalPages}
            currentPage={currentPage}
            onPageChange={(page) => setCurrentPage && setCurrentPage(page)}
          />
        )}
      </BottomPaginationContainer>
    </>
  );
};
