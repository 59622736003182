import { ReactNode } from 'react';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components/macro';

import { Button } from '@features/theme';

const StyledModal = styled(Modal)`
  .modal-content {
    border: none;
    box-shadow: 0 0.8rem 1.2rem 0.1rem rgba(0, 0, 0, 0.1);
    border-radius: 1.6rem;
    min-height: 5rem;
  }
  .modal-body {
    padding: 4rem;
    display: flex;
    flex-direction: column;
    text-align: center;
  }
`;

const Title = styled.span`
  color: ${({ theme }) => theme.colorV2.textPrimary};
  font-size: 2rem;
  font-weight: 700;
  line-height: 130%;
  margin-bottom: 0.8rem;
`;

const Description = styled.div`
  color: ${({ theme }) => theme.colorV2.textSecondary};
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 150%;
`;

const ButtonArea = styled.div`
  margin-top: 3.2rem;
`;

type UserArchivalModalProps = {
  children?: ReactNode;
  opened: boolean;
  onArchive: () => void;
  onClose: () => void;
};

export const UserArchivalModal = ({ children = null, opened, onArchive, onClose }: UserArchivalModalProps) => {
  const onArchiveAndClose = () => {
    onArchive();
    onClose();
  };

  return (
    <StyledModal show={opened} centered onHide={onClose}>
      <Modal.Body>
        <Title>
          Are you sure you want to archive
          <br />
          this user?
        </Title>
        <Description>
          When you archive an user, you remove the contact from your user list but don't lose any data.
        </Description>
        {children}
        <ButtonArea>
          <Button fullWidth onClick={onArchiveAndClose}>
            Archive
          </Button>
          <Button fullWidth variant="text" onClick={onClose}>
            Cancel
          </Button>
        </ButtonArea>
      </Modal.Body>
    </StyledModal>
  );
};
