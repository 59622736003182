import { cloneDeep } from 'lodash';
import { useState } from 'react';
import Select, { MultiValueGenericProps, GroupProps, MultiValue, StylesConfig } from 'react-select';
import { useTheme } from 'styled-components';

import { Language, LANGUAGE_NAMES } from '@features/content/languages';
import { TRANSLATION_SELECTOR_GROUP } from './constants';

import {
  useSelectorCommonStyles,
  CustomDropdownIndicator,
  CustomClearIndicator,
} from '@components/SelectorCommonComponents';
import {
  getTranslationSelectorGroupStyles,
  getTranslationSelectorOptionStyles,
  TranslationSelectorGroup,
  TranslationSelectorGroupGroupHeading,
  TranslationSelectorMenuList,
  TranslationSelectorOption,
  TranslationSelectorValueContainer,
} from './TranslationSelectorCustomComponents';
import { CommonFilterOptionType, TranslationSelectorGroupType, TranslationSelectorGroupedOptions } from '../types';

const CLASSNAME_PREFIX = 'translation-filter-selector';

type TranslationFilterSelectorProps = {
  className?: string;
  placeholder?: string;
  rule: string;
  value: MultiValue<CommonFilterOptionType> | null;
  onChange: (option: MultiValue<CommonFilterOptionType> | null) => void;
};

const getOptions = (groupPrefix: TranslationSelectorGroupType) => {
  const options = Object.entries(LANGUAGE_NAMES).map(([key, value]) => ({
    label: value,
    value: key,
    isDisabled: false,
    type: groupPrefix,
  }));

  return options;
};

const DEFAULT_GROUPED_OPTIONS: TranslationSelectorGroupedOptions[] = [
  {
    label: TRANSLATION_SELECTOR_GROUP.WITH,
    options: getOptions(TRANSLATION_SELECTOR_GROUP.WITH),
  },
  {
    label: TRANSLATION_SELECTOR_GROUP.WITHOUT,
    options: getOptions(TRANSLATION_SELECTOR_GROUP.WITHOUT),
  },
];

export const TranslationSelector = ({
  className,
  placeholder,
  value,
  rule,
  onChange,
}: TranslationFilterSelectorProps) => {
  const [availableOptions, setAvailableOptions] =
    useState<TranslationSelectorGroupedOptions[]>(DEFAULT_GROUPED_OPTIONS);
  const [isAllSelected, setIsAllSelected] = useState<boolean>(false);
  const [selectedGroup, setSelectedGroup] = useState<TranslationSelectorGroupType | undefined>();

  const CommonSelectorStyles = useSelectorCommonStyles<CommonFilterOptionType>({ classNamePrefix: CLASSNAME_PREFIX });
  const theme = useTheme();

  const SelectorStyles: StylesConfig<CommonFilterOptionType> = {
    ...CommonSelectorStyles,
    clearIndicator: (defaultStyles, props) => ({
      ...defaultStyles,
      ...(CommonSelectorStyles.clearIndicator && CommonSelectorStyles.clearIndicator(defaultStyles, props)),
      paddingRight: props.selectProps.menuIsOpen ? '1.2rem' : 'inherit',
    }),
    dropdownIndicator: (defaultStyles, props) => ({
      ...defaultStyles,
      ...(CommonSelectorStyles.dropdownIndicator && CommonSelectorStyles.dropdownIndicator(defaultStyles, props)),
      display: props.hasValue ? 'none' : 'block',
    }),
    group: (defaultStyles, props) => getTranslationSelectorGroupStyles(defaultStyles, props, theme),
    option: (defaultStyles, props) => getTranslationSelectorOptionStyles(defaultStyles, props, theme),
    valueContainer: (defaultStyles) => ({
      ...defaultStyles,
      flexDirection: 'row',
    }),
  };

  const updateAvailableOptions = (newValue: MultiValue<CommonFilterOptionType>) => {
    const availableOptions = cloneDeep(DEFAULT_GROUPED_OPTIONS);
    const withLangs = newValue?.filter((value) => value.type === TRANSLATION_SELECTOR_GROUP.WITH);
    const withoutLangs = newValue?.filter((value) => value.type === TRANSLATION_SELECTOR_GROUP.WITHOUT);

    const getTargetOption = (groupLabel: TranslationSelectorGroupType, optionLabel: string) => {
      const targetGroup = availableOptions.find(({ label }) => label === groupLabel);
      const targetOption = targetGroup?.options.find(({ label }) => label === optionLabel);

      return targetOption;
    };

    withLangs.forEach(({ label: sourceOptionLabel }) => {
      const targetOption = getTargetOption(TRANSLATION_SELECTOR_GROUP.WITHOUT, sourceOptionLabel);

      if (targetOption) targetOption.isDisabled = true;
    });

    withoutLangs.forEach(({ label: sourceOptionLabel }) => {
      const targetOption = getTargetOption(TRANSLATION_SELECTOR_GROUP.WITH, sourceOptionLabel);

      if (targetOption) targetOption.isDisabled = true;
    });

    return availableOptions;
  };

  const handleOnChange = (newValue: MultiValue<CommonFilterOptionType>) => {
    setIsAllSelected(newValue?.length === Object.keys(LANGUAGE_NAMES).length);
    setAvailableOptions(() => updateAvailableOptions(newValue));
    onChange(newValue);
  };

  return (
    <Select<CommonFilterOptionType, true>
      className={className}
      classNamePrefix={CLASSNAME_PREFIX}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      isClearable
      isMulti
      isSearchable={false}
      styles={SelectorStyles}
      components={{
        ClearIndicator: CustomClearIndicator,
        DropdownIndicator: CustomDropdownIndicator,
        GroupHeading: TranslationSelectorGroupGroupHeading,
        Group: (props: GroupProps<CommonFilterOptionType>) => (
          <TranslationSelectorGroup
            {...props}
            isAllSelected={isAllSelected}
            selectedGroup={selectedGroup as TranslationSelectorGroupType}
            setIsAllSelected={setIsAllSelected}
            setSelectedGroup={setSelectedGroup}
          />
        ),
        MenuList: TranslationSelectorMenuList,
        Option: TranslationSelectorOption,
        MultiValueContainer: (props: MultiValueGenericProps<MultiValue<CommonFilterOptionType>>) => (
          <TranslationSelectorValueContainer {...props} rule={rule} />
        ),
      }}
      options={availableOptions}
      placeholder={placeholder}
      value={value}
      onChange={handleOnChange}
    />
  );
};

type SimpleTranslationSelectorProps = Omit<TranslationFilterSelectorProps, 'value' | 'onChange'> & {
  value: { with: Language[]; without: Language[] } | null;
  onChange: (value: { with: Language[]; without: Language[] } | null) => void;
};
export const SimpleTranslationSelector = ({ value, onChange, ...restProps }: SimpleTranslationSelectorProps) => {
  const options: CommonFilterOptionType[] | null = value
    ? [
        ...value.with.map((language) => ({ label: language, value: language, type: 'with' as const })),
        ...value.without.map((language) => ({ label: language, value: language, type: 'without' as const })),
      ]
    : null;

  return (
    <TranslationSelector
      value={options}
      onChange={(options) =>
        onChange(
          options?.length
            ? {
                with: options.filter((option) => option.type === 'with').map((option) => option.value as Language),
                without: options
                  .filter((option) => option.type === 'without')
                  .map((option) => option.value as Language),
              }
            : null,
        )
      }
      {...restProps}
    />
  );
};
