import { GrammarReviewButton } from '@features/content/grammar';
import { PlacementTestButton } from '@features/content/placementTest';
import { Navigation } from './Navigation';
import { Logo } from '@features/app/logo';
import {
  NavigationPanelFooter,
  NavigationPanelContainer,
  LogoAndNavigationContainer,
} from '@features/content/navigation';
import styled from 'styled-components/macro';
import { ReactComponent as CollapseIcon } from './_img/collapse.svg';
import { ReactComponent as ExpandIcon } from './_img/expand.svg';

const CollapseButton = styled.button`
  display: block;
  position: absolute;
  top: 9.6rem;
  left: 100%;

  width: 3.6rem;
  height: 3.6rem;
  background: ${({ theme }) => theme.colorV2.navigationBackground};
  border-radius: 0 0.8rem 0.8rem 0;
  border: 0;
`;

export const CourseNavigation = ({
  collapsed,
  setCollapsed,
}: {
  collapsed: boolean;
  setCollapsed: (callback: (state: boolean) => boolean) => void;
}) => {
  return (
    <NavigationPanelContainer $collapsed={collapsed}>
      <CollapseButton onClick={() => setCollapsed((collapsed) => !collapsed)}>
        {collapsed ? <ExpandIcon /> : <CollapseIcon />}
      </CollapseButton>
      <LogoAndNavigationContainer $collapsed={collapsed}>
        <Logo size="small" className="course-edition__logo" withLink />
        <GrammarReviewButton collapsed={collapsed} />
        <PlacementTestButton collapsed={collapsed} />
        {!collapsed && <Navigation />}
      </LogoAndNavigationContainer>
      <NavigationPanelFooter collapsed={collapsed} />
    </NavigationPanelContainer>
  );
};
