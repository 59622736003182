import { takeLatest } from 'redux-saga/effects';
import { ConversationExerciseActions } from '@actions/ConversationExerciseActions';
import MediaUploadSagas from '@sagas/mediaUpload/definitions/MediaUploadSagasDefinition';
import ConversationExerciseSagas from '@sagas/exercises/definitions/ConversationExerciseSagasDefinition';
import type { AppDispatch } from '@redux/store';

export function* ConversationExerciseRelatedSagas(dispatch: AppDispatch) {
  yield takeLatest(ConversationExerciseActions.SAVE, ConversationExerciseSagas.save, dispatch);
  yield takeLatest(ConversationExerciseActions.UPLOAD_IMAGE1, MediaUploadSagas.uploadImageToExercise);
  yield takeLatest(ConversationExerciseActions.UPLOAD_IMAGE2, MediaUploadSagas.uploadImageToExercise);
  yield takeLatest(ConversationExerciseActions.UPLOAD_IMAGE3, MediaUploadSagas.uploadImageToExercise);
}
