import { apiClient } from '@features/api';

import { ActivitySearchResultType } from '../SearchResultItem';

import type { ActivityQueryParamsType } from './types';

const getQueryParams = ({
  languages,
  courses,
  activityTypes,
  isReused,
  exerciseTypes,
  numExercises,
}: ActivityQueryParamsType) => {
  return {
    languages: languages || undefined,
    courses: courses || undefined,
    activityTypes: activityTypes || undefined,
    exerciseTypes: exerciseTypes || undefined,
    isReused: isReused !== null ? isReused : undefined,
    fromExercisesCount: typeof numExercises?.min === 'number' ? numExercises.min : undefined,
    toExercisesCount: typeof numExercises?.max === 'number' ? numExercises.max : undefined,
  };
};

export const searchByActivity = async (
  query: string,
  page: number,
  filtersParams: ActivityQueryParamsType,
): Promise<{ data: { results: ActivitySearchResultType[]; count: number } }> => {
  return await apiClient.v2.get('/search/activities', {
    params: {
      query,
      page,
      ...getQueryParams(filtersParams),
    },
  });
};
