import { createSlice, ActionCreator } from '@reduxjs/toolkit';
import { grammarReviewInitialState } from '@redux/initialStates/grammarReviewInitialState';
import { GrammarReducers } from '@redux/reducers/grammarSlice/GrammarReducers';

const extraReducers = {
  ...GrammarReducers,
} as Record<string, ActionCreator<any>>;

export const grammarReviewSlice = createSlice({
  name: 'grammar',
  initialState: grammarReviewInitialState,
  reducers: {},

  extraReducers: (builder) => {
    Object.keys(extraReducers).forEach((actionType) => {
      builder.addCase(actionType, extraReducers[actionType]);
    });
  },
});
