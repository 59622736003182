import styled from 'styled-components';

import { HeaderWrapper } from './HeaderWrapper';

const ActivityHeaderWrapper = styled(HeaderWrapper)`
  grid-template-columns: 74% 25%;
`;

export const ActivitySearchResultTypeHeader = () => (
  <ActivityHeaderWrapper>
    <span>Activity</span>
    <span>Location</span>
  </ActivityHeaderWrapper>
);
