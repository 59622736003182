import { CourseInfoInterface } from '@common/interfaces/courses/CourseInfoInterface';
import { OtherLocationsInterface } from '@common/interfaces/exercises/OtherLocationsInterface';
import { ContentTypes } from '@common/enums/ContentTypes';
import { useIsEditAvailableForCourse } from '@features/auth/roles';
import { useUser } from '@features/auth';
import { ReuseDataInterface } from '@common/interfaces/contentTypes/ReuseDataInterface';
import {
  AnyExerciseContentBranch,
  ContentBranches,
  CourseContentBranches,
} from '@common/types/AnyExerciseContentBranch';
import { useAppSelector } from '@redux/store';
import {
  selectCourses,
  selectLoadedExerciseData,
  selectSelectedGroupsOfParentParentContents,
  selectStringReuseModal,
} from '@selectors/CoursesSelectors';

const useContentReuseData = (fieldName?: AnyExerciseContentBranch | CourseContentBranches | ContentBranches | '') => {
  const currentReuseData: ReuseDataInterface = useAppSelector(selectStringReuseModal);
  const content = useAppSelector(selectSelectedGroupsOfParentParentContents);
  const exercise = useAppSelector(selectLoadedExerciseData);

  let contentData;
  if (exercise && !fieldName) {
    contentData = exercise.content;
  } else if (content && !fieldName) {
    contentData = content;
  }
  const isContentReused = fieldName
    ? currentReuseData && currentReuseData.otherPlaces.count > 1
    : contentData && contentData?.mappings > 1;
  const contentMappings = fieldName ? currentReuseData.otherPlaces.contentMappings : contentData?.mappingsPath;

  return { isContentReused, contentMappings };
};

const getCoursesWithoutPermissions = (
  mappings: OtherLocationsInterface[][],
  courses: CourseInfoInterface[],
  userLanguages: string[],
): string[] => {
  const reusedCoursesIds = mappings.map(
    (mappingPath) => mappingPath.find((mapping) => mapping.type === ContentTypes.course)?.id || '',
  );

  const reusedCoursesLanguages = reusedCoursesIds
    .filter((courseId) => courseId)
    .map((courseId) => courses.find((course) => course.id === courseId)?.learningLanguage || '');

  const coursesWithoutPermissions = reusedCoursesLanguages.filter(
    (language) => language && !userLanguages.includes(language),
  );

  return coursesWithoutPermissions;
};

const useUserAccessToEditContent = (
  fieldName?: AnyExerciseContentBranch | CourseContentBranches | ContentBranches | '',
) => {
  const isEditAvailableForCourse = useIsEditAvailableForCourse();
  const { isContentReused, contentMappings } = useContentReuseData(fieldName);

  const { user } = useUser();
  const courses = useAppSelector(selectCourses);

  let coursesWithoutPermissions: string[] = [];

  if (user && courses) {
    if (isContentReused && !user.languages.all) {
      coursesWithoutPermissions = getCoursesWithoutPermissions(contentMappings, courses, user.languages.list);

      return {
        isEditAvailable: isEditAvailableForCourse && !coursesWithoutPermissions.length,
        coursesWithoutPermissions,
      };
    }

    return { isEditAvailable: isEditAvailableForCourse, coursesWithoutPermissions };
  }

  return { isEditAvailable: false, coursesWithoutPermissions };
};

export const useIsEditAvailable = (
  fieldName?: AnyExerciseContentBranch | CourseContentBranches | ContentBranches | '',
) => {
  const { isEditAvailable, coursesWithoutPermissions } = useUserAccessToEditContent(fieldName);

  return { isEditAvailable, coursesWithoutPermissions };
};
