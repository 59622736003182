import React, { useCallback, useState } from 'react';
import styled from 'styled-components/macro';
import { Button } from '@features/theme';
import { ButtonArea, Description, Title } from './styles';

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

type CSATContentProps = {
  open: () => void;
  ignore: () => AsyncGenerator<number>;
};

export const CSATContent = ({ open, ignore }: CSATContentProps) => {
  const [waitFor, setWaitFor] = useState<number | null>(null);

  const onIgnore = useCallback(async () => {
    const generator = ignore();
    let state;

    while ((state = await generator.next())) {
      if (state.done) {
        break;
      }

      setWaitFor(state.value);
    }
  }, [ignore]);

  return (
    <ContentWrapper>
      <Title>Looks like you didn't complete the CSAT survey for a while :)</Title>
      <Description>Your feedback is important to improve Logos</Description>
      <ButtonArea>
        <Button onClick={open}>Open Survey</Button>
        {waitFor === null ? (
          <Button onClick={onIgnore}>Remind me tomorrow</Button>
        ) : (
          <Button onClick={() => {}}>Closing in {waitFor}s</Button>
        )}
      </ButtonArea>
    </ContentWrapper>
  );
};
