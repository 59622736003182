import classnames from 'classnames';
import exclamationIcon from './exclamation.svg';
import { ALL_LANGUAGES_UPPERCASE, LANGUAGE_SHORT_NAMES } from '@features/content/languages';
import './ValidationErrorDisplayer.scss';
import { selectIsIssuesShown } from '@selectors/UiSelectors';
import { useAppSelector } from '@redux/store';

type ErrorLabelProps = {
  type?: 'error' | 'warning';
  text?: string;
  hidden?: boolean;
};
export const ErrorLabel = ({ type = 'error', text = 'Validation error', hidden }: ErrorLabelProps) => {
  if (hidden) {
    return null;
  }

  return (
    <div
      className={classnames('validation-displayer', `validation-displayer--${type}`, {
        'validation-displayer--whole-width': !hidden,
      })}
    >
      <img src={exclamationIcon} alt="" className="validation-displayer__icon" />
      <span>{text}</span>
    </div>
  );
};

type ValidationErrorDisplayerProps = {
  type?: 'error' | 'warning';
  text?: string;
  visible?: boolean;
};
export const ValidationErrorDisplayer = ({ type = 'error', text, visible = false }: ValidationErrorDisplayerProps) => {
  const isIssuesShown = useAppSelector(selectIsIssuesShown);
  let textWithLanguageNames = text || '';

  ALL_LANGUAGES_UPPERCASE.forEach((lang) => {
    if (textWithLanguageNames.includes(lang)) {
      textWithLanguageNames = textWithLanguageNames.replace(RegExp(`\\b${lang}\\b`), LANGUAGE_SHORT_NAMES[lang]);
    }
  });

  return <ErrorLabel type={type} text={textWithLanguageNames} hidden={(!visible && !isIssuesShown) || !text} />;
};
