import { FunctionComponent } from 'react';
import { NumberIndicatorProps } from './NumberIndicatorProps';

const NumberIndicator: FunctionComponent<NumberIndicatorProps> = ({ number, color }: NumberIndicatorProps) => {
  return (
    <div className="number-indicator" style={{ backgroundColor: color }}>
      {number}
    </div>
  );
};

export default NumberIndicator;
