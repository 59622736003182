import { apiClient } from '@features/api';

import { BundleSearchResultType } from '../SearchResultItem';

import type { BundleQueryParamsType } from './types';

const getQueryParams = ({ languages, courses, isVocabulary, isReused, hasImage, hasVideo }: BundleQueryParamsType) => {
  return {
    languages: languages || undefined,
    courses: courses || undefined,
    isVocabulary: isVocabulary !== null ? isVocabulary : undefined,
    isReused: isReused !== null ? isReused : undefined,
    hasImage: hasImage !== null ? hasImage : undefined,
    hasVideo: hasVideo !== null ? hasVideo : undefined,
  };
};

export const searchByBundle = async (
  query: string,
  page: number,
  filtersParams: BundleQueryParamsType,
): Promise<{ data: { results: BundleSearchResultType[]; count: number } }> => {
  return await apiClient.v2.get('/search/bundles', {
    params: {
      query,
      page,
      ...getQueryParams(filtersParams),
    },
  });
};
