import { ReactNode, useEffect, useRef, MouseEvent } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { useOnClickOutside } from './hooks';

// @TODO Add addition style variants for other panels
const StyledPanel = styled.dialog`
  min-width: 60rem;

  width: 33vw;
  height: 100%;
  max-height: 100%;
  max-width: 100%;

  background: ${(props) => props.theme.colorV2.uiBackgroundModal};

  margin-right: 0;
  border: 0;
  border-radius: 0;

  font-size: 1.5rem;
  padding-left: 5.5rem;
  padding-top: 2rem;

  &::backdrop {
    background-color: ${({ theme }) => theme.colorV2.systemOverlayBlack};
  }
`;

const LockScroll = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;

type ModalProps = {
  children: ReactNode;
  className?: string;
  isOpen: boolean;
  lockScroll?: boolean;
  onClickOutside?: (e: MouseEvent<HTMLDialogElement>) => void;
};
export const Panel = ({ children, className, isOpen, onClickOutside, lockScroll = false }: ModalProps) => {
  const dialogRef = useRef<HTMLDialogElement>(null);
  useEffect(() => {
    isOpen ? dialogRef.current?.showModal() : dialogRef.current?.close();
  }, [isOpen]);

  const onClick = useOnClickOutside(dialogRef, onClickOutside ? onClickOutside : () => {});

  return (
    <StyledPanel className={className} onClick={onClick} ref={dialogRef}>
      {lockScroll && isOpen && <LockScroll />}
      {children}
    </StyledPanel>
  );
};
