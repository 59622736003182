import { DBId } from '@common/types/DBId';

import { CommonFilterOptionType } from './SearchModal/SearchContainer/SearchContent/Common/filters/types';
import type {
  ActivityQueryParamsType,
  BundleQueryParamsType,
  ChapterQueryParamsType,
  ExerciseQueryParamsType,
  LessonQueryParamsType,
  StringQueryParamsType,
} from './SearchModal/SearchContainer/service/types';

export type AvailableSearchType = 'id' | 'string' | 'bundle' | 'exercise' | 'activity' | 'lesson' | 'chapter';

export const SEARCH_TYPE_ID: AvailableSearchType = 'id';
export const SEARCH_TYPE_STRING: AvailableSearchType = 'string';
export const SEARCH_TYPE_BUNDLE: AvailableSearchType = 'bundle';
export const SEARCH_TYPE_EXERCISE: AvailableSearchType = 'exercise';
export const SEARCH_TYPE_ACTIVITY: AvailableSearchType = 'activity';
export const SEARCH_TYPE_LESSON: AvailableSearchType = 'lesson';
export const SEARCH_TYPE_CHAPTER: AvailableSearchType = 'chapter';

export const SEARCH_TYPES: AvailableSearchType[] = [
  SEARCH_TYPE_ID,
  SEARCH_TYPE_STRING,
  SEARCH_TYPE_BUNDLE,
  SEARCH_TYPE_EXERCISE,
  SEARCH_TYPE_ACTIVITY,
  SEARCH_TYPE_LESSON,
  SEARCH_TYPE_CHAPTER,
];

export type DefaultFilterValuesType = {
  courses?: CommonFilterOptionType[];
  kTags?: CommonFilterOptionType;
  languages?: CommonFilterOptionType[];
  translations?: CommonFilterOptionType[];
  type?: CommonFilterOptionType[];
};

export type SearchPanelParamsType = {
  bundleName?: string;
  column?: number;
  /**
   * @deprecated Use predefinedType instead
   */
  contentType?: string;
  /**
   * @deprecated Use predefinedType instead
   */
  forReusing?: boolean;
  /**
   * @description Select type for the search that won't be changeable by the user
   */
  predefinedType?: AvailableSearchType;

  courseId?: DBId;

  filtersPreset?: Partial<Record<Exclude<AvailableSearchType, 'id' | 'string'>, DefaultFilterValuesType>> & {
    activity?: Partial<ActivityQueryParamsType>;
    bundle?: Partial<BundleQueryParamsType>;
    chapter?: Partial<ChapterQueryParamsType>;
    exercise?: Partial<ExerciseQueryParamsType>;
    lesson?: Partial<LessonQueryParamsType>;
    string?: Partial<StringQueryParamsType>;
  };

  language?: string;
  parentType?: string;
  position?: number;
  query?: string;
  reuseInProgress?: DBId;
  row?: number;
  visitedBranch?: string;
};

export type SearchErrorType = {
  response: {
    data: {
      detail: string;
      message: string;
    };
  };
};

export type SearchPanelSliceType = SearchPanelParamsType & {
  opened: boolean;
};
