const COURSES_INFO_REQUEST = 'COURSES_INFO_REQUEST';
const COURSES_INFO_RESET = 'COURSES_INFO_RESET';
const COURSES_INFO_REQUEST_SUCCEEDED = 'COURSES_INFO_REQUEST_SUCCEEDED';
const COURSE_LOADED_SUCCEEDED = 'COURSE_LOADED_SUCCEEDED';
const COURSE_GROUPS_LOADED_SUCCEEDED = 'COURSE_GROUPS_LOADED_SUCCEEDED';
const ONE_COURSE_CONTENT_LOADED_SUCCEEDED = 'ONE_COURSE_CONTENT_LOADED_SUCCEEDED';
const ONE_COURSE_CONTENT_FAILED = 'ONE_COURSE_CONTENT_FAILED';
const COURSE_LOADING = 'COURSE_LOADING';
const ONE_COURSE_CONTENT_LOADING = 'ONE_COURSE_CONTENT_LOADING';
const SET_POSITION_OF_NEW_CONTENT = 'SET_POSITION_OF_NEW_CONTENT';
const GROUPS_LOADING = 'GROUPS_LOADING';
const GROUPS_LOADED_SUCCEEDED = 'GROUPS_LOADED_SUCCEEDED';
const UPDATE_GROUPS = 'UPDATE_GROUPS';
const UPDATE_EXERCISES = 'UPDATE_EXERCISES';
const SET_THUMBNAIL_IMAGE = 'SET_THUMBNAIL_IMAGE';
const SET_PAYWALL_IMAGE = 'SET_PAYWALL_IMAGE';

export const CoursesActions = {
  COURSES_INFO_REQUEST,
  COURSES_INFO_RESET,
  COURSES_INFO_REQUEST_SUCCEEDED,
  COURSE_LOADED_SUCCEEDED,
  COURSE_GROUPS_LOADED_SUCCEEDED,
  ONE_COURSE_CONTENT_LOADED_SUCCEEDED,
  ONE_COURSE_CONTENT_FAILED,
  COURSE_LOADING,
  ONE_COURSE_CONTENT_LOADING,
  SET_POSITION_OF_NEW_CONTENT,
  GROUPS_LOADING,
  GROUPS_LOADED_SUCCEEDED,
  UPDATE_GROUPS,
  UPDATE_EXERCISES,
  SET_THUMBNAIL_IMAGE,
  SET_PAYWALL_IMAGE,
} as const;
