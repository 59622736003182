import styled from 'styled-components/macro';

const TableHeaderRow = styled.tr`
  padding: 0 0 0.8rem 0 !important;
  box-shadow: 0 0.1rem 0 ${({ theme }) => theme.color.brandGreyscale600};
  display: grid;
  grid-template-columns: 20% 15% 20% 42%;
  grid-column-gap: 1%;
`;

const TableHeaderItem = styled.th`
  border: none !important;
  font-weight: 700 !important;
  font-size: 1.4rem !important;
  color: ${({ theme }) => theme.colorV2.textTertiary};
`;

export const TableHeader = () => (
  <thead>
    <TableHeaderRow>
      <TableHeaderItem>Name</TableHeaderItem>
      <TableHeaderItem>Gender</TableHeaderItem>
      <TableHeaderItem>Languages</TableHeaderItem>
      <TableHeaderItem>Accent</TableHeaderItem>
    </TableHeaderRow>
  </thead>
);
