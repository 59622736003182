import { useDispatch, useSelector } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

import { SpeechRecognitionExerciseRelatedSagas } from '@sagas/exercises/imports/SpeechRecognitionExerciseSagasImports';
import { MultipleChoiceExerciseRelatedSagas } from '@sagas/exercises/imports/MultipleChoiceExerciseSagasImports';
import { PhraseBuilderExerciseRelatedSagas } from '@sagas/exercises/imports/PhraseBuilderExerciseSagasImports';
import { ComprehensionExerciseRelatedSagas } from '@sagas/exercises/imports/ComprehensionExerciseSagasImports';
import { SlidePptxExerciseRelatedSagas } from '@sagas/exercises/imports/SlidePptxExerciseSagasImports';
import { SlidePdfExerciseRelatedSagas } from '@sagas/exercises/imports/SlidePdfExerciseSagasImports';
import { TranslationPanelRelatedSagas } from '@sagas/translationsPanel/imports/TranslationsPanelSagasImports';
import { ConversationExerciseRelatedSagas } from '@sagas/exercises/imports/ConversationExerciseSagasImports';
import { HighlighterExerciseRelatedSagas } from '@sagas/exercises/imports/HighlighterExerciseSagasImports';
import { FlashcardExerciseRelatedSagas } from '@sagas/exercises/imports/FlashcardExerciseSagasImports';
import { TrueFalseExerciseRelatedSagas } from '@sagas/exercises/imports/TrueFalseExerciseSagasImports';
import { DialogueExerciseRelatedSagas } from '@sagas/exercises/imports/DialogueExerciseSagasImports';
import { SpellingExerciseRelatedSagas } from '@sagas/exercises/imports/SpellingExerciseSagasImports';
import { FillGapExerciseRelatedSagas } from '@sagas/exercises/imports/FillGapExerciseSagasImports';
import { MatchUpExerciseRelatedSagas } from '@sagas/exercises/imports/MatchUpExerciseSagasImports';
import { NavigationRelatedSagas } from '@sagas/navigationMenu/imports/NavigationMenuSagasImports';
import { TypingExerciseRelatedSagas } from '@sagas/exercises/imports/TypingExerciseSagasImports';
import { MediaUploadRelatedSagas } from '@sagas/mediaUpload/imports/MediaUploadSagasImports';
import { TipExerciseRelatedSagas } from '@sagas/exercises/imports/TipExerciseSagasImports';
import { ListenRepeatExerciseRelatedSagas } from '@sagas/exercises/imports/ListenRepeatExerciseSagasImport';
import { PublishingRelatedSagas } from '@sagas/publishing/imports/PublishingSagasImports';
import { SearchRelatedSagas } from '@sagas/search/imports/SearchRelatedSagasImports';
import { CourseRelatedSagas } from '@sagas/courses/imports/CourseSagasImports';
import { UsersRelatedSagas } from '@sagas/users/imports/UsersSagasImports';
import { GramarReviewRelatedSagas } from '@sagas/grammarReview/imports/GrammarReviewSagasImports';
import { PlacementTestRelatedSagas } from '@sagas/placementTest/imports/PlacementTestSagasImports';
import { ActivityRelatedSagas } from '@sagas/activities/imports/ActivitySagasImports';
import { ContentOwnershipRelatedSagas } from '@sagas/contentOwnership/imports/ContentOwnershipSagasImports';
import { RoleplayRelatedSagas } from '@sagas/roleplay/imports/RoleplaySagasImports';
import { VocabularyReviewRelatedSagas } from '@sagas/vocabularyReview/imports/VocabularyReviewImports';
import { courseSlice } from './slices/courseSlice';
import { bulkPublishingSlice } from './slices/bulkPublishingSlice';
import { usersSlice } from './slices/usersSlice';
import { grammarReviewSlice } from './slices/grammarReviewSlice';
import { placementTestSlice } from './slices/placementTestSlice';
import { roleplaySlice } from './slices/roleplaySlice';
import { vocabularyReviewSlice } from './slices/vocabularyReviewSlice';
import { uiSlice } from './slices/uiSlice';

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: {
    [courseSlice.name]: courseSlice.reducer,
    [usersSlice.name]: usersSlice.reducer,
    [grammarReviewSlice.name]: grammarReviewSlice.reducer,
    [placementTestSlice.name]: placementTestSlice.reducer,
    [uiSlice.name]: uiSlice.reducer,
    [bulkPublishingSlice.name]: bulkPublishingSlice.reducer,
    [roleplaySlice.name]: roleplaySlice.reducer,
    [vocabularyReviewSlice.name]: vocabularyReviewSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sagaMiddleware),
});

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector = <T>(selector: (state: AppState) => T): T => useSelector(selector);

sagaMiddleware.run(CourseRelatedSagas, store.dispatch);
sagaMiddleware.run(GramarReviewRelatedSagas, store.dispatch);
sagaMiddleware.run(PlacementTestRelatedSagas);
sagaMiddleware.run(UsersRelatedSagas);
sagaMiddleware.run(PublishingRelatedSagas);
sagaMiddleware.run(ComprehensionExerciseRelatedSagas, store.dispatch);
sagaMiddleware.run(SlidePptxExerciseRelatedSagas, store.dispatch);
sagaMiddleware.run(SlidePdfExerciseRelatedSagas, store.dispatch);
sagaMiddleware.run(ConversationExerciseRelatedSagas, store.dispatch);
sagaMiddleware.run(DialogueExerciseRelatedSagas, store.dispatch);
sagaMiddleware.run(FillGapExerciseRelatedSagas, store.dispatch);
sagaMiddleware.run(FlashcardExerciseRelatedSagas, store.dispatch);
sagaMiddleware.run(HighlighterExerciseRelatedSagas, store.dispatch);
sagaMiddleware.run(MatchUpExerciseRelatedSagas, store.dispatch);
sagaMiddleware.run(MultipleChoiceExerciseRelatedSagas, store.dispatch);
sagaMiddleware.run(PhraseBuilderExerciseRelatedSagas, store.dispatch);
sagaMiddleware.run(SpeechRecognitionExerciseRelatedSagas, store.dispatch);
sagaMiddleware.run(SpellingExerciseRelatedSagas, store.dispatch);
sagaMiddleware.run(TipExerciseRelatedSagas, store.dispatch);
sagaMiddleware.run(TrueFalseExerciseRelatedSagas, store.dispatch);
sagaMiddleware.run(TypingExerciseRelatedSagas, store.dispatch);
sagaMiddleware.run(ListenRepeatExerciseRelatedSagas, store.dispatch);
sagaMiddleware.run(TranslationPanelRelatedSagas, store.dispatch);
sagaMiddleware.run(SearchRelatedSagas);
sagaMiddleware.run(MediaUploadRelatedSagas);
sagaMiddleware.run(NavigationRelatedSagas);
sagaMiddleware.run(ActivityRelatedSagas);
sagaMiddleware.run(ContentOwnershipRelatedSagas);
sagaMiddleware.run(RoleplayRelatedSagas, store.dispatch);
sagaMiddleware.run(VocabularyReviewRelatedSagas);
