import { OtherLocationsInterface } from '@common/interfaces/exercises/OtherLocationsInterface';
import { ContentTypes, ContentType } from '@common/enums/ContentTypes';

export const getLink = (currentWholeLocation: OtherLocationsInterface[]) => {
  const newURL = currentWholeLocation.reduce((result: string, item: OtherLocationsInterface) => {
    if (item.type === ContentTypes.course) {
      return `/${item.type}/${item.id}`;
    } else if (item.type === ContentTypes.grammarReview) {
      return `${result}/grammar-review`;
    } else if (item.type === ContentTypes.grammarCategory) {
      return `${result}/category/${item.id}`;
    } else if (item.type === ContentTypes.grammarTopic) {
      return `${result}/topic/${item.id}`;
    } else if (item.type === ContentTypes.placementTest) {
      return `${result}/placement-test`;
    } else if (item.type === ContentTypes.entrypoint) {
      return `${result}/entrypoint/${item.id}`;
    } else if (item.type === ContentType.group) {
      return `${result}/${item.groupType}/${item.id}`;
    } else if (item.type === ContentTypes.exercise) {
      return `${result}/${item.type}/${item.id}`;
    } else {
      return result;
    }
  }, '');

  return newURL;
};
