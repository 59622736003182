import { ampli } from './adapters/ampli';
import { vercel } from './adapters/vercel';

const PRIMARY_ADAPTER = ampli;
const SECONDARY_ADAPTER = vercel;

const handler = {
  get(adapter: typeof PRIMARY_ADAPTER, prop: keyof typeof PRIMARY_ADAPTER) {
    if (adapter.isLoaded && prop in ampli) {
      if (typeof adapter[prop] == 'function') {
        return (...args: any) => {
          // pass AmpliWrapper method name as Vercel Analytics custom event name
          SECONDARY_ADAPTER.track(prop, ...args);

          return (adapter[prop] as Function).apply(adapter, args);
        };
      }

      return adapter[prop];
    } else {
      return () => {};
    }
  },
};

export const userTracking = new Proxy(PRIMARY_ADAPTER, handler);
