import { Logo } from '@features/app/logo';

import { HeaderWrapper } from '../styles';

export const Header = () => {
  return (
    <HeaderWrapper>
      <Logo size="large" withLink={true} />
    </HeaderWrapper>
  );
};
