import { CourseContentBranches, ContentBranches, CourseImageBranches } from '@common/types/AnyExerciseContentBranch';
import { GenericContentInterface } from '@common/interfaces/contentTypes/GenericContentInterface';
import { LocalizationInterface } from '@common/interfaces/localization/LocalizationInterface';
import ExerciseDataModelAssembler from '@components/Exercises/ExerciseDataModelAssembler';
import { ExerciseCommonActionCreators as ExerciseCommonActions } from '@actionCreators/ExerciseCommonActionCreator';
import { apiClient } from '@features/api';
import { CourseInterface } from '@common/interfaces/courses/CourseInterface';
import { FormikValuesInterface } from '@helpers/formikInitialValuesHelper';
import TranslationsPanelUtils from '@components/TranslationsPanel/TranslationsPanelUtils';
import { ContentTypes, ContentType } from '@common/enums/ContentTypes';
import ContentsService from '@services/ContentsService';
import { DBId } from '@common/types/DBId';
import { selectTextLocalizationsForSave, selectMediaLocalizationsForSave } from '@helpers/localizationSaveHelper';
import { AppDispatch } from '@redux/store';

import { CourseImageType } from './types/CourseServiceTypes';

const TranslationsPanelService = {
  async updateCourseField(
    dispatch: AppDispatch,
    course: CourseInterface,
    contentId: DBId | undefined,
    visitedBranch: CourseContentBranches | CourseImageBranches,
  ): Promise<Partial<Record<'title' | 'description' | CourseImageBranches, DBId>>> {
    let { audioLocalizations, description, imageLocalizations, textLocalizations } =
      TranslationsPanelUtils.extractTextImageAndAudioLocalizationsFromCourse(course, visitedBranch);

    if (
      !selectMediaLocalizationsForSave(audioLocalizations).length &&
      !selectMediaLocalizationsForSave(imageLocalizations).length &&
      !selectTextLocalizationsForSave(textLocalizations).length &&
      !description
    ) {
      return { [ExerciseDataModelAssembler.getCorrectFieldName(visitedBranch)]: null };
    }

    contentId = await TranslationsPanelService.createOrUpdateContent(
      dispatch,
      contentId as DBId,
      course,
      visitedBranch,
      textLocalizations,
      audioLocalizations,
      imageLocalizations,
      ContentTypes.course,
      description,
    );

    return { [ExerciseDataModelAssembler.getCorrectFieldName(visitedBranch)]: contentId };
  },
  async updateGroupField(
    dispatch: AppDispatch,
    content: GenericContentInterface,
    contentId: DBId | undefined,
    visitedBranch: ContentBranches,
  ) {
    let { textLocalizations, audioLocalizations, description } =
      TranslationsPanelUtils.extractTextAndAudioLocalizationsFromContent(content, visitedBranch);

    const correctFieldName = ExerciseDataModelAssembler.getCorrectFieldName(visitedBranch);

    if (
      !selectTextLocalizationsForSave(textLocalizations).length &&
      !selectMediaLocalizationsForSave(audioLocalizations).length &&
      !description
    ) {
      return { [correctFieldName]: null };
    }

    contentId = await TranslationsPanelService.createOrUpdateContent(
      dispatch,
      contentId as DBId,
      content,
      visitedBranch,
      textLocalizations,
      audioLocalizations,
      [],
      ContentType.levelOrLesson,
      description,
    );

    return {
      [correctFieldName]: contentId,
    };
  },
  async createOrUpdateContent(
    dispatch: AppDispatch,
    contentId: DBId,
    course: CourseInterface | GenericContentInterface,
    visitedBranch: CourseContentBranches | CourseImageBranches | ContentBranches,
    textLocalizations: LocalizationInterface[],
    audioLocalizations: LocalizationInterface[],
    imageLocalizations: LocalizationInterface[],
    contentType: string,
    description?: string,
  ): Promise<DBId> {
    const payloadForContent = {
      textLocalizations: selectTextLocalizationsForSave(textLocalizations),
      audioLocalizations: selectMediaLocalizationsForSave(audioLocalizations),
      imageLocalizations: selectMediaLocalizationsForSave(imageLocalizations),
      videoLocalizations: [],
      description: description || '',
    };

    if (['thumbnailImage', 'paywallImage'].includes(visitedBranch)) {
      const imageType: CourseImageType = `course_${visitedBranch.replace('Image', '')}` as CourseImageType;

      payloadForContent.imageLocalizations = payloadForContent.imageLocalizations.map((image) => {
        const { _id, language, value } = image;

        return {
          fileId: _id,
          language,
          type: imageType,
          value,
        };
      });
    }

    if (!contentId) {
      course = course as CourseInterface;

      contentId = await ContentsService.contents.createNewContent(payloadForContent);

      dispatch(
        ExerciseCommonActions.setCommonNewContentId({
          contentType,
          field: visitedBranch,
          newContentId: contentId,
        }),
      );
    } else {
      await ContentsService.contents.update(contentId, payloadForContent);
    }

    return contentId;
  },
  async saveLevelOrLesson(
    dispatch: AppDispatch,
    loadedContent: GenericContentInterface,
    values: FormikValuesInterface,
  ) {
    try {
      const updatePayload = await Promise.all([
        (values?.titleWithLocalizationsChanged ||
          loadedContent.titleWithLocalizationsChanged ||
          loadedContent.titleWithLocalizations?.changed) &&
        !loadedContent.titleWithLocalizations?.isReused
          ? TranslationsPanelService.updateGroupField(
              dispatch,
              loadedContent,
              loadedContent.titleWithLocalizations?._id,
              'titleWithLocalizations',
            )
          : { title: loadedContent.titleWithLocalizations?._id || undefined },
        loadedContent.type === ContentTypes.lesson &&
        (values?.descriptionWithLocalizationsChanged ||
          loadedContent.descriptionWithLocalizationsChanged ||
          loadedContent.descriptionWithLocalizations?.changed) &&
        !loadedContent.descriptionWithLocalizations?.isReused
          ? TranslationsPanelService.updateGroupField(
              dispatch,
              loadedContent,
              loadedContent.descriptionWithLocalizations?._id,
              'descriptionWithLocalizations',
            )
          : { description: loadedContent.descriptionWithLocalizations?._id || undefined },
        (values?.learningOutcomesChanged || loadedContent.learningOutcomesChanged) &&
        !loadedContent.learningOutcomes?.isReused
          ? TranslationsPanelService.updateGroupField(
              dispatch,
              loadedContent,
              loadedContent.learningOutcomes?._id,
              'learningOutcomes',
            )
          : { learningOutcomes: loadedContent.learningOutcomes?._id || undefined },
        loadedContent.lessonType === ContentTypes.checkpoint &&
        (values?.completeMessageChanged || loadedContent.completeMessageChanged) &&
        !loadedContent.completeMessage?.isReused
          ? TranslationsPanelService.updateGroupField(
              dispatch,
              loadedContent,
              loadedContent.completeMessage?._id,
              'completeMessage',
            )
          : { completeMessage: loadedContent.completeMessage?._id || undefined },
        loadedContent.identityGroupChanged && { identityGroup: loadedContent.identityGroup?.id || null },
        loadedContent.certificateTypeChanged && { certificateType: loadedContent.certificateType },
      ]);

      const finalPayload = {
        ...updatePayload
          .filter((item) => {
            if (item) {
              return typeof Object.values(item)[0] !== 'undefined';
            }

            return false;
          })
          .reduce((acc, item) => ({ ...acc, ...item }), {}),
        labels: loadedContent.labels,
      };

      const saveResult = await TranslationsPanelService.updateGroup(finalPayload, loadedContent.id);
      return saveResult;
    } catch (e: any) {
      throw new Error(e as string);
    }
  },
  async updateGroup(payload: any, groupId: DBId | undefined) {
    return await apiClient.noErrorsV2.put(`/content/groups/${groupId}`, payload);
  },
  async getOtherLocations(contentId: DBId) {
    return await apiClient.v2.get(`strings/${contentId}/mappings`);
  },
  async getOtherLocationsForBundle(contentId: DBId) {
    return await apiClient.v2.get(`components/${contentId}/mappings`);
  },
};

export default TranslationsPanelService;
