import { apiClient } from '@features/api';
import { Role } from '@features/auth/roles';

const UsersService = {
  async getUserList(excludedRoles: Role[] = []) {
    const query = excludedRoles.reduce(
      (acc, role, index) => `${acc + (index === 0 ? '?' : '&')}excludedRoles[]=${role}`,
      '/security/users/names',
    );

    return apiClient.noErrorsV2.get(query);
  },
  async getUsers() {
    return await apiClient.v2.get(`/security/users`);
  },
  async getUser(userId: string) {
    return await apiClient.noErrorsV2.get(`/security/users/${userId}`);
  },
  async getRoles() {
    return await apiClient.v2.get(`/security/roles`);
  },
  async getCurrentUser() {
    return await apiClient.v2.get(`/security/users/me`);
  },
  async editUser(userId: string, payload: any) {
    return await apiClient.v2.put(`/security/users/${userId}`, payload);
  },
  async editUserRole(userId: string, payload: any) {
    return await apiClient.v2.put(`/security/users/${userId}`, payload);
  },
  async archiveUser(userId: string, payload: { beneficiary: string }) {
    return await apiClient.v2.post(`/security/users/${userId}/archive`, payload);
  },
};

export default UsersService;
