import { Fragment, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { ContentTypes, type ContentTypesForCreateContentType, type ContentTypesType } from '@common/enums/ContentTypes';
import { GenericContentInterface } from '@common/interfaces/contentTypes/GenericContentInterface';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
  DroppableProvided,
  DraggableProvided,
  DraggableStateSnapshot,
} from 'react-beautiful-dnd';
import { ContentCreator } from '@features/content/contentCreator';
import type { ActivityType } from '@features/content/activities';
import type { ExerciseTypesType } from '@common/enums/ExerciseTypes';
import { ContentCard } from '@components/DraggableList/ContentCard';
import { LessonTypes } from '@common/enums/LessonTypes';
import { GrammarCategoryListItemType, GrammarTopicListItemType } from '@features/content/grammar';
import { ExerciseListItemType } from '@components/ContentTypes/ExerciseCard/types';
import { EntrypointListItemType } from '@features/content/placementTest';
import { RoleplayListItemType } from '@features/content/roleplay';

type DraggableListProps = {
  arrayOfChildren: (
    | GenericContentInterface
    | GrammarCategoryListItemType
    | GrammarTopicListItemType
    | ExerciseListItemType
    | EntrypointListItemType
    | RoleplayListItemType
  )[];
  onDragEnd: (result: DropResult) => void;
  onContentCreate: (
    contentTypeToCreate: ContentTypesType,
    contentCategory: ActivityType | ExerciseTypesType | ContentTypesForCreateContentType | null,
    position: number,
    numItemsToCreate?: number,
  ) => void;
  parentType?: typeof ContentTypes.entrypoint | typeof ContentTypes.grammarTopic;
  creatorAcceptableButtons: Partial<Record<ContentTypesType | 'onlyTip' | 'onlyGradable', boolean>>;
};

export const DraggableList = ({
  arrayOfChildren,
  parentType,
  onDragEnd,
  onContentCreate,
  creatorAcceptableButtons,
}: DraggableListProps) => {
  const [enabled, setEnabled] = useState(false);

  const renderContentCreator = (position: number, isPreenabled: boolean) => {
    return (
      <ContentCreator
        position={position}
        parentType={parentType}
        onCreateContent={(
          contentTypeToCreate: ContentTypesType,
          contentCategory: ActivityType | ExerciseTypesType | ContentTypesForCreateContentType | null,
          numItemsToCreate?: number,
        ) => {
          onContentCreate(contentTypeToCreate, contentCategory, position, numItemsToCreate);
        }}
        acceptableButtons={creatorAcceptableButtons}
        isPreEnabled={isPreenabled}
      />
    );
  };

  // workaround for a bug in react-beautiful-dnd with strict mode
  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));

    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      {enabled && (
        <Droppable droppableId="droppable-1">
          {(provided: DroppableProvided) => (
            <div ref={provided.innerRef} className="droppable-wrapper">
              {arrayOfChildren.length === 0 && (
                <Container>
                  <Row>
                    <Col>{renderContentCreator(0, true)}</Col>
                  </Row>
                </Container>
              )}

              {arrayOfChildren.length > 0 && (
                <>
                  {renderContentCreator(0, false)}
                  {arrayOfChildren.map((content, idx) => {
                    return (
                      <Fragment key={content.id}>
                        <Draggable
                          draggableId={content.id}
                          key={content.id}
                          index={idx}
                          isDragDisabled={(content as GenericContentInterface).lessonType === LessonTypes.checkpoint}
                        >
                          {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
                            <div
                              className={`course-composer__base-content-drag-wrapper el-${idx}`}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              style={{
                                ...provided.draggableProps.style,
                              }}
                            >
                              <ContentCard
                                content={content}
                                dragProps={{
                                  provided: provided,
                                  snapshot,
                                }}
                                arrayOfChildren={arrayOfChildren}
                              />
                            </div>
                          )}
                        </Draggable>
                        {renderContentCreator(idx + 1, false)}
                      </Fragment>
                    );
                  })}
                  {provided.placeholder}
                </>
              )}
            </div>
          )}
        </Droppable>
      )}
    </DragDropContext>
  );
};
