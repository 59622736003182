import { FunctionComponent, useMemo } from 'react';
import classnames from 'classnames';
import { FormikContextType, useFormikContext } from 'formik';

import { getValueByLanguage } from '../../../../../localization-utils';
import { FormikValuesInterface } from '@helpers/formikInitialValuesHelper';
import { InstructionContainerProps } from '../../../PreviewModalProps';

import './InstructionContainer.scss';

export const InstructionContainer: FunctionComponent<InstructionContainerProps> = ({
  customClass = '',
  viaHTML = false,
  content,
  courseLearningLanguage,
  isForList,
}: InstructionContainerProps) => {
  const { values }: FormikContextType<FormikValuesInterface> = useFormikContext();
  const isInstructionOnCourseLang = useMemo(
    () => content?.instructionsLanguage === 'learning',
    [content?.instructionsLanguage],
  );

  const instructionOnEnLang = useMemo(
    () => getValueByLanguage(isForList ? content?.instructions?.textLocalizations : values.instructions),
    [values, isForList, content],
  );
  const instructionOnCourseLang = useMemo(
    () =>
      getValueByLanguage(
        isForList ? content?.instructions?.textLocalizations : values.instructions,
        courseLearningLanguage,
      ),
    [values, courseLearningLanguage, isForList, content],
  );

  if (viaHTML) {
    return (
      <span
        className={classnames('preview__instruction', `${customClass}`)}
        dangerouslySetInnerHTML={{
          __html: (isInstructionOnCourseLang ? instructionOnCourseLang : instructionOnEnLang) || '',
        }}
      />
    );
  }

  return (
    <span className={classnames('preview__instruction', `${customClass}`)}>
      {isInstructionOnCourseLang ? instructionOnCourseLang : instructionOnEnLang}
    </span>
  );
};
