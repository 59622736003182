import { CoursesActions } from '@actions/CoursesActions';
import { CourseSliceInterface } from '@common/interfaces/slices/CourseSliceInterface';
import { LoadingStage } from '@common/enums/LoadingStage';
import { PayloadAction } from '@reduxjs/toolkit';
import produce from 'immer';

const CoursesInfoReducers = {
  [CoursesActions.COURSES_INFO_REQUEST]: (state: CourseSliceInterface) => {
    return produce(state, (draft) => {
      draft.coursesInfo.loaded = LoadingStage.loading;
    });
  },
  [CoursesActions.COURSES_INFO_RESET]: (state: CourseSliceInterface) => {
    return produce(state, (draft) => {
      draft.coursesInfo.loaded = LoadingStage.notLoaded;
    });
  },
  [CoursesActions.COURSES_INFO_REQUEST_SUCCEEDED]: (state: CourseSliceInterface, { payload }: PayloadAction<any>) => {
    return produce(state, (draft) => {
      draft.coursesInfo.loaded = LoadingStage.loaded;
      draft.coursesInfo.courses = payload;
    });
  },
};

export default CoursesInfoReducers;
