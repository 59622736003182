const UPLOAD = 'UPLOADTRANSLATIONACTIONS_UPLOAD';
const UPLOAD_SUCCESS = 'UPLOADTRANSLATIONACTIONS_UPLOAD_SUCCESS';
const UPLOAD_FAIL = 'UPLOADTRANSLATIONACTIONS_UPLOAD_FAIL';
const CLOSE = 'UPLOADTRANSLATIONACTIONS_CLOSE';

export const UploadTranslationActions = {
  UPLOAD,
  UPLOAD_SUCCESS,
  UPLOAD_FAIL,
  CLOSE,
} as const;
