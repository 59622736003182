import { DialogueCharactersPanelModes, UISliceInterface } from '@common/interfaces/slices/UISliceInterface';
import { DialogueExerciseActions } from '@actions/DialogueExerciseActions';
import { PayloadAction } from '@reduxjs/toolkit';

const DialogueUIReducers = {
  [DialogueExerciseActions.SHOW_DIALOGUE_CHARACTERS_SELECTOR_SUCCESS]: (
    state: UISliceInterface,
    { payload }: PayloadAction<any>,
  ): UISliceInterface => {
    return {
      ...state,
      panels: {
        ...state.panels,
        dialogueCharacters: {
          ...state.panels.dialogueCharacters,
          characters: payload.characters,
          visible: true,
          mode: DialogueCharactersPanelModes.assignCharacters,
          title: `Select characters (${state.panels.dialogueCharacters.MAX_CHARACTERS} max)`,
          characterIndex: null,
        },
      },
    };
  },
  [DialogueExerciseActions.SHOW_DIALOGUE_CHARACTERS_SELECTOR_TO_UPDATE_PHOTO_SUCCESS]: (
    state: UISliceInterface,
    { payload: { characters, characterIndex } }: PayloadAction<any>,
  ): UISliceInterface => {
    return {
      ...state,
      panels: {
        ...state.panels,
        dialogueCharacters: {
          ...state.panels.dialogueCharacters,
          characters: characters,
          MAX_CHARACTERS: 1,
          mode: DialogueCharactersPanelModes.changeCharacter,
          visible: true,
          title: 'Change character',
          characterIndex,
        },
      },
    };
  },
};

export default DialogueUIReducers;
