import produce from 'immer';

import { CourseSliceInterface } from '@common/interfaces/slices/CourseSliceInterface';
import { LocalizationInterface } from '@common/interfaces/localization/LocalizationInterface';

// filter audio localizations to be removed and map them to match expected payload by backend
const mapAudioLocalizationsToRemove = (
  audioLocalizations: LocalizationInterface[],
  forCourseLearningLanguage: boolean,
  learningLanguage: string,
) => {
  return audioLocalizations
    .filter(
      (localization) =>
        (forCourseLearningLanguage && localization.language === learningLanguage) ||
        (!forCourseLearningLanguage && localization.language === 'EN'),
    )
    .map((localization) => ({
      ...localization,
      _id: '',
      fileId: null,
      type: 'audio',
      value: '',
    }));
};

export const removeAudioDataFromBundle = (
  clonedCourseSlice: CourseSliceInterface,
  field: string,
  bundleField: string,
  forCourseLearningLanguage: boolean = false,
) => {
  return produce(clonedCourseSlice, (draft) => {
    draft.loadedExercise.exercise.content[bundleField][field].audioLocalizations = mapAudioLocalizationsToRemove(
      clonedCourseSlice.loadedExercise.exercise.content[bundleField][field].audioLocalizations,
      forCourseLearningLanguage,
      clonedCourseSlice.course.learningLanguage,
    );
    draft.mediaUploading.audioUploadingInProcess = forCourseLearningLanguage
      ? [
          ...clonedCourseSlice.mediaUploading.audioUploadingInProcess.filter(
            (item) =>
              item.field !== field ||
              (item.field === field && item.language !== clonedCourseSlice.course.learningLanguage),
          ),
        ]
      : [
          ...clonedCourseSlice.mediaUploading.audioUploadingInProcess.filter(
            (item) => item.field !== field || (item.field === field && item.language !== 'EN'),
          ),
        ];
  });
};
