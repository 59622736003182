import { UISliceInterface } from '@common/interfaces/slices/UISliceInterface';
import { CourseEditionActions } from '@actions/CourseEditionActions';

const CourseEditionUIReducers = {
  [CourseEditionActions.TOGGLE_ISSUES_VISIBILITY]: (state: UISliceInterface): UISliceInterface => {
    return {
      ...state,
      isIssuesShown: !state.isIssuesShown,
    };
  },
};

export default CourseEditionUIReducers;
