import { PayloadAction } from '@reduxjs/toolkit';

import { ContentTypes, ContentType } from '@common/enums/ContentTypes';

import { ContentOwnershipActions } from '@actions/ContentOwnershipActions';
import type { SetContentOwnershipPayload } from '@features/content/contentOwnership';

export const ContentOwnershipsActionsCreator = {
  changeOwner: ({ componentId, childComponentId, owner, contentType, commonCallback }: SetContentOwnershipPayload) => ({
    type: ContentOwnershipActions.CHANGE_COMPONENT_OWNER,
    payload: {
      componentId,
      childComponentId,
      owner,
      contentType,
      commonCallback,
    },
  }),

  setOwner: ({
    componentId,
    childComponentId,
    owner,
    contentType,
  }: SetContentOwnershipPayload): PayloadAction<SetContentOwnershipPayload> => {
    let actionType: string;

    switch (contentType) {
      case ContentTypes.course:
        actionType = ContentOwnershipActions.SET_CONTENT_OWNER;
        break;
      case ContentType.levelOrLesson:
        actionType = ContentOwnershipActions.SET_CONTENT_OWNER;
        break;
      case ContentTypes.grammarReview:
        actionType = ContentOwnershipActions.SET_GRAMMAR_REVIEW_OWNER;
        break;
      case ContentTypes.grammarCategory:
        actionType = ContentOwnershipActions.SET_GRAMMAR_CATEGORY_OWNER;
        break;
      case ContentTypes.grammarTopic:
        actionType = ContentOwnershipActions.SET_GRAMMAR_TOPIC_OWNER;
        break;
      case ContentTypes.placementTest:
        actionType = ContentOwnershipActions.SET_PLACEMENT_TEST_OWNER;
        break;
      case ContentTypes.entrypoint:
        actionType = ContentOwnershipActions.SET_ENTRYPOINT_OWNER;
        break;
      case ContentTypes.exercise:
        actionType = ContentOwnershipActions.SET_EXERCISE_OWNER;
        break;
      default:
        throw new Error('Invalid content type for Content Ownership actions');
    }

    return {
      type: actionType,
      payload: {
        componentId,
        childComponentId,
        contentType,
        owner,
      },
    };
  },
};
