import styled from 'styled-components/macro';

import { ReactComponent as LockContentIcon } from './_img/LockContentIcon.svg';

// Mimic react-draft-wysiwyg styles for toolbar options
export const LockContentOption = styled.div`
  align-items: center;
  align-self: center;
  display: flex;
  justify-content: center;
  margin-bottom: 0.6rem;
  min-width: 2.5rem;

  .rdw-inline-wrapper ~ & {
    border-left: solid 0.1rem ${({ theme }) => theme.colorV2.textQuaternary};
    height: 1.8rem;
    margin-left: 0.8rem;
    padding-left: 1.8rem;
  }
`;

export const StyledLockContentIcon = styled(LockContentIcon)`
  border: solid 0.1rem transparent;
  height: 1.8rem;
  padding: 0.1rem;
  width: 1.8rem;
`;

export const LockContentButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 1.8rem;

  &[aria-selected='true'] {
    ${StyledLockContentIcon} {
      border: solid 0.1rem ${({ theme }) => theme.colorV2.uiDark};
    }
  }

  &:hover {
    cursor: pointer;
  }
`;
