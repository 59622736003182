import { useCallback, useEffect, useState } from 'react';
import { createGlobalStyle } from 'styled-components/macro';

import { useBulkPublishing } from '@features/content/exercises';
import { userTracking } from '@features/app/tracking';

import { CommentsButton } from './CommentsButton';
import { CommentsPanel } from './CommentsPanel';
import { useComponentId } from './useComponentId';
import { getCommentsNumber } from './CommentsService';

const StyleBlockScroll = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;

export const Comments = ({ id }: { id?: string }) => {
  const { currentBulkPublishingState } = useBulkPublishing();

  const [opened, setOpened] = useState(false);
  const [totalCount, setTotalCount] = useState<number | null>(null);
  const [unreadCount, setUnreadCount] = useState<number | null>(null);

  const open = useCallback(() => {
    setOpened(true);
    userTracking.logosCommentingPanelOpened();
  }, [setOpened]);
  const close = useCallback(() => setOpened(false), [setOpened]);

  const locationBasedComponentId = useComponentId();
  const componentId = id || locationBasedComponentId;

  useEffect(() => {
    if (currentBulkPublishingState) {
      return;
    }

    setTotalCount(null);
    setUnreadCount(null);

    if (componentId !== null) {
      getCommentsNumber(componentId).then((result) => {
        setTotalCount(result.headers['x-total-count']);
        setUnreadCount(result.headers['x-unread-count'] || 0);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentId]);

  if (currentBulkPublishingState || !componentId) {
    return null;
  }

  return (
    <>
      {opened && <StyleBlockScroll />}
      {!opened && (
        <CommentsButton
          loaded={totalCount !== null}
          totalCount={totalCount || 0}
          unreadCount={unreadCount || 0}
          onClick={open}
          modalMode={!!id}
        />
      )}
      <CommentsPanel visible={opened} id={id} close={close} />
    </>
  );
};
