import { ChangeEvent, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { FormikContextType, useFormikContext } from 'formik';
import styled from 'styled-components/macro';

import { LocalizationInterface } from '@common/interfaces/localization/LocalizationInterface';
import { ValidationErrorInterface } from '@common/interfaces/validation/ValidationInterface';
import TranslationsPanelUtils from '@components/TranslationsPanel/TranslationsPanelUtils';
import { TranslationTipActionsCreator } from '@actionCreators/TranslationTipActionsCreator';
import { ReactComponent as ExclamationIcon } from '@components/ValidationErrorDisplayer/exclamation.svg';
import { useIsAdmin } from '@features/auth/roles';
import ArrowLeftIcon from '@static/svg/translationsPanel/Arrow left.svg';
import WarningIcon from '@static/svg/translationsPanel/Warning.svg';

import { TabFooter } from '../TabFooter';
import { Translations } from './Translations';
import { InactiveTranslations } from './InactiveTranslations';
import { GrammarCategoryFormikValues } from '../../GrammarCategory';
import { useToast } from '@features/app/toast';
import { selectGrammarTranslationsPanel, selectIsIssuesShown } from '@selectors/UiSelectors';

const ContextWarning = styled.div`
  align-items: center;
  display: flex;
  background-color: ${({ theme }) => theme.colorV2.utilityAlertBackground};
  border-radius: 0.6rem;
  font-size: 1.4rem;
  gap: 0.8rem;
  padding: 0.8rem;
  margin: 1.6rem 0;
  width: fit-content;

  svg {
    display: inline-block;
    vertical-align: text-bottom;

    path {
      fill: ${({ theme }) => theme.colorV2.utilityError};
    }
  }
`;

type TextTabProps = {
  defaultContext: string;
  defaultTranslations: LocalizationInterface[];
  hiddenTranslations: LocalizationInterface[];
  notAllLanguagesSelected: boolean;
  otherLocationsNumberOfItems: number;
  validationErrors: ValidationErrorInterface[];
  navigateToOtherTab: () => void;
};

export const TranslationsTab = ({
  defaultContext,
  defaultTranslations,
  hiddenTranslations,
  notAllLanguagesSelected,
  otherLocationsNumberOfItems,
  validationErrors,
  navigateToOtherTab,
}: TextTabProps) => {
  const isAdmin = useIsAdmin();
  const { values, setFieldValue }: FormikContextType<GrammarCategoryFormikValues> = useFormikContext();
  const dispatch = useAppDispatch();
  const isIssuesShown = useAppSelector(selectIsIssuesShown);
  const translations = useAppSelector(selectGrammarTranslationsPanel);

  const showToast = useToast();

  const { visitedContentId, fieldName, isEditBlocked, type, isEditDisabled } = translations;

  const [context, setContext] = useState((fieldName && values[`${fieldName}Context`]) || defaultContext);

  const onContextChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (visitedContentId?.includes('default_') && !isAdmin) {
      showToast({
        type: 'info',
        title: 'Default instructions cannot be edited',
        description: 'To remove the default instruction, clear it using the trash can',
      });
    } else {
      if (isEditBlocked) {
        dispatch(TranslationTipActionsCreator.setCurrentContentId(visitedContentId || '', type || '', fieldName || ''));
      } else {
        setContext(e.target.value);
        setFieldValue(`${fieldName}Context`, e.target.value);
        setFieldValue(`${fieldName}Changed`, true);
      }
    }
  };

  const missingContext = TranslationsPanelUtils.getMissingContext(defaultTranslations);

  return (
    <>
      {otherLocationsNumberOfItems > 0 && (
        <Alert variant="warning" className="translations-panel-modal__alert" onClick={navigateToOtherTab}>
          <img src={WarningIcon} alt="Warning" className="translations-panel-modal__alert-icon" />
          <span className="translations-panel-modal__other-locations-text">
            This string lives in {otherLocationsNumberOfItems} other locations
          </span>
          <img
            src={ArrowLeftIcon}
            alt="Go to other locations"
            className="translations-panel-modal__other-locations-icon"
          />
        </Alert>
      )}
      <div className="translations-panel-modal__translation-context-label-wrapper">
        <span className="translations-panel-modal__translation-context-label">Context for translators</span>

        <textarea
          placeholder="Add context for translators"
          className="translations-panel-modal__translator-context"
          value={context || ''}
          onChange={onContextChange}
          disabled={isEditDisabled}
        />

        {!!missingContext.length && (
          <ContextWarning>
            <ExclamationIcon />
            Check if context describe the meaning word{missingContext.length > 1 ? 's' : ''}:{' '}
            {missingContext.map((part) => `${part}= `).join(', ')}.
          </ContextWarning>
        )}
      </div>
      <Translations translations={defaultTranslations} validationErrors={isIssuesShown ? validationErrors : []} />
      {notAllLanguagesSelected ? (
        <InactiveTranslations
          translations={hiddenTranslations}
          validationErrors={isIssuesShown ? validationErrors : []}
        />
      ) : null}
      <TabFooter />
    </>
  );
};
