const SAVE = 'TIP_SAVE';
const SAVE_SUCCEEDED = 'TIP_SAVE_SUCCEEDED';
const SET_INSTRUCTIONS_LANGUAGE = 'TIP_SET_INSTRUCTIONS_LANGUAGE';
const SET_PARAGRAPH_LANGUAGE = 'TIP_SET_PARAGRAPH_LANGUAGE';
const SET_EXAMPLES_LANGUAGE = 'TIP_SET_EXAMPLES_LANGUAGE';
const SET_EXAMPLES_CHANGED = 'SET_EXAMPLES_CHANGED';
const SET_TIP_ALL_FIELDS = 'SET_TIP_ALL_FIELDS';

export const TipExerciseActions = {
  SAVE,
  SAVE_SUCCEEDED,
  SET_INSTRUCTIONS_LANGUAGE,
  SET_PARAGRAPH_LANGUAGE,
  SET_EXAMPLES_LANGUAGE,
  SET_EXAMPLES_CHANGED,
  SET_TIP_ALL_FIELDS,
} as const;
