import { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

import DialogueCharacter from '../DialogueCharacter/DialogueCharacter';
import NumberIndicator from './NumberIndicator/NumberIndicator';
import { DialogueCharacterWithNumberProps } from './DialogueCharacterWithNumberProps';

const DialogueCharacterWithNumber = ({
  imgURL,
  onClick,
  selectable,
  showAsActive,
  number,
  showNumber = true,
  isSelectedCharacter,
}: DialogueCharacterWithNumberProps) => {
  let [isToggled, setIsToggled] = useState(false);

  const theme = useTheme();

  useEffect(() => {
    isSelectedCharacter ? setIsToggled(true) : setIsToggled(false);
  }, [isSelectedCharacter]);

  return (
    <div className={`dialogue-character-with-number`}>
      <DialogueCharacter
        imgURL={imgURL}
        onClick={onClick}
        selectable={selectable}
        showAsActive={showAsActive}
        rollovable={selectable}
        isToggled={isToggled}
        onToggled={(toggled: boolean) => {
          if (isSelectedCharacter === undefined) {
            setIsToggled(toggled);
          }
        }}
      />
      {isToggled && showNumber && number !== undefined && <NumberIndicator number={number} color={theme.color.pink1} />}
    </div>
  );
};

export default DialogueCharacterWithNumber;
