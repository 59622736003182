import { useEffect, useRef, useState } from 'react';
import styled, { css, keyframes } from 'styled-components/macro';

const SEARCH_TYPE_ICON_ANIMATION_DURATION = 300; //ms

type Point = {
  x: number;
  y: number;
};

const FINAL_PADDING_TOP = 30;
const FINAL_PADDING_LEFT = 10;
const ORIGINAL_SIZE = 124;
const FINAL_SIZE = 180;

const animationWrapperX = (diff: Point) => keyframes`
  0% {
    transform: translate(0, 0);
  }

  100% {
    height: ${FINAL_SIZE / 10}rem;
    width: ${FINAL_SIZE / 10}rem;
    transform: translate(-${diff.x - (FINAL_SIZE - ORIGINAL_SIZE) - FINAL_PADDING_LEFT}px, 0);
  }
`;

const animationWrapperY = (diff: Point) => keyframes`
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(0, -${diff.y - (FINAL_SIZE - ORIGINAL_SIZE) - FINAL_PADDING_TOP}px);
  }
`;

const IconWrapperX = styled.div<{ isSelected: boolean; diff: Point | null }>`
  margin-right: 0.8rem;
  height: ${ORIGINAL_SIZE / 10}rem;
  width: ${ORIGINAL_SIZE / 10}rem;

  position: absolute;
  bottom: -2.4rem;
  right: 0;

  ${({ isSelected, diff }) =>
    isSelected &&
    diff &&
    css`
      animation-duration: ${SEARCH_TYPE_ICON_ANIMATION_DURATION / 1000}s;
      animation-name: ${animationWrapperX(diff)};
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;
    `}

  svg {
    width: 100%;
    height: 100%;
    transform: rotate(-15deg);
    z-index: 0;
  }
`;

const ImageWrapperY = styled.div<{ isSelected: boolean; diff: Point | null }>`
  height: 100%;
  width: 100%;

  ${({ isSelected, diff }) =>
    isSelected &&
    diff &&
    css`
      animation-duration: ${SEARCH_TYPE_ICON_ANIMATION_DURATION / 1000}s;
      animation-name: ${animationWrapperY(diff)};
      animation-timing-function: cubic-bezier(-0.25, 0, 0.5, 1);
      animation-fill-mode: forwards;
    `}
`;

const SearchTypeCardBottomGap = styled.div`
  background: ${({ theme }) => theme.colorV2.uiBackgroundPrimary};
  height: 2.4rem;
  position: relative;
  z-index: 1;
`;

const StyledSearchTypeCard = styled.div`
  border-radius: 0.8rem;
  box-shadow: 0 0.4rem 1.2rem rgba(0, 0, 0, 0.08);
  color: ${({ theme }) => theme.color.deepBlue};
  cursor: pointer;
  font-size: 1.8rem;
  font-weight: 700;
  height: 16.5rem;
  margin-bottom: 3.2rem;
  padding: 2.4rem;

  &:hover {
    background: ${({ theme }) => theme.color.quaternaryLight2};

    ${SearchTypeCardBottomGap} {
      background: ${({ theme }) => theme.color.quaternaryLight2};
    }
  }
`;

const SearchTypeCardContent = styled.div`
  height: 11.7rem;
  position: relative;

  span {
    position: relative;
    z-index: 1;
  }
`;

const SearchTypeCardTitle = styled.div`
  display: flex;
`;

type SearchTypeCardProps = {
  icon?: JSX.Element;
  howToHelp?: JSX.Element;
  title: string;
  onClick: () => void;
};

export const SearchTypeCard = ({ icon, title, howToHelp, onClick }: SearchTypeCardProps) => {
  const [isSelected, setIsSelected] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const [diff, setDiff] = useState<{ x: number; y: number } | null>(null);

  useEffect(() => {
    if (ref.current) {
      const modal = ref.current.closest('.modal-body');
      const card = ref.current.closest('div');

      if (modal === null || card === null) {
        return;
      }

      const imageBoundingRect = card.getBoundingClientRect();
      const modalBoundingRect = modal.getBoundingClientRect();

      const xDiff = imageBoundingRect.x - modalBoundingRect.x;
      const yDiff = imageBoundingRect.y - modalBoundingRect.y;

      setDiff({ x: xDiff, y: yDiff });
    }
  }, [ref]);

  const handleOnClick = () => {
    setIsSelected(true);
    setTimeout(() => {
      onClick();
    }, SEARCH_TYPE_ICON_ANIMATION_DURATION);
  };

  return (
    <StyledSearchTypeCard onClick={handleOnClick}>
      <SearchTypeCardContent>
        <SearchTypeCardTitle>
          {title}
          {howToHelp}
        </SearchTypeCardTitle>
        <IconWrapperX ref={ref} isSelected={isSelected} diff={diff}>
          <ImageWrapperY isSelected={isSelected} diff={diff}>
            {icon}
          </ImageWrapperY>
        </IconWrapperX>
      </SearchTypeCardContent>
      {!isSelected && <SearchTypeCardBottomGap />}
    </StyledSearchTypeCard>
  );
};
