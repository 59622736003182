import styled from 'styled-components/macro';
import Avatar from 'react-avatar';
import CopyToClipboard from 'react-copy-to-clipboard';
import Tooltip from 'react-tooltip';

import IDDisplayer from '@components/IDDisplayer/IDDisplayer';
import { Flag } from '@components/Flag';
import { mapLanguageV2ToLanguage } from '@features/content/languages';

import { AudioRequestType } from '../../../types';
import { ReactComponent as RemoveIcon } from '../img/removeIcon.svg';
import { StatusContainer } from './StatusContainer';
import { DBId } from '@common/types/DBId';
import { useToast } from '@features/app/toast';

const TableRow = styled.tr`
  min-height: 8rem;
  font-size: 1.6rem;
  box-shadow: 0 0.1rem 0 ${({ theme }) => theme.color.brandGreyscale600};
  display: grid;
  grid-template-columns: 10% 6% 14% 20% 14% 18% 8% 4%;
  grid-column-gap: 1%;
  align-items: center;
`;

const TableItem = styled.td`
  height: 100%;
  border: none !important;
  display: flex;
  align-items: center;
`;

const VerticalTableItem = styled(TableItem)`
  flex-direction: column;
  align-items: start;
`;

const IdContainer = styled(CopyToClipboard)`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
`;

const UserAvatar = styled(Avatar)`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-right: 1.6rem;
`;

export const CancelAudioRequestButton = styled(RemoveIcon)`
  cursor: pointer;
`;

export const CancelAudioRequestTooltip = styled(Tooltip)`
  border-radius: 0.8rem !important;
  font-size: 1.6rem !important;
  font-weight: 500 !important;
`;

type AudioRequestTableItemProps = {
  audioRequest: AudioRequestType;
  showCancelAudioRequest: boolean;
  onCancelAudioRequest: (AudioRequestId: DBId) => void;
};

export const AudioRequestTableItem = ({
  audioRequest,
  showCancelAudioRequest,
  onCancelAudioRequest,
}: AudioRequestTableItemProps) => {
  const date = new Date(audioRequest.requestAt);

  const showToast = useToast();

  return (
    <TableRow>
      <TableItem title={audioRequest.id}>
        <IdContainer
          text={audioRequest.id || 'N/A'}
          onCopy={() => {
            showToast({
              type: 'info',
              title: 'Content ID copied to clipboard',
            });
          }}
        >
          <span>{audioRequest.id}</span>
        </IdContainer>
      </TableItem>
      <TableItem>
        <Flag countries={mapLanguageV2ToLanguage(audioRequest.language)} />
      </TableItem>
      <TableItem>{audioRequest.voiceArtist.name}</TableItem>
      <VerticalTableItem>
        <IDDisplayer id={audioRequest.resourceId} mode="small" truncateAt={10} />
        {audioRequest.phrase}
      </VerticalTableItem>
      <TableItem>
        <StatusContainer status={audioRequest.requestStatus} exportId={audioRequest.exportId || ''} />
      </TableItem>
      <TableItem>
        <UserAvatar name={audioRequest.requester.name} size="40" />
        {audioRequest.requester.name}
      </TableItem>
      <TableItem>{`${date.toLocaleString('default', {
        month: 'short',
      })} ${date.getDate()}, ${date.getFullYear()}`}</TableItem>
      <TableItem>
        {showCancelAudioRequest && (
          <>
            <div data-tip="Cancel audio request" data-for="cancel-audio-request-tooltip">
              <CancelAudioRequestButton
                onClick={() => {
                  onCancelAudioRequest(audioRequest.id);
                }}
              />
            </div>
            <CancelAudioRequestTooltip effect="solid" id="cancel-audio-request-tooltip" />
          </>
        )}
      </TableItem>
    </TableRow>
  );
};
