import { capitalize } from 'lodash';
import styled from 'styled-components/macro';

import { CommonFilterSelector, CommonFilterOptionType } from '@components/SelectorCommonComponents';

const StyledCommonFilterSelector = styled(CommonFilterSelector)`
  width: 100% !important;
`;

type GenderSelectorPropsType = {
  className?: string;
  options: string[];
  value?: string;
  onChange: (selectedOption: CommonFilterOptionType | null) => void;
};

export const AccentSelector = ({ className, options, value, onChange }: GenderSelectorPropsType) => {
  const accentOptions: CommonFilterOptionType[] = options.map((item) => ({
    label: capitalize(item),
    value: item,
  }));

  return (
    <StyledCommonFilterSelector
      className={className}
      isClearable={false}
      options={accentOptions}
      placeholder=""
      value={accentOptions.find((option) => option.value === value) ?? null}
      onChange={onChange}
    />
  );
};
