import { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { CourseEditionActionsCreator } from '@actionCreators/CourseEditionActionsCreator';
import { AnyExerciseInterface } from '@common/types/exercises/AnyExerciseInterface';

import { PreviewModalContent } from '../common';
import { PreviewModalForListContentProps } from '../PreviewModalProps';
import { PreviewModalListSidebar } from './PreviewModalListSidebar';

import './PreviewModalForListContent.scss';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { selectPreviewExercise } from '@selectors/CoursesSelectors';

const ListContentWrapper = styled.div`
  display: flex;
  height: 90vh;
`;

export const PreviewModalForListContent = ({
  onModalClose,
  deviceType,
  setDeviceType,
  answerState,
  setAnswerState,
  exercisesNavigation,
  courseId,
  title,
}: PreviewModalForListContentProps) => {
  const dispatch = useAppDispatch();
  const [isFirstExerciseReadyToLoad, setIsFirstExerciseReadyToLoad] = useState(false);

  const exerciseForPreview: AnyExerciseInterface | null = useAppSelector(selectPreviewExercise);

  const currentPosition = useMemo(
    () => exercisesNavigation.map((ex) => ex.id).indexOf(exerciseForPreview?.content.id || ''),
    [exercisesNavigation, exerciseForPreview],
  );

  const getExerciseForPreview = useCallback(() => {
    dispatch(CourseEditionActionsCreator.getExerciseForActivityPreview(courseId, exercisesNavigation[0].id));
  }, [dispatch, courseId, exercisesNavigation]);

  useEffect(() => {
    if (exercisesNavigation.length && !exerciseForPreview) {
      setIsFirstExerciseReadyToLoad(true);
    }
  }, [exercisesNavigation, isFirstExerciseReadyToLoad, exerciseForPreview]);

  useEffect(() => {
    if (isFirstExerciseReadyToLoad) {
      setIsFirstExerciseReadyToLoad(false);
      getExerciseForPreview();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFirstExerciseReadyToLoad, setIsFirstExerciseReadyToLoad]);

  const onNavigate = (exerciseIndex: number) => {
    setAnswerState(0);
    dispatch(
      CourseEditionActionsCreator.getExerciseForActivityPreview(courseId, exercisesNavigation[exerciseIndex].id),
    );
  };

  return (
    <ListContentWrapper>
      <PreviewModalListSidebar
        exercisesNavigation={exercisesNavigation}
        currentPosition={currentPosition}
        courseId={courseId}
        exerciseForPreview={exerciseForPreview}
        title={title}
        isPreviousDisabled={currentPosition === 0}
        isNextDisabled={currentPosition === exercisesNavigation.length - 1}
        onNavigate={(exerciseIndex: number) => onNavigate(exerciseIndex)}
      />
      <div className="exercise-preview-for-list__content">
        {exerciseForPreview && (
          <PreviewModalContent
            onModalClose={onModalClose}
            type={exerciseForPreview.content.type}
            deviceType={deviceType}
            setDeviceType={setDeviceType}
            answerState={answerState}
            setAnswerState={setAnswerState}
            content={exerciseForPreview.content}
            isForList={true}
          />
        )}
      </div>
    </ListContentWrapper>
  );
};
