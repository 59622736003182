import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { Loader } from '@features/theme';
import IDDisplayer from '@components/IDDisplayer/IDDisplayer';
import { LessonAttachedToIdentityGroupType } from '../types';

const LessonsList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 45rem;
  max-height: 45rem;
  justify-content: start;
  margin-bottom: 2.4rem;
  overflow: auto;
`;

const LessonsListTitle = styled.div`
  font-weight: 700;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.color.deepBlue65};
  margin-bottom: 2.4rem;
  align-self: start;
`;

const NoLessons = styled.div`
  display: flex;
  align-self: center;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.color.deepBlue65};
`;

const LessonWrapper = styled(Link)`
  margin-bottom: 2.4rem;
  padding: 0.5rem;
  border-radius: 0.8rem;
  color: ${({ theme }) => theme.color.brandBlack};

  &:hover {
    text-decoration: none;
    color: ${({ theme }) => theme.color.brandBlack};
    background: ${({ theme }) => theme.color.brandGreyscale500};
  }
`;

const LessonTitle = styled.span`
  font-weight: 700;
  font-size: 1.6rem;
`;

export const IdentityGroupLessonsList = ({
  isNotLoadedYet,
  lessons,
}: {
  isNotLoadedYet: boolean;
  lessons: LessonAttachedToIdentityGroupType[];
}) => (
  <>
    <LessonsListTitle>Mapped lessons to this identity group</LessonsListTitle>
    <LessonsList id="lessons-list">
      {isNotLoadedYet ? (
        <Loader size="L" />
      ) : (
        <>
          {lessons.length ? (
            <>
              {lessons.map((lesson) => (
                <LessonWrapper key={lesson.id} to={`/content/${lesson.id}`} target="_blank">
                  <IDDisplayer id={lesson.id} />
                  <LessonTitle>{lesson.title || 'Untitled lesson'}</LessonTitle>
                </LessonWrapper>
              ))}
            </>
          ) : (
            <NoLessons>No attached lessons</NoLessons>
          )}
        </>
      )}
    </LessonsList>
  </>
);
