import _ from 'lodash';
import { arrayMoveImmutable } from 'array-move';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components/macro';

import { SelectedGroupsOfParentInterface } from '@common/interfaces/groups/SelectedGroupsOfParentInterface';
import { ContentBranches } from '@common/types/AnyExerciseContentBranch';
import { ValidationErrorDisplayer } from '@components/ValidationErrorDisplayer';
import { ValidationErrorInterface } from '@common/interfaces/validation/ValidationInterface';
import ImageUploadUtils from '@components/MediaUpload/ImageUploadUtils';
import { CommonExerciseActionsCreator } from '@actionCreators/CommonExerciseActionsCreator';
import { useIsEditAvailable } from '@features/content/courses';
import { ComponentOtherPlacesDisplayer } from '@components/ComponentOtherPlacesDisplayer';
import { Loader } from '@features/theme';
import { WritableInputText } from '@components/WritableInputText';
import TranslationsTipV2 from '@components/TranslationTipV2/TranslationTipV2';
import { PublishingStatus } from '@components/Publishing/PublishingStatus';
import { ContentType, ContentTypes } from '@common/enums/ContentTypes';
import { ImageUploadModes } from '@common/enums/FileUploadModes';
import IDDisplayer from '@components/IDDisplayer/IDDisplayer';
import { ImageUpload } from '@components/MediaUpload';
import { DraggableList } from '@components/DraggableList/DraggableList';
import { LearningOutcomes } from './LessonMainData/LearningOutcomes/LearningOutcomes';
import { CompleteMessage } from './LessonMainData/CompleteMessage/CompleteMessage';
import {
  type VocabularyBundleResultType,
  VocabularyBundles,
} from './LessonMainData/VocabularyBundles/VocabularyBundles';
import { BaseContentActionsCreator } from '@actionCreators/BaseContentActionsCreator';
import { LessonInterface } from '@common/interfaces/contentTypes/LessonInterface';
import { ContentTypesActionsCreator } from '@actionCreators/ContentTypesActionsCreator';
import { LoadingStage } from '@common/enums/LoadingStage';
import helpersService from '@services/HelpersService';
import { AccessWarning } from '@components/Warning';
import { DBId } from '@common/types/DBId';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { GrammarTopicLabel } from '@features/content/grammar';
import {
  LESSON_ROLEPLAY,
  LESSON_CERTIFICATE,
  LESSON_CHECKPOINT,
  LESSON_LIVE,
  LESSON_SPEAKING,
  LessonType,
} from '@common/enums/LessonTypes';
import { IdentityGroupSelector } from '@features/progressMatching';
import { HelpDisplayer } from '@features/help';
import { CertificateLessonTypeSelector } from './LessonMainData/CertificateLessonTypeSelector';
import {
  selectCourse,
  selectLoadedExercises,
  selectSelectedGroupsOfParent,
  selectSelectedGroupsOfParentParentContents,
  selectSelectedGroupsOfParentParentContentsRoleplayScenario,
} from '@selectors/CoursesSelectors';
import { isFeatureEnabled } from '@helpers/featuresHelper';
import { clone } from '@helpers/clone';
import GroupsService from '@services/GroupsService';
import { Language } from '@features/content/languages';
import { ContentAnalyticsIndicator } from '@features/content/contentAnalytics';
import { userTracking } from '@features/app/tracking';
import { AttachScenarioModal, RoleplayScenarioBinder, RoleplayService } from '@features/content/roleplay';
import { Modal, useDialogModal } from '@features/modal';
import { useToast } from '@features/app/toast';
import { ContentCard } from '@components/DraggableList/ContentCard';
import { CourseEditionActionsCreator } from '@actionCreators/CourseEditionActionsCreator';
import { ModalControls } from '@features/modal';

const NonEditableTitleContainer = styled.div`
  font-weight: 700;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colorV2.uiDark};
  font-size: 3rem;
  margin-bottom: 0.4rem;
`;

const NonEditableDescriptionContainer = styled.div`
  color: ${({ theme }) => theme.colorV2.textTertiary};
  font-size: 1.8rem;
`;

const LessonTitleWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 0.4rem;
`;

const StyledTranslationsTipV2 = styled(TranslationsTipV2)`
  flex: 1;
`;

const CONTENT_BRANCH_TITLES: Record<ContentBranches, string> = {
  titleWithLocalizations: 'title',
  descriptionWithLocalizations: 'description',
  completeMessage: 'complete message',
  learningOutcomes: 'learning outcomes',
};

const getCreatorAcceptableButtons = (lessonType: LessonType) => {
  if (lessonType === LESSON_LIVE) {
    return {
      [ContentTypes.slidePdf]: true,
      [ContentTypes.slidePptx]: true,
    };
  }

  if (isFeatureEnabled('speakingBites') && lessonType === LESSON_SPEAKING) {
    return {
      [ContentTypes.speakingActivity]: true,
    };
  }

  return {
    [ContentTypes.activity]: true,
  };
};

const getDefaultContextForTranslators = (focus: string, visitedBranch: ContentBranches) => {
  if (focus) {
    return `${_.startCase(focus)} lesson ${CONTENT_BRANCH_TITLES[visitedBranch]}`;
  }
};

export const LessonMainData = () => {
  const { isEditAvailable } = useIsEditAvailable();
  const dispatch = useAppDispatch();
  let { courseId } = useParams<{ courseId: DBId }>();

  const loadedCourse = useAppSelector(selectCourse);
  const selectedGroupsOfParent: SelectedGroupsOfParentInterface = useAppSelector(selectSelectedGroupsOfParent);
  const loadedContent = useAppSelector(selectSelectedGroupsOfParentParentContents) as LessonInterface;
  const isRoleplayLesson = loadedContent?.lessonType === LESSON_ROLEPLAY;
  const isCheckpointLesson = loadedContent?.lessonType === LESSON_CHECKPOINT;
  const isCertificateLesson = loadedContent?.lessonType === LESSON_CERTIFICATE;
  const isLiveLesson = loadedContent?.lessonType === LESSON_LIVE;
  const isGrammarLesson = loadedContent?.focus === 'grammar';

  const exercisesOfParent = useAppSelector(selectLoadedExercises);
  const roleplayScenario = useAppSelector(selectSelectedGroupsOfParentParentContentsRoleplayScenario);

  const [isFetchingVocabularyReviewBundles, setIsFetchingVocabularyReviewBundle] = useState(false);
  const [vocabularyReviewBundles, setVocabularyReviewBundles] = useState<VocabularyBundleResultType[]>([]);

  const showToast = useToast();

  const attachScenarioToLesson = (scenarioId: DBId, modalControls: ModalControls) => {
    RoleplayService.attachScenarioToLesson(scenarioId, loadedContent.id)
      .then(() => {
        modalControls.close();

        showToast({
          type: 'success',
          title: 'Scenario succesfully attached',
        });

        dispatch(CourseEditionActionsCreator.getGroup(loadedContent.id, loadedCourse.id));
      })
      .catch((error: any) => {
        showToast({
          type: 'error',
          title: 'Error',
          description: error.message ?? 'An error ocurred when attaching a scenario',
        });
      });
  };

  const { open: openAttachScenarioModal, modal: attachScenarioModal } = useDialogModal((modalControls) => (
    <Modal lockScroll size="M" {...modalControls}>
      <AttachScenarioModal
        isOpen={modalControls.isOpen}
        onAttach={(scenarioId) => {
          attachScenarioToLesson(scenarioId, modalControls);
        }}
        close={() => {
          modalControls.close();
        }}
      />
    </Modal>
  ));

  const titleValidationErrors = loadedContent?.validation?.errors.filter(
    (error: ValidationErrorInterface) => error.field === 'title',
  );
  const descriptionValidationErrors = loadedContent?.validation?.errors.filter(
    (error: ValidationErrorInterface) => error.field === 'description',
  );
  const childrenValidationErrors = loadedContent?.validation?.errors.filter(
    (error: ValidationErrorInterface) => error.field === 'children' || error.field === 'exercises',
  );
  const lessonUnitImageValidationErrors = loadedContent?.validation?.errors.filter(
    (error: ValidationErrorInterface) => error.field === 'thumbnailImage',
  );
  const lessonImageValidationErrors = loadedContent?.validation?.errors.filter(
    (error: ValidationErrorInterface) => error.field === 'image',
  );

  const getVocabularyReviewBundles = async () => {
    try {
      if (!vocabularyReviewBundles.length) {
        setIsFetchingVocabularyReviewBundle(true);
        const {
          data: { bundles, count = 0 },
        } = await GroupsService.lessons.getVocabularyReviewBundles(
          loadedContent.id,
          loadedCourse.learningLanguage as Language,
        );

        if (count > 0) {
          setVocabularyReviewBundles(bundles);
        }

        setIsFetchingVocabularyReviewBundle(false);
      }
    } catch (error) {
      console.error();
    }
  };

  if (isCheckpointLesson) {
    return (
      <div className="course-composer">
        <div className="course-edition__first-top-element">
          <PublishingStatus ready={loadedContent.ready} changeStatus={loadedContent.changeStatus} />
          <Row style={{ padding: '0', margin: '0' }}>
            <Col style={{ padding: '0', margin: '0' }}>
              <div id={`lesson-${loadedContent.id}`}>
                <IDDisplayer id={loadedContent.id} mode="normal" />
                <ComponentOtherPlacesDisplayer
                  mappings={loadedContent?.mappings}
                  mappingsPath={loadedContent?.mappingsPath}
                />
                <NonEditableTitleContainer>
                  Checkpoint
                  <HelpDisplayer type="guideline" id="guideline-checkpoint" />
                </NonEditableTitleContainer>
                <NonEditableDescriptionContainer>
                  Take a quiz to jump ahead to the next chapter
                </NonEditableDescriptionContainer>
              </div>
            </Col>
          </Row>
          {!isEditAvailable && <AccessWarning />}
        </div>
        <LearningOutcomes
          getDefaultContextForTranslators={getDefaultContextForTranslators}
          loadedContent={loadedContent}
        />
        <VocabularyBundles
          content={vocabularyReviewBundles}
          learningLanguage={loadedCourse.learningLanguage}
          loading={isFetchingVocabularyReviewBundles}
          onClick={getVocabularyReviewBundles}
        />
        <CompleteMessage
          loadedContent={loadedContent}
          getDefaultContextForTranslators={getDefaultContextForTranslators}
        />
      </div>
    );
  }

  if (isRoleplayLesson) {
    return (
      <>
        <div className="course-composer">
          <div className="course-edition__first-top-element">
            <PublishingStatus ready={loadedContent.ready} changeStatus={loadedContent.changeStatus} />
            <Row style={{ padding: '0', margin: '0 0 4rem 0' }}>
              <Col style={{ padding: '0', margin: '0' }}>
                <div id={`lesson-${loadedContent.id}`}>
                  <IDDisplayer id={loadedContent.id} mode="normal" />
                  <ComponentOtherPlacesDisplayer
                    mappings={loadedContent?.mappings}
                    mappingsPath={loadedContent?.mappingsPath}
                  />
                  <NonEditableTitleContainer>
                    {roleplayScenario?.title ?? 'AI Roleplay'}
                    <HelpDisplayer type="guideline" id="guideline-ai-roleplay" />
                  </NonEditableTitleContainer>
                  <NonEditableDescriptionContainer>
                    Introducing Busuu's AI Roleplay Lessons
                  </NonEditableDescriptionContainer>
                </div>
              </Col>
            </Row>
            {!isEditAvailable && <AccessWarning />}
            {selectedGroupsOfParent.loaded !== LoadingStage.loaded ? (
              <Loader size="L" />
            ) : (
              <>
                {roleplayScenario ? (
                  <ContentCard
                    arrayOfChildren={[]}
                    content={{
                      ...roleplayScenario,
                      type: ContentTypes.roleplayScenario,
                    }}
                    showRemove={false}
                  />
                ) : (
                  <RoleplayScenarioBinder
                    isEditAvailable={isEditAvailable}
                    isPreEnabled
                    onBind={() => openAttachScenarioModal()}
                  />
                )}
              </>
            )}
          </div>
        </div>
        {attachScenarioModal}
      </>
    );
  }

  return (
    <div className="course-composer">
      <div className="course-edition__first-top-element">
        <div className="course-edition__publish-status-and-other-places-container">
          <PublishingStatus ready={loadedContent.ready} changeStatus={loadedContent.changeStatus} />
          <ComponentOtherPlacesDisplayer
            mappings={loadedContent?.mappings}
            mappingsPath={loadedContent?.mappingsPath}
          />
          {isGrammarLesson && loadedContent.grammarTopic && (
            <GrammarTopicLabel
              link={`/course/${courseId}/grammar-review/category/${loadedContent.grammarTopic.categoryId}/topic/${loadedContent.grammarTopic.id}`}
              title={loadedContent.grammarTopic.title || 'Untitled topic'}
            />
          )}
        </div>
        <Row style={{ padding: '0', margin: '0' }}>
          <Col style={{ padding: '0', margin: '0' }}>
            <div id={`lesson-${loadedContent.id}`}>
              <IDDisplayer id={loadedContent.id} mode="normal" />
              <LessonTitleWrapper>
                {!isLiveLesson && loadedContent?.contentAnalytics && (
                  <ContentAnalyticsIndicator
                    data={loadedContent.contentAnalytics.data}
                    status={loadedContent.contentAnalytics.status}
                    onDataDisplayed={() => {
                      userTracking.logosCompletionRatesSeen({
                        lesson_id: loadedContent.id,
                      });
                    }}
                  />
                )}
                <StyledTranslationsTipV2
                  className="content-name"
                  content={loadedContent}
                  defaultContextForTranslators={getDefaultContextForTranslators(
                    loadedContent?.focus,
                    'titleWithLocalizations',
                  )}
                  errors={titleValidationErrors}
                  isExercise={false}
                  isLesson={true}
                  type={ContentType.levelOrLesson}
                  visitedBranch="titleWithLocalizations"
                >
                  <WritableInputText
                    fontSize="30"
                    bold
                    withoutBorder
                    placeholder={'Untitled lesson'}
                    id="lesson-title-input"
                  />
                </StyledTranslationsTipV2>
              </LessonTitleWrapper>
              <TranslationsTipV2
                className="content-description"
                content={loadedContent}
                defaultContextForTranslators={getDefaultContextForTranslators(
                  loadedContent?.focus,
                  'descriptionWithLocalizations',
                )}
                errors={descriptionValidationErrors}
                isExercise={false}
                isLesson={true}
                type={ContentType.levelOrLesson}
                visitedBranch="descriptionWithLocalizations"
              >
                <WritableInputText
                  withGreyColor
                  withoutBorder
                  placeholder={'Lesson description'}
                  id="lesson-description-input"
                />
              </TranslationsTipV2>
              <ValidationErrorDisplayer
                text={
                  lessonImageValidationErrors?.length
                    ? 'Lesson image is missing. Go back to chapter page to upload it.'
                    : ''
                }
              />
              {isCertificateLesson && <CertificateLessonTypeSelector content={loadedContent} />}
              {!isCheckpointLesson && <IdentityGroupSelector identityGroup={loadedContent.identityGroup} />}
            </div>
          </Col>

          {!isLiveLesson && (
            <Col sm={4} className="course-edition__file-upload">
              <ImageUpload
                mode={ImageUploadModes['no-text']}
                text="Upload unit image (exactly 2048x2048)"
                imageData={ImageUploadUtils.getImageDataOutsideExercise(loadedContent.thumbnailImage)}
                onProcessingFinished={(url: string) => {
                  dispatch(
                    CommonExerciseActionsCreator.setValueAfterProcessing({
                      url,
                      mediaType: 'image',
                      type: ContentTypes.lesson,
                      field: 'thumbnailImage',
                      language: 'EN',
                    }),
                  );
                }}
                previewMode={false}
                onChange={(file, progressHandler) => {
                  dispatch(
                    BaseContentActionsCreator.uploadUnitImageToLesson({
                      lessonId: loadedContent.categoryId as DBId,
                      contentId: loadedContent.id,
                      contentIdBeingUpdated: loadedContent.thumbnailImage?._id,
                      file,
                      courseId,
                      progressHandler,
                    }),
                  );
                }}
                onRemove={() => {
                  dispatch(
                    BaseContentActionsCreator.removeUnitImageFromLesson({
                      lessonId: loadedContent.categoryId as DBId,
                      contentId: loadedContent.id,
                      courseId,
                      contentIdBeingUpdated: loadedContent.thumbnailImage?._id,
                    }),
                  );
                }}
                fieldName={'thumbnailImage'}
                errors={lessonUnitImageValidationErrors}
              />
            </Col>
          )}
        </Row>
        {!isEditAvailable && <AccessWarning />}
      </div>

      <LearningOutcomes
        loadedContent={loadedContent}
        getDefaultContextForTranslators={getDefaultContextForTranslators}
      />
      <VocabularyBundles
        content={vocabularyReviewBundles}
        learningLanguage={loadedCourse.learningLanguage}
        loading={isFetchingVocabularyReviewBundles}
        onClick={getVocabularyReviewBundles}
      />

      {(!isLiveLesson && selectedGroupsOfParent.groupsLoaded !== LoadingStage.loaded) ||
      (isLiveLesson && exercisesOfParent.loaded !== LoadingStage.loaded) ? (
        <Loader size="L" />
      ) : (
        <DraggableList
          arrayOfChildren={isLiveLesson ? exercisesOfParent.exercises : selectedGroupsOfParent.groups}
          onDragEnd={(result) => {
            const items = isLiveLesson ? exercisesOfParent.exercises : selectedGroupsOfParent.groups;

            result.destination &&
              dispatch(
                ContentTypesActionsCreator.setOrder(
                  arrayMoveImmutable(clone(items), result.source.index, result.destination.index),
                  result.source.index,
                  result.destination.index,
                  ContentTypes.lesson,
                  loadedContent.id,
                  loadedCourse.id,
                ),
              );
          }}
          onContentCreate={(contentTypeToCreate, contentCategory, position) => {
            dispatch(
              ContentTypesActionsCreator.onCreateContent(
                loadedCourse.id,
                contentTypeToCreate,
                ContentTypes.lesson,
                loadedContent.id,
                contentCategory,
                position,
              ),
            );
          }}
          creatorAcceptableButtons={getCreatorAcceptableButtons(loadedContent?.lessonType)}
        />
      )}
      <ValidationErrorDisplayer text={helpersService.getValidationErrorMessageText(childrenValidationErrors)} />
    </div>
  );
};
