import { useState, useCallback } from 'react';
import styled from 'styled-components';

import { ContentTypes } from '@common/enums/ContentTypes';

import { DashboardTableItemsInterface } from '../../types';
import { orderTriangle, sortBy } from './TableHelpers';

const SortRow = styled.div`
  display: grid;
  grid-template-columns: 52% 24% 24%;
  border-bottom: 0.1rem solid ${({ theme }) => theme.color.brandGreyscale600};
  color: ${({ theme }) => theme.color.brandGreyscale800};
  font-size: 14px;
  padding: 8px;
`;

const SortTitle = styled.span`
  &:hover {
    cursor: pointer;
  }
`;

export type SortableFieldType = {
  ascending: boolean;
  field: string;
  fieldType?: string;
};

type CoursesTableProps = {
  filteredItems: DashboardTableItemsInterface[];
  setFilteredItems: (courses: DashboardTableItemsInterface[]) => void;
};

export const SortControllers = ({ filteredItems, setFilteredItems }: CoursesTableProps) => {
  const [orderByField, setOrderByField] = useState({
    field: 'name',
    ascending: true,
  });

  const sortOrder = useCallback(
    (newFieldOrder: SortableFieldType) => {
      const filteredAllCourses = sortBy(
        filteredItems,
        newFieldOrder.field,
        ContentTypes.string,
        newFieldOrder.ascending,
      );
      setOrderByField({
        field: newFieldOrder.field,
        ascending: newFieldOrder.ascending,
      });

      setFilteredItems(filteredAllCourses as DashboardTableItemsInterface[]);
    },
    [filteredItems, setFilteredItems],
  );

  return (
    <SortRow>
      <div>
        <SortTitle onClick={() => sortOrder({ field: 'title', ascending: !orderByField.ascending })}>Title</SortTitle>
        {orderTriangle(orderByField, 'title')}
      </div>
      <div>
        <SortTitle onClick={() => sortOrder({ field: 'learningLanguage', ascending: !orderByField.ascending })}>
          Course language
        </SortTitle>
        {orderTriangle(orderByField, 'learningLanguage')}
      </div>
      <div>
        <SortTitle onClick={() => sortOrder({ field: 'interfaceLanguages', ascending: !orderByField.ascending })}>
          Interface language
        </SortTitle>
        {orderTriangle(orderByField, 'interfaceLanguages')}
      </div>
    </SortRow>
  );
};
