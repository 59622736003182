import styled from 'styled-components/macro';
import { useState } from 'react';

import { Button } from '@features/theme';
import { InfoModalContent, Modal, useDialogModal } from '@features/modal';
import { DBId } from '@common/types/DBId';
import { PublishingActionsCreator } from '@actionCreators/PublishingActionCreator';
import { Loader } from '@features/theme';
import { bulkPublish, bulkUnpublish } from '../services';
import { BulkPublishingActionsCreator } from '@actionCreators/BulkPublishingActionsCreator';
import { useBulkPublishing } from '../_hooks';
import { useToast } from '@features/app/toast';
import { useAppDispatch } from '@redux/store';
import { ListOfLinks } from '@components/ListOfLinks';

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

const Title = styled.span`
  color: ${({ theme }) => theme.color.brandBlack};
  font-weight: 800;
  font-size: 2rem;
  margin-bottom: 0.8rem;
`;

const Description = styled.span`
  color: ${({ theme }) => theme.color.brandGreyscale800};
  font-weight: 400;
  font-size: 1.6rem;
  margin-bottom: 3.2rem;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;

export const BulkPublishingModalContent = ({
  parentId,
  parentType,
  close,
}: {
  parentId: DBId;
  parentType: string;
  close: () => void;
}) => {
  const [isPublishInProgress, setIsPublishInProgress] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [invalidExercises, setInvalidExercises] = useState<string[] | null>(null);

  const { selectedForBulkPublishing, currentBulkPublishingState } = useBulkPublishing();

  const dispatch = useAppDispatch();

  const showToast = useToast();

  const { open: openBulkPublishErrorModal, modal: publishMultipleErrorModal } = useDialogModal((modalControls) => (
    <Modal {...modalControls}>
      <InfoModalContent
        testIdPrefix="multi-publish-error"
        title="Content cannot be published!"
        description={errorMessage}
        close={() => {
          setErrorMessage(null);
          modalControls.close();
          setIsPublishInProgress(false);
          close();
        }}
      >
        {invalidExercises && <ListOfLinks ids={invalidExercises} />}
      </InfoModalContent>
    </Modal>
  ));

  const handlePublish = async () => {
    setIsPublishInProgress(true);
    try {
      if (currentBulkPublishingState === 'publish') {
        await bulkPublish(selectedForBulkPublishing, parentId);
        dispatch(PublishingActionsCreator.changeReadyStateOnMultiPublish(selectedForBulkPublishing));
      } else {
        await bulkUnpublish(selectedForBulkPublishing, parentId, parentType);
        dispatch(PublishingActionsCreator.changeReadyStateOnMultiUnpublish(selectedForBulkPublishing));
      }

      showToast({
        type: 'success',
        title: 'Added to pending changes',
        description: 'Pending changes will be updated on production with the next content deploy',
      });

      setIsPublishInProgress(false);
      close();
      dispatch(BulkPublishingActionsCreator.disableAll());
    } catch (e: any) {
      if (currentBulkPublishingState === 'publish') {
        setErrorMessage(e.response.data.detail);
        openBulkPublishErrorModal();
        setInvalidExercises(e.response.data.payload.exercises || []);
      } else {
        showToast({
          type: 'error',
          title: 'Content can not be unpublished',
          description: e?.response?.data?.detail,
        });

        setIsPublishInProgress(false);
      }
    }
  };

  return (
    <ModalWrapper>
      {publishMultipleErrorModal}
      <Title>Are you sure you want to {currentBulkPublishingState === 'publish' ? 'publish' : 'unpublish'}?</Title>
      <Description>Pending changes will be updated on production with the next content deploy.</Description>

      <ButtonsContainer>
        <Button variant="primary" onClick={handlePublish}>
          {isPublishInProgress ? <Loader /> : currentBulkPublishingState === 'publish' ? 'Publish' : 'Unpublish'}
        </Button>
        <Button variant="secondary" onClick={() => !isPublishInProgress && close()}>
          Cancel
        </Button>
      </ButtonsContainer>
    </ModalWrapper>
  );
};
