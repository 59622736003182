import { takeLatest } from 'redux-saga/effects';

import { PhraseBuilderExerciseActions } from '@actions/PhraseBuilderExerciseActions';
import PhraseBuilderExerciseSagas from '@sagas/exercises/definitions/PhraseBuilderExerciseSagasDefinition';
import ExerciseSagas from '../definitions/ExerciseSagas';
import type { AppDispatch } from '@redux/store';

export function* PhraseBuilderExerciseRelatedSagas(dispatch: AppDispatch) {
  yield takeLatest(PhraseBuilderExerciseActions.SAVE, PhraseBuilderExerciseSagas.save, dispatch);
  yield takeLatest(PhraseBuilderExerciseActions.REMOVE_AUDIO, ExerciseSagas.removeAudioFromExercise);
}
