const SAVE = 'TRUEFALSE_EXERCISE_SAVE';
const UPLOAD_IMAGE = 'TRUEFALSE_EXERCISE_UPLOAD_IMAGE';
const SET_STATEMENT_AS = 'TRUEFALSE_SET_STATEMENT_AS';
const SET_INSTRUCTIONS_LANGUAGE = 'TRUEFALSE_SET_INSTRUCTIONS_LANGUAGE';
const SET_STATEMENT_LANGUAGE = 'TRUEFALSE_SET_STATEMENT_LANGUAGE';
const REMOVE_IMAGE = 'TRUEFALSE_REMOVE_IMAGE';
const REMOVE_AUDIO = 'TRUEFALSE_REMOVE_AUDIO';
const REMOVE_MAIN_CONTENT = 'TRUEFALSE_REMOVE_MAIN_CONTENT';
const SET_TRUE_FALSE_ALL_FIELDS = 'SET_TRUE_FALSE_ALL_FIELDS';

export const TrueFalseExerciseActions = {
  SAVE,
  UPLOAD_IMAGE,
  SET_STATEMENT_AS,
  SET_INSTRUCTIONS_LANGUAGE,
  SET_STATEMENT_LANGUAGE,
  REMOVE_IMAGE,
  REMOVE_AUDIO,
  REMOVE_MAIN_CONTENT,
  SET_TRUE_FALSE_ALL_FIELDS,
} as const;
