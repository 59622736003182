import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import BreadcrumbNavigation from '@components/BreadcrumbNavigation/BreadcrumbNavigation';
import { useIsAccessCourseStats } from '@features/auth/roles';
import { CourseStatsHeader } from './CourseStatsHeader';
import { CourseStatsTable } from './CourseStatsTable';
import { CourseStatsTitle } from './CourseStatsTitle';

const CourseStatsWrapper = styled.div`
  margin: 0 4rem;
  width: 100%;
`;

export const CourseStatsPage = () => {
  const history = useHistory();
  const isAccessCourseStats = useIsAccessCourseStats();

  useEffect(() => {
    if (!isAccessCourseStats) {
      history.replace('/');
    }
  }, [isAccessCourseStats, history]);

  return (
    <CourseStatsWrapper>
      <CourseStatsHeader />
      <BreadcrumbNavigation breadcrumbItems={[<span>Course stats</span>]} />
      <CourseStatsTitle />
      <CourseStatsTable />
    </CourseStatsWrapper>
  );
};
