import { ExerciseTypes } from '@common/enums/ExerciseTypes';
import { ExerciseRelatedErrorsInterface } from '@common/interfaces/contentTypes/ResourceBundleInterface';
import { CourseSliceInterface } from '@common/interfaces/slices/CourseSliceInterface';
import { ValidationErrorInterface } from '@common/interfaces/validation/ValidationInterface';

interface ChildrenStatusesInterface {
  id: string;
  validationStatus: {
    errors: ValidationErrorInterface[];
    exercisesRelatedErrors: ExerciseRelatedErrorsInterface[];
    valid: boolean;
  };
}

export const setBundleErrorsToValidation = (
  bundleName: string,
  exerciseErrors: ValidationErrorInterface[],
  bundleErrors: ValidationErrorInterface[],
) => {
  return [
    ...exerciseErrors,
    ...bundleErrors
      .map((error: ValidationErrorInterface) => ({
        field: `${bundleName}.${error.field}`,
        message: error.message,
        isWarning: false,
      }))
      .filter((error: ValidationErrorInterface) => {
        return !exerciseErrors.find(
          (exerciseError) => exerciseError.field === error.field && exerciseError.message === error.message,
        );
      }),
  ];
};

export const updateBundleValidationStatus = (
  clonedCourseSlice: CourseSliceInterface,
  childrenStatuses: ChildrenStatusesInterface[],
) => {
  const bundleErrorsOtherPlaces = childrenStatuses.map((item: ChildrenStatusesInterface) => ({
    ...item,
    validationStatus: { exerciseRelatedErrors: item.validationStatus.exercisesRelatedErrors },
  }));

  const updateErrors = (bundleName: string) => {
    const bundleId = clonedCourseSlice.loadedExercise.exercise.content[bundleName]?._id;
    const errorForThisBundleInOtherPlace = bundleErrorsOtherPlaces.find((item: any) => bundleId === item.id);
    if (bundleId && errorForThisBundleInOtherPlace) {
      clonedCourseSlice.loadedExercise.exercise.content[bundleName].validationStatus =
        errorForThisBundleInOtherPlace.validationStatus;
      const validationErrors = childrenStatuses.find((item: any) => bundleId === item.id);
      if (validationErrors?.validationStatus.errors) {
        clonedCourseSlice.loadedExercise.exercise.content.validation.valid =
          validationErrors.validationStatus.valid && clonedCourseSlice.loadedExercise.exercise.content.validation.valid;
        clonedCourseSlice.loadedExercise.exercise.content.validation.errors = setBundleErrorsToValidation(
          bundleName,
          clonedCourseSlice.loadedExercise.exercise.content.validation.errors,
          validationErrors.validationStatus.errors,
        );
      }
    }
  };

  if (bundleErrorsOtherPlaces && bundleErrorsOtherPlaces.length) {
    switch (clonedCourseSlice.loadedExercise.exercise.content.type) {
      case ExerciseTypes.comprehension:
      case ExerciseTypes.phraseBuilder:
      case ExerciseTypes.speechRecognition:
      case ExerciseTypes.spelling:
      case ExerciseTypes.trueFalse:
      case ExerciseTypes.typing:
        updateErrors('mainBundle');
        break;
      case ExerciseTypes.flashcard:
        updateErrors('learningWordBundle');
        break;
      case ExerciseTypes.fillgap:
        updateErrors('mainBundle');
        updateErrors('distractor1');
        updateErrors('distractor2');
        break;
      case ExerciseTypes.matchup:
        updateErrors('fixedItem1');
        updateErrors('fixedItem2');
        updateErrors('fixedItem3');
        updateErrors('matchingItem1');
        updateErrors('matchingItem2');
        updateErrors('matchingItem3');
        break;
      case ExerciseTypes.multipleChoice:
        updateErrors('answerBundle');
        updateErrors('distractor1');
        updateErrors('distractor2');
        break;
      default:
        break;
    }
  }

  return clonedCourseSlice;
};
