import { CSSProperties } from 'react';
import styled from 'styled-components';

export type BoxProps = Pick<
  CSSProperties,
  | 'alignItems'
  | 'backgroundColor'
  | 'border'
  | 'display'
  | 'flexBasis'
  | 'flexDirection'
  | 'flexGrow'
  | 'flexShrink'
  | 'height'
  | 'gap'
  | 'width'
  | 'marginBottom'
>;

export const Box = styled.div<BoxProps>(
  ({
    alignItems,
    border,
    backgroundColor,
    display = 'flex',
    flexBasis,
    flexDirection,
    flexGrow,
    flexShrink,
    height,
    gap = '8px',
    width,
    marginBottom,
  }) => ({
    alignItems,
    backgroundColor,
    border,
    display,
    flexBasis,
    flexDirection,
    flexGrow,
    flexShrink,
    height,
    gap,
    width,
    marginBottom,
  }),
);
