import styled from 'styled-components/macro';
import { FC, useEffect, useMemo, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';

import BreadcrumbNavigation from '@components/BreadcrumbNavigation/BreadcrumbNavigation';
import { useIsAdmin } from '@features/auth/roles';
import { AudioManagementHeader } from './AudioManagementHeader';
import { AudioRequestsTable } from './AudioRequestsTable';
import { VoiceArtistsTable } from './VoiceArtistsTable';

const TabWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1.6rem;
  position: relative;
`;

const StyledTabs = styled(Tabs)`
  margin-bottom: 0;
`;

const AudioManagementWrapper = styled.div`
  margin: 0 4rem;
  width: 100%;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 3rem;
  margin-bottom: 5.6rem;
`;

type AudioManagementTabKey = 'audioRequests' | 'voiceArtists';
type AudioManagementTab = { key: AudioManagementTabKey; title: string; Component: FC };

export const AudioManagementPage = () => {
  const isAdmin = useIsAdmin();

  const history = useHistory();
  const { search } = useLocation();

  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  const [selectedTab, setSelectedTab] = useState<AudioManagementTabKey | null>(null);

  useEffect(() => {
    if (isAdmin && searchParams.get('currentTab')) {
      setSelectedTab(searchParams.get('currentTab') as AudioManagementTabKey);
    } else {
      setSelectedTab('audioRequests');
    }
  }, [isAdmin, searchParams]);

  const tabs: AudioManagementTab[] = [
    {
      key: 'audioRequests',
      title: 'Audio Requests',
      Component: () => <AudioRequestsTable />,
    },
  ];

  if (isAdmin) {
    tabs.push({
      key: 'voiceArtists',
      title: 'Voice Artists',
      Component: () => <VoiceArtistsTable />,
    });
  }

  return (
    <AudioManagementWrapper>
      <AudioManagementHeader />
      <BreadcrumbNavigation breadcrumbItems={[<span>Audio Management</span>]} />
      <Title>Audio Management</Title>

      <StyledTabs
        activeKey={selectedTab}
        onSelect={(selectedTabString: AudioManagementTab['key']) => {
          if (selectedTabString) {
            setSelectedTab(selectedTabString);
            searchParams.set('currentTab', selectedTabString);
            history.replace({ search: searchParams.toString() });
          }
        }}
      >
        {tabs.map(({ key, title, Component }) => (
          <Tab eventKey={key} title={title} key={key}>
            <TabWrapper>{selectedTab === key && <Component />}</TabWrapper>
          </Tab>
        ))}
      </StyledTabs>
    </AudioManagementWrapper>
  );
};
