import 'draft-js/dist/Draft.css';
import KTagsCreator from '@components/Exercises/Common/KTagsCreator/KTagsCreator';
import TranslationsTipV2 from '@components/TranslationTipV2/TranslationTipV2';
import HighlighterToolProps from './HighlighterToolProps';

const MAX_CHARACTERS = 8;

export const HighlighterTool = ({
  value,
  placeholder,
  className,
  fieldName,
  row,
  showKTags = false,
  dataSourceMode = 'editorState',
  errors = [],
}: HighlighterToolProps) => (
  <TranslationsTipV2
    visitedBranch={fieldName}
    row={row}
    errors={errors}
    showErrorDisplayer={false}
    hideRemove={true}
    hideSearch={true}
    flipped={false}
    showLanguageSwitcher={false}
  >
    <KTagsCreator
      value={value}
      placeholder={placeholder}
      className={className}
      showKTags={showKTags}
      highlighterMode
      dataSourceMode={dataSourceMode}
      maxCharacters={MAX_CHARACTERS}
      errors={errors}
    />
  </TranslationsTipV2>
);
