import { type DisplayContentToUserModeType } from '@common/enums/DisplayContentToUserMode';
import { CommonActions } from '@actions/CommonActions';
import { Language } from '@features/content/languages';
import { ContentTypes } from '@common/enums/ContentTypes';
import { FormikValuesInterface } from '@helpers/formikInitialValuesHelper';
import { ComprehensionExerciseActions } from '@actions/ComprehensionExerciseActions';
import { ComprehensionOptions } from '@components/Exercises/Comprehension/types/ComprehensionOptions';
import ComprehensionExerciseInterface from '@components/Exercises/Comprehension/interfaces/ComprehensionExerciseInterface';

export const ComprehensionExerciseActionsCreator = {
  save: (exercise: ComprehensionExerciseInterface, learningLanguage: Language, values: FormikValuesInterface) => ({
    type: ComprehensionExerciseActions.SAVE,
    payload: {
      exercise,
      learningLanguage,
      values,
    },
  }),
  setInstructionsLanguage: (instructionsLanguage: DisplayContentToUserModeType) => ({
    type: ComprehensionExerciseActions.SET_INSTRUCTIONS_LANGUAGE,
    payload: {
      instructionsLanguage,
    },
  }),
  setTitleLanguage: (titleLanguage: DisplayContentToUserModeType, withoutUpdateButtonState: boolean) => ({
    type: ComprehensionExerciseActions.SET_TITLE_LANGUAGE,
    payload: {
      titleLanguage,
      withoutUpdateButtonState,
    },
  }),
  setMainContentLanguage: (mainContentLanguage: DisplayContentToUserModeType) => ({
    type: ComprehensionExerciseActions.SET_MAIN_CONTENT_LANGUAGE,
    payload: {
      mainContentLanguage,
    },
  }),
  removeReadingImage: () => ({
    type: ComprehensionExerciseActions.REMOVE_READING_IMAGE,
  }),
  removeVideo: () => ({
    type: ComprehensionExerciseActions.REMOVE_VIDEO,
  }),
  nullifyProvider: () => ({
    type: CommonActions.NULLIFY_FIELD,
    payload: {
      field: 'provider',
    },
  }),
  nullifyTitle: () => ({
    type: CommonActions.NULLIFY_FIELD,
    payload: {
      field: 'title',
    },
  }),
  setComprehensionOption: (option: ComprehensionOptions) => ({
    type: ComprehensionExerciseActions.SET_COMPREHENSION_OPTION,
    payload: option,
  }),
  setReadingImage: (exercise: ComprehensionExerciseInterface, image: File, progressHandler: Function) => ({
    type: ComprehensionExerciseActions.UPLOAD_READING_IMAGE,
    payload: {
      exercise,
      image,
      exerciseType: ContentTypes.comprehension,
      section: ComprehensionExerciseActions.UPLOAD_READING_IMAGE,
      progressHandler,
    },
  }),
  removeMainText: () => ({
    type: ComprehensionExerciseActions.REMOVE_MAIN_TEXT,
  }),
};
