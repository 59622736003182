import { useMemo } from 'react';
import { components, MultiValue, MultiValueGenericProps, OptionProps } from 'react-select';
import styled from 'styled-components/macro';
import { CommonFilterOptionType } from '../types';
import { CommonFilterMultiSelector } from '@components/SelectorCommonComponents';
import { Checkbox } from '@features/theme';
import { CourseInfoInterface } from '@common/interfaces/courses/CourseInfoInterface';
import { DBId } from '@common/types/DBId';

const OptionContainer = styled.div`
  display: flex;
`;

const CourseNameContainer = styled.div`
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 14rem;
`;

const OptionCourseNameContainer = styled(CourseNameContainer)`
  max-width: 20rem;
`;

const CourseCustomOption = ({
  availableCourses,
  ...props
}: OptionProps<CommonFilterOptionType> & { availableCourses: CourseInfoInterface[] }) => {
  const {
    data: { value },
    isSelected,
  } = props;

  return (
    <components.Option {...props}>
      <OptionContainer key={value}>
        <Checkbox checked={isSelected} onToggle={() => null} />
        <OptionCourseNameContainer>
          {availableCourses.find((course) => course.id === value)?.title || 'Untitled course'}
        </OptionCourseNameContainer>
      </OptionContainer>
    </components.Option>
  );
};

type CourseValueContainerProps = MultiValueGenericProps<MultiValue<CommonFilterOptionType>> & {
  title: string;
  value: MultiValue<CommonFilterOptionType> | null;
};

const CourseValueContainer = ({ children, title, value, ...props }: CourseValueContainerProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let [_, input] = children as any;

  return (
    <components.MultiValueContainer {...props}>
      <CourseNameContainer title={title}>
        {value && value.length > 1 ? `${value.length} selected` : `${value?.[0].label}`}
      </CourseNameContainer>
      {input}
    </components.MultiValueContainer>
  );
};

const CLASSNAME_PREFIX = 'course-selector';

type CourseSelectorProps = {
  availableCourses: CourseInfoInterface[];
  className?: string;
  placeholder?: string;
  rule?: string;
  value: MultiValue<CommonFilterOptionType> | null;
  onChange: (option: MultiValue<CommonFilterOptionType> | null) => void;
};

export const CourseSelector = ({ availableCourses, className, value, onChange }: CourseSelectorProps) => {
  const options = availableCourses?.map((course) => ({ value: course.id, label: course.title })) || [];
  const rule = 'Course';
  const title = useMemo(() => (value?.length ? value.map((course) => course.label).join('\n') : ''), [value]);

  return (
    <CommonFilterMultiSelector
      classNamePrefix={CLASSNAME_PREFIX}
      className={className}
      closeMenuOnSelect={false}
      value={value}
      options={options}
      placeholder={rule}
      rule={rule}
      valueContainer={(props: MultiValueGenericProps<MultiValue<CommonFilterOptionType>>) => (
        <CourseValueContainer title={title} value={value} {...props} />
      )}
      optionContainer={(props: OptionProps<CommonFilterOptionType>) => (
        <CourseCustomOption availableCourses={availableCourses} {...props} />
      )}
      onChange={onChange}
    />
  );
};

type SimpleCourseSelectorProps = Omit<CourseSelectorProps, 'value' | 'onChange' | 'availableCourses'> & {
  courses: CourseInfoInterface[];
  courseFilter: (course: CourseInfoInterface) => boolean;
  value: DBId[] | null;
  onChange: (value: DBId[] | null) => void;
};
export const SimpleCourseSelector = ({
  courses,
  courseFilter,
  value,
  onChange,
  ...restProps
}: SimpleCourseSelectorProps) => {
  const courseTitles = useMemo(() => {
    return courses.reduce(
      (result, course) => {
        result[course.id] = course.title;

        return result;
      },
      {} as Record<string, string>,
    );
  }, [courses]);

  const availableCourses = courses.filter(courseFilter);

  const selectedCourses =
    value !== null
      ? value.map((courseId) => {
          const courseTitle = courseTitles[courseId];

          if (!courseTitle) {
            return { value: courseId, label: courseId }; // This is a case when course is no longer available
          }

          return {
            label: courseTitle,
            value: courseId,
          };
        })
      : null;

  return (
    <CourseSelector
      availableCourses={availableCourses}
      value={selectedCourses}
      onChange={(option) => onChange(option?.length ? option.map((courseOption) => courseOption.value) : null)}
      {...restProps}
    />
  );
};
