const SAVE = 'MULTIPLECHOICE_SAVE';
const SET_INSTRUCTIONS_LANGUAGE = 'MULTIPLECHOICE_SET_INSTRUCTIONS_LANGUAGE';
const SET_CHOICES_LANGUAGE = 'MULTIPLECHOICE_SET_CHOICES_LANGUAGE';
const UPLOAD_MAIN_CONTENT_IMAGE = 'MULTIPLECHOICE_UPLOAD_MAIN_CONTENT_IMAGE';
const UPLOAD_ANSWER_IMAGE = 'MULTIPLECHOICE_UPLOAD_ANSWER_IMAGE';
const UPLOAD_DISTRACTOR1_IMAGE = 'MULTIPLECHOICE_UPLOAD_DISTRACTOR1_IMAGE';
const UPLOAD_DISTRACTOR2_IMAGE = 'MULTIPLECHOICE_UPLOAD_DISTRACTOR2_IMAGE';
const REMOVE_IMAGE = 'MULTIPLECHOICE_REMOVE_IMAGE';
const REMOVE_AUDIO = 'MULTIPLECHOICE_REMOVE_AUDIO';
const REMOVE_ANSWER_IMAGE = 'MULTIPLECHOICE_REMOVE_ANSWER_IMAGE';
const REMOVE_DISTRACTOR1_IMAGE = 'MULTIPLECHOICE_REMOVE_DISTRACTOR1_IMAGE';
const REMOVE_DISTRACTOR2_IMAGE = 'MULTIPLECHOICE_REMOVE_DISTRACTOR2_IMAGE';
const SET_IMAGES_ENABLED = 'MULTIPLECHOICE_SET_IMAGES_ENABLED';
const SET_MULTIPLE_CHOICE_ALL_FIELDS = 'SET_MULTIPLE_CHOICE_ALL_FIELDS';

export const MultipleChoiceExerciseActions = {
  SAVE,
  SET_INSTRUCTIONS_LANGUAGE,
  SET_CHOICES_LANGUAGE,
  UPLOAD_MAIN_CONTENT_IMAGE,
  UPLOAD_ANSWER_IMAGE,
  UPLOAD_DISTRACTOR1_IMAGE,
  UPLOAD_DISTRACTOR2_IMAGE,
  REMOVE_IMAGE,
  REMOVE_AUDIO,
  REMOVE_ANSWER_IMAGE,
  REMOVE_DISTRACTOR1_IMAGE,
  REMOVE_DISTRACTOR2_IMAGE,
  SET_IMAGES_ENABLED,
  SET_MULTIPLE_CHOICE_ALL_FIELDS,
} as const;
