import { useParams } from 'react-router-dom';

import { ValidationErrorDisplayer } from '@components/ValidationErrorDisplayer';
import { LoadingStage } from '@common/enums/LoadingStage';
import { DBId } from '@common/types/DBId';
import { useIsEditAvailable } from '@features/content/courses';
import IDDisplayer from '@components/IDDisplayer/IDDisplayer';
import { PublishingStatus } from '@components/Publishing/PublishingStatus';
import { DraggableList } from '@components/DraggableList/DraggableList';
import helpersService from '@services/HelpersService';
import { HelpDisplayer } from '@features/help';
import { ContentTypes } from '@common/enums/ContentTypes';
import { GrammarReviewActionCreators } from '@actionCreators/GrammarReviewActionCreators';
import { AccessWarning } from '@components/Warning';
import { Loader } from '@features/theme';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { selectGrammarReview } from '@selectors/GrammarSelectors';
import { arrayMoveImmutable } from 'array-move';
import { clone } from '@helpers/clone';

export const GrammarReviewData = () => {
  const dispatch = useAppDispatch();
  const { isEditAvailable } = useIsEditAvailable();
  let { courseId } = useParams<{
    courseId: DBId;
  }>();

  const grammarReview = useAppSelector(selectGrammarReview);
  const { content, categoriesData } = grammarReview;

  const childrenValidationErrors =
    content?.validationStatus?.errors.filter((error) => error.field === 'grammarCategories') || [];

  if (grammarReview.loaded === LoadingStage.loading) {
    return <Loader size="L" />;
  }

  return (
    <>
      <div className="course-composer">
        <div className="course-edition__first-top-element">
          <div className="course-edition__publish-status-and-other-places-container">
            <PublishingStatus
              ready={content ? content.ready : false}
              changeStatus={
                content
                  ? content.changeStatus
                  : {
                      hasNewChanges: false,
                      hasPendingChanges: false,
                    }
              }
            />
          </div>
          <div className="content-common-header__activity-details">
            <IDDisplayer id={content?.id} mode="normal" />
            <div className="content-common-header__content-title content-common-header__content-title--fixed-height">
              <span>Grammar Review</span>
              <HelpDisplayer id="how-to-grammar-review" type="how-to" />
            </div>
          </div>
          {!isEditAvailable && <AccessWarning />}
        </div>

        {categoriesData.loaded !== LoadingStage.loaded ? (
          <Loader size="L" />
        ) : (
          <DraggableList
            arrayOfChildren={categoriesData.categories}
            onDragEnd={(result) => {
              result.destination &&
                dispatch(
                  GrammarReviewActionCreators.setCategoriesOrder(
                    arrayMoveImmutable(clone(categoriesData.categories), result.source.index, result.destination.index),
                    result.source.index,
                    result.destination.index,
                    ContentTypes.grammarReview,
                    courseId,
                    courseId,
                  ),
                );
            }}
            onContentCreate={(contentTypeToCreate, contentCategory, position) => {
              dispatch(GrammarReviewActionCreators.createCategory(courseId, position));
            }}
            creatorAcceptableButtons={{
              [ContentTypes.grammarCategory]: true,
            }}
          />
        )}
        <ValidationErrorDisplayer text={helpersService.getValidationErrorMessageText(childrenValidationErrors)} />
      </div>
    </>
  );
};
