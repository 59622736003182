import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';

import { ContentOwnershipService, SetContentOwnershipPayload } from '@features/content/contentOwnership';
import { ContentOwnershipsActionsCreator } from '@actionCreators/ContentOwnershipActionCreator';
import UsersService from '@services/UsersService';
import { addToast } from '@features/app/toast';

export const ContentOwnershipSagas = {
  *onChangeComponentOwner({ type, payload }: PayloadAction<SetContentOwnershipPayload>) {
    try {
      const { componentId, childComponentId, contentType, owner, commonCallback } = payload;

      yield call(ContentOwnershipService.changeComponentOwner, {
        componentId,
        childComponentId,
        contentType,
        ownerId: owner?.id as string,
      });

      const {
        data: { manager },
      }: Awaited<ReturnType<typeof UsersService.getUser>> = yield call(UsersService.getUser, owner?.id as string);

      const hydratedOwner = {
        id: owner?.id as string,
        fullName: owner?.fullName as string,
        manager,
      };

      yield put(
        ContentOwnershipsActionsCreator.setOwner({
          componentId,
          childComponentId,
          contentType,
          owner: hydratedOwner,
        }),
      );

      if (commonCallback) commonCallback();

      addToast({
        type: 'success',
        title: 'Owner was succesfully assigned',
      });
    } catch (error) {
      addToast({
        type: 'error',
        title: 'An error occurred on assigning owner',
      });

      console.error(error);
    }
  },
};
