import { SelectedGroupsOfParentInterface } from '@common/interfaces/groups/SelectedGroupsOfParentInterface';
import { ContentTypes } from '@common/enums/ContentTypes';
import { NavigationItemType } from '@features/content/navigation';

export const getSelfStudyLessons = (
  structure: NavigationItemType[],
  selectedGroupsOfParent: SelectedGroupsOfParentInterface,
) => {
  let indexForPreviewCounter = 0;

  const parentItem = structure.find(
    (structureItem) => structureItem.id === selectedGroupsOfParent?.parentContents?.categoryId,
  );
  const allChildrenOfParent = structure
    .filter((structureItem) => structureItem.parentId === parentItem?.id)
    .map((structureItem) => {
      if (structureItem.ready && structureItem.type !== ContentTypes.review) {
        indexForPreviewCounter++;
        return {
          ...structureItem,
          indexForPreview: indexForPreviewCounter,
        };
      }
      return structureItem;
    });

  const indexOfCurrentLiveLesson = allChildrenOfParent.findIndex(
    (structureItem) => structureItem.id === selectedGroupsOfParent?.parentContents?.id,
  );

  let allReadyChildrenBeforeCurrentLesson = allChildrenOfParent.slice(0, indexOfCurrentLiveLesson);
  const lastPrevLiveLessonIndex = allReadyChildrenBeforeCurrentLesson
    .map((structureItem) => structureItem.type)
    .lastIndexOf(ContentTypes.liveLesson);

  if (lastPrevLiveLessonIndex > -1) {
    allReadyChildrenBeforeCurrentLesson = allReadyChildrenBeforeCurrentLesson.slice(lastPrevLiveLessonIndex + 1);
  }

  const allReadyNotReviewChildrenBeforeCurrentLesson = allReadyChildrenBeforeCurrentLesson.filter(
    (structureItem) => structureItem.ready && structureItem.type !== ContentTypes.review,
  );

  if (allReadyNotReviewChildrenBeforeCurrentLesson.length) {
    return allReadyNotReviewChildrenBeforeCurrentLesson.reduce((output, item) => `${output} ${item.id}`, '');
  } else {
    return '';
  }
};
