import { FunctionComponent } from 'react';
import classnames from 'classnames';

import { KeyboardShortcut } from './KeyboardShortcut';
import { getCorrectPhrase } from '../../../../../localization-utils';
import { PREVIEW_DEVICE_TYPE } from '../../../constants';
import { DialogueMissedWordsProps } from '../../../PreviewModalProps';

import './DialogueMissedWords.scss';

export const DialogueMissedWords: FunctionComponent<DialogueMissedWordsProps> = ({
  deviceType,
  answerState,
  allMissedWords,
}: DialogueMissedWordsProps) => {
  return (
    <>
      {!answerState ? (
        <>
          {deviceType === PREVIEW_DEVICE_TYPE.MOBILE ? (
            <div className={classnames('dialogue-all-missed-words', `dialogue-all-missed-words--${deviceType}`)}>
              {allMissedWords.map((word: string, index: number) => (
                <>
                  {word && (
                    <span className="dialogue-all-missed-words__word">
                      {getCorrectPhrase(word)}
                      <KeyboardShortcut answerState={answerState} value={index + 1} />
                    </span>
                  )}
                </>
              ))}
            </div>
          ) : (
            <div className="dialogue-all-missed-words-container">
              <div className={classnames('dialogue-all-missed-words', `dialogue-all-missed-words--${deviceType}`)}>
                {allMissedWords.map((word: string, index: number) => (
                  <>
                    {word && (
                      <span className="dialogue-all-missed-words__word">
                        {getCorrectPhrase(word)}
                        <KeyboardShortcut answerState={answerState} value={index + 1} />
                      </span>
                    )}
                  </>
                ))}
              </div>
            </div>
          )}
        </>
      ) : null}
    </>
  );
};
