import { takeLatest } from 'redux-saga/effects';
import { SpellingExerciseActions } from '@actions/SpellingExerciseActions';
import MediaUploadSagas from '@sagas/mediaUpload/definitions/MediaUploadSagasDefinition';
import SpellingExerciseSagas from '@sagas/exercises/definitions/SpellingExerciseSagasDefinition';
import type { AppDispatch } from '@redux/store';

export function* SpellingExerciseRelatedSagas(dispatch: AppDispatch) {
  yield takeLatest(SpellingExerciseActions.UPLOAD_IMAGE, MediaUploadSagas.uploadImageToExercise);
  yield takeLatest(SpellingExerciseActions.SAVE, SpellingExerciseSagas.save, dispatch);
}
