import { AxiosResponse } from 'axios';

import { DBId } from '@common/types/DBId';
import { apiClient } from '@features/api';

import { VocabularyReviewListItem, MatchedExercisesLocations, VocabularyReviewViewParams } from './types';

export const VocabularyReviewService = {
  async getLexicalItems({
    language,
    filters,
    page,
  }: VocabularyReviewViewParams): Promise<{ count: number; results: VocabularyReviewListItem[] }> {
    const response = await apiClient.noErrorsV2.get(`search/lexical-items`, {
      params: {
        language,
        page,
        ...filters,
      },
    });

    return response.data;
  },
  async getSpeechParts(): Promise<AxiosResponse<{ speechParts: string[] }>> {
    return await apiClient.noErrorsV2.get(`content/lexical-items/speech-parts`);
  },
  async getExercisesMatchedLocations({
    lexicalItemId,
  }: {
    lexicalItemId: DBId;
  }): Promise<AxiosResponse<MatchedExercisesLocations>> {
    /** @TODO Remove this mock and enable the production code when endpoint is available */
    const mockResponse = {
      data: Array(30).fill([
        {
          id: 'course_pack_en_onboarding',
          type: 'course',
          fieldStoredAs: null,
          language: 'EN',
          label: 'Busuu Internal Onboarding',
        },
        {
          id: '5518ac2e-72a5-467a-b241-0d3883eac0c8',
          type: 'group',
          fieldStoredAs: 'groups',
          groupType: 'level',
          label: 'Test level - Santi',
        },
        {
          id: '47e09a91-0a2b-4664-a8c0-c9fb892db618',
          type: 'group',
          fieldStoredAs: 'groups',
          groupType: 'chapter',
          label: 'Introductions',
        },
        {
          id: '19bb3481-6b35-4c6d-9d84-20f969a0f216',
          type: 'group',
          fieldStoredAs: 'groups',
          groupType: 'lesson',
          label: 'Giving your nationality',
        },
        {
          id: 'df290783-eaed-4ba1-a17b-355cde91af9b',
          type: 'group',
          fieldStoredAs: 'groups',
          groupType: 'activity',
          label: 'Vocabulary',
        },
        {
          id: 'd5499259-5611-4aae-a9d9-c978585ff7c0',
          type: 'exercise',
          fieldStoredAs: 'exercises',
          exerciseType: 'flashcard',
          label: 'Flashcard',
        },
      ]),
      status: 200,
    };
    return new Promise((resolve) => setTimeout(() => resolve(mockResponse as AxiosResponse), 1000));
  },
};
