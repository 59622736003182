import { WritableInputText } from './WritableInputText';

type NonWritableInputTextProps = {
  value: string;
  placeholder?: string;
  className: string;
};

export const NonWritableInputText = ({ ...props }: NonWritableInputTextProps) => {
  return <WritableInputText mode="text" {...props} readOnly />;
};
