import { startCase, capitalize } from 'lodash';

import filterIcon from '../_img/filter.svg';
import { CefrSelector } from './CefrSelector';
import { areFiltersSelected } from './util';
import { SpeechPartSelector } from './SpeechPartSelector';
import { FilterActionButton, FiltersCol, FiltersContainer, StyledCheckbox, StyledSearchBox } from './styles';
import type { FiltersState } from './types';

const CHECKBOX_FILTER_NAMES = ['hasAudio', 'hasImage', 'hasVideo', 'hasAllTranslations'];

const getCheckboxFilterLabel = (filterName: string) =>
  filterName !== 'hasAllTranslations' ? capitalize(startCase(filterName)) : 'Has all translations';

type VocabularyReviewFiltersProps = {
  filters: FiltersState;
  onApply: () => void;
  onClear: () => void;
  onFilter: (
    filterType: keyof FiltersState,
    value: FiltersState['cefr' | 'hasAudio' | 'hasImage' | 'hasAllTranslations' | 'hasVideo' | 'query' | 'speechPart'],
  ) => void;
};

export const VocabularyReviewFilters = ({ filters, onApply, onClear, onFilter }: VocabularyReviewFiltersProps) => {
  const filterButtonsDisabled = !areFiltersSelected(filters);

  return (
    <FiltersContainer data-testid="vocabulary-review-filters-container">
      <FiltersCol>
        <CefrSelector value={filters.cefr} onFilter={(value) => onFilter('cefr', value as FiltersState['cefr'])} />
        <SpeechPartSelector
          value={filters.speechPart}
          onFilter={(value) => onFilter('speechPart', value as FiltersState['speechPart'])}
        />
        {CHECKBOX_FILTER_NAMES.map((filterName) => (
          <StyledCheckbox
            checked={!!filters[filterName as keyof FiltersState]}
            key={filterName}
            label={getCheckboxFilterLabel(filterName)}
            onToggle={(value) => onFilter(filterName as keyof FiltersState, value)}
          />
        ))}
      </FiltersCol>
      <FiltersCol>
        <StyledSearchBox
          filterTerm={filters.query || ''}
          inputIcon={filterIcon}
          onSearchChange={(value: string) => onFilter('query', value)}
          placeholder="Filter by phrase"
        />
        <FilterActionButton disabled={filterButtonsDisabled} size="S" variant="secondary" onClick={onApply}>
          Apply Filters
        </FilterActionButton>
        <FilterActionButton disabled={filterButtonsDisabled} size="S" variant="tertiary" onClick={onClear}>
          Clear All
        </FilterActionButton>
      </FiltersCol>
    </FiltersContainer>
  );
};
