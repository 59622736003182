import { call, put, select } from 'redux-saga/effects';

import SpeechRecognitionExerciseInterface from '@components/Exercises/SpeechRecognition/interfaces/SpeechRecognitionExerciseInterface';
import MultipleChoiceExerciseInterface from '@components/Exercises/MultipleChoice/interfaces/MultipleChoiceExerciseInterface';
import ComprehensionExerciseInterface from '@components/Exercises/Comprehension/interfaces/ComprehensionExerciseInterface';
import PhraseBuilderExerciseInterface from '@components/Exercises/PhraseBuilder/interfaces/PhraseBuilderExerciseInterface';
import ConversationExerciseInterface from '@components/Exercises/Conversation/interfaces/ConversationExerciseInterface';
import HighlighterExerciseInterface from '@components/Exercises/Highlighter/interfaces/HighlighterExerciseInterface';
import SpeechRecognitionExerciseService from '@services/ExerciseRelatedServices/SpeechRecognitionExerciseService';
import TrueFalseExerciseInterface from '@components/Exercises/TrueFalse/interfaces/TrueFalseExerciseInterface';
import FlashcardExerciseInterface from '@components/Exercises/Flashcard/interfaces/FlashCardExerciseInterface';
import DialogueExerciseInterface from '@components/Exercises/Dialogue/interfaces/DialogueExerciseInterface';
import SpellingExerciseInterface from '@components/Exercises/Spelling/interfaces/SpellingExerciseInterface';
import MultipleChoiceExerciseService from '@services/ExerciseRelatedServices/MultipleChoiceExerciseService';
import ComprehensionExerciseService from '@services/ExerciseRelatedServices/ComprehensionExerciseService';
import PhraseBuilderExerciseService from '@services/ExerciseRelatedServices/PhraseBuilderExerciseService';
import FillGapExerciseInterface from '@components/Exercises/FillGap/interfaces/FillGapExerciseInterface';
import MatchUpExerciseInterface from '@components/Exercises/MatchUp/interfaces/MatchUpExerciseInterface';
import ConversationExerciseService from '@services/ExerciseRelatedServices/ConversationExerciseService';
import TypingExerciseInterface from '@components/Exercises/Typing/interfaces/TypingExerciseInterface';
import HighlighterExerciseService from '@services/ExerciseRelatedServices/HighlighterExerciseService';
import FlashCardExerciseService from '@services/ExerciseRelatedServices/FlashcardExerciseService';
import TrueFalseExerciseService from '@services/ExerciseRelatedServices/TrueFalseExerciseService';
import DialogueExerciseService from '@services/ExerciseRelatedServices/DialogueExerciseService';
import SpellingExerciseService from '@services/ExerciseRelatedServices/SpellingExerciseService';
import FillGapExerciseService from '@services/ExerciseRelatedServices/FillGapExerciseService';
import MatchUpExerciseService from '@services/ExerciseRelatedServices/MatchUpExerciseService';
import TipExerciseInterface from '@components/Exercises/Tip/interfaces/TipExerciseInterface';
import TypingExerciseService from '@services/ExerciseRelatedServices/TypingExerciseService';
import TipExerciseService from '@services/ExerciseRelatedServices/TipExerciseService';
import ListenRepeatExerciseService from '@services/ExerciseRelatedServices/ListenRepeatExerciseService';
import { AnyExerciseInterface } from '@common/types/exercises/AnyExerciseInterface';
import { StructureItemInterface } from '@common/interfaces/navigation/StructureInterface';
import { CourseInterface } from '@common/interfaces/courses/CourseInterface';
import { ContentTypesActions } from '@actions/ContentTypesActions';
import { ContentTypes, ContentType, type ContentTypesType } from '@common/enums/ContentTypes';
import { SectionActions } from '@actions/SectionActions';
import { UploadMediaResponse } from '@services/HelpersService';
import { CommonActions } from '@actions/CommonActions';
import ExercisesService from '@services/ExercisesService';
import { ExerciseTypes } from '@common/enums/ExerciseTypes';
import { MediaTypes } from '@common/enums/MediaTypes';
import MediaService from '@services/MediaService';
import { PayloadAction } from '@reduxjs/toolkit';
import { GrammarReviewActions } from '@actions/GrammarReviewActions';
import { PlacementTestActions } from '@actions/PlacementTestActions';
import { apiClient } from '@features/api';
import { DBId } from '@common/types/DBId';
import { AudioUploadActionsCreator } from '@actionCreators/AudioUploadActionsCreator';
import { selectTranslationsPanel } from '@selectors/UiSelectors';
import {
  selectCourse,
  selectCourseStructure,
  selectInterfaceLanguages,
  selectLoadedExerciseContent,
  selectLoadedExerciseData,
  selectSelectedGroupsOfParentParentContents,
} from '@selectors/CoursesSelectors';
import { selectGrammarNavigationStructure } from '@selectors/GrammarSelectors';
import { selectPlacementTestNavigationStructure } from '@selectors/PlacementTestSelectors';
import { AnyLoadedExercise } from '@common/types/exercises/AnyLoadedExercise';
import { AnyExerciseContentBranch } from '@common/types/AnyExerciseContentBranch';
import ListenRepeatExerciseInterface from '@components/Exercises/ListenRepeat/interfaces/ListenRepeatExerciseInterface';

const ExerciseSagas = {
  *uploadSoundForContentInTranslatorPanel({
    payload,
  }: ReturnType<typeof AudioUploadActionsCreator.uploadSoundForContentInTranslatorPanel>) {
    try {
      let { exercise, localization, visitedBranch, uploadedSound, row, column, bundleName, progressHandler } = payload;

      yield put({
        type: CommonActions.AUDIO_UPLOADING_STARTED,
        payload: { audioField: visitedBranch, language: localization?.language, row },
      });

      const translationPanel: ReturnType<typeof selectTranslationsPanel> = yield select(selectTranslationsPanel);

      const loadedExercise: ReturnType<typeof selectLoadedExerciseContent> = yield select(selectLoadedExerciseContent);

      let contentType: ContentTypesType | typeof ContentType.levelOrLesson = ContentTypes.exercise;
      let contentData;

      if (!translationPanel.visibleForCourse && !translationPanel.visibleForLevelAndLesson) {
        if (visitedBranch === 'script') {
          contentData = (loadedExercise as any)[visitedBranch][row || 0].line;
        } else if (['phrase0', 'phrase1', 'phrase2', 'phrase3'].includes(visitedBranch)) {
          contentData = (loadedExercise as any).phrases[row || 0];
        } else if (visitedBranch === 'examples') {
          contentData = (loadedExercise as any)[visitedBranch][row || 0][column || 0];
        } else {
          contentData = bundleName
            ? (loadedExercise as any)[bundleName][visitedBranch]
            : (loadedExercise as any)[visitedBranch];
        }
      }

      const contentId = translationPanel.visitedContentId || undefined;
      if (translationPanel.visibleForCourse) {
        contentType = ContentTypes.course;

        const course: ReturnType<typeof selectCourse> = yield select(selectCourse);
        contentData = (course as any)[visitedBranch];
      } else if (translationPanel.visibleForLevelAndLesson) {
        contentType = ContentType.levelOrLesson;

        const levelOrLesson: ReturnType<typeof selectSelectedGroupsOfParentParentContents> = yield select(
          selectSelectedGroupsOfParentParentContents,
        );
        contentData = (levelOrLesson as any)[visitedBranch];
      }

      if (uploadedSound) {
        const { mediaId, mediaURL }: UploadMediaResponse = yield MediaService.uploadMedia(
          MediaTypes.audio,
          uploadedSound,
          undefined,
          undefined,
          contentId,
          progressHandler,
          contentData,
          localization?.language,
        );

        yield put(
          AudioUploadActionsCreator.uploadAudioForContentInTranslatorPanelSucceeded({
            exercise: exercise as AnyLoadedExercise,
            localization,
            visitedBranch: visitedBranch as AnyExerciseContentBranch,
            mediaURL,
            row,
            column,
            contentType,
            bundleName,
            newContentId: mediaId,
          }),
        );
      } else {
        const audioLocalizations = [
          {
            fileId: null,
            language: localization?.language,
            value: '',
          },
        ];

        yield call(() => apiClient.noErrorsV2.put(`content/resources/${contentId}`, { audioLocalizations }));

        yield put(
          AudioUploadActionsCreator.uploadAudioForContentInTranslatorPanelSucceeded({
            exercise: exercise as AnyLoadedExercise,
            localization,
            visitedBranch: visitedBranch as AnyExerciseContentBranch,
            mediaURL: null,
            row,
            column,
            contentType,
            bundleName,
            newContentId: contentData._id,
          }),
        );
      }
    } catch (e: any) {
      let { localization, visitedBranch, row } = payload;

      yield put({
        type: CommonActions.AUDIO_UPLOADING_FINISHED,
        payload: {
          audioField: visitedBranch,
          language: localization?.language,
          row,
          details: e?.message,
        },
      });
      console.error(e);
    }
  },
  *requestOneExerciseCommon() {
    try {
      const loadedCourse: CourseInterface = yield select(selectCourse);
      const structure: StructureItemInterface[] = yield select(selectCourseStructure);
      const grammarStructure: StructureItemInterface[] = yield select(selectGrammarNavigationStructure);
      const placementTestStructure: StructureItemInterface[] = yield select(selectPlacementTestNavigationStructure);
      const exercise: AnyExerciseInterface = yield select(selectLoadedExerciseData);

      const courseParentId = structure?.find((item) => item.id === exercise.content.id)?.parentId || '';
      const grammarParentId = grammarStructure?.find((item) => item.id === exercise.content.id)?.parentId || '';
      const placementTestParentId =
        placementTestStructure?.find((item) => item.id === exercise.content.id)?.parentId || '';

      yield call(ExerciseSagas.requestOneExercise as any, {
        payload: {
          exerciseId: exercise.content.id,
          courseId: loadedCourse.id,
          parentId: courseParentId || grammarParentId || placementTestParentId,
        },
      });
    } catch (e: any) {
      console.error(e);
    }
  },
  *requestOneExercise({ payload }: PayloadAction<any>): any {
    try {
      if (!payload.withoutLoader) {
        yield put({
          type: SectionActions.SET_CURRENT_EXERCISE_AS_LOADING,
        });
      }

      const exercise: AnyExerciseInterface = yield call(
        ExercisesService.misc.getExercise,
        payload.exerciseId,
        payload.courseId,
        payload.parentId,
      );

      const exerciseAndEmptyLocalizationBranchesPayload = {
        content: { ...exercise.content },
      };

      switch (exercise.content.type) {
        case ExerciseTypes.flashcard: {
          const flashcardExercise = FlashCardExerciseService.ensureExerciseFieldsAreReadyForUse(
            exerciseAndEmptyLocalizationBranchesPayload,
            exercise as FlashcardExerciseInterface,
          );
          yield put({
            type: SectionActions.COURSE_EXERCISE_FLASHCARD_REQUEST_SUCCEEDED,
            payload: flashcardExercise,
          });

          yield put({
            type: GrammarReviewActions.SET_GRAMMAR_EXERCISE,
            payload: flashcardExercise,
          });
          yield put({
            type: PlacementTestActions.SET_PLACEMENT_EXERCISE,
            payload: flashcardExercise,
          });

          break;
        }

        case ExerciseTypes.tip: {
          const tipExercise = TipExerciseService.ensureExerciseFieldsAreReadyForUse(
            exerciseAndEmptyLocalizationBranchesPayload,
            exercise as TipExerciseInterface,
          );
          yield put({
            type: SectionActions.COURSE_EXERCISE_TIP_REQUEST_SUCCEEDED,
            payload: tipExercise,
          });
          yield put({
            type: GrammarReviewActions.SET_GRAMMAR_EXERCISE,
            payload: tipExercise,
          });
          yield put({
            type: PlacementTestActions.SET_PLACEMENT_EXERCISE,
            payload: tipExercise,
          });

          break;
        }

        case ExerciseTypes.trueFalse: {
          const trueFalseExercise = TrueFalseExerciseService.ensureExerciseFieldsAreReadyForUse(
            exerciseAndEmptyLocalizationBranchesPayload,
            exercise as TrueFalseExerciseInterface,
          );
          yield put({
            type: SectionActions.COURSE_EXERCISE_TRUEFALSE_REQUEST_SUCCEEDED,
            payload: trueFalseExercise,
          });
          yield put({
            type: GrammarReviewActions.SET_GRAMMAR_EXERCISE,
            payload: trueFalseExercise,
          });
          yield put({
            type: PlacementTestActions.SET_PLACEMENT_EXERCISE,
            payload: trueFalseExercise,
          });

          break;
        }

        case ExerciseTypes.multipleChoice: {
          const multipleChoiceExercise = MultipleChoiceExerciseService.ensureExerciseFieldsAreReadyForUse(
            exerciseAndEmptyLocalizationBranchesPayload,
            exercise as MultipleChoiceExerciseInterface,
          );
          yield put({
            type: SectionActions.COURSE_EXERCISE_MULTIPLE_CHOICE_REQUEST_SUCCEEDED,
            payload: multipleChoiceExercise,
          });
          yield put({
            type: GrammarReviewActions.SET_GRAMMAR_EXERCISE,
            payload: multipleChoiceExercise,
          });
          yield put({
            type: PlacementTestActions.SET_PLACEMENT_EXERCISE,
            payload: multipleChoiceExercise,
          });

          break;
        }

        case ExerciseTypes.phraseBuilder: {
          const field = 'phrase';
          const languages = yield select(selectInterfaceLanguages);
          const phraseBuilderExercise = exercise as PhraseBuilderExerciseInterface;
          const hasWarning = phraseBuilderExercise.content.mainBundle?.[field]?.textLocalizations.some(
            (tl) => languages.includes(tl.language) && !tl.value,
          );
          if (hasWarning) {
            const message = 'We recommend you add translations for this field.';
            phraseBuilderExercise.content.validation.errors.push({ isWarning: true, field, message });
          }
          const processedPhraseBuilderExercise = PhraseBuilderExerciseService.ensureExerciseFieldsAreReadyForUse(
            exerciseAndEmptyLocalizationBranchesPayload,
            phraseBuilderExercise,
          );
          yield put({
            type: SectionActions.COURSE_EXERCISE_PHRASE_BUILDER_REQUEST_SUCCEEDED,
            payload: processedPhraseBuilderExercise,
          });
          yield put({
            type: GrammarReviewActions.SET_GRAMMAR_EXERCISE,
            payload: processedPhraseBuilderExercise,
          });
          yield put({
            type: PlacementTestActions.SET_PLACEMENT_EXERCISE,
            payload: processedPhraseBuilderExercise,
          });

          break;
        }

        case ExerciseTypes.dialogue: {
          const dialogueExercise = DialogueExerciseService.ensureExerciseFieldsAreReadyForUse(
            exerciseAndEmptyLocalizationBranchesPayload,
            exercise as DialogueExerciseInterface,
          );
          yield put({
            type: SectionActions.COURSE_EXERCISE_DIALOGUE_REQUEST_SUCCEEDED,
            payload: dialogueExercise,
          });
          yield put({
            type: GrammarReviewActions.SET_GRAMMAR_EXERCISE,
            payload: dialogueExercise,
          });
          yield put({
            type: PlacementTestActions.SET_PLACEMENT_EXERCISE,
            payload: dialogueExercise,
          });

          break;
        }

        case ExerciseTypes.conversation: {
          const conversationExercise = ConversationExerciseService.ensureExerciseFieldsAreReadyForUse(
            exerciseAndEmptyLocalizationBranchesPayload,
            exercise as ConversationExerciseInterface,
          );
          yield put({
            type: SectionActions.COURSE_EXERCISE_CONVERSATION_REQUEST_SUCCEEDED,
            payload: conversationExercise,
          });
          yield put({
            type: GrammarReviewActions.SET_GRAMMAR_EXERCISE,
            payload: conversationExercise,
          });
          yield put({
            type: PlacementTestActions.SET_PLACEMENT_EXERCISE,
            payload: conversationExercise,
          });

          break;
        }

        case ExerciseTypes.speechRecognition: {
          const speechRecognitionExercise = exercise as SpeechRecognitionExerciseInterface;
          const field = 'phrase';
          const languages = yield select(selectInterfaceLanguages);
          const hasWarning = speechRecognitionExercise.content.mainBundle?.[field]?.textLocalizations.some(
            (tl) => languages.includes(tl.language) && !tl.value,
          );
          if (hasWarning) {
            const message = 'We recommend you add translations for this field.';
            speechRecognitionExercise.content.validation.errors.push({ isWarning: true, field, message });
          }
          const processedSpeechRecognitionExercise =
            SpeechRecognitionExerciseService.ensureExerciseFieldsAreReadyForUse(
              exerciseAndEmptyLocalizationBranchesPayload,
              speechRecognitionExercise,
            );
          yield put({
            type: SectionActions.COURSE_EXERCISE_SPEECH_RECOGNITION_REQUEST_SUCCEEDED,
            payload: processedSpeechRecognitionExercise,
          });
          yield put({
            type: GrammarReviewActions.SET_GRAMMAR_EXERCISE,
            payload: processedSpeechRecognitionExercise,
          });
          yield put({
            type: PlacementTestActions.SET_PLACEMENT_EXERCISE,
            payload: processedSpeechRecognitionExercise,
          });

          break;
        }

        case ExerciseTypes.spelling: {
          const field = 'phrase';
          const languages = yield select(selectInterfaceLanguages);
          const spellingExercise = exercise as SpellingExerciseInterface;
          const hasWarning = spellingExercise.content.mainBundle?.[field]?.textLocalizations.some(
            (tl) => languages.includes(tl.language) && !tl.value,
          );
          if (hasWarning) {
            const message = 'We recommend you add translations for this field.';
            spellingExercise.content.validation.errors.push({ isWarning: true, field, message });
          }
          const processedSpellingExercise = SpellingExerciseService.ensureExerciseFieldsAreReadyForUse(
            exerciseAndEmptyLocalizationBranchesPayload,
            spellingExercise,
          );
          yield put({
            type: SectionActions.COURSE_EXERCISE_SPELLING_REQUEST_SUCCEEDED,
            payload: processedSpellingExercise,
          });
          yield put({
            type: GrammarReviewActions.SET_GRAMMAR_EXERCISE,
            payload: processedSpellingExercise,
          });
          yield put({
            type: PlacementTestActions.SET_PLACEMENT_EXERCISE,
            payload: processedSpellingExercise,
          });

          break;
        }

        case ExerciseTypes.matchup: {
          const matchuExercise = MatchUpExerciseService.ensureExerciseFieldsAreReadyForUse(
            exerciseAndEmptyLocalizationBranchesPayload,
            exercise as MatchUpExerciseInterface,
          );
          yield put({
            type: SectionActions.COURSE_EXERCISE_MATCHUP_REQUEST_SUCCEEDED,
            payload: matchuExercise,
          });
          yield put({
            type: GrammarReviewActions.SET_GRAMMAR_EXERCISE,
            payload: matchuExercise,
          });
          yield put({
            type: PlacementTestActions.SET_PLACEMENT_EXERCISE,
            payload: matchuExercise,
          });

          break;
        }

        case ExerciseTypes.typing: {
          const field = 'phrase';
          const languages = yield select(selectInterfaceLanguages);
          const typingExercise = exercise as TypingExerciseInterface;
          const hasWarning = typingExercise.content.mainBundle?.[field]?.textLocalizations.some(
            (tl) => languages.includes(tl.language) && !tl.value,
          );
          if (hasWarning) {
            const message = 'We recommend you add translations for this field.';
            typingExercise.content.validation.errors.push({ isWarning: true, field, message });
          }
          const processedTypingExercise = TypingExerciseService.ensureExerciseFieldsAreReadyForUse(
            exerciseAndEmptyLocalizationBranchesPayload,
            typingExercise,
          );
          yield put({
            type: SectionActions.COURSE_EXERCISE_TYPING_REQUEST_SUCCEEDED,
            payload: processedTypingExercise,
          });
          yield put({
            type: GrammarReviewActions.SET_GRAMMAR_EXERCISE,
            payload: processedTypingExercise,
          });
          yield put({
            type: PlacementTestActions.SET_PLACEMENT_EXERCISE,
            payload: processedTypingExercise,
          });

          break;
        }

        case ExerciseTypes.highlighter: {
          const highlighterExercise = HighlighterExerciseService.ensureExerciseFieldsAreReadyForUse(
            exerciseAndEmptyLocalizationBranchesPayload,
            exercise as HighlighterExerciseInterface,
          );
          yield put({
            type: SectionActions.COURSE_EXERCISE_HIGHLIGHTER_REQUEST_SUCCEEDED,
            payload: highlighterExercise,
          });
          yield put({
            type: GrammarReviewActions.SET_GRAMMAR_EXERCISE,
            payload: highlighterExercise,
          });
          yield put({
            type: PlacementTestActions.SET_PLACEMENT_EXERCISE,
            payload: highlighterExercise,
          });

          break;
        }

        case ExerciseTypes.fillgap: {
          const field = 'phrase';
          const languages = yield select(selectInterfaceLanguages);
          const fillgapExercise = exercise as FillGapExerciseInterface;
          const hasWarning = fillgapExercise.content.mainBundle?.[field]?.textLocalizations.some(
            (tl) => languages.includes(tl.language) && !tl.value,
          );
          if (hasWarning) {
            const message = 'We recommend you add translations for this field.';
            fillgapExercise.content.validation.errors.push({ isWarning: true, field, message });
          }
          const processedFillgapExercise = FillGapExerciseService.ensureExerciseFieldsAreReadyForUse(
            exerciseAndEmptyLocalizationBranchesPayload,
            fillgapExercise,
          );
          yield put({
            type: SectionActions.COURSE_EXERCISE_FILLGAP_REQUEST_SUCCEEDED,
            payload: processedFillgapExercise,
          });
          yield put({
            type: GrammarReviewActions.SET_GRAMMAR_EXERCISE,
            payload: processedFillgapExercise,
          });
          yield put({
            type: PlacementTestActions.SET_PLACEMENT_EXERCISE,
            payload: processedFillgapExercise,
          });

          break;
        }

        case ExerciseTypes.comprehension: {
          const comprehensionExercise = ComprehensionExerciseService.ensureExerciseFieldsAreReadyForUse(
            exerciseAndEmptyLocalizationBranchesPayload,
            exercise as ComprehensionExerciseInterface,
          );
          yield put({
            type: SectionActions.COURSE_EXERCISE_COMPREHENSION_REQUEST_SUCCEEDED,
            payload: comprehensionExercise,
          });
          yield put({
            type: GrammarReviewActions.SET_GRAMMAR_EXERCISE,
            payload: comprehensionExercise,
          });
          yield put({
            type: PlacementTestActions.SET_PLACEMENT_EXERCISE,
            payload: comprehensionExercise,
          });

          break;
        }

        case ExerciseTypes.slidePdf: {
          yield put({
            type: SectionActions.COURSE_EXERCISE_SLIDE_PDF_REQUEST_SUCCEEDED,
            payload: exercise,
          });
          yield put({
            type: GrammarReviewActions.SET_GRAMMAR_EXERCISE,
            payload: exercise,
          });
          yield put({
            type: PlacementTestActions.SET_PLACEMENT_EXERCISE,
            payload: exercise,
          });

          break;
        }

        case ExerciseTypes.slidePptx: {
          yield put({
            type: SectionActions.COURSE_EXERCISE_SLIDE_PPTX_REQUEST_SUCCEEDED,
            payload: exercise,
          });
          yield put({
            type: GrammarReviewActions.SET_GRAMMAR_EXERCISE,
            payload: exercise,
          });
          yield put({
            type: PlacementTestActions.SET_PLACEMENT_EXERCISE,
            payload: exercise,
          });

          break;
        }

        case ExerciseTypes.listenRepeat: {
          const listenRepeatExercise = ListenRepeatExerciseService.ensureExerciseFieldsAreReadyForUse(
            exerciseAndEmptyLocalizationBranchesPayload,
            exercise as ListenRepeatExerciseInterface,
          );

          yield put({
            type: SectionActions.COURSE_EXERCISE_LISTEN_REPEAT_REQUEST_SUCCEEDED,
            payload: listenRepeatExercise,
          });
          yield put({
            type: GrammarReviewActions.SET_GRAMMAR_EXERCISE,
            payload: listenRepeatExercise,
          });
          yield put({
            type: PlacementTestActions.SET_PLACEMENT_EXERCISE,
            payload: listenRepeatExercise,
          });

          break;
        }

        default: {
          throw new TypeError(`${exercise.content.type} not found`);
        }
      }

      yield put({
        type: ContentTypesActions.UPDATE_EXERCISE_IN_NAVIGATION,
        payload: { id: exercise.content.id, experiment: !!exercise.content.experiment },
      });

      yield put({
        type: ContentTypesActions.COURSE_NAVIGATION_ITEM_SELECTED,
        payload: {
          id: exercise.content.id,
          type: exercise.type,
          withoutUpdateLoadedState: true,
        },
      });
    } catch (e: any) {
      console.error(e);
    }
  },
  *requestExerciseForActivityPreview({ payload }: PayloadAction<any>): any {
    try {
      const exercise: AnyExerciseInterface = yield call(
        ExercisesService.misc.getExercise,
        payload.exerciseId,
        payload.courseId,
      );
      yield put({
        type: SectionActions.SET_EXERCISE_FOR_ACTIVITY_PREVIEW,
        payload: exercise,
      });
    } catch (e: any) {
      console.error(e);
    }
  },
  *removeAudioFromExercise({ payload }: PayloadAction<{ contentId: DBId; language: string }>) {
    try {
      const { contentId, language } = payload;
      const audioLocalizations = [
        {
          fileId: null,
          language,
          value: '',
        },
      ];

      yield call(() => apiClient.noErrorsV2.put(`content/resources/${contentId}`, { audioLocalizations }));
    } catch (e) {
      console.error(e);
    }
  },
};

export default ExerciseSagas;
