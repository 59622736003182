import { SectionActions } from '@actions/SectionActions';
import { ExerciseTypes } from '@common/enums/ExerciseTypes';
import { LoadingStage } from '@common/enums/LoadingStage';
import { CourseSliceInterface } from '@common/interfaces/slices/CourseSliceInterface';
import ExerciseDataModelAssembler from '@components/Exercises/ExerciseDataModelAssembler';
import { updateTextField } from '@helpers/updateTextField';
import { ConversationExerciseActions } from '@actions/ConversationExerciseActions';
import ConversationExerciseInterface from '@components/Exercises/Conversation/interfaces/ConversationExerciseInterface';
import { selectedGroupsOfParentInitialData } from '@redux/initialStates/courseInitialState';
import { PayloadAction } from '@reduxjs/toolkit';
import { clone } from '@helpers/clone';

const ConversationExerciseCourseReducers = {
  [SectionActions.COURSE_EXERCISE_CONVERSATION_REQUEST_SUCCEEDED]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    let clonedCourseSlice: CourseSliceInterface = clone(state);
    let { content }: ConversationExerciseInterface = payload;

    let exercise: ConversationExerciseInterface = {
      ...clonedCourseSlice.loadedExercise.exercise,
      content,
      type: ExerciseTypes.conversation,
    };

    return {
      ...clonedCourseSlice,
      selectedGroupsOfParent: selectedGroupsOfParentInitialData,
      loadedExercise: {
        loaded: LoadingStage.loaded,
        exercise,
      },
    };
  },
  [ConversationExerciseActions.SET_INSTRUCTIONS_LANGUAGE]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    return ExerciseDataModelAssembler.updateCheckbox('instructionsLanguage', state, payload);
  },
  [ConversationExerciseActions.UPDATE_MINIMUM_NUMBER_OF_WORDS]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    return ExerciseDataModelAssembler.updateNumberField('minimumNumberOfWords', state, payload.minimumNumberOfWords);
  },
  [ConversationExerciseActions.SET_CONVERSATION_ALL_FIELDS]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    let clonedCourseSlice: CourseSliceInterface = clone(state);

    const { values } = payload;

    clonedCourseSlice = updateTextField(clonedCourseSlice, values, 'instructions');
    clonedCourseSlice = updateTextField(clonedCourseSlice, values, 'hint');

    return clonedCourseSlice;
  },
};

export default ConversationExerciseCourseReducers;
