import { PayloadAction } from '@reduxjs/toolkit';
import produce from 'immer';

import { CourseSliceInterface } from '@common/interfaces/slices/CourseSliceInterface';
import { ContentOwnershipActions } from '@actions/ContentOwnershipActions';
import { SetContentOwnershipPayload } from '@features/content/contentOwnership';

const ExerciseContentOwnershipReducers = {
  [ContentOwnershipActions.SET_EXERCISE_OWNER]: (
    state: CourseSliceInterface,
    { payload: { owner } }: PayloadAction<SetContentOwnershipPayload>,
  ) => {
    return produce(state, (draft) => {
      draft.loadedExercise.exercise.content.owner = owner;
    });
  },
};

export default ExerciseContentOwnershipReducers;
