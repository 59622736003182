import { DBId } from '@common/types/DBId';
import { apiClient } from '@features/api';

import { GrammarTopicSearchResultDetailsType } from '../types';

export const getGrammarTopicResultDetails = async (
  topicId: DBId,
): Promise<{ data: GrammarTopicSearchResultDetailsType }> => {
  const response = await apiClient.noErrorsV2.get(`/components/${topicId}/mappings`);

  return {
    data: {
      mappings: response.data,
    },
  };
};
