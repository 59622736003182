import { apiClient } from '@features/api';
import { IdentityGroupType, LessonAttachedToIdentityGroupType } from './types';

export const getIdentityGroups = async (): Promise<{ data: { identityGroups: IdentityGroupType[] } }> => {
  return await apiClient.noErrorsV2.get('/content/identity-groups');
};

export const createIdentityGroup = async (title: string) => {
  return await apiClient.noErrorsV2.post('/content/identity-groups', { title });
};

export const getLessons = async (
  identityGroupId: string,
): Promise<{ data: { lessons: LessonAttachedToIdentityGroupType[]; publishedLessonsCount: number } }> => {
  return await apiClient.noErrorsV2.get(`/content/identity-groups/${identityGroupId}`);
};

export const updateIdentityGroup = async (identityGroupId: string, title: string) => {
  return await apiClient.noErrorsV2.put(`/content/identity-groups/${identityGroupId}`, { title });
};

export const deleteIdentityGroup = async (identityGroupId: string) => {
  return apiClient.noErrorsV2.delete(`/content/identity-groups/${identityGroupId}`);
};
