import CreatableSelect from 'react-select/creatable';
import styled from 'styled-components';

import { ResourceBundleInterface } from '@common/interfaces/contentTypes/ResourceBundleInterface';
import { LocalizationInterface } from '@common/interfaces/localization/LocalizationInterface';
import ImageUploadDimensionDescriptors from '@common/enums/FileUploadDimensionDescriptors';
import { WritableInputText } from '@components/WritableInputText';
import TranslationsTipV2 from '@components/TranslationTipV2/TranslationTipV2';
import warningImage from '@static/svg/translationsPanel/Warning.svg';
import { ImageUploadModes } from '@common/enums/FileUploadModes';
import { AudioUpload, ImageUpload, VideoUpload } from '@components/MediaUpload';
import { Sizes } from '@common/enums/Sizes';

import { AddButton, Box, LabelledContent, Typography } from './components';
import { useAppSelector } from '@redux/store';
import { selectLearningLanguage, selectResourceBundleData } from '@selectors/CoursesSelectors';

const ImageUploadStyled = styled(ImageUpload)<{ disabled: boolean }>(({ disabled, theme }) => ({
  backgroundColor: disabled ? theme.color.brandPrimary50 : theme.color.brandWhite,
}));

const VideoUploadStyled = styled(VideoUpload)<{ disabled: boolean }>(({ disabled, theme }) => ({
  backgroundColor: disabled ? theme.color.brandPrimary50 : theme.color.brandWhite,
}));

type ResourceProps = {
  bundle: ResourceBundleInterface;
  resources: ResourceData[];
  bundleName: string;
};

const ImageResource = ({ resources }: ResourceProps) => {
  let imageURL = resources.find((item) => item.resource === 'image')?.value;

  if (resources.find((item) => item.resource === 'image')?.isOpened) {
    return (
      <LabelledContent text="Image">
        <ImageUploadStyled
          width={ImageUploadDimensionDescriptors.comprehension.readingImage.width}
          height={ImageUploadDimensionDescriptors.comprehension.readingImage.height}
          size={Sizes.fullscreen}
          mode={ImageUploadModes.normal}
          className={!imageURL ? 'resource-bundle-modal__empty-uploader' : ''}
          disabled
          imageData={{ mediaId: '', mediaValue: imageURL || '', contentId: '', processed: true }}
          previewMode={false}
          isForExercise
        />
      </LabelledContent>
    );
  } else {
    return <AddButton text="Add Image" disabled />;
  }
};

const VideoResource = ({ resources }: ResourceProps) => {
  const resource = resources.find((item) => item.resource === 'video');
  let videoURL = resource?.value;

  if (resource?.isOpened) {
    return (
      <LabelledContent text="Video">
        <VideoUploadStyled
          className={!videoURL ? 'resource-bundle-modal__empty-uploader' : ''}
          disabled
          videoData={{ contentId: '', mediaId: '', mediaValue: videoURL || '', processed: true }}
          fieldName="video"
          fullScreen
        />
      </LabelledContent>
    );
  } else {
    return <AddButton text="Add Video" disabled />;
  }
};

const ImageAndVideoResource = ({ bundle, resources, bundleName }: ResourceProps) => {
  return (
    <div className="resource-bundle-modal__image_and-video-container">
      <ImageResource bundle={bundle} resources={resources} bundleName={bundleName} />
      <VideoResource bundle={bundle} resources={resources} bundleName={bundleName} />
    </div>
  );
};

const PhraseResource = ({ resources, bundleName }: ResourceProps) => {
  if (resources.find((item) => item.resource === 'phrase')?.isOpened) {
    return (
      <LabelledContent text="Phrase">
        <TranslationsTipV2
          bundleName={bundleName}
          visitedBranch={'phrase'}
          showErrorDisplayer={false}
          hideRemove={true}
        >
          <WritableInputText
            disabled
            value={resources.find((item) => item.resource === 'phrase')?.value}
            id="entire-bundle-phrase-input"
          />
        </TranslationsTipV2>
      </LabelledContent>
    );
  } else {
    return <AddButton text="Add Phrase" disabled />;
  }
};

const AlternativeValuesResource = ({ bundle, resources }: ResourceProps) => {
  const courseLearningLanguage = useAppSelector(selectLearningLanguage);

  const altValuesOptions = bundle?.phrase?.textLocalizations
    .find((loc: LocalizationInterface) => loc.language === courseLearningLanguage)
    ?.alternativeValues?.map((value: string) => ({ label: value, value }));

  if (resources.find((item) => item.resource === 'alternative_value')?.isOpened) {
    return (
      <LabelledContent text="Alternative Values">
        <CreatableSelect
          placeholder="New value..."
          defaultValue={altValuesOptions}
          isDisabled={true}
          isMulti
          className="basic-multi-select"
          classNamePrefix="select"
        />
      </LabelledContent>
    );
  } else {
    return <AddButton text="Add Alternative Values" disabled />;
  }
};

const PhraseAudioResource = ({ resources }: ResourceProps) => {
  if (resources.find((item) => item.resource === 'phrase_audio')?.isOpened) {
    return (
      <LabelledContent text="Audio">
        <AudioUpload
          disabled
          fullScreen
          audioData={{
            contentId: '',
            mediaId: '',
            mediaValue: resources.find((item) => item.resource === 'phrase_audio')?.value || '',
            processed: true,
          }}
        />
      </LabelledContent>
    );
  } else {
    return <AddButton text="Add Audio" disabled />;
  }
};

const KeyPhraseResource = ({ resources, bundleName }: ResourceProps) => {
  if (resources.find((item) => item.resource === 'keyphrase')?.isOpened) {
    return (
      <LabelledContent text="Key Phrase">
        <TranslationsTipV2
          bundleName={bundleName}
          visitedBranch={'example'}
          showErrorDisplayer={false}
          hideRemove={true}
        >
          <WritableInputText
            disabled
            value={resources.find((item) => item.resource === 'keyphrase')?.value}
            id="entire-bundle-key-phrase-input"
          />
        </TranslationsTipV2>
      </LabelledContent>
    );
  } else {
    return <AddButton text="Add Key Phrase" disabled />;
  }
};

const KeyPhraseAudioResource = ({ resources }: ResourceProps) => {
  if (resources.find((item) => item.resource === 'keyphrase_audio')?.isOpened) {
    return (
      <LabelledContent text="Audio">
        <AudioUpload
          disabled
          fullScreen
          audioData={{
            contentId: '',
            mediaId: '',
            mediaValue: resources.find((item) => item.resource === 'keyphrase_audio')?.value || '',
            processed: true,
          }}
        />
      </LabelledContent>
    );
  } else {
    return <AddButton text="Add Audio" disabled />;
  }
};

const resourcesMap = {
  image: ImageResource,
  video: VideoResource,
  image_and_video: ImageAndVideoResource,
  phrase: PhraseResource,
  alternative_value: AlternativeValuesResource,
  phrase_audio: PhraseAudioResource,
  keyphrase: KeyPhraseResource,
  keyphrase_audio: KeyPhraseAudioResource,
} as const;
export type Resource = keyof typeof resourcesMap;
export type ResourceData = { resource: Resource; isOpened: boolean; isAvailable: boolean; value: string | undefined };

const mergeResources = (resources: ResourceData[]) => {
  const result: ResourceData[] = [];
  let index = 0;
  while (index < resources.length) {
    if (resources[index].resource === 'image' && resources[index + 1] && resources[index + 1].resource === 'video') {
      result.push({
        resource: 'image_and_video',
        isOpened: true,
        isAvailable: true,
        value: '',
      });
      index++;
    } else {
      result.push(resources[index]);
    }
    index++;
  }
  return result;
};

export const Resources = ({ resources, bundleName }: { resources: ResourceData[]; bundleName: string }) => {
  const currentResourceBundle = useAppSelector(selectResourceBundleData);
  const inactiveResources = resources.filter((resource) => !resource.isAvailable);
  const activeResources = resources.filter((resource) => resource.isAvailable);

  if (!currentResourceBundle) {
    return null;
  }

  return (
    <Box flexDirection="column">
      {mergeResources(activeResources)
        .map((activeResource) => resourcesMap[activeResource.resource])
        .map((ActiveResource, index) => (
          <Box key={`active-${index}`} marginBottom="24px">
            <ActiveResource bundle={currentResourceBundle} resources={resources} bundleName={bundleName} />
          </Box>
        ))}
      {!!mergeResources(inactiveResources).length && (
        <div className="resource-bundle-modal__warning">
          <Box alignItems="center">
            <img src={warningImage} alt="Warning" />
            <Typography fontSize="14px">
              The items below are part of this Resource Bundle but are not used in this exercise.
            </Typography>
          </Box>
        </div>
      )}
      {mergeResources(inactiveResources)
        .map((inactiveResource) => resourcesMap[inactiveResource.resource])
        .map((InactiveResource, index) => (
          <Box key={`inactive-${index}`} marginBottom="24px">
            <InactiveResource bundle={currentResourceBundle} resources={resources} bundleName={bundleName} />
          </Box>
        ))}
    </Box>
  );
};
