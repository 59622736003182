const UPLOAD_AUDIO_SUCCEEDED = 'UPLOAD_AUDIO_SUCCEEDED';
const UPLOAD_AUDIO = 'UPLOAD_AUDIO';
const UPLOAD_AUDIO_FOR_CONTENT_IN_TRANSLATOR_PANEL = 'UPLOAD_AUDIO_UPLOAD_AUDIO_FOR_CONTENT_IN_TRANSLATOR_PANEL';
const UPLOAD_AUDIO_FOR_CONTENT_IN_TRANSLATOR_PANEL_SUCCEEDED =
  'UPLOAD_AUDIO_UPLOAD_AUDIO_FOR_CONTENT_IN_TRANSLATOR_PANEL_SUCCEEDED';

export const AudioUploadActions = {
  UPLOAD_AUDIO_SUCCEEDED,
  UPLOAD_AUDIO,
  UPLOAD_AUDIO_FOR_CONTENT_IN_TRANSLATOR_PANEL,
  UPLOAD_AUDIO_FOR_CONTENT_IN_TRANSLATOR_PANEL_SUCCEEDED,
} as const;
