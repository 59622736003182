import { ContentTypes } from '@common/enums/ContentTypes';
import { ContentCreatorWidget, type ContentCreatorWidgetItemType } from '@features/theme';
import { useAppSelector } from '@redux/store';
import { selectCreatingContentPosition } from '@selectors/CoursesSelectors';

import ACTIVITY_ICONS from '../ActivityTypeIcon/_img';
import {
  ACTIVITY_COMPREHENSION_SKILLS,
  ACTIVITY_CONVERSATION,
  ACTIVITY_DEVELOP,
  ACTIVITY_DIALOGUE,
  ACTIVITY_DISCOVER,
  ACTIVITY_MEMORISE,
  ACTIVITY_PRACTICE,
  ACTIVITY_PRODUCTIVE_SKILLS,
  ACTIVITY_PRONUNCIATION,
  ACTIVITY_QUIZ,
  ACTIVITY_READING,
  ACTIVITY_SPEAKING,
  ACTIVITY_TITLES,
  ACTIVITY_VIDEO,
  ACTIVITY_VOCABULARY,
  type ActivityType,
} from '../ActivityTypes';

const ACTIVITY_CREATOR_ITEMS: ContentCreatorWidgetItemType<ActivityType>[] = [
  {
    label: ACTIVITY_TITLES.vocabulary,
    iconSrc: ACTIVITY_ICONS.vocabulary,
    showByDefault: true,
    showOnlyIcon: true,
    type: ACTIVITY_VOCABULARY,
  },
  {
    label: ACTIVITY_TITLES.memorise,
    iconSrc: ACTIVITY_ICONS.memorise,
    showByDefault: true,
    showOnlyIcon: true,
    type: ACTIVITY_MEMORISE,
  },
  {
    label: ACTIVITY_TITLES.dialogue,
    iconSrc: ACTIVITY_ICONS.dialogue,
    showByDefault: true,
    showOnlyIcon: true,
    type: ACTIVITY_DIALOGUE,
  },
  {
    label: ACTIVITY_TITLES.quiz,
    iconSrc: ACTIVITY_ICONS.quiz,
    showByDefault: true,
    showOnlyIcon: true,
    type: ACTIVITY_QUIZ,
  },
  {
    label: ACTIVITY_TITLES.discover,
    iconSrc: ACTIVITY_ICONS.discover,
    showByDefault: true,
    showOnlyIcon: true,
    type: ACTIVITY_DISCOVER,
  },
  {
    label: ACTIVITY_TITLES.develop,
    iconSrc: ACTIVITY_ICONS.develop,
    showByDefault: true,
    showOnlyIcon: true,
    type: ACTIVITY_DEVELOP,
  },
  {
    label: ACTIVITY_TITLES.practice,
    iconSrc: ACTIVITY_ICONS.practice,
    showByDefault: false,
    type: ACTIVITY_PRACTICE,
  },
  {
    label: ACTIVITY_TITLES.conversation,
    iconSrc: ACTIVITY_ICONS.conversation,
    showByDefault: false,
    type: ACTIVITY_CONVERSATION,
  },
  {
    label: ACTIVITY_TITLES.pronunciation,
    iconSrc: ACTIVITY_ICONS.pronunciation,
    showByDefault: false,
    type: ACTIVITY_PRONUNCIATION,
  },
  {
    label: ACTIVITY_TITLES.video,
    iconSrc: ACTIVITY_ICONS.video,
    showByDefault: false,
    type: ACTIVITY_VIDEO,
  },
  {
    label: ACTIVITY_TITLES.reading,
    iconSrc: ACTIVITY_ICONS.reading,
    showByDefault: false,
    type: ACTIVITY_READING,
  },
  {
    label: ACTIVITY_TITLES.comprehensionSkills,
    iconSrc: ACTIVITY_ICONS.comprehensionSkills,
    showByDefault: false,
    type: ACTIVITY_COMPREHENSION_SKILLS,
  },
  {
    label: ACTIVITY_TITLES.productiveSkills,
    iconSrc: ACTIVITY_ICONS.productiveSkills,
    showByDefault: false,
    type: ACTIVITY_PRODUCTIVE_SKILLS,
  },
];

const ACTIVITY_CREATOR_SPEAKING_ITEM: ContentCreatorWidgetItemType<ActivityType>[] = [
  {
    label: ACTIVITY_TITLES.speakingActivity,
    iconSrc: ACTIVITY_ICONS.speakingActivity,
    showByDefault: true,
    type: ACTIVITY_SPEAKING,
  },
];

type ActivityCreatorProps = {
  acceptableButtons: Partial<Record<typeof ContentTypes.activity | typeof ContentTypes.speakingActivity, boolean>>;
  isEditAvailable: boolean;
  isPreEnabled: boolean;
  position: number;
  onCreateContent: (type: ActivityType) => void;
  onSearch: () => void;
};

export const ActivityCreator = ({
  acceptableButtons,
  isEditAvailable,
  isPreEnabled,
  position,
  onCreateContent,
  onSearch,
}: ActivityCreatorProps) => {
  const creatingContentPosition = useAppSelector(selectCreatingContentPosition);
  const isCreateInProgress = creatingContentPosition !== undefined && +creatingContentPosition === position;

  return (
    <ContentCreatorWidget
      isEditAvailable={isEditAvailable}
      isPreEnabled={isPreEnabled}
      isCreateInProgress={isCreateInProgress}
      items={acceptableButtons[ContentTypes.speakingActivity] ? ACTIVITY_CREATOR_SPEAKING_ITEM : ACTIVITY_CREATOR_ITEMS}
      position={position}
      title="Create a first activity to get started"
      onItemSelected={(item) => onCreateContent(item.type)}
      onSearch={onSearch}
    />
  );
};
