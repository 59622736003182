import { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';

import { OtherLocationsInterface } from '@common/interfaces/exercises/OtherLocationsInterface';
import { CourseInfoInterface } from '@common/interfaces/courses/CourseInfoInterface';
import { TranslationsPanelContentInterface } from '@common/interfaces/exercises/TranslationsPanelContentInterface';
import { DBId } from '@common/types/DBId';
import { FlagTextPositionMode } from '@components/Flag';
import IDDisplayer from '@components/IDDisplayer/IDDisplayer';
import { OtherPlacesModal } from '@components/ComponentOtherPlacesDisplayer/OtherPlacesModal';
import { type Toast, useToast } from '@features/app/toast';
import { constants as contentConstants } from '@features/content';
import { ImageDataType } from '@features/content/media';
import { HelpDisplayer } from '@features/help';
import { Modal, useDialogModal } from '@features/modal';
import { Button, Loader } from '@features/theme';
import { useAppSelector } from '@redux/store';
import { selectCoursesInfo } from '@selectors/CoursesSelectors';
import TranslationsPanelService from '@services/TranslationsPanelService';

import { ReactComponent as ChevronIcon } from './_img/chevron.svg';
import { ReactComponent as CopyIcon } from './_img/copy.svg';
import {
  CopyToClipboardButton,
  StyledFlag,
  VocabularyBundleData,
  VocabularyBundleImage,
  VocabularyBundlePhrase,
  VocabularyBundleLocations,
  VocabularyBundleLocationsWrapper,
  VocabularyBundlesContainer,
  VocabularyBundlesList,
  VocabularyBundlesListItem,
  VocabularyBundlesTitle,
  VocabularyBundlesToggle,
  VocabularyBundlesWrapper,
} from './styles';
import { Language } from '@features/content/languages';

const { DEFAULT_LANGUAGE } = contentConstants;

export type VocabularyBundleResultType = {
  courses: DBId[];
  id: DBId;
  image: ImageDataType | undefined;
  mappingsCount: number;
  phrase: TranslationsPanelContentInterface | undefined;
};

type VocabularyBundleResultProps = Omit<VocabularyBundleResultType, 'phrase'> & {
  phrase: string | undefined;
};

type VocabularyBundlesProps = {
  content: VocabularyBundleResultType[];
  loading: boolean;
  learningLanguage: string;
  onClick: () => void;
};

const getPhraseToDisplay = (phrase: VocabularyBundleResultType['phrase'], learningLanguage: string) => {
  const phraseTextLocalizations =
    phrase?.textLocalizations.reduce((acc: Record<string, string>, { language, value }) => {
      if ([DEFAULT_LANGUAGE, learningLanguage].includes(language)) {
        acc[language] = value;
      }

      return acc;
    }, {}) || {};

  if (
    phraseTextLocalizations[learningLanguage] &&
    phraseTextLocalizations[DEFAULT_LANGUAGE] &&
    phraseTextLocalizations[learningLanguage] !== phraseTextLocalizations[DEFAULT_LANGUAGE]
  ) {
    return `${phraseTextLocalizations[learningLanguage]} / ${phraseTextLocalizations[DEFAULT_LANGUAGE]}`;
  }

  if (phraseTextLocalizations[learningLanguage]) return phraseTextLocalizations[learningLanguage];
  if (phraseTextLocalizations[DEFAULT_LANGUAGE]) return phraseTextLocalizations[DEFAULT_LANGUAGE];
};

const VocabularyBundleResult = ({ courses, id, image, mappingsCount, phrase }: VocabularyBundleResultProps) => {
  const [mappingsDetails, setMappingsDetails] = useState<OtherLocationsInterface[][]>([]);

  const coursesInfo = useAppSelector(selectCoursesInfo);
  const countries: string[] = [];

  for (const course of courses) {
    const courseInState = coursesInfo.courses.find((_course: CourseInfoInterface) => _course.id === course);

    if (courseInState && !countries.includes(courseInState.learningLanguage)) {
      countries.push(courseInState.learningLanguage);
    }
  }

  const { open: openOtherPlacesModal, modal: otherPlacesModal } = useDialogModal((modalControls) => (
    <Modal {...modalControls} onClickOutside={modalControls.close}>
      <OtherPlacesModal
        close={() => {
          setMappingsDetails([]);
          modalControls.close();
        }}
        mappings={mappingsCount}
        mappingsDetails={mappingsDetails}
      />
    </Modal>
  ));

  const handleOnClick = () => {
    openOtherPlacesModal();

    try {
      TranslationsPanelService.getOtherLocationsForBundle(id).then(({ data }) => {
        setMappingsDetails(data);
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <VocabularyBundlesListItem>
        {!!image && <VocabularyBundleImage src={image.value} />}
        <VocabularyBundleData>
          <IDDisplayer id={id} mode="small" truncateAt={10} />
          {phrase && <VocabularyBundlePhrase>{phrase}</VocabularyBundlePhrase>}
        </VocabularyBundleData>
        {!!mappingsCount && (
          <VocabularyBundleLocationsWrapper>
            {!!countries.length && (
              <StyledFlag
                countries={countries as Language[]}
                mode={FlagTextPositionMode.withoutText}
                tooltipEnabled={false}
              />
            )}
            <VocabularyBundleLocations
              role="button"
              onClick={handleOnClick}
            >{`Used in ${mappingsCount} location(s)`}</VocabularyBundleLocations>
          </VocabularyBundleLocationsWrapper>
        )}
      </VocabularyBundlesListItem>
      {otherPlacesModal}
    </>
  );
};

export const VocabularyBundles = ({ content, learningLanguage, loading, onClick }: VocabularyBundlesProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const showToast = useToast();

  let bundleListText = '';

  const handleOnClick = () => {
    if (!isOpen) onClick();
    setIsOpen(!isOpen);
  };

  const onCopyBundleList = (text: string, result: boolean) => {
    const toastData: Toast = result
      ? {
          type: 'info',
          title: 'Copied to clipboard',
          description: 'Vocabulary bundle list has been copied to clipboard',
        }
      : {
          type: 'error',
          title: 'Error',
          description: 'An error occurred when copying Vocabulary bundle list to clipboard',
        };

    showToast(toastData);
  };

  return (
    <VocabularyBundlesWrapper>
      <Accordion>
        <Accordion.Toggle as={VocabularyBundlesToggle} eventKey="0" isOpen={isOpen} onClick={handleOnClick}>
          <VocabularyBundlesTitle>
            Lesson Vocabulary
            <HelpDisplayer type="how-to" id="how-to-vocabulary-bundles" />
          </VocabularyBundlesTitle>
          <ChevronIcon />
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <VocabularyBundlesContainer noContent={loading || !content.length}>
            {loading ? (
              <Loader />
            ) : (
              <>
                {content.length > 0 ? (
                  <>
                    <VocabularyBundlesList>
                      {content.map((bundle) => {
                        const phraseToDisplay = getPhraseToDisplay(bundle.phrase, learningLanguage);

                        bundleListText += `${phraseToDisplay}\n`;

                        return (
                          <VocabularyBundleResult
                            courses={bundle.courses}
                            id={bundle.id}
                            image={bundle.image}
                            key={bundle.id}
                            mappingsCount={bundle.mappingsCount}
                            phrase={phraseToDisplay}
                          />
                        );
                      })}
                    </VocabularyBundlesList>
                    <CopyToClipboardButton text={bundleListText} onCopy={onCopyBundleList}>
                      <Button icon={<CopyIcon />} onClick={() => {}} size="M" variant="text">
                        Copy list to clipboard
                      </Button>
                    </CopyToClipboardButton>
                  </>
                ) : (
                  <p>This lesson does not contain any vocabulary review bundle.</p>
                )}
              </>
            )}
          </VocabularyBundlesContainer>
        </Accordion.Collapse>
      </Accordion>
    </VocabularyBundlesWrapper>
  );
};
