import Avatar from 'react-avatar';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';

import { DropdownButtonDirections, type DropdownButtonDirectionsType } from '@common/enums/DropdownButtonDirections';
import { logout, useUser } from '@features/auth';
import { useIsAdmin, useIsAccessCourseStats } from '@features/auth/roles';
import { ContextualMenu } from '@features/theme';

import adminSettingsIcon from './img/adminSettingsIcon.svg';
import audioIcon from './img/audioIcon.svg';
import courseStatsIcon from './img/courseStatsIcon.svg';
import logoutIcon from './img/logoutIcon.svg';
import personalInfoIcon from './img/personalInfoIcon.svg';
import progressMatchingIcon from './img/progressMatchingIcon.svg';

const baseProfileImageStyles = css`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.1);
`;

const ProfileImageWrapper = styled.div`
  height: 40px;
  margin-left: 24px;
  width: 40px;
`;

const ProfileImageAvatar = styled(Avatar)`
  ${baseProfileImageStyles}
`;

const ProfileMenuListItemIcon = styled.img`
  margin-right: 10px;
`;

export function ProfileImage({
  className,
  place = DropdownButtonDirections.down,
}: {
  className?: string;
  place?: DropdownButtonDirectionsType;
}) {
  const { user } = useUser();
  const isAdmin = useIsAdmin();
  const isAccessCourseStats = useIsAccessCourseStats();

  return (
    <ProfileImageWrapper className={className}>
      {user ? (
        <ContextualMenu
          position={place}
          triggerIcon={<ProfileImageAvatar name={`${user.firstName} ${user.lastName}`} size="40" />}
          variant="large"
          withTrigger
        >
          <Link to="/personal-info">
            <ProfileMenuListItemIcon src={personalInfoIcon} alt="Personal Information" />
            <span>Personal Information</span>
          </Link>

          {isAdmin ? (
            <Link to="/admin-settings">
              <ProfileMenuListItemIcon src={adminSettingsIcon} alt="Admin Settings" />
              <span>Admin Settings</span>
            </Link>
          ) : null}

          {isAccessCourseStats ? (
            <Link to="/course-stats">
              <ProfileMenuListItemIcon src={courseStatsIcon} alt="Course Stats" />
              <span>Course Stats</span>
            </Link>
          ) : null}

          <Link to="/progress-matching">
            <ProfileMenuListItemIcon src={progressMatchingIcon} alt="Progress Matching" />
            <span>Progress Matching</span>
          </Link>

          <Link to="/audio-management">
            <ProfileMenuListItemIcon src={audioIcon} alt="Audio Management" />
            <span>Audio Management</span>
          </Link>

          <div onClick={logout} key="logout">
            <ProfileMenuListItemIcon src={logoutIcon} alt="Logout" />
            <span>Logout</span>
          </div>
        </ContextualMenu>
      ) : null}
    </ProfileImageWrapper>
  );
}
