import { SearchActions } from '@actions/SearchActions';

import type { SearchPanelParamsType, StringSearchResultDetailsType } from '@features/search';

export const SearchModalActionsCreator = {
  showSearchV2Panel: ({
    bundleName,
    column,
    courseId,
    filtersPreset,
    contentType,
    forReusing,
    predefinedType,
    language,
    query,
    parentType,
    position,
    row,
    visitedBranch,
  }: SearchPanelParamsType = {}) => ({
    type: SearchActions.SHOW_SEARCH_PANEL_V2,
    payload: {
      bundleName,
      column,
      courseId,
      filtersPreset,
      contentType,
      forReusing,
      predefinedType,
      language,
      query,
      parentType,
      position,
      row,
      visitedBranch,
    },
  }),
  hideSearchV2: () => ({ type: SearchActions.HIDE_SEARCH_PANEL_V2 }),
  resetFilters: () => ({ type: SearchActions.RESET_SEARCH_FILTERS }),
  reuseString: (newString: StringSearchResultDetailsType) => ({
    type: SearchActions.USE_STRING_V2,
    payload: newString,
  }),
  copyString: (newString: StringSearchResultDetailsType) => ({
    type: SearchActions.COPY_STRING_V2,
    payload: newString,
  }),
  reuseBundle: (id: string, bundleName: string) => ({
    type: SearchActions.USE_BUNDLE_V2,
    payload: { id, bundleName },
  }),
  copyBundle: (id: string, bundleName: string) => ({
    type: SearchActions.COPY_BUNDLE_V2,
    payload: { id, bundleName },
  }),
  reuseExercise: (id: string, parentId: string, position: number) => ({
    type: SearchActions.USE_EXERCISE_V2,
    payload: { id, parentId, position },
  }),
  copyExercise: (id: string, parentId: string, position: number) => ({
    type: SearchActions.COPY_EXERCISE_V2,
    payload: { id, parentId, position },
  }),
  reuseLesson: (id: string, parentId: string, position: number) => ({
    type: SearchActions.USE_LESSON_V2,
    payload: { id, parentId, position },
  }),
  copyLesson: (id: string, parentId: string, position: number) => ({
    type: SearchActions.COPY_LESSON_V2,
    payload: { id, parentId, position },
  }),
  reuseActivity: (id: string, parentId: string, position: number) => ({
    type: SearchActions.USE_ACTIVITY_V2,
    payload: { id, parentId, position },
  }),
  copyActivity: (id: string, parentId: string, position: number) => ({
    type: SearchActions.COPY_ACTIVITY_V2,
    payload: { id, parentId, position },
  }),
};
