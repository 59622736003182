// Vocabulary Review
const SET_INITIAL_VOCABULARY_REVIEW = 'SET_INITIAL_VOCABULARY_REVIEW';
const GET_LEXICAL_ITEMS = 'GET_LEXICAL_ITEMS';
const VOCABULARY_REVIEW_LOADING = 'VOCABULARY_REVIEW_LOADING';
const VOCABULARY_REVIEW_LOADED = 'VOCABULARY_REVIEW_LOADED';

// Lexical Item
const SET_INITIAL_LEXICAL_ITEM = 'SET_INITIAL_LEXICAL_ITEM';
const LEXICAL_ITEM_LOADING = 'LEXICAL_ITEM_LOADING';
const LEXICAL_ITEM_LOADED = 'LEXICAL_ITEM_LOADED';

export const VocabularyReviewActions = {
  SET_INITIAL_VOCABULARY_REVIEW,
  GET_LEXICAL_ITEMS,
  VOCABULARY_REVIEW_LOADING,
  VOCABULARY_REVIEW_LOADED,
  SET_INITIAL_LEXICAL_ITEM,
  LEXICAL_ITEM_LOADING,
  LEXICAL_ITEM_LOADED,
};
