import { EditorState, Modifier, SelectionState } from 'draft-js';
import 'draft-js/dist/Draft.css';
import { KTagStrategyReturn } from '../Types';
import KTagStrategiesCommonUtils from './KTagStrategiesCommonUtils';

const KTagStandardStrategy = (
  editorState: EditorState,
  charactersMode: boolean,
  totalCharacters: number,
  maxCharacters: number,
): KTagStrategyReturn => {
  let selectionState: SelectionState = editorState.getSelection();
  let currentContent = editorState.getCurrentContent();
  let contentState;
  let theClass = '';

  theClass = 'KTAG_GROUP';

  contentState = Modifier.applyInlineStyle(currentContent, selectionState, theClass);
  editorState = EditorState.push(editorState, contentState, 'change-inline-style');

  return {
    editorState,
    totalBlocks: KTagStrategiesCommonUtils.calculateTotalBlocks(contentState),
  };
};

export default KTagStandardStrategy;
