import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { RoleplayActionCreators } from '@actionCreators/RoleplayActionsCreators';
import { ContentTypes } from '@common/enums/ContentTypes';
import { LoadingStage } from '@common/enums/LoadingStage';
import BreadcrumbNavigation from '@components/BreadcrumbNavigation/BreadcrumbNavigation';
import { type LanguageV2, LANGUAGE_NAMES_V2 } from '@features/content/languages';
import { ContentOwner } from '@features/content/contentOwnership';
import { SearchModal } from '@features/search';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { selectRoleplay } from '@selectors/roleplaySelectors';

import { BreadcrumbsAndOwnershipWrapper } from '../styles';
import { Header } from '../Header';
import { RoleplayData } from './RoleplayData';

export const RoleplayContent = () => {
  const { languageId } = useParams<{ languageId: LanguageV2 }>();
  const roleplay = useAppSelector(selectRoleplay);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(RoleplayActionCreators.setInitialRoleplay());
  }, [dispatch, languageId]);

  useEffect(() => {
    if (
      roleplay.loaded === LoadingStage.loaded &&
      roleplay.categoriesData.loaded === LoadingStage.notLoaded &&
      languageId
    ) {
      dispatch(RoleplayActionCreators.getRoleplayCategories(languageId));
    }
  }, [dispatch, languageId, roleplay]);

  const contentName = `${LANGUAGE_NAMES_V2[languageId]} Roleplay`;

  return (
    <div className="course-edition__course-data-composer">
      <SearchModal />
      <Header contentId={roleplay.content.id} contentName={contentName} learningLanguage={languageId} />
      <BreadcrumbsAndOwnershipWrapper>
        <BreadcrumbNavigation breadcrumbItems={[<span>{contentName}</span>]} />
        <ContentOwner
          contentType={ContentTypes.roleplay}
          componentId={roleplay.content.id}
          dates={roleplay.content.dates}
          owner={roleplay.content.owner}
        />
      </BreadcrumbsAndOwnershipWrapper>
      <RoleplayData contentName={contentName} languageId={languageId} roleplay={roleplay} />
    </div>
  );
};
