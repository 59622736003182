import SlidePptxExerciseInterface from '@components/Exercises/SlidePptx/interfaces/SlidePptxExerciseInterface';
import { ExerciseCommonActionCreators as ExerciseCommonActions } from '@actionCreators/ExerciseCommonActionCreator';
import { AppDispatch } from '@redux/store';
import { editSlidePptxExercise } from '@services/exercises/editExerciseService';
import { EditSlidePptxExerciseRequest } from '@services/exercises/editExerciseTypes';
import { addToast } from '@features/app/toast';

const SlidePptxExerciseService = {
  async save(dispatch: AppDispatch, exercise: SlidePptxExerciseInterface) {
    try {
      const { pptxFileId } = exercise.content;

      let payload: EditSlidePptxExerciseRequest = {
        pptxFileId: pptxFileId || null,
      };

      if (exercise.content?.pptxUrlChanged) {
        if (exercise.content.id) {
          await editSlidePptxExercise(exercise.content.id, payload);

          dispatch(ExerciseCommonActions.setSaveProgress({ value: false, updateData: true }));

          addToast({
            type: 'success',
            title: 'This exercise has been saved',
          });
        }
      }
    } catch (e: any) {
      if (e.response?.status === 400) {
        addToast({
          type: 'error',
          title: `${e.response.data?.detail}`,
        });
      }

      throw new Error(e as string);
    }
  },
};

export default SlidePptxExerciseService;
