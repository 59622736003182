import Avatar from 'react-avatar';

import './NameContainer.scss';

export const NameContainer = ({ name }: { name: string }) => {
  return (
    <div className="name-container">
      <Avatar name={name} className="name-container__image" size="40" />
      <span>{name}</span>
    </div>
  );
};
