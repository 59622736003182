import { call, select } from 'redux-saga/effects';
import ExerciseSagas from '@sagas/exercises/definitions/ExerciseSagas';
import SlidePdfExerciseInterface from '@components/Exercises/SlidePdf/interfaces/SlidePdfExerciseInterface';
import SlidePdfExerciseService from '@services/ExerciseRelatedServices/SlidePdfExerciseService';
import type { AppDispatch } from '@redux/store';
import { selectLoadedExerciseData } from '@selectors/CoursesSelectors';

const SlidePdfExerciseSagas = {
  *save(
    dispatch: AppDispatch,
    {
      type,
      payload,
    }: {
      type: string;
      payload: {
        exercise: SlidePdfExerciseInterface;
      };
    },
  ) {
    let exercise: SlidePdfExerciseInterface = yield select(selectLoadedExerciseData);

    yield call(SlidePdfExerciseService.save, dispatch, exercise);

    yield call(ExerciseSagas.requestOneExerciseCommon);
  },
};

export default SlidePdfExerciseSagas;
