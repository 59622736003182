import { delay, put, call, select } from 'redux-saga/effects';

import { PlacementTestService } from '@services/PlacementTestService';
import { PayloadAction } from '@reduxjs/toolkit';
import { CommonActions } from '@actions/CommonActions';
import { PlacementTestActions } from '@actions/PlacementTestActions';
import { ExerciseTypes, type ExerciseTypesType } from '@common/enums/ExerciseTypes';
import { SearchActions } from '@actions/SearchActions';
import { SearchService } from '@features/search';
import { DBId } from '@common/types/DBId';
import { ContentTypes } from '@common/enums/ContentTypes';
import { NavigationItemType } from '@features/content/navigation';
import { placementTestInitialContent } from '@redux/initialStates/placementTestInitialState';
import { courseSlice } from '@redux/slices/courseSlice';
import CourseSagas from '@sagas/courses/definitions/CourseSagasDefinition';
import { CoursesActions } from '@actions/CoursesActions';
import { getNewArray } from '@helpers/newAddedElementHelper';
import { addToast } from '@features/app/toast';
import { SearchModalActionsCreator } from '@actionCreators/SearchModalActionsCreator';
import {
  selectPlacementTestContent,
  selectPlacementTestEntrypointExercises,
  selectPlacementTestNavigationStructure,
} from '@selectors/PlacementTestSelectors';

const newAddedExerciseData = {
  audio: null,
  changeStatus: {
    hasPendingChanges: false,
    hasNewChanges: true,
  },
  imageData: null,
  previewText: null,
  name: null,
  validationStatus: {
    invalidChildEntities: [],
    validated: false,
    valid: false,
    errors: [],
  },
};

export const PlacementTestSagas = {
  *getPlacementTest({ payload: { courseId } }: PayloadAction<{ courseId: DBId }>) {
    try {
      yield put({ type: PlacementTestActions.PLACEMENT_TEST_LOADING });

      const result: Awaited<ReturnType<typeof PlacementTestService.getPlacementTest>> = yield call(
        PlacementTestService.getPlacementTest,
        courseId,
      );

      if (result.status === 200) {
        yield put({ type: PlacementTestActions.PLACEMENT_TEST_LOADED, payload: result.data });
      }
    } catch (e: any) {
      if (e?.response?.status === 404) {
        yield put({ type: PlacementTestActions.PLACEMENT_TEST_LOADED, payload: placementTestInitialContent });

        yield put({
          type: PlacementTestActions.UPDATE_NAVIGATION,
          payload: [
            {
              id: courseId,
              title: 'Placement Test',
              type: ContentTypes.placementTest,
              children: false,
              ready: false,
              parentId: null,
              expanded: false,
            },
          ],
        });
      }
      console.error(e);
    }
  },
  *createPlacementTest({ payload: { courseId } }: PayloadAction<{ courseId: DBId }>) {
    try {
      const createResult: Awaited<ReturnType<typeof PlacementTestService.createPlacementTest>> = yield call(
        PlacementTestService.createPlacementTest,
        courseId,
      );

      if (createResult.status === 200) {
        yield put({ type: PlacementTestActions.SET_INITIAL_PLACEMENT_TEST });
      }
    } catch (e: any) {
      console.error(e);
    }
  },
  *getEntrypoints({
    payload: { courseId, placementTestId },
  }: PayloadAction<{ courseId: DBId; placementTestId: DBId }>) {
    try {
      yield put({ type: PlacementTestActions.ENTRYPOINTS_LOADING });

      if (placementTestId) {
        const entrypointsResult: Awaited<ReturnType<typeof PlacementTestService.getEntrypoints>> = yield call(
          PlacementTestService.getEntrypoints,
          courseId,
        );

        if (entrypointsResult.status === 200) {
          yield put({
            type: PlacementTestActions.ENTRYPOINTS_LOADED,
            payload: entrypointsResult.data.entrypoints,
          });
        }
      } else {
        yield put({ type: PlacementTestActions.ENTRYPOINTS_LOADED, payload: [] });
      }
    } catch (e) {
      console.error(e);
    }
  },
  // Navigation sagas
  *getAllNavigation({ payload: { courseId, entrypointId } }: PayloadAction<{ courseId: DBId; entrypointId: DBId }>) {
    try {
      const placementTestContent: ReturnType<typeof selectPlacementTestContent> =
        yield select(selectPlacementTestContent);
      const navigation: ReturnType<typeof selectPlacementTestNavigationStructure> = yield select(
        selectPlacementTestNavigationStructure,
      );
      let entrypoints = [];
      let exercises = [];
      if (courseId && !navigation.filter((elem) => elem.parentId === courseId).length) {
        entrypoints = yield call(PlacementTestSagas.getPlacementTestNavigation, { courseId });
      }
      if (entrypointId && !navigation.filter((elem) => elem.parentId === entrypointId).length) {
        exercises = yield call(PlacementTestSagas.getEntrypointNavigation, { courseId, entrypointId });
      }

      if (entrypoints?.length || exercises.length) {
        yield put({
          type: PlacementTestActions.UPDATE_NAVIGATION,
          payload: [
            {
              id: courseId,
              title: 'Placement Test',
              type: ContentTypes.placementTest,
              children: !!entrypointId || !!entrypoints.length,
              ready: placementTestContent.ready,
              parentId: null,
              expanded: true,
            },
            ...entrypoints.map((entrypoint: NavigationItemType) => ({
              ...entrypoint,
              expanded: entrypoint.id === entrypointId,
            })),
            ...exercises,
          ],
        });
      } else if (navigation.find((elem) => elem.type === ContentTypes.placementTest)?.id !== courseId) {
        yield put({
          type: PlacementTestActions.UPDATE_NAVIGATION,
          payload: [
            {
              id: courseId,
              title: 'Placement Test',
              type: ContentTypes.placementTest,
              children: false,
              ready: placementTestContent.ready,
              parentId: null,
              expanded: true,
            },
          ],
        });
      }
    } catch (e) {
      console.error(e);
    }
  },
  *getPlacementTestNavigation({ courseId }: { courseId: DBId }) {
    try {
      yield put({ type: PlacementTestActions.SET_LOADING_PARENT_ID, payload: courseId });

      const placementTestNavigationResult: Awaited<ReturnType<typeof PlacementTestService.getPlacementTestNavigation>> =
        yield call(PlacementTestService.getPlacementTestNavigation, courseId);

      if (placementTestNavigationResult.status === 200) {
        yield put({ type: PlacementTestActions.SET_LOADING_PARENT_ID, payload: '' });
        return placementTestNavigationResult.data.entrypoints.map((entrypoint: NavigationItemType) => ({
          ...entrypoint,
          parentId: courseId,
          type: ContentTypes.entrypoint,
        }));
      } else {
        return [];
      }
    } catch (e) {
      yield put({ type: PlacementTestActions.SET_LOADING_PARENT_ID, payload: '' });
      console.error(e);
    }
  },
  *getEntrypointNavigation({ courseId, entrypointId }: { courseId: DBId; entrypointId: DBId }) {
    try {
      yield put({ type: PlacementTestActions.SET_LOADING_PARENT_ID, payload: entrypointId });

      const entrypointNavigationResult: Awaited<ReturnType<typeof PlacementTestService.getEntrypointNavigation>> =
        yield call(PlacementTestService.getEntrypointNavigation, courseId, entrypointId);

      if (entrypointNavigationResult.status === 200) {
        yield put({ type: PlacementTestActions.SET_LOADING_PARENT_ID, payload: '' });
        return entrypointNavigationResult.data.exercises.map((exercise) => ({
          ...exercise,
          parentId: entrypointId,
        }));
      } else {
        return [];
      }
    } catch (e) {
      console.error(e);
      return [];
    }
  },
  *updateEntrypointNavigation({ courseId, entrypointId }: { courseId: DBId; entrypointId: DBId }) {
    try {
      const navigation: ReturnType<typeof selectPlacementTestNavigationStructure> = yield select(
        selectPlacementTestNavigationStructure,
      );

      let exercisesNavigation = [];

      if (entrypointId) {
        const exercisesResult: Awaited<ReturnType<typeof PlacementTestService.getEntrypointNavigation>> = yield call(
          PlacementTestService.getEntrypointNavigation,
          courseId,
          entrypointId,
        );
        exercisesNavigation = exercisesResult.data.exercises;
      }

      yield put({
        type: PlacementTestActions.UPDATE_NAVIGATION,
        payload: [
          ...navigation
            .filter(
              (element) =>
                element.parentId !== entrypointId &&
                (element.type !== ContentTypes.exercise || !Object.values(ExerciseTypes).includes(element.type as any)),
            )
            .map((element) => ({
              ...element,
              expanded:
                element.type === ContentTypes.entrypoint && entrypointId === element.id
                  ? exercisesNavigation.length
                  : element.expanded,
              children:
                element.type === ContentTypes.entrypoint && entrypointId === element.id
                  ? exercisesNavigation.length
                  : element.children,
            })),
          ...exercisesNavigation.map((exercise) => ({ ...exercise, parentId: entrypointId })),
        ],
      });
    } catch (e) {
      console.error(e);
      return [];
    }
  },
  // Entrypoint sagas
  *createEntrypointExercise({
    payload: { courseId, entrypointId, exerciseType, position },
  }: PayloadAction<{ courseId: DBId; entrypointId: DBId; exerciseType: ExerciseTypesType; position: number }>) {
    try {
      const exercises: ReturnType<typeof selectPlacementTestEntrypointExercises> = yield select(
        selectPlacementTestEntrypointExercises,
      );

      yield put(courseSlice.actions.setIsCreatingContent(`gradable-${position}`));

      let createResult: Awaited<ReturnType<typeof PlacementTestService.createExercise>>;
      try {
        createResult = yield call(PlacementTestService.createExercise, exerciseType, {
          entrypointId,
          position,
        });
      } finally {
        yield put(courseSlice.actions.setIsCreatingContent(undefined));
      }

      const newElementId = createResult.data.id;
      if (createResult.status === 201 && newElementId) {
        const newGroupElement = {
          id: newElementId,
          type: exerciseType,
          ...newAddedExerciseData,
        };
        const newExercises = getNewArray(exercises, newGroupElement, position);

        yield put({
          type: PlacementTestActions.ENTRYPOINT_EXERCISES_LOADED,
          payload: newExercises,
        });

        yield call(CourseSagas.getValidationResult, {
          type: ContentTypes.entrypoint,
        });
      }

      yield call(PlacementTestSagas.updateEntrypointNavigation, { courseId, entrypointId });

      yield put({
        type: CoursesActions.SET_POSITION_OF_NEW_CONTENT,
        payload: { newElementId },
      });

      yield delay(2000);

      yield put({
        type: CoursesActions.SET_POSITION_OF_NEW_CONTENT,
        payload: {},
      });
    } catch (e) {
      console.error(e);
    }
  },
  *getEntrypoint({ payload: { courseId, entrypointId } }: PayloadAction<{ courseId: DBId; entrypointId: DBId }>) {
    try {
      yield put({ type: PlacementTestActions.ENTRYPOINT_LOADING });

      const result: Awaited<ReturnType<typeof PlacementTestService.getEntrypoint>> = yield call(
        PlacementTestService.getEntrypoint,
        courseId,
        entrypointId,
      );

      if (result.status === 200) {
        yield put({ type: PlacementTestActions.ENTRYPOINT_LOADED, payload: result.data });
      }
    } catch (e) {
      console.error(e);
    }
  },
  *getEntrypointExercises({
    payload: { courseId, entrypointId },
  }: PayloadAction<{ courseId: DBId; entrypointId: DBId }>) {
    try {
      yield put({ type: PlacementTestActions.ENTRYPOINT_EXERCISES_LOADING });

      const result: Awaited<ReturnType<typeof PlacementTestService.getEntrypointExercises>> = yield call(
        PlacementTestService.getEntrypointExercises,
        courseId,
        entrypointId,
      );

      if (result.status === 200) {
        yield put({ type: PlacementTestActions.ENTRYPOINT_EXERCISES_LOADED, payload: result.data.exercises });
      }
    } catch (e) {
      console.error(e);
    }
  },
  *saveEntrypoint({
    payload: { courseId, entrypointId, requestPayload },
  }: PayloadAction<{ courseId: DBId; entrypointId: DBId; requestPayload: { chapter: { id: DBId; title: string } } }>) {
    try {
      yield put({
        type: CommonActions.SET_IS_SAVE_LOADING,
        payload: { value: true },
      });

      const saveResult: Awaited<ReturnType<typeof PlacementTestService.saveEntrypoint>> = yield call(
        PlacementTestService.saveEntrypoint,
        courseId,
        entrypointId,
        { chapter: requestPayload.chapter.id },
      );

      yield put({
        type: CommonActions.SET_IS_SAVE_LOADING,
        payload: { value: false },
      });

      if (saveResult.status === 200) {
        yield put({ type: PlacementTestActions.SET_LINKED_CHAPTER, payload: requestPayload.chapter });
        addToast({
          type: 'success',
          title: 'Chapter selected as midpoint',
        });

        yield call(CourseSagas.getValidationResult, {
          type: ContentTypes.entrypoint,
        });
      }
    } catch (e) {
      console.error(e);
    }
  },
  *removeExercise({ payload }: PayloadAction<{ courseId: DBId; entrypointId: DBId; exerciseId: DBId }>) {
    try {
      const exercises: ReturnType<typeof selectPlacementTestEntrypointExercises> = yield select(
        selectPlacementTestEntrypointExercises,
      );
      const { courseId, entrypointId, exerciseId } = payload;

      let deleteResult: Awaited<ReturnType<typeof PlacementTestService.removeExercise>>;

      yield put(courseSlice.actions.setIsDeleteInProgress(true));
      deleteResult = yield call(PlacementTestService.removeExercise, courseId, entrypointId, exerciseId);

      if (deleteResult.status === 204) {
        yield put(courseSlice.actions.setIsDeleteInProgress(false));
        if (exercises.find((exercise) => exercise.id === exerciseId)) {
          const newExercises = exercises.filter((exercise) => exercise.id !== exerciseId);
          yield put({
            type: PlacementTestActions.ENTRYPOINT_EXERCISES_LOADED,
            payload: newExercises,
          });
        }

        yield call(PlacementTestSagas.updateEntrypointNavigation, { courseId, entrypointId });

        yield call(CourseSagas.getValidationResult, {
          type: ContentTypes.entrypoint,
        });
      }
    } catch (e) {
      yield put(courseSlice.actions.setIsDeleteInProgress(false));
      console.error(e);
    }
  },
  *reuseExercise({
    payload: { courseId, entrypointId, exerciseId, position, exerciseType, prevExerciseId },
  }: PayloadAction<{
    courseId: DBId;
    entrypointId: DBId;
    exerciseId: DBId;
    exerciseType: ExerciseTypesType;
    position: number;
    prevExerciseId?: DBId;
  }>) {
    try {
      const exercises: ReturnType<typeof selectPlacementTestEntrypointExercises> = yield select(
        selectPlacementTestEntrypointExercises,
      );

      yield put({
        type: SearchActions.SET_REUSE_IN_PROGRESS_V2,
        payload: prevExerciseId || exerciseId,
      });

      const reuseResult: Awaited<ReturnType<typeof PlacementTestService.useExercise>> = yield call(
        PlacementTestService.useExercise,
        courseId,
        entrypointId,
        exerciseId,
        position,
      );

      if (reuseResult.status === 204) {
        yield put(SearchModalActionsCreator.hideSearchV2());

        addToast({
          type: 'success',
          title: prevExerciseId ? 'Exercise was copied' : 'Exercise was reused',
        });

        const newGroupElement = {
          id: exerciseId,
          type: exerciseType,
          changeStatus: {
            hasPendingChanges: false,
            hasNewChanges: false,
          },
          validationStatus: {
            invalidChildEntities: [],
            validated: false,
            valid: false,
            errors: [],
          },
        };

        const newExercises = getNewArray(exercises, newGroupElement, position);

        yield put({
          type: PlacementTestActions.ENTRYPOINT_EXERCISES_LOADED,
          payload: newExercises,
        });

        yield call(CourseSagas.getValidationResult, {
          type: ContentTypes.entrypoint,
        });

        yield call(PlacementTestSagas.updateEntrypointNavigation, { courseId, entrypointId });

        yield put({
          type: CoursesActions.SET_POSITION_OF_NEW_CONTENT,
          payload: { exerciseId },
        });

        yield delay(2000);

        yield put({
          type: CoursesActions.SET_POSITION_OF_NEW_CONTENT,
          payload: {},
        });
      }
    } catch (e) {
      yield put({
        type: SearchActions.SET_REUSE_IN_PROGRESS_V2,
        payload: '',
      });

      console.error(e);
    }
  },
  *copyExercise({
    payload: { courseId, entrypointId, exerciseId, position, exerciseType },
  }: PayloadAction<{
    courseId: DBId;
    entrypointId: DBId;
    exerciseId: DBId;
    exerciseType: ExerciseTypesType;
    position: number;
  }>) {
    try {
      yield put({
        type: SearchActions.SET_REUSE_IN_PROGRESS_V2,
        payload: exerciseId,
      });

      const cloneResult: Awaited<ReturnType<typeof SearchService.cloneExercise>> = yield call(
        SearchService.cloneExercise,
        exerciseId,
      );

      if (cloneResult.status === 200) {
        yield call(PlacementTestSagas.reuseExercise, {
          payload: {
            courseId,
            entrypointId,
            exerciseId: cloneResult.data.id,
            exerciseType,
            position,
            prevExerciseId: exerciseId,
          },
        } as PayloadAction<{
          courseId: DBId;
          entrypointId: DBId;
          exerciseId: DBId;
          exerciseType: ExerciseTypesType;
          position: number;
          prevExerciseId?: DBId;
        }>);
      }
    } catch (e) {
      yield put({
        type: SearchActions.SET_REUSE_IN_PROGRESS_V2,
        payload: '',
      });

      console.error(e);
    }
  },
};
