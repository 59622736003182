import { FunctionComponent } from 'react';
import { LoadedSlidePdfExerciseInterface } from '@common/interfaces/exercises/LoadedExerciseInterface';
import { ValidationErrorInterface } from '@common/interfaces/validation/ValidationInterface';
import ExerciseProps from '@components/Exercises/ExerciseProps';
import { SlideFileUpload } from '@components/MediaUpload';
import { PreviewModal } from '@features/content/preview';
import { ValidationErrorDisplayer } from '@components/ValidationErrorDisplayer';
import { useAppDispatch, useAppSelector } from '@redux/store';
import helpersService from '@services/HelpersService';
import SlidePdfExerciseInterface from './interfaces/SlidePdfExerciseInterface';
import { SlidePdfExerciseActionsCreator } from '@actionCreators/SlidePdfExerciseActionsCreator';
import { selectLoadedExerciseData } from '@selectors/CoursesSelectors';

const SlidePdfExercise: FunctionComponent<ExerciseProps<LoadedSlidePdfExerciseInterface>> = ({
  exercise: exerciseParam,
}: ExerciseProps<LoadedSlidePdfExerciseInterface>) => {
  const dispatch = useAppDispatch();

  const loadedExercise = useAppSelector(selectLoadedExerciseData) as SlidePdfExerciseInterface;

  const { errors } = loadedExercise?.content?.validation;

  const pdfErrors = errors.filter((error: ValidationErrorInterface) => error.field === 'pdfUrl');

  return (
    <div className="exercise-slideshow">
      <PreviewModal type={loadedExercise.type} content={loadedExercise.content} />
      <header className="exercise-slideshow__header">
        <h1 className="exercise-slideshow__exercise-title">Slideshow</h1>
      </header>

      <div className="exercise-slideshow__wrapper">
        <div className="exercise-slideshow__container">
          <ValidationErrorDisplayer text={helpersService.getValidationErrorMessageText(pdfErrors)} />
        </div>
        <SlideFileUpload
          errors={pdfErrors}
          fileUrl={loadedExercise.content.pdfUrl}
          fileUrlChanged={loadedExercise.content.pdfUrlChanged}
          type="pdf"
          onChange={(file: File) => {
            if (file !== null && loadedExercise.content.id !== undefined) {
              dispatch(SlidePdfExerciseActionsCreator.uploadPdfFile(file));
            }
          }}
          onRemove={() => {
            dispatch(SlidePdfExerciseActionsCreator.setPdfFile(''));
          }}
        />
      </div>
    </div>
  );
};

export default SlidePdfExercise;
