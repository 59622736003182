import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { Logo } from '@features/app/logo';
import { Button } from '@features/theme';
import { ProfileImage } from '@components/RolesAndPermissions/ProfileImage/ProfileImage';
import Error404Image from './img/Error404.svg';
import { ReactComponent as ArrowIcon } from './img/Arrow.svg';

const ErrorPageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ErrorPageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 4px 48px;
  align-items: center;

  .dropdown-menu {
    transform: translate3d(-220px, 45px, 0px) !important;
  }
`;

const ErrorDescriptionContainer = styled.div`
  width: 100%;
  padding: 30px 60px 0 150px;
  display: flex;
  position: relative;
`;

const ErrorDescription = styled.div`
  color: ${({ theme }) => theme.colorV2.errorPageDescriptionText};
  z-index: 1;
  max-width: 400px;
  display: flex;
  flex-direction: column;
`;

const ErrorTitle = styled.span`
  margin: 20px 0 28px;
  line-height: 62px;
  font-weight: 800;
  font-size: 48px;
  letter-spacing: -0.015em;
  opacity: 0.9;
`;

const ErrorText = styled.span`
  line-height: 27px;
  font-size: 18px;
  margin-bottom: 28px;
`;

const ErrorCaption = styled(ErrorText)`
  margin-bottom: 20px;
  font-weight: 700;
  line-height: 27px;
  font-size: 18px;
  letter-spacing: -0.015em;
`;

const ErrorImage = styled.img`
  position: absolute;
  top: 0px;
  right: 0px;
  width: 1070px;
  height: 560px;
`;

export const ErrorPage = ({ code }: { code: number }) => {
  const history = useHistory();

  if (code !== 401) {
    return (
      <ErrorPageWrapper>
        <ErrorPageHeader>
          <Logo size="large" withLink />
          <ProfileImage />
        </ErrorPageHeader>
        <ErrorDescriptionContainer>
          <ErrorDescription>
            <ErrorTitle>Oops, it seems like we got lost in translation...</ErrorTitle>
            <ErrorCaption>Did you know...?</ErrorCaption>
            <ErrorText>
              ... the story of the Tower of Babel explains the origins of the multiplicity of languages? God was
              concerned that humans had blasphemed by building the tower to avoid a second flood. As a punishment, God
              created multiple languages. According to the story, humans ended up divided into linguistic groups, unable
              to understand one another.
            </ErrorText>
            <Button size="L" onClick={() => history.push('/')}>
              Go back to All courses <ArrowIcon />
            </Button>
          </ErrorDescription>
          {code === 404 && <ErrorImage src={Error404Image} alt="Error 404" />}
        </ErrorDescriptionContainer>
      </ErrorPageWrapper>
    );
  }

  return <div>{code} error</div>;
};
