/** @deprecated Use LessonType type instead */
export const LessonTypes = {
  certificate: 'certificate',
  checkpoint: 'checkpoint',
  lesson: 'lesson',
  live: 'live',
  review: 'review',
} as const;
/** @deprecated Use LessonType type instead */
export type LessonTypesType = (typeof LessonTypes)[keyof typeof LessonTypes];

export type LessonType = 'certificate' | 'checkpoint' | 'lesson' | 'live' | 'review' | 'roleplay' | 'speaking';
export type LessonTypeAllowedToChange = Extract<LessonType, 'lesson' | 'review'>;
export type LessonTypeWithFocus = Extract<LessonType, 'certificate' | 'lesson' | 'review'>;

export const LESSON_CERTIFICATE: LessonType & LessonTypeWithFocus = 'certificate';
export const LESSON_CHECKPOINT: LessonType = 'checkpoint';
export const LESSON_LESSON: LessonTypeAllowedToChange & LessonTypeWithFocus = 'lesson';
export const LESSON_LIVE: LessonType = 'live';
export const LESSON_REVIEW: LessonTypeAllowedToChange & LessonTypeWithFocus = 'review';
export const LESSON_ROLEPLAY: LessonType = 'roleplay';
export const LESSON_SPEAKING: LessonType = 'speaking';

export const LESSONS: LessonType[] = [
  LESSON_CERTIFICATE,
  LESSON_CHECKPOINT,
  LESSON_LESSON,
  LESSON_LIVE,
  LESSON_REVIEW,
  LESSON_ROLEPLAY,
  LESSON_SPEAKING,
];

export const LESSON_TITLES: { [key in LessonType]: string } = {
  [LESSON_CERTIFICATE]: 'Certificate',
  [LESSON_CHECKPOINT]: 'Checkpoint',
  [LESSON_LESSON]: 'Lesson',
  [LESSON_LIVE]: 'Live',
  [LESSON_REVIEW]: 'Review',
  [LESSON_ROLEPLAY]: 'AI Roleplay',
  [LESSON_SPEAKING]: 'Speaking',
};

export type CertificateLessonType = 'business' | 'regular';

export const CERTIFICATE_LESSON_TYPE_TITLES: { [key in CertificateLessonType]: string } = {
  business: 'Business',
  regular: 'Regular',
};

export const LESSON_FOCUSES = [
  'grammar',
  'listening',
  'mixed',
  'pronunciation',
  'reading',
  'review',
  'speaking',
  'vocabulary',
  'writing',
] as const;
export type LessonFocus = (typeof LESSON_FOCUSES)[number];
export const LESSON_FOCUS_TITLES: { [key in LessonFocus]: string } = {
  grammar: 'Grammar',
  listening: 'Listening',
  mixed: 'Mixed',
  pronunciation: 'Pronunciation',
  reading: 'Reading',
  review: 'Review',
  speaking: 'Speaking',
  vocabulary: 'Vocabulary',
  writing: 'Writing',
};
