import { apiClient } from '@features/api';

import { ChapterSearchResultType } from '../SearchResultItem';

import type { ChapterQueryParamsType } from './types';

const getQueryParams = ({ languages, courses, isPublished, lessonTypes, numLessons }: ChapterQueryParamsType) => {
  return {
    languages: languages || undefined,
    courses: courses || undefined,
    lessonTypes: lessonTypes || undefined,
    isPublished: isPublished !== null ? isPublished : undefined,
    fromLessonsCount: typeof numLessons?.min === 'number' ? numLessons.min : undefined,
    toLessonsCount: typeof numLessons?.max === 'number' ? numLessons.max : undefined,
  };
};

export const searchByChapter = async (
  query: string,
  page: number,
  filtersParams: ChapterQueryParamsType,
): Promise<{ data: { results: ChapterSearchResultType[]; count: number } }> => {
  return await apiClient.noErrorsV2.get('/search/chapters', {
    params: {
      query,
      page,
      ...getQueryParams(filtersParams),
    },
  });
};
