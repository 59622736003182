import { openKTag, closedKTag } from '../../../../localization-utils';

export const getWordsArrayWithMissedWords = (phrase: string): string[] => {
  if (phrase && phrase.length) {
    if (phrase.includes(openKTag) || phrase.includes(closedKTag)) {
      return phrase
        ?.split(openKTag)
        .map((item) => (item.includes(closedKTag) ? `!missed!${item}` : item))
        .reduce((result: string[], item) => [...result, ...item.split(closedKTag)], []);
    } else if (phrase.includes(openKTag.toUpperCase()) || phrase.includes(closedKTag.toUpperCase())) {
      return phrase
        ?.split(openKTag.toUpperCase())
        .map((item) => (item.includes(closedKTag.toUpperCase()) ? `!missed!${item}` : item))
        .reduce((result: string[], item) => [...result, ...item.split(closedKTag.toUpperCase())], []);
    }
  }
  return [];
};
