import styled from 'styled-components/macro';

import { ReactComponent as SearchIcon } from './img/search.svg';

const SearchIconContainer = styled.div<{ isMenuOpen: boolean }>`
  position: absolute;
  left: 1rem;
  top: 0.5rem;

  svg {
    fill: ${({ theme, isMenuOpen }) => (isMenuOpen ? theme.color.brandWhite : theme.color.darkBlue15)};
  }
`;

export const SearchImage = ({ isMenuOpen }: { isMenuOpen: boolean }) => (
  <SearchIconContainer isMenuOpen={isMenuOpen}>
    <SearchIcon />
  </SearchIconContainer>
);
