import { FunctionComponent } from 'react';
import classnames from 'classnames';

import { KeyboardShortcut } from './common';
import { HighlighterContentProps } from '../../PreviewModalProps';
import { TranslationsPanelContentInterface } from '@common/interfaces/exercises/TranslationsPanelContentInterface';
import { getValueByLanguage, getCorrectPhrase, openKTag } from '../../../../localization-utils';

import './HighlighterContent.scss';

export const HighlighterContent: FunctionComponent<HighlighterContentProps> = ({
  content,
  deviceType,
  answerState,
  courseLearningLanguage,
}: HighlighterContentProps) => {
  const isCorrectSelected = answerState === 1;
  const isIncorrectSelected = answerState === 2;
  return (
    <>
      <div className="highlighter-phrases">
        {content.phrases?.map((phrase: TranslationsPanelContentInterface, phraseIndex: number) => {
          // All words in sentence
          const phraseArray = getValueByLanguage(phrase.textLocalizations, courseLearningLanguage).split(' ');

          // Amount of words in previous phrases
          const previousAmount =
            content.phrases
              ?.slice(0, phraseIndex)
              .reduce((length: number, prevPhrase: TranslationsPanelContentInterface) => {
                const phraseArray = getValueByLanguage(prevPhrase.textLocalizations, courseLearningLanguage).split(' ');
                return length + phraseArray.length;
              }, 1) || 0;

          return (
            <div className="highlighter-phrase">
              {phraseArray?.map((word: string, index: number) => {
                // First incorrect word in sentence(to mark it as incorrect)
                const selectedWord = getValueByLanguage(phrase.textLocalizations, courseLearningLanguage)
                  .split(' ')
                  .find((word: string) => !word.includes(openKTag) && !word.includes(openKTag.toUpperCase()));

                return (
                  <>
                    {word ? (
                      <div
                        className={classnames(
                          'highlighter-phrase__word',
                          {
                            'highlighter-phrase__word--correct':
                              isCorrectSelected && (word.includes(openKTag) || word.includes(openKTag.toUpperCase())),
                          },
                          {
                            'highlighter-phrase__word--unknown':
                              (isCorrectSelected &&
                                !word.includes(openKTag) &&
                                !word.includes(openKTag.toUpperCase())) ||
                              (isIncorrectSelected &&
                                !word.includes(openKTag) &&
                                !word.includes(openKTag.toUpperCase()) &&
                                word !== selectedWord),
                          },
                          {
                            'highlighter-phrase__word--incorrect':
                              isIncorrectSelected &&
                              !word.includes(openKTag) &&
                              !word.includes(openKTag.toUpperCase()) &&
                              selectedWord === word,
                          },
                        )}
                      >
                        {getCorrectPhrase(word)}
                        <KeyboardShortcut answerState={null} value={index + previousAmount} />
                      </div>
                    ) : null}
                  </>
                );
              })}
            </div>
          );
        })}
      </div>
    </>
  );
};
