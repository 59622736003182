import { useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { $createTextNode, TextNode, $nodesOfType } from 'lexical';
import { LexicalTypeaheadMenuPlugin, TypeaheadOption } from '@lexical/react/LexicalTypeaheadMenuPlugin';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import Avatar from 'react-avatar';
import styled from 'styled-components';

import { $createMentionNode, MentionNode } from './MentionNode';

const MentionMenuItemAvatar = styled(Avatar)`
  border-radius: 50%;
`;

const MentionMenuItemWrapper = styled.li`
  padding: 1rem 1.6rem;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 0.8rem;
  align-items: center;

  font-size: 1.6rem;
  line-height: 150%;
  cursor: default;

  &:hover {
    background-color: ${({ theme }) => theme.colorV2.baseEditorMentionsMenuBackground};
  }
`;

const MentionsMenu = styled.ul`
  position: absolute;
  display: block;
  background-color: ${({ theme }) => theme.colorV2.baseEditorMentionsMenuBackground};
  border: 0.1rem solid ${({ theme }) => theme.colorV2.baseEditorMentionsMenuBorder};
  border-radius: 0.8rem;
  padding: 0.4rem 0;
  width: 26rem;
  z-index: 10000;

  transform: translateY(2.4rem);
`;

class MentionTypeaheadOption extends TypeaheadOption {
  name: string;
  id: string;

  constructor(name: string, id: string) {
    super(name);
    this.name = name;
    this.id = id;
  }
}

const MentionsMenuItem = ({
  index,
  isSelected,
  onClick,
  onMouseEnter,
  option,
}: {
  index: number;
  isSelected: boolean;
  onClick: () => void;
  onMouseEnter: () => void;
  option: MentionTypeaheadOption;
}) => {
  let className = 'item';
  if (isSelected) {
    className += ' selected';
  }

  return (
    <MentionMenuItemWrapper
      key={option.key}
      tabIndex={-1}
      className={className}
      ref={option.setRefElement}
      role="option"
      aria-selected={isSelected}
      id={'typeahead-item-' + index}
      onMouseEnter={onMouseEnter}
      onClick={onClick}
    >
      <MentionMenuItemAvatar name={option.name} size="24" />
      <span>{option.name}</span>
    </MentionMenuItemWrapper>
  );
};

type UserInfo = {
  id: string;
  fullName: string;
};

type MentionPluginProps = {
  findUsers: (query: string) => Promise<UserInfo[] | null>;
  onChange: (mentions: string[]) => void;
};

const cache: Record<string, UserInfo[]> = {};

export const MentionsPlugin = ({ findUsers, onChange }: MentionPluginProps) => {
  const [editor] = useLexicalComposerContext();

  const [queryString, setQueryString] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<UserInfo[] | null>(null);

  useEffect(() => {
    if (!queryString) {
      setUsers([]);
      setLoading(false);

      return;
    }

    if (cache[queryString]) {
      setUsers(cache[queryString]);
      setLoading(false);

      return;
    }

    setLoading(true);

    let cancelled = false;

    findUsers(queryString).then((users) => {
      if (cancelled) {
        return;
      }

      setUsers(users);
      setLoading(false);
    });

    return () => {
      cancelled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryString]);

  const options = users ? users.map((user) => new MentionTypeaheadOption(user.fullName, user.id)) : [];

  const checkForMentionMatch = useCallback(
    (text: string) => {
      const match = text.match(/@(\w*)$/);

      if (match) {
        return {
          leadOffset: match.index || 0,
          matchingString: match[1],
          replaceableString: match[0],
        };
      }

      editor.getEditorState().read(() => {
        const mentionIds = $nodesOfType(MentionNode).map((mentionNode) => mentionNode.getId());

        onChange(mentionIds);
      });

      return null;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editor],
  );

  const onSelectOption = useCallback(
    (selectedOption: MentionTypeaheadOption, nodeToReplace: TextNode | null, closeMenu: () => void) => {
      editor.update(() => {
        const mentionNode = $createMentionNode(selectedOption.name, selectedOption.id);
        const spaceNode = $createTextNode(' ');
        if (nodeToReplace) {
          nodeToReplace.replace(mentionNode);
          mentionNode.insertAfter(spaceNode);
          spaceNode.select();
        }
        closeMenu();
      });
    },
    [editor],
  );

  return (
    <LexicalTypeaheadMenuPlugin
      onQueryChange={setQueryString}
      onSelectOption={onSelectOption}
      options={options}
      menuRenderFn={(anchorElementRef, { selectedIndex, selectOptionAndCleanUp, setHighlightedIndex }) =>
        anchorElementRef.current
          ? createPortal(
              <MentionsMenu>
                {loading && <MentionMenuItemWrapper>Loading</MentionMenuItemWrapper>}
                {!loading && options.length === 0 && (
                  <MentionMenuItemWrapper>No options available</MentionMenuItemWrapper>
                )}
                {!loading &&
                  options.map((option, i: number) => (
                    <MentionsMenuItem
                      index={i}
                      isSelected={selectedIndex === i}
                      onClick={() => {
                        setHighlightedIndex(i);
                        selectOptionAndCleanUp(option);
                      }}
                      onMouseEnter={() => {
                        setHighlightedIndex(i);
                      }}
                      key={option.key}
                      option={option}
                    />
                  ))}
              </MentionsMenu>,
              anchorElementRef.current,
            )
          : null
      }
      triggerFn={checkForMentionMatch}
    />
  );
};
