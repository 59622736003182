import {
  BaseEditor,
  Button,
  ActionItemPlugin,
  ActionItemType,
  CustomListItemNode,
  FocusBlurPlugin,
  InitialValuePlugin,
  KeyboardActionsPlugin,
  MentionsPlugin,
  MentionNode,
  ReadOnlyPlugin,
  ToolbarPlugin,
} from '@features/theme';

import { ListNode } from '@lexical/list';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';

import { useUserList } from '@features/users';

import { UserInfoType } from '../../types';

const CommentEditorWrapper = styled.div<{ dirty: boolean }>`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  width: 100%;

  .base-editor-keyboardActionsFootnote {
    opacity: ${({ dirty }) => (dirty ? 1 : 0)};
    transition: opacity 0.3s ease-out;
  }
`;

const StyledBaseEditor = styled(BaseEditor)<{ collapsed: boolean }>`
  border-color: ${({ collapsed, theme }) =>
    collapsed ? theme.colorV2.commentEditorCollapsedBorder : theme.colorV2.commentEditorActiveBorder};
  min-height: ${({ collapsed }) => (collapsed ? '4.4rem' : '16.5rem')};
  font-size: 1.6rem;
  height: fit-content;
  transition: height 0.3s ease-in-out;
  overflow: ${({ collapsed }) => (collapsed ? 'hidden' : 'visible')};

  [role='toolbar'] {
    display: ${({ collapsed }) => (collapsed ? 'none' : 'flex')};
    opacity: ${({ collapsed }) => (collapsed ? 0 : 1)};
    transition: opacity 0.3s ease-in-out;
  }
`;

const CommentEditorActions = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 0.4rem;
`;

type CommentEditorProps = {
  busy: boolean;
  content?: string;
  id?: string;
  saveLabel?: string;
  onCancelComment?: () => void;
  onSaveComment: (comment: string, mentionIds: string[], actionItems: ActionItemType[]) => void;
};

export const CommentEditor = ({
  busy,
  content,
  id,
  saveLabel = 'Save',
  onCancelComment,
  onSaveComment,
}: CommentEditorProps) => {
  const [dirty, setDirty] = useState(false);
  const [initialValue, setInitialValue] = useState<string | undefined>(content || '');
  const [isIdle, setIsIdle] = useState(true);
  const [mentionIds, setMentionIds] = useState<string[]>([]);
  const [actionItems, setActionItems] = useState<ActionItemType[]>([]);
  const [value, setValue] = useState(initialValue);

  const location = useLocation();

  const { userList } = useUserList();

  const actionItemPlaceholder = "Type an action, use '@' to assign to someone...";
  const keyboardActionsFootnote = `<strong>Return</strong> for new line, <strong>Shift + Enter</strong> to ${saveLabel.toLowerCase()}`;

  const toolbarConfig = {
    actionItem: true,
    underline: false,
  };

  const findUsers = async (query: string): Promise<UserInfoType[] | null> => {
    return new Promise(async (resolve) => {
      try {
        const result = userList.filter((user) => user.fullName.toLowerCase().includes(query.toLowerCase()));
        resolve(result);
      } catch (error) {
        console.error(error);
        return null;
      }
    });
  };

  const resetEditor = () => {
    setInitialValue(content || '');
    setValue('');
    setIsIdle(true);
  };

  const handleCancelComment = () => {
    onCancelComment && onCancelComment();
    resetEditor();
  };

  const handleSaveComment = (content: string, mentionIds: string[], actionItems: ActionItemType[]) => {
    onSaveComment(content, mentionIds, actionItems);
    resetEditor();
  };

  const onActionItemsChange = (actionItems: ActionItemType[]) => {
    setActionItems(actionItems);
  };

  const onMentionsChange = (mentionIds: string[]) => {
    setMentionIds(mentionIds);
  };

  useEffect(() => {
    if (dirty) setInitialValue(undefined);
  }, [dirty]);

  useEffect(() => {
    setDirty(!!value?.trim().length);
  }, [value]);

  useEffect(() => {
    resetEditor();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <CommentEditorWrapper data-testid="comment-editor" dirty={dirty}>
      <StyledBaseEditor
        collapsed={isIdle}
        id={id}
        nodes={[MentionNode, ListNode, CustomListItemNode]}
        placeholder="Add a comment"
        onChange={({ markdown }) => {
          setValue(markdown);
        }}
        headerPlugins={[<ToolbarPlugin key="_toolbarPlugin" config={toolbarConfig} reset={isIdle} />]}
        editorPlugins={[
          <FocusBlurPlugin key="_focusBlurPlugin" onFocus={() => isIdle && setIsIdle(false)} />,
          <ReadOnlyPlugin key="_readOnlyPlugin" readonly={busy} />,
          <InitialValuePlugin key="_initialValuePlugin" forceClear initialValue={initialValue} valueType="markdown" />,
          <MentionsPlugin findUsers={findUsers} key="_mentionsPlugin" onChange={onMentionsChange} />,
          <ActionItemPlugin
            key="_actionItemPlugin"
            placeholder={actionItemPlaceholder}
            onChange={onActionItemsChange}
          />,
          <KeyboardActionsPlugin
            key="_keyboardActionsPlugin"
            footnote={keyboardActionsFootnote}
            onKeyEnter={() => handleSaveComment(value as string, mentionIds, actionItems)}
          />,
        ]}
      />
      {!isIdle && (
        <CommentEditorActions>
          <Button disabled={busy} variant="tertiary" onClick={handleCancelComment}>
            Cancel
          </Button>
          <Button disabled={busy || !dirty} onClick={() => handleSaveComment(value as string, mentionIds, actionItems)}>
            {saveLabel}
          </Button>
        </CommentEditorActions>
      )}
    </CommentEditorWrapper>
  );
};
