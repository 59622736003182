import { UISliceInterface } from '@common/interfaces/slices/UISliceInterface';
import { PublishingActions } from '@actions/PublishingActions';
import { PayloadAction } from '@reduxjs/toolkit';

const TranslationsPanelUIReducers = {
  [PublishingActions.TOGGLE_QUEUE_SIDEBAR_VISIBILITY]: (
    state: UISliceInterface,
    _action: PayloadAction,
  ): UISliceInterface => {
    return {
      ...state,
      panels: {
        ...state.panels,
        publishing: {
          visible: !state.panels.publishing.visible,
        },
      },
    };
  },
};

export default TranslationsPanelUIReducers;
