import { FunctionComponent, useState } from 'react';
import { TranslationTipActionsCreator } from '@actionCreators/TranslationTipActionsCreator';
import { ErrorsFromOtherPlacesModal } from './ErrorsFromOtherPlacesModal/ErrorsFromOtherPlacesModal';
import { ExerciseRelatedErrorsInterface } from '@common/interfaces/contentTypes/ResourceBundleInterface';
import { useAppDispatch, useAppSelector } from '@redux/store';
import warningIcon from '@static/svg/warning.svg';

import './ErrorFromOtherPlacesDisplayer.scss';
import { selectIsIssuesShown } from '@selectors/UiSelectors';

interface ErrorFromOtherPlacesDisplayerProps {
  id: string;
  exerciseRelatedErrors: ExerciseRelatedErrorsInterface[];
}

export const ErrorFromOtherPlacesDisplayer: FunctionComponent<ErrorFromOtherPlacesDisplayerProps> = ({
  id,
  exerciseRelatedErrors,
}: ErrorFromOtherPlacesDisplayerProps) => {
  const dispatch = useAppDispatch();
  const isIssuesShown = useAppSelector(selectIsIssuesShown);

  const [isModalOpened, setIsModalOpened] = useState(false);

  const showOtherPlacesErrorModal = () => {
    setIsModalOpened(true);
    dispatch(TranslationTipActionsCreator.setErrorBundlePlaces([]));
  };

  return (
    <>
      {isModalOpened && (
        <ErrorsFromOtherPlacesModal
          handleClose={() => setIsModalOpened(false)}
          id={id}
          exerciseRelatedErrors={exerciseRelatedErrors}
        />
      )}
      {isIssuesShown ? (
        <div className="error-displayer-from-other-places">
          <img src={warningIcon} alt="Warning" className="error-displayer-from-other-places__image" />
          <span>This Resource Bundle is also shared in the places where issues found.</span>
          <span className="error-displayer-from-other-places__link" onClick={showOtherPlacesErrorModal}>
            See all
          </span>
        </div>
      ) : null}
    </>
  );
};
