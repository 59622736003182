import { Button } from 'react-bootstrap';
import styled from 'styled-components';

import { ReactComponent as ArrowIcon } from './arrow.svg';

const PaginationContainer = styled.div`
  display: flex;
  width: 25rem;
  justify-content: space-between;
`;

const PaginationEllipsis = styled.span`
  align-items: center;
  display: flex;
  font-size: 1.4rem;
  margin: 0 0.4rem;

  &:after {
    content: '...';
  }
`;

const PaginationButton = styled(Button)<{
  disabled?: boolean;
  selected?: boolean;
}>`
  all: unset;
  align-items: center;
  background-color: transparent;
  border: none;
  border-radius: 50%;
  box-shadow: none;
  color: ${({ theme }) => theme.colorV2.paginationButtonBaseColor} !important;
  display: flex;
  font-size: 1.4rem;
  font-weight: 700;
  height: 2.2rem;
  justify-content: center;
  margin: 0 0.4rem;
  outline: none;
  padding: 0;
  transition: all 0.5s linear;
  width: 2.2rem;

  &:active,
  &:focus {
    box-shadow: none;
    outline: none;
    background-color: transparent;

    &:hover {
      background-color: ${({ theme }) => theme.colorV2.paginationButtonHoverFocusedBackground};
    }
  }

  ${({ disabled }) =>
    disabled &&
    `
    background-color: transparent !important;
    border-color: transparent !important;
  `}

  ${({ selected, theme }) =>
    selected
      ? `
    color: ${theme.colorV2.pagitationButtonSelectedColor} !important;
    cursor: default !important;;
    background-color: transparent !important;
  `
      : `
    &:hover {
      color: ${theme.colorV2.paginationButtonHoverBaseBackground} !important;
    }
  `}
`;

type PaginationProps = {
  currentPage: number;
  hasNextPage?: boolean;
  totalPages?: number | null;
  className?: string;
  onPageChange: (page: number) => void;
};

export const Pagination = ({
  currentPage,
  hasNextPage,
  totalPages = null,
  className,
  onPageChange,
}: PaginationProps) => {
  const renderPaginationItem = (page: number) => {
    return (
      <PaginationButton
        aria-current={currentPage === page}
        aria-label={`Go to page ${page}`}
        onClick={() => onPageChange(page)}
        selected={currentPage === page}
      >
        {page}
      </PaginationButton>
    );
  };

  return (
    <PaginationContainer role="navigation" className={className}>
      <PaginationButton
        aria-label="Go to previous page"
        disabled={currentPage === 1}
        onClick={() => onPageChange(currentPage - 1)}
      >
        <ArrowIcon style={{ transform: 'rotate(-180deg)' }} />
      </PaginationButton>

      {renderPaginationItem(1)}

      {currentPage > 3 && currentPage - 1 > 2 && <PaginationEllipsis />}

      {currentPage > 2 && currentPage - 1 !== 1 && renderPaginationItem(currentPage - 1)}

      {currentPage !== 1 && currentPage !== totalPages && renderPaginationItem(currentPage)}

      {totalPages &&
        currentPage < totalPages - 1 &&
        currentPage + 1 !== totalPages &&
        renderPaginationItem(currentPage + 1)}

      {totalPages && currentPage < totalPages - 2 && currentPage + 1 < totalPages - 1 && <PaginationEllipsis />}

      {totalPages && totalPages > 1 && renderPaginationItem(totalPages)}

      <PaginationButton
        aria-label="Go to next page"
        disabled={currentPage === totalPages || hasNextPage === false}
        onClick={() => onPageChange(currentPage + 1)}
      >
        <ArrowIcon />
      </PaginationButton>
    </PaginationContainer>
  );
};
