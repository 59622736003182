import styled from 'styled-components';
import { SearchButton } from './SearchButton';

const Wrapper = styled.div`
  margin-left: auto;
`;

export const HeaderSearch = () => (
  <Wrapper>
    <SearchButton />
  </Wrapper>
);
