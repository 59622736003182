import { CommonActions } from '@actions/CommonActions';
import { type DisplayContentToUserModeType } from '@common/enums/DisplayContentToUserMode';
import { Language } from '@features/content/languages';
import { FormikValuesInterface } from '@helpers/formikInitialValuesHelper';
import { HighlighterExerciseActions } from '@actions/HighlighterExerciseActions';
import HighlighterExerciseInterface from '@components/Exercises/Highlighter/interfaces/HighlighterExerciseInterface';

export const HighlighterExerciseActionsCreator = {
  save: (exercise: HighlighterExerciseInterface, learningLanguage: Language, values: FormikValuesInterface) => ({
    type: HighlighterExerciseActions.SAVE,
    payload: {
      exercise,
      learningLanguage,
      values,
    },
  }),
  setInstructionsLanguage: (instructionsLanguage: DisplayContentToUserModeType) => ({
    type: HighlighterExerciseActions.SET_INSTRUCTIONS_LANGUAGE,
    payload: {
      instructionsLanguage,
    },
  }),
  setPhrase1Text: (value: string, language: string) => ({
    type: HighlighterExerciseActions.SET_PHRASE1_TEXT,
    payload: {
      value,
      language,
    },
  }),
  setPhrase2Text: (value: string, language: string) => ({
    type: HighlighterExerciseActions.SET_PHRASE2_TEXT,
    payload: {
      value,
      language,
    },
  }),
  setPhrase3Text: (value: string, language: string) => ({
    type: HighlighterExerciseActions.SET_PHRASE3_TEXT,
    payload: {
      value,
      language,
    },
  }),
  nullifyPhrase: (arrayRowPosition: number) => ({
    type: CommonActions.NULLIFY_FIELD,
    payload: {
      field: 'phrases',
      arrayRowPosition,
    },
  }),
};
