import 'draft-js/dist/Draft.css';
import React, { FunctionComponent, useEffect, useState } from 'react';
import KTagsCreator from '@components/Exercises/Common/KTagsCreator/KTagsCreator';
import KTagsCreatorHelpers from '@components/Exercises/Common/KTagsCreator/KTagsCreatorHelpers';
import KTagSelectionStrategy from '@components/Exercises/Common/KTagsCreator/strategies/KTagSelectionStrategy';
import FillgapToolProps from './FillgapToolProps';

const FillgapTool: FunctionComponent<FillgapToolProps> = ({
  value,
  placeholder,
  className,
  onChange,
  onChangeGroups,
  maxGroups = 3,
  gapMode = true,
  errors = [],
  onChangeInstant,
  isChangeBlocked = false,
}: FillgapToolProps) => {
  let [totalGroups, setTotalGroups] = useState(() => {
    return !value ? 0 : KTagsCreatorHelpers.countKTags(value);
  });

  useEffect(() => {
    onChangeGroups && onChangeGroups(totalGroups);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalGroups]);

  return (
    <div className={`fill-gap-tool ${className !== undefined ? className : ''}`}>
      <div className="fill-gap-tool__gaps">
        {gapMode ? 'Gap' : 'Group'} limit:
        <span
          className={`fill-gap-tool__available-gaps ${
            totalGroups > maxGroups ? 'fill-gap-tool__available-gaps--exceeded' : ''
          }`}
        >
          {`${totalGroups}/${maxGroups}`}
        </span>
      </div>
      <KTagsCreator
        addGroupStrategy={KTagSelectionStrategy}
        value={value}
        placeholder={placeholder}
        gapMode={gapMode}
        onChange={(availableText: string) => {
          const totalGroups = KTagsCreatorHelpers.countKTags(availableText);
          setTotalGroups(totalGroups);

          onChange && onChange(availableText);
        }}
        errors={errors}
        onChangeInstant={onChangeInstant}
        isChangeBlocked={isChangeBlocked}
      />
    </div>
  );
};

export default FillgapTool;
