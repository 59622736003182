import { FunctionComponent, useMemo } from 'react';
import classnames from 'classnames';
import ReactPlayer from 'react-player';
import { useFormikContext } from 'formik';

import { FlashcardContentProps } from '../../PreviewModalProps';

import { VocabularyMark } from './common';
import { LocalizationInterface } from '@common/interfaces/localization/LocalizationInterface';
import { FormikValuesInterface } from '@helpers/formikInitialValuesHelper';
import playerControllers from './img/player.svg';
import dynamicIcon from './img/dynamic.svg';
import { getValueByLanguage } from '../../../../localization-utils';

import './FlashcardContent.scss';

export const FlashcardContent: FunctionComponent<FlashcardContentProps> = ({
  content,
  deviceType,
  courseLearningLanguage,
  isForList,
}: FlashcardContentProps) => {
  const { values }: { values: FormikValuesInterface } = useFormikContext();
  const mainImage = useMemo(
    () => getValueByLanguage(content.learningWordBundle?.image?.imageLocalizations),
    [content.learningWordBundle],
  );
  const mainVideo = useMemo(
    () => getValueByLanguage(content.learningWordBundle?.video?.videoLocalizations),
    [content.learningWordBundle],
  );
  const learningWord = useMemo(
    () =>
      getValueByLanguage(
        isForList ? content.learningWordBundle?.phrase?.textLocalizations : values.learningWordBundle_phrase,
        courseLearningLanguage,
      ),
    [values, content.learningWordBundle, isForList, courseLearningLanguage],
  );
  const altLearningWords = useMemo(
    () =>
      content.learningWordBundle?.phrase?.textLocalizations.find(
        (localization: LocalizationInterface) => localization.language === courseLearningLanguage,
      )?.alternativeValues,
    [content.learningWordBundle, courseLearningLanguage],
  );
  const learningENWord = useMemo(
    () =>
      getValueByLanguage(
        isForList ? content.learningWordBundle?.phrase?.textLocalizations : values.learningWordBundle_phrase,
      ),
    [isForList, content.learningWordBundle, values],
  );
  const learningExample = useMemo(
    () =>
      getValueByLanguage(
        isForList ? content.learningWordBundle?.example?.textLocalizations : values.learningWordBundle_example,
        courseLearningLanguage,
      ),
    [values, isForList, content.learningWordBundle, courseLearningLanguage],
  );

  const mainAudio = useMemo(
    () => getValueByLanguage(content.learningWordBundle?.phrase?.audioLocalizations, courseLearningLanguage),
    [content.learningWordBundle, courseLearningLanguage],
  );
  const exampleAudio = useMemo(
    () => getValueByLanguage(content.learningWordBundle?.example?.audioLocalizations, courseLearningLanguage),
    [content.learningWordBundle, courseLearningLanguage],
  );

  return (
    <>
      <div className={classnames('flashcard-image', `flashcard-image--${deviceType}`)}>
        {mainVideo ? (
          <div className="flashcard__video">
            <ReactPlayer url={mainVideo} controls={false} width={'initial'} />
            <img src={playerControllers} className="flashcard-player" alt="Player" />
            <VocabularyMark isVocabulary={content.learningWordBundle?.isVocabulary} />
          </div>
        ) : (
          <>
            {mainImage ? (
              <>
                <img src={mainImage} className="flashcard-background" alt="Flashcard" />
                <VocabularyMark isVocabulary={content.learningWordBundle?.isVocabulary} />
              </>
            ) : null}
            {mainAudio ? <img src={playerControllers} className="flashcard-player" alt="Player" /> : null}
          </>
        )}
      </div>
      <span className="flashcard-text">
        {altLearningWords?.length ? [learningWord, ...altLearningWords].join(' / ') : learningWord}
      </span>
      <span className="flashcard-text--interface">{learningENWord}</span>
      {learningExample && (
        <div className="flashcard-examples">
          <div className="flashcard-example">
            <span className="flashcard-example__text">Example</span>
            {exampleAudio ? <img src={dynamicIcon} alt="Dynamic" /> : null}
          </div>
          <span className="flashcard-examples__example">{learningExample}</span>
        </div>
      )}
    </>
  );
};
