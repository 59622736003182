import React from 'react';
import styled from 'styled-components/macro';
import { Dropdown } from 'react-bootstrap';

import { Button } from '@features/theme';
import { ReactComponent as PlusIcon } from './img/plus.svg';

const FiltersButton = styled(Button)`
  background-color: transparent;
  font-size: 1.6rem;
  line-height: 3.6rem;
  height: 3.6rem;

  svg {
    height: 2.6rem;
    margin-right: 0.4rem;
    width: 2.6rem;
  }
`;

const FiltersDropdown = styled(Dropdown)`
  min-width: unset;
`;

const FiltersDropdownMenu = styled(Dropdown.Menu)`
  border: 0.1rem solid ${({ theme }) => theme.color.quaternaryBase};
  border-radius: 0.8rem;
  box-shadow: 0 0.4rem 0.8rem rgba(0, 0, 0, 0.05);
  padding: 0;
`;

const FiltersDropdownItem = styled(Dropdown.Item)`
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  height: 4.4rem;
`;

type CustomToggleProps = {
  isDisabled: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const FiltersDropdownToggle = React.forwardRef(
  ({ isDisabled, onClick }: CustomToggleProps, ref: React.Ref<HTMLButtonElement>) => (
    <FiltersButton ref={ref} disabled={isDisabled} size="S" icon={<PlusIcon />} variant="secondary" onClick={onClick}>
      Filters
    </FiltersButton>
  ),
);

export const AddFiltersButton = ({
  isDisabled,
  filterOptions,
  onFilterAdd,
}: {
  isDisabled: boolean;
  filterOptions: string[];
  onFilterAdd: (newFilterLabel: string) => void;
}) => (
  <FiltersDropdown onSelect={(filterOption: string | null) => filterOption && onFilterAdd(filterOption)}>
    <Dropdown.Toggle isDisabled={isDisabled} as={FiltersDropdownToggle} />

    <FiltersDropdownMenu>
      {filterOptions.map((filterOption, idx) => (
        <FiltersDropdownItem key={`${filterOption}-${idx}`} eventKey={filterOption}>
          {filterOption}
        </FiltersDropdownItem>
      ))}
    </FiltersDropdownMenu>
  </FiltersDropdown>
);
