import { Button } from '@features/theme';
import Avatar from 'react-avatar';
import styled, { css } from 'styled-components/macro';

export const Comment = styled.div<{ editable: boolean }>`
  position: relative;
  display: flex;
  border-radius: 0.8rem;
  margin: 0 -0.8rem 1.8rem -0.8rem;
  padding: 0.8rem;
  ${({ editable, theme }) =>
    editable &&
    `&:hover {
      background: ${theme.colorV2.commentItemHoverBackground};
  }`}
`;

export const CommentData = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: ${({ theme }) => theme.colorV2.commentItemText}};
  padding-left: 1.6rem;
  position: relative;
`;

export const CommentMeta = styled.div`
  display: flex;
  font-size: 1.4rem;
  margin-bottom: 0.8rem;
`;

export const CommentSentAt = styled.span`
  font-weight: 400;
  color: ${({ theme }) => theme.colorV2.commentItemMetadataText};
`;

export const CommentToolbar = styled.div`
  display: flex;
  gap: 0.8rem;
  position: absolute;
  right: 0.8rem;
  top: 0.8rem;
  z-index: 1;
`;

export const CommentToolbarButton = styled(Button)<{ title: string }>`
  padding: 0;

  span > svg {
    margin: 0;
  }
`;

export const Message = styled.div<{ collapsed: boolean }>`
  font-size: 1.6rem;
  font-weight: 400;
  overflow: hidden;
  pointer-events: none;

  ${({ collapsed }) =>
    collapsed &&
    css`
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      display: -webkit-box;
    `}

  mark {
    background-color: ${({ theme }) => theme.colorV2.commentEditorMentionBackground};
    border-radius: 0.8rem;
    color: ${({ theme }) => theme.colorV2.baseEditorMentionColor};
    display: inline-block;
    padding: 0 0.4rem;
  }

  .action-item-list {
    list-style: none;
    padding-left: 0;
  }

  .action-item {
    margin-bottom: 1rem;
    min-height: 2rem;
    position: relative;
    padding-left: 2.4rem;

    &:before {
      background-size: cover;
      border: 0.1rem solid ${({ theme }) => theme.colorV2.uiDivider};
      border-radius: 0.4rem;
      content: '';
      cursor: pointer;
      display: block;
      height: 2rem;
      left: 0;
      pointer-events: auto;
      position: absolute;
      top: 0.2rem;
      width: 2rem;
    }

    input[type='checkbox'] {
      display: none;
    }
  }

  .action-item-checked {
    &:before {
      border: 1px solid ${({ theme }) => theme.colorV2.accentPrimary};
      background-color: ${({ theme }) => theme.colorV2.accentPrimary};
      background-repeat: no-repeat;
    }

    &:after {
      border-color: ${({ theme }) => theme.colorV2.textLight};
      border-style: solid;
      border-width: 0 0.1rem 0.1rem 0;
      content: '' !important;
      cursor: pointer;
      display: block;
      height: 0.8rem;
      left: 0.7rem;
      position: absolute;
      right: 0.7rem;
      top: 0.6rem;
      transform: rotate(45deg);
      width: 0.5rem;
    }
  }
`;

export const ShowMore = styled.span`
  align-self: flex-end;
  color: ${({ theme }) => theme.colorV2.commentItemButtonShowMoreText};
  font-size: 1.4rem;
  font-weight: 700;
  cursor: pointer;
`;

export const UserAvatar = styled(Avatar)`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.1);
`;

export const UserName = styled.span`
  font-weight: 700;
  margin-right: 0.4rem;
`;

export const ConfirmDeleteBlock = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.colorV2.commentItemConfirmDeleteBackground};
  border-radius: 0.8rem;
  color: ${({ theme }) => theme.colorV2.commentItemConfirmDeleteText};
  display: flex;
  font-size: 1.4rem;
  justify-content: space-between;
  line-height: 5rem;
  height: 5rem;
  padding: 0 1.6rem 0 5rem;
  margin-bottom: 0.8rem;
`;

export const DeleteButtonsContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const CancelButton = styled.span`
  color: ${({ theme }) => theme.colorV2.commentItemButtonCancelText};
  font-weight: 700;
  margin-right: 16px;
  cursor: pointer;
`;

export const ConfirmButton = styled.span`
  font-weight: 700;
  color: ${({ theme }) => theme.colorV2.commentItemButtonConfirmText};
  cursor: pointer;
`;

export const ReactionPill = styled.span`
  border: solid 0.1rem ${({ theme }) => theme.colorV2.uiDivider};
  border-radius: 1rem;
  cursor: pointer;
  font-size: 1.2rem;
  margin-right: 0.6rem;
  padding: 0.2rem 0.8rem;
`;
