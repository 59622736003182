import { random } from 'lodash';
import { useState, Fragment } from 'react';
import { Alert } from 'react-bootstrap';
import styled from 'styled-components';

import { LoadingStage, type LoadingStageType } from '@common/enums/LoadingStage';
import { TableLoader } from '@components/Loaders/TableLoader';

import { DashboardTableItemsInterface } from '../../types';
import { DashboardTableItem } from './DashboardTableItem';
import { FilterControllers } from './FilterControllers';
import { SortControllers } from './SortControllers';

const TableContainer = styled.div`
  padding-bottom: 70px;
  margin: 0 40px;
`;

const TableTitle = styled.h2`
  font-size: 24px;
  margin-bottom: 16px;
`;

export const DashboardTable = ({
  items,
  loaded,
}: {
  items: DashboardTableItemsInterface[];
  loaded: LoadingStageType;
}) => {
  const [filteredItems, setFilteredItems] = useState<DashboardTableItemsInterface[] | undefined>();

  return (
    <TableContainer data-testid="dashboard-table">
      <TableTitle>All content</TableTitle>
      <FilterControllers items={items} loaded={loaded} setFilteredItems={setFilteredItems} />
      {loaded === LoadingStage.loaded && filteredItems !== undefined && filteredItems.length === 0 && (
        <Alert variant="info">No results matching your filters</Alert>
      )}
      {(loaded === LoadingStage.loading && filteredItems?.length === 0) || filteredItems === undefined ? (
        <TableLoader />
      ) : null}
      {filteredItems !== undefined && filteredItems.length > 0 && (
        <>
          <SortControllers setFilteredItems={setFilteredItems} filteredItems={filteredItems} />
          {filteredItems.map((course) => {
            return (
              <Fragment key={`${course.itemType}-${course.id}-${random(0, 100000)}`}>
                <DashboardTableItem course={course} itemType={course.itemType} />
              </Fragment>
            );
          })}
        </>
      )}
    </TableContainer>
  );
};
