import { DBId } from '@common/types/DBId';
import { apiClient } from '@features/api';

import { ExerciseSearchResultDetailsType } from '../types';

export const getExerciseResultDetails = async (
  exerciseId: DBId,
): Promise<{ data: ExerciseSearchResultDetailsType }> => {
  const response = await apiClient.noErrorsV2.get(`/components/${exerciseId}/mappings`);

  return {
    data: {
      mappings: response.data,
    },
  };
};
