import type { ActivityType } from '../ActivityTypes';
import ACTIVITY_ICONS from './_img';
import styled from 'styled-components/macro';

const ImageWrapper = styled.div<{ isLegacy: boolean }>`
  margin-right: 1.2rem;
  align-items: center;
  ${({ isLegacy }) => !isLegacy && 'background-color: #116eee;'}
  flex-shrink: 0;
  border-radius: 50%;
  display: flex;
  height: 5.6rem;
  justify-content: center;
  width: 5.6rem;
`;

const ActivityImage = styled.img<{ isLegacy: boolean }>`
  ${({ isLegacy }) => (isLegacy ? 'height: 90%' : 'filter: brightness(10%) sepia(1) invert(1)')};
`;

export type ActivityTypeIconProps = {
  type: ActivityType;
  isLegacy: boolean;
};

export const ActivityTypeIcon = ({ type, isLegacy }: ActivityTypeIconProps) => {
  const typeImage = ACTIVITY_ICONS[type];

  const chosenImage = isLegacy ? ACTIVITY_ICONS.entity : typeImage;

  return (
    <ImageWrapper isLegacy={isLegacy}>
      <ActivityImage src={chosenImage} alt={type} title={type} isLegacy={isLegacy} />
    </ImageWrapper>
  );
};
