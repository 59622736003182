import { Button } from '@features/theme';
import { ReactComponent as StarIcon } from './img/star.svg';
import styled, { css } from 'styled-components/macro';
import { useState } from 'react';
import { SaveFiltersDialog } from './SaveFiltersDialog';

const Container = styled.div`
  position: relative;
  max-height: 3.6rem;
`;

const StyledButton = styled(Button)`
  ${({ disabled, theme }) => css`
    background-color: ${disabled ? 'transparent' : theme.colorV2.uiBackgroundPrimary};
    border-color: ${theme.colorV2.input.border.default};
    color: ${disabled ? theme.colorV2.textQuaternary : theme.colorV2.input.color.default};
    font-size: 1.4rem;
    height: 3.6rem;
    line-height: 3.6rem;
    padding: 1.2rem 1.6rem;

    svg {
      height: 2.6rem;
      margin-right: 0.2rem;
      width: 2.6rem;

      path {
        fill: ${disabled ? theme.colorV2.textQuaternary : theme.colorV2.input.color.default};
      }
    }

    &:hover {
      svg path {
        fill: ${disabled ? theme.colorV2.textQuaternary : theme.colorV2.accentPrimary};
      }
    }
  `}
`;

const StyledDialog = styled(SaveFiltersDialog)`
  position: absolute;
  right: 0;
  top: calc(100% + 0.4rem);
`;

type SaveSearchButtonProps = {
  onSave: (name: string) => Promise<void>;
  disabled: boolean;
};

export const SaveFilters = ({ disabled, onSave }: SaveSearchButtonProps) => {
  const [open, setOpen] = useState(false);

  return (
    <Container>
      {open && <StyledDialog onSave={onSave} close={() => setOpen(false)} />}
      <StyledButton
        disabled={disabled}
        icon={<StarIcon />}
        size="S"
        variant="tertiary"
        onClick={() => setOpen((state) => !state)}
      >
        Save
      </StyledButton>
    </Container>
  );
};
