import Select from 'react-select';
import './SearchableSelector.scss';

export type SelectorOptionType = { label: string; value: string | null };

export const SearchableSelector = ({
  placeholder = 'Searchable Selector',
  onSelect,
  disabled = false,
  options = [],
  className = '',
  value,
  testId,
}: {
  placeholder?: string;
  onSelect?: (selectedOption: SelectorOptionType | null) => void;
  disabled?: boolean;
  options?: SelectorOptionType[];
  className?: string;
  value?: SelectorOptionType['value'];
  testId?: string;
}) => (
  <Select
    aria-label={testId}
    className={className}
    classNamePrefix="searchable-selector"
    data-testid={testId}
    isDisabled={disabled}
    isSearchable
    options={options}
    placeholder={placeholder}
    value={options.find((option) => option.value === value)}
    onChange={(selectedOption) => onSelect && onSelect(selectedOption)}
  />
);
