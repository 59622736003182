import type { AppState } from '@redux/store';

export const selectGrammarNavigationStructure = ({ grammar }: AppState) => grammar.navigation.structure;
export const selectGrammarNavigationLoadingParentId = ({ grammar }: AppState) => grammar.navigation.loadingParentId;

export const selectGrammarReview = ({ grammar }: AppState) => grammar.grammarReview;
export const selectGrammarReviewId = ({ grammar }: AppState) => grammar.grammarReview.content.id;
export const selectGrammarReviewContent = ({ grammar }: AppState) => grammar.grammarReview.content;
export const selectGrammarReviewCategories = ({ grammar }: AppState) => grammar.grammarReview.categoriesData.categories;

export const selectGrammarCategory = ({ grammar }: AppState) => grammar.grammarCategory;
export const selectGrammarCategoryContent = ({ grammar }: AppState) => grammar.grammarCategory.content;
export const selectGrammarCategoryTopics = ({ grammar }: AppState) => grammar.grammarCategory.topicsData.topics;

export const selectGrammarTopic = ({ grammar }: AppState) => grammar.grammarTopic;
export const selectGrammarTopicContent = ({ grammar }: AppState) => grammar.grammarTopic.content;
export const selectGrammarTopicExercises = ({ grammar }: AppState) => grammar.grammarTopic.exercisesData.exercises;
export const selectGrammarTopicReferenceExercises = ({ grammar }: AppState) =>
  grammar.grammarTopic.exercisesData.exercises.referenceExercises;
export const selectGrammarTopicBankExercises = ({ grammar }: AppState) =>
  grammar.grammarTopic.exercisesData.exercises.bankExercises;

export const selectGrammarExercise = ({ grammar }: AppState) => grammar.grammarExercise;
export const selectGrammarExerciseContent = ({ grammar }: AppState) => grammar.grammarExercise.content;
