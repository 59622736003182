import { takeLatest } from 'redux-saga/effects';

import { TypingExerciseActions } from '@actions/TypingExerciseActions';
import ExerciseSagas from '@sagas/exercises/definitions/ExerciseSagas';
import MediaUploadSagas from '@sagas/mediaUpload/definitions/MediaUploadSagasDefinition';
import TypingExerciseSagas from '@sagas/exercises/definitions/TypingExerciseSagasDefinition';
import type { AppDispatch } from '@redux/store';

export function* TypingExerciseRelatedSagas(dispatch: AppDispatch) {
  yield takeLatest(TypingExerciseActions.SAVE, TypingExerciseSagas.save, dispatch);
  yield takeLatest(TypingExerciseActions.UPLOAD_IMAGE, MediaUploadSagas.uploadImageToExercise);
  yield takeLatest(TypingExerciseActions.REMOVE_AUDIO, ExerciseSagas.removeAudioFromExercise);
}
