import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { CourseInfoInterface } from '@common/interfaces/courses/CourseInfoInterface';
import { RecentPreloader } from './RecentPreloader';
import { RecentCoursesTitle } from './RecentCoursesTitle';
import { RecentCoursesList } from './RecentCoursesList';
import { getRecentCourses } from './counters';
import { useAppSelector } from '@redux/store';
import { selectCoursesInfo } from '@selectors/CoursesSelectors';

const MAX_NUMBER_OF_RECENT_COURSES = 4;

const RecentCoursesWrapper = styled.div`
  margin: 5.6rem 4rem;
`;

export const DashboardRecentCourses = () => {
  const [isDeleteModeOn, setIsDeleteModeOn] = useState(false);
  const [recentCourseIds, setRecentCourseIds] = useState(getRecentCourses(MAX_NUMBER_OF_RECENT_COURSES));

  useEffect(() => {
    const refreshRecentCourses = () => {
      setRecentCourseIds(getRecentCourses(MAX_NUMBER_OF_RECENT_COURSES));
    };

    window.addEventListener('storage', refreshRecentCourses);

    return () => {
      window.removeEventListener('storage', refreshRecentCourses);
    };
  }, []);

  const courses = useAppSelector(selectCoursesInfo);
  const coursesMap = courses.courses.reduce(
    (result, course) => {
      result[course.id] = course;

      return result;
    },
    {} as { [key in string]: CourseInfoInterface },
  );

  const recentCourses = recentCourseIds.map((course) => coursesMap[course.id]).filter((course) => course);

  if (courses.courses.length && !recentCourses.length) {
    return null;
  }

  return (
    <RecentCoursesWrapper>
      <RecentCoursesTitle isDeleteModeOn={isDeleteModeOn} setIsDeleteModeOn={setIsDeleteModeOn} />
      {recentCourses.length ? (
        <RecentCoursesList recentCourses={recentCourses} isDeleteModeOn={isDeleteModeOn} />
      ) : (
        <RecentPreloader />
      )}
    </RecentCoursesWrapper>
  );
};
