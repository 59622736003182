import styled from 'styled-components';

import moreIcon from './more.svg';

const MoreButton = styled.img<{ isExpanded: boolean }>`
  justify-self: center;
  transition: all 0.2s ease-out;
  ${({ isExpanded }) => isExpanded && 'transform: rotate(180deg);'}
`;

export const CardMoreButton = ({ isExpanded }: { isExpanded: boolean }) => (
  <MoreButton src={moreIcon} alt="More" isExpanded={isExpanded} />
);
