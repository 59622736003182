import ComprehensionExerciseInterface from '@components/Exercises/Comprehension/interfaces/ComprehensionExerciseInterface';
import ExerciseDataModelAssembler from '@components/Exercises/ExerciseDataModelAssembler';
import { ExerciseCommonActionCreators as ExerciseCommonActions } from '@actionCreators/ExerciseCommonActionCreator';
import { ExerciseService } from '@common/types/ExerciseService';
import ExercisesService from '@services/ExercisesService';
import { FormikValuesInterface } from '@helpers/formikInitialValuesHelper';
import { AppDispatch } from '@redux/store';
import { clone } from '@helpers/clone';
import { MatchingItemsLanguageType } from '@components/Exercises/MatchUp/enums/MatchingItemsLanguageType';
import { type DisplayContentToUserModeType } from '@common/enums/DisplayContentToUserMode';
import { editComprehensionExercise } from '@services/exercises/editExerciseService';
import { EditComprehensionExerciseRequest } from '@services/exercises/editExerciseTypes';
import { addToast } from '@features/app/toast';

type ComprehensionExerciseServiceType = ExerciseService<ComprehensionExerciseInterface>;

const ensureDataOnShowToUsersInCourseLanguage = (
  showToUsersInCourseValue: MatchingItemsLanguageType | DisplayContentToUserModeType | null,
): any => {
  if (showToUsersInCourseValue === null) return 'interface';
  else return showToUsersInCourseValue;
};

const ComprehensionExerciseService: ComprehensionExerciseServiceType = {
  ensureExerciseFieldsAreReadyForUse(
    exerciseAndEmptyLocalizationBranchesPayload: any,
    exercise: ComprehensionExerciseInterface,
  ) {
    let output = clone(exerciseAndEmptyLocalizationBranchesPayload);

    return ExerciseDataModelAssembler.ensureFieldIsReadyForUse(
      exercise,
      output,
      ['instructions', 'mainBundle', 'title'],
      exerciseAndEmptyLocalizationBranchesPayload,
    );
  },
  async save(dispatch: AppDispatch, exercise: ComprehensionExerciseInterface, values: FormikValuesInterface) {
    try {
      let payload: EditComprehensionExerciseRequest = {
        instructionsLanguage: ensureDataOnShowToUsersInCourseLanguage(exercise.content.instructionsLanguage),
        provider: exercise.content.provider,
        mainVideoEnabled: exercise.content.mainVideoEnabled || false,
        mainTextEnabled: exercise.content.mainTextEnabled || false,
        titleLanguage: ensureDataOnShowToUsersInCourseLanguage(exercise.content.titleLanguage),
        mainContentLanguage: ensureDataOnShowToUsersInCourseLanguage(exercise.content.mainContentLanguage),
        experiment: exercise.content.experiment,
      };

      const updatePayload = await Promise.all([
        (values.instructionsChanged || exercise.content.instructions.changed) && !exercise.content.instructions.isReused
          ? ExercisesService.misc.saveField(dispatch, 'instructions', exercise, payload)
          : { instructions: exercise.content.instructions._id },
        (values.titleChanged || exercise.content?.title?.changed || exercise.content?.titleChanged) &&
        !exercise.content.title?.isReused
          ? ExercisesService.misc.saveField(dispatch, 'title', exercise, payload)
          : { title: exercise.content.title?._id || null },
        values.mainBundle_phraseChanged ||
        exercise.content?.mainBundle?.isVocabularyChanged ||
        exercise.content?.mainBundle?.phraseChanged ||
        exercise.content?.mainBundle?.phrase?.changed ||
        exercise.content?.mainBundle?.image?.changed ||
        exercise.content?.mainBundle?.imageChanged ||
        exercise.content?.mainBundle?.video?.changed ||
        exercise.content?.mainBundle?.videoChanged ||
        exercise.content?.mainBundle?.phrase?.isReused
          ? ExercisesService.misc.saveBundle(dispatch, 'mainBundle', exercise, payload, values)
          : null,
      ]);

      payload = {
        ...payload,
        ...updatePayload.reduce((sum: any, item: any) => ({ ...sum, ...item }), {}),
      };

      if (exercise.content.id) {
        await editComprehensionExercise(exercise.content.id, payload);

        dispatch(ExerciseCommonActions.setSaveProgress({ value: false, updateData: true }));

        addToast({
          type: 'success',
          title: 'This exercise has been saved',
        });
      }
    } catch (e: any) {
      if (e.response?.status === 400) {
        addToast({
          type: 'error',
          title: `${e.response.data?.detail}`,
        });
      }

      throw new Error(e as string);
    }
  },
};

export default ComprehensionExerciseService;
