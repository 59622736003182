import { useState } from 'react';
import { Modal } from 'react-bootstrap';

import { searchByBundle } from '../../service';
import { useSearch } from '../../../SearchContext';
import { ReactComponent as BundleIcon } from '../../img/bundle.svg';
import { BundleSearchResultItem, BundleSearchResultType } from '../../SearchResultItem';
import { SearchResults } from '../../SearchResults';
import {
  DEFAULT_PAGINATION_PAGE,
  SearchHeader,
  SearchResultsWrapper,
  useFiltersState,
  useResultsHandler,
} from '../Common';
import { BundleSearchResultTypeHeader } from '../../SearchResultTypeHeader';
import { useAppSelector } from '@redux/store';
import { selectCourses } from '@selectors/CoursesSelectors';
import { saveFavouriteSearch } from '../Common/favouriteSearches';
import { SEARCH_TYPE_BUNDLE } from '../../../../types';
import type { CommonSearchComponentProps } from '../SearchContent';
import type { BundleQueryParamsType } from '../../service/types';
import { BundleSearchFilters } from './BundleSearchFilters';
import { selectSearchPanel } from '@selectors/UiSelectors';

const defaultFilters: BundleQueryParamsType = {
  languages: null,
  courses: null,
  isReused: null,
  hasImage: null,
  isVocabulary: null,
  hasVideo: null,
};

/**
 * This component is responsible for:
 * - Modal layout for Bundle search
 * - All logic that is specific to Bundle search
 * - Calling Bundle search API
 * - Storing search filters
 * - Storing search results
 */
export const BundleSearch = ({ trackSearch, onBack }: CommonSearchComponentProps) => {
  const availableCourses = useAppSelector(selectCourses);
  const { filtersPreset } = useAppSelector(selectSearchPanel);

  const { search, query } = useSearch();

  const { results, count, clearResults, onSuccess, onError } = useResultsHandler<BundleSearchResultType>();
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGINATION_PAGE);

  const filtersState = useFiltersState(defaultFilters, filtersPreset?.bundle || {});

  const onPaginatedSearch = (query: string | null, page: number) => {
    if (query) {
      search(() => searchByBundle(query, page, filtersState.state))
        .then(onSuccess)
        .catch(onError);

      trackSearch();
    } else {
      clearResults();
    }
  };

  const onFreshSearch = (query: string | null) => {
    setCurrentPage(DEFAULT_PAGINATION_PAGE);
    onPaginatedSearch(query, DEFAULT_PAGINATION_PAGE);
  };

  const onPageChange = (page: number) => {
    setCurrentPage(page);
    onPaginatedSearch(query, page);
  };

  return (
    <>
      <Modal.Header closeButton>
        <SearchHeader
          filtersArea={
            <BundleSearchFilters
              filtersState={filtersState}
              availableCourses={availableCourses}
              onSaveFilters={async (name) => saveFavouriteSearch(name, SEARCH_TYPE_BUNDLE, filtersState.state)}
            />
          }
          icon={<BundleIcon />}
          title="Bundle search"
          onBack={onBack}
          onSearch={onFreshSearch}
        />
      </Modal.Header>

      <Modal.Body>
        <SearchResultsWrapper onPageCount={results.length}>
          <SearchResults<BundleSearchResultType>
            results={results}
            count={count}
            currentPage={currentPage}
            setCurrentPage={onPageChange}
            header={<BundleSearchResultTypeHeader />}
            renderItem={(item) => <BundleSearchResultItem content={item} key={item.id} />}
          />
        </SearchResultsWrapper>
      </Modal.Body>
    </>
  );
};
