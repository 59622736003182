import { components, MultiValue, MultiValueGenericProps, OptionProps } from 'react-select';
import styled from 'styled-components/macro';

import { Flag, FlagTextPositionMode } from '@components/Flag';
import { ALL_LANGUAGES_UPPERCASE, Language, LANGUAGE_NAMES } from '@features/content/languages';
import { Checkbox } from '@features/theme';
import { CommonFilterMultiSelector } from '../CommonFilterMultiSelector';
import { CommonFilterOptionType } from '../types';

const OptionContainer = styled.div`
  display: flex;
`;

const FlagsContainer = styled(Flag)<{ isMenuOpen: boolean }>`
  max-width: 12rem;

  .flag__text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre;
    color: ${({ theme, isMenuOpen }) => (isMenuOpen ? theme.color.brandWhite : theme.color.deepBlue)};
  }

  .flag__more-countries-circle {
    color: ${({ theme }) => theme.color.deepBlue};
  }
`;

const LanguageCustomOption = (props: OptionProps<CommonFilterOptionType>) => {
  const {
    data: { value },
    isSelected,
  } = props;

  return (
    <components.Option {...props}>
      <OptionContainer key={value}>
        <Checkbox checked={isSelected} onToggle={() => null} />
        <Flag
          countries={value as Language}
          showCountryName={false}
          tooltipEnabled={true}
          mode={FlagTextPositionMode.withoutText}
        />
        {LANGUAGE_NAMES[value as Language]}
      </OptionContainer>
    </components.Option>
  );
};

type LanguageValueContainerProps = MultiValueGenericProps<MultiValue<CommonFilterOptionType>> & {
  title: string;
  value: MultiValue<CommonFilterOptionType> | null;
};

const LanguageValueContainer = ({ title, value, ...props }: LanguageValueContainerProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let [_, input] = props.children as any;

  return (
    <components.MultiValueContainer {...props}>
      <div title={title}>
        <FlagsContainer
          isMenuOpen={props.selectProps.menuIsOpen}
          countries={value?.map((value) => value.value as Language) || []}
          tooltipEnabled={false}
        />
      </div>
      {input}
    </components.MultiValueContainer>
  );
};

const CLASSNAME_PREFIX = 'language-selector';

type LanguageSelectorProps = {
  className?: string;
  placeholder?: string;
  rule?: string;
  value: MultiValue<CommonFilterOptionType> | null;
  onChange: (option: MultiValue<CommonFilterOptionType> | null) => void;
};

const options = ALL_LANGUAGES_UPPERCASE.map((lang) => ({ value: lang, label: LANGUAGE_NAMES[lang] }));

export const LanguageSelector = ({ className, value, onChange }: LanguageSelectorProps) => {
  const rule = 'Language';
  const title = value?.length ? value.map((lang) => lang.label).join('\n') : '';

  return (
    <CommonFilterMultiSelector
      className={className}
      classNamePrefix={CLASSNAME_PREFIX}
      closeMenuOnSelect={false}
      options={options}
      placeholder={rule}
      rule={rule}
      value={value}
      valueContainer={(props: MultiValueGenericProps<MultiValue<CommonFilterOptionType>>) => (
        <LanguageValueContainer title={title} value={value || []} {...props} />
      )}
      optionContainer={(props: OptionProps<CommonFilterOptionType>) => <LanguageCustomOption {...props} />}
      onChange={onChange}
    />
  );
};

type SimpleLanguageSelectorProps = Omit<LanguageSelectorProps, 'value' | 'onChange'> & {
  value: Language[] | null;
  onChange: (value: Language[] | null) => void;
};
export const SimpleLanguageSelector = ({ value, onChange, ...restProps }: SimpleLanguageSelectorProps) => {
  return (
    <LanguageSelector
      value={
        value
          ? value.map((language) => ({
              label: language.toUpperCase(),
              value: language,
            }))
          : null
      }
      onChange={(option) =>
        onChange(option?.length ? option.map((languageOption) => languageOption.value as Language) : null)
      }
      {...restProps}
    />
  );
};
