import React, { useState, useCallback } from 'react';
import lodash from 'lodash';
import { useFormikContext } from 'formik';
import classnames from 'classnames';
import { BidimensionalEditorModes } from '@components/BidimensionalEditor/enums/BidimensionalEditorModes';
import { ValidationErrorDisplayer } from '@components/ValidationErrorDisplayer';
import { TranslationTipActionsCreator } from '@actionCreators/TranslationTipActionsCreator';
import {
  TranslationsPanelModes,
  type TranslationsPanelModesType,
} from '@components/TranslationsPanel/enums/TranslationsPanelModes';
import { LocalizationInterface } from '@common/interfaces/localization/LocalizationInterface';
import { ValidationErrorInterface } from '@common/interfaces/validation/ValidationInterface';
import BidimensionalEditor from '@components/BidimensionalEditor/BidimensionalEditor';
import KTagsCreatorHelpers from '@components/Exercises/Common/KTagsCreator/KTagsCreatorHelpers';
import { Flag, FlagTextPositionMode } from '@components/Flag';
import { DBId } from '@common/types/DBId';
import PhoneticIcon from '@static/svg/translationsPanel/Phonetic.svg';
import { useAppDispatch } from '@redux/store';
import { Language, PHONETIC_LANGUAGES } from '@features/content/languages';
import { ContentTypes } from '@common/enums/ContentTypes';
import { LockContent } from '@features/content/exercises';

interface TranslationBlockProps {
  bundleId?: string;
  bundleName?: string;
  column?: number | undefined;
  contentId?: DBId;
  courseLearningLanguage?: string;
  disabled: boolean;
  disableLearningLanguage?: boolean;
  exerciseType: string;
  isBundleChangeBlocked?: boolean;
  isChangeBlocked?: boolean;
  translationsBlockValuesArray: LocalizationInterface[];
  translationsPanelMode: TranslationsPanelModesType;
  otherPlacesCount?: number;
  row?: number | undefined;
  show?: boolean;
  storeBranch: string;
  type?: string;
  validationErrors?: ValidationErrorInterface[];
  onTranslationChange: Function;
}

interface TranslationsBlockInstanceProps extends TranslationBlockProps {
  localization: LocalizationInterface;
  idx: number;
}

const TOOLBAR_DEFAULT_OPTIONS = {
  options: ['inline', 'emoji'],
  inline: {
    options: ['bold', 'italic', 'underline', 'strikethrough'],
  },
};

export const TranslationsBlockInstance = ({
  bundleId,
  bundleName,
  column,
  contentId,
  courseLearningLanguage,
  disabled,
  disableLearningLanguage,
  exerciseType,
  idx,
  isBundleChangeBlocked,
  isChangeBlocked,
  localization,
  otherPlacesCount,
  row,
  show,
  storeBranch,
  type,
  translationsPanelMode,
  validationErrors,
  onTranslationChange,
}: TranslationsBlockInstanceProps) => {
  const { values, setFieldValue } = useFormikContext();
  const dispatch = useAppDispatch();
  const isPhoneticLanguage = PHONETIC_LANGUAGES.includes(localization.language as Language);

  const fieldName = bundleName ? `${bundleName}_${storeBranch}` : storeBranch;
  let [text, setText] = useState(
    storeBranch !== 'script'
      ? (values as any)[fieldName]?.find((item: any) => item.language === localization.language && !item.isPhonetic)
          ?.value || ''
      : localization.value,
  );

  const setReusedData = () => {
    dispatch(
      TranslationTipActionsCreator.setCurrentContentId(
        isBundleChangeBlocked ? bundleId || '' : contentId || '',
        type || '',
        storeBranch || '',
        row,
        column,
        bundleName,
        isBundleChangeBlocked,
      ),
    );
  };

  let onChangeHandler = (e: any) => {
    if (isChangeBlocked) {
      setReusedData();
    }

    if (!isChangeBlocked) {
      setText(e.target.value);
      if (storeBranch !== 'script') {
        const newValues = (values as any)?.[fieldName]?.map((item: any) => {
          if (item.language === localization.language && !item.isPhonetic) {
            return {
              ...item,
              value: e.target.value || '',
            };
          }
          return item;
        });
        setFieldValue(fieldName, newValues);
        setFieldValue(`${fieldName}Changed`, true);
      } else {
        debounceHandler(e.target.value);
      }
    }
  };

  const customChangeHandler = (val: any) => {
    onTranslationChange && onTranslationChange(val || '', localization.language, false);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceHandler = useCallback(
    lodash.debounce((val) => customChangeHandler(val), 300),
    [],
  );

  let [phoneticText, setPhoneticText] = useState(
    storeBranch !== 'script' && storeBranch !== 'examples'
      ? (values as any)[fieldName]?.find((item: any) => item.language === localization.language && item.isPhonetic)
          ?.value || ''
      : localization.phoneticValue || '',
  );

  let onPhoneticChangeHandler = (e: any) => {
    if (isChangeBlocked) {
      setReusedData();
    }

    if (!isChangeBlocked) {
      setPhoneticText(e.target.value);
      if (storeBranch !== 'script' && storeBranch !== 'examples') {
        const newValues = (values as any)?.[fieldName]?.map((item: any) => {
          if (item.language === localization.language && item.isPhonetic) {
            return {
              ...item,
              value: e.target.value || '',
            };
          }
          return item;
        });

        setFieldValue(fieldName, newValues);
        setFieldValue(`${fieldName}Changed`, true);
      } else {
        debouncePhoneticHandler(e.target.value);
      }
    }
  };
  const customPhoneticChangeHandler = (val: any) => {
    onTranslationChange && onTranslationChange(val || '', localization.language, true);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncePhoneticHandler = useCallback(
    lodash.debounce((val) => customPhoneticChangeHandler(val), 300),
    [],
  );

  const validationError = validationErrors
    ?.filter((error) => error.message.includes('text'))
    .find((error) => error.message.includes(localization.language));

  const isValidationErrorShown = !!validationErrors?.find((error) => error.message.includes(localization.language))
    ?.message;

  let editorToolbar = TOOLBAR_DEFAULT_OPTIONS;
  let editorSingleLine = false;

  const shouldShowLockContentOption =
    exerciseType === ContentTypes.tip || ['feedback', 'instructions', 'statement'].includes(storeBranch);

  if (shouldShowLockContentOption) {
    if (storeBranch === 'feedback') {
      editorToolbar = {
        options: ['inline'],
        inline: {
          options: ['bold'],
        },
      };
      editorSingleLine = true;
    } else if (storeBranch === 'instructions' || storeBranch === 'statement') {
      editorToolbar = {
        options: [],
        inline: {
          options: [],
        },
      };
      editorSingleLine = true;
    }
  }

  const editorToolbarCustomButtons = shouldShowLockContentOption ? [<LockContent />] : undefined;

  return (
    <li
      key={idx}
      className={classnames('translations-panel-modal__translation', {
        'translations-panel-modal__translation--hidden': !show,
      })}
    >
      <div
        className={classnames('translations-panel-modal__translation-data', {
          'translations-panel-modal__translation-data--with-error': validationError,
        })}
      >
        <Flag mode={FlagTextPositionMode.before} countries={localization.language} />
        {isPhoneticLanguage && translationsPanelMode === TranslationsPanelModes.normal && (
          <div className="translations-panel-modal__translation-text-phonetic">
            {disableLearningLanguage && localization.language === courseLearningLanguage && (
              <div>{KTagsCreatorHelpers.createKTagsFormat(localization.value)}</div>
            )}
            {(!disableLearningLanguage ||
              (disableLearningLanguage && localization.language !== courseLearningLanguage)) && (
              <>
                <input
                  id={`translation-${localization.language}`}
                  type="text"
                  dir="auto"
                  className="translations-panel-modal__translation-input"
                  placeholder="Translation"
                  value={text}
                  disabled={disabled || (disableLearningLanguage && localization.language === courseLearningLanguage)}
                  onChange={onChangeHandler}
                />
                <input
                  type="text"
                  className="translations-panel-modal__translation-input"
                  placeholder="Phonetic"
                  disabled={disabled}
                  value={phoneticText}
                  onChange={onPhoneticChangeHandler}
                />
                <img
                  src={PhoneticIcon}
                  alt="Phonetic"
                  className="translations-panel-modal__translation-phonetic-icon"
                />
              </>
            )}
          </div>
        )}
        {isPhoneticLanguage && translationsPanelMode === TranslationsPanelModes.textArea && (
          <div className="translations-panel-modal__translation-text-phonetic">
            {disableLearningLanguage && localization.language === courseLearningLanguage && (
              <div>{KTagsCreatorHelpers.createKTagsFormat(localization.value)}</div>
            )}
            {(!disableLearningLanguage ||
              (disableLearningLanguage && localization.language !== courseLearningLanguage)) && (
              <>
                <textarea
                  dir="auto"
                  className="translations-panel-modal__translation-area"
                  placeholder="Translation"
                  value={text}
                  disabled={disabled || (disableLearningLanguage && localization.language === courseLearningLanguage)}
                  onChange={onChangeHandler}
                />
                <input
                  type="text"
                  className="translations-panel-modal__translation-input"
                  placeholder="Phonetic"
                  disabled={disabled}
                  value={phoneticText}
                  onChange={onPhoneticChangeHandler}
                />
                <img
                  src={PhoneticIcon}
                  alt="Phonetic"
                  className="translations-panel-modal__translation-phonetic-icon"
                />
              </>
            )}
          </div>
        )}
        {isPhoneticLanguage && translationsPanelMode === TranslationsPanelModes.wysiwyg && (
          <div className="translations-panel-modal__translation-text-phonetic">
            <BidimensionalEditor
              mode={BidimensionalEditorModes.mono}
              storeBranch={storeBranch as string}
              bundleName={bundleName}
              localizationId={localization._id}
              storeRow={row}
              storeColumn={column}
              language={localization.language}
              maxColums={1}
              fullWidth
              toolbar={editorToolbar}
              dataSourceMode="defaultEditorState"
              ignoreLanguageForTips={true}
              isLesson={storeBranch === 'completeMessage'}
              errorsShown={isValidationErrorShown}
              isChangeBlocked={isChangeBlocked}
              singleLine={editorSingleLine}
              toolbarCustomButtons={editorToolbarCustomButtons}
              onChange={(val) => {
                const newValues = (values as any)?.[fieldName]?.map((item: any) => {
                  if (item.language === localization.language && !item.isPhonetic) {
                    return {
                      ...item,
                      value: val || '',
                    };
                  }
                  return item;
                });

                setFieldValue(fieldName, newValues);
                setFieldValue(`${fieldName}Changed`, true);
              }}
              onChangeInstant={() => {
                if (isChangeBlocked) {
                  setReusedData();
                }
              }}
            />
            <input
              type="text"
              className="translations-panel-modal__translation-input"
              placeholder="Phonetic"
              disabled={disabled}
              value={phoneticText}
              onChange={onPhoneticChangeHandler}
            />
            <img src={PhoneticIcon} alt="Phonetic" className="translations-panel-modal__translation-phonetic-icon" />
          </div>
        )}

        {!isPhoneticLanguage && translationsPanelMode === TranslationsPanelModes.normal && (
          <div
            className={classnames('translations-panel-modal__translation-text', {
              'translations-panel-modal__translation-text--ktag':
                disableLearningLanguage && localization.language === courseLearningLanguage,
            })}
          >
            {disableLearningLanguage && localization.language === courseLearningLanguage && (
              <div>{KTagsCreatorHelpers.createKTagsFormat(localization.value)}</div>
            )}
            {(!disableLearningLanguage ||
              (disableLearningLanguage && localization.language !== courseLearningLanguage)) && (
              <>
                <input
                  id={`translation-${localization.language}`}
                  type="text"
                  dir="auto"
                  className="translations-panel-modal__translation-input"
                  placeholder="Translation"
                  value={text}
                  disabled={disabled}
                  onChange={onChangeHandler}
                />
              </>
            )}
          </div>
        )}
        {!isPhoneticLanguage && translationsPanelMode === TranslationsPanelModes.textArea && (
          <div
            className={classnames('translations-panel-modal__translation-text', {
              'translations-panel-modal__translation-text--ktag':
                disableLearningLanguage && localization.language === courseLearningLanguage,
            })}
          >
            {disableLearningLanguage && localization.language === courseLearningLanguage && (
              <div>{KTagsCreatorHelpers.createKTagsFormat(localization.value)}</div>
            )}
            {(!disableLearningLanguage ||
              (disableLearningLanguage && localization.language !== courseLearningLanguage)) && (
              <>
                <textarea
                  dir="auto"
                  className="translations-panel-modal__translation-area"
                  placeholder="Translation"
                  value={text}
                  disabled={disabled}
                  onChange={onChangeHandler}
                />
              </>
            )}
          </div>
        )}
        {!isPhoneticLanguage && translationsPanelMode === TranslationsPanelModes.wysiwyg && (
          <div className="translations-panel-modal__translation-text--wysiwyg">
            <React.Fragment>
              <BidimensionalEditor
                mode={BidimensionalEditorModes.mono}
                storeRow={row}
                storeColumn={column}
                storeBranch={storeBranch as string}
                bundleName={bundleName}
                localizationId={localization._id}
                language={localization.language}
                maxColums={1}
                fullWidth
                toolbar={editorToolbar}
                dataSourceMode="defaultEditorState"
                isLesson={storeBranch === 'completeMessage'}
                ignoreLanguageForTips={true}
                errorsShown={isValidationErrorShown}
                isChangeBlocked={isChangeBlocked}
                singleLine={editorSingleLine}
                toolbarCustomButtons={editorToolbarCustomButtons}
                onChange={(val) => {
                  const newValues = (values as any)?.[fieldName]?.map((item: any) => {
                    if (item.language === localization.language && !item.isPhonetic) {
                      return {
                        ...item,
                        value: val || '',
                      };
                    }
                    return item;
                  });

                  setFieldValue(fieldName, newValues);
                  setFieldValue(`${fieldName}Changed`, true);
                }}
                onChangeInstant={() => {
                  if (isChangeBlocked) {
                    setReusedData();
                  }
                }}
              />
            </React.Fragment>
          </div>
        )}
      </div>
      <ValidationErrorDisplayer text={validationError?.message} />
    </li>
  );
};

export const TranslationsBlock = (props: TranslationBlockProps) => {
  const { translationsBlockValuesArray, show } = props;

  return (
    <ul
      className={classnames('translations-panel-modal__translations-block', {
        'translations-panel-modal__translations-block--hidden': !show,
      })}
    >
      {[...translationsBlockValuesArray]
        .sort((localization: LocalizationInterface, localization2: LocalizationInterface) => {
          return localization.language.localeCompare(localization2.language);
        })
        .map((localization: LocalizationInterface, idx: number) => {
          return <TranslationsBlockInstance {...props} localization={localization} idx={idx} key={idx} />;
        })}
    </ul>
  );
};
