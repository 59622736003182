const SAVE = 'FLASHCARD_SAVE';
const UPLOAD_IMAGE = 'FLASHCARD_UPLOAD_IMAGE';
const SET_LEARNING_EXAMPLE_TEXT = 'FLASHCARD_SET_LEARNING_EXAMPLE_TEXT';
const SET_LEARNING_WORD_TEXT_ALT = 'FLASHCARD_SET_LEARNING_WORD_TEXT_ALT';
const SET_INSTRUCTIONS_LANGUAGE = 'FLASHCARD_SET_INSTRUCTIONS_LANGUAGE';
const REMOVE_IMAGE = 'FLASHCARD_REMOVE_IMAGE';
const REMOVE_AUDIO = 'FLASHCARD_REMOVE_AUDIO';
const REMOVE_EXAMPLE_AUDIO = 'FLASHCARD_REMOVE_EXAMPLE_AUDIO';
const NULLIFY_EXAMPLE = 'NULLIFY_EXAMPLE';
const SET_FLASHCARD_ALL_FIELDS = 'SET_FLASHCARD_ALL_FIELDS';
const REMOVE_VIDEO = 'REMOVE_VIDEO';
const UPLOAD_VIDEO_SUCCESS = 'UPLOAD_VIDEO_SUCCESS';

export const FlashcardExerciseActions = {
  SAVE,
  UPLOAD_IMAGE,
  SET_LEARNING_EXAMPLE_TEXT,
  SET_LEARNING_WORD_TEXT_ALT,
  SET_INSTRUCTIONS_LANGUAGE,
  REMOVE_IMAGE,
  REMOVE_AUDIO,
  REMOVE_EXAMPLE_AUDIO,
  NULLIFY_EXAMPLE,
  SET_FLASHCARD_ALL_FIELDS,
  REMOVE_VIDEO,
  UPLOAD_VIDEO_SUCCESS,
} as const;
