import { ContentTypes } from '@common/enums/ContentTypes';
import { ExerciseTypes } from '@common/enums/ExerciseTypes';
import { allTypesMap } from '@helpers/contentCardHelper';
import { getPositionOfLesson } from '@helpers/contentCardHelper';
import { ACTIVITIES, ACTIVITY_TITLES, type ActivityType } from '@features/content/activities';
import { NavigationItemType } from '@features/content/navigation';
import { LessonInterface } from '@common/interfaces/contentTypes/LessonInterface';

export const getLink = (content: NavigationItemType, structure: NavigationItemType[]) => {
  const courseId = structure.find((element) => element.type === ContentTypes.course)?.id;
  switch (content.type) {
    case ContentTypes.course:
      return `/course/${courseId}`;
    case ContentTypes.level:
      return `/course/${courseId}/level/${content.id}`;
    case ContentTypes.chapter:
      return `/course/${courseId}/level/${content.parentId}/chapter/${content.id}`;
    case ContentTypes.lesson:
    case ContentTypes.checkpoint:
    case ContentTypes.certificate:
    case ContentTypes.liveLesson:
    case ContentTypes.review:
    case ContentTypes.roleplay:
    case ContentTypes.speakingLesson:
      const levelId = structure?.find((item) => item.id === content.parentId)?.parentId;
      return `/course/${courseId}/level/${levelId}/chapter/${content.parentId}/lesson/${content.id}`;

    default:
      if (ACTIVITIES.includes(content.type as ActivityType) && content.hasOwnProperty('oldType')) {
        const chapterId = structure?.find((item) => item.id === content.parentId)?.parentId;
        const levelId = structure?.find((item) => item.id === chapterId)?.parentId;

        return `/course/${courseId}/level/${levelId}/chapter/${chapterId}/lesson/${content.parentId}/activity/${content.id}`;
      }

      if (
        Object.values(ExerciseTypes).includes(content.type as any) &&
        content.type !== ContentTypes.slidePptx &&
        content.type !== ContentTypes.slidePdf
      ) {
        const lessonId = structure?.find((item) => item.id === content.parentId)?.parentId;
        const chapterId = structure?.find((item) => item.id === lessonId)?.parentId;
        const levelId = structure?.find((item) => item.id === chapterId)?.parentId;

        return `/course/${courseId}/level/${levelId}/chapter/${chapterId}/lesson/${lessonId}/activity/${content.parentId}/exercise/${content.id}`;
      } else if (
        Object.values(ExerciseTypes).includes(content.type as any) &&
        (content.type === ContentTypes.slidePptx || content.type === ContentTypes.slidePdf)
      ) {
        const chapterId = structure?.find((item) => item.id === content.parentId)?.parentId;
        const levelId = structure?.find((item) => item.id === chapterId)?.parentId;

        return `/course/${courseId}/level/${levelId}/chapter/${chapterId}/lesson/${content.parentId}/exercise/${content.id}`;
      } else {
        return `/course/${courseId}/content/${content.id}`;
      }
  }
};

const getElementPosition = (content: NavigationItemType, structure: NavigationItemType[]) => {
  if (![ContentTypes.course, ContentTypes.level].includes(content.type as any)) {
    const lessonTypes = ['lesson', 'checkpoint', 'live', 'review', 'certificate'];
    if (lessonTypes.includes(content.type)) {
      const lessons = structure?.filter((element) => content.parentId === element.parentId);
      const positionNumber = getPositionOfLesson(content.id, lessons as unknown as LessonInterface[]);
      return positionNumber !== null ? `${positionNumber + 1}` : '';
    } else if (
      Object.values(ExerciseTypes).includes(content.type as any) ||
      ACTIVITIES.includes(content.type as ActivityType)
    ) {
      const contentReadyIds = structure
        ?.filter((element) => content.parentId === element.parentId && element.ready)
        .map((element) => element.id);

      const contentPosition =
        contentReadyIds.indexOf(content.id) === -1 ? null : contentReadyIds.indexOf(content.id) + 1;

      return contentPosition ? `${contentPosition}` : '';
    } else {
      const contentReadyIds = structure
        ?.filter((element) => element.type === content.type && content.parentId === element.parentId && element.ready)
        .map((element) => element.id);

      const contentPosition =
        contentReadyIds.indexOf(content.id) === -1 ? null : contentReadyIds.indexOf(content.id) + 1;

      return contentPosition ? `${contentPosition}` : '';
    }
  } else {
    return '';
  }
};

export const getTitle = (content: NavigationItemType, structure: NavigationItemType[]) => {
  const elementPosition = getElementPosition(content, structure);

  const isLesson = [
    ContentTypes.lesson,
    ContentTypes.liveLesson,
    ContentTypes.certificate,
    ContentTypes.checkpoint,
    ContentTypes.review,
    ContentTypes.roleplay,
    ContentTypes.speakingLesson,
  ].includes(content.type as any);
  const isActivity = ACTIVITIES.includes(content.type as ActivityType);
  const isExercise = Object.values(ExerciseTypes).includes(content.type as any);

  if (isLesson) {
    const defaultLessonTitle = 'Untitled lesson';

    if (content.type === ContentTypes.checkpoint) {
      return `Checkpoint${elementPosition ? ` # ${elementPosition}` : ''}`;
    } else if (content.type === ContentTypes.liveLesson) {
      return `Live Lesson - ${content.title || defaultLessonTitle}`;
    } else if (content.type === ContentTypes.certificate) {
      return `Certificate - ${content.title || defaultLessonTitle}`;
    } else if (content.type === ContentTypes.speakingLesson) {
      return `Speaking - ${content.title || defaultLessonTitle}`;
    } else if (content.type === ContentTypes.roleplay) {
      return content.title || 'AI Roleplay';
    } else {
      return content.title || defaultLessonTitle;
    }
  } else if (isActivity) {
    return ACTIVITY_TITLES[content.type as ActivityType];
  } else if (isExercise) {
    return content.title || allTypesMap[content.type as keyof typeof allTypesMap];
  } else {
    const defaultTitle = `Untitled ${content.type}`;

    return content.title || defaultTitle;
  }
};
