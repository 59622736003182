import { BulkPublishingActions } from '@actions/BulkPublishingActions';
import type { DBId } from '@common/types/DBId';
import type { BulkPublishOptionValue } from '@features/content/exercises';

export const BulkPublishingActionsCreator = {
  enableBulkPublishingState: (selectedOption: BulkPublishOptionValue) => ({
    type: BulkPublishingActions.ENABLE_BULK_PUBLISHING_STATE,
    payload: { selectedOption },
  }),
  disableAll: () => ({
    type: BulkPublishingActions.DISABLE_ALL,
  }),
  toggleSelectedChildren: (selectedChildren: DBId) => ({
    type: BulkPublishingActions.TOGGLE_SELECTED_CHILDREN,
    payload: { selectedChildren },
  }),
  setSelectedChildren: (selectedChildren: DBId[]) => ({
    type: BulkPublishingActions.SET_SELECTED_CHILDREN,
    payload: { selectedChildren },
  }),
};
