import { call, put } from 'redux-saga/effects';
import { DialogueExerciseActions } from '@actions/DialogueExerciseActions';
import DialogueExerciseService from '@services/ExerciseRelatedServices/DialogueExerciseService';

const DialogueCharactersPanelSagas = {
  *loadCharacters({ payload }: any) {
    try {
      const characters: Awaited<ReturnType<typeof DialogueExerciseService.requestCharacters>> = yield call(
        DialogueExerciseService.requestCharacters,
      );

      yield put({
        type: DialogueExerciseActions.SHOW_DIALOGUE_CHARACTERS_SELECTOR_SUCCESS,
        payload: {
          characters,
        },
      });
    } catch (e: any) {
      console.error(e);
    }
  },
  *showDialogueCharactersSelectorToUpdatePhoto({ payload: { characterIndex, characterImage } }: any) {
    try {
      const characters: Awaited<ReturnType<typeof DialogueExerciseService.requestCharacters>> = yield call(
        DialogueExerciseService.requestCharacters,
      );

      yield put({
        type: DialogueExerciseActions.SHOW_DIALOGUE_CHARACTERS_SELECTOR_TO_UPDATE_PHOTO_SUCCESS,
        payload: {
          characters,
          characterIndex,
          characterImage,
        },
      });
    } catch (e: any) {
      console.error(e);
    }
  },
};

export default DialogueCharactersPanelSagas;
