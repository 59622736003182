import styled, { css } from 'styled-components/macro';

export const Title = styled.div`
  ${({ theme }) => css`
    color: ${theme.colorV2.textPrimary};
    font-weight: 800;
    font-size: 2rem;
    margin-bottom: ${theme.space.L};
    text-align: center;
  `}
`;

export const ScenarioListWrapper = styled.ul`
  height: 56.4rem;
  list-style: none;
  margin-top: ${({ theme }) => theme.space.L};
  padding: 0;
  overflow-x: scroll;
`;

export const ScenarioListItemContainer = styled.li<{ $selected: boolean }>`
  ${({ $selected, theme }) => css`
    background-color: ${$selected ? theme.colorV2.uiBackgroundSecondary : 'inherit'};
    display: flex;
    gap: ${theme.space.M};
    margin-bottom: ${theme.space.L};
    padding: 0 ${theme.space.M};

    &:hover {
      background-color: ${theme.colorV2.uiBackgroundSecondary};
      cursor: pointer;
    }
  `}
`;

export const ScenarioListItemDataWrapper = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colorV2.placementTestCardText};
  display: flex;
  gap: 1.8rem;
  padding: 1rem;
  width: 100%;
`;

export const ScenarioListItemTitle = styled.div`
  align-items: center;
  display: flex;
  font-size: 1.6rem;
  font-weight: 700;
  max-width: 90%;
  min-height: 2.5rem;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.space.M};
  justify-content: flex-end;
`;

export const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CEFRSelectorWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.space.S};
`;

export const CEFRSelectorButton = styled.button<{ $active: boolean }>`
  ${({ $active, theme }) => css`
    all: unset;
    background-color: ${$active ? theme.colorV2.uiBackgroundSecondary : 'transparent'};
    border-radius: 1.2rem;
    color: ${$active ? theme.colorV2.accentPrimary : theme.colorV2.textSecondary};
    font-size: 1.4rem;
    min-width: 2.4rem;
    padding: ${({ theme }) => theme.space.S};
    text-align: center;

    &:hover {
      background-color: ${theme.colorV2.uiBackgroundSecondary};
      color: ${theme.colorV2.accentPrimary};
    }
  `}
`;
