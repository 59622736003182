import { random } from 'lodash';
import { useEffect, useState } from 'react';

import { ValidationErrorInterface } from '@common/interfaces/validation/ValidationInterface';
import { FailedUploadingMessage } from '../FailedUploadingMessage/FailedUploadingMessage';
import { useIsEditAvailable } from '@features/content/courses';
import { Loader } from '@features/theme';
import { ReactComponent as ImgUploadIcon } from '@static/svg/upload.svg';
import { ReactComponent as DeleteIcon } from '@static/svg/x-icon.svg';

import { ReactComponent as DownloadIcon } from './img/download.svg';
import { ReactComponent as PdfIcon } from './img/pdfFileIcon.svg';
import { ReactComponent as PptxIcon } from './img/pptxFileIcon.svg';

import {
  FileUploaded,
  FileUploadedDownloadLink,
  FileUploadedImageContainer,
  FileUploadedRemoveButton,
  FileUploadedStatus,
  SlideFileUploadWrapper,
  UploadDescription,
  UploadDimensions,
  UploadInput,
  UploadInstructions,
  UploadLabel,
} from './styles';
import { selectIsIssuesShown } from '@selectors/UiSelectors';
import { useAppSelector } from '@redux/store';
import { selectImageOrVideoUploadingFailed, selectImageOrVideoUploadingInProgress } from '@selectors/CoursesSelectors';

type SlideFileUploadProps = {
  disabled?: boolean;
  errors?: ValidationErrorInterface[];
  isChangeBlocked?: boolean;
  fileUrl?: string | null;
  fileUrlChanged?: boolean;
  text?: string;
  type: 'pdf' | 'pptx';
  onClick?: Function;
  onChange?: (file: File) => void;
  onChangeInstant?: Function;
  onRemove?: () => void;
};

export const SlideFileUpload = ({
  disabled = false,
  errors = [],
  isChangeBlocked = false,
  fileUrl: _fileUrl,
  fileUrlChanged,
  text = '',
  type,
  onClick,
  onChange,
  onChangeInstant,
  onRemove,
}: SlideFileUploadProps) => {
  const { isEditAvailable } = useIsEditAvailable();
  if (!_fileUrl) _fileUrl = undefined;

  const [slideFile, setSlideFile] = useState(_fileUrl);

  const isIssuesShown = useAppSelector(selectIsIssuesShown);

  const accept =
    type === 'pdf' ? 'application/pdf' : 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
  const fieldName = type === 'pdf' ? 'pdfUrl' : 'pptxUrl';
  const fileExt = type.toUpperCase();
  const uniqueId = random(0, 10000);
  const imageOrVideoUploadingInProcess: string[] = useAppSelector(selectImageOrVideoUploadingInProgress);
  const imageOrVideoUploadingFailed = useAppSelector(selectImageOrVideoUploadingFailed);
  const errorDetails = imageOrVideoUploadingFailed.find((item) => item.field === fieldName);
  const uploadResult = errorDetails ? 'File upload failed.' : 'File was succesfully uploaded.';

  useEffect(() => {
    if (_fileUrl !== undefined) {
      setSlideFile(`${_fileUrl}`);
    } else {
      setSlideFile(undefined);
    }
  }, [_fileUrl]);

  const isValidationErrorsShown = errors && errors.length && isIssuesShown;

  const RemoveButton = (
    <FileUploadedRemoveButton
      variant="text"
      disabled={disabled}
      onClick={() => {
        if (isChangeBlocked) {
          onChangeInstant && onChangeInstant();
        } else {
          setSlideFile(undefined);
          onRemove && onRemove();
        }
      }}
    >
      <DeleteIcon />
    </FileUploadedRemoveButton>
  );

  return (
    <>
      <SlideFileUploadWrapper
        isSlideFileUploaded={!!slideFile}
        withError={isValidationErrorsShown || !!errorDetails}
        withErrorFailed={!!errorDetails}
        onClick={(evt) => {
          evt.stopPropagation();
          onClick && onClick();
        }}
      >
        {!slideFile ? (
          <>
            {imageOrVideoUploadingInProcess.includes(fieldName) ? (
              <Loader />
            ) : (
              <>
                {isEditAvailable ? (
                  <>
                    <ImgUploadIcon />

                    <UploadInstructions>
                      <UploadLabel
                        htmlFor={isChangeBlocked ? '' : `browse-${uniqueId}`}
                        onClick={() => {
                          if (isChangeBlocked) {
                            onChangeInstant && onChangeInstant();
                          }
                        }}
                      >
                        Browse
                      </UploadLabel>
                      <UploadDescription> to upload {fileExt}</UploadDescription>
                      <UploadInput
                        accept={accept}
                        disabled={!isEditAvailable || disabled}
                        id={`browse-${uniqueId}`}
                        name="browse"
                        type="file"
                        onChange={(e) => {
                          if (onChange && e.target.files !== null) {
                            onChange(e.target.files[0]);
                          }
                        }}
                      />
                    </UploadInstructions>

                    <UploadDimensions>{text}</UploadDimensions>
                  </>
                ) : (
                  <UploadDescription>Get edit permissions to upload {fileExt}</UploadDescription>
                )}
              </>
            )}
          </>
        ) : (
          <FileUploaded>
            <FileUploadedImageContainer>
              {type === 'pdf' ? <PdfIcon /> : <PptxIcon />}
              {isEditAvailable && RemoveButton}
            </FileUploadedImageContainer>
            {!!slideFile && !fileUrlChanged ? (
              <FileUploadedDownloadLink download href={slideFile} target="_blank">
                <DownloadIcon />
                <span>Download material ({fileExt})</span>
              </FileUploadedDownloadLink>
            ) : (
              <FileUploadedStatus>{uploadResult}</FileUploadedStatus>
            )}
          </FileUploaded>
        )}
      </SlideFileUploadWrapper>
      <FailedUploadingMessage errorDetails={errorDetails} />
    </>
  );
};
