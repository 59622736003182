import { useState } from 'react';
import styled from 'styled-components/macro';

import { ListenRepeatExerciseActionsCreator } from '@actionCreators/ListenRepeatExerciseActionsCreator';
import { CommonExerciseActionsCreator } from '@actionCreators/CommonExerciseActionsCreator';
import { ExerciseCommonActionCreators as ExerciseCommonActions } from '@actionCreators/ExerciseCommonActionCreator';
import { SearchModalActionsCreator } from '@actionCreators/SearchModalActionsCreator';
import { TranslationTipActionsCreator } from '@actionCreators/TranslationTipActionsCreator';
import { VideoUploadActionsCreator } from '@actionCreators/VideoUploadActionsCreator';
import { ContentTypes } from '@common/enums/ContentTypes';
import { ImageUploadModes } from '@common/enums/FileUploadModes';
import { Sizes } from '@common/enums/Sizes';
import { type DisplayContentToUserModeType } from '@common/enums/DisplayContentToUserMode';
import ImageUploadDimensionDescriptors from '@common/enums/FileUploadDimensionDescriptors';
import { LoadedListenRepeatExerciseInterface } from '@common/interfaces/exercises/LoadedExerciseInterface';
import { ValidationErrorInterface } from '@common/interfaces/validation/ValidationInterface';
import ContentToggler from '@components/Exercises/Common/ContentToggler/ContentToggler';
import ExerciseProps from '@components/Exercises/ExerciseProps';
import { Title, TitleContainer } from '@components/Exercises/Common';
import { ImageUpload, VideoUpload } from '@components/MediaUpload';
import ImageUploadUtils from '@components/MediaUpload/ImageUploadUtils';
import { ResourceBundleWrapper } from '@components/Exercises/Common/ResourceBundle';
import TranslationsTipV2 from '@components/TranslationTipV2/TranslationTipV2';
import { ValidationErrorDisplayer } from '@components/ValidationErrorDisplayer';
import { AccessWarning } from '@components/Warning';
import { WritableInputText } from '@components/WritableInputText';
import { UserLanguageCheckbox } from '@components/Exercises/Common/UserLanguageCheckbox';
import { constants as contentConstants } from '@features/content';
import { useIsEditAvailable } from '@features/content/courses';
import { Instructions, removeMediaProcessingValidationError } from '@features/content/exercises';
import { HelpDisplayer } from '@features/help';
import { getResources } from '@helpers/getResourcesHelper';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { selectIsIssuesShown } from '@selectors/UiSelectors';
import {
  selectInterfaceLanguages,
  selectLearningLanguage,
  selectLoadedExerciseData,
} from '@selectors/CoursesSelectors';
import helpersService from '@services/HelpersService';
import { userTracking } from '@features/app/tracking';

import {
  ExerciseCommonBoxTitle,
  ExerciseCommonContainer,
  ExerciseCommonWrapper,
  MultiOptionWrapper,
} from '../Common/styled';
import ListenRepeatExerciseInterface from './interfaces/ListenRepeatExerciseInterface';
import { ReactComponent as CreateIcon } from './img/Create.svg';
import { ReactComponent as SearchIcon } from './img/Search.svg';

const MediaWrapper = styled.div`
  aspect-ratio: 1.78;
  height: 36rem;
  width: auto;
`;

const { DEFAULT_LANGUAGE, MEDIA_PROCESSING_VALIDATION_MESSAGE } = contentConstants;

export const ListenRepeatExercise = ({
  exercise: exerciseParam,
}: ExerciseProps<LoadedListenRepeatExerciseInterface>) => {
  const dispatch = useAppDispatch();
  const { isEditAvailable } = useIsEditAvailable();
  const courseLearningLanguage = useAppSelector(selectLearningLanguage);
  const interfaceLanguages = useAppSelector(selectInterfaceLanguages);
  const isIssuesShown = useAppSelector(selectIsIssuesShown);

  const loadedExercise = useAppSelector(selectLoadedExerciseData) as ListenRepeatExerciseInterface;
  const [shouldShowListenRepeatOptions, setShouldShowListenRepeatOptions] = useState(
    !loadedExercise.content.mainBundle?._id,
  );

  const { errors } = loadedExercise?.content?.validation;

  const phraseAudioLocalizations = loadedExercise?.content?.mainBundle?.phrase.audioLocalizations;

  const availableLanguagesForAudioLocalizations = Array.from(new Set([...interfaceLanguages, courseLearningLanguage]));
  const availablePhraseAudioLocalizations = phraseAudioLocalizations.filter((audioLocalization) =>
    availableLanguagesForAudioLocalizations.includes(audioLocalization.language),
  );

  // Image and Video only have EN localization
  const imageLocalization = loadedExercise?.content?.mainBundle?.image?.imageLocalizations?.find(
    (imageLocalization) => imageLocalization.language === DEFAULT_LANGUAGE,
  );
  const videoLocalization = loadedExercise?.content?.mainBundle?.video?.videoLocalizations?.find(
    (videoLocalization) => videoLocalization.language === DEFAULT_LANGUAGE,
  );

  const instructionsErrors = errors.filter((error: ValidationErrorInterface) => error.field === 'instructions');
  const mainBundleErrors = errors.filter((error: ValidationErrorInterface) => error.field === 'mainBundle');
  const mainVideoErrors = errors.filter((error: ValidationErrorInterface) => error.field === 'mainBundle.video');

  if (mainVideoErrors.length && videoLocalization?.processed && videoLocalization?.value) {
    const updatedErrors = removeMediaProcessingValidationError({
      errors,
      message: MEDIA_PROCESSING_VALIDATION_MESSAGE.VIDEO.replace('%LANG%', DEFAULT_LANGUAGE),
    });

    if (updatedErrors.length < errors.length) {
      dispatch(CommonExerciseActionsCreator.updateValidationErrors({ errors: updatedErrors }));
    }
  }

  const mainImageErrors = errors.filter((error: ValidationErrorInterface) => error.field === 'mainBundle.image');

  if (mainImageErrors.length && imageLocalization?.processed && imageLocalization?.value) {
    const updatedErrors = removeMediaProcessingValidationError({
      errors,
      message: MEDIA_PROCESSING_VALIDATION_MESSAGE.IMAGE.replace('%LANG%', DEFAULT_LANGUAGE),
    });

    if (updatedErrors.length < errors.length) {
      dispatch(CommonExerciseActionsCreator.updateValidationErrors({ errors: updatedErrors }));
    }
  }

  const phraseErrors = errors.filter(
    (error: ValidationErrorInterface) =>
      error.field === 'mainBundle.phrase' && !error.message.toLowerCase().includes('audio'),
  );
  const phraseAudioErrors = errors.filter(
    (error: ValidationErrorInterface) =>
      error.field === 'mainBundle.phrase' && error.message.toLowerCase().includes('audio'),
  );

  if (phraseAudioErrors.length) {
    let updatedErrors = [...errors];

    availablePhraseAudioLocalizations.forEach(({ language, processed, value }) => {
      if (processed && value) {
        updatedErrors = removeMediaProcessingValidationError({
          errors,
          fieldName: 'phraseAudioErrors.phrase',
          message: MEDIA_PROCESSING_VALIDATION_MESSAGE.AUDIO.replace('%LANG%', language),
        });
      }
    });

    if (updatedErrors.length < errors.length) {
      dispatch(CommonExerciseActionsCreator.updateValidationErrors({ errors: updatedErrors }));
    }
  }

  const isMainBundleChangeBlocked =
    loadedExercise.content.mainBundle?.mappings?.count &&
    loadedExercise.content.mainBundle?.mappings?.count > 1 &&
    !loadedExercise.content.mainBundle?.isReusingConfirmed;

  const setReusedData = (id: string, field: string, isBundle: boolean) => {
    dispatch(
      TranslationTipActionsCreator.setCurrentContentId(
        id,
        ContentTypes.exercise,
        field,
        undefined,
        undefined,
        'mainBundle',
        isBundle,
      ),
    );
  };

  return (
    <div>
      <TitleContainer>
        <Title>Listen & Repeat</Title>
        <HelpDisplayer type="guideline" id="guideline-listen-repeat-exercise" />
      </TitleContainer>

      {!isEditAvailable && <AccessWarning />}
      <div>
        <ExerciseCommonWrapper>
          <ExerciseCommonContainer>
            <ExerciseCommonBoxTitle>Instruction</ExerciseCommonBoxTitle>
            <ValidationErrorDisplayer text={helpersService.getValidationErrorMessageText(instructionsErrors)} />
          </ExerciseCommonContainer>
          <Instructions />
          <UserLanguageCheckbox
            checkedField={exerciseParam.exercise?.content.instructionsLanguage}
            onChange={(displayContentToUserMode: DisplayContentToUserModeType) => {
              dispatch(ListenRepeatExerciseActionsCreator.setInstructionsLanguage(displayContentToUserMode));
            }}
          />
        </ExerciseCommonWrapper>

        {shouldShowListenRepeatOptions ? (
          <ExerciseCommonWrapper>
            <MultiOptionWrapper hasErrors={!!mainVideoErrors.length && isIssuesShown}>
              <li className="multi-option-chooser__option">
                <div
                  className="multi-option-chooser__option-inner"
                  onClick={() => {
                    if (isEditAvailable) {
                      dispatch(
                        SearchModalActionsCreator.showSearchV2Panel({
                          bundleName: 'mainBundle',
                          contentType: 'bundle',
                          forReusing: true,
                          predefinedType: 'bundle',
                        }),
                      );
                      userTracking.logosSearchOpened({
                        source: 'resource_bundle',
                      });
                    }
                  }}
                >
                  <SearchIcon />
                  <span>Reuse resource bundle</span>
                </div>
              </li>
              <li className="multi-option-chooser__option">
                <div
                  className="multi-option-chooser__option-inner"
                  onClick={() => {
                    if (isEditAvailable) {
                      setShouldShowListenRepeatOptions(false);
                    }
                  }}
                >
                  <CreateIcon />
                  <span>Create from scratch</span>
                </div>
              </li>
            </MultiOptionWrapper>
          </ExerciseCommonWrapper>
        ) : (
          <ResourceBundleWrapper
            bundle={loadedExercise.content.mainBundle}
            bundleName="mainBundle"
            errors={mainBundleErrors}
            resources={getResources(loadedExercise, courseLearningLanguage)}
          >
            <ExerciseCommonWrapper>
              <ExerciseCommonWrapper>
                <ExerciseCommonContainer>
                  <ExerciseCommonBoxTitle>Video</ExerciseCommonBoxTitle>
                  <ValidationErrorDisplayer text={helpersService.getValidationErrorMessageText(mainVideoErrors)} />
                </ExerciseCommonContainer>

                <MediaWrapper>
                  <VideoUpload
                    videoData={ImageUploadUtils.getDisplayVideoForFileUpload(loadedExercise, 'mainBundle')}
                    onProcessingFinished={(url: string) => {
                      dispatch(
                        CommonExerciseActionsCreator.setValueAfterProcessing({
                          url,
                          mediaType: 'video',
                          type: ContentTypes.exercise,
                          field: 'video',
                          language: 'EN',
                          bundleName: 'mainBundle',
                        }),
                      );
                    }}
                    onChange={(uploadedVideo: File, progressHandler: (progress: number) => void) => {
                      dispatch(
                        VideoUploadActionsCreator.uploadVideo(
                          loadedExercise,
                          uploadedVideo,
                          'mainBundle',
                          progressHandler,
                        ),
                      );
                    }}
                    onRemove={() => {
                      dispatch(ListenRepeatExerciseActionsCreator.removeVideo());
                    }}
                    fullScreen
                    errors={mainVideoErrors}
                    fieldName="video"
                    isChangeBlocked={!!isMainBundleChangeBlocked}
                    onChangeInstant={() => setReusedData(loadedExercise.content.mainBundle?._id || '', 'video', true)}
                  />
                </MediaWrapper>
              </ExerciseCommonWrapper>

              <ExerciseCommonWrapper>
                <ExerciseCommonContainer>
                  <ExerciseCommonBoxTitle>Phrase</ExerciseCommonBoxTitle>
                  <ValidationErrorDisplayer text={helpersService.getValidationErrorMessageText(phraseErrors)} />
                </ExerciseCommonContainer>

                <TranslationsTipV2
                  visitedBranch="phrase"
                  bundleName="mainBundle"
                  errors={phraseErrors}
                  showErrorDisplayer={false}
                >
                  <WritableInputText id="listen-repeat-phrase-input" />
                </TranslationsTipV2>
              </ExerciseCommonWrapper>

              <ExerciseCommonWrapper>
                <ContentToggler
                  text="Image"
                  open={!!loadedExercise.content.mainBundle?.image?._id}
                  onSwitch={() => {
                    dispatch(ExerciseCommonActions.setImageEnabled(!loadedExercise.content.mainImageEnabled));
                  }}
                  onRemove={() => {
                    if (isMainBundleChangeBlocked) {
                      setReusedData(loadedExercise.content.mainBundle?._id || '', 'image', true);
                    } else {
                      dispatch(ListenRepeatExerciseActionsCreator.removeImage());
                    }
                  }}
                  isChangeBlocked={!!isMainBundleChangeBlocked}
                  errors={mainImageErrors}
                >
                  <MediaWrapper>
                    <ImageUpload
                      width={ImageUploadDimensionDescriptors.fillgap.image.width}
                      height={ImageUploadDimensionDescriptors.fillgap.image.height}
                      size={Sizes.fullscreen}
                      mode={ImageUploadModes.normal}
                      onChange={(file: File, progressHandler: (progress: number) => void) => {
                        if (file !== null && loadedExercise.content.id !== undefined) {
                          dispatch(ListenRepeatExerciseActionsCreator.setImage(loadedExercise, file, progressHandler));
                        }
                      }}
                      onRemove={() => {
                        dispatch(ListenRepeatExerciseActionsCreator.removeImage());
                      }}
                      imageData={ImageUploadUtils.getDisplayImageForFileUpload(
                        loadedExercise,
                        'image',
                        undefined,
                        'mainBundle',
                      )}
                      onProcessingFinished={(url: string) => {
                        dispatch(
                          CommonExerciseActionsCreator.setValueAfterProcessing({
                            url,
                            mediaType: 'image',
                            type: ContentTypes.exercise,
                            field: 'image',
                            language: DEFAULT_LANGUAGE,
                            bundleName: 'mainBundle',
                          }),
                        );
                      }}
                      previewMode={false}
                      isForExercise
                      errors={mainImageErrors}
                      isChangeBlocked={!!isMainBundleChangeBlocked}
                      onChangeInstant={() => setReusedData(loadedExercise.content.mainBundle?._id || '', 'image', true)}
                    />
                  </MediaWrapper>
                </ContentToggler>
              </ExerciseCommonWrapper>
            </ExerciseCommonWrapper>
          </ResourceBundleWrapper>
        )}
      </div>
    </div>
  );
};
