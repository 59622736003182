import styled from 'styled-components';

import { HeaderWrapper } from './HeaderWrapper';

const TopicHeaderWrapper = styled(HeaderWrapper)`
  grid-template-columns: 63% 10% 25%;
`;

export const GrammarTopicSearchResultTypeHeader = () => (
  <TopicHeaderWrapper>
    <span>Grammar Topic</span>
    <span>Linked Lesson</span>
    <span>Location</span>
  </TopicHeaderWrapper>
);
