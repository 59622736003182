import { DBId } from '@common/types/DBId';
import { BidimensionalEditorActions } from '@actions/BidimensionalEditorActions';

export const BidimensionalEditorActionsCreator = {
  updateReduxStoreCell: (
    newText: string,
    storeRow: number,
    storeColumn: number,
    storeBranch: string,
    language: string,
    localizationId: DBId | undefined,
    bundleName?: string,
  ) => ({
    type: BidimensionalEditorActions.UPDATE_REDUX_STORE_CELL,
    payload: {
      newText,
      storeRow,
      storeColumn,
      storeBranch,
      language,
      localizationId,
      bundleName,
    },
  }),
  addRow: (branch: string, insertAt?: number) => ({
    type: BidimensionalEditorActions.ADD_ROW,
    payload: {
      branch,
      insertAt,
    },
  }),
  removeRow: (rowToRemove: number, branch: string) => ({
    type: BidimensionalEditorActions.REMOVE_ROW,
    payload: {
      rowToRemove,
      branch,
    },
  }),
  addColumn: (branch: string, insertAt?: number) => ({
    type: BidimensionalEditorActions.ADD_COLUMN,
    payload: {
      branch,
      insertAt,
    },
  }),
  removeColumn: (columnToRemove: number, branch: string) => ({
    type: BidimensionalEditorActions.REMOVE_COLUMN,
    payload: {
      columnToRemove,
      branch,
    },
  }),
};
