import { useParams } from 'react-router-dom';

import { ACTIVITY_TITLES, type ActivityType } from '@features/content/activities';
import { DBId } from '@common/types/DBId';
import { ListPreviewModal } from './ListPreviewModal';
import { useAppSelector } from '@redux/store';
import { selectIsPreviewForExercisesListOpened } from '@selectors/UiSelectors';
import { selectCourseStructure } from '@selectors/CoursesSelectors';
import { selectGrammarNavigationStructure } from '@selectors/GrammarSelectors';
import { selectPlacementTestNavigationStructure } from '@selectors/PlacementTestSelectors';

export const ListPreviewModalContainer = () => {
  const { courseId, topicId, entrypointId, activityId } = useParams<{
    courseId: DBId;
    topicId: DBId;
    entrypointId: DBId;
    activityId: DBId;
  }>();

  const isPreviewForExercisesListOpened = useAppSelector(selectIsPreviewForExercisesListOpened);

  const courseStructure = useAppSelector(selectCourseStructure);
  const exercisesActivityNavigation = courseStructure.filter((item) => item.parentId === activityId);

  const grammarStructure = useAppSelector(selectGrammarNavigationStructure);
  const exercisesGrammarNavigation = grammarStructure.filter((item) => item.parentId === topicId);

  const placementStructure = useAppSelector(selectPlacementTestNavigationStructure);
  const exercisesPlacementNavigation = placementStructure.filter((item) => item.parentId === entrypointId);

  const getExercisesList = () => {
    if (topicId) {
      return exercisesGrammarNavigation;
    } else if (entrypointId) {
      return exercisesPlacementNavigation;
    } else if (activityId) {
      return exercisesActivityNavigation;
    } else {
      return [];
    }
  };

  const getTitle = () => {
    if (topicId) {
      return grammarStructure.find((item) => item.id === topicId)?.title || 'Untitled topic';
    } else if (entrypointId) {
      return placementStructure.find((item) => item.id === entrypointId)?.title || 'Untitled entrypoint';
    } else if (activityId) {
      return ACTIVITY_TITLES[courseStructure.find((item) => item.id === activityId)?.type as ActivityType];
    } else {
      return '';
    }
  };

  return (
    <ListPreviewModal
      isOpened={isPreviewForExercisesListOpened}
      courseId={courseId}
      title={getTitle()}
      exercisesList={getExercisesList()}
    />
  );
};
