interface RecentCourse {
  id: string;
  viewedCount: number;
}

const COUNTERS_KEY = 'course-view-counters';

const readCourseCounters = () => {
  const recentCoursesString = localStorage.getItem(COUNTERS_KEY);

  if (!recentCoursesString) {
    return {};
  }

  return JSON.parse(recentCoursesString) as { [key in string]: number };
};

export const clearCourseCounter = (id: string) => {
  const recentCoursesString = localStorage.getItem(COUNTERS_KEY);

  if (recentCoursesString) {
    const prevCourses = JSON.parse(recentCoursesString);
    delete prevCourses[id];
    localStorage.setItem(COUNTERS_KEY, JSON.stringify(prevCourses));
    window.dispatchEvent(new Event('storage'));
  }
};

export const getRecentCourses = (limit: number) => {
  const courseCounters = readCourseCounters();

  const recentCourses = Object.entries(courseCounters).map(([id, viewedCount]: [string, number]) => ({
    id,
    viewedCount: Number(viewedCount),
  })) as RecentCourse[];

  return recentCourses.sort((a, b) => b.viewedCount - a.viewedCount).slice(0, limit);
};

export const countCourseView = (id: string) => {
  const courseCounters = readCourseCounters();

  if (!courseCounters[id]) {
    courseCounters[id] = 0;
  }

  courseCounters[id]++;

  localStorage.setItem(COUNTERS_KEY, JSON.stringify(courseCounters));
};
