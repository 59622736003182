import { useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import { ContentTypesActionsCreator } from '@actionCreators/ContentTypesActionsCreator';
import {
  LessonType,
  LessonTypeAllowedToChange,
  LessonTypeWithFocus,
  LESSON_ROLEPLAY,
  LESSON_CERTIFICATE,
  LESSON_CHECKPOINT,
  LESSON_LESSON,
  LESSON_LIVE,
  LESSON_REVIEW,
  LESSON_SPEAKING,
} from '@common/enums/LessonTypes';
import { PublishingCardButton } from '@components/ContentTypes/BaseContent/PublishingCardButton';
import { CardPendingStatus } from '@components/ContentTypes/BaseContent/CardPendingStatus';
import { RemoveCardButton } from '@components/ContentTypes/BaseContent/RemoveCardButton';
import { CardPublishingStatus } from '@components/ContentTypes/BaseContent/CardPublishingStatus';
import { CardValidationMark } from '@components/ContentTypes/BaseContent/CardValidationMark';
import { CardDragHandler } from '@components/ContentTypes/BaseContent/CardDragHandler';
import { LessonCardImage } from '@components/ContentTypes/LessonCard/LessonCardImage';
import { RemoveModal } from '@components/ContentTypes/BaseContent/RemoveModal';
import { LessonInterface } from '@common/interfaces/contentTypes/LessonInterface';
import { DragAndDropType } from '@components/DraggableList/DragAndDropType';
import { getValueByLanguage } from '@features/content';
import { ContentAnalyticsIndicator } from '@features/content/contentAnalytics';
import { getUrlToNavigate } from '@helpers/contentCardHelper';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { selectNewContentElementId, selectSelectedGroupsOfParentParentContents } from '@selectors/CoursesSelectors';
import { userTracking } from '@features/app/tracking';

import { LessonTypeSelector } from './LessonTypeSelector';
import { LessonObjetiveFocusSelector } from './LessonObjetiveFocusSelector';
import { CardWrapper, CardDataWrapper, CardControllers, DataWrapper, Data, Title, Description } from '../card.styles';

const ALLOWED_LESSON_TYPES_TO_CHANGE: LessonTypeAllowedToChange[] = [LESSON_LESSON, LESSON_REVIEW];
const LESSONS_TYPES_WITH_FOCUS: LessonTypeWithFocus[] = [LESSON_CERTIFICATE, LESSON_LESSON, LESSON_REVIEW];

const LessonCardDataWrapper = styled(CardDataWrapper)<{ $lessonType: LessonType }>`
  padding-left: ${({ $lessonType }) => ($lessonType === LESSON_LIVE ? '3.6rem' : '1rem')};
`;

type LessonCardProps = {
  content: LessonInterface;
  idx: number | null;
  dndProps?: DragAndDropType;
};

const getLessonTitle = ({
  idx,
  lessonType,
  ready,
  commonLessonTitle,
}: {
  idx: number | null;
  lessonType: LessonType;
  ready: boolean;
  commonLessonTitle: string;
}) => {
  // Checkpoint and Roleplay lessons have no editable title
  if (lessonType === LESSON_CHECKPOINT) {
    return ready && idx !== null ? `Checkpoint # ${idx + 1}` : 'Checkpoint';
  }

  if (lessonType === LESSON_ROLEPLAY) {
    return 'AI Roleplay';
  }

  return commonLessonTitle;
};

const getLessonDescription = (lessonType: LessonType, description: string) => {
  // Checkpoint and Roleplay lessons have no editable description
  if (lessonType === LESSON_CHECKPOINT) {
    return 'Take a quiz to jump ahead to the next chapter';
  }

  if (lessonType === LESSON_ROLEPLAY) {
    return "Introducing Busuu's AI Roleplay Lessons";
  }

  return description;
};

export const LessonCard = ({ content, idx, dndProps }: LessonCardProps) => {
  const params: { courseId?: string; levelId?: string; lessonId?: string; activityId?: string } = useParams();

  const {
    id,
    ready,
    type,
    focus,
    lessonType,
    titleWithLocalizations,
    descriptionWithLocalizations,
    changeStatus,
    validation,
    contentAnalytics,
  } = content;

  const loadedContent = useAppSelector(selectSelectedGroupsOfParentParentContents);
  let contentToValidate = loadedContent;
  const isChildInvalid = Object.values(contentToValidate?.validation?.invalidChildEntities || {})?.includes(id);

  const dispatch = useAppDispatch();
  const [hovered, setHovered] = useState(false);
  const [isRemoveModalOpened, setIsRemoveModalOpened] = useState(false);

  const newElementId = useAppSelector(selectNewContentElementId);

  const isCheckpointLesson = lessonType === LESSON_CHECKPOINT;

  const handleRemove = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsRemoveModalOpened(true);
  };

  const eachLessonPrefixByType = {
    [LESSON_ROLEPLAY]: '',
    [LESSON_CERTIFICATE]: 'Certificate - ',
    [LESSON_CHECKPOINT]: 'Checkpoint - ',
    [LESSON_LESSON]: ready && idx !== null ? `${idx + 1} ` : '',
    [LESSON_LIVE]: 'Live Lesson - ',
    [LESSON_REVIEW]: 'Review - ',
    [LESSON_SPEAKING]: 'Speaking - ',
  };

  const isIndexPrefixHidden =
    (!ready && ![LESSON_CERTIFICATE, LESSON_LIVE, LESSON_REVIEW, LESSON_SPEAKING].includes(lessonType)) ||
    isCheckpointLesson;

  const canChangeLessonType = ALLOWED_LESSON_TYPES_TO_CHANGE.includes(lessonType as LessonTypeAllowedToChange);
  const hasObjectiveFocus = LESSONS_TYPES_WITH_FOCUS.includes(lessonType as LessonTypeWithFocus);

  const description = getValueByLanguage(descriptionWithLocalizations?.textLocalizations) || 'Lesson description';

  const commonLessonTitle = getValueByLanguage(titleWithLocalizations?.textLocalizations) || 'Untitled lesson';
  const lessonTitle = getLessonTitle({ idx, lessonType, ready, commonLessonTitle });
  const lessonDescription = getLessonDescription(lessonType, description);

  return (
    <CardWrapper id={`lesson-${id}`} onMouseOver={() => setHovered(true)} onMouseOut={() => setHovered(false)}>
      <RemoveModal
        opened={isRemoveModalOpened}
        onHide={() => setIsRemoveModalOpened(false)}
        onDelete={() => {
          dispatch(ContentTypesActionsCreator.removeContent(content));
        }}
      />
      <CardDragHandler dndProps={dndProps} visible={hovered} />
      <LessonCardDataWrapper
        to={getUrlToNavigate(params, id, type)}
        $active={dndProps?.snapshot?.isDragging || id === newElementId}
        $hovered={hovered}
        $lessonType={lessonType}
      >
        {lessonType !== LESSON_LIVE && (
          <ContentAnalyticsIndicator
            data={contentAnalytics?.data}
            status={contentAnalytics?.status}
            onDataDisplayed={() => {
              userTracking.logosCompletionRatesSeen({
                lesson_id: id,
              });
            }}
          />
        )}
        <CardPublishingStatus hasPendingChanges={!!changeStatus?.hasPendingChanges} ready={!!ready} />
        <DataWrapper>
          <LessonCardImage content={content} />
          <Data>
            <CardPendingStatus content={content} />
            {canChangeLessonType ? (
              <LessonTypeSelector
                allowedLessonTypesToChange={ALLOWED_LESSON_TYPES_TO_CHANGE}
                lessonType={lessonType as LessonTypeAllowedToChange}
                lessonTitle={`${eachLessonPrefixByType[lessonType]}${lessonTitle}`}
                id={id}
              />
            ) : (
              <Title title={lessonTitle}>
                {!isIndexPrefixHidden && `${eachLessonPrefixByType[lessonType]}`}
                {lessonTitle}
                <CardValidationMark isInvalid={isChildInvalid || !validation?.valid} />
              </Title>
            )}
            <Description title={lessonDescription}>{lessonDescription}</Description>
          </Data>
          <CardControllers>
            {hasObjectiveFocus && <LessonObjetiveFocusSelector id={id} focus={focus} />}
            <PublishingCardButton hovered={hovered} content={content} />
            <RemoveCardButton hovered={hovered} content={content} onRemove={handleRemove} />
          </CardControllers>
        </DataWrapper>
      </LessonCardDataWrapper>
    </CardWrapper>
  );
};
