import styled from 'styled-components/macro';

const AccentInput = styled.input`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colorV2.textPrimary};
  border: 0.1rem solid ${({ theme }) => theme.colorV2.buttonTertiaryBaseBorder};
  border-radius: 0.8rem;
  height: 4rem;
  font-size: 1.6rem;
  position: relative;

  &:focus-within,
  &:active {
    border: 0.1rem solid ${({ theme }) => theme.colorV2.accentPrimary};
  }
  padding: 0.8rem;
  width: 100%;
`;

export const AccentContainer = ({
  isEditMode,
  accent,
  setAccent,
}: {
  isEditMode: boolean;
  accent: string;
  setAccent: (accent: string) => void;
}) => (
  <>
    {isEditMode ? (
      <AccentInput placeholder="Accent" value={accent} onChange={(e) => setAccent(e.target.value)} />
    ) : (
      accent || '-'
    )}
  </>
);
