import styled from 'styled-components';

import { Button } from '@features/theme';

const LinkedModalFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
`;

const StyledButtonSave = styled(Button)`
  min-width: 10.8rem;
  margin-left: 1.6rem;

  img {
    height: 2.4rem;
    width: 2.4rem;
  }
`;

export const LinkedChapterModalFooter = ({
  onClose,
  onSave,
  isSaveInProgress,
  isChanged = false,
}: {
  onClose: () => void;
  onSave: () => void;
  isSaveInProgress: boolean;
  isChanged: boolean;
}) => (
  <LinkedModalFooter>
    <Button data-testid="linked-chapter-modal-close-button" variant="secondary" onClick={onClose}>
      Cancel
    </Button>
    <StyledButtonSave
      data-testid="linked-chapter-modal-save-button"
      disabled={!isChanged}
      loading={isSaveInProgress}
      onClick={onSave}
    >
      Save
    </StyledButtonSave>
  </LinkedModalFooter>
);
