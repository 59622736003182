import { FunctionComponent } from 'react';
import classnames from 'classnames';

import playerControllers from '../img/player.svg';
import { ImageContainerProps } from '../../../PreviewModalProps';
import audioPlayerController from './img/audioPlayer.svg';
import { VocabularyMark } from './VocabularyMark';

import './ImageContainer.scss';

export const ImageContainer: FunctionComponent<ImageContainerProps> = ({
  deviceType,
  isImageEnabled,
  isAudioEnabled,
  imageSrc,
  isVocabulary = false,
}: ImageContainerProps) => {
  return (
    <>
      {isImageEnabled ? (
        <div className={classnames('image-container', `image-container--${deviceType}`)}>
          <img src={imageSrc} className="image-container__background" alt="Background" />
          <VocabularyMark isVocabulary={isVocabulary} />
          {isAudioEnabled ? <img src={playerControllers} className="image-container__player" alt="Audio" /> : null}
        </div>
      ) : (
        <>{isAudioEnabled ? <img src={audioPlayerController} className="audio-player" alt="Audio" /> : null}</>
      )}
    </>
  );
};
