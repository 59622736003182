import { Role } from '@features/auth/roles/index';
import { UserInterface } from '@common/interfaces/users/UserInterface';
import { Language } from '@features/content/languages';
import { useUser } from '../hooks';
import { isFeatureEnabled } from '@helpers/featuresHelper';
import { useAppSelector } from '@redux/store';
import { selectCourse } from '@selectors/CoursesSelectors';

const ROLES_ALLOWED_TO_PUBLISH: Role[] = ['ROLE_ADMIN', 'ROLE_PUBLISHER'];
const ROLES_ALLOWED_TO_UPLOAD_TRANSLATIONS: Role[] = ['ROLE_ADMIN', 'ROLE_PUBLISHER', 'ROLE_EDITOR'];
const ROLES_ALLOWED_TO_EDIT: Role[] = [
  'ROLE_ADMIN',
  'ROLE_PUBLISHER',
  'ROLE_UNPUBLISHER',
  'ROLE_EDITOR',
  'ROLE_TRAINEE',
];
const ROLES_ALLOWED_TO_ONLY_UNPUBLISH: Role[] = ['ROLE_ADMIN', 'ROLE_PUBLISHER', 'ROLE_UNPUBLISHER'];

const hasUserAccessToLanguage = (user: UserInterface, language: Language) => {
  return user.languages.all || user.languages.list.includes(language) || user.role === 'ROLE_ADMIN';
};

export const useIsAdmin = () => {
  const { user } = useUser();

  return user?.role === 'ROLE_ADMIN';
};

export const useIsPublishAvailable = () => {
  const { user } = useUser();
  const currentCourse = useAppSelector(selectCourse);

  if (
    isFeatureEnabled('publication') &&
    user &&
    currentCourse.id &&
    hasUserAccessToLanguage(user, currentCourse.learningLanguage as Language)
  ) {
    return {
      publish: ROLES_ALLOWED_TO_PUBLISH.includes(user.role),
      unpublish: ROLES_ALLOWED_TO_ONLY_UNPUBLISH.includes(user.role),
    };
  }

  return {
    publish: false,
    unpublish: false,
  };
};

export const useIsUploadTranslationsAvailable = () => {
  const { user } = useUser();
  const currentCourse = useAppSelector(selectCourse);

  if (user && currentCourse.id) {
    return (
      ROLES_ALLOWED_TO_UPLOAD_TRANSLATIONS.includes(user.role) &&
      hasUserAccessToLanguage(user, currentCourse.learningLanguage as Language)
    );
  }

  return false;
};

export const useIsEditAvailableForCourse = () => {
  const { user } = useUser();
  const currentCourse = useAppSelector(selectCourse);

  if (user) {
    const allowedToEdit = ROLES_ALLOWED_TO_EDIT.includes(user.role);

    if (currentCourse.id !== 'null') {
      return allowedToEdit && hasUserAccessToLanguage(user, currentCourse.learningLanguage as Language);
    } else {
      return allowedToEdit;
    }
  }

  return false;
};

export const useIsAccessCourseStats = () => {
  const { user } = useUser();

  if (user) {
    return (
      isFeatureEnabled('publication') &&
      (ROLES_ALLOWED_TO_PUBLISH.includes(user.role) || user.role === 'ROLE_COURSE_STATS_VIEWER')
    );
  }

  return false;
};
