import { LocalizationInterface } from '@common/interfaces/localization/LocalizationInterface';
import { AudioInstance, CommonAudioProps } from './AudioInstance';

export type AudiosProps = CommonAudioProps & {
  audios: LocalizationInterface[];
};

export const Audios = ({ audios, ...rest }: AudiosProps) => (
  <ul className="translations-panel-modal__audios-block">
    {audios?.length &&
      audios
        .sort((localization, localization2) => {
          return localization.language.localeCompare(localization2.language);
        })
        .map((localization, idx) => {
          return <AudioInstance {...rest} localization={localization} idx={idx} key={idx} />;
        })}
  </ul>
);
