import { SearchModalActionsCreator } from '@actionCreators/SearchModalActionsCreator';
import { useIsEditAvailable } from '@features/content/courses';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { selectSearchPanel } from '@selectors/UiSelectors';
import { userTracking } from '@features/app/tracking';

import { BundleDetailsTab, BundleSearchResultDetailsType } from '../../types';
import {
  AudiosAndTranslationsContent,
  DetailsTabs,
  DetailsWrapper,
  LocationsContent,
  ReuseContentButton,
} from '../Common';
import { GeneralInfo } from './GeneralInfo';

type DetailsProps = {
  isLoaded: boolean;
  isExpanded: boolean;
  errorMessage: string;
  details: BundleSearchResultDetailsType | null;
  defaultTab: BundleDetailsTab['key'] | null;
};

export const BundleResultDetails = ({ isLoaded, isExpanded, errorMessage, details, defaultTab }: DetailsProps) => {
  const dispatch = useAppDispatch();
  const isLoaderDisplayed = !isLoaded && !details;
  const { isEditAvailable } = useIsEditAvailable();
  const searchParams = useAppSelector(selectSearchPanel);

  const { bundleName, forReusing, reuseInProgress } = searchParams;
  const showReuseContentButton = isEditAvailable && forReusing;

  const tabs: BundleDetailsTab[] = [
    {
      key: 'general',
      title: 'General',
      Component: () => <GeneralInfo details={details} />,
    },
    {
      key: 'phraseAudioAndTranslation',
      title: 'Phrase Audio & Translation',
      Component: () => (
        <AudiosAndTranslationsContent
          audioLocalizations={details?.phrase?.audioLocalizations || []}
          textLocalizations={details?.phrase?.textLocalizations || []}
        />
      ),
    },
    {
      key: 'keyPhraseAudioAndTranslation',
      title: 'Key Phrase Audio & Translation',
      Component: () => (
        <AudiosAndTranslationsContent
          audioLocalizations={details?.keyPhrase?.audioLocalizations || []}
          textLocalizations={details?.keyPhrase?.textLocalizations || []}
        />
      ),
    },
    {
      key: 'locations',
      title: 'Location(s)',
      Component: () => <LocationsContent locations={details?.mappings || []} />,
    },
  ];

  const onCopyContent = () => {
    details && dispatch(SearchModalActionsCreator.copyBundle(details.id, bundleName as string));
  };
  const onUseContent = () => {
    details && dispatch(SearchModalActionsCreator.reuseBundle(details.id, bundleName as string));
    userTracking.logosSearchReuseSelected({
      type: 'bundle',
    });
  };

  return (
    <DetailsWrapper isExpanded={isExpanded} errorMessage={errorMessage} isLoaderDisplayed={isLoaderDisplayed}>
      {showReuseContentButton && (
        <ReuseContentButton
          contentLabel="Resource Bundle"
          inProgress={reuseInProgress === details?.id}
          onCopyContent={onCopyContent}
          onUseContent={onUseContent}
        />
      )}
      <DetailsTabs tabs={tabs} defaultTab={defaultTab} />
    </DetailsWrapper>
  );
};
