import { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Button } from 'react-bootstrap';
import ReactPlayer from 'react-player';
import classnames from 'classnames';
import styled from 'styled-components/macro';
import { apiClient } from '@features/api';
import { useIsEditAvailable } from '@features/content/courses';
import { FailedUploadingMessage } from '../FailedUploadingMessage/FailedUploadingMessage';
import InfoIcon from '@components/MediaUpload/AudioUpload/img/Info.svg';
import TrashIcon from '@static/svg/Trash.svg';
import UploadIcon from '@static/svg/upload.svg';
import DownloadIcon from './img/Download.svg';
import { random } from 'lodash';
import { HelpDisplayer } from '@features/help';
import { constants as contentConstants } from '@features/content';
import { selectIsIssuesShown } from '@selectors/UiSelectors';
import { useAppSelector } from '@redux/store';
import {
  selectImageOrVideoUploadingFailed,
  selectImageOrVideoUploadingInProgress,
  selectIsSaveButtonEnabled,
} from '@selectors/CoursesSelectors';
import { ValidationErrorInterface } from '@common/interfaces/validation/ValidationInterface';
import { MediaDataInterface } from '@common/interfaces/contentTypes/MediaDataInterface';

const { MEDIA_STATUS_REQUEST_INTERVAL, MEDIA_STATUS_REQUEST_ATTEMPTS } = contentConstants;

const Instructions = styled.div<{ isFlex: boolean }>`
  display: ${({ isFlex }) => (isFlex ? 'flex' : 'block')};
  align-items: center;
  gap: 0.5rem;
  color: ${({ theme }) => theme.color.brandGreyscale800};
  font-size: 1.4rem;
  text-align: center;
`;

const Image = styled.img`
  margin: 0 !important;
`;

type VideoUploadProps = {
  disabled?: boolean;
  onChange?: (file: File, setProgress: (number: number) => void) => void;
  onRemove?: () => void;
  fullScreen?: boolean;
  withHelp?: boolean;
  errors?: ValidationErrorInterface[];
  videoData?: MediaDataInterface;
  onProcessingFinished?: (url: string) => void;
  fieldName?: string;
  className?: string;
  isChangeBlocked?: boolean;
  onChangeInstant?: () => void;
};

const VideoUpload = ({
  disabled = false,
  onChange,
  onRemove,
  videoData,
  withHelp = true,
  fullScreen = false,
  onProcessingFinished,
  errors = [],
  fieldName = '',
  className,
  isChangeBlocked = false,
  onChangeInstant,
}: VideoUploadProps) => {
  const { isEditAvailable } = useIsEditAvailable();
  const [uniqueId] = useState(random(0, 10000));
  const [videoFile, setVideoFile] = useState(videoData?.mediaValue || '');
  const [runInterval, setRunInterval] = useState(false);
  const [isFirstRequestPending, setIsFirstRequestPending] = useState(false);
  const [processing, setProcessing] = useState(videoData?.mediaId && !videoData?.processed);
  const [progress, setProgress] = useState(0);

  const imageOrVideoUploadingInProcess: string[] = useAppSelector(selectImageOrVideoUploadingInProgress);
  const isSaveButtonEnabled = useAppSelector(selectIsSaveButtonEnabled);

  const isIssuesShown = useAppSelector(selectIsIssuesShown);

  const isErrorsShown = errors && errors.length && isIssuesShown;

  const imageOrVideoUploadingFailed = useAppSelector(selectImageOrVideoUploadingFailed);

  const errorDetails = imageOrVideoUploadingFailed.find((item) => item.field === fieldName);

  const isDragAndDropDisabled = videoFile !== '' || !isEditAvailable;

  const onDropHandler = (file: File) => {
    if (isChangeBlocked) {
      onChangeInstant && onChangeInstant();
    } else {
      onChange && onChange(file, setProgress);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop: (files) => onDropHandler(files[0]) });

  const removeVideo = () => {
    setVideoFile('');
    setProcessing(false);
    onRemove && onRemove();
    setProgress(0);
  };

  const { contentId, mediaId } = videoData || {};

  const getStatus = useCallback(() => {
    apiClient.v2.get(`content/resources/${contentId}/video/${mediaId}/status`).then((data) => {
      if (data.data.processed) {
        setVideoFile(data.data.url);
        onProcessingFinished && onProcessingFinished(data.data.url);
        setProcessing(false);
      } else {
        setRunInterval(true);
      }
    });
  }, [contentId, mediaId, onProcessingFinished]);

  useEffect(() => {
    if (processing && !isFirstRequestPending) {
      setIsFirstRequestPending(true);
      getStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFirstRequestPending, processing]);

  useEffect(() => {
    if (runInterval) {
      const intervalId = setInterval(() => {
        apiClient.v2.get(`content/resources/${contentId}/video/${mediaId}/status`).then((data) => {
          if (data.data.processed) {
            setVideoFile(data.data.url);
            onProcessingFinished && onProcessingFinished(data.data.url);
            setProcessing(false);
            clearInterval(intervalId);
            setRunInterval(false);
          }
        });
      }, MEDIA_STATUS_REQUEST_INTERVAL);

      setTimeout(() => {
        clearInterval(intervalId);
      }, MEDIA_STATUS_REQUEST_INTERVAL * MEDIA_STATUS_REQUEST_ATTEMPTS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [runInterval]);

  useEffect(() => {
    setVideoFile(videoData?.mediaValue || '');
    setProcessing(videoData?.mediaId && !videoData?.processed);
  }, [videoData]);

  return (
    <>
      <div
        className={classnames(
          className,
          'video-upload',
          { 'video-upload--disabled': disabled },
          { 'video-upload--without-file': !videoFile },
          { 'video-upload--with-file': videoFile !== '' },
          { 'video-upload--processing': processing },
          { 'video-upload--fullscreen': fullScreen },
          { 'video-upload--with-error': isErrorsShown || errorDetails },
          { 'video-upload--with-error--failed': errorDetails },
          { 'video-upload--dragging': isDragActive },
          { 'video-upload--upload-in-progress': imageOrVideoUploadingInProcess.includes(fieldName) },
        )}
        {...(isDragAndDropDisabled ? null : getRootProps())}
        onClick={(e) => {
          if (isDragAndDropDisabled) {
            return null;
          } else {
            if (isChangeBlocked) {
              e.stopPropagation();
              onChangeInstant && onChangeInstant();
            } else {
              const { onClick } = getRootProps();
              onClick && onClick(e);
            }
          }
        }}
      >
        {!videoFile && !processing && (
          <>
            {imageOrVideoUploadingInProcess.includes(fieldName) ? (
              <div className="video-upload__progress-container">
                <span>Uploading ... {progress}%</span>
                <progress id="progressBar" value={progress} max="100"></progress>
              </div>
            ) : (
              <span className="video-upload__instructions">
                {isEditAvailable ? (
                  <>
                    <img src={UploadIcon} alt="" className="video-upload__upload-icon" />
                    <Instructions isFlex={withHelp}>
                      <span className="video-upload__instructions__description">Drag & drop your video here, or </span>
                      <label htmlFor={isChangeBlocked ? '' : `browse-${uniqueId}`} className="file-upload__browse">
                        browse
                      </label>
                      <span className="video-upload__instructions__description"> to upload.</span>
                      {withHelp && <HelpDisplayer type="guideline" id="guideline-upload-video" />}
                    </Instructions>

                    <span className="video-upload__requirements">(1920x1080, 250MB max, .mp4 supported)</span>
                    <input
                      {...getInputProps()}
                      type="file"
                      className="file-upload__file"
                      id={`browse-${uniqueId}`}
                      name="browse"
                      disabled={!isEditAvailable || disabled}
                    />
                  </>
                ) : (
                  <span className="video-upload__instructions__description">Get edit permissions to upload video</span>
                )}
              </span>
            )}
          </>
        )}
        {processing && (
          <div className="video-upload--processing">
            <img src={InfoIcon} alt="" className="video-upload__info-icon" />
            <span>
              {isSaveButtonEnabled
                ? 'We are processing this video. Please press “Save” and check back later.'
                : 'We are processing this video.'}
            </span>
          </div>
        )}
        {videoFile !== '' && !processing && (
          <>
            <ReactPlayer url={videoFile} controls={true} width={'initial'} height={withHelp ? '360px' : '100%'} />
            <Button variant="light" className="video-upload__download">
              <a href={videoFile} target="_blank" rel="noopener noreferrer">
                <Image src={DownloadIcon} alt="" className="video-upload__download-icon" />
              </a>
            </Button>
            {isEditAvailable ? (
              <Button
                variant="light"
                disabled={disabled}
                onClick={() => {
                  if (isChangeBlocked) {
                    onChangeInstant && onChangeInstant();
                  } else {
                    removeVideo();
                  }
                }}
                className="video-upload__trash"
              >
                <Image src={TrashIcon} alt="Upload different media" />
              </Button>
            ) : null}
          </>
        )}
      </div>
      <FailedUploadingMessage errorDetails={errorDetails} />
    </>
  );
};

export default VideoUpload;
