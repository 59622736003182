import { takeLatest } from 'redux-saga/effects';

import { PlacementTestSagas } from '@sagas/placementTest/definitions/PlacementTestSagasDefinition';
import { PlacementTestActions } from '@actions/PlacementTestActions';

export function* PlacementTestRelatedSagas() {
  yield takeLatest(PlacementTestActions.GET_PLACEMENT_TEST, PlacementTestSagas.getPlacementTest);
  yield takeLatest(PlacementTestActions.CREATE_PLACEMENT_TEST, PlacementTestSagas.createPlacementTest);
  yield takeLatest(PlacementTestActions.GET_ENTRYPOINTS, PlacementTestSagas.getEntrypoints);
  yield takeLatest(PlacementTestActions.GET_ALL_NAVIGATION, PlacementTestSagas.getAllNavigation);
  yield takeLatest(PlacementTestActions.GET_ENTRYPOINT, PlacementTestSagas.getEntrypoint);
  // Entrypoint sagas
  yield takeLatest(PlacementTestActions.GET_ENTRYPOINT_EXERCISES, PlacementTestSagas.getEntrypointExercises);
  yield takeLatest(PlacementTestActions.SAVE_ENTRYPOINT, PlacementTestSagas.saveEntrypoint);
  yield takeLatest(PlacementTestActions.CREATE_EXERCISE, PlacementTestSagas.createEntrypointExercise);
  yield takeLatest(PlacementTestActions.REMOVE_EXERCISE, PlacementTestSagas.removeExercise);
  yield takeLatest(PlacementTestActions.REUSE_EXERCISE, PlacementTestSagas.reuseExercise);
  yield takeLatest(PlacementTestActions.COPY_EXERCISE, PlacementTestSagas.copyExercise);
}
