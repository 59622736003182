import { FunctionComponent } from 'react';
import styled from 'styled-components';

const ShowInCourseLangWrapper = styled.div<{ disabled: boolean }>`
  align-items: center;
  display: flex;

  ${(props) =>
    props.disabled &&
    `
  cursor: default;
  opacity: 0.5;
  pointer-events: none;
  `}
`;

const ShowInCourseLangText = styled.span`
  color: ${({ theme }) => theme.color.brandGreyscale800};
  cursor: pointer;
  font-size: 14px;
  user-select: none;
`;

interface UserLanguageCheckboxViewProps {
  isEditAvailable: boolean;
  toggle: () => void;
  checked: boolean;
  courseLanguageName: string;
  disabled: boolean;
}

export const UserLanguageCheckboxView: FunctionComponent<UserLanguageCheckboxViewProps> = ({
  isEditAvailable,
  toggle,
  checked,
  courseLanguageName,
  disabled,
}: UserLanguageCheckboxViewProps) => (
  <ShowInCourseLangWrapper disabled={disabled}>
    <input type="checkbox" checked={!!checked} onChange={toggle} disabled={disabled || !isEditAvailable} />

    <ShowInCourseLangText onClick={() => isEditAvailable && toggle()}>
      Show to users in {courseLanguageName}
    </ShowInCourseLangText>
  </ShowInCourseLangWrapper>
);
