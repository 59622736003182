import styled from 'styled-components/macro';

import { ContentTypes } from '@common/enums/ContentTypes';
import { Button } from '@features/theme';

import { PreviewModalFooterProps } from '../PreviewModalProps';
import { ReactComponent as CorrectIcon } from './img/correct.svg';
import { ReactComponent as IncorrectIcon } from './img/incorrect.svg';

const PreviewModalFooterWrapper = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colorV2.uiBackgroundPrimary};
  box-shadow: 0 -0.2rem 0.4rem 0 rgba(0, 0, 0, 0.09);
  display: flex;
  height: 9.2rem;
  justify-content: space-between;
`;

const AnswerResultContainer = styled.div`
  align-items: center;
  display: flex;
  font-size: 2.4rem;
  font-weight: 700;
  gap: 1.2rem;
  padding: 0 1.6rem;
`;

const Explanation = styled.div`
  color: ${({ theme }) => theme.colorV2.textPrimary};
  flex: 1;
  font-size: 1.6rem;
  line-height: 1.5;
  padding: 0 1.6rem;
`;

const FakeContinueButton = styled(Button)`
  pointer-events: none;
`;

export const typesWithoutFooter: string[] = [
  ContentTypes.conversation,
  ContentTypes.dialogue,
  ContentTypes.fillgap,
  ContentTypes.flashcard,
  ContentTypes.highlighter,
  ContentTypes.matchup,
  ContentTypes.multipleChoice,
  ContentTypes.phraseBuilder,
  ContentTypes.slidePdf,
  ContentTypes.speechRecognition,
  ContentTypes.spelling,
  ContentTypes.tip,
  ContentTypes.typing,
];

export const PreviewModalFooter = ({ answerState, explanation, type }: PreviewModalFooterProps) => {
  if (answerState === 0) return null;

  return (
    <PreviewModalFooterWrapper>
      <AnswerResultContainer>
        {answerState === 1 && (
          <>
            <CorrectIcon title="Correct" />
            <span>Success!</span>
          </>
        )}
        {answerState === 2 && (
          <>
            <IncorrectIcon title="Incorrect" />
            <span>Next time!</span>
          </>
        )}
      </AnswerResultContainer>
      {explanation && <Explanation dangerouslySetInnerHTML={{ __html: explanation }} />}
      {!typesWithoutFooter.includes(type) && <FakeContinueButton onClick={() => {}}>Continue</FakeContinueButton>}
    </PreviewModalFooterWrapper>
  );
};
