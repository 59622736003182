import { AxiosResponse } from 'axios';

import { DBId } from '@common/types/DBId';
import { apiClient } from '@features/api';
import { ActionItemType } from '@features/theme';

import type { CommentType } from './types';
import type { ReactionNameType } from './CommentsPanel/CommentsList/CommentItem';

type CommentPayloadType = {
  message: string;
  mentions: string[];
  actionItems: ActionItemType[];
};

export const getComments = async (componentId: DBId): Promise<{ data: { comments: CommentType[] } }> => {
  return apiClient.noErrorsV2.get(`/content/components/${componentId}/comments`);
};

export const getCommentsNumber = async (componentId: DBId): Promise<AxiosResponse> => {
  return apiClient.noErrorsV2.head(`/content/components/${componentId}/comments`);
};

export const addComment = async (
  componentId: DBId,
  { message, mentions, actionItems }: CommentPayloadType,
): Promise<{ data: { id: string } }> => {
  return apiClient.noErrorsV2.post(`/content/components/${componentId}/comments`, { message, mentions, actionItems });
};

export const deleteComment = async (componentId: DBId, commentId: DBId): Promise<{ data: { id: string } }> => {
  return apiClient.noErrorsV2.delete(`/content/components/${componentId}/comments/${commentId}`);
};

export const updateComment = async (
  componentId: DBId,
  commentId: DBId,
  { message, mentions, actionItems }: CommentPayloadType,
): Promise<{}> => {
  return apiClient.noErrorsV2.put(`/content/components/${componentId}/comments/${commentId}`, {
    message,
    mentions,
    actionItems,
  });
};

export const updateActionItemStatus = async (
  componentId: DBId,
  commentId: DBId,
  actionItemId: string,
  state: boolean,
): Promise<{}> => {
  return apiClient.noErrorsV2.put(
    `/content/components/${componentId}/comments/${commentId}/action-items/${actionItemId}`,
    { state },
  );
};

export const addReaction = async (componentId: DBId, commentId: DBId, reactionName: ReactionNameType) => {
  return apiClient.noErrorsV2.post(`/content/components/${componentId}/comments/${commentId}/reactions`, {
    emoji: reactionName,
  });
};

export const removeReaction = async (componentId: DBId, commentId: DBId, reactionName: ReactionNameType) => {
  return apiClient.noErrorsV2.delete(`/content/components/${componentId}/comments/${commentId}/reactions`, {
    data: { emoji: reactionName },
  });
};
