import { call, put, select } from 'redux-saga/effects';
import { NavigationActions } from '@actions/NavigationActions';
import { ContentTypes, type ContentTypesType } from '@common/enums/ContentTypes';
import NavigationService from '@services/NavigationService';
import { DBId } from '@common/types/DBId';
import { PayloadAction } from '@reduxjs/toolkit';
import { NavigationItemType } from '@features/content/navigation';
import { getValueByLanguage } from '@features/content';
import { selectCourse, selectCourseStructure } from '@selectors/CoursesSelectors';

const NavigationMenuSagas = {
  *getAllNavigation({
    payload: { courseId, levelId, chapterId, lessonId, activityId },
  }: PayloadAction<{
    courseId: DBId;
    levelId: DBId;
    chapterId: DBId;
    lessonId: DBId;
    activityId: DBId;
  }>) {
    try {
      const courseContent: ReturnType<typeof selectCourse> = yield select(selectCourse);
      const navigation: ReturnType<typeof selectCourseStructure> = yield select(selectCourseStructure);
      let levels = [];
      let chapters = [];
      let lessons = [];
      let activities = [];
      let exercises = [];
      let childrensLength = 0;

      if (courseId && !navigation.filter((elem) => elem.parentId === courseId).length) {
        levels = yield call(NavigationMenuSagas.getCourseNavigation, { courseId });
        childrensLength += levels.length;
      }
      if (levelId && !navigation.filter((elem) => elem.parentId === levelId).length) {
        chapters = yield call(NavigationMenuSagas.getLevelNavigation, { levelId });
        childrensLength += chapters.length;
      }
      if (chapterId && !navigation.filter((elem) => elem.parentId === chapterId).length) {
        lessons = yield call(NavigationMenuSagas.getChapterNavigation, { chapterId });
        childrensLength += lessons.length;
      }
      if (lessonId && !navigation.filter((elem) => elem.parentId === lessonId).length) {
        activities = yield call(NavigationMenuSagas.getLessonNavigation, { lessonId });
        childrensLength += activities.length;
        exercises = yield call(NavigationMenuSagas.getLiveLessonNavigation, { lessonId });
        childrensLength += exercises.length;
      }
      if (activityId && !navigation.filter((elem) => elem.parentId === activityId).length) {
        exercises = yield call(NavigationMenuSagas.getActivityNavigation, { activityId });
        childrensLength += exercises.length;
      }
      if (childrensLength > 0) {
        yield put({
          type: NavigationActions.UPDATE_NAVIGATION,
          payload: [
            {
              id: courseId,
              title: courseContent.title,
              type: ContentTypes.course,
              children: !!levelId || !!levels.length,
              ready: courseContent.ready,
              parentId: null,
              expanded: true,
            },
            ...levels.map((level: NavigationItemType) => ({
              ...level,
              expanded: level.id === levelId,
            })),
            ...chapters.map((chapter: NavigationItemType) => ({
              ...chapter,
              expanded: chapter.id === chapterId,
            })),
            ...lessons.map((lesson: NavigationItemType) => ({
              ...lesson,
              expanded: lesson.id === lessonId,
              type: lesson.type === 'speaking' ? ContentTypes.speakingLesson : lesson.type,
            })),
            ...activities.map((activity: NavigationItemType) => ({
              ...activity,
              expanded: activity.id === activityId,
              type: activity.type === 'speaking' ? ContentTypes.speakingActivity : activity.type,
            })),
            ...exercises,
            ...navigation.map((item: NavigationItemType) => ({
              ...item,
              expanded: item.id === levelId || item.id === chapterId || item.id === lessonId || item.id === activityId,
            })),
          ],
        });
      } else {
        yield put({
          type: NavigationActions.UPDATE_NAVIGATION,
          payload: [
            {
              id: courseId,
              title: courseContent.title,
              type: ContentTypes.course,
              children: !!navigation.length,
              ready: courseContent.ready,
              parentId: null,
              expanded: true,
            },
            ...navigation,
          ],
        });
      }
    } catch (e) {
      console.error(e);
    }
  },
  *updateNavigation(parentId: string, parentType: ContentTypesType | null) {
    try {
      switch (parentType) {
        case ContentTypes.course:
          yield call(NavigationMenuSagas.updateCourseNavigation, { courseId: parentId });
          break;
        case ContentTypes.level:
          yield call(NavigationMenuSagas.updateLevelNavigation, { levelId: parentId });
          break;
        case ContentTypes.chapter:
          yield call(NavigationMenuSagas.updateChapterNavigation, { chapterId: parentId });
          break;
        case ContentTypes.lesson:
          yield call(NavigationMenuSagas.updateLessonNavigation, { lessonId: parentId });
          break;
        case ContentTypes.activity:
          yield call(NavigationMenuSagas.updateActivityNavigation, { activityId: parentId });
          break;
        default:
          console.log('Update Navigation warning: Unknown parent type');
      }
    } catch (e) {
      console.error(e);
    }
  },
  *updateCourseNavigation({ courseId }: { courseId: DBId }) {
    try {
      const navigation: ReturnType<typeof selectCourseStructure> = yield select(selectCourseStructure);
      const course: ReturnType<typeof selectCourse> = yield select(selectCourse);

      let levels = [];

      if (courseId) {
        levels = yield call(NavigationMenuSagas.getCourseNavigation, { courseId });
      }

      yield put({
        type: NavigationActions.UPDATE_NAVIGATION,
        payload: [
          ...navigation
            .filter((element) => element.parentId !== courseId)
            .map((element) => ({
              ...element,
              title: getValueByLanguage(course.titleWithLocalizations?.textLocalizations),
              expanded: courseId === element.id ? levels.length : element.expanded,
              children: courseId === element.id ? levels.length : element.children,
            })),
          ...levels,
        ],
      });
    } catch (e) {
      console.error(e);
    }
  },
  *updateLevelNavigation({ levelId }: { levelId: DBId }) {
    try {
      const navigation: ReturnType<typeof selectCourseStructure> = yield select(selectCourseStructure);

      let chapters = [];

      if (levelId) {
        chapters = yield call(NavigationMenuSagas.getLevelNavigation, { levelId });
      }

      yield put({
        type: NavigationActions.UPDATE_NAVIGATION,
        payload: [
          ...navigation
            .filter((element) => element.parentId !== levelId)
            .map((element) => ({
              ...element,
              expanded: levelId === element.id ? chapters.length : element.expanded,
              children: levelId === element.id ? chapters.length : element.children,
            })),
          ...chapters,
        ],
      });
    } catch (e) {
      console.error(e);
    }
  },
  *updateChapterNavigation({ chapterId }: { chapterId: DBId }) {
    try {
      const navigation: ReturnType<typeof selectCourseStructure> = yield select(selectCourseStructure);

      let lessons = [];

      if (chapterId) {
        lessons = yield call(NavigationMenuSagas.getChapterNavigation, { chapterId });
      }

      yield put({
        type: NavigationActions.UPDATE_NAVIGATION,
        payload: [
          ...navigation
            .filter((element) => element.parentId !== chapterId)
            .map((element) => ({
              ...element,
              expanded: chapterId === element.id ? lessons.length : element.expanded,
              children: chapterId === element.id ? lessons.length : element.children,
            })),
          ...lessons.map((lesson: NavigationItemType) => ({
            ...lesson,
            type: lesson.type === 'speaking' ? ContentTypes.speakingLesson : lesson.type,
          })),
        ],
      });
    } catch (e) {
      console.error(e);
    }
  },
  *updateLessonNavigation({ lessonId }: { lessonId: DBId }) {
    try {
      const navigation: ReturnType<typeof selectCourseStructure> = yield select(selectCourseStructure);

      let activities = [];
      let exercises = [];

      if (lessonId) {
        activities = yield call(NavigationMenuSagas.getLessonNavigation, { lessonId });
        exercises = yield call(NavigationMenuSagas.getLiveLessonNavigation, { lessonId });
      }

      yield put({
        type: NavigationActions.UPDATE_NAVIGATION,
        payload: [
          ...navigation
            .filter((element) => element.parentId !== lessonId)
            .map((element) => ({
              ...element,
              expanded: lessonId === element.id ? activities.length : element.expanded,
              children: lessonId === element.id ? activities.length : element.children,
            })),
          ...activities.map((activity: NavigationItemType) => ({
            ...activity,
            type: activity.type === 'speaking' ? ContentTypes.speakingActivity : activity.type,
          })),
          ...exercises,
        ],
      });
    } catch (e) {
      console.error(e);
    }
  },
  *updateActivityNavigation({ activityId }: { activityId: DBId }) {
    try {
      const navigation: ReturnType<typeof selectCourseStructure> = yield select(selectCourseStructure);

      let exercises = [];

      if (activityId) {
        exercises = yield call(NavigationMenuSagas.getActivityNavigation, { activityId });
      }

      yield put({
        type: NavigationActions.UPDATE_NAVIGATION,
        payload: [
          ...navigation
            .filter((element) => element.parentId !== activityId)
            .map((element) => ({
              ...element,
              expanded: activityId === element.id ? exercises.length : element.expanded,
              children: activityId === element.id ? exercises.length : element.children,
            })),
          ...exercises,
        ],
      });
    } catch (e) {
      console.error(e);
    }
  },
  *getCourseNavigation({ courseId }: { courseId: DBId }) {
    try {
      yield put({ type: NavigationActions.SET_LOADING_PARENT_ID, payload: courseId });

      const courseNavigationResult: Awaited<ReturnType<typeof NavigationService.getCourseNavigation>> = yield call(
        NavigationService.getCourseNavigation,
        courseId,
      );

      if (courseNavigationResult.status === 200) {
        yield put({ type: NavigationActions.SET_LOADING_PARENT_ID, payload: '' });
        return courseNavigationResult.data.levels.map((level: NavigationItemType) => ({
          ...level,
          parentId: courseId,
          type: ContentTypes.level,
        }));
      } else {
        return [];
      }
    } catch (e) {
      yield put({ type: NavigationActions.SET_LOADING_PARENT_ID, payload: '' });
      console.error(e);
    }
  },
  *getLevelNavigation({ levelId }: { levelId: DBId }) {
    try {
      yield put({ type: NavigationActions.SET_LOADING_PARENT_ID, payload: levelId });

      const levelNavigationResult: Awaited<ReturnType<typeof NavigationService.getLevelNavigation>> = yield call(
        NavigationService.getLevelNavigation,
        levelId,
      );

      if (levelNavigationResult.status === 200) {
        yield put({ type: NavigationActions.SET_LOADING_PARENT_ID, payload: '' });
        return levelNavigationResult.data.chapters.map((chapter: NavigationItemType) => ({
          ...chapter,
          parentId: levelId,
          type: ContentTypes.chapter,
        }));
      } else {
        return [];
      }
    } catch (e) {
      yield put({ type: NavigationActions.SET_LOADING_PARENT_ID, payload: '' });
      console.error(e);
    }
  },
  *getChapterNavigation({ chapterId }: { chapterId: DBId }) {
    try {
      yield put({ type: NavigationActions.SET_LOADING_PARENT_ID, payload: chapterId });

      const chapterNavigationResult: Awaited<ReturnType<typeof NavigationService.getChapterNavigation>> = yield call(
        NavigationService.getChapterNavigation,
        chapterId,
      );

      if (chapterNavigationResult.status === 200) {
        yield put({ type: NavigationActions.SET_LOADING_PARENT_ID, payload: '' });
        return chapterNavigationResult.data.lessons.map((lesson: NavigationItemType) => ({
          ...lesson,
          parentId: chapterId,
        }));
      } else {
        return [];
      }
    } catch (e) {
      yield put({ type: NavigationActions.SET_LOADING_PARENT_ID, payload: '' });
      console.error(e);
    }
  },
  *getLessonNavigation({ lessonId }: { lessonId: DBId }) {
    try {
      yield put({ type: NavigationActions.SET_LOADING_PARENT_ID, payload: lessonId });

      const lessonNavigationResult: Awaited<ReturnType<typeof NavigationService.getLessonNavigation>> = yield call(
        NavigationService.getLessonNavigation,
        lessonId,
      );

      if (lessonNavigationResult.status === 200) {
        yield put({ type: NavigationActions.SET_LOADING_PARENT_ID, payload: '' });
        return lessonNavigationResult.data.activities.map((activity: NavigationItemType) => ({
          ...activity,
          parentId: lessonId,
        }));
      } else {
        return [];
      }
    } catch (e) {
      yield put({ type: NavigationActions.SET_LOADING_PARENT_ID, payload: '' });
      console.error(e);
    }
  },
  *getLiveLessonNavigation({ lessonId }: { lessonId: DBId }) {
    try {
      yield put({ type: NavigationActions.SET_LOADING_PARENT_ID, payload: lessonId });

      const liveLessonNavigationResult: Awaited<ReturnType<typeof NavigationService.getLiveLessonNavigation>> =
        yield call(NavigationService.getLiveLessonNavigation, lessonId);

      if (liveLessonNavigationResult.status === 200) {
        yield put({ type: NavigationActions.SET_LOADING_PARENT_ID, payload: '' });
        return liveLessonNavigationResult.data.exercises.map((exercise: NavigationItemType) => ({
          ...exercise,
          parentId: lessonId,
        }));
      } else {
        return [];
      }
    } catch (e) {
      yield put({ type: NavigationActions.SET_LOADING_PARENT_ID, payload: '' });
      console.error(e);
    }
  },
  *getActivityNavigation({ activityId }: { activityId: DBId }) {
    try {
      yield put({ type: NavigationActions.SET_LOADING_PARENT_ID, payload: activityId });

      const activityNavigationResult: Awaited<ReturnType<typeof NavigationService.getActivityNavigation>> = yield call(
        NavigationService.getActivityNavigation,
        activityId,
      );

      if (activityNavigationResult.status === 200) {
        yield put({ type: NavigationActions.SET_LOADING_PARENT_ID, payload: '' });
        return activityNavigationResult.data.exercises.map((exercise: NavigationItemType) => ({
          ...exercise,
          parentId: activityId,
        }));
      } else {
        return [];
      }
    } catch (e) {
      yield put({ type: NavigationActions.SET_LOADING_PARENT_ID, payload: '' });
      console.error(e);
    }
  },
};

export default NavigationMenuSagas;
