import { useAppDispatch, useAppSelector } from '@redux/store';
import styled from 'styled-components/macro';

import { LoadedSlidePptxExerciseInterface } from '@common/interfaces/exercises/LoadedExerciseInterface';
import { ValidationErrorInterface } from '@common/interfaces/validation/ValidationInterface';
import ExerciseProps from '@components/Exercises/ExerciseProps';
import { ValidationErrorDisplayer } from '@components/ValidationErrorDisplayer';
import { SlideFileUpload } from '@components/MediaUpload';
import { PreviewModal } from '@features/content/preview';
import helpersService from '@services/HelpersService';

import SlidePptxExerciseInterface from './interfaces/SlidePptxExerciseInterface';
import { SlidePptxExerciseActionsCreator } from '@actionCreators/SlidePptxExerciseActionsCreator';
import { selectLoadedExerciseData } from '@selectors/CoursesSelectors';

const SlidePptxExerciseTitle = styled.h1`
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 5rem;
`;

const SlidePptxExerciseWrapper = styled.div`
  margin-bottom: 3.2rem;
`;

const SlidePptxExerciseContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const SlidePptxExercise = ({ exercise: exerciseParam }: ExerciseProps<LoadedSlidePptxExerciseInterface>) => {
  const dispatch = useAppDispatch();

  const loadedExercise = useAppSelector(selectLoadedExerciseData) as SlidePptxExerciseInterface;

  const { errors } = loadedExercise?.content?.validation;

  const pptxErrors = errors.filter((error: ValidationErrorInterface) => error.field === 'pptxUrl');

  return (
    <div className="exercise-slideshow">
      <PreviewModal type={loadedExercise.type} content={loadedExercise.content} />
      <header className="exercise-slideshow__header">
        <SlidePptxExerciseTitle>Presentation</SlidePptxExerciseTitle>
      </header>

      <SlidePptxExerciseWrapper>
        <SlidePptxExerciseContainer>
          <ValidationErrorDisplayer text={helpersService.getValidationErrorMessageText(pptxErrors)} />
        </SlidePptxExerciseContainer>
        <SlideFileUpload
          errors={pptxErrors}
          fileUrl={loadedExercise.content.pptxUrl}
          fileUrlChanged={loadedExercise.content.pptxUrlChanged}
          type="pptx"
          onChange={(file: File) => {
            if (file !== null && loadedExercise.content.id !== undefined) {
              dispatch(SlidePptxExerciseActionsCreator.uploadPptxFile(file));
            }
          }}
          onRemove={() => {
            dispatch(SlidePptxExerciseActionsCreator.setPptxFile(''));
          }}
        />
      </SlidePptxExerciseWrapper>
    </div>
  );
};

export default SlidePptxExercise;
