import { useEffect } from 'react';

import { LoadingStage } from '@common/enums/LoadingStage';
import CourseEdition from '@components/CourseEdition/CourseEdition';
import { coursesInfoRequest } from '@actionCreators/CourseActionCreator';
import { Comments } from '@features/comments';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { selectCourse, selectCoursesInfo } from '@selectors/CoursesSelectors';
import { GrammarReviewActionCreators } from '@actionCreators/GrammarReviewActionCreators';
import { PlacementTestActionCreators } from '@actionCreators/PlacementTestActionCreators';

export const MainContent = () => {
  const dispatch = useAppDispatch();
  const course = useAppSelector(selectCourse);
  const coursesInfo = useAppSelector(selectCoursesInfo);

  useEffect(() => {
    if (coursesInfo.loaded === LoadingStage.notLoaded) {
      dispatch(coursesInfoRequest());
    }
  }, [dispatch, coursesInfo]);

  useEffect(() => {
    dispatch(GrammarReviewActionCreators.setInitialGrammarReview());
    dispatch(PlacementTestActionCreators.setInitialPlacementTest());
  }, [dispatch]);

  return (
    <>
      <CourseEdition />
      {course.loaded === LoadingStage.loaded && <Comments />}
    </>
  );
};
