import { createSlice, createAsyncThunk, PayloadAction, ActionCreator } from '@reduxjs/toolkit';

import { ExerciseType, type ExerciseTypesType } from '@common/enums/ExerciseTypes';
import { ResourceBundleInterface } from '@common/interfaces/contentTypes/ResourceBundleInterface';
import { DBId } from '@common/types/DBId';
import ExerciseDataModelAssembler from '@components/Exercises/ExerciseDataModelAssembler';
import { apiClient } from '@features/api';
import initialState from '@redux/initialStates/courseInitialState';

// Reducers
import CommonActionsCourseReducers from '@redux/reducers/courseSlice/common/CommonActionsCourseReducers';
import ActivityReducers from '@redux/reducers/courseSlice/course/ActivityReducers';
import BusuuLogoActionsCourseCourseReducers from '@redux/reducers/courseSlice/course/BusuuLogoActionsCourseCourseReducers';
import CoursesInfoReducers from '@redux/reducers/courseSlice/course/CoursesInfoReducers';
import CourseContentOwnershipReducers from '@redux/reducers/courseSlice/course/CourseContentOwnershipReducers';
import CourseLoadCourseCourseReducers from '@redux/reducers/courseSlice/course/CourseLoadCourseCourseReducers';
import CourseUpdateCourseCourseReducers from '@redux/reducers/courseSlice/course/CourseUpdateCourseCourseReducers';
import NewCoursePanelActionsCourseCourseReducers from '@redux/reducers/courseSlice/course/NewCoursePanelActionsCourseCourseReducers';
import LessonReducers from '@redux/reducers/courseSlice/course/LessonReducers';
import SectionActionsCourseCourseReducers from '@redux/reducers/courseSlice/course/SectionActionsCourseCourseReducers';
import SidePanelCourseCourseReducers from '@redux/reducers/courseSlice/course/SidePanelCourseCourseReducers';
import ExerciseContentOwnershipReducers from '@redux/reducers/courseSlice/exercises/common/ExerciseContentOwnershipReducers';
import ExerciseValidationErrorsReducers from '@redux/reducers/courseSlice/exercises/common/ExerciseValidationErrorsReducers';
import ComprehensionExerciseCourseReducers from '@redux/reducers/courseSlice/exercises/ComprehensionExerciseCourseReducers';
import ConversationExerciseCourseReducers from '@redux/reducers/courseSlice/exercises/ConversationExerciseCourseReducers';
import DialogueExerciseCourseReducers from '@redux/reducers/courseSlice/exercises/DialogueExerciseCourseReducers';
import FillGapExerciseCourseReducers, {
  FillGapExerciseCourseReducersV2,
} from '@redux/reducers/courseSlice/exercises/FillGapExerciseCourseReducers';
import FlashcardExerciseCourseReducers from '@redux/reducers/courseSlice/exercises/FlashcardExerciseCourseReducers';
import HighlighterExerciseCourseReducers from '@redux/reducers/courseSlice/exercises/HighlighterExerciseCourseReducers';
import ListenRepeatExerciseCourseReducers from '@redux/reducers/courseSlice/exercises/ListenRepeatExerciseCourseReducers';
import MatchUpExerciseCourseReducers from '@redux/reducers/courseSlice/exercises/MatchUpExerciseCourseReducers';
import MultipleChoiceExerciseCourseReducers from '@redux/reducers/courseSlice/exercises/MultipleChoiceExerciseCourseReducers';
import PhraseBuilderExerciseCourseReducers from '@redux/reducers/courseSlice/exercises/PhraseBuilderExerciseCourseReducers';
import SlidePptxExerciseCourseReducers from '@redux/reducers/courseSlice/exercises/SlidePptxExerciseCourseReducers';
import SpeechRecognitionExerciseCourseReducers from '@redux/reducers/courseSlice/exercises/SpeechRecognitionExerciseCourseReducers';
import SpellingExerciseCourseReducers from '@redux/reducers/courseSlice/exercises/SpellingExerciseCourseReducers';
import SlideshowExerciseCourseReducers from '@redux/reducers/courseSlice/exercises/SlidePdfExerciseCourseReducers';
import TipExerciseCourseReducers from '@redux/reducers/courseSlice/exercises/TipExerciseCourseReducers';
import TrueFalseExerciseCourseReducers from '@redux/reducers/courseSlice/exercises/TrueFalseExerciseCourseReducers';
import TypingExerciseCourseReducers from '@redux/reducers/courseSlice/exercises/TypingExerciseCourseReducers';
import AudioMediaUploadCourseReducers from '@redux/reducers/courseSlice/mediaUpload/AudioMediaUploadCourseReducers';
import ImageMediaUploadCourseReducers from '@redux/reducers/courseSlice/mediaUpload/ImageMediaUploadCourseReducers';
import NavigationActionsCourseReducers from '@redux/reducers/courseSlice/navigation/NavigationActionsCourseReducers';
import PublishingActionsCourseReducers from '@redux/reducers/courseSlice/publishing/PublishingActionsCourseReducers';
import TranslationsPanelCourseReducers from '@redux/reducers/courseSlice/translationsPanel/TranslationsPanelCourseReducers';
import { exerciseEditors } from '@services/exercises/editExerciseService';

export const setExerciseLabels = createAsyncThunk(
  'courses/setExerciseLabels',
  async ({
    exerciseId,
    exerciseType,
    labels,
  }: {
    exerciseId: DBId;
    exerciseType: ExerciseTypesType;
    labels: string[];
  }) => {
    const exerciseEditor = exerciseEditors[exerciseType as ExerciseType];

    if (!exerciseEditor) {
      throw new Error('Exercise type editor is missing');
    }

    await exerciseEditor(exerciseId, {
      labels,
    });

    return {
      exerciseId,
      labels,
    };
  },
);

export const setGroupLabels = createAsyncThunk(
  'courses/setGroupLabels',
  async ({ groupId, labels }: { groupId: DBId; labels: string[] }) => {
    await apiClient.noErrorsV2.put(`content/groups/${groupId}`, {
      labels,
    });

    return {
      groupId,
      labels,
    };
  },
);

export const setCourseLabels = createAsyncThunk(
  'courses/setCourseLabels',
  async ({ courseId, labels }: { courseId: DBId; labels: string[] }) => {
    await apiClient.v2.put(`content/courses/${courseId}`, {
      labels,
    });

    return {
      courseId,
      labels,
    };
  },
);

const extraReducers = {
  ...ActivityReducers,
  ...AudioMediaUploadCourseReducers,
  ...BusuuLogoActionsCourseCourseReducers,
  ...CommonActionsCourseReducers,
  ...ComprehensionExerciseCourseReducers,
  ...ConversationExerciseCourseReducers,
  ...CourseContentOwnershipReducers,
  ...CourseLoadCourseCourseReducers,
  ...CourseUpdateCourseCourseReducers,
  ...CoursesInfoReducers,
  ...ImageMediaUploadCourseReducers,
  ...ExerciseContentOwnershipReducers,
  ...ExerciseValidationErrorsReducers,
  ...DialogueExerciseCourseReducers,
  ...FillGapExerciseCourseReducers,
  ...FlashcardExerciseCourseReducers,
  ...HighlighterExerciseCourseReducers,
  ...ListenRepeatExerciseCourseReducers,
  ...MatchUpExerciseCourseReducers,
  ...MultipleChoiceExerciseCourseReducers,
  ...NavigationActionsCourseReducers,
  ...NewCoursePanelActionsCourseCourseReducers,
  ...LessonReducers,
  ...PhraseBuilderExerciseCourseReducers,
  ...SlidePptxExerciseCourseReducers,
  ...PublishingActionsCourseReducers,
  ...SectionActionsCourseCourseReducers,
  ...SidePanelCourseCourseReducers,
  ...SlideshowExerciseCourseReducers,
  ...SpeechRecognitionExerciseCourseReducers,
  ...SpellingExerciseCourseReducers,
  ...TipExerciseCourseReducers,
  ...TranslationsPanelCourseReducers,
  ...TrueFalseExerciseCourseReducers,
  ...TypingExerciseCourseReducers,
} as Record<string, ActionCreator<any>>;

export const courseSlice = createSlice({
  name: 'courses',
  initialState,
  reducers: {
    setIsCreatingContent: (state, { payload }: PayloadAction<string | number | undefined>) => {
      state.newContent.creatingContentPosition = payload !== undefined ? `${payload}` : undefined;
    },
    setIsDeleteInProgress: (state, { payload }: PayloadAction<boolean>) => {
      state.newContent.isDeleteInProgress = payload;
    },
    fetchResourceBundleRequested: (state, { payload }: PayloadAction<string>) => {
      state.resourceBundleData.currentResourceBundle.id = payload;
      state.resourceBundleData.currentResourceBundle.isFetching = true;
    },
    fetchResourceBundleSucceeded: (state, { payload }: PayloadAction<ResourceBundleInterface>) => {
      state.resourceBundleData.currentResourceBundle.value = payload;
      state.resourceBundleData.currentResourceBundle.isFetching = false;
      state.resourceBundleData.currentResourceBundle.isFetched = true;
    },
    nullifyResourceBundle: (state) => {
      state.resourceBundleData.currentResourceBundle.value = null;
      state.resourceBundleData.currentResourceBundle.isFetched = false;
    },
    fetchResourceBundleFailed: (state, { payload }: PayloadAction<string>) => {
      state.resourceBundleData.currentResourceBundle.isFetching = false;
      state.resourceBundleData.currentResourceBundle.fetchError = payload;
    },
    setResourceBundleFields: (state, { payload }: PayloadAction<Partial<ResourceBundleInterface>>) => {
      const { value } = state.resourceBundleData.currentResourceBundle;
      if (value !== null) {
        Object.assign(value, payload);
      }
    },
    updateResourceBundleRequested: (state) => {
      state.resourceBundleData.currentResourceBundle.isUpdating = true;
    },
    updateResourceBundleSucceeded: (state) => {
      state.resourceBundleData.currentResourceBundle.isUpdating = false;
    },
    updateResourceBundleFailed: (state) => {
      state.resourceBundleData.currentResourceBundle.isUpdating = false;
    },
    generateTipExerciseEmptyExamples: (state) => {
      state.loadedExercise.exercise.content.examples = [
        [ExerciseDataModelAssembler.generateTranslationsPanelContentPopulatedWithEmptyLocalizations('Tip example.')],
      ];
    },
    addThirdPairToMatchup: (state) => {
      state.loadedExercise.exercise.content.fixedItem3 = {
        example: null,
        image: ExerciseDataModelAssembler.generateTranslationsPanelContentPopulatedWithEmptyLocalizations(),
        isVocabulary: false,
        phrase:
          ExerciseDataModelAssembler.generateTranslationsPanelContentPopulatedWithEmptyLocalizations('Matchup option.'),
        video: ExerciseDataModelAssembler.generateTranslationsPanelContentPopulatedWithEmptyLocalizations(),
      };
      state.loadedExercise.exercise.content.matchingItem3 = {
        example: null,
        image: ExerciseDataModelAssembler.generateTranslationsPanelContentPopulatedWithEmptyLocalizations(),
        isVocabulary: false,
        phrase:
          ExerciseDataModelAssembler.generateTranslationsPanelContentPopulatedWithEmptyLocalizations('Matchup option.'),
        video: ExerciseDataModelAssembler.generateTranslationsPanelContentPopulatedWithEmptyLocalizations(),
      };
      state.loadedExercise.exercise.content.fixedItem3Changed = true;
      state.loadedExercise.exercise.content.matchingItem3Changed = true;
    },
    ...FillGapExerciseCourseReducersV2,
  },
  extraReducers: (builder) => {
    Object.keys(extraReducers).forEach((actionType) => {
      builder.addCase(actionType, extraReducers[actionType]);
    });

    builder.addCase(setExerciseLabels.fulfilled, (state, action) => {
      if (state.loadedExercise.exercise.content.id === action.payload.exerciseId) {
        state.loadedExercise.exercise.content.labels = action.payload.labels;
      }
    });

    builder.addCase(setGroupLabels.fulfilled, (state, action) => {
      if (state.selectedGroupsOfParent?.parentContents?.id === action.payload.groupId) {
        state.selectedGroupsOfParent.parentContents.labels = action.payload.labels;
      }
    });

    builder.addCase(setCourseLabels.fulfilled, (state, action) => {
      if (state.course.id === action.payload.courseId) {
        state.course.labels = action.payload.labels;
      }
    });
  },
});
