import React, { useState } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { captureException } from '@sentry/react';
import { UnauthorizedErrorPage } from '@features/app/errors';
import { PreloaderFullscreen } from '@components/Loaders/PreloaderFullscreen';
import { UserInterface } from '@common/interfaces/users/UserInterface';

type SecureRouteTemplateProps = {
  routeProps: RouteProps;
  user: UserInterface | null;
  handleAuthorization: () => Promise<void>;
};
export const SecureRouteTemplate = ({ routeProps, user, handleAuthorization }: SecureRouteTemplateProps) => {
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(user !== null);

  if (user) {
    return <Route {...routeProps} />;
  }

  if (loading) {
    return <PreloaderFullscreen />;
  }

  if (loaded && !user) {
    return <UnauthorizedErrorPage />;
  }

  setLoading(true);
  handleAuthorization()
    .then(() => {
      setLoading(false);
      setLoaded(true);
    })
    .catch((e) => {
      captureException(e);

      setLoading(false);
      setLoaded(true);
    });

  return <PreloaderFullscreen />;
};
