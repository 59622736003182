import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { mergeRegister } from '@lexical/utils';
import { BLUR_COMMAND, COMMAND_PRIORITY_LOW, FOCUS_COMMAND } from 'lexical';
import { useLayoutEffect } from 'react';

export const FocusBlurPlugin = ({ onBlur, onFocus }: { onBlur?: () => void; onFocus?: () => void }) => {
  const [editor] = useLexicalComposerContext();

  useLayoutEffect(() => {
    return mergeRegister(
      editor.registerCommand(
        FOCUS_COMMAND,
        () => {
          onFocus && onFocus();
          return false;
        },
        COMMAND_PRIORITY_LOW,
      ),
      editor.registerCommand(
        BLUR_COMMAND,
        () => {
          onBlur && onBlur();
          return false;
        },
        COMMAND_PRIORITY_LOW,
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor]);

  return null;
};
