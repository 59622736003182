import styled, { css } from 'styled-components/macro';

import { SearchBox } from '@components/Search';
import { Button, Checkbox } from '@features/theme';

export const FiltersContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.space.L};
  min-width: 119rem;
`;

export const FiltersCol = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.space.S};
  height: 2.4rem;
  justify-content: flex-end;
  width: 58rem;

  &:first-child {
    height: auto;
    justify-content: flex-start;
    width: 50rem;
  }
`;

export const CustomOptionContainer = styled.div`
  display: flex;
`;

export const CustomOptionNameContainer = styled.div`
  display: inline-block;
  max-width: 24rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const StyledSearchBox = styled(SearchBox)`
  ${({ theme }) => css`
    background-color: ${theme.colorV2.uiBackgroundSecondary};
    margin-right: ${theme.space.S};
    padding-left: ${theme.space.S} !important;
    width: 32rem;

    input {
      background-color: ${theme.colorV2.uiBackgroundSecondary} !important;

      ::placeholder {
        font-weight: 700;
      }
    }
  `};
`;

export const StyledCheckbox = styled(Checkbox)`
  ${({ theme }) => css`
    color: ${theme.colorV2.textTertiary};
    margin: ${theme.space.S} ${theme.space.S} 0 0;
  `}
`;

export const FilterActionButton = styled(Button)`
  font-size: 1.4rem;
  line-height: 2.1rem;
`;
