const ACTIVITY_CHANGE_TYPE = 'ACTIVITY_CHANGE_TYPE';
const ACTIVITY_CHANGE_TYPE_SUCCEEDED = 'ACTIVITY_CHANGE_TYPE_SUCCEEDED';
const CONTENT_SAVE_NAME_AND_DESCRIPTION = 'CONTENT_SAVE_NAME_AND_DESCRIPTION';
const COURSE_CREATE_CONTENT = 'COURSE_CREATE_CONTENT';
const COURSE_SAVE_NAME_AND_DESCRIPTION = 'COURSE_SAVE_NAME_AND_DESCRIPTION';
const COURSE_UPDATE_CONTENTS_ORDER = 'COURSE_UPDATE_CONTENTS_ORDER';
const COURSE_UPDATE_LESSON_FOCUS = 'COURSE_UPDATE_LESSON_FOCUS';
const CHANGE_IDENTITY_GROUP = 'CHANGE_IDENTITY_GROUP';
const CHANGE_LESSON_CERTIFICATE_TYPE = 'CHANGE_LESSON_CERTIFICATE_TYPE';
const CHANGE_LESSON_TYPE = 'CHANGE_LESSON_TYPE';
const CHANGE_LESSON_TYPE_SUCCEEDED = 'CHANGE_LESSON_TYPE_SUCEEDED';
const UPDATE_EXERCISE_IN_NAVIGATION = 'UPDATE_EXERCISE_IN_NAVIGATION';
const COURSE_UPDATE_LESSON_FOCUS_SUCCESS = 'COURSE_UPDATE_LESSON_FOCUS_SUCCESS';
const COURSE_UPDATE_CONTENTS_ORDER_SUCCEEDED = 'COURSE_UPDATE_CONTENTS_ORDER_SUCCEEDED';
const COURSE_UPDATE_LEVEL_TYPE = 'COURSE_UPDATE_LEVEL_TYPE';
const COURSE_UPDATE_LEVEL_TYPE_SUCCESS = 'COURSE_UPDATE_LEVEL_TYPE_SUCCESS';
const COURSE_NAVIGATION_ITEM_SELECTED = 'COURSE_NAVIGATION_ITEM_SELECTED';
const EDIT_EVERYWHERE = 'EDIT_EVERYWHERE';
const LESSON_CLEAR_IMAGE_LOCALIZATIONS = 'LESSON_CLEAR_IMAGE_LOCALIZATIONS';
const LESSON_CLEAR_UNIT_IMAGE_LOCALIZATIONS = 'LESSON_CLEAR_UNIT_IMAGE_LOCALIZATIONS';
const LESSON_REMOVE_IMAGE = 'LESSON_REMOVE_IMAGE';
const LESSON_REMOVE_UNIT_IMAGE = 'LESSON_REMOVE_UNIT_IMAGE';
const LESSON_SET_IMAGE = 'LESSON_SET_IMAGE';
const LESSON_SET_UNIT_IMAGE = 'LESSON_SET_UNIT_IMAGE';
const LESSON_UPLOAD_IMAGE = 'LESSON_UPLOAD_IMAGE';
const LESSON_UPLOAD_UNIT_IMAGE = 'LESSON_UPLOAD_UNIT_IMAGE';
const REMOVE_CONTENT = 'REMOVE_CONTENT';
const SET_CURRENT_CONTENT_ID = 'SET_CURRENT_CONTENT_ID';
const SET_ERROR_BUNDLE_PLACES = 'SET_ERROR_BUNDLE_PLACES';
const SET_SAVE_BUTTON_STATE = 'SET_SAVE_BUTTON_STATE';
const SET_REUSED_DATA = 'SET_REUSED_DATA';

export const ContentTypesActions = {
  ACTIVITY_CHANGE_TYPE,
  ACTIVITY_CHANGE_TYPE_SUCCEEDED,
  CONTENT_SAVE_NAME_AND_DESCRIPTION,
  COURSE_CREATE_CONTENT,
  COURSE_SAVE_NAME_AND_DESCRIPTION,
  COURSE_UPDATE_CONTENTS_ORDER,
  COURSE_UPDATE_LESSON_FOCUS,
  CHANGE_IDENTITY_GROUP,
  CHANGE_LESSON_CERTIFICATE_TYPE,
  CHANGE_LESSON_TYPE,
  CHANGE_LESSON_TYPE_SUCCEEDED,
  UPDATE_EXERCISE_IN_NAVIGATION,
  COURSE_UPDATE_LESSON_FOCUS_SUCCESS,
  COURSE_UPDATE_CONTENTS_ORDER_SUCCEEDED,
  COURSE_UPDATE_LEVEL_TYPE,
  COURSE_UPDATE_LEVEL_TYPE_SUCCESS,
  COURSE_NAVIGATION_ITEM_SELECTED,
  EDIT_EVERYWHERE,
  LESSON_CLEAR_IMAGE_LOCALIZATIONS,
  LESSON_CLEAR_UNIT_IMAGE_LOCALIZATIONS,
  LESSON_REMOVE_IMAGE,
  LESSON_REMOVE_UNIT_IMAGE,
  LESSON_SET_IMAGE,
  LESSON_SET_UNIT_IMAGE,
  LESSON_UPLOAD_IMAGE,
  LESSON_UPLOAD_UNIT_IMAGE,
  REMOVE_CONTENT,
  SET_CURRENT_CONTENT_ID,
  SET_ERROR_BUNDLE_PLACES,
  SET_SAVE_BUTTON_STATE,
  SET_REUSED_DATA,
} as const;
