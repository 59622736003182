import { takeLatest } from 'redux-saga/effects';

import { FlashcardExerciseActions } from '@actions/FlashcardExerciseActions';
import ExerciseSagas from '@sagas/exercises/definitions/ExerciseSagas';
import MediaUploadSagas from '@sagas/mediaUpload/definitions/MediaUploadSagasDefinition';
import FlashcardExerciseSagas from '@sagas/exercises/definitions/FlashcardExerciseSagasDefinition';
import type { AppDispatch } from '@redux/store';

export function* FlashcardExerciseRelatedSagas(dispatch: AppDispatch) {
  yield takeLatest(FlashcardExerciseActions.UPLOAD_IMAGE, MediaUploadSagas.uploadImageToExercise);
  yield takeLatest(FlashcardExerciseActions.SAVE, FlashcardExerciseSagas.save, dispatch);
  yield takeLatest(FlashcardExerciseActions.REMOVE_AUDIO, ExerciseSagas.removeAudioFromExercise);
  yield takeLatest(FlashcardExerciseActions.REMOVE_EXAMPLE_AUDIO, ExerciseSagas.removeAudioFromExercise);
}
