import styled from 'styled-components/macro';

import { HelpTooltip } from '@features/help';
import progressMatchingSelectedIcon from './progressMatchingSelectedIcon.svg';
import progressMatchingIcon from '../img/progressMatchingIcon.svg';

const ProgressMatchingImage = styled.img`
  margin-left: 0.8rem;
`;

export const NavigationProgressMatchingImage = ({ selected }: { selected: boolean }) => (
  <>
    <ProgressMatchingImage
      src={selected ? progressMatchingSelectedIcon : progressMatchingIcon}
      alt="Progress matching"
      data-tip="Progress match"
      data-for="progress-matching-navigation-tooltip"
    />
    <HelpTooltip id="progress-matching-navigation-tooltip" effect="solid" />
  </>
);
