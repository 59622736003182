import { forwardRef } from 'react';
import styled from 'styled-components';

import { emojiSet } from './emojiSet';

const EmojiSelectorWrapper = styled.div<{ open: boolean }>`
  background-color: ${({ theme }) => theme.colorV2.baseEditorEmojiSelectorBackground};
  border: 0.1rem solid ${({ theme }) => theme.colorV2.baseEditorEmojiSelectorBorder};
  box-shadow: 0.3rem 0.3rem 0.5rem ${({ theme }) => theme.colorV2.baseEditorEmojiSelectorBorder};
  display: none;
  flex-direction: row;
  flex-wrap: wrap;
  height: 18rem;
  overflow: auto;
  padding: 0.5rem;
  width: 19rem;
  left: -1rem;

  ${({ open }) => open && 'display: flex;'}
`;

const EmojiContainer = styled.div`
  cursor: pointer;
  font-size: 2.2rem;
  height: 2.4rem;
  margin: 0.25rem;
  width: 2.4rem;
`;

type EmojiSelectorProps = {
  className?: string;
  open: boolean;
  onSelect: (emoji: string) => void;
};

export const EmojiSelector = forwardRef<HTMLDivElement, EmojiSelectorProps>(({ className, open, onSelect }, ref) => {
  return (
    <EmojiSelectorWrapper className={className} open={open} ref={ref}>
      {open &&
        Array.from(emojiSet).map((emoji) => (
          <EmojiContainer key={`emoji-${emoji}`} onClick={() => onSelect(emoji)}>
            {emoji}
          </EmojiContainer>
        ))}
    </EmojiSelectorWrapper>
  );
});
