import styled from 'styled-components';

import { Logo } from '@features/app/logo';
import { useIsAdmin } from '@features/auth/roles';
import { HeaderSearch, SearchModal } from '@features/search';
import { Panel, useDialogModal } from '@features/modal';

import { CourseLanguagesPanel } from '@components/CourseLanguagesPanel/CourseLanguagesPanel';
import { ProfileImage } from '@components/RolesAndPermissions/ProfileImage/ProfileImage';
import { NewCourseButton } from './NewCourseButton';
import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';

// @TODO This is a fix for an incorrect styles of ProfileImage, margins should not be set inside of shared components, but outside
const StyledProfileImage = styled(ProfileImage)`
  margin-left: 0;
`;

const HeaderWrapper = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2.4rem;

  margin: 4rem 4rem 7rem;
`;

export const DashboardHeader = () => {
  const isAdmin = useIsAdmin();

  const [courseToRedirect, setCourseToRedirect] = useState<string | null>(null);
  const { open: openNewCourseModal, modal: newCourseModal } = useDialogModal((modalControls) => (
    <Panel {...modalControls} lockScroll onClickOutside={modalControls.close}>
      <CourseLanguagesPanel close={modalControls.close} onUpdated={(courseId) => setCourseToRedirect(courseId)} />
    </Panel>
  ));

  if (courseToRedirect !== null) {
    return <Redirect to={`/course/${courseToRedirect}`} />;
  }

  return (
    <HeaderWrapper>
      {newCourseModal}
      <SearchModal />
      <Logo size="large" withLink={false} />
      <HeaderSearch />
      {isAdmin && <NewCourseButton onClick={openNewCourseModal} />}
      <StyledProfileImage />
    </HeaderWrapper>
  );
};
