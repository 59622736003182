import { ContentTypes } from '@common/enums/ContentTypes';
import { ExerciseTypes } from '@common/enums/ExerciseTypes';
import { DBId } from '@common/types/DBId';
import { ACTIVITIES, type ActivityType } from '@features/content/activities';
import { NavigationItemType } from '@features/content/navigation';
import { getRealId } from '@helpers/reuseHelper';

/**
 * Returns real ids array from a ids array potentially containing reused content ids
 */
const sanitizePathIds = (pathIds: (DBId | null | undefined)[]) => pathIds.map((id) => (id ? getRealId(id) : null));

export const getCurrentPathIds = (content: NavigationItemType, structure: NavigationItemType[]) => {
  const courseId = structure.find((element) => element.type === ContentTypes.course)?.id;

  switch (content.type) {
    case ContentTypes.course:
      return [courseId];
    case ContentTypes.level:
      return [courseId, content.id];
    case ContentTypes.chapter:
      return [courseId, content.parentId, content.id];
    case ContentTypes.lesson:
    case ContentTypes.checkpoint:
    case ContentTypes.certificate:
    case ContentTypes.liveLesson:
    case ContentTypes.review:
    case ContentTypes.speakingLesson:
      const levelId = structure?.find((item) => item.id === content.parentId)?.parentId;

      return sanitizePathIds([courseId, levelId, content.parentId, content.id]);

    default:
      if (ACTIVITIES.includes(content.type as ActivityType) && content.hasOwnProperty('oldType')) {
        const chapterId = structure?.find((item) => item.id === content.parentId)?.parentId;
        const levelId = structure?.find((item) => item.id === chapterId)?.parentId;

        return sanitizePathIds([courseId, levelId, chapterId, content.parentId, content.id]);
      }

      if (
        Object.values(ExerciseTypes).includes(content.type as any) &&
        content.type !== ContentTypes.slidePptx &&
        content.type !== ContentTypes.slidePdf
      ) {
        const lessonId = structure?.find((item) => item.id === content.parentId)?.parentId;
        const chapterId = structure?.find((item) => item.id === lessonId)?.parentId;
        const levelId = structure?.find((item) => item.id === chapterId)?.parentId;

        return sanitizePathIds([courseId, levelId, chapterId, lessonId, content.parentId, content.id]);
      } else if (
        Object.values(ExerciseTypes).includes(content.type as any) &&
        (content.type === ContentTypes.slidePptx || content.type === ContentTypes.slidePdf)
      ) {
        const chapterId = structure?.find((item) => item.id === content.parentId)?.parentId;
        const levelId = structure?.find((item) => item.id === chapterId)?.parentId;

        return sanitizePathIds([courseId, levelId, chapterId, content.parentId, content.id]);
      } else {
        return sanitizePathIds([courseId, content.id]);
      }
  }
};
