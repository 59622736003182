import styled from 'styled-components';

import { Loader } from '@features/theme';

const PreloaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.75);
  height: 100vh;
  width: 100vw;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 13000;
`;

export const PreloaderFullscreen = () => (
  <PreloaderWrapper>
    <Loader size="L" />
  </PreloaderWrapper>
);
