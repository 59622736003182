import styled, { css } from 'styled-components/macro';

const QuantityHandlerContainer = styled.div`
  ${({ theme }) => css`
    align-items: center;
    background-color: ${theme.colorV2.uiBackgroundPrimary};
    border: solid 0.1rem ${theme.colorV2.uiDivider};
    border-radius: 4.8rem;
    color: ${theme.colorV2.textPrimary};
    display: flex;
    font-size: 1.6rem;
    height: 4rem;
    justify-content: space-between;
    line-height: 2rem;
    padding: 0.3rem;
    width: 10.6rem;
  `}
`;

const QuantityHandlerButton = styled.button`
  ${({ theme }) => css`
    all: unset;
    background-color: ${theme.colorV2.uiBackgroundSecondary};
    border-radius: 50%;
    font-size: 1.4rem;
    height: 3.2rem;
    text-align: center;
    width: 3.2rem;

    &:hover {
      cursor: pointer;
    }

    &[disabled] {
      color: ${theme.colorV2.textQuaternary};

      &:hover {
        cursor: not-allowed;
      }
    }
  `}
`;

export type QuantityHandlerActionType = 'decrease' | 'increase';

type QuantityHandlerProps = {
  className?: string;
  minValue?: number;
  maxValue?: number;
  value: number;
  onChange: (action: QuantityHandlerActionType) => void;
};

export const QuantityHandler = ({ className, minValue, maxValue, value, onChange }: QuantityHandlerProps) => (
  <QuantityHandlerContainer className={className} data-testid="quantityHandler">
    <QuantityHandlerButton disabled={value === minValue} onClick={() => onChange('decrease')}>
      -
    </QuantityHandlerButton>
    <span>{value}</span>
    <QuantityHandlerButton disabled={value === maxValue} onClick={() => onChange('increase')}>
      +
    </QuantityHandlerButton>
  </QuantityHandlerContainer>
);
