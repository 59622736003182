import { ExerciseService } from '@common/types/ExerciseService';
import ExerciseDataModelAssembler from '@components/Exercises/ExerciseDataModelAssembler';
import { ExerciseCommonActionCreators as ExerciseCommonActions } from '@actionCreators/ExerciseCommonActionCreator';
import { FormikValuesInterface } from '@helpers/formikInitialValuesHelper';
import PhraseBuilderExerciseInterface from '@components/Exercises/PhraseBuilder/interfaces/PhraseBuilderExerciseInterface';
import ExercisesService from '@services/ExercisesService';
import { AppDispatch } from '@redux/store';
import { clone } from '@helpers/clone';
import { editPhraseBuilderExercise } from '@services/exercises/editExerciseService';
import { EditPhraseBuilderExerciseRequest, DisplayedLanguageType } from '@services/exercises/editExerciseTypes';
import { addToast } from '@features/app/toast';

type PhraseBuilderExerciseServiceType = ExerciseService<PhraseBuilderExerciseInterface>;

const PhraseBuilderExerciseService: PhraseBuilderExerciseServiceType = {
  ensureExerciseFieldsAreReadyForUse(
    exerciseAndEmptyLocalizationBranchesPayload: any,
    exercise: PhraseBuilderExerciseInterface,
  ) {
    let output = clone(exerciseAndEmptyLocalizationBranchesPayload);

    return ExerciseDataModelAssembler.ensureFieldIsReadyForUse(
      exercise,
      output,
      ['instructions', 'mainBundle', 'feedback'],
      exerciseAndEmptyLocalizationBranchesPayload,
    );
  },
  async save(dispatch: AppDispatch, exercise: PhraseBuilderExerciseInterface, values: FormikValuesInterface) {
    try {
      let payload: EditPhraseBuilderExerciseRequest = {
        instructionsLanguage: exercise.content.instructionsLanguage as DisplayedLanguageType,
        recapExerciseId: exercise.content.recapExerciseId,
        displayAudio: exercise.content.displayAudio,
        experiment: exercise.content.experiment,
      };

      const updatePayload = await Promise.all([
        (values.instructionsChanged || exercise.content.instructions.changed) && !exercise.content.instructions.isReused
          ? ExercisesService.misc.saveField(dispatch, 'instructions', exercise, payload)
          : { instructions: exercise.content.instructions._id },
        (values.feedbackChanged || exercise.content.feedback?.changed || exercise.content.feedbackChanged) &&
        !exercise.content.feedback?.isReused
          ? ExercisesService.misc.saveField(dispatch, 'feedback', exercise, payload)
          : { feedback: exercise.content.feedback?._id || null },
        values.mainBundle_phraseChanged ||
        exercise.content?.mainBundle?.isVocabularyChanged ||
        exercise.content?.mainBundle?.phrase?.changed ||
        exercise.content?.mainBundle?.phrase?.isReused
          ? ExercisesService.misc.saveBundle(dispatch, 'mainBundle', exercise, payload, values)
          : null,
      ]);

      payload = {
        ...payload,
        ...updatePayload.reduce((sum: any, item: any) => ({ ...sum, ...item }), {}),
      };

      if (exercise.content.id) {
        await editPhraseBuilderExercise(exercise.content.id, payload);

        dispatch(ExerciseCommonActions.setSaveProgress({ value: false, updateData: true }));

        addToast({
          type: 'success',
          title: 'This exercise has been saved',
        });
      }
    } catch (e: any) {
      if (e.response?.status === 400) {
        addToast({
          type: 'error',
          title: `${e.response.data?.detail}`,
        });
      }

      throw new Error(e as string);
    }
  },
};

export default PhraseBuilderExerciseService;
