import { useLayoutEffect, useMemo, useRef } from 'react';
import styled from 'styled-components/macro';

export type BasePopoverPosition = 'bottom' | 'bottom-right' | 'left';

export type BasePopoverProps = {
  className?: string;
  children: React.ReactNode;
  isRelativeToParent?: boolean;
  position?: BasePopoverPosition;
  show: boolean;
  triggerDOMNode: HTMLElement;
  onHide: () => void;
};

type PositioningDataType = {
  left: string;
  right: string;
  top: string;
};

const DEFAULT_POSITION = 'left';
const DISTANCE_GAP = 0.8; // rem
const REM_BASE = 10; // px

const BasePopoverWrapper = styled.div<{ positioningData: PositioningDataType }>`
  background-color: ${({ theme }) => theme.colorV2.uiBackgroundSecondary};
  border-radius: 0.8rem;
  overflow: scroll;
  box-shadow: 0 0.2rem 0.4rem 0 rgba(0, 0, 0, 0.1);
  padding: 2.4rem;
  position: absolute;
  width: 36rem;
  cursor: default;
  text-align: initial;
  white-space: normal;
  z-index: ${({ theme }) => theme.zIndex.basePopover};

  ${({ positioningData }) => {
    if (!positioningData) return '';

    return `
      left: ${positioningData.left};
      right: ${positioningData.right};
      top: ${positioningData.top};
    `;
  }}
`;

export const BasePopover = ({
  className,
  children,
  isRelativeToParent = false,
  position = DEFAULT_POSITION,
  show,
  triggerDOMNode,
  onHide,
}: BasePopoverProps) => {
  const positioningData = useMemo(() => {
    let positioningDataValue: PositioningDataType = {
      left: '0',
      top: '0',
      right: 'auto',
    };

    if (!triggerDOMNode) return positioningDataValue;

    if (isRelativeToParent) {
      const { offsetHeight, offsetWidth } = triggerDOMNode;

      if (position === 'left') {
        positioningDataValue = {
          ...positioningDataValue,
          left: `${offsetWidth / REM_BASE + DISTANCE_GAP}rem`,
        };
      } else if (position === 'bottom') {
        positioningDataValue = {
          ...positioningDataValue,
          top: `${offsetHeight / REM_BASE + DISTANCE_GAP * 2}rem`,
        };
      } else if (position === 'bottom-right') {
        positioningDataValue = {
          left: 'auto',
          right: `${DISTANCE_GAP * 2}rem`,
          top: `${offsetHeight / REM_BASE + DISTANCE_GAP * 2}rem`,
        };
      }
    } else {
      const { bottom, right, top } = triggerDOMNode?.getBoundingClientRect();

      if (position === 'left') {
        positioningDataValue = {
          ...positioningDataValue,
          left: `${right / REM_BASE + DISTANCE_GAP}rem`,
          top: `${top / REM_BASE}rem`,
        };
      } else if (position === 'bottom') {
        positioningDataValue = {
          ...positioningDataValue,
          top: `${bottom / REM_BASE + DISTANCE_GAP * 2}rem`,
        };
      } else if (position === 'bottom-right') {
        positioningDataValue = {
          left: 'auto',
          right: `${DISTANCE_GAP * 2}rem`,
          top: `${bottom / REM_BASE + DISTANCE_GAP * 2}rem`,
        };
      }
    }

    return positioningDataValue;
  }, [isRelativeToParent, triggerDOMNode, position]);

  const popoverRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const listenerPopoverClick = (event: any) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target) && show) {
        onHide();
      }
    };

    document.addEventListener('click', listenerPopoverClick);

    return () => {
      document.removeEventListener('click', listenerPopoverClick);
    };
  }, [popoverRef, show, onHide]);

  if (!show) return null;

  return (
    <BasePopoverWrapper
      className={className}
      positioningData={positioningData}
      ref={popoverRef}
      role="complementary"
      onClick={(evt: React.MouseEvent<HTMLDivElement>) => {
        evt.stopPropagation();
        evt.preventDefault();
      }}
    >
      {children}
    </BasePopoverWrapper>
  );
};
