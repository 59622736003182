import { useState } from 'react';

import { ValidationErrorInterface } from '@common/interfaces/validation/ValidationInterface';
import { ValidationErrorDisplayer } from '@components/ValidationErrorDisplayer';
import helpersService from '@services/HelpersService';

import { IconsPanel } from './IconsPanel';
import { SelectedIcon } from './SelectedIcon';
import { SelectedIconAndValidationErrorContainer } from './styles';

export const IconsSelector = ({ errors }: { errors: ValidationErrorInterface[] }) => {
  const [isPanelOpened, setIsPanelOpened] = useState(false);

  return (
    <>
      <SelectedIconAndValidationErrorContainer>
        <SelectedIcon openPanel={() => setIsPanelOpened(true)} />
        <ValidationErrorDisplayer text={helpersService.getValidationErrorMessageText(errors)} />
      </SelectedIconAndValidationErrorContainer>
      {isPanelOpened && <IconsPanel closePanel={() => setIsPanelOpened(false)} />}
    </>
  );
};
