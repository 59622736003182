import { LessonInterface } from '@common/interfaces/contentTypes/LessonInterface';
import { useIsEditorHidden } from '@helpers/useHideEditor';
import BidimensionalEditor from '@components/BidimensionalEditor/BidimensionalEditor';
import { constants as contentConstants } from '@features/content';
import { BidimensionalEditorModes } from '@components/BidimensionalEditor/enums/BidimensionalEditorModes';
import { ValidationErrorInterface } from '@common/interfaces/validation/ValidationInterface';
import { TranslationsPanelModes } from '@components/TranslationsPanel/enums/TranslationsPanelModes';
import { ContentType } from '@common/enums/ContentTypes';
import TranslationsTipV2 from '@components/TranslationTipV2/TranslationTipV2';
import { ContentBranches } from '@common/types/AnyExerciseContentBranch';

import './CompleteMessage.scss';

type CompleteMessageProps = {
  loadedContent: LessonInterface;
  getDefaultContextForTranslators: (focus: string, visitedBranch: ContentBranches) => string | undefined;
};

export const CompleteMessage = ({ loadedContent, getDefaultContextForTranslators }: CompleteMessageProps) => {
  const { isEditorHidden } = useIsEditorHidden({ visitedBranch: 'completeMessage' });

  const renderEditor = () => (
    <TranslationsTipV2
      content={loadedContent}
      defaultContextForTranslators={getDefaultContextForTranslators(loadedContent?.focus, 'completeMessage')}
      errors={loadedContent.validation.errors.filter(
        (error: ValidationErrorInterface) => error.field === 'completeMessage',
      )}
      showLanguageSwitcher={false}
      translationsPanelTranslationsMode={TranslationsPanelModes.wysiwyg}
      type={ContentType.levelOrLesson}
      visitedBranch="completeMessage"
    >
      <BidimensionalEditor
        mode={BidimensionalEditorModes.mono}
        storeBranch="completeMessage"
        isLesson={true}
        language={contentConstants.DEFAULT_LANGUAGE}
        maxColums={1}
        dataSourceMode={'editorState'}
        ignoreLanguageForTips={true}
        toolbar={{
          options: ['inline', 'emoji'],
          inline: {
            options: ['bold', 'italic'],
          },
        }}
        charactersLimit={120}
      />
    </TranslationsTipV2>
  );

  return (
    <div className="complete-message">
      <div className="complete-message__caption">Completion message</div>
      {isEditorHidden ? <div>{renderEditor()}</div> : <>{renderEditor()}</>}
    </div>
  );
};
