export const DEFAULTS = {
  'how-to': {
    DESCRIPTION: 'How-to articles give advice on how to enter content.',
    TITLE: 'How to...',
  },
  guideline: {
    DESCRIPTION: 'Guidelines are rules that must be followed.',
    TITLE: 'Guideline',
  },
};
