import { Navigation } from './Navigation';
import { CourseButton } from './CourseButton';
import { Logo } from '@features/app/logo';
import {
  NavigationPanelFooter,
  NavigationPanelContainer,
  LogoAndNavigationContainer,
} from '@features/content/navigation';

export const NavigationPanel = () => (
  <NavigationPanelContainer>
    <LogoAndNavigationContainer>
      <Logo size="small" className="course-edition__logo" withLink />
      <CourseButton />
      <Navigation />
    </LogoAndNavigationContainer>
    <NavigationPanelFooter />
  </NavigationPanelContainer>
);
