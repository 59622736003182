import { useEffect, useState } from 'react';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import { MultiValue } from 'react-select';
import styled from 'styled-components/macro';

import { CommonFilterOptionType, LanguageSelector } from '@components/SelectorCommonComponents';
import { WritableInputText } from '@components/WritableInputText';
import { Language } from '@features/content/languages';
import { Button } from '@features/theme';

import { sendCreateVoiceArtist } from '../_service';
import { GenderType } from '../types';
import { GenderSelector } from './GenderSelector';
import { useToast } from '@features/app/toast';

const StyledModal = styled(Modal)`
  .modal-content {
    border: none;
    border-radius: 1.6rem;
    box-shadow: 0 0.8rem 1.2rem rgba(0, 0, 0, 0.1);
    padding: 4rem 2.5rem;
    transition: height 0.3s ease-out;
    width: 100%;
  }

  .modal-header {
    border: none;
    display: flex;
    padding: 0;
    position: relative;

    .modal-title {
      font-size: 2rem;
      font-weight: 700;
      text-align: center;
      margin-bottom: 2.4rem;
      width: 100%;
    }

    .close {
      color: ${({ theme }) => theme.colorV2.textTertiary};
      font-size: 3.2rem;
      font-weight: normal;
      margin: 0;
      margin-top: 0;
      opacity: 1;
      padding: 0;
      padding-top: 0;
      position: absolute;
      right: 0;
      top: -2.4rem;
      z-index: 100;
    }
  }

  .modal-dialog {
    max-width: 80rem;
  }

  .modal-body {
    background-color: ${({ theme }) => theme.colorV2.uiBackgroundPrimary};
    padding: 0;
  }
`;

const StyledRow = styled(Row)`
  margin-bottom: 2.4rem;
`;

const Label = styled.label`
  color: ${({ theme }) => theme.colorV2.textTertiary};
  font-size: 1.4rem;
  font-weight: 700;
  margin-bottom: 0.4rem;
`;

const StyledLanguageSelector = styled(LanguageSelector)`
  .language-selector__control {
    heigh: 3.2rem !important;
  }

  .language-selector__value-container {
    height: 4.2rem !important;
  }
`;

const ActionsWrapper = styled.div`
  display: flex;
  gap: 1.6rem;
  justify-content: flex-end;
  padding-top: 1.6rem;
`;

export const NewVoiceArtistModal = ({ show, onHide }: { show: boolean; onHide: () => void }) => {
  const [accent, setAccent] = useState<string | undefined>();
  const [selectedGender, setSelectedGender] = useState<CommonFilterOptionType | null>(null);
  const [selectedLanguages, setSelectedLanguages] = useState<MultiValue<{ label: string; value: string }> | null>(null);
  const [name, setName] = useState<string | undefined>();
  const [sendButtonDisabled, setSendButtonDisabled] = useState<boolean>(true);

  const showToast = useToast();

  const resetFields = () => {
    setAccent(undefined);
    setSelectedGender(null);
    setSelectedLanguages(null);
    setName(undefined);
  };

  const handleCreateVoiceArtist = async () => {
    sendCreateVoiceArtist({
      accent: accent as string,
      gender: selectedGender?.value as GenderType,
      languages: selectedLanguages?.map(({ value }) => value) as Language[],
      name: name as string,
    })
      .then(() => {
        showToast({
          type: 'info',
          title: 'Voice artist created',
        });
      })
      .catch(() => {
        showToast({
          type: 'info',
          title: 'Voice artist creation failed',
        });
      })
      .finally(() => {
        onHide();
        resetFields();
      });
  };

  const handleCancel = () => {
    onHide();
    resetFields();
  };

  useEffect(() => {
    setSendButtonDisabled(!accent || !selectedGender || !selectedLanguages || !name);
  }, [accent, selectedGender, selectedLanguages, name]);

  return (
    <StyledModal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Create Voice Artist</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <StyledRow>
            <Col md={7}>
              <Label>Name</Label>
              <WritableInputText value={name} onChange={setName} />
            </Col>
            <Col>
              <Label>Gender</Label>
              <GenderSelector selectedGender={selectedGender} onChange={setSelectedGender} />
            </Col>
          </StyledRow>
          <StyledRow>
            <Col md={7}>
              <Label>Accent</Label>
              <WritableInputText value={accent} onChange={setAccent} />
            </Col>
            <Col>
              <Label>Languages</Label>
              <StyledLanguageSelector value={selectedLanguages} onChange={setSelectedLanguages} />,
            </Col>
          </StyledRow>
        </Container>
        <ActionsWrapper>
          <Button variant="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button disabled={sendButtonDisabled} onClick={handleCreateVoiceArtist}>
            Send
          </Button>
        </ActionsWrapper>
      </Modal.Body>
    </StyledModal>
  );
};
