import { FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';

import { GenericContentInterface } from '@common/interfaces/contentTypes/GenericContentInterface';
import { PublishingActionsCreator } from '@actionCreators/PublishingActionCreator';
import { ActivityInterface } from '@common/interfaces/contentTypes/ActivityInterface';
import { Loader } from '@features/theme';
import { PublishingButton, EmptyButton } from '@components/Publishing/cardPublish.styles';
import { useIsPublishAvailable } from '@features/auth/roles';
import { ContentTypes } from '@common/enums/ContentTypes';
import { DBId } from '@common/types/DBId';
import { ExerciseTypes } from '@common/enums/ExerciseTypes';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { selectIsPublishingInProgress } from '@selectors/CoursesSelectors';

interface PublishingCardButtonProps {
  content: GenericContentInterface | ActivityInterface;
  hovered: boolean;
}

export const PublishingCardButton: FunctionComponent<PublishingCardButtonProps> = ({
  content,
  hovered,
}: PublishingCardButtonProps) => {
  const dispatch = useAppDispatch();
  const { courseId, levelId, chapterId, lessonId, activityId } = useParams<{
    courseId: DBId;
    levelId: DBId;
    chapterId: DBId;
    lessonId: DBId;
    activityId: DBId;
  }>();
  const { id, ready, type, validation, changeStatus, lessonType } = content;
  const isPublishingInProgress = useAppSelector(selectIsPublishingInProgress);

  const { publish: isPublishAvailable, unpublish: isUnpublishAvailable } = useIsPublishAvailable();

  const isUpdateButtonVisible = isPublishAvailable && ready && validation.valid && changeStatus.hasNewChanges;
  const isUnplublishVisible = isUnpublishAvailable && ready;

  const isPublishButtonVisible =
    isPublishAvailable &&
    (ready || validation.valid || lessonType === ContentTypes.checkpoint || lessonType === ContentTypes.roleplayLesson);

  const getParentId = () => {
    if (type === ContentTypes.level) {
      return courseId;
    } else if (type === ContentTypes.chapter) {
      return levelId;
    } else if (type === ContentTypes.lesson) {
      return chapterId;
    } else if (type === ContentTypes.activity) {
      return lessonId;
    } else if (Object.values(ExerciseTypes).includes(type as any) || type === ContentTypes.exercise) {
      return activityId;
    }
  };

  return (
    <>
      {isUpdateButtonVisible ? (
        <PublishingButton
          variant="primary"
          hovered={hovered}
          size="S"
          onClick={(e: any) => {
            e.preventDefault();
            e.stopPropagation();
            dispatch(PublishingActionsCreator.setContentToPublish(id, type, ready, true, getParentId()));
          }}
        >
          {isPublishingInProgress ? <Loader size="S" /> : 'Update'}
        </PublishingButton>
      ) : (
        <EmptyButton />
      )}
      {isPublishButtonVisible ? (
        <PublishingButton
          variant={ready ? 'secondary' : 'primary'}
          hovered={hovered}
          size="S"
          onClick={(e: any) => {
            e.preventDefault();
            e.stopPropagation();
            dispatch(PublishingActionsCreator.setContentToPublish(id, type, ready, false, getParentId(), 'group'));
          }}
        >
          {isPublishingInProgress ? <Loader size="S" /> : ready ? 'Unpublish' : 'Publish'}
        </PublishingButton>
      ) : (
        <>
          {isUnplublishVisible ? (
            <PublishingButton
              variant="secondary"
              hovered={hovered}
              size="S"
              onClick={(e: any) => {
                e.preventDefault();
                e.stopPropagation();
                dispatch(PublishingActionsCreator.setContentToPublish(id, type, ready, false, getParentId(), 'group'));
              }}
            >
              {isPublishingInProgress ? <Loader size="S" /> : 'Unpublish'}
            </PublishingButton>
          ) : (
            <EmptyButton />
          )}
        </>
      )}
    </>
  );
};
