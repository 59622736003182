import styled from 'styled-components/macro';

import { Loader } from '@features/theme';
import { Role, ROLE_NAMES } from '@features/auth/roles';

import './TableItem.scss';

const RolesDescriptionTableRow = styled.tr`
  border-bottom: 0.1rem solid ${({ theme }) => theme.colorV2.uiDivider};
`;

const RolesDescriptionTableCell = styled.td<{ overview?: boolean }>`
  font-size: ${({ overview }) => (overview ? '1.2rem' : '1.6rem')};
  height: 5.4rem;
  padding: 1rem 0;
  width: ${({ overview }) => (overview ? '25%' : 'auto')};

  &:first-child,
  &:nth-child(3) {
    width: 15%;
  }
`;

const RolesDescriptionPermission = styled.span`
  background: ${({ theme }) => theme.colorV2.accentPrimary};
  border-radius: 0.4rem;
  font-size: 1rem;
  font-weight: 500;
  padding: 0.4rem;
  color: ${({ theme }) => theme.colorV2.textLight};
  margin: 0 0.6rem 0.6rem 0;
`;

const Permissions = ({ permissions }: { permissions: string[] }) => {
  return (
    <RolesDescriptionTableCell>
      {permissions.map((permission: string, index: number) => (
        <RolesDescriptionPermission key={index}>{permission}</RolesDescriptionPermission>
      ))}
    </RolesDescriptionTableCell>
  );
};

export const TableItem = ({
  role,
  permissions,
  count,
  overview,
  isRolesLoading,
}: {
  role: Role;
  permissions: string[];
  count: number;
  overview: string;
  isRolesLoading: boolean;
}) => {
  return (
    <RolesDescriptionTableRow>
      <RolesDescriptionTableCell>{ROLE_NAMES[role]}</RolesDescriptionTableCell>
      <Permissions permissions={permissions} />
      <RolesDescriptionTableCell>{isRolesLoading ? <Loader /> : count}</RolesDescriptionTableCell>
      <RolesDescriptionTableCell overview>{overview}</RolesDescriptionTableCell>
    </RolesDescriptionTableRow>
  );
};
