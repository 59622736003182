import { Modal } from 'react-bootstrap';
import styled from 'styled-components/macro';

import { DeviceType, PreviewModalProps } from '../PreviewModalProps';
import { ModalContainer, PreviewModalContent } from '../common';
import { usePreviewState } from '../usePreviewState';
import { useAppSelector } from '@redux/store';
import { selectIsPreviewOpened } from '@selectors/UiSelectors';

const ModalWrapper = styled(ModalContainer)<{ deviceType: DeviceType }>`
  .modal-content {
    overflow: overlay;
  }
`;

export const PreviewModal = ({ content, type }: PreviewModalProps) => {
  const { answerState, deviceType, setDeviceType, setAnswerState, onModalClose } = usePreviewState();

  const isPreviewOpened = useAppSelector(selectIsPreviewOpened);

  return (
    <ModalWrapper centered deviceType={deviceType} show={isPreviewOpened} onHide={onModalClose}>
      <Modal.Body>
        {isPreviewOpened && (
          <PreviewModalContent
            onModalClose={onModalClose}
            type={type}
            deviceType={deviceType}
            setDeviceType={setDeviceType}
            answerState={answerState}
            setAnswerState={setAnswerState}
            content={content}
            isForList={false}
          />
        )}
      </Modal.Body>
    </ModalWrapper>
  );
};
