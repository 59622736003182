import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';

export const CardWrapper = styled.div`
  display: flex;
  font-weight: 700;
`;

export const CardDataWrapper = styled(Link)<{ $active: boolean; $hovered: boolean; $isDisabled?: boolean }>`
  align-items: center;
  color: ${({ theme }) => theme.colorV2.placementTestCardText};
  display: flex;
  gap: 1.8rem;
  padding: 1rem;
  width: 100%;

  ${({ $isDisabled }) => $isDisabled && 'opacity: 0.5;'}

  &:hover {
    color: ${({ theme }) => theme.colorV2.placementTestCardText};
    text-decoration: none;
  }

  ${({ $hovered, theme }) =>
    $hovered &&
    `
    background: ${theme.colorV2.placementTestCardHoverBackground};
    box-shadow: 0 0.1rem 0 ${theme.colorV2.uiDivider}, inset 0 0.1rem 0 ${theme.colorV2.uiDivider};
    cursor: pointer;
  `}

  ${({ $active, theme }) =>
    $active &&
    `
    box-shadow: 0 0.1rem 0 ${theme.colorV2.uiDivider}, inset 0 0.1rem 0 ${theme.colorV2.uiDivider};
    background: ${theme.colorV2.placementTestCardActiveBackground};
  `}
`;

export const CardControllers = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

export const DataWrapper = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
`;

export const Data = styled.div`
  width: 100%;
  height: 100%;
  min-width: 40rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Title = styled.div`
  min-height: 2.5rem;
  display: flex;
  align-items: center;
  max-width: 90%;
  font-size: 1.6rem;
  font-weight: 700;
`;

export const Description = styled.div`
  color: ${({ theme }) => theme.colorV2.placementTestCardDescriptionText};
  font-size: 1.4rem;
  max-width: 45rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const ExercisePhraseContainer = styled.div`
  display: table;
  table-layout: fixed;
  width: 100%;
`;

export const ExercisePhrase = styled.div`
  color: ${({ theme }) => theme.colorV2.textSecondary};
  display: table-cell;
  font-size: 1.6rem;
  padding-right: 1.6rem;
  min-width: 37.2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  white-space: nowrap;
`;

export const ExerciseMedia = styled.div<{ hasImage: boolean }>`
  align-items: center;
  display: flex;
  gap: 0.8rem;
  width: 13.7rem;

  > * {
    align-items: center;
    display: flex;
    gap: 0.8rem;

    ::before {
      background-color: ${({ theme }) => theme.colorV2.textQuaternary};
      content: '';
      display: inline-block;
      height: 1rem;
      width: 0.1rem;
    }
  }

  > *:first-child::before {
    display: none;
  }

  ${({ hasImage }) =>
    !hasImage &&
    `
    > *:nth-child(2)::before {
      background-color: transparent;

    }
  `};
`;

export const ExerciseImagePlaceholder = styled.div`
  width: 5.5rem;
`;

export const ExerciseImage = styled.img`
  height: 4rem;
  width: 100%;
  border-radius: 0.8rem;
  border: 0.1rem solid rgba(0, 0, 0, 0.1);
  object-fit: cover;
`;

export const ScenarioImage = styled.img`
  border-radius: 3.2rem;
  border: solid 0.1rem rgba(0, 0, 0, 0.1);
  height: 6rem;
  margin-right: ${({ theme }) => theme.space.M};
  width: 6rem;
`;

export const ScenarioImagePlaceholder = styled.div`
  ${({ theme }) => css`
    align-items: center;
    background-color: ${theme.colorV2.uiBackgroundSecondary};
    border-radius: 3.2rem;
    display: flex;
    height: 6rem;
    justify-content: center;
    margin-right: ${theme.space.M};
    min-width: 6rem;
    width: 6rem;
  `}
`;
