import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FormikContextType, useFormikContext } from 'formik';

import helpersService from '@services/HelpersService';
import { FormikValuesInterface } from '@helpers/formikInitialValuesHelper';
import { ContentTypesActionsCreator } from '@actionCreators/ContentTypesActionsCreator';
import { saveContent } from '@components/CourseEdition/saveContent';
import { AnyExerciseContentInterface } from '@common/types/exercises/AnyExerciseContentInterface';
import { ContentTypes, type ContentTypesType } from '@common/enums/ContentTypes';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { DBId } from '@common/types/DBId';
import { EntrypointListItemType, PlacementTestContentType } from './PlacementTest';
import { ExerciseListItemType } from '@components/ContentTypes/ExerciseCard/types';
import type { LoadingStageType } from '@common/enums/LoadingStage';
import { useOpenSearchShortcut } from '@features/search';
import { selectCourse, selectIsSaveButtonEnabled, selectLoadedExercise } from '@selectors/CoursesSelectors';
import {
  selectPlacementTest,
  selectPlacementTestEntrypoint,
  selectPlacementTestEntrypointExercises,
  selectPlacementTestEntrypoints,
  selectPlacementTestExercise,
} from '@selectors/PlacementTestSelectors';

// Return current placement test content (type, children array and save function)
export const useCurrentPlacementContent = () => {
  const formikContext: FormikContextType<FormikValuesInterface> = useFormikContext();
  const dispatch = useAppDispatch();
  const { courseId, entrypointId, exerciseId } = useParams<{
    courseId: DBId;
    entrypointId: DBId;
    exerciseId: DBId;
  }>();

  const isSaveButtonEnabled = useAppSelector(selectIsSaveButtonEnabled);
  const currentCourse = useAppSelector(selectCourse);
  const placementTest = useAppSelector(selectPlacementTest);
  const entrypoint = useAppSelector(selectPlacementTestEntrypoint);
  const exercises = useAppSelector(selectPlacementTestEntrypointExercises);
  const placementExercise = useAppSelector(selectPlacementTestExercise);
  const entrypoints = useAppSelector(selectPlacementTestEntrypoints);
  const exercise = useAppSelector(selectLoadedExercise);

  let placementType: typeof ContentTypes.placementTest | typeof ContentTypes.entrypoint | typeof ContentTypes.exercise =
    ContentTypes.placementTest;
  let childrenArray: (EntrypointListItemType | ExerciseListItemType)[] = entrypoints;
  let placementContent: PlacementTestContentType | AnyExerciseContentInterface = placementTest.content;
  let isPlacementContentLoaded: LoadingStageType = placementTest.loaded;
  let parentId = courseId;
  let onSave = () => {};

  if (entrypointId && exerciseId) {
    placementType = ContentTypes.exercise;
    childrenArray = [];
    placementContent = placementExercise.content;
    isPlacementContentLoaded = placementExercise.loaded;
    parentId = entrypointId;
    onSave = () => {
      saveContent(dispatch, exercise, currentCourse.learningLanguage, formikContext?.values);
      formikContext?.resetForm({ values: formikContext?.values });
      dispatch(ContentTypesActionsCreator.setSaveButtonState(false));
    };
  } else if (entrypointId && !exerciseId) {
    placementType = ContentTypes.entrypoint;
    childrenArray = exercises;
    placementContent = entrypoint.content;
    isPlacementContentLoaded = entrypoint.loaded;
    parentId = courseId;
    onSave = () => {
      // @TODO: add save entrypoint function
      console.log('Save entrypoint');
    };
  }

  useOpenSearchShortcut();

  // Update save button availability on content change
  useEffect(() => {
    const isPlacementTestChanged = false;
    const isPlacementTestNotChanged = false;

    const isExerciseChanged =
      entrypointId &&
      exerciseId &&
      (formikContext?.dirty ||
        helpersService.checkIsExerciseChanged(
          exercise?.exercise?.content,
          exercise?.exercise?.type as unknown as ContentTypesType,
        ));
    const isExerciseNotChanged =
      entrypointId &&
      exerciseId &&
      !formikContext?.dirty &&
      !helpersService.checkIsExerciseChanged(
        exercise?.exercise?.content,
        exercise?.exercise?.type as unknown as ContentTypesType,
      );

    const isContentChanged = isPlacementTestChanged || isExerciseChanged;
    const isContentNotChanged = isPlacementTestNotChanged || isExerciseNotChanged;

    if (isContentChanged && !isSaveButtonEnabled) {
      dispatch(ContentTypesActionsCreator.setSaveButtonState(true));
    } else if (isContentNotChanged && isSaveButtonEnabled) {
      dispatch(ContentTypesActionsCreator.setSaveButtonState(false));
    }
  }, [formikContext, isSaveButtonEnabled, entrypointId, exerciseId, exercise, dispatch]);

  return { placementContent, isPlacementContentLoaded, parentId, placementType, childrenArray, onSave };
};
