import { ExerciseTypes, type ExerciseTypesType } from '@common/enums/ExerciseTypes';
import {
  LoadedComprehensionExerciseInterface,
  LoadedConversationExerciseInterface,
  LoadedDialogueExerciseInterface,
  LoadedFillGapExerciseInterface,
  LoadedFlashCardExerciseInterface,
  LoadedHighlighterExerciseInterface,
  LoadedMatchUpExerciseInterface,
  LoadedMultipleChoiceExerciseInterface,
  LoadedSlidePptxExerciseInterface,
  LoadedSpeechRecognitionExerciseInterface,
  LoadedSpellingExerciseInterface,
  LoadedSlidePdfExerciseInterface,
  LoadedTipExerciseInterface,
  LoadedTrueFalseExerciseInterface,
  LoadedTypingExerciseInterface,
  LoadedListenRepeatExerciseInterface,
} from '@common/interfaces/exercises/LoadedExerciseInterface';
import { AnyLoadedExercise } from '@common/types/exercises/AnyLoadedExercise';
import { ComponentOtherPlacesDisplayer } from '@components/ComponentOtherPlacesDisplayer';
import ComprehensionExercise from '@components/Exercises/Comprehension/ComprehensionExercise';
import ConversationExercise from '@components/Exercises/Conversation/ConversationExercise';
import DialogueExercise from '@components/Exercises/Dialogue/DialogueExercise';
import FillGapExercise from '@components/Exercises/FillGap/FillGapExercise';
import Flashcard from '@components/Exercises/Flashcard/FlashcardExercise';
import { HighlighterExercise } from '@components/Exercises/Highlighter/HighlighterExercise';
import { ListenRepeatExercise } from '@components/Exercises/ListenRepeat/ListenRepeatExercise';
import MatchUpExercise from '@components/Exercises/MatchUp/MatchUpExercise';
import MultipleChoiceExercise from '@components/Exercises/MultipleChoice/MultipleChoiceExercise';
import { PhraseBuilderExercise } from '@components/Exercises/PhraseBuilder';
import SlidePptxExercise from '@components/Exercises/SlidePptx/SlidePptxExercise';
import SpeechRecognitionExercise from '@components/Exercises/SpeechRecognition/SpeechRecognitionExercise';
import SpellingExercise from '@components/Exercises/Spelling/SpellingExercise';
import SlidePdfExercise from '@components/Exercises/SlidePdf/SlidePdfExercise';
import TipExercise from '@components/Exercises/Tip/TipExercise';
import TrueFalseExercise from '@components/Exercises/TrueFalse/TrueFalseExercise';
import TypingExercise from '@components/Exercises/Typing/TypingExercise';
import IDDisplayer from '@components/IDDisplayer/IDDisplayer';
import { PublishingStatus } from '@components/Publishing/PublishingStatus';
import ExercisesService from '@services/ExercisesService';

import ExerciseLoaderProps from './ExerciseLoaderProps';

const ExerciseLoader = ({ loadedExercise }: ExerciseLoaderProps) => {
  let { type: exerciseType }: { type: ExerciseTypesType } = loadedExercise?.exercise;

  function renderExercise(loadedExercise: AnyLoadedExercise, type: ExerciseTypesType) {
    switch (type) {
      case ExerciseTypes.flashcard: {
        return <Flashcard exercise={loadedExercise as LoadedFlashCardExerciseInterface} />;
      }

      case ExerciseTypes.tip: {
        return <TipExercise exercise={loadedExercise as LoadedTipExerciseInterface} />;
      }

      case ExerciseTypes.trueFalse: {
        return <TrueFalseExercise exercise={loadedExercise as LoadedTrueFalseExerciseInterface} />;
      }

      case ExerciseTypes.multipleChoice: {
        return <MultipleChoiceExercise exercise={loadedExercise as LoadedMultipleChoiceExerciseInterface} />;
      }

      case ExerciseTypes.phraseBuilder: {
        return <PhraseBuilderExercise />;
      }

      case ExerciseTypes.dialogue: {
        return <DialogueExercise exercise={loadedExercise as LoadedDialogueExerciseInterface} />;
      }

      case ExerciseTypes.conversation: {
        return <ConversationExercise exercise={loadedExercise as LoadedConversationExerciseInterface} />;
      }

      case ExerciseTypes.speechRecognition: {
        return <SpeechRecognitionExercise exercise={loadedExercise as LoadedSpeechRecognitionExerciseInterface} />;
      }

      case ExerciseTypes.spelling: {
        return <SpellingExercise exercise={loadedExercise as LoadedSpellingExerciseInterface} />;
      }

      case ExerciseTypes.matchup: {
        return <MatchUpExercise exercise={loadedExercise as LoadedMatchUpExerciseInterface} />;
      }

      case ExerciseTypes.highlighter: {
        return <HighlighterExercise exercise={loadedExercise as LoadedHighlighterExerciseInterface} />;
      }

      case ExerciseTypes.typing: {
        return <TypingExercise exercise={loadedExercise as LoadedTypingExerciseInterface} />;
      }

      case ExerciseTypes.fillgap: {
        return <FillGapExercise exercise={loadedExercise as LoadedFillGapExerciseInterface} />;
      }

      case ExerciseTypes.comprehension: {
        return <ComprehensionExercise exercise={loadedExercise as LoadedComprehensionExerciseInterface} />;
      }

      case ExerciseTypes.slidePdf: {
        return <SlidePdfExercise exercise={loadedExercise as LoadedSlidePdfExerciseInterface} />;
      }

      case ExerciseTypes.slidePptx: {
        return <SlidePptxExercise exercise={loadedExercise as LoadedSlidePptxExerciseInterface} />;
      }

      case ExerciseTypes.listenRepeat: {
        return <ListenRepeatExercise exercise={loadedExercise as LoadedListenRepeatExerciseInterface} />;
      }
    }
  }

  return (
    <div id={`exercise-${loadedExercise.exercise.content.id}`} className="exercise-loader">
      <div className="course-edition__publish-status-and-other-places-container">
        <PublishingStatus
          ready={loadedExercise.exercise.content.ready}
          changeStatus={loadedExercise.exercise.content.changeStatus}
        />
        <ComponentOtherPlacesDisplayer
          mappings={loadedExercise.exercise?.content?.mappings}
          mappingsPath={loadedExercise.exercise?.content?.mappingsPath}
        />
      </div>
      <IDDisplayer id={ExercisesService.misc.getExerciseId(loadedExercise?.exercise)} />
      {renderExercise(loadedExercise, exerciseType)}
    </div>
  );
};

export default ExerciseLoader;
