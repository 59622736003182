import styled from 'styled-components/macro';

import type { DisplayContentToUserModeType } from '@common/enums/DisplayContentToUserMode';
import { TrueFalseExerciseActionsCreator } from '@actionCreators/TrueFalseExerciseActionsCreator';
import { UserLanguageCheckbox } from '@components/Exercises/Common/UserLanguageCheckbox';
import { ValidationErrorInterface } from '@common/interfaces/validation/ValidationInterface';
import TranslationsTipV2 from '@components/TranslationTipV2/TranslationTipV2';
import { TranslationsPanelModes } from '@components/TranslationsPanel/enums/TranslationsPanelModes';
import { TrueFalseAnswerModes, type TrueFalseAnswerModesType } from './index';
import { useAppDispatch } from '@redux/store';
import BidimensionalEditor from '@components/BidimensionalEditor/BidimensionalEditor';
import { BidimensionalEditorModes } from '@components/BidimensionalEditor/enums/BidimensionalEditorModes';
import { LockContent } from '@features/content/exercises';
import { constants as contentConstants } from '@features/content';

const { DEFAULT_LANGUAGE } = contentConstants;

const TrueFalseStatementWrapper = styled.div`
  overflow: hidden;
  position: relative;
  padding-bottom: 45px;
`;

const TrueFalseStatementButtons = styled.div`
  background: ${({ theme }) => theme.color.brandWhite};
  position: absolute;
  right: 0;
  top: 4.5rem;
  z-index: 10;
`;

const TrueFalseStatementButton = styled.button<{ isAnswerErrorsShown: boolean; isSelected: boolean }>`
  font-weight: 700;
  background: ${({ theme }) => theme.color.brandWhite};
  border: 1px solid ${({ theme }) => theme.color.brandGreyscale600};
  ${({ isAnswerErrorsShown, theme }) => isAnswerErrorsShown && `border-color: ${theme.color.secondaryBase};`}
  color: #33252b2f;
  font-size: 1.4rem;
  height: 4.2rem;
  transition: color 0.5s;
  width: 8rem;

  &:hover,
  &:active,
  &:focus {
    cursor: pointer;
    color: ${({ theme }) => theme.colorV2.uiDarkest};
  }
`;

const TrueStatementButton = styled(TrueFalseStatementButton)`
  ${({ isSelected, theme }) =>
    isSelected &&
    `
      background-color: ${theme.color.primaryLighter};
      color: ${theme.color.brandBlack};
    `}
`;

const FalseStatementButton = styled(TrueFalseStatementButton)`
  border-bottom-right-radius: 8px;
  border-left: 0;
  position: relative;

  ${({ isSelected, theme }) =>
    isSelected &&
    `
      background-color: ${theme.color.secondaryBase};
      color: ${theme.color.brandWhite} !important;
    `}
`;

interface TrueFalseStatementViewProps {
  isInputErrorsShown: boolean;
  isAnswerErrorsShown: boolean;
  onButtonClick: (newState: TrueFalseAnswerModesType) => void;
  selectedButton: TrueFalseAnswerModesType | boolean | null;
  value: string | undefined;
  inputErrors: ValidationErrorInterface[];
  language: DisplayContentToUserModeType;
}

export const TrueFalseStatementView = ({
  isInputErrorsShown,
  isAnswerErrorsShown,
  onButtonClick,
  selectedButton,
  value,
  inputErrors,
  language,
}: TrueFalseStatementViewProps) => {
  const dispatch = useAppDispatch();

  return (
    <TrueFalseStatementWrapper>
      <TranslationsTipV2
        visitedBranch={'statement'}
        errors={inputErrors}
        showErrorDisplayer={false}
        forTrueFalse={true}
        translationsPanelTranslationsMode={TranslationsPanelModes.wysiwyg}
      >
        <BidimensionalEditor
          dataSourceMode="defaultEditorState"
          ignoreLanguageForTips
          maxColums={1}
          mode={BidimensionalEditorModes.mono}
          language={DEFAULT_LANGUAGE}
          singleLine
          storeBranch="statement"
          toolbar={{
            options: ['inline'],
            inline: {
              options: ['bold'],
            },
          }}
          toolbarCustomButtons={[<LockContent />]}
        />
      </TranslationsTipV2>

      <TrueFalseStatementButtons>
        <TrueStatementButton
          isAnswerErrorsShown={isAnswerErrorsShown}
          isSelected={`${selectedButton}` === TrueFalseAnswerModes.true}
          onClick={() => onButtonClick(TrueFalseAnswerModes.true)}
        >
          True
        </TrueStatementButton>
        <FalseStatementButton
          isAnswerErrorsShown={isAnswerErrorsShown}
          isSelected={`${selectedButton}` === TrueFalseAnswerModes.false}
          onClick={() => onButtonClick(TrueFalseAnswerModes.false)}
        >
          False
        </FalseStatementButton>
      </TrueFalseStatementButtons>

      <UserLanguageCheckbox
        checkedOverride={true}
        checked={false}
        disabled={true}
        checkedField={language}
        onChange={(displayContentToUserMode: DisplayContentToUserModeType) => {
          dispatch(TrueFalseExerciseActionsCreator.setStatementLanguage(displayContentToUserMode));
        }}
      />
    </TrueFalseStatementWrapper>
  );
};
