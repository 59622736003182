import { useMemo } from 'react';
import styled from 'styled-components/macro';

import { ChapterSearchResultType, ChapterDetailsTab } from '../../types';
import { CardWrapper, CardTitle, CardLocations, CardMoreButton } from '../Common';

const ChapterCardWrapper = styled(CardWrapper)`
  grid-template-columns: 74% 20% 4%;
`;

export const ChapterResultCard = ({
  content,
  isExpanded,
  onExpand,
}: {
  content: ChapterSearchResultType;
  isExpanded: boolean;
  onExpand: (tabName: ChapterDetailsTab['key'] | null) => void;
}) => {
  const plainTextTitle = useMemo(
    () => content.title?.replace(/(<([^>]+)>)|(&.+;)/gi, '') || 'Untitled chapter',
    [content.title],
  );

  return (
    <ChapterCardWrapper isExpanded={isExpanded} onClick={() => onExpand(null)}>
      <CardTitle id={content.id} title={plainTextTitle} />
      <CardLocations
        courses={content.courses}
        locationsCount={content.locationsCount}
        onExpand={() => onExpand('locations')}
      />
      <CardMoreButton isExpanded={isExpanded} />
    </ChapterCardWrapper>
  );
};
