import { useEffect, useMemo, useState } from 'react';

import { CommonFilterOptionType } from '@components/SelectorCommonComponents';
import { UserArchivalModal, UserListItem } from '@features/users';

import { BeneficiarySelector } from './BeneficiarySelector';

type UserBeneficiaryArchivalModalProps = {
  managerId: string | null;
  opened: boolean;
  userList: UserListItem[];
  onArchive: (beneficiary: string) => void;
  onClose: () => void;
};

export const UserBeneficiaryArchivalModal = ({
  managerId,
  opened,
  userList,
  onArchive,
  onClose,
}: UserBeneficiaryArchivalModalProps) => {
  const [selectedBeneficiary, setSelectedBeneficiary] = useState<CommonFilterOptionType | null>(null);

  const beneficiarySelectorOptions: CommonFilterOptionType[] = useMemo(() => {
    return userList.map((user) => ({
      label: user.fullName,
      value: user.id,
    }));
  }, [userList]);

  const handleOnArchive = () => {
    onArchive(selectedBeneficiary?.value as string);
  };

  useEffect(() => {
    const manager = userList.find((user) => user.id === managerId);

    if (manager) setSelectedBeneficiary({ label: manager.fullName, value: manager.id });

    return () => {
      setSelectedBeneficiary(null);
    };
  }, [managerId, userList]);

  return (
    <UserArchivalModal opened={opened} onArchive={handleOnArchive} onClose={onClose}>
      <BeneficiarySelector
        options={beneficiarySelectorOptions}
        value={selectedBeneficiary}
        onChange={setSelectedBeneficiary}
      />
    </UserArchivalModal>
  );
};
