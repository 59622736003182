import classnames from 'classnames';
import styled from 'styled-components/macro';

import { allTypesMap } from '@helpers/contentCardHelper';
import { AnyExerciseContentInterface } from '@common/types/exercises/AnyExerciseContentInterface';

import { PreviewModalListSidebarProps } from '../PreviewModalProps';
import { ReactComponent as ArrowIcon } from '../common/img/arrow.svg';

import './PreviewModalListSidebar.scss';

const PREVIEW_SIDEBAR_WIDTH = 23;

const ListNavigationWrapper = styled.div`
  align-items: center;
  justify-content: center;
  box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.09);
  display: flex;
  max-width: ${PREVIEW_SIDEBAR_WIDTH}rem;
  height: 9.2rem;
  justify-content: space-between;
  padding: 0 2.4rem;
`;

const ButtonNavPrev = styled.button`
  align-items: center;
  background-color: ${({ theme }) => theme.colorV2.uiBackgroundSecondary};
  border: solid 0.1rem ${({ theme }) => theme.colorV2.accentPrimary};
  border-radius: 50%;
  display: flex;
  height: 3.84rem;
  justify-content: center;
  width: 3.84rem;

  &[disabled] {
    opacity: 0.5;
  }
`;

const ButtonNavNext = styled(ButtonNavPrev)`
  transform: rotate(180deg);
`;

export const PreviewModalListSidebar = ({
  exercisesNavigation,
  courseId,
  currentPosition,
  exerciseForPreview,
  title,
  isPreviousDisabled,
  isNextDisabled,
  onNavigate,
}: PreviewModalListSidebarProps) => {
  let numberOfExercise = 0;

  return (
    <div className="exercise-preview-for-activity-sidebar">
      <div className="exercise-preview-for-activity-sidebar__header">{title}</div>
      <div className="exercise-preview-for-activity-sidebar__navigation">
        <div className="exercise-preview-for-activity-sidebar__navigation__items">
          {exercisesNavigation.map((exercise: AnyExerciseContentInterface, index: number) => {
            if (exercise.ready) numberOfExercise++;
            return (
              <div
                id={`preview-navigation-exercise-${exercise.id}`}
                key={`${exercise.id}-${index}`}
                className={classnames('exercise-preview-for-activity-sidebar__navigation__items__item', {
                  'exercise-preview-for-activity-sidebar__navigation__items__item--active':
                    exercise.id === exerciseForPreview?.content.id,
                })}
                onClick={() => index !== currentPosition && onNavigate(index)}
              >
                {`${exercise.ready ? `${numberOfExercise} - ` : ''}${allTypesMap[exercise?.type]}`}
              </div>
            );
          })}
        </div>
      </div>
      <ListNavigationWrapper>
        <ButtonNavPrev disabled={isPreviousDisabled} onClick={() => onNavigate(currentPosition - 1)}>
          <ArrowIcon title="Previous exercise" />
        </ButtonNavPrev>
        <ButtonNavNext disabled={isNextDisabled} onClick={() => onNavigate(currentPosition + 1)}>
          <ArrowIcon title="Next exercise" />
        </ButtonNavNext>
      </ListNavigationWrapper>
    </div>
  );
};
