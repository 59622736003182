import { LocalizationInterface } from '@common/interfaces/localization/LocalizationInterface';
import { TranslationInstance, CommonTranslationProps } from './TranslationInstance';

export type TranslationsProps = CommonTranslationProps & {
  translations: LocalizationInterface[];
};

export const Translations = ({ translations, ...rest }: TranslationsProps) => (
  <ul className="translations-panel-modal__translations-block">
    {translations
      .sort((localization, localization2) => localization.language.localeCompare(localization2.language))
      .map((localization, idx) => (
        <TranslationInstance {...rest} localization={localization} idx={idx} key={idx} />
      ))}
  </ul>
);
