import ReactTooltip from 'react-tooltip';
import styled from 'styled-components/macro';

import { ContentType } from '@common/enums/ContentTypes';
import { LessonInterface } from '@common/interfaces/contentTypes/LessonInterface';
import { ValidationErrorInterface } from '@common/interfaces/validation/ValidationInterface';
import type { ContentBranches } from '@common/types/AnyExerciseContentBranch';
import { TranslationsPanelModes } from '@components/TranslationsPanel/enums/TranslationsPanelModes';
import TranslationsTipV2 from '@components/TranslationTipV2/TranslationTipV2';

import { WritableInputText } from '@components/WritableInputText';
import helpIcon from '@static/svg/help.svg';

const LearningOutcomesWrapper = styled.div`
  margin-left: 1.5rem;
  margin-top: 4.2rem;
`;

const LearningOutcomesTitle = styled.div`
  color: ${({ theme }) => theme.colorV2.textTertiary};
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 150%;
  margin-bottom: 0.6rem;
`;

const LearningOutcomesHelpIcon = styled.img`
  margin-left: 0.6rem;
`;

const StyledReactTooltip = styled(ReactTooltip)`
  line-height: 1.8rem;
  max-width: 250px;
  z-index: 14000 !important;
`;

type LearningOutcomesProps = {
  getDefaultContextForTranslators: (focus: string, visitedBranch: ContentBranches) => string | undefined;
  loadedContent: LessonInterface;
};

export const LearningOutcomes = ({ loadedContent, getDefaultContextForTranslators }: LearningOutcomesProps) => (
  <LearningOutcomesWrapper>
    <StyledReactTooltip id="learning-outcomes-tooltip" effect="solid" place="right" />
    <LearningOutcomesTitle>
      <span>Learning outcomes</span>
      <LearningOutcomesHelpIcon
        src={helpIcon}
        alt="Help"
        data-tip={'Each learning outcome must begin with an asterisk * and must be written on a separate line.'}
        data-for="learning-outcomes-tooltip"
      />
    </LearningOutcomesTitle>

    <TranslationsTipV2
      content={loadedContent}
      defaultContextForTranslators={getDefaultContextForTranslators(loadedContent?.focus, 'learningOutcomes')}
      errors={loadedContent.validation.errors.filter(
        (error: ValidationErrorInterface) => error.field === 'learningOutcomes',
      )}
      type={ContentType.levelOrLesson}
      translationsPanelTranslationsMode={TranslationsPanelModes.textArea}
      visitedBranch="learningOutcomes"
    >
      <WritableInputText placeholder={'· Learning Outcome 1\n· Learning Outcome 2\n· Learning Outcome 3'} mode="area" />
    </TranslationsTipV2>
  </LearningOutcomesWrapper>
);
