import { Modal } from 'react-bootstrap';
import styled from 'styled-components/macro';

import { Button, Loader } from '@features/theme';
import { AnyLoadedExercise } from '@common/types/exercises/AnyLoadedExercise';
import { ExerciseTypes } from '@common/enums/ExerciseTypes';
import { ContentTypes } from '@common/enums/ContentTypes';
import { useAppSelector } from '@redux/store';
import { selectIsPublishingInProgress, selectLoadedExercise } from '@selectors/CoursesSelectors';

const PublishModalContainer = styled(Modal)`
  .modal-content {
    border-radius: 1.6rem;
  }
`;

const Body = styled(Modal.Body)`
  display: flex;
  flex-direction: column;
  min-height: 30rem;
  align-items: center;
  padding: 4rem;
`;

const Title = styled.span`
  color: ${({ theme }) => theme.color.brandBlack};
  font-weight: 800;
  font-size: 2rem;
  margin-bottom: 0.8rem;
  text-align: center;
`;

const Description = styled.span`
  color: ${({ theme }) => theme.color.brandGreyscale800};
  font-size: 1.6rem;
  margin-bottom: 3.2rem;
  text-align: center;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ModalButton = styled(Button)`
  margin-bottom: 0.8rem;
`;

export default interface PublishModalProps {
  opened: boolean;
  onHide: any;
  onSubmit: any;
  forUnpublish: boolean;
  forUpdate: boolean;
  forReusedContent: boolean;
  type: string;
}

export const PublishModal = ({
  opened,
  onHide,
  onSubmit,
  forUnpublish,
  forUpdate,
  forReusedContent,
  type,
}: PublishModalProps) => {
  const isPublishingInProgress = useAppSelector(selectIsPublishingInProgress);

  const loadedExercise: AnyLoadedExercise | null = useAppSelector(selectLoadedExercise);
  const isComprehensionExercise =
    type === ExerciseTypes.comprehension ||
    (type === ContentTypes.exercise && loadedExercise.exercise.type === ExerciseTypes.comprehension);

  const publishingText = `${
    forUnpublish ? (isComprehensionExercise ? 'Unpublish anyway' : 'Unpublish') : forUpdate ? 'Update' : 'Publish'
  }`;

  return (
    <PublishModalContainer show={opened} centered onHide={onHide}>
      <Body>
        <Title>Are you sure you want to {forUnpublish ? 'unpublish' : forUpdate ? 'update' : 'publish'}?</Title>
        <Description>
          {forReusedContent
            ? `This content is being reused elsewhere. Updating everywhere will update all instances with the next deployment. To avoid this, select “${publishingText} here”.`
            : 'Content changes will be updated on production with the next deploy.'}
        </Description>

        {isComprehensionExercise && forUnpublish ? (
          <Description>
            This comprehension exercise can be used as a recap in other exercises. If you unpublish it, it will no
            longer appear as a recap option.
          </Description>
        ) : null}
        <ButtonsContainer>
          <ModalButton onClick={() => onSubmit(false)}>
            {isPublishingInProgress ? (
              <Loader size="S" />
            ) : forReusedContent ? (
              `${publishingText} here`
            ) : (
              `${publishingText}`
            )}
          </ModalButton>
          {forReusedContent ? (
            <ModalButton variant="secondary" onClick={() => onSubmit(true)}>
              {isPublishingInProgress ? <Loader size="S" /> : `${publishingText} everywhere`}
            </ModalButton>
          ) : null}
          <ModalButton onClick={onHide} className="publish-modal__buttons__cancel" variant="secondary">
            Cancel
          </ModalButton>
        </ButtonsContainer>
      </Body>
    </PublishModalContainer>
  );
};
