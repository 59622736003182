import styled from 'styled-components';

export const CardWrapper = styled.div<{ isExpanded: boolean }>`
  cursor: pointer;
  width: 100%;
  padding: 1.6rem;
  box-shadow: 0 0.4rem 1.2rem rgba(0, 0, 0, 0.08);
  border-radius: 0.8rem;
  border: 0.1rem solid ${({ isExpanded, theme }) => (isExpanded ? theme.color.brandPrimaryBase : 'transparent')};
  display: grid;
  grid-column-gap: 1%;
  align-items: center;

  &:hover {
    background: ${({ theme }) => theme.color.quaternaryLight2};
  }
`;
