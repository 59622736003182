import { call, put, select, delay, all } from 'redux-saga/effects';
import { getNewStructureElement, getNewGroupElement, getNewArray, getParentType } from '@helpers/newAddedElementHelper';
import { GroupActionCreators_saveCourseNameAndDescription } from '@actionCreators/ContentTypesActionsCreator';
import { CourseImageBranches } from '@common/types/AnyExerciseContentBranch';
import { GenericContentInterface } from '@common/interfaces/contentTypes/GenericContentInterface';
import { AnyExerciseContentInterface } from '@common/types/exercises/AnyExerciseContentInterface';
import { MultipleChoiceExerciseActions } from '@actions/MultipleChoiceExerciseActions';
import { ConversationExerciseActions } from '@actions/ConversationExerciseActions';
import { ExerciseInterface } from '@common/interfaces/contentTypes/ExerciseInterface';
import { CourseInterface } from '@common/interfaces/courses/CourseInterface';
import TranslationsPanelService from '@services/TranslationsPanelService';
import { ContentTypesActions } from '@actions/ContentTypesActions';
import { CoursesActions } from '@actions/CoursesActions';
import { SectionActions } from '@actions/SectionActions';
import { CommonActions } from '@actions/CommonActions';
import { ExerciseTypes } from '@common/enums/ExerciseTypes';
import { ContentTypes, ContentTypesForCreateContent } from '@common/enums/ContentTypes';
import { LoadingStage, type LoadingStageType } from '@common/enums/LoadingStage';
import ContentsService from '@services/ContentsService';
import CoursesService from '@services/CoursesService';
import GroupsService from '@services/GroupsService';
import {
  GroupsService_updateLessonFocus,
  GroupsService_updateLessonType,
  GroupsService_updateLevelType,
} from '@services/types/GroupsServiceTypes';
import { DBId } from '@common/types/DBId';
import type { AppDispatch } from '@redux/store';
import { courseSlice } from '@redux/slices/courseSlice';
import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { GrammarReviewService } from '@services/GrammarReviewService';
import { GrammarCategoryContentType, GrammarTopicContentType } from '@features/content/grammar';
import { GrammarReviewActions } from '@actions/GrammarReviewActions';
import { PlacementTestService } from '@services/PlacementTestService';
import { EntrypointContentType } from '@features/content/placementTest';
import { PlacementTestActions } from '@actions/PlacementTestActions';
import NavigationMenuSagas from '@sagas/navigationMenu/definitions/NavigationMenuSagasDefinition';
import { addToast } from '@features/app/toast';
import {
  selectCourse,
  selectGroupsOfSelectedGroupsOfParent,
  selectLoadedExerciseContent,
  selectLoadedExerciseLoaded,
  selectLoadedExercisesExercises,
  selectParentTypeOfSelectedGroupsOfParent,
  selectSelectedGroupsOfParentParentContents,
  selectSelectedGroupsOfParentParentContentsCategoryId,
  selectSelectedGroupsOfParentParentContentsLessonType,
} from '@selectors/CoursesSelectors';
import { selectGrammarCategoryContent, selectGrammarTopicContent } from '@selectors/GrammarSelectors';
import { selectPlacementTestEntrypointContent } from '@selectors/PlacementTestSelectors';
import { LESSON_SPEAKING } from '@common/enums/LessonTypes';
import { ACTIVITY_SPEAKING } from '@features/content/activities';

export type UploadImageToExerciseAvailableSections =
  | typeof MultipleChoiceExerciseActions.UPLOAD_MAIN_CONTENT_IMAGE
  | typeof MultipleChoiceExerciseActions.UPLOAD_ANSWER_IMAGE
  | typeof MultipleChoiceExerciseActions.UPLOAD_DISTRACTOR1_IMAGE
  | typeof MultipleChoiceExerciseActions.UPLOAD_DISTRACTOR2_IMAGE
  | typeof ConversationExerciseActions.UPLOAD_IMAGE1
  | typeof ConversationExerciseActions.UPLOAD_IMAGE2
  | typeof ConversationExerciseActions.UPLOAD_IMAGE3;

const CourseSagas = {
  *updateLessonFocus({ payload: { lessonId, focus } }: PayloadAction<GroupsService_updateLessonFocus>) {
    try {
      yield call(GroupsService.lessons.updateFocus, lessonId, focus);

      yield put({
        type: ContentTypesActions.COURSE_UPDATE_LESSON_FOCUS_SUCCESS,
        payload: { lessonId, focus },
      });
    } catch (e: any) {
      if (e.response.data.detail) {
        addToast({
          type: 'error',
          title: e.response.data.title,
          description: e.response.data.detail,
        });
      } else {
        console.error(e);
      }
    }
  },
  *updateLessonType({ payload: { lessonId, lessonType } }: PayloadAction<GroupsService_updateLessonType>) {
    try {
      yield call(GroupsService.lessons.updateType, lessonId, lessonType);

      yield put({
        type: ContentTypesActions.CHANGE_LESSON_TYPE_SUCCEEDED,
        payload: { lessonId, lessonType },
      });
    } catch (e: any) {
      if (e.response.data.detail) {
        addToast({
          type: 'error',
          title: e.response.data.title,
          description: e.response.data.detail,
        });
      } else {
        console.error(e);
      }
    }
  },
  *updateLevelType({ payload: { levelId, levelType } }: PayloadAction<GroupsService_updateLevelType>) {
    try {
      yield call(GroupsService.levels.updateType, levelId, levelType);

      yield put({
        type: ContentTypesActions.COURSE_UPDATE_LEVEL_TYPE_SUCCESS,
        payload: { levelId, levelType },
      });
    } catch (e: any) {
      console.error(e);
    }
  },
  *onCreateContent({ payload }: any) {
    try {
      const groups: ReturnType<typeof selectGroupsOfSelectedGroupsOfParent> = yield select(
        selectGroupsOfSelectedGroupsOfParent,
      );
      const exercises: ReturnType<typeof selectLoadedExercisesExercises> = yield select(selectLoadedExercisesExercises);

      const { contentCategory, parentId, type } = payload;

      let createResult: Awaited<ReturnType<typeof ContentsService.contents.createContent>>;
      yield put(courseSlice.actions.setIsCreatingContent(payload.position));
      try {
        createResult = yield call(ContentsService.contents.createContent, payload);
      } finally {
        yield put(courseSlice.actions.setIsCreatingContent(undefined));
      }

      const newElementId = createResult.data.id;

      if (createResult.status === 201 && newElementId) {
        const newElementData = {
          contentCategory,
          parentId,
          type,
        };

        const parentType = getParentType(type);
        const newStructureElement = getNewStructureElement(newElementId, newElementData);
        const newGroupElement = getNewGroupElement(newElementId, newElementData);

        if (newStructureElement && newGroupElement) {
          if (![ContentTypes.exercise, ContentTypes.slidePdf, ContentTypes.slidePptx].includes(type)) {
            // Update groups
            const newGroups = getNewArray(groups, newGroupElement, payload.position);

            yield put({
              type: CoursesActions.UPDATE_GROUPS,
              payload: newGroups,
            });
          } else {
            // Update exercises
            const newExercises = getNewArray(exercises, newGroupElement, payload.position);

            yield put({
              type: CoursesActions.UPDATE_EXERCISES,
              payload: newExercises,
            });
          }

          yield call(NavigationMenuSagas.updateNavigation, parentId, parentType);
        }

        if (parentType) {
          yield call(CourseSagas.getValidationResult, {
            type: parentType,
          });
        }
      }

      yield put({
        type: CoursesActions.SET_POSITION_OF_NEW_CONTENT,
        payload: { newElementId },
      });

      yield delay(2000);

      yield put({
        type: CoursesActions.SET_POSITION_OF_NEW_CONTENT,
        payload: {},
      });
    } catch (e: any) {
      console.error(e);
    }
  },
  *uploadThumbnailImage({ payload }: PayloadAction<any>) {
    try {
      let loadedCourse: CourseInterface = yield select(selectCourse);
      yield put({
        type: CommonActions.IMAGE_UPLOADING_STARTED,
        payload: { imageField: 'thumbnailImage' },
      });

      const uploadResult: Awaited<ReturnType<typeof CoursesService.uploadThumbnailImage>> = yield call(
        CoursesService.uploadThumbnailImage,
        payload.image,
        loadedCourse,
        payload.progressHandler,
      );

      if (uploadResult.mediaId) {
        yield put({
          type: CoursesActions.SET_THUMBNAIL_IMAGE,
          payload: { content: { ...uploadResult.content, _id: uploadResult.mediaId } },
        });
      }
      yield put({
        type: CommonActions.IMAGE_UPLOADING_FINISHED,
        payload: { imageField: 'thumbnailImage' },
      });
    } catch (e: any) {
      payload.progressHandler(0);
      yield put({
        type: CommonActions.IMAGE_UPLOADING_FINISHED,
        payload: {
          imageField: 'thumbnailImage',
          details: e?.message,
        },
      });
      console.error(e);
    }
  },
  *uploadPaywallImage({ payload }: PayloadAction<any>) {
    try {
      let loadedCourse: CourseInterface = yield select(selectCourse);
      yield put({
        type: CommonActions.IMAGE_UPLOADING_STARTED,
        payload: { imageField: 'paywallImage' },
      });

      const uploadResult: Awaited<ReturnType<typeof CoursesService.uploadPaywallImage>> = yield call(
        CoursesService.uploadPaywallImage,
        payload.image,
        loadedCourse,
        payload.progressHandler,
      );

      if (uploadResult.mediaId) {
        yield put({
          type: CoursesActions.SET_PAYWALL_IMAGE,
          payload: { content: { ...uploadResult.content, _id: uploadResult.mediaId } },
        });
      }
      yield put({
        type: CommonActions.IMAGE_UPLOADING_FINISHED,
        payload: { imageField: 'paywallImage' },
      });
    } catch (e: any) {
      payload.progressHandler(0);
      yield put({
        type: CommonActions.IMAGE_UPLOADING_FINISHED,
        payload: {
          imageField: 'paywallImage',
          details: e?.message,
        },
      });
      console.error(e);
    }
  },
  *loadOneContent({ payload }: PayloadAction<any>) {
    try {
      let loadedCourse: CourseInterface = yield select(selectCourse);

      if (!payload.withoutLoader) {
        yield put({
          type: CoursesActions.ONE_COURSE_CONTENT_LOADING,
        });
      }

      let { contentId, parentId } = payload;
      const parentContents: Awaited<ReturnType<typeof ContentsService.contents.getById>> = yield call(
        ContentsService.contents.getById,
        contentId,
        loadedCourse.id,
        parentId,
        loadedCourse.learningLanguage,
      );

      if (payload.updateOnlyGroups) {
        yield call(CourseSagas.getGroupsOfContent, {
          parentId: contentId,
        });
      } else {
        let mappedParentContents = parentContents;

        if (
          parentContents?.type === ContentTypes.activity &&
          parentContents?.activityType === ContentTypesForCreateContent.speaking &&
          mappedParentContents
        ) {
          mappedParentContents.activityType = ACTIVITY_SPEAKING;
        }

        yield put({
          type: CoursesActions.ONE_COURSE_CONTENT_LOADED_SUCCEEDED,
          payload: { parentContents, parentId: contentId },
        });

        yield call(CourseSagas.getGroupsOfContent, {
          parentId: contentId,
        });

        yield put({
          type: ContentTypesActions.COURSE_NAVIGATION_ITEM_SELECTED,
          payload: {
            id: parentContents?.id,
            type: parentContents?.type,
            withoutUpdateLoadedState: true,
          },
        });
      }
    } catch (e: any) {
      yield put({
        type: CoursesActions.ONE_COURSE_CONTENT_FAILED,
        message: e.message,
      });
    }
  },
  *coursesInfoRequest() {
    try {
      const coursesInfo: Awaited<ReturnType<typeof CoursesService.getCoursesInfo>> = yield call(
        CoursesService.getCoursesInfo,
      );

      const sanitizedCoursesInfo = coursesInfo.map((courseInfo: any) => {
        return {
          ...courseInfo,
          title: courseInfo.title || 'Untitled Course',
        };
      });

      yield put({
        type: CoursesActions.COURSES_INFO_REQUEST_SUCCEEDED,
        payload: sanitizedCoursesInfo,
      });
    } catch (e: any) {
      yield put({ type: 'COURSES_INFO_REQUEST_FAILED', message: e.message });
    }
  },
  *loadCourse(payload: any) {
    try {
      yield put({
        type: CoursesActions.COURSE_LOADING,
      });

      let { courseId }: { courseId: DBId } = payload;
      let course: CourseInterface = yield call(CoursesService.get, courseId);

      yield put({
        type: CoursesActions.COURSE_LOADED_SUCCEEDED,
        payload: { course },
      });
    } catch (e: any) {
      yield put({ type: 'ONE_COURSE_REQUEST_FAILED', message: e.message });
    }
  },
  *loadCourseWithItsGroups(payload: any) {
    try {
      if (!payload.withoutLoader) {
        yield put({
          type: CoursesActions.COURSE_LOADING,
        });
      }

      let { courseId }: { courseId: DBId } = payload;

      let course: CourseInterface = yield call(CoursesService.get, courseId);

      if (payload.updateOnlyGroups) {
        yield put({
          type: CoursesActions.COURSE_GROUPS_LOADED_SUCCEEDED,
          payload: { course },
        });
      } else {
        yield put({
          type: CoursesActions.COURSE_LOADED_SUCCEEDED,
          payload: { course, isCourse: true },
        });
      }

      yield call(CourseSagas.getGroupsOfContent, {
        parentId: course.id,
      });
    } catch (e: any) {
      yield put({ type: 'ONE_COURSE_REQUEST_FAILED', message: e.message });
    }
  },
  *getGroupsOfContent(payload: any) {
    try {
      let parentType: string = yield select(selectParentTypeOfSelectedGroupsOfParent);
      let groupType: string = yield select(selectSelectedGroupsOfParentParentContentsLessonType);

      let loadedCourse: CourseInterface = yield select(selectCourse);

      let exercisesLoaded: LoadingStageType = yield select(selectLoadedExerciseLoaded);

      let { parentId }: { parentId: DBId } = payload;

      if (
        parentType === ContentTypes.activity ||
        (parentType === ContentTypes.lesson && groupType === ContentTypes.liveLesson)
      ) {
        if (exercisesLoaded === LoadingStage.notLoaded) {
          yield put({
            type: SectionActions.GET_ALL_EXERCISES_OF_PARENT_ID,
            payload: { parentId },
          });
        }
      } else {
        yield put({
          type: CoursesActions.GROUPS_LOADING,
        });

        const groups: Awaited<ReturnType<typeof ContentsService.groups.getGroupsOfContent>> = yield call(
          ContentsService.groups.getGroupsOfContent,
          parentId,
          loadedCourse.id,
          loadedCourse.learningLanguage,
        );

        let mappedGroups = groups;

        if (parentType === ContentTypes.chapter) {
          mappedGroups = groups.map((group) => {
            if (group.lessonType && group.lessonType === ContentTypesForCreateContent.speaking) {
              return {
                ...group,
                lessonType: LESSON_SPEAKING,
              };
            }

            return group;
          });
        }

        if (parentType === ContentTypes.lesson) {
          mappedGroups = groups.map((group) => {
            if (group.activityType && group.activityType === ContentTypesForCreateContent.speaking) {
              return {
                ...group,
                activityType: ACTIVITY_SPEAKING,
              };
            }
            return group;
          });
        }

        yield put({
          type: CoursesActions.GROUPS_LOADED_SUCCEEDED,
          payload: { parentId, groups: mappedGroups },
        });
      }
    } catch (e: any) {
      yield put({ type: 'GROUPS_LOADED_FAILED', message: e.message });
    }
  },
  *getValidationResult(payload: { type: string }) {
    try {
      let loadedCourse: CourseInterface = yield select(selectCourse);
      let loadedContent: GenericContentInterface = yield select(selectSelectedGroupsOfParentParentContents);
      const loadedExercise: AnyExerciseContentInterface = yield select(selectLoadedExerciseContent);
      const grammarCategoryContent: GrammarCategoryContentType = yield select(selectGrammarCategoryContent);
      const grammarTopicContent: GrammarTopicContentType = yield select(selectGrammarTopicContent);
      const entrypointContent: EntrypointContentType = yield select(selectPlacementTestEntrypointContent);

      yield put({
        type: CommonActions.VALIDATION_IS_LOADING,
        payload: {
          value: true,
        },
      });

      let validationResult: Awaited<ReturnType<typeof CoursesService.getValidationResult>> | null = null;

      switch (payload.type) {
        case ContentTypes.course: {
          validationResult = yield call(
            CoursesService.getValidationResult,
            payload.type,
            loadedCourse.id,
            loadedCourse.id,
          );
          break;
        }
        case ContentTypes.exercise: {
          validationResult = yield call(
            CoursesService.getValidationResult,
            payload.type,
            loadedExercise.id,
            loadedCourse.id,
          );
          break;
        }

        case ContentTypes.activity:
        case ContentTypes.lesson:
        case ContentTypes.chapter:
        case ContentTypes.level: {
          validationResult = yield call(
            CoursesService.getValidationResult,
            payload.type,
            loadedContent.id,
            loadedCourse.id,
          );
          break;
        }

        case ContentTypes.grammarReview: {
          validationResult = yield call(GrammarReviewService.getReviewValidationResult, loadedCourse.id);
          break;
        }
        case ContentTypes.grammarCategory: {
          validationResult = yield call(
            GrammarReviewService.getCategoryValidationResult,
            loadedCourse.id,
            grammarCategoryContent.id,
          );
          break;
        }
        case ContentTypes.grammarTopic: {
          validationResult = yield call(
            GrammarReviewService.getTopicValidationResult,
            loadedCourse.id,
            grammarTopicContent.id,
          );
          break;
        }
        case ContentTypes.entrypoint: {
          validationResult = yield call(
            PlacementTestService.getEntrypointValidationResult,
            loadedCourse.id,
            entrypointContent.id,
          );
          break;
        }

        default: {
          console.log('Unknown type');
        }
      }

      if (validationResult?.status === 200) {
        const isGrammar =
          payload.type === ContentTypes.grammarReview ||
          payload.type === ContentTypes.grammarCategory ||
          payload.type === ContentTypes.grammarTopic;
        const isPlacement = payload.type === ContentTypes.placementTest || payload.type === ContentTypes.entrypoint;

        let action: string = CommonActions.SET_VALIDATION_RESULT;
        if (isGrammar) action = GrammarReviewActions.SET_VALIDATION_RESULT;
        if (isPlacement) action = PlacementTestActions.SET_VALIDATION_RESULT;

        yield put({
          type: action,
          payload: {
            type: payload.type,
            data: validationResult.data,
          },
        });
      }

      yield put({
        type: CommonActions.VALIDATION_IS_LOADING,
        payload: {
          value: false,
        },
      });
    } catch (e: any) {
      yield put({
        type: CommonActions.VALIDATION_IS_LOADING,
        payload: {
          value: false,
        },
      });
      console.error(e);
    }
  },
  *saveCourseNameAndDescription(
    dispatch: AppDispatch,
    { payload }: PayloadAction<GroupActionCreators_saveCourseNameAndDescription>,
  ) {
    try {
      const { values } = payload;

      yield put({
        type: CommonActions.SET_COURSE_ALL_VALUES,
        payload: {
          values,
        },
      });

      yield put({
        type: CommonActions.SET_IS_SAVE_LOADING,
        payload: {
          value: true,
        },
      });
      let loadedCourse: CourseInterface = yield select(selectCourse);

      const updatePayload: Partial<Record<'title' | 'description' | CourseImageBranches, DBId | null>>[] = yield all([
        (values.titleWithLocalizationsChanged ||
          loadedCourse.titleWithLocalizationsChanged ||
          loadedCourse.titleWithLocalizations?.changed) &&
        !loadedCourse.titleWithLocalizations?.isReused
          ? call(
              TranslationsPanelService.updateCourseField,
              dispatch,
              loadedCourse,
              loadedCourse.titleWithLocalizations?._id,
              'titleWithLocalizations',
            )
          : {},
        (values.descriptionWithLocalizationsChanged ||
          loadedCourse.descriptionWithLocalizationsChanged ||
          loadedCourse.descriptionWithLocalizations?.changed) &&
        !loadedCourse.descriptionWithLocalizations?.isReused
          ? call(
              TranslationsPanelService.updateCourseField,
              dispatch,
              loadedCourse,
              loadedCourse.descriptionWithLocalizations?._id,
              'descriptionWithLocalizations',
            )
          : {},
        loadedCourse?.thumbnailImageChanged
          ? call(
              TranslationsPanelService.updateCourseField,
              dispatch,
              loadedCourse,
              loadedCourse.thumbnailImage?._id,
              'thumbnailImage',
            )
          : {},
        loadedCourse?.paywallImageChanged
          ? call(
              TranslationsPanelService.updateCourseField,
              dispatch,
              loadedCourse,
              loadedCourse.paywallImage?._id,
              'paywallImage',
            )
          : {},
      ]);

      const payloadForCourseUpdate = {
        ...updatePayload.reduce(
          (
            sum: Partial<Record<'title' | 'description' | CourseImageBranches, DBId | null>>,
            item: Partial<Record<'title' | 'description' | CourseImageBranches, DBId | null>>,
          ) => ({ ...sum, ...item }),
          {},
        ),
      };

      CoursesService.updateCourse(loadedCourse.id, payloadForCourseUpdate);

      if (values.titleWithLocalizationsChanged) {
        yield put({
          type: CoursesActions.COURSES_INFO_RESET,
        });
      }

      // Update structure
      yield call(NavigationMenuSagas.updateNavigation, loadedCourse.id, ContentTypes.course);

      yield put({
        type: CommonActions.SET_IS_SAVE_LOADING,
        payload: {
          value: false,
          updateData: true,
        },
      });
      addToast({
        type: 'success',
        title: 'Course name and its description have been saved',
      });

      yield call(CourseSagas.getValidationResult, {
        type: ContentTypes.course,
      });
    } catch (e: any) {
      yield put({
        type: CommonActions.SET_IS_SAVE_LOADING,
        payload: {
          value: false,
          updateData: false,
        },
      });
      console.error(e);
    }
  },
  *saveContentNameAndDescription(
    dispatch: AppDispatch,
    { payload }: PayloadAction<GroupActionCreators_saveCourseNameAndDescription>,
  ) {
    try {
      const { values } = payload;

      yield put({
        type: CommonActions.SET_GROUP_ALL_VALUES,
        payload: {
          values,
        },
      });

      yield put({
        type: CommonActions.SET_IS_SAVE_LOADING,
        payload: {
          value: true,
        },
      });
      let loadedContent: GenericContentInterface = yield select(selectSelectedGroupsOfParentParentContents);
      let contentType: string = yield select(selectParentTypeOfSelectedGroupsOfParent);
      const parentId: string = yield select(selectSelectedGroupsOfParentParentContentsCategoryId);
      const loadedCourse: ReturnType<typeof selectCourse> = yield select(selectCourse);

      const saveResult: Awaited<ReturnType<typeof TranslationsPanelService.saveLevelOrLesson>> = yield call(
        TranslationsPanelService.saveLevelOrLesson,
        dispatch,
        loadedContent,
        values,
      );

      // Update structure
      yield call(
        NavigationMenuSagas.updateNavigation,
        contentType === ContentTypes.level ? loadedCourse.id : parentId,
        getParentType(contentType),
      );

      yield put({
        type: CommonActions.SET_IS_SAVE_LOADING,
        payload: {
          value: false,
          updateData: true,
        },
      });
      if (saveResult?.status === 204) {
        addToast({
          type: 'success',
          title: 'Content changes have been saved',
        });
      }
      yield call(CourseSagas.getValidationResult, {
        type: contentType,
      });
    } catch (e: any) {
      yield put({
        type: CommonActions.SET_IS_SAVE_LOADING,
        payload: {
          value: false,
          updateData: false,
        },
      });
      console.error(e);
    }
  },

  *requestAllExercisesOfContentId({ payload }: any) {
    try {
      const loadedCourse: CourseInterface = yield select(selectCourse);

      if (loadedCourse.id) {
        const exercisesOfParent: ExerciseInterface[] = yield call(
          ContentsService.contents.getAllExercisesOfContent,
          payload.parentId,
          loadedCourse.id,
        );

        yield put({
          type: SectionActions.COURSE_EXERCISES_REQUEST_SUCCEEDED,
          payload: { exercises: exercisesOfParent, parentId: payload.parentId },
        });
      }
    } catch (e: any) {
      console.error(e);
    }
  },
  *requestRecapOptions({ payload }: any) {
    try {
      const recapOptions: ExerciseInterface[] = yield call(
        ContentsService.contents.getRecapOptions,
        payload.exerciseId,
      );

      yield put({
        type: SectionActions.RECAP_OPTIONS_REQUEST_SUCCEEDED,
        payload: { recapOptions },
      });
    } catch (e: any) {
      console.error(e);
    }
  },
  *updateContentsOrder({ payload }: any) {
    try {
      yield put({
        type: ContentTypesActions.COURSE_UPDATE_CONTENTS_ORDER_SUCCEEDED,
        payload,
      });

      let parentId;

      if (payload.parentId === undefined) {
        parentId = payload.courseId;
      } else {
        parentId = payload.parentId;
      }

      const groupId = payload.orderedItems[payload.destinationIndex].id;

      const changeGroupOrderPayload = {
        parentId,
        parentType: payload.parentType,
        groupId,
        newIndex: payload.destinationIndex,
      };

      yield call(ContentsService.contents.updateOrder, changeGroupOrderPayload);

      if (payload.parentType) {
        yield call(CourseSagas.getValidationResult, {
          type: payload.parentType,
        });
      }

      yield call(NavigationMenuSagas.updateNavigation, parentId, payload.parentType);
    } catch (e: any) {
      console.error(e);
    }
  },
  *removeContent({ payload }: any) {
    try {
      const groups: ReturnType<typeof selectGroupsOfSelectedGroupsOfParent> = yield select(
        selectGroupsOfSelectedGroupsOfParent,
      );

      const exercises: ReturnType<typeof selectLoadedExercisesExercises> = yield select(selectLoadedExercisesExercises);

      const loadedCourse: ReturnType<typeof selectCourse> = yield select(selectCourse);

      const loadedContent: ReturnType<typeof selectSelectedGroupsOfParentParentContents> = yield select(
        selectSelectedGroupsOfParentParentContents,
      );

      const { type, id } = payload;

      let deleteResult: AxiosResponse<any, any>;
      const parentType = getParentType(type);

      yield put(courseSlice.actions.setIsDeleteInProgress(true));
      if (type === ContentTypes.level) {
        deleteResult = yield call(ContentsService.contents.removeLevel, id, loadedCourse.id);
      } else if (type === ContentTypes.lesson || type === ContentTypes.chapter || type === ContentTypes.activity) {
        deleteResult = yield call(ContentsService.contents.removeGroup, id, loadedContent?.id as string);
      } else {
        deleteResult = yield call(ContentsService.contents.removeExercise, id, loadedContent?.id as string);
      }

      if (deleteResult.status === 204 || deleteResult.status === 200) {
        yield put(courseSlice.actions.setIsDeleteInProgress(false));
        if (Object.values(ExerciseTypes).includes(type) || type === ContentTypes.slidePdf) {
          // Update exercises
          yield put({
            type: CoursesActions.UPDATE_EXERCISES,
            payload: exercises.filter((item: any) => item.id !== id),
          });
        } else {
          // Update groups
          yield put({
            type: CoursesActions.UPDATE_GROUPS,
            payload: groups.filter((item: any) => item.id !== id),
          });
        }

        // Update structure
        yield call(
          NavigationMenuSagas.updateNavigation,
          type === ContentTypes.level ? loadedCourse.id : loadedContent?.id || '',
          parentType,
        );

        if (parentType) {
          yield call(CourseSagas.getValidationResult, {
            type: parentType,
          });
        }
      }
    } catch (e: any) {
      yield put(courseSlice.actions.setIsDeleteInProgress(false));
      console.error(e);
    }
  },
};

export default CourseSagas;
