import { ReactNode } from 'react';
import styled from 'styled-components/macro';

import { Button } from '@features/theme';

const InfoModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

const Title = styled.span`
  color: ${({ theme }) => theme.color.brandBlack};
  font-weight: 800;
  font-size: 2rem;
  margin-bottom: 0.8rem;
`;

const Description = styled.span`
  color: ${({ theme }) => theme.color.brandGreyscale800};
  font-weight: 400;
  font-size: 1.6rem;
  margin-bottom: 3.2rem;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;

export const InfoModalContent = ({
  title,
  description,
  testIdPrefix,
  children,
  close,
}: {
  title: string;
  description: string | null;
  testIdPrefix: string;
  children?: ReactNode;
  close: () => void;
}) => (
  <InfoModalWrapper data-testid={`${testIdPrefix}-modal-body`}>
    <Title>{title}</Title>
    {description && <Description>{description}</Description>}
    {children}
    <ButtonsContainer>
      <Button onClick={close}>Got it</Button>
    </ButtonsContainer>
  </InfoModalWrapper>
);
