import Select, { StylesConfig } from 'react-select';

import { dropdown_defaultCode } from '@components/Dropdown';
import { Role } from '@features/auth/roles';
import { AVAILABLE_LEARNING_LANGUAGES, Language, LANGUAGE_NAMES } from '@features/content/languages';
import { CommonFilterOptionType, useSelectorCommonStyles } from '@components/SelectorCommonComponents';

import { DefaultPlaceholder } from '../DefaultPlaceholder/DefaultPlaceholder';

export interface OptionInterface {
  value: string | Language;
  label: string;
}

const allCoursesOption = { label: 'All courses', value: dropdown_defaultCode };

export const courseLanguagesDropdownFilters: OptionInterface[] = [
  allCoursesOption,
  ...AVAILABLE_LEARNING_LANGUAGES.map((language) => ({
    label: `${LANGUAGE_NAMES[language]} courses`,
    value: language,
  })),
];

interface CourseContainerProps {
  isEditMode: boolean;
  currentRole: Role;
  setCurrentLanguage: (newLanguages: OptionInterface[]) => void;
  setAllLanguagesSelected: (isAllSelected: boolean) => void;
  currentLanguage: OptionInterface[];
  allLanguagesSelected: boolean;
}

export const CourseContainer = ({
  isEditMode,
  currentRole,
  setCurrentLanguage,
  setAllLanguagesSelected,
  currentLanguage,
  allLanguagesSelected,
}: CourseContainerProps) => {
  const baseSelectStyles = useSelectorCommonStyles<CommonFilterOptionType>({
    classNamePrefix: 'course-language-selector',
  });

  const selectStyles: StylesConfig<CommonFilterOptionType> = {
    ...baseSelectStyles,
    dropdownIndicator: (defaultStyles, props) => ({
      ...defaultStyles,
      ...(baseSelectStyles.dropdownIndicator && baseSelectStyles.dropdownIndicator(defaultStyles, props)),
      display: `${currentLanguage?.length || allLanguagesSelected ? 'none' : 'block'}`,
    }),
  };

  if (isEditMode) {
    return (
      <Select
        placeholder="Course language"
        styles={selectStyles}
        value={allLanguagesSelected ? [allCoursesOption] : currentLanguage}
        isMulti
        onChange={(values) => {
          setCurrentLanguage((values as OptionInterface[]).filter((item) => item.value !== dropdown_defaultCode));
          setAllLanguagesSelected(
            (values as OptionInterface[]).map((item) => item.value).includes(dropdown_defaultCode),
          );
        }}
        options={
          currentRole === 'ROLE_ADMIN' || currentRole === 'ROLE_VIEWER' || allLanguagesSelected
            ? [allCoursesOption]
            : courseLanguagesDropdownFilters
        }
      />
    );
  } else {
    return (
      <>
        {allLanguagesSelected || (currentLanguage && currentLanguage.length) ? (
          <>
            {allLanguagesSelected ? (
              <span>All courses</span>
            ) : (
              <span>{currentLanguage.map((lang: OptionInterface) => lang.label).join(', ')}</span>
            )}
          </>
        ) : (
          <DefaultPlaceholder>Not assigned</DefaultPlaceholder>
        )}
      </>
    );
  }
};
