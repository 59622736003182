import { registerConfig } from '@features/config';
import { ApiConfig } from './config';
import { createClients } from './client';
import { isFeatureEnabled } from '@helpers/featuresHelper';

registerConfig<ApiConfig>('api', {
  baseUrl:
    (isFeatureEnabled('vpn')
      ? process.env.REACT_APP_BASE_URL_NO_PATH
      : process.env.REACT_APP_BASE_PUBLIC_URL_NO_PATH) || 'http://localhost:8080',
});

export const apiClient = createClients();
