import { $getRoot, $insertNodes, LexicalEditor } from 'lexical';
import { $generateHtmlFromNodes, $generateNodesFromDOM } from '@lexical/html';

import { SerializerInterface } from './serializer';

class HtmlEditorSerializer implements SerializerInterface {
  editor: LexicalEditor;

  constructor(editor: LexicalEditor) {
    this.editor = editor;
  }

  deserialize(htmlString: string) {
    this.editor.update(() => {
      const parser = new DOMParser();
      const dom = parser.parseFromString(htmlString, 'text/html');
      const nodes = $generateNodesFromDOM(this.editor, dom);

      $getRoot().select();
      $insertNodes(nodes);
    });
  }

  serialize() {
    return $generateHtmlFromNodes(this.editor);
  }
}

export default HtmlEditorSerializer;
