import { ListItemNode } from '@lexical/list';
import { InitialConfigType, LexicalComposer } from '@lexical/react/LexicalComposer';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { EditorState, Klass, LexicalNode } from 'lexical';

import { CustomListItemNode } from './nodes';
import { OnChangePlugin, TreeViewPlugin } from './plugins';
import {
  baseEditorTheme,
  EditorContainer,
  EditorPlaceholder,
  EditorTextWrapper,
  StyledContentEditable,
} from './styles';
import { SupportedValueTypes } from './utils/serializer';

export type BaseEditorValueType = {
  [key in SupportedValueTypes]: string;
};

export type BaseEditorProps = {
  debug?: boolean;
  className?: string;
  id?: string;
  initialValue?: EditorState;
  editorPlugins?: JSX.Element[];
  footerPlugins?: JSX.Element[];
  headerPlugins?: JSX.Element[];
  nodes?: Klass<LexicalNode>[];
  placeholder?: string | null;
  onChange: (editorValue: BaseEditorValueType) => void;
  onError?: (error: Error) => void;
};

export const BaseEditor = ({
  debug = false,
  className,
  id,
  headerPlugins = [],
  editorPlugins = [],
  footerPlugins = [],
  nodes = [],
  placeholder,
  onChange,
  onError = (error: Error) => console.error(error),
}: BaseEditorProps) => {
  const editorId = `base-editor${id ? `-${id}` : ''}`;

  const initialConfig: InitialConfigType = {
    namespace: editorId,
    nodes: [
      ...nodes,
      {
        replace: ListItemNode,
        with: (node) => {
          return new CustomListItemNode();
        },
      },
    ],
    theme: baseEditorTheme,
    onError,
  };

  if (debug && process.env.NODE_ENV === 'development') {
    footerPlugins.push(<TreeViewPlugin key="_treeView" />);
  }

  const plugins = [
    ...headerPlugins,
    <EditorTextWrapper key="_editor">
      <RichTextPlugin
        contentEditable={<StyledContentEditable />}
        placeholder={placeholder ? <EditorPlaceholder>{placeholder}</EditorPlaceholder> : null}
        ErrorBoundary={LexicalErrorBoundary}
      />
    </EditorTextWrapper>,
    <OnChangePlugin key="_onChange" onChange={onChange} />,
    ...editorPlugins,
    ...footerPlugins,
  ];

  return (
    <EditorContainer className={className} data-testid={editorId} debug={debug} id={editorId}>
      <LexicalComposer initialConfig={initialConfig}>{plugins}</LexicalComposer>
    </EditorContainer>
  );
};
