import { components, OptionProps } from 'react-select';

import { CommonFilterOptionType } from '@components/SelectorCommonComponents';
import { Checkbox } from '@features/theme';

import { CustomOptionContainer, CustomOptionNameContainer } from './styles';

const { Option } = components;

/** Replaces react-select default Option template */
export const SelectorCustomOption = (props: OptionProps<CommonFilterOptionType>) => {
  const {
    data: { label, value },
    isSelected,
  } = props;

  return (
    <Option {...props}>
      <CustomOptionContainer key={value}>
        <Checkbox checked={isSelected} onToggle={() => null} />
        <CustomOptionNameContainer>{label}</CustomOptionNameContainer>
      </CustomOptionContainer>
    </Option>
  );
};
