import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import { GrammarReviewService } from '@services/GrammarReviewService';
import { DBId } from '@common/types/DBId';
import { GrammarReviewActionCreators } from '@actionCreators/GrammarReviewActionCreators';
import { SidePanel, SidePanelBody, SidePanelHeader } from '@features/theme';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { selectGrammarCategoryContent } from '@selectors/GrammarSelectors';

const StyledSidePanel = styled(SidePanel)`
  min-width: 590px;

  .modal-content {
    padding: 0 !important;
  }

  .modal-header {
    padding: 24px 40px;
    margin-bottom: 32px;
  }
`;

const PanelFooter = styled.div`
  position: fixed;
  width: 590px;
  bottom: 0px;
  background: ${({ theme }) => theme.colorV2.grammarCategoryIconsSelectorFooterBackground};
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: end;
  padding-right: 40px;
  box-shadow: 0px -1px 0px ${({ theme }) => theme.colorV2.grammarCategoryIconsSelectorFooterShadow};
`;

const Icon = styled.img`
  width: 68px;
  height: 68px;
`;

const IconsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 0 80px 56px;
`;

const IconContainer = styled.div<{ selected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 88px;
  height: 88px;
  margin: 0 42px 42px 0;
  border: 1px solid rgba(0, 0, 0, 0.1);
  ${(props) =>
    props.selected && `border: 4px solid ${props.theme.colorV2.grammarCategoryIconsSelectorContainerSelected};`}
  border-radius: 130px;

  :hover {
    background: ${({ theme }) => theme.colorV2.grammarCategoryIconsSelectorContainerHover};
    cursor: pointer;
  }
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 96px;
  border-radius: 24px;
  font-size: 16px;
  font-weight: 400;
`;

const CloseButton = styled(StyledButton)`
  border: 2px solid ${({ theme }) => theme.colorV2.grammarCategoryIconsSelectorButtonCloseBorder};
  font-weight: 700;
  color: ${({ theme }) => theme.colorV2.grammarCategoryIconsSelectorButtonCloseText};
  background: ${({ theme }) => theme.colorV2.grammarCategoryIconsSelectorButtonCloseBackground};

  :hover {
    color: ${({ theme }) => theme.colorV2.grammarCategoryIconsSelectorButtonCloseText};
    background: ${({ theme }) => theme.colorV2.grammarCategoryIconsSelectorButtonCloseHoverBackground};
  }
`;

const SaveButton = styled(StyledButton)`
  background: ${({ theme }) => theme.colorV2.grammarCategoryIconsSelectorButtonSaveText};
`;

export const IconsPanel = ({ closePanel }: { closePanel: () => void }) => {
  const dispatch = useAppDispatch();
  const { courseId } = useParams<{ courseId: DBId }>();
  const grammarContent = useAppSelector(selectGrammarCategoryContent);

  const [selectedIcon, setSelectedIcon] = useState<{ svg: string; pdf: string } | null>(grammarContent.icon);
  const icons: { svg: string; pdf: string }[] =
    JSON.parse(sessionStorage.getItem('grammar_category_icons') as string) || [];
  const [isIconsLoading, setIsIconsLoading] = useState<boolean>(!!icons.length);

  useEffect(() => {
    if (!icons.length && !isIconsLoading) {
      setIsIconsLoading(true);
      GrammarReviewService.getGrammarCategoryIcons(courseId).then((result) => {
        setIsIconsLoading(false);

        sessionStorage.setItem('grammar_category_icons', JSON.stringify(result.data));
      });
    }
  }, [courseId, icons.length, isIconsLoading]);

  return (
    <StyledSidePanel
      onClose={closePanel}
      header={<SidePanelHeader>Select category image</SidePanelHeader>}
      body={
        <>
          <SidePanelBody>
            <IconsContainer>
              {icons.map((icon, index) => (
                <IconContainer key={index} selected={!!selectedIcon && icon.svg === selectedIcon.svg}>
                  <Icon src={icon.svg} alt={`icon-number-${index}`} onClick={() => setSelectedIcon(icon)} />
                </IconContainer>
              ))}
            </IconsContainer>
          </SidePanelBody>
          <PanelFooter>
            <CloseButton onClick={closePanel}>Close</CloseButton>
            <SaveButton
              onClick={() => {
                selectedIcon && dispatch(GrammarReviewActionCreators.setGrammarCategoryIcon(selectedIcon));
                closePanel();
              }}
              disabled={!selectedIcon}
            >
              Save
            </SaveButton>
          </PanelFooter>
        </>
      }
    />
  );
};
