import React from 'react';
import { SimpleLanguageSelector } from '@components/SelectorCommonComponents';
import type { CourseInfoInterface } from '@common/interfaces/courses/CourseInfoInterface';

import type { StateConfig } from '../Common/useFiltersState';
import type { ExerciseQueryParamsType } from '../../service/types';
import { FiltersContainer } from '../Common/SearchHeader/FiltersContainer';
import { FilterField, SimpleCourseSelector, SimpleTypeSelector, SimpleYesNoSelector } from '../Common';
import { isFeatureEnabled } from '@helpers/featuresHelper';
import { EXERCISE_TITLES, EXERCISES } from '@common/enums/ExerciseTypes';
import { ContentTypes } from '@common/enums/ContentTypes';
import { Language } from '@features/content/languages';

let exerciseTypes = EXERCISES;
if (!isFeatureEnabled('speakingBites')) {
  exerciseTypes = exerciseTypes.filter((type) => type !== ContentTypes.listenRepeat);
}

type ExerciseSearchFiltersProps = {
  filtersState: StateConfig<ExerciseQueryParamsType>;
  availableCourses: CourseInfoInterface[];
  onSaveFilters?: (name: string) => Promise<void>;
};
export const ExerciseSearchFilters = ({
  filtersState,
  availableCourses,
  onSaveFilters,
}: ExerciseSearchFiltersProps) => {
  const filters = [
    <FilterField
      state={filtersState}
      name="languages"
      render={(value, onChange) => <SimpleLanguageSelector value={value} onChange={onChange} />}
    />,
    <FilterField
      state={filtersState}
      name="courses"
      render={(value, onChange) => (
        <SimpleCourseSelector
          courses={availableCourses}
          courseFilter={(course) =>
            filtersState.state.languages === null ||
            filtersState.state.languages.includes(course.learningLanguage as Language)
          }
          value={value}
          onChange={onChange}
        />
      )}
    />,
    <FilterField
      state={filtersState}
      name="exerciseTypes"
      render={(value, onChange) => (
        <SimpleTypeSelector
          types={exerciseTypes}
          labelGenerator={(type) => EXERCISE_TITLES[type]}
          value={value}
          onChange={onChange}
        />
      )}
    />,
  ];
  const secondaryFilters = [
    {
      filter: (
        <FilterField
          state={filtersState}
          name="hasImage"
          render={(value, onChange) => (
            <SimpleYesNoSelector placeholder="Has image" rule="Has image" value={value} onChange={onChange} />
          )}
        />
      ),
      label: 'Has image',
      onRemove: () => filtersState.resetField('hasImage'),
    },
    {
      filter: (
        <FilterField
          state={filtersState}
          name="isPublished"
          render={(value, onChange) => (
            <SimpleYesNoSelector placeholder="Is Published" rule="Is Published" value={value} onChange={onChange} />
          )}
        />
      ),
      label: 'Is Published',
      onRemove: () => filtersState.resetField('isPublished'),
    },
    {
      filter: (
        <FilterField
          state={filtersState}
          name="isReused"
          render={(value, onChange) => (
            <SimpleYesNoSelector placeholder="Is Reused" rule="Is Reused" value={value} onChange={onChange} />
          )}
        />
      ),
      label: 'Is Reused',
      onRemove: () => filtersState.resetField('isReused'),
    },
    {
      filter: (
        <FilterField
          state={filtersState}
          name="isInExperiment"
          render={(value, onChange) => (
            <SimpleYesNoSelector
              placeholder="Is in Experiment"
              rule="Is in Experiment"
              value={value}
              onChange={onChange}
            />
          )}
        />
      ),
      label: 'Is in Experiment',
      onRemove: () => filtersState.resetField('isInExperiment'),
    },
    {
      filter: (
        <FilterField
          state={filtersState}
          name="isUsedInGrammarReview"
          render={(value, onChange) => (
            <SimpleYesNoSelector
              placeholder="Used in Grammar Review"
              rule="Used in Grammar Review"
              value={value}
              onChange={onChange}
            />
          )}
        />
      ),
      label: 'Used in Grammar Review',
      onRemove: () => filtersState.resetField('isUsedInGrammarReview'),
    },
    {
      filter: (
        <FilterField
          state={filtersState}
          name="isUsedInPlacementTest"
          render={(value, onChange) => (
            <SimpleYesNoSelector
              placeholder="Used in Placement Test"
              rule="Used in Placement Test"
              value={value}
              onChange={onChange}
            />
          )}
        />
      ),
      label: 'Used in Placement Test',
      onRemove: () => filtersState.resetField('isUsedInPlacementTest'),
    },
  ];

  return (
    <FiltersContainer
      filters={filters.filter((filter) => Boolean(filter)) as NonNullable<(typeof filters)[number]>[]}
      secondaryFilters={
        (secondaryFilters || []).filter((filter) => Boolean(filter)) as NonNullable<(typeof secondaryFilters)[number]>[]
      }
      emptyFilters={filtersState.isEmpty}
      onSaveFilters={onSaveFilters}
    />
  );
};
