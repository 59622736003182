import styled from 'styled-components';
import { Fragment } from 'react';

import { DBId } from '@common/types/DBId';
import { Loader } from '@features/theme';
import { LinkedLesson } from './LinkedLesson';
import { CefrType, AvailableLessonsForTopic } from '../types';

const LinkedModalLevels = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 458px;
  max-height: 458px;
  justify-content: start;
  margin-bottom: 24px;
  overflow: auto;
  scroll-behavior: smooth;
`;

const CefrTitle = styled.span`
  font-weight: 700;
  font-size: 14px;
  color: ${({ theme }) => theme.colorV2.grammarTopicLinkedLessonsListText};
  text-transform: uppercase;
  margin-bottom: 24px;
`;

const NoLessons = styled.div`
  display: flex;
  align-self: center;
  font-size: 14px;
  color: ${({ theme }) => theme.colorV2.grammarTopicLinkedLessonsListText};
`;

const cefrFullNames: Record<CefrType, string> = {
  A1: 'Beginner a1',
  A2: 'Elementary a2',
  B1: 'Intermediate b1',
  B2: 'Upper Intermediate b2',
  C1: 'Advanced c1',
};

const NoLessonsMessage = () => <NoLessons>No available lessons</NoLessons>;

export const LinkedLessonsList = ({
  isNotLoadedYet,
  availableLessons,
  currentTopicId,
  currentSelectedLessons,
  onLessonToggle,
}: {
  isNotLoadedYet: boolean;
  availableLessons: AvailableLessonsForTopic[];
  currentTopicId: DBId;
  currentSelectedLessons: string[];
  onLessonToggle: (newCheckedValue: boolean, lessonId: DBId) => void;
}) => (
  <LinkedModalLevels id="lessons-list">
    {isNotLoadedYet ? (
      <Loader size="L" />
    ) : (
      <>
        {availableLessons.length ? (
          <>
            {availableLessons.map((lessonsData) => (
              <Fragment key={`${lessonsData.cefr}-title`}>
                <CefrTitle id={lessonsData.id}>{cefrFullNames[lessonsData.cefr]}</CefrTitle>
                {lessonsData.lessons.length ? (
                  <>
                    {lessonsData.lessons.map((lesson) => (
                      <LinkedLesson
                        key={`${lessonsData.cefr}-${lesson.id}`}
                        content={lesson}
                        currentTopicId={currentTopicId}
                        isSelected={currentSelectedLessons.includes(lesson.id)}
                        onToggle={(newValue) => onLessonToggle(newValue, lesson.id)}
                      />
                    ))}
                  </>
                ) : (
                  <NoLessonsMessage />
                )}
              </Fragment>
            ))}
          </>
        ) : (
          <NoLessonsMessage />
        )}
      </>
    )}
  </LinkedModalLevels>
);
