const GET_COURSE = 'ONE_COURSE_REQUEST';
const GET_CONTENT = 'COURSE_CONTENT_REQUEST';
const GET_ALL_EXERCISES_OF_PARENT_ID = 'COURSE_EXERCISES_REQUEST';
const COURSE_EXERCISES_REQUEST_SUCCEEDED = 'COURSE_EXERCISES_REQUEST_SUCCEEDED';
const GET_EXERCISE = 'COURSE_EXERCISE_REQUEST';
const GET_EXERCISE_FOR_ACTIVITY_PREVIEW = 'GET_EXERCISE_FOR_ACTIVITY_PREVIEW';
const SET_EXERCISE_FOR_ACTIVITY_PREVIEW = 'SET_EXERCISE_FOR_ACTIVITY_PREVIEW';
const GET_COURSE_WITH_ITS_GROUPS = 'ONE_COURSE_REQUEST_WITH_ITS_GROUPS';
const COURSE_EXERCISE_FLASHCARD_REQUEST_SUCCEEDED = 'COURSE_EXERCISE_FLASHCARD_REQUEST_SUCCEEDED';
const COURSE_EXERCISE_TIP_REQUEST_SUCCEEDED = 'COURSE_EXERCISE_TIP_REQUEST_SUCCEEDED';
const COURSE_EXERCISE_TRUEFALSE_REQUEST_SUCCEEDED = 'COURSE_EXERCISE_TRUEFALSE_REQUEST_SUCCEEDED';
const COURSE_EXERCISE_SPEECH_RECOGNITION_REQUEST_SUCCEEDED = 'COURSE_EXERCISE_SPEECH_RECOGNITION_REQUEST_SUCCEEDED';
const COURSE_EXERCISE_SPELLING_REQUEST_SUCCEEDED = 'COURSE_EXERCISE_SPELLING_REQUEST_SUCCEEDED';
const COURSE_EXERCISE_MULTIPLE_CHOICE_REQUEST_SUCCEEDED = 'COURSE_EXERCISE_MULTIPLE_CHOICE_REQUEST_SUCCEEDED';
const COURSE_EXERCISE_PHRASE_BUILDER_REQUEST_SUCCEEDED = 'COURSE_EXERCISE_PHRASE_BUILDER_REQUEST_SUCCEEDED';
const COURSE_EXERCISE_DIALOGUE_REQUEST_SUCCEEDED = 'COURSE_EXERCISE_DIALOGUE_REQUEST_SUCCEEDED';
const COURSE_EXERCISE_CONVERSATION_REQUEST_SUCCEEDED = 'COURSE_EXERCISE_CONVERSATION_REQUEST_SUCCEEDED';
const COURSE_EXERCISE_MATCHUP_REQUEST_SUCCEEDED = 'COURSE_EXERCISE_MATCHUP_REQUEST_SUCCEEDED';
const COURSE_EXERCISE_HIGHLIGHTER_REQUEST_SUCCEEDED = 'COURSE_EXERCISE_HIGHLIGHTER_REQUEST_SUCCEEDED';
const COURSE_EXERCISE_TYPING_REQUEST_SUCCEEDED = 'COURSE_EXERCISE_TYPING_REQUEST_SUCCEEDED';
const COURSE_EXERCISE_FILLGAP_REQUEST_SUCCEEDED = 'SECTIONACTIONS_COURSE_EXERCISE_FILLGAP_REQUEST_SUCCEEDED';
const COURSE_EXERCISE_COMPREHENSION_REQUEST_SUCCEEDED =
  'SECTIONACTIONS_COURSE_EXERCISE_COMPREHENSION_REQUEST_SUCCEEDED';
const COURSE_EXERCISE_SLIDE_PDF_REQUEST_SUCCEEDED = 'SECTIONACTIONS_COURSE_EXERCISE_SLIDE_PDF_REQUEST_SUCCEEDED';
const COURSE_EXERCISE_SLIDE_PPTX_REQUEST_SUCCEEDED = 'SECTIONACTIONS_COURSE_EXERCISE_SLIDE_PPTX_REQUEST_SUCCEEDED';
const COURSE_EXERCISE_LISTEN_REPEAT_REQUEST_SUCCEEDED =
  'SECTIONACTIONS_COURSE_EXERCISE_LISTEN_REPEAT_REQUEST_SUCCEEDED';
const SET_CURRENT_EXERCISE_AS_LOADING = 'SECTIONACTIONS_COURSE_EXERCISE_SET_AS_LOADING';
const GET_RECAP_OPTIONS_FOR_EXERCISE = 'GET_RECAP_OPTIONS_FOR_EXERCISE';
const RECAP_OPTIONS_REQUEST_SUCCEEDED = 'RECAP_OPTIONS_REQUEST_SUCCEEDED';

export const SectionActions = {
  GET_COURSE,
  GET_CONTENT,
  GET_ALL_EXERCISES_OF_PARENT_ID,
  COURSE_EXERCISES_REQUEST_SUCCEEDED,
  GET_EXERCISE,
  GET_EXERCISE_FOR_ACTIVITY_PREVIEW,
  SET_EXERCISE_FOR_ACTIVITY_PREVIEW,
  GET_COURSE_WITH_ITS_GROUPS,
  COURSE_EXERCISE_FLASHCARD_REQUEST_SUCCEEDED,
  COURSE_EXERCISE_TIP_REQUEST_SUCCEEDED,
  COURSE_EXERCISE_TRUEFALSE_REQUEST_SUCCEEDED,
  COURSE_EXERCISE_SPEECH_RECOGNITION_REQUEST_SUCCEEDED,
  COURSE_EXERCISE_SPELLING_REQUEST_SUCCEEDED,
  COURSE_EXERCISE_MULTIPLE_CHOICE_REQUEST_SUCCEEDED,
  COURSE_EXERCISE_PHRASE_BUILDER_REQUEST_SUCCEEDED,
  COURSE_EXERCISE_DIALOGUE_REQUEST_SUCCEEDED,
  COURSE_EXERCISE_CONVERSATION_REQUEST_SUCCEEDED,
  COURSE_EXERCISE_MATCHUP_REQUEST_SUCCEEDED,
  COURSE_EXERCISE_HIGHLIGHTER_REQUEST_SUCCEEDED,
  COURSE_EXERCISE_TYPING_REQUEST_SUCCEEDED,
  COURSE_EXERCISE_FILLGAP_REQUEST_SUCCEEDED,
  COURSE_EXERCISE_COMPREHENSION_REQUEST_SUCCEEDED,
  COURSE_EXERCISE_SLIDE_PDF_REQUEST_SUCCEEDED,
  COURSE_EXERCISE_SLIDE_PPTX_REQUEST_SUCCEEDED,
  COURSE_EXERCISE_LISTEN_REPEAT_REQUEST_SUCCEEDED,
  SET_CURRENT_EXERCISE_AS_LOADING,
  GET_RECAP_OPTIONS_FOR_EXERCISE,
  RECAP_OPTIONS_REQUEST_SUCCEEDED,
} as const;

// TODO: improve naming as per PR https://github.com/Busuu/logos-cms/pull/53/files#r517438558
