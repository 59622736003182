import { useAppDispatch } from '@redux/store';
import { useMemo, useState } from 'react';
import styled from 'styled-components/macro';

import { CommonFilterSelector, CommonFilterOptionType } from '@components/SelectorCommonComponents';
import { Button, Loader } from '@features/theme';
import { useUserList } from '@features/users';

import { ContentOwnershipsActionsCreator } from '@actionCreators/ContentOwnershipActionCreator';
import { ContentOwnerProps } from './types';

const OWNER_SELECTOR_CLASSNAME_PREFIX = 'owner-selector';

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

const Title = styled.span`
  color: ${({ theme }) => theme.colorV2.textPrimary};
  font-size: 2rem;
  font-weight: 700;
  line-height: 130%;
  margin-bottom: 0.8rem;
`;

const Description = styled.span`
  color: ${({ theme }) => theme.colorV2.textSecondary};
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 150%;
  margin-bottom: 0.8rem;
`;

const OwnerSelector = styled(CommonFilterSelector)`
  margin-bottom: 3.2rem;
  width: 100% !important;

  .owner-selector__value-container::before {
    display: none;
  }

  .owner-selector__option {
    text-align: left;
  }
`;

const CancelButton = styled(Button)`
  color: ${({ theme }) => theme.colorV2.textTertiary};
  margin-top: 0.8rem;
`;

const LoaderWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
`;

type AssignOwnerModalContentProps = Omit<ContentOwnerProps, 'owner'> & { close: () => void };

export const AssignOwnerModalContent = ({
  componentId,
  childComponentId,
  contentType,
  close,
}: AssignOwnerModalContentProps) => {
  const [loading, setLoading] = useState(false);
  const [selectedOwner, setSelectedOwner] = useState<CommonFilterOptionType | null>(null);

  const dispatch = useAppDispatch();
  const { userList } = useUserList({ storageKey: 'users-non-viewer', excludedRoles: ['ROLE_VIEWER'] });

  const ownerSelectorOptions: CommonFilterOptionType[] = useMemo(() => {
    return userList.map((user) => ({
      label: user.fullName,
      value: user.id,
    }));
  }, [userList]);

  const handleOnAssignClick = async () => {
    const { label: fullName, value: id } = selectedOwner as CommonFilterOptionType;

    setLoading(true);

    dispatch(
      ContentOwnershipsActionsCreator.changeOwner({
        componentId,
        childComponentId,
        contentType,
        owner: { id, fullName },
        commonCallback: () => {
          setLoading(false);
          close();
        },
      }),
    );
  };

  return (
    <ModalWrapper>
      <Title>Are you sure you want to reassign the ownership?</Title>
      <Description>
        This action will reassign ownership of this content and all related content within that belonged to the previous
        user.
      </Description>
      {loading ? (
        <LoaderWrapper>
          <Loader size="L" />
        </LoaderWrapper>
      ) : (
        <>
          <OwnerSelector
            classNamePrefix={OWNER_SELECTOR_CLASSNAME_PREFIX}
            options={ownerSelectorOptions}
            placeholder="Choose owner"
            value={selectedOwner}
            withSearchIcon
            onChange={(owner) => setSelectedOwner(owner)}
          />
          <div>
            <Button disabled={!selectedOwner} fullWidth onClick={handleOnAssignClick}>
              Assign
            </Button>
            <CancelButton fullWidth variant="text" onClick={close}>
              Cancel
            </CancelButton>
          </div>
        </>
      )}
    </ModalWrapper>
  );
};
