import { ContentTypes, ContentType, type ContentTypeType, type ContentTypesType } from '@common/enums/ContentTypes';
import { DBId } from '@common/types/DBId';
import { apiClient } from '@features/api';

type ChangeComponentOwnerParamsType = {
  componentId: DBId;
  childComponentId?: DBId;
  ownerId: string;
  contentType: ContentTypesType | ContentTypeType;
};

const getUrlForChangeOwner = ({
  componentId,
  childComponentId,
  contentType,
}: Omit<ChangeComponentOwnerParamsType, 'ownerId'>) => {
  let url;

  switch (contentType) {
    case ContentTypes.course:
      url = `/content/courses/${componentId}/owner`;
      break;
    case ContentType.levelOrLesson:
      url = `/content/groups/${componentId}/owner`;
      break;
    case ContentTypes.grammarReview:
      url = `/courses/${componentId}/grammar-review/owner`;
      break;
    case ContentTypes.grammarCategory:
      url = `/courses/${componentId}/grammar-review/categories/${childComponentId}/owner`;
      break;
    case ContentTypes.grammarTopic:
      url = `/courses/${componentId}/grammar-review/topics/${childComponentId}/owner`;
      break;
    case ContentTypes.placementTest:
      url = `/courses/${componentId}/placement-test/owner`;
      break;
    case ContentTypes.entrypoint:
      url = `/courses/${componentId}/placement-test/${childComponentId}/owner`;
      break;
    case ContentTypes.exercise:
      url = `/content/exercises/${componentId}/owner`;
      break;
  }

  return url;
};

export const ContentOwnershipService = {
  async changeComponentOwner({ componentId, childComponentId, ownerId, contentType }: ChangeComponentOwnerParamsType) {
    const url = getUrlForChangeOwner({ componentId, childComponentId, contentType });

    if (typeof url === 'undefined') {
      return Promise.reject(new Error(`No changeComponentOwner url fetched for contentType ${contentType}`));
    }

    return await apiClient.noErrorsV2.put(url, { ownerId });
  },
};
