import { ContentTypes } from '@common/enums/ContentTypes';
import { ACTIVITIES } from '@features/content/activities';
import { NavigationItemType } from '@features/content/navigation';

export const MOVEABLE_CONTENT_TYPES: Partial<NavigationItemType['type']>[] = [
  ContentTypes.chapter,
  ContentTypes.certificate,
  ContentTypes.checkpoint,
  ContentTypes.lesson,
  ContentTypes.liveLesson,
  ContentTypes.review,
  ContentTypes.roleplay,
  ContentTypes.speakingLesson,
  ContentTypes.comprehension,
  ContentTypes.conversation,
  ContentTypes.dialogue,
  ContentTypes.fillgap,
  ContentTypes.flashcard,
  ContentTypes.highlighter,
  ContentTypes.listenRepeat,
  ContentTypes.matchup,
  ContentTypes.multipleChoice,
  ContentTypes.phraseBuilder,
  ContentTypes.slidePdf,
  ContentTypes.slidePptx,
  ContentTypes.speechRecognition,
  ContentTypes.spelling,
  ContentTypes.tip,
  ContentTypes.trueFalse,
  ContentTypes.typing,
  ...ACTIVITIES,
];
