const COURSE_CREATE_SUCCEEDED = 'COURSE_CREATE_SUCCEEDED';
const COURSE_TOGGLE_UI_LANGUAGE = 'COURSE_TOGGLE_UI_LANGUAGE';
const COURSE_SET_MAIN_LANGUAGE = 'COURSE_SET_MAIN_LANGUAGE';
const SET_ALL_COURSE_UI_LANGUAGES_AS_TOGGLED = 'SET_ALL_COURSE_UI_LANGUAGES_AS_TOGGLED';

export const NewCoursePanelActions = {
  COURSE_CREATE_SUCCEEDED,
  COURSE_TOGGLE_UI_LANGUAGE,
  COURSE_SET_MAIN_LANGUAGE,
  SET_ALL_COURSE_UI_LANGUAGES_AS_TOGGLED,
} as const;
