import { ReactElement, useCallback, useState } from 'react';
import { RouteProps } from 'react-router-dom';
import { UserInterface } from '@common/interfaces/users/UserInterface';
import UsersService from '@services/UsersService';
import { isLocalAuthEnabled, LocalSecurity } from './local-security';
import { OktaSecurity } from './okta-security';
import { SecurityContext } from './context';

export interface SecurityInterface {
  SecurityProvider(props: { children: ReactElement }): ReactElement;
  LoginCallback(): ReactElement;
  LogoutCallback(): ReactElement;
  SecureRoute(props: RouteProps): ReactElement;
  getAuthorizationHeader(): string;
  logout: () => void;
}

const security = isLocalAuthEnabled ? new LocalSecurity() : new OktaSecurity();

export const getAuthorizationHeader = security.getAuthorizationHeader;
export const logout = security.logout;
export const SecureRoute = security.SecureRoute;
export const LoginCallback = security.LoginCallback;
export const LogoutCallback = security.LogoutCallback;

type SecurityProviderProps = {
  children: ReactElement;
  onSetUser?: (user: UserInterface | null) => void;
};
/**
 * @requires RoutingProvider
 */
export const SecurityProvider = ({ children, onSetUser }: SecurityProviderProps) => {
  const [user, setUser] = useState<UserInterface | null>(null);

  const setUserCallback = useCallback(
    (newUser: UserInterface | null) => {
      setUser(newUser);

      onSetUser && onSetUser(newUser);
    },
    [onSetUser],
  );

  const refreshUser = useCallback(async (): Promise<void> => {
    try {
      const result = await UsersService.getCurrentUser();

      setUserCallback(result.data);
    } catch (e) {
      setUserCallback(null);

      throw e;
    }
  }, [setUserCallback]);

  return (
    <security.SecurityProvider>
      <SecurityContext.Provider value={{ user, setUser: setUserCallback, refreshUser }}>
        {children}
      </SecurityContext.Provider>
    </security.SecurityProvider>
  );
};
