import { takeLatest } from 'redux-saga/effects';
import { ComprehensionExerciseActions } from '@actions/ComprehensionExerciseActions';
import MediaUploadSagas from '@sagas/mediaUpload/definitions/MediaUploadSagasDefinition';
import ComprehensionExerciseSagas from '@sagas/exercises/definitions/ComprehensionExerciseSagasDefinition';
import type { AppDispatch } from '@redux/store';

export function* ComprehensionExerciseRelatedSagas(dispatch: AppDispatch) {
  yield takeLatest(ComprehensionExerciseActions.UPLOAD_READING_IMAGE, MediaUploadSagas.uploadImageToExercise);

  yield takeLatest(ComprehensionExerciseActions.SAVE, ComprehensionExerciseSagas.save, dispatch);
}
