import styled from 'styled-components';

import audioCaseImage from './img/audioCase.svg';
import noneCaseImage from './img/noneCase.svg';

const FieldTitle = styled.h1`
  color: ${({ theme }) => theme.color.brandGreyscale800};
  font-size: 14px;
  margin-bottom: 8px;
`;

const RadioButtonsWrapper = styled.div`
  display: flex;
  font-size: 14px;
`;

const MediaVariantWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 50px;
`;

const RadioButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const CustomCheckedRadioInput = styled.input`
  &:checked {
    background-color: initial;
    cursor: default;
    appearance: auto;
    box-shadow: none;
  }
`;

type PhraseBuilderMediaVariantsInterface = {
  isAudioEnabled: boolean;
  onMediaTypeChange: (newMediaType: boolean) => void;
};

export const PhraseBuilderMediaVariants = ({
  isAudioEnabled,
  onMediaTypeChange,
}: PhraseBuilderMediaVariantsInterface) => (
  <>
    <FieldTitle>Media to display</FieldTitle>
    <RadioButtonsWrapper>
      <MediaVariantWrapper>
        <RadioButtonWrapper>
          <CustomCheckedRadioInput type="radio" onChange={() => onMediaTypeChange(false)} checked={!isAudioEnabled} />
          <span>None</span>
        </RadioButtonWrapper>
        <img src={noneCaseImage} alt="None" />
      </MediaVariantWrapper>

      <MediaVariantWrapper>
        <RadioButtonWrapper>
          <CustomCheckedRadioInput type="radio" onChange={() => onMediaTypeChange(true)} checked={isAudioEnabled} />
          <span>Audio</span>
        </RadioButtonWrapper>
        <img src={audioCaseImage} alt="Audio" />
      </MediaVariantWrapper>
    </RadioButtonsWrapper>
  </>
);
