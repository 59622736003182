import { startCase } from 'lodash';
import { useEffect, useState } from 'react';
import { Accordion, Button } from 'react-bootstrap';
import { ActionMeta, components, GroupHeadingProps, GroupProps, MultiValue, Options } from 'react-select';
import styled from 'styled-components/macro';

import { Checkbox } from '@features/theme';
import { Theme } from '@features/theme';

import { TRANSLATION_SELECTOR_GROUP } from '../constants';
import { CommonFilterOptionType, TranslationSelectorGroupType } from '../../types';
import { ReactComponent as ChevronIcon } from './img/chevron.svg';

const GroupHeadingWrapper = styled.div`
  :first-child {
    border-bottom: 0.1rem solid ${({ theme }) => theme.color.darkBlue15};
  }
`;

const GroupHeading = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colorV2.textPrimary};
  display: flex;
  justify-content: space-between;
  padding: 0.8rem 1.6rem;

  button {
    all: unset;
    align-self: flex-end;
  }
`;

const GroupHeadingLabel = styled.div`
  align-items: center;
  display: flex;
`;

const ButtonExpand = styled(ChevronIcon)<{ $expanded: boolean }>`
  transform: ${({ $expanded }) => ($expanded ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform 0.2s ease-in-out;
`;

// This hack is needed to Accordion to work with react-select (declared in TranslationSelectorGroup)
const TranslationSelectorGroupGroupHeading = (props: GroupHeadingProps<CommonFilterOptionType>) => <></>;

type TranslationSelectorGroupProps = GroupProps<CommonFilterOptionType> & {
  isAllSelected: boolean;
  selectedGroup: TranslationSelectorGroupType;
  setIsAllSelected: (isAllSelected: boolean) => void;
  setSelectedGroup: (selectedGroup: TranslationSelectorGroupType) => void;
};

const getTranslationSelectorGroupStyles = (
  defaultStyles: any,
  props: GroupProps<CommonFilterOptionType>,
  theme: Theme,
) => ({
  ...defaultStyles,
  borderTop: `0.1rem solid ${theme.color.darkBlue15}`,
  padding: '0.6rem 1.6rem',
  '> div': {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '0.8rem',
  },
});

const TranslationSelectorGroup = (props: TranslationSelectorGroupProps) => {
  const [checked, setChecked] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const { isAllSelected, label, options, selectedGroup, selectProps, setIsAllSelected, setSelectedGroup } = props;
  const eventKey = label === TRANSLATION_SELECTOR_GROUP.WITH ? '0' : '1';

  const normalizeValueFromOptions = (options: Options<CommonFilterOptionType>) =>
    options.map(
      (option: CommonFilterOptionType & { data?: CommonFilterOptionType }) => option.data,
    ) as MultiValue<CommonFilterOptionType>;

  const handleOnToogle = (checkedValue: boolean) => {
    const newValue = checkedValue ? normalizeValueFromOptions(options) : [];
    const selectedGroup = checkedValue ? label : undefined;

    setIsAllSelected(checkedValue);
    setSelectedGroup(selectedGroup as TranslationSelectorGroupType);
    selectProps.onChange(newValue, {} as ActionMeta<CommonFilterOptionType>);
  };

  useEffect(() => {
    setChecked(isAllSelected && selectedGroup === label);
  }, [isAllSelected, label, selectedGroup]);

  return (
    <GroupHeadingWrapper>
      <GroupHeading>
        <GroupHeadingLabel>
          <Checkbox checked={checked} title="Select/Unselect All" onToggle={handleOnToogle} />
          {startCase(label as string)}
        </GroupHeadingLabel>
        <Accordion.Toggle as={Button} variant="link" eventKey={eventKey}>
          <ButtonExpand $expanded={expanded} onClick={() => setExpanded(!expanded)} />
        </Accordion.Toggle>
      </GroupHeading>
      <Accordion.Collapse eventKey={eventKey}>
        <components.Group {...props} />
      </Accordion.Collapse>
    </GroupHeadingWrapper>
  );
};

export { TranslationSelectorGroupGroupHeading, TranslationSelectorGroup, getTranslationSelectorGroupStyles };
