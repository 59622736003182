import styled from 'styled-components';
import Avatar from 'react-avatar';
import { Button } from 'react-bootstrap';

const PersonalInfoCardWrapper = styled.div`
  padding: 4rem;
  border: 0.1rem solid ${({ theme }) => theme.color.brandGreyscale600};
  border-radius: 2rem;
`;

const PersonalInfoPageWrapper = styled.div`
  padding: 4rem;
  width: 100%;
`;

const PersonalInfoHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-height: 5.5rem;
  margin-bottom: 6rem;

  .dropdown-menu.show {
    transform: translate3d(-22rem, 4.5rem, 0) !important;
  }
`;

const PersonalInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1.6rem;
`;

const PersonalInfoTitle = styled.span`
  font-weight: 800;
  font-size: 3rem;
  letter-spacing: -0.015em;
  margin-bottom: 1.2rem;
`;

const PersonalInfoCard = styled(PersonalInfoCardWrapper)`
  display: flex;
  margin-top: 3rem;
  margin-bottom: 2rem;
`;

const ProfileAvatar = styled(Avatar)`
  margin-right: 4.2rem;
  border-radius: 50%;
`;

const CardDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ProfileName = styled.span`
  font-weight: 800;
  font-size: 1.8rem;
  margin-bottom: 2.4rem;
`;

const ProfileItem = styled.div`
  display: flex;
  margin-bottom: 2rem;
`;

const ProfileItemImage = styled.img`
  margin-right: 1.2rem;
`;

const FormHeader = styled.div`
  display: flex;
  font-weight: 800;
  font-size: 1.8rem;
  padding-bottom: 2.2rem;
  border-bottom: 0.1rem solid ${({ theme }) => theme.color.brandGreyscale600};
`;

const EditImage = styled.img`
  margin-left: 1rem;
  cursor: pointer;
`;

const FormDataItem = styled.div`
  margin-top: 4rem;
  display: grid;
  grid-template-columns: 25% 75%;
  align-items: center;
`;

const ItemFieldValue = styled.span<{ isBold: boolean }>`
  ${({ isBold }) => isBold && 'font-weight: 800;'}
`;

const ItemFieldInputWrapper = styled.div`
  max-width: 35rem;

  input {
    width: 100%;
    padding: 1rem 0.8rem;
    height: 4.6rem;
    border: 0.1rem solid ${({ theme }) => theme.color.brandGreyscale600};
    border-radius: 0.8rem;
  }
`;

const FooterWrapper = styled.div`
  display: flex;
  border-top: 0.1rem solid ${({ theme }) => theme.color.brandGreyscale600};
  width: 100%;
  padding-top: 2rem;
  margin-top: 1.6rem;
`;

const FooterButton = styled(Button)`
  height: 4.8rem;
  display: flex;
  align-items: center;
  border: 0.2rem solid ${({ theme }) => theme.color.brandPrimaryBase};
  border-radius: 3.2rem;
  margin: 0 1rem 0 0;
`;

const CancelButton = styled(FooterButton)`
  background: ${({ theme }) => theme.color.brandWhite};
  color: ${({ theme }) => theme.color.brandPrimaryBase};

  &:hover {
    background: ${({ theme }) => theme.color.brandWhite};
    color: ${({ theme }) => theme.color.brandPrimaryBase};
  }
`;

const SaveButton = styled(FooterButton)<{ $isInProgress: boolean }>`
  color: ${({ theme }) => theme.color.brandWhite};
  background: ${({ theme, $isInProgress }) => ($isInProgress ? theme.color.brandWhite : theme.color.brandPrimaryBase)};
`;

export {
  PersonalInfoCardWrapper,
  PersonalInfoPageWrapper,
  PersonalInfoHeader,
  PersonalInfoWrapper,
  PersonalInfoTitle,
  PersonalInfoCard,
  ProfileAvatar,
  CardDataWrapper,
  ProfileName,
  ProfileItem,
  ProfileItemImage,
  FormHeader,
  EditImage,
  FormDataItem,
  ItemFieldValue,
  ItemFieldInputWrapper,
  FooterWrapper,
  CancelButton,
  SaveButton,
};
