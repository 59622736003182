import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FormikContextType, useFormikContext } from 'formik';

import { AnyExerciseContentInterface } from '@common/types/exercises/AnyExerciseContentInterface';
import { ContentTypesActionsCreator } from '@actionCreators/ContentTypesActionsCreator';
import { saveContent } from '@components/CourseEdition/saveContent';
import { GrammarReviewSliceType } from '@common/interfaces/slices/GrammarReviewSliceType';
import { ContentTypes, type ContentTypesType } from '@common/enums/ContentTypes';
import helpersService from '@services/HelpersService';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { DBId } from '@common/types/DBId';
import { GrammarCategoryListItemType, GrammarReviewContentType } from './GrammarReview';
import { GrammarCategoryContentType, GrammarCategoryFormikValues, GrammarTopicListItemType } from './GrammarCategory';
import { GrammarTopicContentType } from './GrammarTopic';
import { ExerciseListItemType } from '@components/ContentTypes/ExerciseCard/types';
import { GrammarReviewActionCreators } from '@actionCreators/GrammarReviewActionCreators';
import type { LoadingStageType } from '@common/enums/LoadingStage';
import { selectCourse, selectIsSaveButtonEnabled, selectLoadedExercise } from '@selectors/CoursesSelectors';
import {
  selectGrammarCategory,
  selectGrammarCategoryTopics,
  selectGrammarExercise,
  selectGrammarReview,
  selectGrammarReviewCategories,
  selectGrammarTopic,
  selectGrammarTopicExercises,
} from '@selectors/GrammarSelectors';

// Return current grammar content (type, children array and save function)
export const useCurrentGrammarContent = () => {
  const dispatch = useAppDispatch();
  const formikContext: FormikContextType<GrammarCategoryFormikValues> = useFormikContext();
  const { courseId, categoryId, topicId, exerciseId } = useParams<{
    courseId: DBId;
    categoryId: DBId;
    topicId: DBId;
    exerciseId: DBId;
  }>();

  const isSaveButtonEnabled = useAppSelector(selectIsSaveButtonEnabled);

  const currentCourse = useAppSelector(selectCourse);
  const grammarReview = useAppSelector(selectGrammarReview);
  const categories = useAppSelector(selectGrammarReviewCategories);

  const grammarCategory = useAppSelector(selectGrammarCategory);
  const topics = useAppSelector(selectGrammarCategoryTopics);

  const grammarTopic = useAppSelector(selectGrammarTopic);
  const exercises = useAppSelector(selectGrammarTopicExercises);

  const grammarExercise = useAppSelector(selectGrammarExercise);
  const exercise = useAppSelector(selectLoadedExercise);

  // @TODO remove this when https://busuucom.atlassian.net/browse/INTO-2454 is fixed
  const grammarReviewSlice = {
    grammarReview,
    grammarCategory,
    grammarTopic,
  } as Extract<GrammarReviewSliceType, 'grammarReview' | 'grammarCategory' | 'grammarTopic'>;

  let grammarType: ContentTypesType = ContentTypes.grammarReview;
  let childrenArray: (GrammarCategoryListItemType | GrammarTopicListItemType | ExerciseListItemType)[] = categories;
  let grammarContent:
    | GrammarCategoryContentType
    | GrammarReviewContentType
    | GrammarTopicContentType
    | AnyExerciseContentInterface = grammarReview.content;
  let isGrammarContentLoaded: LoadingStageType = grammarReview.loaded;
  let parentId;
  let onSave = () => {};
  if (categoryId && topicId && exerciseId) {
    grammarType = ContentTypes.exercise;
    childrenArray = [];
    grammarContent = grammarExercise.content;
    isGrammarContentLoaded = grammarExercise.loaded;
    parentId = topicId;
    onSave = () => {
      saveContent(dispatch, exercise, currentCourse.learningLanguage, formikContext?.values);
      formikContext?.resetForm({ values: formikContext?.values });
      dispatch(ContentTypesActionsCreator.setSaveButtonState(false));
    };
  } else if (categoryId && topicId && !exerciseId) {
    grammarType = ContentTypes.grammarTopic;
    childrenArray = [...exercises.referenceExercises, ...exercises.bankExercises];
    grammarContent = grammarTopic.content;
    isGrammarContentLoaded = grammarTopic.loaded;
    parentId = categoryId;
    onSave = () => {
      dispatch(
        GrammarReviewActionCreators.saveTopic(courseId, categoryId, topicId, formikContext?.values, grammarReviewSlice),
      );
      formikContext?.resetForm({ values: formikContext?.values });
      dispatch(ContentTypesActionsCreator.setSaveButtonState(false));
    };
  } else if (categoryId && !topicId && !exerciseId) {
    grammarType = ContentTypes.grammarCategory;
    childrenArray = topics;
    grammarContent = grammarCategory.content;
    isGrammarContentLoaded = grammarCategory.loaded;
    parentId = courseId;
    onSave = () => {
      dispatch(
        GrammarReviewActionCreators.saveCategory(courseId, categoryId, formikContext?.values, grammarReviewSlice),
      );
      formikContext?.resetForm({ values: formikContext?.values });
      dispatch(ContentTypesActionsCreator.setSaveButtonState(false));
    };
  }

  // Update save button availability on content change
  useEffect(() => {
    const isGrammarReviewChanged = !categoryId && !topicId && formikContext?.dirty;
    const isGrammarReviewNotChanged = !categoryId && !topicId && !formikContext?.dirty;
    const isGrammarCategoryChanged =
      categoryId &&
      !topicId &&
      (grammarCategory.content.descriptionChanged || grammarCategory.content.iconChanged || formikContext?.dirty);
    const isGrammarCategoryNotChanged =
      categoryId &&
      !topicId &&
      !(grammarCategory.content.descriptionChanged || grammarCategory.content.iconChanged || formikContext?.dirty);

    const isGrammarTopicChanged =
      categoryId &&
      topicId &&
      !exerciseId &&
      (grammarTopic.content.titleChanged || grammarTopic.content.descriptionChanged || formikContext?.dirty);
    const isGrammarTopicNotChanged =
      categoryId &&
      topicId &&
      !exerciseId &&
      !(grammarTopic.content.titleChanged || grammarTopic.content.descriptionChanged || formikContext?.dirty);

    const isExerciseChanged =
      categoryId &&
      topicId &&
      exerciseId &&
      (formikContext?.dirty ||
        helpersService.checkIsExerciseChanged(
          exercise?.exercise?.content,
          exercise?.exercise?.type as unknown as ContentTypesType,
        ));
    const isExerciseNotChanged =
      categoryId &&
      topicId &&
      exerciseId &&
      !formikContext?.dirty &&
      !helpersService.checkIsExerciseChanged(
        exercise?.exercise?.content,
        exercise?.exercise?.type as unknown as ContentTypesType,
      );

    const isContentChanged =
      isGrammarReviewChanged || isGrammarCategoryChanged || isGrammarTopicChanged || isExerciseChanged;
    const isContentNotChanged =
      isGrammarReviewNotChanged || isGrammarCategoryNotChanged || isGrammarTopicNotChanged || isExerciseNotChanged;

    if (isContentChanged && !isSaveButtonEnabled) {
      dispatch(ContentTypesActionsCreator.setSaveButtonState(true));
    } else if (isContentNotChanged && isSaveButtonEnabled) {
      dispatch(ContentTypesActionsCreator.setSaveButtonState(false));
    }
  }, [
    formikContext,
    isSaveButtonEnabled,
    categoryId,
    topicId,
    exerciseId,
    exercise,
    grammarTopic.content,
    grammarCategory.content,
    dispatch,
  ]);

  return { grammarContent, isGrammarContentLoaded, parentId, grammarType, childrenArray, onSave };
};
