import { takeLatest } from 'redux-saga/effects';

import type { AppDispatch } from '@redux/store';

import { RoleplayActions } from '@actions/RoleplayActions';
import { RoleplaySagas } from '@sagas/roleplay/definitions/RoleplaySagasDefinition';

export function* RoleplayRelatedSagas(dispatch: AppDispatch) {
  // Roleplay sagas
  yield takeLatest(RoleplayActions.GET_ROLEPLAY, RoleplaySagas.getRoleplay);
  yield takeLatest(RoleplayActions.GET_ROLEPLAY_CATEGORIES, RoleplaySagas.getRoleplayCategories);
  yield takeLatest(RoleplayActions.CREATE_ROLEPLAY_CATEGORY, RoleplaySagas.createRoleplayCategory);
  yield takeLatest(RoleplayActions.REMOVE_ROLEPLAY_CATEGORY, RoleplaySagas.removeRoleplayCategory);
  yield takeLatest(RoleplayActions.UPDATE_ROLEPLAY_CATEGORIES_ORDER, RoleplaySagas.reorderRoleplayCategories);

  // Roleplay Category sagas
  yield takeLatest(RoleplayActions.GET_ROLEPLAY_CATEGORY, RoleplaySagas.getRoleplayCategory);
  yield takeLatest(RoleplayActions.GET_ROLEPLAY_SCENARIOS, RoleplaySagas.getRoleplayScenarios);
  yield takeLatest(RoleplayActions.CREATE_ROLEPLAY_SCENARIO, RoleplaySagas.createScenario);
  yield takeLatest(RoleplayActions.SAVE_CATEGORY, RoleplaySagas.saveCategory, dispatch);
  yield takeLatest(RoleplayActions.REMOVE_ROLEPLAY_SCENARIO, RoleplaySagas.removeScenario);
  yield takeLatest(RoleplayActions.UPDATE_ROLEPLAY_SCENARIOS_ORDER, RoleplaySagas.reorderScenarios);

  // Roleplay Scenario sagas
  yield takeLatest(RoleplayActions.GET_SCENARIO, RoleplaySagas.getScenario);
  yield takeLatest(RoleplayActions.SAVE_SCENARIO, RoleplaySagas.saveScenario, dispatch);

  // Roleplay Navigation sagas
  yield takeLatest(RoleplayActions.GET_ALL_NAVIGATION, RoleplaySagas.getAllNavigation);

  // Common sagas
  yield takeLatest(RoleplayActions.REMOVE_STRING, RoleplaySagas.removeString);
}
