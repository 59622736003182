import produce from 'immer';
import { CourseSliceInterface } from '@common/interfaces/slices/CourseSliceInterface';
import { NavigationActions } from '@actions/NavigationActions';
import { PayloadAction } from '@reduxjs/toolkit';
import { DBId } from '@common/types/DBId';

const NavigationActionsCourseReducers = {
  [NavigationActions.UPDATE_NAVIGATION]: (state: CourseSliceInterface, { payload }: PayloadAction<any>) => {
    return produce(state, (draft) => {
      draft.course.structure = payload;
    });
  },
  [NavigationActions.SET_LOADING_PARENT_ID]: (state: CourseSliceInterface, { payload }: PayloadAction<DBId>) => {
    return produce(state, (draft) => {
      draft.course.loadingParentId = payload;
    });
  },
};

export default NavigationActionsCourseReducers;
