import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Flag } from '@components/Flag';
import { CourseInfoInterface } from '@common/interfaces/courses/CourseInfoInterface';
import { type Language, LANGUAGE_NAMES, mapLanguageToLanguageV2 } from '@features/content/languages';
import placeholderImage from '@static/png/placeholder-image.png';

import { DashboardTableItemsInterface } from '../../types';
import grammarImage from './_img/grammarImage.svg';
import placementTestImage from './_img/placementTestImage.svg';
import roleplayImage from './_img/roleplayImage.svg';
import vocabularyReviewImage from './_img/vocabularyReviewImage.svg';

type DashboardTableItemProps = {
  course: CourseInfoInterface;
  itemType: DashboardTableItemsInterface['itemType'];
};

const DashboardTableItemContainer = styled.div`
  display: grid;
  grid-template-columns: 54% 23% 23%;
  align-items: center;
  border-top: 0;
  border-bottom: 0.1rem solid ${({ theme }) => theme.color.brandGreyscale600};
  font-size: 1.5rem;

  &:hover {
    background-color: ${({ theme }) => theme.color.brandGreyscale600};
  }
`;

const DashboardTableItemCourseImage = styled.img`
  width: 8rem;
  height: 4.8rem;
  margin-right: 1.6rem;
  border: 0.1rem solid rgba(0, 0, 0, 0.1);
  border-radius: 0.4rem;
`;

const DashboardTableItemLink = styled(Link)`
  padding: 0.8rem;
  color: ${({ theme }) => theme.color.brandBlack};
`;

const getLink = (course: CourseInfoInterface, itemType: DashboardTableItemsInterface['itemType']) => {
  const learningLanguage = mapLanguageToLanguageV2(course.learningLanguage as Language);

  switch (itemType) {
    case 'course':
      return `/course/${course.id}`;
    case 'grammar':
      return `/course/${course.id}/grammar-review`;
    case 'placementTest':
      return `/course/${course.id}/placement-test`;
    case 'roleplay':
      return `roleplay/${learningLanguage}`;
    case 'vocabularyReview':
      return `vocabulary-review/${learningLanguage}`;
    default:
      return '';
  }
};

const getImageProps = (course: CourseInfoInterface, itemType: DashboardTableItemsInterface['itemType']) => {
  switch (itemType) {
    case 'course':
      return {
        alt: `${course.title} course`,
        src: !course.thumbnailImage ? placeholderImage : course.thumbnailImage,
        title: `${course.title} course`,
      };
    case 'grammar':
      return {
        alt: course.title,
        src: grammarImage,
        title: course.title,
      };
    case 'placementTest':
      return {
        alt: course.title,
        src: placementTestImage,
        title: course.title,
      };
    case 'roleplay':
      const roleplayTitle = `${LANGUAGE_NAMES[course.learningLanguage as Language]} Roleplay`;
      return {
        alt: roleplayTitle,
        src: roleplayImage,
        title: roleplayTitle,
      };
    case 'vocabularyReview':
      const vocabularyTitle = `${LANGUAGE_NAMES[course.learningLanguage as Language]} Vocabulary Review`;
      return {
        alt: vocabularyTitle,
        src: vocabularyReviewImage,
        title: vocabularyTitle,
      };
    default:
      return {};
  }
};

export const DashboardTableItem = ({ course, itemType }: DashboardTableItemProps) => (
  <DashboardTableItemContainer data-testid={`dashboard-table-item-${course.id}`}>
    <DashboardTableItemLink to={getLink(course, itemType)}>
      <DashboardTableItemCourseImage {...getImageProps(course, itemType)} />
      {course.title}
    </DashboardTableItemLink>
    <Flag countries={course.learningLanguage} forCoursesList />
    <Flag countries={course.interfaceLanguages} forCoursesList />
  </DashboardTableItemContainer>
);
