import { apiClient } from '@features/api';
import { type ExerciseTypesType } from '@common/enums/ExerciseTypes';
import { DBId } from '@common/types/DBId';

export const PlacementTestService = {
  async getPlacementTest(courseId: DBId) {
    return await apiClient.noErrorsV2.get(`/courses/${courseId}/placement-test`);
  },
  async createPlacementTest(courseId: DBId) {
    return await apiClient.noErrorsV2.post(`/courses/${courseId}/placement-test`);
  },
  async getEntrypoints(courseId: DBId) {
    return await apiClient.noErrorsV2.get(`/courses/${courseId}/placement-test/entrypoints`);
  },
  // Navigation requests
  async getPlacementTestNavigation(courseId: DBId) {
    return await apiClient.noErrorsV2.get(`/navigation/courses/${courseId}/placement-test/entrypoint`);
  },
  async getEntrypointNavigation(
    courseId: DBId,
    entrypointId: DBId,
  ): Promise<{ status: number; data: { exercises: any[] } }> {
    return await apiClient.noErrorsV2.get(
      `/navigation/courses/${courseId}/placement-test/entrypoint/${entrypointId}/exercises`,
    );
  },
  async publishPlacementTest(courseId: DBId) {
    return await apiClient.v2.post(`/courses/${courseId}/placement-test/publish`);
  },
  async unpublishPlacementTest(courseId: DBId) {
    return await apiClient.v2.post(`/courses/${courseId}/placement-test/unpublish`);
  },
  // Entrypoint requests
  async createExercise(exerciseType: ExerciseTypesType, payload: { position: number; entrypointId: DBId }) {
    return await apiClient.v2.post(`content/exercises`, {
      type: exerciseType,
      parentId: payload.entrypointId,
      parentType: 'placementTestEntrypoint',
      position: payload.position,
    });
  },
  async getEntrypoint(courseId: DBId, entrypointId: DBId) {
    return await apiClient.noErrorsV2.get(`/courses/${courseId}/placement-test/${entrypointId}`);
  },
  async getEntrypointValidationResult(courseId: DBId, entrypointId: DBId) {
    return await apiClient.noErrorsV2.get(`/courses/${courseId}/placement-test/${entrypointId}/status`);
  },
  async getChapters(courseId: DBId, entrypointId: DBId) {
    return await apiClient.noErrorsV2.get(`/courses/${courseId}/placement-test/${entrypointId}/available-chapters`);
  },
  async getEntrypointExercises(courseId: DBId, entrypointId: DBId) {
    return await apiClient.noErrorsV2.get(`/courses/${courseId}/placement-test/${entrypointId}/exercises`);
  },
  async saveEntrypoint(courseId: DBId, entrypointId: DBId, requestPayload: { chapter: string }) {
    return await apiClient.v2.put(`/courses/${courseId}/placement-test/${entrypointId}`, { ...requestPayload });
  },
  async removeExercise(courseId: DBId, entrypointId: DBId, exerciseId: DBId) {
    return await apiClient.v2.post(`/courses/${courseId}/placement-test/${entrypointId}/detach-exercise/${exerciseId}`);
  },
  async publishEntrypoint(courseId: DBId, entrypointId: DBId, parentId: DBId) {
    return await apiClient.v2.post(`/courses/${courseId}/placement-test/${entrypointId}/publish`, { parentId });
  },
  async unpublishEntrypoint(courseId: DBId, entrypointId: DBId, parentId: DBId) {
    return await apiClient.v2.post(`/courses/${courseId}/placement-test/${entrypointId}/unpublish`, {
      parentId,
    });
  },
  async useExercise(courseId: DBId, entrypointId: DBId, exerciseId: DBId, position: number) {
    return await apiClient.noErrorsV2.post(
      `/courses/${courseId}/placement-test/${entrypointId}/attach-exercise/${exerciseId}`,
      { position },
    );
  },
};
