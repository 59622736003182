import styled from 'styled-components/macro';

import { Flag, FlagTextPositionMode } from '@components/Flag';
import { Language } from '@features/content/languages';

const FlagSelectableContainer = styled.div`
  align-items: end;
  display: flex;
  justify-content: center;
`;

type FlagSelectableProps = {
  language: Language;
  checked: boolean;
  onChange: () => void;
};
export const FlagSelectable = ({ language, checked, onChange }: FlagSelectableProps) => {
  return (
    <FlagSelectableContainer>
      <input type="checkbox" className="mr-2" onChange={() => onChange()} checked={checked} />
      <Flag countries={language} mode={FlagTextPositionMode.after} onClick={() => onChange()}></Flag>
    </FlagSelectableContainer>
  );
};
