import { registerConfig } from '@features/config';
import { AuthConfig } from './config';

registerConfig<AuthConfig>('auth', {
  issuer: process.env.REACT_APP_OKTA_ISSUER,
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
});

/**
 * @description Security context is useful to mock auth in tests and stories
 */
export { SecurityContext } from './context';

/**
 * @description useUser hooks returns information about the current user
 */
export { useUser } from './hooks';

export {
  /**
   * @description Security provider stores current user information
   */
  SecurityProvider,
  /**
   * @description Secure route requests user authentication, if user is not authenticated yet
   */
  SecureRoute,
  /**
   * @description Login callback handles redirects from auth provider back to the app
   */
  LoginCallback,
  /**
   * @description Logout callback handles redirects from auth provider back to the app
   */
  LogoutCallback,
  /**
   * @description Logout method starts user logout process
   */
  logout,
  /**
   * @description Get authorization header returns header for api requests
   */
  getAuthorizationHeader,
} from './security';
