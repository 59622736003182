import { put, call, delay, select } from 'redux-saga/effects';

import { GrammarReviewService } from '@services/GrammarReviewService';
import { CommonActions } from '@actions/CommonActions';
import { PayloadAction } from '@reduxjs/toolkit';
import { GrammarReviewActions } from '@actions/GrammarReviewActions';
import { courseSlice } from '@redux/slices/courseSlice';
import { getNewArray } from '@helpers/newAddedElementHelper';
import CourseSagas from '@sagas/courses/definitions/CourseSagasDefinition';
import { CoursesActions } from '@actions/CoursesActions';
import { SearchActions } from '@actions/SearchActions';
import { ContentTypes, type ContentTypesType } from '@common/enums/ContentTypes';
import { DBId } from '@common/types/DBId';
import { SearchService } from '@features/search';
import { ContentTypesActions } from '@actions/ContentTypesActions';
import {
  GrammarCategoryContentType,
  GrammarCategoryEditableFieldNames,
  GrammarCategoryListItemType,
  GrammarTopicListItemType,
  GrammarTopicContentType,
  GrammarTopicFormikValues,
  GrammarTopicEditableFieldNames,
  GrammarCategoryFormikValues,
} from '@features/content/grammar';
import { ExerciseListItemType } from '@components/ContentTypes/ExerciseCard/types';
import { NavigationItemType } from '@features/content/navigation';
import MediaService from '@services/MediaService';
import { UploadMediaResponse } from '@services/HelpersService';
import { MediaTypes } from '@common/enums/MediaTypes';
import { LocalizationInterface } from '@common/interfaces/localization/LocalizationInterface';
import { TranslationsPanelContentInterface } from '@common/interfaces/exercises/TranslationsPanelContentInterface';
import { EXERCISE_TIP, ExerciseTypes, type ExerciseTypesType } from '@common/enums/ExerciseTypes';
import { grammarReviewInitialContent } from '@redux/initialStates/grammarReviewInitialState';
import { LoadingStage } from '@common/enums/LoadingStage';
import { addToast } from '@features/app/toast';
import { SearchModalActionsCreator } from '@actionCreators/SearchModalActionsCreator';
import { GrammarReviewActionCreators } from '@actionCreators/GrammarReviewActionCreators';
import { AppDispatch } from '@redux/store';
import {
  selectGrammarCategoryContent,
  selectGrammarCategoryTopics,
  selectGrammarNavigationStructure,
  selectGrammarReviewCategories,
  selectGrammarReviewContent,
  selectGrammarTopicBankExercises,
  selectGrammarTopicContent,
  selectGrammarTopicExercises,
  selectGrammarTopicReferenceExercises,
} from '@selectors/GrammarSelectors';
import { selectGrammarTranslationsPanel } from '@selectors/UiSelectors';

const newAddedExerciseData = {
  changeStatus: {
    hasPendingChanges: false,
    hasNewChanges: true,
  },
  validationStatus: {
    invalidChildEntities: [],
    validated: false,
    valid: false,
    errors: [],
  },
  previewText: null,
  imageData: null,
  audio: null,
  name: null,
};

export const GrammarReviewSagas = {
  // Navigation sagas
  *getAllNavigation({
    payload: { courseId, categoryId, topicId },
  }: PayloadAction<{ courseId: DBId; categoryId: DBId; topicId: DBId }>) {
    try {
      const grammarReviewContent: ReturnType<typeof selectGrammarReviewContent> =
        yield select(selectGrammarReviewContent);
      const navigation: ReturnType<typeof selectGrammarNavigationStructure> = yield select(
        selectGrammarNavigationStructure,
      );
      let categories = [];
      let topics = [];
      let exercises = [];
      if (courseId && !navigation.filter((elem) => elem.parentId === courseId).length) {
        categories = yield call(GrammarReviewSagas.getGrammarReviewNavigation, { courseId });
      }
      if (categoryId && !navigation.filter((elem) => elem.parentId === categoryId).length) {
        topics = yield call(GrammarReviewSagas.getGrammarCategoryNavigation, { courseId, categoryId });
      }
      if (topicId && !navigation.filter((elem) => elem.parentId === topicId).length) {
        exercises = yield call(GrammarReviewSagas.getGrammarTopicNavigation, { courseId, topicId });
      }
      if (categories?.length || topics.length || exercises.length) {
        yield put({
          type: GrammarReviewActions.UPDATE_NAVIGATION,
          payload: [
            {
              id: courseId,
              title: 'Grammar Review',
              type: ContentTypes.grammarReview,
              children: !!categoryId || !!categories.length,
              ready: grammarReviewContent.ready,
              parentId: null,
              expanded: true,
            },
            ...categories.map((category: NavigationItemType) => ({
              ...category,
              expanded: category.id === categoryId,
            })),
            ...topics.map((topic: NavigationItemType) => ({ ...topic, expanded: topic.id === topicId })),
            ...exercises,
          ],
        });
      } else if (navigation.find((elem) => elem.type === ContentTypes.grammarReview)?.id !== courseId) {
        yield put({
          type: GrammarReviewActions.UPDATE_NAVIGATION,
          payload: [
            {
              id: courseId,
              title: 'Grammar Review',
              type: ContentTypes.grammarReview,
              children: false,
              ready: grammarReviewContent.ready,
              parentId: null,
              expanded: true,
            },
          ],
        });
      }
    } catch (e) {
      console.error(e);
    }
  },
  *getGrammarReviewNavigation({ courseId }: { courseId: DBId }) {
    try {
      yield put({ type: GrammarReviewActions.SET_LOADING_PARENT_ID, payload: courseId });

      const reviewNavigationResult: Awaited<ReturnType<typeof GrammarReviewService.getReviewNavigation>> = yield call(
        GrammarReviewService.getReviewNavigation,
        courseId,
      );

      if (reviewNavigationResult.status === 200) {
        yield put({ type: GrammarReviewActions.SET_LOADING_PARENT_ID, payload: '' });
        return reviewNavigationResult.data.categories.map((category: NavigationItemType) => ({
          ...category,
          parentId: courseId,
          type: ContentTypes.grammarCategory,
        }));
      } else {
        return [];
      }
    } catch (e) {
      yield put({ type: GrammarReviewActions.SET_LOADING_PARENT_ID, payload: '' });
      console.error(e);
    }
  },
  *getGrammarCategoryNavigation({ courseId, categoryId }: { courseId: DBId; categoryId: DBId }) {
    try {
      yield put({ type: GrammarReviewActions.SET_LOADING_PARENT_ID, payload: categoryId });

      const categoryNavigationResult: Awaited<ReturnType<typeof GrammarReviewService.getCategoryNavigation>> =
        yield call(GrammarReviewService.getCategoryNavigation, courseId, categoryId);

      if (categoryNavigationResult.status === 200) {
        yield put({ type: GrammarReviewActions.SET_LOADING_PARENT_ID, payload: '' });
        return categoryNavigationResult.data.topics.map((topic: NavigationItemType) => ({
          ...topic,
          parentId: categoryId,
          type: ContentTypes.grammarTopic,
        }));
      }
    } catch (e) {
      console.error(e);
      return [];
    }
  },
  *getGrammarTopicNavigation({ courseId, topicId }: { courseId: DBId; topicId: DBId }) {
    try {
      yield put({ type: GrammarReviewActions.SET_LOADING_PARENT_ID, payload: topicId });

      const topicNavigationResult: Awaited<ReturnType<typeof GrammarReviewService.getTopicNavigation>> = yield call(
        GrammarReviewService.getTopicNavigation,
        courseId,
        topicId,
      );

      if (topicNavigationResult.status === 200) {
        yield put({ type: GrammarReviewActions.SET_LOADING_PARENT_ID, payload: '' });
        return topicNavigationResult.data.exercises.map((exercise: NavigationItemType) => ({
          ...exercise,
          parentId: topicId,
        }));
      } else {
        return [];
      }
    } catch (e) {
      console.error(e);
      return [];
    }
  },
  *updateGrammarReviewNavigation({ courseId, categoryId }: { courseId: DBId; categoryId?: DBId }) {
    try {
      const grammarReviewContent: ReturnType<typeof selectGrammarReviewContent> =
        yield select(selectGrammarReviewContent);
      const navigation: ReturnType<typeof selectGrammarNavigationStructure> = yield select(
        selectGrammarNavigationStructure,
      );

      let categoriesNavigation = [];

      if (courseId) {
        categoriesNavigation = yield call(GrammarReviewSagas.getGrammarReviewNavigation, { courseId });
      }

      yield put({
        type: GrammarReviewActions.UPDATE_NAVIGATION,
        payload: [
          ...navigation.filter(
            (element) => element.parentId !== courseId && element.type !== ContentTypes.grammarReview,
          ),
          {
            id: courseId,
            title: 'Grammar Review',
            type: ContentTypes.grammarReview,
            children: !!categoriesNavigation.length,
            ready: grammarReviewContent.ready,
            parentId: null,
            expanded: true,
          },
          ...categoriesNavigation.map((category: NavigationItemType) => ({
            ...category,
            expanded: categoryId === category.id,
          })),
        ],
      });
    } catch (e) {
      console.error(e);
      return [];
    }
  },
  *updateGrammarCategoryNavigation({ courseId, categoryId }: { courseId: DBId; categoryId: DBId }) {
    try {
      const navigation: ReturnType<typeof selectGrammarNavigationStructure> = yield select(
        selectGrammarNavigationStructure,
      );

      let topicsNavigation = [];

      if (categoryId) {
        topicsNavigation = yield call(GrammarReviewSagas.getGrammarCategoryNavigation, { courseId, categoryId });
      }

      yield put({
        type: GrammarReviewActions.UPDATE_NAVIGATION,
        payload: [
          ...navigation
            .filter((element) => element.parentId !== categoryId)
            .map((element) => ({
              ...element,
              expanded: categoryId === element.id ? topicsNavigation.length : element.expanded,
              children: categoryId === element.id ? topicsNavigation.length : element.children,
            })),
          ...topicsNavigation,
        ],
      });
    } catch (e) {
      console.error(e);
      return [];
    }
  },
  *updateGrammarTopicNavigation({ courseId, topicId }: { courseId: DBId; topicId: DBId }) {
    try {
      const navigation: ReturnType<typeof selectGrammarNavigationStructure> = yield select(
        selectGrammarNavigationStructure,
      );

      let exercisesNavigation = [];

      if (topicId) {
        exercisesNavigation = yield call(GrammarReviewSagas.getGrammarTopicNavigation, { courseId, topicId });
      }

      yield put({
        type: GrammarReviewActions.UPDATE_NAVIGATION,
        payload: [
          ...navigation
            .filter((element) => element.parentId !== topicId)
            .map((element) => ({
              ...element,
              expanded: topicId === element.id ? exercisesNavigation.length : element.expanded,
              children: topicId === element.id ? exercisesNavigation.length : element.children,
            })),
          ...exercisesNavigation,
        ],
      });
    } catch (e) {
      console.error(e);
      return [];
    }
  },

  // Review sagas
  *getGrammarReview({ payload: { courseId } }: PayloadAction<{ courseId: DBId }>) {
    try {
      yield put({ type: GrammarReviewActions.GRAMMAR_REVIEW_LOADING });

      const result: Awaited<ReturnType<typeof GrammarReviewService.getGrammarReview>> = yield call(
        GrammarReviewService.getGrammarReview,
        courseId,
      );

      if (result.status === 200) {
        yield put({ type: GrammarReviewActions.GRAMMAR_REVIEW_LOADED, payload: result.data });
      }
    } catch (e: any) {
      if (e?.response?.status === 404) {
        yield put({ type: GrammarReviewActions.GRAMMAR_REVIEW_LOADED, payload: grammarReviewInitialContent });

        yield put({
          type: GrammarReviewActions.UPDATE_NAVIGATION,
          payload: [
            {
              id: courseId,
              title: 'Grammar Review',
              type: ContentTypes.grammarReview,
              children: false,
              ready: false,
              parentId: null,
              expanded: false,
            },
          ],
        });
      }
      console.error(e);
    }
  },
  *getGrammarCategories({ payload: { courseId, reviewId } }: PayloadAction<{ courseId: DBId; reviewId: DBId }>) {
    try {
      yield put({ type: GrammarReviewActions.GRAMMAR_CATEGORIES_LOADING });

      if (reviewId) {
        const categoriesResult: Awaited<ReturnType<typeof GrammarReviewService.getGrammarCategories>> = yield call(
          GrammarReviewService.getGrammarCategories,
          courseId,
        );

        if (categoriesResult.status === 200) {
          yield put({
            type: GrammarReviewActions.GRAMMAR_CATEGORIES_LOADED,
            payload: categoriesResult.data.categories,
          });
        }
      } else {
        yield put({ type: GrammarReviewActions.GRAMMAR_CATEGORIES_LOADED, payload: [] });
      }
    } catch (e) {
      console.error(e);
    }
  },
  *createGrammarCategory({ payload: { courseId, position } }: PayloadAction<{ courseId: DBId; position: number }>) {
    try {
      const categories: ReturnType<typeof selectGrammarReviewCategories> = yield select(selectGrammarReviewCategories);

      yield put(courseSlice.actions.setIsCreatingContent(position));

      let createResult: Awaited<ReturnType<typeof GrammarReviewService.createCategory>>;
      try {
        createResult = yield call(GrammarReviewService.createCategory, courseId, position);
      } finally {
        yield put(courseSlice.actions.setIsCreatingContent(undefined));
      }

      const newElementId = createResult.data.id;
      if (createResult.status === 200 && newElementId) {
        const newGroupElement = {
          id: newElementId,
          validationStatus: createResult.data.validationStatus,
          changeStatus: {
            hasPendingChanges: false,
            hasNewChanges: false,
          },
          ready: false,
          type: ContentTypes.grammarCategory,
        };
        const newCategories = getNewArray(categories, newGroupElement, position);

        yield put({
          type: GrammarReviewActions.GRAMMAR_CATEGORIES_LOADED,
          payload: newCategories,
        });

        yield call(GrammarReviewSagas.updateGrammarReviewNavigation, { courseId });

        yield call(CourseSagas.getValidationResult, {
          type: ContentTypes.grammarReview,
        });
      }

      yield put({
        type: CoursesActions.SET_POSITION_OF_NEW_CONTENT,
        payload: { newElementId },
      });

      yield delay(2000);

      yield put({
        type: CoursesActions.SET_POSITION_OF_NEW_CONTENT,
        payload: {},
      });
    } catch (e) {
      console.error(e);
    }
  },
  *reorderCategories({
    payload,
  }: PayloadAction<{ courseId: DBId; orderedItems: GrammarCategoryListItemType[]; destinationIndex: number }>) {
    try {
      const { courseId, orderedItems, destinationIndex } = payload;

      yield put({
        type: GrammarReviewActions.GRAMMAR_CATEGORIES_LOADED,
        payload: orderedItems,
      });

      const categoryId = orderedItems[destinationIndex].id;

      const changeGroupOrderPayload = {
        categoryId,
        newIndex: destinationIndex,
      };

      yield call(GrammarReviewService.reorderCategories, { courseId, ...changeGroupOrderPayload });

      yield call(GrammarReviewSagas.updateGrammarReviewNavigation, { courseId });

      yield call(CourseSagas.getValidationResult, {
        type: ContentTypes.grammarReview,
      });
    } catch (e) {
      console.error(e);
    }
  },
  *removeCategory({ payload }: PayloadAction<{ courseId: DBId; categoryId: DBId }>) {
    try {
      const categories: ReturnType<typeof selectGrammarReviewCategories> = yield select(selectGrammarReviewCategories);
      const { courseId, categoryId } = payload;

      let deleteResult: Awaited<ReturnType<typeof GrammarReviewService.removeCategory>>;

      yield put(courseSlice.actions.setIsDeleteInProgress(true));
      deleteResult = yield call(GrammarReviewService.removeCategory, courseId, categoryId);

      if (deleteResult.status === 204) {
        yield put(courseSlice.actions.setIsDeleteInProgress(false));
        const newCategories = categories.filter((category) => category.id !== categoryId);

        yield put({
          type: GrammarReviewActions.GRAMMAR_CATEGORIES_LOADED,
          payload: newCategories,
        });

        yield call(GrammarReviewSagas.updateGrammarReviewNavigation, { courseId });

        yield call(CourseSagas.getValidationResult, {
          type: ContentTypes.grammarReview,
        });
      }
    } catch (e) {
      yield put(courseSlice.actions.setIsDeleteInProgress(false));
      console.error(e);
    }
  },

  // Category sagas
  *getGrammarCategory({ payload: { courseId, categoryId } }: PayloadAction<{ courseId: DBId; categoryId: DBId }>) {
    try {
      yield put({ type: GrammarReviewActions.GRAMMAR_CATEGORY_LOADING });

      const result: Awaited<ReturnType<typeof GrammarReviewService.getGrammarCategory>> = yield call(
        GrammarReviewService.getGrammarCategory,
        courseId,
        categoryId,
      );

      if (result.status === 200) {
        yield put({ type: GrammarReviewActions.GRAMMAR_CATEGORY_LOADED, payload: result.data });
      }
    } catch (e) {
      console.error(e);
    }
  },
  *getGrammarTopics({ payload: { courseId, categoryId } }: PayloadAction<{ courseId: DBId; categoryId: DBId }>) {
    try {
      yield put({ type: GrammarReviewActions.GRAMMAR_TOPICS_LOADING });

      const topicsResult: Awaited<ReturnType<typeof GrammarReviewService.getGrammarTopics>> = yield call(
        GrammarReviewService.getGrammarTopics,
        courseId,
        categoryId,
      );

      if (topicsResult.status === 200) {
        yield put({ type: GrammarReviewActions.GRAMMAR_TOPICS_LOADED, payload: topicsResult.data.topics });
      }
    } catch (e) {
      console.error(e);
    }
  },
  *createGrammarTopic({
    payload: { courseId, categoryId, position },
  }: PayloadAction<{ courseId: DBId; categoryId: DBId; position: number }>) {
    try {
      const topics: ReturnType<typeof selectGrammarCategoryTopics> = yield select(selectGrammarCategoryTopics);

      yield put(courseSlice.actions.setIsCreatingContent(position));

      let createResult: Awaited<ReturnType<typeof GrammarReviewService.createTopic>>;
      try {
        createResult = yield call(GrammarReviewService.createTopic, courseId, categoryId, position);
      } finally {
        yield put(courseSlice.actions.setIsCreatingContent(undefined));
      }

      const newElementId = createResult.data.id;
      if (createResult.status === 200 && newElementId) {
        const newGroupElement = {
          id: newElementId,
          validationStatus: createResult.data.validationStatus,
          changeStatus: {
            hasPendingChanges: false,
            hasNewChanges: false,
          },
          ready: false,
          type: ContentTypes.grammarTopic,
        };
        const newTopics = getNewArray(topics, newGroupElement, position);

        yield put({
          type: GrammarReviewActions.GRAMMAR_TOPICS_LOADED,
          payload: newTopics,
        });

        yield call(GrammarReviewSagas.updateGrammarCategoryNavigation, { courseId, categoryId });

        yield call(CourseSagas.getValidationResult, {
          type: ContentTypes.grammarCategory,
        });
      }

      yield put({
        type: CoursesActions.SET_POSITION_OF_NEW_CONTENT,
        payload: { newElementId },
      });

      yield delay(2000);

      yield put({
        type: CoursesActions.SET_POSITION_OF_NEW_CONTENT,
        payload: {},
      });
    } catch (e) {
      console.error(e);
    }
  },
  *reorderTopics({
    payload,
  }: PayloadAction<{
    courseId: DBId;
    parentId: DBId;
    orderedItems: GrammarTopicListItemType[];
    destinationIndex: number;
  }>) {
    try {
      const { courseId, parentId, orderedItems, destinationIndex } = payload;

      yield put({
        type: GrammarReviewActions.GRAMMAR_TOPICS_LOADED,
        payload: orderedItems,
      });

      const topicId = orderedItems[destinationIndex].id;

      const changeGroupOrderPayload = {
        topicId,
        newIndex: destinationIndex,
      };

      yield call(GrammarReviewService.reorderTopics, { courseId, categoryId: parentId, ...changeGroupOrderPayload });

      yield call(GrammarReviewSagas.updateGrammarCategoryNavigation, { courseId, categoryId: parentId });

      yield call(CourseSagas.getValidationResult, {
        type: ContentTypes.grammarCategory,
      });
    } catch (e) {
      console.error(e);
    }
  },
  *removeTopic({ payload }: PayloadAction<{ courseId: DBId; categoryId: DBId; topicId: DBId }>) {
    try {
      const topics: ReturnType<typeof selectGrammarCategoryTopics> = yield select(selectGrammarCategoryTopics);
      const { courseId, categoryId, topicId } = payload;

      let deleteResult: Awaited<ReturnType<typeof GrammarReviewService.removeTopic>>;

      yield put(courseSlice.actions.setIsDeleteInProgress(true));
      deleteResult = yield call(GrammarReviewService.removeTopic, courseId, categoryId, topicId);

      if (deleteResult.status === 204) {
        yield put(courseSlice.actions.setIsDeleteInProgress(false));
        const newTopics = topics.filter((topic) => topic.id !== topicId);

        yield put({
          type: GrammarReviewActions.GRAMMAR_TOPICS_LOADED,
          payload: newTopics,
        });

        yield call(GrammarReviewSagas.updateGrammarCategoryNavigation, { courseId, categoryId });

        yield call(CourseSagas.getValidationResult, {
          type: ContentTypes.grammarCategory,
        });
      }
    } catch (e) {
      yield put(courseSlice.actions.setIsDeleteInProgress(false));
      console.error(e);
    }
  },
  *saveCategory(
    dispatch: AppDispatch,
    {
      payload: { values, courseId, categoryId },
    }: PayloadAction<{ values: GrammarCategoryFormikValues; courseId: DBId; categoryId: DBId }>,
  ) {
    try {
      yield put({
        type: GrammarReviewActions.SET_CATEGORY_ALL_VALUES,
        payload: { values },
      });

      yield put({
        type: CommonActions.SET_IS_SAVE_LOADING,
        payload: { value: true },
      });

      const grammarCategory: GrammarCategoryContentType = yield select(selectGrammarCategoryContent);

      const payloadForUpdate: Awaited<ReturnType<typeof GrammarReviewService.getPayloadForCategoryUpdate>> = yield call(
        GrammarReviewService.getPayloadForCategoryUpdate,
        grammarCategory,
        values,
        (contentType, fieldName, contentId) => {
          dispatch(
            GrammarReviewActionCreators.setContentId({
              contentType,
              fieldName,
              contentId,
            }),
          );
        },
      );

      const saveResult: Awaited<ReturnType<typeof GrammarReviewService.saveCategory>> = yield call(
        GrammarReviewService.saveCategory,
        courseId,
        categoryId,
        payloadForUpdate,
      );

      yield put({
        type: CommonActions.SET_IS_SAVE_LOADING,
        payload: { value: false },
      });

      yield call(GrammarReviewSagas.updateGrammarReviewNavigation, { courseId, categoryId });

      if (saveResult?.status === 200) {
        addToast({
          type: 'success',
          title: 'Content changes have been saved',
        });
        yield put({
          type: GrammarReviewActions.SET_CATEGORY_TO_NOT_CHANGED,
        });
      }

      yield call(CourseSagas.getValidationResult, {
        type: ContentTypes.grammarCategory,
      });
    } catch (e) {
      yield put({
        type: CommonActions.SET_IS_SAVE_LOADING,
        payload: {
          value: false,
          updateData: false,
        },
      });
      console.error(e);
    }
  },

  // Topic sagas
  *attachLessonsToTopic({
    payload,
  }: PayloadAction<{
    courseId: DBId;
    topicId: DBId;
    lessonIds: DBId[];
    lessonsToAttach: DBId[];
    lessonsToDetach: DBId[];
  }>) {
    try {
      const { courseId, topicId, lessonIds, lessonsToAttach, lessonsToDetach } = payload;

      yield put({
        type: GrammarReviewActions.SET_ATTACHED_LESSONS_STATUS,
        payload: LoadingStage.loading,
      });

      const attachResult: Awaited<ReturnType<typeof GrammarReviewService.attachLessons>> = yield call(
        GrammarReviewService.attachLessons,
        courseId,
        topicId,
        lessonsToAttach,
        lessonsToDetach,
      );

      if (attachResult.status === 204) {
        yield put({
          type: GrammarReviewActions.SET_LINKED_LESSONS,
          payload: { lessonIds },
        });

        addToast({
          type: 'success',
          title: 'Attached lessons updated',
        });

        yield put({
          type: GrammarReviewActions.SET_ATTACHED_LESSONS_STATUS,
          payload: LoadingStage.loaded,
        });

        yield call(CourseSagas.getValidationResult, {
          type: ContentTypes.grammarTopic,
        });
      }
    } catch (e) {
      yield put(courseSlice.actions.setIsDeleteInProgress(false));
      yield put({
        type: GrammarReviewActions.SET_ATTACHED_LESSONS_STATUS,
        payload: LoadingStage.notLoaded,
      });
      console.error(e);
    }
  },
  *updateCefr({
    payload: { courseId, topicId, cefr },
  }: PayloadAction<{ courseId: DBId; topicId: DBId; cefr: string }>) {
    try {
      yield put({
        type: CommonActions.SET_IS_SAVE_LOADING,
        payload: { value: true },
      });

      const saveResult: Awaited<ReturnType<typeof GrammarReviewService.updateTopicCefr>> = yield call(
        GrammarReviewService.updateTopicCefr,
        courseId,
        topicId,
        cefr,
      );

      yield put({
        type: CommonActions.SET_IS_SAVE_LOADING,
        payload: { value: false },
      });

      if (saveResult?.status === 200) {
        addToast({
          type: 'success',
          title: 'Content changes have been saved',
        });
      }

      yield call(CourseSagas.getValidationResult, {
        type: ContentTypes.grammarTopic,
      });
    } catch (e) {
      console.error(e);
    }
  },
  *saveTopic(
    dispatch: AppDispatch,
    {
      payload: { values, courseId, topicId, categoryId },
    }: PayloadAction<{ values: GrammarTopicFormikValues; courseId: DBId; topicId: DBId; categoryId: DBId }>,
  ) {
    try {
      yield put({
        type: GrammarReviewActions.SET_TOPIC_ALL_VALUES,
        payload: { values },
      });

      yield put({
        type: CommonActions.SET_IS_SAVE_LOADING,
        payload: { value: true },
      });

      const grammarTopic: GrammarTopicContentType = yield select(selectGrammarTopicContent);

      const payloadForUpdate: Awaited<ReturnType<typeof GrammarReviewService.getPayloadForTopicUpdate>> = yield call(
        GrammarReviewService.getPayloadForTopicUpdate,
        grammarTopic,
        values,
        (contentType, fieldName, contentId) => {
          dispatch(
            GrammarReviewActionCreators.setContentId({
              contentType,
              fieldName,
              contentId,
            }),
          );
        },
      );

      const saveResult: Awaited<ReturnType<typeof GrammarReviewService.saveTopic>> = yield call(
        GrammarReviewService.saveTopic,
        courseId,
        topicId,
        payloadForUpdate,
      );

      yield put({
        type: CommonActions.SET_IS_SAVE_LOADING,
        payload: { value: false },
      });

      yield call(GrammarReviewSagas.updateGrammarCategoryNavigation, { courseId, categoryId });

      if (saveResult?.status === 200) {
        addToast({
          type: 'success',
          title: 'Content changes have been saved',
        });
        yield put({
          type: GrammarReviewActions.SET_TOPIC_TO_NOT_CHANGED,
        });
      }

      yield call(CourseSagas.getValidationResult, {
        type: ContentTypes.grammarTopic,
      });
    } catch (e) {
      yield put({
        type: CommonActions.SET_IS_SAVE_LOADING,
        payload: {
          value: false,
          updateData: false,
        },
      });
      console.error(e);
    }
  },
  *getGrammarTopic({ payload: { courseId, topicId } }: PayloadAction<{ courseId: DBId; topicId: DBId }>) {
    try {
      yield put({ type: GrammarReviewActions.GRAMMAR_TOPIC_LOADING });

      const result: Awaited<ReturnType<typeof GrammarReviewService.getGrammarTopic>> = yield call(
        GrammarReviewService.getGrammarTopic,
        courseId,
        topicId,
      );

      if (result.status === 200) {
        yield put({ type: GrammarReviewActions.GRAMMAR_TOPIC_LOADED, payload: result.data });
      }
    } catch (e) {
      console.error(e);
    }
  },
  *getGrammarExercises({ payload: { courseId, topicId } }: PayloadAction<{ courseId: DBId; topicId: DBId }>) {
    try {
      yield put({ type: GrammarReviewActions.GRAMMAR_EXERCISES_LOADING });

      const exercisesResult: Awaited<ReturnType<typeof GrammarReviewService.getGrammarExercises>> = yield call(
        GrammarReviewService.getGrammarExercises,
        courseId,
        topicId,
      );

      if (exercisesResult.status === 200) {
        yield put({ type: GrammarReviewActions.GRAMMAR_EXERCISES_LOADED, payload: exercisesResult.data });
      }
    } catch (e) {
      console.error(e);
    }
  },
  *createGrammarExercise({
    payload: { courseId, exerciseType, numExercisesToCreate, position, topicId },
  }: PayloadAction<{
    courseId: DBId;
    exerciseType: ExerciseTypesType;
    numExercisesToCreate: number;
    position: number;
    topicId: DBId;
  }>) {
    try {
      const bankExercises: ReturnType<typeof selectGrammarTopicBankExercises> = yield select(
        selectGrammarTopicBankExercises,
      );

      yield put(courseSlice.actions.setIsCreatingContent(`gradable-${position}`));

      let createResult: Awaited<ReturnType<typeof GrammarReviewService.createExercise>>;
      try {
        createResult = yield call(GrammarReviewService.createExercise, exerciseType, {
          numExercisesToCreate,
          position,
          topicExerciseType: 'grammar',
          topicId,
        });
      } finally {
        yield put(courseSlice.actions.setIsCreatingContent(undefined));
      }

      const newElementIds = createResult.data.ids;

      if (createResult.status === 201 && newElementIds.length) {
        const newElements = newElementIds.map((id: DBId) => ({
          id,
          type: exerciseType,
          ...newAddedExerciseData,
        }));
        const newBankExercises = getNewArray(bankExercises, newElements, position);

        yield put({
          type: GrammarReviewActions.GRAMMAR_EXERCISES_LOADED,
          payload: { bankExercises: newBankExercises },
        });

        yield call(CourseSagas.getValidationResult, {
          type: ContentTypes.grammarTopic,
        });
      }

      yield call(GrammarReviewSagas.updateGrammarTopicNavigation, { courseId, topicId });

      yield put({
        type: CoursesActions.SET_POSITION_OF_NEW_CONTENT,
        payload: { newElementIds },
      });

      yield delay(2000);

      yield put({
        type: CoursesActions.SET_POSITION_OF_NEW_CONTENT,
        payload: {},
      });
    } catch (e) {
      console.error(e);
    }
  },
  *createReferenceExercise({
    payload: { courseId, numExercisesToCreate, position, topicId },
  }: PayloadAction<{ courseId: DBId; numExercisesToCreate: number; position: number; topicId: DBId }>) {
    try {
      const referenceExercises: ReturnType<typeof selectGrammarTopicReferenceExercises> = yield select(
        selectGrammarTopicReferenceExercises,
      );

      yield put(courseSlice.actions.setIsCreatingContent(`${position}`));

      let createResult: Awaited<ReturnType<typeof GrammarReviewService.createExercise>>;
      try {
        createResult = yield call(GrammarReviewService.createExercise, ExerciseTypes.tip, {
          numExercisesToCreate,
          position,
          topicExerciseType: 'reference',
          topicId,
        });
      } finally {
        yield put(courseSlice.actions.setIsCreatingContent(undefined));
      }

      const newElementIds = createResult.data.ids;

      if (createResult.status === 201 && newElementIds.length) {
        const newElements = newElementIds.map((id: DBId) => ({
          id,
          type: EXERCISE_TIP,
          ...newAddedExerciseData,
        }));
        const newReferenceExercises = getNewArray(referenceExercises, newElements, position);

        yield put({
          type: GrammarReviewActions.GRAMMAR_EXERCISES_LOADED,
          payload: { referenceExercises: newReferenceExercises },
        });

        yield call(CourseSagas.getValidationResult, {
          type: ContentTypes.grammarTopic,
        });
      }

      yield call(GrammarReviewSagas.updateGrammarTopicNavigation, { courseId, topicId });

      yield put({
        type: CoursesActions.SET_POSITION_OF_NEW_CONTENT,
        payload: { newElementIds },
      });

      yield delay(2000);

      yield put({
        type: CoursesActions.SET_POSITION_OF_NEW_CONTENT,
        payload: {},
      });
    } catch (e) {
      console.error(e);
    }
  },
  *reuseExercise({
    payload: { courseId, topicId, exerciseId, position, exerciseType, exerciseTopicType },
  }: PayloadAction<{
    courseId: DBId;
    topicId: DBId;
    exerciseId: DBId;
    exerciseType: ExerciseTypesType;
    position: number;
    exerciseTopicType: 'reference' | 'grammar';
  }>) {
    try {
      const referenceExercises: ReturnType<typeof selectGrammarTopicReferenceExercises> = yield select(
        selectGrammarTopicReferenceExercises,
      );
      const bankExercises: ReturnType<typeof selectGrammarTopicBankExercises> = yield select(
        selectGrammarTopicBankExercises,
      );

      yield put({
        type: SearchActions.SET_REUSE_IN_PROGRESS_V2,
        payload: exerciseId,
      });

      const reuseResult: Awaited<ReturnType<typeof GrammarReviewService.useExercise>> = yield call(
        GrammarReviewService.useExercise,
        courseId,
        topicId,
        exerciseId,
        position,
        exerciseTopicType,
      );

      if (reuseResult.status === 204) {
        yield put(SearchModalActionsCreator.hideSearchV2());

        addToast({
          type: 'success',
          title: 'Exercise was reused',
        });

        const newGroupElement = {
          id: exerciseId,
          type: exerciseType,
          changeStatus: {
            hasPendingChanges: false,
            hasNewChanges: false,
          },
          validationStatus: {
            invalidChildEntities: [],
            validated: false,
            valid: false,
            errors: [],
          },
        };

        if (exerciseTopicType === 'reference') {
          const newReferenceExercises = getNewArray(referenceExercises, newGroupElement, position);

          yield put({
            type: GrammarReviewActions.GRAMMAR_EXERCISES_LOADED,
            payload: { referenceExercises: newReferenceExercises },
          });
        } else {
          const newBankExercises = getNewArray(bankExercises, newGroupElement, position);

          yield put({
            type: GrammarReviewActions.GRAMMAR_EXERCISES_LOADED,
            payload: { bankExercises: newBankExercises },
          });
        }

        yield call(CourseSagas.getValidationResult, {
          type: ContentTypes.grammarTopic,
        });

        yield call(GrammarReviewSagas.updateGrammarTopicNavigation, { courseId, topicId });

        yield put({
          type: CoursesActions.SET_POSITION_OF_NEW_CONTENT,
          payload: { exerciseId },
        });

        yield delay(2000);

        yield put({
          type: CoursesActions.SET_POSITION_OF_NEW_CONTENT,
          payload: {},
        });
      }
    } catch (e) {
      yield put({
        type: SearchActions.SET_REUSE_IN_PROGRESS_V2,
        payload: '',
      });

      console.error(e);
    }
  },
  *copyExercise({
    payload: { courseId, topicId, exerciseId, position, exerciseType, exerciseTopicType },
  }: PayloadAction<{
    courseId: DBId;
    topicId: DBId;
    exerciseId: DBId;
    exerciseType: ExerciseTypesType;
    position: number;
    exerciseTopicType: 'reference' | 'grammar';
  }>) {
    try {
      yield put({
        type: SearchActions.SET_REUSE_IN_PROGRESS_V2,
        payload: exerciseId,
      });

      const cloneResult: Awaited<ReturnType<typeof SearchService.cloneExercise>> = yield call(
        SearchService.cloneExercise,
        exerciseId,
      );

      if (cloneResult.status === 200) {
        yield call(GrammarReviewSagas.reuseExercise, {
          payload: {
            courseId,
            topicId,
            exerciseId: cloneResult.data.id,
            exerciseType,
            position,
            exerciseTopicType,
          },
        } as PayloadAction<{
          courseId: DBId;
          topicId: DBId;
          exerciseId: DBId;
          exerciseType: ExerciseTypesType;
          position: number;
          exerciseTopicType: 'reference' | 'grammar';
        }>);
      }
    } catch (e) {
      yield put({
        type: SearchActions.SET_REUSE_IN_PROGRESS_V2,
        payload: '',
      });

      console.error(e);
    }
  },
  *reorderReferenceExercises({
    payload,
  }: PayloadAction<{
    courseId: DBId;
    parentId: DBId;
    orderedItems: ExerciseListItemType[];
    destinationIndex: number;
  }>) {
    try {
      const { courseId, parentId, orderedItems, destinationIndex } = payload;
      yield put({
        type: GrammarReviewActions.GRAMMAR_EXERCISES_LOADED,
        payload: { referenceExercises: orderedItems },
      });

      const exerciseId = orderedItems[destinationIndex].id;

      const changeGroupOrderPayload = {
        exerciseId,
        newIndex: destinationIndex,
      };

      yield call(GrammarReviewService.reorderExercises, {
        courseId,
        topicId: parentId,
        exerciseType: 'reference',
        ...changeGroupOrderPayload,
      });
      yield call(GrammarReviewSagas.updateGrammarTopicNavigation, { courseId, topicId: parentId });

      yield call(CourseSagas.getValidationResult, {
        type: ContentTypes.grammarTopic,
      });
    } catch (e) {
      console.error(e);
    }
  },
  *reorderGrammarExercises({
    payload,
  }: PayloadAction<{
    courseId: DBId;
    parentId: DBId;
    orderedItems: ExerciseListItemType[];
    destinationIndex: number;
  }>) {
    try {
      const { courseId, parentId, orderedItems, destinationIndex } = payload;
      yield put({
        type: GrammarReviewActions.GRAMMAR_EXERCISES_LOADED,
        payload: { bankExercises: orderedItems },
      });

      const exerciseId = orderedItems[destinationIndex].id;

      const changeGroupOrderPayload = {
        exerciseId,
        newIndex: destinationIndex,
      };

      yield call(GrammarReviewService.reorderExercises, {
        courseId,
        topicId: parentId,
        exerciseType: 'grammar',
        ...changeGroupOrderPayload,
      });
      yield call(GrammarReviewSagas.updateGrammarTopicNavigation, { courseId, topicId: parentId });

      yield call(CourseSagas.getValidationResult, {
        type: ContentTypes.grammarTopic,
      });
    } catch (e) {
      console.error(e);
    }
  },
  *removeExercise({
    payload,
  }: PayloadAction<{ courseId: DBId; topicId: DBId; exerciseId: DBId; exerciseType: string }>) {
    try {
      const { referenceExercises, bankExercises }: ReturnType<typeof selectGrammarTopicExercises> =
        yield select(selectGrammarTopicExercises);
      const { courseId, topicId, exerciseId, exerciseType } = payload;

      let deleteResult: Awaited<ReturnType<typeof GrammarReviewService.removeExercise>>;

      yield put(courseSlice.actions.setIsDeleteInProgress(true));
      deleteResult = yield call(
        GrammarReviewService.removeExercise,
        courseId,
        topicId,
        exerciseId,
        exerciseType === ExerciseTypes.tip ? 'reference' : 'grammar',
      );

      if (deleteResult.status === 204) {
        yield put(courseSlice.actions.setIsDeleteInProgress(false));
        if (referenceExercises.find((referenceExercise) => referenceExercise.id === exerciseId)) {
          const newReferenceExercises = referenceExercises.filter(
            (referenceExercise) => referenceExercise.id !== exerciseId,
          );
          yield put({
            type: GrammarReviewActions.GRAMMAR_EXERCISES_LOADED,
            payload: { referenceExercises: newReferenceExercises },
          });
        } else {
          const newBankExercises = bankExercises.filter((bankExercise) => bankExercise.id !== exerciseId);
          yield put({
            type: GrammarReviewActions.GRAMMAR_EXERCISES_LOADED,
            payload: { bankExercises: newBankExercises },
          });
        }

        yield call(GrammarReviewSagas.updateGrammarTopicNavigation, { courseId, topicId });
        yield call(CourseSagas.getValidationResult, {
          type: ContentTypes.grammarTopic,
        });
      }
    } catch (e) {
      yield put(courseSlice.actions.setIsDeleteInProgress(false));
      console.error(e);
    }
  },

  // Common grammar sagas
  *removeString({
    payload: { contentType, fieldName },
  }: PayloadAction<{ contentType: ContentTypesType; fieldName: GrammarCategoryEditableFieldNames }>) {
    try {
      const grammarCategory: GrammarCategoryContentType = yield select(selectGrammarCategoryContent);
      const grammarTopic: GrammarTopicContentType = yield select(selectGrammarTopicContent);

      let stringToRemove: TranslationsPanelContentInterface | null = null;

      switch (contentType) {
        case ContentTypes.grammarCategory: {
          stringToRemove = grammarCategory[fieldName];
          break;
        }
        case ContentTypes.grammarTopic: {
          stringToRemove = grammarTopic[fieldName];
          break;
        }
        default: {
          console.log('Incorrect type');
        }
      }

      if (stringToRemove) {
        const { textLocalizations, audioLocalizations, imageLocalizations, videoLocalizations } = stringToRemove;
        const newTextLocaliz =
          textLocalizations?.map((loc) => ({ ...loc, value: '', phoneticValue: '', _id: '' })) || [];
        const newAudioLocaliz = audioLocalizations?.map((loc) => ({ ...loc, value: '', _id: '' })) || [];
        const newImageLocaliz = imageLocalizations?.map((loc) => ({ ...loc, value: '', _id: '' })) || [];
        const newVideoLocaliz = videoLocalizations?.map((loc) => ({ ...loc, value: '', _id: '' })) || [];

        const newString = {
          _id: '',
          id: '',
          description: '',
          textLocalizations: newTextLocaliz,
          audioLocalizations: newAudioLocaliz,
          imageLocalizations: newImageLocaliz,
          videoLocalizations: newVideoLocaliz,
        };

        yield put({
          type: GrammarReviewActions.SET_STRING,
          payload: { newString, contentType, fieldName },
        });
      }
    } catch (e) {
      console.error(e);
    }
  },
  *makeCopy({
    payload: { contentType, fieldName },
  }: PayloadAction<{
    contentType: ContentTypesType;
    fieldName: GrammarCategoryEditableFieldNames | GrammarTopicEditableFieldNames;
  }>) {
    try {
      const grammarCategory: GrammarCategoryContentType = yield select(selectGrammarCategoryContent);
      const grammarTopic: GrammarTopicContentType = yield select(selectGrammarTopicContent);

      let contentData: GrammarCategoryContentType | GrammarTopicContentType = grammarCategory;
      if (contentType === ContentTypes.grammarTopic) {
        contentData = grammarTopic;
      }

      const cloneResult: Awaited<ReturnType<typeof SearchService.cloneContent>> = yield call(
        SearchService.cloneContent,
        contentData[fieldName].id || contentData[fieldName]._id || '',
      );

      if (cloneResult.status === 200) {
        const newString = {
          ...contentData[fieldName],
          mappings: { count: 1 },
          id: cloneResult.data.id,
          isReusingConfirmed: true,
        };

        yield put({
          type: GrammarReviewActions.SET_STRING,
          payload: { newString, contentType, fieldName },
        });

        yield put({
          type: ContentTypesActions.SET_CURRENT_CONTENT_ID,
          payload: {
            contentId: '',
            type: '',
            visitedBranch: '',
            isStringChangeBlocked: false,
          },
        });
      }
    } catch (e: any) {
      console.error(e);
    }
  },
  *uploadAudio({
    payload,
  }: PayloadAction<{
    localization: LocalizationInterface;
    uploadedSound: File | null;
    progressHandler?: (progress: number) => void;
  }>) {
    try {
      const { localization, uploadedSound, progressHandler } = payload;
      const grammarTranslationsPanel: ReturnType<typeof selectGrammarTranslationsPanel> =
        yield select(selectGrammarTranslationsPanel);

      const { type, fieldName, visitedContentId } = grammarTranslationsPanel;

      yield put({
        type: CommonActions.AUDIO_UPLOADING_STARTED,
        payload: { audioField: fieldName, language: localization.language },
      });

      const grammarCategory: GrammarCategoryContentType = yield select(selectGrammarCategoryContent);
      const grammarTopic: GrammarTopicContentType = yield select(selectGrammarTopicContent);

      let contentData = grammarCategory[fieldName];
      if (type === ContentTypes.grammarTopic) {
        contentData = grammarTopic[fieldName];
      }

      if (uploadedSound && contentData) {
        const { mediaId, mediaURL }: UploadMediaResponse = yield MediaService.uploadMedia(
          MediaTypes.audio,
          uploadedSound,
          undefined,
          undefined,
          visitedContentId,
          progressHandler,
          contentData,
          localization.language,
        );

        yield put({
          type: GrammarReviewActions.SET_AUDIO,
          payload: { localization, fieldName, mediaURL, type, mediaId },
        });
      } else {
        yield put({
          type: GrammarReviewActions.REMOVE_AUDIO,
          payload: { localization, fieldName, type },
        });
      }
    } catch (e: any) {
      const { localization } = payload;
      const grammarTranslationsPanel: ReturnType<typeof selectGrammarTranslationsPanel> =
        yield select(selectGrammarTranslationsPanel);

      const { fieldName } = grammarTranslationsPanel;

      yield put({
        type: CommonActions.AUDIO_UPLOADING_FINISHED,
        payload: {
          audioField: fieldName,
          language: localization.language,
          details: e?.message,
        },
      });
      console.error(e);
    }
  },
};
