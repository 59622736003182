import styled from 'styled-components';

import { SearchModalActionsCreator } from '@actionCreators/SearchModalActionsCreator';
import { ContentTypes, type ContentTypesForCreateContentType, type ContentTypesType } from '@common/enums/ContentTypes';
import { ACTIVITY_SPEAKING, ActivityType } from '@features/content/activities';
import { EXERCISE_LISTEN_REPEAT, type ExerciseType } from '@common/enums/ExerciseTypes';
import { ActivityCreator } from '@features/content/activities';
import { ChapterCreator } from '@features/content/chapters';
import { useIsEditAvailable } from '@features/content/courses';
import { ExerciseCreator, SlideshowCreator } from '@features/content/exercises';
import { GrammarCategoryCreator, GrammarTopicCreator } from '@features/content/grammar';
import { LessonCreator } from '@features/content/lessons';
import { LevelCreator } from '@features/content/levels';
import { RoleplayCategoryCreator, RoleplayScenarioCreator } from '@features/content/roleplay';
import { isFeatureEnabled } from '@helpers/featuresHelper';
import { selectCreatingContentPosition, selectSelectedGroupsOfParentParentContents } from '@selectors/CoursesSelectors';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { userTracking } from '@features/app/tracking';

import { TipCreator } from '../grammar/GrammarExercise/TipCreator/TipCreator';

export const CreatorContainer = styled.div<{ isPreEnabled: boolean }>`
  ${({ isPreEnabled }) => isPreEnabled && 'margin-top: 2.5rem;'}
`;

type ContentCreatorProps = {
  acceptableButtons: Partial<Record<ContentTypesType | 'onlyGradable' | 'onlyTip', boolean>>;
  isPreEnabled: boolean;
  position: number;
  parentType?: typeof ContentTypes.entrypoint | typeof ContentTypes.grammarTopic;
  onCreateContent: (
    itemType: ContentTypesType,
    itemCategory: ActivityType | ExerciseType | ContentTypesForCreateContentType | null,
    numItemsToCreate?: number,
  ) => void;
  onSearch?: () => void;
};

export const ContentCreator = ({
  acceptableButtons,
  parentType,
  isPreEnabled = false,
  position,
  onCreateContent,
}: ContentCreatorProps) => {
  const { isEditAvailable } = useIsEditAvailable();
  const dispatch = useAppDispatch();

  const creatingContentPosition = useAppSelector(selectCreatingContentPosition);
  const loadedContent = useAppSelector(selectSelectedGroupsOfParentParentContents);
  const positionForLoaderCheck = !!acceptableButtons.onlyGradable ? `gradable-${position}` : `${position}`;

  const isCreateInProgress =
    creatingContentPosition !== undefined && creatingContentPosition === positionForLoaderCheck;

  const onActivitySearch = () => {
    dispatch(
      SearchModalActionsCreator.showSearchV2Panel({
        contentType: ContentTypes.activity,
        forReusing: true,
        predefinedType: 'activity',
        filtersPreset:
          loadedContent?.lessonType === 'speaking'
            ? {
                activity: {
                  activityTypes: [ACTIVITY_SPEAKING],
                },
              }
            : undefined,
        position,
      }),
    );
    userTracking.logosSearchOpened({
      source: 'content_creator',
    });
  };

  const onExerciseSearch = () => {
    dispatch(
      SearchModalActionsCreator.showSearchV2Panel({
        contentType: !!acceptableButtons.onlyGradable ? 'onlyGradable' : ContentTypes.exercise,
        forReusing: true,
        predefinedType: 'exercise',
        filtersPreset:
          loadedContent?.activityType === ContentTypes.speakingActivity
            ? {
                exercise: {
                  exerciseTypes: [EXERCISE_LISTEN_REPEAT],
                },
              }
            : undefined,
        parentType: parentType ? parentType : ContentTypes.course,
        position,
      }),
    );
    userTracking.logosSearchOpened({
      source: 'content_creator',
    });
  };

  const onTipExerciseSearch = () => {
    dispatch(
      SearchModalActionsCreator.showSearchV2Panel({
        contentType: 'onlyTip',
        forReusing: true,
        predefinedType: 'exercise',
        parentType: 'grammarTopic',
        position,
      }),
    );
    userTracking.logosSearchOpened({
      source: 'content_creator',
    });
  };

  return (
    <CreatorContainer isPreEnabled={isPreEnabled}>
      {acceptableButtons[ContentTypes.level] && (
        <LevelCreator
          isEditAvailable={isEditAvailable}
          isCreateInProgress={isCreateInProgress}
          onCreateContent={onCreateContent}
          isPreEnabled={isPreEnabled}
          position={position}
        />
      )}
      {acceptableButtons[ContentTypes.chapter] && (
        <ChapterCreator
          isEditAvailable={isEditAvailable}
          isCreateInProgress={isCreateInProgress}
          onCreateContent={onCreateContent}
          isPreEnabled={isPreEnabled}
          position={position}
        />
      )}
      {(acceptableButtons[ContentTypes.lesson] ||
        acceptableButtons[ContentTypes.liveLesson] ||
        acceptableButtons[ContentTypes.review] ||
        acceptableButtons[ContentTypes.checkpoint] ||
        acceptableButtons[ContentTypes.certificate]) && (
        <LessonCreator
          isCreateInProgress={isCreateInProgress}
          isEditAvailable={isEditAvailable}
          isPreEnabled={isPreEnabled}
          position={position}
          onCreateContent={onCreateContent}
        />
      )}
      {(acceptableButtons[ContentTypes.slidePdf] || acceptableButtons[ContentTypes.slidePptx]) && (
        <SlideshowCreator
          isEditAvailable={isEditAvailable}
          isCreateInProgress={isCreateInProgress}
          onCreateContent={onCreateContent}
          isPreEnabled={isPreEnabled}
          position={position}
        />
      )}
      {(acceptableButtons[ContentTypes.activity] ||
        (isFeatureEnabled('speakingBites') && acceptableButtons[ContentTypes.speakingActivity])) && (
        <ActivityCreator
          acceptableButtons={acceptableButtons}
          isEditAvailable={isEditAvailable}
          isPreEnabled={isPreEnabled}
          position={position}
          onCreateContent={(activityType: ActivityType) => {
            onCreateContent(ContentTypes.activity, activityType);
          }}
          onSearch={onActivitySearch}
        />
      )}
      {(acceptableButtons[ContentTypes.exercise] || acceptableButtons.onlyGradable) && (
        <ExerciseCreator
          allowBulk={parentType === ContentTypes.grammarTopic}
          isCreateInProgress={isCreateInProgress}
          isEditAvailable={isEditAvailable}
          isPreEnabled={isPreEnabled}
          position={position}
          onlyGradable={!!acceptableButtons.onlyGradable}
          onCreateContent={(exerciseType: ExerciseType, numExercisesToAdd: number) => {
            onCreateContent(ContentTypes.exercise, exerciseType, numExercisesToAdd);
          }}
          onSearch={onExerciseSearch}
        />
      )}
      {acceptableButtons[ContentTypes.grammarCategory] && (
        <GrammarCategoryCreator
          isCreateInProgress={isCreateInProgress}
          isEditAvailable={isEditAvailable}
          isPreEnabled={isPreEnabled}
          position={position}
          onCreateContent={onCreateContent}
        />
      )}
      {acceptableButtons[ContentTypes.grammarTopic] && (
        <GrammarTopicCreator
          isCreateInProgress={isCreateInProgress}
          isEditAvailable={isEditAvailable}
          isPreEnabled={isPreEnabled}
          position={position}
          onCreateContent={onCreateContent}
        />
      )}
      {acceptableButtons.onlyTip && (
        <TipCreator
          isCreateInProgress={isCreateInProgress}
          isEditAvailable={isEditAvailable}
          isPreEnabled={isPreEnabled}
          position={position}
          onCreateContent={onCreateContent}
          onSearch={onTipExerciseSearch}
        />
      )}
      {acceptableButtons.roleplayCategory && (
        <RoleplayCategoryCreator
          isCreateInProgress={isCreateInProgress}
          isEditAvailable={isEditAvailable}
          isPreEnabled={isPreEnabled}
          position={position}
          onCreateContent={onCreateContent}
        />
      )}
      {acceptableButtons.scenario && (
        <RoleplayScenarioCreator
          isCreateInProgress={isCreateInProgress}
          isEditAvailable={isEditAvailable}
          isPreEnabled={isPreEnabled}
          position={position}
          onCreateContent={onCreateContent}
        />
      )}
    </CreatorContainer>
  );
};
