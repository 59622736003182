import { Button } from '@features/theme';
import { apiClient } from '@features/api';
import { ContentTypes, type ContentTypesType } from '@common/enums/ContentTypes';

type ValidationButtonProps = {
  type: ContentTypesType;
  id: string;
};

const TYPES_TO_URL_MAP: Partial<Record<ContentTypesType, string>> = {
  course: 'courses',
  level: 'groups',
  chapter: 'groups',
  lesson: 'groups',
  activity: 'groups',
  exercise: 'exercises',
  grammarCategory: 'grammar-categories',
  grammarReview: 'grammar-reviews',
  grammarTopic: 'grammar-topics',
  placementTestEntrypoint: 'placement-tests',
};

const ALLOWED_TYPES = [
  ContentTypes.course,
  ContentTypes.level,
  ContentTypes.chapter,
  ContentTypes.lesson,
  ContentTypes.activity,
  ContentTypes.exercise,
  ContentTypes.grammarReview,
  ContentTypes.grammarCategory,
  ContentTypes.grammarTopic,
  ContentTypes.entrypoint,
];

export const ValidationButton = ({ id, type }: ValidationButtonProps) => {
  if (!ALLOWED_TYPES.includes(type as any)) {
    return <span>Invalid content type: {type}</span>;
  }

  const validate = async () => {
    const urlType = TYPES_TO_URL_MAP[type];

    await apiClient.noErrorsV2.post(`content/${urlType}/${id}/validate`);
  };

  return <Button onClick={validate}>Validate</Button>;
};
