import * as _ from 'lodash';
import { FormikContextType, useFormikContext } from 'formik';
import React, { ReactElement, useState } from 'react';

import { ContentTypes, type ContentTypesType } from '@common/enums/ContentTypes';
import { LocalizationInterface } from '@common/interfaces/localization/LocalizationInterface';
import { TranslationsPanelContentInterface } from '@common/interfaces/exercises/TranslationsPanelContentInterface';
import { ValidationErrorInterface } from '@common/interfaces/validation/ValidationInterface';
import { ValidationErrorDisplayer } from '@components/ValidationErrorDisplayer';
import { Language, LANGUAGE_NAMES, mapLanguageV2ToLanguage } from '@features/content/languages';
import {
  constants as contentConstants,
  formatCounterOfFilledLocalizations,
  isLocalizationFilled,
  isLocalizationNeedsToBeCounted,
} from '@features/content';
import { useIsAdmin } from '@features/auth/roles';
import { useIsEditAvailable } from '@features/content/courses';
import { camelCaseSeparateWords } from '@helpers/camel-case-separator';
import { useAppSelector } from '@redux/store';
import { selectIsIssuesShown } from '@selectors/UiSelectors';
import { selectInterfaceLanguages } from '@selectors/CoursesSelectors';
import helpersService from '@services/HelpersService';

import { useCurrentRoleplayContent } from '../useCurrentRoleplayContent';

import type {
  RoleplayCategoryEditableFieldNames,
  RoleplayCategoryFormikValues,
  RoleplayScenarioContentType,
  RoleplayScenarioFieldWithLocalizationNames,
  RoleplayScenarioFormikValues,
} from '../types';
import { TranslationsTip } from './TranslationsTip';

type TranslationsTipWrapperProps = {
  children: ReactElement;
  fieldName: RoleplayCategoryEditableFieldNames | RoleplayScenarioFieldWithLocalizationNames;
  content: TranslationsPanelContentInterface | null;
  type: ContentTypesType;
  errors?: ValidationErrorInterface[];
  className?: string;
  isChangeDisabled?: boolean;
  defaultContextForTranslators?: string;
};

export const TranslationsTipWrapper = ({
  children,
  fieldName,
  content,
  type,
  errors = [],
  className,
  isChangeDisabled = false,
  defaultContextForTranslators,
}: TranslationsTipWrapperProps) => {
  const isAdmin = useIsAdmin();
  const { values, setFieldValue }: FormikContextType<RoleplayCategoryFormikValues & RoleplayScenarioFormikValues> =
    useFormikContext();
  const { isEditAvailable } = useIsEditAvailable();
  const isIssuesShown = useAppSelector(selectIsIssuesShown);
  const isErrorsShown = isIssuesShown && errors?.length;
  const hasErrors = errors?.some((e) => !e.isWarning);
  const hasWarnings = errors?.some((e) => e.isWarning);

  const interfaceLanguages = useAppSelector(selectInterfaceLanguages);

  const { languageId, roleplayContent } = useCurrentRoleplayContent();
  const roleplayLearningLanguage = mapLanguageV2ToLanguage(languageId);

  let totalNeededLanguages = interfaceLanguages.length;

  if (!interfaceLanguages.includes(roleplayLearningLanguage)) {
    totalNeededLanguages = totalNeededLanguages + 1;
  }

  if (!interfaceLanguages.includes('EN') && roleplayLearningLanguage !== 'EN') {
    totalNeededLanguages = totalNeededLanguages + 1;
  }

  const numberOfTranslations =
    content?.audioLocalizations.filter(
      (localization) =>
        isLocalizationFilled(localization) &&
        isLocalizationNeedsToBeCounted(
          localization,
          interfaceLanguages as Language[],
          roleplayLearningLanguage as Language,
        ),
    ).length || 0;
  const audioElements = `${numberOfTranslations}/${totalNeededLanguages}`;
  const translationElements = formatCounterOfFilledLocalizations(
    (values?.[fieldName]?.filter((item) => item.value && !item.isPhonetic) as unknown as LocalizationInterface[]) || [],
    interfaceLanguages as Language[],
    roleplayLearningLanguage as Language,
  );

  const [currentLanguage, setCurrentLanguage] = useState(roleplayLearningLanguage as Language);
  const [isPhonetic, setIsPhonetic] = useState(false);

  const displayedValueInChildren =
    values?.[fieldName].find((item) => item.language === currentLanguage && !item.isPhonetic)?.value || '';

  const placeholder =
    children.props.placeholder === undefined
      ? `${camelCaseSeparateWords(_.upperFirst(fieldName))} in ${LANGUAGE_NAMES[currentLanguage as Language]}`
      : children.props.placeholder;

  const isDefaultString = (content?._id || content?.id)?.includes('default_');
  const isEditDisabled = isChangeDisabled || !isEditAvailable;

  /*
   * The following piece of code removes the potential roleplay lessons with scenario attached from mappings in order to set `isChangeBlocked` properly.
   * @TODO Double check with Backend if scenario mappings are correctly sent as roleplay lesson with scenario attached is currently included in them.
   * If Backend fixes it, restore the original code.
   */

  let isChangeBlocked = false;

  if (isDefaultString && isAdmin) {
    isChangeBlocked = true;
  } else if (!isEditDisabled && !content?.isReusingConfirmed) {
    if (type === ContentTypes.roleplayScenario) {
      const scenarioContent = roleplayContent as RoleplayScenarioContentType;
      // determine if there any other mapped locations which are not roleplay lessons with this scenario attached
      if (content?.mappings?.count) {
        const requiredCountToBlockChange = scenarioContent.attachedLessons.length > 0 ? 2 : 1;

        isChangeBlocked = content?.mappings?.count > requiredCountToBlockChange;
      } else if (content?.mappings?.length) {
        const mappingsWithoutLessonsWithThisScenarioAttached = content?.mappings?.filter(
          (mapping: TranslationsPanelContentInterface['mappings']) =>
            mapping.every(
              (mapItem: TranslationsPanelContentInterface['mappings']) =>
                !scenarioContent.attachedLessons.includes(mapItem.id),
            ),
        );

        isChangeBlocked = mappingsWithoutLessonsWithThisScenarioAttached.length > 0;
      }
    } else {
      isChangeBlocked = content?.mappings?.count > 1 || !!content?.mappings?.length;
    }
  }

  return (
    <>
      <TranslationsTip
        translationElements={translationElements}
        audioElements={audioElements}
        visitedContentId={content?._id || content?.id}
        fieldName={fieldName}
        isEditDisabled={isEditDisabled}
        isChangeBlocked={isChangeBlocked}
        onLanguageSwitchToggled={(switchedToEnglishLanguage: boolean, isPhoneticSelected: boolean) => {
          setCurrentLanguage(
            switchedToEnglishLanguage ? (contentConstants.DEFAULT_LANGUAGE as Language) : roleplayLearningLanguage,
          );
          setIsPhonetic(isPhoneticSelected);
        }}
        type={type}
        currentLanguage={currentLanguage}
        className={className}
        suggestionsQuery={displayedValueInChildren}
        defaultContextForTranslators={defaultContextForTranslators}
      >
        {React.cloneElement(children, {
          placeholder: placeholder,
          value: displayedValueInChildren,
          currentLanguage,
          onChange: (availableText: string) => {
            const newValues = values?.[fieldName]?.map((item) =>
              item.language === currentLanguage && item.isPhonetic === isPhonetic
                ? {
                    ...item,
                    value: availableText,
                  }
                : item,
            );

            setFieldValue(fieldName, newValues);
            setFieldValue(`${fieldName}Changed`, true);
          },
          disabled: isEditDisabled,
          errorsShown: isErrorsShown,
          errorType: hasErrors ? 'error' : hasWarnings ? 'warning' : undefined,
        })}
      </TranslationsTip>
      <ValidationErrorDisplayer text={helpersService.getValidationErrorMessageText(errors)} />
    </>
  );
};
