import styled from 'styled-components';

import { HeaderWrapper } from './HeaderWrapper';

const LevelHeaderWrapper = styled(HeaderWrapper)`
  grid-template-columns: 48% 25% 25%;
`;

export const LevelSearchResultTypeHeader = () => (
  <LevelHeaderWrapper>
    <span>Level</span>
    <span>Cefr</span>
    <span>Location</span>
  </LevelHeaderWrapper>
);
