import { useCallback, useState } from 'react';
import { isEqual } from 'lodash';

export type StateConfig<T> = {
  state: T;
  /**
   * @description true when state matches defaultState
   */
  isEmpty: boolean;
  getField: <K extends keyof T>(field: K) => T[K];
  setField: <K extends keyof T>(field: K) => (value: T[K]) => void;
  resetField: <K extends keyof T>(field: K) => void;
};

export const useFiltersState = <T>(defaultState: T, initialState: Partial<T>): StateConfig<T> => {
  const [state, setState] = useState({
    ...defaultState,
    ...initialState,
  });

  const getField = <K extends keyof T>(field: K) => state[field];
  const setField = useCallback(
    <K extends keyof T>(field: K) =>
      (value: T[K]) =>
        setState((actualState) => ({
          ...actualState,
          [field]: value,
        })),
    [],
  );
  const resetField = useCallback(
    <K extends keyof T>(field: K) => setField(field)(defaultState[field]),
    [setField, defaultState],
  );

  return {
    state,
    isEmpty: isEqual(state, defaultState),
    getField,
    setField,
    resetField,
  };
};
