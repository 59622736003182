import produce from 'immer';
import { GenericContentInterface } from '@common/interfaces/contentTypes/GenericContentInterface';
import { CourseSliceInterface } from '@common/interfaces/slices/CourseSliceInterface';
import { LessonInterface } from '@common/interfaces/contentTypes/LessonInterface';
import { LevelInterface } from '@common/interfaces/contentTypes/LevelInterface';
import { ContentTypesActions } from '@actions/ContentTypesActions';
import { ContentTypes, ContentType } from '@common/enums/ContentTypes';
import { LoadingStage } from '@common/enums/LoadingStage';
import { GroupsService_updateLessonFocus, GroupsService_updateLevelType } from '@services/types/GroupsServiceTypes';
import { PayloadAction } from '@reduxjs/toolkit';
import { clone } from '@helpers/clone';
import { DBId } from '@common/types/DBId';
import { CourseInterface } from '@common/interfaces/courses/CourseInterface';
import { BaseContentActionsCreator } from '@actionCreators/BaseContentActionsCreator';

const findContentInCourse = (
  state: CourseSliceInterface,
  id: DBId,
): {
  clonedCourseSlice: CourseSliceInterface;
  courseClone: CourseInterface;
  courseContentsClone: GenericContentInterface[];
  foundContent: GenericContentInterface | undefined;
} => {
  let clonedCourseSlice: CourseSliceInterface = clone(state);
  let courseClone: CourseInterface = clonedCourseSlice.course;
  let courseContentsClone: GenericContentInterface[] = clonedCourseSlice.selectedGroupsOfParent.groups;
  let foundContent: GenericContentInterface | undefined = courseContentsClone.find(
    (content: GenericContentInterface) => content.id === id,
  );

  return {
    clonedCourseSlice,
    courseClone,
    courseContentsClone,
    foundContent,
  };
};

const CourseUpdateCourseCourseReducers = {
  [ContentTypesActions.COURSE_UPDATE_LESSON_FOCUS_SUCCESS]: (
    state: CourseSliceInterface,
    { payload: { lessonId, focus } }: PayloadAction<GroupsService_updateLessonFocus>,
  ): CourseSliceInterface => {
    let { clonedCourseSlice, courseClone, courseContentsClone, foundContent } = findContentInCourse(state, lessonId);

    if (foundContent !== undefined) (foundContent as LessonInterface).focus = focus;

    return {
      ...clonedCourseSlice,
      course: {
        ...courseClone,
      },
      selectedGroupsOfParent: {
        ...clonedCourseSlice.selectedGroupsOfParent,
        groups: courseContentsClone,
      },
    };
  },
  [ContentTypesActions.COURSE_UPDATE_CONTENTS_ORDER_SUCCEEDED]: (
    state: CourseSliceInterface,
    { payload: { orderedItems, parentType } }: PayloadAction<any>,
  ): CourseSliceInterface => {
    let clonedState: CourseSliceInterface = clone(state);

    if (parentType === ContentTypes.activity) {
      clonedState.loadedExercises.exercises = orderedItems;
    } else {
      if (parentType === ContentTypes.chapter) {
        const checkpointLesson = clonedState.selectedGroupsOfParent.groups.find(
          (group) => group.lessonType === ContentTypes.checkpoint,
        );
        if (checkpointLesson) {
          clonedState.selectedGroupsOfParent.groups = [...orderedItems, checkpointLesson];
        } else {
          clonedState.selectedGroupsOfParent.groups = orderedItems;
        }
      } else {
        clonedState.selectedGroupsOfParent.groups = orderedItems;
      }
    }

    return clonedState;
  },
  [ContentTypesActions.COURSE_NAVIGATION_ITEM_SELECTED]: (
    state: CourseSliceInterface,
    { payload: { id, type, withoutUpdateLoadedState } }: PayloadAction<any>,
  ): CourseSliceInterface => {
    if (state.course?.structure) {
      let output: CourseSliceInterface = {
        ...state,
        selectedGroupsOfParent: {
          ...state.selectedGroupsOfParent,
          parentId: id,
          parentType: type,
        },
      };
      return output;
    } else {
      let output: CourseSliceInterface = {
        ...state,
        course: {
          ...state.course,
        },
        selectedGroupsOfParent: {
          ...state.selectedGroupsOfParent,
          parentType: type,
        },
      };

      if (type === ContentTypes.course) output.course.loaded = LoadingStage.notLoaded;

      return output;
    }
  },
  [ContentTypesActions.COURSE_UPDATE_LEVEL_TYPE_SUCCESS]: (
    state: CourseSliceInterface,
    { payload: { levelId, levelType } }: PayloadAction<GroupsService_updateLevelType>,
  ): CourseSliceInterface => {
    let clonedState: CourseSliceInterface = clone(state);
    let contentToBeUpdated: LevelInterface[] = clone(clonedState.selectedGroupsOfParent.groups);

    let selectedLevel: LevelInterface | undefined = contentToBeUpdated.find((content: GenericContentInterface) => {
      return content.id === levelId;
    });

    if (selectedLevel !== undefined) selectedLevel.levelType = levelType;

    return {
      ...clonedState,
      course: {
        ...clonedState.course,
      },
      selectedGroupsOfParent: {
        ...clonedState.selectedGroupsOfParent,
        groups: contentToBeUpdated,
      },
    };
  },
  [ContentTypesActions.SET_SAVE_BUTTON_STATE]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    let clonedState: CourseSliceInterface = clone(state);

    if (!payload) {
      if (clonedState.selectedGroupsOfParent.parentContents?.titleWithLocalizations) {
        clonedState.selectedGroupsOfParent.parentContents.titleWithLocalizations.changed = false;
      }
      if (clonedState.selectedGroupsOfParent.parentContents?.descriptionWithLocalizations) {
        clonedState.selectedGroupsOfParent.parentContents.descriptionWithLocalizations.changed = false;
      }
      if (clonedState.selectedGroupsOfParent.parentContents) {
        clonedState.selectedGroupsOfParent.parentContents.labelsChanged = false;
        clonedState.selectedGroupsOfParent.parentContents.identityGroupChanged = false;
      }
      if (clonedState.course.titleWithLocalizations) {
        clonedState.course.titleWithLocalizations.changed = false;
      }
      if (clonedState.course.descriptionWithLocalizations) {
        clonedState.course.descriptionWithLocalizations.changed = false;
      }
    }
    return {
      ...clonedState,
      header: {
        ...clonedState.header,
        isSaveButtonEnabled: payload,
      },
    };
  },
  [ContentTypesActions.LESSON_CLEAR_UNIT_IMAGE_LOCALIZATIONS]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    let clonedState: CourseSliceInterface = clone(state);

    return {
      ...clonedState,
      selectedGroupsOfParent: {
        ...clonedState.selectedGroupsOfParent,
        //@ts-ignore
        parentContents: {
          ...clonedState.selectedGroupsOfParent.parentContents,
          thumbnailImage: {
            //@ts-ignore
            ...clonedState.selectedGroupsOfParent.parentContents.thumbnailImage,
            imageLocalizations:
              clonedState.selectedGroupsOfParent.parentContents?.thumbnailImage?.imageLocalizations.map(
                (image: any) => ({ ...image, value: '', _id: '' }),
              ),
          },
        },
        mediaUploading: {
          ...clonedState.mediaUploading,
          imageOrVideoUploadingInProcess: [
            ...clonedState.mediaUploading.imageOrVideoUploadingInProcess.filter((item) => item !== 'thumbnailImage'),
          ],
        },
      },
    };
  },
  [ContentTypesActions.LESSON_CLEAR_IMAGE_LOCALIZATIONS]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    let clonedState: CourseSliceInterface = clone(state);

    return {
      ...clonedState,
      selectedGroupsOfParent: {
        ...clonedState.selectedGroupsOfParent,
        groups: clonedState.selectedGroupsOfParent.groups.map((item) => {
          if (item.id === payload.contentId && item.image) {
            return {
              ...item,
              image: null,
            };
          } else {
            return item;
          }
        }),
      },
      mediaUploading: {
        ...clonedState.mediaUploading,
        imageOrVideoUploadingInProcess: [
          ...clonedState.mediaUploading.imageOrVideoUploadingInProcess.filter((item) => item !== payload.contentId),
        ],
      },
    };
  },
  [ContentTypesActions.LESSON_SET_IMAGE]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    let clonedState: CourseSliceInterface = clone(state);

    return {
      ...clonedState,
      selectedGroupsOfParent: {
        ...clonedState.selectedGroupsOfParent,
        groups: clonedState.selectedGroupsOfParent.groups.map((item) => {
          if (item.id === payload.groupId) {
            return {
              ...item,
              image: {
                ...item.image,
                _id: payload.contentId,
                imageLocalizations: item.image?.imageLocalizations
                  ? item.image.imageLocalizations.map((image: any) =>
                      image.language === 'EN' ? { ...image, _id: payload.value } : { ...image },
                    )
                  : [{ language: 'EN', _id: payload.value }],
              },
            };
          } else {
            return item;
          }
        }),
      },
      mediaUploading: {
        ...clonedState.mediaUploading,
        imageOrVideoUploadingInProcess: [
          ...clonedState.mediaUploading.imageOrVideoUploadingInProcess.filter((item) => item !== payload.contentId),
        ],
      },
    };
  },
  [ContentTypesActions.LESSON_SET_UNIT_IMAGE]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    let clonedState: CourseSliceInterface = clone(state);

    return {
      ...clonedState,
      selectedGroupsOfParent: {
        ...clonedState.selectedGroupsOfParent,
        //@ts-ignore
        parentContents: {
          ...clonedState.selectedGroupsOfParent.parentContents,
          thumbnailImage: {
            //@ts-ignore
            ...clonedState.selectedGroupsOfParent.parentContents.thumbnailImage,
            _id: payload.contentId,
            imageLocalizations: clonedState.selectedGroupsOfParent.parentContents?.thumbnailImage?.imageLocalizations
              ? clonedState.selectedGroupsOfParent.parentContents?.thumbnailImage?.imageLocalizations.map(
                  (image: any) => (image.language === 'EN' ? { ...image, _id: payload.value } : { ...image }),
                )
              : [{ language: 'EN', _id: payload.value, type: 'image' }],
          },
        },
      },
      mediaUploading: {
        ...clonedState.mediaUploading,
        imageOrVideoUploadingInProcess: [
          ...clonedState.mediaUploading.imageOrVideoUploadingInProcess.filter((item) => item !== 'thumbnailImage'),
        ],
      },
    };
  },
  [ContentTypesActions.SET_REUSED_DATA]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    let clonedState: CourseSliceInterface = clone(state);
    const { reusedData } = payload;

    return {
      ...clonedState,
      stringReuseModal: {
        ...clonedState.stringReuseModal,
        otherPlaces: reusedData,
      },
    };
  },
  [ContentTypesActions.SET_ERROR_BUNDLE_PLACES]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    const { otherLocations } = payload;

    return {
      ...state,
      resourceBundleData: {
        ...state.resourceBundleData,
        bundleOtherPlaces: otherLocations,
      },
    };
  },
  [ContentTypesActions.SET_CURRENT_CONTENT_ID]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    let clonedState: CourseSliceInterface = clone(state);
    const {
      contentId,
      type: contentType,
      visitedBranch,
      bundleName,
      isStringChangeBlocked,
      isBundleChangeBlocked,
    } = payload;

    return {
      ...clonedState,
      stringReuseModal: {
        otherPlaces: {
          count: 0,
          contentMappings: [],
        },
        stringReuseModalOpenedFor: contentId,
        contentType,
        visitedBranch,
        bundleName,
        isStringChangeBlocked,
        isBundleChangeBlocked,
      },
    };
  },
  [ContentTypesActions.CHANGE_IDENTITY_GROUP]: (
    state: CourseSliceInterface,
    { payload: identityGroup }: ReturnType<typeof BaseContentActionsCreator.changeIdentityGroup>,
  ): CourseSliceInterface => {
    return produce(state, (draft) => {
      if (draft.selectedGroupsOfParent.parentContents) {
        draft.selectedGroupsOfParent.parentContents.identityGroup = identityGroup
          ? { ...identityGroup, lessons: [] }
          : null;
        draft.selectedGroupsOfParent.parentContents.identityGroupChanged = true;
      }
    });
  },
  [ContentTypesActions.UPDATE_EXERCISE_IN_NAVIGATION]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<{ experiment: boolean; id: DBId }>,
  ): CourseSliceInterface => {
    return produce(state, (draft) => {
      draft.course.structure = draft.course.structure.map((structureItem) =>
        structureItem.id === payload.id ? { ...structureItem, experiment: payload.experiment } : structureItem,
      );
    });
  },
  [ContentTypesActions.EDIT_EVERYWHERE]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    let clonedState: CourseSliceInterface = clone(state);

    const { contentType, visitedBranch, row, column, bundleName, isBundleChangeBlocked } = payload;

    return {
      ...clonedState,
      course:
        contentType === ContentTypes.course
          ? {
              ...clonedState.course,
              [visitedBranch]: {
                ...(clonedState.course as any)[visitedBranch],
                isReusingConfirmed: true,
              },
            }
          : clonedState.course,
      selectedGroupsOfParent: {
        ...clonedState.selectedGroupsOfParent,
        // @ts-ignore
        parentContents:
          contentType === ContentType.levelOrLesson
            ? {
                ...clonedState.selectedGroupsOfParent.parentContents,
                [visitedBranch]: {
                  ...(clonedState.selectedGroupsOfParent.parentContents as any)[visitedBranch],
                  isReusingConfirmed: true,
                },
              }
            : clonedState.selectedGroupsOfParent.parentContents,
      },
      loadedExercise: {
        ...clonedState.loadedExercise,
        // @ts-ignore
        exercise:
          contentType === ContentTypes.exercise
            ? {
                ...clonedState.loadedExercise.exercise,
                content: bundleName
                  ? {
                      ...clonedState.loadedExercise.exercise.content,
                      [bundleName]: {
                        ...(clonedState.loadedExercise.exercise.content as any)[bundleName],
                        [visitedBranch]: {
                          ...(clonedState.loadedExercise.exercise.content as any)[bundleName][visitedBranch],
                          isReusingConfirmed: !isBundleChangeBlocked,
                        },
                        isReusingConfirmed: true,
                      },
                    }
                  : {
                      ...clonedState.loadedExercise.exercise.content,
                      [visitedBranch]:
                        visitedBranch === 'script'
                          ? clonedState.loadedExercise.exercise.content[visitedBranch].map(
                              (item: any, index: number) =>
                                index === row
                                  ? {
                                      ...item,
                                      line: {
                                        ...item.line,
                                        isReusingConfirmed: true,
                                      },
                                    }
                                  : { ...item },
                            )
                          : visitedBranch === 'examples'
                            ? [
                                ...(clonedState.loadedExercise.exercise.content as any)[visitedBranch].map(
                                  (rowItem: any, index: number) =>
                                    index === row
                                      ? rowItem.map((columnItem: any, index: number) =>
                                          index === column
                                            ? {
                                                ...columnItem,
                                                isReusingConfirmed: true,
                                              }
                                            : { ...columnItem },
                                        )
                                      : [...rowItem],
                                ),
                              ]
                            : {
                                ...(clonedState.loadedExercise.exercise.content as any)[visitedBranch],
                                isReusingConfirmed: true,
                              },
                    },
              }
            : clonedState.loadedExercise.exercise,
      },
    };
  },
};

export default CourseUpdateCourseCourseReducers;
