import styled from 'styled-components/macro';

import { isFeatureEnabled } from '@helpers/featuresHelper';

export const StyledTableRow = styled.div<{ isEditMode?: boolean }>`
  align-items: center;
  background-color: ${({ theme, isEditMode }) => (isEditMode ? theme.colorV2.uiBackgroundSecondary : 'transparent')};
  border-bottom: 1px solid ${({ theme }) => theme.color.brandGreyscale600};
  color: ${({ theme }) => theme.colorV2.textPrimary};
  display: grid;
  font-size: 1.4rem;
  padding: 2rem 0;

  ${({ isEditMode }) => {
    if (isEditMode) {
      return `
        grid-template-columns: ${isFeatureEnabled('managerField') ? '20% 10% 15% 10% 16% 29%' : '20% 15% 15% 17% 33%'};
        `;
    } else {
      return `
        grid-template-columns: ${
          isFeatureEnabled('managerField') ? '20% 10% 15% 10% 16% 10% 10% 3% 3% 3%' : '20% 15% 15% 17% 12% 12% 3% 3% 3%'
        };
        `;
    }
  }}

  .table__row--sortable {
    &:hover {
      cursor: pointer;
    }
  }
`;
