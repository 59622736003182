import styled, { css } from 'styled-components/macro';

import SearchIcon from './_img/search.svg';

const CreatorSearchContainer = styled.div<{ allowBulk: boolean; isPreEnabled: boolean }>`
  ${({ allowBulk, isPreEnabled, theme }) => css`
    align-items: center;
    background: ${theme.colorV2.uiLightest};
    color: ${(props) => (isPreEnabled ? props.theme.colorV2.uiDarkest : props.theme.colorV2.accentPrimary)};
    display: flex;
    justify-content: center;
    font-size: 1.8rem;
    font-weight: ${isPreEnabled ? '400' : '700'};
    margin: ${isPreEnabled && !allowBulk ? '2.8rem 0 0 0' : '0'};
    padding-left: 2rem;
    text-transform: ${allowBulk ? 'lowercase' : 'none'};
    transition: 0.5s;
    z-index: ${isPreEnabled ? '0' : '1'};
  `}
`;

const SearchButton = styled.button`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 1.6rem;
    width: 5.6rem;
    height: 4rem;
    background: ${theme.colorV2.uiLightest};
    border: 0.1rem solid ${theme.colorV2.buttonTertiaryBaseBorder};
    box-shadow: 0 0.4rem 0.8rem rgba(0, 0, 0, 0.05);
    border-radius: 4.8rem;

    &:disabled {
      opacity: 0.5;
    }

    &:hover {
      background: #d6dee64d;
    }
  `}
`;

type ContentCreatorSearchProps = {
  allowBulk: boolean;
  isPreEnabled: boolean;
  disabled?: boolean;
  className?: string;
  onSearchClick: React.MouseEventHandler<HTMLButtonElement>;
};

export const ContentCreatorSearch = ({
  className,
  allowBulk,
  isPreEnabled,
  disabled = false,
  onSearchClick,
}: ContentCreatorSearchProps) => (
  <CreatorSearchContainer className={className} allowBulk={allowBulk} isPreEnabled={isPreEnabled}>
    <span>{!isPreEnabled ? 'or' : 'Or reuse'}</span>
    <SearchButton data-testid="contentCreatorSearch" disabled={disabled} onClick={onSearchClick}>
      <img src={SearchIcon} alt="Search" />
    </SearchButton>
  </CreatorSearchContainer>
);
