import styled from 'styled-components';

import { Dropdown } from '@components/Dropdown';
import { useIsEditAvailable } from '@features/content/courses';
import { ContentTypesActionsCreator } from '@actionCreators/ContentTypesActionsCreator';
import { useAppDispatch } from '@redux/store';
import { LevelCardProps } from './LevelCardProps';

const LevelTypeDropdown = styled(Dropdown)`
  margin-right: 2rem;
`;

const LEVEL_TYPES = ['a1', 'a2', 'b1', 'b2', 'c1'];

export const LevelTypeSelector = ({ content }: LevelCardProps) => {
  const dispatch = useAppDispatch();
  const { isEditAvailable } = useIsEditAvailable();

  const levelTypes = LEVEL_TYPES.map((item) => ({ code: item, name: item }));

  return (
    <LevelTypeDropdown
      values={levelTypes}
      title="Select Level"
      disabled={!isEditAvailable}
      onChange={(e) => {
        dispatch(ContentTypesActionsCreator.onLevelTypeChange(content.id, e.code));
      }}
      value={levelTypes.find((type) => type.name === content.levelType)?.code}
    />
  );
};
