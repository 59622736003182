import { DBId } from '@common/types/DBId';
import { apiClient } from '@features/api';

import { ChapterSearchResultDetailsType } from '../types';

export const getChapterResultDetails = async (chapterId: DBId): Promise<{ data: ChapterSearchResultDetailsType }> => {
  const response = await apiClient.noErrorsV2.get(`/components/${chapterId}/mappings`);

  return {
    data: {
      mappings: response.data,
    },
  };
};
