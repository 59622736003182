import styled from 'styled-components';

const CourseStatsTitleContainer = styled.div`
  font-size: 1.6rem;
  margin-bottom: 3.5rem;
`;

const CourseStatsTitleHeader = styled.h1`
  font-size: 3rem;
  font-weight: 800;
  margin-bottom: 1.2rem;
`;

export const CourseStatsTitle = () => {
  return (
    <CourseStatsTitleContainer>
      <CourseStatsTitleHeader>Course Statistics</CourseStatsTitleHeader>
      <p>Only published content will be counted</p>
    </CourseStatsTitleContainer>
  );
};
