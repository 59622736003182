import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { EXERCISE_SLIDE_PDF, EXERCISE_SLIDE_PPTX } from '@common/enums/ExerciseTypes';
import { GenericContentInterface } from '@common/interfaces/contentTypes/GenericContentInterface';
import { AnyExerciseContentInterface } from '@common/types/exercises/AnyExerciseContentInterface';
import { PublishingActionsCreator } from '@actionCreators/PublishingActionCreator';
import { CourseInterface } from '@common/interfaces/courses/CourseInterface';
import { getRealId } from '@helpers/reuseHelper';
import { DBId } from '@common/types/DBId';
import { ContentType, ContentTypes } from '@common/enums/ContentTypes';
import { useIsPublishAvailable } from '@features/auth/roles';
import { CommonPublishButton } from '@features/content/header';
import { useAppDispatch, useAppSelector } from '@redux/store';
import {
  selectCourse,
  selectGroupsOfSelectedGroupsOfParent,
  selectIsPublishingInProgress,
  selectLoadedExerciseContent,
  selectLoadedExercisesExercises,
  selectSelectedGroupsOfParentParentContents,
} from '@selectors/CoursesSelectors';

export const PublishButton = ({ isExercise, type }: { type: string; isExercise: boolean }) => {
  const dispatch = useAppDispatch();
  const [publishedContent, setPublishedContent] = useState<
    CourseInterface | GenericContentInterface | AnyExerciseContentInterface | null
  >(null);
  const [tooltipText, setTooltipText] = useState('');

  let { courseId, levelId, chapterId, lessonId, activityId } = useParams<{
    courseId: DBId;
    levelId: DBId;
    chapterId: DBId;
    lessonId: DBId;
    activityId: DBId;
  }>();
  courseId = getRealId(courseId);
  levelId = getRealId(levelId);
  chapterId = getRealId(chapterId);
  lessonId = getRealId(lessonId);
  activityId = getRealId(activityId);

  const loadedCourse = useAppSelector(selectCourse);
  const loadedContent = useAppSelector(selectSelectedGroupsOfParentParentContents);
  const loadedExercise = useAppSelector(selectLoadedExerciseContent);
  const loadedExercises = useAppSelector(selectLoadedExercisesExercises);
  const groups = useAppSelector(selectGroupsOfSelectedGroupsOfParent);
  const isPublishingInProgress = useAppSelector(selectIsPublishingInProgress);

  const { publish: isPublishAvailable, unpublish: isUnpublishAvailable } = useIsPublishAvailable();

  const isCourseInvalid = useMemo(() => {
    return type === ContentTypes.course && !loadedCourse.validation.valid && !isExercise;
  }, [type, loadedCourse, isExercise]);

  const isContentInvalid = useMemo(() => {
    return (
      ((type === ContentTypes.lesson && !isExercise) ||
        (type === ContentTypes.level && !isExercise) ||
        (type === ContentTypes.chapter && !isExercise) ||
        (type === ContentTypes.activity && !isExercise)) &&
      !loadedContent?.validation.valid
    );
  }, [type, isExercise, loadedContent]);

  const isExerciseInvalid = useMemo(() => {
    return isExercise && !loadedExercise?.validation.valid;
  }, [isExercise, loadedExercise]);

  useEffect(() => {
    if (isExercise) {
      setPublishedContent(loadedExercise);
    } else if (type === ContentTypes.course) {
      setPublishedContent(loadedCourse);
    } else {
      setPublishedContent(loadedContent);
    }
  }, [type, isExercise, loadedExercise, loadedCourse, loadedContent]);

  const isButtonDisabled = () => {
    if (publishedContent?.ready) {
      // Unpublish button for course should be always disabled
      return !isExercise && type === ContentTypes.course;
    } else {
      if (
        type === ContentTypes.activity &&
        !isExercise &&
        !loadedExercises.filter((exercise) => exercise.ready).length
      ) {
        return true;
      }
      if (type !== ContentTypes.activity && !isExercise && !groups.filter((group) => group.ready).length) {
        if (
          loadedContent?.lessonType === ContentTypes.checkpoint ||
          loadedContent?.lessonType === ContentTypes.liveLesson ||
          loadedContent?.lessonType === ContentTypes.roleplay
        ) {
          return isContentInvalid;
        }
        return true;
      }
      return isCourseInvalid || isContentInvalid || isExerciseInvalid;
    }
  };

  useEffect(() => {
    const getChildName = () => {
      switch (type) {
        case ContentTypes.course: {
          return ContentTypes.level;
        }
        case ContentTypes.level: {
          return ContentTypes.chapter;
        }
        case ContentTypes.chapter: {
          return ContentTypes.lesson;
        }
        case ContentTypes.lesson: {
          return ContentTypes.activity;
        }
        case ContentTypes.activity: {
          return ContentTypes.exercise;
        }
      }
    };

    if (publishedContent?.ready) {
      if (!isExercise && type === ContentTypes.course) {
        setTooltipText(`It’s not possible to unpublish a ${type}.`);
      } else {
        setTooltipText('');
      }
    } else {
      if (
        type === ContentTypes.activity &&
        !isExercise &&
        !loadedExercises.filter((exercise) => exercise.ready).length
      ) {
        setTooltipText(`To publish this ${type}, it must contain at least 1 published ${getChildName()}.`);
      } else if (type !== ContentTypes.activity && !isExercise && !groups.filter((group) => group.ready).length) {
        if (
          loadedContent?.lessonType === ContentTypes.checkpoint ||
          loadedContent?.lessonType === ContentTypes.liveLesson ||
          loadedContent?.lessonType === ContentTypes.roleplay
        ) {
          setTooltipText('');
        } else {
          setTooltipText(`To publish this ${type}, it must contain at least 1 published ${getChildName()}.`);
        }
      } else {
        setTooltipText('');
      }
    }
  }, [type, isExercise, publishedContent, loadedExercises, groups, loadedExercise, loadedCourse, loadedContent]);

  const getParentIdForGroup = () => {
    if (type === ContentTypes.activity) {
      return lessonId;
    } else if (type === ContentTypes.lesson) {
      return chapterId;
    } else if (type === ContentTypes.chapter) {
      return levelId;
    } else {
      return courseId;
    }
  };

  const handleButtonClick = (forUpdate: boolean) => {
    if (type === ContentTypes.course && !isExercise) {
      dispatch(
        PublishingActionsCreator.setContentToPublish(
          loadedCourse.id,
          ContentTypes.course,
          loadedCourse.ready,
          forUpdate,
        ),
      );
    } else if (isExercise) {
      const isSlideshowOrPresentation =
        isExercise && [EXERCISE_SLIDE_PDF, EXERCISE_SLIDE_PPTX].includes(loadedExercise.type);
      const parentId = isSlideshowOrPresentation ? lessonId : activityId;

      dispatch(
        PublishingActionsCreator.setContentToPublish(
          loadedExercise?.id || '',
          ContentTypes.exercise,
          loadedExercise?.ready,
          forUpdate,
          parentId,
          'group',
        ),
      );
    } else {
      dispatch(
        PublishingActionsCreator.setContentToPublish(
          loadedContent?.id || '',
          ContentType.group,
          !!loadedContent?.ready,
          forUpdate,
          getParentIdForGroup(),
        ),
      );
    }
  };

  return (
    <CommonPublishButton
      contentType={isExercise ? 'exercise' : type}
      isButtonDisabled={isButtonDisabled()}
      isPublishAvailable={isPublishAvailable}
      isPublishedContent={publishedContent?.ready}
      isPublishingInProgress={isPublishingInProgress}
      isUpdateVisible={publishedContent?.ready && publishedContent?.changeStatus.hasNewChanges}
      isUpdateButtonDisabled={isCourseInvalid || isContentInvalid || isExerciseInvalid}
      isUnpublishVisible={isUnpublishAvailable && publishedContent?.ready}
      tooltipText={tooltipText}
      onPublishClick={handleButtonClick}
    />
  );
};
