import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';

import {
  AudioUploadingFailedInterface,
  ImageOrVideoUploadingInterface,
} from '@common/interfaces/contentTypes/UploadingFailedInterface';

import failIcon from '@components/ContentTypes/BaseContent/img/fail.svg';
import exclamationIcon from '@components/ValidationErrorDisplayer/exclamation.svg';
import { ValidationErrorDisplayer } from '@components/ValidationErrorDisplayer/ValidationErrorDisplayer';

const FailedUploadingImage = styled.img`
  left: 10rem;
  position: absolute;
  top: 1.5rem;

  &:hover {
    cursor: default;
  }
`;
const FailedUploadingMessageIcon = styled.img`
  margin-right: 1rem;
  width: 2rem;
`;
const FailedUploadingTooltip = styled(ReactTooltip)`
  background: ${({ theme }) => theme.color.secondary1} !important;
  display: flex;
  font-size: 1.4rem;
  line-height: 1.8rem;
  margin-top: -5rem !important;
  max-width: 25rem;
  position: fixed;
  z-index: 14000 !important;

  color: ${({ theme }) => theme.color.brandBlack} !important;
  padding: 5rem !important;

  .tooltip-inner {
    border-radius: 0.4rem;
    padding: 1rem 1rem;
  }

  &:after {
    border-top-color: ${({ theme }) => theme.color.secondary1} !important;
  }

  &:before {
    height: 0 !important;
  }
`;
const FailedUploadingTooltipContent = styled.div`
  display: flex;
`;

interface FailedUploadingMessageProps {
  errorDetails?: AudioUploadingFailedInterface | ImageOrVideoUploadingInterface;
  viaTooltip?: boolean;
}

export const FailedUploadingMessage = ({ errorDetails, viaTooltip = false }: FailedUploadingMessageProps) => {
  return (
    <>
      {errorDetails && (
        <>
          {viaTooltip && (
            <FailedUploadingTooltip id="uploading-failed-tooltip" effect="solid">
              <FailedUploadingTooltipContent>
                <FailedUploadingMessageIcon src={exclamationIcon} alt="Failed" />
                {errorDetails.details}
              </FailedUploadingTooltipContent>
            </FailedUploadingTooltip>
          )}
          {viaTooltip ? (
            <FailedUploadingImage
              alt="Failed"
              src={failIcon}
              data-tip={errorDetails.details}
              data-for="uploading-failed-tooltip"
            />
          ) : (
            <ValidationErrorDisplayer type="error" text={errorDetails.details} visible={true} />
          )}
        </>
      )}
    </>
  );
};
