import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { ContentTypes, type ContentTypesType } from '@common/enums/ContentTypes';
import { DBId } from '@common/types/DBId';
import { CommonNavigationItem, NavigationItemType, ChildrenArray } from '@features/content/navigation';
import { type LanguageV2, LANGUAGE_NAMES_V2 } from '@features/content/languages';
import { allTypesMap } from '@helpers/contentCardHelper';

const getTitle = (content: NavigationItemType, language: LanguageV2) => {
  if (content.type === ContentTypes.roleplay) {
    return `${LANGUAGE_NAMES_V2[language]} Roleplay`;
  }

  if (content.title) return content.title;

  return allTypesMap[content.type as ContentTypesType];
};

export const NavigationItem = ({
  content,
  structure,
  onExpand,
  loadingParentId,
  currentPathIds,
}: {
  content: NavigationItemType;
  structure: NavigationItemType[];
  onExpand: (type: ContentTypesType, id: DBId) => void;
  loadingParentId: string;
  currentPathIds: string[];
}) => {
  const { languageId, categoryId, scenarioId } = useParams<{
    languageId: LanguageV2;
    categoryId: DBId;
    scenarioId: DBId;
  }>();
  const [expanded, setExpanded] = useState(content.expanded);

  useEffect(() => {
    if (!expanded && (categoryId === content.id || scenarioId === content.id)) {
      setExpanded(true);
      content.children && handleExpand(content.type as ContentTypesType, content.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryId, scenarioId]);

  const handleExpand = (type: ContentTypesType, id: DBId) => {
    if (!loadingParentId) {
      setExpanded(!expanded);

      if (!expanded) {
        onExpand(type, id);
      }
    }
  };

  const getUrl = () => {
    let url = '';

    if (content.type === ContentTypes.roleplay) {
      url = `/roleplay/${languageId}`;
    } else if (content.type === ContentTypes.roleplayCategory) {
      url = `/roleplay/${languageId}/category/${content.id}`;
    } else if (content.type === ContentTypes.roleplayScenario) {
      const categoryId = structure.find((element) => element.id === content.parentId)?.id;
      url = `/roleplay/${languageId}/category/${categoryId}/scenario/${content.id}`;
    }

    return url;
  };
  const childrenArray = structure.filter((element) => element.parentId === content.id);
  const grandChildrenArray = structure.filter(
    (element) => element.parentId && childrenArray.map((element) => element.id).includes(element.parentId),
  );

  return (
    <>
      <CommonNavigationItem
        currentPathIds={currentPathIds}
        loadingParentId={loadingParentId}
        content={content}
        expanded={expanded}
        handleExpand={handleExpand}
        url={getUrl()}
        exerciseId=""
        title={getTitle(content, languageId)}
      />
      <ChildrenArray
        visible={content.children && expanded}
        childrenAmount={
          content.type === ContentTypes.grammarReview
            ? structure.filter((element) => element.type !== ContentTypes.grammarReview).length
            : childrenArray.length + grandChildrenArray.length
        }
      >
        {structure
          .filter((element) => element.parentId === content.id)
          .map((element) => (
            <NavigationItem
              key={element.id}
              content={element}
              structure={structure}
              onExpand={onExpand}
              loadingParentId={loadingParentId}
              currentPathIds={currentPathIds}
            />
          ))}
      </ChildrenArray>
    </>
  );
};
