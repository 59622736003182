import { VocabularyReviewActions } from '@actions/VocabularyReviewActions';
import type { LanguageV2 } from '@features/content/languages';
import type { VocabularyReviewViewParams } from '@features/content/vocabularyReview';

export const VocabularyReviewActionsCreator = {
  setInitialVocabularyReview: (languageId: LanguageV2) => ({
    type: VocabularyReviewActions.SET_INITIAL_VOCABULARY_REVIEW,
    payload: {
      language: languageId,
    },
  }),
  getLexicalItems: ({ language, page }: VocabularyReviewViewParams) => ({
    type: VocabularyReviewActions.GET_LEXICAL_ITEMS,
    payload: {
      language,
      page,
    },
  }),
  setInitialLexicalItem: (languageId: LanguageV2) => ({
    type: VocabularyReviewActions.SET_INITIAL_LEXICAL_ITEM,
    payload: {
      language: languageId,
    },
  }),
};
