import styled from 'styled-components';

import { DropdownButtonDirections } from '@common/enums/DropdownButtonDirections';
import { SearchModalActionsCreator } from '@actionCreators/SearchModalActionsCreator';
import { ProfileImage } from '@components/RolesAndPermissions/ProfileImage/ProfileImage';
import { ReactComponent as SearchIcon } from './search.svg';
import { useAppDispatch } from '@redux/store';
import { userTracking } from '@features/app/tracking';
import { css } from 'styled-components/macro';

const FooterContainer = styled.div<{ $collapsed: boolean }>`
  ${({ theme, $collapsed }) => css`
    display: flex;
    gap: 1.6rem;
    flex-direction: ${$collapsed ? 'column-reverse' : 'row'};
    align-items: center;
    border-top: 0.1rem solid ${theme.colorV2.uiDivider};
    padding: 1.6rem 0 2rem ${$collapsed ? '0' : '2.4rem'};
  `}
`;

const StyledProfileImage = styled(ProfileImage)`
  margin-left: 0;
`;

const SearchButton = styled.div`
  cursor: pointer;
`;

export const NavigationPanelFooter = ({ collapsed = false }: { collapsed?: boolean }) => {
  const dispatch = useAppDispatch();

  return (
    <FooterContainer $collapsed={collapsed}>
      <StyledProfileImage place={DropdownButtonDirections.up} />
      <SearchButton
        onClick={() => {
          dispatch(SearchModalActionsCreator.showSearchV2Panel());
          userTracking.logosSearchOpened({
            source: 'navigation_panel',
          });
        }}
      >
        <SearchIcon />
      </SearchButton>
    </FooterContainer>
  );
};
