import { FunctionComponent, useMemo } from 'react';
import classnames from 'classnames';
import { useFormikContext } from 'formik';

import { FormikValuesInterface } from '@helpers/formikInitialValuesHelper';
import { KeyboardShortcut, ImageContainer } from './common';
import { PhraseBuilderContentProps } from '../../PreviewModalProps';
import { removeBrTag } from '@helpers/htmlTagsHelper';
import { getValueByLanguage, openKTag, closedKTag } from '../../../../localization-utils';

import './PhraseBuilderContent.scss';

export const PhraseBuilderContent: FunctionComponent<PhraseBuilderContentProps> = ({
  content,
  deviceType,
  answerState,
  courseLearningLanguage,
  isForList,
}: PhraseBuilderContentProps) => {
  const { values }: { values: FormikValuesInterface } = useFormikContext();
  const isCorrectSelected = answerState === 1;

  const phrase = useMemo(
    () =>
      getValueByLanguage(
        isForList ? content.mainBundle?.phrase?.textLocalizations : values.mainBundle_phrase,
        courseLearningLanguage,
      ),
    [values, courseLearningLanguage, content.mainBundle, isForList],
  );
  const phraseArray = phrase.includes(closedKTag) ? phrase.split(closedKTag) : phrase.split(closedKTag.toUpperCase());

  return (
    <>
      <ImageContainer
        deviceType={deviceType}
        isImageEnabled={false}
        isAudioEnabled={content.displayAudio}
        imageSrc={''}
        isVocabulary={false}
      />
      <div
        className={classnames('phrase-builder-field', {
          'phrase-builder-field--empty': !answerState,
        })}
      >
        {answerState ? (
          <div className="phrase-builder-words">
            {isCorrectSelected ? (
              <>
                {phrase.length &&
                  phraseArray.map((rawWord: string) => {
                    const word = removeBrTag(rawWord);
                    return (
                      <>
                        {word ? (
                          <div
                            className={classnames('phrase-builder-words__word', 'phrase-builder-words__word--correct')}
                          >
                            {word.includes(openKTag) || word.includes(openKTag.toUpperCase())
                              ? word.replace(openKTag, '').replace(openKTag.toUpperCase(), '')
                              : word}
                          </div>
                        ) : null}
                      </>
                    );
                  })}
              </>
            ) : (
              <>
                {phrase.length &&
                  phraseArray.reverse().map((rawWord: string) => {
                    const word = removeBrTag(rawWord);
                    return (
                      <>
                        {word ? (
                          <div
                            className={classnames(
                              'phrase-builder-words__word',
                              'phrase-builder-words__word--incorrect',
                            )}
                          >
                            {word.includes(openKTag) || word.includes(openKTag.toUpperCase())
                              ? word.replace(openKTag, '').replace(openKTag.toUpperCase(), '')
                              : word}
                          </div>
                        ) : null}
                      </>
                    );
                  })}
              </>
            )}
          </div>
        ) : null}
      </div>
      <div className="phrase-builder-words">
        {phrase.length
          ? phraseArray.map((rawWord: string, index: number) => {
              const word = removeBrTag(rawWord);
              return (
                <>
                  {word ? (
                    <div
                      className={classnames('phrase-builder-words__word', {
                        'phrase-builder-words__word--answered': answerState,
                      })}
                    >
                      {word.includes(openKTag) || word.includes(openKTag.toUpperCase())
                        ? word.replace(openKTag, '').replace(openKTag.toUpperCase(), '')
                        : word}
                      <KeyboardShortcut answerState={answerState} value={index + 1} />
                    </div>
                  ) : null}
                </>
              );
            })
          : null}
      </div>
    </>
  );
};
