const UPLOAD = 'UPLOAD_AUDIOS_UPLOAD';
const UPLOAD_SUCCESS = 'UPLOAD_AUDIOS_UPLOAD_SUCCESS';
const UPLOAD_FAIL = 'UPLOAD_AUDIOS_UPLOAD_FAIL';
const CLOSE = 'UPLOAD_AUDIOS_CLOSE';

export const UploadAudiosActions = {
  UPLOAD,
  UPLOAD_SUCCESS,
  UPLOAD_FAIL,
  CLOSE,
} as const;
