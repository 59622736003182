import { FormikContextType, useFormikContext } from 'formik';
import { useHistory, useParams } from 'react-router-dom';
import { DBId } from '@common/types/DBId';
import BreadcrumbNavigation from '@components/BreadcrumbNavigation/BreadcrumbNavigation';
import { constants as contentConstants } from '@features/content';
import { GrammarTopicFormikValues } from '../types';
import { useAppSelector } from '@redux/store';
import { selectGrammarNavigationStructure } from '@selectors/GrammarSelectors';

export const Breadcrumbs = () => {
  const history = useHistory();
  const { courseId, categoryId } = useParams<{ courseId: DBId; categoryId: DBId }>();

  const navigation = useAppSelector(selectGrammarNavigationStructure);
  const formikContext: FormikContextType<GrammarTopicFormikValues> = useFormikContext();

  const englishTopicName =
    formikContext?.values.title?.find((loc) => loc.language === contentConstants.DEFAULT_LANGUAGE)?.value ||
    'Grammar Topic';

  return (
    <BreadcrumbNavigation
      breadcrumbItems={[
        <span
          className="breadcrumb-navigation__node breadcrumb-navigation__node_link"
          onClick={() => history.push(`/course/${courseId}/grammar-review`)}
        >
          Grammar Review
        </span>,
        <span
          className="breadcrumb-navigation__node breadcrumb-navigation__node_link"
          onClick={() => history.push(`/course/${courseId}/grammar-review/category/${categoryId}`)}
        >
          {navigation.find((element) => element.id === categoryId)?.title || 'Grammar Category'}
        </span>,
        <span>{englishTopicName}</span>,
      ]}
    />
  );
};
