import { useState } from 'react';
import { Modal } from 'react-bootstrap';

import { searchByChapter } from '../../service';
import { useSearch } from '../../../SearchContext';
import { ReactComponent as ChapterIcon } from '../../img/chapter.svg';
import { ChapterSearchResultItem, ChapterSearchResultType } from '../../SearchResultItem';
import { SearchResults } from '../../SearchResults';
import {
  DEFAULT_PAGINATION_PAGE,
  SearchHeader,
  SearchResultsWrapper,
  useFiltersState,
  useResultsHandler,
} from '../Common';
import { ChapterSearchResultTypeHeader } from '../../SearchResultTypeHeader';
import { useAppSelector } from '@redux/store';
import { selectCourses } from '@selectors/CoursesSelectors';
import { saveFavouriteSearch } from '../Common/favouriteSearches';
import { SEARCH_TYPE_CHAPTER } from '../../../../types';
import type { CommonSearchComponentProps } from '../SearchContent';
import type { ChapterQueryParamsType } from '../../service/types';
import { ChapterSearchFilters } from './ChapterSearchFilters';
import { selectSearchPanel } from '@selectors/UiSelectors';

const defaultFilters: ChapterQueryParamsType = {
  languages: null,
  courses: null,
  isPublished: null,
  lessonTypes: null,
  numLessons: null,
};

/**
 * This component is responsible for:
 * - Modal layout for Chapter search
 * - All logic that is specific to Chapter search
 * - Calling Chapter search API
 * - Storing search filters
 * - Storing search results
 */
export const ChapterSearch = ({ trackSearch, onBack }: CommonSearchComponentProps) => {
  const availableCourses = useAppSelector(selectCourses);
  const { filtersPreset } = useAppSelector(selectSearchPanel);

  const { search, query } = useSearch();

  const { results, count, clearResults, onSuccess, onError } = useResultsHandler<ChapterSearchResultType>();
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGINATION_PAGE);

  const filtersState = useFiltersState(defaultFilters, filtersPreset?.chapter || {});

  const onPaginatedSearch = (query: string | null, page: number) => {
    if (query) {
      search(() => searchByChapter(query, page, filtersState.state))
        .then(onSuccess)
        .catch(onError);

      trackSearch();
    } else {
      clearResults();
    }
  };

  const onFreshSearch = (query: string | null) => {
    onPaginatedSearch(query, DEFAULT_PAGINATION_PAGE);
    setCurrentPage(DEFAULT_PAGINATION_PAGE);
  };
  const onPageChange = (page: number) => {
    setCurrentPage(page);
    onPaginatedSearch(query, page);
  };

  return (
    <>
      <Modal.Header closeButton>
        <SearchHeader
          filtersArea={
            <ChapterSearchFilters
              filtersState={filtersState}
              availableCourses={availableCourses}
              onSaveFilters={async (name) => saveFavouriteSearch(name, SEARCH_TYPE_CHAPTER, filtersState.state)}
            />
          }
          icon={<ChapterIcon />}
          title="Chapter search"
          onSearch={onFreshSearch}
          onBack={onBack}
        />
      </Modal.Header>

      <Modal.Body>
        <SearchResultsWrapper onPageCount={results.length}>
          <SearchResults<ChapterSearchResultType>
            results={results}
            count={count}
            currentPage={currentPage}
            setCurrentPage={onPageChange}
            header={<ChapterSearchResultTypeHeader />}
            renderItem={(item) => <ChapterSearchResultItem content={item} key={item.id} />}
          />
        </SearchResultsWrapper>
      </Modal.Body>
    </>
  );
};
