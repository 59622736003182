import produce from 'immer';
import { PayloadAction } from '@reduxjs/toolkit';

import { VocabularyReviewActions } from '@actions/VocabularyReviewActions';
import { LoadingStage } from '@common/enums/LoadingStage';
import { VocabularyReviewSliceType } from '@common/interfaces/slices/VocabularyReviewSliceType';
import { LanguageV2 } from '@features/content/languages';
import type { VocabularyReviewListItem } from '@features/content/vocabularyReview';
import { lexicalItemInitialContent } from '@redux/initialStates/vocabularyReviewInitialState';

export const VocabularyReviewReducers = {
  // Vocabulary Review
  [VocabularyReviewActions.SET_INITIAL_VOCABULARY_REVIEW]: (
    state: VocabularyReviewSliceType,
    { payload }: PayloadAction<{ language: LanguageV2 }>,
  ) => {
    return produce(state, (draft) => {
      draft.vocabularyReview.language = payload.language;
      draft.vocabularyReview.loaded = LoadingStage.notLoaded;
    });
  },
  [VocabularyReviewActions.VOCABULARY_REVIEW_LOADING]: (state: VocabularyReviewSliceType) => {
    return produce(state, (draft) => {
      draft.vocabularyReview.loaded = LoadingStage.loading;
    });
  },
  [VocabularyReviewActions.VOCABULARY_REVIEW_LOADED]: (
    state: VocabularyReviewSliceType,
    { payload }: PayloadAction<{ lexicalItems: VocabularyReviewListItem[]; totalCount: number }>,
  ) => {
    return produce(state, (draft) => {
      draft.vocabularyReview.loaded = LoadingStage.loaded;
      draft.vocabularyReview.totalCount = payload.totalCount;
    });
  },
  // Lexical Item
  [VocabularyReviewActions.SET_INITIAL_LEXICAL_ITEM]: (
    state: VocabularyReviewSliceType,
    { payload }: PayloadAction<{ language: LanguageV2 }>,
  ) => {
    return produce(state, (draft) => {
      draft.lexicalItem.language = payload.language;
      draft.lexicalItem.loaded = LoadingStage.notLoaded;
      draft.lexicalItem.content = lexicalItemInitialContent;
    });
  },
};
