const SAVE = 'CONVERSATION_SAVE';
const SET_INSTRUCTIONS_LANGUAGE = 'CONVERSATION_SET_INSTRUCTIONS_LANGUAGE';
const UPLOAD_IMAGE1 = 'CONVERSATION_UPLOAD_IMAGE1';
const UPLOAD_IMAGE2 = 'CONVERSATION_UPLOAD_IMAGE2';
const UPLOAD_IMAGE3 = 'CONVERSATION_UPLOAD_IMAGE3';
const UPDATE_MINIMUM_NUMBER_OF_WORDS = 'CONVERSATION_UPDATE_MINIMUM_NUMBER_OF_WORDS';
const SET_CONVERSATION_ALL_FIELDS = 'SET_CONVERSATION_ALL_FIELDS';

export const ConversationExerciseActions = {
  SAVE,
  SET_INSTRUCTIONS_LANGUAGE,
  UPLOAD_IMAGE1,
  UPLOAD_IMAGE2,
  UPLOAD_IMAGE3,
  UPDATE_MINIMUM_NUMBER_OF_WORDS,
  SET_CONVERSATION_ALL_FIELDS,
} as const;
