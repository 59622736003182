import styled from 'styled-components';

import preloaderIcon from './img/loader.svg';

const StyledAnimatedLoader = styled.img`
  @keyframes loader {
    0% {
      transform: rotate(0deg);
    }

    50% {
      transform: rotate(180deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  animation: loader 2s infinite;
`;

export const AnimatedLoader = ({ className }: { className?: string }) => (
  <StyledAnimatedLoader src={preloaderIcon} alt="Loader" className={className} />
);
