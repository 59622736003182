import { Modal } from 'react-bootstrap';
import styled from 'styled-components/macro';

export const StyledModal = styled(Modal)`
  .modal-content {
    border: none;
    box-shadow: 0 0.8rem 1.2rem 0.1rem rgba(0, 0, 0, 0.1);
    border-radius: 1.6rem;
    min-height: 5rem;
  }
  .modal-body {
    padding: 4rem;
    display: flex;
    flex-direction: column;
    text-align: center;
  }
`;

export const Title = styled.span`
  color: ${({ theme }) => theme.colorV2.textPrimary};
  font-size: 2rem;
  font-weight: 700;
  line-height: 130%;
  margin-bottom: 0.8rem;
`;

export const Description = styled.div`
  color: ${({ theme }) => theme.colorV2.textSecondary};
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 150%;
`;

export const ButtonArea = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 3.2rem;
`;
