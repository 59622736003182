import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useCallback } from 'react';
import 'draft-js/dist/Draft.css';
import { UserLanguageCheckbox } from '@components/Exercises/Common/UserLanguageCheckbox';
import { LoadedMultipleChoiceExerciseInterface } from '@common/interfaces/exercises/LoadedExerciseInterface';
import { ValidationErrorDisplayer } from '@components/ValidationErrorDisplayer';
import { TranslationTipActionsCreator } from '@actionCreators/TranslationTipActionsCreator';
import { ResourceBundleWrapper } from '@components/Exercises/Common/ResourceBundle';
import ImageUploadDimensionDescriptors from '@common/enums/FileUploadDimensionDescriptors';
import { ValidationErrorInterface } from '@common/interfaces/validation/ValidationInterface';
import { AudioUploadActionsCreator } from '@actionCreators/AudioUploadActionsCreator';
import { CommonExerciseActionsCreator } from '@actionCreators/CommonExerciseActionsCreator';
import { Explanation, Title, TitleContainer } from '@components/Exercises/Common';
import { HelpDisplayer } from '@features/help';
import type { DisplayContentToUserModeType } from '@common/enums/DisplayContentToUserMode';
import { WritableInputText } from '@components/WritableInputText';
import TranslationsTipV2 from '@components/TranslationTipV2/TranslationTipV2';
import { CommonSwitcher } from '@components/Exercises/Common/CommonSwitcher';
import { getResources } from '@helpers/getResourcesHelper';
import ContentToggler from '@components/Exercises/Common/ContentToggler/ContentToggler';
import { RecapSelector } from '@components/Exercises/Common/RecapSelector/RecapSelector';
import { PreviewModal } from '@features/content/preview';
import { constants as contentConstants, findLocalizationInSearchedLanguage } from '@features/content';
import ExerciseProps from '@components/Exercises/ExerciseProps';
import { ImageUploadModes } from '@common/enums/FileUploadModes';
import ImageUploadUtils from '@components/MediaUpload/ImageUploadUtils';
import { AudioUpload, ImageUpload } from '@components/MediaUpload';
import { ExerciseTypes } from '@common/enums/ExerciseTypes';
import { ContentTypes } from '@common/enums/ContentTypes';
import { ExerciseCommonActionCreators as ExerciseCommonActions } from '@actionCreators/ExerciseCommonActionCreator';
import { ExperimentSelector } from '@features/experiment';
import { useIsEditAvailable } from '@features/content/courses';
import helpersService from '@services/HelpersService';
import { AccessWarning } from '@components/Warning';
import { Sizes } from '@common/enums/Sizes';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { MultipleChoiceExerciseActionsCreator } from '@actionCreators/MultipleChoiceExerciseActionsCreator';
import MultipleChoiceExerciseInterface from './interfaces/MultipleChoiceExerciseInterface';
import { Language } from '@features/content/languages';
import { DisplayMediaSelector, Instructions, removeMediaProcessingValidationError } from '@features/content/exercises';
import {
  selectInterfaceLanguages,
  selectLearningLanguage,
  selectLoadedExerciseData,
} from '@selectors/CoursesSelectors';

const { DEFAULT_LANGUAGE, MEDIA_PROCESSING_VALIDATION_MESSAGE } = contentConstants;

const MultipleChoiceExercise = ({ exercise: exerciseParam }: ExerciseProps<LoadedMultipleChoiceExerciseInterface>) => {
  const dispatch = useAppDispatch();
  const { isEditAvailable } = useIsEditAvailable();
  const courseLearningLanguage = useAppSelector(selectLearningLanguage);
  const interfaceLanguages = useAppSelector(selectInterfaceLanguages);

  const loadedExercise = useAppSelector(selectLoadedExerciseData) as MultipleChoiceExerciseInterface;

  const audioLocalizations = loadedExercise?.content?.answerBundle?.phrase?.audioLocalizations;

  const availableLanguagesForAudioLocalizations = Array.from(new Set([...interfaceLanguages, courseLearningLanguage]));
  const availableAudioLocalizations = audioLocalizations.filter((audioLocalization) =>
    availableLanguagesForAudioLocalizations.includes(audioLocalization.language),
  );

  // Image only has EN localization
  const imageLocalization = loadedExercise?.content?.answerBundle?.image?.imageLocalizations?.find(
    (imageLocalization) => imageLocalization.language === DEFAULT_LANGUAGE,
  );

  const { errors } = loadedExercise?.content?.validation;

  const instructionsErrors = errors.filter((error: ValidationErrorInterface) => error.field === 'instructions');
  const mainImageErrors = errors.filter((error: ValidationErrorInterface) => error.field === 'answerBundle.image');

  if (mainImageErrors.length && imageLocalization?.processed && imageLocalization?.value) {
    const updatedErrors = removeMediaProcessingValidationError({
      errors,
      message: MEDIA_PROCESSING_VALIDATION_MESSAGE.IMAGE.replace('%LANG%', DEFAULT_LANGUAGE),
    });

    if (updatedErrors.length < errors.length) {
      dispatch(CommonExerciseActionsCreator.updateValidationErrors({ errors: updatedErrors }));
    }
  }

  const answerBundleErrors = errors.filter((error: ValidationErrorInterface) => error.field === 'answerBundle');
  const answerPhraseTextErrors = errors.filter(
    (error: ValidationErrorInterface) => error.field === 'answerBundle.phrase' && error.message.includes('text'),
  );
  const answerPhraseErrors = errors.filter((error: ValidationErrorInterface) => error.field === 'answerBundle.phrase');
  const answerAudioErrors = errors.filter(
    (error: ValidationErrorInterface) =>
      error.field === 'answerBundle.phrase' && error.message.toLowerCase().includes('audio'),
  );

  if (answerAudioErrors.length) {
    let updatedErrors = [...errors];

    availableAudioLocalizations.forEach(({ language, processed, value }) => {
      if (processed && value) {
        updatedErrors = removeMediaProcessingValidationError({
          errors,
          message: MEDIA_PROCESSING_VALIDATION_MESSAGE.AUDIO.replace('%LANG%', language),
        });
      }
    });

    if (updatedErrors.length < errors.length) {
      dispatch(CommonExerciseActionsCreator.updateValidationErrors({ errors: updatedErrors }));
    }
  }

  const distractorsErrors = errors.filter((error: ValidationErrorInterface) => error.field === 'distractors');
  const distractors0Errors = errors.filter(
    (error: ValidationErrorInterface) =>
      error.field === 'distractors[0].phrase' || error.field === 'distractor1.phrase',
  );
  const distractors0ImageErrors = errors.filter(
    (error: ValidationErrorInterface) => error.field === 'distractors[0].image' || error.field === 'distractor1.image',
  );
  const distractors1Errors = errors.filter(
    (error: ValidationErrorInterface) =>
      error.field === 'distractors[1].phrase' || error.field === 'distractor2.phrase',
  );
  const distractors1ImageErrors = errors.filter(
    (error: ValidationErrorInterface) => error.field === 'distractors[1].image' || error.field === 'distractor2.image',
  );

  const isAnswerChangeBlocked =
    (loadedExercise.content?.answerBundle?.phrase?.mappings?.count > 1 ||
      loadedExercise.content?.answerBundle?.phrase?.mappings?.length > 1) &&
    !loadedExercise.content?.answerBundle?.phrase?.isReusingConfirmed;

  const isAnswerBundleChangeBlocked =
    loadedExercise.content.answerBundle?.mappings?.count &&
    loadedExercise.content.answerBundle?.mappings?.count > 1 &&
    !loadedExercise.content.answerBundle?.isReusingConfirmed;
  const isDistractor1ChangeBlocked =
    loadedExercise.content.distractor1?.mappings?.count &&
    loadedExercise.content.distractor1?.mappings?.count > 1 &&
    !loadedExercise.content.distractor1?.isReusingConfirmed;
  const isDistractor2ChangeBlocked =
    loadedExercise.content.distractor2?.mappings?.count &&
    loadedExercise.content.distractor2?.mappings?.count > 1 &&
    !loadedExercise.content.distractor2?.isReusingConfirmed;

  const requestOrCancelAudioPayload = {
    bundleName: 'answerBundle',
    field: 'phrase',
    language: courseLearningLanguage,
    type: ContentTypes.exercise,
  };

  const setReusedData = (id: string, field: string, bundleName: string, isBundle: boolean) => {
    dispatch(
      TranslationTipActionsCreator.setCurrentContentId(
        id,
        ContentTypes.exercise,
        field,
        undefined,
        undefined,
        bundleName,
        isBundle,
      ),
    );
  };

  const onProcessingFinished = useCallback(
    (url: string) => {
      dispatch(
        CommonExerciseActionsCreator.setValueAfterProcessing({
          url,
          mediaType: 'audio',
          type: ContentTypes.exercise,
          field: 'phrase',
          language: courseLearningLanguage,
          bundleName: 'answerBundle',
        }),
      );
    },
    [dispatch, courseLearningLanguage],
  );

  return (
    <div className="exercise-multiple-choice">
      <PreviewModal type={loadedExercise.type} content={loadedExercise.content} />
      <TitleContainer>
        <Title>Multiple Choice</Title>
        <HelpDisplayer type="guideline" id="guideline-multiple-choice-exercise" />
      </TitleContainer>
      <ExperimentSelector
        isEditAvailable={isEditAvailable}
        currentExperiment={loadedExercise.content.experiment}
        onExperimentChange={(experiment) => dispatch(ExerciseCommonActions.setExperimentValue(experiment))}
      />
      {!isEditAvailable && <AccessWarning />}
      <div className="exercise-multiple-choice__wrapper">
        <div className="exercise-multiple-choice__container">
          <h1 className="exercise-multiple-choice__box-title">{'Instruction'}</h1>
          <ValidationErrorDisplayer text={helpersService.getValidationErrorMessageText(instructionsErrors)} />
        </div>
        <Instructions />
        <UserLanguageCheckbox
          checkedField={exerciseParam.exercise?.content.instructionsLanguage}
          onChange={(displayContentToUserMode: DisplayContentToUserModeType) =>
            dispatch(MultipleChoiceExerciseActionsCreator.setInstructionsLanguage(displayContentToUserMode))
          }
        />
      </div>
      <div className="exercise-multiple-choice__wrapper">
        <div className="exercise-multiple-choice__container">
          <h1 className="exercise-multiple-choice__box-title">Media to display</h1>
        </div>
        <DisplayMediaSelector
          isImageEnabled={loadedExercise.content.mainImageEnabled}
          isAudioEnabled={loadedExercise.content.mainAudioEnabled}
          onChange={({ audioEnabled, imageEnabled }) => {
            dispatch(ExerciseCommonActions.setAudioEnabled(audioEnabled));
            dispatch(ExerciseCommonActions.setImageEnabled(imageEnabled));
          }}
        />
      </div>

      <div className="exercise-multiple-choice__wrapper">
        <div className="exercise-multiple-choice__container">
          <h1 className="exercise-multiple-choice__box-title">
            Exercise answers: correct and distractors (max 2 entities):{' '}
          </h1>
        </div>
        <div className="exercise-multiple-choice__controller">
          <UserLanguageCheckbox
            checkedField={exerciseParam.exercise?.content.choicesLanguage}
            onChange={(displayContentToUserMode: DisplayContentToUserModeType) =>
              dispatch(MultipleChoiceExerciseActionsCreator.setChoicesLanguage(displayContentToUserMode))
            }
          />
          <CommonSwitcher
            value={loadedExercise.content.choicesImagesEnabled}
            onChange={() => {
              if (isEditAvailable) {
                dispatch(MultipleChoiceExerciseActionsCreator.setImagesEnabled());
              }
            }}
            text={'Show answer images'}
          />
        </div>
      </div>
      <ResourceBundleWrapper
        bundle={loadedExercise.content.answerBundle}
        bundleName={'answerBundle'}
        errors={answerBundleErrors}
        resources={getResources(loadedExercise, courseLearningLanguage, 'answerBundle')}
      >
        <>
          <div className="exercise-multiple-choice__wrapper">
            <div className="exercise-multiple-choice__container">
              <h3 className="exercise-multiple-choice__box-title">Correct answer</h3>
              <ValidationErrorDisplayer
                text={helpersService.getValidationErrorMessageText(
                  answerPhraseTextErrors.length ? answerPhraseTextErrors : answerPhraseErrors,
                )}
              />
            </div>
            <TranslationsTipV2
              visitedBranch={'phrase'}
              bundleName={'answerBundle'}
              errors={answerPhraseTextErrors.length ? answerPhraseTextErrors : answerPhraseErrors}
              showErrorDisplayer={false}
            >
              <WritableInputText id="multiple-choice-answer-input" />
            </TranslationsTipV2>
          </div>

          <div className="exercise-multiple-choice__wrapper">
            <ContentToggler
              noMargin
              text="Image"
              open={!!loadedExercise.content.answerBundle.image?._id}
              onRemove={() => {
                if (isAnswerBundleChangeBlocked) {
                  setReusedData(loadedExercise.content.answerBundle?._id || '', 'image', 'answerBundle', true);
                } else {
                  dispatch(MultipleChoiceExerciseActionsCreator.removeImage());
                }
              }}
              isChangeBlocked={!!isAnswerBundleChangeBlocked}
              errors={mainImageErrors}
            >
              <ImageUpload
                width={ImageUploadDimensionDescriptors.multipleChoice.image.width}
                height={ImageUploadDimensionDescriptors.multipleChoice.image.height}
                size={Sizes.fullscreen}
                mode={ImageUploadModes.normal}
                onChange={(file: File, progressHandler: Function) => {
                  if (file !== null && loadedExercise.content.id !== undefined) {
                    dispatch(MultipleChoiceExerciseActionsCreator.setImage(loadedExercise, file, progressHandler));
                  }
                }}
                onRemove={() => {
                  dispatch(MultipleChoiceExerciseActionsCreator.removeImage());
                }}
                imageData={ImageUploadUtils.getDisplayImageForFileUpload(
                  loadedExercise,
                  'image',
                  undefined,
                  'answerBundle',
                )}
                onProcessingFinished={(url: string) => {
                  dispatch(
                    CommonExerciseActionsCreator.setValueAfterProcessing({
                      url,
                      mediaType: 'image',
                      type: ContentTypes.exercise,
                      field: 'image',
                      language: DEFAULT_LANGUAGE,
                      bundleName: 'answerBundle',
                    }),
                  );
                }}
                previewMode={false}
                isForExercise
                errors={mainImageErrors}
                fieldName="answerImage"
                isChangeBlocked={!!isAnswerBundleChangeBlocked}
                onChangeInstant={() =>
                  setReusedData(loadedExercise.content.answerBundle?._id || '', 'image', 'answerBundle', true)
                }
              />
            </ContentToggler>
          </div>

          <div className="exercise-multiple-choice__wrapper">
            <ContentToggler
              noMargin
              text="Audio"
              open={
                !!findLocalizationInSearchedLanguage(
                  loadedExercise?.content?.answerBundle?.phrase?.audioLocalizations,
                  courseLearningLanguage as Language,
                )?.value
              }
              onRemove={() => {
                if (isAnswerBundleChangeBlocked) {
                  setReusedData(loadedExercise.content.answerBundle?._id || '', 'phrase', 'answerBundle', true);
                } else if (isAnswerChangeBlocked) {
                  setReusedData(
                    loadedExercise.content.answerBundle?.phrase?._id ||
                      loadedExercise.content.answerBundle?.phrase?.id ||
                      '',
                    'phrase',
                    'answerBundle',
                    false,
                  );
                } else {
                  dispatch(
                    MultipleChoiceExerciseActionsCreator.removeAudio({
                      contentId:
                        loadedExercise.content.answerBundle?.phrase?._id ||
                        loadedExercise.content.answerBundle?.phrase?.id,
                      language: courseLearningLanguage,
                    }),
                  );
                }
              }}
              isChangeBlocked={isAnswerBundleChangeBlocked || isAnswerChangeBlocked}
              errors={answerAudioErrors}
            >
              <AudioUpload
                audioData={ImageUploadUtils.getAudioForFileUpload(
                  loadedExercise,
                  'phrase',
                  courseLearningLanguage,
                  'answerBundle',
                )}
                onAudioRequestSuccess={(audioRequestId: string) => {
                  dispatch(
                    CommonExerciseActionsCreator.setAudioValueAfterRequestOrCancelAudio({
                      ...requestOrCancelAudioPayload,
                      audioRequestData: {
                        id: audioRequestId,
                        status: 'new',
                      },
                    }),
                  );
                }}
                onCancelAudioRequestSuccess={() => {
                  dispatch(
                    CommonExerciseActionsCreator.setAudioValueAfterRequestOrCancelAudio(requestOrCancelAudioPayload),
                  );
                }}
                onProcessingFinished={onProcessingFinished}
                onChange={(uploadedSound, progressHandler) => {
                  dispatch(
                    AudioUploadActionsCreator.uploadSound(
                      loadedExercise.content.id,
                      ExerciseTypes.multipleChoice,
                      courseLearningLanguage,
                      loadedExercise.content?.answerBundle?.phrase?._id,
                      findLocalizationInSearchedLanguage(
                        loadedExercise?.content?.answerBundle?.phrase?.audioLocalizations,
                        courseLearningLanguage as Language,
                      ),
                      uploadedSound,
                      'phrase',
                      undefined,
                      undefined,
                      true,
                      'answerBundle',
                      progressHandler,
                    ),
                  );
                }}
                onChangeInstant={() => {
                  if (isAnswerBundleChangeBlocked) {
                    setReusedData(loadedExercise.content.answerBundle?._id || '', 'phrase', 'answerBundle', true);
                  } else {
                    setReusedData(
                      loadedExercise.content.answerBundle?.phrase?._id ||
                        loadedExercise.content.answerBundle?.phrase?.id ||
                        '',
                      'phrase',
                      'answerBundle',
                      false,
                    );
                  }
                }}
                isChangeBlocked={isAnswerBundleChangeBlocked || isAnswerChangeBlocked}
                onRemove={() => {
                  dispatch(
                    MultipleChoiceExerciseActionsCreator.removeAudio({
                      contentId:
                        loadedExercise.content.answerBundle?.phrase?._id ||
                        loadedExercise.content.answerBundle?.phrase?.id,
                      language: courseLearningLanguage,
                    }),
                  );
                }}
                fullScreen
                currentLanguage={courseLearningLanguage}
                fieldName={'phrase'}
                errors={answerAudioErrors}
              />
            </ContentToggler>
          </div>
        </>
      </ResourceBundleWrapper>

      <div className="exercise-multiple-choice__wrapper">
        <div className="exercise-multiple-choice__container">
          <h3 className="exercise-multiple-choice__box-title">Distractors</h3>
          <ValidationErrorDisplayer text={helpersService.getValidationErrorMessageText(distractorsErrors)} />
        </div>
        <ResourceBundleWrapper
          bundle={loadedExercise.content.distractor1}
          bundleName={'distractor1'}
          withRedBackground
          resources={getResources(loadedExercise, courseLearningLanguage, 'distractor1')}
        >
          <>
            <div className="exercise-multiple-choice__wrapper">
              <div className="exercise-multiple-choice__container">
                <h3 className="exercise-multiple-choice__box-title">Distractor</h3>
                <ValidationErrorDisplayer text={helpersService.getValidationErrorMessageText(distractors0Errors)} />
              </div>
              <TranslationsTipV2
                visitedBranch={'phrase'}
                bundleName={'distractor1'}
                errors={distractorsErrors?.length ? distractorsErrors : distractors0Errors}
                showErrorDisplayer={false}
              >
                <WritableInputText id="multiple-choice-distractor1-input" />
              </TranslationsTipV2>
            </div>
            {loadedExercise.content.choicesImagesEnabled && (
              <div className="exercise-multiple-choice__wrapper">
                <ContentToggler
                  noMargin
                  text="Image"
                  open={!!loadedExercise.content.distractor1.image?._id}
                  onRemove={() => {
                    if (isDistractor1ChangeBlocked) {
                      setReusedData(loadedExercise.content.distractor1?._id || '', 'image', 'distractor1', true);
                    } else {
                      dispatch(MultipleChoiceExerciseActionsCreator.removeDistractor1Image());
                    }
                  }}
                  isChangeBlocked={!!isDistractor1ChangeBlocked}
                  errors={distractors0ImageErrors}
                >
                  <ImageUpload
                    width={ImageUploadDimensionDescriptors.multipleChoice.image.width}
                    height={ImageUploadDimensionDescriptors.multipleChoice.image.height}
                    size={Sizes.fullscreen}
                    mode={ImageUploadModes.normal}
                    onChange={(file: File, progressHandler: Function) => {
                      if (file !== null && loadedExercise.content.id !== undefined) {
                        dispatch(
                          MultipleChoiceExerciseActionsCreator.setDistractor1Image(
                            loadedExercise,
                            file,
                            progressHandler,
                          ),
                        );
                      }
                    }}
                    onRemove={() => {
                      dispatch(MultipleChoiceExerciseActionsCreator.removeDistractor1Image());
                    }}
                    imageData={ImageUploadUtils.getDisplayImageForFileUpload(
                      loadedExercise,
                      'image',
                      undefined,
                      'distractor1',
                    )}
                    onProcessingFinished={(url: string) => {
                      dispatch(
                        CommonExerciseActionsCreator.setValueAfterProcessing({
                          url,
                          mediaType: 'image',
                          type: ContentTypes.exercise,
                          field: 'image',
                          language: DEFAULT_LANGUAGE,
                          bundleName: 'distractor1',
                        }),
                      );
                    }}
                    previewMode={false}
                    isForExercise
                    errors={distractors0ImageErrors}
                    fieldName="distractor1Image"
                    isChangeBlocked={!!isDistractor1ChangeBlocked}
                    onChangeInstant={() =>
                      setReusedData(loadedExercise.content.distractor1?._id || '', 'image', 'distractor1', true)
                    }
                  />
                </ContentToggler>
              </div>
            )}
          </>
        </ResourceBundleWrapper>

        <ResourceBundleWrapper
          bundle={loadedExercise.content.distractor2}
          bundleName={'distractor2'}
          withRedBackground
          resources={getResources(loadedExercise, courseLearningLanguage, 'distractor2')}
        >
          <>
            <div className="exercise-multiple-choice__wrapper">
              <div className="exercise-multiple-choice__container">
                <h3 className="exercise-multiple-choice__box-title">Distractor</h3>
                <ValidationErrorDisplayer text={helpersService.getValidationErrorMessageText(distractors1Errors)} />
              </div>
              <TranslationsTipV2
                visitedBranch={'phrase'}
                bundleName={'distractor2'}
                errors={distractorsErrors?.length ? distractorsErrors : distractors1Errors}
                showErrorDisplayer={false}
              >
                <WritableInputText id="multiple-choice-distractor2-input" />
              </TranslationsTipV2>
            </div>
            {loadedExercise.content.choicesImagesEnabled && (
              <div className="exercise-multiple-choice__wrapper">
                <ContentToggler
                  noMargin
                  text="Image"
                  open={!!loadedExercise.content.distractor2.image?._id}
                  onRemove={() => {
                    if (isDistractor2ChangeBlocked) {
                      setReusedData(loadedExercise.content.distractor2?._id || '', 'image', 'distractor2', true);
                    } else {
                      dispatch(MultipleChoiceExerciseActionsCreator.removeDistractor2Image());
                    }
                  }}
                  isChangeBlocked={!!isDistractor2ChangeBlocked}
                  errors={distractors1ImageErrors}
                >
                  <ImageUpload
                    width={ImageUploadDimensionDescriptors.multipleChoice.image.width}
                    height={ImageUploadDimensionDescriptors.multipleChoice.image.height}
                    size={Sizes.fullscreen}
                    mode={ImageUploadModes.normal}
                    onChange={(file: File, progressHandler: Function) => {
                      if (file !== null && loadedExercise.content.id !== undefined) {
                        dispatch(
                          MultipleChoiceExerciseActionsCreator.setDistractor2Image(
                            loadedExercise,
                            file,
                            progressHandler,
                          ),
                        );
                      }
                    }}
                    onRemove={() => {
                      dispatch(MultipleChoiceExerciseActionsCreator.removeDistractor2Image());
                    }}
                    imageData={ImageUploadUtils.getDisplayImageForFileUpload(
                      loadedExercise,
                      'image',
                      undefined,
                      'distractor2',
                    )}
                    onProcessingFinished={(url: string) => {
                      dispatch(
                        CommonExerciseActionsCreator.setValueAfterProcessing({
                          url,
                          mediaType: 'image',
                          type: ContentTypes.exercise,
                          field: 'image',
                          language: DEFAULT_LANGUAGE,
                          bundleName: 'distractor2',
                        }),
                      );
                    }}
                    previewMode={false}
                    isForExercise
                    errors={distractors1ImageErrors}
                    fieldName="distractor2Image"
                    isChangeBlocked={!!isDistractor2ChangeBlocked}
                    onChangeInstant={() =>
                      setReusedData(loadedExercise.content.distractor2?._id || '', 'image', 'distractor2', true)
                    }
                  />
                </ContentToggler>
              </div>
            )}
          </>
        </ResourceBundleWrapper>
      </div>
      <div className="exercise-fill-gap__wrapper">
        <Explanation />
      </div>
      <RecapSelector exerciseId={loadedExercise?.content?.id} />
    </div>
  );
};

export default MultipleChoiceExercise;
