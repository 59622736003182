import { useMemo } from 'react';
import styled from 'styled-components/macro';
import * as _ from 'lodash';

import { LevelDetailsTab, LevelSearchResultType } from '../../types';
import { CardWrapper, CardTitle, CardText, CardLocations, CardMoreButton } from '../Common';

const LevelCardWrapper = styled(CardWrapper)`
  grid-template-columns: 48% 25% 20% 4%;
`;

export const LevelResultCard = ({
  content,
  isExpanded,
  onExpand,
}: {
  content: LevelSearchResultType;
  isExpanded: boolean;
  onExpand: (tabName: LevelDetailsTab['key'] | null) => void;
}) => {
  const plainTextTitle = useMemo(
    () => content.title?.replace(/(<([^>]+)>)|(&.+;)/gi, '') || 'Untitled level',
    [content.title],
  );

  return (
    <LevelCardWrapper isExpanded={isExpanded} onClick={() => onExpand(null)}>
      <CardTitle id={content.id} title={plainTextTitle} />
      <CardText text={_.capitalize(content.class)} />
      <CardLocations
        courses={content.courses}
        locationsCount={content.locationsCount}
        onExpand={() => onExpand('locations')}
      />
      <CardMoreButton isExpanded={isExpanded} />
    </LevelCardWrapper>
  );
};
