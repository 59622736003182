import { upperFirst } from 'lodash';
import { MultiValue } from 'react-select';

import { FiltersState } from './types';
import { CommonFilterOptionType } from '@components/SelectorCommonComponents';

export const mapStateValueToFilterOptionValue = <T extends keyof Pick<FiltersState, 'cefr' | 'speechPart'>>(
  stateValue: FiltersState[T],
) =>
  stateValue?.map((value) => ({
    label: upperFirst(value),
    value,
  }));

export const mapFilterOptionValueToStateValue = (selectedMultiOption: MultiValue<CommonFilterOptionType>) =>
  selectedMultiOption?.map(({ value }) => value);

export const handleOnChange = <T extends keyof Pick<FiltersState, 'cefr' | 'speechPart'>>(
  selectedOption: MultiValue<CommonFilterOptionType> | null,
  callback: (value: FiltersState[T]) => void,
) => {
  const value = selectedOption?.length ? mapFilterOptionValueToStateValue(selectedOption) : null;
  callback(value as FiltersState[T]);
};

export const areFiltersSelected = (filters: FiltersState) =>
  Object.keys(filters).some((filterName) => !!filters[filterName as keyof FiltersState]);
