import { PayloadAction } from '@reduxjs/toolkit';
import produce from 'immer';

import { ListenRepeatExerciseActions } from '@actions/ListenRepeatExerciseActions';
import { SectionActions } from '@actions/SectionActions';
import { ExerciseTypes } from '@common/enums/ExerciseTypes';
import { LoadingStage } from '@common/enums/LoadingStage';
import { CourseSliceInterface } from '@common/interfaces/slices/CourseSliceInterface';
import ExerciseDataModelAssembler from '@components/Exercises/ExerciseDataModelAssembler';
import { updateTextField } from '@helpers/updateTextField';
import { UploadMediaResponse } from '@services/HelpersService';
import { selectedGroupsOfParentInitialData } from '@redux/initialStates/courseInitialState';
import { clone } from '@helpers/clone';

const ListenRepeatExerciseCourseReducers = {
  [SectionActions.COURSE_EXERCISE_LISTEN_REPEAT_REQUEST_SUCCEEDED]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    const { content } = payload;

    return produce(state, (draft) => {
      draft.selectedGroupsOfParent = selectedGroupsOfParentInitialData;
      draft.loadedExercise = {
        loaded: LoadingStage.loaded,
        exercise: {
          content,
          type: ExerciseTypes.listenRepeat,
        },
      };
    });
  },

  [ListenRepeatExerciseActions.SET_INSTRUCTIONS_LANGUAGE]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    return produce(state, (draft) => {
      if (draft.loadedExercise.exercise?.content) {
        draft.loadedExercise.exercise.content.instructionsLanguage = payload.instructionsLanguage;
        draft.loadedExercise.exercise.content.instructionsLanguageChanged = true;
      }
    });
  },

  [ListenRepeatExerciseActions.SET_LISTEN_REPEAT_ALL_FIELDS]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<any>,
  ): CourseSliceInterface => {
    let clonedCourseSlice: CourseSliceInterface = clone(state);

    const { values } = payload;

    clonedCourseSlice = updateTextField(clonedCourseSlice, values, 'instructions');
    clonedCourseSlice = updateTextField(clonedCourseSlice, values, 'phrase', 'mainBundle');

    return clonedCourseSlice;
  },

  [ListenRepeatExerciseActions.REMOVE_IMAGE]: (state: CourseSliceInterface): CourseSliceInterface => {
    return ExerciseDataModelAssembler.nullifyFieldInBundle({
      field: 'image',
      bundleName: 'mainBundle',
      state,
    });
  },

  [ListenRepeatExerciseActions.REMOVE_VIDEO]: (state: CourseSliceInterface): CourseSliceInterface => {
    return ExerciseDataModelAssembler.nullifyFieldInBundle({
      field: 'video',
      bundleName: 'mainBundle',
      state,
    });
  },

  [ListenRepeatExerciseActions.UPLOAD_VIDEO_SUCCESS]: (
    state: CourseSliceInterface,
    { payload }: PayloadAction<UploadMediaResponse>,
  ): CourseSliceInterface => {
    const { content, mediaId } = payload;

    return produce(state, (draft) => {
      draft.loadedExercise.exercise.content.mainBundle.video = {
        ...content,
        _id: mediaId,
        changed: true,
      };
      draft.mediaUploading.imageOrVideoUploadingInProcess = draft.mediaUploading.imageOrVideoUploadingInProcess.filter(
        (item) => item !== 'video',
      );
    });
  },
};

export default ListenRepeatExerciseCourseReducers;
