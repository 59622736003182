import { TranslationsPanelContentInterface } from '@common/interfaces/exercises/TranslationsPanelContentInterface';
import { GridLocalizationInterface } from '@common/interfaces/localization/GridLocalizationInterface';
import { LocalizationInterface } from '@common/interfaces/localization/LocalizationInterface';
import { DBId } from '@common/types/DBId';
import { ExerciseService } from '@common/types/ExerciseService';
import ExerciseDataModelAssembler from '@components/Exercises/ExerciseDataModelAssembler';
import TipExerciseContent from '@components/Exercises/Tip/interfaces/TipExerciseContent';
import { FormikValuesInterface } from '@helpers/formikInitialValuesHelper';
import { selectTextLocalizationsForSave, selectMediaLocalizationsForSave } from '@helpers/localizationSaveHelper';
import TipExerciseInterface from '@components/Exercises/Tip/interfaces/TipExerciseInterface';
import { ExerciseCommonActionCreators as ExerciseCommonActions } from '@actionCreators/ExerciseCommonActionCreator';
import ContentsService from '@services/ContentsService';
import ExercisesService from '@services/ExercisesService';
import HelpersService from '@services/HelpersService';
import { AppDispatch } from '@redux/store';
import { clone } from '@helpers/clone';
import { editTipExercise } from '@services/exercises/editExerciseService';
import { EditTipExerciseRequest, DisplayedLanguageType } from '@services/exercises/editExerciseTypes';
import { addToast } from '@features/app/toast';

type TipExerciseServiceType = ExerciseService<TipExerciseInterface> & {
  updateExamples: (exercise: TipExerciseInterface, exerciseId: DBId) => void;
  sanitizeExercise: (exercise: any, data: any) => void;
};

const TipExerciseService: TipExerciseServiceType = {
  sanitizeExercise(exercise: any, data: any) {
    exercise = exercise as TipExerciseContent;

    if (exercise.instructions !== null) {
      exercise = HelpersService.groupByLanguageInBranch(['instructions'], exercise) as TipExerciseContent;
    }

    if (exercise.paragraph !== null) {
      exercise = HelpersService.groupByLanguageInBranch(['paragraph'], exercise) as TipExerciseContent;
    }

    let examples: GridLocalizationInterface[] | null =
      data.data.examples === null ? null : [...(data.data.examples as GridLocalizationInterface[])];

    let maxRows = examples === null ? 1 : Math.max(...examples.map((example) => example.row));
    let maxColumns = examples === null ? 1 : Math.max(...examples.map((example) => example.column));
    let output: LocalizationInterface[][] = [[]];

    if (examples === null || !examples?.length) {
      exercise.examplesWasNullAtLoadTime = true;
      exercise.examples = [[]];
    } else {
      for (let row = 0; row <= maxRows; row++) {
        output[row] = [];

        for (let column = 0; column <= maxColumns; column++) {
          const fieldValue = examples.find((value) => value.column === column && value.row === row);
          if (!fieldValue?.value) {
            output[row][column] = {
              ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranches([
                'textLocalizations',
                'audioLocalizations',
                'imageLocalizations',
                'videoLocalizations',
              ]),
              row: row,
              column: column,
            };
          } else if (fieldValue?.value) {
            output[row][column] = fieldValue?.value;

            output[row][column].row = row;
            output[row][column].column = column;
          }
        }
      }

      exercise.examples = output.map((values) => {
        let output = values.map((value) => {
          return HelpersService.groupLocalization(value);
        });

        return output;
      });
    }

    exercise.mappingsPath = exercise.mappings;
    exercise.mappings = exercise.mappings.length;

    return exercise;
  },
  ensureExerciseFieldsAreReadyForUse(exerciseAndEmptyLocalizationBranchesPayload: any, exercise: TipExerciseInterface) {
    let output = clone(exerciseAndEmptyLocalizationBranchesPayload);

    return ExerciseDataModelAssembler.ensureFieldIsReadyForUse(
      exercise,
      output,
      ['instructions', 'paragraph', 'examples'],
      exerciseAndEmptyLocalizationBranchesPayload,
    );
  },
  async updateExamples(exercise: TipExerciseInterface, exerciseId: DBId) {
    let examples: TranslationsPanelContentInterface[][] | null = exercise.content.examples;
    let examplesContentIds: {
      row: number;
      column: number;
      value: DBId | null;
    }[][] = [];

    if (examples) {
      for (let row = 0; row < examples.length; row++) {
        examplesContentIds[row] = [];

        for (let column = 0; column < examples[row].length; column++) {
          let examplesNewContentID;
          let exampleInCell = examples[row][column];
          const { description, audioLocalizations, textLocalizations } = exampleInCell;
          const payloadForContent = {
            textLocalizations: selectTextLocalizationsForSave(textLocalizations),
            audioLocalizations: selectMediaLocalizationsForSave(audioLocalizations),
            imageLocalizations: [],
            videoLocalizations: [],
            description,
          };

          if (exampleInCell._id === undefined || exampleInCell._id === '') {
            if (!payloadForContent.textLocalizations.length && !payloadForContent.audioLocalizations.length) {
              examplesContentIds[row].push({ row, column, value: null });
            } else {
              examplesNewContentID = await ContentsService.contents.createNewContent(payloadForContent);

              examplesContentIds[row].push({ row, column, value: examplesNewContentID });
            }
          } else if (exampleInCell.changed) {
            // don't pick already uploaded audios
            const processedAudioLocalizations = selectMediaLocalizationsForSave(
              payloadForContent.audioLocalizations.filter((audio) => !audio.value),
            );

            await ContentsService.contents.update(exampleInCell._id, {
              ...payloadForContent,
              audioLocalizations: processedAudioLocalizations.length ? processedAudioLocalizations : [],
            });
            examplesContentIds[row].push({ row, column, value: exampleInCell._id });
          } else {
            examplesContentIds[row].push({ row, column, value: exampleInCell._id });
          }
        }
      }
    }

    return { examples: examplesContentIds.flat() };
  },
  async save(dispatch: AppDispatch, exercise: TipExerciseInterface, values: FormikValuesInterface) {
    try {
      let payload: EditTipExerciseRequest = {
        instructionsLanguage: exercise.content.instructionsLanguage as DisplayedLanguageType,
        examplesLanguage: exercise.content.examplesLanguage as DisplayedLanguageType,
        paragraphLanguage: exercise.content.paragraphLanguage as DisplayedLanguageType,
        numberOfColumns: exercise.content?.examples?.[0].length ? exercise.content?.examples?.[0].length : 1,
        recapExerciseId: exercise.content.recapExerciseId,
        experiment: exercise.content.experiment,
      };

      const updatePayload = await Promise.all([
        (values.instructionsChanged || exercise.content.instructions.changed) && !exercise.content.instructions.isReused
          ? ExercisesService.misc.saveField(dispatch, 'instructions', exercise, payload)
          : { instructions: exercise.content.instructions._id },
        (values.paragraphChanged || exercise.content.paragraph?.changed || exercise.content.paragraphChanged) &&
        !exercise.content.paragraph.isReused
          ? ExercisesService.misc.saveField(dispatch, 'paragraph', exercise, payload)
          : { paragraph: exercise.content.paragraph._id },
        exercise.content.examplesChanged
          ? TipExerciseService.updateExamples(exercise, exercise.content.id as DBId)
          : null,
      ]);

      payload = {
        ...payload,
        ...updatePayload.reduce((sum: any, item: any) => ({ ...sum, ...item }), {}),
      };

      if (exercise.content.id) {
        await editTipExercise(exercise.content.id, payload);

        dispatch(ExerciseCommonActions.setSaveProgress({ value: false, updateData: true }));

        addToast({
          type: 'success',
          title: 'This exercise has been saved',
        });
      }
    } catch (e: any) {
      if (e.response?.status === 400) {
        addToast({
          type: 'error',
          title: `${e.response.data?.detail}`,
        });
      }

      throw new Error(e as string);
    }

    return;
  },
};

export default TipExerciseService;
